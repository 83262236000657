import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { FORM } from '../../config/consts';
import actions from '../../modules/accessSettings';
import PrimaryModal from '../PrimaryModal';
import AddUserAutocomplete from './AddUserAutocomplete';
import { useGetGlobalHooks } from './hooks/getGlobalHooks';
import { useGetUserFormData } from './hooks/getUserFormData';
import messages from './messages';

function AccessGroupAddUserModal({ groupId, onClose, open }) {
  const { dispatch, formatMessage, formMethods } = useGetGlobalHooks();
  const { isLoading, users } = useGetUserFormData();

  const hasFormItemKey = FORM.HAS_FORM;
  const newItemId = FORM.NEW_ITEM_ID;

  const usersList = useMemo(
    () => [
      {
        id: newItemId,
        label: formatMessage(messages.addUserAutocompleteNewUser),
      },
      ...users,
    ],
    [users]
  );
  const { isDirty, isValid } = formMethods.formState;

  useEffect(() => {
    if (open) {
      formMethods.reset({
        email: '',
        firstName: '',
        [hasFormItemKey]: false,
        lastName: '',
        user: null,
      });
      dispatch(actions.getAccessControlUsers(groupId));
    }
  }, [open]);

  const handleCreateAdduser = (data) => {
    if (data.user.id === newItemId) {
      const { email, firstName, lastName } = data;
      dispatch(actions.addAccessGroupUserWithCreate(groupId, { email, firstName, lastName }));
    } else {
      dispatch(actions.addAccessGroupUser(groupId, [data.user.id]));
    }
    onClose();
  };

  const getIsOpen = open || isLoading;
  return (
    getIsOpen && (
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleCreateAdduser)}>
          <PrimaryModal
            actionButtonLabel={formatMessage(messages.accessGroupAddUserModalAdd)}
            isActionButtonDisabled={!isDirty || !isValid}
            isLoading={isLoading}
            name="access-group-addUser-modal"
            onClose={onClose}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onCreate={() => {}}
            open={getIsOpen}
            title={formatMessage(messages.accessGroupAddUserModalTitle)}
          >
            <ModalText />
            <AddUserAutocomplete hasFormItemKey={hasFormItemKey} newItemId={newItemId} users={usersList} />
          </PrimaryModal>
        </form>
      </FormProvider>
    )
  );
}

function ModalText() {
  return (
    <Typography variant="body1">
      <FormattedMessage {...messages.accessGroupAddUserModalText} values={{ br: <br /> }} />
    </Typography>
  );
}

AccessGroupAddUserModal.propTypes = {
  groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

AccessGroupAddUserModal.defaultProps = {
  groupId: '',
};

export default AccessGroupAddUserModal;
