import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const AutocompleteChips = ({
  getIsChipWarning,
  maxLength,
  onChange,
  onClear,
  options,
  placeholder,
  selected,
  size,
  titleKey,
}) => {
  const onChangeHandler = (event, value) => {
    if (options.indexOf(value)) {
      onChange(value);
    }
  };
  const onClearHandler = () => onClear([]);
  const isDisabled = () => maxLength && selected.length === maxLength;
  const hasPlaceholder = () => (maxLength && selected.length < maxLength) || !maxLength;

  return (
    <Box py={1}>
      <Grid container>
        <Grid item xs={10}>
          <Autocomplete
            blurOnSelect
            disableClearable
            filterSelectedOptions
            forcePopupIcon={false}
            getOptionLabel={(option) => option[titleKey]}
            isOptionEqualToValue={(option, value) => option[titleKey] === value[titleKey]}
            multiple
            onChange={onChangeHandler}
            options={options}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  disabled: isDisabled(),
                }}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                }}
                placeholder={hasPlaceholder() ? placeholder : null}
                variant="standard"
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  deleteIcon={
                    <CancelIcon
                      style={
                        getIsChipWarning(option)
                          ? {
                              backgroundColor: 'inherit',
                              color: 'white',
                            }
                          : {}
                      }
                    />
                  }
                  key={option}
                  label={option[titleKey]}
                  size={size}
                  sx={
                    getIsChipWarning(option)
                      ? {
                          backgroundColor: 'warning.main',
                          color: 'common.white',
                        }
                      : {}
                  }
                />
              ))
            }
            size={size}
            sx={isDisabled() ? { '& input': { display: 'none' } } : {}}
            value={selected}
          />
        </Grid>
        {selected.length > 0 && (
          <Grid alignItems="center" container item xs={2}>
            <Grid item>
              <Button color="primary" onClick={onClearHandler} startIcon={<CloseIcon />} sx={{ ml: 1 }}>
                <FormattedMessage {...messages.ClearButton} />
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

AutocompleteChips.propTypes = {
  getIsChipWarning: PropTypes.func.isRequired,
  maxLength: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  placeholder: PropTypes.string.isRequired,
  selected: PropTypes.arrayOf(PropTypes.object).isRequired,
  size: PropTypes.oneOf(['medium', 'small']),
  titleKey: PropTypes.string.isRequired,
};

AutocompleteChips.defaultProps = {
  maxLength: null,
  size: 'medium',
};

export default AutocompleteChips;
