import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import RowMenuItem from '../../../../components/DataGrid/RowMenuItem';
import messages from './messages';

function RemoveApartment({ onClick }) {
  const { formatMessage } = useIntl();

  return (
    <RowMenuItem
      icon={<RemoveIcon />}
      label={formatMessage(messages.accessGroupApartmentsDataGridDataGridRowMenuRemove)}
      onClick={onClick}
    />
  );
}

RemoveApartment.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default RemoveApartment;
