import { defineMessages } from 'react-intl';

export default defineMessages({
  newApartmentFloorHelperText: {
    defaultMessage: 'Usually a group of apartments.',
    description: 'Create new apartment - floor helper text',
    id: 'oneStep.newApartmentFloorHelperText',
  },
  newApartmentFloorLabel: {
    defaultMessage: 'Floor',
    description: 'Create new apartment - floor label',
    id: 'oneStep.newApartmentFloorLabel',
  },
  newApartmentNameHelperText: {
    defaultMessage: 'You can name the apartment after the family name.',
    description: 'Create new apartment - name helper text',
    id: 'oneStep.newApartmentNameHelperText',
  },
  newApartmentNameLabel: {
    defaultMessage: 'Apartment name',
    description: 'Create new apartment - name label',
    id: 'oneStep.newApartmentNameLabel',
  },
  newApartmentNumberHelperText: {
    defaultMessage: 'Use a unique number.',
    description: 'Create new apartment - number helper text',
    id: 'oneStep.newApartmentNumberHelperText',
  },
  newApartmentNumberLabel: {
    defaultMessage: 'Number',
    description: 'Create new apartment - number label',
    id: 'oneStep.newApartmentNumberLabel',
  },
});
