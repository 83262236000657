import { getInitialAccessLogStore, IAccessLogStore } from './accessLog/store';
import { getInitialAccessSettingsStore, IAccessSettingsStore } from './accessSettings/store';
import { getInitialApartmentAdminStore, IApartmentAdminStore } from './apartmentAdmin/store';
import { getInitialApartmentsStore, IApartmentsStore } from './apartments/store';
import { IAuthStore, initialAuthStore } from './auth/store';
import { getInitialBillingStore, IBillingStore } from './billing/store';
import { getInitialCommonStore, ICommonStore } from './common/store';
import { getInitialCompanyStore, ICompanyStore } from './company/store';
import { getInitialCookieSettingsStore, ICookieSettingsStore } from './cookies/store';
import { getInitialDevicesStore, IDevicesStore } from './devices/store/store';
import { getInitialElevatorIntegrationStore, IElevatorIntegrationStore } from './elevatorIntegration/store';
import { getInitialInfrastructureStore, IInfrastructureStore } from './infrastructure/store';
import { getInitialLocalizationStore, ILocalizationStore } from './localization/store';
import {
  getInitialElevatorIntegrationFloorMappingStore,
  IElevatorIntegrationFloorMappingStore,
} from './my2nApi/elevatorIntegrationSlice';
import { getInitialNotificationsStore, INotificationsStore } from './notifications/store';
import { getInitialPaymentsStore, IPaymentsStore } from './payments/store';
import { getInitialPhonebooksStore, IPhonebooksStore } from './phonebooks/store';
import { getInitialSitesStore, ISitesStore } from './sites/store';
import { getInitialSubscriptionStore, ISubscriptionStore } from './subscription/store';
import { getInitialUserStore, IUserStore } from './user/store';

export interface IRootStore {
  accessLog: IAccessLogStore;
  accessSettings: IAccessSettingsStore;
  apartments: IApartmentsStore;
  auth: IAuthStore;
  billing: IBillingStore;
  common: ICommonStore;
  company: ICompanyStore;
  cookieSettings: ICookieSettingsStore;
  devices: IDevicesStore;
  infrastructure: IInfrastructureStore;
  localization: ILocalizationStore;
  notifications: INotificationsStore;
  payments: IPaymentsStore;
  phonebooks: IPhonebooksStore;
  sites: ISitesStore;
  subscription: ISubscriptionStore;
  user: IUserStore;
  elevatorIntegration: IElevatorIntegrationStore;
  apartmentAdmin: IApartmentAdminStore;
  elevatorIntegrationFloorMapping: IElevatorIntegrationFloorMappingStore;
}

export function getInitialStore(): IRootStore {
  return {
    accessLog: getInitialAccessLogStore(),
    accessSettings: getInitialAccessSettingsStore(),
    apartmentAdmin: getInitialApartmentAdminStore(),
    apartments: getInitialApartmentsStore(),
    auth: initialAuthStore(),
    billing: getInitialBillingStore(),
    common: getInitialCommonStore(),
    company: getInitialCompanyStore(),
    cookieSettings: getInitialCookieSettingsStore(),
    devices: getInitialDevicesStore(),
    elevatorIntegration: getInitialElevatorIntegrationStore(),
    elevatorIntegrationFloorMapping: getInitialElevatorIntegrationFloorMappingStore,
    infrastructure: getInitialInfrastructureStore(),
    localization: getInitialLocalizationStore(),
    notifications: getInitialNotificationsStore(),
    payments: getInitialPaymentsStore(),
    phonebooks: getInitialPhonebooksStore(),
    sites: getInitialSitesStore(),
    subscription: getInitialSubscriptionStore(),
    user: getInitialUserStore(),
  };
}
