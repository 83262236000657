import { defineMessages } from 'react-intl';

export default defineMessages({
  AAModalRevokeRightsCancel: {
    defaultMessage: 'Cancel',
    description: 'Revoke apartment admin rights modal - cancel button',
    id: 'AA.modal.revokeRights.cancel',
  },
  AAModalRevokeRightsSubmit: {
    defaultMessage: 'Revoke',
    description: 'Revoke apartment admin rights modal - submit button',
    id: 'AA.modal.revokeRights.submit',
  },
  AAModalRevokeRightsText1: {
    defaultMessage: 'Apartment admin rights are revoked from:',
    description: 'Revoke apartment admin rights modal - header',
    id: 'AA.modal.revokeRights.text1',
  },
  AAModalRevokeRightsTitle: {
    defaultMessage: 'Revoke apartment admin rights',
    description: 'Revoke apartment admin rights modal - title',
    id: 'AA.modal.revokeRights.title',
  },
});
