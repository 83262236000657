import http, { encode } from '.';
import { AxiosResponse } from 'axios';
import { TPaginationParams } from '../modules/commonTypes';

export interface ISiteGenericRequestData {
  companyId: number;
  siteId: number;
}

export function setSiteUrl(data: ISiteGenericRequestData, isFeApi = false): string {
  return `${isFeApi ? 'fe/' : ''}companies/${encode(data.companyId.toString())}/sites/${encode(
    data.siteId.toString()
  )}/`;
}

export async function getSiteData<TResponse>(
  data: ISiteGenericRequestData,
  url: string,
  isFeApi?: boolean,
  params?: TPaginationParams
): Promise<AxiosResponse<TResponse>> {
  if (params) {
    return http.get(`${setSiteUrl(data, isFeApi)}${url}`, { params });
  }
  return http.get(`${setSiteUrl(data, isFeApi)}${url}`);
}

export async function postSiteData<TResponse>(
  siteData: ISiteGenericRequestData,
  url: string,
  data: any,
  isFeApi = false
): Promise<TResponse> {
  if (data) {
    return http.post(`${setSiteUrl(siteData, isFeApi)}${url}`, data);
  }
  return http.post(`${setSiteUrl(siteData, isFeApi)}${url}`);
}

export async function putSiteData<TResponse>(
  siteData: ISiteGenericRequestData,
  url: string,
  data: any,
  isFeApi = false
): Promise<TResponse> {
  if (data) {
    return http.put(`${setSiteUrl(siteData, isFeApi)}${url}`, data);
  }
  return http.put(`${setSiteUrl(siteData, isFeApi)}${url}`);
}

export async function deleteSiteData<TResponse>(
  siteData: ISiteGenericRequestData,
  url: string,
  isFeApi = false
): Promise<TResponse> {
  return http.delete(`${setSiteUrl(siteData, isFeApi)}${url}`);
}

export async function headSiteData<TResponse>(
  siteData: ISiteGenericRequestData,
  url: string,
  params: any,
  isFeApi: boolean
): Promise<AxiosResponse<TResponse>> {
  return http.head(`${setSiteUrl(siteData, isFeApi)}${url}`, { headers: { Accept: '*/*' }, params }); //Axios automatically sends Accept: "application/json, text/plain, */*", which results in 401
}
