import type {} from '@mui/x-data-grid/themeAugmentation';
import { ThemeOptions } from '@mui/material';

declare module '@mui/material' {
  interface Theme {
    navBarHeight: number;
    appNotificationHeightSmallDevice: number;
    appNotificationHeightLargeDevice: number;
  }
}

const navBarHeight = 64;
const appNotificationHeightSmallDevice = 88;
const appNotificationHeightLargeDevice = 42;

export const themeApplicationOptions = {
  appNotificationHeightLargeDevice,
  appNotificationHeightSmallDevice,
  navBarHeight,
};

export const palette = {
  appBar: {
    background: '#003a66',
    companyActive: '#005896',
    text: 'rgba(255,255,255,0.54)',
    username: '#00B4EE',
  },
  background: {
    default: '#F1F4F9',
    light: '#e3f2fd',
    paper: '#ffffff',
  },
  borderColor: '#cdd9e1',
  button: {
    info: {
      background: '#CDD9E1',
      border: '#698398',
      hover: 'rgba(105, 131, 152, 0.08)',
      text: '#698398',
    },
  },
  card: {
    header: '#003a66',
  },
  chip: {
    text: '#132034',
  },
  common: {
    black: '#000',
    white: '#fff',
  },
  divider: '#cdd9e1',
  drawer: {
    selected: '#D7EEFE',
  },
  error: {
    alert: {
      background: '#ffc8c9',
    },
    contrastText: '#FFFFFF',
    dark: '#B00020',
    hoverBg: '#F4433614',
    light: '#cf011c',
    main: '#c0001e',
    text: '#820018',
  },
  fontWeight: {
    bold: 700,
  },
  info: {
    contrastText: '#283345',
    dark: '#819aad',
    light: '#cdd9e1',
    main: '#a4b8c7',
    text: '#698398',
  },
  landingPage: {
    background: '#addcf8',
  },
  menuItem: {
    hover: '#00000014',
  },
  primary: {
    contrastText: '#fff',
    create: 'rgba(215, 238, 254, 0.5)',
    dark: '#0068af',
    light: '#008adf',
    main: '#0079c7',
  },
  qrScanner: {
    background: 'rgb(46, 46, 46)',
    transparent: 'rgba(46, 46, 46, .7)',
  },
  secondary: {
    contrastText: '#fff',
    dark: '#556d83',
    light: '#819aad',
    main: '#698398',
  },
  subscription: {
    backgroundError: '#fbebeb',
    backgroundLight: '#E5ECF0',
  },
  success: {
    contrastText: '#FFFFFF',
    dark: '#388E3C',
    light: '#F2F8F1',
    main: '#43a047',
    text: '#1B5E20',
  },
  text: {
    disabled: '#a4b8c7',
    hint: '#a4b8c7',
    primary: 'rgba(0,0,0,0.87)',
    secondary: 'rgba(0,0,0,0.54)',
  },
  type: 'light',
  warning: {
    contrastText: '#FFFFFF',
    dark: '#F57C00',
    hoverBg: '#ED6C0214',
    light: '#FF9800',
    main: '#FB8C00',
    text: '#B23F00',
  },
};

export const themeOptions: ThemeOptions = {
  components: {
    MuiAlert: {
      styleOverrides: {
        filledInfo: {
          color: palette.info.contrastText,
        },
        outlinedError: {
          color: palette.error.text,
        },
        outlinedInfo: {
          color: palette.info.text,
        },
        outlinedSuccess: {
          color: palette.success.text,
        },
        outlinedWarning: {
          color: palette.warning.text,
        },
        standardError: {
          color: palette.error.text,
        },
        standardInfo: {
          color: palette.info.text,
        },
        standardSuccess: {
          color: palette.success.text,
        },
        standardWarning: {
          color: palette.warning.text,
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          '& .MuiToolbar-root': {
            height: navBarHeight,
          },
          backgroundColor: palette.appBar.background,
          color: palette.appBar.text,
          zIndex: 1201,
        },
      },
    },
    MuiBadge: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          '&.Mui-disabled': {
            backgroundColor: palette.subscription.backgroundLight,
          },
        },
        containedInfo: {
          '&:hover': {
            backgroundColor: 'rgba(164, 184, 199, 1)',
          },
          backgroundColor: palette.button.info.background,
          color: palette.button.info.text,
        },
        outlinedInfo: {
          '&:hover': {
            backgroundColor: palette.button.info.hover,
          },
          borderColor: palette.button.info.border,
          color: palette.button.info.text,
        },
        textInfo: {
          '&:hover': {
            backgroundColor: palette.button.info.hover,
          },
          color: palette.secondary.main,
        },
      },
    },
    MuiCard: {
      defaultProps: {
        elevation: 2,
      },
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
    MuiCardHeader: {
      styleOverrides: {
        action: {
          alignSelf: 'center',
        },
        root: {
          height: '56px',
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        cell: {
          '&:focus, &:focus-within': {
            outline: 'none',
          },
          borderBottomColor: palette.borderColor,
        },
        columnHeader: {
          '&:focus, &:focus-within': {
            outline: 'none',
          },
        },
        columnHeaders: {
          borderBottom: `1px solid ${palette.borderColor} !important`,
        },
        columnHeaderTitleContainer: {
          padding: 0,
        },
        columnSeparator: {
          display: 'none',
        },
        sortIcon: {
          color: palette.secondary.main,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: ({ theme }) =>
          theme.unstable_sx({
            borderRadius: 0,
            maxHeight: {
              sm: 'calc(100% - 160px)',
              xs: 'calc(100% - 48px)',
            },
            mx: 3,
            my: 0,
            position: 'absolute',
            top: (theme) => ({ sm: theme.spacing(14.25), xs: theme.spacing(3) }),
            width: (theme) => `calc(100% - ${theme.spacing(6)})`,
          }),
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: palette.primary.main,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: palette.divider,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          top: navBarHeight,
          transition: 'width .4s ease-in-out',
          width: 256,
        },
        root: {
          '& .MuiListItem-button.Mui-selected': {
            '& span': {
              fontWeight: 700,
            },
            '& svg': {
              fill: palette.primary.main,
            },
            backgroundColor: palette.drawer.selected,
            color: palette.primary.main,
          },
          '& .MuiListItem-root': {
            '& span': {
              fontWeight: 500,
            },
            '& svg': {
              fill: palette.secondary.main,
              verticalAlign: 'middle',
            },
            '&:hover': {
              backgroundColor: palette.menuItem.hover,
            },
            maxHeight: '50px',
            minHeight: '50px',
          },
          '& .MuiListItemIcon-root': {
            minWidth: 0,
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: palette.secondary.main,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.success.dark,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: palette.secondary.dark,
          fontWeight: 700,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: palette.menuItem.hover,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            color: palette.info.main,
            width: 'fit-content',
          },
          '&:-webkit-autofill': {
            transitionDelay: '9999s',
            transitionProperty: 'background-color, color',
          },
        },
        notchedOutline: {
          borderColor: palette.info.main,
        },
        root: {
          '& .MuiSvgIcon-root': {
            color: palette.secondary.main,
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.info.main,
          },
          '&:hover:not(.Mui-focused):not(.Mui-disabled) .MuiOutlinedInput-notchedOutline': {
            borderColor: palette.secondary.dark,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          fill: palette.secondary.main,
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        actions: {
          color: palette.text.secondary,
        },
        selectIcon: {
          fill: palette.text.secondary,
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        indicatorColor: 'primary',
        textColor: 'primary',
      },
    },
    MuiTextField: {
      defaultProps: {
        InputLabelProps: {
          shrink: true,
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        slotProps: {
          popper: {
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [0, -4],
                },
              },
            ],
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        color: 'inherit',
      },
    },
  },
  palette,
};
