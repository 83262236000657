import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert } from '../../components';
import { QR_CODE_READER_ERROR } from '../../config/consts';
import messages from './messages';

const ScanAlerts = ({ error }) => {
  if (error === QR_CODE_READER_ERROR.PERMISSION_DENIED) {
    return (
      <Alert severity="warning" variant="filled">
        <FormattedMessage {...messages.deviceMobileScanQRScanAlertsPermissionDenied} />
      </Alert>
    );
  }

  if (error === QR_CODE_READER_ERROR.INVALID_QR_CODE) {
    return (
      <Alert severity="warning">
        <FormattedMessage {...messages.deviceMobileScanQRScanAlertsInvalidCode} />
      </Alert>
    );
  }

  if (error === QR_CODE_READER_ERROR.TIMEOUT) {
    return (
      <Alert severity="info">
        <FormattedMessage {...messages.deviceMobileScanQRScanAlertsTimeout} />
      </Alert>
    );
  }

  if (error === QR_CODE_READER_ERROR.ALLOW_CAMERA) {
    return (
      <Alert severity="info">
        <FormattedMessage {...messages.deviceMobileScanQRScanAlertsAllowCamera} />
      </Alert>
    );
  }

  return null;
};

ScanAlerts.propTypes = {
  error: PropTypes.string.isRequired,
};

export default ScanAlerts;
