import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import DialpadIcon from '@mui/icons-material/Dialpad';
import { Box, InputAdornment, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { cleanAvailablePin, createUWLUserAssignPin, getAvailablePin } from '../../modules/apartmentAdmin/actions';
import { getCreateUWLWithPinModalSelector } from '../../modules/apartmentAdmin/selectors';
import { IApartmentIdsInfo } from '../../modules/apartments/store';
import { IModalBase } from '../../modules/commonTypes';
import { ActionModal } from '../_DesignSystemWrappers/Modal';
import TextField from '../HookFormFields/TextField';
import { PinPanel } from '../PinPanel';
import messages from './messages';
import {
  defaultValues,
  FormFieldNames,
  getValidationSchema,
  IApartmentAdminPinModalFormData,
} from './validationScheme';

export interface IApartmentAdminPinModalProps extends IModalBase {
  apartmentInfo?: IApartmentIdsInfo;
}

export function ApartmentAdminPinModal({ apartmentInfo, onClose, open: isOpen }: IApartmentAdminPinModalProps) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { error, isFetching, pin } = useSelector(getCreateUWLWithPinModalSelector);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formMethods = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(getValidationSchema(formatMessage)),
  });

  const generatePin = () => {
    if (apartmentInfo) {
      dispatch(getAvailablePin(apartmentInfo.companyId, apartmentInfo.siteId, apartmentInfo.apartmentId));
    }
  };

  const onSuccessfulSubmit = () => {
    setIsSubmitting(false);
    formMethods.reset();
    onClose();
  };

  const onSubmit = (data: IApartmentAdminPinModalFormData) => {
    if (apartmentInfo) {
      const { name } = data;
      setIsSubmitting(true);
      if (pin) {
        dispatch(
          createUWLUserAssignPin(
            apartmentInfo.companyId,
            apartmentInfo.siteId,
            apartmentInfo.apartmentId,
            { lastName: name, pin: pin.pin },
            onSuccessfulSubmit
          )
        );
      }
    }
  };

  useEffect(() => {
    if (error) {
      onClose();
    }
  }, [error]);

  useEffect(() => {
    return () => {
      dispatch(cleanAvailablePin());
    };
  }, []);

  return (
    <FormProvider {...formMethods}>
      <ActionModal
        icon={<CheckIcon />}
        isPrimaryButtonDisabled={isFetching || !formMethods.formState.isValid || !pin || isSubmitting}
        muiDialogProps={{ maxWidth: 'xs' }}
        onClose={onClose}
        open={isOpen}
        primaryButtonAction={formMethods.handleSubmit(onSubmit)}
        primaryButtonText={formatMessage(messages.AAPinModalSavePin)}
        secondaryButtonText={formatMessage(messages.AAPinModalCancel)}
        title={formatMessage(messages.AAPinModalTitle)}
      >
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Box pt={2}>
            <Typography variant="body2" component="span">
              <FormattedMessage {...messages.AAPinModalBody} />
            </Typography>
          </Box>
          <Box pt={2}>
            <TextField
              fullWidth
              name={FormFieldNames.Name}
              required
              helperText={formatMessage(messages.AAPinModalHint)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <DialpadIcon />
                  </InputAdornment>
                ),
              }}
              label={formatMessage(messages.AAPinModalPinName)}
            />
          </Box>
          <Box pt={2}>
            <PinPanel
              isFetching={isFetching && !isSubmitting}
              pin={pin?.pin}
              onGenerate={generatePin}
              onInit={generatePin}
              hasError={!!error}
            />
          </Box>
        </form>
      </ActionModal>
    </FormProvider>
  );
}
