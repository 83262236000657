import { defineMessages } from 'react-intl';

export default defineMessages({
  pinPanelCopied: {
    defaultMessage: 'PIN number copied',
    id: 'shared.notification.PINcopied',
  },
  pinPanelCopy: {
    defaultMessage: 'Copy',
    id: 'userAccessFormPinCopy',
  },
  pinPanelErrorMessage: {
    defaultMessage: 'The PIN couldn’t be generated. Try again',
    id: 'pinPanelErrorMessage',
  },
  pinPanelGenerate: {
    defaultMessage: 'Generate',
    id: 'pinPanelGenerate',
  },
  pinPanelRFIDCopied: {
    defaultMessage: 'RFID key fob number copied',
    id: 'shared.notification.RFIDcopied',
  },
});
