import { defineMessages } from 'react-intl';

export default defineMessages({
  autocompleteGroupApartments: {
    defaultMessage: 'Apartments',
    description: 'Apartments',
    id: 'autocompleteGroupApartments',
  },
  autocompleteGroupDevices: {
    defaultMessage: 'Devices',
    description: 'Devices',
    id: 'autocompleteGroupDevices',
  },
  clearButton: {
    defaultMessage: 'Clear',
    description: 'Autocomplete clear button',
    id: 'autocompleteChipsClearButton',
  },
  placeholderAddDevice: {
    defaultMessage: 'Add device',
    description: 'Add device',
    id: 'autocompleteChipsPlaceholderAddDevice',
  },
  placeholderEmpty: {
    defaultMessage: 'Add device / apartment',
    description: 'Add device / apartment',
    id: 'autocompleteChipsPlaceholderEmpty',
  },
  placeholderSwapApartment: {
    defaultMessage: 'Swap apartment',
    description: 'Swap apartment',
    id: 'autocompleteChipsPlaceholderSwapApartment',
  },
});
