import { Dialog } from '@2n/design-system';
import { Add as AddIcon, Done as DoneIcon } from '@mui/icons-material';
import { Box } from '@mui/material';
import * as React from 'react';
import { IDSModalBase } from '../designSystemUnexported';

export interface ICreateOrEditModalDialogData extends IDSModalBase {
  isEdit: boolean;
  submitDisabled?: boolean;
}

export function CreateOrEditModalDialog(data: ICreateOrEditModalDialogData) {
  return (
    <Dialog
      footerOptions={{
        primaryButton:
          data.primaryButtonAction && data.primaryButtonText !== undefined
            ? {
                action: data.primaryButtonAction,
                icon: {
                  icon: data.isEdit ? <DoneIcon /> : <AddIcon />,
                  iconPosition: 'start',
                },
                isDisabled: data.submitDisabled ?? false,
                text: data.primaryButtonText,
              }
            : undefined,
        secondaryButton:
          data.secondaryButtonAction && data.secondaryButtonText
            ? {
                action: data.secondaryButtonAction,
                text: data.secondaryButtonText,
                variant: 'text',
              }
            : undefined,
      }}
      hasBodyContentPadding={false}
      hideCloseButton={false}
      onClose={data.onClose}
      open={!!data.open}
      title={data.title}
    >
      <Box data-testid={data.testId} sx={{ pt: 3, px: 3 }}>
        {data.children}
      </Box>
    </Dialog>
  );
}

export default CreateOrEditModalDialog;
