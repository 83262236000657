import React, { useCallback, useState } from 'react';
import { IModalBase } from '../modules/commonTypes';

interface IUseModalReturn<TProps> {
  isOpen: boolean;
  Modal: React.FC<TProps>;
  onOpen: () => void;
}

interface IModal<TProps extends IModalBase> {
  Modal: React.FC<TProps>;
  onClose?: () => void;
}

export function useModal<TProps extends IModalBase>({
  Modal,
  onClose = () => undefined,
}: IModal<TProps>): IUseModalReturn<Omit<TProps, keyof IModalBase>> {
  const [open, setOpen] = useState(false);

  const handleOpen = useCallback(() => setOpen(true), []);
  const handleClose = useCallback(() => {
    onClose();
    setOpen(false);
  }, []);

  function DecoratedModal<TDecoratedProps extends TProps>({ ...props }: TDecoratedProps) {
    return open ? <Modal {...props} onClose={handleClose} open /> : null;
  }

  return {
    isOpen: open,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    Modal: useCallback(DecoratedModal, [open]),
    onOpen: handleOpen,
  };
}
