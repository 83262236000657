import { Grid } from '@mui/material';
import React from 'react';
import { getDeviceMaxButtonCount } from '../../../../modules/devices/helpers/callSettings/devicesCallSettingsHelper';
import AddButtonDevice from '../AddButtonDevice';
import DeviceSelect from '../DeviceSelect';
import { IDeviceCallSettings } from '../helpers/types';
import { IDeviceWithCallSettingsDetails } from '../hooks/useGetCallSettingsState';
import Settings from '../Settings';

export interface IDeviceCallSettingsData {
  addedDeviceId: number;
  addedDeviceApartmentId?: number;
  availableDevices: IDeviceWithCallSettingsDetails[];
  currentSelectedDevice: IDeviceCallSettings;
  ringingGroupSizeLimit: number;
  onClearDevice: (deviceId: number) => void;
  onSelectDevice: (deviceId: number, previousDeviceId?: number) => void;
  onAddDevice: () => void;
  onAddButton: (deviceId: number) => void;
  onAddCallDestination: (deviceId: number, buttonIndex: number, addDevice: boolean) => void;
  onChangeButtonName: (deviceId: number, buttonIndex: number, name: string) => void;
  onClearDestination: (deviceId: number, buttonIndex: number, optionKey: number) => void;
  isDeviceSelected: (deviceId: number) => boolean;
  canAddDevice: boolean;
  isAddingDeviceDisabled: boolean;
}

export function DeviceCallSettings(data: IDeviceCallSettingsData) {
  return (
    <>
      <Grid container>
        <Grid item sm={7} xs={12}>
          <DeviceSelect
            availableDevices={data.availableDevices}
            isDeviceSelected={data.isDeviceSelected}
            onClear={() =>
              data.currentSelectedDevice.device && data.onClearDevice(data.currentSelectedDevice.device.id)
            }
            onSelect={(value) => data.onSelectDevice(value, data.currentSelectedDevice.device?.id)}
            value={data.currentSelectedDevice.device?.id ?? ''}
          />
        </Grid>
        {data.canAddDevice && (
          <Grid item sm={5} sx={{ mt: 1.25 }} xs={12}>
            {data.canAddDevice && <AddButtonDevice disabled={data.isAddingDeviceDisabled} onAdd={data.onAddDevice} />}
          </Grid>
        )}
      </Grid>
      {data.currentSelectedDevice.selected && (
        <Settings
          addedDeviceId={data.addedDeviceId}
          deviceButtonsData={data.currentSelectedDevice.buttons}
          deviceMaxButtonCount={
            data.currentSelectedDevice.device ? getDeviceMaxButtonCount(data.currentSelectedDevice.device) : undefined
          }
          hasSelectedApartment={data.addedDeviceApartmentId !== undefined && data.addedDeviceApartmentId > 0}
          onAddButton={() =>
            data.currentSelectedDevice.device && data.onAddButton(data.currentSelectedDevice.device.id)
          }
          onAddCallDestination={(index, addDevice) =>
            data.currentSelectedDevice.device &&
            data.onAddCallDestination(data.currentSelectedDevice.device.id, index, addDevice)
          }
          onChange={(index, name) =>
            data.currentSelectedDevice.device &&
            data.onChangeButtonName(data.currentSelectedDevice.device.id, index, name)
          }
          onClear={(index, optionKey) =>
            data.currentSelectedDevice.device &&
            data.onClearDestination(data.currentSelectedDevice.device.id, index, optionKey)
          }
          ringingGroupSizeLimit={data.ringingGroupSizeLimit}
        />
      )}
    </>
  );
}
