import { ElIntSingleFloorMapping } from '../../../apiRtk/be4feApi';

export function getFloorMappingDto(floorMappingFloors: ElIntSingleFloorMapping[]) {
  const regex = /^$|^null$/;
  return {
    floors: floorMappingFloors
      .map((floorMappingFloor) => {
        return {
          floorId: floorMappingFloor.floorId,
          mappings: floorMappingFloor.mappings.filter(
            (mapping) =>
              !regex.test(mapping.areaId) && !regex.test(mapping.buildingId) && !regex.test(mapping.elevatorGroupId)
          ),
        };
      })
      .filter((floorMappingFloor) => floorMappingFloor.mappings.length !== 0),
  };
}
