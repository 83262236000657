import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { DEVICE_TYPE } from '../../../../config/devices';
import { getTypeFromDeviceType } from '../../../../helpers/devices';
import messages from '../../messages';
import AccessUnit from './AccessUnit';
import CapableLicenseModal from './CapableLicenseModal';
import LicenseBoughtInfo from './LicenseBoughtInfo';
import LicenseSelectionItem from './LicenseSelectionItem';
import NoLicenseNew from './NoLicenseNew';

// eslint-disable-next-line complexity
const LicenseSelection = ({ onChange, selected, serialNumber, snLicenseData }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const data = useMemo(
    () => snLicenseData.serialNumbers.find((item) => item.serialNumber === serialNumber),
    [serialNumber, snLicenseData]
  );

  const isAccessUnit = () => {
    const type = getTypeFromDeviceType(data?.deviceType || '');
    return [DEVICE_TYPE.ACCESS_UNIT, DEVICE_TYPE.ACCESS_UNIT_M, DEVICE_TYPE.CHAMELEON].includes(type);
  };

  return (
    <>
      <CapableLicenseModal isOpen={isDialogOpen} onClose={() => setIsDialogOpen(false)} />
      {data.purchased.length > 0 && (
        <Box>
          <LicenseBoughtInfo licenses={data.purchased} onShowDialog={() => setIsDialogOpen(true)} />
        </Box>
      )}
      <FormControl component="fieldset" fullWidth>
        {data.available.length > 0 && (
          <Typography>
            <FormattedMessage
              {...messages.licenseSelectionFormLabelText}
              values={{
                link: (chunks) => (
                  <Link component="button" onClick={() => setIsDialogOpen(true)} variant="body2">
                    {chunks}
                  </Link>
                ),
              }}
            />
          </Typography>
        )}
        <FormGroup>
          {data.available.length > 0
            ? data.available.map((license) => (
                <LicenseSelectionItem
                  key={license}
                  license={license}
                  onChange={onChange}
                  selected={selected}
                  snLicenseData={snLicenseData}
                />
              ))
            : null}
          {data.available.length === 0 && isAccessUnit() ? <AccessUnit /> : null}
          {data.available.length === 0 && data.purchased.length === 0 && !isAccessUnit() ? <NoLicenseNew /> : null}
        </FormGroup>
      </FormControl>
    </>
  );
};

LicenseSelection.propTypes = {
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.shape({}).isRequired,
  serialNumber: PropTypes.string.isRequired,
  snLicenseData: PropTypes.shape({
    currency: PropTypes.string,
    prices: PropTypes.shape({}),
    serialNumbers: PropTypes.arrayOf(
      PropTypes.shape({
        available: PropTypes.arrayOf(PropTypes.string),
        capable: PropTypes.arrayOf(PropTypes.string),
        deviceType: PropTypes.string,
        purchased: PropTypes.arrayOf(PropTypes.string),
      })
    ),
  }).isRequired,
};

export default LicenseSelection;
