import { defineMessages } from 'react-intl';

export default defineMessages({
  accessGroupDeleteModalDelete: {
    defaultMessage: 'Delete group',
    description: 'Access group delete modal - delete button',
    id: 'accessGroupDeleteModal.delete',
  },
  accessGroupDeleteModalDialogBodyApartmentsUsersDevices: {
    defaultMessage:
      'Do you want to delete the {groupName} Access group?' +
      '<p>Users from  {apartments, plural, one {<apartmentLink># apartment</apartmentLink>}' +
      ' other {<apartmentLink># apartments</apartmentLink>}} and other' +
      ' {users, plural, one {<usersLink># user</usersLink>} other {<usersLink># users</usersLink> }}' +
      ' will lose access via {devices, plural, one {this device} other {these devices}}:</p>',
    description: 'Delete dialog body - apartments, users, devices',
    id: 'dialogBody.apartmentsUsersDevices',
  },
  accessGroupDeleteModalDialogBodyNoApartments: {
    defaultMessage:
      'Do you want to delete the {groupName} Access group?' +
      '<p>{users, plural, one {<usersLink># user</usersLink> } other {<usersLink># users</usersLink> }}' +
      ' will lose access via {devices, plural, one {this device} other {these devices}}:</p>',
    description: 'Delete dialog body - users, no apartments',
    id: 'dialogBody.noApartments',
  },
  accessGroupDeleteModalDialogBodyNoApartmentsNoUsers: {
    defaultMessage:
      'The {groupName} Access group contains' +
      ' {users, plural, one {# user} other {# users}} and' +
      ' {devices, plural, one {# device} other {# devices}}.' +
      '<p>No users will lose access.</p>',
    description: 'Delete dialog body - no apartments, no users',
    id: 'dialogBody.noApartmentsNoUsers',
  },
  accessGroupDeleteModalDialogBodyNoDevices: {
    defaultMessage: 'The {groupName} Access group contains {devices} devices.<p>No users will lose access.</p>',
    description: 'Delete dialog body - no devices',
    id: 'dialogBody.noDevices',
  },
  accessGroupDeleteModalDialogBodyNoUsers: {
    defaultMessage:
      'Do you want to delete the {groupName} Access group?' +
      '<p>{apartments, plural, one {<apartmentLink># apartment</apartmentLink>}' +
      ' other {<apartmentLink># apartments</apartmentLink>}}' +
      ' will lose access via {devices, plural, one {this device} other {these devices}}:</p>',
    description: 'Delete dialog body - apartments, no users',
    id: 'dialogBody.noUsers',
  },
  accessGroupDeleteModalTitle: {
    defaultMessage: 'Delete Access group',
    description: 'Access group delete modal - title',
    id: 'accessGroupDeleteModal.title',
  },
});
