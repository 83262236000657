import { defineMessages } from 'react-intl';

export default defineMessages({
  accessGroupUsersDataGridAddUser: {
    defaultMessage: 'Add user to group',
    description: 'Access settings table - add users button label',
    id: 'accessGroupUsersDataGridAddUser',
  },
  accessGroupUsersDataGridAddUsers: {
    defaultMessage: 'Add users to group',
    description: 'Access settings table - add users button label',
    id: 'accessGroupUsersDataGridAddUsers',
  },
  accessGroupUsersDataGridColumnsAccess: {
    defaultMessage: 'Access method',
    description: 'Access settings users table - column access',
    id: 'accessGroupUsersDataGridColumnsAccess',
  },
  accessGroupUsersDataGridColumnsApartment: {
    defaultMessage: 'Apartment',
    description: 'Access settings users table - column apartment',
    id: 'accessGroupUsersDataGridColumnsApartment',
  },
  accessGroupUsersDataGridColumnsGroups: {
    defaultMessage: 'Access groups',
    description: 'Access settings users table - column groups',
    id: 'accessGroupUsersDataGridColumnsGroups',
  },
  accessGroupUsersDataGridColumnsName: {
    defaultMessage: 'User in group',
    description: 'Access settings users table - column name',
    id: 'accessGroupUsersDataGridColumnsName',
  },
  accessGroupUsersDataGridNoRowsOverlayHeaderText: {
    defaultMessage: 'No users in Access groups yet',
    description: 'Access group devices no rows - headerText',
    id: 'accessGroupUsersDataGridNoRowsOverlayHeaderText',
  },
  accessGroupUsersDataGridNoRowsOverlayText: {
    defaultMessage:
      'Access groups define which users have access to which devices.' +
      '{br}Add users to provide them with access via devices in this group.',
    description: 'Access group devices no rows - text',
    id: 'accessGroupUsersDataGridNoRowsOverlayText',
  },
});
