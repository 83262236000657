import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';

const DevicesInGroupText = ({ allCount, inactiveCount }) => (
  <Box component="span" sx={{ display: 'inline' }}>
    {allCount}
    {inactiveCount > 0 && (
      <Box color="warning.main" component="span">
        {` (${inactiveCount})`}
      </Box>
    )}
  </Box>
);

DevicesInGroupText.propTypes = {
  allCount: PropTypes.number.isRequired,
  inactiveCount: PropTypes.number.isRequired,
};

export default DevicesInGroupText;
