import PropTypes from 'prop-types';
import React from 'react';
import CheckStep from '../CheckStep';
import IntroStep from '../IntroStep';
import ResultStep from '../ResultStep';
import SummaryStep from '../SummaryStep';
import UploadStep from '../UploadStep';

function ContentSwitch({ activeStep }) {
  let step;
  switch (activeStep) {
    case 0:
      step = <IntroStep />;
      break;
    case 1:
      step = <UploadStep />;
      break;
    case 2:
      step = <CheckStep />;
      break;
    case 3:
      step = <SummaryStep />;
      break;
    case 4:
      step = <ResultStep />;
      break;
    default:
      step = null;
      break;
  }
  return step;
}

ContentSwitch.propTypes = {
  activeStep: PropTypes.number.isRequired,
};

export default ContentSwitch;
