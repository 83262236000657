import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  root: {
    '& dl': {
      '& dd': {
        '&:after': {
          content: '"\\a"',
          whiteSpace: 'pre',
        },
        color: 'secondary.main',
        display: 'inline',
        m: 0,
      },
      '& dt': {
        '&:first-of-type': {
          mt: 0,
        },
        color: 'primary.main',
        display: 'inline-block',
        fontWeight: 'bold',
        mt: 0.25,
      },
    },
    listStyle: 'none',
    m: 0,
    p: 0,
  },
};

const GTCDescription = ({ html }) => <Box dangerouslySetInnerHTML={{ __html: html }} sx={styles.root} />;

GTCDescription.propTypes = {
  html: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default GTCDescription;
