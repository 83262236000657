export const moduleName = 'apartments';

export default {
  ADD_APARTMENT_FAILURE: `${moduleName}/ADD_APARTMENT_FAILURE`,
  ADD_APARTMENT_REQUEST: `${moduleName}/ADD_APARTMENT_REQUEST`,
  ADD_APARTMENT_SUCCESS: `${moduleName}/ADD_APARTMENT_SUCCESS`,
  ADD_FLOOR_FAILURE: `${moduleName}/ADD_FLOOR_FAILURE`,
  ADD_FLOOR_REQUEST: `${moduleName}/ADD_FLOOR_REQUEST`,
  ADD_FLOOR_SUCCESS: `${moduleName}/ADD_FLOOR_SUCCESS`,
  CREATE_ADD_APARTMENT_USERS_FAILURE: `${moduleName}/CREATE_ADD_APARTMENT_USERS_FAILURE`,
  CREATE_ADD_APARTMENT_USERS_REQUEST: `${moduleName}/CREATE_ADD_APARTMENT_USERS_REQUEST`,
  CREATE_ADD_APARTMENT_USERS_SUCCESS: `${moduleName}/CREATE_ADD_APARTMENT_USERS_SUCCESS`,
  CREATE_MULTIPLE_APARTMENT_USERS_FAILURE: `${moduleName}/CREATE_MULTIPLE_APARTMENT_USERS_FAILURE`,
  CREATE_MULTIPLE_APARTMENT_USERS_REQUEST: `${moduleName}/CREATE_MULTIPLE_APARTMENT_USERS_REQUEST`,
  CREATE_MULTIPLE_APARTMENT_USERS_SUCCESS: `${moduleName}/CREATE_MULTIPLE_APARTMENT_USERS_SUCCESS`,
  DELETE_APARTMENT_DEVICE_SUCCESS: `${moduleName}/DELETE_APARTMENT_DEVICE_SUCCESS`,
  DELETE_APARTMENT_FAILURE: `${moduleName}/DELETE_APARTMENT_FAILURE`,
  DELETE_APARTMENT_HIDE_MODAL: `${moduleName}/DELETE_APARTMENT_HIDE_MODAL`,
  DELETE_APARTMENT_REQUEST: `${moduleName}/DELETE_APARTMENT_REQUEST`,
  DELETE_APARTMENT_SHOW_MODAL: `${moduleName}/DELETE_APARTMENT_SHOW_MODAL`,
  DELETE_APARTMENT_SUCCESS: `${moduleName}/DELETE_APARTMENT_SUCCESS`,
  DELETE_APARTMENT_USER_FAILURE: `${moduleName}/DELETE_APARTMENT_USER_FAILURE`,
  DELETE_APARTMENT_USER_REQUEST: `${moduleName}/DELETE_APARTMENT_USER_REQUEST`,
  DELETE_APARTMENT_USER_SUCCESS: `${moduleName}/DELETE_APARTMENT_USER_SUCCESS`,
  EDIT_APARTMENT_FAILURE: `${moduleName}/EDIT_APARTMENT_FAILURE`,
  EDIT_APARTMENT_REQUEST: `${moduleName}/EDIT_APARTMENT_REQUEST`,
  EDIT_APARTMENT_SUCCESS: `${moduleName}/EDIT_APARTMENT_SUCCESS`,
  GET_APARTMENT_DETAIL_FAILURE: `${moduleName}/GET_APARTMENT_DETAIL_FAILURE`,
  GET_APARTMENT_DETAIL_REQUEST: `${moduleName}/GET_APARTMENT_DETAIL_REQUEST`,
  GET_APARTMENT_DETAIL_SUCCESS: `${moduleName}/GET_APARTMENT_DETAIL_SUCCESS`,
  GET_APARTMENT_DEVICES_FAILURE: `${moduleName}/GET_APARTMENT_DEVICES_FAILURE`,
  GET_APARTMENT_DEVICES_REQUEST: `${moduleName}/GET_APARTMENT_DEVICES_REQUEST`,
  GET_APARTMENT_DEVICES_SUCCESS: `${moduleName}/GET_APARTMENT_DEVICES_SUCCESS`,
  GET_APARTMENT_USERS_FAILURE: `${moduleName}/GET_APARTMENT_USERS_FAILURE`,
  GET_APARTMENT_USERS_REQUEST: `${moduleName}/GET_APARTMENT_USERS_REQUEST`,
  GET_APARTMENT_USERS_SUCCESS: `${moduleName}/GET_APARTMENT_USERS_SUCCESS`,
  GET_APARTMENTS_LIST_FAILURE: `${moduleName}/GET_APARTMENTS_LIST_FAILURE`,
  GET_APARTMENTS_LIST_REQUEST: `${moduleName}/GET_APARTMENTS_LIST_REQUEST`,
  GET_APARTMENTS_LIST_SUCCESS: `${moduleName}/GET_APARTMENTS_LIST_SUCCESS`,
  GET_SITE_APARTMENTS_FAILURE: `${moduleName}/GET_SITE_APARTMENTS_FAILURE`,
  GET_SITE_APARTMENTS_REQUEST: `${moduleName}/GET_SITE_APARTMENTS_REQUEST`,
  GET_SITE_APARTMENTS_SUCCESS: `${moduleName}/GET_SITE_APARTMENTS_SUCCESS`,
  GET_SITE_FLOORS_FAILURE: `${moduleName}/GET_SITE_FLOORS_FAILURE`,
  GET_SITE_FLOORS_REQUEST: `${moduleName}/GET_SITE_FLOORS_REQUEST`,
  GET_SITE_FLOORS_SUCCESS: `${moduleName}/GET_SITE_FLOORS_SUCCESS`,
  GRANT_APARTMENT_ADMIN_FAILURE: `${moduleName}/GRANT_APARTMENT_ADMIN_FAILURE`,
  GRANT_APARTMENT_ADMIN_REQUEST: `${moduleName}/GRANT_APARTMENT_ADMIN_REQUEST`,
  GRANT_APARTMENT_ADMIN_SUCCESS: `${moduleName}/GRANT_APARTMENT_ADMIN_SUCCESS`,
  REVOKE_APARTMENT_ADMIN_FAILURE: `${moduleName}/REVOKE_APARTMENT_ADMIN_FAILURE`,
  REVOKE_APARTMENT_ADMIN_REQUEST: `${moduleName}/REVOKE_APARTMENT_ADMIN_REQUEST`,
  REVOKE_APARTMENT_ADMIN_SUCCESS: `${moduleName}/REVOKE_APARTMENT_ADMIN_SUCCESS`,
  SET_APARTMENT_ACCESS_GROUP_HIDE_MODAL: `${moduleName}/SET_APARTMENT_ACCESS_GROUP_HIDE_MODAL`,
  SET_APARTMENT_ACCESS_GROUP_SHOW_MODAL: `${moduleName}/SET_APARTMENT_ACCESS_GROUP_SHOW_MODAL`,
};
