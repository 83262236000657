export const LICENSE = {
  APARTMENT_MOBILE_VIDEO_MONTH: 'APARTMENT_MOBILE_VIDEO_MONTH',
  APARTMENT_MOBILE_VIDEO_YEAR: 'APARTMENT_MOBILE_VIDEO_YEAR',
  MOBILE_VIDEO_MONTH: 'MOBILE_VIDEO_MONTH',
  MOBILE_VIDEO_YEAR: 'MOBILE_VIDEO_YEAR',
};

export const PROMO = 'PROMO';

export const TYPE = {
  INTERCOM_LICENSES: 'INTERCOM_LICENSES',
  MOBILE_VIDEO_CODE: 'MOBILE_VIDEO_CODE',
  MOBILE_VIDEO_LICENSE: 'MOBILE_VIDEO_LICENSE',
  PROMO_CODE: 'PROMO_CODE',
};

export const PROCESS = {
  START: 'start',
  STOP: 'stop',
};

export const SERVICE = {
  SUBSCRIPTION_PAYMENT: 'SUBSCRIPTION_PAYMENT',
};

export const PURCHASE_PAYMENT_RESULT = {
  CARD_ERROR: 'CARD_ERROR',
  CARD_ISSUER_ERROR: 'CARD_ISSUER_ERROR',
  CREDIT_LIMIT_EXCEEDED: 'CREDIT_LIMIT_EXCEEDED',
  GATEWAY_ERROR: 'GATEWAY_ERROR',
  INVOICE_PAYMENT_ERROR: 'INVOICE_PAYMENT_ERROR',
  MY2N_ERROR: 'MY2N_ERROR',
  NOT_PROCESSED: 'NOT_PROCESSED',
  OK: 'OK',
  SESSION_EXPIRED: 'SESSION_EXPIRED',
  USER_ERROR: 'USER_ERROR',
};

export const PURCHASE_PAYMENT_STATE = {
  BEFORE_COMPLETION: 'BEFORE_COMPLETION',
  CANCELLED: 'CANCELLED',
  COMPLETED: 'COMPLETED',
  CREATED: 'CREATED',
  DELIVERED: 'DELIVERED',
  FAILED: 'FAILED',
  INVOICE_ISSUED: 'INVOICE_ISSUED',
  PAID: 'PAID',
};

export const BILLING_TYPE = {
  PER_APARTMENT: 'PER_APARTMENT',
  PER_DEVICE: 'PER_DEVICE',
};
