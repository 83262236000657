import { PATHS } from '../../config/consts';

export const getHtmlContent = (html, companyId) =>
  html
    .replace(/%BASE_URL%/g, PATHS.GTC_PAGES_COMPANY_CONTEXT.replace(':companyId', companyId))
    .replace(/%PUBLIC_URL%/g, process.env.PUBLIC_URL);

export const getSidebarData = (html) => {
  const nodes = [];
  if (html) {
    const document = new DOMParser().parseFromString(html, 'text/html');
    document.querySelectorAll('div[data-heading="true"]').forEach((element) => {
      nodes.push({
        scrollLink: element.id,
        title: element.getAttribute('data-title'),
      });
    });
  }
  return nodes;
};

export const getAgreeDocument = (agreements, termsId) => {
  if (typeof termsId === 'number' && termsId && typeof agreements === 'object' && agreements.length > 0) {
    return agreements.find((agreement) => agreement?.termsId === termsId) || null;
  }
  return null;
};
