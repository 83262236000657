import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { NonClosingModal } from '../../../components/_DesignSystemWrappers/Modal';
import { INonClosingModalData } from '../../../components/_DesignSystemWrappers/Modal/NonClosingModal';
import BlockImport from './BlockImport';
import messages from './messages';

function BlockModal({ onClose }: { onClose: INonClosingModalData['onClose'] }) {
  const { formatMessage } = useIntl();

  return (
    <NonClosingModal
      closeOnlyByButton
      hideCloseButton
      primaryButtonText={formatMessage(messages.SiteUsersImportBlockModalOk)}
      secondaryButtonText={''}
      onClose={onClose}
      title={formatMessage(messages.SiteUsersImportModalImportUsers)}
    >
      <BlockImport />
    </NonClosingModal>
  );
}

BlockModal.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default BlockModal;
