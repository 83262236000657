import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import Alert from '../Alert';
import messages from './messages';

function UserAccessGroupsAlert({ hasApartmentRacDevice, isRacActive }) {
  const { formatMessage } = useIntl();
  return (
    (!isRacActive || !hasApartmentRacDevice) && (
      <Box sx={{ '& .MuiAlert-root': { my: 0 } }}>
        <Alert severity="warning">
          {isRacActive
            ? formatMessage(messages.userAccessGroupsAlertNoApartmentRacDevice)
            : formatMessage(messages.userAccessGroupsAlertNoRacActive)}
        </Alert>
      </Box>
    )
  );
}

UserAccessGroupsAlert.propTypes = {
  hasApartmentRacDevice: PropTypes.bool.isRequired,
  isRacActive: PropTypes.bool.isRequired,
};

export default UserAccessGroupsAlert;
