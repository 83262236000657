import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import { FormikFields, SerialNumberMaskedInput } from '../../../../components';
import { addSnackbarError, addSnackbarSuccess } from '../../../../modules/notifications/actions';
import messages from '../../messages';
import SerialNumberList from './SerialNumberList';

const styles = {
  addButton: {
    mt: {
      md: 1,
      xs: 0,
    },
  },
  title: {
    mb: 2,
  },
};

const OneStep = ({ onAddSerialNumber, onSetLicenses, onStepChange, snLicenseData, snLicenseIsLoading }) => {
  const dispatch = useDispatch();
  const intl = useIntl();

  const [serialNumbers, addSerialNumberToList] = useState([]);

  const validationSchema = Yup.object().shape({
    serialNumber: Yup.string()
      .required(<FormattedMessage {...messages.oneStepSerialNumberInputRequired} />)
      .matches(
        /^([0-9]{2})-([0-9]{4})-([0-9]{4})$/,
        <FormattedMessage {...messages.oneStepSerialNumberInputInvalid} />
      ),
  });

  const handleAddSerialNumber = (values, { resetForm }) => {
    if (!serialNumbers.includes(values.serialNumber)) {
      addSerialNumberToList([...serialNumbers, values.serialNumber]);
      onAddSerialNumber([...serialNumbers, values.serialNumber]);
      resetForm({ serialNumber: '' });
    } else {
      dispatch(addSnackbarError(intl.formatMessage(messages.serialNumberListAddAlreadyExist)));
    }
  };

  const handleRemoveSerialNumber = (serialNumber) => {
    const newObj = serialNumbers.filter((item) => item !== serialNumber);
    addSerialNumberToList(newObj);
    dispatch(addSnackbarSuccess(messages.serialNumberListRemoveSuccess));
  };

  return (
    <>
      <Typography sx={styles.title}>
        <FormattedMessage
          values={{
            sup: (chunks) => <sup>{chunks}</sup>,
          }}
          {...messages.oneStepTitle}
        />
      </Typography>
      <Formik
        initialValues={{
          serialNumber: '',
        }}
        onSubmit={handleAddSerialNumber}
        render={({ errors, isValid, touched, values }) => (
          <Form>
            <Grid alignItems="center" container spacing={4}>
              <Grid item lg={4} md={6} xl={3} xs={12}>
                <FormikFields.Input
                  error={touched.serialNumber && !isValid}
                  helperText={errors.serialNumber ? errors.serialNumber : null}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{ inputComponent: SerialNumberMaskedInput }}
                  label={
                    <FormattedMessage
                      values={{
                        sup: (chunks) => <sup>{chunks}</sup>,
                      }}
                      {...messages.oneStepSerialNumberInputLabel}
                    />
                  }
                  name="serialNumber"
                  placeholder="__-____-____"
                  required
                  value={values.serialNumber}
                />
              </Grid>
              <Grid item sx={styles.addButton}>
                <Button color="primary" disabled={!isValid} startIcon={<AddIcon />} type="submit" variant="contained">
                  <FormattedMessage {...messages.oneStepAddButton} />
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
        validationSchema={validationSchema}
      />
      <SerialNumberList
        onRemoveSerialNumber={handleRemoveSerialNumber}
        onSetLicenses={onSetLicenses}
        onStepChange={onStepChange}
        serialNumbers={serialNumbers}
        snLicenseData={snLicenseData}
        snLicenseIsLoading={snLicenseIsLoading}
      />
    </>
  );
};

OneStep.propTypes = {
  onAddSerialNumber: PropTypes.func.isRequired,
  onSetLicenses: PropTypes.func.isRequired,
  onStepChange: PropTypes.func.isRequired,
  snLicenseData: PropTypes.shape({}).isRequired,
  snLicenseIsLoading: PropTypes.bool.isRequired,
};

export default OneStep;
