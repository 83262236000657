import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FE_ROLES } from '../../config/consts';
import { UserRoles } from '../../modules/user/store';
import messages from './messages';

export interface IUserRoleNameProps {
  userRole: UserRoles;
}

export function UserRoleName({ userRole }: IUserRoleNameProps) {
  switch (userRole) {
    case FE_ROLES.SITE_USER:
      return <FormattedMessage {...messages.userRoleNameSiteUser} />;
    case FE_ROLES.SITE_ADMIN:
      return <FormattedMessage {...messages.userRoleNameSiteAdmin} />;
    case FE_ROLES.SITE_ADMIN_PAYMENT:
      return <FormattedMessage {...messages.userRoleNameSiteAdminPayment} />;
    case FE_ROLES.COMPANY_ADMIN:
      return <FormattedMessage {...messages.userRoleNameCompanyAdmin} />;
    case FE_ROLES.LOGIN_LESS:
      return <FormattedMessage {...messages.userRoleNameLoginLess} />;
    case FE_ROLES.APARTMENT_ADMIN:
      return <FormattedMessage {...messages.userRoleNameApartmentAdmin} />;
    case FE_ROLES.GUEST || FE_ROLES.APARTMENT_GUEST:
      return <FormattedMessage {...messages.userRoleNameGuest} />;
    default:
      return <FormattedMessage {...messages.userRoleNameSiteUser} />;
  }
}
