import { EmailOutlined } from '@mui/icons-material';
import { Stack } from '@mui/material';
import React from 'react';
import { UserIcon } from '../../../../components';
import { TypographyWithEllipsisTooltip } from '../../../../components/TypographyWithEllipsisTooltip';
import { ApartmentUser } from '../../../../modules/apartmentAdmin/data';
import { getUserName } from '../../../../modules/user/utils';
import { palette } from '../../../../theme';

export interface IAAUserInfoProps {
  user: ApartmentUser;
}

export function AAUserInfo({ user }: IAAUserInfoProps) {
  return (
    <Stack sx={{ mb: 2, mt: 1 }} spacing={1}>
      <Stack spacing={2} sx={{ pt: 1 }} direction="row">
        <UserIcon userRole={user.role} color="secondary" />
        <TypographyWithEllipsisTooltip fontWeight={palette.fontWeight.bold}>
          {getUserName({ firstName: user.firstName, lastName: user.lastName })}
        </TypographyWithEllipsisTooltip>
      </Stack>
      <Stack spacing={2} sx={{ pb: 1 }} direction="row">
        <EmailOutlined color="secondary" />
        <TypographyWithEllipsisTooltip>{user.email}</TypographyWithEllipsisTooltip>
      </Stack>
    </Stack>
  );
}
