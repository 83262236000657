import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const MoreDetailsButton = ({ isOpen, onClick }) => (
  <Button color="primary" endIcon={isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />} onClick={onClick}>
    <FormattedMessage {...messages.paymentsTableDetail} />
  </Button>
);

MoreDetailsButton.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default MoreDetailsButton;
