import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { SITE_TYPE } from '../../config/sites';
import { LICENSE } from '../../config/subscription';
import { selectors as siteSelectors } from '../../modules/sites';
import actions, { selectors } from '../../modules/subscription';
import Modal from '../Modal';
import messages from './messages';
import PricesInfo from './PricesInfo';
import { styles } from './styles';

const PricingModal = ({
  apartmentDeviceLimit,
  combinedMultiplier,
  currency,
  isOpenPricingModal,
  onClosePricingModal,
  onLoadPrices,
  prices,
  siteType,
}) => {
  useEffect(() => {
    if (isOpenPricingModal) {
      if (siteType === SITE_TYPE.MDU) {
        onLoadPrices(LICENSE.APARTMENT_MOBILE_VIDEO_MONTH, LICENSE.APARTMENT_MOBILE_VIDEO_YEAR);
      } else {
        onLoadPrices(LICENSE.MOBILE_VIDEO_MONTH, LICENSE.MOBILE_VIDEO_YEAR);
      }
    }
  }, [isOpenPricingModal, siteType]);

  return (
    <Modal
      actions={
        <Button color="primary" onClick={onClosePricingModal}>
          <FormattedMessage {...messages.pricingModalClose} />
        </Button>
      }
      fullWidth
      maxWidth="sm"
      name="pricing-modal"
      onClose={onClosePricingModal}
      open={isOpenPricingModal}
      title={
        <FormattedMessage
          values={{
            br: <br />,
            sup: (chunks) => <sup>{chunks}</sup>,
          }}
          {...messages.pricingModalPricingTitle}
        />
      }
    >
      <Box sx={styles.root}>
        {prices ? (
          <PricesInfo
            apartmentDeviceLimit={apartmentDeviceLimit}
            combinedMultiplier={combinedMultiplier}
            currency={currency}
            prices={prices}
            siteType={siteType}
          />
        ) : (
          <Box width="100%">
            <Box display="flex" justifyContent="center">
              <CircularProgress />
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

PricingModal.propTypes = {
  apartmentDeviceLimit: PropTypes.number.isRequired,
  combinedMultiplier: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  isOpenPricingModal: PropTypes.bool.isRequired,
  onClosePricingModal: PropTypes.func.isRequired,
  onLoadPrices: PropTypes.func.isRequired,
  prices: PropTypes.shape({
    [LICENSE.MOBILE_VIDEO_MONTH]: PropTypes.shape({
      chargeVat: PropTypes.bool,
      unitFullPrice: PropTypes.number,
      unitFullPriceVat: PropTypes.number,
    }),
    [LICENSE.MOBILE_VIDEO_YEAR]: PropTypes.shape({
      chargeVat: PropTypes.bool,
      unitFullPrice: PropTypes.number,
      unitFullPriceVat: PropTypes.number,
    }),
    [LICENSE.APARTMENT_MOBILE_VIDEO_MONTH]: PropTypes.shape({
      chargeVat: PropTypes.bool,
      unitFullPrice: PropTypes.number,
      unitFullPriceVat: PropTypes.number,
    }),
    [LICENSE.APARTMENT_MOBILE_VIDEO_YEAR]: PropTypes.shape({
      chargeVat: PropTypes.bool,
      unitFullPrice: PropTypes.number,
      unitFullPriceVat: PropTypes.number,
    }),
  }),
  siteType: PropTypes.string.isRequired,
};

PricingModal.defaultProps = {
  prices: null,
};

const mapStateToProps = (state) => ({
  apartmentDeviceLimit: siteSelectors.getApartmentDeviceLimit(state),
  combinedMultiplier: siteSelectors.getCombinedMultiplier(state),
  prices: selectors.getMobileVideoPurchasePrices(state),
  siteType: siteSelectors.getCurrentSiteType(state),
});

const mapDispatchToProps = {
  onLoadPrices: actions.getPrices,
};

export default connect(mapStateToProps, mapDispatchToProps)(PricingModal);
