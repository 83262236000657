import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import http from '../../api';
import { getNewPasswordValidationSchema } from '../../validations/newPasswordValidationSchema';
import {
  getBasicEmailValidation,
  getFirstNameValidation,
  getLastNameValidation,
} from '../../validations/user/userCommonValidations';
import messages from './messages';

export interface ICacheValidationEmail {
  email?: string;
  isValid: boolean;
}

export function useGetValidationSchema(
  cacheValidationEmail: ICacheValidationEmail,
  setCacheValidationEmail: (cacheValidationEmail: ICacheValidationEmail) => void
) {
  const { formatMessage } = useIntl();

  return Yup.object().shape({
    agree: Yup.boolean()
      .oneOf([true], formatMessage(messages.registerAgreeRequired))
      .required(formatMessage(messages.registerAgreeRequired)),
    captcha: Yup.string().required(formatMessage(messages.registerRequired)),
    companyName: Yup.string().trim().required(formatMessage(messages.registerRequired)),
    email: getBasicEmailValidation(
      formatMessage(messages.registerEmailInvalid),
      formatMessage(messages.registerRequired)
    ).test({
      message: formatMessage(messages.registerEmailUsed),
      name: 'isExistEmail',
      test: async (email) => {
        try {
          if (!email || email.length === 0) {
            return true;
          }
          if (cacheValidationEmail.email === email) {
            return cacheValidationEmail.isValid;
          }
          const response = await http.head('/users', { params: { email } });
          const isValid = response.status === 404;
          setCacheValidationEmail({
            email,
            isValid,
          });
          return isValid;
        } catch (error) {
          return true;
        }
      },
    }),
    firstName: getFirstNameValidation(true, formatMessage),
    lastName: getLastNameValidation(formatMessage),
    ...getNewPasswordValidationSchema(formatMessage),
    newsletters: Yup.boolean().required(),
    referrer: Yup.string(),
  });
}
