import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import RemoveBatchButton from '../../Common/RemoveBatchButton';
import { PROMO_TYPES } from '../../consts';
import promoCodeMessages from '../../messages';

const Header = ({ isRemoveBatchDisabled, itemSubscriptionType, onRemoveBatch }) => (
  <Grid
    alignItems="center"
    container
    item
    justifyContent="space-between"
    sx={{
      pb: {
        sm: 0.5,
        xs: 0,
      },
    }}
    wrap="nowrap"
  >
    <Grid item>
      <Typography fontWeight="bold">
        <FormattedMessage {...promoCodeProductMap[itemSubscriptionType]} />
      </Typography>
    </Grid>
    <Box display={{ xl: 'none', xs: 'block' }}>
      <Grid item sx={{ mr: -1 }}>
        <RemoveBatchButton disabled={isRemoveBatchDisabled} onClick={onRemoveBatch} />
      </Grid>
    </Box>
  </Grid>
);

Header.propTypes = {
  isRemoveBatchDisabled: PropTypes.bool.isRequired,
  itemSubscriptionType: PropTypes.oneOf(Object.keys(PROMO_TYPES)).isRequired,
  onRemoveBatch: PropTypes.func.isRequired,
};

export default Header;

const promoCodeProductMap = {
  MOBILE_VIDEO_POOL_CREDIT_YEAR_APARTMENT: promoCodeMessages.promoCodeProductMobileVideoPoolCreditYearApartment,
  MOBILE_VIDEO_POOL_CREDIT_YEAR_STANDARD: promoCodeMessages.promoCodeProductMobileVideoPoolCreditYearStandard,
};
