import { defineMessages } from 'react-intl';

export default defineMessages({
  userRoleNameAdmin: {
    defaultMessage: 'Admin',
    id: 'AA.userList.role.admin',
  },
  userRoleNameApartmentAdmin: {
    defaultMessage: 'Apartment admin',
    description: 'User role name - apartment admin',
    id: 'AA.userList.role.apartmentAdmin',
  },
  userRoleNameApartmentLoginLessPIN: {
    defaultMessage: 'PIN',
    id: 'AA.userList.role.PIN',
  },
  userRoleNameApartmentLoginLessRFID: {
    defaultMessage: 'RFID Card',
    id: 'AA.userList.role.RFID',
  },
  userRoleNameApartmentUser: {
    defaultMessage: 'User',
    id: 'AA.userList.role.user',
  },
  userRoleNameCompanyAdmin: {
    defaultMessage: 'Company admin',
    description: 'User role name - company admin',
    id: 'userRoleNameCompanyAdmin',
  },
  userRoleNameGuest: {
    defaultMessage: 'Visitor',
    description: 'User role name - Guest',
    id: 'AA.userList.createGuest.xs',
  },
  userRoleNameLoginLess: {
    defaultMessage: 'User without login',
    description: 'User role name - user without login',
    id: 'userRoleNameLoginLess',
  },
  userRoleNameSiteAdmin: {
    defaultMessage: 'Site admin',
    description: 'User role name - site admin',
    id: 'userRoleNameSiteAdmin',
  },
  userRoleNameSiteAdminPayment: {
    defaultMessage: 'Site admin (payments permission)',
    description: 'User role name - site admin with payments',
    id: 'userRoleNameSiteAdminPayment',
  },
  userRoleNameSiteUser: {
    defaultMessage: 'User',
    description: 'User role name - site user',
    id: 'userRoleNameSiteUser',
  },
});
