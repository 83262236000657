import { IMPORT_USERS_FAILED_ROW_RESULT } from '../../../config/consts';

function getImportStats(importResult) {
  if (!importResult) {
    return null;
  }

  const { total } = importResult;

  const imported = importResult.processed;

  const failed = importResult.failedRows?.filter(
    (row) => [IMPORT_USERS_FAILED_ROW_RESULT.OK, IMPORT_USERS_FAILED_ROW_RESULT.ABORTED].indexOf(row.result) === -1
  ).length;

  const aborted = importResult.failedRows?.filter(
    (row) => row.result === IMPORT_USERS_FAILED_ROW_RESULT.ABORTED
  ).length;

  const abortedAtRow = () => {
    const firstAborted = importResult.failedRows?.find((row) => row.result === IMPORT_USERS_FAILED_ROW_RESULT.ABORTED);
    if (firstAborted) {
      return firstAborted.rowNumber;
    }

    return null;
  };

  return {
    aborted,
    abortedAtRow: abortedAtRow(),
    failed,
    imported,
    total,
  };
}

export default getImportStats;
