import CancelIcon from '@mui/icons-material/Cancel';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import ListItemMD from '@mui/material/ListItem';
import TextField from '@mui/material/TextField';
import React from 'react';
import { CallSettingOptionLabel } from '../../DeviceDetail/CallSettings/Intercom/CallSettingOptionLabel';
import { IButtonRenderData } from '../../DeviceDetail/CallSettings/Intercom/hooks/useGetStateData';
import { AddCallDestination } from './AddCallDestination';
import { canAddCallDestination } from './helpers/helpers';

const styles = {
  chip: {
    mx: 0.5,
    my: 0.25,
  },
  root: {
    '&:first-of-type': {
      pt: 0,
    },
    borderColor: 'grey.300',
    px: 0,
    py: 0.5,
  },
  textField: {
    mr: 2,
  },
};

export interface IListItemData {
  addedDeviceId: number;
  buttonData: IButtonRenderData;
  onChange: (value: string) => void;
  onClear: (optionKey: number) => void;
  onAdd: (addDevice: boolean) => void;
  ringingGroupSizeLimit: number;
  hasSelectedApartment: boolean;
}

const ListItem = (data: IListItemData) => {
  const devices = (
    <Box>
      {data.buttonData.selectedOptions?.map((option) => (
        <Chip
          color={option.hasUpdated ? 'primary' : 'default'}
          deleteIcon={<CancelIcon />}
          key={option.optionKey}
          label={CallSettingOptionLabel({
            numberColor: option.hasUpdated ? 'primary.contrastText' : 'primary.dark',
            option,
          })}
          onDelete={option.hasUpdated ? () => data.onClear(option.optionKey) : undefined}
          sx={styles.chip}
          variant={option.hasUpdated ? 'filled' : 'outlined'}
        />
      ))}
    </Box>
  );

  return (
    <>
      <ListItemMD component={'div'} sx={styles.root}>
        <Grid container spacing={2}>
          <Grid item xs={1}>
            <Box fontWeight="bold" pt={1} textAlign="center">
              {data.buttonData.index + 1}
            </Box>
          </Grid>
          <Grid item sm={5} xs={9}>
            <TextField
              name="name"
              onChange={({ target }) => data.onChange(target.value)}
              placeholder={`Button ${data.buttonData.index + 1}`}
              size="small"
              sx={styles.textField}
              value={data.buttonData.buttonName}
              variant="outlined"
            />
          </Grid>
          <Box component={Grid} item sx={{ display: { sm: 'block', xs: 'none' } }} xs={5}>
            {devices}
          </Box>
          <Grid item sm={1} textAlign="right" xs={2}>
            <AddCallDestination
              canAddApartment={
                data.hasSelectedApartment &&
                (data.buttonData.selectedOptions === undefined || data.buttonData.selectedOptions.length === 0)
              }
              disabled={
                !canAddCallDestination(data.addedDeviceId, data.ringingGroupSizeLimit, data.buttonData.selectedOptions)
              }
              onAdd={data.onAdd}
            />
          </Grid>
        </Grid>
      </ListItemMD>
      <Box sx={{ display: { sm: 'none', xs: 'block' } }}>
        <ListItemMD component={'div'} sx={styles.root}>
          <Grid alignItems="center" container spacing={2}>
            <Grid item xs={1} />
            <Grid item xs={9}>
              {devices}
            </Grid>
          </Grid>
        </ListItemMD>
      </Box>
    </>
  );
};

export default ListItem;
