import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MOBILE_VIDEO_STATUS } from '../../config/consts';
import { getCountMobileVideoDevices } from '../../helpers/dashboard';
import { getIsValidUntil, getTerminationTime, TIME_UNIT } from '../../helpers/mobileVideo';
import DevicesInfoChip from '../DevicesInfoChip';
import messages from './messages';

const MobileVideoChip = ({ devices, licenceModel, mvService }) => {
  const getChipByStatus = () => {
    if (
      licenceModel?.status === MOBILE_VIDEO_STATUS.TRIAL_EXPIRED ||
      licenceModel?.status === MOBILE_VIDEO_STATUS.STANDARD_EXPIRED ||
      licenceModel?.status === MOBILE_VIDEO_STATUS.EXPIRED ||
      licenceModel?.status === MOBILE_VIDEO_STATUS.DISABLED
    ) {
      return <DevicesInfoChip error label={<FormattedMessage {...messages.mobileVideoCardServiceNotWork} />} />;
    }
    if (licenceModel?.status === MOBILE_VIDEO_STATUS.TEMPORARY && licenceModel?.requirePaymentCount !== 0) {
      return (
        <DevicesInfoChip
          label={
            <FormattedMessage
              values={{ hours: getTerminationTime(licenceModel?.validityTo, TIME_UNIT.HOURS) }}
              {...messages.mobileVideoCardServiceWillBeTerminated}
            />
          }
          variant="outlined"
          warning
        />
      );
    }
    return null;
  };

  const getChipInaccesible = () => {
    if (
      licenceModel?.unlicensedCount > 0 &&
      licenceModel?.requirePaymentCount > 0 &&
      licenceModel?.unlicensedCount === licenceModel?.requirePaymentCount
    ) {
      return (
        <Tooltip
          placement="bottom-start"
          sx={{
            '&.MuiTooltip-tooltipPlacementBottom': {
              mt: 0,
            },
          }}
          title={
            <FormattedMessage
              values={{ count: licenceModel?.unlicensedCount }}
              {...messages.mobileVideoCardNeedSubscription}
            />
          }
        >
          <div>
            <DevicesInfoChip
              error
              label={
                <FormattedMessage
                  values={{ count: licenceModel?.unlicensedCount }}
                  {...messages.mobileVideoCardServiceInaccessible}
                />
              }
              variant="outlined"
            />
          </div>
        </Tooltip>
      );
    }
    return null;
  };

  const getChipErrorDevices = () => {
    const counts = getCountMobileVideoDevices(devices);
    if (counts.countError > 0) {
      if (counts.countError === 1) {
        return (
          <DevicesInfoChip
            error
            label={<FormattedMessage {...messages.mobileVideoCardServiceNotWorkOnDevice} />}
            variant="outlined"
          />
        );
      }
      return (
        <DevicesInfoChip
          error
          label={
            <FormattedMessage
              values={{
                count: counts.countError,
              }}
              {...messages.mobileVideoCardServiceNotWorkOnDevices}
            />
          }
          variant="outlined"
        />
      );
    }
    if (counts.count === 0) {
      return (
        <DevicesInfoChip error label={<FormattedMessage {...messages.mobileVideoCardNoDevices} />} variant="outlined" />
      );
    }
    return null;
  };

  const getChipError = () => getChipInaccesible() || getChipErrorDevices();

  // eslint-disable-next-line max-statements
  const getChipWarning = () => {
    if (
      licenceModel?.requirePaymentCount !== 0 &&
      getIsValidUntil(licenceModel?.status, licenceModel, mvService?.subscribed)
    ) {
      let time = getTerminationTime(licenceModel?.validityTo, TIME_UNIT.DAYS);
      let text = '';
      if (time === 1) {
        text = messages.mobileVideoCardDayLeft;
      } else if (time === 0) {
        time = getTerminationTime(licenceModel?.validityTo, TIME_UNIT.HOURS);
        if (time === 1) {
          text = messages.mobileVideoCardHourLeft;
        } else {
          text = messages.mobileVideoCardHoursLeft;
        }
      } else {
        text = messages.mobileVideoCardOnlyDaysLeft;
      }
      return <DevicesInfoChip label={<FormattedMessage values={{ time }} {...text} />} variant="outlined" warning />;
    }
    return null;
  };

  const getChipSuccess = () => {
    const counts = getCountMobileVideoDevices(devices);
    if (counts.countError === 0 && counts.count !== 0) {
      return (
        <DevicesInfoChip
          label={<FormattedMessage values={{ count: counts.count }} {...messages.mobileVideoCardServiceWorksOn} />}
          success
        />
      );
    }
    return null;
  };

  return (
    licenceModel?.status !== MOBILE_VIDEO_STATUS.INITIAL &&
    (getChipByStatus() || getChipError() || getChipWarning() || getChipSuccess())
  );
};

MobileVideoChip.propTypes = {
  devices: PropTypes.arrayOf(PropTypes.object).isRequired,
  licenceModel: PropTypes.shape({
    additional: PropTypes.number,
    licencesPool: PropTypes.number,
    status: PropTypes.string,
    unlicensedCount: PropTypes.number,
    validityTo: PropTypes.string,
  }).isRequired,
  mvService: PropTypes.shape({
    subscribed: PropTypes.bool,
  }).isRequired,
};

export default MobileVideoChip;
