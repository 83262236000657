import { defineMessages } from 'react-intl';

export default defineMessages({
  tabPanelAccess: {
    defaultMessage: 'Access',
    description: 'Access label',
    id: 'tabPanel.access',
  },
  tabPanelDevices: {
    defaultMessage: 'Devices',
    description: 'Devices label',
    id: 'tabPanel.devices',
  },
  tabPanelUserDetail: {
    defaultMessage: 'User detail',
    description: 'User detail label',
    id: 'tabPanel.userDetail',
  },
  tabPanelUserDetailXs: {
    defaultMessage: 'Detail',
    description: 'User detail label for small screens',
    id: 'tabPanel.userDetail.xs',
  },
});
