import { defineMessages } from 'react-intl';

export default defineMessages({
  deactivateMy2NAccessModalCancel: {
    defaultMessage: 'Cancel',
    id: 'deactivate.My2NAccess.Modal.cancel',
  },
  deactivateMy2NAccessModalInput: {
    defaultMessage: 'deactivate',
    id: 'deactivate.My2NAccess.Modal.input',
  },
  deactivateMy2NAccessModalInputErr: {
    defaultMessage: 'Your input does not match',
    id: 'deactivate.My2NAccess.Modal.input.err',
  },
  deactivateMy2NAccessModalSubmit: {
    defaultMessage: 'Deactivate',
    id: 'deactivate.My2NAccess.Modal.submit',
  },
  deactivateMy2NAccessModalText1: {
    defaultMessage:
      'After deactivating My2N app access, users will no longer be able to open doors using the app. This applies to all users.',
    id: 'deactivate.My2NAccess.Modal.text1',
  },
  deactivateMy2NAccessModalText2: {
    defaultMessage: 'Type “{theWord}” to confirm.',
    id: 'deactivate.My2NAccess.Modal.text2',
  },
  deactivateMy2NAccessModalTitle: {
    defaultMessage: 'Deactivate My2N app access management',
    id: 'deactivate.My2NAccess.Modal.title',
  },
  deactivateMy2NAccessModalWarning: {
    defaultMessage: 'Credentials already created in My2N will be deleted.',
    id: 'deactivate.My2NAccess.Modal.warning',
  },
});
