import moment from 'moment';

export const getCountCodesByName = (stats, name) => (stats && stats[name] ? stats[name] : 0);

export const getCountCodes = (stats) =>
  [
    getCountCodesByName(stats, 'CREATED'),
    getCountCodesByName(stats, 'USED'),
    getCountCodesByName(stats, 'CANCELLED'),
    getCountCodesByName(stats, 'EXPIRED'),
    getCountCodesByName(stats, 'LOCKED'),
  ].reduce((a, b) => a + b, 0);

export const dateDiff = (start, end) => {
  const startDate = moment(new Date(start).toISOString());
  const endDate = moment(new Date(end).toISOString());
  return endDate.diff(startDate, 'years');
};

export const isBatchClosed = (stats) => {
  const created = getCountCodesByName(stats, 'CREATED');
  const used = getCountCodesByName(stats, 'USED');
  const cancelled = getCountCodesByName(stats, 'CANCELLED');
  return created === 0 && (used > 0 || cancelled > 0);
};
