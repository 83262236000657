import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';
import React, { useRef, useState } from 'react';

interface IRowMenu {
  menuItems: React.ReactNode;
}

function RowMenu({ menuItems }: IRowMenu) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  function handleToggle(event: React.MouseEvent) {
    setOpen(!open);
    event.stopPropagation();
  }

  function handleClose(event: React.MouseEvent) {
    if (anchorRef.current && (anchorRef.current as HTMLButtonElement).contains(event.target as HTMLElement)) {
      return;
    }
    setOpen(false);
  }

  return (
    <Box id="row-menu">
      <IconButton
        aria-controls="row-menu-list-grow"
        onClick={handleToggle}
        ref={anchorRef}
        sx={{ color: 'secondary.main' }}
      >
        <MoreHorizIcon />
      </IconButton>
      <Menu anchorEl={anchorRef.current} onClose={handleClose} open={Boolean(open)}>
        <MenuList
          disablePadding
          onClick={handleClose}
          sx={{
            '& .MuiListItemIcon-root': {
              color: 'secondary.main',
              mx: 1,
            },
            '& .MuiMenuItem-root': {
              height: 48,
              p: 2,
            },
          }}
        >
          {menuItems}
        </MenuList>
      </Menu>
    </Box>
  );
}

export default RowMenu;
