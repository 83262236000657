import LogIcon from '@mui/icons-material/TransferWithinAStation';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useUrlParams } from '../../helpers/urlParams';
import actions, { selectors } from '../../modules/accessSettings';
import RowSection from '../RowSection';
import messages from './messages';

function AccessLogSwitch() {
  const { formatMessage } = useIntl();
  const { deviceId } = useUrlParams();
  const dispatch = useDispatch();
  const isLogActive = useSelector(selectors.getIsAccessLogActive) || false;
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (isLogActive !== isChecked) {
      setIsChecked(isLogActive);
    }
  }, [isLogActive]);

  useEffect(() => {
    dispatch(actions.getAccessControlSettings(deviceId));
  }, []);

  function setSwitchValue(value: boolean) {
    dispatch(actions.setAccessControlLogSettings(deviceId, { active: value }));
    setIsChecked(value);
  }

  return (
    <RowSection icon={<LogIcon />} label={formatMessage(messages.accessLogSwitchSectionLabel)}>
      <Stack alignItems="flex-start" direction="row" mt={-1}>
        <FormControlLabel
          control={<Switch checked={isChecked} onChange={(event) => setSwitchValue(event.target.checked)} />}
          label={
            <Stack direction="column">
              <Typography variant="body1">
                <FormattedMessage
                  {...(isChecked ? messages.accessLogSwitchStateOn : messages.accessLogSwitchStateOff)}
                />
              </Typography>
              <Typography color="secondary" variant="caption">
                <FormattedMessage {...messages.accessLogSwitchInfo} />
              </Typography>
            </Stack>
          }
          sx={{
            alignItems: 'baseline',
          }}
        />
      </Stack>
    </RowSection>
  );
}

export default AccessLogSwitch;
