import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert } from '../../../../components';
import messages from './messages';

const VerificationErrorAlert = () => (
  <Alert severity="error" title={<FormattedMessage {...messages.codeVerificationWarningNotFoundHeader} />}>
    <FormattedMessage {...messages.codeVerificationWarningNotFoundText} />
  </Alert>
);

export default VerificationErrorAlert;
