import { defineMessages } from 'react-intl';

export default defineMessages({
  elevatorIntegrationModalDeactivateCancel: {
    defaultMessage: 'Cancel',
    id: 'elevatorIntegration.modalDeactivate.cancel',
  },
  elevatorIntegrationModalDeactivateSubmit: {
    defaultMessage: 'Deactivate',
    id: 'elevatorIntegration.modalDeactivate.submit',
  },
  elevatorIntegrationModalDeactivateText1: {
    defaultMessage:
      '<p>Deactivation will occur instantly and you can reactivate any time.</p>' +
      '<p>Configuration remains unchanged after deactivation and can be modified.</p>',
    id: 'elevatorIntegration.modalDeactivate.text1',
  },
  elevatorIntegrationModalDeactivateTitle: {
    defaultMessage: 'Deactivate integration',
    id: 'elevatorIntegration.modalDeactivate.title',
  },
});
