import * as yup from 'yup';

export function getValidationSchema() {
  return yup.object().shape({
    device: yup
      .object()
      .nullable()
      .when('hasForm', {
        is: false,
        then: (schema) =>
          schema.nullable().shape({
            deviceId: yup.string().required(),
            deviceName: yup.string().required(),
          }),
      }),
  });
}
