import { defineMessages } from 'react-intl';

export default defineMessages({
  deviceDataGridActionDeviceRowMenuActivateTrial: {
    defaultMessage: 'Activate trial',
    description: 'Device Row Menu - activate trial button',
    id: 'deviceRowMenu.activateTrial',
  },
  deviceDataGridActionDeviceRowMenuDeleteDevice: {
    defaultMessage: 'Delete device',
    description: 'Device Row Menu - delete device',
    id: 'deviceRowMenu.deleteDevice',
  },
  deviceDataGridActionDeviceRowMenuEditDevice: {
    defaultMessage: 'Edit device',
    description: 'Device Row Menu - edit device button',
    id: 'deviceRowMenu.editDevice',
  },
  deviceDataGridActionDeviceRowMenuOpenConfiguration: {
    defaultMessage: 'Open configuration',
    description: 'Device Row Menu - open configuration button',
    id: 'deviceRowMenu.openConfiguration',
  },
  deviceDataGridActionDeviceRowMenuPurchase: {
    defaultMessage: 'Purchase subscription',
    description: 'Device Row Menu - purchase button',
    id: 'deviceRowMenu.purchase',
  },
  deviceDataGridActionDeviceRowMenuReverify: {
    defaultMessage: 'Reverify device',
    description: 'Device Row Menu - reverify button',
    id: 'deviceRowMenu.reverify',
  },
  deviceDataGridColumnsActiveServices: {
    defaultMessage: 'Active services',
    description: 'Device Data Grid columns - active services',
    id: 'deviceDataGrid.columns.activeServices',
  },
  deviceDataGridColumnsApartment: {
    defaultMessage: 'Apartment',
    description: 'Device Data Grid columns - apartment',
    id: 'deviceDataGrid.columns.apartment',
  },
  deviceDataGridColumnsDeviceDataGridActionActivateTrial: {
    defaultMessage: 'Activate trial',
    description: 'Device data grid action - activate trial button',
    id: 'deviceDataGridAction.activateTrial',
  },
  deviceDataGridColumnsDeviceDataGridActionPurchase: {
    defaultMessage: 'Purchase',
    description: 'Device data grid action - purchase button',
    id: 'deviceDataGridAction.purchase',
  },
  deviceDataGridColumnsDeviceDataGridActionReverify: {
    defaultMessage: 'Verify',
    description: 'Device data grid action - reverify button',
    id: 'deviceDataGridAction.reverify',
  },
  deviceDataGridColumnsDeviceDataGridActionUseAvailableCredit: {
    defaultMessage: 'Use available credit',
    description: 'Device data grid action - use available credit button',
    id: 'deviceDataGridAction.useAvailableCredit',
  },
  deviceDataGridColumnsDeviceName: {
    defaultMessage: 'Device name',
    description: 'Device Data Grid columns - device name',
    id: 'deviceDataGrid.columns.deviceName',
  },
  deviceDataGridColumnsDeviceStatus: {
    defaultMessage: 'Device status',
    description: 'Device Data Grid columns - device status',
    id: 'deviceDataGrid.columns.deviceStatus',
  },
  deviceDataGridColumnsDeviceType: {
    defaultMessage: 'Type',
    description: 'Device Data Grid columns - device type',
    id: 'deviceDataGrid.columns.deviceType',
  },
  deviceDataGridColumnsIntegrations: {
    defaultMessage: 'Integrations',
    description: 'Device Data Grid columns - integrations',
    id: 'deviceDataGrid.columns.integrations',
  },
  deviceListChangePasswordButton: {
    defaultMessage: 'Change password',
    description: 'Device data grid action - change password',
    id: 'deviceList.changePassword.button',
  },
});
