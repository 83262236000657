import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { PATHS } from '../../config/consts';
import { ICompanyRoles } from '../../hooks/rolesHooks';
import { TId } from '../../modules/commonTypes';
import { getCurrentUserSelector } from '../../modules/user/selectors';
import { useAppContext } from '../App/AppContextProvider';
import { getRedirectPathForCurrentUser } from '../LoginPage/redirectPath';

export function FallbackPage(): React.JSX.Element {
  const { loggedIn } = useAppContext();
  const user = useSelector(getCurrentUserSelector) as {
    id: TId;
    lastVisitedSiteId?: number;
    lastVisitedCompanyId?: number;
    companies: ICompanyRoles[];
  };

  if (loggedIn) {
    return (
      <Navigate
        replace
        to={{
          pathname: getRedirectPathForCurrentUser({
            companies: user.companies,
          }),
        }}
      />
    );
  }

  return <Navigate replace to={{ pathname: PATHS.LOGIN }} />;
}
