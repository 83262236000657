import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import ElevatorIntegrationServiceSvg from '../../assets/service-elevatorIntegration.svg';
import ItemService from '../IconServices/ItemService';
import messages from './messages';

interface IIconServiceElevatorIntegration {
  elevatorIntegrationService?: {
    active: boolean;
  };
}

function IconServiceElevatorIntegration({ elevatorIntegrationService = undefined }: IIconServiceElevatorIntegration) {
  const { formatMessage } = useIntl();

  const getTitle = useCallback(() => {
    if (elevatorIntegrationService?.active === true) {
      return formatMessage(messages.iconServiceElevatorIntegrationActive);
    }

    if (elevatorIntegrationService?.active === false) {
      return formatMessage(messages.iconServiceElevatorIntegrationInActive);
    }

    return formatMessage(messages.iconServiceElevatorIntegrationNotAvailable);
  }, [elevatorIntegrationService]);

  return (
    <ItemService
      customTitle={getTitle()}
      active={!!elevatorIntegrationService?.active}
      image={ElevatorIntegrationServiceSvg}
      service="ELEVATOR_INTEGRATION"
    />
  );
}

export default IconServiceElevatorIntegration;
