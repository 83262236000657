import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { SuccessIconMessage } from '../../../components';
import { PATHS } from '../../../config/consts';
import { DEVICE_TYPE } from '../../../config/devices';
import { selectors as companySelectors } from '../../../modules/company';
import { selectors as siteSelectors } from '../../../modules/sites';
import messages from './messages';

const styles = {
  description: {
    borderTop: '1px solid',
    borderTopColor: 'text.secondary',
    marginTop: 3,
    paddingTop: 2,
    textAlign: 'left',
  },
  skipped: {
    marginTop: 2,
    textAlign: 'left',
  },
};

const SuccessMessage = ({ callSettingsSkipped, companyId, deviceModalType, siteId }) => {
  const SETTINGS_GUIDE_URL =
    'https://faq.2n.cz/display/FAQ/Axis+door+station+-' + '+How+to+connect+it+to+Mobile+Video+service';

  return (
    <>
      <SuccessIconMessage>
        <FormattedMessage {...messages.successMessageMessage} />
      </SuccessIconMessage>
      <Box sx={styles.description}>
        <Typography variant="body2">
          <FormattedMessage
            {...deviceTypeDescriptionMap[deviceModalType || 'X2N']}
            values={{
              linkSettingsGuide: (
                <Link href={SETTINGS_GUIDE_URL} rel="noopener noreferrer" target="_blank">
                  <FormattedMessage {...messages.successMessageSettingsGuide} />
                </Link>
              ),
            }}
          />
        </Typography>
      </Box>
      {callSettingsSkipped && (
        <Box sx={styles.skipped}>
          <Typography variant="body2">
            <FormattedMessage
              {...deviceTypeSkippedMap[deviceModalType || 'X2N']}
              values={{
                link: (
                  <Link
                    href={PATHS.CALL_SETTINGS_INTERCOMS.replace(':companyId', companyId).replace(':siteId', siteId)}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FormattedMessage {...messages.successMessageCallSettings} />
                  </Link>
                ),
              }}
            />
          </Typography>
        </Box>
      )}
    </>
  );
};

SuccessMessage.propTypes = {
  callSettingsSkipped: PropTypes.bool,
  companyId: PropTypes.number.isRequired,
  deviceModalType: PropTypes.oneOf(['X2N', DEVICE_TYPE.HIPMO, DEVICE_TYPE.DOOR_STATION, DEVICE_TYPE.THIRD_PARTY]),
  siteId: PropTypes.number.isRequired,
};

SuccessMessage.defaultProps = {
  callSettingsSkipped: false,
  deviceModalType: null,
};

const mapStateToProps = (state) => ({
  companyId: companySelectors.getCompanyId(state),
  siteId: siteSelectors.getSiteId(state),
});

export default connect(mapStateToProps, {})(SuccessMessage);

const deviceTypeDescriptionMap = {
  [DEVICE_TYPE.HIPMO]: messages.successMessageDescriptionHipmo,
  [DEVICE_TYPE.DOOR_STATION]: messages.successMessageDescriptionDoorStation,
  [DEVICE_TYPE.THIRD_PARTY]: messages.successMessageDescriptionThirdParty,
  X2N: messages.successMessageDescriptionX2N,
};

const deviceTypeSkippedMap = {
  [DEVICE_TYPE.HIPMO]: messages.successMessageCallSettingsSkippedHipmo,
  [DEVICE_TYPE.DOOR_STATION]: messages.successMessageCallSettingsSkippedDoorStation,
  [DEVICE_TYPE.THIRD_PARTY]: messages.successMessageCallSettingsSkippedThirdParty,
  X2N: messages.successMessageCallSettingsSkippedX2N,
};
