import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const ListHeaderLabel = ({ children }) => (
  <Box>
    <Typography variant="subtitle2">
      <Box color="secondary.main">{children}</Box>
    </Typography>
  </Box>
);

ListHeaderLabel.propTypes = {
  children: PropTypes.any.isRequired,
};

export default ListHeaderLabel;
