import { defineMessages } from 'react-intl';

export default defineMessages({
  destinationSelectPlaceholder: {
    defaultMessage: 'Select area',
    description: 'Placeholder of destination select',
    id: 'destinationSelectPlaceholder',
  },
  elevatorGroupSelectPlaceholder: {
    defaultMessage: 'Select elevator group',
    description: 'Placeholder of elevator group select',
    id: 'elevatorGroupSelectPlaceholder',
  },
});
