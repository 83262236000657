import { useMediaQuery, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import DataGridComponent from '../../../components/DataGrid';
import NameColumn from '../../../components/DataGrid/NameColumn';
import DeviceModelName from '../../../components/DeviceModelName';
import DeviceTypeIcon from '../../../components/DeviceTypeIcon';
import EllipsisText from '../../../components/EllipsisText';
import { useDataGrid } from '../../../helpers/dataGrid';
import messages from './messages';
import WaitingDevicesRowMenu from './WaitingDevicesRowMenu/WaitingDevicesRowMenu';

const DataGrid = ({
  companyId,
  data,
  didInvalid,
  filter,
  isFetching,
  onApprove,
  onLoadDevices,
  onReject,
  page,
  resultsFiltered,
  resultsTotal,
  rowsPerPage,
  siteId,
}) => {
  useDataGrid(
    onLoadDevices,
    {
      companyId,
      filter,
      page,
      rowsPerPage,
      siteId,
    },
    didInvalid
  );

  const { formatMessage } = useIntl();
  const theme = useTheme();
  const upSm = useMediaQuery(theme.breakpoints.up('sm'));

  const loadingWrapper = (children) => (isFetching ? <Skeleton animation="wave" height={25} width="80%" /> : children);

  const columns = useMemo(
    () => [
      {
        disableColumnMenu: true,
        field: 'deviceType',
        flex: 1,
        headerName: formatMessage(messages.waitingDevicesDataGridHeaderDeviceType),
        renderCell: (device) => {
          const deviceType = device?.value?.variant?.replace(/-/g, '.');
          return loadingWrapper(
            device?.value && (
              <Tooltip title={<DeviceModelName deviceType={deviceType} type={device.value?.type} />}>
                <Box component="span" sx={{ alignItems: 'center', display: 'flex' }}>
                  <NameColumn
                    iconComponent={
                      <DeviceTypeIcon deviceType={deviceType} showErrorBadge small type={device.value?.type} />
                    }
                    name={
                      <Box fontWeight="regular">
                        <DeviceModelName deviceType={deviceType} type={device.value?.type} />
                      </Box>
                    }
                  />
                </Box>
              </Tooltip>
            )
          );
        },
        sortable: false,
      },
      {
        disableColumnMenu: true,
        field: 'serialNumber',
        flex: 1,
        headerName: formatMessage(messages.waitingDevicesDataGridHeaderSerialNumber),
        renderCell: (device) =>
          loadingWrapper(device?.value && <EllipsisText>{device.value.serialNumber}</EllipsisText>),
        sortable: false,
      },
      {
        align: 'right',
        disableColumnMenu: true,
        field: 'actions',
        flex: 1,
        headerName: '',
        renderCell: (device) =>
          loadingWrapper(
            device?.value && (
              <>
                <Button color="primary" onClick={() => onApprove(device.value)} sx={{ mr: 1 }}>
                  <FormattedMessage {...messages.waitingDevicesDataGridActionApprove} />
                </Button>
                <Button color="error" onClick={() => onReject(device.value)}>
                  <FormattedMessage {...messages.waitingDevicesDataGridActionReject} />
                </Button>
              </>
            )
          ),
        sortable: false,
      },
      {
        disableColumnMenu: true,
        field: 'rowMenu',
        headerName: '',
        renderCell: (device) =>
          loadingWrapper(
            device?.value && (
              <WaitingDevicesRowMenu
                onApprove={() => onApprove(device.value)}
                onReject={() => onReject(device.value)}
              />
            )
          ),
        sortable: false,
        width: 65,
      },
    ],
    [upSm, isFetching]
  );

  const rows = useMemo(
    () =>
      data.map((device) => ({
        actions: device,
        deviceType: device,
        id: device.id,
        isClickable: false,
        rowMenu: device,
        serialNumber: device,
      })),
    [data]
  );

  return (
    !!data.length && (
      <Box sx={{ mb: 3 }}>
        <DataGridComponent
          columns={columns}
          columnVisibilityModel={{
            actions: upSm,
            rowMenu: !upSm,
          }}
          disableRowSelectionOnClick
          hideFooterPagination
          loading={isFetching}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onOrderBy={() => {}}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onRowsPerPageChange={() => {}}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onSelectPage={() => {}}
          order={'-'}
          page={page}
          resultsFiltered={resultsFiltered}
          resultsTotal={resultsTotal}
          rows={rows}
          rowsPerPage={rowsPerPage}
        />
      </Box>
    )
  );
};

DataGrid.propTypes = {
  companyId: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      serialNumber: PropTypes.string,
      type: PropTypes.string,
      variant: PropTypes.string,
    })
  ).isRequired,
  didInvalid: PropTypes.bool.isRequired,
  filter: PropTypes.shape({
    fulltext: PropTypes.string,
  }).isRequired,
  isFetching: PropTypes.bool.isRequired,
  onApprove: PropTypes.func.isRequired,
  onLoadDevices: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  resultsFiltered: PropTypes.number.isRequired,
  resultsTotal: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  siteId: PropTypes.number.isRequired,
};

export default DataGrid;
