import { SERVICE } from '../config/devices';
import http, { encode } from './index';

export const createAccessGroup = async (company, site, params) =>
  http.post(`/companies/${encode(company)}/sites/${encode(site)}/groups`, params);

export const updateAccessGroup = async (company, site, group, params) =>
  http.put(`/companies/${encode(company)}/sites/${encode(site)}/groups/${encode(group)}`, params);

export const deleteAccessGroup = async (company, site, group) =>
  http.delete(`/companies/${encode(company)}/sites/${encode(site)}/groups/${encode(group)}`);

export const getAccessGroup = async (company, site, group) =>
  http.get(`/companies/${encode(company)}/sites/${encode(site)}/groups/${encode(group)}`);

export const getAccessControlDevices = async (company, site) =>
  http.get(
    `/companies/${encode(company)}/sites/${encode(site)}` + `/services/${SERVICE.ACCESS_CONTROL}/configurations`
  );

export const getAccessGroupDevices = async (company, site, group, params) =>
  http.get(`/companies/${encode(company)}/sites/${encode(site)}/groups/${encode(group)}/devices`, {
    params,
  });

export const removeAccessGroupDevice = async (company, site, group, device) =>
  http.delete(
    `/companies/${encode(company)}/sites/${encode(site)}` + `/groups/${encode(group)}/devices/${encode(device)}`
  );

export const removeAccessGroupUser = async (company, site, group, user) =>
  http.delete(`/companies/${encode(company)}/sites/${encode(site)}` + `/groups/${encode(group)}/users/${encode(user)}`);

export const getAccessGroupsList = async (company, site, params) =>
  http.get(`/companies/${encode(company)}/sites/${encode(site)}/groups`, { params });

export const addAccessGroupDevice = async (company, site, group, device) =>
  http.put(
    `/companies/${encode(company)}/sites/${encode(site)}` + `/groups/${encode(group)}/devices/${encode(device)}`
  );

export const addAccessGroupUser = async (company, site, group, user) =>
  http.put(`/companies/${encode(company)}/sites/${encode(site)}` + `/groups/${encode(group)}/users/${encode(user)}`);

export const addAccessGroupUsers = async (company, site, group, usersUUId) =>
  http.put(`/companies/${encode(company)}/sites/${encode(site)}` + `/groups/${encode(group)}/users`, usersUUId);

export const getApartmentAccessGroupsList = async (company, site, apartment) =>
  http.get(`/companies/${encode(company)}/sites/${encode(site)}/apartments/${encode(apartment)}/groups`);

export const addApartmentAccessGroups = async (company, site, apartment, groups) =>
  http.put(`/companies/${encode(company)}/sites/${encode(site)}/apartments/${encode(apartment)}/groups`, groups);

export const getUserApartmentsAccessGroupsList = async (company, site, user) =>
  http.get(`/companies/${encode(company)}/sites/${encode(site)}/users/${encode(user)}/apartments`);

export const getUserAccessGroupsList = async (company, site, user) =>
  http.get(`/companies/${encode(company)}/sites/${encode(site)}/users/${encode(user)}/groups`);

export const addUserAccessGroups = async (company, site, user, groups) =>
  http.put(`/companies/${encode(company)}/sites/${encode(site)}/users/${encode(user)}/groups`, groups);

export const getDeviceAccessGroupsList = async (company, site, device) =>
  http.get(`/companies/${encode(company)}/sites/${encode(site)}/devices/${encode(device)}/groups`);

export const addDeviceAccessGroups = async (company, site, device, groups) =>
  http.put(`/companies/${encode(company)}/sites/${encode(site)}/devices/${encode(device)}/groups`, groups);

export const getAccessControlSettings = async (company, site, device) =>
  http.get(
    `/companies/${encode(company)}/sites/${encode(site)}` +
      `/devices/${encode(device)}/services/${SERVICE.ACCESS_CONTROL}`
  );

export const setAccessControlSettings = async (company, site, device, data) =>
  http.put(
    `/companies/${encode(company)}/sites/${encode(site)}` +
      `/devices/${encode(device)}/services/${SERVICE.ACCESS_CONTROL}`,
    data
  );

export const setAccessControlLogSettings = async (company, site, device, data) =>
  http.put(
    `/companies/${encode(company)}/sites/${encode(site)}` +
      `/devices/${encode(device)}/services/${SERVICE.ACCESS_CONTROL}/accesslog`,
    data
  );

export const addAccessGroupApartments = async (company, site, group, apartments) =>
  http.put(`/companies/${encode(company)}/sites/${encode(site)}/groups/${encode(group)}/apartments`, apartments);

export const removeAccessGroupApartment = async (company, site, group, apartment) =>
  http.delete(
    `/companies/${encode(company)}/sites/${encode(site)}` + `/groups/${encode(group)}/apartments/${encode(apartment)}`
  );

export const addApartmentsAccessGroups = async (company, site, apartment, groups) =>
  http.put(`/companies/${encode(company)}/sites/${encode(site)}/apartments/${encode(apartment)}/groups`, groups);

export const getAccessGroupApartments = async (company, site, group, params) =>
  http.get(`/companies/${encode(company)}/sites/${encode(site)}/groups/${encode(group)}/apartments`, { params });
