export const styles = {
  actionStyle: {
    color: 'common.white',
    display: {
      sm: 'flex',
      xs: 'none',
    },
    flex: 'none',
    flexGrow: 0,
    marginTop: 0.45,
    order: 1,
    zIndex: 'drawer',
  },
  iconStyle: {
    alignItems: 'center',
    color: 'common.white',
    display: 'flex',
    flex: 'none',
    flexGrow: 0,
    height: 24,
    marginRight: 1,
    marginTop: 1.1,
    order: 0,
    textAlign: 'center',
    width: 24,
    zIndex: 'drawer',
  },
  messageLink: {
    color: 'common.white',
    textDecoration: {
      sm: 'none',
    },
  },
  messageStyle: {
    alignSelf: 'stretch',
    color: 'common.white',
    flex: 'none',
    flexGrow: 0,
    marginTop: 1.1,
    order: 1,
    width: {
      md: 'auto',
      sm: 'calc(100% - 180px)',
      xs: 'calc(100% - 50px)',
    },
    zIndex: 1202,
  },
  notificationBar: (theme) => ({
    bgcolor: 'warning.main',
    height: {
      md: theme.appNotificationHeightLargeDevice,
      xs: theme.appNotificationHeightSmallDevice,
    },
  }),
  root: {
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 1202,
  },
};
