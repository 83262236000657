import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import RowMenuItem from '../../../components/DataGrid/RowMenuItem';
import { PATHS } from '../../../config/consts';
import messages from '../messages';

const DeviceRowMenu = ({ companyId, deviceId, onOpenDeleteDeviceModal, siteId }) => (
  <Box>
    <RowMenuItem
      component={Link}
      icon={<EditIcon />}
      label={<FormattedMessage {...messages.apartmentDevicesRowMenuEditDevice} />}
      to={PATHS.DEVICE_DETAIL.replace(':companyId', companyId)
        .replace(':siteId', siteId)
        .replace(':deviceId', deviceId)}
    />
    <Divider />
    <RowMenuItem
      colorIcon="error.dark"
      colorText="error.dark"
      icon={<DeleteIcon />}
      label={<FormattedMessage {...messages.apartmentDevicesRowMenuDeleteDevice} />}
      onClick={() => onOpenDeleteDeviceModal(deviceId)}
    />
  </Box>
);

DeviceRowMenu.propTypes = {
  companyId: PropTypes.number.isRequired,
  deviceId: PropTypes.number.isRequired,
  onOpenDeleteDeviceModal: PropTypes.func.isRequired,
  siteId: PropTypes.number.isRequired,
};

export default DeviceRowMenu;
