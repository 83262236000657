import React from 'react';
import { useIntl } from 'react-intl';
import { My2NAppAccessDisabledIcon, My2NAppAccessIcon } from '../_Icons';
import RowMenuItem from '../DataGrid/RowMenuItem';
import messages from './messages';

interface ISetMy2NAppAccessMenuItem {
  disabled: boolean;
  isBleActive: boolean;
  onClick: () => void;
}

function SetMy2NAppAccessMenuItem({ disabled, isBleActive, onClick }: ISetMy2NAppAccessMenuItem) {
  const { formatMessage } = useIntl();

  return (
    <RowMenuItem
      disabled={disabled}
      icon={isBleActive ? <My2NAppAccessDisabledIcon /> : <My2NAppAccessIcon />}
      label={formatMessage(
        messages[isBleActive ? 'setMy2NAppAccessMenuItemDisableMy2NAccess' : 'setMy2NAppAccessMenuItemEnableMy2NAccess']
      )}
      onClick={onClick}
    />
  );
}

export default SetMy2NAppAccessMenuItem;
