import { defineMessages } from 'react-intl';

export default defineMessages({
  waitingDevicesDataGridActionApprove: {
    defaultMessage: 'Approve',
    description: 'Waiting devices data grid - approve button',
    id: 'waitingDevicesDataGrid.action.approve',
  },
  waitingDevicesDataGridActionReject: {
    defaultMessage: 'Reject',
    description: 'Waiting devices data grid - reject button',
    id: 'waitingDevicesDataGrid.action.reject',
  },
  waitingDevicesDataGridHeaderDeviceType: {
    defaultMessage: 'Device type',
    description: 'Waiting devices data grid header - deviceType',
    id: 'waitingDevicesDataGrid.header.deviceType',
  },
  waitingDevicesDataGridHeaderSerialNumber: {
    defaultMessage: 'Serial number',
    description: 'Waiting devices data grid header - serial number',
    id: 'waitingDevicesDataGrid.header.serialNumber',
  },
});
