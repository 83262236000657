import { useRef } from 'react';

const oneMinuteLimit = 60 * 1000;

export interface IUseTimestamp {
  isTimestampActive: () => boolean;
}

export function useTimestamp(): IUseTimestamp {
  const lastResendCodeTimestampRef = useRef<number | undefined>(undefined);

  const isTimestampActive = () => {
    const currentTimestamp = Date.now();

    if (!lastResendCodeTimestampRef.current) {
      lastResendCodeTimestampRef.current = currentTimestamp;
      return false;
    }

    const isTimestampActive = currentTimestamp - lastResendCodeTimestampRef.current < oneMinuteLimit;

    if (isTimestampActive) {
      return true;
    }

    lastResendCodeTimestampRef.current = currentTimestamp;

    return false;
  };

  return { isTimestampActive };
}
