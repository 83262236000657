import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { IUserElevatorIntegration } from '../../api/elevatorIntegrationTS';
import messages from './messages';

function ElevatorIntegrationSwitchSettings({
  currentSettings,
  onElevatorIntegrationSet,
}: {
  onElevatorIntegrationSet: (landingCall: boolean, destinationCall: boolean) => void;
  currentSettings: IUserElevatorIntegration | undefined;
}) {
  const { formatMessage } = useIntl();
  const [landingCall, setLandingCall] = useState<boolean>(false);
  const [destinationCall, setDestinationCall] = useState<boolean>(false);

  useEffect(() => {
    setLandingCall(!!currentSettings?.landingCallEnabled);
    setDestinationCall(!!currentSettings?.destinationCallEnabled);
  }, [currentSettings?.landingCallEnabled, currentSettings?.destinationCallEnabled]);

  const handleLandingCallChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const landingCall = event.target.checked;
    setLandingCall(landingCall);
    onElevatorIntegrationSet(landingCall, landingCall ? destinationCall : false);
  };

  const handleDestinationCallChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const destinationCall = event.target.checked;
    setDestinationCall(destinationCall);
    onElevatorIntegrationSet(landingCall, destinationCall);
  };

  return (
    <Stack alignItems="flex-start" direction={{ sm: 'row', xs: 'column' }} sx={{ mt: -1 }}>
      <FormControlLabel
        componentsProps={{
          typography: {
            variant: 'body1',
          },
        }}
        control={<Switch checked={landingCall} onChange={handleLandingCallChange} />}
        label={formatMessage(messages.elevatorIntegrationSettingsLandingCall)}
      />
      <FormControlLabel
        componentsProps={{
          typography: {
            variant: 'body1',
          },
        }}
        disabled={!currentSettings?.landingCallEnabled}
        control={<Switch checked={destinationCall} onChange={handleDestinationCallChange} />}
        label={formatMessage(messages.elevatorIntegrationSettingsDestinationCall)}
      />
    </Stack>
  );
}

export default ElevatorIntegrationSwitchSettings;
