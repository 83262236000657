import { orderBy, uniqBy } from 'lodash';
import { createSelector } from 'reselect';

export const getCreateGroupIsLoading = (state) => state.accessSettings.groupCreate.isFetching;

export const getAccessGroup = (state) => {
  const { data } = state.accessSettings.groupDetail;

  return {
    apartments: data?.apartments || 0,
    description: data?.description || '',
    devices: data?.devices || [],
    id: data?.id || '',
    name: data?.name || '',
    siteId: data?.siteId || '',
    users: data?.users || 0,
  };
};

export const getAccessGroupIsFetching = (state) => state.accessSettings.groupDetail.isFetching;

export const getIsDeleteAccessGroupFetching = (state) => state.accessSettings.groupDelete.isFetching;

export const getAccessGroupName = (state) => state.accessSettings.groupDetail?.data?.name || '';

export const getAccessGroupUsersCount = (state) => state.accessSettings.groupDetail?.data?.users || 0;

export const getIsAccessGroupUserRemoveFetching = (state) => state.accessSettings.groupDetail.users.remove.isFetching;

export const getAccessGroupId = (state) => state.accessSettings.groupDetail?.data?.id;

export const isAccessGroupInvalid = (state) => state.accessSettings.groupDetail.didInvalid;

const getGroupApartments = (state) => state.accessSettings.groupDetail.apartments;

export const getAccessGroupApartments = createSelector(getGroupApartments, (apartments) => apartments?.data || []);

export const isAccessGroupApartmentsFetching = createSelector(
  getGroupApartments,
  (apartments) => apartments.isFetching
);

export const isAccessGroupApartmentsInvalid = createSelector(getGroupApartments, (apartments) => apartments.didInvalid);

export const getAccessGroupDevices = (state) => state.accessSettings.groupDetail.devices?.data || [];

export const isAccessGroupDevicesFetching = (state) => state.accessSettings.groupDetail.devices.isFetching;

export const isAccessGroupDevicesInvalid = (state) => state.accessSettings.groupDetail.devices.didInvalid;

const getAccessGroupDetailUsers = (state) => state.accessSettings.groupDetail.users;

export const getAccessGroupUsers = createSelector(getAccessGroupDetailUsers, (users) => users?.data || []);

export const isAccessGroupUsersFetching = (state) => state.accessSettings.groupDetail.users.isFetching;

export const isAccessGroupUsersInvalid = (state) => state.accessSettings.groupDetail.users.didInvalid;

const getAccessSettingsGroupsList = (state) => state.accessSettings.groupsList;

export const getAccessGroupsListAll = createSelector(
  getAccessSettingsGroupsList,
  (groupsList) => groupsList?.listAll?.data || []
);

export const getAccessGroupsList = createSelector(getAccessSettingsGroupsList, (groupsList) => groupsList?.data || []);

export const isAccessGroupsListFetching = createSelector(
  getAccessSettingsGroupsList,
  (groupsList) => groupsList.isFetching
);

export const isAccessGroupsListInvalid = createSelector(
  getAccessSettingsGroupsList,
  (groupsList) => groupsList.didInvalid
);

const getAccessSettingsApartment = (state) => state.accessSettings.apartment;

const getAccessSettingsGroupsListAll = (state) => state.accessSettings.groupsList.listAll;

export const getApartmentAccessGroupsList = createSelector(
  getAccessSettingsApartment,
  (apartment) => apartment?.data || []
);

export const isApartmentAccessGroupsListInvalid = createSelector(
  getAccessSettingsApartment,
  (apartment) => apartment?.didInvalid
);

export const isCreateAddApartmentAccessGroupsFetching = createSelector(
  getAccessSettingsApartment,
  (apartment) => apartment.createGroup.isFetching
);

export const getAccessGroupApartmentGroupsFetching = createSelector(
  getAccessSettingsApartment,
  getAccessSettingsGroupsListAll,
  (apartment, groupListAll) => groupListAll.isFetching || apartment.isFetching
);

const getAccessSettingsUser = (state) => state.accessSettings.user;

export const getUserAccessGroupsList = createSelector(getAccessSettingsUser, (user) => user?.data || []);

export const getUserApartmentsAccessGroupsList = createSelector(getAccessSettingsUser, (user) => {
  const groups = user?.apartmentsGroups?.data?.flatMap((item) => item.groups);

  return orderBy(
    uniqBy(groups, 'id').map((item) => ({
      id: item.id,
      name: item.name,
    })),
    ['name'],
    ['asc']
  );
});

export const getUserApartmentsList = createSelector(getAccessSettingsUser, (user) =>
  orderBy(
    user?.apartmentsGroups?.data?.map((item) => {
      const { coreId, devicesCount, name, number } = item;
      return {
        devicesCount,
        id: coreId || '',
        name,
        number,
      };
    }) || [],
    ['number'],
    ['asc']
  )
);

export const getActiveApartmentsList = createSelector(getUserApartmentsList, (apartments) =>
  apartments.filter((item) => item.devicesCount > 0)
);

export const isUserApartmentsAccessGroupsListFetching = createSelector(
  getAccessSettingsUser,
  (user) => user?.apartmentsGroups.isFetching
);

export const hasUserApartmentRacDevice = createSelector(
  getAccessSettingsUser,
  (user) => !!user?.apartmentsGroups?.data?.find((item) => item.devicesCount > 0)
);

export const isUserAccessGroupsListInvalid = createSelector(getAccessSettingsUser, (user) => user?.didInvalid);

export const isCreateAddUserAccessGroupsFetching = createSelector(
  getAccessSettingsUser,
  (user) => user?.createGroup.isFetching
);

export const getDeviceAccessGroupsList = (state) => state.accessSettings.device?.data || [];

export const isDeviceAccessGroupsListInvalid = (state) => state.accessSettings.device?.didInvalid;

export const isCreateAddDeviceAccessGroupsFetching = (state) => state.accessSettings.device?.createGroup.isFetching;

const getAccessControlDevices = (state) =>
  state.accessSettings.accessControlDevices?.data?.map((item) => ({
    deviceId: item.device.id,
    deviceName: item.device.name,
  })) || [];

const getAccessGroupDevicesAll = (state) => state.accessSettings.groupDetail.devices.listAll?.data || [];

export const getAccessControlDevicesFiltered = createSelector(
  getAccessControlDevices,
  getAccessGroupDevicesAll,
  (acsDevices, groupDevices) => {
    const addGroups = groupDevices.map((item) => item.deviceId);
    return acsDevices.filter((item) => !addGroups.toString().includes(item.deviceId.toString()));
  }
);

export const isAddAccessGroupDevicesFetching = (state) => state.accessSettings.groupDetail.devices.add.isFetching;

export const getAccessGroupDeviceGroupsFetching = (state) =>
  state.accessSettings.groupsList.listAll.isFetching || state.accessSettings.device.isFetching;

const getAccessControlUsers = (state) =>
  state.accessSettings.accessControlUsers?.data?.map((item) => {
    return {
      apartments: item.apartments.map((element) => ({ name: element.name, number: element.number })),
      role: item.role,
      userId: item.id,
      userName: item.firstName ? `${item.firstName} ${item.lastName}` : item.lastName,
      uuid: item.uuid,
    };
  }) || [];

export const getAccessGroupUsersAll = (state) => state.accessSettings.groupDetail.users.listAll?.data || [];

export const getAccessControlUsersFiltered = createSelector(
  getAccessControlUsers,
  getAccessGroupUsersAll,
  (acsUsers, groupUsers) => {
    const addUsers = groupUsers.map((item) => item.id);
    return acsUsers
      .filter((item) => !addUsers.toString().includes(item.userId.toString()))
      .map((user) => ({
        apartments: user.apartments,
        id: user.userId,
        label: user.userName,
        role: user.role,
        uuid: user.uuid,
      }));
  }
);

export const getAccessGroupUserGroupsFetching = (state) => state.accessSettings.groupsList.listAll.isFetching;

export const isAddAccessGroupUsersFetching = (state) =>
  state.accessSettings.groupDetail.users.add.isFetching || state.accessSettings.groupDetail.users.create.isFetching;

export const getIsAccessLogActive = (state) => state.accessSettings.accessControlSettings.data?.accessLog;

export const getIsAccessControlActive = (state) => state.accessSettings.accessControlSettings.data?.active;

export const getIsAccessControlFetching = (state) => state.accessSettings.accessControlSettings.isFetching;

export const getIsActivateRacAccessGroupDeviceFetching = (state) =>
  state.accessSettings.groupDetail.devices.racActivate.isFetching;

export const getIsDeactivateRacAccessGroupDeviceFetching = (state) =>
  state.accessSettings.groupDetail.devices.racDeactivate.isFetching;

export const getIsSetLogAccessGroupDeviceFetching = (state) =>
  state.accessSettings.groupDetail.devices.logSettings.isFetching;

export const getIsRemoveAccessGroupDeviceFetching = (state) =>
  state.accessSettings.groupDetail.devices.remove.isFetching;

const getAccessControlApartments = (state) =>
  state.accessSettings.accessControlApartments?.data?.map((item) => ({
    floor: item?.floor?.name || '',
    id: item.id,
    label: `${item.number} ${item.name}`,
    name: item.name,
    number: item.number,
  })) || [];

const getAccessGroupApartmentsAll = (state) => state.accessSettings.groupDetail.apartments.listAll?.data || [];

export const getAccessControlApartmentsFiltered = createSelector(
  getAccessControlApartments,
  getAccessGroupApartmentsAll,
  (siteApartments, groupApartments) => {
    const addedApartments = groupApartments.map((item) => item.coreId);
    return orderBy(
      siteApartments.filter((item) => !addedApartments.toString().includes(item.id.toString())),
      ['floor'],
      ['asc']
    );
  }
);

export const isAddAccessGroupApartmentsFetching = (state) => state.accessSettings.groupDetail.apartments.add.isFetching;

export const getIsRemoveAccessGroupApartmentFetching = (state) =>
  state.accessSettings.groupDetail.apartments.remove.isFetching;
