import { defineMessages } from 'react-intl';

export default defineMessages({
  DeviceElevatorIntegrationMainSettingsActive: {
    defaultMessage: 'Integration active',
    description: 'Device Elevator integration settings - active state',
    id: 'DeviceElevatorIntegrationMainSettings.activeState',
  },
  DeviceElevatorIntegrationMainSettingsButton: {
    defaultMessage: 'Set integration',
    description: 'Device Elevator integration settings - button label',
    id: 'DeviceElevatorIntegrationMainSettings.buttonLabel',
  },
  DeviceElevatorIntegrationMainSettingsInactive: {
    defaultMessage: 'Integration inactive <box>(set integration for this device)</box>',
    description: 'Device Elevator integration settings - inactive state',
    id: 'DeviceElevatorIntegrationMainSettings.inactiveState',
  },
});
