import { OryLoginStatus } from '../../ory/types';

export interface IAuthStore {
  accessToken?: string;
  error?: any;
  isFetching: boolean;
  isFirstLogin: boolean;
  isRegistered: boolean;
  preferUserLogin: boolean;
  redirectToResendWithEmail: string;
  resendInvitation: {
    error?: any;
    isFetching: boolean;
  };
  throttlingRetryAfter?: Date;
  oryLoginStatus?: OryLoginStatus;
}

export function initialAuthStore(): IAuthStore {
  return {
    isFetching: false,
    isFirstLogin: false,
    isRegistered: false,
    oryLoginStatus: undefined,
    preferUserLogin: false,
    redirectToResendWithEmail: '',
    resendInvitation: {
      isFetching: false,
    },
    throttlingRetryAfter: undefined,
  };
}
