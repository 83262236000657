import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FAQ_PHONEBOOK } from '../../../config/address';
import messages from '../messages';

const PhoneBookInfo = () => (
  <Typography>
    <FormattedMessage
      {...messages.phoneBookInfoText}
      values={{
        link: (
          <Link href={FAQ_PHONEBOOK} rel="noopener noreferrer" target="_blank" underline="hover">
            {'FAQ'}
          </Link>
        ),
      }}
    />
  </Typography>
);

export default PhoneBookInfo;
