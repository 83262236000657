import { isObject } from 'lodash';
import { MessageDescriptor } from 'react-intl';
import * as Yup from 'yup';
import messages from './messages';
import { IMvSdkSettingsFormData } from './MvSdkSettingsForm';

export function getDefaultValues(data: IMvSdkSettingsFormData | undefined) {
  return {
    authKey: data?.authKey || '',
    bundleId: data?.bundleId || '',
    databaseName: data?.databaseName || '',
    keyId: data?.keyId || '',
    serviceJson: data?.serviceJson || '',
    teamId: data?.teamId || '',
  };
}

export function getValidationSchema(formatMessage: (message: MessageDescriptor) => string) {
  return Yup.object().shape({
    authKey: Yup.string().required(formatMessage(messages.mvSdkSettingsRequired)),
    bundleId: Yup.string().required(formatMessage(messages.mvSdkSettingsRequired)),
    databaseName: Yup.string().required(formatMessage(messages.mvSdkSettingsRequired)),
    keyId: Yup.string().required(formatMessage(messages.mvSdkSettingsRequired)),
    serviceJson: Yup.string()
      .required(formatMessage(messages.mvSdkSettingsRequired))
      .test('json', formatMessage(messages.mvSdkSettingsInvalidFormat), (value) => {
        let valueObject;
        try {
          valueObject = JSON.parse(value);
        } catch (e) {
          return false;
        }
        return isObject(valueObject);
      }),
    teamId: Yup.string().required(formatMessage(messages.mvSdkSettingsRequired)),
  });
}
