import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FE_ROLES } from '../../config/consts';
import { IModalBase } from '../../modules/commonTypes';
import { actionsTS, CONTEXT } from '../../modules/user';
import { IUser } from '../../modules/user/store';
import { ActionModalError } from '../_DesignSystemWrappers/Modal';
import { useGetGlobalHooks } from './hooks/getGlobalHooks';
import messages from './messages';

type ContextKeys = keyof typeof CONTEXT;
export type TContext = (typeof CONTEXT)[ContextKeys];

interface IUserDeleteDialog extends IModalBase {
  context: TContext;
  user: IUser;
}

const UserDeleteModal = ({ context, onClose, open, user }: IUserDeleteDialog) => {
  const { dispatch, formatMessage } = useGetGlobalHooks();

  function handleOnDelete() {
    if (context === CONTEXT.SITE_USERS) {
      dispatch(actionsTS.deleteSiteUserRequest(user, context));
    } else {
      dispatch(actionsTS.deleteUserRequest(user, context));
    }

    onClose();
  }

  return (
    <ActionModalError
      icon={<DeleteIcon />}
      isPrimaryButtonDisabled={false}
      muiDialogProps={{
        maxWidth: 'xs',
      }}
      onClose={onClose}
      open={open}
      primaryButtonAction={handleOnDelete}
      primaryButtonText={formatMessage(messages.userDeleteDialogConfirm)}
      secondaryButtonText={formatMessage(messages.userDeleteDialogCancel)}
      title={
        user.role === FE_ROLES.LOGIN_LESS
          ? formatMessage(messages.loginLessDialogTitle)
          : formatMessage(messages.userDeleteDialogTitle)
      }
    >
      {context === CONTEXT.COMPANY_ADMINS ? (
        <>
          <Typography sx={{ mb: 1.5 }}>
            <FormattedMessage {...messages.companyAdminDeleteDialogBodyRow1} />
          </Typography>
          <Typography>
            <FormattedMessage {...messages.companyAdminDeleteDialogBodyRow2} />
          </Typography>
        </>
      ) : (
        <>
          <Typography sx={{ mb: 1.5 }}>
            <FormattedMessage {...messages.userDeleteDialogBodyRow1} />
          </Typography>
          {user.role !== FE_ROLES.LOGIN_LESS && (
            <Typography>
              <FormattedMessage {...messages.userDeleteDialogBodyRow2} />
            </Typography>
          )}
        </>
      )}
    </ActionModalError>
  );
};

export default UserDeleteModal;
