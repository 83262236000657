import EditIcon from '@mui/icons-material/Edit';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PATHS, ROLES } from '../../../../config/consts';
import { useGetActualCompanyId } from '../../../../hooks/companyHooks';
import { useGetActualSiteId } from '../../../../hooks/siteHooks';
import { useModal } from '../../../../hooks/useModal';
import actions, { selectors } from '../../../../modules/common';
import siteActions from '../../../../modules/sites';
import { isApartmentAdminInSite } from '../../../../modules/user/helpersTS';
import CreateSiteDialog from '../../../CreateSiteModal';
import SiteButton from './SiteButton';
import SiteSwitcher from './SiteSwitcher';

const SiteMenu = ({ active, data, isCompanyAdmin, isFetching, isSideBar, isSiteAdmin, onEdit }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const companyId = useGetActualCompanyId();
  const siteId = useGetActualSiteId();
  const [open, setOpen] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const anchorRef = useRef(null);
  const currentSite = data.find((item) => item.id === siteId);
  const isDataFetching = useSelector(selectors.isFetchingSiteSwitcher);
  const didInvalid = useSelector(selectors.didInvalidSiteSwitcher);

  const { Modal: CreateSiteModal, onOpen: onOpenCreateSiteModal } = useModal({
    Modal: CreateSiteDialog,
  });

  const addSite = () => {
    setOpen(false);
    onOpenCreateSiteModal();
  };

  const selectSite = (currentSiteId) => {
    setOpen(false);
    const site = data.find((item) => item.id === currentSiteId);
    if (site.roles.includes(ROLES.ADMIN)) {
      navigate(PATHS.SITE_DASHBOARD.replace(':companyId', companyId).replace(':siteId', currentSiteId));
    } else if (isApartmentAdminInSite(site)) {
      navigate(PATHS.USERS_IN_APARTMENTS);
    } else {
      navigate(PATHS.USER_PROFILE);
    }
  };

  useEffect(() => {
    if (!isDataFetching && didInvalid) {
      dispatch(actions.loadDataSiteSwitcher());
    }
  }, [companyId, siteId, didInvalid]);

  return (
    (isSiteAdmin || isCompanyAdmin) && (
      <div id="site-menu">
        <Grid
          alignItems="center"
          container
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onFocus={() => {}}
          onMouseLeave={() => setEditVisible(false)}
          onMouseOver={() => setEditVisible(true)}
        >
          <Grid item>
            <SiteButton
              active={active}
              anchorRef={anchorRef}
              onClickButton={() => setOpen((prevState) => !prevState)}
              open={open}
              siteName={currentSite?.name || ''}
            />
          </Grid>
          {currentSite && editVisible && (
            <Grid item>
              <IconButton onClick={() => onEdit(currentSite)}>
                <Box color="common.white">
                  <EditIcon />
                </Box>
              </IconButton>
            </Grid>
          )}
        </Grid>
        <Popper
          anchorEl={anchorRef.current}
          open={open}
          placement="bottom-start"
          style={{ zIndex: theme.zIndex.drawer }}
          transition
        >
          {({ placement, TransitionProps }) => (
            <Fade
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper elevation={24} id="site-menu-list-grow" sx={{ pt: 1 }}>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <div>
                    <SiteSwitcher
                      currentId={siteId}
                      data={data}
                      isFetching={isFetching}
                      isSideBar={isSideBar}
                      onAdd={addSite}
                      onSelect={selectSite}
                    />
                  </div>
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
        <CreateSiteModal />
      </div>
    )
  );
};

SiteMenu.propTypes = {
  active: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  isCompanyAdmin: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isSideBar: PropTypes.bool,
  isSiteAdmin: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
};

SiteMenu.defaultProps = {
  isSideBar: false,
};

const mapStateToProps = (state) => ({
  data: selectors.getDataSiteSwitcher(state),
  isFetching: selectors.isFetchingSiteSwitcher(state),
});

const mapDispatchToProps = {
  onEdit: siteActions.editSiteShowModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteMenu);
