import Tab from '@mui/material/Tab';
import React from 'react';

const SwitchPanelItem = (props) => (
  <Tab
    {...props}
    disableRipple
    sx={{
      '& .Mui-selected': {
        background: 'common.white',
      },
    }}
  />
);

export default SwitchPanelItem;
