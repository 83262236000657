import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import React from 'react';
import RowSectionTitle from '../RowSectionTitle';

interface IRowSection {
  icon: React.ReactNode;
  label: string;
  children: React.ReactNode;
  hasDivider?: boolean;
  labelWidth?: number;
}

function RowSection({ children, hasDivider, icon, label, labelWidth }: IRowSection) {
  return (
    <>
      {hasDivider && <Divider />}
      <Stack direction={{ sm: 'row', xs: 'column' }} spacing={1} sx={{ pb: 3, pt: hasDivider ? 2 : 0 }}>
        <RowSectionTitle icon={icon} label={label} labelWidth={labelWidth} />
        <Box sx={{ width: '100%' }}>{children}</Box>
      </Stack>
    </>
  );
}

export default RowSection;
