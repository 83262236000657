import { defineMessages } from 'react-intl';

export default defineMessages({
  AAModalAccessClose: {
    defaultMessage: 'CLOSE',
    id: 'AA.modal.access.close',
  },
  AAModalAccessText: {
    defaultMessage:
      'Access rights are set by your facility manager. They define which users have access via which devices.<p>For access to work, the user also must be given some credential (PIN, RFID key fob or My2N app access).</p>',
    id: 'AA.modal.access.text',
  },
  AAModalAccessTitle: {
    defaultMessage: 'Access settings help',
    id: 'AA.modal.access.title',
  },
});
