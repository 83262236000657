import { defineMessages } from 'react-intl';

export default defineMessages({
  DevicesAndLicensesCard2Ndevices: {
    defaultMessage: '2N devices{br}with free license',
    id: 'devicesAndLicensesCard.2Ndevices',
  },
  DevicesAndLicensesCard2NdevicesApartments: {
    defaultMessage: 'Apartments{br}active for free',
    id: 'devicesAndLicensesCard.2NdevicesApartments',
  },
  DevicesAndLicensesCardDevicesAboveLimitNotZero: {
    defaultMessage: 'Licenses above limit{br}Contact <link>customer care</link>',
    id: 'devicesAndLicensesCard.devicesAboveLimitNotZero',
  },
  DevicesAndLicensesCardDevicesAboveLimitZero: {
    defaultMessage: 'Licences{br}above limit',
    id: 'devicesAndLicensesCard.devicesAboveLimitZero',
  },
  DevicesAndLicensesCardLicensedApartments: {
    defaultMessage: 'Licensed{br}apartments',
    id: 'devicesAndLicensesCard.licensedApartments',
  },
  DevicesAndLicensesCardLicensedDevices: {
    defaultMessage: 'Licensed{br}devices',
    id: 'devicesAndLicensesCard.licensedDevices',
  },
  DevicesAndLicensesCardTitle: {
    defaultMessage: 'Overview of licensed devices',
    id: 'devicesAndLicensesCard.title',
  },
  DevicesAndLicensesCardTitleApartments: {
    defaultMessage: 'Overview of licensed apartments',
    id: 'devicesAndLicensesCard.titleApartments',
  },
});
