import FilterNoneIcon from '@mui/icons-material/FilterNone';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

const styles = (theme) => ({
  button: {
    '&.MuiIconButton-root': {
      ...theme.typography.caption,
      '& :hover': {
        background: 'none',
        fill: theme.palette.primary.main,
      },
      fill: theme.palette.grey[500],
      fontSize: '1rem',
      marginLeft: theme.spacing(0.5),
    },
  },
  description: {
    ...theme.typography.caption,
    color: theme.palette.secondary.main,
    fontSize: '1rem',
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    '&.MuiSvgIcon-root': {
      ...theme.typography.caption,
      fontSize: '1rem',
    },
  },
  label: {
    ...theme.typography.caption,
    color: theme.palette.common.black,
    fontSize: '1rem',
  },
});

const ClipboardLabel = ({ description, label, value }) => {
  const theme = useTheme();
  const stylesObj = useMemo(() => styles(theme), [theme]);

  const clickHandler = (event) => {
    event.preventDefault();
    copy(value !== null ? value : label);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <Box component="span" sx={stylesObj.label}>
        {label}
      </Box>
      {description && (
        <Box component="span" sx={stylesObj.description}>
          {description}
        </Box>
      )}
      <IconButton aria-label="Copy" onClick={clickHandler} size="small" sx={stylesObj.button} type="button">
        <FilterNoneIcon fontSize="small" sx={stylesObj.iconSmall} />
      </IconButton>
    </Box>
  );
};

ClipboardLabel.propTypes = {
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  value: PropTypes.string,
};

ClipboardLabel.defaultProps = {
  description: null,
  value: null,
};

export default ClipboardLabel;
