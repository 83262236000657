import { SvgIconComponent } from '@mui/icons-material';
import Person from '@mui/icons-material/Person';
import React, { ComponentProps } from 'react';
import { ApartmentAdminBadge } from './ApartmentAdminBadge';

export function ApartmentAdminIcon(props: ComponentProps<SvgIconComponent>) {
  return (
    <ApartmentAdminBadge borderColor={'white'}>
      <Person {...props} />
    </ApartmentAdminBadge>
  );
}
