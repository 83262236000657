import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const UnavailableSettings = () => (
  <Typography color="textSecondary">
    <FormattedMessage {...messages.unavailableSettingsLabel} />
  </Typography>
);

export default UnavailableSettings;
