import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useModal } from '../../../hooks/useModal';
import { CONTEXT } from '../../../modules/user';
import DataGridRowMenu from '../../DataGrid/RowMenu';
import RowMenuItem from '../../DataGrid/RowMenuItem';
import { UserDeleteModal } from '../../index';
import messages from '../messages';

const RowMenu = ({ disabled, onDetail, user }) => {
  const { Modal: UserDeleteUModal, onOpen: onOpenUserDeleteUModal } = useModal({
    Modal: UserDeleteModal,
  });
  return (
    <>
      <DataGridRowMenu
        menuItems={
          <Box>
            <RowMenuItem
              icon={<AccountCircleIcon />}
              key="view-profile"
              label={<FormattedMessage {...messages.userRowMenuViewProfile} />}
              onClick={onDetail}
            />
            <Divider />
            <RowMenuItem
              colorIcon="error.dark"
              colorText="error.dark"
              disabled={disabled}
              icon={<DeleteIcon />}
              key="user-delete"
              label={<FormattedMessage {...messages.userRowMenuDelete} />}
              onClick={onOpenUserDeleteUModal}
            />
          </Box>
        }
      />
      <UserDeleteUModal context={CONTEXT.COMPANY_ADMINS} user={user} />
    </>
  );
};

RowMenu.propTypes = {
  disabled: PropTypes.bool,
  onDetail: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

RowMenu.defaultProps = {
  disabled: false,
};

export default RowMenu;
