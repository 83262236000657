import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PrimaryValue } from '../../../../components';
import messages from './messages';

const TotalValueBar = ({ credits }) => (
  <Box alignItems="center" display="flex" justifyContent="space-between">
    <Typography variant="h6">
      <FormattedMessage {...messages.totalValueBarText} />
    </Typography>
    <Typography>
      <FormattedMessage
        {...messages.totalValueBarValue}
        values={{ value: <PrimaryValue value={credits.toString()} /> }}
      />
    </Typography>
  </Box>
);

TotalValueBar.propTypes = {
  credits: PropTypes.number.isRequired,
};

export default TotalValueBar;
