import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useUrlParams } from '../../../../helpers/urlParams';
import { getElevatorIntegrationValidationSchema } from '../validations/elevatorIntegrationValidationSchema';

export function useGetGlobalHooks() {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const location = useLocation();
  const { companyId, siteId } = useUrlParams();
  const formMethods = useForm({
    defaultValues: {
      destinationCallEnabled: false,
      elevatorIntegrationEnabled: false,
      landingCallEnabled: false,
      visitorCallEnabled: false,
    },
    mode: 'onChange',
    resolver: yupResolver(getElevatorIntegrationValidationSchema(formatMessage)),
    shouldUnregister: true,
  });

  return {
    companyId: companyId!.toString(),
    dispatch,
    formatMessage,
    formMethods,
    location,
    siteId: siteId!.toString(),
  };
}
