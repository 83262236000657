import { useSelector } from 'react-redux';
import * as localizationSelectors from '../../../../modules/localization/selectors';
import * as siteSelectors from '../../../../modules/sites/selectors';

export function useGetSiteLanguageData() {
  const { siteData, siteLanguage } = {
    siteData: useSelector(siteSelectors.getSiteData),
    siteLanguage: useSelector(localizationSelectors.getCurrentSiteLanguage),
  };

  return {
    siteLanguage,
    siteName: siteData.name,
  };
}
