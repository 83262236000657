import PropTypes from 'prop-types';
import React from 'react';
import { FIRMWARE_STATUS } from '../../../config/devices';
import AdaptiveVideoQualityNotification from './AdaptiveVideoQualityNotification';
import UpgradeNotification from './UpgradeNotification';

const VersionNotification = ({ adaptiveVideoQualityWarning, firmware, firmwareStatus, onUpdateFirmware }) => (
  <>
    {adaptiveVideoQualityWarning && firmwareStatus !== FIRMWARE_STATUS.UNSUPPORTED ? (
      <AdaptiveVideoQualityNotification firmware={firmware} onUpdateFirmware={onUpdateFirmware} />
    ) : (
      firmware.info.haveUpdate && (
        <UpgradeNotification firmware={firmware} firmwareStatus={firmwareStatus} onUpdateFirmware={onUpdateFirmware} />
      )
    )}
  </>
);

VersionNotification.propTypes = {
  adaptiveVideoQualityWarning: PropTypes.bool.isRequired,
  firmware: PropTypes.shape({
    info: PropTypes.shape({
      haveUpdate: PropTypes.bool,
      newFirmware: PropTypes.shape({
        releaseNotes: PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string,
            version: PropTypes.string,
          })
        ),
        version: PropTypes.string,
      }),
    }),
    isUpdating: PropTypes.bool,
  }),
  firmwareStatus: PropTypes.string,
  onUpdateFirmware: PropTypes.func.isRequired,
};

VersionNotification.defaultProps = {
  firmware: {
    info: {
      haveUpdate: false,
      newFirmware: {
        releaseNotes: [],
        version: '',
      },
    },
    isUpdating: false,
  },
  firmwareStatus: '',
};

export default VersionNotification;
