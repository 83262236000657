import { IRfid } from '../../api/userApiTS';
import { AccessGroupName, IAccessGroup, IApartmentWithAccessGroups } from './data';

export function getApartmentAccessGroupNames(apartments: IApartmentWithAccessGroups[]): AccessGroupName[] {
  const accessGroupNames: AccessGroupName[] = [];
  apartments.forEach((apartment) => accessGroupNames.push(...getAccessGroupNames(apartment.groups)));
  return accessGroupNames;
}

export function getAccessGroupNames(accessGroups: IAccessGroup[]): AccessGroupName[] {
  return accessGroups.map((group) => group.name);
}

export function getUserRfid(payload: IRfid[]): IRfid | undefined {
  return payload[0] || undefined;
}
