import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { WarningIconMessage } from '../../../components';
import { PATHS } from '../../../config/consts';
import { useUrlParams } from '../../../helpers/urlParams';
import messages from './messages';

const styleDescription = {
  borderTop: '1px solid',
  borderTopColor: 'text.secondary',
  marginTop: 3,
  paddingTop: 2,
  textAlign: 'left',
};

const NoVerifiedMessage = () => {
  const { companyId } = useUrlParams();
  return (
    <>
      <WarningIconMessage>
        <FormattedMessage {...messages.waitingMessageMessage} />
      </WarningIconMessage>
      <Box sx={styleDescription}>
        <Typography variant="body2">
          <FormattedMessage
            {...messages.waitingMessageDescription}
            values={{
              link: (
                <Link href={PATHS.SUPPORT.replace(':companyId', companyId)} rel="noopener noreferrer" target="_blank">
                  <FormattedMessage {...messages.noVerifiedMessageTechnicalSupportTeam} />
                </Link>
              ),
            }}
          />
        </Typography>
      </Box>
    </>
  );
};

export default NoVerifiedMessage;
