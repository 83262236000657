import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { LinksBox } from '../../components';
import { PATHS } from '../../config/consts';
import { MOBILE_VIDEO_STATUS } from '../../config/consts';
import { canPurchaseIntercomLicenceOnNonstandardModel } from '../../helpers/subscriptionsHelpers';
import { useAppContext } from '../App/AppContextProvider';
import messages from './messages';

const LinksSection = ({ companyId, licenceModel, onAddUser, siteId }) => {
  const { formatMessage } = useIntl();
  const pathReplace = (path) => path.replace(':companyId', companyId).replace(':siteId', siteId);
  const { feSiteRoles, userRoles } = useAppContext();

  return (
    <Grid container spacing={2}>
      <Grid item md sm={6} xs={12}>
        <LinksBox
          links={[
            {
              label: formatMessage(messages.linksSectionLinkPhonebooks),
              link: pathReplace(PATHS.CALL_SETTINGS_PHONEBOOKS),
            },
            {
              label: formatMessage(messages.linksSectionLinkIntercoms),
              link: pathReplace(PATHS.CALL_SETTINGS_INTERCOMS),
            },
            {
              label: formatMessage(messages.linksSectionLinkAnsweringUnits),
              link: pathReplace(PATHS.CALL_SETTINGS_ANSWERING_UNITS),
            },
          ]}
          name={formatMessage(messages.linksSectionNameCallSettings)}
        />
      </Grid>
      {!canPurchaseIntercomLicenceOnNonstandardModel(userRoles, licenceModel) && (
        <Grid item md sm={6} xs={12}>
          <LinksBox
            links={
              feSiteRoles.isSiteAdminPayment
                ? [
                    {
                      label: formatMessage(messages.linksSectionLinkPurchase),
                      link: pathReplace(PATHS.SUBSCRIPTION_MOBILE_VIDEO),
                    },
                    {
                      label: formatMessage(messages.linksSectionLinkVoucher),
                      link: pathReplace(PATHS.SUBSCRIPTION_ACTIVATION_CODE),
                    },
                  ]
                : [
                    {
                      label: formatMessage(messages.linksSectionLinkVoucher),
                      link: pathReplace(PATHS.SUBSCRIPTION_ACTIVATION_CODE),
                    },
                  ]
            }
            name={formatMessage(messages.linksSectionNameSubscription)}
          />
        </Grid>
      )}
      <Grid item md sm={6} xs={12}>
        <LinksBox
          links={[
            {
              label: formatMessage(messages.linksSectionLinkUsers),
              link: pathReplace(PATHS.SITE_USERS),
            },
            {
              action: onAddUser,
              label: formatMessage(messages.linksSectionLinkAddUser),
            },
          ]}
          name={formatMessage(messages.linksSectionNameSiteUsers)}
        />
      </Grid>
    </Grid>
  );
};

LinksSection.propTypes = {
  companyId: PropTypes.number.isRequired,
  licenceModel: PropTypes.shape({
    status: PropTypes.oneOf(Object.keys(MOBILE_VIDEO_STATUS)),
    type: PropTypes.string,
  }).isRequired,
  onAddUser: PropTypes.func.isRequired,
  siteId: PropTypes.number.isRequired,
};

export default LinksSection;
