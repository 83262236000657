import { PROMO_TYPES, SUBSCRIPTION_TYPE } from './consts';

export const getVoucherValue = (years, subscriptions) => years * subscriptions;

export const getPrice = (unitFullPrice, years, subscriptions) => unitFullPrice * getVoucherValue(years, subscriptions);

export const getSubscriptionTypeBasedOnProduct = (type) => {
  if (type === PROMO_TYPES.MOBILE_VIDEO_POOL_CREDIT_YEAR_STANDARD) {
    return SUBSCRIPTION_TYPE.DEVICE;
  }
  if (type === PROMO_TYPES.MOBILE_VIDEO_POOL_CREDIT_YEAR_APARTMENT) {
    return SUBSCRIPTION_TYPE.APARTMENT;
  }
  return SUBSCRIPTION_TYPE.DEFAULT;
};
