import { defineMessages } from 'react-intl';

export default defineMessages({
  addCompanyModalAgreeLabel: {
    defaultMessage:
      'I agree with <cloudLink>My2N Cloud Agreement</cloudLink>' +
      ' and I have read <consumerLink>Information for Consumers</consumerLink>',
    id: 'addCompanyModal.agree.label',
  },
  addCompanyModalAgreeRequired: {
    defaultMessage: 'Value is mandatory.',
    id: 'addCompanyModal.agree.required',
  },
  addCompanyModalCancel: {
    defaultMessage: 'Cancel',
    description: 'Add company modal - cancel button',
    id: 'addCompanyModal.cancel',
  },
  addCompanyModalDescription: {
    defaultMessage: 'You can create a new entity with different billing information.',
    description: 'Add company modal - description',
    id: 'addCompanyModal.description',
  },
  addCompanyModalLabelHelperNameCompany: {
    defaultMessage: 'The company name can’t be changed later',
    description: 'Add company modal - helper name company label',
    id: 'addCompanyModal.label.helperNameCompany',
  },
  addCompanyModalLabelNameCompany: {
    defaultMessage: 'New company name',
    description: 'Add company modal - name company label',
    id: 'addCompanyModal.label.nameCompany',
  },
  addCompanyModalSubmit: {
    defaultMessage: 'Create company',
    description: 'Add company modal - submit button',
    id: 'addCompanyModal.submit',
  },
  addCompanyModalTitle: {
    defaultMessage: 'Create new company',
    description: 'Add company modal - create new company button',
    id: 'addCompanyModal.title',
  },
  addCompanyModalVerificationMaxLength: {
    defaultMessage: 'Company name is too long.',
    description: 'Verification max length',
    id: 'addCompanyModal.verification.maxLength',
  },
  addCompanyModalVerificationRequired: {
    defaultMessage: 'Value is mandatory.',
    description: 'Verification required',
    id: 'addCompanyModal.verification.required',
  },
});
