import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useUrlParams } from '../../helpers/urlParams';
import actions, { selectors } from '../../modules/accessSettings';
import apartmentActions from '../../modules/apartments';
import AccessGroupsSettings from '../AccessGroupsSettings';
import ApartmentAccessGroupsModal from '../ApartmentAccessGroupsModal';
import messages from './messages';

function ApartmentAccessGroupsSettings() {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { apartmentId } = useUrlParams();
  const data = {
    groups: useSelector(selectors.getApartmentAccessGroupsList),
    isListInvalid: useSelector(selectors.isApartmentAccessGroupsListInvalid),
  };

  useEffect(() => {
    loadGroupsList();
  }, []);

  useEffect(() => {
    if (data.isListInvalid) {
      loadGroupsList();
    }
  }, [data.isListInvalid]);

  function loadGroupsList() {
    dispatch(actions.getApartmentAccessGroupsList(apartmentId));
  }

  return (
    <>
      <AccessGroupsSettings
        buttonAction={() => dispatch(apartmentActions.setApartmentAccessGroupShowModal(apartmentId))}
        groups={data.groups}
        helperText={formatMessage(messages.apartmentAccessGroupsSettingsHelperText, { count: data.groups.length })}
        isRacActive={false}
        noGroupsLabel={formatMessage(messages.apartmentAccessGroupsSettingsAlertNoGroups)}
        racInactiveLabel={formatMessage(messages.apartmentAccessGroupsSettingsAlertRacInactive)}
      />
      <ApartmentAccessGroupsModal />
    </>
  );
}

export default ApartmentAccessGroupsSettings;
