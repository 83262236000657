export const styles = {
  collapseSidebar: {
    ml: { sm: 'auto', xs: 'calc(325px / 2)' },
    mr: { sm: 'auto' },
  },
  noSidebar: {
    mx: 'auto',
    pb: '3rem',
    pt: 0,
    px: { lg: 3, sm: 2, xs: 1 },
  },
};
