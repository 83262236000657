import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

export const floorField = 'newApartmentFloor';
export const numberField = 'newApartmentNumber';
export const nameField = 'newApartmentName';

export const requiredError = 'required';
export const notUniqueError = 'not unique';

export function getNumberFieldState(values) {
  if (values.formErrors[numberField] === requiredError) {
    return {
      hasError: true,
      helperText: <FormattedMessage {...messages.newApartmentNumberRequiredError} />,
    };
  }

  if (values.formErrors[numberField] === notUniqueError) {
    return {
      hasError: true,
      helperText: <FormattedMessage {...messages.newApartmentNumberNotUniqueError} />,
    };
  }

  return {
    hasError: false,
    helperText: <FormattedMessage {...messages.newApartmentNumberHelperText} />,
  };
}

export function getNameFieldState() {
  return {
    hasError: false,
    helperText: <FormattedMessage {...messages.newApartmentNameHelperText} />,
  };
}

export function getFloorFieldState(values) {
  if (values.formErrors[floorField] === requiredError) {
    return {
      hasError: true,
      helperText: <FormattedMessage {...messages.newApartmentFloorRequiredError} />,
    };
  }

  return {
    hasError: false,
    helperText: <FormattedMessage {...messages.newApartmentFloorHelperText} />,
  };
}
