import AddIcon from '@mui/icons-material/Add';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import DataGridActionButton from '../DataGridActionButton';
import DataGridSearchField from '../DataGridSearchField';

const TableControlPanel = ({
  addButtonAction,
  addButtonLabel,
  createButtonAction,
  createButtonLabel,
  createVisitorButtonAction,
  createVisitorButtonLabel,
  isGuestAccessFFEnabled,
  onSearchFieldChange,
  searchField,
  searchFieldPlaceholder,
  searchFieldText,
  tabToolbar,
}) => (
  <Grid
    container
    spacing={2}
    sx={{
      display: 'flex',
      justifyContent: 'flex-end',
      pb: 1,
      ...(tabToolbar && { px: 3 }),
    }}
  >
    {searchField && (
      <Grid item>
        <DataGridSearchField
          fulltext={searchFieldText}
          onChangeFilter={onSearchFieldChange}
          placeholder={searchFieldPlaceholder}
          size="small"
        />
      </Grid>
    )}
    {createVisitorButtonAction && isGuestAccessFFEnabled && (
      <Grid item>
        <DataGridActionButton
          onClick={createVisitorButtonAction}
          startIcon={<AddIcon />}
          sx={{ minHeight: '100%' }}
          variant="outlined"
        >
          {createVisitorButtonLabel}
        </DataGridActionButton>
      </Grid>
    )}
    {createButtonAction && (
      <Grid item>
        <DataGridActionButton
          onClick={createButtonAction}
          startIcon={<AddIcon />}
          sx={{ minHeight: '100%' }}
          variant="outlined"
        >
          {createButtonLabel}
        </DataGridActionButton>
      </Grid>
    )}
    <Grid item>
      <DataGridActionButton
        onClick={addButtonAction}
        startIcon={<AddIcon />}
        sx={{ minHeight: '100%' }}
        variant={createButtonAction ? 'contained' : 'outlined'}
      >
        {addButtonLabel}
      </DataGridActionButton>
    </Grid>
  </Grid>
);

TableControlPanel.propTypes = {
  addButtonAction: PropTypes.func.isRequired,
  addButtonLabel: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  createButtonAction: PropTypes.func,
  createButtonLabel: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  createVisitorButtonAction: PropTypes.func,
  createVisitorButtonLabel: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  isGuestAccessFFEnabled: PropTypes.bool,
  onSearchFieldChange: PropTypes.func,
  searchField: PropTypes.bool,
  searchFieldPlaceholder: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  searchFieldText: PropTypes.string,
  tabToolbar: PropTypes.bool,
};

TableControlPanel.defaultProps = {
  createButtonAction: null,
  createButtonLabel: '',
  createVisitorButtonAction: null,
  createVisitorButtonLabel: '',
  isGuestAccessFFEnabled: false,
  onSearchFieldChange: null,
  searchField: null,
  searchFieldPlaceholder: '',
  searchFieldText: '',
  tabToolbar: false,
};

export default TableControlPanel;
