import { defineMessages } from 'react-intl';

export default defineMessages({
  ImsResetPasswordAlertBody: {
    defaultMessage:
      'Check your inbox or spam folder. If you have not received the email, check the spelling of the address and make sure to use the address associated with My2N.',
    id: 'ims.resetPassword.alert.body',
  },
  ImsResetPasswordAlertTitle: {
    defaultMessage: 'Recovery code sent to: {email}',
    id: 'ims.resetPassword.alert.title',
  },
  ImsResetPasswordButtonBackToLogin: {
    defaultMessage: 'Back to login',
    id: 'ims.resetPassword.button.backToLogin',
  },
  ImsResetPasswordButtonResendCode: {
    defaultMessage: 'resend code',
    id: 'ims.resetPassword.button.resendCode',
  },
  ImsResetPasswordButtonSubmit: {
    defaultMessage: 'SUBMIT',
    id: 'ims.resetPassword.button.submit',
  },
  ImsResetPasswordRecoveryCodeHelper: {
    defaultMessage: 'Fill in code from email',
    id: 'ims.resetPassword.recoveryCode.helper',
  },
  ImsResetPasswordRecoveryCodeLabel: {
    defaultMessage: 'Recovery code',
    id: 'ims.resetPassword.recoveryCode.label',
  },
  ImsResetPasswordResendCodeError: {
    defaultMessage: 'You can resend the code once every 60 seconds. Please, try again later',
    id: 'ims.resetPassword.resendCode.error',
  },
  ImsResetPasswordResendCodeSuccess: {
    defaultMessage: 'Recovery code resent successfully',
    id: 'ims.resetPassword.resendCode.success',
  },
});
