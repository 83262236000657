import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PATHS } from '../config/consts';
import CallSettings from '../containers/CallSettings';
import AnsweringUnits from '../containers/CallSettings/AnsweringUnits';
import Intercoms from '../containers/CallSettings/Intercoms';
import Phonebooks from '../containers/CallSettings/Phonebooks';
import { useGetLoadedParams } from '../hooks/paramsHooks';
import { ApplicationContext, Roles } from '../modules/commonTypes';
import { PrivateRoute } from './components/PrivateRoute';

export function CallSettingsRoutes(): JSX.Element {
  const { companyId, siteId } = useGetLoadedParams(ApplicationContext.Site);

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <PrivateRoute allowedRoles={{ contextRoles: [Roles.ADMIN, Roles.STANDARD] }}>
            <CallSettings />
          </PrivateRoute>
        }
      >
        <Route element={<Intercoms />} path={'intercoms'} />
        <Route element={<AnsweringUnits />} path={'answering-units'} />
        <Route element={<Phonebooks />} path={'phonebooks'} />
      </Route>
      <Route
        path={PATHS.CALL_SETTINGS}
        element={
          <Navigate
            replace
            to={PATHS.CALL_SETTINGS_INTERCOMS.replace(':companyId', companyId.toString()).replace(
              ':siteId',
              siteId.toString()
            )}
          />
        }
      />
    </Routes>
  );
}
