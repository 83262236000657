import { useIntl } from 'react-intl';
import * as yup from 'yup';
import messages from './messages';

function useAccessGroupFormValidationSchema() {
  const { formatMessage } = useIntl();
  return yup.object().shape({
    hasForm: yup.boolean(),
    name: yup.string().when('hasForm', {
      is: true,
      then: (schema) =>
        schema.required(formatMessage(messages.useAccessGroupFormValidationSchemaValidationRequiredName)),
    }),
  });
}

export default useAccessGroupFormValidationSchema;
