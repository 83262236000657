import { forEach } from 'lodash';

// eslint-disable-next-line complexity
const mapGTC = (item) => ({
  confirmationType: item?.confirmationType || null,
  dateCreated: item?.dateCreated || null,
  dateEffective: item?.dateFirstAgreement || item?.dateEffective || null,
  description: item?.description || null,
  id: item?.id || null,
  inEffect: item?.inEffect || false,
  name: item?.name || null,
  nextId: item?.nextId || null,
  parentId: item?.parentId || null,
  path: item?.path || null,
  showType: item?.showType || null,
  version: item?.version || null,
  versions: [],
});

export const getGTCListData = (gtcData) => {
  const prepareData = gtcData.filter((item) => item?.showType !== 'ELEVATOR_CENTER');
  const children = prepareData.filter((item) => item?.nextId !== null).map(mapGTC);
  const data = prepareData.filter((item) => item?.nextId === null).map(mapGTC);
  forEach(data, (item) => {
    item.versions.push(item);
    if (item.parentId !== null) {
      const findItem = children.find((i) => i.id === item.parentId);
      if (findItem) {
        item.versions.push(findItem);
      }
    }
  });
  return data;
};

export const getLinkFromVersion = (versions) => {
  let link = versions.filter((i) => i.inEffect)?.[0] || null;
  if (link === null && versions.length > 0) {
    // eslint-disable-next-line prefer-destructuring
    link = versions[0];
  }
  return link;
};
