import { SERVICE } from '../../config/devices';
import { PURCHASE_PAYMENT_STATE } from '../../config/subscription';

export const isPromoDetailModalOpen = (state) => state.payments.list.promoDetailModal.show;

export const getPromoDetailModalData = (state) => state.payments.list.promoDetailModal.detail;

export const isPaymentDetailLoading = (state) => state.payments.list.promoDetailModal.isFetching;

export const getPaymentDetailData = (state) => state.payments.list.promoDetailModal.data;

export const isLastMVPaymentFinished = (state) =>
  !(
    state.payments.list.data?.[0]?.orderContent?.[0]?.type === SERVICE.MOBILE_VIDEO &&
    state.payments.list.data?.[0]?.purchaseState === PURCHASE_PAYMENT_STATE.PAID
  );
