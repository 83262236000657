import { defineMessages } from 'react-intl';

export default defineMessages({
  elevatorIntegrationMappingHeaderDestination: {
    defaultMessage: 'Destination (area)',
    id: 'elevatorIntegration.mapping.header.destination',
  },
  elevatorIntegrationMappingHeaderElevatorCore: {
    defaultMessage: 'Elevator core (buildingId, group name)',
    id: 'elevatorIntegration.mapping.header.elevatorCore',
  },
  elevatorIntegrationMappingHeaderMy2Nfloor: {
    defaultMessage: 'My2N floor',
    id: 'elevatorIntegration.mapping.header.My2Nfloor',
  },
  elevatorIntegrationMappingTitle: {
    defaultMessage: 'My2N floor configuration',
    id: 'elevatorIntegration.mapping.title',
  },
  siteSettingsFloorConfigurationFloor: {
    defaultMessage: 'Floor <strong>{name}</strong>',
    description: 'Floor label in table',
    id: 'siteSettingsFloorConfigurationFloor',
  },
});
