import { defineMessages } from 'react-intl';

export default defineMessages({
  DeviceDetailRemoteConfigStatusChangePassword: {
    defaultMessage: 'Change password',
    id: 'deviceDetail.remoteConfig.status.changePassword',
  },
  deviceRCStatusConnected: {
    defaultMessage: 'Connected',
    description: 'Remote configuration Connected',
    id: 'deviceRCStatus.connected',
  },
  deviceRCStatusConnectionRequested: {
    defaultMessage: 'Connection requested',
    description: 'Remote configuration connection requested',
    id: 'deviceRCStatus.connectionRequested',
  },
  deviceRCStatusDisabled: {
    defaultMessage: 'Disabled',
    description: 'Remote configuration disabled',
    id: 'deviceRCStatus.disabled',
  },
  deviceRCStatusOffline: {
    defaultMessage: 'Offline',
    description: 'Remote configuration offline',
    id: 'deviceRCStatus.offline',
  },
  deviceRCStatusOnline: {
    defaultMessage: 'Online',
    description: 'Remote configuration online',
    id: 'deviceRCStatus.online',
  },
  deviceRCStatusUnknown: {
    defaultMessage: 'Unknown',
    description: 'Remote configuration unknown',
    id: 'deviceRCStatus.unknown',
  },
  deviceRCStatusUnsupportedFirmware: {
    defaultMessage: 'Unsupported firmware',
    description: 'Remote configuration unsupported firmware',
    id: 'deviceRCStatus.unsupportedFirmware',
  },
});
