import { Switch, Tooltip } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FE_ROLES } from '../../config/consts';
import { isAdmin } from '../../helpers/user';
import { IUser } from '../../modules/user/store';
import { getSwitchTooltipMessage } from './switchTooltipMessage';

interface IApartmentAdminSwitch {
  onGrantApartmentAdminRights: () => void;
  onRevokeApartmentAdminRights: () => void;
  selectedUser: IUser;
  setSelectedUser: (user: IUser) => void;
}

function ApartmentAdminSwitch({
  onGrantApartmentAdminRights,
  onRevokeApartmentAdminRights,
  selectedUser,
  setSelectedUser,
}: IApartmentAdminSwitch) {
  const isUserAdmin = isAdmin(selectedUser.role);
  const isChecked = isUserAdmin || selectedUser.admin;

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.checked ? onGrantApartmentAdminRights() : onRevokeApartmentAdminRights();
    setSelectedUser(selectedUser);
  };

  return (
    <Tooltip
      title={
        <FormattedMessage
          {...getSwitchTooltipMessage({ isSwitchChecked: !!isChecked, userRole: selectedUser.role })}
          values={{ br: <br /> }}
        />
      }
      placement="right-start"
    >
      <span>
        <Switch
          checked={isChecked}
          disabled={
            isUserAdmin ||
            selectedUser.role === FE_ROLES.LOGIN_LESS ||
            selectedUser.role === FE_ROLES.GUEST ||
            selectedUser.role === FE_ROLES.APARTMENT_GUEST
          }
          onChange={(event) => onChange(event)}
        />
      </span>
    </Tooltip>
  );
}

export default ApartmentAdminSwitch;
