import { defineMessages } from 'react-intl';

export default defineMessages({
  servicePanelButtonDisable: {
    defaultMessage: 'Deactivate service',
    description: 'Disable button',
    id: 'servicePanel.button.disable',
  },
  servicePanelButtonDisableShort: {
    defaultMessage: 'Deactivate',
    description: 'Disable button short',
    id: 'servicePanel.button.disableShort',
  },
  servicePanelButtonEnable: {
    defaultMessage: 'Activate service',
    description: 'Enable button',
    id: 'servicePanel.button.enable',
  },
  servicePanelButtonEnableShort: {
    defaultMessage: 'Activate',
    description: 'Enable button short',
    id: 'servicePanel.button.enableShort',
  },
  servicePanelDialogButtonTextActivate: {
    defaultMessage: 'Activate',
    description: 'Button text activate',
    id: 'servicePanel.dialog.buttonTextActivate',
  },
  servicePanelDialogButtonTextDeactivate: {
    defaultMessage: 'Deactivate',
    description: 'Button text deactivate',
    id: 'servicePanel.dialog.buttonTextDeactivate',
  },
  servicePanelStateDisabled: {
    defaultMessage: 'Disabled',
    description: 'State disabled',
    id: 'servicePanel.state.disabled',
  },
  servicePanelStateUnavailable: {
    defaultMessage: 'Unavailable',
    description: 'State unavailable',
    id: 'servicePanel.state.unavailable',
  },
});
