import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { ElIntBuilding } from '../../../apiRtk/be4feApi';
import { DeviceStopDto } from '../../../apiRtk/elevatorIntegrationApi';
import { ElevatorGroupSelectItem } from '../../../containers/SiteSettings/ElevatorIntegration/FloorConfiguration/FloorConfiguration';
import { getDeviceStops } from '../helpers/helpers';
import SettingsFormSelects from '../SettingsFormSelects';
import SettingsFormSlider from '../SettingsFormSlider';
import messages from './messages';

interface IDeviceElevatorIntegrationItem {
  deviceStopIndex: number;
  setDeviceStops: React.Dispatch<React.SetStateAction<DeviceStopDto[]>>;
  deviceStops: DeviceStopDto[];
  updateDeviceStopsFormData: (deviceStops: DeviceStopDto[]) => void;
  buildingTopologyBuildings: ElIntBuilding[];
  elevatorGroupSelectItems: ElevatorGroupSelectItem[];
}

function DeviceElevatorIntegrationItem({
  buildingTopologyBuildings,
  deviceStopIndex,
  deviceStops,
  elevatorGroupSelectItems,
  setDeviceStops,
  updateDeviceStopsFormData,
}: IDeviceElevatorIntegrationItem) {
  const { watch } = useFormContext();

  useEffect(() => {
    watch((value) => {
      setDeviceStops(getDeviceStops(value));
    });
  }, [watch]);

  function deleteDeviceStop(deviceStopIndex: number) {
    const newDeviceStops = deviceStops.filter((_deviceStop, index) => {
      return index !== deviceStopIndex;
    });
    setDeviceStops(newDeviceStops);
    updateDeviceStopsFormData(newDeviceStops);
  }

  return (
    <>
      <SettingsFormSelects
        buildingTopologyBuildings={buildingTopologyBuildings}
        elevatorGroupSelectItems={elevatorGroupSelectItems}
        deviceStop={deviceStops[deviceStopIndex]}
        deviceStopIndex={deviceStopIndex}
      />
      <SettingsFormSlider deviceStopIndex={deviceStopIndex} />
      <Box>
        <Button fullWidth={false} onClick={() => deleteDeviceStop(deviceStopIndex)} variant="text" color="error">
          <FormattedMessage {...messages.deleteConfigurationButton} />
        </Button>
      </Box>
      {deviceStopIndex + 1 !== deviceStops.length && <Divider />}
    </>
  );
}

export default DeviceElevatorIntegrationItem;
