import CheckCircle from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CustomDate } from '../../../components';
import messages from './messages';

const itemStyle = {
  '& strong': {
    ml: 1,
  },
  '& svg': {
    fill: (theme) => theme.palette.success.main,
    mr: 0.5,
  },
  '&:not(:last-of-type):after': {
    content: '"|"',
    px: 2,
    py: 0,
  },
};

const DocumentDateInfo = ({ agreeDocument, effective, posted }) => (
  <Box color="secondary.main" display="flex">
    <Box alignItems="center" display="flex" sx={itemStyle}>
      <FormattedMessage
        {...messages.documentDateInfoPosted}
        values={{
          date: <CustomDate dateValue={posted} isLegalDocument />,
          strong: (chunk) => <strong>{chunk}</strong>,
        }}
      />
    </Box>
    <Box alignItems="center" display="flex" sx={itemStyle}>
      <FormattedMessage
        {...messages.documentDateInfoEffective}
        values={{
          date: <CustomDate dateValue={effective} isLegalDocument />,
          strong: (chunk) => <strong>{chunk}</strong>,
        }}
      />
    </Box>
    {agreeDocument && (
      <Box alignItems="center" display="flex" sx={itemStyle}>
        <CheckCircle />
        <FormattedMessage
          {...messages.documentDateInfoConfirmed}
          values={{
            date: <CustomDate dateValue={agreeDocument?.dateConfirmed} isLegalDocument />,
            strong: (chunk) => <strong>{chunk}</strong>,
          }}
        />
      </Box>
    )}
  </Box>
);

DocumentDateInfo.propTypes = {
  agreeDocument: PropTypes.shape({
    dateConfirmed: PropTypes.string,
  }),
  effective: PropTypes.string.isRequired,
  posted: PropTypes.string.isRequired,
};

DocumentDateInfo.defaultProps = {
  agreeDocument: null,
};

export default DocumentDateInfo;
