import { IntlFormatters } from 'react-intl';
import * as yup from 'yup';
import { getOryPasswordValidationScheme } from '../../../../validations/oryPasswordValidationSchema';

export interface INewPasswordFormData {
  password: string;
}

export enum FormFieldNames {
  Password = 'password',
}

export const defaultValues: INewPasswordFormData = {
  password: '',
};

export function getNewPasswordFormValidationSchema(formatMessage: IntlFormatters['formatMessage']) {
  return yup.object().shape({
    [FormFieldNames.Password]: getOryPasswordValidationScheme(formatMessage),
  });
}
