import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import CellBox from './CellBox';
import messages from './messages';

function CellValidator({ hideTooltip, isValueValid, value }) {
  function getValue() {
    if (!isValueValid) {
      return (
        <CellBox color={'error.main'} hideTooltip={hideTooltip}>
          {value}
        </CellBox>
      );
    }

    return (
      <CellBox
        hideTooltip={hideTooltip}
        sx={{
          display: 'block',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {value}
      </CellBox>
    );
  }

  return value ? (
    getValue()
  ) : (
    <CellBox color="error.main" hideTooltip={hideTooltip}>
      <FormattedMessage {...messages.siteUsersImportCheckStepDataGridCellEmpty} />
    </CellBox>
  );
}

CellValidator.propTypes = {
  hideTooltip: PropTypes.bool,
  isValueValid: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

CellValidator.defaultProps = {
  hideTooltip: false,
};

export default memo(CellValidator);
