import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonLink } from '../../components';
import { PATHS } from '../../config/consts';
import messages from './messages';

const styles = {
  button: {
    mt: 4,
    px: 6,
    py: 1,
  },
  root: {
    backgroundColor: 'landingPage.background',
    minHeight: '360px',
    px: 1.25,
    py: 8.75,
    textAlign: 'center',
  },
  title: {
    color: 'text.primary',
    display: 'block',
    fontSize: {
      lg: '44px',
      md: '40px',
      sm: '36px',
      xs: '32px',
    },
    fontWeight: 'regular',
    lineHeight: 1.1,
    pb: 2,
  },
  wrapper: {
    margin: '0 auto',
    maxWidth: '1050px',
    width: '100%',
  },
};

const ServiceFooter = () => (
  <Box sx={styles.root}>
    <Box sx={styles.wrapper}>
      <Typography sx={styles.title} variant="h3">
        <FormattedMessage
          values={{
            br: <br />,
            nbsp: <>&nbsp;</>,
            sup: (chunks) => <sup>{chunks}</sup>,
          }}
          {...messages.rcLandingPageServiceFooterTitle}
        />
      </Typography>
      <ButtonLink color="primary" size="large" sx={styles.button} to={PATHS.REGISTER} variant="contained">
        <FormattedMessage {...messages.rcLandingPageCreateAccount} />
      </ButtonLink>
    </Box>
  </Box>
);

export default ServiceFooter;
