import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const BackButton = ({ onClick }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Button color="secondary" onClick={onClick} startIcon={<ArrowBackIcon />}>
      {matches ? (
        <FormattedMessage {...messages.backButtonLabelFull} />
      ) : (
        <FormattedMessage {...messages.backButtonLabelShort} />
      )}
    </Button>
  );
};

BackButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default BackButton;
