import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

export interface IReadOnlyFieldProps {
  label: React.ReactNode;
  value: string | number | React.ReactElement;
}

export function ReadOnlyField({ label, value }: IReadOnlyFieldProps) {
  return (
    <>
      <Box display="block">
        <Typography variant="caption">
          <Box color="secondary.main" fontWeight="bold">
            {label}
          </Box>
        </Typography>
      </Box>
      <Box display="block">
        <Typography>{value}</Typography>
      </Box>
    </>
  );
}

export default ReadOnlyField;
