import { defineMessages } from 'react-intl';

export default defineMessages({
  cancelButton: {
    defaultMessage: 'Cancel',
    description: 'Device Elevator integration settings - cancel button label',
    id: 'DeviceElevatorIntegrationModal.cancelButton',
  },
  confirmButton: {
    defaultMessage: 'Save settings',
    description: 'Device Elevator integration settings - confirm button label',
    id: 'DeviceElevatorIntegrationModal.confirmButton',
  },
  destinationSelectItem: {
    defaultMessage: '<typography>{shortName}</typography> {destinationArea}',
    description: 'Destination select item text',
    id: 'destinationSelectSelectItem',
  },
  distanceLong: {
    defaultMessage: 'Long <box>(15 s)</box>',
    description: 'Device Elevator integration settings modal - form - walking time - long label',
    id: 'DeviceElevatorIntegrationModal.settingsForm.distanceLong',
  },
  distanceShort: {
    defaultMessage: 'Short <box>(5 s)</box>',
    description: 'Device Elevator integration settings modal - form - walking time - short label',
    id: 'DeviceElevatorIntegrationModal.settingsForm.distanceShort',
  },
  distanceVeryLong: {
    defaultMessage: 'Very long <box>(30 s)</box>',
    description: 'Device Elevator integration settings modal - form - walking time - very long label',
    id: 'DeviceElevatorIntegrationModal.settingsForm.distanceVeryLong',
  },
  distanceVeryShort: {
    defaultMessage: 'Very short <box>(0 s)</box>',
    description: 'Device Elevator integration settings modal - form - walking time - very short label',
    id: 'DeviceElevatorIntegrationModal.settingsForm.distanceVeryShort',
  },
  modalTitle: {
    defaultMessage: 'Set elevator integration',
    description: 'Device Elevator integration settings modal - title',
    id: 'DeviceElevatorIntegrationModal.modalTitle',
  },
  newConfigurationButton: {
    defaultMessage: 'New configuration',
    description: 'Device Elevator integration settings - new configuration button label',
    id: 'DeviceElevatorIntegrationModal.newConfigurationButton',
  },
  settingsFormAreaLabel: {
    defaultMessage: 'Device landing destination (area_id)',
    description: 'Device Elevator integration settings modal - form - floor label',
    id: 'DeviceElevatorIntegrationModal.settingsForm.floorLabel',
  },
  settingsFormAreaPlaceholder: {
    defaultMessage: 'Select area',
    description: 'Device Elevator integration settings modal - form - area placeholder',
    id: 'DeviceElevatorIntegrationModal.settingsForm.floorPlaceholder',
  },
  settingsFormBuildingElevatorGroupPlaceholder: {
    defaultMessage: 'Select elevator core',
    description: 'Device Elevator integration settings modal - form - building elevator group placeholder',
    id: 'settingsFormBuildingElevatorGroupPlaceholder',
  },
  settingsFormCoreLabel: {
    defaultMessage: 'Connected elevator core (buildingId, group name)',
    description: 'Device Elevator integration settings modal - form - core label',
    id: 'DeviceElevatorIntegrationModal.settingsForm.coreLabel',
  },
  settingsFormDestinationCall: {
    defaultMessage: 'Destination call',
    description: 'Device Elevator integration settings modal - form - destination call',
    id: 'DeviceElevatorIntegrationModal.settingsForm.destinationCall',
  },
  settingsFormDistanceLabel: {
    defaultMessage: 'Distance to elevator:',
    description: 'Device Elevator integration settings modal - form - distance label',
    id: 'DeviceElevatorIntegrationModal.settingsForm.distanceLabel',
  },
  settingsFormLandingCall: {
    defaultMessage: 'Landing call',
    description: 'Device Elevator integration settings modal - form - landing call',
    id: 'DeviceElevatorIntegrationModal.settingsForm.landingCall',
  },
});
