import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../../messages';

const LicenseBoughtInfo = ({ licenses, onShowDialog }) => (
  <>
    <Typography>
      <FormattedMessage
        {...messages.alreadyBoughtLicenseHeaderWithInfo}
        values={{
          link: (chunks) => (
            <Link component="button" onClick={onShowDialog} variant="body2">
              {chunks}
            </Link>
          ),
        }}
      />
    </Typography>
    {licenses.map((license) => (
      <Box alignItems="center" display="flex" key={license}>
        <CheckIcon sx={{ color: 'action.disabled', mr: 1 }} />
        <Typography
          sx={{
            color: 'action.disabled',
            typography: {
              md: 'body1',
              xs: 'body2',
            },
          }}
        >
          <FormattedMessage values={{ sup: (chunks) => <sup>{chunks}</sup> }} {...licenceSelectionTypeMap[license]} />
        </Typography>
      </Box>
    ))}
  </>
);

LicenseBoughtInfo.propTypes = {
  licenses: PropTypes.arrayOf(PropTypes.string).isRequired,
  onShowDialog: PropTypes.func.isRequired,
};

export default LicenseBoughtInfo;

const licenceSelectionTypeMap = {
  LICENCE_ACCESS_UNIT_LIFT_MODULE: messages.licenseSelectionTypeLICENCE_ACCESS_UNIT_LIFT_MODULE,
  LICENCE_ENHANCED_AUDIO: messages.licenseSelectionTypeLICENCE_ENHANCED_AUDIO,
  LICENCE_ENHANCED_INTEGRATION: messages.licenseSelectionTypeLICENCE_ENHANCED_INTEGRATION,
  LICENCE_ENHANCED_SECURITY: messages.licenseSelectionTypeLICENCE_ENHANCED_SECURITY,
  LICENCE_ENHANCED_VIDEO: messages.licenseSelectionTypeLICENCE_ENHANCED_VIDEO,
  LICENCE_GOLD: messages.licenseSelectionTypeLICENCE_GOLD,
  LICENCE_INFORMCAST: messages.licenseSelectionTypeLICENCE_INFORMCAST,
  LICENCE_INTERCOM_LIFT_MODULE: messages.licenseSelectionTypeLICENCE_INTERCOM_LIFT_MODULE,
  LICENCE_NFC: messages.licenseSelectionTypeLICENCE_NFC,
  LICENCE_NFC_ACCESS_UNIT: messages.licenseSelectionTypeLICENCE_NFC_ACCESS_UNIT,
};
