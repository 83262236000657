import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { DeviceModelName, Price, SectionHeader } from '../../../../components';
import subscriptionMessages from '../../messages';
import DeleteOrderModal from './DeleteOrderModal';
import messages from './messages';

const styles = {
  deleteIcon: {
    pt: {
      sm: 0,
      xs: 1,
    },
  },
  deviceType: {
    color: 'text.secondary',
    pt: 2,
  },
  license: {
    pl: {
      md: 8,
      xs: 2,
    },
    pt: {
      md: 0,
      xs: 2,
    },
  },
};

const OrderList = ({ chargeVat, currency, deviceLicenseOrders, onDeleteOrderLicense, snLicenseData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [deleteOrderId, setDeleteOrderId] = useState(null);

  const handleDeleteOrder = () => {
    onDeleteOrderLicense(deleteOrderId);
    setIsOpen(false);
  };

  const handleOpenDialog = (orderId) => () => {
    setDeleteOrderId(orderId);
    setIsOpen(true);
  };

  const getDeviceType = (sn) => snLicenseData.serialNumbers.find((item) => item.serialNumber === sn)?.deviceType;

  return (
    <>
      <DeleteOrderModal onClose={() => setIsOpen(false)} onDelete={handleDeleteOrder} open={isOpen} />
      <SectionHeader headerText={<FormattedMessage {...messages.orderListHeader} />} variant="h6" />
      {deviceLicenseOrders.map((order) => (
        <Grid container direction="column" key={order.serialNumber}>
          <Grid item>
            <Typography sx={styles.deviceType}>
              {order.serialNumber}
              {' - '}
              <DeviceModelName deviceType={getDeviceType(order.serialNumber)} />
            </Typography>
          </Grid>
          {order.orders.map((item) => (
            <Grid alignItems="center" container item justifyContent="flex-start" key={item.id}>
              <Grid item lg={8} xs={12}>
                <Typography sx={styles.license}>
                  <FormattedMessage
                    values={{
                      sup: (chunks) => <sup>{chunks}</sup>,
                    }}
                    {...licenceSelectionTypeMap[item.product]}
                  />
                </Typography>
              </Grid>
              <Grid item lg={3} xs={11}>
                <Box pl={1.5}>
                  <Price
                    currency={currency}
                    fullPrice={item.unitFullPrice}
                    fullPriceVat={item.unitFullPriceVat}
                    isVat={chargeVat}
                  />
                </Box>
              </Grid>
              <Grid item lg={1} sx={styles.deleteIcon} xs={1}>
                <Tooltip title={<FormattedMessage {...messages.twoStepDeleteButtonTooltip} />}>
                  <IconButton onClick={handleOpenDialog(item.id)}>
                    <DeleteIcon color="secondary" />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          ))}
        </Grid>
      ))}
      {deviceLicenseOrders.length > 0 && (
        <Box pb={2}>
          <Divider />
        </Box>
      )}
    </>
  );
};

OrderList.propTypes = {
  chargeVat: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired,
  deviceLicenseOrders: PropTypes.arrayOf(
    PropTypes.shape({
      orders: PropTypes.arrayOf(
        PropTypes.shape({
          chargeVat: PropTypes.bool,
          currency: PropTypes.string,
          unitFullPrice: PropTypes.number,
          unitFullPriceVat: PropTypes.number,
        })
      ),
      serialNumber: PropTypes.string,
      type: PropTypes.string,
    })
  ).isRequired,
  onDeleteOrderLicense: PropTypes.func.isRequired,
  snLicenseData: PropTypes.shape({
    serialNumbers: PropTypes.arrayOf(
      PropTypes.shape({
        deviceType: PropTypes.string,
        serialNumber: PropTypes.string,
      })
    ),
  }).isRequired,
};

export default OrderList;

const licenceSelectionTypeMap = {
  LICENCE_ACCESS_UNIT_LIFT_MODULE: subscriptionMessages.licenseSelectionTypeLICENCE_ACCESS_UNIT_LIFT_MODULE,
  LICENCE_ENHANCED_AUDIO: subscriptionMessages.licenseSelectionTypeLICENCE_ENHANCED_AUDIO,
  LICENCE_ENHANCED_INTEGRATION: subscriptionMessages.licenseSelectionTypeLICENCE_ENHANCED_INTEGRATION,
  LICENCE_ENHANCED_SECURITY: subscriptionMessages.licenseSelectionTypeLICENCE_ENHANCED_SECURITY,
  LICENCE_ENHANCED_VIDEO: subscriptionMessages.licenseSelectionTypeLICENCE_ENHANCED_VIDEO,
  LICENCE_GOLD: subscriptionMessages.licenseSelectionTypeLICENCE_GOLD,
  LICENCE_INFORMCAST: subscriptionMessages.licenseSelectionTypeLICENCE_INFORMCAST,
  LICENCE_INTERCOM_LIFT_MODULE: subscriptionMessages.licenseSelectionTypeLICENCE_INTERCOM_LIFT_MODULE,
  LICENCE_NFC: subscriptionMessages.licenseSelectionTypeLICENCE_NFC,
  LICENCE_NFC_ACCESS_UNIT: subscriptionMessages.licenseSelectionTypeLICENCE_NFC_ACCESS_UNIT,
};
