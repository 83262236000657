import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import phoneImage from '../../assets/MVphoto.png';
import { useUrlParams } from '../../helpers/urlParams';
import { selectors as commonSelectors } from '../../modules/common';
import actionsPayments from '../../modules/payments';
import { selectors as siteSelectors } from '../../modules/sites';
import subscriptionActions from '../../modules/subscription';
import DashboardCardHeader from '../DashboardCardHeader';
import messages from './messages';
import MobileVideoAction from './MobileVideoAction';
import MobileVideoChip from './MobileVideoChip';
import StatusInfo from './StatusInfo';

const styles = (isSmall, theme) => ({
  actionWrapper: {
    marginTop: 2.75,
  },
  chipWrapper: {
    width: 'fit-content',
  },
  collapsedBackground: {
    backgroundPosition: 'right',
  },
  content: {
    [theme.breakpoints.up('sm')]: {
      minHeight: '208px',
    },
    [theme.breakpoints.up('lg')]: {
      backgroundImage: !isSmall ? `url(${phoneImage})` : 'none',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    },
    padding: theme.spacing(2, 3, 3, 3),
    width: '100%',
  },
  info: {
    [theme.breakpoints.up('sm')]: {
      marginTop: 1.25,
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    position: 'relative',
  },
  notCollapsedBackground: {
    [theme.breakpoints.between('lg', '1500')]: {
      backgroundPosition: '110%',
    },
    backgroundPosition: 'right',
  },
  root: {
    height: '100%',
    width: '100%',
  },
});

const MobileVideoCard = ({
  devices,
  isSidebarCollapsed,
  licenceModel,
  mvService,
  my2nId,
  onActivateService,
  onOpenAvailableCreditModal,
  onStartTrial,
  small,
}) => {
  const { companyId, siteId } = useUrlParams();
  return (
    <Card sx={(theme) => styles(small, theme).root}>
      <DashboardCardHeader
        action={
          <Tooltip title={<FormattedMessage {...messages.mobileVideoCardMy2nIdTooltip} />}>
            <Typography color="secondary" component="span" sx={{ cursor: 'pointer' }} variant="body2">
              <FormattedMessage {...messages.mobileVideoCardMy2nId} values={{ my2nId }} />
            </Typography>
          </Tooltip>
        }
        title={<FormattedMessage {...messages.mobileVideoCardMobileVideo} />}
      />
      <Grid
        container
        sx={(theme) => ({
          ...styles(small, theme).content,
          ...(isSidebarCollapsed
            ? styles(small, theme).collapsedBackground
            : styles(small, theme).notCollapsedBackground),
        })}
      >
        <StatusInfo devices={devices} licenceModel={licenceModel} mvService={mvService} />
        <Grid container item sx={(theme) => styles(small, theme).info}>
          <Box sx={(theme) => styles(small, theme).chipWrapper}>
            <MobileVideoChip devices={devices} licenceModel={licenceModel} mvService={mvService} />
          </Box>
          <Box sx={(theme) => styles(small, theme).actionWrapper}>
            <MobileVideoAction
              companyId={companyId}
              devices={devices}
              licenceModel={licenceModel}
              mvService={mvService}
              onActivateService={onActivateService}
              onOpenAvailableCreditModal={onOpenAvailableCreditModal}
              onStartTrial={onStartTrial}
              siteId={siteId}
            />
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

MobileVideoCard.propTypes = {
  devices: PropTypes.arrayOf(PropTypes.object).isRequired,
  isSidebarCollapsed: PropTypes.bool.isRequired,
  licenceModel: PropTypes.shape({
    additional: PropTypes.number,
    licencesPool: PropTypes.number,
    status: PropTypes.string,
    unlicensedCount: PropTypes.number,
    validityTo: PropTypes.string,
  }).isRequired,
  mvService: PropTypes.shape({
    subscribed: PropTypes.bool,
  }).isRequired,
  my2nId: PropTypes.string.isRequired,
  onActivateService: PropTypes.func.isRequired,
  onOpenAvailableCreditModal: PropTypes.func.isRequired,
  onStartTrial: PropTypes.func.isRequired,
  small: PropTypes.bool,
};

MobileVideoCard.defaultProps = {
  small: false,
};

const mapStateToProps = (state) => ({
  devices: siteSelectors.getDashboardMvServiceDevices(state),
  isSidebarCollapsed: commonSelectors.getIsSidebarCollapsed(state),
  licenceModel: siteSelectors.getDashboardMvServiceLicenceModel(state),
  mvService: siteSelectors.getDashboardMvService(state),
  my2nId: siteSelectors.getMy2nId(state),
});

const mapDispatchToProps = {
  onActivateService: subscriptionActions.activateMobileVideoLicence,
  onStartTrial: actionsPayments.startTrialVersion,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileVideoCard);
