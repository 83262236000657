import GetAppIcon from '@mui/icons-material/GetApp';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { DataGridSearchField, PaymentsTable, Title } from '../../components';
import { PAYMENT_CONTEXT } from '../../config/payments';
import { changeFilter, dataGridProps, getDataGridSelector } from '../../helpers/dataGrid';
import { useUrlParams } from '../../helpers/urlParams';
import commonActions from '../../modules/common';
import actions, { NAME as PAYMENTS_MODULE, selectors } from '../../modules/payments';
import messages from './messages';

const PaymentsOverview = ({
  isPaymentDetailLoading,
  isPromoDetailModalOpen,
  loadPromoPaymentData,
  onClosePromoDetailModal,
  onLoadPayments,
  onOpenPromoDetailModal,
  onSearch,
  paymentDetailData,
  paymentsTable,
  promoDetailModalData,
}) => {
  const { companyId } = useUrlParams();

  const invoiceButton = (link) => (
    <Link component="a" href={link} rel="noreferrer noopener" target="_blank">
      <Button color="primary" startIcon={<GetAppIcon />} variant="outlined">
        <FormattedMessage {...messages.paymentsOverviewInvoice} />
      </Button>
    </Link>
  );

  const showInvoice = (state) => ['INVOICE_ISSUED', 'BEFORE_COMPLETION', 'COMPLETED'].includes(state);
  const [fulltext, setFulltext] = useState(paymentsTable.filter.fulltext || '');

  const onChangeFilter = ({ target }) => {
    const { name, value } = target;
    setFulltext(value);
    changeFilter(onSearch, actions.getPaymentsList, {
      ...paymentsTable,
      companyId,
      context: PAYMENT_CONTEXT.COMPANY_PAYMENTS,
      emptySite: true,
      page: 1,
    })(name, value);
  };

  return (
    <>
      <Title
        heading={<FormattedMessage {...messages.paymentsOverviewTitle} />}
        rightSide={
          paymentsTable.resultsTotal > 0 && <DataGridSearchField fulltext={fulltext} onChangeFilter={onChangeFilter} />
        }
      />
      <PaymentsTable
        {...paymentsTable}
        action={(payment) => <div>{showInvoice(payment.purchaseState) && invoiceButton(payment.link)}</div>}
        companyId={companyId}
        context={PAYMENT_CONTEXT.COMPANY_PAYMENTS}
        isPaymentDetailLoading={isPaymentDetailLoading}
        isPromoDetailModalOpen={isPromoDetailModalOpen}
        loadPromoPaymentData={loadPromoPaymentData}
        onClosePromoDetailModal={onClosePromoDetailModal}
        onLoadPayments={onLoadPayments}
        onOpenPromoDetailModal={onOpenPromoDetailModal}
        paymentDetailData={paymentDetailData}
        promoDetailModalData={promoDetailModalData}
        showLicenseKeys
      />
    </>
  );
};

PaymentsOverview.propTypes = {
  isPaymentDetailLoading: PropTypes.bool.isRequired,
  isPromoDetailModalOpen: PropTypes.bool.isRequired,
  loadPromoPaymentData: PropTypes.func.isRequired,
  onClosePromoDetailModal: PropTypes.func.isRequired,
  onLoadPayments: PropTypes.func.isRequired,
  onOpenPromoDetailModal: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  paymentDetailData: PropTypes.shape({}).isRequired,
  paymentsTable: dataGridProps.isRequired,
  promoDetailModalData: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  isPaymentDetailLoading: selectors.isPaymentDetailLoading(state),
  isPromoDetailModalOpen: selectors.isPromoDetailModalOpen(state),
  paymentDetailData: selectors.getPaymentDetailData(state),
  paymentsTable: getDataGridSelector(state, PAYMENTS_MODULE, 'list'),
  promoDetailModalData: selectors.getPromoDetailModalData(state),
});

const mapDispatchToProps = {
  loadPromoPaymentData: actions.getPaymentPromoDetail,
  onClosePromoDetailModal: actions.closePromoDetailModal,
  onLoadPayments: actions.getPaymentsList,
  onOpenPromoDetailModal: actions.openPromoDetailModal,
  onSearch: commonActions.search,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsOverview);
