import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import * as Api from '../../api/companyApi';
import * as deviceApi from '../../api/deviceApi';
import { PATHS } from '../../config/consts';
import { errorHelper, handleErrorLocation, responseValidateStatusOrThrow } from '../helpers';
import { types as infraTypes } from '../infrastructure/types';
import { addSnackbarSuccess } from '../notifications/actions';
import { invalidateCurrentUser } from '../user/actionsTS';
import actions, { selectors, types } from './index';
import messages from './messages';

export function* loadCompany({ companyId }) {
  try {
    const response = yield call(Api.getCompany, companyId);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({ payload: data, type: types.LOAD_COMPANY_SUCCESS });
    yield put(actions.getCompanyDeviceTypes(companyId));
  } catch (error) {
    yield put(errorHelper(types.LOAD_COMPANY_FAILURE, error));
    if (handleErrorLocation(error?.status)) {
      yield put({
        payload: {
          locationSubRoutesCheck: ['/companies'],
        },
        type: infraTypes.SET_REDIRECT_DATA,
      });
    }
  }
}

export function* addCompany({ values }) {
  try {
    const response = yield call(Api.addCompany, values);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({ payload: data, type: types.ADD_COMPANY_SUCCESS });
    yield put(addSnackbarSuccess(messages.addCompanySnackbarCompanyAddedSuccessfully));
    yield put({
      payload: {
        action: [invalidateCurrentUser()],
        to: PATHS.COMPANY_DASHBOARD.replace(':companyId', data.id),
      },
      type: infraTypes.SET_REDIRECT_DATA,
    });
  } catch (error) {
    yield put(errorHelper(types.ADD_COMPANY_FAILURE, error));
  }
}

export function* deleteCompany({ company }) {
  try {
    const response = yield call(Api.deleteCompany, company.id);
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.DELETE_COMPANY_SUCCESS });
    yield put(addSnackbarSuccess(messages.companySnackbarDeletedSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.DELETE_COMPANY_FAILURE, error));
  }
}

export function* addCompanyRole({ company, role }) {
  try {
    const roleFormatted = `"${role}"`;
    const response = yield call(Api.addRole, company.id, roleFormatted);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({ payload: data, type: types.ADD_COMPANY_ROLE_SUCCESS });
    yield put(addSnackbarSuccess(messages.companySnackbarSettingsSavedSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.ADD_COMPANY_ROLE_FAILURE, error));
  }
}

export function* deleteCompanyRole({ company, role }) {
  try {
    const response = yield call(Api.deleteRole, company.id, role);
    yield responseValidateStatusOrThrow(response);
    yield put({ role, type: types.DELETE_COMPANY_ROLE_SUCCESS });
    yield put(addSnackbarSuccess(messages.companySnackbarSettingsSavedSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.DELETE_COMPANY_ROLE_FAILURE, error));
  }
}

export function* changeCompanySettings({ company, values }) {
  try {
    const response = yield call(Api.changeSettings, company.id, values);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({ payload: data, type: types.CHANGE_SETTINGS_SUCCESS });
    yield put(addSnackbarSuccess(messages.companySnackbarSettingsSavedSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.CHANGE_SETTINGS_FAILURE, error));
  }
}

export function* getMvSdkSettings() {
  let response;
  try {
    const companyId = yield select(selectors.getCompanyId);
    response = yield call(Api.getMvSdk, companyId);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({ payload: data, type: types.GET_MV_SDK_SETTINGS_SUCCESS });
  } catch (error) {
    if (response.status === 404) {
      yield put({ type: types.RESET_MV_SDK_SETTINGS_SUCCESS });
    } else {
      yield put(errorHelper(types.GET_MV_SDK_SETTINGS_FAILURE, error));
    }
  }
}

export function* updateMvSdkSettings({ formActions, values }) {
  try {
    const companyId = yield select(selectors.getCompanyId);
    const response = yield call(Api.updateMvSdk, companyId, values);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put(addSnackbarSuccess(messages.companySnackbarMVSDKSettingsSavedSuccessfully));
    yield put({ payload: data, type: types.UPDATE_MV_SDK_SETTINGS_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.UPDATE_MV_SDK_SETTINGS_FAILURE, error));
  } finally {
    if (formActions && typeof formActions.setFieldValue === 'function') {
      formActions.setFieldValue('authKey', '');
      formActions.setFieldValue('serviceJson', '');
    }
  }
}

export function* resetMvSdkSettings() {
  try {
    const companyId = yield select(selectors.getCompanyId);
    const response = yield call(Api.resetMvSdk, companyId);
    yield responseValidateStatusOrThrow(response);
    yield put(addSnackbarSuccess(messages.companySnackbarMVSDKSettingsRemovedSuccessfully));
    yield put({ type: types.RESET_MV_SDK_SETTINGS_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.RESET_MV_SDK_SETTINGS_FAILURE, error));
  }
}

export function* getCompanyDeviceTypes({ companyId }) {
  try {
    const response = yield call(deviceApi.getDeviceTypesSettings, companyId);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({ payload: data, type: types.GET_COMPANY_DEVICE_TYPES_SUCCESS });
  } catch {
    yield put(errorHelper(types.GET_COMPANY_DEVICE_TYPES_FAILURE));
  }
}

export default function* companySagas() {
  yield all([
    takeLatest(types.ADD_COMPANY_REQUEST, addCompany),
    takeEvery(types.LOAD_COMPANY_REQUEST, loadCompany),
    takeEvery(types.DELETE_COMPANY_REQUEST, deleteCompany),
    takeEvery(types.ADD_COMPANY_ROLE_REQUEST, addCompanyRole),
    takeEvery(types.DELETE_COMPANY_ROLE_REQUEST, deleteCompanyRole),
    takeEvery(types.CHANGE_SETTINGS_REQUEST, changeCompanySettings),
    takeEvery(types.GET_MV_SDK_SETTINGS_REQUEST, getMvSdkSettings),
    takeEvery(types.UPDATE_MV_SDK_SETTINGS_REQUEST, updateMvSdkSettings),
    takeEvery(types.RESET_MV_SDK_SETTINGS_REQUEST, resetMvSdkSettings),
    takeEvery(types.GET_COMPANY_DEVICE_TYPES_REQUEST, getCompanyDeviceTypes),
  ]);
}
