import { PATHS } from '../../config/consts';
import { ICompanyRoles } from '../../hooks/rolesHooks';
import { Roles } from '../../modules/commonTypes';

export interface IUserData {
  lastVisitedCompanyId?: number | null;
  lastVisitedSiteId?: number | null;
  companies: ICompanyRoles[];
}

export function getRedirectPathForCurrentUser(userData: IUserData) {
  if (userData.lastVisitedSiteId !== undefined && userData.lastVisitedSiteId !== null) {
    const companyId = getAccessibleCompanyIdBySiteId(userData.companies, userData.lastVisitedSiteId);
    if (companyId !== undefined) {
      return PATHS.SITE_DASHBOARD.replace(':companyId', companyId.toString()).replace(
        ':siteId',
        userData.lastVisitedSiteId.toString()
      );
    }
  }

  if (
    userData.lastVisitedCompanyId !== undefined &&
    userData.lastVisitedCompanyId !== null &&
    canAccessCompany(userData.companies, userData.lastVisitedCompanyId)
  ) {
    return PATHS.COMPANY_DASHBOARD.replace(':companyId', userData.lastVisitedCompanyId.toString());
  }

  if (userData.companies.length > 0 && canAccessFirstCompany(userData.companies)) {
    if (canAccessFirstSite(userData.companies)) {
      return PATHS.SITE_DASHBOARD.replace(':companyId', userData.companies[0].id.toString()).replace(
        ':siteId',
        userData.companies[0].sites![0].id.toString()
      );
    } else {
      return PATHS.COMPANY_DASHBOARD.replace(':companyId', userData.companies[0].id.toString());
    }
  }

  if (isApartmentAdmin(userData.companies)) {
    return PATHS.USERS_IN_APARTMENTS;
  }

  return PATHS.USER_PROFILE;
}

function getAccessibleCompanyIdBySiteId(companies: ICompanyRoles[], siteId: number): number | undefined {
  const company = companies.find((company) => company.sites?.find((site) => site.id === siteId));
  const userRolesBySite = company?.sites?.find((site) => site.id === siteId);
  if (userRolesBySite?.roles.some((role) => role === Roles.ADMIN)) {
    return company?.id;
  }

  return undefined;
}

function canAccessCompany(companies: ICompanyRoles[], companyId: number): boolean {
  const companyRoles = companies.find((company) => company.id === companyId);

  return companyRoles?.roles.includes(Roles.COMPANY_ADMIN) ?? false;
}

function canAccessFirstCompany(companies: ICompanyRoles[]): boolean {
  return companies[0].roles.includes(Roles.COMPANY_ADMIN);
}

function canAccessFirstSite(companies: ICompanyRoles[]): boolean {
  return !!companies[0].sites?.[0]?.roles.some((role) => role === Roles.COMPANY_ADMIN || role === Roles.ADMIN);
}

function isApartmentAdmin(companies: ICompanyRoles[]): boolean {
  return !!companies?.find(
    (company) =>
      company?.sites?.find(
        (site) => site?.apartments?.find((apartment) => apartment?.roles?.includes(Roles.APARTMENT_ADMIN))
      )
  );
}
