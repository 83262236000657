import { defineMessages } from 'react-intl';

export default defineMessages({
  CompanySwitcherEmptyLabel: {
    defaultMessage: 'Select company...',
    description: 'Company switcher - empty label',
    id: 'CompanySwitcherEmptyLabel',
  },
  CompanySwitcherLabel: {
    defaultMessage: 'Company',
    description: 'Company switcher - company label',
    id: 'CompanySwitcherLabel',
  },
  SidebarAbout: {
    defaultMessage: 'About My2N',
    description: 'Sidebar - about',
    id: 'SidebarAbout',
  },
  SidebarApartmentListMyApartments: {
    defaultMessage: 'My apartments',
    description: 'Apartment list - my apartments',
    id: 'AA.sidebar.myApartments',
  },
  SidebarApartmentListMyProfile: {
    defaultMessage: 'My profile',
    description: 'Apartment list - my profile',
    id: 'AA.sidebar.myProfile',
  },
  SidebarCompanyListBillingInfo: {
    defaultMessage: 'Billing info',
    description: 'Company list - billing info',
    id: 'SidebarCompanyListBillingInfo',
  },
  SidebarCompanyListCompanyAdmins: {
    defaultMessage: 'Company admins',
    description: 'Company list - company admins',
    id: 'SidebarCompanyListCompanyAdmins',
  },
  SidebarCompanyListCompanyDashboard: {
    defaultMessage: 'Company dashboard',
    description: 'Company list - company dashboard',
    id: 'SidebarCompanyListCompanyDashboard',
  },
  SidebarCompanyListMvSdkSettings: {
    defaultMessage: 'SDK Settings',
    description: 'Company list - sdk settings',
    id: 'SidebarCompanyListMvSdkSettings',
  },
  SidebarCompanyListPaymentsOverview: {
    defaultMessage: 'Payments',
    description: 'Company list - payments',
    id: 'SidebarCompanyListPaymentsOverview',
  },
  SidebarCompanyListSites: {
    defaultMessage: 'Sites',
    description: 'Company list - sites',
    id: 'SidebarCompanyListSites',
  },
  SidebarHelp: {
    defaultMessage: 'Tech support',
    description: 'Sidebar - tech support',
    id: 'SidebarHelp',
  },
  SidebarLegalDocuments: {
    defaultMessage: 'Legal documents, privacy',
    description: 'Sidebar - legal documents',
    id: 'SidebarLegalDocuments',
  },
  SidebarSiteListAccessLog: {
    defaultMessage: 'Access log',
    description: 'Site list - access log',
    id: 'SidebarSiteListAccessLog',
  },
  SidebarSiteListAccessSettingsOverview: {
    defaultMessage: 'Access settings',
    description: 'Site list - access settings',
    id: 'SidebarSiteListAccessSettingsOverview',
  },
  SidebarSiteListApartments: {
    defaultMessage: 'Apartments',
    description: 'Site list - apartments',
    id: 'SidebarSiteListApartments',
  },
  SidebarSiteListCallSettings: {
    defaultMessage: 'Call settings',
    description: 'Site list - call settings',
    id: 'SidebarSiteListCallSettings',
  },
  SidebarSiteListDevices: {
    defaultMessage: 'Devices',
    description: 'Site list - devices',
    id: 'SidebarSiteListDevices',
  },
  SidebarSiteListPaymentsOverview: {
    defaultMessage: 'Site payments',
    description: 'Site list - site payments',
    id: 'SidebarSiteListPaymentsOverview',
  },
  SidebarSiteListSiteDashboard: {
    defaultMessage: 'Dashboard',
    description: 'Site list - site dashboard',
    id: 'SidebarSiteListSiteDashboard',
  },
  SidebarSiteListSiteLanguage: {
    defaultMessage: 'Site language',
    description: 'Site list - site language',
    id: 'SidebarSiteListSiteLanguage',
  },
  SidebarSiteListSiteSettings: {
    defaultMessage: 'Site settings',
    description: 'Site list - site settings',
    id: 'SidebarSiteListSiteSettings',
  },
  SidebarSiteListSubscription: {
    defaultMessage: 'Subscriptions, licenses',
    description: 'Site list - subscription',
    id: 'SidebarSiteListSubscription',
  },
  SidebarSiteListUsersAndAdmins: {
    defaultMessage: 'Site users',
    description: 'Site list - site users',
    id: 'SidebarSiteListUsersAndAdmins',
  },
  SiteSwitcherEmptyLabel: {
    defaultMessage: 'Select site...',
    description: 'Site switcher - empty label',
    id: 'SiteSwitcherEmptyLabel',
  },
  SiteSwitcherLabel: {
    defaultMessage: 'Site',
    description: 'Site switcher - site label',
    id: 'SiteSwitcherLabel',
  },
});
