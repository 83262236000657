import { Box, Stack } from '@mui/material';
import React, { Fragment, ReactElement, ReactNode } from 'react';
import { footerStyle } from './styles';

export function getTableFooter({
  footerActions,
  isFetching,
}: {
  isFetching?: boolean;
  footerActions: ReactNode[] | undefined;
}): ReactElement | null {
  if (!isFetching && footerActions && footerActions.length) {
    return (
      <Box sx={footerStyle} key="data-grid-footer">
        <Stack direction="row" spacing={2}>
          {footerActions.map((action, i) => (
            <Fragment key={i}>{action}</Fragment>
          ))}
        </Stack>
      </Box>
    );
  }
  return null;
}
