import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DeviceModelName, ReadOnlyField } from '../../../../components';
import messages from './messages';

const ModelNameField = ({ deviceType, type }) => (
  <Box pb={2}>
    <ReadOnlyField
      label={<FormattedMessage {...messages.modelNameFieldDeviceModelLabel} />}
      value={<DeviceModelName deviceType={deviceType} type={type} unknownType={false} />}
    />
  </Box>
);

ModelNameField.propTypes = {
  deviceType: PropTypes.string,
  type: PropTypes.string.isRequired,
};

ModelNameField.defaultProps = {
  deviceType: '',
};

export default ModelNameField;
