import TextFieldMaterial from '@mui/material/TextField';
import { getIn } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

const TextField = ({ error, field, form: { dirty, errors, touched }, helperText, inputProps, label, ...other }) => {
  const regExp = new RegExp(/(.*(\.).*)*/);
  const errorText = regExp.test(field.name) ? getIn(errors, field.name) : errors[field.name];
  const isTouched = regExp.test(field.name) ? getIn(touched, field.name) : touched[field.name];
  const hasError = dirty && isTouched && errorText !== undefined;

  return (
    <TextFieldMaterial
      error={error || hasError}
      helperText={hasError ? errorText : helperText}
      inputProps={inputProps}
      label={label}
      variant="outlined"
      {...field}
      {...other}
    />
  );
};

TextField.propTypes = {
  error: PropTypes.bool,
  field: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  form: PropTypes.shape({
    dirty: PropTypes.bool,
    errors: PropTypes.shape({}),
    touched: PropTypes.shape({}),
  }),
  fullWidth: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
  inputProps: PropTypes.shape({}),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  margin: PropTypes.oneOf(['none', 'dense', 'normal']),
};

TextField.defaultProps = {
  error: false,
  field: {
    name: '',
  },
  form: {
    dirty: false,
    errors: {},
    touched: {},
  },
  fullWidth: true,
  helperText: ' ',
  inputProps: {},
  label: undefined,
  margin: 'normal',
};

export default TextField;
