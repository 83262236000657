import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert } from '../../../../components';
import messages from './messages';

const VerificationIsExpiredAlert = () => (
  <Alert severity="warning" title={<FormattedMessage {...messages.codeVerificationWarningExpiredHeader} />}>
    <FormattedMessage {...messages.codeVerificationWarningExpiredText} />
  </Alert>
);

export default VerificationIsExpiredAlert;
