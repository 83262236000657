import { Skeleton } from '@mui/material';
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid';
import React, { ReactNode } from 'react';
import { MuiRow, renderAggregatedCell } from './aggregatedCellContentRenderer';
import { getRowMenuColumnDefinition } from './aggregatedRowMenuActions/rowMenuColumnDefinition';
import { DataGridAggregatedProps, IAggregatedColumn } from './data';
import { isColumnRendered } from './utils';

export const firstColumnId = 'aggregated-table-offset-column';

export function getAggregatedDataGridColumns<TAggregatedItem extends object, TItem extends object>(
  props: DataGridAggregatedProps<TAggregatedItem, TItem>
): GridColDef<MuiRow<TItem | TAggregatedItem>>[] {
  const columns: GridColDef<MuiRow<TItem | TAggregatedItem>>[] = props.columns
    .filter((column) => isColumnRendered(column.isRendered))
    .map((column, index) => {
      return {
        disableColumnMenu: true,
        field: index === 0 ? firstColumnId : index.toString(),
        flex: column.flex ?? 1,
        headerName: column.id,
        minWidth: column.minWidth,
        renderCell: (row: GridValidRowModel) => {
          return renderAggregatedDataGridCell({
            column,
            hasItems: !!props.items,
            isFetching: props.isFetching,
            row: row.row,
          });
        },
        sortable: false,
        type: '',
        width: column.width,
      };
    });

  if (props.contextMenu && !props.isFetching) {
    columns.push(getRowMenuColumnDefinition({ rowMenuActions: props.contextMenu }));
  }
  return columns;
}

export function renderAggregatedDataGridCell<TAggregatedItem extends object, TItem extends object>({
  column,
  hasItems,
  isFetching,
  row,
}: {
  row: MuiRow<TItem | TAggregatedItem>;
  isFetching?: boolean;
  hasItems: boolean;
  column: IAggregatedColumn<TAggregatedItem, TItem>;
}): ReactNode {
  if (isFetching || !hasItems) {
    return <Skeleton animation="wave" height={25} width="80%" />;
  }
  return renderAggregatedCell(row, column);
}
