import CloseIcon from '@mui/icons-material/Close';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const styles = {
  button: {
    color: 'primary.main',
    fontSize: 'small',
    px: 0.5,
  },
};

const ButtonScanQRCode = ({ onClick, open }) => (
  <Button
    color="primary"
    onMouseDown={onClick}
    size="small"
    startIcon={open ? <CloseIcon /> : <QrCode2Icon />}
    sx={styles.button}
    variant="link"
  >
    {open ? (
      <FormattedMessage {...messages.buttonScanQRCodeClose} />
    ) : (
      <FormattedMessage {...messages.buttonScanQRCodeScan} />
    )}
  </Button>
);

ButtonScanQRCode.propTypes = {
  onClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ButtonScanQRCode;
