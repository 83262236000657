import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import RowMenuItem from '../../DataGrid/RowMenuItem';
import messages from '../messages';

const ActivateTrial = ({ onStartTrial }) => (
  <>
    <RowMenuItem
      colorIcon="primary.main"
      colorText="primary.main"
      icon={<PlayCircleOutlineIcon />}
      label={<FormattedMessage {...messages.deviceDataGridActionDeviceRowMenuActivateTrial} />}
      onClick={onStartTrial}
    />
    <Divider />
  </>
);

ActivateTrial.propTypes = {
  onStartTrial: PropTypes.func.isRequired,
};

export default ActivateTrial;
