import { Alert } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ICodeData } from '../../../../../modules/subscription/store';
import messages from './messages';

interface ICreditSummaryAlert {
  codeData: ICodeData;
}

function CreditSummaryAlert({ codeData }: ICreditSummaryAlert) {
  return (
    <Alert severity="warning" variant="standard">
      <FormattedMessage
        {...messages.CreditSummaryWarningInsufficientCredit}
        values={{
          apartmentsCovered: codeData.activation.licencesCount,
          apartmentsNeeded: codeData.activation.licencesCount + codeData.activation.unlicensedCount,
          licencesPool: codeData.activation.licencesPool,
        }}
      />
    </Alert>
  );
}

export default CreditSummaryAlert;
