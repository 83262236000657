import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import * as Api from '../../api/paymentsApi';
import { PAYMENT_CONTEXT } from '../../config/payments';
import { selectors as companySelectors } from '../company';
import { errorHelper, responseValidateStatusOrThrow } from '../helpers';
import { selectors as siteSelectors } from '../sites';
import types from './types';

export function* startTrialVersion() {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(siteSelectors.getSiteId);
    const response = yield call(Api.startTrialVersion, companyId, siteId);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({ payload: data, type: types.START_TRIAL_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.START_TRIAL_FAILURE, error));
  }
}

export function* getPaymentPromoDetail({ paymentId, promoId }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const response = yield call(Api.getPaymentPromoDetail, companyId, paymentId, promoId);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({ payload: data, type: types.GET_PAYMENT_DETAIL_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.GET_PAYMENT_DETAIL_FAILURE, error));
  }
}

// eslint-disable-next-line max-statements
export function* getPayments(action) {
  const { companyId, context, filter, order, page, params, rowsPerPage, siteId } = action;
  let response;
  try {
    if (context === PAYMENT_CONTEXT.SUPERADMIN_PAYMENTS) {
      response = yield call(Api.getSuperadminPayments, params);
    } else {
      if ([PAYMENT_CONTEXT.COMPANY_PAYMENTS, PAYMENT_CONTEXT.RECENT_PAYMENTS].includes(context)) {
        response = yield call(Api.getCompanyPayments, companyId, params);
      } else if (context === PAYMENT_CONTEXT.SITE_PAYMENTS) {
        response = yield call(Api.getSitePayments, companyId, siteId, params);
      } else {
        throw new Error('Unsupported context');
      }
    }
    yield responseValidateStatusOrThrow(response);
    const { results, resultsFiltered, resultsTotal } = response.data;
    let payments = [];
    if (context === PAYMENT_CONTEXT.SUPERADMIN_PAYMENTS) {
      payments = results.map((item) => ({
        ...item.payment,
        companyId: item.companyId || null,
        companyName: item.companyName || null,
      }));
    } else {
      payments = results;
    }
    yield put({
      filter,
      order,
      page,
      payload: {
        // eslint-disable-next-line complexity
        payments: payments.map((item) => ({
          chargeVat: item.purchaseOrder?.chargeVat || false,
          companyId: item.companyId || null,
          companyName: item.companyName || null,
          currency: item.purchaseOrder?.currency || null,
          customerOrderNumber: item.purchaseOrder?.customerOrderNumber || null,
          dateCreated: item.dateCreated || null,
          id: item.id || null,
          invoiceNumber: item.invoiceNumber || null,
          link: item.link || null,
          orderContent: item.purchaseOrder?.orderContent || null,
          orderResults: item.purchaseOrder?.orderResults || null,
          paymentNumber: item.paymentNumber || null,
          paymentResult: item.purchasePaymentResult?.paymentResult || null,
          price: item.purchaseOrder?.price || null,
          priceVat: item.purchaseOrder?.priceVat || null,
          primaryCode: item.purchasePaymentResult?.primaryCode || null,
          purchaseState: item.purchaseState || null,
          secondaryCode: item.purchasePaymentResult?.secondaryCode || null,
        })),
        resultsFiltered,
        resultsTotal,
      },
      rowsPerPage,
      type: types.GET_PAYMENTS_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_PAYMENTS_FAILURE, error));
  }
}

export default function* paymentsSagas() {
  yield all([
    takeEvery(types.START_TRIAL_REQUEST, startTrialVersion),
    takeLatest(types.GET_PAYMENTS_REQUEST, getPayments),
    takeLatest(types.GET_PAYMENT_DETAIL_REQUEST, getPaymentPromoDetail),
  ]);
}
