import copy from 'copy-to-clipboard';
import { useCallback } from 'react';
import { MessageDescriptor } from 'react-intl';
import { useDispatch } from 'react-redux';
import { TYPE_DESCRIPTOR } from '../modules/notifications';
import { addSnackbarError, addSnackbarSuccess } from '../modules/notifications/actions';
import messages from './messages';

export type ClipboardImageData = {
  blob: Blob;
}

export interface IUseClipboardProps {
  copySuccessMessage: MessageDescriptor;
}

export interface IUseClipboard {
  copyToClipboard: (data: string | ClipboardImageData) => void;
}

export function useClipboard(props: IUseClipboardProps): IUseClipboard {
  const dispatch = useDispatch();
  const copyToClipboard = useCallback(
    (data: string | ClipboardImageData) => {
      if (typeof data === 'object') {
        if (navigator.userAgent.includes('SamsungBrowser')) {
          dispatch(addSnackbarError(messages.SharedNotificationCopyingNotAllowed, TYPE_DESCRIPTOR));
        } else {
          navigator.clipboard.write([new ClipboardItem({ 'image/png': data.blob })]);
          dispatch(addSnackbarSuccess(props.copySuccessMessage));
        }
      } else {
        if (copy(data)) {
          dispatch(addSnackbarSuccess(props.copySuccessMessage));
        } else {
          dispatch(addSnackbarError(messages.SharedNotificationCopyingNotAllowed, TYPE_DESCRIPTOR));
        }
      }
    },
    [props.copySuccessMessage]
  );
  return {
    copyToClipboard,
  };
}
