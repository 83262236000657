import { defineMessages } from 'react-intl';

export default defineMessages({
  infoBoxDescription: {
    defaultMessage:
      'Once you select and confirm the type of your site, it' +
      ' cannot be changed.',
    description: '',
    id: 'infoBox.description',
  },
  resultMessageMduMessage: {
    defaultMessage: "Since you selected an apartment building, you'll be charged per apartment. ",
    description: '',
    id: 'resultMessage.mdu.message',
  },
  resultMessageMduNextPayment: {
    defaultMessage: 'The next payment is due on {paymentDate}. ',
    description: '',
    id: 'resultMessage.mdu.nextPayment',
  },
  resultMessageMduNextPaymentFreeTrial: {
    defaultMessage: 'Payments will begin after the free trial period.',
    description: '',
    id: 'resultMessage.mdu.nextPaymentFreeTrial',
  },
  resultMessageMduSuccess: {
    defaultMessage: 'Success! Your site is set',
    description: '',
    id: 'resultMessage.success',
  },
  resultMessageMduWarning: {
    defaultMessage: 'Check your device settings',
    description: '',
    id: 'resultMessage.warning',
  },
  resultMessageMduWarningText: {
    defaultMessage:
      'The apartment-building option requires that all devices (except intercoms)' +
      ' are assigned to the corresponding apartments.' +
      ' Each apartment is limited to 4 paid devices' +
      ' (phones, tablets, and 3rd party devices). <url>Assign devices to apartments</url>',
    description: '',
    id: 'resultMessage.warningText',
  },
  selectedItemTypeAdministration: {
    defaultMessage: 'Administration',
    description:
      'This will help you make the right choice for simpler administration of' +
      ' your site and efficient configuration of the relevant services for your building.',
    id: 'selectedItemType.administration',
  },
  selectedItemTypeDescription: {
    defaultMessage:
      'This will help you make the right choice for simpler administration of' +
      ' your site and efficient configuration of the relevant services for your building.',
    description: '',
    id: 'selectedItemType.description',
  },
  selectedItemTypeLimits: {
    defaultMessage: 'Limits',
    description: '',
    id: 'selectedItemType.limits',
  },
  selectedItemTypePaymentModel: {
    defaultMessage: 'Payment model',
    description: '',
    id: 'selectedItemType.paymentModel',
  },
  selectSiteTypeDescription: {
    defaultMessage:
      'This will help you make the right choice for simpler administration of' +
      ' your site and efficient configuration of the relevant services for your building.',
    description: '',
    id: 'selectSiteType.description',
  },
  selectSiteTypeItemsMduAdministrationItem1: {
    defaultMessage: 'You only manage individual apartments.',
    description: '',
    id: 'selectSiteType.items.mdu.administration.item1',
  },
  selectSiteTypeItemsMduAdministrationItem2: {
    defaultMessage: 'The setup is easy for efficient management of multiple living units.',
    description: '',
    id: 'selectSiteType.items.mdu.administration.item2',
  },
  selectSiteTypeItemsMduDescription: {
    defaultMessage: 'The best option for a large building with multiple living units',
    description: '',
    id: 'selectSiteType.items.mdu.description',
  },
  selectSiteTypeItemsMduLimitsItem1: {
    defaultMessage: 'This option is limited to {apartmentDeviceLimit}' + ' phones with subscriptions per apartment.',
    description: '',
    id: 'selectSiteType.items.mdu.limits.item1',
  },
  selectSiteTypeItemsMduLimitsItem2: {
    defaultMessage: 'Each apartment supports an unlimited number of 2N devices.',
    description: '',
    id: 'selectSiteType.items.mdu.limits.item2',
  },
  selectSiteTypeItemsMduPaymentModelItem1: {
    defaultMessage: 'You pay per apartment.',
    description: '',
    id: 'selectSiteType.items.mdu.paymentModel.item1',
  },
  selectSiteTypeItemsMduPaymentModelItem2: {
    defaultMessage:
      "The Site is preconfigured to ensure you don't have" +
      ' to add phones or answer units to each apartment separately.',
    description: '',
    id: 'selectSiteType.items.mdu.paymentModel.item2',
  },
  selectSiteTypeItemsMduTitle: {
    defaultMessage: 'Apartment building',
    id: 'selectSiteType.items.mdu.title',
  },
  selectSiteTypeItemsOtherAdministrationItem1: {
    defaultMessage: 'You manage individual mobile phones or other devices within the Site.',
    description: '',
    id: 'selectSiteType.items.other.administration.item1',
  },
  selectSiteTypeItemsOtherAdministrationItem2: {
    defaultMessage: 'These devices include intercoms, telephones, mobile phones, and' + ' answering units.',
    description: '',
    id: 'selectSiteType.items.other.administration.item2',
  },
  selectSiteTypeItemsOtherDescription: {
    defaultMessage: 'The ideal solution for large facilities',
    description: '',
    id: 'selectSiteType.items.other.description',
  },
  selectSiteTypeItemsOtherLimitsItem1: {
    defaultMessage: 'This option supports up to {deviceLimit} devices.',
    description: '',
    id: 'selectSiteType.items.other.limits.item1',
  },
  selectSiteTypeItemsOtherLimitsItem2: {
    defaultMessage:
      'You can increase the limit of phones as you need.' + ' Just contact our <url>Customer Care team.</url>',
    description: '',
    id: 'selectSiteType.items.other.limits.item2',
  },
  selectSiteTypeItemsOtherPaymentModelItem1: {
    defaultMessage: 'You pay for each phone registered within the Site.',
    description: '',
    id: 'selectSiteType.items.other.paymentModel.item1',
  },
  selectSiteTypeItemsOtherPaymentModelItem2: {
    defaultMessage: 'For each phone you need a separate subscription.',
    description: '',
    id: 'selectSiteType.items.other.paymentModel.item2',
  },
  selectSiteTypeItemsOtherTitle: {
    defaultMessage: 'Other (office, retail, special-purpose)',
    description: '',
    id: 'selectSiteType.items.other.title',
  },
  selectSiteTypeItemsSingleVillaAdministrationItem1: {
    defaultMessage: 'You manage individual mobile phones or other devices within the Site.',
    description: '',
    id: 'selectSiteType.items.singleVilla.administration.item1',
  },
  selectSiteTypeItemsSingleVillaAdministrationItem2: {
    defaultMessage: 'Typically, these are the phones of your family members.',
    description: '',
    id: 'selectSiteType.items.singleVilla.administration.item2',
  },
  selectSiteTypeItemsSingleVillaDescription: {
    defaultMessage: 'Suitable for a house with a maximum of 2–3 living units',
    description: '',
    id: 'selectSiteType.items.singleVilla.description',
  },
  selectSiteTypeItemsSingleVillaLimitsItem1: {
    defaultMessage: 'Family home is best managed with 3-10 phones per site.',
    description: '',
    id: 'selectSiteType.items.singleVilla.limits.item1',
  },
  selectSiteTypeItemsSingleVillaLimitsItem2: {
    defaultMessage: 'One intercom button can call up to {ringingGroupSizeLimit} phones.',
    description: '',
    id: 'selectSiteType.items.singleVilla.limits.item2',
  },
  selectSiteTypeItemsSingleVillaPaymentModelItem1: {
    defaultMessage: 'You pay for each mobile phone registered within the Site.',
    description: '',
    id: 'selectSiteType.items.singleVilla.paymentModel.item1',
  },
  selectSiteTypeItemsSingleVillaPaymentModelItem2: {
    defaultMessage: 'For each phone you need a separate subscription.',
    description: '',
    id: 'selectSiteType.items.singleVilla.paymentModel.item2',
  },
  selectSiteTypeItemsSingleVillaTitle: {
    defaultMessage: 'Family home',
    description: '',
    id: 'selectSiteType.items.singleVilla.title',
  },
  SelectSiteTypeModalBackBtn: {
    defaultMessage: 'Back',
    description: '',
    id: 'selectSiteTypeModal.backBtn',
  },
  SelectSiteTypeModalCancelBtn: {
    defaultMessage: 'Cancel',
    description: '',
    id: 'selectSiteTypeModal.cancelBtn',
  },
  SelectSiteTypeModalCloseBtn: {
    defaultMessage: 'Close',
    description: '',
    id: 'selectSiteTypeModal.closeBtn',
  },
  SelectSiteTypeModalNextBtn: {
    defaultMessage: 'Next',
    description: '',
    id: 'selectSiteTypeModal.nextBtn',
  },
  SelectSiteTypeModalSetTypeBtn: {
    defaultMessage: 'Set type',
    description: '',
    id: 'selectSiteTypeModal.setTypeBtn',
  },
  SelectSiteTypeModalTitle: {
    defaultMessage: 'Select your site type',
    description: '',
    id: 'selectSiteTypeModal.title',
  },
});
