import ErrorIcon from '@mui/icons-material/Error';
import Button from '@mui/material/Button';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Alert } from '../../../components';
import { FIRMWARE_STATUS, RC_STATUS } from '../../../config/devices';
import { IDevice } from '../../../modules/devices/store/deviceStore';
import messages from './messages';

interface IChangePasswordNotificationProps {
  remoteConfigurationStatus: string;
  firmwareStatus: string;
  onOpenRCModal: (device: IDevice) => void;
  device: IDevice;
}

export function DefaultPasswordNotification({
  device,
  firmwareStatus,
  onOpenRCModal,
  remoteConfigurationStatus,
}: IChangePasswordNotificationProps) {
  const { formatMessage } = useIntl();
  const alertProps = useMemo(() => {
    if (remoteConfigurationStatus === RC_STATUS.DISABLED) {
      return {
        button: false,
        descriptionError: messages.DeviceDetailDefaultPasswordErrorTextInactiveRC,
      };
    }
    if (remoteConfigurationStatus === RC_STATUS.OFFLINE) {
      return {
        button: false,
        descriptionError: messages.DeviceDetailDefaultPasswordErrorTextOffline,
      };
    }
    if (remoteConfigurationStatus !== RC_STATUS.OFFLINE && remoteConfigurationStatus !== RC_STATUS.DISABLED) {
      if (firmwareStatus === FIRMWARE_STATUS.SUPPORTED_NEWER || firmwareStatus === FIRMWARE_STATUS.SUPPORTED_OLDER) {
        return {
          button: true,
          descriptionError: messages.DeviceDetailDefaultPasswordErrorTextOutofdateFW,
        };
      }
      if (firmwareStatus === FIRMWARE_STATUS.UNSUPPORTED || firmwareStatus === FIRMWARE_STATUS.DEPRECATED) {
        return {
          button: false,
          descriptionError: messages.DeviceDetailDefaultPasswordErrorTextUnsupportedFW,
        };
      }
    }
    return {
      button: true,
      descriptionError: messages.DeviceDetailDefaultPasswordErrorTextActiveRC,
    };
  }, [remoteConfigurationStatus, firmwareStatus]);

  return (
    <Alert
      icon={<ErrorIcon />}
      action={
        alertProps.button ? (
          <Button
            onClick={() => onOpenRCModal(device)}
            size="small"
            sx={{ '&:hover': { backgroundColor: 'error.hoverBg' }, color: 'error.text' }}
          >
            <FormattedMessage {...messages.DeviceDetailChangePasswordButton} />
          </Button>
        ) : undefined
      }
      severity="error"
      title={formatMessage(messages.DeviceDetailDefaultPasswordErrorTitle)}
      variant="standard"
    >
      <FormattedMessage
        {...alertProps.descriptionError}
        values={{ br: <br /> }}
      />
    </Alert>
  );
}
