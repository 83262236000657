import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from '../../../../components';

const ItemFeature = ({ helpId, id }) => (
  <>
    <FormattedMessage {...id} />
    {helpId && (
      <Tooltip sx={{ ml: 1 }} title={<FormattedMessage {...helpId} />}>
        <InfoOutlinedIcon color="secondary" fontSize="small" />
      </Tooltip>
    )}
  </>
);

ItemFeature.propTypes = {
  helpId: PropTypes.any,
  id: PropTypes.any.isRequired,
};

ItemFeature.defaultProps = {
  helpId: null,
};

export default ItemFeature;
