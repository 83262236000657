import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const UpgradeButton = ({ buttonColor, disabled, onClick }) => (
  <Button disabled={disabled} onClick={() => onClick(true)} size="small" sx={{ color: buttonColor }}>
    <FormattedMessage {...messages.versionNotificationUpdateBtn} />
  </Button>
);

UpgradeButton.propTypes = {
  buttonColor: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default UpgradeButton;
