import LanguageIcon from '@mui/icons-material/Language';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { LANGUAGES, PATHS } from '../../../config/consts';
import useBreakpoints from '../../../helpers/useBreakpoints';
import { useGetActualCompanyId } from '../../../hooks/companyHooks';
import { useGetActualSiteId } from '../../../hooks/siteHooks';
import actions, { selectors as localizationSelectors } from '../../../modules/localization';
import { getLangByLocale } from '../../../modules/localization/helpers';
import { selectors as userSelectors } from '../../../modules/user';
import { useAppContext } from '../../../pages/App/AppContextProvider';
import IconMaterial from '../../IconMaterial';
import Link from '../../Link';
import messages from '../messages';
import LanguageSelectItem from './LanguageSelectItem';
import Loader from './Loader';

const styles = {
  fade: (placement) => ({
    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
  }),
  info: {
    color: 'secondary.main',
    maxWidth: '200px',
  },
  label: {
    color: 'primary.contrastText',
    display: 'flex',
  },
  labelText: {
    maxWidth: {
      sm: '85px',
      xs: '30px',
    },
  },
  paper: {
    '&.MuiPaper-root': {
      mt: 3.4,
    },
  },
  root: {
    cursor: 'pointer',
    display: 'flex',
    mr: {
      sm: 2,
      xs: 1,
    },
  },
};

function LanguageSelect() {
  const { upSm } = useBreakpoints();
  const dispatch = useDispatch();
  const anchorRef = useRef(null);
  const { currentUserLang, loggedIn } = useAppContext();
  const companyId = useGetActualCompanyId();
  const siteId = useGetActualSiteId();

  const [open, setOpen] = useState(false);
  const isFetching = useSelector(localizationSelectors.isLandingPageSelectFetching);
  const isCompanyOrSiteAdmin = useSelector(userSelectors.isCompanyOrSiteAdmin);

  const siteLanguageLocation = PATHS.SITE_SETTINGS_SITE_LANGUAGE.replace(':companyId', companyId).replace(
    ':siteId',
    siteId
  );

  const onMenuItemClick = (lang) => {
    if (loggedIn) {
      dispatch(actions.setUserModalLanguage(lang));
    } else {
      dispatch(actions.setLandingPageLanguage(lang, loggedIn));
    }

    setOpen(false);
  };

  const handleClose = (event) => {
    if (!anchorRef?.current?.contains(event.target)) {
      setOpen(false);
    }
  };

  if (isFetching) return <Loader />;

  return (
    <Box sx={styles.root}>
      <Box aria-controls="select" onClick={() => setOpen((prevState) => !prevState)} ref={anchorRef} sx={styles.label}>
        <LanguageIcon color={loggedIn ? 'info' : 'inherit'} sx={{ mr: 1 }} />
        <Typography sx={{ ...styles.labelText, ...(loggedIn && { opacity: '0.87' }) }}>
          {upSm
            ? getLangByLocale(currentUserLang)?.nonTranslated
            : !loggedIn && getLangByLocale(currentUserLang)?.abbreviation}
        </Typography>
        <Box component="span" sx={!upSm && loggedIn ? { display: 'none' } : undefined}>
          {open ? (
            <IconMaterial name="arrow_drop_up" styles={loggedIn ? { opacity: '0.87' } : {}} />
          ) : (
            <IconMaterial name="arrow_drop_down" styles={loggedIn ? { opacity: '0.87' } : {}} />
          )}
        </Box>
      </Box>
      <Popper anchorEl={anchorRef.current} open={Boolean(open)} transition>
        {({ placement, TransitionProps }) => (
          <Fade {...TransitionProps} style={styles.fade(placement)}>
            <Paper elevation={8} id="select" sx={styles.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList disablePadding>
                  {Object.values(LANGUAGES).map((lang) => (
                    <LanguageSelectItem
                      currentUserLang={getLangByLocale(currentUserLang)}
                      isLoggedIn={loggedIn}
                      key={upSm ? lang.nonTranslated : lang.abbreviation}
                      lang={lang}
                      onClick={() => onMenuItemClick(lang.locale)}
                      upSm={upSm}
                    />
                  ))}
                  {loggedIn && (
                    <Box>
                      <Typography component="div" mt={1} px={2} sx={styles.info} variant="caption">
                        <FormattedMessage {...messages.languageMenuAutomaticTranslations} />
                      </Typography>
                      {isCompanyOrSiteAdmin && (
                        <Typography component="div" p={2} sx={styles.info} variant="caption">
                          {siteId ? (
                            <FormattedMessage
                              {...messages.languageMenuSiteLanguage}
                              values={{
                                link: (chunks) => (
                                  <Link onClick={handleClose} to={siteLanguageLocation}>
                                    {chunks}
                                  </Link>
                                ),
                              }}
                            />
                          ) : (
                            <FormattedMessage {...messages.languageMenuSiteLanguageBold} />
                          )}
                        </Typography>
                      )}
                    </Box>
                  )}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Box>
  );
}

export default LanguageSelect;
