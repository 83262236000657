import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import NoRowsOverlayDataGrid from '../DataGrid/NoRowsOverlay';
import messages from './messages';

const NoRowsSearchOverlay = ({ onClearSearch, searchText }) => (
  <NoRowsOverlayDataGrid
    action={
      <Button color="primary" onClick={onClearSearch} startIcon={<CloseIcon />} sx={{ mt: 3 }} variant="outlined">
        <FormattedMessage {...messages.sitesDataGridNoRowsSearchOverlayClearSearch} />
      </Button>
    }
    headerText={<FormattedMessage {...messages.sitesDataGridNoRowsSearchOverlayHeaderText} />}
    text={
      <FormattedMessage
        {...messages.sitesDataGridNoRowsSearchOverlayText}
        values={{
          br: <br />,
          searchText,
        }}
      />
    }
  />
);

NoRowsSearchOverlay.propTypes = {
  onClearSearch: PropTypes.func.isRequired,
  searchText: PropTypes.string,
};

NoRowsSearchOverlay.defaultProps = {
  searchText: '',
};

export default NoRowsSearchOverlay;
