import AddBoxIcon from '@mui/icons-material/AddBox';
import Button from '@mui/material/Button';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

export interface IAddButtonRowData {
  onAdd: () => void;
}

const AddButtonRow = ({ onAdd }: IAddButtonRowData) => (
  <Button color="secondary" onClick={onAdd} startIcon={<AddBoxIcon />} type="button">
    <FormattedMessage {...messages.addButtonRow} />
  </Button>
);

export default AddButtonRow;
