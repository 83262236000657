import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SectionHeader } from '../../../../components';
import messages from '../../messages';

const BillingHeader = ({ company, onEditBilling }) => (
  <Grid alignItems="center" container direction="row" item justifyContent="space-between">
    <Grid item xs>
      <SectionHeader headerText={<FormattedMessage {...messages.billingInfoHeader} />} variant="h6">
        {company.billingInfoEditable && (
          <Grid container direction="row" item justifyContent="flex-end">
            <Button color="secondary" onClick={onEditBilling} startIcon={<EditIcon />}>
              <FormattedMessage {...messages.billingInfoEdit} />
            </Button>
          </Grid>
        )}
      </SectionHeader>
    </Grid>
  </Grid>
);

BillingHeader.propTypes = {
  company: PropTypes.shape({
    billingInfoEditable: PropTypes.bool,
  }).isRequired,
  onEditBilling: PropTypes.func.isRequired,
};

export default BillingHeader;
