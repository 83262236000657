import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import actions, { selectors } from '../../modules/accessSettings';
import DangerModal from '../DangerModal';
import DialogBody from './DialogBody';
import messages from './messages';

function AccessGroupDeleteModal({ group, onClose, open, redirectTo }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const isLoading = useSelector(selectors.getIsDeleteAccessGroupFetching);
  const [isDialogLoading, setIsDialogLoading] = useState(isLoading);

  useEffect(() => {
    if (!isLoading) {
      setIsDialogLoading((prev) => {
        if (prev) {
          onClose();
          if (redirectTo) {
            navigate(redirectTo);
          }
        }
        return false;
      });
    } else {
      setIsDialogLoading(isLoading);
    }
  }, [isLoading]);

  const handleDeleteGroup = () => {
    dispatch(actions.deleteAccessGroup(group.id));
  };

  const getIsOpen = open || isDialogLoading;
  return (
    getIsOpen && (
      <DangerModal
        actionButtonLabel={formatMessage(messages.accessGroupDeleteModalDelete)}
        isLoading={isDialogLoading}
        name="access-group-delete-modal"
        onClose={onClose}
        onSubmit={handleDeleteGroup}
        open={getIsOpen}
        title={formatMessage(messages.accessGroupDeleteModalTitle)}
      >
        <DialogBody group={group} onClose={onClose} />
      </DangerModal>
    )
  );
}

AccessGroupDeleteModal.propTypes = {
  group: PropTypes.shape({
    apartments: PropTypes.number,
    devices: PropTypes.arrayOf(PropTypes.object),
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    users: PropTypes.number,
  }),
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  redirectTo: PropTypes.string,
};

AccessGroupDeleteModal.defaultProps = {
  group: {},
  redirectTo: '',
};

export default AccessGroupDeleteModal;
