import moment from 'moment';
import React from 'react';

interface ICustomDateProps {
  dateValue: string;
  isLegalDocument?: boolean;
  isTechnicalRecord?: boolean;
  showTime?: boolean;
  showTimeZone?: boolean;
  timezoneShift?: number;
}

const CustomDate = ({ dateValue, isLegalDocument, isTechnicalRecord, showTime, showTimeZone, timezoneShift }: ICustomDateProps) => {
  const isCurrentYear = moment(dateValue).isSame(new Date(), 'year');

  if (showTimeZone) {
    return <>{moment(dateValue).utc().utcOffset(timezoneShift??0).format('D MMM, YYYY, H:mm zZ')}</>;
  }
  if (isTechnicalRecord) {
    return <>{moment(dateValue).format('YYYY-MM-DD HH:mm:ss')}</>;
  }
  if (showTime) {
    if (isCurrentYear) {
      return <>{moment(dateValue).format('D MMM, H:mm')}</>;
    }
    return <>{moment(dateValue).format('D MMM, YYYY H:mm')}</>;
  }
  if (isCurrentYear && !isLegalDocument) {
    return <>{moment(dateValue).format('D MMM')}</>;
  }
  return <>{moment(dateValue).format('D MMM, YYYY')}</>;
};

export default CustomDate;
