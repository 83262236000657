import { defineMessages } from 'react-intl';

export default defineMessages({
  cardIdentifierInputErrorReading: {
    defaultMessage: 'Place the card on the reader',
    description: 'Card identifier reading helper text',
    id: 'cardIdentifierInput.helperText.reading',
  },
  cardIdentifierInputErrorValidId: {
    defaultMessage: 'The card has been successfully read',
    description: 'Card identifier valid id helper text',
    id: 'cardIdentifierInput.helperText.validId',
  },
  cardIdentifierInputHelperTextDefault: {
    defaultMessage: 'Enter the card code manually. A card reader is unavailable on your device',
    description: 'Card identifier default helper text',
    id: 'cardIdentifierInput.helperTextDefault',
  },
  cardIdentifierInputHelperTextDriverAvailable: {
    defaultMessage: 'Enter the card code manually or scan it with a reader',
    description: 'Card identifier driver available helper text',
    id: 'cardIdentifierInput.helperTextDriverAvailable',
  },
  cardIdentifierInputHelperTextDriverTesting: {
    defaultMessage: 'Checking the USB driver for the card reader…',
    description: 'Card identifier driver testing helper text',
    id: 'cardIdentifierInput.helperTextDriverTesting',
  },
  cardIdentifierInputHelperTextErrorCardErr: {
    defaultMessage:
      'You’ve scanned an unsupported card type. Use another card' +
      ' or adjust the driver settings (e.g. for HID iClass SE cards)',
    description: 'Card identifier  card error helper text',
    id: 'cardIdentifierInput.helperText.error.CARD_ERR',
  },
  cardIdentifierInputHelperTextErrorConnectionErr: {
    defaultMessage: 'The driver is not working. Turn it on and try again',
    description: 'Card identifier connection error helper text',
    id: 'cardIdentifierInput.helperText.error.CONNECTION_ERR',
  },
  cardIdentifierInputHelperTextErrorDeviceErr: {
    defaultMessage: 'Your reader isn’t connected. Connect it and try again',
    description: 'Card identifier device error helper text',
    id: 'cardIdentifierInput.helperText.error.DEVICE_ERR',
  },
  cardIdentifierInputHelperTextErrorDriverErr: {
    defaultMessage: "Communication with the reader didn't work. Disconnect the" + ' reader, connect it, and try again',
    description: 'Card identifier driver error helper text',
    id: 'cardIdentifierInput.helperText.error.DRIVER_ERR',
  },
  cardIdentifierInputHelperTextErrorInvalidId: {
    defaultMessage: 'Enter the card code in the correct format',
    description: 'Card identifier device invalid id helper text',
    id: 'cardIdentifierInput.helperTextErrorInvalidId',
  },
  cardIdentifierInputHelperTextErrorPlaceholder: {
    defaultMessage: 'Card reader error',
    description: 'Card identifier error placeholder',
    id: 'cardIdentifierInput.helperTextErrorPlaceholder',
  },
  cardIdentifierInputHelperTextErrorReadingErr: {
    defaultMessage: `The card couldn’t be scanned. Push the button and place the card on the reader`,
    description: 'Card identifier reading error helper text',
    id: 'cardIdentifierInput.helperTextErrorREADING_ERR',
  },
  cardIdentifierInputLabel: {
    defaultMessage: 'RFID card identifier',
    description: 'Card identifier input label',
    id: 'cardIdentifierInput.label',
  },
  readerInfoDownload: {
    defaultMessage: 'Download the driver, install it, turn it' + ' on, and connect the card reader.',
    description: 'Card reader download info',
    id: 'readerInfo.download',
  },
  readerInfoDownloadLink: {
    defaultMessage: '2N<sup>®</sup> USB Driver',
    description: 'Card reader download link',
    id: 'readerInfo.downloadLink',
  },
  readerInfoGuide: {
    defaultMessage: 'For details, see the {manualLink}.',
    description: 'Card reader guide info',
    id: 'readerInfo.guide',
  },
  readerInfoManualLink: {
    defaultMessage: 'user manual',
    description: 'Card reader user guide link',
    id: 'readerInfo.manualLink',
  },
  readerInfoTitle: {
    defaultMessage: 'How to use the reader',
    description: 'Card reader info title',
    id: 'readerInfo.title',
  },
  readerInfoUse: {
    defaultMessage: 'To use your card reader, you need the' + ' {downloadLink} (compatible with MS Windows).',
    description: 'Card reader use info',
    id: 'readerInfo.use',
  },
  readingButtonButtonLabel: {
    defaultMessage: 'Start card reading',
    description: 'Card reader button label',
    id: 'readingButton.label',
  },
  refreshIconButtonTooltip: {
    defaultMessage: 'Check USB driver{br}availability again',
    description: 'Refresh icon button tooltip',
    id: 'refreshIconButton.tooltip',
  },
});
