import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Markdown from 'react-markdown';
import { Modal } from '../../../components';
import messages from './messages';

const FirmwareModal = ({ disabled, onClose, onUpdateFirmware, open, releaseNotes, version }) => {
  const parseNotes = (notes) =>
    notes
      .replace(/\n\*\n/g, '\n\n')
      .replace(/\n\*\r/g, '\n\n')
      .replace(/\n\*$/g, '\n')
      .replace(/\n\*\s-/g, '\n-');

  return (
    <Modal
      actions={
        <>
          <Button onClick={onClose}>
            <FormattedMessage {...messages.firmwareModalCloseBtn} />
          </Button>
          <Button
            color="primary"
            disabled={disabled}
            onClick={() => {
              onUpdateFirmware({ version });
              onClose();
            }}
            startIcon={<ArrowForwardIcon />}
            variant="contained"
          >
            <FormattedMessage {...messages.firmwareModalUpgradeBtn} />
          </Button>
        </>
      }
      headerVariant="primary"
      maxWidth="xs"
      name="firmware-modal"
      onClose={onClose}
      open={open}
      title={<FormattedMessage {...messages.firmwareModalNewVersion} />}
    >
      {releaseNotes?.map((release) => (
        <Box key={release?.version}>
          <Typography paragraph variant="body2">
            <FormattedMessage values={{ version: release?.version }} {...messages.firmwareModalVersionChanges} />
          </Typography>
          <Typography component="div" variant="body2">
            <Markdown>{parseNotes(release?.text)}</Markdown>
          </Typography>
        </Box>
      ))}
    </Modal>
  );
};

FirmwareModal.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdateFirmware: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  releaseNotes: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      version: PropTypes.string,
    })
  ),
  version: PropTypes.string,
};

FirmwareModal.defaultProps = {
  releaseNotes: [{ text: '', version: '' }],
  version: '',
};

export default FirmwareModal;
