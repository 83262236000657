import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PATHS } from '../config/consts';
import ApartmentDetail from '../containers/ApartmentDetail';
import ApartmentAccess from '../containers/ApartmentDetail/ApartmentAccess';
import ApartmentDevices from '../containers/ApartmentDetail/ApartmentDevices';
import ApartmentInfo from '../containers/ApartmentDetail/ApartmentInfo';
import ApartmentUsers from '../containers/ApartmentDetail/ApartmentUsers';
import { useUrlParams } from '../helpers/urlParams';
import { useGetLoadedParams } from '../hooks/paramsHooks';
import { getApartmentDetail } from '../modules/apartments/actions';
import { getIsApartmentDetailFetching } from '../modules/apartments/selectors';
import { ApplicationContext, Roles } from '../modules/commonTypes';
import { PrivateRoute } from './components/PrivateRoute';

export function ApartmentDetailRoutes() {
  const { companyId, siteId } = useGetLoadedParams(ApplicationContext.Site);
  const { apartmentId } = useUrlParams();
  const dispatch = useDispatch();
  const isApartmentDetailFetching = useSelector(getIsApartmentDetailFetching);

  useEffect(() => {
    if (apartmentId !== undefined) {
      dispatch(getApartmentDetail(companyId, siteId, apartmentId));
    }
  }, [companyId, siteId, apartmentId]);

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <PrivateRoute allowedRoles={{ contextRoles: [Roles.ADMIN, Roles.STANDARD] }}>
            <ApartmentDetail />
          </PrivateRoute>
        }
      >
        <Route element={!isApartmentDetailFetching && <ApartmentInfo />} path={'detail'} />
        <Route element={<ApartmentDevices />} path={'devices'} />
        <Route element={<ApartmentUsers />} path={'users'} />
        <Route element={<ApartmentAccess />} path={'access'} />
      </Route>
      <Route
        path={PATHS.APARTMENT}
        element={<Navigate replace to={getFallbackPath(companyId, siteId, apartmentId)} />}
      />
    </Routes>
  );
}

function getFallbackPath(companyId: number, siteId: number, apartmentId?: number): string {
  if (apartmentId !== undefined) {
    return PATHS.APARTMENT_DETAIL.replace(':companyId', companyId.toString())
      .replace(':siteId', siteId.toString())
      .replace(':apartmentId', apartmentId.toString());
  }

  return PATHS.APARTMENTS_OVERVIEW.replace(':companyId', companyId.toString()).replace(':siteId', siteId.toString());
}
