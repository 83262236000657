import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const SectionHeader = ({ children }) => (
  <Box py={2}>
    <Typography variant="h5">{children}</Typography>
  </Box>
);

SectionHeader.propTypes = {
  children: PropTypes.element.isRequired,
};

export default SectionHeader;
