import { MessageDescriptor } from 'react-intl';

export const moduleName = 'localization';

export const types = {
  GET_CURRENT_SITE_LANGUAGE_FAILURE: `${moduleName}/GET_CURRENT_SITE_LANGUAGE_FAILURE`,
  GET_CURRENT_SITE_LANGUAGE_REQUEST: `${moduleName}/GET_CURRENT_SITE_LANGUAGE_REQUEST`,
  GET_CURRENT_SITE_LANGUAGE_SUCCESS: `${moduleName}/GET_CURRENT_SITE_LANGUAGE_SUCCESS`,
  GET_CURRENT_USER_LANGUAGE_FAILURE: `${moduleName}/GET_CURRENT_USER_LANGUAGE_FAILURE`,
  GET_CURRENT_USER_LANGUAGE_REQUEST: `${moduleName}/GET_CURRENT_USER_LANGUAGE_REQUEST`,
  GET_CURRENT_USER_LANGUAGE_SUCCESS: `${moduleName}/GET_CURRENT_USER_LANGUAGE_SUCCESS`,
  SET_CURRENT_SITE_LANGUAGE_REQUEST: `${moduleName}/SET_CURRENT_SITE_LANGUAGE_REQUEST`,
  SET_FETCHING_MESSAGES: `${moduleName}/SET_FETCHING_MESSAGES`,
  SET_LANDING_PAGE_LANGUAGE_FAILURE: `${moduleName}/SET_LANDING_PAGE_LANGUAGE_FAILURE`,
  SET_LANDING_PAGE_LANGUAGE_REQUEST: `${moduleName}/SET_LANDING_PAGE_LANGUAGE_REQUEST`,
  SET_LANDING_PAGE_LANGUAGE_SUCCESS: `${moduleName}/SET_LANDING_PAGE_LANGUAGE_SUCCESS`,
  SET_USER_MODAL_LANGUAGE_FAILURE: `${moduleName}/SET_USER_MODAL_LANGUAGE_FAILURE`,
  SET_USER_MODAL_LANGUAGE_REQUEST: `${moduleName}/SET_USER_MODAL_LANGUAGE_REQUEST`,
  SET_USER_MODAL_LANGUAGE_SUCCESS: `${moduleName}/SET_USER_MODAL_LANGUAGE_SUCCESS`,
};

export interface ILang {
  abbreviation: string;
  locale: string;
  nonTranslated: string;
  translated: MessageDescriptor;
}
