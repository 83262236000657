import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Circle from './Circle';

const Loading = () => (
  <Box sx={{ textAlign: 'center' }}>
    <Typography paragraph>
      <FormattedMessage defaultMessage="Verifying device. Please wait..." id="loading.message" />
    </Typography>
    <Box
      sx={{
        mx: 'auto',
        my: 4,
        width: 100,
      }}
    >
      <Circle />
    </Box>
  </Box>
);

export default Loading;
