import { IntlShape } from 'react-intl';
import * as yup from 'yup';
import {
  getFirstNameValidation,
  getLastNameValidation,
  UserFormFieldNames,
} from '../../../../../validations/user/userCommonValidations';

export interface IFormData {
  firstName?: string;
  lastName?: string;
}

export function getValidationSchema(
  formatMessage: IntlShape['formatMessage'],
  isFirstNameRequired: boolean
): yup.ObjectSchema<IFormData> {
  return yup.object().shape({
    [UserFormFieldNames.firstName]: getFirstNameValidation(isFirstNameRequired, formatMessage),

    [UserFormFieldNames.lastName]: getLastNameValidation(formatMessage),
  });
}
