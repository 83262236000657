import { ApartmentUser } from '../../../../modules/apartmentAdmin/data';
import { isUserGuest } from '../../../../modules/user/utils';
import { isUWLUser, UWLUserType } from '../../utils/UWLUserType';
import messages from '../messages';

export function isUserUWLOrGuest(user: ApartmentUser): boolean {
  return isUWLUser(user) || isUserGuest(user.role);
}

export const userDetailTitleMap = {
  [UWLUserType.NoUWL]: messages.AAUserDetailTitle,
  [UWLUserType.UWLWithPinOnly]: messages.AAUserDetailTitleUwLPIN,
  [UWLUserType.UWLWithRFIDOnly]: messages.AAUserDetailTitleUwLRFID,
  [UWLUserType.UWLWithoutCredentials]: messages.AAUserDetailTitleUwL,
  [UWLUserType.UWLWithMoreCredentials]: messages.AAUserDetailTitleUwL,
};
