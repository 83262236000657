import { defineMessages } from 'react-intl';

export default defineMessages({
  accessGroupDevicesDataGridAddDevice: {
    defaultMessage: 'Add device to group',
    description: 'Access group devices data grid - add device button label',
    id: 'accessGroupDevicesDataGrid.addDevice',
  },
  accessGroupDevicesDataGridColumnsGroups: {
    defaultMessage: 'Access groups',
    description: 'Access group devices data grid - column groups',
    id: 'accessGroupDevicesDataGrid.columns.groups',
  },
  accessGroupDevicesDataGridColumnsLogging: {
    defaultMessage: 'Logging',
    description: 'Access group devices data grid - column logging',
    id: 'accessGroupDevicesDataGrid.columns.logging',
  },
  accessGroupDevicesDataGridColumnsName: {
    defaultMessage: 'Device in group',
    description: 'Access group devices data grid - column name',
    id: 'accessGroupDevicesDataGrid.columns.name',
  },
  accessGroupDevicesDataGridColumnsRac: {
    defaultMessage: 'Residential Access',
    description: 'Access group devices data grid - column rac',
    id: 'accessGroupDevicesDataGrid.columns.rac',
  },
  accessGroupDevicesDataGridNoRowsOverlayHeaderText: {
    defaultMessage: 'No devices in Access groups yet',
    description: 'Access group devices no rows - headerText',
    id: 'accessGroupDevicesDataGrid.noRowsOverlay.headerText',
  },
  accessGroupDevicesDataGridNoRowsOverlayLoggingStatusOff: {
    defaultMessage: 'off',
    description: 'Access group devices data grid - column logging off',
    id: 'loggingStatus.off',
  },
  accessGroupDevicesDataGridNoRowsOverlayLoggingStatusOn: {
    defaultMessage: 'on',
    description: 'Access group devices data grid - column logging on',
    id: 'loggingStatus.on',
  },
  accessGroupDevicesDataGridNoRowsOverlayLoggingStatusTooltipActive: {
    defaultMessage: 'Logging on device is active',
    description: 'Access group devices data grid - logging tooltip active',
    id: 'loggingStatus.tooltip.active',
  },
  accessGroupDevicesDataGridNoRowsOverlayLoggingStatusTooltipInactive: {
    defaultMessage: 'Logging on device is inactive',
    description: 'Access group devices data grid - logging tooltip inactive',
    id: 'loggingStatus.tooltip.inactive',
  },
  accessGroupDevicesDataGridNoRowsOverlayLoggingStatusTooltipLogInactive: {
    defaultMessage: 'Logging on device is inactive',
    description: 'Access group devices data grid - logging tooltip logInactive',
    id: 'loggingStatus.tooltip.logInactive',
  },
  accessGroupDevicesDataGridNoRowsOverlayLoggingStatusTooltipRacInactive: {
    defaultMessage: "'You can resume logging on this device by' + '{br}activating the Residential Access service'",
    description: 'Access group devices data grid - logging tooltip racInactive',
    id: 'loggingStatus.tooltip.racInactive',
  },
  accessGroupDevicesDataGridNoRowsOverlayText: {
    defaultMessage:
      'Access groups define which users have access to which devices.' +
      '{br}Once you add device, it will be available to all users in this group.',
    description: 'Access group devices no rows - text',
    id: 'accessGroupDevicesDataGrid.noRowsOverlay.text',
  },
});
