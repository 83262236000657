import DeactivateIcon from '@mui/icons-material/CancelOutlined';
import ActivateRacIcon from '@mui/icons-material/Check';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import RowMenuItem from '../../../../components/DataGrid/RowMenuItem';
import messages from './messages';

const RacActivation = ({ isRacActive, onClick }) => (
  <RowMenuItem
    icon={isRacActive ? <DeactivateIcon /> : <ActivateRacIcon />}
    label={
      <FormattedMessage
        {...(isRacActive
          ? messages.accessGroupDevicesDataGridDataGridRowMenuRacDeactivate
          : messages.accessGroupDevicesDataGridDataGridRowMenuRacActivate)}
      />
    }
    onClick={onClick}
  />
);

RacActivation.propTypes = {
  isRacActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default RacActivation;
