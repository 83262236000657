import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React from 'react';

const IconBox = ({ children, icon }) => (
  <Grid alignItems="center" container spacing={1}>
    <Grid item sx={{ mt: 1 }}>
      {icon}
    </Grid>
    <Grid item>{children}</Grid>
  </Grid>
);

IconBox.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.node.isRequired,
};

export default IconBox;
