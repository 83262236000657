import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormikFields } from '../../../../../../components';
import messages from '../messages';

const VoucherName = ({ name }) => (
  <FormikFields.Input
    fullWidth
    id="PromoBatchForm-name"
    InputLabelProps={{
      required: false,
      shrink: true,
    }}
    label={<FormattedMessage {...messages.promoBatchFormInputNameLabel} />}
    margin="dense"
    name={`${name}.name`}
    required
    size="small"
    type="text"
  />
);

VoucherName.propTypes = {
  name: PropTypes.string.isRequired,
};

export default VoucherName;
