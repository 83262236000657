import React, { createRef } from 'react';
import { HeaderPublic } from '../../components';
import Footer from './Footer';
import LandingInfo from './LandingInfo';
import LandingMain from './LandingMain';
import News from './News';
import Services from './Services';
import Steps from './Steps';

const HomePage = () => {
  const effortlessLaunchRef = createRef();
  const mobileVideoServiceRef = createRef();
  const partnerApiRef = createRef();
  const remoteConfigurationServiceRef = createRef();
  const serviceRef = createRef();
  const siteManagementRef = createRef();

  return (
    <>
      <HeaderPublic />
      <LandingMain scrollToRef={effortlessLaunchRef} />
      <div ref={effortlessLaunchRef}>
        <Steps />
      </div>
      <LandingInfo
        refMobileVideoService={mobileVideoServiceRef}
        refPartnerApi={partnerApiRef}
        refRemoteConfigurationService={remoteConfigurationServiceRef}
        refSiteManagement={siteManagementRef}
      />
      <div ref={serviceRef}>
        <Services
          refMobileVideoService={mobileVideoServiceRef}
          refPartnerApi={partnerApiRef}
          refRemoteConfigurationService={remoteConfigurationServiceRef}
          refSiteManagement={siteManagementRef}
        />
      </div>
      <News />
      <Footer />
    </>
  );
};

export default HomePage;
