import { ApartmentUser } from '../apartmentAdmin/data';
import { UserCredentials } from '../commonTypes';

export function hasPIN(user: ApartmentUser): boolean {
  return hasCredential({ credential: UserCredentials.PIN, user });
}

export function hasRFID(user: ApartmentUser): boolean {
  return hasCredential({ credential: UserCredentials.RFID, user });
}

export function hasCredential({ credential, user }: { user: ApartmentUser; credential: UserCredentials }): boolean {
  return user.credentials.indexOf(credential) > -1;
}
