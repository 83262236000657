import { defineMessages } from 'react-intl';

export default defineMessages({
  accessGroupUserRemoveModalRemove: {
    defaultMessage: 'Remove',
    description: 'Access group user remove modal - remove button',
    id: 'accessGroupUserRemoveModaRemove',
  },
  accessGroupUserRemoveModalText: {
    defaultMessage:
      'The user <strong>{userName}</strong> will no longer has access via' +
      ' devices in the <strong>{groupName}</strong> access group.{br}{br}' +
      'You can add removed users back to access group anytime.',
    description: 'Access group user remove modal - text',
    id: 'accessGroupUserRemoveModalText',
  },
  accessGroupUserRemoveModalTitle: {
    defaultMessage: 'Remove from access group',
    description: 'Access group user remove modal - title',
    id: 'accessGroupUserRemoveModalTitle',
  },
});
