import { defineMessages } from 'react-intl';

export default defineMessages({
  Label: {
    defaultMessage: 'Connection mode',
    description: 'ConnectionModeField label',
    id: 'connectionModeField.Label',
  },
  networkModeChangeDialogCloudModeDialogCancel: {
    defaultMessage: 'Stay local',
    description: 'Cloud mode dialog cancel button',
    id: 'networkModeChangeDialog.cloudModeDialog.cancel',
  },
  networkModeChangeDialogCloudModeDialogConfirm: {
    defaultMessage: 'Set cloud mode',
    description: 'Cloud mode dialog confirm button',
    id: 'networkModeChangeDialog.cloudModeDialog.confirm',
  },
  networkModeChangeDialogCloudModeDialogTitle: {
    defaultMessage: 'Set to cloud mode',
    description: 'Cloud mode dialog title',
    id: 'networkModeChangeDialog.cloudModeDialog.title',
  },
  networkModeChangeDialogCloudModeDialogWarningMessageRow1: {
    defaultMessage: 'Switching to the cloud mode means you have to configure the device manually.',
    description: 'Cloud mode dialog warning message 1. row',
    id: 'networkModeChangeDialog.cloudModeDialog.warning.messageRow1',
  },
  networkModeChangeDialogCloudModeDialogWarningMessageRow2: {
    defaultMessage: 'For a step-by-step guide, see the {link}.',
    description: 'Cloud mode dialog warning message 2. row',
    id: 'networkModeChangeDialog.cloudModeDialog.warning.messageRow2',
  },
  networkModeChangeDialogLocalModeDialogCancel: {
    defaultMessage: 'Stay in cloud',
    description: 'Local mode dialog cancel button',
    id: 'networkModeChangeDialog.localModeDialog.cancel',
  },
  networkModeChangeDialogLocalModeDialogConfirm: {
    defaultMessage: 'Set local mode',
    description: 'Local mode dialog confirm button',
    id: 'networkModeChangeDialog.localModeDialog.confirm',
  },
  networkModeChangeDialogLocalModeDialogTitle: {
    defaultMessage: 'Set to local mode',
    description: 'Local mode dialog title',
    id: 'networkModeChangeDialog.localModeDialog.title',
  },
  networkModeChangeDialogLocalModeDialogWarningMessageRow1: {
    defaultMessage: 'Switching to the local connection mode will limit this' + ' device to the local area network.',
    description: 'Local mode dialog warning message 1. row',
    id: 'networkModeChangeDialog.localModeDialog.warning.messageRow1',
  },
  networkModeChangeDialogLocalModeDialogWarningMessageRow2: {
    defaultMessage: 'The device won’t be able to reach other devices within' + ' the cloud.',
    description: 'Local mode dialog warning message 2. row',
    id: 'networkModeChangeDialog.localModeDialog.warning.messageRow2',
  },
  optionsCloud: {
    defaultMessage: 'Cloud',
    description: 'ConnectionModeField option cloud',
    id: 'connectionModeField.optionsCloud',
  },
  optionsLocal: {
    defaultMessage: 'Local',
    description: 'ConnectionModeField option local',
    id: 'connectionModeField.optionsLocal',
  },
});
