import ClearIcon from '@mui/icons-material/Clear';
import UploadIcon from '@mui/icons-material/CloudUpload';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { memo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

function UploadFileButton({ disabled, file, onParseFile }) {
  const fileUploaderRef = useRef(null);
  function onClearFile() {
    onParseFile(null);
    fileUploaderRef.current.value = null;
  }

  function onSelectFile(e) {
    e.stopPropagation();
    e.preventDefault();
    onParseFile(e.target.files[0]);
  }

  function onUploadClick() {
    fileUploaderRef.current.click();
  }

  return (
    <>
      <Grid alignItems="center" container spacing={3}>
        <Grid item>
          <Box sx={{ display: 'none' }}>
            <input accept=".csv, .xls, .xlsx" onChange={onSelectFile} ref={fileUploaderRef} type="file" />
          </Box>
          <Button
            color="primary"
            disabled={disabled}
            onClick={onUploadClick}
            startIcon={<UploadIcon />}
            variant="outlined"
          >
            <FormattedMessage {...messages.siteUsersImportUploadStepButtonLabel} />
          </Button>
        </Grid>
        <Grid item>
          <Typography variant="body1">
            {file ? (
              <>
                <Box component="span" fontWeight="bold">
                  {file.name}
                </Box>
                <IconButton color="secondary" onClick={onClearFile} size="small" sx={{ ml: 0.5 }}>
                  <ClearIcon />
                </IconButton>
              </>
            ) : (
              <FormattedMessage {...messages.siteUsersImportUploadStepFileNotSelected} />
            )}
          </Typography>
        </Grid>
      </Grid>
      <FormHelperText sx={{ pt: 0.5 }}>
        <FormattedMessage {...messages.siteUsersImportUploadStepFileHelperText} />
      </FormHelperText>
    </>
  );
}

UploadFileButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  file: PropTypes.shape({
    name: PropTypes.string,
  }),
  onParseFile: PropTypes.func.isRequired,
};

UploadFileButton.defaultProps = {
  file: null,
};

export default memo(UploadFileButton);
