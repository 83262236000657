import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';

export const ImportDataContext = createContext({
  importData: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setImportData: () => {},
});

export const useImportDataContext = () => useContext(ImportDataContext);

function ImportDataProvider({ children }) {
  const [importData, setImportData] = useState([]);
  return <ImportDataContext.Provider value={{ importData, setImportData }}>{children}</ImportDataContext.Provider>;
}

ImportDataProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ImportDataProvider;
