import { defineMessages } from 'react-intl';

export default defineMessages({
  userSelectFieldAssignToMe: {
    defaultMessage: 'Assign to me ({currentUser})',
    description: 'UserSelectField - assign to me',
    id: 'userSelectField.assignToMe',
  },
  userSelectFieldCreateNewUser: {
    defaultMessage: 'Create new user',
    description: 'UserSelectField - create new user',
    id: 'userSelectField.createNewUser',
  },
  userSelectFieldNewUserEmailHelperText: {
    defaultMessage: 'The email is important, please double-check',
    description: 'Device Modal UserSelectField - new user email helper text',
    id: 'userSelectField.newUser.email.helperText',
  },
  userSelectFieldNewUserEmailLabel: {
    defaultMessage: 'New user email',
    description: 'Device Modal UserSelectField - new user email label',
    id: 'userSelectField.newUser.email.label',
  },
  userSelectFieldNewUserFirstName: {
    defaultMessage: 'New user first name',
    description: 'Device Modal UserSelectField - new user first name label',
    id: 'userSelectField.newUser.firstName',
  },
  userSelectFieldNewUserLastName: {
    defaultMessage: 'New user surname',
    description: 'Device Modal UserSelectField - new user last name label',
    id: 'userSelectField.newUser.lastName',
  },
  userSelectFieldUserHelperText: {
    defaultMessage: 'Provide a full name and email address to create a new user',
    description: 'UserSelectField - user helper text',
    id: 'userSelectField.user.helperText',
  },
  userSelectFieldUserLabel: {
    defaultMessage: 'User',
    description: 'UserSelectField - user label',
    id: 'userSelectField.user.label',
  },
});
