import { ApartmentUser } from '../../../modules/apartmentAdmin/data';
import { getUWLUserType, UWLUserType } from './UWLUserType';

export function openDeleteModalBasedOnUserType(params: {
  user: ApartmentUser;
  openDeleteUserModal: () => void;
  openUWLDeleteUserWithPINOnlyModal: () => void;
  openUWLDeleteUserWithRFIDOnlyModal: () => void;
  openUWLDeleteUserModal: () => void;
}) {
  const userType = getUWLUserType(params.user);

  switch (userType) {
    case UWLUserType.UWLWithPinOnly:
      params.openUWLDeleteUserWithPINOnlyModal();
      return;
    case UWLUserType.UWLWithRFIDOnly:
      params.openUWLDeleteUserWithRFIDOnlyModal();
      return;
    case UWLUserType.UWLWithMoreCredentials:
    case UWLUserType.UWLWithoutCredentials:
      params.openUWLDeleteUserModal();
      return;
    default:
      params.openDeleteUserModal();
  }
}
