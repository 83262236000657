import { defineMessages } from 'react-intl';

export default defineMessages({
  SiteUsersImportSummaryStepNumberOfApartments: {
    defaultMessage: '{count, plural, one {<strong>#</strong> apartment} other {<strong>#</strong> apartments}}',
    description: 'Number of apartments.',
    id: 'siteUsersImportSummaryStepNumberOfApartments',
  },
  SiteUsersImportSummaryStepNumberOfBeforeImport: {
    defaultMessage: 'Before import',
    description: 'Before import alert title.',
    id: 'siteUsersImportSummaryStepNumberOfBeforeImport',
  },
  SiteUsersImportSummaryStepNumberOfBeforeImportText: {
    defaultMessage:
      'Make sure you have read the <LinkModal >import rules</LinkModal> in detail. Unintended changes caused by importing might be time consuming to find and correct.',
    description: 'Before import alert text.',
    id: 'siteUsersImportSummaryStepNumberOfBeforeImportText',
  },
  SiteUsersImportSummaryStepNumberOfImportSummaryInfo: {
    defaultMessage:
      'The import may take a few minutes. You cannot perform any other actions in My2N while it’s in progress.',
    description: 'Import summary info.',
    id: 'siteUsersImportSummaryStepNumberOfImportSummaryInfo',
  },
  SiteUsersImportSummaryStepNumberOfTheImportContains: {
    defaultMessage: 'The import contains:',
    description: 'The import contains:',
    id: 'siteUsersImportSummaryStepNumberOfTheImportContains',
  },
  SiteUsersImportSummaryStepNumberOfUsers: {
    defaultMessage: '{count, plural, one {<strong>#</strong> user} other {<strong>#</strong> users}}',
    description: 'Number of users.',
    id: 'siteUsersImportSummaryStepNumberOfUsers',
  },
});
