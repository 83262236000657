import { LoginFlow, RecoveryFlow, SettingsFlow } from '@ory/client';
import { isArrayEmpty } from '../../helpers/array';
import { OryErrorMessageId } from '../types';

export function tryToGetFlowValidationMessageId(
  response: RecoveryFlow | LoginFlow | SettingsFlow
): OryErrorMessageId | undefined {
  let errorMessages = response.ui.messages?.filter((m) => m.type === 'error');
  if (isArrayEmpty(errorMessages)) {
    const nodesWithErrorMessages = response.ui.nodes.filter((n) => n.messages.filter((m) => m.type === 'error').length);
    if (nodesWithErrorMessages && !isArrayEmpty(nodesWithErrorMessages)) {
      errorMessages = nodesWithErrorMessages[0].messages.filter((m) => m.type === 'error');
    }
  }
  if (errorMessages && !isArrayEmpty(errorMessages)) {
    return errorMessages[0].id;
  }
  return undefined;
}
