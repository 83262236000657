import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import EllipsisText from '../../EllipsisText';
import messages from '../messages';

const AccessLogUser = ({ accessLog }) => {
  if (accessLog.user?.name) {
    return <EllipsisText>{accessLog.user?.name}</EllipsisText>;
  }

  return <FormattedMessage {...messages.noRowsSearchOverlayUnknownUser} />;
};

AccessLogUser.propTypes = {
  accessLog: PropTypes.shape({
    user: PropTypes.shape({
      name: PropTypes.string,
    }),
  }).isRequired,
};

export default AccessLogUser;
