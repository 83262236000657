import { defineMessages } from 'react-intl';

export default defineMessages({
  userAccessGroupsAlertNoApartmentRacDevice: {
    defaultMessage: "User won't have access until you set access groups.",
    description: 'User access groups alert - no RAC device',
    id: 'userAccessGroupsAlert.noApartmentRacDevice',
  },
  userAccessGroupsAlertNoRacActive: {
    defaultMessage: "User won't have access until you set credentials.",
    description: 'User access groups alert - no RAC credentials',
    id: 'userAccessGroupsAlert.noRacActive',
  },
});
