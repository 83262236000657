import { AlertTitle } from '@mui/material';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import SectionTemplate from '../SectionTemplate';
import messages from './messages';

function BlockImport() {
  const { formatMessage } = useIntl();

  return (
    <Stack spacing={3}>
      <Alert severity="error" variant="filled">
        <AlertTitle>
          <FormattedMessage {...messages.SiteUsersImportProcessingImportBlockImportCouldNotStarted} />
        </AlertTitle>
        <FormattedMessage {...messages.SiteUsersImportProcessingImportBlockImportAnotherImportInProgress} />
      </Alert>
      <SectionTemplate title={formatMessage(messages.SiteUsersImportProcessingImportBlockImportTryLaterTitle)}>
        <FormattedMessage {...messages.SiteUsersImportProcessingImportBlockImportTryLaterText} />
      </SectionTemplate>
    </Stack>
  );
}

export default BlockImport;
