import { defineMessages } from 'react-intl';

export default defineMessages({
  rcLandingPageCreateAccount: {
    defaultMessage: 'Create My2N account',
    description: 'Services - create my2n account button',
    id: 'rcLandingPage.createAccount',
  },
  rcLandingPageRcMainDescription: {
    defaultMessage: 'with the free cloud service{br}2N<sup>®</sup>{nbsp}Remote Configuration',
    description: 'Rc landing page - description',
    id: 'rcLandingPage.rcMain.description',
  },
  rcLandingPageRcMainTitle: {
    defaultMessage: 'Manage all 2N<sup>®</sup>{nbsp}IP{br} devices online',
    description: 'Rc landing page - title',
    id: 'rcLandingPage.rcMain.title',
  },
  rcLandingPageScenarioListFirstItem: {
    defaultMessage:
      "Is it challenging to arrange a meeting in a tenant's apartment" +
      ' to configure answering units? {br} With 2N<sup>®</sup>{nbsp}Remote configuration' +
      ' you can easily change the settings or make FW upgrade from your office.' +
      ' Avoid delays due to different time preferences and do the work whenever' +
      ' it is comfortable for you.',
    description: 'Rc landing page - list item first',
    id: 'rcLandingPage.scenario.list.firstItem',
  },
  rcLandingPageScenarioListSecondItem: {
    defaultMessage:
      'Do you manage more sites and want to have them all under control?' +
      ' Provide tenants with immediate and remote help through double encrypted communication' +
      ' whenever they need it. Save money and time.',
    description: 'Rc landing page - list item second',
    id: 'rcLandingPage.scenario.list.secondItem',
  },
  rcLandingPageScenarioListThirdItem: {
    defaultMessage:
      'Did you install 2N<sup>®</sup>{nbsp}LTE{nbsp}Verso within your project?' +
      ' Insert a SIM card to the 2N<sup>®</sup>{nbsp}LTE{nbsp}Verso to connect with' +
      ' the network. Then add the intercom to 2N<sup>®</sup>{nbsp}Remote Configuration' +
      ' service on My2N.com portal. Configure all its features from anywhere' +
      ' via a secured VPN connection.',
    description: 'Rc landing page - list item third',
    id: 'rcLandingPage.scenario.list.thirdItem',
  },
  rcLandingPageScenarioTitle: {
    defaultMessage: 'Most common scenarios',
    description: 'Rc landing page - scenario title',
    id: 'rcLandingPage.scenario.title',
  },
  rcLandingPageServiceFooterTitle: {
    defaultMessage: 'Try out our reliable cloud Service {br} 2N<sup>®</sup>{nbsp}Remote' + ' Configuration',
    description: 'Rc landing page - service footer title',
    id: 'rcLandingPage.serviceFooter.title',
  },
  rcLandingPageServicesListFreeOfCharge: {
    defaultMessage: 'Free of charge service',
    description: 'Rc landing page - list item free of charge',
    id: 'rcLandingPage.services.list.freeOfCharge',
  },
  rcLandingPageServicesListNetworkSetup: {
    defaultMessage: 'No need for network setup',
    description: 'Rc landing page - list item network setup',
    id: 'rcLandingPage.services.list.networkSetup',
  },
  rcLandingPageServicesListSaveOnTravelCost: {
    defaultMessage: 'Save on travel cost',
    description: 'Rc landing page - list item save on travel cost',
    id: 'rcLandingPage.services.list.saveOnTravelCost',
  },
  rcLandingPageServicesListSecureConnection: {
    defaultMessage: 'Secure connection',
    description: 'Rc landing page - list item secure connection',
    id: 'rcLandingPage.services.list.secureConnection',
  },
  rcLandingPageServicesTitle: {
    defaultMessage: 'Modern services for 21<sup>st</sup>{nbsp}century integrators',
    description: 'Rc landing page - title',
    id: 'rcLandingPage.services.title',
  },
  rcLandingPageStepsListAddDevices: {
    defaultMessage: 'Add 2N IP devices to the Sites',
    description: 'Rc landing page - list item add devices',
    id: 'rcLandingPage.steps.list.addDevices',
  },
  rcLandingPageStepsListConfigureDevice: {
    defaultMessage: 'Configure each device remotely',
    description: 'Rc landing page - list item configure device',
    id: 'rcLandingPage.steps.list.configureDevice',
  },
  rcLandingPageStepsListCreateAccount: {
    defaultMessage: 'Create My2N account',
    description: 'Rc landing page - list item create account',
    id: 'rcLandingPage.steps.list.createAccount',
  },
  rcLandingPageStepsTitle: {
    defaultMessage: 'Just <strong>3</strong> steps to set up',
    description: 'Rc landing page - steps title',
    id: 'rcLandingPage.steps.title',
  },
});
