import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { CALL_SETTINGS } from '../../../../config/consts';
import { DEVICE_TYPE } from '../../../../config/devices';
import devicesActions, { selectors as devicesSelectors } from '../../../../modules/devices';
import { CallSettingsHeader } from '../CallSettingsHeader';
import CallSettingsListActions from '../CallSettingsListActions';
import ConnectionWarningAlert from '../ConnectionWarningAlert';
import {
  handleChangeListItem,
  handleSaveIpTalkContacts,
  isConnectionWarningHandler,
  isUnlicensedDevicesWarning,
} from '../helpers';
import SettingsWarning from '../SettingsWarning';
import UnlicensedDevicesWarningAlert from '../UnlicensedDevicesWarningAlert';
import ButtonList from './ButtonList';

const IDTalk = ({
  device,
  deviceChipOptions,
  idTalkButtonList,
  isDeviceDetailEditing,
  onSaveContactList,
  ringingGroupSizeLimit,
  setDeviceEditing,
}) => {
  const [talkButtonList, setIdTalkButtonList] = useState(idTalkButtonList);

  useEffect(() => {
    setIdTalkButtonList(idTalkButtonList);
  }, [idTalkButtonList]);

  const isConnectionWarning = useMemo(() => isConnectionWarningHandler(talkButtonList), [talkButtonList]);

  const isUnlicensedWarning = useMemo(() => isUnlicensedDevicesWarning(talkButtonList), [talkButtonList]);

  return (
    <>
      <CallSettingsHeader deviceType={DEVICE_TYPE.ID_TALK} services={device.services} />
      <Box pb={2}>
        <SettingsWarning deviceType={DEVICE_TYPE.ID_TALK} services={device.services} />
      </Box>
      <Box pb={2}>
        {isConnectionWarning && <ConnectionWarningAlert deviceName={device.name} />}
        {!isConnectionWarning && isUnlicensedWarning && <UnlicensedDevicesWarningAlert />}
      </Box>
      <Box pb={2} pt={1} px={2}>
        <ButtonList
          deviceChipOptions={deviceChipOptions}
          isDeviceDetailEditing={isDeviceDetailEditing}
          list={talkButtonList}
          onChangeItem={handleChangeListItem(CALL_SETTINGS.ID_TALK.TYPE, setIdTalkButtonList)}
          ringingGroupSizeLimit={ringingGroupSizeLimit}
          setDeviceEditing={setDeviceEditing}
        />
      </Box>
      <CallSettingsListActions onSave={() => handleSaveIpTalkContacts(talkButtonList, onSaveContactList)} />
    </>
  );
};

IDTalk.propTypes = {
  device: PropTypes.shape({
    name: PropTypes.string,
    services: PropTypes.shape({}),
  }).isRequired,
  deviceChipOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  idTalkButtonList: PropTypes.arrayOf(PropTypes.object).isRequired,
  isDeviceDetailEditing: PropTypes.bool.isRequired,
  onSaveContactList: PropTypes.func.isRequired,
  ringingGroupSizeLimit: PropTypes.number.isRequired,
  setDeviceEditing: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  device: devicesSelectors.getCurrentDeviceDataSelector(state),
  deviceChipOptions: devicesSelectors.getCallSettingsDevicesOptions(state),
  idTalkButtonList: devicesSelectors.getIdTalkButtonList(state),
  isDeviceDetailEditing: devicesSelectors.isDeviceEditing(state),
  ringingGroupSizeLimit: devicesSelectors.getRingingGroupSizeLimit(state),
});

const mapDispatchToProps = {
  onSaveContactList: devicesActions.saveDeviceContactListRequest,
  setDeviceEditing: devicesActions.setDeviceEditing,
};

export default connect(mapStateToProps, mapDispatchToProps)(IDTalk);
