import { useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import BillingInfo from './BillingInfo/BillingInfo';
import PaymentMethod from './PaymentMethod';

const OrderPayment = ({
  company,
  currentMethod,
  invoiceDisabled,
  onEditBilling,
  onOrderNumberChange,
  onSetMethod,
  showInvoiceRadio,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  return (
    <Grid container spacing={matches ? 3 : 0}>
      <Grid item lg={7} xs={12}>
        <BillingInfo company={company} onEditBilling={onEditBilling} onOrderNumberChange={onOrderNumberChange} />
      </Grid>
      <Grid item lg={5} xs={12}>
        <PaymentMethod
          currentMethod={currentMethod}
          invoiceDisabled={invoiceDisabled}
          onSetMethod={onSetMethod}
          showInvoiceRadio={showInvoiceRadio}
        />
      </Grid>
    </Grid>
  );
};

OrderPayment.propTypes = {
  company: PropTypes.shape({
    address: PropTypes.shape({
      city: PropTypes.string,
      country: PropTypes.string,
      postalCode: PropTypes.string,
      street: PropTypes.string,
    }),
    billingInfoEditable: PropTypes.bool,
    name: PropTypes.string,
  }).isRequired,
  currentMethod: PropTypes.string.isRequired,
  invoiceDisabled: PropTypes.bool,
  onEditBilling: PropTypes.func.isRequired,
  onOrderNumberChange: PropTypes.func,
  onSetMethod: PropTypes.func,
  showInvoiceRadio: PropTypes.bool,
};

OrderPayment.defaultProps = {
  invoiceDisabled: false,
  onOrderNumberChange: undefined,
  onSetMethod: undefined,
  showInvoiceRadio: true,
};

export default OrderPayment;
