import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useImportDataContext } from '../ImportDataProvider';
import SectionWrapper from '../SectionWrapper';
import ImportSummary from './ImportSummary';
import messages from './messages';
import SummaryWarning from './SummaryWarning';

function SummaryStep() {
  const { importData } = useImportDataContext();
  return (
    <SectionWrapper>
      <Grid container>
        <Grid item lg={4} md={6} xs={12}>
          <ImportSummary importData={importData} />
          <Typography color="secondary" variant="body2">
            <FormattedMessage {...messages.SiteUsersImportSummaryStepNumberOfImportSummaryInfo} />
          </Typography>
        </Grid>
        <Grid item lg={4} md={6} xs={12}>
          <SummaryWarning />
        </Grid>
      </Grid>
    </SectionWrapper>
  );
}

export default SummaryStep;
