import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';

const boxLimit = 8;

export interface IMobileVideoProps {
  apartmentLimit: number,
  component: React.JSX.Element,
  label: React.JSX.Element,
  value: React.JSX.Element
}

export function MobileVideoItem({ apartmentLimit, component, label, value}: IMobileVideoProps) {
  return (
    <Stack spacing={0.1}>
      <Typography color="secondary" variant="subtitle2">
        {label}
      </Typography>
        {component}
      {apartmentLimit <= boxLimit ? (
        <Typography color="secondary" variant="body2">
        {value}
        </Typography>
      ) : (
        <Typography variant="body1">
          {value}
        </Typography>
      )}
    </Stack>
  )
}
