import { defineMessages } from 'react-intl';

export default defineMessages({
  promoBatchFormInputNameLabel: {
    defaultMessage: 'Voucher name',
    description: 'Batch name input label',
    id: 'promoBatchForm.input.name.label',
  },
  promoBatchFormInputPriceLabel: {
    defaultMessage: 'Total price',
    description: 'Batch price input label',
    id: 'promoBatchForm.input.price.label',
  },
  promoBatchFormInputSubscriptionsAdornmentApartments: {
    defaultMessage: 'apartments',
    description: 'Batch subscriptions input helper adornment apartments',
    id: 'promoBatchForm.input.subscriptions.adornment.apartments',
  },
  promoBatchFormInputSubscriptionsAdornmentDevices: {
    defaultMessage: 'devices',
    description: 'Batch subscriptions input helper adornment devices',
    id: 'promoBatchForm.input.subscriptions.adornment.devices',
  },
  promoBatchFormInputSubscriptionsLabel: {
    defaultMessage: 'Number of subscriptions',
    description: 'Batch subscriptions input label',
    id: 'promoBatchForm.input.subscriptions.label',
  },
  promoBatchFormInputValueAdornmentApartmentYear: {
    defaultMessage: 'year/apartment',
    description: 'Batch value input helper adornment apartment year',
    id: 'promoBatchForm.input.value.adornment.apartmentYear',
  },
  promoBatchFormInputValueAdornmentApartmentYears: {
    defaultMessage: 'years/apartment',
    description: 'Batch value input helper adornment apartment years',
    id: 'promoBatchForm.input.value.adornment.apartmentYears',
  },
  promoBatchFormInputValueAdornmentDeviceYear: {
    defaultMessage: 'year/device',
    description: 'Batch value input helper adornment device year',
    id: 'promoBatchForm.input.value.adornment.deviceYear',
  },
  promoBatchFormInputValueAdornmentDeviceYears: {
    defaultMessage: 'years/device',
    description: 'Batch value input helper adornment device years',
    id: 'promoBatchForm.input.value.adornment.deviceYears',
  },
  promoBatchFormInputValueLabel: {
    defaultMessage: 'Total voucher value',
    description: 'Batch value input label',
    id: 'promoBatchForm.input.value.label',
  },
  promoBatchFormInputYearsCountAdornment: {
    defaultMessage: 'years',
    description: 'Batch code years count input adornment',
    id: 'promoBatchForm.input.yearsCount.adornment',
  },
  promoBatchFormInputYearsCountLabel: {
    defaultMessage: 'Number of years',
    description: 'Batch code years count input label',
    id: 'promoBatchForm.input.yearsCount.label',
  },
  promoBatchFormSelectLabelDeviceApartment: {
    defaultMessage: '(device/apartment)',
    description: 'Select device / apartment label',
    id: 'promoBatchForm.select.labelDeviceApartment',
  },
  promoBatchFormSelectLabelSubscriptionType: {
    defaultMessage: 'Subscription type',
    description: 'Select subscription type label',
    id: 'promoBatchForm.select.labelSubscriptionType',
  },
  promoBatchFormSelectPlaceholder: {
    defaultMessage: 'Select your subscription',
    description: 'Select subscription type placeholder',
    id: 'promoBatchForm.select.placeholder',
  },
  promoBatchFormSelectSelectSubscription: {
    defaultMessage: 'Select your subscription',
    description: 'Select your subscription',
    id: 'promoBatchForm.select.selectSubscription',
  },
});
