import FilterNoneIcon from '@mui/icons-material/FilterNone';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import BatchSwitchPanel from '../BatchSwitchPanel';
import { VIEW } from '../BatchSwitchPanel/consts';
import CodesTextCopyField from '../CodesTextCopyField';
import Modal from '../Modal';
import BasicDataPanel from './BasicDataPanel';
import DetailBatchInfoPanel from './DetailBatchInfoPanel';
import messages from './messages';

const DetailBatchDialog = ({ codes, data, onCloseBatch, onCloseDialog, onCopyAllCodes, onLoadCodes, show }) => {
  const [view, setView] = useState(VIEW.INFO);
  useEffect(() => {
    onLoadCodes(data.id);
  }, [onLoadCodes]);

  const filterCode = (code) => {
    if (view === VIEW.ACTIVE) {
      return code.state === 'CREATED';
    }
    if (view === VIEW.USED) {
      return code.state === 'USED';
    }
    if (view === VIEW.CANCELED) {
      return code.state === 'CANCELLED';
    }
    return view === VIEW.ALL;
  };

  return (
    <Modal
      actions={
        <div>
          <Button onClick={onCloseDialog}>
            <FormattedMessage {...messages.basicDataPanelDetailBatchDialogCloseButton} />
          </Button>
          <Button color="primary" onClick={onCopyAllCodes} startIcon={<FilterNoneIcon />} variant="contained">
            <FormattedMessage {...messages.basicDataPanelDetailBatchDialogCopyAllButton} />
          </Button>
        </div>
      }
      maxWidth="md"
      name="detail-batch-dialog"
      onClose={onCloseDialog}
      open={show}
      title={
        <FormattedMessage
          {...messages.basicDataPanelDetailBatchDialogTitle}
          values={{
            name: data.name,
            strong: (chunks) => <strong>{chunks}</strong>,
          }}
        />
      }
    >
      <BasicDataPanel data={data} onCloseBatch={onCloseBatch} onCloseDialog={onCloseDialog} />
      <BatchSwitchPanel currentView={view} onSelectView={setView} stats={data.stats || {}} />
      {view === VIEW.INFO && <DetailBatchInfoPanel data={data} />}
      {[VIEW.ACTIVE, VIEW.USED, VIEW.CANCELED, VIEW.ALL].includes(view) && (
        <CodesTextCopyField
          data={codes.filter(filterCode).map((code) => ({
            closedAt: code?.closedAt,
            code: code.code,
            context: code.context || {},
            credit: data.configuration.credit,
            dateCreated: data.dateCreated,
            state: code.state,
            validityTo: data.validityTo,
          }))}
          usedView={view === VIEW.USED}
        />
      )}
    </Modal>
  );
};

DetailBatchDialog.propTypes = {
  codes: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      state: PropTypes.string,
    })
  ).isRequired,
  data: PropTypes.shape({
    channel: PropTypes.oneOf(['PROMO', 'PURCHASE']),
    configuration: PropTypes.shape({
      credit: PropTypes.number,
      type: PropTypes.string,
    }),
    createdBy: PropTypes.shape({
      name: PropTypes.string,
    }),
    dateCreated: PropTypes.string,
    id: PropTypes.string,
    invoice: PropTypes.string,
    name: PropTypes.string,
    notes: PropTypes.string,
    stats: PropTypes.shape({
      CANCELLED: PropTypes.number,
      CREATED: PropTypes.number,
      EXPIRED: PropTypes.number,
      LOCKED: PropTypes.number,
      USED: PropTypes.number,
    }),
    utm: PropTypes.string,
    validityTo: PropTypes.string,
  }).isRequired,
  onCloseBatch: PropTypes.func.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  onCopyAllCodes: PropTypes.func.isRequired,
  onLoadCodes: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default DetailBatchDialog;
