import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';
import LoadingButton from '../LoadingButton';

const styles = {
  root: {
    '& button:not(:first-of-type)': {
      marginLeft: 1,
    },
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 2,
  },
};

const FormAction = ({
  disabled,
  onClick,
  onPrevStep,
  prevStepText,
  submitLoading,
  submitStartIcon,
  submitText,
  type,
}) => (
  <Box sx={styles.root}>
    {prevStepText && onPrevStep && (
      <Button onClick={onPrevStep} type="button">
        {prevStepText}
      </Button>
    )}
    <LoadingButton
      color="primary"
      disabled={disabled}
      id="nextstep-button"
      isLoading={submitLoading}
      onClick={onClick || null}
      startIcon={submitStartIcon}
      type={type}
      variant="contained"
    >
      {submitText}
    </LoadingButton>
  </Box>
);

FormAction.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  onPrevStep: PropTypes.func,
  prevStepText: PropTypes.node,
  submitLoading: PropTypes.bool,
  submitStartIcon: PropTypes.node,
  submitText: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['submit', 'button']).isRequired,
};

FormAction.defaultProps = {
  onClick: null,
  onPrevStep: null,
  prevStepText: null,
  submitLoading: false,
  submitStartIcon: null,
};

export default FormAction;
