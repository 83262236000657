import { validFieldsForm } from './helpers';
import types from './types';

export const initialState = {
  editBillingModal: {
    isFetching: false,
    show: false,
    values: {
      city: '',
      country: '',
      currency: '',
      name: '',
      postalCode: '',
      street: '',
      vatNumber: '',
    },
    verify: false,
  },
};

const reducers = {
  [types.EDIT_BILLING_AUTOFILL_VAT_NUMBER_PREFIX]: (state, { prefix }) => {
    const values = {
      ...state.editBillingModal.values,
      vatNumber: prefix,
    };
    return {
      ...state,
      editBillingModal: {
        ...state.editBillingModal,
        values,
        verify: validFieldsForm(values),
      },
    };
  },

  [types.EDIT_BILLING_SHOW_SAGA_MODAL]: (state, { billingData }) => ({
    ...state,
    editBillingModal: {
      ...initialState.editBillingModal,
      show: true,
      values: {
        ...initialState.editBillingModal.values,
        ...billingData,
      },
    },
  }),

  [types.EDIT_BILLING_HIDE_MODAL]: (state) => ({
    ...state,
    editBillingModal: {
      ...initialState.editBillingModal,
    },
  }),

  [types.EDIT_BILLING_FORM_REQUEST]: (state) => ({
    ...state,
    editBillingModal: {
      ...state.editBillingModal,
      isFetching: true,
    },
  }),

  [types.EDIT_BILLING_FORM_SUCCESS]: (state) => ({
    ...state,
    editBillingModal: {
      ...initialState.editBillingModal,
    },
  }),

  [types.EDIT_BILLING_FORM_FAILURE]: (state) => ({
    ...state,
    editBillingModal: {
      ...initialState.editBillingModal,
    },
  }),
};

const reducer = (state = initialState, action = {}) => {
  if (reducers[action.type]) {
    return reducers[action.type](state, action);
  }
  return state;
};

export default reducer;
