import { defineMessages } from 'react-intl';

export default defineMessages({
  AuthLoginAlertTooManyAttempts: {
    defaultMessage: 'Too many login attempts. You can try again at {count}.',
    description: 'Auth Login Alert - too many attempts',
    id: 'authLogin.alert.tooManyAttempts',
  },
  authLoginEmailInvalid: {
    defaultMessage: 'Wrong format of the e-mail.',
    description: 'Auth Login - email invalid',
    id: 'authLogin.email.invalid',
  },
  authLoginEmailLabel: {
    defaultMessage: 'Your e-mail',
    description: 'Auth Login - email label',
    id: 'authLogin.email.label',
  },
  authLoginEmailRequired: {
    defaultMessage: 'To log in enter your e-mail address.',
    description: 'Auth Login - email is required',
    id: 'authLogin.email.required',
  },
  authLoginFailed: {
    defaultMessage: 'Registration failed, your link has expired',
    id: 'authLogin.failureMsg',
  },
  authLoginForgottenPassword: {
    defaultMessage: 'Forgotten password?',
    description: 'Auth Login - forgotten password',
    id: 'authLogin.forgottenPassword',
  },
  authLoginPasswordLabel: {
    defaultMessage: 'Password',
    description: 'Auth Login - password label',
    id: 'authLogin.password.label',
  },
  authLoginPasswordRequired: {
    defaultMessage: 'Enter your password.',
    description: 'Auth Login - password is required',
    id: 'authLogin.password.required',
  },
  authLoginSubmit: {
    defaultMessage: 'Log in',
    description: 'Auth Login - submit button',
    id: 'authLogin.submit',
  },
  authLoginTitle: {
    defaultMessage: 'Log in',
    description: 'Auth Login - title',
    id: 'authLogin.title',
  },
  authLoginUnregisteredAction: {
    defaultMessage: 'sign up here.',
    description: 'Auth Login - sign up',
    id: 'authLogin.unregistered.action',
  },
  authLoginUnregisteredText: {
    defaultMessage: 'To create your own My2N company and sites,',
    description: 'Auth Login - unregistered text',
    id: 'authLogin.unregistered.text',
  },
});
