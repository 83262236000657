import { Delete } from '@mui/icons-material';
import { Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ActionModalError } from '../../../../components/_DesignSystemWrappers/Modal';
import { ApartmentUser } from '../../../../modules/apartmentAdmin/data';
import { IApartmentIdsInfo } from '../../../../modules/apartments/store';
import { IModalBase } from '../../../../modules/commonTypes';
import { TestID } from '../../ApartmentAdminUsersOverviewPage/components/UsersInApartmentDataGrid/testIds';
import { useDeleteUserFromApartment } from '../hooks/useDeleteUserFromApartment';
import { UWLName } from '../UWLName';
import messages from './messages';

export interface UWLDeleteUserWithRFIDOnlyModalProps extends IModalBase {
  user: ApartmentUser;
  apartmentIdsInfo: IApartmentIdsInfo;
}

export function UWLDeleteUserWithRFIDOnlyModal({
  apartmentIdsInfo,
  onClose,
  open,
  user,
}: UWLDeleteUserWithRFIDOnlyModalProps) {
  const { formatMessage, isSubmitting, onDeleteHandler } = useDeleteUserFromApartment({
    apartmentIdsInfo,
    errorMessage: messages.AAmodalDeleteUserRFIDMsgErr,
    onClose,
    successMessage: messages.AAmodalDeleteUserRFIDMsgSucc,
    user,
  });

  return (
    <ActionModalError
      icon={<Delete />}
      onClose={onClose}
      open={open}
      testId={TestID.UWLDeleteUserWithRfidOnlyModal}
      muiDialogProps={{ maxWidth: 'xs' }}
      primaryButtonAction={onDeleteHandler}
      primaryButtonText={formatMessage(messages.AAmodalDeleteUserRFIDSubmit)}
      secondaryButtonText={formatMessage(messages.AAmodalDeleteUserRFIDCancel)}
      isPrimaryButtonDisabled={isSubmitting}
      title={formatMessage(messages.AAmodalDeleteUserRFIDTitle)}
    >
      <UWLName name={{ firstName: user.firstName, lastName: user.lastName }} />
      <Typography variant="body2" component="span">
        <FormattedMessage {...messages.AAmodalDeleteUserRFIDText} />
      </Typography>
    </ActionModalError>
  );
}
