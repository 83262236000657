import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { SERVICE } from '../../config/devices';
import { preferUserLogin as preferUserLoginSelector } from '../../modules/auth/selectors';
import DeviceAppLoginInfo from './components/DeviceAppLoginInfo';
import DeviceBasicDetails from './components/DeviceBasicDetails';
import DeviceCompanyDetails from './components/DeviceCompanyDetails';
import DeviceCredentialsDetails from './components/DeviceCredentialsDetails';
import DeviceQrCodeDetails from './components/DeviceQrCodeDetails';

const styles = {
  paper: {
    p: 3,
    wordBreak: 'break-word',
  },
};

const UserLoserDevice = ({ device, onShowInstallAppModal }) => {
  const isDeviceMigrated = !device.services[SERVICE.NOTIFICATION].legacyAccessEnabled;
  const preferUserLogin = useSelector(preferUserLoginSelector);

  return (
    <Paper sx={styles.paper} data-testid="user-loser-device">
      <Grid container spacing={2}>
        <DeviceBasicDetails device={device} />
        <DeviceCompanyDetails deviceSite={device.site} />
        {!isDeviceMigrated && !preferUserLogin && <DeviceCredentialsDetails deviceServices={device.services} />}
        <DeviceQrCodeDetails
          deviceQrCodeUrl={`${device.services[SERVICE.NOTIFICATION].qrCodeUrl}${
            preferUserLogin ? '&preferUserLogin' : ''
          }`}
          isDeviceMigrated={isDeviceMigrated || preferUserLogin}
        />
        {(isDeviceMigrated || preferUserLogin) && <DeviceAppLoginInfo onShowInstallAppModal={onShowInstallAppModal} />}
      </Grid>
    </Paper>
  );
};

UserLoserDevice.propTypes = {
  device: PropTypes.shape({
    name: PropTypes.string,
    services: PropTypes.shape({
      [SERVICE.MOBILE_VIDEO]: PropTypes.shape({
        sipNumber: PropTypes.string,
        sipPassword: PropTypes.string,
      }),
      [SERVICE.NOTIFICATION]: PropTypes.shape({
        legacyAccessEnabled: PropTypes.bool,
        qrCodeUrl: PropTypes.string,
      }),
    }),
    site: PropTypes.shape({
      company: PropTypes.shape({
        name: PropTypes.string,
      }),
      my2nId: PropTypes.string,
      name: PropTypes.string,
    }),
  }).isRequired,
  onShowInstallAppModal: PropTypes.func.isRequired,
};

export default UserLoserDevice;
