import { Location } from 'react-router-dom';
import { FE_ROLES, PATHS, ROLES } from '../../config/consts';
import { isApartmentAdmin } from '../../helpers/user';
import { Roles } from '../commonTypes';
import { ICompany } from '../company/store';
import { ISite } from '../sites/store';
import { IUserBase, UserRoles } from './store';

interface ISites extends ISite {
  id: number;
  roles: UserRoles;
}

interface ICompanies extends ICompany {
  sites: ISites[];
  roles: UserRoles;
}

interface IRedirectUser {
  data: ICompanies[];
  id: number;
  location: Location;
}

interface INewSiteUserCreator {
  companyId: number | string;
  siteId: number | string;
  newUser: IUserBase;
}

export function redirectUser({ data, id, location }: IRedirectUser): string | undefined {
  const targetCompany = data.find((company) => company.id === id);
  const isSiteAdmin = targetCompany?.sites.find((item) => item?.roles.includes(ROLES.ADMIN));
  const siteId = isSiteAdmin?.id;
  const isCompanyAdmin = targetCompany?.roles.includes(ROLES.COMPANY_ADMIN);

  let destinationPath = PATHS.USER_PROFILE;
  if (isCompanyAdmin) {
    destinationPath = PATHS.COMPANY_DASHBOARD.replace(':companyId', id.toString());
  } else if (isSiteAdmin && siteId !== undefined) {
    destinationPath = PATHS.SITE_DASHBOARD.replace(':companyId', id.toString()).replace(':siteId', siteId.toString());
  } else if (isApartmentAdmin(data)) {
    destinationPath = PATHS.USERS_IN_APARTMENTS;
  }

  if (location.pathname !== destinationPath) {
    return destinationPath;
  }

  return undefined;
}

export const newSiteUserCreator = ({ companyId, newUser, siteId }: INewSiteUserCreator) => ({
  ...(newUser.email ? { email: newUser.email } : { type: FE_ROLES.LOGIN_LESS }),
  firstName: newUser.firstName ? newUser.firstName : null,
  grantedRoles: {
    companyRoles: [
      {
        companyId,
        types: [ROLES.STANDARD],
      },
    ],
    siteRoles: [
      {
        siteId,
        types: [ROLES.USER],
      },
    ],
  },
  lastName: newUser.lastName,
});

export function getRedirectPathForDeletedUser(companyId: number, siteId?: number, apartmentId?: number): string {
  if (siteId !== undefined && apartmentId !== undefined) {
    return PATHS.APARTMENT_USERS.replace(':companyId', companyId.toString())
      .replace(':siteId', siteId.toString())
      .replace(':apartmentId', apartmentId.toString());
  }

  if (siteId !== undefined) {
    return PATHS.SITE_USERS.replace(':companyId', companyId.toString()).replace(':siteId', siteId.toString());
  }

  return PATHS.COMPANY_ADMIN.replace(':companyId', companyId.toString());
}

export function isApartmentAdminInSite(site: { apartments?: { roles?: Roles[] }[] }): boolean {
  return !!site?.apartments?.find((apartment) => apartment?.roles?.includes(Roles.APARTMENT_ADMIN));
}
