import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';

const PrimaryValue = ({ value }) => (
  <Box color="primary.main" component="span" fontSize="h5.fontSize">
    {value}
  </Box>
);

PrimaryValue.propTypes = {
  value: PropTypes.string.isRequired,
};

export default PrimaryValue;
