import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { transformJsonData } from '../helpers/transformJsonData';
import { useParseCsv } from '../hooks/useParseCsv';
import { useImportDataContext } from '../ImportDataProvider';
import SectionWrapper from '../SectionWrapper';
import InfoAlert from './InfoAlert';
import ManualImport from './ManualImport';
import messages from './messages';
import UploadFileButton from './UploadFileButton';

function UploadStep() {
  const { importData, setImportData } = useImportDataContext();
  const { file, jsonData, onParseFile } = useParseCsv();

  useEffect(() => {
    if (jsonData) {
      setImportData(transformJsonData(jsonData));
    } else {
      setImportData([]);
    }
  }, [jsonData]);

  function handleSetManualData(manualData) {
    setImportData(transformJsonData(manualData));
  }

  return (
    <SectionWrapper>
      <Grid alignItems="flex-start" container spacing={2} sx={{ pt: 2 }}>
        <Grid container direction="column" item md={4} spacing={4} xs={12}>
          <Grid item>
            <Typography>
              <FormattedMessage {...messages.siteUsersImportUploadStepInfo} />
            </Typography>
          </Grid>
          <Grid item>
            <UploadFileButton disabled={!!importData.length && !file} file={file} onParseFile={onParseFile} />
          </Grid>
          <Grid item>
            <InfoAlert />
          </Grid>
        </Grid>
        <Grid item xs>
          <ManualImport disabled={!!file} onSetImportData={handleSetManualData} />
        </Grid>
      </Grid>
    </SectionWrapper>
  );
}

export default UploadStep;
