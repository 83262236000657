import { PAYMENT_CONTEXT } from '../../config/payments';
import { table } from '../../config/styles';

export default {
  bottomText: {
    '& a:visited': {
      color: 'primary.main',
    },
    mt: 3,
  },
  danger: {
    color: 'error.main',
  },
  dateWrapper: {
    display: 'grid',
    textAlign: 'center',
    width: 'max-content',
  },
  details: {
    color: 'grey.600',
    fontSize: '10px',
  },
  errorInfoIcon: {
    display: 'flow-root',
    width: 'max-content',
  },
  infoIcon: {
    color: 'rgba(0,0,0,.54)',
    mx: 2,
    my: 0,
  },
  loading: {
    width: '100%',
  },
  loadingWrapper: {
    backgroundColor: 'inherit !important',
  },
  mobileVideoImg: {
    float: 'left',
    height: '40px',
    mr: 1,
    width: '46px',
  },
  mVideo: {
    display: 'block',
    fontWeight: 'bold',
    minWidth: '100px',
  },
  name: {
    color: 'black',
    fontWeight: 'bold',
    mr: 0.5,
    textAlign: 'left',
  },
  noResults: {
    '& td': {
      fontWeight: 'bold',
      textAlign: 'center',
    },
  },
  price: {
    color: 'grey.600',
    ml: 0.5,
  },
  root: {
    display: 'block',
    padding: '32px',
  },
  rowOdd: {
    backgroundColor: 'grey.100',
  },
  service: {
    alignItems: 'center',
    display: 'flex',
    textAlign: 'left',
  },
  siteName: (context) => ({
    color: 'grey.600',
    display: 'flow-root',
    maxWidth: context === PAYMENT_CONTEXT.RECENT_PAYMENTS ? '500px' : '200px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  subscription: {
    color: 'grey.600',
  },
  success: {
    color: 'success.main',
  },
  table: (props) => ({
    ...props,
    ...table(),
    '& tbody tr': {
      height: '62px',
    },
  }),
  tableWrapper: {
    overflowX: 'auto',
    width: '100%',
  },
  warning: {
    color: 'warning.main',
  },
};
