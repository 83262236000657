import { useSelector } from 'react-redux';
import { MobileVideoStatus, MobileVideoType } from '../../modules/commonTypes';
import { getCompanyData } from '../../modules/company/selectors';
import { isLastMVPaymentFinished as isLastMVPaymentFinishedSelector } from '../../modules/payments/selectors';
import { getDashboardMvService } from '../../modules/sites/selectors';
import { getIsVatVerified, getIsVatVerifiedFetching } from '../../modules/subscription/selectors';

export function useGetSubscriptionCardsData() {
  const company = useSelector(getCompanyData) as ICompany;
  const dashboardMvService = useSelector(getDashboardMvService) as IDashboardMvService;
  const isLastMVPaymentFinished = useSelector(isLastMVPaymentFinishedSelector);
  const isVatVerified = useSelector(getIsVatVerified);
  const isVatVerifiedLoading = useSelector(getIsVatVerifiedFetching);

  return {
    company,
    dashboardMvService,
    isLastMVPaymentFinished,
    isVatVerified,
    isVatVerifiedLoading,
  };
}

export interface IAddress {
  city?: string;
  country?: string;
  postalCode?: string;
  street?: string;
}

export interface ICompany {
  address?: IAddress;
  id: number;
  name: string;
  billingInfoEditable: boolean;
}

export interface ILicenceModel {
  status?: MobileVideoStatus;
  type?: MobileVideoType;
}

export interface IDashboardMvService {
  licenceModel: ILicenceModel;
  paymentFailure?: boolean;
  subscribed?: boolean;
}
