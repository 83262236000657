import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  actionsRoot: {
    '& button': {
      '& span': {
        ml: {
          sm: -0.5,
          xs: 0,
        },
        mr: {
          sm: 1,
          xs: 0,
        },
      },
      '& svg': {
        height: '24px',
        width: '24px',
      },
      height: '47px',
      minWidth: 'fit-content',
    },
    paddingRight: '0 !important',
  },
  rightSide: {
    paddingRight: '0 !important',
  },
  root: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: 3.5,
  },
};

const Title = ({ heading, leftSide, rightSide, rightSideActions }) => (
  <Grid container spacing={2} sx={styles.root}>
    <Grid container item sm xs={10}>
      {leftSide && <Grid item>{leftSide}</Grid>}
      <Grid item>
        <Typography component="span" paragraph variant="h4">
          <Box my={0}>{heading}</Box>
        </Typography>
      </Grid>
    </Grid>
    <Grid item>
      <Grid container item spacing={2}>
        {rightSide && (
          <Grid item sx={styles.rightSide}>
            {rightSide}
          </Grid>
        )}
        {rightSideActions && (
          <Grid item sx={styles.actionsRoot}>
            {rightSideActions}
          </Grid>
        )}
      </Grid>
    </Grid>
  </Grid>
);

Title.propTypes = {
  heading: PropTypes.any.isRequired,
  leftSide: PropTypes.any,
  rightSide: PropTypes.any,
  rightSideActions: PropTypes.any,
};

Title.defaultProps = {
  leftSide: null,
  rightSide: null,
  rightSideActions: null,
};

export default Title;
