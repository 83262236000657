import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import http from '../../api';
import { FooterPublic, HeaderPublic } from '../../components';
import messages from './messages';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const landingBgImage = require('../../assets/landing_bg.jpg');

const styles = {
  box: {
    bgcolor: 'common.white',
    borderRadius: 1,
    boxSizing: 'border-box',
    my: 'auto',
    px: { lg: 3.6, xs: 1.75 },
    py: { lg: 4.1, xs: 1.75 },
    width: '100%',
  },
  boxContent: {
    maxWidth: '610px',
    mx: 'auto',
    my: 'auto',
    p: 1.25,
    width: '100%',
  },
  sub: {
    '& a': {
      color: 'primary.main',
      fontWeight: 'bold',
      ml: 0.5,
      textDecoration: 'none',
      textTransform: 'uppercase',
    },
    color: 'common.white',
    mb: 9,
    mt: 10,
    textAlign: 'center',
  },
  title: {
    color: 'common.white',
    fontSize: '32px',
    fontWeight: 'bold',
    mb: 3,
    mt: { sm: '55px', xl: '60px' },
    textAlign: 'center',
  },
  unsubscribe: {
    bgcolor: '#E8F5E9',
    boxSizing: 'border-box',
    display: 'flex',
    fontSize: 14,
    gap: 1.4,
    px: { lg: 2.5, xs: 1.75 },
    py: { lg: 2, xs: 1.75 },
    width: '100%',
  },
  wrapper: {
    backgroundImage: `url(${landingBgImage})`,
    backgroundPosition: '50% 20%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    minHeight: '980px',
    mx: 'auto',
    my: 0,
  },
};

export function SuspiciousLoginPage() {
  const { token } = useParams();
  useEffect(() => {
    async function callApi() {
      http.get(`/unsubscribe-suspicious-login/${token}`);
    }
    callApi();
  }, []);

  return (
    <>
      <HeaderPublic />
      <Box sx={styles.wrapper}>
        <Box sx={styles.boxContent}>
          <Typography sx={styles.title} variant="h1">
            <FormattedMessage {...messages.suspiciousTitle} />
          </Typography>
          <Box sx={styles.box}>
            <Box sx={styles.unsubscribe}>
              <CheckCircleIcon style={{ color: '#43A047' }} />
              <Typography style={{ fontSize: '14px', marginTop: 'auto' }}>
                <FormattedMessage {...messages.suspiciousBody} />
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <FooterPublic />
    </>
  );
}
