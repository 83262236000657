import { defineMessages } from 'react-intl';

export default defineMessages({
  accessGroupUsersDataGridDataGridRowMenuAccessGroups: {
    defaultMessage: 'Set access groups',
    description: 'Table row actions - access groups',
    id: 'accessGroupUsersDataGrid.dataGridRowMenu.accessGroups',
  },
  accessGroupUsersDataGridDataGridRowMenuCard: {
    defaultMessage: 'Assign new card',
    description: 'Table row actions - card',
    id: 'accessGroupUsersDataGrid.dataGridRowMenu.card',
  },
  accessGroupUsersDataGridDataGridRowMenuEdit: {
    defaultMessage: 'Edit user',
    description: 'Table row actions - edit',
    id: 'accessGroupUsersDataGrid.dataGridRowMenu.edit',
  },
  accessGroupUsersDataGridDataGridRowMenuLogActivate: {
    defaultMessage: 'Activate logging',
    description: 'Table row actions - log activate',
    id: 'accessGroupUsersDataGrid.dataGridRowMenu.logActivate',
  },
  accessGroupUsersDataGridDataGridRowMenuPin: {
    defaultMessage: 'Generate new PIN',
    description: 'Table row actions - pin',
    id: 'accessGroupUsersDataGrid.dataGridRowMenu.pin',
  },
  accessGroupUsersDataGridDataGridRowMenuRemove: {
    defaultMessage: 'Remove from group',
    description: 'Table row actions - remove',
    id: 'accessGroupUsersDataGrid.dataGridRowMenu.remove',
  },
});
