import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import actions from '../../modules/accessSettings';
import PrimaryModal from '../PrimaryModal';
import AddDeviceAutocomplete from './AddDeviceAutocomplete';
import { useGetDeviceFormData } from './hooks/getDeviceFormData';
import { useGetGlobalHooks } from './hooks/getGlobalHooks';
import messages from './messages';

function AccessGroupAddDeviceModal({ groupId, onClose, open }) {
  const { dispatch, formatMessage, formMethods } = useGetGlobalHooks();
  const { devices, isLoading } = useGetDeviceFormData();
  const { isDirty, isValid } = formMethods.formState;

  useEffect(() => {
    if (open) {
      formMethods.reset({
        device: null,
      });
      dispatch(actions.getAccessControlDevices(groupId));
    }
  }, [open]);

  const handleAddDevice = (data) => {
    dispatch(actions.addAccessGroupDevice(groupId, [data.device.deviceId]));
    onClose();
  };

  const getIsOpen = open || isLoading;
  return (
    getIsOpen && (
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleAddDevice)}>
          <PrimaryModal
            actionButtonLabel={formatMessage(messages.accessGroupAddDeviceModalAdd)}
            isActionButtonDisabled={!isDirty || !isValid}
            isLoading={isLoading}
            name="access-group-add-device-modal"
            onClose={onClose}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onCreate={() => {}}
            open={getIsOpen}
            title={formatMessage(messages.accessGroupAddDeviceModalTitle)}
          >
            <ModalText />
            <AddDeviceAutocomplete devices={devices} />
          </PrimaryModal>
        </form>
      </FormProvider>
    )
  );
}

function ModalText() {
  return (
    <Typography variant="body1">
      <FormattedMessage {...messages.accessGroupAddDeviceModalText} values={{ br: <br /> }} />
    </Typography>
  );
}

AccessGroupAddDeviceModal.propTypes = {
  groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

AccessGroupAddDeviceModal.defaultProps = {
  groupId: '',
};

export default AccessGroupAddDeviceModal;
