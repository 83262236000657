import { defineMessages } from 'react-intl';

export default defineMessages({
  siteUserDevicesDataGridColumnsActiveServices: {
    defaultMessage: 'Active services',
    description: 'Device Data Grid columns - active services',
    id: 'siteUserDevicesDataGrid.columns.activeServices',
  },
  siteUserDevicesDataGridColumnsApartment: {
    defaultMessage: 'Apartment',
    description: 'Device Data Grid columns - apartment',
    id: 'siteUserDevicesDataGrid.columns.apartment',
  },
  siteUserDevicesDataGridColumnsDeviceName: {
    defaultMessage: 'Device name',
    description: 'Device Data Grid columns - device name',
    id: 'siteUserDevicesDataGrid.columns.deviceName',
  },
  siteUserDevicesDataGridColumnsDeviceStatus: {
    defaultMessage: 'Device status',
    description: 'Device Data Grid columns - device status',
    id: 'siteUserDevicesDataGrid.columns.deviceStatus',
  },
});
