import { defineMessages } from 'react-intl';

export default defineMessages({
  deviceApproveModalApartmentNumberExists: {
    defaultMessage: 'An apartment with this number already exists.',
    id: 'deviceApproveModal.apartment.numberExists',
  },
  deviceApproveModalApartmentRequired: {
    defaultMessage: 'Choose an apartment',
    id: 'deviceApproveModal.apartment.required',
  },
  deviceApproveModalBasicTitle: {
    defaultMessage: 'Approve device',
    id: 'deviceApproveModal.basicTitle',
  },
  deviceApproveModalCancel: {
    defaultMessage: 'Cancel',
    id: 'deviceApproveModal.cancel',
  },
  deviceApproveModalDescription: {
    defaultMessage: 'Device will be added to My2N.',
    id: 'deviceApproveModal.description',
  },
  deviceApproveModalNameExist: {
    defaultMessage: 'Device with this name already exists.',
    id: 'deviceApproveModal.name.exist',
  },
  deviceApproveModalNameHelper: {
    defaultMessage: 'We recommend naming the device after its location, such as Main Entrance.',
    id: 'deviceApproveModal.name.helper',
  },
  deviceApproveModalNameLabel: {
    defaultMessage: 'Device name',
    id: 'deviceApproveModal.name.label',
  },
  deviceApproveModalRequired: {
    defaultMessage: 'Value is mandatory',
    id: 'deviceApproveModal.required',
  },
  deviceApproveModalSubmit: {
    defaultMessage: 'Approve',
    id: 'deviceApproveModal.submit',
  },
});
