import React from 'react';
import { useIntl } from 'react-intl';
import { RC_STATUS } from '../../config/devices';
import DevicesInfoChip from '../DevicesInfoChip';
import messages from './messages';

const DeviceRCStatus = ({ status }: { status: RC_STATUS }) => {
  const { formatMessage } = useIntl();
  let styles = 'error';
  if ([RC_STATUS.ONLINE, RC_STATUS.CONNECTED].includes(status)) {
    styles = 'success';
  } else if ([RC_STATUS.CONNECTION_REQUESTED, RC_STATUS.UNSUPPORTED_FIRMWARE].includes(status)) {
    styles = 'warning';
  }

  if (
    [
      RC_STATUS.ONLINE,
      RC_STATUS.OFFLINE,
      RC_STATUS.DISABLED,
      RC_STATUS.CONNECTION_REQUESTED,
      RC_STATUS.CONNECTED,
      RC_STATUS.UNSUPPORTED_FIRMWARE,
    ].includes(status)
  ) {
    return (
      <DevicesInfoChip
        error={styles === 'error'}
        label={formatMessage(rcStatusMap[status])}
        size="small"
        success={styles === 'success'}
        variant="outlined"
        warning={styles === 'warning'}
      />
    );
  }

  return (
    <DevicesInfoChip
      error
      label={formatMessage(messages.deviceRCStatusUnknown)}
      size="small"
      variant="outlined"
    />
  );
};

export default DeviceRCStatus;

const rcStatusMap = {
  [RC_STATUS.ONLINE]: messages.deviceRCStatusOnline,
  [RC_STATUS.CONNECTED]: messages.deviceRCStatusConnected,
  [RC_STATUS.CONNECTION_REQUESTED]: messages.deviceRCStatusConnectionRequested,
  [RC_STATUS.DISABLED]: messages.deviceRCStatusDisabled,
  [RC_STATUS.OFFLINE]: messages.deviceRCStatusOffline,
  [RC_STATUS.UNSUPPORTED_FIRMWARE]: messages.deviceRCStatusUnsupportedFirmware,
};
