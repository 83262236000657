import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';

function DataGridSearchField({ fulltext, onChangeFilter, ...other }) {
  const { formatMessage } = useIntl();

  return (
    <Box bgcolor="common.white">
      <TextField
        {...other}
        autoComplete="off"
        inputProps={{
          sx: { py: 1.5 },
        }}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        margin="none"
        name="fulltext"
        onChange={onChangeFilter}
        placeholder={formatMessage(messages.DataGridSearchFieldPlaceholder)}
        type="search"
        value={fulltext}
        variant="outlined"
      />
    </Box>
  );
}

DataGridSearchField.propTypes = {
  fulltext: PropTypes.string.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
};

export default DataGridSearchField;
