import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { InfoModal } from '../../../../../../../components/_DesignSystemWrappers/Modal';
import { IModalBase } from '../../../../../../../modules/commonTypes';
import messages from './messages';

interface IAccessSettingsHelpModalProps extends IModalBase {}

export function AccessSettingsHelpModal({ onClose, open }: IAccessSettingsHelpModalProps) {
  const { formatMessage } = useIntl();

  return (
    <InfoModal
      buttonText={formatMessage(messages.AAModalAccessClose)}
      open={open}
      muiDialogProps={{ maxWidth: 'xs' }}
      title={formatMessage(messages.AAModalAccessTitle)}
      onClose={onClose}
    >
      <FormattedMessage {...messages.AAModalAccessText} />
    </InfoModal>
  );
}
