import { defineMessages } from 'react-intl';

export default defineMessages({
  AAmodalDeleteUserRFIDCancel: {
    defaultMessage: 'Cancel',
    id: 'AAmodal.deleteUserRFID.cancel',
  },
  AAmodalDeleteUserRFIDMsgErr: {
    defaultMessage: 'There was an error, please try again',
    id: 'AAmodal.deleteUserRFID.msgErr',
  },
  AAmodalDeleteUserRFIDMsgSucc: {
    defaultMessage: 'RFID key fob deleted successfully',
    id: 'AAmodal.deleteUserRFID.msgSucc',
  },
  AAmodalDeleteUserRFIDSubmit: {
    defaultMessage: 'Delete key fob',
    id: 'AAmodal.deleteUserRFID.submit',
  },
  AAmodalDeleteUserRFIDText: {
    defaultMessage:
      'When you delete the RFID key fob, it can no longer be used for access associated with this apartment.<p>If the key fob user is a member of other apartments they are not deleted from those.</p>',
    id: 'AAmodal.deleteUserRFID.text',
  },
  AAmodalDeleteUserRFIDTitle: {
    defaultMessage: 'Delete RFID key fob',
    id: 'AAmodal.deleteUserRFID.title',
  },
});
