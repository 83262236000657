import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import RowMenuItem from '../../../../components/DataGrid/RowMenuItem';
import messages from './messages';

const ApproveDevice = ({ onApprove }) => (
  <RowMenuItem
    colorIcon="primary.main"
    colorText="primary.main"
    icon={<CheckCircleIcon />}
    label={<FormattedMessage {...messages.waitingDevicesDataGridWaitingDevicesRowMenuApprove} />}
    onClick={onApprove}
  />
);

ApproveDevice.propTypes = {
  onApprove: PropTypes.func.isRequired,
};

export default ApproveDevice;
