import { defineMessages } from 'react-intl';

export default defineMessages({
  AAmodalGeneratePINCancel: {
    defaultMessage: 'Cancel',
    id: 'AAmodal.generatePIN.cancel',
  },
  AAmodalGeneratePINSubmit: {
    defaultMessage: 'save & send pin',
    id: 'AAmodal.generatePIN.submit',
  },
  AAmodalGeneratePINSubmitUwL: {
    defaultMessage: 'save pin',
    id: 'AAmodal.generatePIN.submitUwL',
  },
  AAmodalGeneratePINText1: {
    defaultMessage: 'The PIN code allows to open doors through which the user is granted access.',
    id: 'AAmodal.generatePIN.text1',
  },
  AAmodalGeneratePINText2: {
    defaultMessage: 'This PIN code is assigned to:',
    id: 'AAmodal.generatePIN.text2',
  },
  AAmodalGeneratePINTitle: {
    defaultMessage: 'Generate new PIN',
    id: 'AAmodal.generatePIN.title',
  },
});
