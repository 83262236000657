import { useEffect, useState } from 'react';
import { CARD_READER_STATE, DRIVER_ADDRESS, DRIVER_COMMAND, SOCKET_ERROR } from './const';

interface IWebSocket extends WebSocket {
  initialTimeout?: number;
  maxTimeout?: number;
}

interface ICardReaderResponse {
  Success: boolean;
  Params?: {
    HashData: string;
  };
  Error?: {
    Code: number;
  };
}

// eslint-disable-next-line max-statements
const useCardReader = () => {
  const [cardId, setCardId] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [response, setResponse] = useState<ICardReaderResponse>();
  const [status, setStatus] = useState<string>(CARD_READER_STATE.INIT);
  const [ws, setWs] = useState<IWebSocket>();

  useEffect(() => {
    testDriver();
  }, []);

  useEffect(() => {
    if (ws?.readyState === 0) {
      connect();
    }
    return () => {
      if (ws?.readyState === 1) {
        stopReading();
      }
    };
  }, [ws]);

  // eslint-disable-next-line max-statements
  useEffect(() => {
    if (response) {
      if (response?.Error?.Code) {
        setErrorState(response.Error.Code);
        stopReading();
      }

      if (response?.Success) {
        if (response?.Params) {
          setCardId(response.Params.HashData);
          stopReading();
        } else {
          if ([CARD_READER_STATE.TEST].includes(status)) {
            stopReading();
          } else {
            startReading();
          }
        }
      }
    }
  }, [response]);

  function initReading() {
    setWs(new WebSocket(DRIVER_ADDRESS));
  }

  function connect() {
    if (ws) {
      ws.onopen = () => {
        ws.initialTimeout = 10;
        ws.maxTimeout = 300;
        ws.send(JSON.stringify(DRIVER_COMMAND.INIT_OBJECT));
      };
      ws.onerror = () => {
        if (status === CARD_READER_STATE.TEST) {
          setStatus(CARD_READER_STATE.DRIVER_UNAVAILABLE);
        } else {
          handleError(SOCKET_ERROR.CONNECTION_ERR);
          setStatus(CARD_READER_STATE.INIT);
        }
      };
      ws.onmessage = (event) => {
        setResponse(JSON.parse(event.data));
      };
    }
  }

  function close() {
    if (ws) {
      ws.close();
    }
  }

  function startReading() {
    if (ws) {
      ws.send(JSON.stringify({ MsgType: DRIVER_COMMAND.BEGIN }));
    }
    setCardId('');
    setStatus(CARD_READER_STATE.READING);
  }

  function stopReading() {
    if (ws) {
      ws.send(JSON.stringify({ MsgType: DRIVER_COMMAND.CANCEL }));
    }
    setStatus(CARD_READER_STATE.DRIVER_AVAILABLE);
    close();
  }

  function testDriver() {
    setStatus(CARD_READER_STATE.TEST);
    handleError('');
    initReading();
  }

  function readCard() {
    setStatus(CARD_READER_STATE.CONNECT);
    handleError('');
    initReading();
  }

  function setErrorState(code: number) {
    if ([1, 2].includes(code)) {
      if (status === CARD_READER_STATE.TEST) {
        handleError(SOCKET_ERROR.CONNECTION_DEVICE_ERR);
      } else {
        handleError(SOCKET_ERROR.DEVICE_ERR);
      }
    } else if ([6, 7, 8, 9, 10].includes(code)) {
      handleError(SOCKET_ERROR.DRIVER_ERR);
    } else if ([11, 12, 13].includes(code)) {
      handleError(SOCKET_ERROR.READING_ERR);
    } else if (code === 17) {
      handleError(SOCKET_ERROR.CARD_ERR);
    } else {
      handleError(SOCKET_ERROR.CONNECTION_ERR);
    }
  }

  function handleError(errorMsg: string) {
    setError(errorMsg);
    if (errorMsg) {
      setCardId('');
    }
  }

  function eraseCardId() {
    setCardId('');
  }

  return {
    cardId,
    eraseCardId,
    error,
    readCard,
    status,
    testDriver,
  };
};

export default useCardReader;
