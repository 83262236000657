import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const styles = (theme) => ({
  root: {
    '&::-webkit-scrollbar': {
      width: '0.5rem',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.grey[400],
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: theme.palette.grey[500],
    },
  },
});

const CookieSettings = ({ maxHeight, onChange, settings }) => {
  const theme = useTheme();

  return (
    <Box height="fit-content" maxHeight={maxHeight} overflow="auto" sx={styles(theme).root}>
      <Grid container direction="column">
        {settings.map((item) => (
          <div key={item.cookieName}>
            <Grid alignItems="baseline" container item justifyContent="space-between">
              <Grid item xs={9}>
                <Typography>
                  <Box
                    color={item.checked ? 'primary.main' : 'text.secondary'}
                    component="span"
                    fontWeight="bold"
                    pt={1}
                  >
                    {item.title}
                  </Box>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Switch
                  checked={item.checked}
                  color="primary"
                  disabled={!item.editable}
                  name={item.cookieName}
                  onClick={onChange}
                />
              </Grid>
            </Grid>
            <Grid item>
              <Box pb={1}>
                <Typography variant="caption">{item.info}</Typography>
              </Box>
            </Grid>
          </div>
        ))}
      </Grid>
    </Box>
  );
};

CookieSettings.propTypes = {
  maxHeight: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  settings: PropTypes.arrayOf(
    PropTypes.shape({
      checked: PropTypes.bool.isRequired,
      cookieName: PropTypes.string.isRequired,
      editable: PropTypes.bool.isRequired,
      info: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    })
  ).isRequired,
};

export default CookieSettings;
