import { useState } from 'react';
import { read, utils } from 'xlsx';

export function useParseCsv() {
  const [file, setFile] = useState();
  const [jsonData, setJsonData] = useState([]);

  const onParseFile = (f) => {
    setFile(f);
    if (f) {
      const reader = new FileReader();
      reader.onload = (ev) => {
        const data = read(ev.target.result, { type: 'binary' });
        const sheetName = data.SheetNames[0];
        const sheet = data.Sheets[sheetName];

        const xlsxData = utils.sheet_to_json(sheet, { defval: '', header: 1 });

        const parsedJsonData = xlsxData.filter((item) => Array.isArray(item)).map((item) => [...item.slice(0, 7)]);

        setJsonData(parsedJsonData);
      };
      if (f.type === 'text/csv') {
        reader.readAsText(f);
      } else {
        reader.readAsBinaryString(f);
      }
    } else {
      setJsonData(null);
    }
  };

  return {
    file,
    jsonData,
    onParseFile,
  };
}
