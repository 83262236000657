import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { useIntl } from 'react-intl';
import messages from '../SiteUsersDataGrid/messages';

const styles = {
  name: {
    ml: {
      sm: 2,
      xs: 0,
    },
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
};

interface INameColumn {
  iconComponent: React.ReactNode;
  isActive?: boolean;
  name: string;
  isTimeLimitedAccess?: boolean;
}

function NameColumn({ iconComponent, isActive = true, isTimeLimitedAccess, name }: INameColumn) {
  const { formatMessage } = useIntl();

  return (
    <Stack alignItems="center" direction="row" sx={{ maxWidth: '100%' }}>
      <Tooltip
        placement="right"
        title={isTimeLimitedAccess ? formatMessage(messages.UserListUserGuestIconTooltip) : ''}
      >
        <Box
          sx={{
            color: isActive ? 'secondary.main' : 'text.disabled',
            display: { sm: 'block', xs: 'none' },
          }}
        >
          {iconComponent}
        </Box>
      </Tooltip>
      <Box
        fontWeight="bold"
        sx={{
          ...styles.name,
          color: isActive ? 'inherit' : 'text.disabled',
        }}
      >
        {name}
      </Box>
    </Stack>
  );
}

export default NameColumn;
