import React from 'react';
import { useSelector } from 'react-redux';
import { isLoadingSelector } from '../../modules/auth/selectors';
import { PublicFormWrapper } from '../_components/PublicFormWrapper';
import { EmailForm } from './components/EmailForm/EmailForm';
import { NewPasswordForm } from './components/NewPasswordForm';
import { RecoveryCodeForm } from './components/RecoveryCodeForm/RecoveryCodeForm';
import { ResetPasswordFormStep } from './types';
import { useResetPasswordPage } from './useResetPasswordPage';

export function ResetPasswordPage() {
  const isFetching = useSelector(isLoadingSelector);

  const {
    email,
    emailFormSubmit,
    formTitle,
    isOryApiFetching,
    newPasswordFormSuccess,
    oryRecoveryFlowData,
    recoveryCodeFormSuccess,
    recoveryFlowRestart,
    recoveryWholeProcessRestart,
    resetPasswordFormStep,
  } = useResetPasswordPage();

  return (
    <PublicFormWrapper title={formTitle}>
      {resetPasswordFormStep === ResetPasswordFormStep.EmailForm && (
        <EmailForm initEmail={email} isDisabled={isFetching || isOryApiFetching} onSubmit={emailFormSubmit} />
      )}

      {resetPasswordFormStep === ResetPasswordFormStep.ORYRecoveryCodeForm && oryRecoveryFlowData && (
        <RecoveryCodeForm
          recoveryFlowData={oryRecoveryFlowData}
          email={email}
          onSuccess={recoveryCodeFormSuccess}
          onRecoveryProcessRestart={recoveryWholeProcessRestart}
          onRecoveryFlowRestart={recoveryFlowRestart}
        />
      )}
      {resetPasswordFormStep === ResetPasswordFormStep.ORYNewPasswordForm && (
        <NewPasswordForm onSuccess={newPasswordFormSuccess} onRecoveryProcessRestart={recoveryWholeProcessRestart} />
      )}
    </PublicFormWrapper>
  );
}
