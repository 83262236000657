import { defineMessages } from 'react-intl';

export default defineMessages({
  idTalkButtonsLongPress: {
    defaultMessage: 'Long press',
    description: 'Long press button',
    id: 'idTalk.buttons.longPress',
  },
  idTalkButtonsShortPress: {
    defaultMessage: 'Short press',
    description: 'Short press button',
    id: 'idTalk.buttons.shortPress',
  },
  idTalkLabelDevicesToCall: {
    defaultMessage: 'Devices to call to',
    description: 'Devices to call label',
    id: 'idTalk.label.devicesToCall',
  },
  idTalkLabelMode: {
    defaultMessage: 'Press mode',
    description: 'Button label',
    id: 'idTalk.label.mode',
  },
  idTalkLabelRingGroup: {
    defaultMessage: 'Ring group',
    description: 'Ring group label',
    id: 'idTalk.label.ringGroup',
  },
  idTalkPlaceholderAddDevice: {
    defaultMessage: 'Click to add device',
    description: 'Placeholder addDevice',
    id: 'idTalk.placeholder.addDevice',
  },
  idTalkPlaceholderAddMoreDevices: {
    defaultMessage: 'Click to add more devices',
    description: 'Placeholder add more devices',
    id: 'idTalk.placeholder.addMoreDevices',
  },
});
