import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import AccessGroupsChipList from '../AccessGroupsChipList';
import ChipListWrapper from '../ChipListWrapper';
import RowSection from '../RowSection';
import SetAccessGroupButton from '../SetAccessGroupButton';
import UserIcon from '../UserIcon';
import messages from './messages';

function UserAccessGroupsList({ buttonAction, groups, userRole }) {
  const { formatMessage } = useIntl();

  return (
    <RowSection icon={<UserIcon userRole={userRole} />} label={formatMessage(messages.userAccessGroupsListLabel)}>
      <Stack
        direction={{ sm: 'row', xs: 'column' }}
        justifyContent={{ sm: 'space-between', xs: 'flex-start' }}
        sx={{ widht: 'inherit' }}
      >
        <Box>
          <ChipListWrapper emptyPlaceholder={formatMessage(messages.userAccessGroupsListEmpty)}>
            {groups.length ? <AccessGroupsChipList groups={groups} /> : null}
          </ChipListWrapper>
        </Box>
        <Box>
          <SetAccessGroupButton onClick={buttonAction} />
        </Box>
      </Stack>
    </RowSection>
  );
}

UserAccessGroupsList.propTypes = {
  buttonAction: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(PropTypes.object).isRequired,
  userRole: PropTypes.string.isRequired,
};

export default UserAccessGroupsList;
