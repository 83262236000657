import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonLink } from '../../../components';
import { MOBILE_VIDEO_STATUS, PATHS } from '../../../config/consts';
import { useModal } from '../../../hooks/useModal';
import ChangeCreditCardDialog from './ChangeCreditCardDialog';
import HowToChangeCreditCardDialog from './HowToChangeCreditCardDialog';
import messages from './messages';

const MobileVideoPaymentBox = ({
  companyId,
  isLastMVPaymentFinished,
  licenceModel,
  onDeactivate,
  paymentFailure,
  siteId,
  subscribed,
}) => {
  const { Modal: HowToChangeCreditCardModal, onOpen: onOpenHowToChangeCreditCardModal } = useModal({
    Modal: HowToChangeCreditCardDialog,
  });

  const { Modal: ChangeCreditCardModal, onOpen: onOpenChangeCreditCardModal } = useModal({
    Modal: ChangeCreditCardDialog,
  });

  const handleDeactivatePayment = () => {
    onDeactivate();
  };

  if ([MOBILE_VIDEO_STATUS.TRIAL_ACTIVE, MOBILE_VIDEO_STATUS.TRIAL_EXPIRED].includes(licenceModel?.status)) {
    return <>{false}</>;
  }

  return (
    <div>
      {licenceModel?.status}
      <Typography variant="h5">
        <FormattedMessage {...messages.mobileVideoPaymentTitle} />
      </Typography>
      <div>
        <Typography variant="subtitle1">
          <Box component="span" sx={{ mr: 1 }}>
            <FormattedMessage {...messages.mobileVideoPaymentRecurringPayment} />
          </Box>
          {subscribed ? (
            <>
              <FormattedMessage {...messages.mobileVideoPaymentActive} />
              <Button color="primary" onClick={onOpenChangeCreditCardModal}>
                <FormattedMessage {...messages.mobileVideoPaymentDeactivate} />
              </Button>
            </>
          ) : (
            <>
              <FormattedMessage {...messages.mobileVideoPaymentNotActive} />
              {licenceModel?.status === MOBILE_VIDEO_STATUS.STANDARD && !paymentFailure && (
                <ButtonLink
                  color="primary"
                  disabled={!isLastMVPaymentFinished}
                  to={PATHS.SUBSCRIPTION_MOBILE_VIDEO.replace(':companyId', companyId).replace(':siteId', siteId)}
                >
                  <FormattedMessage {...messages.mobileVideoPaymentActivate} />
                </ButtonLink>
              )}
            </>
          )}
        </Typography>
        {licenceModel?.status === MOBILE_VIDEO_STATUS.STANDARD && (
          <Button color="primary" onClick={onOpenHowToChangeCreditCardModal} sx={{ p: 0 }} variant="text">
            <HelpOutlineIcon sx={{ pr: 1 }} />
            <FormattedMessage {...messages.mobileVideoPaymentChangeCreditCard} />
          </Button>
        )}
      </div>
      <HowToChangeCreditCardModal />
      <ChangeCreditCardModal onConfirm={handleDeactivatePayment} />
    </div>
  );
};

MobileVideoPaymentBox.propTypes = {
  companyId: PropTypes.number,
  isLastMVPaymentFinished: PropTypes.bool.isRequired,
  licenceModel: PropTypes.shape({
    status: PropTypes.string,
  }).isRequired,
  onDeactivate: PropTypes.func.isRequired,
  paymentFailure: PropTypes.bool,
  siteId: PropTypes.number,
  subscribed: PropTypes.bool,
};

export default MobileVideoPaymentBox;
