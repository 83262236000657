import { defineMessages } from 'react-intl';

export default defineMessages({
  accessLogResultIconAccessTypeTooltipCard: {
    defaultMessage: 'RFID card or badge',
    description: 'Access Log Table - access type tooltip - CARD',
    id: 'accessLogDataGrid.accessTypeTooltip.CARD',
  },
  accessLogResultIconAccessTypeTooltipCode: {
    defaultMessage: 'PIN or QR code',
    description: 'Access Log Table - access type tooltip - CODE',
    id: 'accessLogDataGrid.accessTypeTooltip.CODE',
  },
  accessLogResultIconAccessTypeTooltipFingerPrint: {
    defaultMessage: 'Fingerprint',
    description: 'Access Log Table - access type tooltip - FINGER_PRINT',
    id: 'accessLogDataGrid.accessTypeTooltip.FINGER_PRINT',
  },
  accessLogResultIconAccessTypeTooltipLicensePlate: {
    defaultMessage: 'Car license plate',
    description: 'Access Log Table - access type tooltip - LICENSE_PLATE',
    id: 'accessLogDataGrid.accessTypeTooltip.LICENSE_PLATE',
  },
  accessLogResultIconAccessTypeTooltipMobileKey: {
    defaultMessage: 'WaveKey',
    description: 'Access Log Table - access type tooltip - MOBILE_KEY',
    id: 'accessLogDataGrid.accessTypeTooltip.MOBILE_KEY',
  },
  accessLogResultIconAccessTypeTooltipQrCode: {
    defaultMessage: 'QR code',
    description: 'Access Log Table - access type tooltip - QR_CODE',
    id: 'accessLogTable.accessTypeTooltip.QR_CODE',
  },
  accessLogResultIconAccessTypeTooltipRemote: {
    defaultMessage: 'Remote call or API',
    description: 'Access Log Table - access type tooltip - REMOTE',
    id: 'accessLogDataGrid.accessTypeTooltip.REMOTE',
  },
  accessLogResultIconAccessTypeTooltipRex: {
    defaultMessage: 'Request to exit',
    description: 'Access Log Table - access type tooltip - REX',
    id: 'accessLogDataGrid.accessTypeTooltip.REX',
  },
  accessLogResultIconAccessTypeTooltipUnauthorizedAccess: {
    defaultMessage: 'Unauthorised access',
    description: 'Access Log Table - access type tooltip - unauthorized access',
    id: 'accessLogDataGrid.accessTypeTooltip.unauthorizedAccess',
  },
  accessLogResultIconAccessTypeTooltipUnknown: {
    defaultMessage: 'Unknown type',
    description: 'Access Log Table - access type tooltip - unknown',
    id: 'accessLogDataGrid.accessTypeTooltip.unknown',
  },
  accessLogResultIconDenied: {
    defaultMessage: 'Access denied',
    description: 'Access Log Table - access result - denied',
    id: 'accessLogDataGrid.accessLogResultIconDenied',
  },
  accessLogResultIconSuccessful: {
    defaultMessage: 'Successful access',
    description: 'Access Log Table - access result - successful',
    id: 'accessLogDataGrid.accessLogResultIconSuccessful',
  },
  accessLogResultIconUnauthorized: {
    defaultMessage: 'Unauthorised access',
    description: 'Access Log Table - access result - unauthorized',
    id: 'accessLogDataGrid.accessLogResultIconUnauthorized',
  },
  accessTypeTooltipColumnsAccessMethodDesktop: {
    defaultMessage: 'Access method',
    description: 'Access Log Table - accessMethodDesktop column',
    id: 'accessLogDataGrid.columns.accessMethodDesktop',
  },
  accessTypeTooltipColumnsAccessMethodMobile: {
    defaultMessage: 'Access',
    description: 'Access Log Table - accessMethodMobile column',
    id: 'accessLogDataGrid.columns.accessMethodMobile',
  },
  accessTypeTooltipColumnsDetail: {
    defaultMessage: 'Detail',
    description: 'Access Log Table - detail column',
    id: 'accessLogDataGrid.columns.detail',
  },
  accessTypeTooltipColumnsDevice: {
    defaultMessage: 'Device',
    description: 'Access Log Table - device column',
    id: 'accessLogDataGrid.columns.device',
  },
  accessTypeTooltipColumnsTime: {
    defaultMessage: 'Time',
    description: 'Access Log Table - time column',
    id: 'accessLogDataGrid.columns.time',
  },
  accessTypeTooltipColumnsUser: {
    defaultMessage: 'User',
    description: 'Access Log Table - user column',
    id: 'accessLogDataGrid.columns.user',
  },
  columnsDeviceTime: {
    defaultMessage: 'Device time:{br}{time}',
    description: 'Access Log Table - device time',
    id: 'accessLogDataGrid.deviceTime',
  },
  columnsNoResults: {
    defaultMessage: 'No results found.',
    description: 'Access Log Table - no results',
    id: 'accessLogDataGrid.noResults',
  },
  columnsNoRowsOverlayHeaderText: {
    defaultMessage: 'No access records',
    description: 'Access Log Table - no rows overlay - header text',
    id: 'accessLogDataGrid.noRowsOverlay.headerText',
  },
  columnsNoRowsOverlayNoRowsSearchOverlayClearSearch: {
    defaultMessage: 'Clear search',
    description: 'Access Log Table - no rows search overlay - btn clear search',
    id: 'accessLogDataGrid.noRowsSearchOverlay.clearSearch',
  },
  columnsNoRowsOverlayNoRowsSearchOverlayHeaderText: {
    defaultMessage: 'No search results',
    description: 'Access Log Table - no rows search overlay - header text',
    id: 'accessLogDataGrid.noRowsSearchOverlay.headerText',
  },
  columnsNoRowsOverlayNoRowsSearchOverlayText: {
    defaultMessage: 'We couldn\'t find a match for "{searchText}". Please try another search.',
    description: 'Access Log Table - no rows search overlay - text',
    id: 'accessLogDataGrid.noRowsSearchOverlay.text',
  },
  columnsNoRowsOverlayText: {
    defaultMessage:
      'Once you activate logging on some device, access records will appear ' +
      'here.{br}It is also possible there are no records from the last 90 days.',
    description: 'Access Log Table - no rows overlay - text',
    id: 'accessLogDataGrid.noRowsOverlay.text',
  },
  noRowsSearchOverlayFilterHeadRecords: {
    defaultMessage:
      'Total of <strong>{total}</strong> access records from last 90 days ' + '(<strong>{alerts}</strong> failed)',
    description: 'Filter head - results',
    id: 'filterHead.records',
  },
  noRowsSearchOverlayFilterHeadRecordsFulltext: {
    defaultMessage: 'Showing results for your search query from the last 90 days.',
    description: 'Filter head - results fulltext',
    id: 'filterHead.recordsFulltext',
  },
  noRowsSearchOverlayFilterHeadShowFaileds: {
    defaultMessage: 'Show only problematic records',
    description: 'Filter head - show only failed records',
    id: 'filterHead.showFaileds',
  },
  noRowsSearchOverlayUnknownUser: {
    defaultMessage: 'Unknown user',
    description: 'Access Log Table - unknown user',
    id: 'accessLogDataGrid.unknownUser',
  },
});
