import { SITE_TYPE } from '../../../../config/sites';
import * as fieldsControl from './apartmentFormFieldsControl';

export function checkApartmentFormValidations(actualState, target, site, existingApartments) {
  const prevFormErrors = actualState.formErrors;
  prevFormErrors[target.name] = undefined;

  if (target.name === fieldsControl.floorField) {
    prevFormErrors[fieldsControl.floorField] = validateFloorField(target.value, site);

    return prevFormErrors;
  }

  if (target.name === fieldsControl.numberField) {
    prevFormErrors[fieldsControl.numberField] = validateNumberField(target.value, existingApartments);

    return prevFormErrors;
  }

  if (target.name === fieldsControl.nameField) {
    prevFormErrors[fieldsControl.nameField] = undefined;

    return prevFormErrors;
  }

  return prevFormErrors;
}

export function checkApartmentFormBeforeSubmit(values, site, existingApartments) {
  if (values.apartmentId !== 'createNewApartment') {
    return undefined;
  }

  const clonedFormErrors = values.formErrors;
  clonedFormErrors[fieldsControl.floorField] = validateFloorField(values.newApartmentFloor, site);
  clonedFormErrors[fieldsControl.numberField] = validateNumberField(values.newApartmentNumber, existingApartments);
  clonedFormErrors[fieldsControl.nameField] = undefined;

  if (hasError(clonedFormErrors)) {
    return clonedFormErrors;
  }

  return undefined;
}

export function hasError(formErrors) {
  return (
    formErrors[fieldsControl.floorField] !== undefined ||
    formErrors[fieldsControl.numberField] !== undefined ||
    formErrors[fieldsControl.nameField] !== undefined
  );
}

function validateFloorField(actualValue, site) {
  if (site.type === SITE_TYPE.MDU && actualValue === '') {
    return fieldsControl.requiredError;
  }

  return undefined;
}

function validateNumberField(actualValue, existingApartments) {
  if (actualValue === '') {
    return fieldsControl.requiredError;
  }

  if (!isApartmentNumberUnique(actualValue, existingApartments)) {
    return fieldsControl.notUniqueError;
  }

  return undefined;
}

function isApartmentNumberUnique(newApartmentNumber, existingApartments) {
  for (let i = 0; i < existingApartments.length; i++) {
    const floor = existingApartments[i];
    for (let j = 0; j < floor.items.length; j++) {
      const apartment = floor.items[j];
      if (apartment.number === newApartmentNumber) {
        return false;
      }
    }
  }

  return true;
}
