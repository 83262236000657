import InfoIcon from '@mui/icons-material/InfoOutlined';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';

const styles = {
  img: {
    '& img': {
      border: '1px solid',
      borderColor: 'divider',
      height: '130px',
      width: '130px',
    },
  },
  infoIcon: {
    color: 'secondary.main',
    ml: 0.5,
  },
  title: {
    color: 'secondary.main',
    float: 'left',
    fontSize: 12,
    fontWeight: 'bold',
    mt: 0.5,
  },
};

function DeviceQrCodeDetails({ deviceQrCodeUrl, isDeviceMigrated }) {
  return (
    <Grid container item lg sm={6}>
      <Grid item>
        <Grid container direction="column" item spacing={1}>
          {isDeviceMigrated ? (
            <QrCodeAfterMigration deviceQrCodeUrl={deviceQrCodeUrl} />
          ) : (
            <QrCodeBeforeMigration deviceQrCodeUrl={deviceQrCodeUrl} />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

DeviceQrCodeDetails.propTypes = {
  deviceQrCodeUrl: PropTypes.string.isRequired,
  isDeviceMigrated: PropTypes.bool.isRequired,
};

export default DeviceQrCodeDetails;

// eslint-disable-next-line react/prop-types
function QrCodeAfterMigration({ deviceQrCodeUrl }) {
  return (
    <>
      <Grid item>
        <Typography sx={styles.title}>
          <FormattedMessage {...messages.userLoserDeviceQrAfterMigrationTitle} />
        </Typography>
        <Tooltip
          placement="right"
          title={
            <FormattedMessage
              {...messages.userLoserDeviceQrAfterMigrationTooltip}
              values={{
                br: <br />,
                p: (...chunks) => <p>{chunks}</p>,
              }}
            />
          }
        >
          <InfoIcon sx={styles.infoIcon} />
        </Tooltip>
      </Grid>
      <Grid item sx={styles.img}>
        <img alt="QR Code" src={deviceQrCodeUrl} />
      </Grid>
    </>
  );
}

// eslint-disable-next-line react/prop-types
function QrCodeBeforeMigration({ deviceQrCodeUrl }) {
  return (
    <>
      <Grid item sx={styles.img}>
        <img alt="QR Code" src={deviceQrCodeUrl} />
      </Grid>
      <Grid item>
        <Typography>
          <FormattedMessage {...messages.userLoserDeviceQrBeforeMigration} />
        </Typography>
      </Grid>
    </>
  );
}
