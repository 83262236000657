import DownloadIcon from '@mui/icons-material/GetApp';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import CustomDate from '../CustomDate';
import ReadOnlyField from '../ReadOnlyField';
import messages from './messages';

const ValidityInfo = ({ invoiceId, invoiceName, validityFrom, validityTo }) => (
  <Grid container spacing={2}>
    <Grid item sm={3} xs={6}>
      <ReadOnlyField
        label={<FormattedMessage {...messages.validityInfoValidityFrom} />}
        value={<CustomDate dateValue={validityFrom} />}
      />
    </Grid>
    <Grid item sm={3} xs={6}>
      <ReadOnlyField
        label={<FormattedMessage {...messages.validityInfoValidityTo} />}
        value={<CustomDate dateValue={validityTo} />}
      />
    </Grid>
    <Grid alignItems="flex-end" container item sm={3} xs={6}>
      <Grid item>
        <ReadOnlyField label={<FormattedMessage {...messages.validityInfoInvoice} />} value={invoiceName} />
      </Grid>
      <Grid item>
        <IconButton color="primary" href={`${process.env.PAYMENT_GATEWAY_URL}/invoices?id=${invoiceId}`} size="small">
          <DownloadIcon />
        </IconButton>
      </Grid>
    </Grid>
  </Grid>
);

ValidityInfo.propTypes = {
  invoiceId: PropTypes.string.isRequired,
  invoiceName: PropTypes.string.isRequired,
  validityFrom: PropTypes.string.isRequired,
  validityTo: PropTypes.string.isRequired,
};

export default ValidityInfo;
