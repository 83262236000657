import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import SiteAboutDialog from '../../containers/Sites/SiteAboutModal';
import CompanyAboutDialog from '../../containers/UserProfile/CompanyAboutModal';
import { useModal } from '../../hooks/useModal';
import Alert from '../Alert';
import messages from './messages';

export const styles = {
  button: {
    backgroundColor: 'info.main',
    color: 'info.contrastText',
    fontFamily: 'roboto',
    p: 0.3,
    px: 1,
  },
};

const NoCompanyBox = ({ onOpenAddCompanyModal }) => {
  const { Modal: SiteAboutModal, onOpen: onOpenSiteAboutModal } = useModal({
    Modal: SiteAboutDialog,
  });
  const { Modal: CompanyAboutModal, onOpen: onOpenCompanyAboutModal } = useModal({
    Modal: CompanyAboutDialog,
  });

  return (
    <Alert
      action={
        <Button onClick={onOpenAddCompanyModal} size="small" startIcon={<AddIcon />} sx={styles.button}>
          <FormattedMessage {...messages.noCompanyBoxCreateCompany} />
        </Button>
      }
      severity="info"
      title={<FormattedMessage {...messages.noCompanyBoxTitle} />}
    >
      <FormattedMessage
        {...messages.noCompanyBoxDescription}
        values={{
          link: (chunks) => (
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <Link component="button" onClick={onOpenCompanyAboutModal}>
              {chunks}
            </Link>
          ),
        }}
      />
      <CompanyAboutModal onOpenSiteAboutModal={onOpenSiteAboutModal} />
      <SiteAboutModal onOpenCreateCompanyModal={onOpenAddCompanyModal} />
    </Alert>
  );
};

NoCompanyBox.propTypes = {
  onOpenAddCompanyModal: PropTypes.func.isRequired,
};

export default NoCompanyBox;
