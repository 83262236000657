import SettingsIcon from '@mui/icons-material/Settings';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import RowMenuItem from '../../DataGrid/RowMenuItem';
import messages from '../messages';

const OpenRc = ({ device, disabled, onOpenRCModal }) => (
  <RowMenuItem
    data-gaId="deviceListOpenConfigurationBtn"
    disabled={disabled}
    icon={<SettingsIcon />}
    label={<FormattedMessage {...messages.deviceDataGridActionDeviceRowMenuOpenConfiguration} />}
    onClick={() => onOpenRCModal(device)}
  />
);

OpenRc.propTypes = {
  device: PropTypes.shape({}).isRequired,
  disabled: PropTypes.bool.isRequired,
  onOpenRCModal: PropTypes.func.isRequired,
};

export default OpenRc;
