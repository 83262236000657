import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectProps } from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ElIntDestination } from '../../../../../apiRtk/be4feApi';
import messages from './messages';

interface IDestinationSelect extends SelectProps {
  destinations: Array<ElIntDestination>;
  destinationId: string;
}

export function DestinationSelect({ destinationId, destinations, ...other }: IDestinationSelect) {
  return (
    <FormControl size="small" style={{ width: '100%' }}>
      <Select
        {...other}
        defaultValue={destinationId}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: 160,
            },
          },
        }}
      >
        <MenuItem value="null">
          <Typography color="secondary">
            <FormattedMessage {...messages.elevatorIntegrationMappingDestinationAreaNull} />
          </Typography>
        </MenuItem>

        {destinations.map((destination, index) => (
          <MenuItem key={index} value={destination.areaId}>
            <FormattedMessage
              {...messages.destinationSelectItem}
              values={{
                destinationArea: destination.areaId,
                shortName: destination.shortName,
                typography: (chunks) => (
                  <Typography component="span" color="primary">
                    <b>{chunks}</b>&nbsp;
                  </Typography>
                ),
              }}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
