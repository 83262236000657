import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const IpAddressField = ({ disabled, value }) => (
  <Box pb={2}>
    <TextField
      disabled={disabled}
      fullWidth
      InputProps={{ readOnly: true }}
      label={<FormattedMessage {...messages.Name} />}
      margin="normal"
      name="ipAddress"
      value={value}
      variant="outlined"
    />
  </Box>
);

IpAddressField.propTypes = {
  disabled: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
};

export default IpAddressField;
