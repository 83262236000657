import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { PATHS } from '../../../config/consts';
import ButtonLink from '../../ButtonLink';
import messages from '../messages';

const styles = {
  buttonBase: {
    mx: 1,
  },
  fade: (placement) => ({
    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
  }),
  icons: {
    color: 'common.white',
  },
  menuItem: {
    py: 1.5,
  },
  paper: {
    '&.MuiPaper-root': {
      mr: 3.5,
      mt: 2.8,
    },
  },
  popper: {
    display: {
      md: 'none',
      sm: 'flex',
    },
  },
  root: {
    display: {
      md: 'none',
      sm: 'flex',
    },
  },
  typography: {
    textTransform: 'initial',
  },
};

function HeaderPublicBurgerMenu() {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => setOpen((prevState) => !prevState);

  const handleClose = (event) => {
    if (!anchorRef?.current?.contains(event.target)) {
      setOpen(false);
    }
  };

  return (
    <Box sx={styles.root}>
      <ButtonBase
        aria-controls="menu"
        aria-haspopup
        disableRipple
        onClick={handleToggle}
        ref={anchorRef}
        sx={styles.buttonBase}
      >
        {open ? <CloseIcon fontSize="large" sx={styles.icons} /> : <MenuIcon fontSize="large" sx={styles.icons} />}
      </ButtonBase>
      <Popper anchorEl={anchorRef.current} open={Boolean(open)} sx={styles.popper} transition>
        {({ placement, TransitionProps }) => (
          <Fade {...TransitionProps} style={styles.fade(placement)}>
            <Paper elevation={8} id="menu" sx={styles.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList disablePadding>
                  <MenuItem
                    component={ButtonLink}
                    onClick={handleToggle}
                    sx={styles.menuItem}
                    to={PATHS.LANDING_MY2N_APP}
                  >
                    <Typography sx={styles.typography} variant="body2">
                      <FormattedMessage
                        tagName="span"
                        values={{ sup: (chunks) => <sup>{chunks}</sup> }}
                        {...messages.headerPublicLogoMenuMobileVideo}
                      />
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    component={ButtonLink}
                    divider
                    onClick={handleToggle}
                    sx={styles.menuItem}
                    to={PATHS.LANDING_REMOTE_CONFIGURATION}
                  >
                    <Typography sx={styles.typography} variant="body2">
                      <FormattedMessage
                        tagName="span"
                        values={{ sup: (chunks) => <sup>{chunks}</sup> }}
                        {...messages.headerPublicLogoMenuRemoteConfiguration}
                      />
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    component={ButtonLink}
                    onClick={handleToggle}
                    startIcon={<AccountCircleIcon />}
                    sx={{ ...styles.menuItem, px: 2.5 }}
                    to={PATHS.LOGIN}
                  >
                    <Typography sx={styles.typography} variant="body2">
                      <FormattedMessage {...messages.headerPublicLogoMenuLogin} />
                    </Typography>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Box>
  );
}

export default HeaderPublicBurgerMenu;
