import { defineMessages } from 'react-intl';

export default defineMessages({
  accessGroupDetailFormInfoApartments: {
    defaultMessage: 'assigned {count, plural, one {apartment} other {apartments}}',
    description: 'Access group detail - apartments info label',
    id: 'accessGroupDetailForm.info.apartments',
  },
  accessGroupDetailFormInfoDevices: {
    defaultMessage: 'assigned {count, plural, one {device} other {devices}}',
    description: 'Access group detail - devices info label',
    id: 'accessGroupDetailForm.info.devices',
  },
  accessGroupDetailFormInfoUsers: {
    defaultMessage: 'assigned {count, plural, one {user} other {users}}',
    description: 'Access group detail - users info label',
    id: 'accessGroupDetailForm.info.users',
  },
  accessGroupDetailFormInputDescription: {
    defaultMessage: 'Access group description',
    description: 'Access group detail - description input label',
    id: 'accessGroupDetailForm.input.description',
  },
  accessGroupDetailFormInputDescriptionMaxLength: {
    defaultMessage: 'Description is too long',
    description: 'Access group detail - description input maxLength',
    id: 'accessGroupDetailForm.input.descriptionMaxLength',
  },
  accessGroupDetailFormInputNameHelperText: {
    defaultMessage: 'Use a simple name, e. g. Garage',
    description: 'Access group detail - name input helper text',
    id: 'accessGroupDetailForm.input.name.helperText',
  },
  accessGroupDetailFormInputNameLabel: {
    defaultMessage: 'Access group name',
    description: 'Access group detail - name input label',
    id: 'accessGroupDetailForm.input.name',
  },
  accessGroupDetailFormInputNameLongName: {
    defaultMessage: 'Shorter group names are typically more useful',
    description: 'Access group detail - name input long name helper text',
    id: 'accessGroupDetailForm.input.name.longName',
  },
  accessGroupDetailFormInputNameMaxLength: {
    defaultMessage: 'Name is too long',
    description: 'Access group detail - name input maxLength',
    id: 'accessGroupDetailForm.input.nameMaxLength',
  },
  accessGroupDetailFormInputRequired: {
    defaultMessage: 'Group name is required',
    description: 'Access group detail - input required',
    id: 'accessGroupDetailForm.input.required',
  },
});
