import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import actions from '../../modules/common';
import messages from './messages';

const styles = {
  serviceAlert: {
    alignItems: 'center',
    backgroundColor: 'primary.main',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minHeight: '40px',
    position: 'fixed',
    px: 4,
    py: 1,
    textAlign: 'center',
    top: 0,
    width: '100%',
    zIndex: 3000,
  },
  text: {
    color: '#f5f5f5',
  },
};

const ServiceAlert = ({ onSetAlertHeight, onSetShow, show }) => {
  useEffect(() => {
    onSetShow(show);

    if (show) {
      const listenerAction = () => {
        onSetAlertHeight(document.getElementById('serviceAlert')?.clientHeight);
      };
      window.addEventListener('resize', listenerAction);
      return () => {
        window.removeEventListener('resize', listenerAction);
      };
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, [show]);

  useEffect(() => {
    onSetAlertHeight(document.getElementById('serviceAlert')?.clientHeight);
  }, []);

  return (
    show && (
      <Box id="serviceAlert" sx={styles.serviceAlert}>
        <Typography sx={styles.text}>
          <FormattedMessage {...messages.serviceAlertText} />
        </Typography>
      </Box>
    )
  );
};

ServiceAlert.propTypes = {
  onSetAlertHeight: PropTypes.func.isRequired,
  onSetShow: PropTypes.func.isRequired,
  show: PropTypes.bool,
};

ServiceAlert.defaultProps = {
  show: false,
};

const mapDispatchToProps = {
  onSetAlertHeight: actions.setServiceAlertHeight,
  onSetShow: actions.setServiceAlertShow,
};

export default connect(null, mapDispatchToProps)(ServiceAlert);
