import { defineMessages } from 'react-intl';

export default defineMessages({
  AAUserDetailAccessHelp: {
    defaultMessage: 'Access settings help',
    id: 'AA.userDetail.access.help',
  },
  AAUserDetailAccessNoAccessGroups: {
    defaultMessage: 'No access groups set',
    id: 'AA.userDetail.access.noAccessGroups',
  },
  AAUserDetailAccessNoAccessGroupsCredentials: {
    defaultMessage: 'No access rights are set for apartment. Contact your facility manager.',
    id: 'AA.userDetail.access.noAccessGroupsCredentials',
  },
  AAUserDetailAccessPIN: {
    defaultMessage: 'PIN',
    id: 'AA.userDetail.access.PIN',
  },
  AAUserDetailAccessQR: {
    defaultMessage: 'Access code',
    id: 'AA.userDetail.access.QR',
  },
  AAUserDetailAccessRFID: {
    defaultMessage: 'RFID key fob',
    id: 'AA.userDetail.access.RFID',
  },
  AAUserDetailAccessValidFrom: {
    defaultMessage: '<typography>from</typography> {validityStart}',
    id: 'user.access.validityStart',
  },
  AAUserDetailAccessValidTo: {
    defaultMessage: '<typography>to</typography> {validityEnd}',
    id: 'user.access.validityEnd',
  },
});
