import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const PromoCodesService = ({ orderResultsLength }) => (
  <FormattedMessage
    {...messages.paymentsTableServicePROMOLabel}
    values={{
      count: (
        <span>
          <br />
          <FormattedMessage
            {...messages.paymentsTableServicePROMOCount}
            values={{
              count: orderResultsLength,
            }}
          />
        </span>
      ),
    }}
  />
);

PromoCodesService.propTypes = {
  orderResultsLength: PropTypes.number.isRequired,
};

export default PromoCodesService;
