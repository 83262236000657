import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import RowMenuItem from '../../../../components/DataGrid/RowMenuItem';
import messages from './messages';

const RejectDevice = ({ onReject }) => (
  <RowMenuItem
    colorIcon="error.main"
    colorText="error.main"
    icon={<HighlightOffIcon />}
    label={<FormattedMessage {...messages.waitingDevicesDataGridWaitingDevicesRowMenuReject} />}
    onClick={onReject}
  />
);

RejectDevice.propTypes = {
  onReject: PropTypes.func.isRequired,
};

export default RejectDevice;
