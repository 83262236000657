import Stack from '@mui/material/Stack';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import TextField from '../HookFormFields/TextField';
import messages from './messages';

function NewGroupForm() {
  const { formatMessage } = useIntl();
  const { control } = useFormContext();

  return (
    <Stack direction="column" spacing={2}>
      <TextField
        autoFocus
        control={control}
        fullWidth
        helperText={formatMessage(messages.accessGroupCreateModalInputNameHelperText)}
        label={formatMessage(messages.accessGroupCreateModalInputNameLabel)}
        name="name"
        placeholder={formatMessage(messages.accessGroupCreateModalInputNamePlaceholder)}
        required
      />
      <TextField
        control={control}
        fullWidth
        label={formatMessage(messages.accessGroupCreateModalInputDescription)}
        multiline
        name="description"
        rows={4}
      />
    </Stack>
  );
}

export default NewGroupForm;
