import { transformSelectedOption } from '../../../../containers/DeviceDetail/CallSettings/Intercom/helpers/renderHelpers';
import { ICallSettingOption } from '../../../../containers/DeviceDetail/CallSettings/Intercom/hooks/useGetStateData';
import { IApartment } from '../../../apartments/store';
import { ContactType, DeviceFeatureType, IDevice } from '../../store/deviceStore';
import { getButtonConfigurationFeature } from './devicesCallSettingsHelper';
import { EnrichedContact } from './enrichedStructures';

export class CallSettingsDataManager {
  _externalIdToContactMap: Record<string, EnrichedContact> = {};

  _deviceIdToMobileVideoMap: Record<number, IDeviceOptionData> = {};

  _callSettingsChipOptions: ICallSettingOption[] = [];

  constructor(deviceChipOptions: IDevice[], apartmentChipOptions: IApartment[], editedDevice: IDevice) {
    this.convertDevicesToChipOptions(deviceChipOptions);
    this.convertApartmentsToChipOptions(apartmentChipOptions);

    const buttonConfiguration = getButtonConfigurationFeature(editedDevice.features);

    if (buttonConfiguration !== undefined) {
      buttonConfiguration.buttons.forEach((button) => {
        this._externalIdToContactMap[button.externalId] = new EnrichedContact(editedDevice, button.externalId);
      });
    }
  }

  public getCallSettingsChipOptions(): ICallSettingOption[] {
    return this._callSettingsChipOptions;
  }

  public getMap(): Record<string, EnrichedContact> {
    return this._externalIdToContactMap;
  }

  public getContactDataByExternalIdAndFeature(
    featureType: DeviceFeatureType,
    externalId: string
  ): IContactData | undefined {
    if (this._externalIdToContactMap[externalId] === undefined) {
      return undefined;
    }

    const contact = this._externalIdToContactMap?.[externalId]._contactToFeaturesMap?.[featureType]?.contacts;

    return {
      contactId: contact!._contactId,
      sipNumber: contact!._contactItemSipNumber!,
    };
  }

  public getSelectedDeviceConfigId(deviceId: number): IDeviceOptionData {
    return this._deviceIdToMobileVideoMap[deviceId];
  }

  public tryGetContactItemId(
    externalId: string,
    featureType: DeviceFeatureType,
    contactType: ContactType
  ): number | undefined {
    if (this._externalIdToContactMap[externalId] === undefined) {
      return undefined;
    }

    return this._externalIdToContactMap[externalId]?._contactToFeaturesMap[featureType]?.contacts._contactItemIdsMap[
      contactType
    ];
  }

  convertDevicesToChipOptions(deviceChipOptions: IDevice[]) {
    deviceChipOptions.forEach((deviceChipOption: IDevice) => {
      this._callSettingsChipOptions.push(transformSelectedOption(deviceChipOption, false));
      this._deviceIdToMobileVideoMap[deviceChipOption.id] = getDeviceServicesIds(deviceChipOption);
    });
  }

  convertApartmentsToChipOptions(apartmentChipOptions: IApartment[]) {
    apartmentChipOptions.forEach((apartment: IApartment) => {
      this._callSettingsChipOptions.push(transformSelectedOption(apartment, false));
    });
  }
}

function getDeviceServicesIds(device: IDevice): IDeviceOptionData {
  return {
    gatewayId: device.services?.GSM_CALLS?.gatewayId,
    gsmCallsId: device.services?.GSM_CALLS?.id,
    localCallsId: device.services?.LOCAL_CALLS?.id,
    mobileVideoId: device.services?.MOBILE_VIDEO?.id,
  };
}

export interface IContactData {
  contactId: number;
  sipNumber: string;
}

interface IDeviceOptionData {
  mobileVideoId?: number;
  localCallsId?: number;
  gsmCallsId?: number;
  gatewayId?: number;
}
