import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../config/consts';
import { useUrlParams } from '../../helpers/urlParams';
import DeleteDevicesList from './DeleteDevicesList';
import messages from './messages';

function DialogBody({ group, onClose }) {
  const navigate = useNavigate();
  const { companyId, siteId } = useUrlParams();
  const { apartments, devices, id, name, users } = group;

  // eslint-disable-next-line complexity
  const getBodyMesage = () => {
    if (!!devices?.length && !!users && !!apartments) {
      return messages.accessGroupDeleteModalDialogBodyApartmentsUsersDevices;
    }

    if (!!devices?.length && !!apartments && !users) {
      return messages.accessGroupDeleteModalDialogBodyNoUsers;
    }

    if (!!devices?.length && !!users && !apartments) {
      return messages.accessGroupDeleteModalDialogBodyNoApartments;
    }

    if (!devices?.length) {
      return messages.accessGroupDeleteModalDialogBodyNoDevices;
    }

    return messages.accessGroupDeleteModalDialogBodyNoApartmentsNoUsers;
  };

  function handleLinkClick(path) {
    onClose();
    navigate(path.replace(':companyId', companyId).replace(':siteId', siteId).replace(':groupId', id));
  }

  return (
    <>
      <Typography component="span" varinat="body1">
        <FormattedMessage
          {...getBodyMesage()}
          values={{
            apartmentLink: (chunks) => (
              <MessageLink onClick={() => handleLinkClick(PATHS.ACCESS_SETTINGS_APARTMENTS)}>{chunks}</MessageLink>
            ),
            apartments,
            devices: devices?.length,
            groupName: <strong>{name}</strong>,
            p: (chunks) => <p>{chunks}</p>,
            strong: (chunks) => <strong>{chunks}</strong>,
            users,
            usersLink: (chunks) => (
              <MessageLink onClick={() => handleLinkClick(PATHS.ACCESS_SETTINGS_USERS)}>{chunks}</MessageLink>
            ),
          }}
        />
      </Typography>
      {(!!users || !!apartments) && !!devices?.length && <DeleteDevicesList devices={devices} />}
    </>
  );
}

function MessageLink({ ...other }) {
  return (
    <Link
      {...other}
      sx={{
        cursor: 'pointer',
        fontWeight: 'bold',
      }}
    />
  );
}

DialogBody.propTypes = {
  group: PropTypes.shape({
    apartments: PropTypes.number,
    devices: PropTypes.arrayOf(
      PropTypes.shape({
        deviceId: PropTypes.string,
        deviceName: PropTypes.string,
        deviceType: PropTypes.string,
      })
    ),
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    users: PropTypes.number,
  }),
  onClose: PropTypes.func.isRequired,
};

DialogBody.defaultProps = {
  group: {
    apartments: 0,
    devices: [],
    id: '',
    name: '',
    users: 0,
  },
};

export default DialogBody;
