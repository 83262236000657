import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ButtonLink from '../../components/ButtonLink';
import DashboardCardHeader from '../../components/DashboardCardHeader';
import { PATHS } from '../../config/consts';
import { useUrlParams } from '../../helpers/urlParams';
import messages from './messages';

const styles = {
  button: {
    mt: {
      sm: 3,
      xs: 1,
    },
  },
  content: {
    minHeight: {
      sm: '208px',
    },
    pb: 3,
    pt: 0,
    px: 3,
  },
  root: {
    display: 'grid',
    height: {
      sm: '232px',
    },
    textAlign: 'center',
    width: '100%',
  },
  text: {
    pt: {
      sm: 0.6,
      xs: 1,
    },
    textAlign: 'center',
  },
  title: {
    pt: {
      sm: 4,
      xs: 2,
    },
    textAlign: 'center',
  },
};

const NoDevicesBox = ({ onAddDevice }) => {
  const { companyId, siteId } = useUrlParams();
  return (
    <Card>
      <DashboardCardHeader
        action={
          <ButtonLink
            color="primary"
            to={PATHS.DEVICES_OVERVIEW.replace(':companyId', companyId).replace(':siteId', siteId)}
          >
            <FormattedMessage {...messages.noDevicesBoxSeeAllDevices} />
          </ButtonLink>
        }
        title={<FormattedMessage {...messages.noDevicesBoxHeaderTitle} />}
      />
      <CardContent sx={styles.content}>
        <Typography sx={styles.title} variant="h6">
          <FormattedMessage {...messages.noDevicesBoxTitle} />
        </Typography>
        <Box sx={styles.text}>
          <Typography variant="body2">
            <FormattedMessage {...messages.noDevicesBoxDescription} />
          </Typography>
          <Button color="primary" onClick={onAddDevice} startIcon={<AddIcon />} sx={styles.button} variant="contained">
            <FormattedMessage {...messages.noDevicesBoxAddDevice} />
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

NoDevicesBox.propTypes = {
  onAddDevice: PropTypes.func.isRequired,
};

export default NoDevicesBox;
