import { initialState } from './reducer';

export interface ISubscriptionStore {}

export interface ICodeData {
  activation: IActivation;
  configuration: IConfiguration;
}

interface IActivation {
  expiration: string;
  initialConsumption: number;
  licencesCount: number;
  licencesPool: number;
  nextPayment: number;
  unlicensedCount: number;
}

interface IConfiguration {
  credit: number;
}

export function getInitialSubscriptionStore(): ISubscriptionStore {
  return { ...initialState };
}
