import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import messages from './messages';

const styles = {
  disabled: {
    color: 'action.disabled',
  },
  fullPrice: {
    pr: 0.5,
  },
  fullPriceVat: {
    color: 'text.secondary',
  },
  price: {
    textAlign: {
      md: 'left',
      sm: 'right',
    },
  },
};

const Price = ({ currency, fullPrice, fullPriceVat, isDisabled, isVat }) => (
  <>
    <Typography
      component="span"
      sx={{
        ...styles.fullPrice,
        ...(isDisabled && styles.disabled),
      }}
      variant="body2"
    >
      <FormattedNumber
        currency={currency}
        // eslint-disable-next-line react/style-prop-object
        style="currency"
        value={fullPrice}
      />
    </Typography>
    <Typography
      component="span"
      sx={{
        ...styles.fullPriceVat,
        ...(isDisabled && styles.disabled),
      }}
      variant="body2"
    >
      {isVat && (
        <FormattedMessage
          {...messages.Vat}
          values={{
            price: (
              <FormattedNumber
                currency={currency}
                // eslint-disable-next-line react/style-prop-object
                style="currency"
                value={fullPriceVat}
              />
            ),
          }}
        />
      )}
    </Typography>
  </>
);

Price.propTypes = {
  currency: PropTypes.string.isRequired,
  fullPrice: PropTypes.number.isRequired,
  fullPriceVat: PropTypes.number,
  isDisabled: PropTypes.bool,
  isVat: PropTypes.bool,
};

Price.defaultProps = {
  fullPriceVat: null,
  isDisabled: false,
  isVat: false,
};

export default Price;
