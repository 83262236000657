import { IApartmentDetail } from '../modules/apartments/store';
import { TId } from '../modules/commonTypes';
import { isArrayEmpty } from './array';

export function getUserApartments({
  apartmentList,
  userAssignedApartmentsIds,
}: {
  apartmentList: IApartmentDetail[] | undefined;
  userAssignedApartmentsIds: TId[] | undefined;
}): IApartmentDetail[] {
  const userApartmentDetails: IApartmentDetail[] = [];
  if (!isArrayEmpty(userAssignedApartmentsIds) && !isArrayEmpty(apartmentList)) {
    userAssignedApartmentsIds!.forEach((apartmentId) => {
      const apartmentDetail = apartmentList!.find((a) => a.id === apartmentId);
      if (apartmentDetail) {
        userApartmentDetails.push(apartmentDetail);
      }
    });
  }
  return userApartmentDetails;
}
