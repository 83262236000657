import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonDanger, Modal } from '../../../../components';
import messages from './messages';

const RemoveBatchModal = ({ onClose, onRemoveBatch, open }) => {
  const handleRemoveBatch = () => {
    onRemoveBatch();
    onClose();
  };

  return (
    <Modal
      actions={
        <div>
          <Button onClick={onClose}>
            <FormattedMessage {...messages.removeBatchModalActionClose} />
          </Button>
          <ButtonDanger onClick={handleRemoveBatch} startIcon={<DeleteIcon />}>
            <FormattedMessage {...messages.removeBatchModalActionRemove} />
          </ButtonDanger>
        </div>
      }
      headerVariant="danger"
      name="remove-batch-modal"
      onClose={onClose}
      open={open}
      title={<FormattedMessage {...messages.removeBatchModalTitle} />}
    >
      <Typography>
        <FormattedMessage {...messages.removeBatchModalText} />
      </Typography>
    </Modal>
  );
};

RemoveBatchModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onRemoveBatch: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default RemoveBatchModal;
