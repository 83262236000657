import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { SERVICE } from '../../../config/devices';
import { SITE_TYPE } from '../../../config/sites';
import { isActivable, isExpirable } from '../../../helpers/mobileVideo';
import sitesActions from '../../../modules/sites';
import RowMenu from '../../DataGrid/RowMenu';
import DeleteSite from './DeleteSite';
import RenameSite from './RenameSite';
import SetSiteType from './SetSiteType';

const DataGridRowMenu = ({ onDeleteSite, onRenameSite, onSetSiteType, site }) => (
  <RowMenu
    menuItems={
      <Box>
        <RenameSite onRenameSite={onRenameSite} site={site} />
        {site?.type === SITE_TYPE.UNKNOWN &&
          !isActivable(site?.services?.[SERVICE.MOBILE_VIDEO]?.licenceModel?.status) &&
          !isExpirable(site?.services?.[SERVICE.MOBILE_VIDEO]?.licenceModel?.status) && (
            <SetSiteType onSetSiteType={onSetSiteType} site={site} />
          )}
        <Divider />
        <DeleteSite key="deleteSite" onDeleteSite={onDeleteSite} site={site} />
      </Box>
    }
  />
);

DataGridRowMenu.propTypes = {
  onDeleteSite: PropTypes.func.isRequired,
  onRenameSite: PropTypes.func.isRequired,
  onSetSiteType: PropTypes.func.isRequired,
  site: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    services: PropTypes.shape({
      [SERVICE.MOBILE_VIDEO]: PropTypes.shape({
        licenceModel: PropTypes.shape({
          status: PropTypes.string,
        }),
      }),
    }),
    type: PropTypes.string,
  }).isRequired,
};

const mapDispatchToProps = {
  onDeleteSite: sitesActions.deleteSiteShowModal,
  onRenameSite: sitesActions.editSiteShowModal,
};

export default connect(null, mapDispatchToProps)(DataGridRowMenu);
