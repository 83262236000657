import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Paper } from '../../../components';
import { OutlinedTextField } from '../../../components/HookFormFields/TextField';
import { useUnlockSettings } from './hooks/useUnlockSettings';
import messages from './messages';

function UnlockSettings() {
  const { disableSubmit, formatMessage, formMethods, onSubmit } = useUnlockSettings();

  return (
    <Paper>
      <Typography>
        <FormattedMessage {...messages.unlockSettingsHeader} />
      </Typography>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Grid container direction="column">
            <Grid container item>
              <Grid item xs={4}>
                <OutlinedTextField
                  fullWidth
                  id="code"
                  helperText={formatMessage(messages.unlockSettingsHelperText)}
                  hasTopAndBottomMargin
                  label={formatMessage(messages.unlockSettingsLabel)}
                  name="code"
                  placeholder={formatMessage(messages.unlockSettingsPlaceholder)}
                />
              </Grid>
            </Grid>
            <Grid container item justifyContent="flex-end">
              <Grid item>
                <Button color="primary" disabled={disableSubmit} type="submit" variant="contained">
                  <FormattedMessage {...messages.unlockSettingsButtonLabel} />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </Paper>
  );
}

export default UnlockSettings;
