import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import IconBox from '../../../components/IconBox';
import { getApartmentsCount } from '../helpers/getApartmentsCount';
import SectionTemplate from '../SectionTemplate';
import messages from './messages';

function ImportSummary({ importData }) {
  const { formatMessage } = useIntl();
  const importDataCount = useMemo(() => getApartmentsCount(importData), [importData]);
  return (
    <SectionTemplate title={formatMessage(messages.SiteUsersImportSummaryStepNumberOfTheImportContains)}>
      <Box sx={{ pb: 2, pl: 2 }}>
        <IconBox icon={<AccountCircleIcon color="secondary" />}>
          <Typography>
            <FormattedMessage
              {...messages.SiteUsersImportSummaryStepNumberOfUsers}
              values={{
                count: importData.length,
                strong: (chunks) => <strong>{chunks}</strong>,
              }}
            />
          </Typography>
        </IconBox>
        <IconBox icon={<FolderSharedIcon color="secondary" />}>
          <Typography>
            <FormattedMessage
              {...messages.SiteUsersImportSummaryStepNumberOfApartments}
              values={{
                count: importDataCount,
                strong: (chunks) => <strong>{chunks}</strong>,
              }}
            />
          </Typography>
        </IconBox>
      </Box>
    </SectionTemplate>
  );
}

ImportSummary.propTypes = {
  importData: PropTypes.array.isRequired,
};
export default ImportSummary;
