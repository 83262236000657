import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MOBILE_VIDEO_STATUS, MOBILE_VIDEO_TYPE, PATHS } from '../../config/consts';
import { getCountMobileVideoDevices } from '../../helpers/dashboard';
import { getIsValidUntil } from '../../helpers/mobileVideo';
import { useAppContext } from '../../pages/App/AppContextProvider';
import ButtonLink from '../ButtonLink';
import messages from './messages';

const MobileVideoAction = ({
  companyId,
  devices,
  licenceModel,
  mvService,
  onActivateService,
  onOpenAvailableCreditModal,
  onStartTrial,
  siteId,
}) => {
  const { feSiteRoles } = useAppContext();

  const getStartTrialButton = () => {
    if (licenceModel?.status === MOBILE_VIDEO_STATUS.INITIAL) {
      return (
        <Button color="primary" onClick={onStartTrial} startIcon={<AccessTimeIcon />} variant="contained">
          <FormattedMessage {...messages.mobileVideoCardStartTrial} />
        </Button>
      );
    }
    return null;
  };

  const getActivateServiceButton = () => {
    if (
      (licenceModel?.status === MOBILE_VIDEO_STATUS.TRIAL_EXPIRED ||
        licenceModel?.status === MOBILE_VIDEO_STATUS.STANDARD_EXPIRED) &&
      licenceModel?.requirePaymentCount === 0 &&
      licenceModel?.unlicensedCount > licenceModel?.requirePaymentCount
    ) {
      return (
        <Tooltip
          placement="right-start"
          title={<FormattedMessage {...messages.mobileVideoCardKeepUsingDevicesForFree} />}
        >
          <Button color="primary" onClick={onActivateService} variant="outlined">
            <FormattedMessage {...messages.mobileVideoCardActivateService} />
          </Button>
        </Tooltip>
      );
    }
    return null;
  };

  const getPurchaseSubscriptionButton = () => {
    if (
      (licenceModel?.status === MOBILE_VIDEO_STATUS.TRIAL_ACTIVE ||
        licenceModel?.status === MOBILE_VIDEO_STATUS.TRIAL_EXPIRED ||
        licenceModel?.status === MOBILE_VIDEO_STATUS.STANDARD_EXPIRED ||
        licenceModel?.status === MOBILE_VIDEO_STATUS.TEMPORARY ||
        (licenceModel?.status === MOBILE_VIDEO_STATUS.STANDARD &&
          licenceModel?.unlicensedCount > 0 &&
          licenceModel?.additional > licenceModel?.licencesPool)) &&
      feSiteRoles.isSiteAdminPayment
    ) {
      return (
        <ButtonLink
          color="primary"
          to={PATHS.SUBSCRIPTION_MOBILE_VIDEO.replace(':companyId', companyId).replace(':siteId', siteId)}
          variant="outlined"
        >
          <FormattedMessage {...messages.mobileVideoCardPurchaseSubscription} />
        </ButtonLink>
      );
    }
    return null;
  };

  const getUseAvailableCreditsButton = () => {
    if (
      licenceModel?.status === MOBILE_VIDEO_STATUS.STANDARD &&
      licenceModel?.unlicensedCount > 0 &&
      licenceModel?.additional <= licenceModel?.licencesPool
    ) {
      return (
        <Button color="primary" onClick={onOpenAvailableCreditModal} variant="outlined">
          <FormattedMessage {...messages.mobileVideoCardUseAvailableCredits} />
        </Button>
      );
    }
    return null;
  };

  const getActivateRecurringPaymentButton = () => {
    if (
      licenceModel?.status === MOBILE_VIDEO_STATUS.STANDARD &&
      !mvService?.subscribed &&
      feSiteRoles.isSiteAdminPayment
    ) {
      return (
        <ButtonLink
          color="primary"
          to={PATHS.SUBSCRIPTION_MOBILE_VIDEO.replace(':companyId', companyId).replace(':siteId', siteId)}
          variant="outlined"
        >
          <FormattedMessage {...messages.mobileVideoCardActivateRecurringPayment} />
        </ButtonLink>
      );
    }
    return null;
  };

  const getShowDevicesButton = () => {
    if (
      getCountMobileVideoDevices(devices).countError > 0 &&
      licenceModel?.status !== MOBILE_VIDEO_STATUS.DISABLED &&
      licenceModel?.status !== MOBILE_VIDEO_STATUS.EXPIRED
    ) {
      return (
        <ButtonLink
          color="primary"
          to={PATHS.DEVICES_OVERVIEW.replace(':companyId', companyId).replace(':siteId', siteId)}
          variant="outlined"
        >
          <FormattedMessage {...messages.mobileVideoCardShowDevices} />
        </ButtonLink>
      );
    }
    return null;
  };

  const getButton = () =>
    getStartTrialButton() ||
    getActivateServiceButton() ||
    getPurchaseSubscriptionButton() ||
    getUseAvailableCreditsButton() ||
    getActivateRecurringPaymentButton() ||
    getShowDevicesButton();

  const getText = () => {
    let textKey = '';
    if (licenceModel?.status === MOBILE_VIDEO_STATUS.DISABLED) {
      textKey = messages.mobileVideoCardContactCustomerCare;
    } else if (licenceModel?.status === MOBILE_VIDEO_STATUS.EXPIRED) {
      textKey = messages.mobileVideoCardContactDistributor;
    } else if (
      licenceModel?.status === MOBILE_VIDEO_STATUS.ACTIVE &&
      getIsValidUntil(licenceModel?.status, licenceModel)
    ) {
      textKey = messages.mobileVideoCardProlongService;
    } else if (licenceModel?.type === MOBILE_VIDEO_TYPE.STANDARD) {
      textKey = messages.mobileVideoCardPayForMobilePhonesOnly;
    }
    return (
      textKey && (
        <Typography color="secondary.main" variant="body2">
          <FormattedMessage {...textKey} />
        </Typography>
      )
    );
  };

  return getButton() || getText();
};

MobileVideoAction.propTypes = {
  companyId: PropTypes.number.isRequired,
  devices: PropTypes.arrayOf(PropTypes.object).isRequired,
  licenceModel: PropTypes.shape({
    additional: PropTypes.number,
    licencesPool: PropTypes.number,
    status: PropTypes.string,
    unlicensedCount: PropTypes.number,
    validityTo: PropTypes.string,
  }).isRequired,
  mvService: PropTypes.shape({
    subscribed: PropTypes.bool,
  }).isRequired,
  onActivateService: PropTypes.func.isRequired,
  onOpenAvailableCreditModal: PropTypes.func.isRequired,
  onStartTrial: PropTypes.func.isRequired,
  siteId: PropTypes.number.isRequired,
};

export default MobileVideoAction;
