import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from '../../components';
import ButtonDanger from '../../components/ButtonDanger';
import messages from './messages';

interface ResetMvSdkSettingsDialogProps {
  onClose: () => void;
  onConfirm: () => void;
  open: boolean;
}

interface ActionsProps {
  onClose: () => void;
  onConfirm: () => void;
}

interface WrappedButtonDangerProps {
  onClick: () => void;
  startIcon: React.ReactNode;
  children: React.ReactNode;
}

export function ResetMvSdkSettingsDialog({ onClose, onConfirm, open }: ResetMvSdkSettingsDialogProps) {
  return (
    <Modal
      actions={<Actions onClose={onClose} onConfirm={onConfirm} />}
      headerVariant="danger"
      maxWidth="xs"
      name="reset-settings-dialog"
      onClose={onClose}
      open={open}
      title={<FormattedMessage {...messages.resetSettingsDialogTitle} />}
      canClose={true}
      isForm={false}
      noCloseReasons={[]}
    >
      <Typography>
        <FormattedMessage {...messages.resetSettingsDialogContent} />
      </Typography>
    </Modal>
  );
}

function Actions({ onClose, onConfirm }: ActionsProps) {
  return (
    <div>
      <Button color="inherit" onClick={onClose}>
        <FormattedMessage {...messages.resetSettingsDialogCancel} />
      </Button>
      <WrappedButtonDanger onClick={onConfirm} startIcon={<DeleteIcon />}>
        {<FormattedMessage {...messages.resetSettingsDialogConfirm} />}
      </WrappedButtonDanger>
    </div>
  );
}

function WrappedButtonDanger({ children, onClick, startIcon }: WrappedButtonDangerProps) {
  return (
    <ButtonDanger onClick={onClick} startIcon={startIcon} {...({ children } as any)}>
      {children}
    </ButtonDanger>
  );
}
