import React from 'react';
import { ApartmentAccessGroupsSettings, Paper } from '../../../components';

function ApartmentAccess() {
  return (
    <Paper tabContent>
      <ApartmentAccessGroupsSettings />
    </Paper>
  );
}

export default ApartmentAccess;
