import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Box from '@mui/material/Box';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from '../../assets/my2n_logo.svg';
import { PATHS } from '../../config/consts';
import { selectors as commonSelectors } from '../../modules/common';
import ButtonLink from '../ButtonLink';
import HeaderPublicBurgerMenu from './HeaderPublicBurgerMenu';
import LanguageSelect from './LanguageSelect';
import messages from './messages';

const styles = {
  leftNavigation: {
    '& sup': {
      fontSize: '.6em',
    },
    display: {
      md: 'block',
      xs: 'none',
    },
    fontSize: '14px',
  },
  logo: {
    '& a': {
      '& svg': {
        height: 60,
        width: 135,
      },
      m: 0,
    },
    mr: {
      md: 4,
      xs: 1,
    },
  },
  root: {
    '& a': {
      '&:first-of-type': {
        ml: 0,
      },
      '&:hover': {
        color: 'primary.main',
      },
      '&:last-of-type': {
        mr: 0,
      },
      color: 'common.white',
      mx: 2,
      textDecoration: 'none',
      textTransform: 'initial',
    },
    background: '#051526',
    height: 80,
    px: {
      sm: 2,
      xs: 0,
    },
    width: '100%',
    zIndex: 15,
  },
  serviceAlert: (serviceAlertHeight) => ({
    marginBottom: `${serviceAlertHeight}px`,
    top: `${serviceAlertHeight}px`,
  }),
};

function HeaderPublic() {
  const isServiceAlertOpen = useSelector(commonSelectors.isServiceAlertOpen);
  const serviceAlertHeight = useSelector(commonSelectors.getServiceAlertHeight);

  return (
    <Box
      alignItems="center"
      component="header"
      display="flex"
      justifyContent="space-between"
      sx={{
        ...styles.root,
        ...(isServiceAlertOpen && styles.serviceAlert(serviceAlertHeight)),
      }}
    >
      <Box alignItems="center" display="flex">
        <Box sx={styles.logo}>
          <Link title="My2N.com" to={PATHS.HOMEPAGE}>
            <img alt="My2N" src={logo} />
          </Link>
        </Box>
        <Box component="nav" sx={styles.leftNavigation}>
          <Link to={PATHS.LANDING_MY2N_APP}>
            <FormattedMessage
              tagName="span"
              values={{ sup: (chunks) => <sup>{chunks}</sup> }}
              {...messages.headerPublicLogoMenuMobileVideo}
            />
          </Link>
          <Link to={PATHS.LANDING_REMOTE_CONFIGURATION}>
            <FormattedMessage
              tagName="span"
              values={{ sup: (chunks) => <sup>{chunks}</sup> }}
              {...messages.headerPublicLogoMenuRemoteConfiguration}
            />
          </Link>
        </Box>
      </Box>
      <Box alignItems="center" component="nav" display="flex">
        <LanguageSelect />
        <ButtonLink
          id="login-link"
          startIcon={<AccountCircleIcon sx={{ '&.MuiSvgIcon-root': { fontSize: '24px' } }} />}
          to={PATHS.LOGIN}
        >
          <FormattedMessage {...messages.headerPublicLogoMenuLogin} />
        </ButtonLink>
        <HeaderPublicBurgerMenu />
      </Box>
    </Box>
  );
}

export default HeaderPublic;
