import { SvgIcon } from '@mui/material';
import Badge from '@mui/material/Badge';
import React from 'react';
import { ReactComponent as ThirdPartyAxisSIP } from '../../assets/ThirdPartyAxisSIP.svg';
import Icon2N from '../Icon2N';
import { devicesWithSvgIcon, getIcon } from './deviceTypeHelpers';
import { Icon } from './Icon';

const styles = {
  badgeError: {
    '& .MuiBadge-badge': {
      backgroundColor: 'error.main',
    },
  },
  badgeWarning: {
    '& .MuiBadge-badge': {
      backgroundColor: 'warning.main',
    },
  },
};

export interface IDeviceTypeIconProps {
  deviceType: string;
  showAvatar?: boolean;
  showErrorBadge?: boolean;
  showWarningBadge?: boolean;
  small?: boolean;
  type?: string;
}

export function DeviceTypeIcon({
  deviceType,
  showAvatar,
  showErrorBadge,
  showWarningBadge,
  small,
  type,
}: IDeviceTypeIconProps) {
  const svgIcon = type !== undefined ? devicesWithSvgIcon[type] : undefined;
  return (
    <Badge
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'bottom',
      }}
      invisible={!showErrorBadge && !showWarningBadge}
      overlap="circular"
      sx={{
        ...(showWarningBadge && styles.badgeWarning),
        ...(showErrorBadge && styles.badgeError),
      }}
      variant="dot"
    >
      <Icon showAvatar={showAvatar}>
        {svgIcon !== undefined ? (
          <SvgIcon component={ThirdPartyAxisSIP} color="inherit" />
        ) : (
          <Icon2N name={`${getIcon(deviceType, type)}${small ? '-small' : ''}`} />
        )}
      </Icon>
    </Badge>
  );
}
