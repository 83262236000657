import { defineMessages } from 'react-intl';

export default defineMessages({
  configureMy2NAccessModal4DoNotManageHelper: {
    defaultMessage: 'My2N app access can’t be enabled for My2N users',
    id: 'configure.My2NAccess.Modal4.doNotManage.helper',
  },
  configureMy2NAccessModal4DoNotManageLabel: {
    defaultMessage: 'Don’t manage via My2N',
    id: 'configure.My2NAccess.Modal4.doNotManage.label',
  },
  configureMy2NAccessModal4DoNotManageWarn: {
    defaultMessage: 'My2N app access can’t be enabled for My2N users',
    id: 'configure.My2NAccess.Modal4.doNotManage.warning',
  },
  configureMy2NAccessModal4Info: {
    defaultMessage:
      'Doplnit kontext, proč je potřeba to vypnout, že by to s ACOM nefungovalo dobře a že má nástroj na správu BLE může později přesunout, ale vyžaduje to práci',
    id: 'configure.My2NAccess.Modal4.info',
  },
  configureMy2NAccessModal4ManageHelper: {
    defaultMessage: 'My2N app access can be easily enabled for My2N users',
    id: 'configure.My2NAccess.Modal4.manage.helper',
  },
  configureMy2NAccessModal4ManageLabel: {
    defaultMessage: 'Manage via My2N',
    id: 'configure.My2NAccess.Modal4.manage.label',
  },
});
