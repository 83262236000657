import { defineMessages } from 'react-intl';

export default defineMessages({
  CreateSiteModalCancel: {
    defaultMessage: 'Cancel',
    description: 'Create site modal - cancel button',
    id: 'createSiteModal.cancel',
  },
  CreateSiteModalContentTitle: {
    defaultMessage: 'A separate site is useful if you:',
    description: 'Create site modal - content title',
    id: 'createSiteModal.content.title',
  },
  CreateSiteModalLangInputHelperText: {
    defaultMessage:
      'User interface and emails are in this language.' + '{br}Users can set their preferred language later.',
    description: 'Create site modal - language input helper text',
    id: 'createSiteModal.langInput.helperText',
  },
  CreateSiteModalListBillingInfo: {
    defaultMessage: 'will use the same billing info for the new site',
    description: 'Create site modal - list item billing info',
    id: 'createSiteModal.list.billingInfo',
  },
  CreateSiteModalListDevices: {
    defaultMessage: 'want to interconnect a separate group of devices',
    description: 'Create site modal - list item devices',
    id: 'createSiteModal.list.devices',
  },
  CreateSiteModalListManageBuilding: {
    defaultMessage: 'manage several buildings or a large property',
    description: 'Create site modal - list item manage building',
    id: 'createSiteModal.list.manageBuilding',
  },
  createSiteModalListSiteLanguage: {
    defaultMessage:
      '<ul>' +
      '<li>site language affects user interface and emails</li>' +
      '<li>users can set their preferred language later</li>' +
      '<li>our platform uses automated translations, translation accuracy may vary for non-English languages</li>' +
      '</ul>',
    description: 'Create site modal - site language input description',
    id: 'createSiteModal.list.siteLanguage',
  },
  CreateSiteModalNameInputHelperText: {
    defaultMessage: 'Choose a helpful name',
    description: 'Create site modal - name input helper text',
    id: 'createSiteModal.nameInput.helperText',
  },
  CreateSiteModalNameInputLabel: {
    defaultMessage: 'New site name',
    description: 'Create site modal - name input label',
    id: 'createSiteModal.nameInput.label',
  },
  CreateSiteModalSubmit: {
    defaultMessage: 'Create site',
    description: 'Create site modal - submit button',
    id: 'createSiteModal.submit',
  },
  CreateSiteModalTitle: {
    defaultMessage: 'Create new site',
    description: 'Create site modal - title',
    id: 'createSiteModal.title',
  },
});
