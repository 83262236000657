import { IntlFormatters } from 'react-intl';
import messages from './messages';

export interface ILabel {
  label: string;
  placeholder?: string;
}
export interface IParams {
  labels: string[];
  formatMessage: IntlFormatters['formatMessage'];
}

export function getContactLabelsValue({ formatMessage, labels }: IParams): ILabel {
  if (labels.length === 0) {
    return {
      label: '',
    };
  }
  else if (labels.length === 1) {
    return {
      label: labels[0],
    };
  } else {
    return {
      label: '',
      placeholder: formatMessage(messages.AAmodalChangeLabelPlaceholderDifferent),
    };
  }
}
