import { IntlFormatters } from 'react-intl';
import { conditionalArray } from '../../../../../../helpers/array';
import { ApartmentUser } from '../../../../../../modules/apartmentAdmin/data';
import { hasPIN, hasRFID } from '../../../../../../modules/apartmentAdmin/utils';
import { isUserGuest } from '../../../../../../modules/user/utils';
import { isUWLUser } from '../../../../utils/UWLUserType';
import { IUserAccessSettingsItemAction } from '../components/UserAccessSettingsItem';
import { TestID } from '../testIds';
import messages from './messages';

export interface IPinActionsParams {
  user: ApartmentUser;
  openShowPinModal: () => void;
  openShowVisitorModal: () => void;
  openUWLDeletePinModal: () => void;
  openDeletePinModal: () => void;
  openShowQrModal: () => void;
  openGeneratePinModal: () => void;
  openVisitorRegenerateModal: () => void;
  formatMessage: IntlFormatters['formatMessage'];
}

export interface IQRActionsParams {
  user: ApartmentUser;
  openDeleteQRModal: () => void;
  openShowQrModal: () => void;
  openGeneratePinModal: () => void;
  openVisitorRegenerateModal: () => void;
  formatMessage: IntlFormatters['formatMessage'];
}

export interface IValidityActionsParams {
  user: ApartmentUser;
  openShowVisitorModal: () => void;
  openVisitorRegenerateModal: () => void;
  formatMessage: IntlFormatters['formatMessage'];
}

export interface IRfidActionsParams {
  user: ApartmentUser;
  openShowRfidModal: () => void;
  openUWLDeleteRfidModal: () => void;
  openDeleteRfidModal: () => void;
  formatMessage: IntlFormatters['formatMessage'];
}

export function getValidityActions({
  formatMessage,
  openShowVisitorModal,
  openVisitorRegenerateModal,
  user,
}: IValidityActionsParams): IUserAccessSettingsItemAction[] {
  return conditionalArray([
    {
      color: 'primary',
      label: hasPIN(user)
        ? formatMessage({ ...messages.AAUserDetailAccessEditValidity })
        : formatMessage({ ...messages.AAUserDetailAccessGeneratePIN }),
      onClick: hasPIN(user) ? openShowVisitorModal : openVisitorRegenerateModal,
      testId: TestID.EditValidity,
    },
  ]);
}

export function getQrActions({
  formatMessage,
  openDeleteQRModal,
  openGeneratePinModal,
  openShowQrModal,
  openVisitorRegenerateModal,
  user,
}: IQRActionsParams): IUserAccessSettingsItemAction[] {
  return conditionalArray([
    isUserGuest(user.role) &&
      hasPIN(user) && {
        label: formatMessage({ ...messages.AAUserDetailAccessShowPIN }),
        onClick: openShowQrModal,
        testId: TestID.ShowQrPin,
      },
    hasPIN(user) && {
      color: 'error',
      label: formatMessage({ ...messages.AAUserDetailAccessDeletePIN }),
      onClick: openDeleteQRModal,
      testId: TestID.DeletePin,
    },
    !hasPIN(user) && {
      color: 'primary',
      label: formatMessage({ ...messages.AAUserDetailAccessGeneratePIN }),
      onClick: isUserGuest(user.role) ? openVisitorRegenerateModal : openGeneratePinModal,
      testId: TestID.GeneratePin,
    },
  ]);
}

export function getPinActions({
  formatMessage,
  openDeletePinModal,
  openGeneratePinModal,
  openShowPinModal,
  openShowVisitorModal,
  openUWLDeletePinModal,
  openVisitorRegenerateModal,
  user,
}: IPinActionsParams): IUserAccessSettingsItemAction[] {
  return conditionalArray([
    hasPIN(user) && {
      label: isUserGuest(user.role)
        ? formatMessage({ ...messages.AAUserDetailAccessShowEditPIN })
        : formatMessage({ ...messages.AAUserDetailAccessShowPIN }),
      onClick: isUserGuest(user.role) ? openShowVisitorModal : openShowPinModal,
      testId: isUserGuest(user.role) ? TestID.ShowEditPin : TestID.ShowPin,
    },
    hasPIN(user) && {
      color: 'error',
      label: formatMessage({ ...messages.AAUserDetailAccessDeletePIN }),
      onClick: () => {
        isUWLUser(user) ? openUWLDeletePinModal() : openDeletePinModal();
      },
      testId: TestID.DeletePin,
    },
    !hasPIN(user) && {
      color: 'primary',
      label: formatMessage({ ...messages.AAUserDetailAccessGeneratePIN }),
      onClick: isUserGuest(user.role) ? openVisitorRegenerateModal : openGeneratePinModal,
      testId: TestID.GeneratePin,
    },
  ]);
}

export function getRfidActions({
  formatMessage,
  openDeleteRfidModal,
  openShowRfidModal,
  openUWLDeleteRfidModal: openUWLDeleteRfidPModal,
  user,
}: IRfidActionsParams): IUserAccessSettingsItemAction[] {
  return conditionalArray([
    hasRFID(user) && {
      label: formatMessage({ ...messages.AAUserDetailAccessShowRFID }),
      onClick: openShowRfidModal,
      testId: TestID.ShowRFID,
    },
    hasRFID(user) && {
      color: 'error',
      label: formatMessage({ ...messages.AAUserDetailAccessDeleteRFID }),
      onClick: () => {
        isUWLUser(user) ? openUWLDeleteRfidPModal() : openDeleteRfidModal();
      },
      testId: TestID.DeleteRFID,
    },
  ]);
}
