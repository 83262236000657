import { defineMessages } from 'react-intl';

export default defineMessages({
  UsersImportButtonLabel: {
    defaultMessage: 'Import users',
    description: 'Button users import - label',
    id: 'usersImportButton.label',
  },
  UsersImportButtonLabelMobile: {
    defaultMessage: 'Import',
    description: 'Button users import - label for mobile devices',
    id: 'usersImportButton.labelMobile',
  },
});
