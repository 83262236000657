import { defineMessages } from 'react-intl';

export default defineMessages({
  licenseDetailModalClose: {
    defaultMessage: 'Close',
    description: 'License detail modal - close button',
    id: 'licenseDetailModal.close',
  },
  licenseDetailModalTableLicenseFeatures: {
    defaultMessage: 'License features',
    description: 'License detail modal table - license features',
    id: 'licenseDetailModal.table.licenseFeatures',
  },
  licenseDetailModalTableLicenseKey: {
    defaultMessage: 'License key',
    description: 'License detail modal table - license key',
    id: 'licenseDetailModal.table.licenseKey',
  },
  licenseDetailModalTableSerialNumber: {
    defaultMessage: 'Serial number',
    description: 'License detail modal table - serial number',
    id: 'licenseDetailModal.table.serialNumber',
  },
  licenseDetailModalTitle: {
    defaultMessage: 'License detail for invoice No.',
    description: 'License detail modal - title',
    id: 'licenseDetailModal.title',
  },
  licensesTypeLICENCE_ACCESS_UNIT_LIFT_MODULE: {
    defaultMessage: '9160401 - 2N Access Unit - Lift module license',
    description: 'OneStep - nfc',
    id: 'licenses.type.LICENCE_ACCESS_UNIT_LIFT_MODULE',
  },
  licensesTypeLICENCE_ENHANCED_AUDIO: {
    defaultMessage: '9137905 - 2N IP Intercom - Enhanced Audio license',
    description: 'License - audio',
    id: 'licenses.type.LICENCE_ENHANCED_AUDIO',
  },
  licensesTypeLICENCE_ENHANCED_INTEGRATION: {
    defaultMessage: '9137907 - 2N IP Intercom - Enhanced Integration license',
    description: 'License - integration',
    id: 'licenses.type.LICENCE_ENHANCED_INTEGRATION',
  },
  licensesTypeLICENCE_ENHANCED_SECURITY: {
    defaultMessage: '9137908 - 2N IP Intercom - Enhanced Security license',
    description: 'License - security',
    id: 'licenses.type.LICENCE_ENHANCED_SECURITY',
  },
  licensesTypeLICENCE_ENHANCED_VIDEO: {
    defaultMessage: '9137906 - 2N IP Intercom - Enhanced Video license',
    description: 'License - video',
    id: 'licenses.type.LICENCE_ENHANCED_VIDEO',
  },
  licensesTypeLICENCE_GOLD: {
    defaultMessage: '9137909 - 2N IP Intercom - Gold license',
    description: 'License - gold',
    id: 'licenses.type.LICENCE_GOLD',
  },
  licensesTypeLICENCE_INFORMCAST: {
    defaultMessage: '9137910 - 2N IP Intercom - Informacast license',
    description: 'License - informacast',
    id: 'licenses.type.LICENCE_INFORMCAST',
  },
  licensesTypeLICENCE_INTERCOM_LIFT_MODULE: {
    defaultMessage: '9137916 - 2N IP Intercom - Lift module license',
    description: 'License - lift',
    id: 'licenses.type.LICENCE_INTERCOM_LIFT_MODULE',
  },
  licensesTypeLICENCE_NFC: {
    defaultMessage: '9137915 - 2N IP Intercom - NFC license',
    description: 'License - nfc',
    id: 'licenses.type.LICENCE_NFC',
  },
  licensesTypeLICENCE_NFC_ACCESS_UNIT: {
    defaultMessage: '916012 - 2N Access Unit - NFC license',
    description: 'OneStep - nfc',
    id: 'licenses.type.LICENCE_NFC_ACCESS_UNIT',
  },
  paymentsTableAnnualSubscription: {
    defaultMessage: 'Annual subscription',
    description: 'PaymentsTable - annual subscription',
    id: 'payments.table.annualSubscription',
  },
  paymentsTableColumnsCompany: {
    defaultMessage: 'Company',
    description: 'PaymentsTable - Company column',
    id: 'payments.table.columns.company',
  },
  paymentsTableColumnsDate: {
    defaultMessage: 'Date',
    description: 'PaymentsTable - Date column',
    id: 'payments.table.columns.date',
  },
  paymentsTableColumnsInvoiceNumber: {
    defaultMessage: 'Invoice Number',
    description: 'PaymentsTable - Invoice Number column',
    id: 'payments.table.columns.invoiceNumber',
  },
  paymentsTableColumnsLicenseKeys: {
    defaultMessage: 'License keys',
    description: 'PaymentsTable - license keys',
    id: 'payments.table.columns.licenseKeys',
  },
  paymentsTableColumnsPaymentNumber: {
    defaultMessage: 'Payment Number',
    description: 'PaymentsTable - Payment Number column',
    id: 'payments.table.columns.paymentNumber',
  },
  paymentsTableColumnsPoNumber: {
    defaultMessage: 'PO Number',
    description: 'PaymentsTable - PO Number column',
    id: 'payments.table.columns.poNumber',
  },
  paymentsTableColumnsPrice: {
    defaultMessage: 'Price',
    description: 'PaymentsTable - Price column',
    id: 'payments.table.columns.price',
  },
  paymentsTableColumnsService: {
    defaultMessage: 'Service',
    description: 'PaymentsTable - Service column',
    id: 'payments.table.columns.service',
  },
  paymentsTableColumnsStatus: {
    defaultMessage: 'Status',
    description: 'PaymentsTable - Status column',
    id: 'payments.table.columns.status',
  },
  paymentsTableDetail: {
    defaultMessage: 'Detail',
    description: 'PaymentsTable - detail button for license keys',
    id: 'payments.table.detail',
  },
  paymentsTableErrorCARD_ERROR: {
    defaultMessage: `The payment was not successful. The entered credit card has expired.
            Please try again with a different credit card.`,
    description: 'PaymentsTable - card error',
    id: 'payments.table.error.CARD_ERROR',
  },
  paymentsTableErrorCARD_ISSUER_ERROR: {
    defaultMessage: `The payment was not successful. Please contact your credit
            card provider.`,
    description: 'PaymentsTable - card issuer error',
    id: 'payments.table.error.CARD_ISSUER_ERROR',
  },
  paymentsTableErrorCREDIT_LIMIT_EXCEEDED: {
    defaultMessage: 'The credit limit has been exceeded.',
    description: 'PaymentsTable - credit limit exceeded error',
    id: 'payments.table.error.CREDIT_LIMIT_EXCEEDED',
  },
  paymentsTableErrorGATEWAY_ERROR: {
    defaultMessage: `The payment was not successful. The payment gateway is not available.
            Please try again later.`,
    description: 'PaymentsTable - gateway error',
    id: 'payments.table.error.GATEWAY_ERROR',
  },
  paymentsTableErrorGENERIC: {
    defaultMessage: `The payment was not successful, please try again later or
            contact our support.`,
    description: 'PaymentsTable - generic error',
    id: 'payments.table.error.GENERIC',
  },
  paymentsTableErrorINVOICE_PAYMENT_ERROR: {
    defaultMessage: 'There was an error while processing the order.',
    description: 'PaymentsTable - invoice payment error',
    id: 'payments.table.error.INVOICE_PAYMENT_ERROR',
  },
  paymentsTableErrorMY2N_ERROR: {
    defaultMessage: `The payment was not successful. We are working hard to fix the issue.
            Please try again later.`,
    description: 'PaymentsTable - my2n error',
    id: 'payments.table.error.MY2N_ERROR',
  },
  paymentsTableErrorNOT_PROCESSED: {
    defaultMessage: 'The payment has been cancelled by the user.',
    description: 'PaymentsTable - not processed error',
    id: 'payments.table.error.NOT_PROCESSED',
  },
  paymentsTableErrorSESSION_EXPIRED: {
    defaultMessage: 'The payment has failed due to timeout.',
    description: 'PaymentsTable - session expired error',
    id: 'payments.table.error.SESSION_EXPIRED',
  },
  paymentsTableErrorUSER_ERROR: {
    defaultMessage: `The payment was not successful. You have entered incorrect
            credit card details, please try again.`,
    description: 'PaymentsTable - user error',
    id: 'payments.table.error.USER_ERROR',
  },
  paymentsTableInvoice: {
    defaultMessage: 'Invoice',
    description: 'PaymentsTable - invoice button',
    id: 'payments.table.invoice',
  },
  paymentsTableMonthlySubscription: {
    defaultMessage: 'Monthly subscription',
    description: 'PaymentsTable - monthly subscription',
    id: 'payments.table.monthlySubscription',
  },
  paymentsTableNoResultsCompany: {
    defaultMessage: 'You have not made any payments yet.',
    description: 'PaymentsTable - no results context company',
    id: 'payments.table.noResultsCompany',
  },
  paymentsTableNoResultsFound: {
    defaultMessage: 'No results found.',
    description: 'PaymentsTable - no results found',
    id: 'payments.table.noResultsFound',
  },
  paymentsTableNoResultsSites: {
    defaultMessage: `You have not purchased any 2N<sup>®</sup> Mobile Video subscriptions yet.
            <purchaseSubscriptionUrl>Purchase subscription</purchaseSubscriptionUrl>.`,
    description: 'PaymentsTable - no results context sites',
    id: 'payments.table.noResultsSites',
  },
  paymentsTableNoResultsSuperadmin: {
    defaultMessage: 'The company has not made any payments yet.',
    description: 'PaymentsTable - no results context superadmin',
    id: 'payments.table.noResultsSuperadmin',
  },
  paymentsTablePriceVat: {
    defaultMessage: '({price} incl. VAT)',
    description: 'PaymentsTable - price VAT',
    id: 'payments.table.priceVat',
  },
  paymentsTablePurchaseSubscription: {
    defaultMessage: 'Purchase subscription',
    description: 'PaymentsTable - purchase subscription',
    id: 'payments.table.purchaseSubscription',
  },
  paymentsTableServiceIP_INTERCOM: {
    defaultMessage: '2N IP Intercom License',
    description: 'PaymentsTable - Ip Intercom',
    id: 'payments.table.service.IP_INTERCOM',
  },
  paymentsTableServiceMOBILE_VIDEO: {
    defaultMessage: 'Mobile Video',
    description: 'PaymentsTable - Mobile Video',
    id: 'payments.table.service.MOBILE_VIDEO',
  },
  paymentsTableServicePROMOCount: {
    defaultMessage: '({count, plural, one {# batch} other {# batches}})',
    description: 'PaymentsTable - promo codes',
    id: 'payments.table.service.PROMO.count',
  },
  paymentsTableServicePROMODetail: {
    defaultMessage:
      'The order contains {count, plural, one {# batch of codes}' + ' other {# batches of codes}}: {codes}',
    description: 'PaymentsTable - promo codes detail',
    id: 'payments.table.service.PROMO.detail',
  },
  paymentsTableServicePROMOLabel: {
    defaultMessage: 'Activation codes {count}',
    description: 'PaymentsTable - promo codes label',
    id: 'payments.table.service.PROMO.label',
  },
  paymentsTableStatusInfo: {
    defaultMessage: `Primary code: {primaryCode} -secondary code:
          {secondaryCode} -payment result: {paymentResult}`,
    description: 'PaymentsTable - status info',
    id: 'payments.table.statusInfo',
  },
  paymentStatusBEFORE_COMPLETION: {
    defaultMessage: 'Generating licenses',
    description: '',
    id: 'payments.table.paymentStatusBEFORE_COMPLETION',
  },
  paymentStatusCANCELLED: {
    defaultMessage: 'Payment cancelled',
    description: '',
    id: 'payments.table.paymentStatusCANCELLED',
  },
  paymentStatusCOMPLETED: {
    defaultMessage: 'Delivered',
    description: '',
    id: 'payments.table.paymentStatusCOMPLETED',
  },
  paymentStatusCREATED: {
    defaultMessage: 'Incomplete',
    description: '',
    id: 'payments.table.paymentStatusCREATED',
  },
  paymentStatusDELIVERED: {
    defaultMessage: 'Generating licenses',
    description: '',
    id: 'payments.table.paymentStatusDELIVERED',
  },
  paymentStatusFAILED: {
    defaultMessage: 'Payment failed',
    description: '',
    id: 'payments.table.paymentStatusFAILED',
  },
  paymentStatusINVOICE_ISSUED: {
    defaultMessage: 'Generating licenses',
    description: '',
    id: 'payments.table.paymentStatusINVOICE_ISSUED',
  },
  paymentStatusPAID: {
    defaultMessage: 'Generating licenses',
    description: '',
    id: 'payments.table.paymentStatusPAID',
  },
  paymentStatusREFUNDED: {
    defaultMessage: 'Payment refunded',
    description: '',
    id: 'payments.table.paymentStatusREFUNDED',
  },
  paymentsTitle: {
    defaultMessage: 'Payments',
    description: 'Payments - title',
    id: 'payments.title',
  },
  promoDetailModalClose: {
    defaultMessage: 'Close',
    description: 'Promo detail modal - close button',
    id: 'promoDetailModal.close',
  },
  promoDetailModalCopyAllButton: {
    defaultMessage: 'Copy all codes',
    description: 'License detail modal - copy all button',
    id: 'promoDetailModal.copyAllButton',
  },
  promoDetailModalTitle: {
    defaultMessage: 'Detail of {name}',
    description: 'Promo detail modal - title',
    id: 'promoDetailModal.title',
  },
  subscriptionInfoCount: {
    defaultMessage: 'Number of codes',
    description: 'Subscription info - count label',
    id: 'subscriptionInfo.count',
  },
  subscriptionInfoPrice: {
    defaultMessage: 'Total price',
    description: 'Validity info - price label',
    id: 'subscriptionInfo.price',
  },
  subscriptionInfoSubscriptions: {
    defaultMessage: 'Subscriptions for 1 code',
    description: 'Validity info - subscriptions label',
    id: 'subscriptionInfo.subscriptions',
  },
  subscriptionInfoTotal: {
    defaultMessage: 'Total value',
    description: 'Validity info - total label',
    id: 'subscriptionInfo.total',
  },
  subscriptionInfoValueCount: {
    defaultMessage: '{value} codes',
    description: 'Subscription info - count value',
    id: 'subscriptionInfo.value.count',
  },
  subscriptionInfoValuePriceVat: {
    defaultMessage: '{price} ({priceVat} incl. VAT)',
    description: 'Validity info - priceVat value',
    id: 'subscriptionInfo.value.priceVat',
  },
  subscriptionInfoValueSubscriptionsMOBILE_VIDEO_POOL_CREDIT_YEAR_APARTMENT: {
    defaultMessage: '{value, plural, one {<strong>#</strong> year}' + 'other {<strong>#</strong> years}}/apartment',
    description: 'Validity info - subscriptions value years apartment',
    id: 'subscriptionInfo.value.subscriptions.MOBILE_VIDEO_POOL_CREDIT_YEAR_APARTMENT',
  },
  subscriptionInfoValueSubscriptionsMOBILE_VIDEO_POOL_CREDIT_YEAR_STANDARD: {
    defaultMessage: '{value, plural, one {<strong>#</strong> year}' + 'other {<strong>#</strong> years}}/device',
    description: 'Validity info - subscriptions value MOBILE_VIDEO_POOL_CREDIT_YEAR_STANDARD',
    id: 'subscriptionInfo.value.subscriptions.MOBILE_VIDEO_POOL_CREDIT_YEAR_STANDARD',
  },
  subscriptionInfoValueSubscriptionsOther: {
    defaultMessage: '{value, plural, one {<strong>#</strong> month}' + 'other {<strong>#</strong> months}}/device',
    description: 'Validity info - subscriptions value other',
    id: 'subscriptionInfo.value.subscriptionsOther',
  },
  subscriptionInfoValueTotalMOBILE_VIDEO_POOL_CREDIT_YEAR_APARTMENT: {
    defaultMessage: '{value, plural, one {<strong>#</strong> year}' + 'other {<strong>#</strong> years}}/apartment',
    description: 'Validity info - total value years apartment',
    id: 'subscriptionInfo.value.total.MOBILE_VIDEO_POOL_CREDIT_YEAR_APARTMENT',
  },
  subscriptionInfoValueTotalMOBILE_VIDEO_POOL_CREDIT_YEAR_STANDARD: {
    defaultMessage: '{value, plural, one {<strong>#</strong> year}' + 'other {<strong>#</strong> years}}/device',
    description: 'Validity info - total value MOBILE_VIDEO_POOL_CREDIT_YEAR_STANDARD',
    id: 'subscriptionInfo.value.total.MOBILE_VIDEO_POOL_CREDIT_YEAR_STANDARD',
  },
  subscriptionInfoValueTotalOther: {
    defaultMessage: '<strong>{value}</strong> m/device',
    description: 'Validity info - total value other',
    id: 'subscriptionInfo.value.totalOther',
  },
  validityInfoInvoice: {
    defaultMessage: 'Invoice',
    description: 'Validity info - invoice label',
    id: 'validityInfo.invoice',
  },
  validityInfoValidityFrom: {
    defaultMessage: 'Valid from',
    description: 'Validity info - from label',
    id: 'validityInfo.validityFrom',
  },
  validityInfoValidityTo: {
    defaultMessage: 'Valid to',
    description: 'Validity info - to label',
    id: 'validityInfo.validityTo',
  },
});
