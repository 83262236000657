import React, { ReactNode } from 'react';
import { EmptyValue } from '../EmptyValue';
import ItemService from '../IconServices/ItemService';
import { ITypographyWithEllipsisTooltipProps, TypographyWithEllipsisTooltip } from '../TypographyWithEllipsisTooltip';
import UserAccessSettingsStatus from '../UserAccessSettingsStatus';
import UserEmail from '../UserEmail';
import { ApartmentUserRoleName, UserRoleName } from '../UserRoleName';
import {
  ICellContentComponentBase,
  isCellContentApartmentUserRoleComponent,
  isCellContentEmailComponent,
  isCellContentIconAndNameComponent,
  isCellContentServiceItemComponent,
  isCellContentUserAccessStatusIconComponent,
  isCellContentUserRoleComponent,
} from './cellContentComponentsData';
import { IconAndName } from './components/IconAndName';
import { IAggregatedColumn } from './data';

export type MuiRow<T extends object> = { id: number; data: T; isAggregatedDataType?: boolean };

const typoBaseConfig: Partial<ITypographyWithEllipsisTooltipProps> = {
  component: 'span',
  variant: 'body2',
};

export function renderAggregatedCell<TAggregatedItem extends object, TItem extends object>(
  rowData: MuiRow<TAggregatedItem | TItem>,
  column: IAggregatedColumn<TAggregatedItem, TItem>
): string | ReactNode {
  if (isAggregatedDataType(rowData)) {
    return renderCellContentComponent(column.aggregatedRowContent(rowData.data as TAggregatedItem));
  }
  return renderCellContentComponent(column.content((rowData as any).data as TItem));
}

export function renderCellContentComponent(cellContent: ReactNode | ICellContentComponentBase): string | ReactNode {
  if (isCellContentIconAndNameComponent(cellContent)) {
    return <IconAndName {...cellContent.data} />;
  }
  if (isCellContentEmailComponent(cellContent)) {
    return <UserEmail {...cellContent.data} />;
  }
  if (isCellContentUserRoleComponent(cellContent)) {
    return (
      <TypographyWithEllipsisTooltip {...typoBaseConfig}>
        <UserRoleName {...cellContent.data} />
      </TypographyWithEllipsisTooltip>
    );
  }
  if (isCellContentApartmentUserRoleComponent(cellContent)) {
    return (
      <TypographyWithEllipsisTooltip {...typoBaseConfig}>
        <ApartmentUserRoleName {...cellContent.data} />
      </TypographyWithEllipsisTooltip>
    );
  }
  if (isCellContentServiceItemComponent(cellContent)) {
    return <ItemService {...cellContent.data} />;
  }
  if (isCellContentUserAccessStatusIconComponent(cellContent)) {
    return <UserAccessSettingsStatus {...cellContent.data} />;
  }

  if (cellContent && typeof cellContent === 'string') {
    return <TypographyWithEllipsisTooltip {...typoBaseConfig}>{cellContent}</TypographyWithEllipsisTooltip>;
  }
  return cellContent === '' || cellContent === undefined || cellContent === null ? <EmptyValue /> : cellContent;
}

export function isAggregatedDataType<TAggregatedItem extends object, TItem extends object>(
  object: MuiRow<TAggregatedItem> | MuiRow<TItem>
): object is MuiRow<TAggregatedItem> {
  return !!object.isAggregatedDataType;
}
