import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonLink, LoadingButton } from '../../../components';
import { PATHS } from '../../../config/consts';
import { useUrlParams } from '../../../helpers/urlParams';
import { useAppContext } from '../../../pages/App/AppContextProvider';
import { isDisabledMobileVideoLicense } from '../routeHelpers';
import messages from './messages';

const ActionsSection = ({ isLoading, isMobileVideoAllowed, isNextStepDisabled, isVatVerified, onNextStep }) => {
  const handleNextStepButtonClick = () => onNextStep();
  const { feSiteRoles, userRoles } = useAppContext();
  const { companyId, siteId } = useUrlParams();

  return (
    <Grid container item justifyContent="space-between">
      {feSiteRoles.isSiteAdminPayment && (
        <Box display={{ sm: 'block', xs: 'none' }}>
          <Grid item xs>
            <ButtonLink
              color="primary"
              disabled={isDisabledMobileVideoLicense(isMobileVideoAllowed, userRoles, isVatVerified)}
              to={PATHS.SUBSCRIPTION_MOBILE_VIDEO.replace(':companyId', companyId).replace(':siteId', siteId)}
            >
              <FormattedMessage {...messages.actionsSectionPurchaseSubscriptionButtonLabel} />
            </ButtonLink>
          </Grid>
        </Box>
      )}
      <Grid container item justifyContent="flex-end" xs>
        <Grid item>
          <LoadingButton
            color="primary"
            disabled={isNextStepDisabled || isLoading}
            isLoading={isLoading}
            onClick={handleNextStepButtonClick}
            variant="contained"
          >
            <FormattedMessage {...messages.actionsSectionNextStepButtonLabel} />
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

ActionsSection.propTypes = {
  isLoading: PropTypes.bool,
  isMobileVideoAllowed: PropTypes.bool.isRequired,
  isNextStepDisabled: PropTypes.bool.isRequired,
  isVatVerified: PropTypes.bool.isRequired,
  onNextStep: PropTypes.func.isRequired,
};

ActionsSection.defaultProps = {
  isLoading: false,
};

export default ActionsSection;
