import Box from '@mui/material/Box';
import React from 'react';
import { useSelector } from 'react-redux';
import { IMPORT_USERS_STATES } from '../../config/consts';
import getImportStats from '../../containers/SiteUsersImport/helpers/getImportStats';
import ImportResultFailed from '../../containers/SiteUsersImport/ResultStep/ImportResultFailed';
import ImportResultSuccess from '../../containers/SiteUsersImport/ResultStep/ImportResultSuccess';
import { selectors } from '../../modules/user';

function ImportResultStatus() {
  const currentImportUsersData = useSelector(selectors.currentImportUsersData);
  const importStats = getImportStats(currentImportUsersData);
  return (
    <Box>
      {currentImportUsersData?.state === IMPORT_USERS_STATES.SUCCESS ? (
        <ImportResultSuccess importStats={importStats} />
      ) : (
        <ImportResultFailed importStats={importStats} inline />
      )}
    </Box>
  );
}

export default ImportResultStatus;
