import { defineMessages } from 'react-intl';

export default defineMessages({
  accessSettingsDataGridChipsEmpty: {
    defaultMessage: 'No devices',
    description: 'Access settings table - no devices label',
    id: 'accessSettingsDataGrid.chips.empty',
  },
  accessSettingsDataGridChipsRacActive: {
    defaultMessage: 'Residential Access service is active',
    description: 'Access settings table - devices disabled tooltip',
    id: 'accessSettingsDataGrid.chips.rac.active',
  },
  accessSettingsDataGridChipsRacInactive: {
    defaultMessage: 'Residential Access service is inactive',
    description: 'Access settings table - devices disabled tooltip',
    id: 'accessSettingsDataGrid.chips.rac.inactive',
  },
  accessSettingsDataGridColumnsApartments: {
    defaultMessage: 'Apartments',
    description: 'Access settings table - apartments column',
    id: 'accessSettingsDataGrid.columns.apartments',
  },
  accessSettingsDataGridColumnsDevicesLabel: {
    defaultMessage: 'Devices in group',
    description: 'Access settings table - devices column label',
    id: 'accessSettingsDataGrid.columns.devices.label',
  },
  accessSettingsDataGridColumnsName: {
    defaultMessage: 'Access groups',
    description: 'Access settings table - column name',
    id: 'accessSettingsDataGrid.columns.name',
  },
  accessSettingsDataGridColumnsUsers: {
    defaultMessage: 'Users',
    description: 'Access settings table - users column',
    id: 'accessSettingsDataGrid.columns.users',
  },
  accessSettingsDataGridNoRowsOverlayButton: {
    defaultMessage: 'Create access group',
    description: 'Access settings table - button',
    id: 'accessSettingsDataGrid.noRowsOverlay.button',
  },
  accessSettingsDataGridNoRowsOverlayHeaderText: {
    defaultMessage: 'No Access groups yet',
    description: 'Access group devices no rows - headerText',
    id: 'accessSettingsDataGrid.noRowsOverlay.headerText',
  },
  accessSettingsDataGridNoRowsOverlayText: {
    defaultMessage:
      'Access groups define which users have access to which devices.' +
      '{br}Once you create an Access group, it will appear here.',
    description: 'Access group devices no rows - text',
    id: 'accessSettingsDataGrid.noRowsOverlay.text',
  },
});
