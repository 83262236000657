import { defineMessages } from 'react-intl';

export default defineMessages({
  configureMy2NAccessModal4CustomLocationIdLabel: {
    defaultMessage: 'Your location ID',
    id: 'configure.My2NAccess.Modal4.customLocationID.label',
  },
  configureMy2NAccessModal4LocationIDFound: {
    defaultMessage: 'Found on the site',
    id: 'configure.My2NAccess.Modal4.locationID.found',
  },
  configureMy2NAccessModal4LocationIDGenerate: {
    defaultMessage: 'Generate new ID',
    id: 'configure.My2NAccess.Modal4.locationID.generate',
  },
  configureMy2NAccessModal4LocationIDLabel: {
    defaultMessage: 'New location ID',
    id: 'configure.My2NAccess.Modal4.locationID.label',
  },
  configureMy2NAccessModal4LocationIDOwn: {
    defaultMessage: 'Set your own ID',
    id: 'configure.My2NAccess.Modal4.locationID.own',
  },
  configureMy2NAccessModal4LocationIDPlaceholder: {
    defaultMessage: 'Select location ID',
    id: 'configure.My2NAccess.Modal4.locationID.placeholder',
  },
  configureMy2NAccessModal4ManageInfo: {
    defaultMessage:
      'Existing credentials should still work, but we can’t guarantee full functionality. Please verify them.',
    id: 'configure.My2NAccess.Modal4.manage.info',
  },
  configureMy2NAccessModal4ManageWarnA: {
    defaultMessage:
      'Existing credentials should still work, but we can’t guarantee full functionality. Please verify them.',
    id: 'configure.My2NAccess.Modal4.manage.warning.A',
  },
  configureMy2NAccessModal4ManageWarnB: {
    defaultMessage:
      'Credentials created outside of My2N will be deleted. Their users will no longer be able to open doors using the app.',
    id: 'configure.My2NAccess.Modal4.manage.warning.B',
  },
  configureMy2NAccessModal4ManageWarnC: {
    defaultMessage:
      'Existing credentials should still work, but we can’t guarantee full functionality. Please verify them.',
    id: 'configure.My2NAccess.Modal4.manage.warning.C',
  },
});
