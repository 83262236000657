import { defaults, pick } from 'lodash';
import { call, select } from 'redux-saga/effects';
import * as Api from '../../../api/deviceApi';
import { CONFIGURATION_MODE, CONNECTION_MODE, SERVICE } from '../../../config/devices';
import { selectors as companySelectors } from '../../../modules/company';
import { selectors } from '../../../modules/devices';
import { responseValidateStatusOrThrow } from '../../../modules/helpers';
import { selectors as siteSelectors } from '../../../modules/sites';
import { hasConnectionModeField } from './DeviceDetailNetworkSettings/helpers';

export function* editDeviceBase(values) {
  const companyId = yield select(companySelectors.getCompanyId);
  const siteId = yield select(siteSelectors.getSiteId);
  const device = yield select(selectors.getCurrentDeviceDataSelector);

  const defaultValues = pick(device, ['name', 'userId', 'type', 'externalId', 'apartmentId', 'address']);
  const valuesBasic = pick(values, ['name', 'userId', 'apartmentId']);
  if (Object.keys(valuesBasic).length > 0) {
    const response = yield call(Api.editDevice, companyId, siteId, device.id, defaults(valuesBasic, defaultValues));
    yield responseValidateStatusOrThrow(response);
  }
}

export function* editLocalCallsIdentifier(values) {
  if (values.localCallsIdentifier) {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(siteSelectors.getSiteId);
    const device = yield select(selectors.getCurrentDeviceDataSelector);

    const data = device?.services?.[SERVICE.LOCAL_CALLS];
    if (data) {
      data.name = values.localCallsIdentifier;
      const response = yield call(Api.editDeviceMode, companyId, siteId, device.id, SERVICE.LOCAL_CALLS, data);
      yield responseValidateStatusOrThrow(response);
    }
  }
}

export function* answeringUnitsChange(values) {
  const device = yield select(selectors.getCurrentDeviceDataSelector);
  if (values.connectionMode && hasConnectionModeField(device.type)) {
    yield toggleService(
      SERVICE.MOBILE_VIDEO,
      device.id,
      device?.services?.[SERVICE.MOBILE_VIDEO],
      values.connectionMode === CONNECTION_MODE.CLOUD
    );
    yield toggleService(
      SERVICE.LOCAL_CALLS,
      device.id,
      device?.services?.[SERVICE.LOCAL_CALLS],
      !device?.services?.[SERVICE.LOCAL_CALLS].active
    );
  }
}

export function* acsSwitch(values) {
  const device = yield select(selectors.getCurrentDeviceDataSelector);
  if (values.configurationMode) {
    const serviceData = device?.services?.[SERVICE.ACS];
    const mode = serviceData?.active ? CONFIGURATION_MODE.AUTOMATIC : CONFIGURATION_MODE.MANUAL;
    if (mode !== values.configurationMode) {
      yield toggleService(SERVICE.ACS, device.id, serviceData, !serviceData?.active);
    }
  }
}

export function* toggleService(service, deviceId, serviceData, active) {
  const companyId = yield select(companySelectors.getCompanyId);
  const siteId = yield select(siteSelectors.getSiteId);
  const response = yield call(Api.editDeviceMode, companyId, siteId, deviceId, service, {
    ...serviceData,
    active,
  });
  yield responseValidateStatusOrThrow(response);
}

export default function* basicDetailSagas(values) {
  yield editDeviceBase(values);
  yield editLocalCallsIdentifier(values);
  yield answeringUnitsChange(values);
  yield acsSwitch(values);
}
