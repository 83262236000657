import Avatar from '@mui/material/Avatar';
import React from 'react';
import { UserIcon } from '..';
import { ApartmentUserRole } from '../../modules/apartmentAdmin/data';
import { IUser } from '../../modules/user/store';
import { ApartmentAdminAvatarIcon } from '../ApartmentAdminIcons/ApartmentAdminAvatarIcon';

function UserAvatarIcon({ userRole }: { userRole: IUser['role'] | ApartmentUserRole }) {
  if (userRole === 'APARTMENT_ADMIN') {
    return <ApartmentAdminAvatarIcon />;
  }
  return (
    <Avatar
      sx={{
        bgcolor: 'button.info.background',
        color: 'secondary.main',
        height: 48,
        width: 48,
      }}
    >
      {userRole && <UserIcon userRole={userRole} />}
    </Avatar>
  );
}

export default UserAvatarIcon;
