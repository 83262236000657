import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { WarningIconMessage } from '../../../components';
import { PATHS } from '../../../config/consts';
import { DEVICE_TYPE } from '../../../config/devices';
import { selectors as companySelectors } from '../../../modules/company';
import { selectors as siteSelectors } from '../../../modules/sites';
import messages from './messages';

const styles = {
  description: {
    borderTop: '1px solid',
    borderTopColor: 'text.secondary',
    marginTop: 3,
    paddingTop: 2,
    textAlign: 'left',
  },
  skipped: {
    marginTop: 2,
    textAlign: 'left',
  },
};

const WaitingMessage = ({ callSettingsSkipped, companyId, deviceModalType, siteId }) => (
  <>
    <WarningIconMessage>
      <FormattedMessage {...messages.waitingMessageMessage} />
    </WarningIconMessage>
    <Box sx={styles.description}>
      <Typography variant="body2">
        <FormattedMessage {...messages.waitingMessageDescription} />
      </Typography>
    </Box>
    {callSettingsSkipped && (
      <Box sx={styles.skipped}>
        <Typography variant="body2">
          <FormattedMessage
            {...deviceTypeSkippedMap[deviceModalType || 'X2N']}
            values={{
              link: (
                <Link
                  href={PATHS.CALL_SETTINGS_INTERCOMS.replace(':companyId', companyId).replace(':siteId', siteId)}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <FormattedMessage {...messages.successMessageCallSettings} />
                </Link>
              ),
            }}
          />
        </Typography>
      </Box>
    )}
  </>
);

WaitingMessage.propTypes = {
  callSettingsSkipped: PropTypes.bool,
  companyId: PropTypes.number.isRequired,
  deviceModalType: PropTypes.oneOf(['X2N', DEVICE_TYPE.HIPMO, DEVICE_TYPE.DOOR_STATION, DEVICE_TYPE.THIRD_PARTY]),
  siteId: PropTypes.number.isRequired,
};

WaitingMessage.defaultProps = {
  callSettingsSkipped: false,
  deviceModalType: null,
};

const mapStateToProps = (state) => ({
  companyId: companySelectors.getCompanyId(state),
  siteId: siteSelectors.getSiteId(state),
});

export default connect(mapStateToProps)(WaitingMessage);

const deviceTypeSkippedMap = {
  [DEVICE_TYPE.HIPMO]: messages.successMessageCallSettingsSkippedHipmo,
  [DEVICE_TYPE.DOOR_STATION]: messages.successMessageCallSettingsSkippedDoorStation,
  [DEVICE_TYPE.THIRD_PARTY]: messages.successMessageCallSettingsSkippedThirdParty,
  X2N: messages.successMessageCallSettingsSkippedX2N,
};
