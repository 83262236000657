import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const TwitterWidget = ({ height, primaryColor, profileName }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'twitter';
    script.src = '//platform.twitter.com/widgets.js';
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.getElementById('twitter').remove();
    };
  }, []);

  return (
    <a
      className="twitter-timeline"
      data-height={height}
      data-link-color={primaryColor}
      href={`https://twitter.com/${profileName}?ref_src=twsrc%5Etfw`}
    >
      {`Tweets by ${profileName}`}
    </a>
  );
};

TwitterWidget.propTypes = {
  height: PropTypes.number,
  primaryColor: PropTypes.string,
  profileName: PropTypes.string,
};

TwitterWidget.defaultProps = {
  height: 400,
  primaryColor: '#0091ea',
  profileName: '2N_My2N',
};

export default TwitterWidget;
