import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { useHasFeature } from '../components/Feature/Feature';
import { PATHS } from '../config/consts';
import featureFlags from '../config/featureFlags';
import AboutPage from '../containers/AboutPage';
import AccessLog from '../containers/AccessLogPage';
import AccessSettingsOverview from '../containers/AccessSettingsOverview';
import ApartmentsOverview from '../containers/ApartmentsOverview';
import DevicesOverview from '../containers/DevicesOverview';
import NotVerifiedDevices from '../containers/NotVerifiedDevices';
import PhonebookDetail from '../containers/PhonebookDetail';
import SitePayments from '../containers/SitePayments';
import SiteUsers from '../containers/SiteUsers';
import SiteUsersImport from '../containers/SiteUsersImport';
import SupportPage from '../containers/SupportPage';
import { useUrlParams } from '../helpers/urlParams';
import { setInvalidateSiteSwitcher } from '../modules/common/actionsTS';
import { MobileVideoType, Roles } from '../modules/commonTypes';
import { isCompanyFetching } from '../modules/company/selectors';
import { getSiteElevatorIntegration } from '../modules/elevatorIntegration/actions';
import { clearSiteData, loadSite } from '../modules/sites/actions';
import { getDashboardMvServiceLicenceModel, getSiteData, isSiteFetching } from '../modules/sites/selectors';
import { didInvalidCurrentSite } from '../modules/sites/selectorsTS';
import SiteDashboardPage from '../pages/SiteDashboardPage';
import { AccessGroupDetailRoutes } from './AccessGroupDetailRoutes';
import { ApartmentDetailRoutes } from './ApartmentDetailRoutes';
import { CallSettingsRoutes } from './CallSettingsRoutes';
import { PrivateRoute } from './components/PrivateRoute';
import { DeviceDetailRoutes } from './DeviceDetailRoutes';
import { replaceURLParams } from './helpers/pathParamsReplacer';
import { SiteSettingsRoutes } from './SiteSettingsRoutes';
import { SiteUsersDetailRoutes } from './SiteUsersDetailRoutes';
import { SubscriptionRoutes } from './SubscriptionRoutes';

export function SiteRoutes(): React.ReactElement {
  const dispatch = useDispatch();
  const { siteId } = useUrlParams();

  const [ stateCompanyId, setStateCompanyId] = useState<number | undefined>(undefined);
  const { companyId } = useUrlParams();

  const isSiteLoading = useSelector(isSiteFetching);
  const didInvalid = useSelector(didInvalidCurrentSite);
  const loadedSite = useSelector(getSiteData);
  const hasElevatorIntegrationFF = useHasFeature(featureFlags.ELEVATOR_INTEGRATION);
  const isCompanyLoading = useSelector(isCompanyFetching);

  const licenceModel = useSelector(getDashboardMvServiceLicenceModel);
  let subscriptionRequiredRoles = [Roles.MOBILE_VIDEO_BUYER, Roles.ADMIN, Roles.COMPANY_ADMIN];
  if (licenceModel?.type !== MobileVideoType.STANDARD) {
    subscriptionRequiredRoles = [Roles.IP_INTERCOM_BUYER];
  }

  const companyChanged = companyId !== stateCompanyId;
  const siteNotLoaded = !companyChanged || (loadedSite?.id === undefined);

  useEffect(() => {
    if (companyChanged) {
      setStateCompanyId(companyId);
    }
  }, [companyId]);

  useEffect(() => {
    if (siteNotLoaded && !isSiteLoading && companyId) {
      dispatch(loadSite(companyId, siteId));
    }
    if (siteNotLoaded && hasElevatorIntegrationFF && companyId && siteId) {
      dispatch(getSiteElevatorIntegration(companyId, siteId));
    }

    if (siteNotLoaded) {
      return () => {
        dispatch(clearSiteData());
        dispatch(setInvalidateSiteSwitcher());
      };
    }
    return () => {};
  }, [companyId, siteId, hasElevatorIntegrationFF, didInvalid]);

  useEffect(() => {
    if (!isCompanyLoading) {
      dispatch(setInvalidateSiteSwitcher());
    }
  }, [siteId, isCompanyLoading]);

  if (isSiteLoading || loadedSite?.id === undefined) {
    return <>{false}</>;
  }

  return (
    <Routes>
      <Route
        element={
          <PrivateRoute allowedRoles={{ contextRoles: [Roles.ADMIN, Roles.STANDARD] }}>
            <SiteDashboardPage />
          </PrivateRoute>
        }
        path={'/'}
      />
      <Route
        element={
          <PrivateRoute allowedRoles={{ contextRoles: [Roles.ADMIN, Roles.STANDARD] }}>
            <DevicesOverview />
          </PrivateRoute>
        }
        path={'/devices'}
      />
      <Route
        element={
          <PrivateRoute allowedRoles={{ contextRoles: [Roles.ADMIN, Roles.STANDARD] }}>
            <SiteUsers />
          </PrivateRoute>
        }
        path={'/users'}
      />
      <Route
        element={
          <PrivateRoute allowedRoles={{ contextRoles: [Roles.ADMIN, Roles.STANDARD] }}>
            <ApartmentsOverview />
          </PrivateRoute>
        }
        path={'/apartments'}
      />
      <Route
        element={
          <PrivateRoute allowedRoles={{ contextRoles: [Roles.ADMIN, Roles.STANDARD] }}>
            <AccessSettingsOverview />
          </PrivateRoute>
        }
        path={'/access-settings'}
      />
      <Route
        element={
          <PrivateRoute allowedRoles={{ contextRoles: [Roles.ADMIN, Roles.STANDARD] }}>
            <AccessLog />
          </PrivateRoute>
        }
        path={'/access-log'}
      />
      <Route
        element={
          <PrivateRoute allowedRoles={{ contextRoles: [Roles.ADMIN, Roles.STANDARD] }}>
            <SitePayments />
          </PrivateRoute>
        }
        path={'/payments'}
      />
      <Route
        element={
          <PrivateRoute allowedRoles={{ contextRoles: [Roles.ADMIN, Roles.STANDARD] }}>
            <AboutPage />
          </PrivateRoute>
        }
        path={'/about'}
      />
      <Route
        element={
          <PrivateRoute allowedRoles={{ contextRoles: [Roles.ADMIN, Roles.STANDARD] }}>
            <SupportPage />
          </PrivateRoute>
        }
        path={'/help'}
      />
      <Route
        element={
          <PrivateRoute allowedRoles={{ contextRoles: [Roles.ADMIN, Roles.STANDARD] }}>
            <SiteUsersImport />
          </PrivateRoute>
        }
        path={'/users/import'}
      />
      <Route
        element={
          <PrivateRoute allowedRoles={{ contextRoles: [Roles.ADMIN, Roles.STANDARD] }}>
            <PhonebookDetail />
          </PrivateRoute>
        }
        path={'/phonebook/:phonebookId'}
      />
      <Route
        element={
          <PrivateRoute allowedRoles={{ contextRoles: [Roles.ADMIN, Roles.STANDARD] }}>
            <NotVerifiedDevices />
          </PrivateRoute>
        }
        path={'/not-verified-devices'}
      />

      <Route path="/devices/:deviceId/*" element={<DeviceDetailRoutes />} />
      <Route path="/users/:userId/*" element={<SiteUsersDetailRoutes />} />
      <Route path="/apartments/:apartmentId/*" element={<ApartmentDetailRoutes />} />
      <Route path="/call-settings/*" element={<CallSettingsRoutes />} />
      <Route path="/access-settings/:groupId/*" element={<AccessGroupDetailRoutes />} />
      <Route path="/site-settings/*" element={<SiteSettingsRoutes />} />
      <Route
        path="/subscription/*"
        element={
          <PrivateRoute
            allowedRoles={{ contextRoles: subscriptionRequiredRoles }}
            fallback={replaceURLParams({ params: { companyId, siteId }, url: PATHS.SITE_DASHBOARD })}
          >
            <SubscriptionRoutes />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
