export const SUBSCRIPTION_TYPE = {
  APARTMENT: 'APARTMENT',
  DEFAULT: '',
  DEVICE: 'DEVICE',
};

export const PROMO_TYPES = {
  MOBILE_VIDEO_POOL_CREDIT_YEAR_APARTMENT: 'MOBILE_VIDEO_POOL_CREDIT_YEAR_APARTMENT',
  MOBILE_VIDEO_POOL_CREDIT_YEAR_STANDARD: 'MOBILE_VIDEO_POOL_CREDIT_YEAR_STANDARD',
};
