import { useState } from 'react';
import { IDTMFSwitchFeature } from '../../../modules/devices/store/deviceStore';
import { fillInitStateItem, initialState, ISwitchItem } from './helpers';

export function useLoadSwitchList(
  feature: IDTMFSwitchFeature
): [ISwitchItem[], (index: number, name: string, value: string | boolean, codeWarn?: boolean) => void] {
  const values = feature ?? {};
  let initState = initialState();
  if (values && values.id && values.switches && values.switches.length > 0) {
    initState = initialState().map((item) => fillInitStateItem(values, item));
  }

  const [items, setItems] = useState<ISwitchItem[]>(initState);
  const handleChange = (index: number, name: string, value: string | boolean, codeWarn: boolean = false) => {
    const itemsCopy = [...items];
    const i = itemsCopy.findIndex((item) => item.index === index);
    itemsCopy[i] = { ...itemsCopy[i], codeWarn, [name]: value };
    setItems(itemsCopy);
  };

  return [items, handleChange];
}
