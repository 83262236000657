import { Tooltip } from '@mui/material';
import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { isArrayEmpty } from '../../helpers/array';
import { TooltipForEllipsisElement } from '../TooltipForEllipsisElement/TooltipForEllipsisElement';
import { isEllipsisActive } from '../TooltipForEllipsisElement/utils';

const styles = {
  disabled: {
    borderColor: 'action.disabled',
    color: 'action.disabled',
  },
  errorDefault: {
    backgroundColor: 'error.main',
    color: 'common.white',
  },
  errorOutlined: {
    borderColor: 'error.main',
    color: 'error.main',
  },
  root: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  successDefault: {
    backgroundColor: 'success.main',
    color: 'common.white',
  },
  successOutlined: {
    borderColor: 'success.main',
    color: 'success.main',
  },
  warningDefault: {
    backgroundColor: 'warning.main',
    color: 'common.white',
  },
  warningOutlined: {
    borderColor: 'warning.main',
    color: 'warning.main',
  },
};

// eslint-disable-next-line complexity
function DevicesInfoChip({ disabled, error, label, size, success, variant, warning }) {
  const childrenRef = useRef(undefined);
  const [hasActiveEllipsis, setHasActiveEllipsis] = useState(false);

  return (
    <TooltipForEllipsisElement
      title={label}
      onGetTextElementWithEllipsis={(child) => child && !isArrayEmpty(child.children) && child.children[0]}
    >
      <Chip
        label={label}
        size={size}
        sx={{
          ...styles.root,
          ...(success && variant === 'default' && styles.successDefault),
          ...(success && variant === 'outlined' && styles.successOutlined),
          ...(warning && variant === 'default' && styles.warningDefault),
          ...(warning && variant === 'outlined' && styles.warningOutlined),
          ...(error && variant === 'default' && styles.errorDefault),
          ...(error && variant === 'outlined' && styles.errorOutlined),
          ...(disabled && styles.disabled),
        }}
        variant={variant}
      />
    </TooltipForEllipsisElement>
  );
}

const colorMap = {};

DevicesInfoChip.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  size: PropTypes.oneOf(['medium', 'small']),
  success: PropTypes.bool,
  variant: PropTypes.oneOf(['default', 'outlined']),
  warning: PropTypes.bool,
};

DevicesInfoChip.defaultProps = {
  disabled: false,
  error: false,
  size: 'medium',
  success: false,
  variant: 'default',
  warning: false,
};

export default DevicesInfoChip;
