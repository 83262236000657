import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';

const styles = {
  voucherValueTitle: {
    pr: {
      sm: 2,
      xs: 0,
    },
    textAlign: {
      sm: 'end',
      xs: 'left',
    },
  },
};

const ItemValueTitle = () => (
  <Grid item lg="auto" sm={6} xl={4} xs={12}>
    <Typography color="text.secondary" sx={styles.voucherValueTitle}>
      <FormattedMessage {...messages.orderListValue} />
    </Typography>
  </Grid>
);

export default ItemValueTitle;
