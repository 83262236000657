import { UseFormReturn } from 'react-hook-form';
import { IntlFormatters } from 'react-intl';
import { Dispatch } from 'redux';
import { isArrayEmpty } from '../../../../helpers/array';
import { addSnackbarError } from '../../../../modules/notifications/actions';
import { IError } from '../../../../modules/reduxTypes';
import { getOryErrorFromOryErrorMessageId, getOryGeneralErrorMessage } from '../../../../ory/errors/oryErrorMessages';
import { IUseOryResponseParser } from '../../../../ory/hooks/oryResponseParser';
import { OryError, OryErrorMessage, OryErrorMessageId } from '../../../../ory/types';
import messages from './messages';
import { IActivateAccountFormData } from './validationScheme';

export type RegistrationError = IError | IFinishRegistrationOryError | undefined;
interface IFinishRegistrationOryError {
  data?: {
    detail?: {
      messages?: {
        id: OryErrorMessageId;
      }[];
    };
  };
}

export function handleFinishRegistrationErrors({
  dispatch,
  error,
  formatMessage,
  formData,
  parseOryResponse,
}: {
  error: RegistrationError;
  dispatch: Dispatch;
  formatMessage: IntlFormatters['formatMessage'];
  formData: UseFormReturn<IActivateAccountFormData>;
  parseOryResponse: IUseOryResponseParser['parseOryResponse'];
}): void {
  const oryError = tryToGetOryError(error, formatMessage);
  if (oryError) {
    parseOryResponse({ response: oryError, validationField: { fieldName: 'password', formData } });
  } else if (error && !isIFinishRegistrationOryError(error) && error.status === 409) {
    dispatch(addSnackbarError(formatMessage(messages.ImsNewPasswordErrorAccountActivated)));
  } else {
    dispatch(addSnackbarError(getOryGeneralErrorMessage(formatMessage)));
  }
}

export function tryToGetOryError(
  error: RegistrationError | undefined,
  formatMessage: IntlFormatters['formatMessage']
): OryErrorMessage | OryError | undefined {
  if (error && isIFinishRegistrationOryError(error) && error.data) {
    if (error.data.detail && error.data.detail.messages && !isArrayEmpty(error.data.detail.messages)) {
      const oryError = getOryErrorFromOryErrorMessageId(error.data.detail.messages[0].id, formatMessage);
      if (oryError !== undefined) {
        return oryError;
      }
    }
    return undefined;
  }
}

function isIFinishRegistrationOryError(error: RegistrationError): error is IFinishRegistrationOryError {
  return !!(
    error &&
    (error as IFinishRegistrationOryError).data &&
    (error as IFinishRegistrationOryError).data?.detail
  );
}
