import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { CALL_SETTINGS } from '../../../../config/consts';
import { DEVICE_TYPE } from '../../../../config/devices';
import devicesActions, { selectors as devicesSelectors } from '../../../../modules/devices';
import { CallSettingsHeader } from '../CallSettingsHeader';
import CallSettingsListActions from '../CallSettingsListActions';
import ConnectionWarningAlert from '../ConnectionWarningAlert';
import {
  handleAddListItem,
  handleChangeListItem,
  handleSaveListItem,
  isConnectionWarningHandler,
  isUnlicensedDevicesWarning,
} from '../helpers';
import SettingsWarning from '../SettingsWarning';
import UnlicensedDevicesWarningAlert from '../UnlicensedDevicesWarningAlert';
import ContactList from './ContactList';
import messages from './messages';

const Compact = ({
  compactContactList,
  device,
  deviceChipOptions,
  isDeviceDetailEditing,
  onSaveContactList,
  setDeviceEditing,
}) => {
  const [contactList, setContactList] = useState(compactContactList);

  useEffect(() => {
    setContactList(compactContactList);
  }, [compactContactList]);

  const isConnectionWarning = useMemo(() => isConnectionWarningHandler(contactList), [contactList]);

  const isUnlicensedWarning = useMemo(() => isUnlicensedDevicesWarning(contactList), [contactList]);

  return (
    <>
      <CallSettingsHeader deviceType={DEVICE_TYPE.ID_COMPACT} services={device.services} />
      <Box pb={2}>
        <SettingsWarning deviceType={DEVICE_TYPE.ID_COMPACT} services={device.services} />
      </Box>
      <Box pb={2}>
        {isConnectionWarning && <ConnectionWarningAlert deviceName={device.name} />}
        {!isConnectionWarning && isUnlicensedWarning && <UnlicensedDevicesWarningAlert />}
      </Box>
      <Box pb={2} pt={1} px={2}>
        <ContactList
          deviceChipOptions={deviceChipOptions}
          isDeviceDetailEditing={isDeviceDetailEditing}
          list={contactList}
          onChangeItem={handleChangeListItem(CALL_SETTINGS.COMPACT.TYPE, setContactList)}
          setDeviceEditing={setDeviceEditing}
        />
      </Box>
      <CallSettingsListActions
        addButtonLabel={<FormattedMessage {...messages.AddButton} />}
        onAdd={handleAddListItem(CALL_SETTINGS.COMPACT.TYPE, setContactList)}
        onSave={() => handleSaveListItem(compactContactList, contactList, onSaveContactList)}
      />
    </>
  );
};

Compact.propTypes = {
  compactContactList: PropTypes.arrayOf(PropTypes.object).isRequired,
  device: PropTypes.shape({
    name: PropTypes.string,
    services: PropTypes.shape({}),
  }).isRequired,
  deviceChipOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  isDeviceDetailEditing: PropTypes.bool.isRequired,
  onSaveContactList: PropTypes.func.isRequired,
  setDeviceEditing: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  compactContactList: devicesSelectors.getCompactContactList(state),
  device: devicesSelectors.getCurrentDeviceDataSelector(state),
  deviceChipOptions: devicesSelectors.getCallSettingsDevicesOptions(state),
  isDeviceDetailEditing: devicesSelectors.isDeviceEditing(state),
});

const mapDispatchToProps = {
  onSaveContactList: devicesActions.saveDeviceContactListRequest,
  setDeviceEditing: devicesActions.setDeviceEditing,
};

export default connect(mapStateToProps, mapDispatchToProps)(Compact);
