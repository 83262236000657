import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import CustomDate from '../CustomDate';
import messages from './messages';
import { styles } from './styles';

const ExpirationDate = ({ expirationDate, isRecurringPayment, licencesPool, multiplier, requirePaymentCount }) =>
  !isRecurringPayment && licencesPool < requirePaymentCount * multiplier ? (
    <Typography sx={styles.text} variant="body2">
      <FormattedMessage
        values={{
          date: <CustomDate dateValue={expirationDate} />,
          text: (chunks) => (
            <Box component="span" sx={styles.textColor}>
              {chunks}
            </Box>
          ),
        }}
        {...messages.creditPoolCardExpiration}
      />
    </Typography>
  ) : null;

ExpirationDate.propTypes = {
  expirationDate: PropTypes.string,
  isRecurringPayment: PropTypes.bool,
  licencesPool: PropTypes.number,
  multiplier: PropTypes.number,
  requirePaymentCount: PropTypes.number,
};

ExpirationDate.defaultProps = {
  expirationDate: null,
  isRecurringPayment: false,
  licencesPool: null,
  multiplier: 1,
  requirePaymentCount: null,
};

export default ExpirationDate;
