import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { QRCode } from '../../../../../components';
import messages from '../messages';

interface IMVAppLoginInfo {
  onOpenInfoModal: () => void;
  qrCodeUrl: string | undefined;
}

function MVAppLoginInfo({ onOpenInfoModal, qrCodeUrl }: IMVAppLoginInfo) {
  return (
    <Stack direction="row" alignItems="flex-start" spacing={2}>
      {qrCodeUrl && <QRCode alt="App login QR code" isInstallApp={false} src={qrCodeUrl} />}
      <Stack spacing={2}>
        <Typography color="secondary.main" fontWeight="bold" variant="caption">
          <FormattedMessage {...messages.AAUserDetailMobileVideoAppTitle} />
        </Typography>
        <Typography variant="caption">
          <FormattedMessage
            values={{
              link: (
                <Link href="#" onClick={onOpenInfoModal}>
                  <FormattedMessage {...messages.AAUserDetailMobileVideoAppLink} />
                </Link>
              ),
            }}
            {...messages.AAUserDetailMobileVideoAppText}
          />
        </Typography>
      </Stack>
    </Stack>
  );
}

export default MVAppLoginInfo;
