import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Outlet } from 'react-router-dom';
import AppVersionWarning from '../../../components/AppVersionWarning';
import { useRedirectOnChange } from '../../../hooks/redirectOnChange';
import { selectors as commonSelectors } from '../../../modules/common';
import StandardContent from './StandardContent';

const styles = {
  layout: (serviceAlertHeight) => ({
    '& .MuiAppBar-positionFixed': {
      top: `${serviceAlertHeight}px`,
    },
  }),
  root: (serviceAlertHeight, isServiceAlertOpen) => ({
    ml: { md: '255px' },
    mt: serviceAlertHeight !== null && isServiceAlertOpen ? `${serviceAlertHeight + 88}px` : 11,
    width: 'auto',
  }),
  submenu: {
    mb: 5,
    mt: { sm: -7.5, xs: 9 },
    width: 'auto',
  },
  submenuWrapper: {
    boxShadow: 'none',
    height: '62px',
    mt: 8,
  },
  superadmin: {
    mx: 'auto',
    px: { lg: 0, xs: 2 },
    py: 0,
  },
};

function Layout({
  collapse,
  company,
  isCompanyAdmin,
  isServiceAlertOpen,
  isSiteAdmin,
  isVersionWarningDisplayed,
  serviceAlertHeight,
}) {
  useRedirectOnChange();

  return (
    <Box
      sx={{
        ...(isServiceAlertOpen && serviceAlertHeight !== null && styles.layout(serviceAlertHeight)),
      }}
    >
      <AppVersionWarning />
      <StandardContent
        collapse={collapse}
        company={company}
        isCompanyAdmin={isCompanyAdmin}
        isServiceAlertOpen={isServiceAlertOpen}
        isSiteAdmin={isSiteAdmin}
        isVersionWarningDisplayed={isVersionWarningDisplayed}
        serviceAlertHeight={serviceAlertHeight}
      >
        {<Outlet />}
      </StandardContent>
    </Box>
  );
}

Layout.propTypes = {
  collapse: PropTypes.bool,
  company: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  isCompanyAdmin: PropTypes.bool,
  isServiceAlertOpen: PropTypes.bool.isRequired,
  isSiteAdmin: PropTypes.bool,
  isVersionWarningDisplayed: PropTypes.bool,
  serviceAlertHeight: PropTypes.number,
};

Layout.defaultProps = {
  collapse: false,
  company: {},
  isCompanyAdmin: false,
  isSiteAdmin: false,
  isVersionWarningDisplayed: false,
  serviceAlertHeight: null,
};

const mapStateToProps = (state) => ({
  collapse: commonSelectors.getIsSidebarCollapsed(state),
  company: state.company.data,
  isCompanyAdmin: commonSelectors.isCompanyAdmin(state),
  isServiceAlertOpen: commonSelectors.isServiceAlertOpen(state),
  isSiteAdmin: commonSelectors.isSiteAdmin(state),
  isVersionWarningDisplayed: commonSelectors.isSiteVersionWarningDisplayed(state),
  serviceAlertHeight: commonSelectors.getServiceAlertHeight(state),
});

export default connect(mapStateToProps)(Layout);
