import { Dialog } from '@2n/design-system';
import { Box } from '@mui/material';
import React from 'react';
import { IDSModalBase } from '../designSystemUnexported';

export interface INonClosingModalData extends IDSModalBase {
  closeOnlyByButton: boolean;
  hideCloseButton: boolean;
}

export function NonClosingModal(data: INonClosingModalData) {
  return (
    <Dialog
      closeOnlyByButton={data.closeOnlyByButton}
      footerOptions={{
        primaryButton:
          data.primaryButtonText !== undefined
            ? {
                action: () => data.onClose(false),
                color: data.secondaryButtonText ? 'primary' : 'secondary',
                text: data.primaryButtonText,
                variant: 'text',
              }
            : undefined,
        secondaryButton: data.secondaryButtonText
          ? {
              action: () => data.onClose(false),
              color: 'secondary',
              text: data.secondaryButtonText,
              variant: 'text',
            }
          : undefined,
      }}
      hasBodyContentPadding
      hideCloseButton={data.hideCloseButton}
      onClose={data.onClose}
      open
      title={data.title}
    >
      <Box sx={{ paddingTop: 2 }} data-testid={data.testId}>
        {data.children}
      </Box>
    </Dialog>
  );
}

export default NonClosingModal;
