export const moduleName = 'subscription';

export default {
  ACTIVATE_ACTIVATION_CODE_ERROR: `${moduleName}/ACTIVATE_ACTIVATION_CODE_ERROR`,
  ACTIVATE_ACTIVATION_CODE_REQUEST: `${moduleName}/ACTIVATE_ACTIVATION_CODE_REQUEST`,
  ACTIVATE_ACTIVATION_CODE_SUCCESS: `${moduleName}/ACTIVATE_ACTIVATION_CODE_SUCCESS`,
  ACTIVATE_MOBILE_VIDEO_LICENSE_FAILURE: `${moduleName}/ACTIVATE_MOBILE_VIDEO_LICENSE_FAILURE`,
  ACTIVATE_MOBILE_VIDEO_LICENSE_REQUEST: `${moduleName}/ACTIVATE_MOBILE_VIDEO_LICENSE_REQUEST`,
  ACTIVATE_MOBILE_VIDEO_LICENSE_SUCCESS: `${moduleName}/ACTIVATE_MOBILE_VIDEO_LICENSE_SUCCESS`,
  CLEAR_SUBSCRIPTION: `${moduleName}/CLEAR_SUBSCRIPTION`,
  CREATE_LICENSE_INVOICE_PAYMENT_FAILURE: `${moduleName}/CREATE_LICENSE_INVOICE_PAYMENT_FAILURE`,
  CREATE_LICENSE_INVOICE_PAYMENT_REQUEST: `${moduleName}/CREATE_LICENSE_INVOICE_PAYMENT_REQUEST`,
  CREATE_LICENSE_INVOICE_PAYMENT_SUCCESS: `${moduleName}/CREATE_LICENSE_INVOICE_PAYMENT_SUCCESS`,
  CREATE_PROMO_INVOICE_PAYMENT_FAILURE: `${moduleName}/CREATE_PROMO_INVOICE_PAYMENT_FAILURE`,
  CREATE_PROMO_INVOICE_PAYMENT_REQUEST: `${moduleName}/CREATE_PROMO_INVOICE_PAYMENT_REQUEST`,
  CREATE_PROMO_INVOICE_PAYMENT_SUCCESS: `${moduleName}/CREATE_PROMO_INVOICE_PAYMENT_SUCCESS`,
  DEACTIVATE_RECURRING_PAYMENT_FAILURE: `${moduleName}/DEACTIVATE_RECURRING_PAYMENT_FAILURE`,
  DEACTIVATE_RECURRING_PAYMENT_REQUEST: `${moduleName}/DEACTIVATE_RECURRING_PAYMENT_REQUEST`,
  DEACTIVATE_RECURRING_PAYMENT_SUCCESS: `${moduleName}/DEACTIVATE_RECURRING_PAYMENT_SUCCESS`,
  DELETE_ORDER_LICENSE_FAILURE: `${moduleName}/DELETE_ORDER_LICENSE_FAILURE`,
  DELETE_ORDER_LICENSE_REQUEST: `${moduleName}/DELETE_ORDER_LICENSE_REQUEST`,
  DELETE_ORDER_LICENSE_SUCCESS: `${moduleName}/DELETE_ORDER_LICENSE_SUCCESS`,
  DELETE_PROMO_CODES_ORDER_CONTENT_FAILURE: `${moduleName}/DELETE_PROMO_CODES_ORDER_CONTENT_FAILURE`,
  DELETE_PROMO_CODES_ORDER_CONTENT_REQUEST: `${moduleName}/DELETE_PROMO_CODES_ORDER_CONTENT_REQUEST`,
  DELETE_PROMO_CODES_ORDER_CONTENT_SUCCESS: `${moduleName}/DELETE_PROMO_CODES_ORDER_CONTENT_SUCCESS`,
  GET_MOBILE_VIDEO_LICENSE_FAILURE: `${moduleName}/GET_MOBILE_VIDEO_LICENSE_FAILURE`,
  GET_MOBILE_VIDEO_LICENSE_PRICES_FAILURE: `${moduleName}/GET_MOBILE_VIDEO_LICENSE_PRICES_FAILURE`,
  GET_MOBILE_VIDEO_LICENSE_PRICES_REQUEST: `${moduleName}/GET_MOBILE_VIDEO_LICENSE_PRICES_REQUEST`,
  GET_MOBILE_VIDEO_LICENSE_PRICES_SUCCESS: `${moduleName}/GET_MOBILE_VIDEO_LICENSE_PRICES_SUCCESS`,
  GET_MOBILE_VIDEO_LICENSE_REQUEST: `${moduleName}/GET_MOBILE_VIDEO_LICENSE_REQUEST`,
  GET_MOBILE_VIDEO_LICENSE_SUCCESS: `${moduleName}/GET_MOBILE_VIDEO_LICENSE_SUCCESS`,
  GET_PAYMENTS_FAILURE: `${moduleName}/GET_PAYMENTS_FAILURE`,
  GET_PAYMENTS_REQUEST: `${moduleName}/GET_PAYMENTS_REQUEST`,
  GET_PAYMENTS_SUCCESS: `${moduleName}/GET_PAYMENTS_SUCCESS`,
  GET_PROMO_CODES_FAILURE: `${moduleName}/GET_PROMO_CODES_FAILURE`,
  GET_PROMO_CODES_ORDER_FAILURE: `${moduleName}/GET_PROMO_CODES_ORDER_FAILURE`,
  GET_PROMO_CODES_ORDER_REQUEST: `${moduleName}/GET_PROMO_CODES_ORDER_REQUEST`,
  GET_PROMO_CODES_ORDER_SUCCESS: `${moduleName}/GET_PROMO_CODES_ORDER_SUCCESS`,
  GET_PROMO_CODES_REQUEST: `${moduleName}/GET_PROMO_CODES_REQUEST`,
  GET_PROMO_CODES_SUCCESS: `${moduleName}/GET_PROMO_CODES_SUCCESS`,
  GET_SERIAL_NUMBER_LICENSES_FAILURE: `${moduleName}/GET_SERIAL_NUMBER_LICENSES_FAILURE`,
  GET_SERIAL_NUMBER_LICENSES_REQUEST: `${moduleName}/GET_SERIAL_NUMBER_LICENSES_REQUEST`,
  GET_SERIAL_NUMBER_LICENSES_SUCCES: `${moduleName}/GET_SERIAL_NUMBER_LICENSES_SUCCES`,
  GET_VAT_VERIFICATION_ERROR: `${moduleName}/GET_VAT_VERIFICATION_ERROR`,
  GET_VAT_VERIFICATION_REQUEST: `${moduleName}/GET_VAT_VERIFICATION_REQUEST`,
  GET_VAT_VERIFICATION_SUCCES: `${moduleName}/GET_VAT_VERIFICATION_SUCCES`,
  HIDE_LICENSE_DETAIL_MODAL: `${moduleName}/HIDE_LICENSE_DETAIL_MODAL`,
  INVALIDATE_CODE_DATA: `${moduleName}/INVALIDATE_CODE_DATA`,
  INVALIDATE_PAYMENT_STATUS: `${moduleName}/INVALIDATE_PAYMENT_STATUS`,
  PAYMENT_RESULT_FROM_SOCKET: `${moduleName}/PAYMENT_RESULT_FROM_SOCKET`,
  SET_MOBILE_VIDEO_PRODUCT_FAILURE: `${moduleName}/SET_MOBILE_VIDEO_PRODUCT_FAILURE`,
  SET_MOBILE_VIDEO_PRODUCT_REQUEST: `${moduleName}/SET_MOBILE_VIDEO_PRODUCT_REQUEST`,
  SET_MOBILE_VIDEO_PRODUCT_SUCCESS: `${moduleName}/SET_MOBILE_VIDEO_PRODUCT_SUCCESS`,
  SET_PAYMENT_RESULT: `${moduleName}/SET_PAYMENT_RESULT`,
  SET_PAYMENTS_FAILURE: `${moduleName}/SET_PAYMENTS_FAILURE`,
  SET_PAYMENTS_REQUEST: `${moduleName}/SET_PAYMENTS_REQUEST`,
  SET_PAYMENTS_SUCCESS: `${moduleName}/SET_PAYMENTS_SUCCESS`,
  SET_PROMO_CODES_FAILURE: `${moduleName}/SET_PROMO_CODES_FAILURE`,
  SET_PROMO_CODES_ORDER_FAILURE: `${moduleName}/SET_PROMO_CODES_ORDER_FAILURE`,
  SET_PROMO_CODES_ORDER_REQUEST: `${moduleName}/SET_PROMO_CODES_ORDER_REQUEST`,
  SET_PROMO_CODES_ORDER_SUCCESS: `${moduleName}/SET_PROMO_CODES_ORDER_SUCCESS`,
  SET_PROMO_CODES_REQUEST: `${moduleName}/SET_PROMO_CODES_REQUEST`,
  SET_PROMO_CODES_SUCCESS: `${moduleName}/SET_PROMO_CODES_SUCCESS`,
  SET_SERIAL_NUMBER_LICENSES_FAILURE: `${moduleName}/SET_SERIAL_NUMBER_LICENSES_FAILURE`,
  SET_SERIAL_NUMBER_LICENSES_REQUEST: `${moduleName}/SET_SERIAL_NUMBER_LICENSES_REQUEST`,
  SET_SERIAL_NUMBER_LICENSES_SUCCESS: `${moduleName}/SET_SERIAL_NUMBER_LICENSES_SUCCESS`,
  SHOW_LICENSE_DETAIL_MODAL: `${moduleName}/SHOW_LICENSE_DETAIL_MODAL`,
  USE_AVAILABLE_CREDIT_REQUEST: `${moduleName}/USE_AVAILABLE_CREDIT_REQUEST`,
  VERIFY_ACTIVATION_CODE_ERROR: `${moduleName}/VERIFY_ACTIVATION_CODE_ERROR`,
  VERIFY_ACTIVATION_CODE_FAILURE: `${moduleName}/VERIFY_ACTIVATION_CODE_FAILURE`,
  VERIFY_ACTIVATION_CODE_REQUEST: `${moduleName}/VERIFY_ACTIVATION_CODE_REQUEST`,
  VERIFY_ACTIVATION_CODE_SUCCESS: `${moduleName}/VERIFY_ACTIVATION_CODE_SUCCESS`,
};
