import { defineMessages } from 'react-intl';

export default defineMessages({
  apartmentsOverviewCardActiveForFree: {
    defaultMessage: '<bold>{count}</bold> active for free',
    description: '',
    id: 'apartmentsOverviewCard.ACTIVE_FOR_FREE',
  },
  apartmentsOverviewCardActiveSubscriptions: {
    defaultMessage: '<bold>{count}</bold> with subscriptions',
    description: '',
    id: 'apartmentsOverviewCard.ACTIVE_SUBSCRIPTIONS',
  },
  apartmentsOverviewCardAddedApartments: {
    defaultMessage: '{count, plural, one {apartment} other {apartments}}',
    description: '',
    id: 'apartmentsOverviewCard.addedApartments',
  },
  ApartmentsOverviewCardFreeWithIntercom: {
    defaultMessage: '<bold>1</bold> free with intercom',
    id: 'apartmentsOverviewCard.FREE_WITH_INTERCOM',
  },
  apartmentsOverviewCardNoService: {
    defaultMessage: '<bold>{count}</bold> no service',
    description: '',
    id: 'apartmentsOverviewCard.NO_SERVICE',
  },
  apartmentsOverviewCardSeeAllApartments: {
    defaultMessage: 'See all apartments',
    description: '',
    id: 'apartmentsOverviewCard.seeAllApartments',
  },
  apartmentsOverviewCardTitle: {
    defaultMessage: 'Apartments',
    description: '',
    id: 'apartmentsOverviewCard.title',
  },
  apartmentsOverviewCardUnlicensed: {
    defaultMessage: '<bold>{count}</bold> unlicensed',
    description: '',
    id: 'apartmentsOverviewCard.UNLICENSED',
  },
});
