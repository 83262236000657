import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import {
  AccessGroupAddApartmentModal,
  AccessGroupApartmentRemoveModal,
  ApartmentAccessGroupsModal,
  Paper,
} from '../../../components';
import TableControlPanel from '../../../components/TableControlPanel';
import { useUrlParams } from '../../../helpers/urlParams';
import actions from '../../../modules/apartments';
import DataGrid from './DataGrid';
import messages from './messages';

function AccessGroupApartmentsDataGrid() {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { groupId } = useUrlParams();
  const [modalOpen, setModalOpen] = useState(null);
  const [modalApartment, setmodalApartment] = useState('');
  const modalType = {
    ADD: 'ADD',
    Add_GROUP: 'Add_GROUP',
    REMOVE: 'REMOVE',
  };

  const handleOpenModal = (type, apartment) => {
    setmodalApartment(apartment);
    setModalOpen(type);
  };

  const handleCloseModal = () => setModalOpen(null);

  return (
    <>
      <Paper tabContent tabTable>
        <TableControlPanel
          addButtonAction={() => setModalOpen(modalType.ADD)}
          addButtonLabel={formatMessage(messages.accessGroupApartmentsDataGridAddApartment)}
          tabToolbar
        />
        <DataGrid
          onRemove={(apartment) => handleOpenModal(modalType.REMOVE, apartment)}
          onSetAccessGroup={(apartment) => dispatch(actions.setApartmentAccessGroupShowModal(apartment.coreId))}
        />
      </Paper>
      <AccessGroupAddApartmentModal groupId={groupId} onClose={handleCloseModal} open={modalOpen === modalType.ADD} />
      <AccessGroupApartmentRemoveModal
        apartmentId={modalApartment?.coreId}
        apartmentName={
          modalApartment?.name ? `${modalApartment?.number} – ${modalApartment?.name}` : modalApartment?.number
        }
        onClose={handleCloseModal}
        open={modalOpen === modalType.REMOVE}
        users={modalApartment?.userCount}
      />
      <ApartmentAccessGroupsModal />
    </>
  );
}

export default AccessGroupApartmentsDataGrid;
