import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../../messages';

interface IFloorName {
  name: string;
}

export function FloorName({ name }: IFloorName) {
  return name.length < 4 ? (
    <>
      <Typography sx={{ mt: 1 }}>
        <FormattedMessage
          {...messages.siteSettingsFloorConfigurationFloor}
          values={{ name, strong: (chunks) => <strong>{chunks}</strong> }}
        />
      </Typography>
    </>
  ) : (
    <strong>{name}</strong>
  );
}
