import AlertIcon from '@mui/icons-material/NewReleases';
import Alert from '@mui/material/Alert';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

function InfoAlert() {
  return (
    <Alert icon={<AlertIcon />} severity="success">
      <FormattedMessage {...messages.siteUsersImportUploadStepInfoAlert} />
    </Alert>
  );
}

export default InfoAlert;
