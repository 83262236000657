/**
 * Recursively trims whitespace from string values in an object, excluding string properties specifically defined by their keys.
 * @template {undefined | null | string | any[] | { [key: string]: any }} T - The type of the input data.
 * @param {T} data - The object to trim.
 * @param {string[]} [keysToExclude=[]] - Optional parameter - the array of keys of string properties to exclude from trimming.
 * @returns {T} with trimmed string values.
 */
export const trimStrings = <T extends undefined | null | string | any[] | { [key: string]: any }>(data: T, keysToExclude: string[] = []): T => {
  if (data === undefined || data === null) {
    return data;
  }
  if (typeof data === 'string') {
    data = data.trim() as T;
    return data !== '' ? data : (null as T);
  }
  if (Array.isArray(data)) {
    return data.map(item => trimStrings(item, keysToExclude)) as T;
  }
  if (typeof data === 'object') {
    const dataCopy: { [key: string]: any } = {};
    for (const key in data) {
      if (!Object.prototype.hasOwnProperty.call(data, key)) {
        continue;
      }
      if (keysToExclude.includes(key) && typeof data[key] === 'string') {
        dataCopy[key] = data[key];
        continue;
      }
      dataCopy[key] = trimStrings(data[key], keysToExclude);
    }
    return dataCopy as T;
  }
  return data;
};
