import { Box } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { ICallSettingOption } from './hooks/useGetStateData';

interface IOptionLabelData {
  numberColor: string;
  option: ICallSettingOption;
  props?: React.HTMLAttributes<HTMLLIElement>;
}

export function CallSettingOptionLabel(data: IOptionLabelData): JSX.Element {
  return (
    <Stack
      component="li"
      direction="row"
      spacing={data.option.optionName && data.option.optionIcon.stringAsIcon ? 1 : 0}
      sx={{ alignItems: 'center', display: 'flex' }}
      {...data.props}
    >
      <Box color={data.numberColor} component="span" fontWeight="bold">
        {data.option.optionIcon?.stringAsIcon}
      </Box>
      <Typography component="span" variant="body2">
        {data.option.optionName}
      </Typography>
    </Stack>
  );
}
