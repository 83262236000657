import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import screenImg from '../../assets/landing_rc.png';
import messages from './messages';

const styles = {
  imageWrapper: {
    display: {
      md: 'block',
      xs: 'none',
    },
    pl: 6,
    width: '50%',
  },
  item: {
    display: 'flex',
    my: 8,
  },
  itemTitle: {
    fontSize: '26px',
    mb: 1,
  },
  list: {
    listStyle: 'none',
    px: 2,
  },
  marker: {
    backgroundColor: 'primary.main',
    borderRadius: '100%',
    color: 'common.white',
    flexShrink: 0,
    fontSize: '22px',
    fontStyle: 'normal',
    height: '34px',
    lineHeight: '34px',
    textAlign: 'center',
    width: '34px',
  },
  root: {
    backgroundColor: 'background.default',
    width: '100%',
  },
  title: {
    '& strong': {
      color: 'primary.main',
      fontWeight: 'regular',
    },
    color: 'common.black',
    fontSize: '40px',
    textAlign: 'center',
  },
  wrapper: {
    maxWidth: '1050px',
    mx: 'auto',
    px: 2,
    py: 4,
    width: '100%',
  },
};

const Steps = () => (
  <Box sx={styles.root}>
    <Box sx={styles.wrapper}>
      <Typography component="h2" sx={styles.title}>
        <FormattedMessage
          {...messages.rcLandingPageStepsTitle}
          values={{ strong: (chunks) => <strong>{chunks}</strong> }}
        />
      </Typography>
      <Box alignItems="center" display="flex">
        <Box component="ul" sx={styles.list}>
          <Box component="li" sx={styles.item}>
            <Box sx={styles.marker}>{'1'}</Box>
            <Box display="flex" flexDirection="column" sx={{ ml: 2 }}>
              <Typography component="h3" sx={styles.itemTitle}>
                <FormattedMessage {...messages.rcLandingPageStepsListCreateAccount} />
              </Typography>
            </Box>
          </Box>
          <Box component="li" sx={styles.item}>
            <Box sx={styles.marker}>{'2'}</Box>
            <Box display="flex" flexDirection="column" sx={{ ml: 2 }}>
              <Typography component="h3" sx={styles.itemTitle}>
                <FormattedMessage {...messages.rcLandingPageStepsListAddDevices} />
              </Typography>
            </Box>
          </Box>
          <Box component="li" sx={styles.item}>
            <Box sx={styles.marker}>{'3'}</Box>
            <Box display="flex" flexDirection="column" sx={{ ml: 2 }}>
              <Typography component="h3" sx={styles.itemTitle}>
                <FormattedMessage {...messages.rcLandingPageStepsListConfigureDevice} />
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={styles.imageWrapper}>
          <img alt="Remote configuration" src={screenImg} />
        </Box>
      </Box>
    </Box>
  </Box>
);

export default Steps;
