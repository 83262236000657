import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SelectField } from '../../../../components';
import messages from './messages';

const DeviceOwnerField = ({ disabled, onChange, users, value }) => (
  <Box mt={1} pb={1} maxWidth={'100%'}>
    <SelectField
      disabled={disabled}
      fullWidth
      label={<FormattedMessage {...messages.Label} />}
      labelKey="fullname"
      margin="none"
      name="userId"
      onChange={onChange}
      options={users}
      value={users.length > 0 ? value : ''}
      variant="outlined"
    />
  </Box>
);

DeviceOwnerField.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      fullname: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

DeviceOwnerField.defaultProps = {
  users: [],
};

export default DeviceOwnerField;
