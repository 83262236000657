export const styles = {
  buttons: {
    '& > button': {
      mx: 1,
      my: 0,
    },
    '& button': {
      mx: { sm: 0 },
      my: { sm: 0.5 },
    },
    display: 'flex',
    justifyContent: { sm: 'right', xs: 'center' },
    textAlign: { sm: 'left', xs: 'center' },
    width: { sm: '34%' },
  },
  errorMessage: {
    color: 'error.main',
    fontSize: '12px',
    padding: '2px 8px',
    textAlign: 'center',
  },
  missingPin: {
    color: 'action.disabled'
  },
  qr: {
    textAlign: 'center',
  },
  root: {
    border: 1,
    borderColor: '#A4B8C7',
    borderRadius: '4px',
    display: { sm: 'flex' },
    paddingBottom: '8px',
    position: 'relative',
    px: 2,
    py: 2,
  },
  rootError: {
    borderColor: 'error.main',
  },
};
