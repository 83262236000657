import { differenceBy, get, groupBy, orderBy } from 'lodash';
import { createSelector } from 'reselect';

const compareApartments = (userId, apartments, orderedApartments) =>
  differenceBy(
    orderedApartments,
    apartments.filter((apt) => ![].concat(...Object.values(apt.roles)).includes(userId)),
    'id'
  );

const orderApartments = (apartments) =>
  orderBy(
    apartments.map((item) => ({
      floor: item?.floor?.name || '',
      id: item.id,
      label: `${item.number} ${item.name}`,
      name: item.name,
      number: item.number,
    })),
    ['floor'],
    ['asc']
  );

export const getApartmentDetail = ({ apartments }) => apartments.detail;
export const getIsApartmentDetailFetching = ({ apartments }) => apartments.detail.isFetching;
export const getApartmentData = ({ apartments }) => apartments.detail.data;
export const getApartmentId = ({ apartments }) => apartments.detail.data.id;
export const getApartmentDevices = ({ apartments }) => apartments.devicesList || [];
export const getApartmentUsers = ({ apartments }) => apartments.apartmentUsers.list.data;

export const getApartmentsList = createSelector(
  ({ apartments }) => apartments.apartmentsList,
  (apartmentsList) => apartmentsList?.data || []
);

export const getApartmentsListOrdered = createSelector(getApartmentsList, orderApartments);

export const getInitialApartmentsList = createSelector(
  ({ user }) => user.userApartmentModal.user?.id,
  getApartmentsList,
  getApartmentsListOrdered,
  compareApartments
);

export const getUserInfoFormInitialApartments = createSelector(
  ({ user }) => user.userDetail.data?.id,
  getApartmentsList,
  getApartmentsListOrdered,
  compareApartments
);

export const getApartmentsListIsFetching = createSelector(
  ({ apartments }) => apartments.apartmentsList,
  (apartmentsList) => apartmentsList?.isFetching
);

export const getDidInvalidFloors = ({ apartments }) => apartments.floorsList.didInvalid;
export const getFloors = ({ apartments }) => apartments.floorsList.data;
export const getApartmentsListGrouped = (state) => {
  const items = groupBy(getApartmentsList(state), item => {
    return get(item, 'floor.name', '')
  });
  return Object.keys(items).map((floorName) => ({
    floorName,
    items: items[floorName].map((item) => ({
      id: item.id,
      name: item.name,
      number: item.number,
    })),
  }));
};

const apartmentsDeleteModal = (state) => state.apartments.setAccessGroupsModal;

export const getApartmentAccessGroupsModalShow = createSelector(
  apartmentsDeleteModal,
  (accessGroupsModal) => accessGroupsModal.show
);

export const getApartmentAccessGroupsModalApartmentId = createSelector(
  apartmentsDeleteModal,
  (accessGroupsModal) => accessGroupsModal.apartmentId || ''
);

export const isDeleteModalOpen = ({ apartments }) => apartments.deleteModal.show;
export const isDeleteApartmentFetching = ({ apartments }) => apartments.deleteModal?.isFetching || false;
export const getApartmentIdFromDeleteModal = ({ apartments }) => apartments.deleteModal.apartmentId;

export const isAddApartmentUsersModalFetching = ({ apartments }) =>
  apartments.apartmentUsers.addApartmentUsersModal.isFetching;

export const isApartmentListFetching = ({ apartments }) => apartments.apartmentsList?.isFetching;
