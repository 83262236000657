import ClearIcon from '@mui/icons-material/Clear';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import actions from '../../modules/devices';
import ButtonDanger from '../ButtonDanger';
import Modal from '../Modal';
import messages from './messages';

const DeviceRejectModal = ({ device, onClose, onReject, open }) => {
  const handleReject = () => {
    onReject(device.id);
    onClose();
  };

  return (
    <Modal
      actions={
        <>
          <Button onClick={onClose}>
            <FormattedMessage {...messages.deviceRejectModalCancel} />
          </Button>
          <ButtonDanger disabled={false} onClick={() => handleReject()} startIcon={<ClearIcon />} variant="outlined">
            <FormattedMessage {...messages.deviceRejectModalSubmit} />
          </ButtonDanger>
        </>
      }
      headerVariant="danger"
      maxWidth="xs"
      name="device-reject-modal"
      onClose={onClose}
      open={open}
      title={<FormattedMessage {...messages.deviceRejectModalTitle} />}
    >
      <Typography>
        <FormattedMessage {...messages.deviceRejectModalQuestion} />
      </Typography>
    </Modal>
  );
};

DeviceRejectModal.propTypes = {
  device: PropTypes.shape({
    firmware: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    modelName: PropTypes.string,
    name: PropTypes.string,
    serialNumber: PropTypes.string,
    type: PropTypes.string,
    variant: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default connect(null, {
  onReject: actions.rejectDeviceRequest,
})(DeviceRejectModal);
