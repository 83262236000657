import React from 'react';
import { FormattedMessage } from 'react-intl';
import Alert from '../Alert';
import messages from './messages';

interface ConfigurationManualAlertBoxProps {
  isConfigModeManual: boolean;
  values: Record<string, (chunks: React.ReactNode) => React.ReactNode>;
}

export const ConfigurationManualAlertBox = ({ isConfigModeManual, values }: ConfigurationManualAlertBoxProps) =>
  isConfigModeManual ? (
    <Alert severity="warning">
      <FormattedMessage {...messages.configurationModeTextManualAlert} />
    </Alert>
  ) : (
    <Alert severity="info">
      <FormattedMessage {...messages.configurationModeTextAutomaticAlert} values={values} />
    </Alert>
  );
