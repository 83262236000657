import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { AutocompleteChips } from '../../../../components';
import { getIsChipWarning } from '../helpers';
import { RingingGroup } from '../RingingGroup';
import messages from './messages';

const ButtonListItem = ({
  data,
  deviceChipOptions,
  isDeviceDetailEditing,
  onChangeItem,
  ringingGroupSizeLimit,
  setDeviceEditing,
}) => {
  const { formatMessage } = useIntl();
  const handleChangeChips = (value) => {
    if (!isDeviceDetailEditing) {
      setDeviceEditing(true);
    }
    onChangeItem({ ...data, selectedDevices: value });
  };

  return (
    <ListItem
      sx={{
        borderColor: 'grey.300',
        borderTop: '1px solid',
        p: 0,
      }}
    >
      <Grid alignItems="center" container>
        <Grid item md={1} xs={1}>
          {data.index === 0 && <FormattedMessage {...messages.idTalkButtonsShortPress} />}
          {data.index === 1 && <FormattedMessage {...messages.idTalkButtonsLongPress} />}
        </Grid>
        <Grid item md={2} xs={5}>
          <RingingGroup ringingGroup={data.ringingGroup} />
        </Grid>
        <Grid item md={9} xs={12}>
          <AutocompleteChips
            getIsChipWarning={getIsChipWarning}
            maxLength={ringingGroupSizeLimit}
            onChange={handleChangeChips}
            onClear={handleChangeChips}
            options={deviceChipOptions}
            placeholder={
              data.selectedDevices.length > 0
                ? formatMessage(messages.idTalkPlaceholderAddMoreDevices)
                : formatMessage(messages.idTalkPlaceholderAddDevice)
            }
            selected={data.selectedDevices}
            titleKey="name"
            warningKey="warning"
          />
        </Grid>
      </Grid>
    </ListItem>
  );
};

ButtonListItem.propTypes = {
  data: PropTypes.shape({
    index: PropTypes.number,
    ringingGroup: PropTypes.string,
    selectedDevices: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  deviceChipOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  isDeviceDetailEditing: PropTypes.bool.isRequired,
  onChangeItem: PropTypes.func.isRequired,
  ringingGroupSizeLimit: PropTypes.number.isRequired,
  setDeviceEditing: PropTypes.func.isRequired,
};

export default ButtonListItem;
