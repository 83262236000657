import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as Api from '../../api/accessLogApi';
import { selectors as companySelectors } from '../company';
import { errorHelper, responseValidateStatusOrThrow } from '../helpers';
import { selectors as sitesSelectors } from '../sites';
import types from './types';

export function* getAccessLogDetail({ logId }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(sitesSelectors.getSiteId);
    const response = yield call(Api.getAccessLogDetail, companyId, siteId, logId);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({
      payload: data,
      type: types.GET_ACCESS_LOG_DETAIL_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_ACCESS_LOG_DETAIL_FAILURE, error));
  }
}

export function* getAccessLogsList(action) {
  const { companyId, filter, order, page, params, rowsPerPage, siteId } = action;
  try {
    const response = yield call(Api.getAccessLogsList, companyId, siteId, params);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({
      filter,
      order,
      page,
      payload: data,
      rowsPerPage,
      type: types.GET_ACCESS_LOGS_LIST_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_ACCESS_LOGS_LIST_FAILURE, error));
  }
}

export default function* accessLogSagas() {
  yield all([
    takeLatest(types.GET_ACCESS_LOG_DETAIL_REQUEST, getAccessLogDetail),
    takeLatest(types.GET_ACCESS_LOGS_LIST_REQUEST, getAccessLogsList),
  ]);
}
