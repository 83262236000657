import { MessageDescriptor } from 'react-intl';
import * as yup from 'yup';
import { SiteWithAcCredentialsSettingsResponse } from '../../../apiRtk/be4feApi';
import messages from './messages';

export enum FormFieldNames {
  GuestLength = 'guestLength',
  GuestQR = 'guestQR',
  SiteBLE = 'siteBLE',
}

export function getDefaultValues(siteData: SiteWithAcCredentialsSettingsResponse | undefined) {
  return {
    guestLength: siteData?.services.ACCESS_CONTROL?.guestPinLength || 0,
    guestQR: siteData?.services.ACCESS_CONTROL?.guestShowQr || false,
    siteBLE: siteData?.services.ACCESS_CONTROL?.bluetoothEnabled ?? undefined,
  };
}

export function getValidationSchema(formatMessage: (message: MessageDescriptor) => string) {
  return yup.object().shape({
    [FormFieldNames.SiteBLE]: yup.boolean(),
    [FormFieldNames.GuestLength]: yup
      .number()
      .min(5, formatMessage(messages.siteSettingsAccessGuestLengthErrRange))
      .max(15, formatMessage(messages.siteSettingsAccessGuestLengthErrRange))
      .typeError(formatMessage(messages.siteSettingsAccessGuestLengthErrRange)),
    [FormFieldNames.GuestQR]: yup.boolean(),
  });
}
