import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IMPORT_USERS_STATES } from '../../config/consts';
import Alert from '../Alert';
import Link from '../Link';
import getImportStats from './helpers/getImportStats';
import messages from './messages';

const AlertFinished = ({ data, onClose, onShowImportModal }) => {
  const importStats = getImportStats(data);

  if (!importStats || importStats.total === 0) {
    return null;
  }

  const onSeeResults = (event) => {
    onShowImportModal();
    event.preventDefault();
  };

  return (
    <Alert onClose={onClose} severity="warning">
      {importStats.imported > 0 && (
        <FormattedMessage
          {...messages.SiteUsersImportAlertStatusImported}
          values={{
            count: importStats.imported,
          }}
        />
      )}
      {importStats.failed > 0 && (
        <FormattedMessage
          {...messages.SiteUsersImportAlertStatusFailed}
          values={{
            count: importStats.failed,
          }}
        />
      )}
      {importStats.aborted > 0 && (
        <FormattedMessage
          {...messages.SiteUsersImportAlertStatusAborted}
          values={{
            count: importStats.aborted,
            row: importStats.abortedAtRow,
          }}
        />
      )}
      <Link onClick={onSeeResults} sx={{ pl: 0.5 }} to="/">
        <FormattedMessage {...messages.SiteUsersImportAlertStatusSeeResults} />
      </Link>
    </Alert>
  );
};

AlertFinished.propTypes = {
  data: PropTypes.shape({
    failed: PropTypes.number,
    failedRows: PropTypes.arrayOf(
      PropTypes.shape({
        email: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        result: PropTypes.string,
        rowNumber: PropTypes.number,
      })
    ),
    processed: PropTypes.number,
    state: PropTypes.oneOf([
      IMPORT_USERS_STATES.PROCESSING,
      IMPORT_USERS_STATES.SUCCESS,
      IMPORT_USERS_STATES.FAILURE,
      IMPORT_USERS_STATES.ABORTED,
    ]),
    total: PropTypes.number,
  }),
  onClose: PropTypes.func.isRequired,
  onShowImportModal: PropTypes.func.isRequired,
};

AlertFinished.defaultProps = {
  data: null,
};

export default AlertFinished;
