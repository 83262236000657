import { Button, Divider, Stack } from '@mui/material';
import React, { ReactNode } from 'react';
import { isArrayEmpty } from '../../../../../../../helpers/array';

export interface IUserAccessSettingsItemAction {
  label: string;
  testId: string;
  color?: 'error' | 'primary';
  onClick: () => void;
}

export interface IUserAccessSettingsItemProps {
  children: ReactNode;
  actions?: IUserAccessSettingsItemAction[];
  hasDivider?: boolean;
  isWithoutTopPadding?: boolean;
}

export function UserAccessSettingsItem(props: IUserAccessSettingsItemProps) {
  return (
    <>
      <Stack
        direction="row"
        py={2}
        sx={{ pt: props.isWithoutTopPadding ? 0 : undefined }}
        justifyContent="space-between"
        alignItems="center"
      >
        <>{props.children}</>
        <>
          {!isArrayEmpty(props.actions) && (
            <Stack direction="row" gap={1}>
              {props.actions?.map((action, key) => (
                <Button
                  sx={{ whiteSpace: 'nowrap' }}
                  key={key}
                  onClick={action.onClick}
                  color={action.color || 'secondary'}
                  size="small"
                  variant="text"
                  test-id={action.testId}
                >
                  {action.label}
                </Button>
              ))}
            </Stack>
          )}
        </>
      </Stack>
      {props.hasDivider && <Divider />}
    </>
  );
}
