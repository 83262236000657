import { useSelector } from 'react-redux';
import { Roles } from '../modules/commonTypes';
import { getCurrentUserSelector } from '../modules/user/selectors';

export function useGetCurrentUserApartmentRoles(
  companyId?: number,
  siteId?: number,
  apartmentId?: number
): Roles[] | undefined {
  const currentUser = useSelector(getCurrentUserSelector) as {
    companies: { id: number; sites: { id: number; apartments: { id: number; roles: Roles[] }[] }[] }[];
  };

  if (companyId === undefined || siteId === undefined || apartmentId === undefined) {
    return [];
  }

  return currentUser.companies
    ?.find((company) => company.id === companyId)
    ?.sites?.find((site) => site.id === siteId)
    ?.apartments?.find((apartment) => apartment.id === apartmentId)?.roles;
}
