import { Edit, RemoveCircleOutline } from '@mui/icons-material';
import React from 'react';
import { IntlFormatters } from 'react-intl';
import { IRowAction } from '../../../../../components/DataGridAggregated/data';
import { ApartmentUser } from '../../../../../modules/apartmentAdmin/data';
import { getUWLUserType, UWLUserType } from '../../../utils/UWLUserType';
import messages from './messages';

export function createUserRowMenuActions({
  formatMessage,
  onDeleteUser,
  onEditUser,
  user,
}: {
  user: ApartmentUser;
  formatMessage: IntlFormatters['formatMessage'];
  onDeleteUser: () => void;
  onEditUser: () => void;
}): IRowAction[] {
  const userType = getUWLUserType(user);

  switch (userType) {
    case UWLUserType.UWLWithPinOnly:
      return [
        getEditUserItem({ label: formatMessage(messages.AAUserListContextMenuEditPIN), onClick: onEditUser }),
        getDeleteUserItem({ label: formatMessage(messages.AAUserListContextMenuDeletePIN), onClick: onDeleteUser }),
      ];
    case UWLUserType.UWLWithRFIDOnly:
      return [
        getEditUserItem({ label: formatMessage(messages.AAUserListContextMenuEditRFID), onClick: onEditUser }),
        getDeleteUserItem({ label: formatMessage(messages.AAUserListContextMenuDeleteRFID), onClick: onDeleteUser }),
      ];
    default:
      return [
        getEditUserItem({ label: formatMessage(messages.AAUserListContextMenuEditUser), onClick: onEditUser }),
        getDeleteUserItem({ label: formatMessage(messages.AAUserListContextMenuDeleteUser), onClick: onDeleteUser }),
      ];
  }
}

function getEditUserItem({ label, onClick }: { label: string; onClick: () => void }): IRowAction {
  return {
    icon: <Edit />,
    label: label,
    onClick,
  };
}

function getDeleteUserItem({ label, onClick }: { label: string; onClick: () => void }): IRowAction {
  return {
    hasErrorStyle: true,
    hasTopSeparator: true,
    icon: <RemoveCircleOutline />,
    label: label,
    onClick,
  };
}
