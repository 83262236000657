import { SettingsFlow } from '@ory/client';
import { AxiosError } from 'axios';
import { IntlFormatters } from 'react-intl';
import { Dispatch } from 'redux';
import { oryApi } from '../../../config/oryFrontendApi';
import { processOryApiError } from '../../../errors/errorParser';
import { getOryErrorFromOryErrorMessageId } from '../../../errors/oryErrorMessages';
import { IOryGeneralError, OryContext, OryErrorMessage, OryResponse, OrySuccess } from '../../../types';
import { getCSRFToken } from '../../../utils/CSRFToken';
import { hasResponseOKStatus } from '../../../utils/responseStatus';
import { tryToGetFlowValidationMessageId } from '../../../utils/validationMessageId';

export async function updatePassword(
  params: { password: string },
  fetchingState: React.Dispatch<React.SetStateAction<boolean>>,
  dispatch: Dispatch,
  formatMessage: IntlFormatters['formatMessage']
): Promise<OryResponse> {
  fetchingState(true);
  try {
    const settingsFlowInit = await oryApi.createBrowserSettingsFlow();
    const csrfToken = getCSRFToken(settingsFlowInit.data);

    const settingsFlow = await oryApi.updateSettingsFlow({
      flow: settingsFlowInit.data.id,
      updateSettingsFlowBody: { csrf_token: csrfToken, method: 'password', password: params.password },
    });

    fetchingState(false);

    if (hasResponseOKStatus(settingsFlow)) {
      return handleSettingsFlowValidations(settingsFlow.data, formatMessage, OryContext.ChangeCurrentUserPassword);
    }

    return false;
  } catch (e) {
    fetchingState(false);
    return processOryApiError({
      axiosErrorResponse: e as AxiosError<{ error: IOryGeneralError }>,
      context: OryContext.ChangeCurrentUserPassword,
      dispatch,
      formatMessage,
    });
  }
}

function handleSettingsFlowValidations(
  response: SettingsFlow,
  formatMessage: IntlFormatters['formatMessage'],
  context: OryContext
): boolean | OryErrorMessage {
  const oryErrorMessageId = tryToGetFlowValidationMessageId(response);
  if (oryErrorMessageId) {
    return getOryErrorFromOryErrorMessageId(oryErrorMessageId, formatMessage, context);
  }
  return OrySuccess;
}
