import Badge from '@mui/material/Badge';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const styles = {
  badge: {
    '& .MuiBadge-badge': {
      backgroundColor: 'success.main',
      zIndex: 'auto',
    },
    cursor: 'pointer',
  },
  tooltip: {
    backgroundColor: 'transparent',
    maxWidth: '160px',
  },
};

interface IApartmentFreeTooltip {
  children: JSX.Element;
  count: number;
  freeLicence?: number;
}

function ApartmentFreeTooltip({ children, count, freeLicence }: IApartmentFreeTooltip) {
  return (
    <Tooltip
      componentsProps={{
        tooltip: {
          sx: styles.tooltip,
        },
      }}
      placement="right-start"
      title={
        <Paper sx={{ p: 1 }}>
          <Typography color="primary" fontWeight="bold" variant="body2">
            <FormattedMessage
              {...(freeLicence === 1
                ? messages.FreeWithIntercomApartmentModelPopupTitle
                : messages.apartmentFreeTooltipHeader)}
            />
          </Typography>
          <Typography color="secondary" variant="caption">
            <FormattedMessage
              {...(freeLicence === 1
                ? messages.FreeWithIntercomApartmentModelPopupText
                : messages.apartmentFreeTooltipText)}
            />
          </Typography>
        </Paper>
      }
    >
      <Badge sx={styles.badge} variant="dot">
        <Typography color={count > 0 ? 'text.primary' : 'text.disabled'} component="div" noWrap variant="body2">
          {children}
        </Typography>
      </Badge>
    </Tooltip>
  );
}

export default ApartmentFreeTooltip;
