import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import actions, { selectors } from '../../modules/accessSettings';
import PrimaryModal from '../PrimaryModal';
import { useGetGlobalHooks } from './hooks/getGlobalHooks';
import messages from './messages';
import NewGroupForm from './NewGroupForm';

const AccessGroupCreateModal = ({ onClose, open }) => {
  const { dispatch, formatMessage, formMethods } = useGetGlobalHooks();
  const isLoading = useSelector(selectors.getCreateGroupIsLoading);
  const { isDirty, isValid } = formMethods.formState;

  useEffect(() => {
    if (open) {
      formMethods.reset({
        description: '',
        name: '',
      });
    }
  }, [open]);

  const handleCreateGroup = ({ description, name }) => {
    dispatch(actions.createAccessGroup({ description, name }));
    onClose();
  };

  const getIsOpen = open || isLoading;

  return (
    getIsOpen && (
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleCreateGroup)}>
          <PrimaryModal
            actionButtonLabel={formatMessage(messages.accessGroupCreateModalCreate)}
            isActionButtonDisabled={!isDirty || !isValid}
            isLoading={isLoading}
            name="access-group-create-modal"
            onClose={onClose}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onCreate={() => {}}
            open={getIsOpen}
            title={formatMessage(messages.accessGroupCreateModalTitle)}
          >
            <ModalText />
            <NewGroupForm />
          </PrimaryModal>
        </form>
      </FormProvider>
    )
  );
};

function ModalText() {
  return (
    <Typography sx={{ pb: 3 }} variant="body1">
      <FormattedMessage {...messages.accessGroupCreateModalInfo} />
    </Typography>
  );
}

AccessGroupCreateModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default AccessGroupCreateModal;
