import { defineMessages } from 'react-intl';

export default defineMessages({
  userLanguageModalAlert: {
    defaultMessage:
      'Our platform uses automated translations. Translation accuracy may vary for non-English languages.',
    description: 'User language modal - change alert text',
    id: 'userLanguageModal.alert',
  },
  UserLanguageModalPrimaryButtonTextChange: {
    defaultMessage: 'Change language',
    description: 'User language modal - primary button text change option',
    id: 'userLanguageModal.primaryButtonText.change',
  },
  UserLanguageModalSecondaryButtonText: {
    defaultMessage: 'Cancel',
    description: 'User language modal - secondary button text',
    id: 'userLanguageModal.secondaryButtonText',
  },
  UserLanguageModalTitleChange: {
    defaultMessage: 'Change language',
    description: 'USer language modal - title change option',
    id: 'userLanguageModal.title.change',
  },
});
