import { defineMessages } from 'react-intl';

export default defineMessages({
  userEmailConfirmed: {
    defaultMessage: 'E-mail confirmed',
    description: 'User Email - email confirmed',
    id: 'userEmail.confirmed',
  },
  userEmailUnconfirmed: {
    defaultMessage: 'E-mail unconfirmed',
    description: 'User Email - email unconfirmed',
    id: 'userEmail.unconfirmed',
  },
});
