import Box from '@mui/material/Box';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import mvideo1Img from '../../assets/mvideo1.svg';
import mvideo2Img from '../../assets/mvideo2.svg';
import mvideo3Img from '../../assets/mvideo3.svg';
import rc2Img from '../../assets/rc2.svg';
import messages from './messages';

const styles = {
  content: (theme) => ({
    '& img': {
      [theme.breakpoints.down('md')]: {
        maxWidth: '90px',
      },
      margin: '0 auto 30px',
      maxWidth: '110px',
    },
    '& li': {
      [theme.breakpoints.down('sm')]: {
        '&:nth-of-type(3n)': {
          clear: 'both',
        },
        display: 'block',
        float: 'left',
        width: '50%',
      },
      display: 'table-cell',
      paddingBottom: '30px',
      textAlign: 'center',
      width: 'auto',
    },
    '& span': {
      [theme.breakpoints.down('lg')]: {
        fontSize: '18px',
        maxWidth: '195px',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '16px',
        maxWidth: '170px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '14px',
        padding: '0 10px',
      },
      color: 'text.default',
      display: 'block',
      fontSize: '20px',
      margin: '0 auto',
      maxWidth: '220px',
      textAlign: 'center',
    },
    '& ul': {
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
      display: 'table',
      listStyleType: 'none',
      margin: 0,
      padding: 0,
      tableLayout: 'auto',
      width: '100%',
    },
    margin: '0 auto',
    maxWidth: '1050px',
    padding: '0 10px',
    position: 'relative',
  }),
  root: (theme) => ({
    '& h2': {
      [theme.breakpoints.down('lg')]: {
        fontSize: '38px',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '36px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '34px',
      },
      color: '#242424',
      fontSize: '40px',
      fontWeight: 400,
      marginBottom: '55px',
      marginTop: 0,
      padding: '0 20px',
      textAlign: 'center',
    },
    background: 'white',
    lineHeight: 1.5,
    minHeight: '420px',
    overflow: 'hidden',
    padding: '70px 0 0',
  }),
};

const Features = () => (
  <Box sx={styles.root}>
    <h2>
      <FormattedMessage {...messages.mvideoFeaturesTitle} />
    </h2>
    <Box sx={styles.content}>
      <ul>
        <li>
          <img alt="mvideo1" src={mvideo1Img} />
          <span>
            <FormattedMessage {...messages.mvideoFeaturesOne} />
          </span>
        </li>
        <li>
          <img alt="mvideo2" src={mvideo2Img} />
          <span>
            <FormattedMessage {...messages.mvideoFeaturesTwo} />
          </span>
        </li>
        <li>
          <img alt="mvideo3" src={mvideo3Img} />
          <span>
            <FormattedMessage {...messages.mvideoFeaturesThree} />
          </span>
        </li>
        <li>
          <img alt="mvideo4" src={rc2Img} />
          <span>
            <FormattedMessage {...messages.mvideoFeaturesFour} />
          </span>
        </li>
      </ul>
    </Box>
  </Box>
);

export default Features;
