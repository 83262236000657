import { defineMessages } from 'react-intl';

export default defineMessages({
  intervalButtonsOneMonth: {
    defaultMessage: '1m',
    description: 'One month button',
    id: 'dataRangePicker.intervalButtonsOneMonth',
  },
  intervalButtonsOneYear: {
    defaultMessage: '1Y',
    description: 'One year button',
    id: 'dataRangePicker.intervalButtonsOneYear',
  },
  intervalButtonsSixMonth: {
    defaultMessage: '6m',
    description: 'Six months button',
    id: 'dataRangePicker.intervalButtonsSixMonth',
  },
  intervalButtonsThreeYears: {
    defaultMessage: '3Y',
    description: 'Three years button',
    id: 'dataRangePicker.intervalButtonsThreeYears',
  },
  intervalButtonsTwoYears: {
    defaultMessage: '2Y',
    description: 'Two years button',
    id: 'dataRangePicker.intervalButtonsTwoYears',
  },
});
