import { defineMessages } from 'react-intl';

export default defineMessages({
  statusActiveForFree: {
    defaultMessage: 'Active for free',
    description: '',
    id: 'status.ACTIVE_FOR_FREE',
  },
  statusActiveSubscriptions: {
    defaultMessage: 'Active',
    description: '',
    id: 'status.ACTIVE_SUBSCRIPTIONS',
  },
  statusNoService: {
    defaultMessage: 'No service',
    description: '',
    id: 'status.NO_SERVICE',
  },
  statusOther: {
    defaultMessage: 'Other',
    description: '',
    id: 'statusOther',
  },
  statusUnlicensed: {
    defaultMessage: 'Unlicensed',
    description: '',
    id: 'status.UNLICENSED',
  },
});
