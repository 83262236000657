import React from 'react';
import { FormattedMessage } from 'react-intl';
import NoRowsOverlayDataGrid from '../DataGrid/NoRowsOverlay';
import messages from './messages';

const NoRowsOverlay = () => (
  <NoRowsOverlayDataGrid
    headerText={<FormattedMessage {...messages.noRowsOverlayHeaderText} />}
    text={<FormattedMessage {...messages.noRowsOverlayText} />}
  />
);

export default NoRowsOverlay;
