import { useSelector } from 'react-redux';
import { selectors } from '../../../modules/accessSettings';

export function useGetUserFormData() {
  const { isLoading, users } = {
    isLoading: useSelector(selectors.isAddAccessGroupUsersFetching),
    users: useSelector(selectors.getAccessControlUsersFiltered),
  };

  return {
    isLoading,
    users,
  };
}
