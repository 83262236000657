import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { useUrlParams } from '../../helpers/urlParams';
import actions, { selectors } from '../../modules/apartments';
import ApartmentHeader from './ApartmentHeader';
import ApartmentTabPanel from './ApartmentTabPanel';

function ApartmentDetail() {
  const dispatch = useDispatch();
  const { apartmentId, companyId, siteId } = useUrlParams();
  const { data, didInvalid } = useSelector(selectors.getApartmentDetail);

  useEffect(() => {
    if (didInvalid && companyId && siteId && apartmentId) {
      dispatch(actions.getApartmentDetail(companyId, siteId, apartmentId));
    }
  }, [didInvalid]);

  if (!apartmentId || !companyId || !siteId) {
    return <>{false}</>;
  }

  return (
    <>
      <ApartmentHeader
        apartment={data}
        onOpenDeleteApartmentModal={(id) => dispatch(actions.deleteApartmentShowModal(id))}
      />
      <ApartmentTabPanel companyId={companyId} siteId={siteId} apartmentId={apartmentId} />
      <Outlet />
    </>
  );
}

export default ApartmentDetail;
