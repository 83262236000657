import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import useBreakpoints from '../../helpers/useBreakpoints';
import { useClipboard } from '../../hooks/useClipboard';
import messages from './messages';
import { styles } from './styles';
import { TestID } from './testIds';

export interface IPinPanelProps {
  isFetching: boolean;
  hasError: boolean;
  pin?: string;
  isReadOnly?: boolean;
  onInit: () => void;
  onGenerate: (() => void) | undefined;
  isPreviewingRFID?: boolean;
}

export function PinPanel({
  hasError,
  isFetching,
  isPreviewingRFID,
  isReadOnly,
  onGenerate,
  onInit,
  pin,
}: IPinPanelProps) {
  const { copyToClipboard } = useClipboard({
    copySuccessMessage: isPreviewingRFID ? messages.pinPanelRFIDCopied : messages.pinPanelCopied,
  });
  const { upSm } = useBreakpoints();

  const pinLength = pin?.length ?? 0;

  useEffect(() => {
    onInit();
  }, []);

  const onCopyToClipboard = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (pin !== undefined) {
      copyToClipboard(pin);
    }
  };

  return (
    <>
      <Box
        sx={{
          ...styles.root,
          ...(hasError && styles.rootError),
          ...(isReadOnly && styles.readOnly),
        }}
      >
        <Box data-pin={pin} sx={styles.pin}>
          <Typography
            sx={{ ...(pinLength > 6 && styles.smallPin), ...(pinLength > 13 && styles.extraSmallPin) }}
            variant="h2"
          >
            {pin || '####'}
          </Typography>
        </Box>
        <Box sx={styles.buttons}>
          <Stack direction={upSm ? 'column' : 'row'} justifyContent={'center'} spacing={2}>
            {!isReadOnly && (
              <Button
                data-testid={TestID.GenerateButton}
                color="primary"
                size="small"
                disabled={isFetching}
                onClick={onGenerate}
                startIcon={<RefreshIcon />}
              >
                <FormattedMessage {...messages.pinPanelGenerate} />
              </Button>
            )}
            <Button
              data-testid={TestID.CopyButton}
              color={isReadOnly ? 'secondary' : 'primary'}
              size="small"
              disabled={!pin || !!(pin && isFetching)}
              onClick={onCopyToClipboard}
              startIcon={<FileCopyOutlinedIcon />}
            >
              <FormattedMessage {...messages.pinPanelCopy} />
            </Button>
          </Stack>
        </Box>

        {isFetching && (
          <Box sx={styles.progressWrapper}>
            <LinearProgress sx={styles.progress} />
          </Box>
        )}
      </Box>
      {hasError && (
        <Typography sx={styles.errorMessage} variant="body2">
          <FormattedMessage {...messages.pinPanelErrorMessage} />
        </Typography>
      )}
    </>
  );
}
