import { Theme } from '@mui/material/styles';
import { alpha } from '@mui/system';

export const styles = {
  buttons: {
    '& > button': {
      mx: 1,
      my: 0,
    },
    '& button': {
      mx: { sm: 0 },
      my: { sm: 0.5 },
    },
    display: 'flex',
    justifyContent: { sm: 'right', xs: 'center' },
    textAlign: { sm: 'left', xs: 'center' },
    width: { sm: '34%' },
  },
  errorMessage: {
    color: 'error.main',
    pt: 1,
    textAlign: 'center',
  },
  extraSmallPin: {
    fontSize: 16,
    fontWeight: 400,
    wordWrap: 'break-word',
  },
  pin: {
    '& h2': {
      fontWeight: 500,
      letterSpacing: 3,
      mx: 0,
      my: { md: 1.5, sm: 0.25 },
    },
    '&:not([data-pin])': {
      color: 'grey.400',
    },
    mr: { sm: '6%' },
    textAlign: 'center',
    width: { sm: '60%' },
  },
  progress: {
    bgcolor: 'primary.light !important',
  },
  progressWrapper: {
    bottom: 0,
    left: 0,
    position: 'absolute',
    right: 0,
  },
  readOnly: {
    bgcolor: (theme: Theme) => alpha(theme.palette.secondary.main, 0.08),
    borderColor: 'info.main',
  },
  root: {
    bgcolor: 'background.default',
    border: 2,
    borderColor: 'primary.main',
    borderRadius: '4px',
    display: { sm: 'flex' },
    position: 'relative',
    px: 2,
    py: 2,
  },
  rootError: {
    borderColor: 'error.main',
  },
  smallPin: {
    fontSize: 28,
    letterSpacing: 0,
    px: 0,
    py: 2.5,
  },
};
