import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { PATHS } from '../../config/consts';
import actions, { selectors as commonSelectors } from '../../modules/common';
import { selectors as companySelectors } from '../../modules/company';
import { getGTCListData } from '../GTCPage/helper';
import { getAgreeDocument } from '../GTCPageDetailCompany/helper';
import GTCItem from './GTCItem';
import messages from './messages';

const GTCPageCompany = ({ agreements, companyId, gtcData, onLoadGTCAgreements, onLoadGTCList }) => {
  useEffect(() => {
    onLoadGTCList(companyId);
  }, [companyId]);

  useEffect(() => {
    if (companyId) {
      onLoadGTCAgreements(companyId);
    }
  }, [companyId]);

  return (
    <>
      <Typography sx={{ mb: 4 }} variant="h4">
        <FormattedMessage {...messages.gtcPageCompanyTitle} />
      </Typography>
      <Grid container spacing={3}>
        {getGTCListData(gtcData).map((item) => (
          <Grid item key={item.id} md={4} sm={6} xs={12}>
            <GTCItem
              agree={Boolean(getAgreeDocument(agreements, item.id))}
              basePath={PATHS.GTC_PAGE_COMPANY_CONTEXT_DETAIL.replace(':companyId', companyId)}
              cookieSettings={item?.confirmationType === 'BROWSER' && item?.showType === 'COOKIE'}
              description={item.description}
              name={item.name}
              versions={item.versions}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

GTCPageCompany.propTypes = {
  agreements: PropTypes.arrayOf(
    PropTypes.shape({
      authorId: PropTypes.number,
      companyId: PropTypes.number,
      dateCreated: PropTypes.string,
      id: PropTypes.number,
      termsId: PropTypes.number,
    })
  ).isRequired,
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  gtcData: PropTypes.arrayOf(
    PropTypes.shape({
      confirmationType: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
      nextId: PropTypes.number,
      path: PropTypes.string,
      showType: PropTypes.string,
    })
  ).isRequired,
  onLoadGTCAgreements: PropTypes.func.isRequired,
  onLoadGTCList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  agreements: commonSelectors.getGTCAgreements(state),
  companyId: companySelectors.getCompanyId(state),
  gtcData: commonSelectors.getGTCList(state),
});

const dispatchToProps = {
  onLoadGTCAgreements: actions.loadGTCAgreements,
  onLoadGTCList: actions.loadGTCList,
};

export default connect(mapStateToProps, dispatchToProps)(GTCPageCompany);
