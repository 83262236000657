import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { enhancedBe4FEApi } from '../../../../../../apiRtk/be4feApiEnhancer';
import { deleteUserPin } from '../../../../../../modules/apartmentAdmin/actions';
import { ApartmentUser } from '../../../../../../modules/apartmentAdmin/data';
import { getUserPinSelector } from '../../../../../../modules/apartmentAdmin/selectors';
import { IApartmentIdsInfo } from '../../../../../../modules/apartments/store';

export interface IDeletePinProps {
  apartmentIdsInfo: IApartmentIdsInfo;
  user: ApartmentUser;
  onClose: () => void;
}

export interface IUseDeletePin {
  isSubmitting: boolean;
  onDeleteHandler: () => void;
}
export function useDeletePin({
  apartmentIdsInfo: { apartmentId, companyId, siteId },
  onClose,
  user,
}: IDeletePinProps): IUseDeletePin {
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { error } = useSelector(getUserPinSelector);

  const onDeleteSuccessHandler = () => {
    setIsSubmitting(false);
    dispatch(enhancedBe4FEApi.util.resetApiState());
    onClose();
  };

  const onDeleteHandler = () => {
    setIsSubmitting(true);
    dispatch(deleteUserPin(companyId, siteId, apartmentId, user.id, onDeleteSuccessHandler));
  };

  useEffect(() => {
    if (error) {
      setIsSubmitting(false);
    }
  }, [error]);

  return {
    isSubmitting,
    onDeleteHandler,
  };
}
