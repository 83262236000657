import { defineMessages } from 'react-intl';

export default defineMessages({
  applicationVersionFieldLabel: {
    defaultMessage: 'Mobile app version',
    description: '',
    id: 'applicationVersionField.label',
  },
  applicationVersionFieldUnknown: {
    defaultMessage: 'Unknown',
    description: '',
    id: 'applicationVersionField.unknown',
  },
});
