import { defineMessages } from 'react-intl';

export default defineMessages({
  AAmodalDeleteUserUwLCancel: {
    defaultMessage: 'Cancel',
    id: 'AAmodal.deleteUserUwL.cancel',
  },
  AAmodalDeleteUserUwLMsgErr: {
    defaultMessage: 'There was an error, please try again',
    id: 'AAmodal.deleteUserUwL.msgErr',
  },
  AAmodalDeleteUserUwLMsgSucc: {
    defaultMessage: 'User deleted successfully',
    id: 'AAmodal.deleteUserUwL.msgSucc',
  },
  AAmodalDeleteUserUwLSubmit: {
    defaultMessage: 'Delete user',
    id: 'AAmodal.deleteUserUwL.submit',
  },
  AAmodalDeleteUserUwLText: {
    defaultMessage:
      'By deleting, the user will lose access.<p>If the user is a member of other apartments they are not deleted from those.</p>',
    id: 'AAmodal.deleteUserUwL.text',
  },
  AAmodalDeleteUserUwLTitle: {
    defaultMessage: 'Delete user',
    id: 'AAmodal.deleteUserUwL.title',
  },
});
