import Stack from '@mui/material/Stack';
import React from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import useBreakpoints from '../../../../helpers/useBreakpoints';
import { IApartmentDetail, IFloor, INewApartment } from '../../../../modules/apartments/store';
import { BillingType } from '../../../../modules/commonTypes';
import { IMVLicenceModel } from '../../../../modules/sites/store';
import { IApartmentFormProps } from '../../../ApartmentsOverview/hooks/useGetApartmentFormData';
import ApartmentBasicInfoSection from './ApartmentBasicInfoSection';
import ApartmentStatusSection from './ApartmentStatusSection';

interface IApartmentDataForm {
  apartmentDetailData?: IApartmentDetail;
  floors: Array<IFloor>;
  formMethods: UseFormReturn<IApartmentFormProps>;
  formWrapperProps?: object;
  isSiteMdu: boolean;
  isModal: boolean;
  licenceModel: IMVLicenceModel;
  onSubmit: (values: INewApartment) => void;
}

function ApartmentDataForm({
  apartmentDetailData,
  floors,
  formMethods,
  formWrapperProps,
  isModal,
  isSiteMdu,
  licenceModel,
  onSubmit,
}: IApartmentDataForm) {
  const { downSm } = useBreakpoints();

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <Stack direction={downSm ? 'column' : 'row'} py={isModal ? 3 : 0} spacing={3}>
          <ApartmentBasicInfoSection
            floors={floors}
            formMethods={formMethods}
            formWrapperProps={formWrapperProps}
            isModal={isModal}
            isSiteMdu={isSiteMdu}
            licenceModel={licenceModel}
          />
          {!isModal && apartmentDetailData && licenceModel.billingType === BillingType.PER_APARTMENT && (
            <ApartmentStatusSection
              apartmentDetailData={apartmentDetailData}
              floors={floors}
              formMethods={formMethods}
              formWrapperProps={formWrapperProps}
              licenceModel={licenceModel}
            />
          )}
        </Stack>
      </form>
    </FormProvider>
  );
}

export default ApartmentDataForm;
