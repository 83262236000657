import { AxiosResponse } from 'axios';
import { TId, TPaginationParams } from '../modules/commonTypes';
import { IUser } from '../modules/user/store';
import { encode } from './index';
import { getSiteData, ISiteGenericRequestData, postSiteData } from './siteRequest';

export type TMultipleUsers = { lastName: string }[];

export interface ICreateMultipleUsersData {
  users: TMultipleUsers;
  generatePin: boolean;
}

export interface IGetApartmentUsersData {
  users: Array<IUser>;
  resultsFiltered: number;
  resultsTotal: number;
}

export const createMultipleUsers = async (
  siteData: ISiteGenericRequestData,
  apartmentId: TId,
  data: ICreateMultipleUsersData
) => postSiteData(siteData, `apartments/${encode(apartmentId.toString())}/bulk-login-less`, data, true);

export const getApartmentUsers = async (
  siteData: ISiteGenericRequestData,
  apartmentId: TId,
  params: TPaginationParams
): Promise<AxiosResponse<IGetApartmentUsersData>> =>
  getSiteData(siteData, `apartments/${encode(apartmentId.toString())}/users`, true, params);
