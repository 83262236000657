import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFinishRegistrationMutation } from '../../../../apiRtk/legacyFeApi';
import { PATHS } from '../../../../config/consts';
import { buildUrlWithQueryParams, UrlQueryParamName, UrlQueryParamValueBoolean } from '../../../../helpers/queryParams';
import { useLocationState } from '../../../../hooks/useLocationState';
import { getAccessTokenSelector } from '../../../../modules/auth/selectors';
import { addSnackbarSuccess } from '../../../../modules/notifications/actions';
import { getCurrentUserSelector } from '../../../../modules/user/selectors';
import { useOryLoginApi } from '../../../../ory/hooks/oryApi';
import { useOryResponseParser } from '../../../../ory/hooks/oryResponseParser';
import { isOryUserPotentiallyAlreadyLoggedIn } from '../../../../ory/hooks/oryUser/utils';
import { ILogoutLocationStateRedirect } from '../../../LogoutPage/LogoutPage';
import messages from './messages';
import { IUseNewPasswordForm, IUseNewPasswordFormProps } from './useAccountActivationFormData';
import { getOnFormSubmit } from './useAccountActivationFormSubmit';
import {
  defaultValues,
  FormFieldNames,
  getActivateAccountValidationScheme,
  IActivateAccountFormData,
} from './validationScheme';

export function useAccountActivationForm({ onSuccess }: IUseNewPasswordFormProps): IUseNewPasswordForm {
  const [searchParams] = useSearchParams();
  const currentUser = useSelector(getCurrentUserSelector);
  const accessToken = useSelector(getAccessTokenSelector);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [finishRegistration, { isLoading }] = useFinishRegistrationMutation();
  const { isOryApiFetching, loginToOry } = useOryLoginApi();
  const { navigateWithState } = useLocationState<ILogoutLocationStateRedirect>();
  const { parseOryResponse } = useOryResponseParser();

  const [isRegistrationFinished, setIsRegistrationFinished] = useState(false);
  const formData = useForm<IActivateAccountFormData>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(getActivateAccountValidationScheme(formatMessage)),
  });

  const registrationId = searchParams.get('id');
  const email = searchParams.get('email');
  const operation = searchParams.get('operation');
  const preferUserLogin = searchParams.get('preferUserLogin') === 'true';

  useEffect(() => {
    if (!registrationId || !email) {
      navigate(PATHS.LOGIN);
    }
  }, []);

  useEffect(() => {
    if (currentUser && currentUser.id) {
      const isOryUserLoggedIn = isOryUserPotentiallyAlreadyLoggedIn(accessToken, currentUser);
      const navigatePath = isOryUserLoggedIn
        ? buildUrlWithQueryParams({
            baseUrl: PATHS.LOGOUT,
            queryParams: { [UrlQueryParamName.IsOrySessionActive]: UrlQueryParamValueBoolean.True },
          })
        : PATHS.LOGOUT;

      navigateWithState({
        navigateTo: navigatePath,
        state: { redirectTo: PATHS.ACCOUNT_ACTIVATION + window.location.search },
      });
    }
  }, [currentUser]);

  useEffect(() => {
    if (isRegistrationFinished && currentUser && currentUser.id) {
      dispatch(addSnackbarSuccess({ ...messages.ImsNewPasswordSuccess }));
      onSuccess();
    }
  }, [currentUser?.id, isRegistrationFinished]);

  const { isDirty: isPasswordValidationDirty } = formData.getFieldState(FormFieldNames.Password);

  return {
    email: email!,
    formData,
    isLoading: isLoading || isOryApiFetching,
    isPasswordHelperTextVisible: !isPasswordValidationDirty,
    onFormSubmit: getOnFormSubmit({
      dispatch,
      email: email!,
      finishRegistration,
      formatMessage,
      formData,
      loginToOry,
      operation: operation!,
      parseOryResponse,
      preferUserLogin,
      registrationId: registrationId!,
      setIsRegistrationFinished,
    }),
  };
}
