import Box from '@mui/material/Box';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { IMPORT_USERS_STATES } from '../../../config/consts';
import { selectors } from '../../../modules/user';
import getImportStats from '../helpers/getImportStats';
import SectionTemplate from '../SectionTemplate';
import ImportResultFailed from './ImportResultFailed';
import ImportResultSuccess from './ImportResultSuccess';
import messages from './messages';

function ImportResult() {
  const { formatMessage } = useIntl();
  const currentImportUsersData = useSelector(selectors.currentImportUsersData);
  const importStats = getImportStats(currentImportUsersData);
  return (
    <SectionTemplate title={formatMessage(messages.siteUsersImportResultStepImportProcessResult)}>
      <Box>
        {currentImportUsersData?.state === IMPORT_USERS_STATES.SUCCESS ? (
          <ImportResultSuccess importStats={importStats} />
        ) : (
          <ImportResultFailed importStats={importStats} />
        )}
      </Box>
    </SectionTemplate>
  );
}

export default ImportResult;
