import Typography from '@mui/material/Typography';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import actions, { selectors } from '../../modules/accessSettings';
import DangerModal from '../DangerModal';
import messages from './messages';

interface IAccessGroupUserRemoveModal {
  onClose: () => void;
  open: boolean;
  userId: string;
  userName: string;
}

const AccessGroupUserRemoveModal = ({ onClose, open, userId, userName }: IAccessGroupUserRemoveModal) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { groupName, isLoading } = {
    groupName: useSelector(selectors.getAccessGroupName),
    isLoading: useSelector(selectors.getIsAccessGroupUserRemoveFetching),
  };

  const handleRemoveDevice = useCallback(() => {
    dispatch(actions.removeAccessGroupUser(userId));
    onClose();
  }, [userId]);

  const getIsOpen = open || isLoading;

  return getIsOpen ? (
    <DangerModal
      actionButtonLabel={formatMessage(messages.accessGroupUserRemoveModalRemove)}
      isLoading={isLoading}
      name="access-group-user-remove-modal"
      onClose={onClose}
      onSubmit={handleRemoveDevice}
      open={getIsOpen}
      title={formatMessage(messages.accessGroupUserRemoveModalTitle)}
    >
      <Typography variant="body1">
        <FormattedMessage
          {...messages.accessGroupUserRemoveModalText}
          values={{
            br: <br />,
            groupName,
            strong: (chunks) => <strong>{chunks}</strong>,
            userName,
          }}
        />
      </Typography>
    </DangerModal>
  ) : (
    <>{false}</>
  );
};

export default AccessGroupUserRemoveModal;
