import { DataGrid } from '@mui/x-data-grid';
import React from 'react';
import { MuiRow } from './aggregatedCellContentRenderer';
import { getAggregatedDataGridColumns } from './aggregatedColumns';
import {
  getAggregatedCellClassName,
  getAggregatedDataGridClassName,
  getAggregatedRowClassName,
  getAggregatedRowHeight,
} from './aggregatedDataGridStyleFunctions';
import { getAggregatedDataGridRows, onAggregatedTableRowClick } from './aggregatedRows';
import { DataGridAggregatedProps } from './data';
import { getTableFooter } from './footerActions';
import * as styles from './styles';

export function DataGridAggregated<TAggregatedItem extends object, TItem extends object>(
  props: DataGridAggregatedProps<TAggregatedItem, TItem>
) {
  return (
    <DataGrid
      sx={styles.aggregatedDataGridStyle}
      columnHeaderHeight={0}
      getRowHeight={(params) => getAggregatedRowHeight(params.model as MuiRow<TAggregatedItem | TItem>)}
      slots={{
        footer: () => getTableFooter({ footerActions: props.footerActions, isFetching: props.isFetching }),
      }}
      rowSelection={false}
      onRowClick={(params) =>
        onAggregatedTableRowClick({
          isFetching: props.isFetching,
          onAggregatedRowClick: props.onAggregatedRowClick,
          onRowClick: props.onRowClick,
          row: params.row,
        })
      }
      rows={getAggregatedDataGridRows(props)}
      columns={getAggregatedDataGridColumns(props)}
      checkboxSelection={false}
      disableRowSelectionOnClick
      className={getAggregatedDataGridClassName({ footerActions: props.footerActions })}
      getCellClassName={(params) => getAggregatedCellClassName({ columnId: params.field, row: params.row })}
      getRowClassName={(params) =>
        getAggregatedRowClassName({
          isFetching: props.isFetching,
          onAggregatedRowClick: props.onAggregatedRowClick,
          onRowClick: props.onRowClick,
          row: params.row,
        })
      }
      hideFooterPagination
    />
  );
}
