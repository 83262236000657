import { Box, Typography } from '@mui/material';
import React from 'react';
import { FooterPublic, HeaderPublic } from '../../../components';
import { styles } from './styles';

export interface IPublicFormWrapperProps {
  title: string;
  children: React.ReactNode;
  subContent?: React.ReactNode;
}

export function PublicFormWrapper(props: IPublicFormWrapperProps) {
  return (
    <>
      <HeaderPublic />
      <Box sx={styles.wrapper}>
        <Box sx={styles.boxContent}>
          <Typography sx={styles.title} variant="h1">
            {props.title}
          </Typography>
          <Box sx={styles.box}>{props.children}</Box>
          {props.subContent && props.subContent}
        </Box>
      </Box>
      <FooterPublic />
    </>
  );
}
