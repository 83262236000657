import { FE_ROLES, USERS_CREDENTIALS } from '../../config/consts';
import { IApartment } from '../apartments/store';
import { IReduxStoreBase, Roles, TId, TUuid } from '../commonTypes';
import { ICompany } from '../company/store';
import { initialState } from './reducer';

interface IGroup {
  id: string;
  name: string;
  description: string;
}

export type AccessType = keyof typeof USERS_CREDENTIALS;

export type UserRoles = keyof typeof FE_ROLES;

export interface IUser extends IUserBase {
  admin?: boolean;
  uuid: string;
  apartments: Array<IApartment>;
  role: UserRoles;
  roles?: Roles[];
  credentials: Array<AccessType>;
  groups: Array<IGroup>;
}

export interface IUserStore extends IReduxStoreBase<IUser> {
  userDetail: IReduxStoreBase<IUserDetail>;
  userRfidCard: IReduxStoreBase<object> | undefined;
  my2NAppAccessStatus: IReduxStoreBase<object> | undefined;
}

export interface IUserDetail extends IUserBase {
  newsletters: boolean;
  uuid: TUuid;
  role: UserRoles;
  apartments: TId[];
}

export enum UserContext {
  COMPANY_ADMINS = 'companyAdmins',
  CURRENT_USER = 'currentUser',
  SITE_USERS = 'usersSites',
}

export interface INewUser {
  firstName?: string;
  lastName: string;
  email?: string;
}

export interface IUserBase {
  id: number;
  firstName?: string;
  lastName: string;
  email: string;
}

export interface IUserIdsInfo {
  siteId: TId;
  companyId: TId;
  userId: TId;
}

export interface ICurrentUser extends IUserBase {
  companies: Array<ICompany>;
  dateCreated: string;
  enabled: boolean;
  lastUpdated: string;
  locale: string;
  newsletters: boolean;
  roles: Array<string>;
  type: string;
  uuid: string;
}

export function getInitialUserStore(): IUserStore {
  return {
    ...initialState,
    data: undefined,
    didInvalid: false,
    error: undefined,
    isEditing: false,
    isFetching: false,
    my2NAppAccessStatus: undefined,
    userDetail: { ...initialState.userDetail, data: undefined, isEditing: false },
    userRfidCard: undefined,
  };
}
