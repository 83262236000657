import { useSelector } from 'react-redux';
import * as apartmentSelectors from '../../../../../modules/apartments/selectors';
import * as companySelectors from '../../../../../modules/company/selectors';
import * as userSelectors from '../../../../../modules/user/selectors';
import { getValidationParameters } from '../../../../DeviceModal/helpers';

export function useGetUserInfoFormData() {
  const { apartmentOptions, companyName, currentUserId, initialApartments, user, userModalData, userModalDataId } = {
    apartmentOptions: useSelector(apartmentSelectors.getApartmentsListOrdered),
    companyName: useSelector(companySelectors.getCompanyName),
    currentUserId: useSelector(userSelectors.getCurrentUserIdSelector),
    initialApartments: useSelector(apartmentSelectors.getUserInfoFormInitialApartments),
    user: useSelector(userSelectors.getCurrentUserDetail),
    userModalData: useSelector(userSelectors.getUserModalData),
    userModalDataId: useSelector(userSelectors.getUserModalDataId),
  };
  const isCurrentUser = typeof currentUserId === 'number' && currentUserId === userModalDataId;
  const validation = useSelector(getValidationParameters);
  const initialUserRole = userModalData.role;

  return {
    apartmentsData: {
      apartmentOptions,
      initialApartments,
    },
    companyData: {
      companyName,
    },
    userData: {
      currentUserId,
      initialUserRole,
      isCurrentUser,
      user,
      userModalData,
      validation,
    },
  };
}
