import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import ButtonLink from '../ButtonLink';
import DashboardCardHeader from '../DashboardCardHeader';

const LinksBox = ({ links, name }) => (
  <Card sx={{ height: '100%', width: '100%' }}>
    <DashboardCardHeader title={name} />
    <Box p={3} pt={2}>
      <Grid container direction="column">
        {links.map((item, id) => (
          <Grid item key={`link-${id + 1}`}>
            {!item?.action ? (
              <ButtonLink color="primary" size="small" sx={{ pl: 0 }} to={item.link}>
                <Typography sx={{ textTransform: 'none' }} variant="body2">
                  {item.label}
                </Typography>
              </ButtonLink>
            ) : (
              <Button color="primary" onClick={item.action} size="small" sx={{ pl: 0 }}>
                <Typography sx={{ textTransform: 'none' }} variant="body2">
                  {item.label}
                </Typography>
              </Button>
            )}
          </Grid>
        ))}
      </Grid>
    </Box>
  </Card>
);

LinksBox.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.func,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
      link: PropTypes.string,
    })
  ).isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

export default LinksBox;
