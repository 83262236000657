import { defineMessages } from 'react-intl';

export default defineMessages({
  headerPublicLogoHeadline: {
    defaultMessage: 'My2N',
    description: '',
    id: 'headerPublic.logo.headline',
  },
  headerPublicLogoMenuDashboard: {
    defaultMessage: 'Dashboard',
    description: '',
    id: 'headerPublic.menu.dashboard',
  },
  headerPublicLogoMenuLogin: {
    defaultMessage: 'Log in',
    description: '',
    id: 'headerPublic.menu.login',
  },
  headerPublicLogoMenuMobileVideo: {
    defaultMessage: 'My2N App',
    description: '',
    id: 'headerPublic.menu.mobileVideo',
  },
  headerPublicLogoMenuRemoteConfiguration: {
    defaultMessage: '2N<sup>®</sup> Remote Configuration',
    description: '',
    id: 'headerPublic.menu.remoteConfiguration',
  },
  languageMenuAutomaticTranslations: {
    defaultMessage:
      'Our platform uses automated translations. Translation accuracy may vary for non-English languages.',
    id: 'languageMenu.automaticTranslations',
  },
  languageMenuSiteLanguage: {
    defaultMessage: 'Visit <link>Site settings</link> section for site-wide settings.',
    id: 'languageMenu.siteLanguage',
  },
  languageMenuSiteLanguageBold: {
    defaultMessage: 'Visit Site settings section for site-wide settings.',
    id: 'languageMenu.siteLanguageBold',
  },
});
