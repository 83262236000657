import { api } from './api';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDeviceConfig: build.query<GetDeviceConfigApiResponse, GetDeviceConfigApiArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/elevator-integration/devices/${queryArg.deviceId}`,
      }),
    }),
    getDeviceIntegrationStatuses: build.mutation<
      GetDeviceIntegrationStatusesApiResponse,
      GetDeviceIntegrationStatusesApiArg
    >({
      query: (queryArg) => ({
        body: queryArg.body,
        method: 'POST',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/elevator-integration/devices/integration-statuses`,
      }),
    }),
    getSiteBuildingTopology: build.query<GetSiteBuildingTopologyApiResponse, GetSiteBuildingTopologyApiArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/elevator-integration/building-topology`,
      }),
    }),
    getSiteElevatorIntegration: build.query<GetSiteElevatorIntegrationApiResponse, GetSiteElevatorIntegrationApiArg>({
      query: (queryArg) => ({ url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/elevator-integration` }),
    }),
    getSiteFloorMapping: build.query<GetSiteFloorMappingApiResponse, GetSiteFloorMappingApiArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/elevator-integration/floor-mapping`,
      }),
    }),
    getUser: build.query<GetUserApiResponse, GetUserApiArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/elevator-integration/users/${queryArg.userId}`,
      }),
    }),
    linkSiteWithElevatorProvider: build.mutation<
      LinkSiteWithElevatorProviderApiResponse,
      LinkSiteWithElevatorProviderApiArg
    >({
      query: (queryArg) => ({
        body: queryArg.linkSiteRequestDto,
        method: 'PUT',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/elevator-integration/link`,
      }),
    }),
    refreshSiteBuildingTopology: build.mutation<
      RefreshSiteBuildingTopologyApiResponse,
      RefreshSiteBuildingTopologyApiArg
    >({
      query: (queryArg) => ({
        method: 'POST',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/elevator-integration/building-topology/refresh`,
      }),
    }),
    updateDeviceConfig: build.mutation<UpdateDeviceConfigApiResponse, UpdateDeviceConfigApiArg>({
      query: (queryArg) => ({
        body: queryArg.deviceRequestDto,
        method: 'PUT',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/elevator-integration/devices/${queryArg.deviceId}`,
      }),
    }),
    updateSite: build.mutation<UpdateSiteApiResponse, UpdateSiteApiArg>({
      query: (queryArg) => ({
        body: queryArg.siteRequestDto,
        method: 'PUT',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/elevator-integration`,
      }),
    }),
    updateSiteFloorMapping: build.mutation<UpdateSiteFloorMappingApiResponse, UpdateSiteFloorMappingApiArg>({
      query: (queryArg) => ({
        body: queryArg.floorMappingDto,
        method: 'PUT',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/elevator-integration/floor-mapping`,
      }),
    }),
    updateUser: build.mutation<UpdateUserApiResponse, UpdateUserApiArg>({
      query: (queryArg) => ({
        body: queryArg.userRequestDto,
        method: 'PUT',
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/elevator-integration/users/${queryArg.userId}`,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as elevatorIntegrationApi };
export type GetSiteElevatorIntegrationApiResponse = /** status 200 OK */ SiteDto;
export type GetSiteElevatorIntegrationApiArg = {
  companyId: string;
  siteId: string;
};
export type UpdateSiteApiResponse = /** status 204 Site elevator configuration has been updated */ Unit;
export type UpdateSiteApiArg = {
  companyId: string;
  siteId: string;
  siteRequestDto: SiteRequestDto;
};
export type GetUserApiResponse = /** status 200 OK */ UserDto;
export type GetUserApiArg = {
  companyId: string;
  siteId: string;
  userId: string;
};
export type UpdateUserApiResponse = /** status 204 User elevator configuration has been updated */ Unit;
export type UpdateUserApiArg = {
  companyId: string;
  siteId: string;
  userId: string;
  userRequestDto: UserRequestDto;
};
export type LinkSiteWithElevatorProviderApiResponse =
  /** status 204 Site has been linked with elevator provider */ Unit;
export type LinkSiteWithElevatorProviderApiArg = {
  companyId: string;
  siteId: string;
  linkSiteRequestDto: LinkSiteRequestDto;
};
export type GetSiteFloorMappingApiResponse = /** status 200 OK */ FloorMappingDto;
export type GetSiteFloorMappingApiArg = {
  companyId: string;
  siteId: string;
};
export type UpdateSiteFloorMappingApiResponse = /** status 204 No Content */ Unit;
export type UpdateSiteFloorMappingApiArg = {
  companyId: string;
  siteId: string;
  floorMappingDto: FloorMappingDto;
};
export type GetDeviceConfigApiResponse = /** status 200 Device elevator integration configuration */ DeviceDto;
export type GetDeviceConfigApiArg = {
  companyId: string;
  siteId: string;
  deviceId: string;
};
export type UpdateDeviceConfigApiResponse = /** status 204 Configuration has been set up */ Unit;
export type UpdateDeviceConfigApiArg = {
  companyId: string;
  siteId: string;
  deviceId: string;
  deviceRequestDto: DeviceRequestDto;
};
export type GetDeviceIntegrationStatusesApiResponse =
  /** status 200 Device elevator integration */ DeviceIntegrationStatusDto;
export type GetDeviceIntegrationStatusesApiArg = {
  companyId: string;
  siteId: string;
  body: string[];
};
export type RefreshSiteBuildingTopologyApiResponse = /** status 204 No Content */ BuildingTopologyDto;
export type RefreshSiteBuildingTopologyApiArg = {
  companyId: string;
  siteId: string;
};
export type GetSiteBuildingTopologyApiResponse = /** status 200 OK */ BuildingTopologyDto;
export type GetSiteBuildingTopologyApiArg = {
  companyId: string;
  siteId: string;
};
export type SiteDto = {
  buildingIds: string[];
  elevatorIntegrationEnabled: boolean;
  landingCallEnabled: boolean;
  destinationCallEnabled: boolean;
  visitorCallEnabled: boolean;
};
export type ServerErrorResponse = {
  message?: string;
  detail?: object;
  uuid?: string;
};
export type Unit = object;
export type SiteRequestDto = {
  elevatorIntegrationEnabled: boolean;
  landingCallEnabled: boolean;
  destinationCallEnabled: boolean;
  visitorCallEnabled: boolean;
};
export type UserDto = {
  userId: string;
  landingCallEnabled: boolean;
  destinationCallEnabled: boolean;
};
export type UserRequestDto = {
  landingCallEnabled: boolean;
  destinationCallEnabled: boolean;
};
export type LinkSiteRequestDto = {
  buildingIds: string[];
  secret: string;
};
export type StopDto = {
  buildingId: string;
  elevatorGroupId: string;
  areaId: string;
};
export type SingleFloorMappingDto = {
  floorId: string;
  mappings: StopDto[];
};
export type FloorMappingDto = {
  floors: SingleFloorMappingDto[];
};
export type DeviceStopDto = {
  buildingId: string;
  elevatorGroupId: string;
  areaId: string;
  walkingTime: number;
};
export type DeviceDto = {
  stops: DeviceStopDto[];
  landingCallEnabled: boolean;
  destinationCallEnabled: boolean;
  integrationActive: boolean;
};
export type DeviceRequestDto = {
  stops: DeviceStopDto[];
  landingCallEnabled: boolean;
  destinationCallEnabled: boolean;
};
export type DeviceIntegrationStatusDto = {
  deviceId: string;
  integrationActive: boolean;
};
export type DestinationDto = {
  areaId: string;
  shortName: string;
};
export type ElevatorGroupDto = {
  id: string;
  name: string;
  destinations: DestinationDto[];
};
export type BuildingDto = {
  id: string;
  elevatorGroups: ElevatorGroupDto[];
};
export type BuildingTopologyDto = {
  buildings: BuildingDto[];
};
export const {
  useGetDeviceConfigQuery,
  useGetDeviceIntegrationStatusesMutation,
  useGetSiteBuildingTopologyQuery,
  useGetSiteElevatorIntegrationQuery,
  useGetSiteFloorMappingQuery,
  useGetUserQuery,
  useLinkSiteWithElevatorProviderMutation,
  useRefreshSiteBuildingTopologyMutation,
  useUpdateDeviceConfigMutation,
  useUpdateSiteFloorMappingMutation,
  useUpdateSiteMutation,
  useUpdateUserMutation,
} = injectedRtkApi;
