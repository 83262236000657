import { defineMessages } from 'react-intl';

export default defineMessages({
  formActionBackBtn: {
    defaultMessage: 'Back',
    description: 'Form action - back button',
    id: 'x2nDevice.formAction.backBtn',
  },
  formActionCancelBtn: {
    defaultMessage: 'Cancel',
    description: 'Form action - cancel button',
    id: 'x2nDevice.formAction.cancelBtn',
  },
  formActionNextBtn: {
    defaultMessage: 'Next',
    description: 'Form action - next button',
    id: 'x2nDevice.formAction.nextBtn',
  },
  securityCodeControlInputLabel: {
    defaultMessage: 'Device Security code',
    description: 'Security code control label',
    id: 'securityCodeControl.input.label',
  },
  serialNumberControlInputLabel: {
    defaultMessage: 'Device serial number',
    description: 'Serial number control label',
    id: 'serialNumberControl.input.label',
  },
  serialNumberControlNoValid: {
    defaultMessage: 'Invalid format. Check for the typo.',
    description: 'Serial number control no valid',
    id: 'serialNumberControl.noValid',
  },
  storeHashControlStoreHashLabel: {
    defaultMessage: 'Remember security code',
    description: '2N Device one step - remember checkbox',
    id: 'storeHashControl.storeHash.label',
  },
  storeHashControlStoreHashTooltip: {
    defaultMessage:
      'This is useful when your intercom accidentally disconnects. Thanks to' +
      ' the code it will automatically connect to the service again.',
    description: '2N Device one step - tooltip',
    id: 'storeHashControl.storeHash.tooltip',
  },
  X2nAddDeviceModalApartmentModelFreeLicenseFirstStep: {
    defaultMessage:
      'Adding this 2N intercom to the site will grant' + 'you a free Mobile Video license for one apartment.',
    id: 'x2n.addDeviceModal.apartmentModel.freeLicense.firstStep',
  },
  X2nAddDeviceModalDeviceModelFreeLicenseFirstStep: {
    defaultMessage:
      'Adding this 2N intercom to the site will grant you a free Mobile Video' +
      'license for up to {count} {count, plural, one {smartphone or tablet} other {smartphones or tablets}}.',
    id: 'x2n.addDeviceModal.deviceModel.freeLicense.firstStep',
  },
  x2nDeviceOneStepApartmentLabel: {
    defaultMessage: 'Apartment',
    description: '2N Device one step - apartment field label',
    id: 'x2nDeviceOneStep.apartment.label',
  },
  x2nDeviceOneStepNameHelperText: {
    defaultMessage: 'We recommend naming the device after its location, such as Main Entrance.',
    description: '2N Device one step - name device helper text',
    id: 'x2nDeviceOneStep.name.helperText',
  },
  x2nDeviceOneStepNameLabel: {
    defaultMessage: 'Device name',
    description: '2N Device one step - name device label',
    id: 'x2nDeviceOneStep.name.label',
  },
  x2nDeviceOneStepSecurityCodeInfoBox: {
    defaultMessage:
      'You can find the serial number and Security Code inside the product packaging. You can' +
      ' also <url>generate a new security code</url> or contact our Customer Service.',
    description: '2N Device one step - info box',
    id: 'securityCode.infoBox',
  },
  x2nDeviceOneStepSecurityCodeSomeDevicesNotRequiredHint: {
    defaultMessage: 'Some devices don’t require security code',
    description: 'Hint that, not all device require security code.',
    id: 'securityCode.someDevicesNotRequiredHint',
  },
  x2nDeviceOneStepSecurityCodeThisDeviceNotRequiredHint: {
    defaultMessage: 'This device doesn’t require security code',
    description: 'Hint that, this device does not require security code.',
    id: 'securityCode.thisDeviceNotRequiredHint',
  },
  x2nDeviceOneStepSerialNumberParagraph: {
    defaultMessage:
      'Before you add your intercom or answering unit, it needs to be verified. We do this' +
      ' for your safety, to make sure that the device is yours.',
    description: '2N Device one step - serial number description',
    id: 'x2nDeviceOneStep.serialNumber.paragraph',
  },
});
