import RemoveIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const RemoveBatchButton = ({ disabled, onClick }) => (
  <>
    <Box display={{ sm: 'block', xs: 'none' }}>
      <Button color="secondary" disabled={disabled} onClick={onClick} startIcon={<RemoveIcon />}>
        <FormattedMessage {...messages.removeBatchButtonLabel} />
      </Button>
    </Box>
    <Box display={{ sm: 'none', xs: 'block' }}>
      <IconButton color="secondary" disabled={disabled} onClick={onClick}>
        <RemoveIcon />
      </IconButton>
    </Box>
  </>
);

RemoveBatchButton.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default RemoveBatchButton;
