import { defineMessages } from 'react-intl';

export default defineMessages({
  agreement: {
    defaultMessage:
      'I want to use the voucher with' +
      ' <strong>{credit}</strong> {credit, plural, one {credit} other {credits}}' +
      ' to replenish my credit pool, of which' +
      ' consuming <strong>{consumption}</strong> {consumption, plural, one' +
      ' {credit} other {credits}} will be used immediately' +
      ' to extend my Mobile Video subscription.',
    description: 'After activate header',
    id: 'agreement',
  },
  AgreementInsufficientCredit: {
    defaultMessage:
      'I want to use the voucher with <strong>{credit}</strong>' +
      '{credit, plural, one {credit} other {credits}} to replenish my credit pool.' +
      'Immediately, <strong>{consumption}</strong> {consumption, plural, one {credit}' +
      'other {credits}} from the credit pool will be used.',
    id: 'agreement.insufficientCredit',
  },
  codeSummaryHeader: {
    defaultMessage: 'After you activate the code:',
    description: 'After activate header',
    id: 'codeSummary.header',
  },
  CurrentMonthSummaryConsumption: {
    defaultMessage: 'Current month consumption: {credits} {credits, plural, one {credit} other {credits}}',
    id: 'currentMonthSummary.consumption',
  },
  CurrentMonthSummaryConsumptionAdditional: {
    defaultMessage:
      'Additional consumption until the end of month:' +
      '<strong>{credits}</strong> {credits, plural, one {credit} other {credits}}',
    id: 'currentMonthSummary.consumption.additional',
  },
  CurrentMonthSummaryInfoApartmentPaidServicesActive: {
    defaultMessage: 'Based on the number of unlicensed apartments with activated paid services',
    id: 'currentMonthSummary.info.apartment.paidServicesActive',
  },
  currentMonthSummaryInfoDevices: {
    defaultMessage: 'Based on the number of devices with active subscriptions for the' + ' remainder of this month.',
    description: 'Current month devices summary info',
    id: 'currentMonthSummary.info.devices',
  },
  nextPaymentSummaryNextPayment: {
    defaultMessage: 'The next payment ({nextPayment}) from your credit card' + ' is due on {expiration}',
    description: 'Next payment info',
    id: 'nextPaymentSummary.nextPayment',
  },
  siteStatusSummaryInfoStandardExpired: {
    defaultMessage: 'Currently, your site is inactive' + '{br}Using the voucher will make your site active',
    description: 'Site status standard expired info',
    id: 'siteStatusSummary.info.standardExpired',
  },
  siteStatusSummaryInfoTrialActive: {
    defaultMessage: 'Using a voucher will end your trial period' + '{br}Your site will remain active',
    description: 'Site status trial active info',
    id: 'siteStatusSummary.info.trialActive',
  },
  siteStatusSummaryInfoTrialExpired: {
    defaultMessage: 'Your trial period has expired' + '{br}Using the voucher will make your site active',
    description: 'Site status trial expired info',
    id: 'siteStatusSummary.info.trialExpired',
  },
  totalValueBarText: {
    defaultMessage: 'The total value of your voucher',
    description: 'Total value bar text',
    id: 'totalValueBar.text',
  },
  totalValueBarValue: {
    defaultMessage: '{value} credits',
    description: 'Total value bar value',
    id: 'totalValueBar.value',
  },
  twoStepHeader: {
    defaultMessage: 'Extend your subscription',
    description: 'Code summary header',
    id: 'twoStep.header',
  },
});
