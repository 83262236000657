import { defineMessages } from 'react-intl';

export default defineMessages({
  companyButtonCompany: {
    defaultMessage: 'Company:',
    description: 'Company button - company',
    id: 'companyButton.company',
  },
  companyButtonEmptyCompany: {
    defaultMessage: 'Select company...',
    description: 'Company button - company not set',
    id: 'companyButton.emptyCompany',
  },
  companySwitcherCreateCompany: {
    defaultMessage: 'Create company',
    description: 'Company switcher - create company',
    id: 'companySwitcher.createCompany',
  },
  siteButtonEmptySite: {
    defaultMessage: 'Select site...',
    description: 'Site button - site not set',
    id: 'siteButton.emptySite',
  },
  siteButtonSite: {
    defaultMessage: 'Site:',
    description: 'Site button - site',
    id: 'siteButton.site',
  },
  siteSwitcherCreateSite: {
    defaultMessage: 'Create site',
    description: 'Site switcher - create site',
    id: 'siteSwitcher.createSite',
  },
  siteSwitcherViewAllSites: {
    defaultMessage: 'View all sites',
    description: 'Site switcher - view all sites',
    id: 'siteSwitcher.viewAllSites',
  },
  userMenuAssignedDevices: {
    defaultMessage: 'Assigned devices',
    description: 'User menu - assigned devices',
    id: 'userMenu.assignedDevices',
  },
  userMenuLogOut: {
    defaultMessage: 'Log out',
    description: 'User menu - log out',
    id: 'userMenu.logOut',
  },
  userMenuUserProfile: {
    defaultMessage: 'User profile',
    description: 'User menu - user profile',
    id: 'userMenu.userProfile',
  },
});
