import 'moment/locale/cs';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fi';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/nl';
import { csCZ, deDE, enUS, esES, fiFI, frFR, itIT, nlNL } from '@mui/material/locale';
import React from 'react';
import { ResolvedIntlConfig } from 'react-intl';
import { LANGUAGES } from './config/consts';

export function getMessagesByLocale(locale: string) {
  const beStyleLocale = locale.replace('-', '_');
  switch (beStyleLocale) {
    case LANGUAGES.english.locale:
      return import('../public/translations/en.json');
    case LANGUAGES.czech.locale:
      return import('../public/translations/cs.json');
    case LANGUAGES.german.locale:
      return import('../public/translations/de.json');
    case LANGUAGES.spanish.locale:
      return import('../public/translations/es.json');
    case LANGUAGES.finnish.locale:
      return import('../public/translations/fi.json');
    case LANGUAGES.french.locale:
      return import('../public/translations/fr.json');
    case LANGUAGES.italian.locale:
      return import('../public/translations/it.json');
    case LANGUAGES.dutch.locale:
      return import('../public/translations/nl.json');
    default:
      return import('../public/translations/en.json');
  }
}

export const getMuiLocalizations = (locale: string) => {
  const beStyleLocale = locale.replace('-', '_');
  switch (beStyleLocale) {
    case LANGUAGES.english.locale:
      return enUS;
    case LANGUAGES.czech.locale:
      return csCZ;
    case LANGUAGES.german.locale:
      return deDE;
    case LANGUAGES.spanish.locale:
      return esES;
    case LANGUAGES.finnish.locale:
      return fiFI;
    case LANGUAGES.french.locale:
      return frFR;
    case LANGUAGES.italian.locale:
      return itIT;
    case LANGUAGES.dutch.locale:
      return nlNL;
    default:
      return enUS;
  }
};

export function getDefaultRichTextElements(): ResolvedIntlConfig['defaultRichTextElements'] {
  return {
    b: (chunks) => <b>{chunks}</b>,
    br: () => <br />,
    li: (chunks) => <li>{chunks}</li>,
    ol: (chunks) => <ol>{chunks}</ol>,
    p: (chunks) => <p>{chunks}</p>,
    strong: (chunks) => <strong>{chunks}</strong>,
    ul: (chunks) => <ul>{chunks}</ul>,
  };
}
