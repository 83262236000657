import DoneIcon from '@mui/icons-material/Done';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ConfirmDialog from '../ConfirmDialog';

const ConfirmButton = ({
  confirmInfo,
  dialogHeaderVariant,
  dialogTitle,
  disableButtonText,
  enableButtonText,
  isActive,
  onConfirm,
  submitButton,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleConfirm = () => {
    onConfirm();
    setIsOpen(false);
  };

  const handleIsOpen = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setIsOpen(!isOpen);
  };

  return (
    <>
      <ConfirmDialog
        headerVariant={dialogHeaderVariant}
        onCancel={handleIsOpen}
        onConfirm={handleConfirm}
        open={isOpen}
        submitButton={() => submitButton(handleConfirm)}
        title={dialogTitle}
      >
        {confirmInfo}
      </ConfirmDialog>
      {isActive ? (
        <Button color="info" onClick={handleIsOpen} size="small" startIcon={<HighlightOffIcon />} variant="contained">
          {disableButtonText}
        </Button>
      ) : (
        <Button color="primary" onClick={handleIsOpen} size="small" startIcon={<DoneIcon />} variant="contained">
          {enableButtonText}
        </Button>
      )}
    </>
  );
};

ConfirmButton.propTypes = {
  confirmInfo: PropTypes.node.isRequired,
  dialogHeaderVariant: PropTypes.oneOf(['primary', 'danger']),
  dialogTitle: PropTypes.node,
  disableButtonText: PropTypes.node.isRequired,
  enableButtonText: PropTypes.node.isRequired,
  isActive: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  submitButton: PropTypes.func,
};

ConfirmButton.defaultProps = {
  dialogHeaderVariant: 'primary',
  dialogTitle: null,
  isActive: false,
  submitButton: () => null,
};

export default ConfirmButton;
