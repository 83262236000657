import TextField from '@mui/material/TextField';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import { utils } from 'xlsx';
import messages from './messages';

const ManualImport = ({ disabled, onSetImportData }) => {
  const { formatMessage } = useIntl();

  const handleChangeData = (event) => {
    const data = Papa.parse(event.target.value || '', { skipEmptyLines: true });
    const sheet = utils.aoa_to_sheet(data.data);
    onSetImportData(utils.sheet_to_json(sheet, { defval: '', header: 1 }));
  };

  return (
    <TextField
      disabled={disabled}
      fullWidth
      InputProps={{
        minRows: 12,
        placeholder: formatMessage(messages.siteUsersImportUploadStepManualImportPlaceholder),
      }}
      multiline
      onChange={handleChangeData}
      sx={{
        '& textarea': {
          maxHeight: 310,
          overflow: 'auto !important',
        },
      }}
      variant="outlined"
    />
  );
};

ManualImport.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onSetImportData: PropTypes.func.isRequired,
};

export default memo(ManualImport);
