import Badge from '@mui/material/Badge';
import React from 'react';

export function ApartmentAdminBadge({ borderColor, children }: { borderColor?: string; children: React.ReactNode }) {
  return (
    <Badge
      overlap="circular"
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      variant="dot"
      color="primary"
      sx={{
        '& .MuiBadge-badge': {
          border: `2px solid ${borderColor}`,
          borderRadius: '50%',
          height: '12px',
          padding: '0 4px',
          width: '12px',
        },
      }}
    >
      {children}
    </Badge>
  );
}
