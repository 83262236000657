import { defineMessages } from 'react-intl';

export default defineMessages({
  localizationHelpersSetByUser: {
    defaultMessage: 'set by user',
    description: 'Localization helpers - set by user select option label',
    id: 'localizationHelpersSetByUser',
  },
  localizationHelpersSiteDefault: {
    defaultMessage: 'site default',
    description: 'Localization helpers - site default select option label',
    id: 'localizationHelpersSiteDefault',
  },
  localizationLanguagesCzech: {
    defaultMessage: 'Czech',
    description: 'Localization languages - Czech',
    id: 'localizationLanguagesCzech',
  },
  localizationLanguagesDutch: {
    defaultMessage: 'Dutch',
    description: 'Localization languages - Dutch',
    id: 'localizationLanguagesDutch',
  },
  localizationLanguagesEnglish: {
    defaultMessage: 'English',
    description: 'Localization languages - English',
    id: 'localizationLanguagesEnglish',
  },
  localizationLanguagesFinnish: {
    defaultMessage: 'Finnish',
    description: 'Localization languages - Finnish',
    id: 'localizationLanguagesFinnish',
  },
  localizationLanguagesFrench: {
    defaultMessage: 'French',
    description: 'Localization languages - French',
    id: 'localizationLanguagesFrench',
  },
  localizationLanguagesGerman: {
    defaultMessage: 'German',
    description: 'Localization languages - German',
    id: 'localizationLanguagesGerman',
  },
  localizationLanguagesItalian: {
    defaultMessage: 'Italian',
    description: 'Localization languages - Italian',
    id: 'localizationLanguagesItalian',
  },
  localizationLanguagesSpanish: {
    defaultMessage: 'Spanish',
    description: 'Localization languages - Spanish',
    id: 'localizationLanguagesSpanish',
  },
  localizationSnackbarLanguageSetSuccessfully: {
    defaultMessage: 'Language set successfully.',
    description: 'Localization snackbar - Language set successfully',
    id: 'localizationSnackbarLanguageSetSuccessfully',
  },
});
