import http, { encode } from './index';

export const createInvoicePayment = async (company, purchase, data) =>
  http.post(`/companies/${encode(company)}/purchase/payments/${encode(purchase)}`, data);

export const getSerialNumberLicenses = async (company, serialNumber) =>
  http.get(`/companies/${encode(company)}/purchase/IP_INTERCOM?serialNumbers=${encode(serialNumber)}`);

export const setPurchase = async (company, data) => http.post(`/companies/${encode(company)}/purchase`, data);

export const getPayments = async (company, purchase) =>
  http.get(`/companies/${encode(company)}/purchase/payments/${purchase}`);

export const setPayments = async (company, purchase, data) =>
  http.put(`/companies/${encode(company)}/purchase/payments/${encode(purchase)}`, data);

export const getPromoCodes = async (company) => http.get(`/companies/${encode(company)}/purchase/PROMO`);

export const deletePromoOrderContent = async (company, purchase, id) =>
  http.delete(`/companies/${encode(company)}/purchase/payments/${encode(purchase)}/content/${id}`);

export const deleteOrderLicense = async (company, purchase, licenseId) =>
  http.delete(`/companies/${encode(company)}/purchase/payments/${encode(purchase)}/content/${licenseId}`);

export const getMobileVideoSubscriptionRequest = async (company, siteId) =>
  http.get(`/companies/${encode(company)}/purchase/MOBILE_VIDEO?siteId=${encode(siteId)}`);

export const updateMvLicence = async (company, siteId, action) =>
  http.post(`/companies/${encode(company)}/sites/${encode(siteId)}/services/MOBILE_VIDEO/licence`, action);

export const getMobileVideoPricesRequest = async (company, filterMonth, filterYear) =>
  http.get(
    // eslint-disable-next-line max-len
    `/companies/${encode(company)}/purchase/prices?product=${encode(filterMonth)},${encode(filterYear)}`
  );

export const verifyActivationCode = async (companyId, siteId, code) =>
  http.get(`/promo/codes/${encode(code)}/activation`, {
    params: {
      companyId,
      siteId,
    },
  });

export const activateActivationCode = async (companyId, siteId, code) =>
  http.post(`/promo/codes/${encode(code)}/activation`, {
    companyId,
    siteId,
  });

export const getVatVerification = async (companyId) => http.head(`/companies/${encode(companyId)}/purchase/vat`);
