import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import LoadingButton from '../LoadingButton';
import Modal from '../Modal';
import messages from './messages';

const PrimaryModal = ({
  actionButtonLabel,
  buttonIcon,
  children,
  isActionButtonDisabled,
  isLoading,
  name,
  onClose,
  onCreate,
  open,
  title,
  ...other
}) =>
  open && (
    <Modal
      {...other}
      actions={
        <>
          <Button onClick={onClose}>
            <FormattedMessage {...messages.primaryModalButtonCancel} />
          </Button>
          <LoadingButton
            color="primary"
            disabled={isActionButtonDisabled}
            isLoading={isLoading}
            onClick={onCreate}
            startIcon={buttonIcon || <AddIcon />}
            type="submit"
            variant="contained"
          >
            {actionButtonLabel}
          </LoadingButton>
        </>
      }
      fullWidth
      maxWidth="xs"
      name={name}
      onClose={onClose}
      open={open}
      PaperProps={{ component: 'form', noValidate: true }}
      title={title}
    >
      {children}
    </Modal>
  );

PrimaryModal.propTypes = {
  actionButtonLabel: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  buttonIcon: PropTypes.element,
  children: PropTypes.node.isRequired,
  isActionButtonDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
};

PrimaryModal.defaultProps = {
  buttonIcon: null,
  isActionButtonDisabled: false,
  isLoading: false,
};

export default PrimaryModal;
