export default {
  actions: {
    justifyContent: 'flex-end',
    mt: 1,
    position: 'relative',
  },
  actionsLeft: {
    left: 0,
    position: 'absolute',
  },
  adornment: {
    '& input': {
      textAlign: 'end',
    },
  },
  boldInput: {
    '& input': {
      color: '#000',
      fontWeight: '500',
    },
  },
  channelButtonGroup: {
    mt: 1,
  },
  dateButtonsGrid: {
    '& button': {
      fontSize: '14px',
      lineHeight: 1.5,
      minWidth: 'auto',
      textTransform: 'none',
    },
    '& hr': {
      mx: 0,
      my: 4.5,
      width: 2,
    },
    alignItems: 'center',
  },
  datePicker: {
    '& > div': {
      my: 0,
    },
    '& fieldset': {
      border: '2px solid #E0E0E0 !Important',
      borderRadius: '4px',
    },
    width: '45%',
  },
  datePickers: {
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
  divider: {
    '& hr': {
      height: 2,
    },
    mx: 0,
    my: 2,
  },
  firstGridRow: {
    flexGrow: 1,
  },
  gridRow: {
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    mt: 1,
  },
  helperText: {
    color: 'secondary.main',
  },
  inputAdornment: {
    pr: 0.25,
  },
  invoiceGrid: {
    '& fieldset': {
      border: '2px solid #E0E0E0 !Important',
      borderRadius: '4px',
    },
    '& input': {
      color: '#4A4A4A',
      fontSize: '14px',
      opacity: 1,
      textAlign: 'start',
    },
    width: '100%',
  },
  modalFirstStep: {
    '& #dialog-content': {
      pb: 0,
    },
  },
  notes: {
    '& fieldset': {
      border: '2px solid #E0E0E0 !Important',
      borderRadius: '4px',
    },
  },
  textField: {
    '& fieldset': {
      border: '2px solid #7E8F99',
      borderRadius: '4px',
    },
    '& input': {
      fontSize: '14px',
      py: 1.75,
    },
  },
  textFieldLabel: {
    color: '#4A4A4A',
    fontSize: '14px',
    fontWeight: '700',
  },
  typography: {
    pt: 4,
  },
};
