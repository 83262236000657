import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { QR_CODE_READER_ERROR, QR_CODE_READER_PERMISSION_DENIED, QR_CODE_READER_TIMEOUTS } from '../../config/consts';
import { QR_CODE_DEVICE_MASK } from '../../config/devices';

const QRCodeReader = ({ children, error, onError, onSuccess, videoContainerStyle, videoStyle }) => {
  const [isWorking, setIsWorking] = useState(false);

  useEffect(() => {
    const allowTimeout = setTimeout(() => {
      if (!error && !isWorking) {
        onError(QR_CODE_READER_ERROR.ALLOW_CAMERA);
      }
    }, QR_CODE_READER_TIMEOUTS.ALLOW_CAMERA);
    if (isWorking && error === QR_CODE_READER_ERROR.ALLOW_CAMERA) {
      onError('');
    }
    return () => {
      clearTimeout(allowTimeout);
    };
  }, [error, isWorking]);

  useEffect(() => {
    const scanTimeout = setTimeout(() => {
      if (!error) {
        onError(QR_CODE_READER_ERROR.TIMEOUT);
      }
    }, QR_CODE_READER_TIMEOUTS.USER_INACTIVE);
    return () => {
      clearTimeout(scanTimeout);
    };
  }, [error]);

  return (
    <QrReader
      constraints={{
        facingMode: process.env.NODE_ENV === 'development' ? 'user' : 'environment',
      }}
      onResult={(result, err) => {
        if (!isWorking) {
          setIsWorking(true);
        }
        if (result) {
          onSuccess(result?.text);
          if (!result?.text?.match(QR_CODE_DEVICE_MASK)) {
            onError(QR_CODE_READER_ERROR.INVALID_QR_CODE);
          }
        }
        if (err && err?.message?.match(QR_CODE_READER_PERMISSION_DENIED)) {
          onError(QR_CODE_READER_ERROR.PERMISSION_DENIED);
        }
      }}
      scanDelay={1000}
      videoContainerStyle={videoContainerStyle}
      videoStyle={videoStyle}
      ViewFinder={() => children}
    />
  );
};

QRCodeReader.propTypes = {
  children: PropTypes.node,
  error: PropTypes.string.isRequired,
  onError: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  videoContainerStyle: PropTypes.oneOfType([PropTypes.object]),
  videoStyle: PropTypes.oneOfType([PropTypes.object]),
};

QRCodeReader.defaultProps = {
  children: null,
  videoContainerStyle: null,
  videoStyle: null,
};

export default QRCodeReader;
