import InfoIcon from '@mui/icons-material/Info';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';

function InfoAlert({ children }) {
  return (
    <Alert
      icon={
        <InfoIcon
          sx={{
            color: 'secondary.main',
            display: {
              sm: 'flex',
              xs: 'none',
            },
          }}
        />
      }
      sx={{
        backgroundColor: 'subscription.backgroundLight',
        color: 'secondary.main',
        my: 2,
      }}
    >
      <Typography variant="body2">{children}</Typography>
    </Alert>
  );
}

InfoAlert.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default InfoAlert;
