import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './styles/UserAccessForm';

const SectionTitle = ({ isEmpty, onInfoClick, startIcon, title }) => (
  <Box sx={styles.sectionHeader}>
    <Grid container>
      <Grid item sx={styles.formIcon}>
        {startIcon}
      </Grid>
      <Grid item>
        <Typography color={isEmpty ? 'secondary' : 'primary'} sx={styles.formTitle} variant="h6">
          {title}
        </Typography>
      </Grid>
      <Grid item sx={styles.infoIcon}>
        <InfoOutlinedIcon color="disabled" onClick={onInfoClick} />
      </Grid>
    </Grid>
    <Divider />
  </Box>
);

SectionTitle.propTypes = {
  isEmpty: PropTypes.bool,
  onInfoClick: PropTypes.func.isRequired,
  startIcon: PropTypes.any.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

SectionTitle.defaultProps = {
  isEmpty: true,
};

export default SectionTitle;
