import { defineMessages } from 'react-intl';

export default defineMessages({
  userRfidModalAlertCardAssignedText: {
    defaultMessage:
      'Each RFID card can only be assigned to one user within a site.' +
      ' This card is assigned to another {br}user.' +
      '{br}To change the card user, remove the card from the {link}.',
    description: 'RFID assign error - alert card already assigned',
    id: 'userRfidModal.alertCardAssigned.text',
  },
  userRfidModalAlertCardAssignedUserLink: {
    defaultMessage: "current user's detail",
    description: 'RFID assign error - link to user',
    id: 'userRfidModal.alertCardAssigned',
  },
  userRfidModalAssigneeText: {
    defaultMessage: 'This RFID code is assigned to:',
    description: 'User rfid modal - assignee text',
    id: 'userRfidModalAssigneeText',
  },
  userRfidModalCancelButton: {
    defaultMessage: 'Cancel',
    description: 'User rfid modal - cancel button',
    id: 'userRfidModalCancelButton',
  },
  userRfidModalConfirmButton: {
    defaultMessage: 'Assign card',
    description: 'User rfid modal - confirm button',
    id: 'userRfidModalConfirmButton',
  },
  userRfidModalNewTitle: {
    defaultMessage: 'Assign RFID card',
    description: 'User rfid modal - title',
    id: 'userRfidModalNewTitle',
  },
});
