import { defineMessages } from 'react-intl';

export default defineMessages({
  elevatorIntegrationSettingsDestinationCall: {
    defaultMessage: 'Preset elevator destination on entry',
    description: 'Elevator integration switch settings - destination call label',
    id: 'elevatorIntegrationSwitchSettings.destinationCall',
  },
  elevatorIntegrationSettingsLandingCall: {
    defaultMessage: 'Call elevator on entry',
    description: 'Elevator integration switch settings - landing call label',
    id: 'elevatorIntegrationSwitchSettings.landingCall',
  },
});
