import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';

function CellTooltip({ children }) {
  const { formatMessage } = useIntl();
  return <Tooltip title={formatMessage(messages.siteUsersImportCheckStepDataGridCellTooltip)}>{children}</Tooltip>;
}

CellTooltip.propTypes = {
  children: PropTypes.node.isRequired,
};

export default memo(CellTooltip);
