import React from 'react';
import { Navigate } from 'react-router-dom';
import { PATHS } from '../../config/consts';
import { useCanUserAccessRoute } from '../../hooks/rolesHooks';
import { IAllowedRoles } from './types';

export interface IPrivateRoute {
  children: React.ReactElement;
  allowedRoles: IAllowedRoles;
  fallback?: string;
}

export function PrivateRoute({ allowedRoles, children, fallback }: IPrivateRoute): React.ReactElement {
  const canAccess = useCanUserAccessRoute(allowedRoles);

  if (canAccess) {
    return children;
  }

  if (fallback !== undefined) {
    return <Navigate to={fallback}></Navigate>;
  }

  return <Navigate to={PATHS.USER_PROFILE}></Navigate>;
}
