import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { LICENSE } from '../../config/subscription';
import CustomDate from '../CustomDate';
import PriceFormat from '../PriceFormat';
import messages from './messages';

const styles = {
  body: {
    '& tr :nth-of-type(1), :nth-of-type(2), :nth-of-type(3)': {
      borderTop: 0,
    },
  },
  buttonWrapper: {
    ml: -1,
    mt: 1,
  },
  date: {
    color: 'text.secondary',
  },
  price: {
    color: 'primary.main',
    fontWeight: 500,
    mr: 1,
  },
  priceWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  root: {
    height: '100%',
  },
  row: {
    '& td': {
      borderTop: '1px solid',
      borderTopColor: 'grey.300',
    },
    minHeight: 48,
  },
};

const TableContent = ({ onSeePricing, payments }) => {
  const getSubscription = (license) => {
    let type = '';
    let subscribtionMessage;

    if (license === LICENSE.MOBILE_VIDEO_MONTH) {
      type = 'monthlySubscription';
      subscribtionMessage = messages.paymentsAndCreditsConsumptionContentMonthlySubscription;
    } else if (license === LICENSE.MOBILE_VIDEO_YEAR) {
      type = 'annualSubscription';
      subscribtionMessage = messages.paymentsAndCreditsConsumptionContentAnnualSubscription;
    }
    return type !== '' ? <FormattedMessage {...subscribtionMessage} /> : '';
  };

  return (
    <Grid container direction="column" justifyContent="space-between" sx={styles.root}>
      <Grid item>
        <Table>
          <TableBody sx={styles.body}>
            {payments.map((payment) => (
              <TableRow hover key={payment.id} sx={styles.row}>
                <TableCell sx={styles.date}>
                  <CustomDate dateValue={payment.dateCreated} />
                </TableCell>
                <TableCell display={{ sm: 'block', xs: 'none' }}>
                  {getSubscription(payment.orderContent.product)}
                </TableCell>
                <TableCell>
                  <Box sx={styles.priceWrapper}>
                    <Box sx={styles.price}>
                      <PriceFormat currency={payment.currency} price={payment.price} />
                    </Box>
                    {payment.priceVat && (
                      <Box component="span">
                        <FormattedMessage
                          {...messages.noPaymentsContentPaymentsContentPriceVat}
                          values={{
                            price: <PriceFormat currency={payment.currency} price={payment.priceVat} />,
                          }}
                        />
                      </Box>
                    )}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Grid>
      <Grid item sx={styles.buttonWrapper}>
        <Button color="primary" onClick={onSeePricing}>
          <FormattedMessage {...messages.noPaymentsContentPaymentsContentSeePricing} />
        </Button>
      </Grid>
    </Grid>
  );
};

TableContent.propTypes = {
  onSeePricing: PropTypes.func.isRequired,
  payments: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default TableContent;
