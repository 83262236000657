import { Box, Stack, SvgIconProps, Typography } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import React, { ReactNode, SVGProps } from 'react';
import { palette } from '../../../../../../../theme';

const iconWidth = 24;
const spacingPx = 8;

export interface ITextContent {
  content: string;
  isBold?: boolean;
}

export interface IIconWithContentProps {
  Icon: React.FC<SvgIconProps> | React.FC<SVGProps<SVGSVGElement>>;
  textContent?: ITextContent;
  children?: ReactNode;
  isActive?: boolean;
  isAlignCentered?: boolean;
  hasIconOffset?: boolean;
}

export function IconWithContent(props: IIconWithContentProps) {
  const color = props.isActive ? 'primary' : 'secondary';
  const spacing = props.textContent?.isBold ? 1 : 2;
  return (
    <Stack
      direction="row"
      sx={{ width: `calc(100% - ${iconWidth}px - ${spacing * spacingPx}px)` }}
      alignItems={props.isAlignCentered ? 'center' : undefined}
      spacing={spacing}
    >
      <Box sx={{ fontSize: 0, pt: props.hasIconOffset ? 0.5 : undefined }}>
        <SvgIcon component={props.Icon} color={color} />
      </Box>
      {props.textContent ? (
        <Typography
          color={color}
          variant={props.textContent.isBold ? 'body1' : 'body2'}
          fontWeight={props.textContent.isBold ? palette.fontWeight.bold : undefined}
        >
          {props.textContent.content}
        </Typography>
      ) : (
        props.children
      )}
    </Stack>
  );
}
