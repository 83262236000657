import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const styles = {
  button: {
    borderColor: 'white',
    color: 'white',
  },
  root: {
    pb: 4,
    pt: 1,
    textAlign: 'center',
  },
};

const CloseButton = ({ onClick }) => (
  <Box sx={styles.root}>
    <Button onClick={onClick} startIcon={<CloseIcon />} sx={styles.button} variant="outlined">
      <FormattedMessage {...messages.deviceMobileScanQRCloseScanner} />
    </Button>
  </Box>
);

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CloseButton;
