import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React from 'react';

const DetailDeleteButton = ({ disabled, label, onClick }) => (
  <>
    <Box sx={{ display: { lg: 'block', xs: 'none' } }}>
      <Button color="secondary" disabled={disabled} onClick={onClick} startIcon={<DeleteIcon />}>
        {label}
      </Button>
    </Box>
    <Box sx={{ display: { lg: 'none', xs: 'block' } }}>
      <IconButton color="secondary" disabled={disabled} onClick={onClick}>
        <DeleteIcon />
      </IconButton>
    </Box>
  </>
);

DetailDeleteButton.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.element.isRequired,
  onClick: PropTypes.func.isRequired,
};

DetailDeleteButton.defaultProps = {
  disabled: false,
};

export default DetailDeleteButton;
