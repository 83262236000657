import { defineMessages } from 'react-intl';

export default defineMessages({
  billingInfoAddressCountry: {
    defaultMessage: 'Country',
    description: 'Billing info - address country',
    id: 'billingInfo.address.country',
  },
  billingInfoAddressStreet: {
    defaultMessage: 'Street',
    description: 'Billing info - address street',
    id: 'billingInfo.address.street',
  },
  billingInfoAddressTitle: {
    defaultMessage: 'Address',
    description: 'Billing info - address title',
    id: 'billingInfo.address.title',
  },
  billingInfoCurrencyAndVatCurrency: {
    defaultMessage: 'Currency',
    description: 'Billing info - currency',
    id: 'billingInfo.currencyAndVat.currency',
  },
  billingInfoCurrencyAndVatDescription: {
    defaultMessage: 'If you need to change your Billing info, please contact Customer Care at',
    description: 'Billing info - description',
    id: 'billingInfo.currencyAndVat.description',
  },
  billingInfoCurrencyAndVatTitle: {
    defaultMessage: 'Currency and VAT',
    description: 'Billing info - currency and vat title',
    id: 'billingInfo.currencyAndVat.title',
  },
  billingInfoCurrencyAndVatVatNumber: {
    defaultMessage: 'VAT Number',
    description: 'Billing info - vat number',
    id: 'billingInfo.currencyAndVat.vatNumber',
  },
  billingInfoEditBillingInfo: {
    defaultMessage: 'Edit billing info',
    description: 'Billing info - edit billing info',
    id: 'billingInfo.editBillingInfo',
  },
  billingInfoPleaseComplete: {
    defaultMessage: 'Please complete the billing information first.',
    description: 'Billing info - please complete',
    id: 'billingInfo.pleaseComplete',
  },
  billingInfoTitle: {
    defaultMessage: 'Billing information',
    description: 'Billing info - title',
    id: 'billingInfo.title',
  },
});
