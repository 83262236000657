import { defineMessages } from 'react-intl';

export default defineMessages({
  hipmoFormApartmentNumberExists: {
    defaultMessage: 'An apartment with this number already exists.',
    description: 'Device Modal - new apartment form - apartment number already exists',
    id: 'hipmoForm.apartment.numberExists',
  },
  hipmoFormApartmentRequired: {
    defaultMessage: "Choose an apartment','hipmoForm.apartment.required",
    description: '',
    id: 'hipmoForm.apartment.required',
  },
  hipmoFormEmailNoValid: {
    defaultMessage: 'Wrong format of the e-mail.',
    description: 'Device Modal HipmoOrThirdDevice - email is not valid',
    id: 'hipmoForm.email.noValid',
  },
  hipmoFormNameExist: {
    defaultMessage: 'This device name is already used.',
    description: 'Device Modal HipmoOrThirdDevice - name exist',
    id: 'hipmoForm.name.exist',
  },
  hipmoFormRequired: {
    defaultMessage: 'Value is mandatory',
    description: 'Device Modal - required value',
    id: 'hipmoForm.required',
  },
  hipmoFormTooLong: {
    defaultMessage: 'Value is too long',
    description: 'Device Modal - value is too long',
    id: 'hipmoForm.tooLong',
  },
});
