import { Box } from '@mui/material';
import React from 'react';
import { TypographyWithEllipsisTooltip } from '../../../../components/TypographyWithEllipsisTooltip';
import { getUserName } from '../../../../modules/user/utils';
import { palette } from '../../../../theme';

export interface IUWLName {
  firstName?: string;
  lastName: string;
}

export interface IUWLNameProps {
  name: IUWLName;
}

export function UWLName(props: IUWLNameProps) {
  return (
    <Box sx={{ pb: 1, pt: 1 }}>
      <TypographyWithEllipsisTooltip variant="body1" fontWeight={palette.fontWeight.bold}>
        {getUserName(props.name)}
      </TypographyWithEllipsisTooltip>
    </Box>
  );
}
