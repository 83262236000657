import { defineMessages } from 'react-intl';

export default defineMessages({
  InstallAppModalAlertInfo: {
    defaultMessage:
      'With the My2N app, you get to control the door from anywhere and see who is entering your building.',
    description: 'Install app modal content - alert info text',
    id: 'installAppModalContentAlertInfo',
  },
  InstallAppModalAlertSuccess: {
    defaultMessage:
      'Account activated successfully. Install our My2N app on your mobile device/smartphone to get started.',
    description: 'Install app modal content - alert success text',
    id: 'installAppModalContentAlertSuccess',
  },
  InstallAppModalAndroidLink: {
    defaultMessage: 'Install My2N app on Android',
    description: 'Install app modal content - android link',
    id: 'installAppModalContentAndroidLink',
  },
  InstallAppModalIosLink: {
    defaultMessage: 'Install My2N app on iOS',
    description: 'Install app modal content - ios link',
    id: 'installAppModalContentIosLink',
  },
});
