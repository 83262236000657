import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { orderItemConfigurationPropTypes } from '../../types';
import messages from '../messages';

const ItemYearsAndSubscriptionsCount = ({ item }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('xl'));
  const message = getMessage(item.type, item.devices);
  return (
    <Grid container item lg={3} sm={6} spacing={matches ? 0 : 3} xl={4}>
      <Grid item xl={6}>
        <Typography>
          <Box component="span" fontWeight="bold">
            {item.years}
          </Box>{' '}
          <Box color="text.secondary" component="span">
            {item.years === 1 ? (
              <FormattedMessage {...messages.orderListYear} />
            ) : (
              <FormattedMessage {...messages.orderListYears} />
            )}
          </Box>
        </Typography>
      </Grid>
      <Grid item xl={6}>
        <Typography whiteSpace="nowrap">
          <Box component="span" fontWeight="bold">
            {item.devices}
          </Box>{' '}
          <Box color="text.secondary" component="span">
            <FormattedMessage {...message} />
          </Box>
        </Typography>
      </Grid>
    </Grid>
  );
};

ItemYearsAndSubscriptionsCount.propTypes = {
  item: orderItemConfigurationPropTypes.isRequired,
};

export default ItemYearsAndSubscriptionsCount;

function getMessage(itemType, devicesCount) {
  if (itemType === 'MOBILE_VIDEO_POOL_CREDIT_YEAR_APARTMENT') {
    return devicesCount === 1
      ? messages.orderListMobileVideoPoolCreditYearApartmentOne
      : messages.orderListMobileVideoPoolCreditYearApartmentMore;
  }

  return devicesCount === 1
    ? messages.orderListMobileVideoPoolCreditYearStandartOne
    : messages.orderListMobileVideoPoolCreditYearStandartMore;
}
