import { Stack, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { InfoModal } from '../../../../../../../components/_DesignSystemWrappers/Modal';
import { PinPanel } from '../../../../../../../components/PinPanel';
import { getUserPin } from '../../../../../../../modules/apartmentAdmin/actions';
import { ApartmentUser } from '../../../../../../../modules/apartmentAdmin/data';
import { getUserPinSelector } from '../../../../../../../modules/apartmentAdmin/selectors';
import { IApartmentIdsInfo } from '../../../../../../../modules/apartments/store';
import { IModalBase } from '../../../../../../../modules/commonTypes';
import { TestID } from '../../testIds';
import { AAUserName } from '../AAUserName';
import messages from './messages';

interface IShowPinModalProps extends IModalBase {
  apartmentIdsInfo: IApartmentIdsInfo;
  user: ApartmentUser;
}
export function ShowPinModal({
  apartmentIdsInfo: { apartmentId, companyId, siteId },
  onClose,
  open,
  user,
}: IShowPinModalProps) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { data, isFetching } = useSelector(getUserPinSelector);

  const onInitHandler = () => {
    dispatch(getUserPin(companyId, siteId, apartmentId, user.id));
  };

  return (
    <InfoModal
      buttonText={formatMessage(messages.AAmodalShowPINClose)}
      muiDialogProps={{ maxWidth: 'xs' }}
      onClose={onClose}
      testId={TestID.ShowPinModal}
      open={open}
      title={formatMessage(messages.AAmodalShowPINTitle)}
    >
      <Stack spacing={2} pb={1}>
        <Typography variant="body2">
          <FormattedMessage {...messages.AAmodalShowPINText1} />
        </Typography>
        <Typography variant="body2">
          <FormattedMessage {...messages.AAmodalShowPINText2} />
        </Typography>
      </Stack>
      <Stack spacing={2} pb={2}>
        <AAUserName user={user} />
        <PinPanel
          isReadOnly
          pin={data?.pin}
          onInit={onInitHandler}
          onGenerate={undefined}
          isFetching={isFetching}
          hasError={false}
        />
      </Stack>
    </InfoModal>
  );
}
