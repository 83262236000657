import { useSelector } from 'react-redux';
import { selectors } from '../../../modules/accessSettings';

export function useGetDeviceFormData() {
  const { devices, isLoading } = {
    devices: useSelector(selectors.getAccessControlDevicesFiltered),
    isLoading: useSelector(selectors.isAddAccessGroupDevicesFetching),
  };

  return {
    devices,
    isLoading,
  };
}
