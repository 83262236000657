import { defineMessages } from 'react-intl';

export default defineMessages({
  apartmentFreeTooltipHeader: {
    defaultMessage: 'Free',
    description: 'Apartment Free Tooltip - header',
    id: 'apartmentFreeTooltip.header',
  },
  apartmentFreeTooltipText: {
    defaultMessage: 'Mobile Video is free for all apartments using only 2N devices (no mobile phones or tablets).',
    description: 'Apartment Free Tooltip - text',
    id: 'apartmentFreeTooltip.text',
  },
  FreeWithIntercomApartmentModelPopupText: {
    defaultMessage: 'Having a 2N intercom on the site grants' + 'a free Mobile Video license for one apartment',
    id: 'freeWithIntercom.apartmentModel.popup.text',
  },
  FreeWithIntercomApartmentModelPopupTitle: {
    defaultMessage: 'Free with intercom',
    id: 'freeWithIntercom.apartmentModel.popup.title',
  },
});
