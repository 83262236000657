import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';

const EllipsisText = ({ children }) => (
  <Box
    component="span"
    sx={{
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }}
  >
    {children}
  </Box>
);

EllipsisText.propTypes = {
  children: PropTypes.oneOfType([PropTypes.any, PropTypes.string]).isRequired,
};

export default EllipsisText;
