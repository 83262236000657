import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveIcon from '@mui/icons-material/RemoveCircle';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { EmptyValue } from '../EmptyValue';
import { TypographyWithEllipsisTooltip } from '../TypographyWithEllipsisTooltip';
import messages from './messages';

export interface IUserEmailProps {
  isEnabled: boolean;
  email?: string;
}

function UserEmail({ email, isEnabled }: IUserEmailProps) {
  if (!email)
    return (
      <Stack>
        <EmptyValue />
      </Stack>
    );

  return (
    <Stack direction="row" spacing={0.5} sx={{ maxWidth: '100%' }}>
      {isEnabled ? (
        <Tooltip title={<FormattedMessage {...messages.userEmailConfirmed} />}>
          <CheckCircleIcon color="success" fontSize="small" />
        </Tooltip>
      ) : (
        <Tooltip title={<FormattedMessage {...messages.userEmailUnconfirmed} />}>
          <RemoveIcon color="error" fontSize="small" />
        </Tooltip>
      )}
      <TypographyWithEllipsisTooltip variant="inherit" component="span">
        {email}
      </TypographyWithEllipsisTooltip>
    </Stack>
  );
}

export default UserEmail;
