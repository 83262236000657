import React from 'react';
import { useSelector } from 'react-redux';
import { UserAccessForm } from '../../../components';
import { getUserApartments } from '../../../helpers/userApartments';
import { selectors as apartmentsSelectors } from '../../../modules/apartments';
import { selectors } from '../../../modules/user';
import { IUserDetail } from '../../../modules/user/store';

function Access() {
  const { apartmentList, user, userMy2NAppAccessEnabledStatus, userPin, userRfidCard } = {
    apartmentList: useSelector(apartmentsSelectors.getApartmentsList),
    user: useSelector(selectors.getCurrentUserDetail),
    userMy2NAppAccessEnabledStatus: useSelector(selectors.getUserMy2NAppAccessEnabledStatus),
    userPin: useSelector(selectors.getUserPin),
    userRfidCard: useSelector(selectors.getUserRfidCard),
  };

  return (
    <UserAccessForm
      user={{
        ...user,
        apartments: getUserApartments({ apartmentList, userAssignedApartmentsIds: (user as IUserDetail).apartments }),
      }}
      userPin={userPin}
      userRfidCard={userRfidCard}
      userMy2NAppAccessEnabledStatus={userMy2NAppAccessEnabledStatus}
    />
  );
}

export default Access;
