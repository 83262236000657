import { defineMessages } from 'react-intl';

export default defineMessages({
  tabPanelAccessSettings: {
    defaultMessage: 'Access',
    description: 'Access settings label',
    id: 'tabPanel.accessSettings',
  },
  tabPanelCallSettings: {
    defaultMessage: 'Call settings',
    description: 'Call settings label',
    id: 'tabPanel.callSettings',
  },
  tabPanelDeviceDetail: {
    defaultMessage: 'Device detail',
    description: 'Device detail label',
    id: 'tabPanel.deviceDetail',
  },
  tabPanelDeviceDetailXs: {
    defaultMessage: 'Detail',
    description: 'Device detail label for small screens',
    id: 'tabPanel.deviceDetail.xs',
  },
  tabPanelPhonebooks: {
    defaultMessage: 'Phonebooks',
    description: 'Phonebooks label',
    id: 'tabPanel.phonebooks',
  },
  tabPanelSwitchCode: {
    defaultMessage: 'Switch codes',
    description: 'Switch codes label',
    id: 'tabPanel.switchCode',
  },
  tabPanelUnlockSettings: {
    defaultMessage: 'Unlock settings',
    description: 'Unlock settings label',
    id: 'tabPanel.unlockSettings',
  },
});
