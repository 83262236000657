import { defineMessages } from 'react-intl';

export default defineMessages({
  elevatorIntegrationModalActivateCancel: {
    defaultMessage: 'Cancel',
    id: 'elevatorIntegration.modalActivate.cancel',
  },
  elevatorIntegrationModalActivateSubmit: {
    defaultMessage: 'Activate',
    id: 'elevatorIntegration.modalActivate.submit',
  },
  elevatorIntegrationModalActivateText1: {
    defaultMessage:
      '<p>Activation will occur instantly and you can deactivate any time.</p>' +
      '<p>Make sure you have the integration fully configured.</p>',
    id: 'elevatorIntegration.modalActivate.text1',
  },
  elevatorIntegrationModalActivateTitle: {
    defaultMessage: 'Activate integration',
    id: 'elevatorIntegration.modalActivate.title',
  },
});
