import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

function LanguageSelectItem({ currentUserLang, isLoggedIn, lang, onClick, upSm }) {
  const isSelected = isEqual(lang, currentUserLang);

  function getItemLabel() {
    if (upSm) {
      return lang.nonTranslated;
    }

    if (isLoggedIn) {
      return lang.nonTranslated;
    }

    return lang.abbreviation;
  }

  return (
    <MenuItem
      onClick={onClick}
      selected={isSelected}
      sx={{
        '&.Mui-selected': {
          bgcolor: 'drawer.selected',
          color: 'primary.main',
        },
        py: 1,
      }}
    >
      <Typography variant="body2">{getItemLabel()}</Typography>
    </MenuItem>
  );
}

LanguageSelectItem.propTypes = {
  currentUserLang: PropTypes.shape({
    abbreviation: PropTypes.string,
    nonTranslated: PropTypes.string,
  }).isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  lang: PropTypes.shape({
    abbreviation: PropTypes.string,
    nonTranslated: PropTypes.string,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  upSm: PropTypes.bool.isRequired,
};

export default LanguageSelectItem;
