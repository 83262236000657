import MaterialLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { InfoModal } from '../../../components/_DesignSystemWrappers/Modal';
import { IModalBase } from '../../../modules/commonTypes';
import messages from './messages';

function HowToChangeCreditCardDialog({ onClose, open }: IModalBase) {
  const { formatMessage } = useIntl();
  return (
    <InfoModal
      buttonText={formatMessage(messages.HowToChangeCreditCardDialogClose)}
      onClose={onClose}
      open={open}
      title={formatMessage(messages.HowToChangeCreditCardDialogTitle)}
    >
      <Typography sx={{ mb: 1.5 }}>
        <FormattedMessage
          {...messages.HowToChangeCreditCardDialogText}
          values={{
            link: (chunks) =>
              <MaterialLink
                component={Link}
                to={"https://wiki.2n.com/faqmy2n/en/how-to-change-credit-card-information-104866375.html"}
              >
                {chunks}
              </MaterialLink>
          }}
        />
      </Typography>
    </InfoModal>
)}

export default HowToChangeCreditCardDialog;
