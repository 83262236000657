import { FE_ROLES, ROLES, SITE_ROLES_MAP } from '../config/consts';
import { FERoles } from '../modules/commonTypes';
import { CONTEXT } from '../modules/user';

export const isApartmentAdmin = (companies) =>
  !!companies?.find((company) =>
    company?.sites?.find((site) =>
      site?.apartments?.find((apartment) => apartment?.roles?.includes(ROLES.APARTMENT_ADMIN))
    )
  );

export const canUserDelete = (user, currentUser, context, siteIdsInfo = undefined) => {
  const currentUserRole = currentUser && siteIdsInfo && getCurrentRole(currentUser, siteIdsInfo, context);
  const userRole = user?.role;

  return !(
    user?.id === currentUser?.id ||
    (userRole === FERoles.COMPANY_ADMIN && context === CONTEXT.SITE_USERS) ||
    (context === CONTEXT.SITE_USERS &&
      userRole === FERoles.SITE_ADMIN_PAYMENT &&
      (currentUserRole === FERoles.SITE_ADMIN_PAYMENT || currentUserRole === FERoles.SITE_ADMIN))
  );
};

export const getCurrentRole = (data, validation, context) => {
  const company = data?.companies?.find((item) => item.id === validation.companyId);
  const siteRoles = company?.sites?.find((item) => item.id === validation.siteId)?.roles;
  const companyRoles = company?.roles;

  if (context === CONTEXT.COMPANY_ADMINS || companyRoles?.includes(ROLES.COMPANY_ADMIN)) {
    return FE_ROLES.COMPANY_ADMIN;
  }

  if (siteRoles?.includes(ROLES.ADMIN) && companyRoles?.includes(ROLES.MOBILE_VIDEO_BUYER)) {
    return FE_ROLES.SITE_ADMIN_PAYMENT;
  }

  if (siteRoles?.includes(ROLES.ADMIN)) {
    return FE_ROLES.SITE_ADMIN;
  }

  return FE_ROLES.SITE_USER;
};

export const onSetRole = (setRole, userRole, data, validation, context) => {
  const role = getCurrentRole(data, validation, context);
  if (role && userRole && role !== userRole) {
    setRole(data.id, {
      grantedRoles: {
        companyRoles: SITE_ROLES_MAP?.[role]?.[userRole].grantedRoles.companyRoles.length
          ? [
              {
                companyId: validation.companyId,
                types: SITE_ROLES_MAP?.[role]?.[userRole].grantedRoles.companyRoles,
              },
            ]
          : [],
        siteRoles: SITE_ROLES_MAP?.[role]?.[userRole].grantedRoles.siteRoles.length
          ? [
              {
                siteId: validation.siteId,
                types: SITE_ROLES_MAP?.[role]?.[userRole].grantedRoles.siteRoles,
              },
            ]
          : [],
      },
      revokedRoles: {
        companyRoles: SITE_ROLES_MAP?.[role]?.[userRole].revokedRoles.companyRoles.length
          ? [
              {
                companyId: validation.companyId,
                types: SITE_ROLES_MAP?.[role]?.[userRole].revokedRoles.companyRoles,
              },
            ]
          : [],
        siteRoles: SITE_ROLES_MAP?.[role]?.[userRole].revokedRoles.siteRoles.length
          ? [
              {
                siteId: validation.siteId,
                types: SITE_ROLES_MAP?.[role]?.[userRole].revokedRoles.siteRoles,
              },
            ]
          : [],
      },
    });
  }
};

export const isAdmin = (role) =>
  role === FE_ROLES.COMPANY_ADMIN || role === FE_ROLES.SITE_ADMIN || role === FE_ROLES.SITE_ADMIN_PAYMENT;
