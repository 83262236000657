import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { useUrlParams } from '../../../helpers/urlParams';
import { getUserValidationSchema } from '../../../validations/createUserValidationSchema';

export function useGetGlobalHooks() {
  const { companyId, siteId } = useUrlParams();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const formMethods = useForm({
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
    },
    mode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        ...getUserValidationSchema(companyId, siteId, formatMessage),
      })
    ),
    shouldUnregister: true,
  });

  return {
    dispatch,
    formatMessage,
    formMethods,
  };
}
