import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import RowMenuItem from '../../DataGrid/RowMenuItem';
import messages from './messages';

const DeleteApartment = ({ apartmentId, disabled, onDeleteApartment }) => (
  <RowMenuItem
    colorIcon="error.dark"
    colorText="error.dark"
    disabled={disabled}
    icon={<DeleteIcon />}
    label={<FormattedMessage {...messages.apartmentRowMenuDeleteApartment} />}
    onClick={() => onDeleteApartment(apartmentId)}
  />
);

DeleteApartment.propTypes = {
  apartmentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  disabled: PropTypes.bool.isRequired,
  onDeleteApartment: PropTypes.func.isRequired,
};

export default DeleteApartment;
