import React, { useEffect, useState } from 'react';
import { Paper, Title } from '../../components';
import { useGetGlobalHooks } from './hooks/getGlobalHooks';
import messages from './messages';
import { MvSdkSettingsDefault } from './MvSdkSettingsDefault';
import { IMvSdkSettingsFormData, MvSdkSettingsForm } from './MvSdkSettingsForm';

function MvSdkSettings() {
  const { companyActions, data, dispatch, formatMessage, formMethods, isDefault, isLoading } = useGetGlobalHooks();
  const [isCustom, setIsCustom] = useState(!isDefault);
  const [isOpenResetDialog, setIsOpenResetDialog] = useState(false);

  useEffect(() => {
    setIsCustom(!isDefault);
    formMethods.reset({
      authKey: data.authKey,
      bundleId: data.bundleId,
      databaseName: data.databaseName,
      keyId: data.keyId,
      serviceJson: data.serviceJson,
      teamId: data.teamId,
    });
  }, [isDefault]);

  useEffect(() => {
    dispatch(companyActions.getMvSdkSettings());
  }, []);

  const submitForm = (values: IMvSdkSettingsFormData) => {
    const val = {
      apnConfiguration: {
        authKey: values.authKey,
        bundleId: values.bundleId,
        keyId: values.keyId,
        teamId: values.teamId,
      },
      fcmConfiguration: {
        databaseName: values.databaseName,
        serviceJson: JSON.parse(values.serviceJson),
      },
    };
    dispatch(companyActions.updateMvSdkSetings(val));
  };

  const handleResetSettings = () => {
    setIsOpenResetDialog(false);
    dispatch(companyActions.resetMvSdkSettings());
    setIsCustom(false);
    formMethods.reset();
  };

  return (
    <>
      <Title heading={formatMessage(messages.mvSdkSettingsTitle)} />
      <Paper>
        {!isLoading &&
          (isCustom ? (
            <MvSdkSettingsForm
              formMethods={formMethods}
              handleResetSettings={handleResetSettings}
              isCustom={isCustom}
              isOpenResetDialog={isOpenResetDialog}
              setIsOpenResetDialog={setIsOpenResetDialog}
              submitForm={submitForm}
            />
          ) : (
            <MvSdkSettingsDefault setIsCustom={setIsCustom} />
          ))}
      </Paper>
    </>
  );
}

export default MvSdkSettings;
