import InfoIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import React from 'react';

function InfoIconButton({ onClick }: { onClick: () => void }) {
  return (
    <IconButton data-testid="cardReaderInfoIconButton" onClick={onClick} size="small">
      <InfoIcon />
    </IconButton>
  );
}

export default InfoIconButton;
