import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReadOnlyField } from '../../../../components';
import messages from './messages';

const SerialNumberField = ({ value }) => (
  <Box pb={2}>
    <ReadOnlyField label={<FormattedMessage {...messages.Label} />} value={value} />
  </Box>
);

SerialNumberField.propTypes = {
  value: PropTypes.string.isRequired,
};

export default SerialNumberField;
