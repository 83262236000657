import TemplateIcon from '@mui/icons-material/TableView';
import Button from '@mui/material/Button';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { USERS_IMPORT_TEMPLATE } from '../../../config/address';
import messages from './messages';

function TemplateButton() {
  return (
    <Button href={`${process.env.PUBLIC_URL}${USERS_IMPORT_TEMPLATE}`} startIcon={<TemplateIcon />} variant="outlined">
      <FormattedMessage {...messages.siteUsersImportIntroStepTemplateButton} />
    </Button>
  );
}

export default TemplateButton;
