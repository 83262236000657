import NewReleasesIcon from '@mui/icons-material/NewReleases';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { Alert, PricingModal } from '../../components';
import { COOKIES, FUNCTIONAL_COOKIES } from '../../config/consts';
import { useCookie } from '../../helpers/cookies';
import { useAppContext } from '../App/AppContextProvider';
import messages from './messages';

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const AlertBoxFreeDevices = ({ currency, userId }) => {
  const query = useQuery();
  const { feSiteRoles } = useAppContext();
  const cookieName = FUNCTIONAL_COOKIES.INFO_PANEL.concat(userId);
  const { getCookie, setFunctionalCookie } = useCookie();
  const [isAlertCookie, setAlertCookie] = useState(!!getCookie(cookieName)?.[COOKIES.ALERTBOX]);
  const [openPricingModal, setOpenPricingModal] = useState(query.has('pricing'));

  const handlePricingModal = () => {
    setOpenPricingModal((prev) => !prev);
  };

  const handleCloseAlertBox = () => {
    setFunctionalCookie(cookieName, { ...getCookie(cookieName), [COOKIES.ALERTBOX]: true });
    setAlertCookie(true);
  };

  return (
    <>
      {!isAlertCookie && (
        <Alert
          action={
            feSiteRoles.isSiteAdminPayment ? (
              <Button onClick={handlePricingModal} size="small">
                <FormattedMessage {...messages.alertBoxFreeDevicesSeeNewPricing} />
              </Button>
            ) : null
          }
          icon={<NewReleasesIcon />}
          onClose={handleCloseAlertBox}
          severity="success"
          title={<FormattedMessage {...messages.alertBoxFreeDevicesTitle} />}
        >
          <FormattedMessage {...messages.alertBoxFreeDevicesSubtitle} />
        </Alert>
      )}
      <PricingModal
        currency={currency}
        isOpenPricingModal={openPricingModal}
        onClosePricingModal={handlePricingModal}
      />
    </>
  );
};

AlertBoxFreeDevices.propTypes = {
  currency: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
};

export default AlertBoxFreeDevices;
