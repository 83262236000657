import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import useBreakpoints from '../../helpers/useBreakpoints';
import { ApartmentAndAccessSettingsInfo, IApartmentAndAccessSettingsInfoProps } from './ApartmentAndAccessSettingsInfo';
import { IUserTitleProps } from './data';
import { IRemoveButtonProps, RemoveButton } from './RemoveButton';
import { IUserInfoProps, UserInfo } from './UserInfo';

export function UserTitle(props: IUserTitleProps) {
  const { downSm } = useBreakpoints();

  const userInfoData: IUserInfoProps = {
    firstName: props.user.firstName,
    lastName: props.user.lastName,
    onBackButtonClick: props.onBackButtonClick,
    role: props.user.role,
  };
  const removeButtonData: IRemoveButtonProps = {
    siteIdsInfo: props.deleteUserButtonData?.siteIdsInfo,
    user: props.user,
  };

  const ApartmentInfoData: IApartmentAndAccessSettingsInfoProps = {
    apartments: props.apartments,
    hasUserInfoBackButton: !!props.onBackButtonClick,
    isApartmentChipLinkDisabled: props.isApartmentChipLinkDisabled,
    userAccessDetails: props.userAccessDetails,
  };

  return (
    <Box pb={2}>
      {downSm ? (
        <>
          <Stack alignItems="center" direction="row">
            <UserInfo {...userInfoData} />
            {props.hasDeleteUserButton && <RemoveButton {...removeButtonData} />}
          </Stack>
          <ApartmentAndAccessSettingsInfo {...ApartmentInfoData} />
        </>
      ) : (
        <Stack alignItems="center" direction="row">
          <UserInfo {...userInfoData} />
          <ApartmentAndAccessSettingsInfo {...ApartmentInfoData} />
          {props.hasDeleteUserButton && <RemoveButton {...removeButtonData} />}
        </Stack>
      )}
    </Box>
  );
}
