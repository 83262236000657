import { defineMessages } from 'react-intl';

export default defineMessages({
  DeviceDetailRemoteConfigStatusChangePassword: {
    defaultMessage: 'Change password',
    id: 'deviceDetail.remoteConfig.status.changePassword',
  },
  remoteConfigurationServiceActivationDisable: {
    defaultMessage: 'Do you want to deactivate Remote Configuration service for this device?',
    description: 'Configuration service modal - disable info',
    id: 'remoteConfigurationService.activation.disable',
  },
  remoteConfigurationServiceActivationEnable: {
    defaultMessage: 'Do you want to activate Remote Configuration service for this device?',
    description: 'Configuration service modal - enable info',
    id: 'remoteConfigurationService.activation.enable',
  },
  remoteConfigurationServiceActivationTitleDisable: {
    defaultMessage: 'Deactivate Remote Configuration',
    description: 'Configuration service title modal - disable info',
    id: 'remoteConfigurationService.activationTitle.disable',
  },
  remoteConfigurationServiceActivationTitleEnable: {
    defaultMessage: 'Activate Remote Configuration',
    description: 'Configuration service title modal - enable info',
    id: 'remoteConfigurationService.activationTitle.enable',
  },
  remoteConfigurationServiceButtonsOpenConfiguration: {
    defaultMessage: 'Open configuration',
    description: 'Open configuration button',
    id: 'remoteConfigurationService.buttons.openConfiguration',
  },
  remoteConfigurationServiceButtonsRenewDeviceServiceHelperText: {
    defaultMessage: "Remote Configuration service in case doesn't work properly. ",
    description: 'Renew device service helper text',
    id: 'remoteConfigurationService.buttons.renewDeviceService.helperText',
  },
  remoteConfigurationServiceButtonsRenewDeviceServiceSettings: {
    defaultMessage: 'Renew device service settings',
    description: 'Renew device service settings',
    id: 'remoteConfigurationService.buttons.renewDeviceServiceSettings',
  },
  remoteConfigurationServiceModalCancelButton: {
    defaultMessage: 'Cancel',
    description: 'Configuration service modal - cancel button',
    id: 'remoteConfigurationService.modal.cancelButton',
  },
  remoteConfigurationServiceModalConfirmButton: {
    defaultMessage: 'Ok',
    description: 'Configuration service modal - confirm button',
    id: 'remoteConfigurationService.modal.confirmButton',
  },
  remoteConfigurationServiceModalText: {
    defaultMessage:
      'Are you sure you want to renew part of the device service settings' +
      ' related to the Remote Configuration Service?',
    description: 'Configuration service modal - text',
    id: 'remoteConfigurationService.modal.text',
  },
  remoteConfigurationServiceRemoteConfiguration: {
    defaultMessage: '2N<sup>®</sup> Remote Configuration Services',
    description: 'Configuration service - remote configuration',
    id: 'remoteConfigurationService.remoteConfiguration',
  },
  remoteConfigurationServiceStatusActive: {
    defaultMessage: 'Active',
    description: 'Status active',
    id: 'remoteConfigurationService.status.active',
  },
  remoteConfigurationServiceStatusNotActive: {
    defaultMessage: 'Inactive',
    description: 'Status not active',
    id: 'remoteConfigurationService.status.notActive',
  },
  remoteConfigurationStatusLabel: {
    defaultMessage: 'Status info',
    description: 'Remote configuration label',
    id: 'remoteConfigurationStatus.label',
  },
});
