import Box from '@mui/material/Box';
import { lighten } from '@mui/material/styles';
import { connect } from 'formik';
import PropTypes from 'prop-types';
import React, { createRef, useEffect } from 'react';

const styles = {
  error: {
    background: (theme) => lighten(theme.palette.error.main, 0.88),
    color: 'error.main',
  },
  root: {
    mx: 0,
    my: 0.5,
    p: 2,
  },
  success: {
    background: (theme) => lighten(theme.palette.success.main, 0.88),
    color: 'success.main',
  },
  warning: {
    background: (theme) => lighten(theme.palette.warning.main, 0.88),
    color: 'warning.main',
  },
};

export const TYPE = {
  ERROR: 'error',
  SUCCESS: 'success',
  WARNING: 'warning',
};

const StatusMessage = ({ formik }) => {
  const ref = createRef();
  useEffect(() => {
    if (ref.current !== null) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [ref]);

  const { status } = formik;
  if (!status || !status.msg) {
    return null;
  }

  return (
    <Box
      ref={ref}
      sx={{
        ...styles.root,
        ...(status.type === TYPE.SUCCESS && styles.success),
        ...(status.type === TYPE.WARNING && styles.warning),
        ...((status.type === TYPE.ERROR || !status.type) && styles.error),
      }}
    >
      <span>{status.msg}</span>
    </Box>
  );
};

StatusMessage.propTypes = {
  formik: PropTypes.shape({
    status: PropTypes.shape({
      msg: PropTypes.string,
      type: PropTypes.oneOf([TYPE.SUCCESS, TYPE.WARNING, TYPE.ERROR]),
    }),
  }).isRequired,
};

export default connect(StatusMessage);
