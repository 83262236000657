import { defineMessages } from 'react-intl';

export default defineMessages({
  SiteUsersImportAlertStatusAborted: {
    defaultMessage: '{count, plural, one {# was} other {# were}} canceled at row {row}. ',
    description: 'Site users import alert - aborted',
    id: 'siteUsersImportAlertStatusImportedAborted',
  },
  SiteUsersImportAlertStatusAlertProcessingContent: {
    defaultMessage:
      'A data import is in progress in this site. Only one import at a time is ' +
      'allowed for security reasons. <a>View progress</a>',
    description: '',
    id: 'siteUsersImportAlertProcessingContent',
  },
  SiteUsersImportAlertStatusFailed: {
    defaultMessage: '{count} couldn’t be imported. ',
    description: 'Site users import alert - failed',
    id: 'siteUsersImportAlertStatusImportedFailed',
  },
  SiteUsersImportAlertStatusImported: {
    defaultMessage: '{count, plural, one {# user was} other {# users were}} imported. ',
    description: 'Site users import alert - imported',
    id: 'siteUsersImportAlertStatusImported',
  },
  SiteUsersImportAlertStatusLastImportTookPlace: {
    defaultMessage: 'The last import took place on {lastImportIn}',
    description: 'Last import step - last import took place',
    id: 'lastImportStep.lastImportTookPlace',
  },
  SiteUsersImportAlertStatusModalButton: {
    defaultMessage: 'Close',
    description: 'Site users import alert - modal button',
    id: 'siteUsersImportAlertStatusModalButton',
  },
  SiteUsersImportAlertStatusModalTitle: {
    defaultMessage: 'Last import report',
    description: 'Site users import alert - modal title',
    id: 'siteUsersImportAlertStatusModalTitle',
  },
  SiteUsersImportAlertStatusNoUsersImported: {
    defaultMessage: 'No users were imported. ',
    description: 'Site users import alert - no users imported',
    id: 'siteUsersImportAlertStatusNoUsersImported',
  },
  SiteUsersImportAlertStatusSeeResults: {
    defaultMessage: 'See results',
    description: 'Site users import alert success - See results',
    id: 'siteUsersImportAlertStatusSeeResults',
  },
});
