import { AccountCircle, Person, PersonOutline, SvgIconComponent } from '@mui/icons-material';
import DialpadIcon from '@mui/icons-material/Dialpad';
import React, { ComponentProps } from 'react';
import { FE_ROLES } from '../../config/consts';
import { ApartmentUserRole } from '../../modules/apartmentAdmin/data';
import { IUser } from '../../modules/user/store';
import { ApartmentAdminIcon } from '../ApartmentAdminIcons/ApartmentAdminIcon';

interface IUserIcon extends ComponentProps<SvgIconComponent> {
  userRole: IUser['role'] | ApartmentUserRole;
}

export function UserIcon({ userRole, ...props }: IUserIcon) {
  switch (userRole) {
    case FE_ROLES.LOGIN_LESS:
    case ApartmentUserRole.APARTMENT_LOGIN_LESS:
      return <PersonOutline {...props} />;
    case FE_ROLES.GUEST:
      return <DialpadIcon />;
    case FE_ROLES.APARTMENT_GUEST:
      return <DialpadIcon />;
    case FE_ROLES.SITE_USER:
    case ApartmentUserRole.APARTMENT_USER:
      return <Person {...props} />;
    case ApartmentUserRole.APARTMENT_ADMIN:
      return <ApartmentAdminIcon {...props} />;
    default:
      return <AccountCircle {...props} />;
  }
}

export default UserIcon;
