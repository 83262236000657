import {
  IDeviceElevatorIntegration,
  IElevatorIntegrationFloorMapping,
  IUserElevatorIntegration,
} from '../../api/elevatorIntegrationTS';
import { IRootStore } from '../store';

export const isSiteElevatorIntegrationActive = ({ elevatorIntegration }: IRootStore): boolean =>
  !!elevatorIntegration.site?.data?.buildingIds?.length;

export const getUserElevatorIntegrationData = ({
  elevatorIntegration,
}: IRootStore): IUserElevatorIntegration | undefined => elevatorIntegration.user?.data;

export const isUserElevatorIntegrationInvalid = ({ elevatorIntegration }: IRootStore): boolean =>
  elevatorIntegration.user.didInvalidate;

export const isDeviceElevatorIntegrationInvalid = ({ elevatorIntegration }: IRootStore): boolean =>
  elevatorIntegration.device.didInvalidate;

export const isDeviceElevatorIntegrationActive = ({ elevatorIntegration }: IRootStore): boolean | undefined =>
  elevatorIntegration.device?.data?.integrationActive;

export const getDeviceElevatorIntegrationData = ({
  elevatorIntegration,
}: IRootStore): IDeviceElevatorIntegration | undefined => elevatorIntegration.device?.data;

export const isSiteElevatorIntegrationFloorMappingFetching = ({ elevatorIntegration }: IRootStore): boolean =>
  elevatorIntegration.site.isFetching;

export const getSiteElevatorIntegrationFloorMappingFloors = ({
  elevatorIntegration,
}: IRootStore): IElevatorIntegrationFloorMapping['floors'] => {
  const { data } = elevatorIntegration.site.floorMapping;

  return data?.floors || [];
};

export const getBuildingTopologyBuildings = ({
  elevatorIntegration,
}: IRootStore): {
  id: string;
  elevatorGroups: {
    id: string;
    name: string;
    destinations: {
      areaId: string;
      shortName: string;
    }[];
  }[];
}[] => elevatorIntegration.site.floorMapping.data?.buildingTopology?.buildings || [];

export const getFloorMappingFloors = ({
  elevatorIntegration,
}: IRootStore): {
  floorId: string;
  mappings: { buildingId: string; areaId: string; elevatorGroupId: string }[];
}[] => elevatorIntegration.site.floorMapping.data?.floorMapping.floors || [];
