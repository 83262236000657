import TextField from '@mui/material/TextField';
import React from 'react';
import { TEXT_FIELD } from '../../../config/devices';

export interface ITextFieldRowData {
  handleChangeName: (event: React.ChangeEvent<{ value: unknown }>) => void;
  placeholder: string;
  value?: string;
}

export function TextFieldRow(data: ITextFieldRowData): JSX.Element {
  return (
    <TextField
      inputProps={{
        maxLength: TEXT_FIELD.CHARACTER_LIMIT,
      }}
      name="contact"
      onChange={(event) => data.handleChangeName(event)}
      placeholder={data.placeholder}
      sx={{
        pl: 0,
        pr: 3,
        py: 1,
      }}
      value={data.value ?? ''}
      variant="outlined"
    />
  );
}

export default TextFieldRow;
