import { ROLES } from '../../config/consts';
import { isMVCardActive } from './helpers';

const isLicensesCardActive = (userRoles) =>
  userRoles.includes(ROLES.IP_INTERCOM_BUYER) && userRoles.includes(ROLES.GREEN_ADMIN);

export const isDisabledMobileVideoLicense = (isMobileVideoAllowed, userRoles, isVatVerified) =>
  !isMobileVideoAllowed || !isMVCardActive(userRoles) || !isVatVerified;

export const isVisibleMobileVideoLicense = (feSiteRoles) => feSiteRoles.isSiteAdminPayment;

export const isDisabledDeviceLicense = (userRoles, isVatVerified) => !isLicensesCardActive(userRoles) || !isVatVerified;

export const isVisibleDeviceLicense = (feSiteRoles) => feSiteRoles.isCompanyAdmin;

export const isVisiblePromoCodes = (userRoles, feSiteRoles, isVatVerified) =>
  isLicensesCardActive(userRoles) && feSiteRoles.isCompanyAdmin && isVatVerified;
