import { Switch, Tooltip } from '@mui/material';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import actions from '../../../modules/apartments';
import { IApartmentDetail, IFloor } from '../../../modules/apartments/store';
import { MobileVideoStatus, MobileVideoType, TId } from '../../../modules/commonTypes';
import { IMVLicenceModel } from '../../../modules/sites/store';
import { editApartmentValues } from './helpers';
import messages from './messages';

export interface IPaidServicesSwitch {
  apartmentValue: IApartmentDetail;
  companyId: TId;
  floors: Array<IFloor>;
  licenceModel: IMVLicenceModel;
  onOpenActivatePaidServicesModal: () => void;
  setApartmentValue: (apartment: IApartmentDetail) => void;
  siteId: TId;
}

function PaidServicesSwitch({
  apartmentValue,
  companyId,
  floors,
  licenceModel,
  onOpenActivatePaidServicesModal,
  setApartmentValue,
  siteId,
}: IPaidServicesSwitch) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const { licencesLimit, paidCount, status, type } = licenceModel;
  const isChecked = apartmentValue.paidServicesActive;
  const isMVTypeStandard = type === MobileVideoType.STANDARD;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.checked && !sessionStorage.getItem('paidModal') && isMVTypeStandard && paidCount >= licencesLimit
      ? onOpenActivatePaidServicesModal()
      : dispatch(
          actions.editApartment(
            companyId,
            siteId,
            apartmentValue,
            floors,
            editApartmentValues(!isChecked, apartmentValue)
          )
        );
    setApartmentValue(apartmentValue);
  };

  const tooltipTitleText = useMemo(() => {
    if (!isMVTypeStandard) {
      return isChecked
        ? messages.paidServicesRowMenuTooltipDisableActivableAndExpirable
        : messages.paidServicesRowMenuTooltipEnableActivableAndExpirable;
    }
    if (status === MobileVideoStatus.TRIAL_ACTIVE) {
      return isChecked
        ? messages.paidServicesRowMenuTooltipDisableTrial
        : messages.paidServicesRowMenuTooltipEnableTrial;
    } else {
      return isChecked ? messages.PaidServicesRowMenuTooltipDisable : messages.PaidServicesRowMenuTooltipEnable;
    }
  }, [isChecked, isMVTypeStandard, status]);

  return (
    <Tooltip title={formatMessage(tooltipTitleText, { br: <br /> })} placement="right-start">
      <span>
        <Switch checked={isChecked} onChange={(event) => handleChange(event)} />
      </span>
    </Tooltip>
  );
}

export default PaidServicesSwitch;
