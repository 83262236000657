import CheckIcon from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ActionModal from '../../../../components/_DesignSystemWrappers/Modal/ActionModal';
import messages from '../messages';

function ConfirmationModal({ onClose, onSubmit }) {
  const { formatMessage } = useIntl();

  return (
    <ActionModal
      icon={<CheckIcon />}
      isPrimaryButtonDisabled={false}
      muiDialogProps={{ maxWidth: 'xs' }}
      onClose={onClose}
      open
      primaryButtonAction={() => {
        onSubmit();
        onClose();
      }}
      primaryButtonText={formatMessage(messages.siteLanguageConfirmationModalSubmit)}
      secondaryButtonText={formatMessage(messages.siteLanguageConfirmationModalCancel)}
      title={formatMessage(messages.siteLanguageConfirmationModalTitle)}
    >
      <Typography sx={{ pb: 8, pt: 2 }} variant="body1">
        <FormattedMessage {...messages.siteLanguageConfirmationModalContent} />
      </Typography>
    </ActionModal>
  );
}

ConfirmationModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ConfirmationModal;
