import QrCodeIcon from '@mui/icons-material/QrCode';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React from 'react';

const QrButton = ({ isIcon, label, onClick }) =>
  isIcon ? (
    <IconButton color="primary" onClick={onClick} sx={{ left: 5 }}>
      <QrCodeIcon />
    </IconButton>
  ) : (
    <Button color="primary" onClick={onClick} startIcon={<QrCodeIcon />} sx={{ left: 5 }}>
      {label}
    </Button>
  );

QrButton.propTypes = {
  isIcon: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default QrButton;
