import { defineMessages } from 'react-intl';

export default defineMessages({
  createUserModalCancel: {
    defaultMessage: 'Cancel',
    description: 'Create user modal - cancel button',
    id: 'AAmodal.newUser.cancel',
  },
  createUserModalEmailHelperText: {
    defaultMessage: 'The email is important, please check it',
    description: 'Create user modal - email helper text',
    id: 'AAmodal.newUser.mailHelper',
  },
  createUserModalEmailLabel: {
    defaultMessage: 'User email',
    description: 'Create user modal - email label',
    id: 'AAmodal.newUser.mailLabel',
  },
  createUserModalFirstNameLabel: {
    defaultMessage: 'First name',
    description: 'Create user modal - first name label',
    id: 'AAmodal.newUser.firstnameLabel',
  },
  createUserModalLastNameLabel: {
    defaultMessage: 'Last name',
    description: 'Create user modal - surname label',
    id: 'AAmodal.newUser.surnameLabel',
  },
  createUserModalMVLabel: {
    defaultMessage: 'Activate Mobile Video',
    description: 'Create user modal - activate mobile video label',
    id: 'AAmodal.newUser.activeMVlabel',
  },
  createUserModalMVLimitExceeded: {
    defaultMessage: 'Limit of mobile devices with Mobile Video reached',
    id: 'AAmodal.newUser.MVlimitReached',
  },
  createUserModalMVUnlicensed: {
    defaultMessage: 'Mobile Video service is not available for this apartment. Contact your facility manager.',
    id: 'AAmodal.newUser.MVnotAvailable',
  },
  createUserModalSubmit: {
    defaultMessage: 'Create user',
    description: 'Create user modal - submit button',
    id: 'AAmodal.newUser.submit',
  },
  createUserModalTitle: {
    defaultMessage: 'Create new user',
    description: 'Create user modal - title',
    id: 'AAmodal.newUser.title',
  },
});
