import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactSVG } from 'react-svg';
import My2LogoIcon from '../../assets/my2LogoIcon.svg';
import TechSupportIcon from '../../assets/techSupportIcon.svg';
import { PATHS } from '../../config/consts';
import { useGetActualCompanyId } from '../../hooks/companyHooks';
import { useGetActualSiteId } from '../../hooks/siteHooks';
import messages from './messages';
import SidebarItem from './SidebarItem';

const CommonList = ({ onCloseSidebarMobile }: { onCloseSidebarMobile: () => void }) => {
  const companyId = useGetActualCompanyId();
  const siteId = useGetActualSiteId();

  return (
    <List>
      <Box color="secondary.main" pb={2} pt={4}>
        <SidebarItem
          activePaths={[PATHS.ABOUT, PATHS.ABOUT_SITE]}
          icon={<ReactSVG src={My2LogoIcon} wrapper="span" />}
          id="about-link"
          link={
            siteId
              ? PATHS.ABOUT_SITE.replace(':companyId', companyId).replace(':siteId', siteId)
              : PATHS.ABOUT.replace(':companyId', companyId)
          }
          onClick={onCloseSidebarMobile}
          title={<FormattedMessage {...messages.SidebarAbout} />}
          disabled={false}
          showOnlyTitle={false}
          showSelected
          showTitleOnHover
        />
        <SidebarItem
          activePaths={[PATHS.SUPPORT, PATHS.SUPPORT_SITE]}
          icon={<ReactSVG src={TechSupportIcon} wrapper="span" />}
          id="help-link"
          link={
            siteId
              ? PATHS.SUPPORT_SITE.replace(':companyId', companyId).replace(':siteId', siteId)
              : PATHS.SUPPORT.replace(':companyId', companyId)
          }
          onClick={onCloseSidebarMobile}
          title={<FormattedMessage {...messages.SidebarHelp} />}
          disabled={false}
          showOnlyTitle={false}
          showSelected
          showTitleOnHover
        />
        <SidebarItem
          activePaths={[
            PATHS.GTC_PAGES_COMPANY_CONTEXT,
            PATHS.GTC_PAGE_COMPANY_CONTEXT_DETAIL,
            PATHS.GTC_PAGE_COMPANY_CONTEXT_DETAIL_BASIC,
          ]}
          icon={<PlaylistAddCheckIcon />}
          id="legal-link"
          link={PATHS.GTC_PAGES_COMPANY_CONTEXT.replace(':companyId', companyId)}
          onClick={onCloseSidebarMobile}
          title={<FormattedMessage {...messages.SidebarLegalDocuments} />}
          disabled={false}
          showOnlyTitle={false}
          showSelected
          showTitleOnHover
        />
      </Box>
    </List>
  );
};

export default CommonList;
