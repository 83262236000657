import { Box, Skeleton } from '@mui/material';
import React from 'react';
import { IApartmentAdminUserDetailInfo } from '../../../../../apiRtk/dataTransform/AATranforms';
import { UserTitle } from '../../../../../components/UserTitle';
import { skeletonTitleWidth } from '../../../../../cssConstants';
import { UserCredentials } from '../../../../../modules/commonTypes';

export interface IUserTitleWithSkeletonProps {
  userDetailInfo: IApartmentAdminUserDetailInfo | undefined;
  isFetching?: boolean;
  onBackButtonClick: () => void;
}

export function UserTitleWithSkeleton({ isFetching, onBackButtonClick, userDetailInfo }: IUserTitleWithSkeletonProps) {
  if (isFetching || userDetailInfo === undefined) {
    return (
      <Box height={64}>
        <Skeleton width={skeletonTitleWidth} height={48} />
      </Box>
    );
  }
  const user = userDetailInfo.user;
  const apartment = userDetailInfo.apartmentInfo;

  return (
    <UserTitle
      user={{ firstName: user.firstName, id: user.id, lastName: user.lastName, role: user.role }}
      apartments={{
        count: 1,
        id: apartment.id,
        name: apartment.name || '',
        number: apartment.number,
      }}
      userAccessDetails={{
        hasPin: user.credentials.indexOf('PIN') > -1,
        hasRfid: user.credentials.indexOf('RFID') > -1,
        hiddenStatuses: [UserCredentials.BT],
      }}
      hasDeleteUserButton={false}
      isApartmentChipLinkDisabled={true}
      onBackButtonClick={onBackButtonClick}
    />
  );
}
