import PropTypes from 'prop-types';
import React from 'react';
import { MOBILE_VIDEO_CODE } from '../../../../config/consts';
import VerificationErrorAlert from './VerificationErrorAlert';
import VerificationIsExpiredAlert from './VerificationIsExpiredAlert';
import VerificationIsUsedAlert from './VerificationIsUsedAlert';

const VerificationErrorInfo = ({ activationCodeError }) => {
  const isNotFound = () => {
    if (activationCodeError?.status === 404) {
      return true;
    }

    if (activationCodeError?.data?.code && activationCodeError.data?.message) {
      if (activationCodeError?.data?.code === MOBILE_VIDEO_CODE.STATE.NOT_FOUND.CODE) {
        return true;
      }
    }

    return false;
  };

  const isUsed = () => {
    if (activationCodeError?.status === 410 && activationCodeError?.data?.state === MOBILE_VIDEO_CODE.STATE.USED) {
      return true;
    }

    return false;
  };

  const isExpired = () => {
    if (activationCodeError?.status === 410 && activationCodeError?.data?.state !== MOBILE_VIDEO_CODE.STATE.USED) {
      return true;
    }

    return false;
  };

  return (
    <>
      {isNotFound() && <VerificationErrorAlert />}
      {isUsed() && (
        <VerificationIsUsedAlert
          errorCompanyName={activationCodeError?.data?.context?.company?.name}
          errorSiteName={activationCodeError?.data?.context?.site?.name}
        />
      )}
      {isExpired() && <VerificationIsExpiredAlert />}
    </>
  );
};

VerificationErrorInfo.propTypes = {
  activationCodeError: PropTypes.shape({
    data: PropTypes.shape({
      code: PropTypes.string,
      context: PropTypes.shape({
        company: PropTypes.shape({
          name: PropTypes.string,
        }),
        site: PropTypes.shape({
          name: PropTypes.string,
        }),
      }),
      message: PropTypes.string,
      state: PropTypes.string,
    }),
    status: PropTypes.number,
  }).isRequired,
};

export default VerificationErrorInfo;
