import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PATHS } from '../../config/consts';
import { useUrlParams } from '../../helpers/urlParams';
import ButtonLink from '../ButtonLink';
import messages from './messages';
import { styles } from './styles/content';

const NoPaymentsContent = () => {
  const { companyId, siteId } = useUrlParams();
  return (
    <Grid alignItems="center" container direction="column" spacing={2} sx={styles.content}>
      <Grid item>
        <Typography sx={styles.textColor} variant="body2">
          <FormattedMessage {...messages.contentNoPaymentsContentPaymentInfo} />
        </Typography>
      </Grid>
      <Grid item>
        <Typography sx={styles.textColor} variant="body2">
          <FormattedMessage {...messages.contentNoPaymentsContentServiceInfo} />
        </Typography>
      </Grid>
      <Grid item sx={styles.buttonWrapper}>
        <ButtonLink
          color="primary"
          to={PATHS.SUBSCRIPTION_ACTIVATION_CODE.replace(':companyId', companyId).replace(':siteId', siteId)}
          variant="outlined"
        >
          <FormattedMessage {...messages.contentNoPaymentsContentUseVoucher} />
        </ButtonLink>
      </Grid>
    </Grid>
  );
};

export default NoPaymentsContent;
