import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import RowMenuItem from '../../../components/DataGrid/RowMenuItem';
import { PATHS } from '../../../config/consts';
import actions from '../../../modules/phonebooks';
import messages from '../messages';

const PhonebooksRowMenu = ({ companyId, onDeletePhonebook, phonebook, siteId }) => (
  <Box>
    <RowMenuItem
      component={Link}
      icon={<EditIcon />}
      label={<FormattedMessage {...messages.phonebooksDataGridRowMenuEditPhonebook} />}
      to={PATHS.PHONEBOOK_DETAIL.replace(':companyId', companyId)
        .replace(':siteId', siteId)
        .replace(':phonebookId', phonebook.id)}
    />
    <Divider />
    <Tooltip
      disableHoverListener={!phonebook.isDefault}
      title={phonebook.isDefault && <FormattedMessage {...messages.phonebooksDataGridRowMenuCantDelete} />}
    >
      <span>
        <RowMenuItem
          colorIcon="error.dark"
          colorText="error.dark"
          disabled={phonebook.isDefault}
          icon={<DeleteIcon />}
          label={<FormattedMessage {...messages.phonebooksDataGridRowMenuDelete} />}
          onClick={() => onDeletePhonebook(phonebook)}
        />
      </span>
    </Tooltip>
  </Box>
);

PhonebooksRowMenu.propTypes = {
  companyId: PropTypes.number.isRequired,
  onDeletePhonebook: PropTypes.func.isRequired,
  phonebook: PropTypes.shape({
    id: PropTypes.number,
    isDefault: PropTypes.bool,
  }).isRequired,
  siteId: PropTypes.number.isRequired,
};

const mapDispatchToProps = {
  onDeletePhonebook: actions.deletePhonebookShowModal,
};

export default connect(null, mapDispatchToProps)(PhonebooksRowMenu);
