import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

export const DASH_LIMIT = 8;
export const DASH_WIDTH = 16;

const styles = {
  active: {
    '&:hover': {
      cursor: 'pointer',
    },
    backgroundColor: 'success.main',
  },
  countDevices: {
    '&:hover': {
      cursor: 'pointer',
    },
    fontWeight: 'bold',
  },
  dash: {
    '&:first-of-type': {
      ml: 0,
    },
    '&:last-of-type': {
      mr: 0,
    },
    backgroundColor: 'grey.400',
    height: '4px',
    margin: '0 1px',
    width: DASH_WIDTH,
  },
  green: {
    color: 'success.main',
  },
  line: {
    display: 'flex',
    ml: 1,
  },
  root: {
    alignItems: 'center',
    display: 'flex',
  },
  text: {
    ml: 1,
  },
};

const DevicesColumn = ({ apartmentDeviceLimit, devicesCount, isMDU, usageDevices }) => (
  <Box sx={styles.root}>
    <Tooltip title={<FormattedMessage {...messages.devicesColumnTotalDevices} />}>
      <Box component="strong" sx={styles.countDevices}>
        {devicesCount}
      </Box>
    </Tooltip>
    {isMDU && (
      <>
        {apartmentDeviceLimit <= DASH_LIMIT ? (
          <Box sx={styles.line}>
            {Array.from({ length: apartmentDeviceLimit }, (_, i) =>
              i < usageDevices ? (
                <Tooltip key={i} title={<FormattedMessage {...messages.devicesColumnDevicesWithSubscriptions} />}>
                  <Box sx={{ ...styles.dash, ...styles.active }} />
                </Tooltip>
              ) : (
                <Box key={i} sx={styles.dash} />
              )
            )}
          </Box>
        ) : (
          <Tooltip title={<FormattedMessage {...messages.devicesColumnDevicesWithSubscriptions} />}>
            <Box sx={styles.text}>
              <FormattedMessage
                {...messages.devicesColumnDevices}
                values={{
                  count: usageDevices,
                  devices: usageDevices <= apartmentDeviceLimit ? usageDevices : apartmentDeviceLimit,
                  green: (chunks) => (
                    <Box component="span" sx={styles.green}>
                      <strong>{chunks}</strong>
                    </Box>
                  ),
                  totalDevices: apartmentDeviceLimit,
                }}
              />
            </Box>
          </Tooltip>
        )}
      </>
    )}
  </Box>
);

DevicesColumn.propTypes = {
  apartmentDeviceLimit: PropTypes.number.isRequired,
  devicesCount: PropTypes.number.isRequired,
  isMDU: PropTypes.bool.isRequired,
  usageDevices: PropTypes.number.isRequired,
};

export default DevicesColumn;
