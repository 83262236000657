import { defineMessages } from 'react-intl';

export default defineMessages({
  confirmDialogCancelButton: {
    defaultMessage: 'Cancel',
    description: 'Confirm dialog - cancel button',
    id: 'confirmDialog.cancelButton',
  },
  confirmDialogConfirmButton: {
    defaultMessage: 'Ok',
    description: 'Confirm dialog - confirm button',
    id: 'confirmDialog.confirmButton',
  },
  confirmDialogText: {
    defaultMessage:
      'Are you sure you want to renew part of the device service settings' +
      ' related to the Remote Configuration Service?',
    description: 'Confirm dialog - text',
    id: 'confirmDialog.text',
  },
});
