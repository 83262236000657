import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { PATHS } from '../../config/consts';
import ButtonLink from '../ButtonLink';

const AccessGroupsTableChipList = ({ companyId, currentGroupId, groups, siteId }) => {
  let sortedGroups = sortBy(groups, ['name']);
  const currentGroup = sortedGroups.find((group) => group?.id === currentGroupId?.toString());

  if (currentGroup) {
    const filteredGroups = sortedGroups.filter((group) => group?.id !== currentGroupId?.toString());
    sortedGroups = [currentGroup].concat(filteredGroups);
  }

  const isCurrentGroupId = (group) => group.id === currentGroupId;

  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        mt: 0.5,
      }}
    >
      {sortedGroups.map((group) => (
        <Chip
          color="primary"
          component={!isCurrentGroupId(group) ? ButtonLink : null}
          key={group.id}
          label={group.name}
          onClick={(event) => event.stopPropagation()}
          size="small"
          sx={{
            '&:hover': {
              cursor: !isCurrentGroupId(group) ? 'pointer' : 'default',
            },
            display: 'inline-grid',
            mb: 0.5,
            mr: 1,
            textTransform: 'none',
          }}
          to={PATHS.ACCESS_SETTINGS_DETAIL.replace(':companyId', companyId)
            .replace(':siteId', siteId)
            .replace(':groupId', group.id)}
          variant={isCurrentGroupId(group) ? 'filled' : 'outlined'}
        />
      ))}
    </Box>
  );
};

AccessGroupsTableChipList.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  currentGroupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  groups: PropTypes.arrayOf(PropTypes.object).isRequired,
  siteId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default AccessGroupsTableChipList;
