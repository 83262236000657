import { IntlFormatters } from 'react-intl';
import * as yup from 'yup';
import { INewPasswordFormData } from '../../../pages/ResetPasswordPage/components/NewPasswordForm/validationScheme';
import messages from '../../../validations/messages';
import { getOryPasswordValidationScheme } from '../../../validations/oryPasswordValidationSchema';

export interface IChangePasswordFormData extends INewPasswordFormData {
  currentPassword?: string;
}

export enum FormFieldNames {
  Password = 'password',
  CurrentPassword = 'currentPassword',
}

export const defaultValues: IChangePasswordFormData = {
  currentPassword: '',
  password: '',
};

export function getChangePasswordFormValidationSchema(
  formatMessage: IntlFormatters['formatMessage'],
  isCurrentPasswordMandatory?: boolean
) {
  return yup.object().shape({
    [FormFieldNames.Password]: getOryPasswordValidationScheme(formatMessage),
    [FormFieldNames.CurrentPassword]: isCurrentPasswordMandatory
      ? yup.string().required(messages.validationRequired)
      : yup.string(),
  });
}
