import { InfoBar, InfoBarType, InfoBarVariant } from '@2n/design-system';
import React from 'react';

export interface ISuccessBarData {
  title?: string;
  children: React.ReactNode;
}

export function FilledSuccessBar(data: ISuccessBarData) {
  return (
    <InfoBar
      variant={InfoBarVariant.standard}
      type={InfoBarType.success}
      title={data.title || undefined}
      sx={{
        backgroundColor: 'success.light',
        color: 'success.text',
      }}
    >
      {data.children}
    </InfoBar>
  );
}
