import Box from '@mui/material/Box';
import { lighten } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { ReactSVG } from 'react-svg';
import symbolErrorSvg from '../../assets/symbolError.svg';

const styles = {
  message: {
    color: 'error.main',
    fontSize: (theme) => theme.typography.fontSize * 1.15,
    fontWeight: 'bold',
    marginTop: 1,
  },
  root: {
    '& .wrapperSvg': {
      '& svg': {
        fill: (theme) => theme.palette.error.main,
        height: '100%',
        width: '100%',
      },
      background: (theme) => lighten(theme.palette.error.main, 0.88),
      borderRadius: '100%',
      height: '144px',
      p: 4,
      width: '144px',
    },
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
};

const ErrorIconMessage = ({ children }) => (
  <Box sx={styles.root}>
    <ReactSVG className="wrapperSvg" src={symbolErrorSvg} />
    <Typography paragraph sx={styles.message}>
      {children}
    </Typography>
  </Box>
);

ErrorIconMessage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default ErrorIconMessage;
