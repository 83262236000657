import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import UserRoleSelect from './UserRoleSelect';

function BasicInfo({ companyName, control, currentUserRole, initialUserRole, isGuest, onOpenChangeUserRoleModal }) {
  return (
    <Grid item sx={{ mb: 0.5 }} xs={12}>
      <UserRoleSelect
        companyName={companyName}
        control={control}
        currentUserRole={currentUserRole || initialUserRole}
        initialUserRole={initialUserRole}
        onOpenChangeUserRoleModal={onOpenChangeUserRoleModal}
        isGuest={isGuest}
      />
    </Grid>
  );
}

BasicInfo.propTypes = {
  companyName: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  currentUserRole: PropTypes.string.isRequired,
  initialUserRole: PropTypes.string.isRequired,
  isGuest: PropTypes.bool.isRequired,
  onOpenChangeUserRoleModal: PropTypes.func.isRequired,
};

export default BasicInfo;
