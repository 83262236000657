import React from 'react';
import { ElIntSingleFloorMapping } from '../../../../../apiRtk/be4feApi';
import { IElevatorIntegrationFloorMappingStore } from '../../../../../modules/my2nApi/elevatorIntegrationSlice';
import { ElevatorGroupSelectItem } from '../FloorConfiguration';
import { disableSelectedElevatorGroups } from '../helpers';
import FloorMapping from './FloorMapping';

interface IFloorItem extends ElIntSingleFloorMapping {
  name: string;
  elevatorGroups: Array<ElevatorGroupSelectItem>;
  elevatorIntegrationFloorMappingStore: IElevatorIntegrationFloorMappingStore;
}

export function FloorItem({
  elevatorGroups,
  elevatorIntegrationFloorMappingStore,
  floorId,
  mappings,
  name,
}: IFloorItem) {
  const filteredElevatorGroups = disableSelectedElevatorGroups(
    elevatorIntegrationFloorMappingStore,
    elevatorGroups,
    floorId
  );

  return (
    <>
      {mappings.map((mapping, index) => {
        return (
          <>
            <FloorMapping
              key={`${mapping.buildingId}${mapping.elevatorGroupId}`}
              context={index + 1 === mappings.length ? 'add' : 'delete'}
              index={index}
              floorMapping={mapping}
              floorName={name}
              floorId={floorId}
              elevatorGroups={filteredElevatorGroups}
            />
          </>
        );
      })}
    </>
  );
}
