import {
  Alert,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useGetGlobalInfo } from '../../../../../hooks/useGetGlobalInfo';
import { FormFieldNames } from '../../../validationSchema';
import messages from './messages';
import { SelectIdAlertText } from './SelectIdAlertText';

interface ISelectId {
  locationIds?: string[];
  disabled?: boolean;
}

export function SelectId({ disabled, locationIds }: ISelectId) {
  const { formatMessage } = useGetGlobalInfo();
  const formMethods = useFormContext();
  const selectValue = formMethods.watch(FormFieldNames.SelectId);

  const handleSelectChange = (event: SelectChangeEvent) => {
    formMethods.setValue(FormFieldNames.SelectId, event.target.value as string, { shouldValidate: true });
  };

  const renderValue = (selected: string) => {
    switch (selected) {
      case 'GenerateNew':
        return <FormattedMessage {...messages.configureMy2NAccessModal4LocationIDGenerate} />;
      case 'OwnId':
        return <FormattedMessage {...messages.configureMy2NAccessModal4LocationIDOwn} />;
      default:
        return <Typography>{selected}</Typography>;
    }
  };

  return (
    <>
      <Box sx={{ alignItems: 'center', display: 'flex', pl: 4, py: 2 }}>
        <FormControl sx={{ flex: 1, maxWidth: 'calc(50% - 8px)', minWidth: 200 }}>
          <InputLabel disabled={disabled}>
            <FormattedMessage {...messages.configureMy2NAccessModal4LocationIDLabel} />
          </InputLabel>
          <Select
            {...formMethods.register(FormFieldNames.SelectId)}
            disabled={disabled}
            defaultValue={formatMessage(messages.configureMy2NAccessModal4LocationIDPlaceholder)}
            onChange={handleSelectChange}
            renderValue={renderValue}
            label={formatMessage(messages.configureMy2NAccessModal4LocationIDLabel)}
          >
            {locationIds && locationIds.length > 0 && (
              <MenuItem
                value="Found"
                disabled
                sx={{
                  '&.Mui-disabled': {
                    color: 'inherit',
                    opacity: 1,
                  },
                }}
              >
                <Typography fontStyle={'italic'}>
                  <FormattedMessage {...messages.configureMy2NAccessModal4LocationIDFound} />
                </Typography>
              </MenuItem>
            )}
            {locationIds &&
              locationIds.map((locationId, index) => (
                <MenuItem key={index} value={locationId}>
                  <Typography pl={2}>{locationId}</Typography>
                </MenuItem>
              ))}
            <MenuItem value="GenerateNew">
              <FormattedMessage {...messages.configureMy2NAccessModal4LocationIDGenerate} />
            </MenuItem>
            <MenuItem value="OwnId">
              <FormattedMessage {...messages.configureMy2NAccessModal4LocationIDOwn} />
            </MenuItem>
          </Select>
        </FormControl>
        {selectValue === 'OwnId' && (
          <TextField
            {...formMethods.register(FormFieldNames.CustomId)}
            disabled={disabled}
            sx={{ flex: 1, minWidth: 200, ml: 2 }}
            label={formatMessage(messages.configureMy2NAccessModal4CustomLocationIdLabel)}
            variant="outlined"
          />
        )}
      </Box>
      {selectValue && !disabled && (
        <Alert
          severity={locationIds?.includes(selectValue) && locationIds?.length === 1 ? 'info' : 'warning'}
          sx={{ mb: 2 }}
        >
          <SelectIdAlertText selected={selectValue} locationIds={locationIds} />
        </Alert>
      )}
    </>
  );
}
