import { SERVICE } from '../../config/devices';
import { ApartmentStatus, IReduxListStoreBase, Roles, TId } from '../commonTypes';
import { initialState } from './reducer';

export interface IApartmentsStore {
  apartmentsList?: IReduxListStoreBase<IApartmentDetail>;
}

export function getInitialApartmentsStore(): IApartmentsStore {
  return { ...initialState };
}

export interface INewFloor {
  name: string;
}

export interface INewApartment {
  floor?: string;
  name?: string;
  number: string;
  paidServicesActive?: boolean;
}

export interface IApartmentBase {
  id: TId;
  name?: string;
  number: string;
}

export interface IApartment extends IApartmentBase {
  floor: string;
  label: string;
}

export interface IApartmentIdsInfo {
  siteId: TId;
  apartmentId: TId;
  companyId: TId;
}

export interface IApartmentDetail {
  name?: string;
  number: string;
  id: number;
  floorId?: number;
  services: IApartmentService;
  devicesCount: number;
  paidServicesActive: boolean;
  roles: {
    [Roles.RESIDENT]: Array<number>;
  };
  externalId: string;
  floor?: IFloor;
}

export type IApartmentService = {
  [key in SERVICE]?: {
    status: ApartmentStatus;
  };
};

export interface IFloor extends INewFloor {
  id: number;
}

export type TApartmentRole = typeof Roles.RESIDENT | typeof Roles.APARTMENT_ADMIN;
