import PropTypes from 'prop-types';

export const unitChipOptionsProps = PropTypes.arrayOf(
  PropTypes.shape({
    deviceConfigId: PropTypes.number,
    name: PropTypes.string,
  })
);

export const unitContactListProps = PropTypes.arrayOf(
  PropTypes.shape({
    contacts: PropTypes.arrayOf(
      PropTypes.shape({
        contactItems: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number,
            type: PropTypes.string,
          })
        ),
        id: PropTypes.number,
        name: PropTypes.string,
      })
    ),
    id: PropTypes.number,
    type: PropTypes.string,
  })
);

export const unitFirmwareProps = PropTypes.shape({
  info: PropTypes.shape({
    currentVersion: PropTypes.string,
    haveUpdate: PropTypes.bool,
    newFirmware: PropTypes.shape({
      releaseNotes: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          version: PropTypes.string,
        })
      ).isRequired,
      url: PropTypes.string,
      version: PropTypes.string,
    }),
  }),
});
