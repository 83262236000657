export const moduleName = 'phonebooks';

export default {
  ADD_PHONEBOOK_SHOW_MODAL: `${moduleName}/ADD_PHONEBOOK_SHOW_MODAL`,
  DELETE_PHONEBOOK_FAILURE: `${moduleName}/DELETE_PHONEBOOK_FAILURE`,
  DELETE_PHONEBOOK_HIDE_MODAL: `${moduleName}/DELETE_PHONEBOOK_HIDE_MODAL`,
  DELETE_PHONEBOOK_REQUEST: `${moduleName}/DELETE_PHONEBOOK_REQUEST`,
  DELETE_PHONEBOOK_SHOW_MODAL: `${moduleName}/DELETE_PHONEBOOK_SHOW_MODAL`,
  DELETE_PHONEBOOK_SUCCESS: `${moduleName}/DELETE_PHONEBOOK_SUCCESS`,
  EDIT_PHONEBOOK_SHOW_MODAL: `${moduleName}/EDIT_PHONEBOOK_SHOW_MODAL`,
  LOAD_MEMBERS_LIST_FAILURE: `${moduleName}/LOAD_MEMBERS_LIST_FAILURE`,
  LOAD_MEMBERS_LIST_REQUEST: `${moduleName}/LOAD_MEMBERS_LIST_REQUEST`,
  LOAD_MEMBERS_LIST_SUCCESS: `${moduleName}/LOAD_MEMBERS_LIST_SUCCESS`,
  LOAD_PHONEBOOKS_LIST_FAILURE: `${moduleName}/LOAD_PHONEBOOKS_LIST_FAILURE`,
  LOAD_PHONEBOOKS_LIST_REQUEST: `${moduleName}/LOAD_PHONEBOOKS_LIST_REQUEST`,
  LOAD_PHONEBOOKS_LIST_SUCCESS: `${moduleName}/LOAD_PHONEBOOKS_LIST_SUCCESS`,
  LOAD_PHONEBOOKS_NAMES_FAILURE: `${moduleName}/LOAD_PHONEBOOKS_NAMES_FAILURE`,
  LOAD_PHONEBOOKS_NAMES_REQUEST: `${moduleName}/LOAD_PHONEBOOKS_NAMES_REQUEST`,
  LOAD_PHONEBOOKS_NAMES_SUCCESS: `${moduleName}/LOAD_PHONEBOOKS_NAMES_SUCCESS`,
  PHONEBOOK_DETAIL_FAILURE: `${moduleName}/PHONEBOOK_DETAIL_FAILURE`,
  PHONEBOOK_DETAIL_REQUEST: `${moduleName}/PHONEBOOK_DETAIL_REQUEST`,
  PHONEBOOK_DETAIL_SUCCESS: `${moduleName}/PHONEBOOK_DETAIL_SUCCESS`,
  PHONEBOOK_HIDE_MODAL: `${moduleName}/PHONEBOOK_HIDE_MODAL`,
  SAVE_PHONEBOOK_FAILURE: `${moduleName}/SAVE_PHONEBOOK_FAILURE`,
  SAVE_PHONEBOOK_REQUEST: `${moduleName}/SAVE_PHONEBOOK_REQUEST`,
  SAVE_PHONEBOOK_SUCCESS: `${moduleName}/SAVE_PHONEBOOK_SUCCESS`,
};
