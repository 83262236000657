import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import PropTypes from 'prop-types';
import React from 'react';

const RadioListFilled = ({ field, options, ...props }) => (
  <RadioGroup {...field} {...props}>
    <>
      {options.map((option) => (
        <FormControlLabel
          control={<Radio color="primary" />}
          key={option.value}
          label={option.label}
          value={option.value}
        />
      ))}
    </>
  </RadioGroup>
);

RadioListFilled.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    value: PropTypes.string,
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      value: PropTypes.string,
    })
  ).isRequired,
};

export default RadioListFilled;
