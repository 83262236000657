import DialpadIcon from '@mui/icons-material/Dialpad';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import AddApartmentUsersDialog from '../../../components/AddApartmentUsersModal';
import ApartmentAddMultipleUsersModal from '../../../components/ApartmentAddMultipleUsersModal';
import ApartmentAdminSwitch from '../../../components/ApartmentAdminSwitch';
import ApartmentNameChip from '../../../components/ApartmentNameChip';
import DataGrid from '../../../components/DataGrid';
import NameColumn from '../../../components/DataGrid/NameColumn';
import RenderCell from '../../../components/DataGrid/RenderCell';
import { useHasFeature } from '../../../components/Feature/Feature';
import GrantApartmentAdminRightsDialog from '../../../components/GrantApartmentAdminRightsModal';
import Paper from '../../../components/Paper';
import RevokeApartmentAdminRightsDialog from '../../../components/RevokeApartmentAdminRightsModal';
import TableControlPanel from '../../../components/TableControlPanel';
import UserAccessGroupsModal from '../../../components/UserAccessGroupsModal';
import UserAccessSettingsStatus from '../../../components/UserAccessSettingsStatus';
import UserEmail from '../../../components/UserEmail';
import UserIcon from '../../../components/UserIcon';
import { UserRoleName } from '../../../components/UserRoleName';
import { FE_ROLES, PATHS } from '../../../config/consts';
import featureFlags from '../../../config/featureFlags';
import { getDataGridSelector, useButtonText, useDataGrid } from '../../../helpers/dataGrid';
import { isAdmin } from '../../../helpers/user';
import { useModal } from '../../../hooks/useModal';
import actions, { NAME as APARTMENT_MODULE } from '../../../modules/apartments';
import { getApartmentDevices } from '../../../modules/apartments/selectors';
import { VisitorModalContext } from '../../../modules/commonTypes';
import { isFetchingMessages } from '../../../modules/localization/selectors';
import { getSiteData } from '../../../modules/sites/selectors';
import VisitorModal from '../../../pages/apartmentAdmin/ApartmentAdminUsersOverviewPage/components/VisitorModal/VisitorModal';
import messages from '../messages';
import ApartmentUsersRowMenu from './ApartmentUsersRowMenu';
import { useGetGlobalHooks } from './hooks/getGlobalHooks';

function ApartmentUsers() {
  const isGuestAccessFFEnabled = useHasFeature(featureFlags.GUEST_ACCESS);
  const { breakpoints, dispatch, formatMessage, isSiteMDU, navigate, urlParams } = useGetGlobalHooks();
  const { apartmentId, companyId, siteId } = urlParams;
  const upSm = breakpoints.upSm;

  const siteData = useSelector(getSiteData);

  const { Modal: AddApartmentUsersModal, onOpen: onOpenAddApartmentUsersModal } = useModal({
    Modal: AddApartmentUsersDialog,
  });
  const { Modal: AddMultipleUsersModal, onOpen: onOpenAddMultipleUsersModal } = useModal({
    Modal: ApartmentAddMultipleUsersModal,
  });
  const { Modal: VisitorUModal, onOpen: onOpenVisitorUModal } = useModal({
    Modal: VisitorModal,
    onClose: () => {
      dispatch(actions.getApartmentUsers(apartmentId, companyId, siteId, page, rowsPerPage, filter, order));
    },
  });
  const [selectedUser, setSelectedUser] = useState({});
  const { Modal: GrantApartmentAdminRightsModal, onOpen: onOpenGrantApartmentAdminRightsModal } = useModal({
    Modal: GrantApartmentAdminRightsDialog,
  });
  const { Modal: RevokeApartmentAdminRightsModal, onOpen: onOpenRevokeApartmentAdminRightsModal } = useModal({
    Modal: RevokeApartmentAdminRightsDialog,
  });

  const isLocalizationLoaded = useSelector(isFetchingMessages);
  const { data, didInvalid, filter, isFetching, order, page, resultsFiltered, resultsTotal, rowsPerPage } = useSelector(
    (state) => getDataGridSelector(state, APARTMENT_MODULE, 'apartmentUsers.list')
  );
  const apartmentDevices = useSelector(getApartmentDevices).data || [];

  const { onOrderBy, onRowsPerPageChange, onSelectPage } = useDataGrid(
    (...args) => dispatch(actions.getApartmentUsers(...args)),
    {
      apartmentId,
      companyId,
      emptyApartment: apartmentId === null,
      emptySite: siteId === null,
      filter,
      order,
      page,
      rowsPerPage,
      siteId,
    },
    didInvalid,
    isFetching
  );

  useEffect(() => {
    if (didInvalid) {
      dispatch(actions.getApartmentUsers(apartmentId, companyId, siteId, page, rowsPerPage, filter, order));
    }
  }, [didInvalid]);

  const columns = useMemo(
    () => [
      {
        disableColumnMenu: true,
        field: 'lastName',
        flex: 1,
        headerName: formatMessage(messages.apartmentUsersDataGridGridColumnsName),
        renderCell: (cell) => (
          <RenderCell isFetching={isFetching}>
            <NameColumn
              isTimeLimitedAccess={!!(cell?.value?.validFrom || cell?.value?.validTo)}
              iconComponent={
                cell?.value?.admin && !isAdmin(cell.value?.role) ? (
                  <UserIcon userRole={FE_ROLES.APARTMENT_ADMIN} />
                ) : cell?.value?.role === FE_ROLES.GUEST ? (
                  <DialpadIcon />
                ) : (
                  <UserIcon userRole={cell.value?.role} />
                )
              }
              name={cell.value?.firstName ? `${cell.value?.lastName} ${cell.value?.firstName}` : cell.value?.lastName}
            />
          </RenderCell>
        ),
        sortable: true,
      },
      {
        disableColumnMenu: true,
        field: 'apartment',
        flex: 1,
        headerName: formatMessage(messages.apartmentUsersDataGridGridColumnsApartment),
        renderCell: (cell) => (
          <RenderCell isFetching={isFetching}>
            <ApartmentNameChip
              count={cell.value?.apartments?.length}
              name={cell.value?.apartments[0]?.name}
              number={cell.value?.apartments[0]?.number}
            />
          </RenderCell>
        ),
        sortable: false,
      },
      {
        disableColumnMenu: true,
        field: 'email',
        flex: 1,
        headerName: formatMessage(messages.apartmentUsersDataGridGridColumnsEmail),
        renderCell: (cell) => (
          <RenderCell isFetching={isFetching}>
            <UserEmail email={cell.value?.email} isEnabled={cell.value?.enabled} />
          </RenderCell>
        ),
        sortable: true,
      },
      {
        disableColumnMenu: true,
        field: 'role',
        flex: 1,
        headerName: formatMessage(messages.apartmentUsersDataGridGridColumnsRole),
        renderCell: (cell) => (
          <RenderCell isFetching={isFetching}>
            <UserRoleName
              userRole={cell.value?.admin && !isAdmin(cell.value?.role) ? FE_ROLES.APARTMENT_ADMIN : cell.value?.role}
            />
          </RenderCell>
        ),
        sortable: true,
      },
      ...(isSiteMDU
        ? [
            {
              disableColumnMenu: true,
              field: 'apartmentAdmin',
              flex: 1,
              headerName: formatMessage(messages.AAApartmentUserListTitle),
              renderCell: (cell) => (
                <RenderCell isFetching={isFetching}>
                  <ApartmentAdminSwitch
                    onGrantApartmentAdminRights={onOpenGrantApartmentAdminRightsModal}
                    onRevokeApartmentAdminRights={onOpenRevokeApartmentAdminRightsModal}
                    selectedUser={cell.value}
                    setSelectedUser={setSelectedUser}
                  />
                </RenderCell>
              ),
              sortable: false,
            },
          ]
        : []),
      {
        disableColumnMenu: true,
        field: 'access',
        flex: 1,
        headerName: formatMessage(messages.apartmentUsersDataGridGridColumnsAccess),
        renderCell: (cell) => (
          <RenderCell isFetching={isFetching}>
            <UserAccessSettingsStatus
              credentials={cell?.value?.credentials}
              isTimeLimitedAccess={!!(cell?.value?.validFrom || cell?.value?.validTo)}
            />
          </RenderCell>
        ),
        sortable: false,
      },
      {
        disableColumnMenu: true,
        field: 'rowMenu',
        headerName: '',
        renderCell: (cell) => (
          <RenderCell isFetching={isFetching}>
            <ApartmentUsersRowMenu
              siteData={siteData}
              companyId={companyId}
              onOpenGrantApartmentAdminRightsModal={onOpenGrantApartmentAdminRightsModal}
              onOpenRevokeApartmentAdminRightsModal={onOpenRevokeApartmentAdminRightsModal}
              onSetSelectedUser={setSelectedUser}
              siteId={siteId}
              user={cell.value}
            />
          </RenderCell>
        ),
        sortable: false,
        width: 65,
      },
    ],
    [isFetching, breakpoints.upSm, breakpoints.upLg, isLocalizationLoaded]
  );

  const rows = useMemo(
    () =>
      data.map((user) => ({
        access: user,
        apartment: user,
        apartmentAdmin: user,
        email: user,
        id: user.id,
        isClickable: true,
        lastName: user,
        role: user,
        rowMenu: user,
      })),
    [data]
  );

  return (
    <Paper tabContent tabTable>
      <TableControlPanel
        isGuestAccessFFEnabled={isGuestAccessFFEnabled}
        addButtonAction={onOpenAddApartmentUsersModal}
        addButtonLabel={
          upSm
            ? formatMessage(messages.apartmentUsersControlPanelAddUsers)
            : formatMessage(messages.apartmentUsersControlPanelAddUsersXs)
        }
        createButtonAction={onOpenAddMultipleUsersModal}
        createButtonLabel={
          upSm
            ? formatMessage(messages.apartmentUsersCreateMultipleUsers)
            : formatMessage(messages.apartmentUsersCreateMultipleUsersXs)
        }
        createVisitorButtonAction={onOpenVisitorUModal}
        createVisitorButtonLabel={
          upSm
            ? formatMessage(messages.AAuserListApartmentUsersCreateGuest)
            : formatMessage(messages.AAuserListApartmentUsersCreateGuestXs)
        }
        tabToolbar
      />
      <DataGrid
        columns={columns}
        columnVisibilityModel={{
          access: breakpoints.upSm,
          apartment: breakpoints.upLg,
          apartmentAdmin: breakpoints.upSm,
          email: breakpoints.upLg,
        }}
        disableRowSelectionOnClick
        hideFooterPagination={isFetching}
        loading={isFetching}
        onCellClick={(params) => {
          if (params.field !== 'rowMenu' && params.field !== 'apartmentAdmin') {
            navigate(
              PATHS.SITE_USER_DETAIL.replace(':companyId', companyId)
                .replace(':siteId', siteId)
                .replace(':userId', params.value?.id)
            );
          }
        }}
        onOrderBy={onOrderBy}
        onRowsPerPageChange={onRowsPerPageChange}
        onSelectPage={onSelectPage}
        order={order}
        page={page}
        resultsFiltered={resultsFiltered}
        resultsTotal={resultsTotal}
        rows={rows}
        rowsPerPage={rowsPerPage}
      />
      <UserAccessGroupsModal />
      <AddApartmentUsersModal />
      <AddMultipleUsersModal />
      <VisitorUModal
        msgContext={VisitorModalContext.APARTMENT_USERS_NEW_GUESTMODAL}
        apartmentInfo={{ apartmentId, companyId, siteId }}
        siteName={siteData?.name}
        apartmentDevices={apartmentDevices}
      />
      <GrantApartmentAdminRightsModal selectedUser={selectedUser} />
      <RevokeApartmentAdminRightsModal selectedUser={selectedUser} />
    </Paper>
  );
}

export default ApartmentUsers;
