import { defineMessages } from 'react-intl';

export default defineMessages({
  apartmentAccessGroupsSettingsAlertNoGroups: {
    defaultMessage: "Apartment users won't have access until you set Access groups.",
    description: 'Access group chip list - info no groups',
    id: 'apartmentAccessGroupsSettings.alert.noGroups',
  },
  apartmentAccessGroupsSettingsAlertRacInactive: {
    defaultMessage: 'Set Access groups to allow access for this apartment users.',
    description: 'Access group chip list - info inactive',
    id: 'apartmentAccessGroupsSettings.alert.racInactive',
  },
  apartmentAccessGroupsSettingsHelperText: {
    defaultMessage:
      'Apartment users have access via devices in' +
      ' {count, plural, =1 {this Access group} other {these Access groups}}',
    description: 'Access group chip list - helper text',
    id: 'apartmentAccessGroupsSettings.helperText',
  },
});
