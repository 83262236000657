import { GridRowModel, GridRowsProp } from '@mui/x-data-grid';
import { isAggregatedDataType, MuiRow } from './aggregatedCellContentRenderer';
import { DataGridAggregatedProps, IAggregatedRows } from './data';

const firstRowId: number = 1;
const skeletonAggregatedRowsCount: number = 5;

export function getAggregatedDataGridRows<TAggregatedItem extends object, TItem extends object>(
  props: DataGridAggregatedProps<TAggregatedItem, TItem>
) {
  return props.isFetching || !props.items ? getSkeletonRows() : getDataRows(props.items);
}

export function onAggregatedTableRowClick<TAggregatedItem extends object, TItem extends object>({
  isFetching,
  onAggregatedRowClick,
  onRowClick,
  row,
}: {
  row: MuiRow<TItem | TAggregatedItem>;
  isFetching?: boolean;
  onRowClick?: DataGridAggregatedProps<TAggregatedItem, TItem>['onRowClick'];
  onAggregatedRowClick?: DataGridAggregatedProps<TAggregatedItem, TItem>['onAggregatedRowClick'];
}) {
  if (isFetching) {
    return;
  }
  if (onAggregatedRowClick && isAggregatedDataType(row) && row.isAggregatedDataType) {
    onAggregatedRowClick(row.data as TAggregatedItem);
  } else if (onRowClick) {
    onRowClick(row.data as TItem);
  }
}

export function getDataRows<TAggregatedItem extends object, TItem extends object>(
  aggregatedItems: IAggregatedRows<TAggregatedItem, TItem>
): GridRowsProp<MuiRow<TAggregatedItem | TItem>> {
  return [getAggregatedParentRow(aggregatedItems), ...getAggregatedRows(aggregatedItems)];
}

export function getSkeletonRows() {
  return [getSkeletonAggregatedParentRow(), ...getSkeletonAggregatedRows()];
}

export function getAggregatedParentRow<TAggregatedItem extends object, TItem extends object>(
  aggregatedItems: IAggregatedRows<TAggregatedItem, TItem>
): GridRowModel<MuiRow<TAggregatedItem>> {
  return {
    data: aggregatedItems.aggregatedParentItem,
    id: firstRowId,
    isAggregatedDataType: true,
  };
}

export function getAggregatedRows<TAggregatedItem extends object, TItem extends object>(
  aggregatedItems: IAggregatedRows<TAggregatedItem, TItem>
): GridRowsProp<MuiRow<TItem>> {
  return aggregatedItems.items.map((row, index) => {
    return {
      data: row,
      id: firstRowId + index + 1,
    };
  }) as readonly MuiRow<TItem>[];
}

export function getSkeletonAggregatedParentRow(): GridRowModel<MuiRow<object>> {
  return {
    data: {},
    id: firstRowId,
    isAggregatedDataType: true,
  };
}

export function getSkeletonAggregatedRows(): GridRowsProp<MuiRow<object>> {
  return new Array(skeletonAggregatedRowsCount).fill(undefined).map((_v, index) => ({
    data: {},
    id: firstRowId + index + 1,
  }));
}
