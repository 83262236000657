import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import SectionTemplate from '../SectionTemplate';
import messages from './messages';
import TemplateButton from './TemplateButton';

function About() {
  const { formatMessage } = useIntl();
  return (
    <SectionTemplate title={formatMessage(messages.siteUsersImportIntroStepAboutTitle)}>
      <Stack alignItems="flex-start" direction="column" justifyContent="center">
        <Typography component="div" variant="body1">
          <FormattedMessage
            values={{
              p: (chunks) => <p>{chunks}</p>,
            }}
            {...messages.siteUsersImportIntroStepAbout}
          />
        </Typography>
        <TemplateButton />
      </Stack>
    </SectionTemplate>
  );
}

About.propTypes = {
  onHelpModalOpen: PropTypes.func.isRequired,
};

export default About;
