import AddIcon from '@mui/icons-material/Add';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { IModalBase } from '../../modules/commonTypes';
import actions from '../../modules/sites';
import ActionModal from '../_DesignSystemWrappers/Modal/ActionModal';
import { OutlinedTextField } from '../HookFormFields/TextField';
import SiteLanguageSelect from '../SiteLanguageSelect';
import { ICreateSiteValues, useGetGlobalHooks } from './hooks/getGlobalHooks';
import messages from './messages';

function CreateSiteModal({ onClose, open }: IModalBase) {
  const { dispatch, formatMessage, formMethods } = useGetGlobalHooks();

  function handleSubmit(data: ICreateSiteValues) {
    dispatch(actions.addSiteFormRequest({ locale: data.lang, name: data.name }));
    onClose();
  }

  return (
    <ActionModal
      icon={<AddIcon />}
      isPrimaryButtonDisabled={!formMethods.formState.isValid}
      muiDialogProps={{ maxWidth: 'xs' }}
      onClose={onClose}
      open={open}
      primaryButtonAction={formMethods.handleSubmit(handleSubmit)}
      primaryButtonText={formatMessage(messages.CreateSiteModalSubmit)}
      secondaryButtonText={formatMessage(messages.CreateSiteModalCancel)}
      title={formatMessage(messages.CreateSiteModalTitle)}
      testId="create-site-modal"
    >
      <Stack my={2} sx={{ '& ul': { m: 1, p: '0 0 0 16px' } }}>
        <FormattedMessage {...messages.CreateSiteModalContentTitle} />
        <ul>
          <FormattedMessage tagName="li" {...messages.CreateSiteModalListManageBuilding} />
          <FormattedMessage tagName="li" {...messages.CreateSiteModalListDevices} />
          <FormattedMessage tagName="li" {...messages.CreateSiteModalListBillingInfo} />
        </ul>
        <FormProvider {...formMethods}>
          <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
            <Stack spacing={3} mt={3}>
              <OutlinedTextField
                fullWidth
                helperText={formatMessage(messages.CreateSiteModalNameInputHelperText)}
                label={formatMessage(messages.CreateSiteModalNameInputLabel)}
                id="name"
                name="name"
                data-testid="site-name"
                required
              />
              <SiteLanguageSelect
                data-testid="site-language"
                sx={{ '& .MuiFormHelperText-root': { display: 'none' } }}
              />
            </Stack>
            <Typography component="div" color="secondary.main" variant="body2">
              <FormattedMessage {...messages.createSiteModalListSiteLanguage} />
            </Typography>
          </form>
        </FormProvider>
      </Stack>
    </ActionModal>
  );
}

export default CreateSiteModal;
