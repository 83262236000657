import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReadOnlyField } from '../../../components';
import messages from '../messages';
import { sharedStyles } from '../styles';

const styles = {
  my2nId: {
    color: 'secondary.main',
    fontWeight: 'bold',
  },
  normal: {
    fontWeight: 'regular',
  },
};

function DeviceCompanyDetails({ deviceSite }) {
  return (
    <Grid container direction="column" item lg={3} sm={6} spacing={2}>
      <Grid item>
        <ReadOnlyField
          label={<FormattedMessage {...messages.userLoserDeviceCompany} />}
          value={
            <Box component="span" sx={sharedStyles.bold}>
              {deviceSite.company.name}
            </Box>
          }
        />
      </Grid>
      <Grid item>
        <ReadOnlyField
          label={
            <span>
              <FormattedMessage {...messages.userLoserDeviceSite} />{' '}
              <Box component="span" sx={styles.normal}>
                <FormattedMessage {...messages.userLoserDeviceMy2nId} />
              </Box>
            </span>
          }
          value={
            <span>
              <Box component="span" sx={sharedStyles.bold}>
                {deviceSite.name}
              </Box>
              <Box component="span" sx={styles.my2nId}>
                {' ('}
                {deviceSite.my2nId}
                {')'}
              </Box>
            </span>
          }
        />
      </Grid>
    </Grid>
  );
}

DeviceCompanyDetails.propTypes = {
  deviceSite: PropTypes.shape({
    company: PropTypes.shape({
      name: PropTypes.string,
    }),
    my2nId: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};

export default DeviceCompanyDetails;
