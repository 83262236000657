import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import ReportProblem from '@mui/icons-material/ReportProblem';
import { Alert } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ApartmentLabelRequest, useUpdateApartmentLabelMutation } from '../../../../../apiRtk/be4feApi';
import ActionModal from '../../../../../components/_DesignSystemWrappers/Modal/ActionModal';
import { OutlinedTextField } from '../../../../../components/HookFormFields/TextField';
import { getUsersInApartments } from '../../../../../modules/apartmentAdmin/actions';
import { IApartmentIdsInfo } from '../../../../../modules/apartments/store';
import { IModalBase } from '../../../../../modules/commonTypes';
import { TYPE_DESCRIPTOR } from '../../../../../modules/notifications';
import { addSnackbarError, addSnackbarSuccess } from '../../../../../modules/notifications/actions';
import { getContactLabelsValue } from './helpers';
import messages from './messages';
import { labelValidationSchema } from './validationScheme';

interface IChangeApartmentLabelModalProps extends IModalBase {
  apartmentIdsInfo: IApartmentIdsInfo;
  contactLabels?: string[],
}

function ChangeApartmentLabelModal({ apartmentIdsInfo, contactLabels, onClose, open }: IChangeApartmentLabelModalProps) {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [updateApartmentLabel] = useUpdateApartmentLabelMutation();
  const contactLabel = getContactLabelsValue({ formatMessage, labels: contactLabels || [] });

  const formMethods = useForm<ApartmentLabelRequest>({
    defaultValues: {
      label: contactLabel.label,
    },
    mode: 'onChange',
    resolver: yupResolver(labelValidationSchema(formatMessage)),
  });

  function handleSubmit(formData: ApartmentLabelRequest) {
    updateApartmentLabel({
      apartmentId: apartmentIdsInfo.apartmentId,
      apartmentLabelRequest: {
        label: formData.label,
      },
      companyId: apartmentIdsInfo.companyId,
      siteId: apartmentIdsInfo.siteId,
    }).unwrap()
      .then(() => {
        dispatch(addSnackbarSuccess({ ...messages.AAmodalChangeLabelSuccess }));
      })
      .catch(() =>
        dispatch(addSnackbarError({ ...messages.AAmodalChangeLabelError }, TYPE_DESCRIPTOR)))
      .finally(() => {
        dispatch(getUsersInApartments());
        onClose()
      })
  }

  return (
    <ActionModal
      icon={<CheckIcon />}
      open={open}
      isPrimaryButtonDisabled={!formMethods.formState.isDirty || !formMethods.formState.isValid}
      onClose={onClose}
      title={formatMessage(messages.AAmodalChangeLabelTitle)}
      muiDialogProps={{ maxWidth: 'xs' }}
      primaryButtonText={formatMessage(messages.AAmodalChangeLabelSubmit)}
      primaryButtonAction={formMethods.handleSubmit(handleSubmit)}
      secondaryButtonText={formatMessage(messages.AAmodalChangeLabelCancel)}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
          <Box pt={2}>
            <FormattedMessage {...messages.AAmodalChangeLabelText} />
          </Box>
          <Box pt={2} pb={1}>
            <OutlinedTextField
              disabled={(contactLabels?.length ?? 0) === 0}
              placeholder={contactLabel.placeholder}
              fullWidth
              name="label"
              label={formatMessage(messages.AAmodalChangeLabelLabel)}
              helperText={(contactLabels?.length ?? 0) > 1 ? formatMessage(messages.AAmodalChangeLabelHelperDifferent) : undefined}
            />
          </Box>
          {(contactLabels?.length ?? 0) === 0 && (
            <Box pb={2}>
              <Alert
                icon={<ReportProblem />}
                severity="warning"
              >
                <FormattedMessage {...messages.AAmodalChangeLabelWarningAlertNotAdded} />
              </Alert>
            </Box>
          )}
        </form>
      </FormProvider>
    </ActionModal>
  )
}

export default ChangeApartmentLabelModal;
