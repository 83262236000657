import { defineMessages } from 'react-intl';

export default defineMessages({
  UserApartmentModalCancelButton: {
    defaultMessage: 'Cancel',
    description: 'Site users set apartment modal - cancel button',
    id: 'userApartmentModal.cancelButton',
  },
  UserApartmentModalDescription: {
    defaultMessage: 'Apartments group users and devices together for easier administration.',
    description: 'Site users set apartment modal - description',
    id: 'userApartmentModal.description',
  },
  UserApartmentModalHeader: {
    defaultMessage: 'Set apartment to user',
    description: 'Site users set apartment modal - modal header',
    id: 'userApartmentModal.header',
  },
  UserApartmentModalSetApartmentButton: {
    defaultMessage: 'Set Apartment',
    description: 'Site users set apartment modal - set apartment button',
    id: 'userApartmentModal.setApartmentButton',
  },
});
