import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FE_ROLES } from '../../../config/consts';
import { getDataGridSelector } from '../../../helpers/dataGrid';
import deviceActions from '../../../modules/devices';
import userActions, { NAME as USER_MODULE, selectors } from '../../../modules/user';
import InfoAlert from './InfoAlert';
import SiteUserDevicesDataGrid from './SiteUserDevicesDataGrid';

function Devices() {
  const dispatch = useDispatch();
  const { devicesDataGrid, userData } = {
    devicesDataGrid: useSelector((state) => getDataGridSelector(state, USER_MODULE, 'userDetail.devices')),
    userData: useSelector(selectors.getCurrentUserDetail),
  };

  return userData?.role === FE_ROLES.LOGIN_LESS ? (
    <InfoAlert />
  ) : (
    <SiteUserDevicesDataGrid
      {...devicesDataGrid}
      onLoadDevices={(...callbacks) => dispatch(userActions.getSiteUserDevicesList(...callbacks))}
      onVerify={(...callbacks) => dispatch(deviceActions.verifyDeviceShowModal(...callbacks))}
    />
  );
}

export default Devices;
