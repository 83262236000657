import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const RenewServiceDialog = ({ handleCancel, handleConfirm, open }) => (
  <Dialog onClose={handleCancel} open={open} sx={{ m: 3 }}>
    <DialogContent>
      <DialogContentText sx={{ color: 'black', mt: 2 }}>
        <FormattedMessage {...messages.renewMobileVideoDialogTitle} />
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button color="primary" onClick={handleCancel}>
        <FormattedMessage {...messages.renewMobileVideoDialogCancel} />
      </Button>
      <Button color="primary" onClick={handleConfirm} variant="contained">
        <FormattedMessage {...messages.renewMobileVideoDialogConfirm} />
      </Button>
    </DialogActions>
  </Dialog>
);

RenewServiceDialog.propTypes = {
  handleCancel: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

RenewServiceDialog.defaultProps = {
  open: false,
};

export default RenewServiceDialog;
