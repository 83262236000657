import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../config/consts';
import { DEVICE_TYPE, SERVICE } from '../../config/devices';
import { useBreakpoints } from '../../helpers/useBreakpoints';
import { isNotVerified } from '../../modules/devices/helpers/helpers';
import { isFetchingMessages } from '../../modules/localization/selectors';
import ApartmentNameChip from '../ApartmentNameChip';
import DataGrid from '../DataGrid';
import NameColumn from '../DataGrid/NameColumn';
import DeviceModelName from '../DeviceModelName';
import DeviceStatus from '../DeviceStatus';
import DeviceTypeIcon from '../DeviceTypeIcon';
import EllipsisText from '../EllipsisText';
import messages from './messages';
import NoRowsOverlay from './NoRowsOverlay';
import QrButton from './QrButton';
import QrInfoDialog from './QrInfoDialog';

const UserProfileDevicesDataGrid = ({
  data,
  isFetching,
  onOrderBy,
  onRowsPerPageChange,
  onSelectPage,
  order,
  page,
  resultsFiltered,
  resultsTotal,
  rowsPerPage,
  userAdminSites,
}) => {
  const { formatMessage } = useIntl();
  const [isQrOpen, setIsQrOpen] = useState(false);
  const [qrDevice, setQrDevice] = useState({
    id: '',
    legacyAccessEnabled: true,
    password: '',
    src: '',
  });
  const navigate = useNavigate();
  const { downSm, upLg, upMd, upSm } = useBreakpoints();
  const isLocalizationLoaded = useSelector(isFetchingMessages);

  const handleQrDialogOpen = (deviceData) => {
    setQrDevice({
      id: deviceData?.services?.[SERVICE.MOBILE_VIDEO]?.sipNumber,
      legacyAccessEnabled: deviceData?.services?.[SERVICE.NOTIFICATION]?.legacyAccessEnabled,
      password: deviceData?.services?.[SERVICE.MOBILE_VIDEO]?.sipPassword,
      src: deviceData?.services?.[SERVICE.NOTIFICATION]?.qrCodeUrl,
    });
    setIsQrOpen(true);
  };

  const loadingWrapper = (children) => (isFetching ? <Skeleton animation="wave" height={25} width="80%" /> : children);

  const columns = useMemo(
    () => [
      {
        disableColumnMenu: true,
        field: 'name',
        flex: 1,
        headerName: formatMessage(downSm ? messages.columnsDeviceNameMobile : messages.columnsDeviceNameDesktop),
        renderCell: (device) =>
          loadingWrapper(
            device?.value && (
              <Tooltip title={<DeviceModelName deviceType={device.value?.deviceType} type={device.value?.type} />}>
                <Box
                  component="span"
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    overflow: 'hidden',
                  }}
                >
                  <NameColumn
                    iconComponent={
                      <DeviceTypeIcon
                        deviceType={device.value?.deviceType}
                        error={isNotVerified(device.value?.verificationStatus)}
                        small
                        type={device.value?.type}
                      />
                    }
                    name={device.value?.name}
                  />
                </Box>
              </Tooltip>
            )
          ),
        sortable: true,
      },
      {
        disableColumnMenu: true,
        field: 'site',
        flex: 1,
        headerName: formatMessage(messages.columnsSite),
        renderCell: (device) =>
          loadingWrapper(device?.value && <EllipsisText>{device.value?.site?.name || ''}</EllipsisText>),
        sortable: true,
      },
      {
        disableColumnMenu: true,
        field: 'apartment',
        flex: 1,
        headerName: formatMessage(messages.columnsApartment),
        renderCell: (device) =>
          loadingWrapper(
            device?.value && (
              <ApartmentNameChip name={device.value?.apartment?.name} number={device.value?.apartment?.number} />
            )
          ),
        sortable: false,
      },
      {
        disableColumnMenu: true,
        field: 'status',
        headerName: formatMessage(downSm ? messages.columnsDeviceStatusMobile : messages.columnsDeviceStatusDesktop),
        renderCell: (device) => loadingWrapper(device?.value && <DeviceStatus device={device.value} />),
        sortable: false,
        width: 170,
      },
      {
        align: 'right',
        disableColumnMenu: true,
        field: 'loginInfo',
        headerAlign: 'right',
        headerName: getLoginInfoHeaderName(data, upSm, formatMessage),
        renderCell: (device) =>
          loadingWrapper(
            device?.value &&
              (device.value?.services?.[SERVICE.NOTIFICATION]?.qrCodeUrl ? (
                <QrButton
                  isIcon={!upSm}
                  label={formatMessage(messages.actionQrInfo)}
                  onClick={(event) => {
                    handleQrDialogOpen(device.value);
                    event.stopPropagation();
                  }}
                />
              ) : (
                ''
              ))
          ),
        sortable: false,
        width: upSm ? 160 : 55,
      },
    ],
    [downSm, upSm, upMd, upLg, isFetching, isLocalizationLoaded]
  );

  const rows = useMemo(
    () =>
      data.map((device) => ({
        apartment: device,
        id: device.id,
        isClickable: userAdminSites.includes(device.site?.id),
        loginInfo: device,
        name: device,
        site: device,
        status: device,
      })),
    [data]
  );

  return (
    <>
      <DataGrid
        data-testid="user-profile-device-grid"
        columns={columns}
        columnVisibilityModel={{
          apartment: upLg,
          site: upMd,
        }}
        disableRowSelectionOnClick
        hideFooterPagination={isFetching}
        loading={isFetching}
        noRowsOverlay={<NoRowsOverlay />}
        onCellClick={(params) => {
          if (userAdminSites.includes(params.value?.site?.id)) {
            navigate(
              PATHS.DEVICE_DETAIL.replace(':companyId', params.value?.site?.company?.id)
                .replace(':siteId', params.value?.site?.id)
                .replace(':deviceId', params.value?.id)
            );
          }
        }}
        onOrderBy={onOrderBy}
        onRowsPerPageChange={onRowsPerPageChange}
        onSelectPage={onSelectPage}
        order={order}
        page={page}
        resultsFiltered={resultsFiltered}
        resultsTotal={resultsTotal}
        rows={rows}
        rowsPerPage={rowsPerPage}
      />
      <QrInfoDialog
        deviceId={qrDevice.id}
        devicePassword={qrDevice.password}
        legacyAccessEnabled={qrDevice.legacyAccessEnabled}
        onCancel={() => setIsQrOpen(false)}
        open={isQrOpen}
        src={qrDevice.src}
      />
    </>
  );
};

UserProfileDevicesDataGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filter: PropTypes.shape({ fulltext: PropTypes.string }).isRequired,
  isFetching: PropTypes.bool.isRequired,
  onOrderBy: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  onSelectPage: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  resultsFiltered: PropTypes.number.isRequired,
  resultsTotal: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  userAdminSites: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default UserProfileDevicesDataGrid;

function getLoginInfoHeaderName(devices, upSmBreakpoint, formatMessage) {
  if (!upSmBreakpoint) {
    return '';
  }

  const containsMigratedDevice = devices
    .filter((device) => device.type === DEVICE_TYPE.HIPMO)
    .some((device) => device.services[SERVICE.NOTIFICATION]?.legacyAccessEnabled === false);

  return containsMigratedDevice
    ? formatMessage(messages.columnsLoginAfterMigration)
    : formatMessage(messages.columnsLoginInfo);
}
