import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ListHeaderLabel } from '../../../components';
import messages from './messages';

const ListHeader = ({ isDoorStation }: { isDoorStation: boolean }) => (
  <ListItem divider>
    <Grid container justifyContent="center" py={2} spacing={2}>
      <Grid item xs={1}>
        <ListHeaderLabel>
          <FormattedMessage {...messages.listHeaderSwitch} />
        </ListHeaderLabel>
      </Grid>
      <Grid item xs={1}>
        <ListHeaderLabel>
          <FormattedMessage {...messages.listHeaderSwitchEnabled} />
        </ListHeaderLabel>
      </Grid>
      <Grid item xs={isDoorStation ? 4 : 3}>
        <ListHeaderLabel>
          <FormattedMessage {...messages.listHeaderDtmfCode} />
        </ListHeaderLabel>
      </Grid>
      <Grid item xs={isDoorStation ? 6 : 4}>
        <ListHeaderLabel>
          <FormattedMessage
            {...messages.listHeaderFunctionName}
            values={{ small: (chunks) => <small>{chunks}</small> }}
          />
        </ListHeaderLabel>
      </Grid>
      {!isDoorStation && (
        <Grid item xs={3}>
          <ListHeaderLabel>
            <FormattedMessage {...messages.listHeaderSwitchSettingsSource} />
          </ListHeaderLabel>
        </Grid>
      )}
    </Grid>
  </ListItem>
);

export default ListHeader;
