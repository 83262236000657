import {
  IDeviceElevatorIntegrationSet,
  ISiteElevatorIntegrationSettings,
  IUserElevatorIntegration,
} from '../../api/elevatorIntegrationTS';
import { TId, TUuid } from '../commonTypes';
import { types } from './types';

export const getSiteElevatorIntegration = (companyId: TId, siteId: TId) => ({
  companyId,
  siteId,
  type: types.GET_SITE_ELEVATOR_INTEGRATION_REQUEST,
});

export const setSiteElevatorIntegration = (companyId: TId, siteId: TId, data: ISiteElevatorIntegrationSettings) => ({
  companyId,
  data: data,
  siteId,
  type: types.SET_SITE_ELEVATOR_INTEGRATION_REQUEST,
});

export const getUserElevatorIntegration = (companyId: TId, siteId: TId, userId: TUuid) => ({
  companyId,
  siteId,
  type: types.GET_USER_ELEVATOR_INTEGRATION_REQUEST,
  userId,
});

export const setUserElevatorIntegration = (
  companyId: TId,
  siteId: TId,
  userId: TUuid,
  data: IUserElevatorIntegration
) => ({
  companyId,
  data: data,
  siteId,
  type: types.SET_USER_ELEVATOR_INTEGRATION_REQUEST,
  userId,
});

export const getDeviceElevatorIntegration = (companyId: TId, siteId: TId, deviceId: TId) => ({
  companyId,
  deviceId,
  siteId,
  type: types.GET_DEVICE_ELEVATOR_INTEGRATION_REQUEST,
});

export const setDeviceElevatorIntegration = (
  companyId: TId,
  siteId: TId,
  deviceId: TId,
  data: IDeviceElevatorIntegrationSet
) => ({
  companyId,
  data: data,
  deviceId,
  siteId,
  type: types.SET_DEVICE_ELEVATOR_INTEGRATION_REQUEST,
});

export const getElevatorIntegrationFloorMapping = (companyId: TId, siteId: TId) => ({
  companyId,
  siteId,
  type: types.GET_ELEVATOR_INTEGRATION_FLOOR_MAPPING_REQUEST,
});
