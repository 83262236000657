import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  DataGrid,
  DeleteDeviceModal,
  DeviceModelName,
  DeviceStatus,
  DeviceTypeIcon,
  IconServices,
} from '../../../components';
import NameColumn from '../../../components/DataGrid/NameColumn';
import RowMenu from '../../../components/DataGrid/RowMenu';
import { isActive } from '../../../components/DeviceDataGrid/helpers';
import { PATHS } from '../../../config/consts';
import { SERVICE } from '../../../config/devices';
import { getDataGridSelector, useDataGrid } from '../../../helpers/dataGrid';
import { useUrlParams } from '../../../helpers/urlParams';
import useBreakpoints from '../../../helpers/useBreakpoints';
import apartmentActions, { NAME as APARTMENT_MODULE } from '../../../modules/apartments';
import devicesActions from '../../../modules/devices';
import { showErrorBadge, showWarningBadge } from '../../../modules/devices/helpers/helpers';
import { isFetchingMessages } from '../../../modules/localization/selectors';
import messages from '../messages';
import DeviceRowMenu from './DeviceRowMenu';

// eslint-disable-next-line max-statements
const ApartmentDevices = ({
  data,
  didInvalid,
  filter,
  isFetching,
  onLoadDevices,
  onOpenDeleteDeviceModal,
  order,
  page,
  resultsFiltered,
  resultsTotal,
  rowsPerPage,
}) => {
  const { apartmentId, companyId, siteId } = useUrlParams();
  const { onOrderBy, onRowsPerPageChange, onSelectPage } = useDataGrid(
    onLoadDevices,
    {
      apartmentId,
      companyId,
      emptyApartment: apartmentId === null,
      emptySite: siteId === null,
      filter,
      order,
      page,
      rowsPerPage,
      siteId,
    },
    didInvalid,
    isFetching
  );

  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const { upMd } = useBreakpoints();
  const isLocalizationLoaded = useSelector(isFetchingMessages);

  const columns = useMemo(
    () => [
      {
        disableColumnMenu: true,
        field: 'name',
        flex: 1,
        headerName: formatMessage(messages.apartmentDevicesDeviceName),
        renderCell: (cell) =>
          isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <Tooltip title={<DeviceModelName deviceType={cell.value?.deviceType} type={cell.value?.type} />}>
              <Box
                component="span"
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  overflow: 'hidden',
                }}
              >
                <NameColumn
                  iconComponent={
                    <DeviceTypeIcon
                      deviceType={cell.value?.deviceType}
                      showErrorBadge={showErrorBadge(
                        cell.value?.services?.[SERVICE.ACS]?.firmwareStatus,
                        cell.value?.verificationStatus
                      )}
                      showWarningBadge={showWarningBadge(
                        cell.value?.services?.[SERVICE.ACS]?.firmwareStatus,
                        cell.value?.verificationStatus
                      )}
                      small
                      type={cell.value?.type}
                    />
                  }
                  name={cell.value?.name}
                />
              </Box>
            </Tooltip>
          ),
        sortable: true,
      },
      {
        disableColumnMenu: true,
        field: 'services',
        flex: 1,
        headerName: formatMessage(messages.apartmentDevicesActiveServices),
        renderCell: (cell) =>
          isFetching ? <Skeleton animation="wave" height={25} width="80%" /> : <IconServices item={cell.value} />,
        sortable: false,
      },
      {
        disableColumnMenu: true,
        field: 'status',
        flex: 1,
        headerName: formatMessage(messages.apartmentDevicesDeviceStatus),
        renderCell: (cell) =>
          isFetching ? <Skeleton animation="wave" height={25} width="80%" /> : <DeviceStatus device={cell.value} />,
        sortable: false,
      },
      {
        disableColumnMenu: true,
        field: 'rowMenu',
        headerName: '',
        renderCell: (cell) =>
          isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <RowMenu
              menuItems={
                <DeviceRowMenu
                  companyId={companyId}
                  deviceId={cell.value.id}
                  onOpenDeleteDeviceModal={onOpenDeleteDeviceModal}
                  siteId={siteId}
                />
              }
            />
          ),
        sortable: false,
        width: 65,
      },
    ],
    [upMd, isFetching, isLocalizationLoaded]
  );

  const rows = useMemo(
    () =>
      data.map((device) => ({
        id: device.id,
        isClickable: isActive(device?.services),
        name: device,
        rowMenu: device,
        services: device,
        status: device,
      })),
    [data]
  );

  return (
    <>
      <DataGrid
        columns={columns}
        columnVisibilityModel={{
          services: upMd,
        }}
        disableRowSelectionOnClick
        hideFooterPagination={isFetching}
        loading={isFetching}
        onCellClick={(params) => {
          if (params.field !== 'rowMenu') {
            navigate(
              PATHS.DEVICE_DETAIL.replace(':companyId', params.value?.site?.company?.id)
                .replace(':siteId', params.value?.site?.id)
                .replace(':deviceId', params.value?.id)
            );
          }
        }}
        onOrderBy={onOrderBy}
        onRowsPerPageChange={onRowsPerPageChange}
        onSelectPage={onSelectPage}
        order={order}
        page={page}
        resultsFiltered={resultsFiltered}
        resultsTotal={resultsTotal}
        rows={rows}
        rowsPerPage={rowsPerPage}
      />
      <DeleteDeviceModal />
    </>
  );
};

ApartmentDevices.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  didInvalid: PropTypes.bool.isRequired,
  filter: PropTypes.shape({}).isRequired,
  isFetching: PropTypes.bool.isRequired,
  onLoadDevices: PropTypes.func.isRequired,
  onOpenDeleteDeviceModal: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  resultsFiltered: PropTypes.number.isRequired,
  resultsTotal: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  ...getDataGridSelector(state, APARTMENT_MODULE, 'devicesList'),
});

const mapDispatchToProps = {
  onLoadDevices: apartmentActions.getApartmentDevicesList,
  onOpenDeleteDeviceModal: devicesActions.deleteDeviceModalShow,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApartmentDevices);
