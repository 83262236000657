import { Check, Send } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { FilledSuccessBar } from '../../../../components/_DesignSystemWrappers/SuccessBar/FilledSuccessBar';
import { OutlinedTextField } from '../../../../components/HookFormFields/TextField';
import { PATHS } from '../../../../config/consts';
import { useOryRecoveryApi } from '../../../../ory/hooks/oryApi';
import { IOryRecoveryFlow } from '../../../../ory/types';
import { RouterLink } from '../Link/PrimaryLink';
import messages from './messages';
import { useRecoveryCodeForm } from './useRecoveryCodeForm';

export interface IRecoveryFormProps {
  recoveryFlowData: IOryRecoveryFlow;
  email: string;
  onSuccess: () => void;
  onRecoveryFlowRestart: (newFlowId?: string) => Promise<boolean>;
  onRecoveryProcessRestart: () => void;
}

export function RecoveryCodeForm({
  email,
  onRecoveryFlowRestart,
  onRecoveryProcessRestart,
  onSuccess,
  recoveryFlowData,
}: IRecoveryFormProps) {
  const { formatMessage } = useIntl();

  const { isOryApiFetching, resendRecoveryCode } = useOryRecoveryApi();
  const { buttonsDirection, buttonsSpacing, formData, formSubmit, hasNewFlowData, resendCode } = useRecoveryCodeForm({
    email,
    onRecoveryFlowRestart,
    onRecoveryProcessRestart,
    onSuccess,
    recoveryFlowData,
    resendRecoveryCode,
  });

  return (
    <FormProvider {...formData}>
      <form onSubmit={formData.handleSubmit(formSubmit)}>
        <Stack direction="column" spacing={2}>
          <FilledSuccessBar title={formatMessage(messages.ImsResetPasswordAlertTitle, { email })}>
            <Typography>
              <FormattedMessage {...messages.ImsResetPasswordAlertBody} />
            </Typography>
          </FilledSuccessBar>
          <div>
            <OutlinedTextField
              label={<FormattedMessage {...messages.ImsResetPasswordRecoveryCodeLabel} />}
              name="recoveryCode"
              disabled={hasNewFlowData}
              required
              fullWidth
              helperText={<FormattedMessage {...messages.ImsResetPasswordRecoveryCodeHelper} />}
              sx={{ mb: 1, mt: 2 }}
            />
          </div>

          <Stack
            direction={buttonsDirection}
            spacing={buttonsSpacing}
            justifyContent="space-between"
            alignItems="center"
          >
            <RouterLink to={PATHS.LOGIN}>
              <FormattedMessage {...messages.ImsResetPasswordButtonBackToLogin} />
            </RouterLink>

            <Stack direction={buttonsDirection} spacing={buttonsSpacing}>
              <Button
                color="primary"
                size="large"
                variant="outlined"
                startIcon={<Send />}
                disabled={isOryApiFetching}
                onClick={resendCode}
              >
                <FormattedMessage {...messages.ImsResetPasswordButtonResendCode} />
              </Button>
              <Button
                disabled={!formData.formState.isValid || isOryApiFetching || hasNewFlowData}
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                startIcon={<Check />}
              >
                <FormattedMessage {...messages.ImsResetPasswordButtonSubmit} />
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
}
