import Box from '@mui/material/Box';
import React from 'react';
import { useSelector } from 'react-redux';
import { getCompanyId } from '../../modules/company/selectors';
import { getSiteId } from '../../modules/sites/selectors';
import ApartmentList from './ApartmentList';
import CommonList from './CommonList';
import CompanyList from './CompanyList';
import NonAdminCommonList from './NonAdminCommonList';
import SiteList from './SiteList';
import { styles } from './styles/Sidebar';

const SidebarNavigation = ({ onCloseSidebarMobile }: { onCloseSidebarMobile: () => void }) => {
  const companyId = useSelector(getCompanyId);
  const siteId = useSelector(getSiteId);

  return (
    <>
      {companyId && !siteId && <CompanyList onCloseSidebarMobile={onCloseSidebarMobile} />}
      {companyId && siteId && <SiteList onCloseSidebarMobile={onCloseSidebarMobile} />}
      {!companyId && !siteId && <ApartmentList onCloseSidebarMobile={onCloseSidebarMobile} />}
      <Box sx={styles.drawerFooter}>
        {companyId ? (
          <CommonList onCloseSidebarMobile={onCloseSidebarMobile} />
        ) : (
          <NonAdminCommonList onCloseSidebarMobile={onCloseSidebarMobile} />
        )}
      </Box>
    </>
  );
};

export default SidebarNavigation;
