import { AnyAction, ReducersMapObject } from 'redux';
import rootReducerToolkit from '../reducersToolkit';
import accessLog from './accessLog/reducer';
import accessSettings from './accessSettings/reducer';
import { apartmentAdminReducers } from './apartmentAdmin/reducer';
import apartments from './apartments/reducer';
import { authReducers } from './auth/reducer';
import billing from './billing/reducer';
import common from './common/reducer';
import company from './company/reducer';
import cookieSettings from './cookies/reducer';
import devices from './devices/reducer';
import { elevatorIntegrationReducers } from './elevatorIntegration/reducer';
import { infrastructureReducers } from './infrastructure/reducer';
import { localizationReducers } from './localization/reducer';
import notifications from './notifications/reducer';
import payments from './payments/reducer';
import phonebooks from './phonebooks/reducer';
import sites from './sites/reducer';
import { IRootStore } from './store';
import subscription from './subscription/reducer';
import user from './user/reducer';

export function getAllReducers(): ReducersMapObject<IRootStore, AnyAction> {
  return {
    ...rootReducerToolkit,
    accessLog,
    accessSettings,
    apartmentAdmin: apartmentAdminReducers,
    apartments,
    auth: authReducers,
    billing,
    common,
    company: company as any,
    cookieSettings,
    devices: devices as any,
    elevatorIntegration: elevatorIntegrationReducers,
    infrastructure: infrastructureReducers,
    localization: localizationReducers,
    notifications,
    payments,
    phonebooks,
    sites: sites as any,
    subscription,
    user: user as any,
  };
}
