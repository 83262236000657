import { IApartmentIdsInfo } from '../../modules/apartments/store';
import { composedIdSeparator } from './config';

export function parseApartmentComposedId(composedIdParam: string): IApartmentIdsInfo | false {
  const parts = composedIdParam.split(composedIdSeparator);
  if (parts.length === 3 && parts.filter((p) => !isNaN(parseInt(p, 10))).length === 3) {
    return {
      apartmentId: parseInt(parts[2], 10),
      companyId: parseInt(parts[0], 10),
      siteId: parseInt(parts[1], 10),
    };
  }
  return false;
}
