import DashboardIcon from '@mui/icons-material/Dashboard';
import ApartmentsIcon from '@mui/icons-material/FolderShared';
import PaymentsIcon from '@mui/icons-material/Payments';
import SettingsIcon from '@mui/icons-material/Settings';
import CompanyAdminsIcon from '@mui/icons-material/SupervisedUserCircle';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import ViewListIcon from '@mui/icons-material/ViewList';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import CallSettingsIcon from '../../assets/call_settings.svg';
import DevicesIcon from '../../assets/devices.svg';
import SubscriptionIcon from '../../assets/subscription.svg';
import { PATHS } from '../../config/consts';
import { isActivable, isExpirable } from '../../helpers/mobileVideo';
import { canPurchaseIntercomLicenceOnNonstandardModel } from '../../helpers/subscriptionsHelpers';
import { useGetActualCompanyId } from '../../hooks/companyHooks';
import { useGetActualSiteId } from '../../hooks/siteHooks';
import * as siteActions from '../../modules/sites/actions';
import * as sitesSelectors from '../../modules/sites/selectorsTS';
import { useAppContext } from '../../pages/App/AppContextProvider';
import messages from './messages';
import SidebarItem from './SidebarItem';

function SiteList({ onCloseSidebarMobile }: { onCloseSidebarMobile: () => void }) {
  const companyId = useGetActualCompanyId();
  const siteId = useGetActualSiteId();
  const dispatch = useDispatch();
  const { feSiteRoles, userRoles } = useAppContext();
  const licenceModel = useSelector(sitesSelectors.getDashboardMvServiceLicenceModel);
  const formatUrl = (path: string) => path.replace(':companyId', companyId).replace(':siteId', siteId);

  useEffect(() => {
    if (licenceModel === undefined) {
      dispatch(siteActions.getSiteDashboard(companyId, siteId));
    }
  }, [licenceModel?.status]);

  return (
    <List>
      <SidebarItem
        activePaths={[PATHS.SITE_DASHBOARD]}
        icon={<DashboardIcon />}
        id="dashboard-link"
        link={formatUrl(PATHS.SITE_DASHBOARD)}
        onClick={onCloseSidebarMobile}
        title={<FormattedMessage {...messages.SidebarSiteListSiteDashboard} />}
        disabled={false}
        showOnlyTitle={false}
        showSelected
        showTitleOnHover
      />
      <SidebarItem
        activePaths={[
          PATHS.DEVICES_OVERVIEW,
          PATHS.DEVICE,
          PATHS.DEVICE_DETAIL,
          PATHS.DEVICE_UNLOCK_SETTINGS,
          PATHS.DEVICE_CALL_SETTINGS,
          PATHS.DEVICE_PHONEBOOKS,
          PATHS.DEVICE_SWITCH_CODES,
          PATHS.DEVICE_ACCESS_SETTINGS,
        ]}
        icon={<ReactSVG src={DevicesIcon} wrapper="span" />}
        id="devices-link"
        link={formatUrl(PATHS.DEVICES_OVERVIEW)}
        onClick={onCloseSidebarMobile}
        title={<FormattedMessage {...messages.SidebarSiteListDevices} />}
        disabled={false}
        showOnlyTitle={false}
        showSelected
        showTitleOnHover
      />
      <SidebarItem
        activePaths={[
          PATHS.SITE_USERS,
          PATHS.SITE_USERS_IMPORT,
          PATHS.SITE_USER,
          PATHS.SITE_USER_DETAIL,
          PATHS.SITE_USER_DEVICES,
          PATHS.SITE_USER_ACCESS,
        ]}
        icon={<CompanyAdminsIcon />}
        id="users-link"
        link={formatUrl(PATHS.SITE_USERS)}
        onClick={onCloseSidebarMobile}
        title={<FormattedMessage {...messages.SidebarSiteListUsersAndAdmins} />}
        disabled={false}
        showOnlyTitle={false}
        showSelected
        showTitleOnHover
      />
      <SidebarItem
        activePaths={[
          PATHS.APARTMENTS_OVERVIEW,
          PATHS.APARTMENT,
          PATHS.APARTMENT_DETAIL,
          PATHS.APARTMENT_DEVICES,
          PATHS.APARTMENT_USERS,
          PATHS.APARTMENT_ACCESS,
        ]}
        icon={<ApartmentsIcon />}
        id="apartments-link"
        link={formatUrl(PATHS.APARTMENTS_OVERVIEW)}
        onClick={onCloseSidebarMobile}
        title={<FormattedMessage {...messages.SidebarSiteListApartments} />}
        disabled={false}
        showOnlyTitle={false}
        showSelected
        showTitleOnHover
      />
      <Divider />
      <SidebarItem
        activePaths={[
          PATHS.CALL_SETTINGS,
          PATHS.CALL_SETTINGS_INTERCOMS,
          PATHS.CALL_SETTINGS_ANSWERING_UNITS,
          PATHS.CALL_SETTINGS_PHONEBOOKS,
          PATHS.PHONEBOOK_DETAIL,
        ]}
        icon={<ReactSVG src={CallSettingsIcon} wrapper="span" />}
        id="callsettings-link"
        link={formatUrl(PATHS.CALL_SETTINGS_INTERCOMS)}
        onClick={onCloseSidebarMobile}
        title={<FormattedMessage {...messages.SidebarSiteListCallSettings} />}
        disabled={false}
        showOnlyTitle={false}
        showSelected
        showTitleOnHover
      />
      <SidebarItem
        activePaths={[
          PATHS.ACCESS_SETTINGS,
          PATHS.ACCESS_SETTINGS_APARTMENTS,
          PATHS.ACCESS_SETTINGS_GROUP,
          PATHS.ACCESS_SETTINGS_DETAIL,
          PATHS.ACCESS_SETTINGS_DEVICES,
          PATHS.ACCESS_SETTINGS_USERS,
        ]}
        icon={<TransferWithinAStationIcon />}
        id="accessSettings-link"
        link={formatUrl(PATHS.ACCESS_SETTINGS)}
        onClick={onCloseSidebarMobile}
        title={<FormattedMessage {...messages.SidebarSiteListAccessSettingsOverview} />}
        disabled={false}
        showOnlyTitle={false}
        showSelected
        showTitleOnHover
      />
      <SidebarItem
        activePaths={[PATHS.ACCESS_LOG]}
        icon={<ViewListIcon />}
        id="accesslog-link"
        link={formatUrl(PATHS.ACCESS_LOG)}
        onClick={onCloseSidebarMobile}
        title={<FormattedMessage {...messages.SidebarSiteListAccessLog} />}
        disabled={false}
        showOnlyTitle={false}
        showSelected
        showTitleOnHover
      />
      <Divider />
      <SidebarItem
        activePaths={[
          PATHS.SUBSCRIPTION,
          PATHS.SUBSCRIPTION_MOBILE_VIDEO,
          PATHS.SUBSCRIPTION_LICENSES,
          PATHS.SUBSCRIPTION_ACTIVATION_CODE,
          PATHS.SUBSCRIPTION_VOUCHERS,
        ]}
        icon={<ReactSVG src={SubscriptionIcon} wrapper="span" />}
        id="subscription-link"
        link={formatUrl(PATHS.SUBSCRIPTION)}
        onClick={onCloseSidebarMobile}
        title={<FormattedMessage {...messages.SidebarSiteListSubscription} />}
        disabled={canPurchaseIntercomLicenceOnNonstandardModel(userRoles, licenceModel)}
        showOnlyTitle={false}
        showSelected
        showTitleOnHover
      />
      {!isActivable(licenceModel?.status) && !isExpirable(licenceModel?.status) && (
        <SidebarItem
          activePaths={[PATHS.SITE_PAYMENTS]}
          disabled={!feSiteRoles.isSiteAdminPayment}
          icon={<PaymentsIcon />}
          id="payments-link"
          link={formatUrl(PATHS.SITE_PAYMENTS)}
          onClick={onCloseSidebarMobile}
          title={<FormattedMessage {...messages.SidebarSiteListPaymentsOverview} />}
          showOnlyTitle={false}
          showSelected
          showTitleOnHover
        />
      )}
      <SidebarItem
        activePaths={[PATHS.SITE_SETTINGS, PATHS.SITE_SETTINGS_SITE_LANGUAGE, PATHS.SITE_SETTINGS_ELEVATOR_INTEGRATION]}
        icon={<SettingsIcon />}
        id="siteSettings-link"
        link={formatUrl(PATHS.SITE_SETTINGS_SITE_LANGUAGE)}
        onClick={onCloseSidebarMobile}
        title={<FormattedMessage {...messages.SidebarSiteListSiteSettings} />}
        disabled={false}
        showOnlyTitle={false}
        showSelected
        showTitleOnHover
      />
    </List>
  );
}

export default SiteList;
