import { IButtonRenderData } from '../../../../containers/DeviceDetail/CallSettings/Intercom/hooks/useGetStateData';
import * as consts from '../../../constants';
import { ContactItemAction } from '../../../constants';
import {
  ContactItemType,
  DeviceFeatureType,
  IApartmentContact,
  IContactItem,
  IRingingGroupContact,
} from '../../store/deviceStore';
import { CallSettingsDataManager, IContactData } from './callSettingsDataManager';

export function getNewContactItems(
  callSettingsDataManager: CallSettingsDataManager,
  buttonData: IButtonRenderData,
  featureType: DeviceFeatureType,
  isDevice: boolean
): IContactItem[] | undefined {
  if (featureType !== consts.contactList) {
    return undefined;
  }

  const contactItems: IContactItem[] = [];
  if (isDevice) {
    const members: number[] = [];
    buttonData.selectedOptions?.forEach((selectedDevice) => {
      members.push(callSettingsDataManager.getSelectedDeviceConfigId(selectedDevice.optionKey).mobileVideoId!);

      const localCalls = tryGetLocalCallsContactItem(callSettingsDataManager, selectedDevice.optionKey);
      if (localCalls !== undefined) {
        contactItems.push(localCalls);
      }

      const gsmCalls = tryGetGsmCallsContactItem(callSettingsDataManager, selectedDevice.optionKey);
      if (gsmCalls !== undefined) {
        contactItems.push(gsmCalls);
      }
    });

    const contactItem = createRingingGroupContactItemRequestObject(members);
    contactItems.push(createContactItemRequestObject(contactItem, consts.createRingingGroup));
  } else {
    const contactItem = createApartmentContactItemRequestObject(buttonData.selectedOptions![0].optionKey);
    contactItems.push(createContactItemRequestObject(contactItem, consts.createApartment));
  }

  return contactItems;
}

export interface IRequestContactItem extends IContactItem {
  action?: ContactItemAction;
}

export function getUpdatedContactItems(
  callSettingsDataManager: CallSettingsDataManager,
  buttonData: IButtonRenderData,
  featureType: DeviceFeatureType,
  isDevice: boolean,
  contactData: IContactData
): IRequestContactItem[] | undefined {
  if (featureType !== consts.contactList) {
    return undefined;
  }

  const contactItems: IRequestContactItem[] = [];
  if (isDevice || isDevice === undefined) {
    const members: number[] = [];
    buttonData.selectedOptions?.forEach((selectedDevice) => {
      members.push(callSettingsDataManager.getSelectedDeviceConfigId(selectedDevice.optionKey).mobileVideoId!);

      const localCalls = tryGetLocalCallsContactItem(callSettingsDataManager, selectedDevice.optionKey);
      if (localCalls !== undefined) {
        contactItems.push(localCalls);
      }

      const gsmCalls = tryGetGsmCallsContactItem(callSettingsDataManager, selectedDevice.optionKey);
      if (gsmCalls !== undefined) {
        contactItems.push(gsmCalls);
      }
    });
    const contactItemId = callSettingsDataManager.tryGetContactItemId(
      buttonData.externalId!,
      featureType,
      consts.ringingGroup
    );

    const contactItem = createRingingGroupContactItemRequestObject(
      members,
      contactItemId,
      buttonData.externalId,
      contactData?.sipNumber
    );

    if (contactItemId === undefined) {
      contactItems.push(createContactItemRequestObject(contactItem, consts.createRingingGroup));
    } else {
      contactItems.push(createContactItemRequestObject(contactItem, consts.updateRingingGroup));
    }
  } else {
    const contactItemId = callSettingsDataManager.tryGetContactItemId(
      buttonData.externalId!,
      featureType,
      consts.apartment
    );

    const contactItem = createApartmentContactItemRequestObject(
      buttonData.selectedOptions![0].optionKey,
      contactItemId,
      contactData?.sipNumber
    );

    if (contactItemId === undefined) {
      contactItems.push(createContactItemRequestObject(contactItem, consts.createApartment));
    } else {
      contactItems.push(createContactItemRequestObject(contactItem, consts.updateApartment));
    }
  }

  return contactItems;
}

function tryGetGsmCallsContactItem(
  callSettingsDataManager: CallSettingsDataManager,
  selectedDeviceId: number
): IGsmCallsContactItem | undefined {
  const { gatewayId, gsmCallsId } = callSettingsDataManager.getSelectedDeviceConfigId(selectedDeviceId);
  if (gsmCallsId !== undefined) {
    return {
      action: consts.gsmCallsAction,
      deviceConfigId: gsmCallsId,
      gatewayId: gatewayId!,
      type: consts.gsmCallsType,
    };
  }

  return undefined;
}
function tryGetLocalCallsContactItem(
  callSettingsDataManager: CallSettingsDataManager,
  selectedDeviceId: number
): ILocalCallsContactItem | undefined {
  const { localCallsId } = callSettingsDataManager.getSelectedDeviceConfigId(selectedDeviceId);
  if (localCallsId !== undefined) {
    return {
      action: consts.localCallsAction,
      deviceConfigId: localCallsId,
      type: consts.localCallsType,
    };
  }

  return undefined;
}

function createApartmentContactItemRequestObject(
  apartmentId: number,
  contactItemId?: number,
  sipNumber?: string
): IApartmentContact {
  return {
    apartmentId,
    id: contactItemId,
    sipNumber,
    type: consts.apartment,
  };
}

function createRingingGroupContactItemRequestObject(
  members: number[],
  contactItemId?: number,
  externalId?: string,
  sipNumber?: string
): IRingingGroupContact {
  return {
    externalId,
    gsmFallback: undefined,
    id: contactItemId,
    members,
    sipNumber,
    type: consts.ringingGroup,
  };
}

function createContactItemRequestObject(
  typeSpecificData: ContactItemType,
  action: ContactItemAction
): IRequestContactItem {
  return {
    ...typeSpecificData,
    action,
  };
}

interface IGsmCallsContactItem extends IContactItem {
  deviceConfigId: number;
  action: ContactItemAction;
  gatewayId: number;
}

interface ILocalCallsContactItem extends IContactItem {
  deviceConfigId: number;
  action: ContactItemAction;
}
