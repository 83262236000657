import { defineMessages } from 'react-intl';

export default defineMessages({
  UserAccessBtnValidityEdit: {
    defaultMessage: 'Change validity',
    id: 'user.access.btnValidityEdit',
  },
  UserAccessValidityEnd: {
    defaultMessage: '<typography>to</typography> {validityEnd} ',
    id: 'user.access.validityEnd',
  },
  UserAccessValidityLabel: {
    defaultMessage: 'Access validity',
    id: 'user.access.validityLabel',
  },
  UserAccessValidityStart: {
    defaultMessage: '<typography>from</typography> {validityStart}',
    id: 'user.access.validityStart',
  },
});
