import LocationOnIcon from '@mui/icons-material/LocationOn';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../config/consts';
import { useGetActualCompanyId } from '../../hooks/companyHooks';
import { useGetActualSiteId } from '../../hooks/siteHooks';
import { selectors } from '../../modules/common';
import SiteSwitcher from '../Navbar/components/siteMenu/SiteSwitcher';
import SideBarMenu from '../SideBarMenu';
import messages from './messages';

const styles = {
  switcher: {
    '& li, & div > div > div': {
      px: 2,
    },
  },
};

function SiteSwitcherMobile({ onDrawerClose, onOpenCreateSiteModal, onSetOpen, open }) {
  const navigate = useNavigate();
  const companyId = useGetActualCompanyId();
  const siteId = useGetActualSiteId();

  const data = useSelector(selectors.getDataSiteSwitcher);
  const isFetching = useSelector(selectors.isFetchingSiteSwitcher);

  function addSite() {
    onDrawerClose(false);
    onSetOpen(false);
    onOpenCreateSiteModal();
  }

  function selectSite(currentSiteId) {
    onDrawerClose(false);
    onSetOpen(false);
    navigate(PATHS.SITE_DASHBOARD.replace(':companyId', companyId).replace(':siteId', currentSiteId));
  }

  return (
    <SideBarMenu
      buttonEmptyLabel={<FormattedMessage {...messages.SiteSwitcherEmptyLabel} />}
      buttonItemLabel={<FormattedMessage {...messages.SiteSwitcherLabel} />}
      buttonStartIcon={<LocationOnIcon />}
      buttonValue={data.find((item) => item.id === siteId)?.name || ''}
      onButtonClick={() => onSetOpen(!open)}
      open={open}
    >
      <Box sx={styles.switcher}>
        <SiteSwitcher
          currentId={siteId}
          data={data}
          isFetching={isFetching}
          isSideBar
          onAdd={addSite}
          onSelect={selectSite}
        />
      </Box>
    </SideBarMenu>
  );
}

SiteSwitcherMobile.propTypes = {
  onDrawerClose: PropTypes.func.isRequired,
  onOpenCreateSiteModal: PropTypes.func.isRequired,
  onSetOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default SiteSwitcherMobile;
