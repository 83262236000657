import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { ButtonDanger, Modal } from '../../../components';
import actions, { selectors } from '../../../modules/phonebooks';
import messages from '../messages';

const styles = {
  actions: (theme) => ({
    [theme.breakpoints.down('sm')]: {
      '& button:first-of-type': {
        mt: 1,
      },
      alignItems: 'flex-end',
      display: 'flex',
      flexDirection: 'column-reverse',
    },
    textAlign: 'right',
  }),
  firstDescription: {
    mb: 1.5,
  },
  list: {
    mb: 0,
  },
};

const DeletePhonebookModal = ({ isFetching, onClose, onConfirm, open, phonebook }) =>
  open ? (
    <Modal
      actions={
        <Box sx={styles.actions}>
          <Button onClick={onClose}>
            <FormattedMessage {...messages.deletePhonebookModalCancel} />
          </Button>
          <ButtonDanger isLoading={isFetching} onClick={() => onConfirm(phonebook)} startIcon={<DeleteIcon />}>
            <FormattedMessage {...messages.deletePhonebookModalConfirm} />
          </ButtonDanger>
        </Box>
      }
      headerVariant="danger"
      maxWidth="xs"
      name="delete-phonebook-modal"
      onClose={onClose}
      open
      title={<FormattedMessage {...messages.deletePhonebookModalTitle} />}
    >
      <Typography sx={styles.firstDescription}>
        <FormattedMessage {...messages.deletePhonebookModalDescription1} />
      </Typography>
      <Typography component="div">
        <FormattedMessage
          {...messages.deletePhonebookModalDescription2}
          values={{
            list: (
              <Box component="ul" sx={styles.list}>
                <FormattedMessage tagName="li" {...messages.deletePhonebookModalListRow1} />
                <FormattedMessage tagName="li" {...messages.deletePhonebookModalListRow2} />
              </Box>
            ),
          }}
        />
      </Typography>
    </Modal>
  ) : null;

DeletePhonebookModal.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool,
  phonebook: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  }).isRequired,
};

DeletePhonebookModal.defaultProps = {
  open: false,
};

const mapStateToProps = (state) => ({
  isFetching: selectors.deletePhoneBookFetching(state),
  open: selectors.deletePhonebookShow(state),
  phonebook: selectors.deletePhonebook(state),
});

const mapDispatchToProps = {
  onClose: actions.deletePhonebookHideModal,
  onConfirm: actions.deletePhonebookConfirm,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeletePhonebookModal);
