import CheckCircle from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IconBox } from '../../../components';
import messages from './messages';

function ImportResultSuccess({ importStats }) {
  return (
    <IconBox icon={<CheckCircle color="success" />}>
      <Typography>
        <FormattedMessage
          {...messages.siteUsersImportResultStepAllUsersImported}
          values={{
            count: importStats.imported,
            strong: (chunks) => (
              <Box component="span" sx={{ color: 'success.main', fontWeight: 'bold' }}>
                {chunks}
              </Box>
            ),
          }}
        />
      </Typography>
    </IconBox>
  );
}

ImportResultSuccess.propTypes = {
  importStats: PropTypes.object.isRequired,
};

export default ImportResultSuccess;
