import AddIcon from '@mui/icons-material/Add';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PATHS } from '../../../../config/consts';
import { useGetActualCompanyId } from '../../../../hooks/companyHooks';
import { useAppContext } from '../../../../pages/App/AppContextProvider';
import ButtonLink from '../../../ButtonLink';
import messages from '../../messages';
import { styles as switcherStyles } from '../../styles/commonSwitcher';

const SiteSwitcher = ({ currentId, data, isFetching, isSideBar, onAdd, onSelect }) => {
  const { feSiteRoles } = useAppContext();
  const companyId = useGetActualCompanyId();
  return (
    <Box
      sx={{
        ...(isSideBar ? switcherStyles.switcherSideBar : switcherStyles.switcherWrapper),
      }}
    >
      {!isFetching && (
        <Box display="flex" flexDirection="column" maxHeight={!isSideBar ? 400 : 'none'}>
          {feSiteRoles.isCompanyAdmin && (
            <Box
              sx={{
                px: 3,
                py: 1,
              }}
            >
              <ButtonLink
                color="primary"
                id="sites-link"
                size="small"
                to={PATHS.COMPANY_SITES.replace(':companyId', companyId)}
              >
                <FormattedMessage {...messages.siteSwitcherViewAllSites} />
              </ButtonLink>
              <Box component="span" px={2}>
                <Badge badgeContent={data.length} color="primary">
                  <span />
                </Badge>
              </Box>
            </Box>
          )}
          <MenuList
            disablePadding
            sx={{
              ...switcherStyles.listWrapper,
              ...(data.length > 7 && switcherStyles.scrollbar),
            }}
          >
            {data.map((item) => (
              <MenuItem
                key={item.id}
                onClick={() => onSelect(item.id)}
                selected={currentId === item.id}
                sx={switcherStyles.listItem}
              >
                <ListItemIcon sx={switcherStyles.listItemIcon}>
                  <LocationOnIcon color="secondary" />
                </ListItemIcon>
                <Box component="span" sx={switcherStyles.listItemName}>
                  {item.name}
                </Box>
              </MenuItem>
            ))}
          </MenuList>
        </Box>
      )}
      {feSiteRoles.isCompanyAdmin && (
        <>
          {data.length > 7 && !isSideBar && <Divider />}
          <Box sx={switcherStyles.addNewButton}>
            <Button
              color="primary"
              fullWidth
              id="add-site-button"
              onClick={() => onAdd()}
              startIcon={<AddIcon />}
              variant="outlined"
            >
              <FormattedMessage {...messages.siteSwitcherCreateSite} />
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

SiteSwitcher.propTypes = {
  currentId: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  isFetching: PropTypes.bool.isRequired,
  isSideBar: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

SiteSwitcher.defaultProps = {
  currentId: null,
};

export default SiteSwitcher;
