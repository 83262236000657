import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import iconDoneSvg from '../../../assets/icon_done.svg';
import messages from './messages';

const SuccessMessage = ({ onClose }) => (
  <>
    <img alt="" src={iconDoneSvg} />
    <Typography color="success.main" paragraph>
      <FormattedMessage {...messages.successMessageMessage} />
    </Typography>
    <Box display="flex" justifyContent="flex-end">
      <Button color="primary" onClick={onClose} sx={{ mt: 2 }} variant="contained">
        <FormattedMessage {...messages.successMessageCloseButton} />
      </Button>
    </Box>
  </>
);

SuccessMessage.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default SuccessMessage;
