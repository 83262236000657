import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { PROMO_TYPES } from '../../containers/Subscription/PromoCode/consts';
import ReadOnlyField from '../ReadOnlyField';
import messages from './messages';

const SubscriptionInfo = ({ count, currency, price, priceVat, subscriptions, type }) => {
  const isTypeOther = useMemo(() => Object.values(PROMO_TYPES).indexOf(type) === -1, [type]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={3} xs={6}>
        <ReadOnlyField
          label={<FormattedMessage {...messages.subscriptionInfoCount} />}
          value={
            <FormattedMessage
              {...messages.subscriptionInfoValueCount}
              values={{
                value: (
                  <Box component="span" fontWeight="bold">
                    {count}
                  </Box>
                ),
              }}
            />
          }
        />
      </Grid>
      <Grid item sm={3} xs={6}>
        <ReadOnlyField
          label={<FormattedMessage {...messages.subscriptionInfoSubscriptions} />}
          value={
            <FormattedMessage
              {...(isTypeOther ? messages.subscriptionInfoValueSubscriptionsOther : subscribtionInfoTypeMap[type])}
              values={{
                strong: (chunks) => <strong>{chunks}</strong>,
                value: subscriptions,
              }}
            />
          }
        />
      </Grid>
      <Grid item sm={3} xs={6}>
        <ReadOnlyField
          label={<FormattedMessage {...messages.subscriptionInfoTotal} />}
          value={
            <FormattedMessage
              {...(isTypeOther ? messages.subscriptionInfoValueTotalOther : subscribtionInfoTotalMap[type])}
              values={{
                strong: (chunks) => <strong>{chunks}</strong>,
                value: subscriptions * count,
              }}
            />
          }
        />
      </Grid>
      <Grid item sm={3} xs={6}>
        <ReadOnlyField
          label={<FormattedMessage {...messages.subscriptionInfoPrice} />}
          value={
            <FormattedMessage
              {...messages.subscriptionInfoValuePriceVat}
              values={{
                price: (
                  <Box component="span" fontWeight="bold">
                    <FormattedNumber
                      currency={currency}
                      // eslint-disable-next-line react/style-prop-object
                      style="currency"
                      value={price}
                    />
                  </Box>
                ),
                priceVat: (
                  <FormattedNumber
                    currency={currency}
                    // eslint-disable-next-line react/style-prop-object
                    style="currency"
                    value={priceVat}
                  />
                ),
              }}
            />
          }
        />
      </Grid>
    </Grid>
  );
};

SubscriptionInfo.propTypes = {
  count: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  priceVat: PropTypes.number.isRequired,
  subscriptions: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
};

export default SubscriptionInfo;

const subscribtionInfoTypeMap = {
  [PROMO_TYPES.MOBILE_VIDEO_POOL_CREDIT_YEAR_APARTMENT]:
    messages.subscriptionInfoValueSubscriptionsMOBILE_VIDEO_POOL_CREDIT_YEAR_APARTMENT,
  [PROMO_TYPES.MOBILE_VIDEO_POOL_CREDIT_YEAR_STANDARD]:
    messages.subscriptionInfoValueSubscriptionsMOBILE_VIDEO_POOL_CREDIT_YEAR_STANDARD,
};

const subscribtionInfoTotalMap = {
  [PROMO_TYPES.MOBILE_VIDEO_POOL_CREDIT_YEAR_APARTMENT]:
    messages.subscriptionInfoValueTotalMOBILE_VIDEO_POOL_CREDIT_YEAR_APARTMENT,
  [PROMO_TYPES.MOBILE_VIDEO_POOL_CREDIT_YEAR_STANDARD]:
    messages.subscriptionInfoValueTotalMOBILE_VIDEO_POOL_CREDIT_YEAR_STANDARD,
};
