import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../config/consts';
import { useDataGrid } from '../../helpers/dataGrid';
import useBreakpoints from '../../helpers/useBreakpoints';
import { canUserDelete } from '../../helpers/user';
import { isFetchingMessages } from '../../modules/localization/selectors';
import { CONTEXT } from '../../modules/user';
import DataGrid from '../DataGrid';
import EllipsisText from '../EllipsisText';
import Icon2N from '../Icon2N';
import UserEmail from '../UserEmail';
import messages from './messages';
import RowMenu from './RowMenu/RowMenu';

const styles = {
  user: {
    '& .my2n': {
      color: 'grey.500',
      mr: 1,
    },
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  userName: {
    fontWeight: 'bold',
  },
};

const CompanyAdminDataGrid = ({
  companyId,
  currentUser,
  didInvalid,
  filter,
  isFetching,
  onDeleteUser,
  onLoadUsers,
  order,
  page,
  resultsFiltered,
  rowsPerPage,
  users,
}) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { upLg, upMd, upSm } = useBreakpoints();
  const { onOrderBy, onRowsPerPageChange, onSelectPage } = useDataGrid(
    onLoadUsers,
    {
      companyId,
      emptyApartment: true,
      emptySite: true,
      filter,
      order,
      page,
      rowsPerPage,
      siteId: null,
    },
    didInvalid
  );
  const isLocalizationLoaded = useSelector(isFetchingMessages);

  const deleteUserEvent = (event, user) => {
    event.preventDefault();
    onDeleteUser(user);
  };

  const getDetailHref = (id) => PATHS.COMPANY_ADMIN_DETAIL.replace(':companyId', companyId).replace(':userId', id);

  const columns = useMemo(
    () => [
      {
        disableColumnMenu: true,
        field: 'lastName',
        flex: 1,
        headerName: formatMessage(messages.companyAdminDataGridColumnsName),
        renderCell: (cell) =>
          isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <Box sx={styles.user}>
              {upSm && <Icon2N name="person" />}
              <EllipsisText>
                <Typography sx={styles.userName} variant="body2">
                  {cell.value.lastName} {cell.value.firstName}
                </Typography>
              </EllipsisText>
            </Box>
          ),
        sortable: true,
      },
      {
        disableColumnMenu: true,
        field: 'email',
        flex: 1,
        headerName: formatMessage(messages.companyAdminDataGridColumnsEmail),
        renderCell: (cell) =>
          isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <UserEmail email={cell.value.email} isEnabled={cell.value.enabled} />
          ),
        sortable: false,
      },
      {
        disableColumnMenu: true,
        field: 'role',
        flex: 1,
        headerName: formatMessage(messages.companyAdminDataGridColumnsRole),
        renderCell: () =>
          isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <EllipsisText>
              <FormattedMessage {...messages.companyAdminDataGridRoleCompanyAdmin} />
            </EllipsisText>
          ),
        sortable: false,
      },
      {
        disableColumnMenu: true,
        field: 'rowMenu',
        headerName: '',
        renderCell: (cell) =>
          isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <RowMenu
              disabled={!canUserDelete(cell.value, currentUser, CONTEXT.COMPANY_ADMINS)}
              onDelete={(e) => deleteUserEvent(e, cell.value)}
              onDetail={() =>
                navigate(PATHS.COMPANY_ADMIN_DETAIL.replace(':companyId', companyId).replace(':userId', cell.value.id))
              }
              user={cell.value}
            />
          ),
        sortable: false,
        width: 65,
      },
    ],
    [isFetching, upMd, upSm, upLg, isLocalizationLoaded]
  );

  const rows = useMemo(
    () =>
      users.map((user) => ({
        email: user,
        id: user.id,
        isClickable: true,
        lastName: user,
        role: user,
        rowMenu: user,
      })),
    [users]
  );

  return (
    <DataGrid
      columns={columns}
      columnVisibilityModel={{
        email: upMd,
      }}
      disableRowSelectionOnClick
      hideFooterPagination={isFetching}
      loading={isFetching}
      onCellClick={(params) => {
        if (params.field !== 'rowMenu') {
          navigate(PATHS.COMPANY_ADMIN_DETAIL.replace(':companyId', companyId).replace(':userId', params.value.id));
        }
      }}
      onOrderBy={onOrderBy}
      onRowsPerPageChange={onRowsPerPageChange}
      onSelectPage={onSelectPage}
      order={order}
      page={page}
      resultsFiltered={resultsFiltered}
      resultsTotal={resultsFiltered}
      rows={rows}
      rowsPerPage={rowsPerPage}
    />
  );
};

CompanyAdminDataGrid.propTypes = {
  companyId: PropTypes.number.isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  didInvalid: PropTypes.bool.isRequired,
  filter: PropTypes.shape({ fulltext: PropTypes.string }).isRequired,
  isFetching: PropTypes.bool.isRequired,
  onDeleteUser: PropTypes.func.isRequired,
  onLoadUsers: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  resultsFiltered: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default CompanyAdminDataGrid;
