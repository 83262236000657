import BluetoothIcon from '@mui/icons-material/Bluetooth';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DialpadIcon from '@mui/icons-material/Dialpad';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LoginIcon from '@mui/icons-material/Login';
import LogoutIcon from '@mui/icons-material/Logout';
import PhoneLockedIcon from '@mui/icons-material/PhoneLocked';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import Tooltip from '@mui/material/Tooltip';
import { uniqBy } from 'lodash';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ACCESS_LOG_CREDENTIAL_TYPE, ACCESS_LOG_FAILED_RESULTS, ACCESS_LOG_RESULT } from '../../../config/consts';
import messages from '../messages';
import { AccessLogPropType } from '../type';

const accessTypeTooltip = (credential) => {
  let tooltip = null;

  switch (credential?.type) {
    case ACCESS_LOG_CREDENTIAL_TYPE.MOBILE_KEY:
    case ACCESS_LOG_CREDENTIAL_TYPE.CARD:
    case ACCESS_LOG_CREDENTIAL_TYPE.FINGER_PRINT:
    case ACCESS_LOG_CREDENTIAL_TYPE.CODE:
    case ACCESS_LOG_CREDENTIAL_TYPE.QR_CODE:
    case ACCESS_LOG_CREDENTIAL_TYPE.REMOTE:
    case ACCESS_LOG_CREDENTIAL_TYPE.REX:
    case ACCESS_LOG_CREDENTIAL_TYPE.LICENSE_PLATE:
      tooltip = <FormattedMessage {...messagesMap[credential?.type]} />;
      break;
    default:
      tooltip = <FormattedMessage {...messages.accessLogResultIconAccessTypeTooltipUnknown} />;
  }

  return tooltip;
};

const AccessLogAccessType = ({ accessLog }) => (
  <>
    {accessLog.result === ACCESS_LOG_RESULT.UNAUTHORIZED && (
      <Tooltip
        placement="right"
        title={<FormattedMessage {...messages.accessLogResultIconAccessTypeTooltipUnauthorizedAccess} />}
      >
        <LoginIcon sx={{ color: 'error.dark' }} />
      </Tooltip>
    )}

    {uniqBy(accessLog.credentials, 'type')?.map((credential) => {
      const iconProps = {
        sx: {
          color: ACCESS_LOG_FAILED_RESULTS.includes(accessLog?.result) ? 'error.dark' : 'primary.main',
        },
      };

      let icon = null;
      switch (credential?.type) {
        case ACCESS_LOG_CREDENTIAL_TYPE.MOBILE_KEY:
          icon = <BluetoothIcon {...iconProps} />;
          break;
        case ACCESS_LOG_CREDENTIAL_TYPE.CARD:
          icon = <CreditCardIcon {...iconProps} />;
          break;
        case ACCESS_LOG_CREDENTIAL_TYPE.FINGER_PRINT:
          icon = <FingerprintIcon {...iconProps} />;
          break;
        case ACCESS_LOG_CREDENTIAL_TYPE.CODE:
          icon = <DialpadIcon {...iconProps} />;
          break;
        case ACCESS_LOG_CREDENTIAL_TYPE.QR_CODE:
          icon = <QrCode2Icon {...iconProps} />;
          break;
        case ACCESS_LOG_CREDENTIAL_TYPE.REMOTE:
          icon = <PhoneLockedIcon {...iconProps} />;
          break;
        case ACCESS_LOG_CREDENTIAL_TYPE.REX:
          icon = <LogoutIcon {...iconProps} />;
          break;
        case ACCESS_LOG_CREDENTIAL_TYPE.LICENSE_PLATE:
          icon = <DirectionsCarIcon {...iconProps} />;
          break;
        default:
          icon = <HelpOutlineIcon {...iconProps} />;
          break;
      }

      return (
        <Tooltip key={credential?.type} placement="right" title={accessTypeTooltip(credential)}>
          {icon}
        </Tooltip>
      );
    })}
  </>
);

AccessLogAccessType.propTypes = {
  accessLog: AccessLogPropType.isRequired,
};

export default AccessLogAccessType;

const messagesMap = {
  [ACCESS_LOG_CREDENTIAL_TYPE.LICENSE_PLATE]: messages.accessLogResultIconAccessTypeTooltipLicensePlate,
  [ACCESS_LOG_CREDENTIAL_TYPE.MOBILE_KEY]: messages.accessLogResultIconAccessTypeTooltipMobileKey,
  [ACCESS_LOG_CREDENTIAL_TYPE.CARD]: messages.accessLogResultIconAccessTypeTooltipCard,
  [ACCESS_LOG_CREDENTIAL_TYPE.FINGER_PRINT]: messages.accessLogResultIconAccessTypeTooltipFingerPrint,
  [ACCESS_LOG_CREDENTIAL_TYPE.CODE]: messages.accessLogResultIconAccessTypeTooltipCode,
  [ACCESS_LOG_CREDENTIAL_TYPE.QR_CODE]: messages.accessLogResultIconAccessTypeTooltipQrCode,
  [ACCESS_LOG_CREDENTIAL_TYPE.REMOTE]: messages.accessLogResultIconAccessTypeTooltipRemote,
  [ACCESS_LOG_CREDENTIAL_TYPE.REX]: messages.accessLogResultIconAccessTypeTooltipRex,
};
