import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

interface ILocationIDs {
  locationIds: string[];
}

function LocationIDs({ locationIds }: ILocationIDs) {
  return (
    <>
      {locationIds.length <= 3 ? (
        <>
          <Typography>
            <FormattedMessage {...messages.configureMy2NaccessModal3Text1MaybeBluetooth} />
          </Typography>
          <Typography>
            <FormattedMessage {...messages.configureMy2NaccessModal3Text2MoreIds} />
          </Typography>
          <ul style={{ listStyleType: 'none' }}>
            {locationIds?.map((locationId, index) => <li key={'locationId' + index}>{locationId}</li>)}
          </ul>
          <Typography variant="caption">
            <FormattedMessage {...messages.configureMy2NaccessModal3Text3} />
          </Typography>
        </>
      ) : (
        <>
          <Typography>
            <FormattedMessage {...messages.configureMy2NaccessModal3Text1Bluetooth} />
          </Typography>
          <Typography>
            <FormattedMessage {...messages.configureMy2NaccessModal3Text2ManyIds} />
          </Typography>
          <Typography variant="caption">
            <FormattedMessage {...messages.configureMy2NaccessModal3Text3} />
          </Typography>
        </>
      )}
    </>
  );
}

export default LocationIDs;
