import GppGoodIcon from '@mui/icons-material/GppGood';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const SetAccessGroupButton = ({ onClick, ...other }) => (
  <Button
    {...other}
    color="primary"
    onClick={onClick}
    startIcon={<GppGoodIcon />}
    sx={{
      '&.MuiButton-root': {
        whiteSpace: 'nowrap',
      },
    }}
    variant="contained"
  >
    <FormattedMessage {...messages.setAccessGroupButtonLabel} />
  </Button>
);

SetAccessGroupButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default SetAccessGroupButton;
