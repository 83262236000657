import Box from '@mui/material/Box';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import landingMainBgImage from '../../assets/landing_main_new.jpg';
import ButtonLink from '../../components/ButtonLink';
import { PATHS } from '../../config/consts';
import messages from './messages';

const styles = {
  content: {
    '& > div': {
      '& > *': {
        maxWidth: {
          md: '60%',
        },
      },
      display: 'table-cell',
      verticalAlign: 'middle',
    },
    display: 'table',
    height: '100%',
    maxWidth: '1050px',
    mx: 'auto',
    my: 0,
    position: 'relative',
    px: 4,
    py: 2,
    width: '100%',
  },
  description: {
    color: 'white',
    display: 'block',
    fontSize: {
      lg: '32px',
      md: '26px',
      sm: '24px',
      xs: '20px',
    },
    fontWeight: 'light',
    mb: 0,
    mt: 2.5,
  },
  registerBtn: (theme) => ({
    '&:hover': {
      bgcolor: `${theme.palette.primary.dark} !important`,
      borderColor: `${theme.palette.primary.dark} !important`,
    },
    bgcolor: `${theme.palette.primary.main} !important`,
    border: 1,
    borderColor: `${theme.palette.primary.main} !important`,
    borderRadius: 0.5,
    color: `${theme.palette.primary.contrastText} !important`,
    mt: 4,
    px: 2,
    py: 1,
  }),
  root: (theme) => ({
    [theme.breakpoints.down('md')]: {
      height: '350px',
      pt: 0,
    },
    backgroundImage: `url(${landingMainBgImage})`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    height: 0,
    lineHeight: 1.5,
    paddingTop: `${(630 / 1904) * 100}%`,
    position: 'relative',
  }),
  title: {
    color: 'white',
    display: 'block',
    fontSize: {
      lg: '44px',
      md: '40px',
      sm: '36px',
      xs: '32px',
    },
    fontWeight: 'regular',
    lineHeight: 1.1,
    mb: 2,
    mt: 0,
  },
  wrapper: {
    height: '100%',
    position: 'absolute',
    textAlign: {
      sm: 'left',
      xs: 'center',
    },
    top: 0,
    width: '100%',
  },
};

function LandingMain() {
  return (
    <Box sx={styles.root}>
      <Box sx={styles.wrapper}>
        <Box sx={styles.content}>
          <div>
            <Box component="h2" sx={styles.title}>
              <FormattedMessage {...messages.landingMainTitle} values={{ nbsp: <>&nbsp;</> }} />
            </Box>
            <Box component="span" sx={styles.description}>
              <FormattedMessage {...messages.landingMainDescription} />
            </Box>
            <ButtonLink sx={styles.registerBtn} to={PATHS.REGISTER}>
              <FormattedMessage {...messages.landingMainRegister} />
            </ButtonLink>
          </div>
        </Box>
      </Box>
    </Box>
  );
}

export default LandingMain;
