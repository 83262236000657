import { IButtonRenderData } from '../../../containers/DeviceDetail/CallSettings/Intercom/hooks/useGetStateData';
import { IState } from '../../../containers/DeviceModal/CallSettingsStep/helpers/types';
import { IApartment } from '../../apartments/store';
import * as consts from '../../constants';
import { IRequestButtonsFeature, IRequestContactsFeature } from '../saveActions';
import { ContactType, DeviceFeatureType, IDevice } from '../store/deviceStore';
import { CallSettingsDataManager } from './callSettings/callSettingsDataManager';
import { getDisplayName, getRequestDataForFeature } from './callSettings/createRequestObject';
import { ExternalIdGenerator } from './callSettings/externalIdGenerator';

export interface ISaveContactListResponse {
  id: number;
  externalId?: string;
  type: DeviceFeatureType;
  virtualNumbersEnabled?: boolean;
  contacts: IResponseContact[];
}

export interface IResponseContact {
  id: number;
  externalId: string;
  name: string;
  virtualNumber?: number;
  contactItems: IResponseContactItem[];
}

export interface IResponseContactItem {
  id: number;
  externalId: string;
  type: ContactType;
}

export type CallSettingsRequestObject = IRequestContactsFeature | IRequestButtonsFeature;

export function addContactsFromResponse(
  buttonsRequest: IRequestButtonsFeature,
  response: ISaveContactListResponse
): IRequestButtonsFeature {
  const clonedRequest = { ...buttonsRequest };
  clonedRequest.buttons = [];

  buttonsRequest.buttons.forEach((button) => {
    const responseContact = response.contacts.find((contact) => contact.externalId === button.externalId);
    const clonedButton = { ...button };
    clonedButton.contacts = [responseContact?.id];
    clonedButton.index++;
    clonedRequest.buttons.push(clonedButton);
  });
  return clonedRequest;
}

export function handleSaveDeviceCallSettings(
  callSettingsDataManager: CallSettingsDataManager,
  updatedCallSettings: IButtonRenderData[],
  device: IDevice,
  availableApartments: IApartment[],
  onSaveContactList: (data: Map<DeviceFeatureType, CallSettingsRequestObject>) => void
): void {
  const featuresRequestData = new Map<DeviceFeatureType, CallSettingsRequestObject>();
  const externalIdGenerator = new ExternalIdGenerator();

  featuresRequestData.set(
    consts.contactList,
    getRequestDataForFeature(
      callSettingsDataManager,
      updatedCallSettings,
      device,
      consts.contactList,
      false,
      externalIdGenerator,
      availableApartments
    )
  );

  featuresRequestData.set(
    consts.buttonConfiguration,
    getRequestDataForFeature(
      callSettingsDataManager,
      updatedCallSettings,
      device,
      consts.buttonConfiguration,
      true,
      externalIdGenerator,
      availableApartments
    )
  );

  const displayConfigurationData = getRequestDataForFeature(
    callSettingsDataManager,
    updatedCallSettings,
    device,
    consts.display,
    true,
    externalIdGenerator,
    availableApartments
  );
  displayConfigurationData.name = getDisplayName(device);

  featuresRequestData.set(consts.display, displayConfigurationData);

  onSaveContactList(featuresRequestData);
}

export function handleSaveMultipleDevicesCallSettings(
  callSettings: {
    state: IState;
    availableApartments: IApartment[];
    availableDevices: IDevice[];
  },
  onSaveContactList: (
    data: {
      device: IDevice;
      requestObjects: Map<DeviceFeatureType, CallSettingsRequestObject>;
    }[]
  ) => void
): void {
  const devicesRequests: {
    device: IDevice;
    requestObjects: Map<DeviceFeatureType, CallSettingsRequestObject>;
  }[] = [];

  callSettings.state.devicesCallSettings.forEach((deviceCallSettings) => {
    if (deviceCallSettings.device) {
      const callSettingsDataManager = new CallSettingsDataManager(
        callSettings.availableDevices,
        callSettings.availableApartments,
        deviceCallSettings.device
      );
      const featuresRequestData = new Map<DeviceFeatureType, CallSettingsRequestObject>();
      const externalIdGenerator = new ExternalIdGenerator();

      featuresRequestData.set(
        consts.contactList,
        getRequestDataForFeature(
          callSettingsDataManager,
          deviceCallSettings.buttons,
          deviceCallSettings.device,
          consts.contactList,
          false,
          externalIdGenerator,
          callSettings.availableApartments
        )
      );

      featuresRequestData.set(
        consts.buttonConfiguration,
        getRequestDataForFeature(
          callSettingsDataManager,
          deviceCallSettings.buttons,
          deviceCallSettings.device,
          consts.buttonConfiguration,
          true,
          externalIdGenerator,
          callSettings.availableApartments
        )
      );

      const displayConfigurationData = getRequestDataForFeature(
        callSettingsDataManager,
        deviceCallSettings.buttons,
        deviceCallSettings.device,
        consts.display,
        true,
        externalIdGenerator,
        callSettings.availableApartments
      );
      displayConfigurationData.name = getDisplayName(deviceCallSettings.device);
      featuresRequestData.set(consts.display, displayConfigurationData);

      devicesRequests.push({
        device: deviceCallSettings.device,
        requestObjects: featuresRequestData,
      });
    }
  });

  onSaveContactList(devicesRequests);
}
