import { all, call, put, take, takeLatest } from 'redux-saga/effects';
import * as Api from '../../api/authApi';
import { PATHS } from '../../config/consts';
import { UserIsInOryResetPasswordResponseStatus } from '../../ory/config/const';
import { errorHelper, responseValidateStatusOrThrow } from '../helpers';
import { types as infraTypes } from '../infrastructure/types';
import { addSnackbarSuccess } from '../notifications/actions';
import userActions from '../user';
import userTypes from '../user/types';
import messages from './messages';
import types from './types';

export function* login({ values }) {
  let responseHeaders;
  try {
    const response = yield call(Api.loginRequest, { email: values.email, password: values.password });
    responseHeaders = response.headers;
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.LOGIN_SUCCESS, values: response.data });

    // Load current user
    yield put(userActions.getCurrentUser());
    yield take([userTypes.GET_CURRENT_USER_SUCCESS]);
  } catch (error) {
    if (error?.status === UserIsInOryResetPasswordResponseStatus) {
      values.onUserIsInOry();
      yield put({ type: types.LOGIN_SUCCESS, values: undefined });
      return;
    }
    if (error?.status === 403) {
      yield put({ type: types.LOGIN_FAILURE_RESEND, values });
    } else if (error?.status === 429 && responseHeaders && responseHeaders.get('Retry-After')) {
      yield put({
        type: types.LOGIN_THROTTLING,
        values: { throttlingRetryAfter: new Date(responseHeaders.get('Retry-After')) },
      });
    } else {
      yield put(errorHelper(types.LOGIN_FAILURE, error));
    }
  }
}

export function* setAuthToken({ accessToken, preferUserLogin }) {
  yield put({ type: types.LOGIN_SUCCESS, values: { accessToken, preferUserLogin } });
  yield call(setFirstLogin, { value: true });
  yield put(userActions.getCurrentUser());
  yield take([userTypes.GET_CURRENT_USER_SUCCESS]);
}

export function* setFirstLogin({ value }) {
  yield put({ type: types.FIRST_LOGIN, values: { value } });
}

export function* register({ values }) {
  try {
    const response = yield call(Api.registerRequest, {
      ...values,
      action: 'NEW_REGISTRATION',
    });
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.REGISTER_SUCCESS });
    yield put(addSnackbarSuccess(messages.authSnackbarConfirmationEmail));
  } catch (error) {
    yield put(errorHelper(types.REGISTER_FAILURE, error));
  }
}

export function* resetPassword({ values }) {
  try {
    const response = yield call(Api.resetPasswordRequest, { email: values.email });
    if (response.status === UserIsInOryResetPasswordResponseStatus) {
      values.onUserIsInOry();
      yield put({ type: types.RESET_PASSWORD_SUCCESS });
    } else {
      yield responseValidateStatusOrThrow(response);
      yield put({ type: types.RESET_PASSWORD_SUCCESS });
      yield put(addSnackbarSuccess(messages.authSnackbarPasswordReset));
    }
  } catch (error) {
    yield put(errorHelper(types.RESET_PASSWORD_FAILURE, error));
  }
}

export function* resendConfirmationEmail({ values }) {
  try {
    const response = yield call(Api.resendConfirmationEmailRequest, values);
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.RESEND_INVITATION_EMAIL_SUCCESS });
    yield put(addSnackbarSuccess(messages.authSnackbarInvitationEmail));
  } catch (error) {
    yield put(errorHelper(types.RESEND_INVITATION_EMAIL_FAILURE, error));
  }
}

export function* logout() {
  localStorage.clear();
  yield put({
    payload: {
      to: PATHS.LOGIN,
    },
    type: infraTypes.SET_REDIRECT_DATA,
  });
}

export default function* index() {
  yield all([
    takeLatest(types.LOGIN_REQUEST, login),
    takeLatest(types.SET_AUTH_TOKEN, setAuthToken),
    takeLatest(types.SET_FIRST_LOGIN, setFirstLogin),
    takeLatest(types.REGISTER_REQUEST, register),
    takeLatest(types.RESET_PASSWORD_REQUEST, resetPassword),
    takeLatest(types.RESEND_INVITATION_EMAIL_REQUEST, resendConfirmationEmail),
    takeLatest(types.LOGOUT, logout),
  ]);
}
