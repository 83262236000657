import { AxiosResponse } from 'axios';
import { TPaginationParams } from '../modules/commonTypes';
import { IUser } from '../modules/user/store';
import { encode } from './index';
import { getSiteData, ISiteGenericRequestData } from './siteRequest';

export const getAccessGroupUsersNew = async (
  siteData: ISiteGenericRequestData,
  group: number,
  params: TPaginationParams
): Promise<AxiosResponse<Array<IUser>>> =>
  getSiteData(siteData, `groups/${encode(group.toString())}/users`, true, params);
