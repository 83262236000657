import AddIcon from '@mui/icons-material/Add';
import BusinessIcon from '@mui/icons-material/Business';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../../messages';
import { styles as switcherStyles } from '../../styles/commonSwitcher';

const CompanySwitcher = ({ currentId, data, isFetching, isSideBar, onAdd, onSelect }) => (
  <Box
    sx={{
      ...(isSideBar ? switcherStyles.switcherSideBar : switcherStyles.switcherWrapper),
    }}
  >
    {!isFetching && (
      <Box display="flex" flexDirection="column" maxHeight={!isSideBar ? 400 : 'none'}>
        <MenuList
          disablePadding
          sx={{
            ...switcherStyles.listWrapper,
            ...(data.length > 7 && switcherStyles.scrollbar),
            py: 1,
          }}
        >
          {data.map((item) => (
            <MenuItem
              key={item.id}
              onClick={() => onSelect(item.id)}
              selected={currentId === item.id}
              sx={switcherStyles.listItem}
            >
              <ListItemIcon sx={switcherStyles.listItemIcon}>
                <BusinessIcon color="secondary" />
              </ListItemIcon>
              <Box component="span" sx={switcherStyles.listItemName}>
                {item.name}
              </Box>
            </MenuItem>
          ))}
        </MenuList>
      </Box>
    )}
    {data.length > 9 && !isSideBar && <Divider />}
    <Box sx={switcherStyles.addNewButton}>
      <Button color="primary" fullWidth onClick={() => onAdd()} startIcon={<AddIcon />} variant="outlined">
        <FormattedMessage {...messages.companySwitcherCreateCompany} />
      </Button>
    </Box>
  </Box>
);

CompanySwitcher.propTypes = {
  currentId: PropTypes.number,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  isFetching: PropTypes.bool.isRequired,
  isSideBar: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

CompanySwitcher.defaultProps = {
  currentId: null,
};

export default CompanySwitcher;
