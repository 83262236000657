import { defineMessages } from 'react-intl';

export default defineMessages({
  siteUsersImportCheckStepDataGridCellEmpty: {
    defaultMessage: 'EMPTY',
    description: 'Data grid - table cell - empty',
    id: 'siteUsersImportCheckStepDataGridCellEmpty',
  },
  siteUsersImportCheckStepDataGridCellTooltip: {
    defaultMessage: 'Double-click to edit',
    description: 'Data grid - table cell - tooltip',
    id: 'siteUsersImportCheckStepDataGridCellTooltip',
  },
  siteUsersImportCheckStepDataGridDeleteTooltip: {
    defaultMessage: 'Delete row',
    description: 'Data grid - table - delete row',
    id: 'siteUsersImportCheckStepDataGridDeleteTooltip',
  },
  siteUsersImportCheckStepDataGridTableHeadApartmentName: {
    defaultMessage: 'Apartment name',
    description: 'Data grid - table head - apartment name',
    id: 'siteUsersImportCheckStepDataGridTableHeadApartmentName',
  },
  siteUsersImportCheckStepDataGridTableHeadComment: {
    defaultMessage: 'Comment',
    description: 'Data grid - table head - comment',
    id: 'siteUsersImportCheckStepDataGridTableHeadComment',
  },
  siteUsersImportCheckStepDataGridTableHeadEmail: {
    defaultMessage: 'Email',
    description: 'Data grid - table head - email',
    id: 'siteUsersImportCheckStepDataGridTableHeadEmail',
  },
  siteUsersImportCheckStepDataGridTableHeadFirstName: {
    defaultMessage: 'First name',
    description: 'Data grid - table head - first name',
    id: 'siteUsersImportCheckStepDataGridTableHeadFirstName',
  },
  siteUsersImportCheckStepDataGridTableHeadFloor: {
    defaultMessage: 'Floor',
    description: 'Data grid - table head - floor',
    id: 'siteUsersImportCheckStepDataGridTableHeadFloor',
  },
  siteUsersImportCheckStepDataGridTableHeadLastName: {
    defaultMessage: 'Surname',
    description: 'Data grid - table head - lastName',
    id: 'siteUsersImportCheckStepDataGridTableHeadLastName',
  },
  siteUsersImportCheckStepDataGridTableHeadNumber: {
    defaultMessage: 'Number',
    description: 'Data grid - table head - number',
    id: 'siteUsersImportCheckStepDataGridTableHeadNumber',
  },
  siteUsersImportCheckStepEditableValueClickToEdit: {
    defaultMessage: 'Click to edit',
    description: 'Editable value - click to edit',
    id: 'siteUsersImportCheckStepEditableValueClickToEdit',
  },
  siteUsersImportCheckStepRecordsStepDescription: {
    defaultMessage: 'You entered {entered} {entered, plural, one {user} other {users}} contacts. ',
    description: 'Records step - description',
    id: 'siteUsersImportCheckStepRecordsStepDescription ',
  },
  siteUsersImportCheckStepRecordsStepDescriptionErrors: {
    defaultMessage: '{errors} need to be fixed.',
    description: 'Records step - description errors',
    id: 'siteUsersImportCheckStepRecordsStepDescriptionErrors',
  },
  siteUsersImportCheckStepRecordsStepViewErrors: {
    defaultMessage: 'View errors only',
    description: 'Records step - view errors',
    id: 'siteUsersImportCheckStepRecordsStepViewErrors',
  },
});
