import { selectors as authSelectors } from '../../modules/auth';
import { selectors as companySelectors } from '../../modules/company';
import { helpers as deviceHelpers } from '../../modules/devices';
import { selectors as sitesSelectors } from '../../modules/sites';

export const getValidationParameters = (state) => ({
  accessToken: authSelectors.getAccessTokenSelector(state),
  companyId: companySelectors.getCompanyId(state),
  siteId: sitesSelectors.getSiteId(state),
});

export const isAvailableForApartment = (types, deviceType) =>
  deviceHelpers.findItemInDeviceTypeTemplate(types, deviceType, 'availableForApartment') || false;
