import MaterialPaper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  root: {
    mb: 3,
    p: 3,
  },
  tabContent: {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  tabTable: {
    '& .MuiDataGrid-root': {
      border: 'none',
    },
    p: 0,
    pb: 1,
    pt: 3,
  },
};

const Paper = ({ children, tabContent, tabTable }) => (
  <MaterialPaper
    sx={{
      ...styles.root,
      ...(tabContent && styles.tabContent),
      ...(tabTable && styles.tabTable),
    }}
  >
    {children}
  </MaterialPaper>
);

Paper.propTypes = {
  children: PropTypes.any,
  tabContent: PropTypes.bool,
  tabTable: PropTypes.bool,
};

Paper.defaultProps = {
  children: null,
  tabContent: false,
  tabTable: false,
};

export default Paper;
