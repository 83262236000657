import { defineMessages } from 'react-intl';

export default defineMessages({
  closeBatchDialogBody: {
    defaultMessage:
      'If you close this batch, all active unused codes will close and no one will' +
      ' be able to use them. The codes from batch may have been sent to recipients who ' +
      'will not be able to use them.',
    description: '',
    id: 'closeBatchDialog.body',
  },
  closeBatchDialogCancel: {
    defaultMessage: 'Cancel',
    description: 'CloseBatchDialog - cancel button',
    id: 'closeBatchDialog.cancel',
  },
  closeBatchDialogClose: {
    defaultMessage: 'Close batch',
    description: 'CloseBatchDialog - close button',
    id: 'closeBatchDialog.close',
  },
  closeBatchDialogConfirmQuestion: {
    defaultMessage: 'Do you really want to close <strong>{name}</strong>?',
    description: 'Confirm question message',
    id: 'closeBatchDialog.confirmQuestion',
  },
  closeBatchDialogResolution: {
    defaultMessage: 'Resolution',
    description: 'CloseBatchDialog - resolution',
    id: 'closeBatchDialog.resolution',
  },
  closeBatchDialogResolutionRequired: {
    defaultMessage: 'Required',
    description: 'CloseBatchDialog - resolutionRequired helper text',
    id: 'closeBatchDialog.resolutionRequired',
  },
  closeBatchDialogTitle: {
    defaultMessage: 'Close selected batch',
    description: 'Close selected batch title',
    id: 'closeBatchDialog.title',
  },
});
