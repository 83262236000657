import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { addSnackbarSuccess } from '../../../../modules/notifications/actions';
import { containsOneOfSpecificErrors } from '../../../../ory/errors/errorParser';
import { IUseLoginApi, IUseSettingsApi, useOryLoginApi, useOrySettingsApi } from '../../../../ory/hooks/oryApi';
import { IUseOryResponseParser, useOryResponseParser } from '../../../../ory/hooks/oryResponseParser';
import { OryErrorNamedMessageId } from '../../../../ory/types';
import messages from './messages';
import {
  defaultValues,
  FormFieldNames,
  getNewPasswordFormValidationSchema,
  INewPasswordFormData,
} from './validationScheme';

export interface IUseNewPasswordFormProps {
  onRecoveryProcessRestart: () => void;
  onSuccess: () => void;
}

export interface IUseNewPasswordForm {
  formData: UseFormReturn<INewPasswordFormData>;
  onFormSubmit: (values: INewPasswordFormData) => Promise<void>;
  isOryApiFetching: boolean;
  isPasswordHelperTextVisible: boolean;
}

export function useNewPasswordForm({
  onRecoveryProcessRestart,
  onSuccess,
}: IUseNewPasswordFormProps): IUseNewPasswordForm {
  const { formatMessage } = useIntl();
  const { parseOryResponse } = useOryResponseParser();
  const { isOryApiFetching, updatePassword } = useOrySettingsApi();
  const { isOryApiFetching: isOryLoginApiFetching, logoutFromOry } = useOryLoginApi();
  const dispatch = useDispatch();

  const formData = useForm<INewPasswordFormData>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(getNewPasswordFormValidationSchema(formatMessage)),
  });

  const { isDirty: isPasswordValidationDirty } = formData.getFieldState('password');
  const isPasswordHelperTextVisible = !isPasswordValidationDirty;

  return {
    formData,
    isOryApiFetching: isOryApiFetching || isOryLoginApiFetching,
    isPasswordHelperTextVisible,
    onFormSubmit: getOnFormSubmit({
      dispatch,
      formData,
      logoutFromOry,
      onRecoveryProcessRestart,
      onSuccess,
      parseOryResponse,
      updatePassword,
    }),
  };
}

export function getOnFormSubmit({
  dispatch,
  formData,
  logoutFromOry,
  onRecoveryProcessRestart,
  onSuccess,
  parseOryResponse,
  updatePassword,
}: {
  updatePassword: IUseSettingsApi['updatePassword'];
  parseOryResponse: IUseOryResponseParser['parseOryResponse'];
  logoutFromOry: IUseLoginApi['logoutFromOry'];
  formData: UseFormReturn<INewPasswordFormData>;
  onRecoveryProcessRestart: () => void;
  onSuccess: () => void;
  dispatch: Dispatch;
}) {
  return async function (values: INewPasswordFormData) {
    const response = await updatePassword({
      password: values.password,
    });
    parseOryResponse({
      onError: async (error) => {
        if (error) {
          const isSessionRefreshRequired = containsOneOfSpecificErrors(error, [
            OryErrorNamedMessageId.Session_refresh_required,
          ]);

          if (isSessionRefreshRequired) {
            await logoutFromOry();
          }

          const isSessionInactive = containsOneOfSpecificErrors(error, [OryErrorNamedMessageId.Session_inactive]);

          if (isSessionRefreshRequired || isSessionInactive) {
            onRecoveryProcessRestart();
          }
        }
      },
      onSuccess: () => {
        dispatch(addSnackbarSuccess(messages.ImsNewPasswordSuccess));
        onSuccess();
      },
      response,
      validationField: {
        fieldName: FormFieldNames.Password,
        formData,
      },
    });
  };
}
