import { defineMessages } from 'react-intl';

export default defineMessages({
  accessGroupAddUserModalAdd: {
    defaultMessage: 'Add to group',
    description: 'Add user modal - add button',
    id: 'accessGroupAddUserModalAdd',
  },
  accessGroupAddUserModalCancel: {
    defaultMessage: 'Cancel',
    description: 'Add user modal - cancel button',
    id: 'accessGroupAddUserModalCancel',
  },
  accessGroupAddUserModalText: {
    defaultMessage:
      'Access groups define which users have access via which devices.' +
      '{br}{br}Users with set credentials will have access via all devices in selected access groups. ' +
      'Access is activated within seconds.',
    description: 'Add user modal - text',
    id: 'accessGroupAddUserModalText',
  },
  accessGroupAddUsersModalTitle: {
    defaultMessage: 'Add users to Access group',
    description: 'Add users modal - title',
    id: 'accessGroupAddUsersModalTitle',
  },
  addUserAutocompleteLabel: {
    defaultMessage: 'Users',
    description: 'Add user autocomplete - label',
    id: 'addUserAutocompleteLabel',
  },
  addUserAutocompleteNewUser: {
    defaultMessage: 'Create new user',
    description: 'Add user autocomplete - new user option label',
    id: 'addUserAutocompleteNewUser',
  },
  addUserAutocompleteNewUserWithoutLogin: {
    defaultMessage: 'Create new user without login',
    description: 'Add user autocomplete - new user option label',
    id: 'addUserAutocompleteNewUserWithoutLogin',
  },
  addUserAutocompletePlaceholder: {
    defaultMessage: 'Select, or create a new one',
    description: 'Add user autocomplete - placeholder',
    id: 'addUserAutocompletePlaceholder',
  },
  newUserFieldNewUserEmailHelperText: {
    defaultMessage: 'The email is important, please double-check',
    description: 'User email input - new user email helper text',
    id: 'newUserFieldNewUserEmailHelperText',
  },
  newUserFieldNewUserEmailLabel: {
    defaultMessage: 'New user email',
    description: 'User email input - new user email label',
    id: 'newUserFieldNewUserEmailLabel',
  },
  newUserFieldNewUserFirstName: {
    defaultMessage: 'New user first name',
    description: 'User firstName input - new user first name label',
    id: 'newUserFieldNewUserFirstName',
  },
  newUserFieldNewUserLastName: {
    defaultMessage: 'New user surname',
    description: 'User lastName input - new user last name label',
    id: 'newUserFieldNewUserLastName',
  },
  validationEmailExistUser: {
    defaultMessage: 'User with this e-mail address already exists in this Site.',
    description: 'User email input - new user email helper text',
    id: 'validationEmailExistUser',
  },
  validationEmailValid: {
    defaultMessage: 'Wrong format of the e-mail.',
    description: 'User email input - new user email helper text',
    id: 'validationEmailValid',
  },
  validationMaxLength: {
    defaultMessage: 'Name is too long.',
    description: 'User email input - new user email helper text',
    id: 'validationMaxLength',
  },
  validationRequired: {
    defaultMessage: 'Value is mandatory',
    description: 'User email input - new user email helper text',
    id: 'validationRequired',
  },
});
