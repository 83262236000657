import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IModalBase } from '../../modules/commonTypes';
import actions from '../../modules/devices';
import { DeviceFormSwitch } from './DeviceFormSwitch';

export function DeviceModal({ onClose, open }: IModalBase): JSX.Element {
  const [deviceType, setDevice] = useState<string | undefined>(undefined);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!open) {
      setDevice(undefined);
    }
  }, [open]);

  function onClearDevice(): void {
    setDevice(undefined);
  }

  function onCloseModal(): void {
    dispatch(actions.addDeviceClearModalData());
    onClose();
  }

  return (
    <DeviceFormSwitch
      deviceType={deviceType}
      onClose={onCloseModal}
      onSelectDevice={(value) => setDevice(value)}
      onClearDevice={onClearDevice}
      open={open}
    />
  );
}
