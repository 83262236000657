import Box from '@mui/material/Box';
import { alpha } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  inner: {
    borderColor: 'white',
    borderStyle: 'solid',
    content: '""',
    height: '30px',
    position: 'absolute',
    width: '29px',
  },
};

const QRScanArea = ({ size }) => (
  <Box
    sx={{
      '&:after': {
        ...styles.inner,
        borderWidth: '3px 3px 0 0',
        right: -7,
        top: -7,
      },
      '&:before': {
        ...styles.inner,
        borderWidth: '3px 0 0 3px',
        left: -7,
        top: -7,
      },
      boxShadow: (theme) => `0 0 0 1000px ${alpha(theme.palette.common.black, 0.8)}`,
      height: `${size}px`,
      left: `calc(50% - ${size / 2}px)`,
      position: 'fixed',
      top: `calc(50% - ${size / 2}px)`,
      width: `${size}px`,
      zIndex: 1,
    }}
  >
    <Box
      sx={{
        '&:after': {
          ...styles.inner,
          borderWidth: '0 3px 3px 0',
          bottom: -7,
          right: -7,
        },
        '&:before': {
          ...styles.inner,
          borderWidth: '0 0 3px 3px',
          bottom: -7,
          left: -7,
        },
      }}
    />
  </Box>
);

QRScanArea.propTypes = {
  size: PropTypes.number,
};

QRScanArea.defaultProps = {
  size: 200,
};

export default QRScanArea;
