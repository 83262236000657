import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React from 'react';

function InputField({ fieldState, helperText, label, params, placeholder }) {
  return (
    <TextField
      {...params}
      error={!!fieldState?.error}
      helperText={fieldState?.error?.message || helperText}
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        ...params.inputProps,
        autoComplete: 'off',
      }}
      label={label}
      placeholder={placeholder}
    />
  );
}

InputField.propTypes = {
  fieldState: PropTypes.shape({
    error: PropTypes.shape({
      message: PropTypes.string,
    }),
  }).isRequired,
  helperText: PropTypes.string.isRequired,
  label: PropTypes.string,
  params: PropTypes.shape({
    inputProps: PropTypes.shape({}),
  }).isRequired,
  placeholder: PropTypes.string,
};

InputField.defaultProps = {
  label: '',
  placeholder: '',
};

export default InputField;
