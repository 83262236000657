import AddCircle from '@mui/icons-material/AddCircle';
import { Menu, MenuItem } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

export interface IAddCallDestinationData {
  disabled: boolean;
  canAddApartment: boolean;
  onAdd: (addDevice: boolean) => void;
}

export function AddCallDestination({ canAddApartment, disabled, onAdd }: IAddCallDestinationData) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  function handleClose(addDevice: boolean): void {
    onAdd(addDevice);
    setAnchorEl(null);
  }

  return (
    <Box pt={0.5} textAlign="center">
      <IconButton color="secondary" disabled={disabled} onClick={handleClick} sx={{ m: 0, p: '0' }}>
        <AddCircle />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="basic-menu"
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        onClose={() => setAnchorEl(null)}
        open={open}
      >
        <MenuItem onClick={() => handleClose(true)}>
          <FormattedMessage {...messages.addCallDestinationButtonDevice} />
        </MenuItem>
        {canAddApartment && (
          <MenuItem onClick={() => handleClose(false)}>
            <FormattedMessage {...messages.addCallDestinationButtonApartment} />
          </MenuItem>
        )}
      </Menu>
    </Box>
  );
}
