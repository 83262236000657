import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../../messages';
import { unitPurchaseProps } from '../../unitProps';

const styles = {
  priceVat: {
    pl: 1,
  },
};

const PriceMessage = ({ currency, payment }) => {
  const { formatNumber } = useIntl();
  return (
    <>
      <Typography>{formatNumber(payment.unitFullPrice, { currency, style: 'currency' })}</Typography>
      <Typography color="secondary" sx={styles.priceVat} variant="body2">
        <FormattedMessage
          {...messages.mobileVideoLicenseSubscriptionPricePriceVat}
          values={{
            priceVat: formatNumber(payment.unitFullPriceVat, { currency, style: 'currency' }),
          }}
        />
      </Typography>
    </>
  );
};

PriceMessage.propTypes = {
  currency: PropTypes.string.isRequired,
  payment: unitPurchaseProps.isRequired,
};

export default PriceMessage;
