import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SERVICE } from '../../../../../config/devices';
import { IDevice } from '../../../../../modules/devices/store/deviceStore';
import messages from '../messages';

interface IMVStatus {
  device: IDevice | undefined;
  isActive: boolean;
}

function MVStatus({ device, isActive }: IMVStatus) {
  function getStatusColor() {
    if (isActive && device?.services?.[SERVICE.MOBILE_VIDEO]?.hasLicence) {
      return 'success.main';
    }
    if (isActive && !device?.services?.[SERVICE.MOBILE_VIDEO]?.hasLicence) {
      return 'warning.main';
    }
    return 'secondary.main';
  }

  return (
    <Typography color={getStatusColor()} fontWeight="bold" variant="body1">
      <FormattedMessage
        {...(isActive
          ? { ...messages.AAUserDetailMobileVideoServiceActive }
          : { ...messages.AAUserDetailMobileVideoServiceInactive })}
      />
    </Typography>
  );
}

export default MVStatus;
