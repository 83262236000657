import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { PATHS } from '../../config/consts';
import { useGetLoadedParams } from '../../hooks/paramsHooks';
import { ApplicationContext, IModalBase } from '../../modules/commonTypes';
import { actionsTS } from '../../modules/user';
import { TUserActionContext } from '../../modules/user/actionsTS';
import { IUser } from '../../modules/user/store';
import { replaceURLParams } from '../../routes/helpers/pathParamsReplacer';
import { assignRfidCardValidationSchema } from '../../validations/assignRfidCardValidationSchema';
import ActionModal from '../_DesignSystemWrappers/Modal/ActionModal';
import Alert from '../Alert';
import CardReader from '../CardReader';
import Link from '../Link';
import UserInfo from '../UserInfo';
import messages from './messages';

interface IUserRfidModalNew extends IModalBase {
  user: IUser;
  context: TUserActionContext;
}

export interface IFormProps {
  card: string;
}

function UserRfidModalNew({ context, onClose, open, user }: IUserRfidModalNew) {
  const { formatMessage } = useIntl();
  const { companyId, siteId } = useGetLoadedParams(ApplicationContext.Site);
  const dispatch = useDispatch();
  const assignedRef = useRef<string | undefined>(undefined);

  const formMethods = useForm<IFormProps>({
    defaultValues: {
      card: '',
    },
    mode: 'onChange',
    resolver: yupResolver<any>(assignRfidCardValidationSchema(companyId, siteId, formatMessage, assignedRef)),
  });

  function onSave() {
    dispatch(actionsTS.createUserRfid(companyId, siteId, user.id, formMethods.getValues('card'), context));
    onClose();
  }

  return (
    <ActionModal
      icon={<CheckIcon />}
      isPrimaryButtonDisabled={!formMethods.formState.isValid}
      muiDialogProps={{ maxWidth: 'xs' }}
      onClose={onClose}
      open={open}
      primaryButtonAction={onSave}
      primaryButtonText={formatMessage(messages.userRfidModalConfirmButton)}
      secondaryButtonText={formatMessage(messages.userRfidModalCancelButton)}
      title={formatMessage(messages.userRfidModalNewTitle)}
    >
      <Box pt={2}>
        <Typography variant="body2">
          <FormattedMessage {...messages.userRfidModalAssigneeText} />
        </Typography>
      </Box>
      <Box pt={2}>
        <UserInfo user={user} />
      </Box>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSave)}>
          <Box py={3}>
            <CardReader />
          </Box>
          {formMethods.formState.errors?.card?.type === 'isAssigned' && (
            <Box mt={2}>
              <Alert severity="error">
                <FormattedMessage
                  values={{
                    br: <br />,
                    link: (
                      <Link
                        onClick={onClose}
                        to={replaceURLParams({
                          params: { companyId, siteId, userId: assignedRef.current },
                          url: PATHS.SITE_USER_ACCESS,
                        })}
                      >
                        <FormattedMessage {...messages.userRfidModalAlertCardAssignedUserLink} />
                      </Link>
                    ),
                  }}
                  {...messages.userRfidModalAlertCardAssignedText}
                />
              </Alert>
            </Box>
          )}
        </form>
      </FormProvider>
    </ActionModal>
  );
}

export default UserRfidModalNew;
