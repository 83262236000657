import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { IRegisterFormData, RegisterForm } from './RegisterForm';
import { ICacheValidationEmail, useGetValidationSchema } from './useGetValidation';

export interface IRegisterControlData {
  onRegister: (formValues: IRegisterFormData) => void;
}

export function RegisterControl({ onRegister }: IRegisterControlData) {
  const [cacheValidationEmail, setCacheValidationEmail] = useState<ICacheValidationEmail>({
    email: undefined,
    isValid: false,
  });

  useEffect(() => {
    const script = document.createElement('script');
    script.id = 'recaptcha';
    script.src = 'https://www.google.com/recaptcha/api.js';
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);
    return () => {
      document.getElementById('recaptcha')?.remove();
    };
  });

  const validationSchema = useGetValidationSchema(cacheValidationEmail, setCacheValidationEmail);
  const formData = useForm<IRegisterFormData>({
    defaultValues: {
      agree: false,
      captcha: '',
      companyName: '',
      email: '',
      firstName: '',
      lastName: '',
      newsletters: false,
      password: '',
    },
    mode: 'onChange',
    resolver: yupResolver<IRegisterFormData>(validationSchema),
  });

  return (
    <FormProvider {...formData}>
      <form onSubmit={formData.handleSubmit((formValues: IRegisterFormData) => onRegister({
        ...formValues,
        newsletters: !formValues.newsletters
      }))}><RegisterForm />
      </form>
    </FormProvider>
  );
}
