import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { DEVICE_TYPE, SERVICE } from '../../../../config/devices';
import ApplicationVersionField from '../ApplicationVersionField';
import DeviceInfoTitle from '../DeviceInfoTitle';
import FirmwareInfoField from '../FirmwareInfoField';
import LastSynchronizationField from '../LastSynchronizationField';
import ModelNameField from '../ModelNameField';
import SerialNumberField from '../SerialNumberField';
import { hasFirmwareField, hasSerialNumberField, hasSynchronizationField } from './helpers';

const DeviceDetailDeviceInfo = ({ device, firmware, firmwareDisabled, onUpdateFirmware }) => (
  <Grid container direction="column">
    <Grid item>
      <DeviceInfoTitle type="device" />
    </Grid>
    <Grid item>
      <ModelNameField deviceType={device.deviceType} type={device.type} />
    </Grid>
    {hasSerialNumberField(device.type) && (
      <Grid item>
        <SerialNumberField value={device.serialNumber} />
      </Grid>
    )}
    {[DEVICE_TYPE.HIPMO, DEVICE_TYPE.THIRD_PARTY].includes(device.type) && (
      <Grid item>
        <ApplicationVersionField deviceApp={device?.app || {}} />
      </Grid>
    )}
    {hasFirmwareField(device.type) && (
      <Grid item>
        <FirmwareInfoField
          device={device}
          firmware={firmware}
          firmwareDisabled={firmwareDisabled}
          onUpdateFirmware={onUpdateFirmware}
        />
      </Grid>
    )}
    {hasSynchronizationField(device.type) && (
      <Grid item>
        <LastSynchronizationField value={device?.services?.[SERVICE.ACS]?.lastSeen} />
      </Grid>
    )}
  </Grid>
);

DeviceDetailDeviceInfo.propTypes = {
  device: PropTypes.shape({
    app: PropTypes.shape({
      name: PropTypes.string,
      os: PropTypes.string,
      version: PropTypes.string,
    }),
    deviceType: PropTypes.string,
    name: PropTypes.string,
    serialNumber: PropTypes.string,
    services: PropTypes.shape({
      [SERVICE.ACS]: PropTypes.shape({
        lastSeen: PropTypes.string,
      }),
    }),
    type: PropTypes.string,
  }).isRequired,
  firmware: PropTypes.shape({}).isRequired,
  firmwareDisabled: PropTypes.bool.isRequired,
  onUpdateFirmware: PropTypes.func.isRequired,
};

export default DeviceDetailDeviceInfo;
