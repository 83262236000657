import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { PATHS } from '../../config/consts';
import { PAYMENT_CONTEXT } from '../../config/payments';
import { getCompanyDataSelector } from '../../containers/BillingInfo/selectors';
import { dataGridProps, getDataGridSelector } from '../../helpers/dataGrid';
import billingActions from '../../modules/billing';
import { selectors as companySelectors } from '../../modules/company';
import actions, { NAME as PAYMENTS_MODULE } from '../../modules/payments';
import { useAppContext } from '../../pages/App/AppContextProvider';
import ButtonLink from '../ButtonLink';
import DashboardCardHeader from '../DashboardCardHeader';
import PricingModal from '../PricingModal';
import EmptyContent from './EmptyContent';
import messages from './messages';
import NoPaymentsContent from './NoPaymentsContent';
import PaymentsContent from './PaymentsContent';
import TableContent from './TableContent';

const styles = {
  content: {
    justifyContent: 'center',
    minHeight: { sm: '208px', xs: 'none' },
    pb: 3,
    pt: 0,
    px: 3,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    minHeight: { md: '305px', xs: 'none' },
  },
};

const PaymentsAndCreditsConsumption = ({
  companyId,
  companyName,
  currency,
  isBillingEditable,
  isBillingFilled,
  onEditBillingShowModal,
  onLoadPayments,
  paymentsTable,
  siteId,
}) => {
  const { feSiteRoles } = useAppContext();

  useEffect(() => {
    if (!feSiteRoles.isSiteAdmin) {
      onLoadPayments(companyId, siteId, 1, 10, { fulltext: '' }, '-dateCreated', PAYMENT_CONTEXT.SITE_PAYMENTS);
    }
  }, [feSiteRoles.isSiteAdmin]);

  const payments = paymentsTable.data.slice(0, 4);

  const [isOpenPricingModal, setIsOpenPricingModal] = useState(false);
  const handlePricingModal = () => setIsOpenPricingModal((prev) => !prev);

  const getCardAction = () => {
    if (feSiteRoles.isSiteAdminPayment) {
      return payments.length === 0 ? (
        <Button color="primary" onClick={handlePricingModal} variant="text">
          <FormattedMessage {...messages.noPaymentsContentPaymentsContentSeePricing} />
        </Button>
      ) : (
        <ButtonLink
          color="primary"
          to={PATHS.SITE_PAYMENTS.replace(':companyId', companyId).replace(':siteId', siteId)}
          variant="text"
        >
          <FormattedMessage {...messages.noPaymentsContentPaymentsContentSeeAll} />
        </ButtonLink>
      );
    }

    return null;
  };

  const getIsNoPaymentsContent = () => feSiteRoles.isSiteAdmin;

  const getIsPaymentsContent = () => feSiteRoles.isCompanyAdmin || feSiteRoles.isSiteAdminPayment;

  return (
    <Card sx={styles.root}>
      <DashboardCardHeader
        action={getCardAction()}
        title={<FormattedMessage {...messages.noPaymentsContentPaymentsContentTitle} />}
      />
      <CardContent sx={styles.content}>
        {payments.length !== 0 && feSiteRoles.isSiteAdminPayment ? (
          <TableContent onSeePricing={handlePricingModal} payments={payments} />
        ) : (
          <>
            {getIsPaymentsContent() && (
              <>
                {feSiteRoles.isCompanyAdmin && (
                  <EmptyContent
                    companyId={companyId}
                    isBillingEditable={isBillingEditable}
                    isBillingFilled={isBillingFilled}
                    onEditBillingShowModal={onEditBillingShowModal}
                  />
                )}
                {!feSiteRoles.isCompanyAdmin && feSiteRoles.isSiteAdminPayment && (
                  <PaymentsContent companyName={companyName} isBillingInfo={isBillingFilled} />
                )}
              </>
            )}
            {getIsNoPaymentsContent() && <NoPaymentsContent />}
          </>
        )}
      </CardContent>
      {isOpenPricingModal && (
        <PricingModal
          currency={currency}
          isOpenPricingModal={isOpenPricingModal}
          onClosePricingModal={handlePricingModal}
        />
      )}
    </Card>
  );
};

PaymentsAndCreditsConsumption.propTypes = {
  companyId: PropTypes.number.isRequired,
  companyName: PropTypes.string.isRequired,
  currency: PropTypes.string,
  isBillingEditable: PropTypes.bool.isRequired,
  isBillingFilled: PropTypes.bool.isRequired,
  onEditBillingShowModal: PropTypes.func.isRequired,
  onLoadPayments: PropTypes.func.isRequired,
  paymentsTable: dataGridProps.isRequired,
  siteId: PropTypes.number.isRequired,
};

PaymentsAndCreditsConsumption.defaultProps = {
  currency: '',
};

const mapStateToProps = (state) => {
  const { data, isFilled } = getCompanyDataSelector(state);
  return {
    companyName: companySelectors.getCompanyName(state),
    currency: companySelectors.getCurrentCompanyCurrencySelector(state),
    isBillingEditable: data.billingInfoEditable,
    isBillingFilled: isFilled,
    paymentsTable: getDataGridSelector(state, PAYMENTS_MODULE, 'list'),
  };
};

const mapDispatchToProps = {
  onEditBillingShowModal: billingActions.editBillingShowModal,
  onLoadPayments: actions.getPaymentsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsAndCreditsConsumption);
