import { IntlFormatters } from 'react-intl';
import { Dispatch } from 'redux';
import { isArrayEmpty } from '../../helpers/array';
import { addSnackbarError } from '../../modules/notifications/actions';
import {
  IOryGeneralError,
  IOryGeneralErrorDetailWithNewFlowId,
  isOryAxiosError,
  OryAxiosErrorResponse,
  OryContext,
  OryError,
  OryErrorCustomNamedMessageId,
  OryErrorDetailType,
  OryErrorMessage,
  OryErrorMessageId,
  OryErrorMessageShowMethod,
} from '../types';
import { tryToGetFlowValidationMessageId } from '../utils/validationMessageId';
import {
  getOryErrorMessageText,
  getOryErrorShowMethod,
  getOryGeneralErrorMessage,
  tryToGetOryErrorMessageDefinition,
} from './oryErrorMessages';

export function processOryApiError({
  axiosErrorResponse,
  context,
  dispatch,
  formatMessage,
  hideNotificationErrors,
}: {
  dispatch: Dispatch;
  formatMessage: IntlFormatters['formatMessage'];
  axiosErrorResponse?: OryAxiosErrorResponse;
  hideNotificationErrors?: boolean;
  context?: OryContext;
}): OryErrorMessage | OryError {
  if (axiosErrorResponse && axiosErrorResponse.response && axiosErrorResponse.response.data) {
    let oryErrorId: OryErrorMessageId | undefined = undefined;

    if (isOryAxiosError(axiosErrorResponse.response.data)) {
      oryErrorId = axiosErrorResponse.response.data.error.id;
      const errorWithDetail = tryToGetErrorMessageWithDetail(
        axiosErrorResponse.response.data.error,
        formatMessage,
        context
      );
      if (errorWithDetail) {
        return errorWithDetail;
      }
    } else {
      oryErrorId = tryToGetFlowValidationMessageId(axiosErrorResponse.response.data);
    }

    if (oryErrorId) {
      const errorMessageDefinition = tryToGetOryErrorMessageDefinition(oryErrorId, formatMessage);
      if (!errorMessageDefinition) {
        showSnackbarError(dispatch, getOryGeneralErrorMessage(formatMessage), hideNotificationErrors);
        return false;
      }

      const showMethod = getOryErrorShowMethod(errorMessageDefinition, context);

      if (showMethod === OryErrorMessageShowMethod.AppNotification) {
        showSnackbarError(dispatch, getOryErrorMessageText(oryErrorId, formatMessage, context), hideNotificationErrors);
      }
      return { context, id: oryErrorId, showMethod };
    }
  }

  showSnackbarError(dispatch, getOryGeneralErrorMessage(formatMessage), hideNotificationErrors);
  return false;
}

function showSnackbarError(dispatch: Dispatch, message: string, hideNotificationErrors?: boolean) {
  if (!hideNotificationErrors) {
    dispatch(addSnackbarError(message));
  }
}

export function tryToGetErrorMessageWithDetail(
  oryError: IOryGeneralError,
  formatMessage: IntlFormatters['formatMessage'],
  context?: OryContext
): OryErrorMessage | undefined {
  if (
    //For this case there is no specific id from ORY, so just reason can be used.
    oryError.code === 400 &&
    oryError.reason === 'The request was submitted too often. Please request another code.'
  ) {
    const newFlowId = tryToGetFlowIdFromOryGeneralErrorDetails(oryError.details);
    if (newFlowId) {
      const definition = tryToGetOryErrorMessageDefinition(
        OryErrorCustomNamedMessageId.RecoveryCodeMaxAttempts,
        formatMessage
      );
      return {
        context,
        detail: {
          data: { newFlowId },
          type: OryErrorDetailType.RecoveryCodeMaxAttempts,
        },
        id: OryErrorCustomNamedMessageId.RecoveryCodeMaxAttempts,
        showMethod:
          (definition && getOryErrorShowMethod(definition, context)) || OryErrorMessageShowMethod.FieldValidation,
      };
    }
  }

  return undefined;
}

export function containsOneOfSpecificErrors(
  apiErrorsResult: OryErrorMessage,
  possibleErrors: OryErrorMessageId[]
): boolean {
  if (possibleErrors.indexOf(apiErrorsResult.id) > -1) {
    return true;
  }

  return false;
}

function tryToGetFlowIdFromOryGeneralErrorDetails(
  oryErrorDetail: IOryGeneralErrorDetailWithNewFlowId | undefined
): string | undefined {
  if (
    oryErrorDetail &&
    oryErrorDetail.continue_with &&
    Array.isArray(oryErrorDetail.continue_with) &&
    !isArrayEmpty(oryErrorDetail.continue_with) &&
    oryErrorDetail.continue_with[0].flow &&
    oryErrorDetail.continue_with[0].flow.id
  ) {
    return oryErrorDetail.continue_with[0].flow.id;
  }
  return undefined;
}
