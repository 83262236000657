import { CommonTypes, IError, IReducers, IReduxAction } from '../reduxTypes';
import { getInitialLocalizationStore, ILocalizationStore } from './store';
import { types } from './types';

const reducers: IReducers<ILocalizationStore, CommonTypes> = {
  [types.SET_LANDING_PAGE_LANGUAGE_FAILURE]: (state: ILocalizationStore, error: IError): ILocalizationStore => ({
    ...state,
    landingPageSelect: {
      ...getInitialLocalizationStore().landingPageSelect,
      error,
    },
  }),
  [types.SET_USER_MODAL_LANGUAGE_FAILURE]: (state: ILocalizationStore, error: IError): ILocalizationStore => ({
    ...state,
    userLanguageModal: {
      ...getInitialLocalizationStore().userLanguageModal,
      error,
    },
  }),

  [types.SET_LANDING_PAGE_LANGUAGE_REQUEST]: (state: ILocalizationStore): ILocalizationStore => ({
    ...state,
    landingPageSelect: {
      ...getInitialLocalizationStore().landingPageSelect,
      isFetching: true,
    },
  }),
  [types.SET_USER_MODAL_LANGUAGE_REQUEST]: (state: ILocalizationStore): ILocalizationStore => ({
    ...state,
    userLanguageModal: {
      ...state.userLanguageModal,
      error: undefined,
      isFetching: true,
    },
  }),

  [types.GET_CURRENT_SITE_LANGUAGE_SUCCESS]: (state: ILocalizationStore, value: string): ILocalizationStore => ({
    ...state,
    currentSiteLang: value,
  }),

  [types.GET_CURRENT_USER_LANGUAGE_SUCCESS]: (state: ILocalizationStore, value: string): ILocalizationStore => ({
    ...state,
    currentUserLang: value,
  }),

  [types.SET_LANDING_PAGE_LANGUAGE_SUCCESS]: (state: ILocalizationStore, value: string): ILocalizationStore => ({
    ...state,
    currentUserLang: value,
    landingPageSelect: {
      ...getInitialLocalizationStore().landingPageSelect,
      isFetching: false,
    },
  }),

  [types.SET_USER_MODAL_LANGUAGE_SUCCESS]: (state: ILocalizationStore, value: string): ILocalizationStore => ({
    ...state,
    currentUserLang: value,
    userLanguageModal: {
      ...getInitialLocalizationStore().userLanguageModal,
    },
  }),
  [types.SET_FETCHING_MESSAGES]: (state: ILocalizationStore, value: boolean): ILocalizationStore => ({
    ...state,
    isFetchingMessages: value,
  }),
};

export function localizationReducers<TValue extends CommonTypes>(
  state = getInitialLocalizationStore(),
  action: IReduxAction<TValue>
): ILocalizationStore {
  if (reducers[action.type]) {
    if (action.error !== undefined) {
      return reducers[action.type](state, action.error);
    }
    return reducers[action.type](state, action.payload);
  }

  return state;
}
