import { defineMessages } from 'react-intl';

export default defineMessages({
  contentFillBillingInfo: {
    defaultMessage: 'Edit billing info',
    description: 'PaymentsAndCredits - fill in billing info',
    id: 'paymentsAndCreditsConsumption.fillBillingInfo',
  },
  contentFillBillingToPurchase: {
    defaultMessage: 'To purchase MobileVideo services, please fill in your billing details.',
    description: 'PaymentsAndCreditsConsumption - fill billing before purchase',
    id: 'paymentsAndCreditsConsumption.fillBillingToPurchase',
  },
  contentHereYouCanAccess: {
    defaultMessage: `Here you can access a complete overview of your <a>company’s payments</a>,
    {br}including licence purchases and credit usage.`,
    description: 'PaymentsAndCreditsConsumption - here you can access',
    id: 'paymentsAndCreditsConsumption.hereYouCanAccess',
  },
  contentNoPaymentsContentPaymentInfo: {
    defaultMessage: 'Payment information is only accessible to authorized site administrators.',
    description: 'No payments content - payment info',
    id: 'paymentsAndCreditsConsumption.noPaymentsContent.paymentInfo',
  },
  contentNoPaymentsContentServiceInfo: {
    defaultMessage: 'To start using Mobile Video services,' + ' use a voucher from your distributor.',
    description: 'No payments content - service info',
    id: 'paymentsAndCreditsConsumption.noPaymentsContent.serviceInfo',
  },
  contentNoPaymentsContentUseVoucher: {
    defaultMessage: 'Use voucher',
    description: 'No payments content - use voucher button label',
    id: 'paymentsAndCreditsConsumption.noPaymentsContent.useVoucher',
  },
  noPaymentsContentPaymentsContentBuySubscription: {
    defaultMessage: 'Buy subscription',
    description: 'No payments content - buy subscription button label',
    id: 'paymentsAndCreditsConsumption.paymentsContent.buySubscription',
  },
  noPaymentsContentPaymentsContentContactAdminInfo: {
    defaultMessage:
      'Contact the {companyName} company administrator{br}' + 'and ask them to fill in the billing information.',
    description: 'No payments content - contact admin text',
    id: 'paymentsAndCreditsConsumption.paymentsContent.contactAdminInfo',
  },
  noPaymentsContentPaymentsContentNoBillingInfo: {
    defaultMessage:
      'To start using Mobile Video services, buy a subscription or use a' + ' voucher from your distributor.',
    description: 'Payments content - no billing info',
    id: 'paymentsAndCreditsConsumption.paymentsContent.noBillingInfo',
  },
  noPaymentsContentPaymentsContentNoBillingInfoPayments: {
    defaultMessage:
      'You can start using Mobile Video after you purchase a subscription.' +
      '{br}If you want to buy a subscription, you need complete billing information.',
    description: 'Payments content - no billing info',
    id: 'paymentsAndCreditsConsumption.paymentsContent.noBillingInfoPayments',
  },
  noPaymentsContentPaymentsContentPriceVat: {
    defaultMessage: '({price} incl. VAT)',
    description: 'PaymentsAndCreditsConsumption - price VAT',
    id: 'paymentsAndCreditsConsumption.priceVat',
  },
  noPaymentsContentPaymentsContentSeeAll: {
    defaultMessage: 'See all',
    description: 'PaymentsAndCredits - see all',
    id: 'paymentsAndCreditsConsumption.seeAll',
  },
  noPaymentsContentPaymentsContentSeePricing: {
    defaultMessage: 'See pricing',
    description: 'PaymentsAndCredits - see pricing',
    id: 'paymentsAndCreditsConsumption.seePricing',
  },
  noPaymentsContentPaymentsContentStartUsingMv: {
    defaultMessage: 'You can start using MobileVideo after you purchase a subscription.',
    description: 'PaymentsAndCreditsConsumption - start using mobile video',
    id: 'paymentsAndCreditsConsumption.startUsingMv',
  },
  noPaymentsContentPaymentsContentTitle: {
    defaultMessage: 'Credit usage and payments',
    description: 'PaymentsAndCreditsConsumption - title',
    id: 'paymentsAndCreditsConsumption.title',
  },
  paymentsAndCreditsConsumptionCheckBillingInfo: {
    defaultMessage: 'Check your billing info',
    description: 'PaymentsAndCredits - check billing info',
    id: 'paymentsAndCreditsConsumption.checkBillingInfo',
  },
  paymentsAndCreditsConsumptionCheckBillingToPurchase: {
    defaultMessage: 'Before purchase MobileVideo services, please check your billing details.',
    description: 'PaymentsAndCreditsConsumption - check billing before purchase',
    id: 'paymentsAndCreditsConsumption.checkBillingToPurchase',
  },
  paymentsAndCreditsConsumptionContentAnnualSubscription: {
    defaultMessage: 'Annual subscription',
    description: 'PaymentsAndCreditsConsumption - annual subscription',
    id: 'paymentsAndCreditsConsumption.content.annualSubscription',
  },
  paymentsAndCreditsConsumptionContentMonthlySubscription: {
    defaultMessage: 'Monthly subscription',
    description: 'PaymentsAndCreditsConsumption - monthly subscription',
    id: 'paymentsAndCreditsConsumption.content.monthlySubscription',
  },
});
