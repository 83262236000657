import { ThemeProvider } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { DocumentDateInfo, HeaderPublic, Loader, ScrollMenu } from '../../components';
import { PATHS } from '../../config/consts';
import actions, { selectors } from '../../modules/common';
import Content from './Content';
import { getHtmlContent, getSidebarData, getTitleContent } from './helper';

const styles = {
  content: {
    maxWidth: 660,
    pb: 10,
    pt: 4,
  },
  title: {
    fontSize: '2rem',
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: 1.334,
    m: 0,
  },
};

const GTCPageDetail = ({ data, isFetching, notFound, onLoadGTCDetail }) => {
  const { slug, version = null } = useParams();
  const fullPath = encodeURI((version ? `${slug}/${version}` : slug).trim());
  useEffect(() => {
    if (!notFound) {
      onLoadGTCDetail(fullPath);
    }
  }, [fullPath]);

  if (notFound && !isFetching && !data?.html) {
    return <Navigate replace to={PATHS.GTC_PAGES} />;
  }

  const html = data?.html ? getHtmlContent(data.html) : '';
  const documentTitle = data?.name || '';
  const sidebarData = getSidebarData(html, documentTitle);

  return (
    <ThemeProvider
      theme={(globalTheme) => ({
        ...globalTheme,
        palette: { ...(globalTheme?.palette || {}), background: { default: 'white' } },
      })}
    >
      <CssBaseline />
      <HeaderPublic />
      <Container maxWidth="xl">
        <Grid container>
          <Grid display={{ md: 'block', xs: 'none' }} item xs={3}>
            {sidebarData.length > 1 && <ScrollMenu menuData={sidebarData} />}
          </Grid>
          <Grid item sx={styles.content} xs>
            {isFetching ? (
              <Loader />
            ) : (
              <>
                <Box
                  component="h1"
                  dangerouslySetInnerHTML={{ __html: getTitleContent(documentTitle) }}
                  sx={styles.title}
                />
                {data && <DocumentDateInfo effective={data?.dateEffective} posted={data?.dateCreated} />}
                <Content html={html} />
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};

GTCPageDetail.propTypes = {
  data: PropTypes.shape({
    dateCreated: PropTypes.string,
    dateEffective: PropTypes.string,
    html: PropTypes.string,
    name: PropTypes.string,
  }),
  isFetching: PropTypes.bool.isRequired,
  notFound: PropTypes.bool.isRequired,
  onLoadGTCDetail: PropTypes.func.isRequired,
};

GTCPageDetail.defaultProps = {
  data: null,
};

const mapStateToProps = (state) => ({
  data: selectors.getGTCDetail(state),
  isFetching: selectors.isFetchingGTCDetail(state),
  notFound: selectors.isNotFoundGTCDetail(state),
});

const mapDispatchToProps = {
  onLoadGTCDetail: actions.loadGTCDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(GTCPageDetail);
