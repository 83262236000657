import React from 'react';
import { MOBILE_VIDEO_STATUS } from '../../config/consts';
import { CALLS_STATUS, SERVICE, VERIFICATION_STATUS } from '../../config/devices';
import { SITE_TYPE } from '../../config/sites';
import { isOpenRcConfigurationDisabled } from '../../containers/DeviceDetail/helpers';
import { IDevice } from '../../modules/devices/store/deviceStore';
import { IMVLicenceModel } from '../../modules/sites/store';

interface IDeviceDataGridAction {
  activateTrialComponent: React.ReactElement;
  changePasswordComponent: React.ReactElement;
  device: IDevice;
  licenceModel: IMVLicenceModel;
  purchaseComponent: React.ReactElement;
  reverifyComponent: React.ReactElement;
  useAvailableCreditComponent: React.ReactElement;
}

const DeviceDataGridAction = ({
  activateTrialComponent,
  changePasswordComponent,
  device,
  licenceModel,
  purchaseComponent,
  reverifyComponent,
  useAvailableCreditComponent,
}: IDeviceDataGridAction) => {
  const shouldRenderReVerify =
    device.verificationStatus &&
    ![VERIFICATION_STATUS.VERIFIED, VERIFICATION_STATUS.ZOMBIE].includes(device?.verificationStatus);

  const shouldRenderChangePassword =
    device.hasDefaultPassword &&
    device.services?.[SERVICE.REMOTE_CONFIGURATION]?.active &&
    !isOpenRcConfigurationDisabled(
      device.services?.[SERVICE.REMOTE_CONFIGURATION]?.status,
      device.services?.[SERVICE.ACS]?.firmwareStatus
    );

  const shouldRenderActivateTrial =
    licenceModel?.status === MOBILE_VIDEO_STATUS.INITIAL &&
    [CALLS_STATUS.UNLICENSED, CALLS_STATUS.LOCAL].includes(device.callsStatus as CALLS_STATUS);

  const MVStandardCSUnlicensed =
    licenceModel?.status === MOBILE_VIDEO_STATUS.STANDARD && device.callsStatus === CALLS_STATUS.UNLICENSED;

  if (shouldRenderReVerify) {
    return reverifyComponent;
  }

  if (shouldRenderChangePassword) {
    return changePasswordComponent;
  }

  if (device?.site?.type !== SITE_TYPE.MDU) {
    if (MVStandardCSUnlicensed) {
      if (licenceModel?.additional > licenceModel?.licencesPool) {
        return purchaseComponent;
      } else {
        return useAvailableCreditComponent;
      }
    }

    if (shouldRenderActivateTrial) {
      return activateTrialComponent;
    }
  }

  return <>{false}</>;
};

export default DeviceDataGridAction;
