import TodayIcon from '@mui/icons-material/Today';
import { Box, Button, Divider, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useGetUserCredentialsValidityQuery } from '../../apiRtk/accessControlApi';
import { ReactComponent as TimeLimitedIcon } from '../../assets/time-limited.svg';
import { useGetActualCompanyId } from '../../hooks/companyHooks';
import { useGetActualSiteId } from '../../hooks/siteHooks';
import { useModal } from '../../hooks/useModal';
import { EnrichedApartmentUser } from '../../modules/apartmentAdmin/data';
import { getApartmentDevices } from '../../modules/apartments/selectors';
import { VisitorModalContext } from '../../modules/commonTypes';
import { getSiteData } from '../../modules/sites/selectors';
import { IconWithContent } from '../../pages/apartmentAdmin/ApartmentAdminUserDetailPage/components/UserAccessSettings/components/IconWithContent';
import VisitorModal from '../../pages/apartmentAdmin/ApartmentAdminUsersOverviewPage/components/VisitorModal/VisitorModal';
import { CustomDate } from '../index';
import RowSection from '../RowSection';
import { useGetGlobalHooks } from '../UserModal/hooks/getGlobalHooks';
import messages from './messages';

interface ISectionAccessValidity {
  user: EnrichedApartmentUser;
}

function UserAccessValidity({ user }: ISectionAccessValidity) {
  const [enrichedUser, setEnrichedUser] = useState(user);
  const { formatMessage } = useGetGlobalHooks();
  const isVisitorValidityVisible = enrichedUser.validFrom || enrichedUser.validTo;
  const companyId = useGetActualCompanyId();
  const siteId = useGetActualSiteId();
  const siteData = useSelector(getSiteData);
  const apartmentDevices = useSelector(getApartmentDevices).data || [];

  const { data: userCredentialsValidity } = useGetUserCredentialsValidityQuery(
    {
      companyId,
      siteId,
      userId: user.id.toString(),
    },
    { refetchOnMountOrArgChange: true }
  );

  const { Modal: VisitorUModal, onOpen: onOpenVisitorUModal } = useModal({
    Modal: VisitorModal,
  });

  useEffect(() => {
    setEnrichedUser({
      ...user,
      validFrom: userCredentialsValidity?.validFrom,
      validTo: userCredentialsValidity?.validTo,
    });
  }, [userCredentialsValidity]);

  return isVisitorValidityVisible ? (
    <>
      <RowSection
        icon={<IconWithContent Icon={TimeLimitedIcon}></IconWithContent>}
        label={formatMessage(messages.UserAccessValidityLabel)}
      >
        <Stack
          direction={{ sm: 'row', xs: 'column' }}
          justifyContent={{ sm: 'space-between', xs: 'flex-start' }}
          sx={{ width: 'inherit' }}
        >
          {userCredentialsValidity && (
            <>
              <Box>
                {userCredentialsValidity.validFrom && (
                  <>
                    <FormattedMessage
                      {...messages.UserAccessValidityStart}
                      values={{
                        typography: (chunk) => (
                          <Typography component="span" color="secondary">
                            {chunk}
                          </Typography>
                        ),
                        validityStart: CustomDate({ dateValue: userCredentialsValidity.validFrom, showTime: true }),
                      }}
                    />
                    &nbsp;
                  </>
                )}
                {userCredentialsValidity.validTo && (
                  <FormattedMessage
                    {...messages.UserAccessValidityEnd}
                    values={{
                      typography: (chunk) => (
                        <Typography component="span" color="secondary">
                          {chunk}
                        </Typography>
                      ),
                      validityEnd: CustomDate({ dateValue: userCredentialsValidity.validTo, showTime: true }),
                    }}
                  />
                )}
              </Box>
              <Box>
                <Button onClick={onOpenVisitorUModal} startIcon={<TodayIcon />} variant="outlined">
                  <FormattedMessage {...messages.UserAccessBtnValidityEdit} />
                </Button>
              </Box>
            </>
          )}
        </Stack>
      </RowSection>
      <Divider sx={{ mb: 3 }} />
      {companyId && siteId && (
        <VisitorUModal
          msgContext={VisitorModalContext.USER_ACCESS_VALIDITY_EDITMODAL}
          user={enrichedUser}
          apartmentInfo={{ companyId, siteId }}
          apartmentDevices={user?.apartments.length ? apartmentDevices : []}
          siteName={siteData?.name}
          userCredentialsValidity={userCredentialsValidity}
        />
      )}
    </>
  ) : (
    <></>
  );
}

export default UserAccessValidity;
