import { useSelector } from 'react-redux';
import { useUrlParams } from '../../../helpers/urlParams';
import { useGetLoadedParams } from '../../../hooks/paramsHooks';
import { ApplicationContext } from '../../../modules/commonTypes';
import { selectors } from '../../../modules/elevatorIntegration';

export function useGetDeviceElevatorIntegrationData() {
  const { companyId, siteId } = useGetLoadedParams(ApplicationContext.Site);
  const { deviceId } = useUrlParams();
  const isInvalid = useSelector(selectors.isDeviceElevatorIntegrationInvalid);
  const isActive = useSelector(selectors.isDeviceElevatorIntegrationActive);
  const data = useSelector(selectors.getDeviceElevatorIntegrationData);

  return {
    data,
    isActive,
    isInvalid,
    urlParams: {
      companyId,
      deviceId,
      siteId,
    },
  };
}
