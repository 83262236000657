import { IUWLUserAssignedPin } from '../../api/userApiTS';
import { TId, TUuid } from '../commonTypes';
import { IMobileVideoService } from '../devices/store/deviceStore';
import { DeviceTypeEnum } from '../devices/store/storeTypes';
import { ICreateApartmentUser } from './data';
import { types } from './types';

export const getUsersInApartments = () => ({
  type: types.GET_USERS_IN_APARTMENTS_REQUEST,
});

export const deleteUserPin = (companyId: TId, siteId: TId, apartmentId: TId, userId: TId, onSuccess: () => void) => ({
  apartmentId,
  companyId,
  onSuccess,
  siteId,
  type: types.DELETE_USER_PIN_REQUEST,
  userId,
});

export const deleteUserRFID = (companyId: TId, siteId: TId, apartmentId: TId, userId: TId, onSuccess: () => void) => ({
  apartmentId,
  companyId,
  onSuccess,
  siteId,
  type: types.DELETE_USER_RFID_REQUEST,
  userId,
});

export const getApartmentsAccessGroups = (companyId: TId, siteId: TId, apartmentId: TId, userId: TId) => ({
  apartmentId,
  companyId,
  siteId,
  type: types.GET_APARTMENT_GROUPS_REQUEST,
  userId,
});

export const generateUserPin = (companyId: TId, siteId: TId, apartmentId: TId, userId: TId) => ({
  apartmentId,
  companyId,
  siteId,
  type: types.GENERATE_USER_PIN_REQUEST,
  userId,
});

export const activateUserPin = (
  companyId: TId,
  siteId: TId,
  apartmentId: TId,
  userId: TId,
  pinId: TUuid,
  onSuccess: () => void
) => ({
  apartmentId,
  companyId,
  onSuccess,
  pinId,
  siteId,
  type: types.ACTIVATE_USER_PIN_REQUEST,
  userId,
});

export const getUserPin = (companyId: TId, siteId: TId, apartmentId: TId, userId: TId) => ({
  apartmentId,
  companyId,
  siteId,
  type: types.GET_USER_PIN_REQUEST,
  userId,
});

export const getUserRfid = (companyId: TId, siteId: TId, apartmentId: TId, userId: TId) => ({
  apartmentId,
  companyId,
  siteId,
  type: types.GET_USER_RFID_REQUEST,
  userId,
});

export const getUserAccessGroups = (companyId: TId, siteId: TId, apartmentId: TId, userId: TId) => ({
  apartmentId,
  companyId,
  siteId,
  type: types.GET_USER_GROUPS_REQUEST,
  userId,
});

export const getAvailablePin = (companyId: TId, siteId: TId, apartmentId: TId) => ({
  apartmentId,
  companyId,
  siteId,
  type: types.GET_AVAILABLE_PIN_REQUEST,
});

export const cleanAvailablePin = () => ({
  type: types.GET_AVAILABLE_PIN_CLEAN,
});

export const createApartmentUser = (companyId: TId, siteId: TId, apartmentId: TId, values: ICreateApartmentUser) => ({
  apartmentId,
  companyId,
  siteId,
  type: types.CREATE_APARTMENT_USER_REQUEST,
  values,
});

export const createUWLUserAssignPin = (
  companyId: TId,
  siteId: TId,
  apartmentId: TId,
  userAssignedPin: IUWLUserAssignedPin,
  onSuccess: () => void
) => ({
  apartmentId,
  companyId,
  onSuccess,
  siteId,
  type: types.CREATE_UWL_ASSIGN_PIN_REQUEST,
  userAssignedPin,
});

export const getDevicesWithinApartment = (companyId: TId, siteId: TId, apartmentId: TId, userId: TId) => ({
  apartmentId,
  companyId,
  siteId,
  type: types.GET_DEVICES_WITHIN_APARTMENT_REQUEST,
  userId,
});

export const createDeviceWithinApartment = (
  companyId: TId,
  siteId: TId,
  apartmentId: TId,
  values: { type: DeviceTypeEnum; userId: TId }
) => ({
  apartmentId,
  companyId,
  siteId,
  type: types.CREATE_DEVICE_WITHIN_APARTMENT_REQUEST,
  values,
});

export const deleteDeviceWithinApartment = (companyId: TId, siteId: TId, apartmentId: TId, deviceId: TId) => ({
  apartmentId,
  companyId,
  deviceId,
  siteId,
  type: types.DELETE_DEVICE_WITHIN_APARTMENT_REQUEST,
});

export const invalidateUsersInApartments = () => ({
  type: types.GET_USERS_IN_APARTMENTS_INVALIDATE,
});

export const changeDeviceMVStatus = (
  companyId: TId,
  siteId: TId,
  apartmentId: TId,
  deviceId: TId,
  values: IMobileVideoService
) => ({
  apartmentId,
  companyId,
  deviceId,
  siteId,
  type: types.CHANGE_DEVICE_MV_STATUS_REQUEST,
  values,
});
