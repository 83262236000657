import MuiTextField, { OutlinedTextFieldProps, StandardTextFieldProps } from '@mui/material/TextField';
import React from 'react';
import { Control, Controller, FieldPath, FieldValues, useFormContext } from 'react-hook-form';

export interface IOutlinedTextField<T extends FieldValues> extends Omit<OutlinedTextFieldProps, 'variant'> {
  name: FieldPath<T>;
  hasTopAndBottomMargin?: boolean;
}

export interface IStandardTextField<T extends FieldValues> extends Omit<StandardTextFieldProps, 'variant'> {
  name: FieldPath<T>;
  hasTopAndBottomMargin?: boolean;
}

export interface IOutlinedTextFieldWithControl<T extends FieldValues> extends IOutlinedTextField<T> {
  control: Control<T>;
}

export function OutlinedTextField<T extends FieldValues>({ name, ...other }: IOutlinedTextField<T>): React.JSX.Element;
/** @deprecated primary use component without control (control is acquired from context) */
export function OutlinedTextField<T extends FieldValues>({
  control,
  name,
  ...other
}: IOutlinedTextFieldWithControl<T>): React.JSX.Element;

export function OutlinedTextField<T extends FieldValues>({
  control,
  hasTopAndBottomMargin: spaced,
  name,
  sx,
  ...other
}: IOutlinedTextFieldWithControl<T>): React.JSX.Element {
  const { control: contextControl } = useFormContext<T>();
  return (
    <Controller
      control={control ?? contextControl}
      name={name}
      render={({ field, fieldState }) => (
        <MuiTextField
          {...other}
          {...field}
          disabled={other.disabled}
          error={!!fieldState?.error}
          helperText={fieldState?.error?.message || other.helperText || ' '}
          variant="outlined"
          sx={spaced ? { mb: 1, mt: 2 } : sx}
        />
      )}
    />
  );
}

export function StandardTextField<T extends FieldValues>({ name, ...other }: IStandardTextField<T>): React.JSX.Element {
  const { control } = useFormContext<T>();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <MuiTextField
          {...other}
          {...field}
          disabled={other.disabled}
          error={!!fieldState?.error}
          helperText={fieldState?.error?.message || other.helperText || ' '}
          variant="standard"
        />
      )}
    />
  );
}

export default OutlinedTextField;
