import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { DeviceTypeIcon } from '../../../components';
import { SERVICE } from '../../../config/devices';
import { showErrorBadge, showWarningBadge } from '../../../modules/devices/helpers/helpers';
import DeleteDevice from './DeleteDevice';
import DeviceServices from './DeviceServices';

const DeviceTitle = ({ device, onDelete }) => (
  <Box pb={2}>
    <Grid container>
      <Grid container item lg={10} xs={10}>
        <Grid container item>
          <Box pr={2}>
            <DeviceTypeIcon
              deviceType={device.deviceType}
              showAvatar
              showErrorBadge={showErrorBadge(
                device?.services?.[SERVICE.ACS]?.firmwareStatus,
                device?.verificationStatus
              )}
              showWarningBadge={showWarningBadge(
                device?.services?.[SERVICE.ACS]?.firmwareStatus,
                device?.verificationStatus
              )}
              small
              type={device.type}
            />
          </Box>
          <Box pr={2}>
            <Typography noWrap variant="h4">
              {device.name}
            </Typography>
          </Box>
          <DeviceServices device={device} />
        </Grid>
      </Grid>
      <Grid alignItems="center" container item justifyContent="flex-end" lg={2} xs={2}>
        <Grid item>
          <DeleteDevice deviceId={device.id} onDelete={onDelete} />
        </Grid>
      </Grid>
    </Grid>
  </Box>
);

DeviceTitle.propTypes = {
  device: PropTypes.shape({
    deviceType: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    services: PropTypes.shape({
      [SERVICE.ACS]: PropTypes.shape({
        firmwareStatus: PropTypes.string,
      }),
    }),
    type: PropTypes.string,
    verificationStatus: PropTypes.string,
  }).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DeviceTitle;
