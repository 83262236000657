import { AxiosResponse } from 'axios';
import { IntlFormatters } from 'react-intl';
import * as yup from 'yup';
import { AARequestType, setAARequest } from '../api/apartmentAdminApi';
import { TId } from '../modules/commonTypes';
import messages from './messages';
import { getBasicEmailValidation, getFirstNameValidation, getLastNameValidation } from './user/userCommonValidations';

export function getApartmentUserValidationSchema(
  apartmentId: TId,
  companyId: TId,
  siteId: TId,
  formatMessage: IntlFormatters['formatMessage']
) {
  return {
    email: getBasicEmailValidation(
      formatMessage(messages.createUserValidationSchemaInvalidEmail),
      formatMessage(messages.createUserValidationSchemaRequiredEmail)
    ).test({
      message: formatMessage(messages.createApartmentUserValidationSchemaEmailExists),
      name: 'isExistEmail',
      test: async (email: string) => {
        let state = true;
        await setAARequest(
          AARequestType.HEAD,
          `companies/${companyId}/sites/${siteId}/apartments/${apartmentId}/users`,
          { email }
        ).then((response: AxiosResponse) => {
          state = response.status === 404;
        });

        return state;
      },
    }),
    firstName: getFirstNameValidation(true, formatMessage),
    lastName: getLastNameValidation(formatMessage),
    mobileVideoActivated: yup.boolean().required(),
  };
}
