import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CURRENCY } from '../../config/consts';
import CustomDate from '../CustomDate';
import messages from './messages';
import { styles } from './styles';

const NextPayment = ({ chargeVat, creditsCount, currency, isRecurringPayment, paymentDate, price, priceVat }) =>
  isRecurringPayment ? (
    <Box sx={styles.text}>
      <Typography variant="body2">
        <Box component="span" sx={styles.textColor}>
          <FormattedMessage {...messages.creditPoolCardNextPayment} />
        </Box>
        {creditsCount !== 0 && (
          <FormattedMessage
            values={{
              count: creditsCount,
              span: (chunks) => (
                <Box component="span" sx={{ ...styles.number, ...styles.leftSpace }}>
                  {chunks}
                </Box>
              ),
              text: (chunks) => (
                <Box component="span" sx={styles.textColor}>
                  {chunks}
                </Box>
              ),
            }}
            {...messages.creditPoolCardCreditsCount}
          />
        )}
        <Box component="span" sx={{ ...styles.number, ...styles.space }}>
          {price}
        </Box>
        {currency}
        {chargeVat && (
          <Box component="span" sx={styles.leftSpace}>
            <FormattedMessage
              values={{
                currency,
                price: priceVat,
                text: (chunks) => (
                  <Box component="span" sx={styles.textColor}>
                    {chunks}
                  </Box>
                ),
              }}
              {...messages.creditPoolCardPriceVat}
            />
          </Box>
        )}
      </Typography>
      <Box component="span" sx={styles.textColor}>
        <FormattedMessage {...messages.creditPoolCardDate} values={{ date: <CustomDate dateValue={paymentDate} /> }} />
      </Box>
    </Box>
  ) : null;

NextPayment.propTypes = {
  chargeVat: PropTypes.bool,
  creditsCount: PropTypes.number,
  currency: PropTypes.oneOf(Object.keys(CURRENCY)),
  isRecurringPayment: PropTypes.bool,
  paymentDate: PropTypes.string,
  price: PropTypes.number,
  priceVat: PropTypes.number,
};

NextPayment.defaultProps = {
  chargeVat: false,
  creditsCount: null,
  currency: CURRENCY.EUR,
  isRecurringPayment: false,
  paymentDate: null,
  price: null,
  priceVat: null,
};

export default NextPayment;
