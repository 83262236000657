import MaterialLink, { LinkProps } from '@mui/material/Link';
import React, { forwardRef } from 'react';
import { Link as ReactLinkRouter } from 'react-router-dom';

export interface ILinkProps extends LinkProps {
  to: string;
}

const Link = forwardRef<typeof ReactLinkRouter, ILinkProps>((props, ref: any) => (
  <MaterialLink component={ReactLinkRouter} ref={ref} underline="hover" {...props} />
));

export default Link;
