import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Paper } from '../../../components';
import { DEVICE_TYPE } from '../../../config/devices';
import { useUrlParams } from '../../../helpers/urlParams';
import devicesActions, { selectors as devicesSelectors } from '../../../modules/devices';
import Compact from './Compact/Compact';
import IDTalk from './IDTalk/IDTalk';
import Intercom from './Intercom/Intercom';

const CallSettings = ({ device, isDeviceInvalid, onLoadDeviceChipOptions }) => {
  const { companyId, deviceId, siteId } = useUrlParams();

  useEffect(() => {
    onLoadDeviceChipOptions(companyId, siteId, deviceId);
  }, [companyId, siteId, deviceId]);

  useEffect(() => {
    if (isDeviceInvalid) {
      onLoadDeviceChipOptions(companyId, siteId, deviceId);
    }
  }, [isDeviceInvalid]);

  return (
    <Paper tabContent>
      {[DEVICE_TYPE.ID_COMPACT, DEVICE_TYPE.ID_VIEW].includes(device.type) && <Compact />}
      {[
        DEVICE_TYPE.IP_INTERCOM,
        DEVICE_TYPE.IP_INTERCOM_V2,
        DEVICE_TYPE.IP_STYLE,
        DEVICE_TYPE.IP_ONE_INTERCOM,
        DEVICE_TYPE.DOOR_STATION,
      ].includes(device.type) && <Intercom />}
      {[DEVICE_TYPE.ID_TALK, DEVICE_TYPE.CLIP].includes(device.type) && <IDTalk />}
    </Paper>
  );
};

CallSettings.propTypes = {
  device: PropTypes.shape({
    type: PropTypes.string,
  }).isRequired,
  isDeviceInvalid: PropTypes.bool.isRequired,
  onLoadDeviceChipOptions: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { data, didInvalid } = devicesSelectors.getCurrentDeviceDetailSelector(state);
  return {
    device: data,
    isDeviceInvalid: didInvalid,
  };
};

const mapDispatchToProps = {
  onLoadDeviceChipOptions: devicesActions.getCallSettingsDevicesChips,
};

export default connect(mapStateToProps, mapDispatchToProps)(CallSettings);
