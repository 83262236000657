import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CustomDate, ReadOnlyField } from '../../../../components';
import messages from './messages';

const LastSynchronizationField = ({ value }) => (
  <ReadOnlyField
    label={<FormattedMessage {...messages.Label} />}
    value={value ? <CustomDate dateValue={value} showTime /> : <FormattedMessage {...messages.NeverSynchronized} />}
  />
);

LastSynchronizationField.propTypes = {
  value: PropTypes.string,
};

LastSynchronizationField.defaultProps = {
  value: null,
};

export default LastSynchronizationField;
