import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import my2nDesktop from '../../assets/my2n_desktop.png';
import serviceApi from '../../assets/service_api.svg';
import serviceMv from '../../assets/service_mv.svg';
import serviceRc from '../../assets/service_rc.svg';
import serviceSite from '../../assets/service_site.svg';
import LandingInfoService from './LandingInfoService';
import messages from './messages';

const styles = {
  content: {
    '& strong': {
      color: 'primary.main',
    },
    maxWidth: '1050px',
    mx: 'auto',
    my: 0,
    p: 1,
    pb: 1,
    position: 'relative',
    pt: 0,
    width: '100%',
  },
  root: (theme) => ({
    '& h1': {
      [theme.breakpoints.down('md')]: {
        fontSize: '36px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '32px',
      },
      color: 'grey.900',
      fontSize: '36px',
      fontWeight: 'regular',
      my: 0,
    },
    bgcolor: 'grey.100',
    lineHeight: 1.5,
    minHeight: '170px',
    position: 'relative',
    pt: 6,
    textAlign: 'center',
    width: '100%',
  }),
  services: (theme) => ({
    [theme.breakpoints.down('lg')]: {
      maxWidth: '1050px',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      pb: 3,
    },
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '1200px',
    mx: 'auto',
    my: 0,
    p: 2,
    pb: 5,
    pt: 0,
    width: '100%',
  }),
  servicesImage: (theme) => ({
    [theme.breakpoints.down('lg')]: {
      width: '246px',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    '& img': {
      maxWidth: '100%',
    },
  }),
  servicesItem: {
    alignItems: 'center',
    display: 'flex',
    flexBasis: 'auto',
    flexDirection: 'column',
    flexGrow: 1,
    flexShrink: 0,
    justifyContent: 'center',
  },
};

const LandingInfo = ({ refMobileVideoService, refPartnerApi, refRemoteConfigurationService, refSiteManagement }) => {
  const onScroll = (reference) => (event) => {
    event.preventDefault();
    reference.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.content}>
        <h1>
          <FormattedMessage values={{ strong: (text) => <strong>{text}</strong> }} {...messages.landingInfoTitle} />
        </h1>
      </Box>
      <Box sx={styles.services}>
        <Box sx={styles.servicesItem}>
          <LandingInfoService
            image={<img alt="Remote Configuration" src={serviceRc} />}
            onClick={onScroll(refRemoteConfigurationService)}
            text={
              <FormattedMessage
                {...messages.landingInfoServicesRc}
                values={{
                  sup: (text) => <sup>{text}</sup>,
                }}
              />
            }
          />
          <LandingInfoService
            image={<img alt="My2N App" src={serviceMv} />}
            onClick={onScroll(refMobileVideoService)}
            text={
              <FormattedMessage
                {...messages.landingInfoServicesMv}
                values={{
                  sup: (text) => <sup>{text}</sup>,
                }}
              />
            }
          />
        </Box>
        <Box sx={styles.servicesImage}>
          <img alt="My2N desktop" src={my2nDesktop} />
        </Box>
        <Box sx={styles.servicesItem}>
          <LandingInfoService
            image={<img alt="Partner API" src={serviceApi} />}
            onClick={onScroll(refPartnerApi)}
            text={
              <FormattedMessage
                {...messages.landingInfoServicesApi}
                values={{
                  sup: (text) => <sup>{text}</sup>,
                }}
              />
            }
          />
          <LandingInfoService
            image={<img alt="Sites management" src={serviceSite} />}
            onClick={onScroll(refSiteManagement)}
            text={<FormattedMessage {...messages.landingInfoServicesSite} />}
          />
        </Box>
      </Box>
    </Box>
  );
};

LandingInfo.propTypes = {
  refMobileVideoService: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  refPartnerApi: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
    .isRequired,
  refRemoteConfigurationService: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  refSiteManagement: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
    .isRequired,
};

export default LandingInfo;
