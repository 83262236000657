import http, { encode } from './index';

export const getUser = async (user) => http.get(`/users/${encode(user)}`);

export const getSiteUsers = async (company, site, params) =>
  http.get(`/companies/${encode(company)}/sites/${encode(site)}/active-users`, {
    params,
  });

export const getCompanyUsers = async (company, site, params) =>
  http.get(`/companies/${encode(company)}/users`, {
    params,
  });

export const deleteUser = async (user, values) => http.put(`/users/${encode(user)}/roles`, values);

export const deleteSiteUser = async (company, site, user, values) =>
  http.put(`/companies/${encode(company)}/sites/${encode(site)}/users/${encode(user)}/roles`, values);

export const getSiteUserDetail = async (company, site, user) =>
  http.get(`/companies/${encode(company)}/sites/${encode(site)}/users/${encode(user)}`);

export const getCompanyAdminDetail = async (company, user) =>
  http.get(`/companies/${encode(company)}/users/${encode(user)}`);

export const updateProfile = async (values) => http.put('/users/current', values);

export const updateProfilePatch = async (values) => http.patch('/users/current', values);

export const createUser = async (values) => http.post('/users', values);

export const updateSiteUserPatch = async (company, site, user, values) =>
  http.patch(`/companies/${encode(company)}/sites/${encode(site)}/users/${encode(user)}`, values);

export const updateApartmentUser = async (company, site, apartment, user, values) =>
  http.put(
    `/companies/${encode(company)}/sites/${encode(site)}` + `/apartments/${encode(apartment)}/users/${encode(user)}`,
    values
  );

export const updateAdminUser = async (company, user, values) =>
  http.put(`/companies/${encode(company)}/users/${encode(user)}`, values);

export const updateAdminUserPatch = async (company, user, values) =>
  http.patch(`/companies/${encode(company)}/users/${encode(user)}`, values);

export const updatePassword = async (values) => http.put('/users/current', values);

export const setRole = async (userId, data) => http.put(`/users/${encode(userId)}/roles`, data);

export const getUserPin = async (companyId, siteId, userId) =>
  http.get(`/companies/${encode(companyId)}/sites/${encode(siteId)}/users/${encode(userId)}/credentials/pin`);

export const revokeUserPin = async (companyId, siteId, userId) =>
  http.delete(`/companies/${encode(companyId)}/sites/${encode(siteId)}/users/${encode(userId)}/credentials/pin`);

export const createUserRfid = async (companyId, siteId, userId, rfidCode) =>
  http.post(`/companies/${encode(companyId)}/sites/${encode(siteId)}/users/${encode(userId)}/credentials/rfid`, {
    rfidCode,
  });

export const getRfidCardOwner = async (companyId, siteId, rfidCode) =>
  http.get(`/companies/${encode(companyId)}/sites/${encode(siteId)}/accesses/credentials/rfid/${encode(rfidCode)}`);

export const getUserRfidCards = async (companyId, siteId, userId) =>
  http.get(`/companies/${encode(companyId)}/sites/${encode(siteId)}/users/${encode(userId)}/credentials/rfid`);

export const removeUserRfidCard = async (companyId, siteId, userId, rfidId) =>
  http.delete(
    `/companies/${encode(companyId)}/sites/${encode(siteId)}/users/${encode(userId)}` + `/credentials/rfid/${rfidId}`
  );

export const getImportUsersStatus = async (companyId, siteId) =>
  http.get(`/companies/${encode(companyId)}/sites/${encode(siteId)}/imports`);

export const importUsersStart = async (companyId, siteId, data) =>
  http.post(`/companies/${encode(companyId)}/sites/${encode(siteId)}/imports/json`, data);

export const importUsersAbort = async (companyId, siteId) =>
  http.put(`/companies/${encode(companyId)}/sites/${encode(siteId)}/imports/abort`);

export const getSiteUserDevicesRequest = async (company, site, user, params) =>
  http.get(`companies/${encode(company)}/sites/${encode(site)}/users/${encode(user)}/devices`, {
    params: {
      ...params,
    },
  });
