import { MessageDescriptor } from 'react-intl';
import { UserCredentials } from '../../modules/commonTypes';
import messages from './messages';

export function getStatusTooltipTitle(isServiceActive: boolean, service: UserCredentials): MessageDescriptor {
  return isServiceActive ? ActiveUserCredentialsMessage[service] : InactiveUserCredentialsMessage[service];
}

const ActiveUserCredentialsMessage: { [key in UserCredentials]: MessageDescriptor } = {
  [UserCredentials.PIN]: messages.UserAccessSettingsStatusPin,
  [UserCredentials.RFID]: messages.UserAccessSettingsStatusRfId,
  [UserCredentials.BT]: messages.UserAccessSettingsStatusBluetooth,
};

const InactiveUserCredentialsMessage: { [key in UserCredentials]: MessageDescriptor } = {
  [UserCredentials.PIN]: messages.UserAccessSettingsStatusPinNot,
  [UserCredentials.RFID]: messages.UserAccessSettingsStatusRfIdNot,
  [UserCredentials.BT]: messages.UserAccessSettingsStatusBluetoothNot,
};
