export default {
  buttons: {
    '& button': {
      ml: 1,
    },
    mb: 2,
    mt: 4,
    textAlign: 'right',
  },
  changePassword: {
    mb: 3,
  },
  formTitle: {
    fontSize: '16px !important',
    mb: 1,
  },
  infoForm: {
    pt: 3,
  },
  root: {
    px: 3,
    py: 1,
    textAlign: 'left',
  },
};
