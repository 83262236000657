import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { FE_ROLES } from '../../../../../config/consts';
import { useUrlParams } from '../../../../../helpers/urlParams';
import * as siteSelectors from '../../../../../modules/sites/selectors';
import * as userSelectors from '../../../../../modules/user/selectors';
import { getValidationSchema } from '../utils';

export function useGetGlobalHooks() {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { companyId, siteId } = useUrlParams();
  const userRole = useSelector(userSelectors.getCurrentUserDetailRole);

  const { isSiteMdu, siteLang } = {
    isSiteMdu: useSelector(siteSelectors.isSiteMdu),
    siteLang: useSelector(siteSelectors.getSiteLang),
  };

  const methods = useForm({
    defaultValues: {
      apartment: [],
      email: '',
      firstName: '',
      lang: '',
      lastName: '',
      role: '',
    },
    mode: 'onChange',
    resolver: yupResolver(
      getValidationSchema(companyId, siteId, isSiteMdu, isFirstNameRequired(userRole), formatMessage)
    ),
    shouldUnregister: true,
  });

  return {
    dispatch,
    generalInfo: { companyId, isSiteMdu, siteId, siteLang },
    methods,
    userRole,
  };
}

export function isFirstNameRequired(userRole) {
  return userRole !== FE_ROLES.LOGIN_LESS && userRole !== FE_ROLES.GUEST;
}
