import CheckIcon from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import actions, { selectors } from '../../modules/accessSettings';
import PrimaryModal from '../PrimaryModal';
import { useGetGlobalHooks } from './hooks/getGlobalHooks';
import messages from './messages';

const RacActivateModal = ({ deviceId, onClose, open }) => {
  const { dispatch, formatMessage, handleSubmit } = useGetGlobalHooks();
  const isLoading = useSelector(selectors.getIsActivateRacAccessGroupDeviceFetching);

  const onSubmit = () => {
    dispatch(actions.activateRacAccessGroupDevice(deviceId));
    onClose();
  };

  const getIsOpen = open || isLoading;
  return (
    getIsOpen && (
      <FormProvider>
        <form onSubmit={handleSubmit(onSubmit)}>
          <PrimaryModal
            actionButtonLabel={formatMessage(messages.racActivateModalActivate)}
            buttonIcon={<CheckIcon />}
            isActionButtonDisabled={false}
            isLoading={isLoading}
            name="rac-activate-modal"
            onClose={onClose}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onCreate={() => {}}
            open={getIsOpen}
            title={formatMessage(messages.racActivateModalTitle)}
          >
            <Typography variant="body1">
              <FormattedMessage {...messages.racActivateModalText} />
            </Typography>
          </PrimaryModal>
        </form>
      </FormProvider>
    )
  );
};

RacActivateModal.propTypes = {
  deviceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

RacActivateModal.defaultProps = {
  deviceId: '',
};

export default RacActivateModal;
