import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { InfoModal } from '../../../../../../../components/_DesignSystemWrappers/Modal';
import QRPanel from '../../../../../../../components/QRPanel/QRPanel';
import { IModalBase } from '../../../../../../../modules/commonTypes';
import messages from './messages';

interface IShowQrModal extends IModalBase {
  onClose: () => void;
  open: boolean;
  qrUrl: string;
}

export function ShowQrModal({ onClose, open, qrUrl }: IShowQrModal) {
  const { formatMessage } = useIntl();

  function handleSubmit() {
    onClose();
  }

  return (
    <InfoModal
      buttonText={formatMessage({ ...messages.AamodalShowGuestQrSubmit })}
      onClose={onClose}
      open={open}
      primaryButtonAction={handleSubmit}
      title={formatMessage({ ...messages.AamodalShowGuestQrTitle })}
      muiDialogProps={{ maxWidth: 'xs' }}
    >
      <Typography variant="body2" sx={{ pb: 1 }}>
        <FormattedMessage {...messages.AamodalShowGuestQrText1} />
      </Typography>
      <Box sx={{ mt: 2 }}>
        <QRPanel url={qrUrl} />
      </Box>
    </InfoModal>
  );
}
