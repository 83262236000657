import DomainIcon from '@mui/icons-material/Domain';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from '../../components';
import messages from './messages';

const styles = {
  content: {
    display: {
      sm: 'inline-table',
      xs: 'block',
    },
  },
  icon: {
    '& svg': {
      color: 'secondary.main',
      fontSize: (theme) => theme.typography.h3.fontSize,
    },
    border: (theme) => `1px dashed ${theme.palette.secondary.main}`,
    borderRadius: '100%',
    display: {
      sm: 'table-cell',
      xs: 'none',
    },
    height: '88px !important',
    textAlign: 'center',
    verticalAlign: 'middle',
    width: '88px',
  },
  info: {
    '& svg': {
      mx: 2,
      my: 1,
    },
    backgroundColor: 'background.default',
    boxShadow: 'none',
    color: 'secondary.main',
    display: 'flex',
    mt: 4,
    pl: 0,
    pr: 4,
    py: 1,
  },
  list: {
    '& ul': {
      my: 0,
    },
    ml: {
      sm: 3,
      xs: 0,
    },
  },
};

function CompanyAboutModal({ onClose, onOpenSiteAboutModal, open }) {
  return (
    <Modal
      actions={(
        <Box>
          <Button color="primary" onClick={() => {
            onClose();
            onOpenSiteAboutModal();
          }}>
            <FormattedMessage {...messages.companyAboutModalWhatIsSite} />
          </Button>
          <Button color="primary" onClick={onClose}>
            <FormattedMessage {...messages.companyAboutModalClose} />
          </Button>
        </Box>
      )}
      maxWidth="sm"
      name="company-about-modal"
      onClose={onClose}
      open={open}
      title={
        <FormattedMessage {...messages.companyAboutModalTitle} />
      }
    >
      <Box sx={styles.content}>
        <Box sx={styles.icon}>
          <DomainIcon />
        </Box>
        <Box sx={styles.list}>
          <ul>
            <li>
              <FormattedMessage {...messages.companyAboutModalListItemOne} />
            </li>
            <li>
              <FormattedMessage {...messages.companyAboutModalListItemTwo} />
            </li>
            <li>
              <FormattedMessage {...messages.companyAboutModalListItemThird} />
            </li>
          </ul>
        </Box>
      </Box>
      <Paper sx={styles.info}>
        <InfoOutlinedIcon />
        <FormattedMessage
          {...messages.companyAboutModalNotification}
          tagName="p"
          values={{ strong: (chunks) => <strong>{chunks}</strong> }}
        />
      </Paper>
    </Modal>
  );
}

CompanyAboutModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onOpenSiteAboutModal: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default CompanyAboutModal;
