import http, { encode } from './index';

export const getCompany = async (companyId) => http.get(`/companies/${encode(companyId)}`);
export const addCompany = async (values) => http.post('/companies', values);

export const deleteCompany = async (company) => http.delete(`/companies/${encode(company)}`);

export const deleteRole = async (company, role) => http.delete(`/companies/${encode(company)}/roles/${role}`);

export const addRole = async (company, role) =>
  http.post(`/companies/${encode(company)}/roles`, role, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

export const changeSettings = async (company, values) => http.put(`/companies/${encode(company)}`, values);

export const getMvSdk = async (company) => http.get(`/companies/${encode(company)}/mvsdk`);

export const updateMvSdk = async (company, values) => http.put(`/companies/${encode(company)}/mvsdk`, values);

export const resetMvSdk = async (company) => http.delete(`/companies/${encode(company)}/mvsdk`);
