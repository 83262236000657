import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Modal from '../Modal';
import messages from './messages';

const ConfirmDialog = ({ children, headerVariant, onCancel, onConfirm, open, submitButton, title }) => (
  <Modal
    actions={
      <>
        <Button color="secondary" onClick={onCancel}>
          <FormattedMessage {...messages.confirmDialogCancelButton} />
        </Button>
        {submitButton(onConfirm) || (
          <Button color="primary" onClick={onConfirm} variant="contained">
            {<FormattedMessage {...messages.confirmDialogConfirmButton} />}
          </Button>
        )}
      </>
    }
    headerVariant={headerVariant}
    maxWidth="xs"
    name="confirm-dialog"
    onClick={(event) => event.stopPropagation()}
    onClose={onCancel}
    open={open}
    title={title}
  >
    <Box>{children}</Box>
  </Modal>
);

ConfirmDialog.propTypes = {
  children: PropTypes.node.isRequired,
  headerVariant: PropTypes.oneOf(['primary', 'danger']),
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  submitButton: PropTypes.func,
  title: PropTypes.node,
};

ConfirmDialog.defaultProps = {
  headerVariant: 'primary',
  submitButton: () => null,
  title: null,
};

export default ConfirmDialog;
