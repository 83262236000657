import { defineMessages } from 'react-intl';

export default defineMessages({
  callSettingsDataGridColumnsDeviceName: {
    defaultMessage: 'Device name',
    description: 'Intercoms Data Grid - device name column',
    id: 'callSettingsDataGrid.columns.deviceName',
  },
  callSettingsDataGridNoRowsHeader: {
    defaultMessage: 'No devices yet',
    description: 'Intercoms Data Grid - no rows header',
    id: 'callSettingsDataGrid.noRows.header',
  },
  callSettingsDataGridNoRowsText: {
    defaultMessage: 'Once you add a new device, it will appear here',
    description: 'Intercoms Data Grid - no rows text',
    id: 'callSettingsDataGrid.noRows.text',
  },
  callSettingsTitle: {
    defaultMessage: 'Call settings',
    description: 'Call Settings - title page',
    id: 'callSettings.title',
  },
  callSettingsTypesAnsweringUnits: {
    defaultMessage: 'Answering units',
    description: 'Call settings - answering units type',
    id: 'callSettings.types.answeringUnits',
  },
  callSettingsTypesIntercoms: {
    defaultMessage: 'Intercoms',
    description: 'Call Settings - intercoms type',
    id: 'callSettings.types.intercoms',
  },
  callSettingsTypesPhonebooks: {
    defaultMessage: 'Phonebooks',
    description: 'Call Settings - phonebooks type',
    id: 'callSettings.types.phonebooks',
  },
  deletePhonebookModalCancel: {
    defaultMessage: 'Cancel',
    description: 'Delete phonebook Modal - cancel button',
    id: 'deletePhonebookModal.cancel',
  },
  deletePhonebookModalConfirm: {
    defaultMessage: 'Delete phonebook',
    description: 'Phonebook Form Modal - confirm button',
    id: 'deletePhonebookModal.confirm',
  },
  deletePhonebookModalDescription1: {
    defaultMessage: 'You will delete the phone book from all the devices on which it is set up.',
    description: 'Phonebook Form Modal - description 1',
    id: 'deletePhonebookModal.description1',
  },
  deletePhonebookModalDescription2: {
    defaultMessage: 'Deleting the phone book will: {list}',
    description: 'Phonebook Form Modal - description 2',
    id: 'deletePhonebookModal.description2',
  },
  deletePhonebookModalListRow1: {
    defaultMessage: 'remove it from the mobile app for all users,',
    description: 'Phonebook Form Modal - list row 1',
    id: 'deletePhonebookModal.listRow1',
  },
  deletePhonebookModalListRow2: {
    defaultMessage: 'replace your phone book with a default one.',
    description: 'Phonebook Form Modal - list row 2',
    id: 'deletePhonebookModal.listRow2',
  },
  deletePhonebookModalTitle: {
    defaultMessage: 'Delete phonebook',
    description: 'Phonebook Form Modal - title',
    id: 'deletePhonebookModal.title',
  },
  phonebookFormModalCancel: {
    defaultMessage: 'Cancel',
    description: 'Phonebook Form Modal - cancel button',
    id: 'phonebookFormModal.cancel',
  },
  phonebookFormModalLabelName: {
    defaultMessage: 'Phonebook name',
    description: 'Phonebook Form Modal - name label',
    id: 'phonebookFormModal.label.name',
  },
  phonebookFormModalNameExist: {
    defaultMessage: 'Phonebook with this name already exists.',
    description: 'Phonebook Form Modal - name exists',
    id: 'phonebookFormModal.nameExist',
  },
  phonebookFormModalRequired: {
    defaultMessage: 'Value is mandatory',
    description: 'Phonebook Form Modal - required value',
    id: 'phonebookFormModal.required',
  },
  phonebookFormModalSubmit: {
    defaultMessage: 'Save',
    description: 'Phonebook Form Modal - submit button',
    id: 'phonebookFormModal.submit',
  },
  phonebookFormModalTitleAdd: {
    defaultMessage: 'Add new phonebook',
    description: 'Phonebook Form Modal - add new phonebook',
    id: 'phonebookFormModal.title.add',
  },
  phonebookFormModalTitleEdit: {
    defaultMessage: 'Edit phonebook',
    description: 'Phonebook Form Modal - edit phonebook',
    id: 'phonebookFormModal.title.edit',
  },
  phoneBookInfoText: {
    defaultMessage: 'Please read the {link} to learn more about Phonebooks.',
    description: 'Phonebook info text',
    id: 'phoneBookInfo.text',
  },
  phonebookMembersTableColumnsDeviceId: {
    defaultMessage: 'Device ID',
    description: 'Phonebook Members Table - device id column',
    id: 'phonebookMembersTable.columns.deviceId',
  },
  phonebookMembersTableColumnsName: {
    defaultMessage: 'Name',
    description: 'Phonebook Members Table - name column',
    id: 'phonebookMembersTable.columns.name',
  },
  phonebookMembersTableColumnsType: {
    defaultMessage: 'Type',
    description: 'Phonebook Members Table - type column',
    id: 'phonebookMembersTable.columns.type',
  },
  phonebookMembersTableNoResults: {
    defaultMessage: 'No results found.',
    description: 'Phonebook Members Table - no results',
    id: 'phonebookMembersTable.noResults',
  },
  phonebookMembersTableSearchPlaceholder: {
    defaultMessage: 'Device name, ID',
    description: 'Phonebook Members Table - Search input placeholder',
    id: 'phonebookMembersTable.search.placeholder',
  },
  phonebookMembersTableTitle: {
    defaultMessage: 'Phonebook Devices',
    description: 'Phonebook Members Table - title',
    id: 'phonebookMembersTable.title',
  },
  phonebooksDataGridColumnsDeviceCount: {
    defaultMessage: 'Device Count',
    description: 'Phonebooks DataGrid - device count column',
    id: 'phonebooksDataGrid.columns.deviceCount',
  },
  phonebooksDataGridColumnsNameDesktop: {
    defaultMessage: 'Phonebook Name',
    description: 'Phonebooks DataGrid - name column for desktop',
    id: 'phonebooksDataGrid.columns.nameDesktop',
  },
  phonebooksDataGridColumnsNameMobile: {
    defaultMessage: 'Phonebook',
    description: 'Phonebooks DataGrid - name column for mobile',
    id: 'phonebooksDataGrid.columns.nameMobile',
  },
  phonebooksDataGridRowMenuCantDelete: {
    defaultMessage: 'Default phonebook cannot be deleted',
    description: 'Phonebooks DataGrid - cannot delete phonebook',
    id: 'phonebooksDataGrid.rowMenu.cantDelete',
  },
  phonebooksDataGridRowMenuDelete: {
    defaultMessage: 'Delete',
    description: 'Phonebooks DataGrid - delete phonebook',
    id: 'phonebooksDataGrid.rowMenu.delete',
  },
  phonebooksDataGridRowMenuEditPhonebook: {
    defaultMessage: 'Edit phonebook',
    description: 'Phonebooks DataGrid - edit phonebook',
    id: 'phonebooksDataGrid.rowMenu.editPhonebook',
  },
  toolsAddNewPhonebook: {
    defaultMessage: 'Add new Phonebook',
    description: 'Tools - add new phonebook',
    id: 'tools.addNewPhonebook',
  },
  toolsSearchPlaceholder: {
    defaultMessage: 'Phonebook name',
    description: 'Tools - search placeholder',
    id: 'tools.searchPlaceholder',
  },
});
