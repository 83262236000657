import { createSelector } from 'reselect';
import { SERVICE } from '../../config/devices';
import { SITE_TYPE } from '../../config/sites';
import { getSiteUsersFilteredData } from './helpers';

export const getSiteData = ({ sites }) => sites.data;
export const getSiteRoles = ({ sites }) => sites.data.roles;
export const getSiteLang = ({ sites }) => sites.data?.locale || null;
export const getSiteId = (state) => getSiteData(state)?.id ?? undefined;
export const getSiteService = (state, serviceName) => getSiteData(state)?.services?.[serviceName] || null;

export const getSitesForTableSelector = (sites, selector) => ({
  data: sites[selector]?.data || [],
  didInvalid: sites[selector]?.didInvalid || false,
  filter: sites[selector]?.filter || { fulltext: '' },
  isFetching: sites[selector]?.isFetching || false,
  order: sites[selector]?.order || '',
  page: sites[selector]?.page || 1,
  resultsFiltered: sites[selector]?.resultsFiltered || 0,
  resultsTotal: sites[selector]?.resultsTotal || 0,
  rowsPerPage: sites[selector]?.rowsPerPage || 15,
});

export const getSitesListForTableSelector = ({ sites }) => getSitesForTableSelector(sites, 'list');
export const getSitesRecentForTableSelector = ({ sites }) => getSitesForTableSelector(sites, 'recentSites');

export const getSitesServiceMobileVideoLicenceModel = (state) => {
  const mobileVideo = getSiteService(state, SERVICE.MOBILE_VIDEO);
  return mobileVideo && mobileVideo.licenceModel;
};

export const getSitesServiceMobileVideoProduct = (state) => getSitesServiceMobileVideoLicenceModel(state)?.product;

export const getUnlicensedMobileVideoCount = (state) =>
  getSitesServiceMobileVideoLicenceModel(state)?.unlicensedCount || 0;

export const getLastVisitedSite = (state) => state.sites.recentSites.data?.[0]?.id || null;
export const getMy2nId = (state) => state.sites.data.my2nId || '';
export const getDashboardDevices = (state) => state.sites.dashboard.data?.devices || {};
export const getDashboardIsFetching = (state) => state.sites.dashboard.isFetching;
export const getDashboardIsInvalid = (state) => state.sites.dashboard.didInvalid;

export const getDashboardMvService = (state) => state.sites.dashboard.data?.services?.[SERVICE.MOBILE_VIDEO] || {};

export const getDashboardMvServiceLicenceModel = (state) =>
  state.sites.dashboard.data?.services?.[SERVICE.MOBILE_VIDEO]?.licenceModel || {};

export const getCombinedMultiplier = (state) => getDashboardMvServiceLicenceModel(state)?.combinedMultiplier || null;

export const getDashboardMvServiceApartments = (state) =>
  state.sites.dashboard.data?.services?.[SERVICE.MOBILE_VIDEO]?.apartments?.status || {};
export const getDashboardMvServiceDevices = (state) =>
  state.sites.dashboard.data?.services?.[SERVICE.MOBILE_VIDEO]?.devices || [];

export const getCurrentSiteType = (state) => state.sites.data?.type || SITE_TYPE.UNKNOWN;
export const isSiteFetching = (state) => state.sites.isFetching;
export const isSiteTypeModalShow = (state) => state.sites.setTypeModal.show;
export const isSubmitSiteTypeModal = (state) => state.sites.setTypeModal.isSubmit;
export const getErrorSiteTypeModal = (state) => state.sites.setTypeModal.error;

export const isSiteError = (state) => !!state.sites.error;

export const getApartmentDeviceLimit = (state) => state.sites.data?.apartmentDeviceLimit || 0;

export const isSiteMdu = (state) => state.sites.data?.type === SITE_TYPE.MDU;

export const getSiteUsers = createSelector(
  ({ sites }) => sites.siteUsers.data,
  (sitesData) => sitesData || []
);

export const getSiteUsersIsFetching = ({ sites }) => sites.siteUsers.isFetching;

export const getSiteUsersData = (state) => getSiteUsersFilteredData(state);

export const getSiteDataFromTypeModal = (state) => state.sites.setTypeModal.site;
