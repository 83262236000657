import { defineMessages } from 'react-intl';

export default defineMessages({
  ImsNewPasswordAlertBody: {
    defaultMessage:
      'Until you set a new password, your original password is still valid.{br} After setting your password, you will be logged in immediately.',
    id: 'ims.newPassword.alert.body',
  },
  ImsNewPasswordButtonSetPassword: {
    defaultMessage: 'Set password',
    id: 'ims.newPassword.button.setPassword',
  },

  ImsNewPasswordInputHelperText: {
    defaultMessage: 'Use at least: 8 characters, 1 uppercase letter, 1 lowercase letter, 1 digit',
    id: 'ims.newPassword.input.helperText',
  },

  ImsNewPasswordInputLabel: {
    defaultMessage: 'New password',
    id: 'ims.newPassword.input.label',
  },

  ImsNewPasswordSuccess: {
    defaultMessage: 'New password set successfully',
    id: 'ims.newPassword.success',
  },
});
