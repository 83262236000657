import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors as siteSelectors } from '../../../modules/sites';
import { getErrors } from '../helpers/getErrors';
import { useImportDataContext } from '../ImportDataProvider';
import SectionWrapper from '../SectionWrapper';
import DataGrid from './DataGrid';
import DataGridSummary from './DataGridSummary';

function CheckStep() {
  const [viewErrors, setViewErrors] = useState(false);
  const { importData, setImportData } = useImportDataContext();

  const errors = useMemo(() => getErrors(importData), [importData]);

  useEffect(() => {
    if (errors.length === 0 && viewErrors) {
      setViewErrors(false);
    }
  }, [errors]);

  return (
    <>
      <SectionWrapper>
        <DataGridSummary
          dataLength={importData.length}
          errorsLength={errors.length}
          onSetViewErrors={() => setViewErrors(!viewErrors)}
          viewErrors={viewErrors}
        />
      </SectionWrapper>
      <DataGrid data={importData} errors={errors} onSetData={setImportData} viewErrors={viewErrors} />
    </>
  );
}

export default CheckStep;
