import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { OryResponse } from '../../../types';
import { updatePassword } from './settingsApi';

export interface IChangePasswordProps {
  password: string;
}
export interface IUseSettingsApi {
  updatePassword: (props: IChangePasswordProps) => Promise<OryResponse>;
  isOryApiFetching: boolean;
}

export function useOrySettingsApi(): IUseSettingsApi {
  const [isFetching, setIsFetching] = useState(false);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  return {
    isOryApiFetching: isFetching,
    updatePassword: (props) => updatePassword(props, setIsFetching, dispatch, formatMessage),
  };
}
