import AccessIcon from '@mui/icons-material/GppGood';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import RowMenuItem from '../../../../components/DataGrid/RowMenuItem';
import messages from './messages';

function SetAccessGroups({ onClick }) {
  const { formatMessage } = useIntl();
  return (
    <RowMenuItem
      icon={<AccessIcon />}
      label={formatMessage(messages.accessGroupApartmentsDataGridDataGridRowMenuAccessGroups)}
      onClick={onClick}
    />
  );
}

SetAccessGroups.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default SetAccessGroups;
