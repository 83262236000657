import messages from '../messages';

export default (closeModal, context, formatMessage) => ({
  closeOnlyByButton: context === 'firstLogin',
  hideCloseButton: context === 'firstLogin',
  onClose: closeModal,
  primaryButtonText: formatMessage(
    context === 'firstLogin' ? messages.installAppModalAlreadyInstalledButton : messages.installAppModalCloseButton
  ),
  secondaryButtonText: context === 'firstLogin' ? formatMessage(messages.installAppModalLaterButton) : undefined,
  title: formatMessage(
    context === 'firstLogin' ? messages.installAppModalWelcomeTitle : messages.installAppModalInstallTitle
  ),
});
