import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import actions, { selectors } from '../../modules/cookies';
import messages from './messages';

const CookieSettingsButton = ({ floating, iconColor, isCookieConsentOpen, setOpenCookieConsent, tooltipPlacement }) => (
  <Box
    sx={
      floating
        ? {
            bottom: 16,
            position: 'fixed',
            right: 16,
            zIndex: 10000,
          }
        : {}
    }
  >
    <Tooltip placement={tooltipPlacement} title={<FormattedMessage {...messages.cookieSettingsButtonTooltip} />}>
      <Box color={iconColor}>
        <IconButton onClick={() => setOpenCookieConsent(!isCookieConsentOpen)} size="small">
          <SettingsIcon />
        </IconButton>
      </Box>
    </Tooltip>
  </Box>
);

CookieSettingsButton.propTypes = {
  floating: PropTypes.bool,
  iconColor: PropTypes.string,
  isCookieConsentOpen: PropTypes.bool.isRequired,
  setOpenCookieConsent: PropTypes.func.isRequired,
  tooltipPlacement: PropTypes.string,
};

CookieSettingsButton.defaultProps = {
  floating: false,
  iconColor: '#819AAD',
  tooltipPlacement: 'left',
};

const mapStateToProps = (state) => ({
  isCookieConsentOpen: selectors.getIsCookieSettingsOpen(state),
});

const mapDispatchToProps = {
  setOpenCookieConsent: actions.setCookieSettingsOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(CookieSettingsButton);
