import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import rc1Img from '../../assets/rc1.svg';
import rc2Img from '../../assets/rc2.svg';
import rc3Img from '../../assets/rc3.svg';
import rc4Img from '../../assets/rc4.svg';
import messages from './messages';

const styles = {
  content: {
    '& img': {
      maxWidth: '110px',
      maxWidthMd: '90px',
      mb: 3.75,
      mx: 'auto',
    },
    margin: '0 auto',
    maxWidth: '1050px',
    position: 'relative',
    px: 1.25,
    py: 0,
  },
  item: (theme) => ({
    [theme.breakpoints.down('sm')]: {
      '&:nth-of-type(3n)': {
        clear: 'both',
      },
      display: 'block',
      float: 'left',
      width: '50%',
    },
    display: 'table-cell',
    pb: 3.75,
    textAlign: 'center',
    width: 'auto',
  }),
  list: {
    display: {
      sm: 'table',
      sx: 'block',
    },
    listStyleType: 'none',
    m: 0,
    p: 0,
    tableLayout: 'auto',
    width: '100%',
  },
  name: {
    color: 'text.default',
    display: 'block',
    fontSize: {
      lg: '18px',
      md: '16px',
      sm: '14px',
    },
    maxWidth: '220px',
    maxWidthLg: '195px',
    maxWidthMd: '170px',
    mx: 'auto',
    px: {
      md: 0,
      xs: 1.25,
    },
    textAlign: 'center',
  },
  root: {
    background: 'white',
    lineHeight: 1.5,
    minHeight: '420px',
    overflow: 'hidden',
    pb: 0,
    pt: 8.75,
    px: 0,
  },
  title: {
    color: 'text.primary',
    fontSize: {
      lg: '38px',
      md: '36px',
      xs: '34px',
    },
    fontWeight: 400,
    mb: 8,
    mt: 0,
    px: 2.5,
    py: 0,
    textAlign: 'center',
  },
};

const Features = () => (
  <Box sx={styles.root}>
    <Typography component="h2" sx={styles.title}>
      <FormattedMessage
        values={{
          br: <br />,
          nbsp: <>&nbsp;</>,
          sup: (chunks) => <sup>{chunks}</sup>,
        }}
        {...messages.rcLandingPageServicesTitle}
      />
    </Typography>
    <Box sx={styles.content}>
      <Box component="ul" sx={styles.list}>
        <Box component="li" sx={styles.item}>
          <img alt="rc1" src={rc1Img} />
          <Typography>
            <FormattedMessage {...messages.rcLandingPageServicesListSaveOnTravelCost} />
          </Typography>
        </Box>
        <Box component="li" sx={styles.item}>
          <img alt="rc2" src={rc2Img} />
          <Typography>
            <FormattedMessage {...messages.rcLandingPageServicesListNetworkSetup} />
          </Typography>
        </Box>
        <Box component="li" sx={styles.item}>
          <img alt="rc3" src={rc3Img} />
          <Typography>
            <FormattedMessage {...messages.rcLandingPageServicesListSecureConnection} />
          </Typography>
        </Box>
        <Box component="li" sx={styles.item}>
          <img alt="rc4" src={rc4Img} />
          <Typography>
            <FormattedMessage {...messages.rcLandingPageServicesListFreeOfCharge} />
          </Typography>
        </Box>
      </Box>
    </Box>
  </Box>
);

export default Features;
