import { RecoveryFlow } from '@ory/client';
import { AxiosError } from 'axios';
import { IntlFormatters } from 'react-intl';
import { Dispatch } from 'redux';
import { oryApi } from '../../../config/oryFrontendApi';
import { processOryApiError } from '../../../errors/errorParser';
import { getOryErrorFromOryErrorMessageId } from '../../../errors/oryErrorMessages';
import {
  IOryGeneralError,
  IOryRecoveryFlow,
  OryErrorMessage,
  OryResponse,
  OryResponseWithData,
  OrySuccess,
} from '../../../types';
import { getCSRFToken } from '../../../utils/CSRFToken';
import { hasResponseOKStatus } from '../../../utils/responseStatus';
import { tryToGetFlowValidationMessageId } from '../../../utils/validationMessageId';

export async function createOrGetOryRecoveryFlow(
  params: { email: string; existingFlowId?: string },
  fetchingState: React.Dispatch<React.SetStateAction<boolean>>,
  dispatch: Dispatch,
  formatMessage: IntlFormatters['formatMessage']
): Promise<OryResponseWithData<IOryRecoveryFlow>> {
  fetchingState(true);
  try {
    let recoveryFlowInit;
    if (params.existingFlowId) {
      recoveryFlowInit = await oryApi.getRecoveryFlow({ id: params.existingFlowId });
    } else {
      recoveryFlowInit = await oryApi.createBrowserRecoveryFlow();
    }
    const csrfToken = getCSRFToken(recoveryFlowInit.data);

    const recoveryFlow = await oryApi.updateRecoveryFlow({
      flow: recoveryFlowInit.data.id,
      updateRecoveryFlowBody: { csrf_token: csrfToken, email: params.email, method: 'code' },
    });

    fetchingState(false);

    if (recoveryFlow.status === 200) {
      return { csrfToken: getCSRFToken(recoveryFlow.data), id: recoveryFlow.data.id };
    }

    return false;
  } catch (e) {
    fetchingState(false);
    return processOryApiError({
      axiosErrorResponse: e as AxiosError<{ error: IOryGeneralError }>,
      dispatch,
      formatMessage,
    });
  }
}

export async function confirmOryRecoveryFlow(
  params: {
    code: string;
    csrfToken: string;
    id: string;
  },
  fetchingState: React.Dispatch<React.SetStateAction<boolean>>,
  dispatch: Dispatch,
  formatMessage: IntlFormatters['formatMessage']
): Promise<OryResponse> {
  fetchingState(true);
  try {
    const recoveryFlow = await oryApi.updateRecoveryFlow({
      flow: params.id,
      updateRecoveryFlowBody: { code: params.code, csrf_token: params.csrfToken, method: 'code' },
    });

    fetchingState(false);
    if (hasResponseOKStatus(recoveryFlow)) {
      return handleRecoveryFlowValidations(recoveryFlow.data, formatMessage);
    }

    return false;
  } catch (e) {
    fetchingState(false);
    return processOryApiError({
      axiosErrorResponse: e as AxiosError<{ error: IOryGeneralError }>,
      dispatch,
      formatMessage,
    });
  }
}

export async function resendRecoveryCode(
  params: {
    csrfToken: string;
    id: string;
    email: string;
  },
  fetchingState: React.Dispatch<React.SetStateAction<boolean>>,
  dispatch: Dispatch,
  formatMessage: IntlFormatters['formatMessage']
): Promise<OryResponse> {
  fetchingState(true);
  try {
    const recoveryFlow = await oryApi.updateRecoveryFlow({
      flow: params.id,
      updateRecoveryFlowBody: { csrf_token: params.csrfToken, email: params.email, method: 'code' },
    });
    fetchingState(false);

    if (hasResponseOKStatus(recoveryFlow)) {
      return handleRecoveryFlowValidations(recoveryFlow.data, formatMessage);
    }
    return false;
  } catch (e) {
    fetchingState(false);
    return processOryApiError({
      axiosErrorResponse: e as AxiosError<{ error: IOryGeneralError }>,
      dispatch,
      formatMessage,
    });
  }
}

function handleRecoveryFlowValidations(
  response: RecoveryFlow,
  formatMessage: IntlFormatters['formatMessage']
): boolean | OryErrorMessage {
  const oryErrorMessageId = tryToGetFlowValidationMessageId(response);
  if (oryErrorMessageId) {
    return getOryErrorFromOryErrorMessageId(oryErrorMessageId, formatMessage);
  }
  return OrySuccess;
}
