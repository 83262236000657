import { defineMessages } from 'react-intl';

export default defineMessages({
  editBillingModalAddressLabelCity: {
    defaultMessage: 'City',
    description: 'Edit billing modal - city label',
    id: 'editBillingModal.address.labelCity',
  },
  editBillingModalAddressLabelCountry: {
    defaultMessage: 'Country',
    description: 'Edit billing modal - country label',
    id: 'editBillingModal.address.labelCountry',
  },
  editBillingModalAddressLabelPostCode: {
    defaultMessage: 'Post code',
    description: 'Edit billing modal - post code label',
    id: 'editBillingModal.address.labelPostCode',
  },
  editBillingModalAddressLabelStreet: {
    defaultMessage: 'Street',
    description: 'Edit billing modal - street label',
    id: 'editBillingModal.address.labelStreet',
  },
  editBillingModalAddressTitle: {
    defaultMessage: 'Address',
    description: 'Edit billing modal - address',
    id: 'editBillingModal.address.title',
  },
  editBillingModalCancel: {
    defaultMessage: 'Cancel',
    description: 'Edit billing modal - cancel button',
    id: 'editBillingModal.cancel',
  },
  editBillingModalLabelCompanyFamily: {
    defaultMessage: 'Company name',
    description: 'Edit billing modal - company name label',
    id: 'editBillingModal.label.companyName',
  },
  editBillingModalLabelCurrency: {
    defaultMessage: 'Currency',
    description: 'Edit billing modal - currency label',
    id: 'editBillingModal.label.currency',
  },
  editBillingModalLabelVatNumber: {
    defaultMessage: 'VAT Number',
    description: 'Edit billing modal - vat number label',
    id: 'editBillingModal.label.vatNumber',
  },
  editBillingModallSave: {
    defaultMessage: 'Save changes',
    description: 'Edit billing modal - save button',
    id: 'editBillingModal.save',
  },
  editBillingModalTitle: {
    defaultMessage: 'Edit company billing info',
    description: 'Edit billing modal - edit billing info',
    id: 'editBillingModal.title',
  },
  editBillingModalValidationMaxLength: {
    defaultMessage: 'The name is too long.',
    description: 'Edit billing modal - validation max length',
    id: 'editBillingModal.valdation.maxLength',
  },
  editBillingModalValidationRequired: {
    defaultMessage: 'Value is mandatory.',
    description: 'Edit billing modal - validation required',
    id: 'editBillingModal.valdation.required',
  },
  editBillingModalVatInformation: {
    defaultMessage: 'VAT information',
    description: 'Edit billing modal - vat information',
    id: 'editBillingModal.vatInformation',
  },
});
