import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import CookieSettings from './CookieSettings';

const styles = {
  content: {
    height: '100%',
  },
  fullHeight: {
    borderRadius: 0,
    bottom: 0,
    height: '100%',
    left: 'auto',
    right: 0,
    width: '316px',
  },
  panel: {
    bottom: 2,
    left: 'auto',
    right: 2,
  },
  root: {
    backgroundColor: 'grey.300',
    borderRadius: 1,
    padding: 2,
    position: 'fixed',
    width: 300,
    zIndex: 10000,
  },
};

const CookieConsentComponent = ({
  acceptButtonLabel,
  fullHeightSettings,
  info,
  links,
  maxSettingsHeight,
  onAccept,
  onSettingsChange,
  onSettingsClick,
  onSettingsSave,
  saveSettingsButtonLabel,
  settings,
  settingsButtonLabel,
  settingsOpen,
}) => (
  <Box
    component="div"
    sx={{
      ...styles.root,
      ...((!fullHeightSettings || !settingsOpen) && styles.panel),
      ...(fullHeightSettings && settingsOpen && styles.fullHeight),
    }}
  >
    <Grid container direction="column" justifyContent="space-between" spacing={2} sx={styles.content}>
      <Grid container direction="column" item>
        <Box pb={3}>
          {info && (
            <Grid item>
              <Typography variant="caption">{info}</Typography>
            </Grid>
          )}
          {links.length > 0 &&
            links.map((item) => (
              <Grid item key={item.title}>
                <Box pt={1}>
                  <Link href={item.link} rel="noopener" target="_blank">
                    <Typography variant="caption">{item.title}</Typography>
                  </Link>
                </Box>
              </Grid>
            ))}
        </Box>
        {settingsOpen && (
          <Grid item>
            <Box pb={2}>
              <CookieSettings maxHeight={maxSettingsHeight} onChange={onSettingsChange} settings={settings} />
            </Box>
            {saveSettingsButtonLabel && (
              <Button color="primary" onClick={onSettingsSave} variant="contained">
                {saveSettingsButtonLabel}
              </Button>
            )}
          </Grid>
        )}
      </Grid>
      <Grid container direction="row" item spacing={2}>
        <Grid item>
          <Button color="primary" onClick={onAccept} variant="contained">
            {acceptButtonLabel}
          </Button>
        </Grid>
        {settings.length > 0 && (
          <Grid item>
            <Button color="primary" onClick={onSettingsClick}>
              {settingsButtonLabel}
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  </Box>
);

CookieConsentComponent.propTypes = {
  acceptButtonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  fullHeightSettings: PropTypes.bool,
  info: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    })
  ),
  maxSettingsHeight: PropTypes.string,
  onAccept: PropTypes.func.isRequired,
  onSettingsChange: PropTypes.func,
  onSettingsClick: PropTypes.func,
  onSettingsSave: PropTypes.func,
  saveSettingsButtonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  settings: PropTypes.arrayOf(PropTypes.object),
  settingsButtonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  settingsOpen: PropTypes.bool,
};

CookieConsentComponent.defaultProps = {
  fullHeightSettings: false,
  info: '',
  links: [],
  maxSettingsHeight: 'none',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSettingsChange: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSettingsClick: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSettingsSave: () => {},
  saveSettingsButtonLabel: '',
  settings: [],
  settingsButtonLabel: '',
  settingsOpen: false,
};

export default CookieConsentComponent;
