import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useIntl } from 'react-intl';
import { useGetPinForUserQuery } from '../../apiRtk/accessControlApi';
import { useUrlParams } from '../../helpers/urlParams';
import { useModal } from '../../hooks/useModal';
import { ShowQrModal as ShowQrModalComponent } from '../../pages/apartmentAdmin/ApartmentAdminUserDetailPage/components/UserAccessSettings/components/ShowQrModal';
import messages from './messages';
import Panel from './Panel';

const styles = {
  buttons: {
    '& > button': {
      m: 0,
      ml: 1,
    },
    textAlign: 'right',
    width: {
      lg: '140px',
    },
  },
  errorMessage: {
    color: 'error.main',
    pt: 1,
    textAlign: 'center',
  },
  iconButton: {
    px: 1,
  },
  pin: {
    overflow: 'hidden',
    width: {
      lg: 'calc(100% - 140px)',
    },
  },
  pinNormal: {
    fontWeight: 400,
    m: 0,
  },
  smallPin: {
    fontSize: 24,
    letterSpacing: 0,
  },
};

interface PanelQrProps {
  error?: boolean;
  pin: string;
}

const PanelQr = ({ error, pin }: PanelQrProps) => {
  const { formatMessage } = useIntl();
  const { companyId, siteId, userId } = useUrlParams();
  const { Modal: ShowQrModal, onOpen: openShowQrModal } = useModal({ Modal: ShowQrModalComponent });
  const { data: apartmentPinForUser } = useGetPinForUserQuery(
    {
      companyId: companyId!.toString(),
      siteId: siteId!.toString(),
      userId: userId!.toString(),
    },
    { refetchOnMountOrArgChange: true }
  );

  return (
    <>
      <Panel errorMessage={formatMessage(messages.userAccessFormPinErrorMessage)} isError={error || false}>
        <Box data-pin={pin} sx={styles.pin}>
          <Typography sx={[styles.pinNormal, pin?.length > 6 ? styles.smallPin : {}]} variant="h4">
            {''.padEnd(pin.length, '#')}
          </Typography>
        </Box>
        <Box sx={styles.buttons}>
          <IconButton onClick={openShowQrModal} sx={styles.iconButton}>
            <VisibilityOutlinedIcon />
          </IconButton>
        </Box>
      </Panel>
      <ShowQrModal qrUrl={apartmentPinForUser?.link ? apartmentPinForUser?.link : ''} />
    </>
  );
};

export default PanelQr;
