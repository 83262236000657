import { defineMessages } from 'react-intl';

export default defineMessages({
  AAmodalChangeLabelCancel: {
    defaultMessage: "Cancel",
    id: "AAmodal.changeLabel.cancel"
  },
  AAmodalChangeLabelError: {
    defaultMessage: "There was an error, please try again",
    id: "AAmodal.changeLabel.error"
  },
  AAmodalChangeLabelHelperDifferent: {
    defaultMessage: "Changing the label will make it the same on all intercoms",
    id: "AAmodal.changeLabel.helperDifferent"
  },
  AAmodalChangeLabelLabel: {
    defaultMessage: "Apartment button label ",
    id: "AAmodal.changeLabel.label"
  },
  AAmodalChangeLabelPlaceholderDifferent: {
    defaultMessage: "Different labels on intercoms",
    id: "AAmodal.changeLabel.placeholderDifferent"
  },
  AAmodalChangeLabelSubmit: {
    defaultMessage: "change label",
    id: "AAmodal.changeLabel.submit"
  },
  AAmodalChangeLabelSuccess: {
    defaultMessage: "The button label changed successfully",
    id: "AAmodal.changeLabel.success"
  },
  AAmodalChangeLabelText: {
    defaultMessage: "Here you can change the label associated with the apartment's intercom button." +
      "<p>This function will only take effect on intercoms with displays.</p>",
    id: "AAmodal.changeLabel.text"
  },
  AAmodalChangeLabelTitle: {
    defaultMessage: "Change button label",
    id: "AAmodal.changeLabel.title"
  },
  AAmodalChangeLabelWarningAlertNotAdded: {
    defaultMessage: "Your apartment is not yet added to any intercom. Contact your facility manager.",
    id: "AAmodal.changeLabel.warningAlert.notAdded"
  }
});
