import { defineMessages } from 'react-intl';

export default defineMessages({
  userRoleInfoAPARTMENT_ADMINHeader: {
    defaultMessage: 'Apartment admin',
    description: 'User role info item apartment admin header',
    id: 'userRoleInfoAPARTMENT_ADMINHeader',
  },
  userRoleInfoAuthorization0: {
    defaultMessage: 'Uses devices within this site.',
    description: 'User role authorization 0',
    id: 'userRoleInfoAuthorization0',
  },
  userRoleInfoAuthorization1: {
    defaultMessage: '<strong>Can use</strong> the My2N mobile app.',
    description: 'User role info authorization 1',
    id: 'userRoleInfoAuthorization1',
  },
  userRoleInfoAuthorization2: {
    defaultMessage: '<strong>Can’t use</strong> the My2N mobile app.',
    description: 'User role info authorization 2',
    id: 'userRoleInfoAuthorization2',
  },
  userRoleInfoAuthorization3: {
    defaultMessage: 'Manages settings of this site.',
    description: 'User role info item authorization 3',
    id: 'userRoleInfoAuthorization3',
  },
  userRoleInfoAuthorization4: {
    defaultMessage: '<strong>Can’t see</strong> the prices of services and make payments.',
    description: 'User role info item authorization 4',
    id: 'userRoleInfoAuthorization4',
  },
  userRoleInfoAuthorization5: {
    defaultMessage:
      '<strong>Can see</strong> the prices of services and make payments within the {companyName} company.',
    description: 'User role info item authorization 5',
    id: 'userRoleInfoAuthorization5',
  },
  userRoleInfoAuthorization6: {
    defaultMessage: 'Manages all sites and site admins.',
    description: 'User role info item authorization 6',
    id: 'userRoleInfoAuthorization6',
  },
  userRoleInfoAuthorization7: {
    defaultMessage: 'Makes payments and has access to billing info.',
    description: 'User role info item authorization 7',
    id: 'userRoleInfoAuthorization7',
  },
  userRoleInfoCOMPANY_ADMINHeader: {
    defaultMessage: 'Company admin',
    description: 'User role info item company admin header',
    id: 'userRoleInfoCOMPANY_ADMINHeader',
  },
  userRoleInfoCOMPANY_ADMINSelected: {
    defaultMessage: 'Company admin',
    description: 'User role info item company admin selected',
    id: 'userRoleInfoCOMPANY_ADMINSelected',
  },
  userRoleInfoLOGIN_LESSHeader: {
    defaultMessage: 'site user without login',
    description: 'User role info item site user without login header',
    id: 'userRoleInfoLOGIN_LESSHeader',
  },
  userRoleInfoLOGIN_LESSSelected: {
    defaultMessage: 'Site user without login',
    description: 'User role info item site user without login selected',
    id: 'userRoleInfoLOGIN_LESSSelected',
  },
  userRoleInfoRole: {
    defaultMessage: 'User role {role}:',
    description: 'User role label',
    id: 'userRoleInfoRole',
  },
  userRoleInfoSITE_ADMIN_PAYMENTHeader: {
    defaultMessage: 'site admin',
    description: 'User role info item site admin payments permission header',
    id: 'userRoleInfoSITE_ADMIN_PAYMENTHeader',
  },
  userRoleInfoSITE_ADMIN_PAYMENTSelected: {
    defaultMessage: 'Site admin with payments permission',
    description: 'User role info item site admin payments permission selected',
    id: 'userRoleInfoSITE_ADMIN_PAYMENTSelected',
  },
  userRoleInfoSITE_ADMINHeader: {
    defaultMessage: 'site admin',
    description: 'User role info item site admin header',
    id: 'userRoleInfoSITE_ADMINHeader',
  },
  userRoleInfoSITE_ADMINSelected: {
    defaultMessage: 'Site admin',
    description: 'User role info item site admin selected',
    id: 'userRoleInfoSITE_ADMINSelected',
  },
  userRoleInfoSITE_USERHeader: {
    defaultMessage: 'site user',
    description: 'User role info item site user header',
    id: 'userRoleInfoSITE_USERHeader',
  },
  userRoleInfoSITE_USERSelected: {
    defaultMessage: 'Site user',
    description: 'User role info item site user selected',
    id: 'userRoleInfoSITE_USERSelected',
  },
  userRoleSelectHelperText: {
    defaultMessage: 'Assigning a role to a user determines their rights. You can change the role later.',
    description: 'User role select helper text',
    id: 'userRoleSelectHelperText',
  },
  userRoleSelectItemCompanyAdmin: {
    defaultMessage: 'Company admin',
    description: 'User role select item company admin',
    id: 'userRoleSelectItemCompanyAdmin',
  },
  userRoleSelectItemLoginLessUser: {
    defaultMessage: 'Site user without login',
    description: 'User role select item - login-less user',
    id: 'userRoleSelectItemLoginLessUser',
  },
  userRoleSelectItemSiteAdmin: {
    defaultMessage: 'Site admin',
    description: 'User role select item site admin',
    id: 'userRoleSelectItemSiteAdmin',
  },
  userRoleSelectItemSiteAdminPayment: {
    defaultMessage: 'Site admin with payments permission',
    description: 'User role select item site admin payments permission',
    id: 'userRoleSelectItemSiteAdminPayment',
  },
  userRoleSelectItemSiteUser: {
    defaultMessage: 'Site user',
    description: 'User role select item site user',
    id: 'userRoleSelectItemSiteUser',
  },
  userRoleSelectItemSiteUserWithoutLogin: {
    defaultMessage: 'Site user without login',
    description: 'User role select item site user without login',
    id: 'userRoleSelectItemSiteUserWithoutLogin',
  },
  userRoleSelectLabel: {
    defaultMessage: 'User role',
    description: 'User role select label',
    id: 'userRoleSelectLabel',
  },
  userRoleSelectLoginLessUserHelperText: {
    defaultMessage: 'Assigning a role to a user determines their rights.{br}You can change the role later.',
    description: 'User role select helper text - login less user FF',
    id: 'userRoleSelectLoginLessUserHelperText',
  },
});
