import MailOutlineIcon from '@mui/icons-material/MailOutline';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import React from 'react';
import { IUser, IUserBase } from '../../modules/user/store';
import UserIcon from '../UserIcon';

const styles = {
  icon: {
    '& svg': {
      color: 'secondary.main',
    },
    minWidth: 40,
  },
  item: {
    pb: 0.4,
    pt: 0,
    px: 0,
  },
  root: {
    width: '100%',
  },
  userName: {
    fontWeight: 'bold',
  },
};

export interface IUserData extends IUserBase {
  role: IUser['role'];
}

function SelectedUser({ user }: { user: IUserData }) {
  return (
    <List sx={styles.root}>
      <ListItem sx={styles.item}>
        <ListItemIcon sx={styles.icon}>
          <UserIcon userRole={user?.role} />
        </ListItemIcon>
        <Typography sx={styles.userName} variant="body2">
          {user?.id !== undefined && (user?.firstName ? `${user?.firstName} ${user?.lastName}` : user?.lastName)}
        </Typography>
      </ListItem>
      {user?.email && (
        <ListItem sx={styles.item}>
          <ListItemIcon sx={styles.icon}>
            <MailOutlineIcon />
          </ListItemIcon>
          <Typography variant="body2">{user?.email}</Typography>
        </ListItem>
      )}
    </List>
  );
}

export default SelectedUser;
