import LockIcon from '@mui/icons-material/Lock';
import { Box, Button, InputAdornment, Stack, Typography } from '@mui/material';
import React from 'react';
import { FormProvider, UseFormReturn } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import OutlinedTextField from '../../components/HookFormFields/TextField';
import messages from './messages';
import { ResetMvSdkSettingsDialog } from './ResetMvSdkSettingsDialog';

export interface IMvSdkSettingsFormData {
  authKey: string;
  bundleId: string;
  keyId: string;
  teamId: string;
  databaseName: string;
  serviceJson: string;
}

interface MvSdkSettingsFormProps {
  formMethods: UseFormReturn<IMvSdkSettingsFormData>;
  handleResetSettings: () => void;
  isCustom: boolean;
  isOpenResetDialog: boolean;
  setIsOpenResetDialog: (isOpen: boolean) => void;
  submitForm: (values: IMvSdkSettingsFormData) => void;
}

interface ActionsProps {
  formMethods: UseFormReturn<IMvSdkSettingsFormData>;
  isCustom: boolean;
  setIsOpenResetDialog: (isOpen: boolean) => void;
}

const styles = {
  actions: { pb: 0, pt: 2, px: 0, textAlign: 'right' },
  adornment: { color: 'grey.500' },
};

const hiddenValueProps = {
  endAdornment: (
    <InputAdornment position="start" sx={styles.adornment}>
      <LockIcon />
    </InputAdornment>
  ),
};

export function MvSdkSettingsForm({
  formMethods,
  handleResetSettings,
  isCustom,
  isOpenResetDialog,
  setIsOpenResetDialog,
  submitForm,
}: MvSdkSettingsFormProps) {
  return (
    <>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(submitForm)}>
          <Typography variant="h6" paddingBottom={2}>
            <FormattedMessage {...messages.mvSdkSettingsAppleApn} />
          </Typography>
          <Stack spacing={1}>
            <OutlinedTextField
              label={<FormattedMessage {...messages.mvSdkSettingsBundleId} />}
              name="bundleId"
              required
            />
            <OutlinedTextField label={<FormattedMessage {...messages.mvSdkSettingsKeyId} />} name="keyId" required />
            <OutlinedTextField label={<FormattedMessage {...messages.mvSdkSettingsTeamId} />} name="teamId" required />
            <OutlinedTextField
              helperText={!formMethods.watch('authKey') && <FormattedMessage {...messages.mvSdkSettingsHiddenValue} />}
              InputProps={hiddenValueProps}
              label={<FormattedMessage {...messages.mvSdkSettingsAuthKey} />}
              multiline
              name="authKey"
              required
            />
          </Stack>
          <Typography variant="h6" paddingBottom={2}>
            <FormattedMessage {...messages.mvSdkSettingsGoogleFcm} />
          </Typography>
          <Stack spacing={1}>
            <OutlinedTextField
              label={<FormattedMessage {...messages.mvSdkSettingsDatabaseName} />}
              name="databaseName"
              required
            />
            <OutlinedTextField
              helperText={
                !formMethods.watch('serviceJson') && <FormattedMessage {...messages.mvSdkSettingsHiddenValue} />
              }
              InputProps={hiddenValueProps}
              label={<FormattedMessage {...messages.mvSdkSettingsServiceJson} />}
              multiline
              name="serviceJson"
              required
            />
          </Stack>
          <Actions isCustom={isCustom} formMethods={formMethods} setIsOpenResetDialog={setIsOpenResetDialog} />
          <ResetMvSdkSettingsDialog
            onClose={() => setIsOpenResetDialog(false)}
            onConfirm={handleResetSettings}
            open={isOpenResetDialog}
          />
        </form>
      </FormProvider>
    </>
  );
}

function Actions({ formMethods, isCustom, setIsOpenResetDialog }: ActionsProps) {
  return (
    <Box sx={styles.actions}>
      <Button color="primary" disabled={!isCustom} onClick={() => setIsOpenResetDialog(true)}>
        <FormattedMessage {...messages.mvSdkSettingsResetToDefault} />
      </Button>
      <Button color="primary" disabled={!formMethods.formState.isValid} type="submit" variant="contained">
        <FormattedMessage {...messages.mvSdkSettingsSave} />
      </Button>
    </Box>
  );
}
