import DoneIcon from '@mui/icons-material/Done';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import getFilteredCountries from '../../config/countries';
import currencies from '../../config/currencies';
import actions from '../../modules/billing';
import FormikFields from '../FormikFields';
import LoadingButton from '../LoadingButton';
import Modal from '../Modal';
import VatInfoText from '../VatInfoText';
import messages from './messages';

const BillingInfoModal = ({ billingData, editableCurrency, isLoading, onClose, onSave, open }) => {
  const onSend = (values) => onSave(values);
  const getValidationSchema = () => {
    const required = <FormattedMessage {...messages.editBillingModalValidationRequired} />;
    const maxLength = <FormattedMessage {...messages.editBillingModalValidationMaxLength} />;
    const maxStringLength = 255;
    return Yup.object().shape({
      city: Yup.string().max(maxStringLength, maxLength).required(required),
      country: Yup.string().required(required),
      currency: Yup.string().required(required),
      name: Yup.string().max(maxStringLength, maxLength).required(required),
      postalCode: Yup.string().max(maxStringLength, maxLength).required(required),
      street: Yup.string().max(maxStringLength, maxLength).required(required),
      vatNumber: Yup.string().max(maxStringLength, maxLength),
    });
  };

  return (
    <Formik
      enableReinitialize
      initialValues={billingData}
      onSubmit={onSend}
      render={({ handleSubmit, isValid }) => (
        <Modal
          actions={
            <Box>
              <Button id="close-button" onClick={onClose}>
                <FormattedMessage {...messages.editBillingModalCancel} />
              </Button>
              <LoadingButton
                color="primary"
                disabled={!isValid}
                id="submit-button"
                isLoading={isLoading}
                onClick={handleSubmit}
                startIcon={<DoneIcon />}
                type="submit"
                variant="contained"
              >
                <FormattedMessage {...messages.editBillingModallSave} />
              </LoadingButton>
            </Box>
          }
          maxWidth="xs"
          name="billing-info-modal"
          onClose={onClose}
          open={open}
          title={<FormattedMessage {...messages.editBillingModalTitle} />}
        >
          <Form autoComplete="off" noValidate>
            <Box pt={1}>
              <FormikFields.Input
                id="name-input"
                label={<FormattedMessage {...messages.editBillingModalLabelCompanyFamily} />}
                margin="none"
                name="name"
                required
                type="text"
                variant="outlined"
              />
              <FormikFields.Select
                disabled={!editableCurrency}
                fullWidth
                id="currency-select"
                label={<FormattedMessage {...messages.editBillingModalLabelCurrency} />}
                labelKey="name"
                margin="none"
                name="currency"
                options={currencies}
                required
                select
                valueKey="value"
                variant="outlined"
              />
              <Typography sx={{ mb: 2 }} variant="subtitle1">
                <FormattedMessage {...messages.editBillingModalAddressTitle} />
              </Typography>
              <FormikFields.Input
                id="street-input"
                label={<FormattedMessage {...messages.editBillingModalAddressLabelStreet} />}
                margin="none"
                name="street"
                required
                type="text"
                variant="outlined"
              />
              <FormikFields.Input
                id="city-input"
                label={<FormattedMessage {...messages.editBillingModalAddressLabelCity} />}
                margin="none"
                name="city"
                required
                type="text"
                variant="outlined"
              />
              <FormikFields.Input
                id="postalcode-input"
                label={<FormattedMessage {...messages.editBillingModalAddressLabelPostCode} />}
                margin="none"
                name="postalCode"
                required
                type="text"
                variant="outlined"
              />
              <FormikFields.Select
                fullWidth
                id="country-select"
                label={<FormattedMessage {...messages.editBillingModalAddressLabelCountry} />}
                labelKey="name"
                margin="none"
                name="country"
                options={getFilteredCountries}
                required
                select
                valueKey="key"
                variant="outlined"
              />
              <Typography sx={{ mb: 2 }} variant="subtitle1">
                <FormattedMessage {...messages.editBillingModalVatInformation} />
              </Typography>
              <FormikFields.Input
                disabled={!editableCurrency}
                id="vat-input"
                label={<FormattedMessage {...messages.editBillingModalLabelVatNumber} />}
                margin="none"
                name="vatNumber"
                type="text"
                variant="outlined"
              />
              <VatInfoText />
            </Box>
          </Form>
        </Modal>
      )}
      validationSchema={getValidationSchema()}
    />
  );
};

BillingInfoModal.propTypes = {
  billingData: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string,
    currency: PropTypes.string,
    name: PropTypes.string,
    postalCode: PropTypes.string,
    street: PropTypes.string,
    vatNumber: PropTypes.string,
  }),
  editableCurrency: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  open: PropTypes.bool,
  verify: PropTypes.bool,
};

BillingInfoModal.defaultProps = {
  billingData: {
    city: '',
    country: '',
    currency: '',
    name: '',
    postalCode: '',
    street: '',
    vatNumber: '',
  },
  editableCurrency: true,
  isLoading: false,
  open: false,
  verify: false,
};

const mapStateToProps = ({ billing }) => ({
  billingData: billing.editBillingModal.values,
  isLoading: billing.editBillingModal.isFetching,
  open: billing.editBillingModal.show,
});

const mapDispatchToProps = {
  onClose: actions.editBillingHideModal,
  onSave: actions.editBillingFormRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingInfoModal);
