import { defineMessages } from 'react-intl';

export default defineMessages({
  accessGroupApartmentsDataGridAddApartment: {
    defaultMessage: 'Add apartment to group',
    description: 'Access group apartment data grid - add apartment button label',
    id: 'accessGroupApartmentsDataGrid.addApartment',
  },
  accessGroupApartmentsDataGridColumnsFloor: {
    defaultMessage: 'Floor',
    description: 'Access group apartment data grid - column floor',
    id: 'accessGroupApartmentsDataGrid.columns.floor',
  },
  accessGroupApartmentsDataGridColumnsGroups: {
    defaultMessage: 'Access groups',
    description: 'Access group apartment data grid - column groups',
    id: 'accessGroupApartmentsDataGrid.columns.groups',
  },
  accessGroupApartmentsDataGridColumnsName: {
    defaultMessage: 'Apartment number and name',
    description: 'Access group apartment data grid - column name',
    id: 'accessGroupApartmentsDataGrid.columns.name',
  },
  accessGroupApartmentsDataGridColumnsUsers: {
    defaultMessage: 'Users',
    description: 'Access group apartment data grid - column users',
    id: 'accessGroupApartmentsDataGrid.columns.users',
  },
  accessGroupApartmentsDataGridNoRowsOverlayHeaderText: {
    defaultMessage: 'No apartments in Access groups yet',
    description: 'Access group apartments no rows - headerText',
    id: 'accessGroupApartmentsDataGrid.noRowsOverlay.headerText',
  },
  accessGroupApartmentsDataGridNoRowsOverlayText: {
    defaultMessage: 'Add an apartment to enable all apartment users access via apartment in this Access group.',
    description: 'Access group apartments no rows - text',
    id: 'accessGroupApartmentsDataGrid.noRowsOverlay.text',
  },
});
