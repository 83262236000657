import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../config/consts';
import actions from '../../modules/auth';
import { setOryLoginStatus } from '../../modules/auth/actions';
import { getCurrentUser } from '../../modules/user/actions';
import { useOryRecoveryApi } from '../../ory/hooks/oryApi';
import { OryResponseParserResult, useOryResponseParser } from '../../ory/hooks/oryResponseParser';
import { IOryRecoveryFlow, OryLoginStatus, OryResponseWithData } from '../../ory/types';
import { getResetPasswordPageTitle } from './title';
import { IResetPasswordFormData, ResetPasswordFormStep } from './types';

export interface IUseResetPasswordPage {
  recoveryWholeProcessRestart: () => void;
  recoveryFlowRestart: (newFlowId?: string) => Promise<boolean>;
  emailFormSubmit: (values: IResetPasswordFormData) => void;
  recoveryCodeFormSuccess: () => void;
  newPasswordFormSuccess: () => void;
  resetPasswordFormStep: ResetPasswordFormStep;
  email: string;
  isOryApiFetching: boolean;
  oryRecoveryFlowData: IOryRecoveryFlow | undefined;
  formTitle: string;
}

export function useResetPasswordPage(): IUseResetPasswordPage {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [resetPasswordFormStep, setResetPasswordFormStep] = useState(ResetPasswordFormStep.EmailForm);
  const [oryRecoveryFlowData, setOryRecoveryFlowData] = useState<IOryRecoveryFlow | undefined>(undefined);
  const { parseOryResponseWithData } = useOryResponseParser();
  const { createOrGetOryRecoveryFlow, isOryApiFetching } = useOryRecoveryApi();
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const recoveryFlowRestart = async (newFlowId?: string): Promise<boolean> => {
    const response = await createOrGetOryRecoveryFlow({ email, existingFlowId: newFlowId });
    return processCreateOrGetOryRecoverFlowResponse(response);
  };

  const recoveryWholeProcessRestart = () => {
    setOryRecoveryFlowData(undefined);
    setResetPasswordFormStep(ResetPasswordFormStep.EmailForm);
    setEmail(email);
  };

  const processCreateOrGetOryRecoverFlowResponse = (
    response: OryResponseWithData<IOryRecoveryFlow>
  ): OryResponseParserResult => {
    return parseOryResponseWithData({
      onSuccess: (data) => {
        setOryRecoveryFlowData(data);
        setResetPasswordFormStep(ResetPasswordFormStep.ORYRecoveryCodeForm);
      },
      response,
    });
  };

  const recoveryCodeFormSuccess = () => {
    dispatch(setOryLoginStatus(OryLoginStatus.SessionConfirmed));
    dispatch(getCurrentUser());
    setResetPasswordFormStep(ResetPasswordFormStep.ORYNewPasswordForm);
  };

  const newPasswordFormSuccess = () => {
    navigate(PATHS.LOGIN);
  };

  const emailFormSubmit = (values: IResetPasswordFormData) => {
    const onUserIsInOry = async () => {
      const response = await createOrGetOryRecoveryFlow({ email: values.email });
      processCreateOrGetOryRecoverFlowResponse(response);
    };
    setEmail(values.email);
    dispatch(actions.resetPasswordRequest({ email: values.email, onUserIsInOry }));
  };

  return {
    email,
    emailFormSubmit,
    formTitle: getResetPasswordPageTitle(resetPasswordFormStep, formatMessage),
    isOryApiFetching,
    newPasswordFormSuccess,
    oryRecoveryFlowData,
    recoveryCodeFormSuccess,
    recoveryFlowRestart,
    recoveryWholeProcessRestart,
    resetPasswordFormStep,
  };
}
