import { defineMessages } from 'react-intl';

export default defineMessages({
  accessGroupAutocompleteSelectHelperText: {
    defaultMessage: 'You can set one or more access groups.',
    description: 'Add group autocomplete - helper text',
    id: 'accessGroupAutocomplete.select.helperText',
  },
  accessGroupAutocompleteSelectLabel: {
    defaultMessage: 'Access groups',
    description: 'Add group autocomplete - label',
    id: 'accessGroupAutocomplete.select.label',
  },
  accessGroupAutocompleteSelectNewItemLabel: {
    defaultMessage: 'Create new group',
    description: 'Add group autocomplete - new item label',
    id: 'accessGroupAutocomplete.select.newItemLabel',
  },
  accessGroupAutocompleteSelectPlaceholder: {
    defaultMessage: 'Select, or create a new one',
    description: 'Add group autocomplete - placeholder',
    id: 'accessGroupAutocomplete.select.placeholder',
  },
  groupFormFieldNameHelperText: {
    defaultMessage: 'Use a simple name, e. g. Garage',
    description: 'Group form field - name helper text',
    id: 'groupForm.field.name.helperText',
  },
  groupFormFieldNameLabel: {
    defaultMessage: 'Group name',
    description: 'Group form field - name label',
    id: 'groupForm.field.name.label',
  },
  useAccessGroupFormValidationSchemaValidationGroup: {
    defaultMessage: 'Value is mandatory.',
    description: 'Group data form - group is exist',
    id: 'useAccessGroupFormValidationSchema.validation.group',
  },
  useAccessGroupFormValidationSchemaValidationRequiredName: {
    defaultMessage: 'Enter the group name',
    description: 'Group data form - name is required',
    id: 'useAccessGroupFormValidationSchema.validation.requiredName',
  },
});
