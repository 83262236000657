import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ApartmentUserRole } from '../../modules/apartmentAdmin/data';
import { IUser } from '../../modules/user/store';
import { EmptyValue } from '../EmptyValue';
import messages from './messages';

export interface IApartmentUserRoleNameProps {
  apartmentUserRole: ApartmentUserRole;
  credentials: IUser['credentials'];
}

export function ApartmentUserRoleName({ apartmentUserRole, credentials }: IApartmentUserRoleNameProps) {
  if (apartmentUserRole === ApartmentUserRole.APARTMENT_ADMIN_INHERITED) {
    return <FormattedMessage {...messages.userRoleNameAdmin} />;
  }
  if (apartmentUserRole === ApartmentUserRole.APARTMENT_ADMIN) {
    return <FormattedMessage {...messages.userRoleNameApartmentAdmin} />;
  }
  if (apartmentUserRole === ApartmentUserRole.APARTMENT_GUEST) {
    return <FormattedMessage {...messages.userRoleNameGuest} />;
  }
  if (apartmentUserRole === ApartmentUserRole.APARTMENT_LOGIN_LESS) {
    if (credentials && credentials.length === 1) {
      if (credentials[0] === 'PIN') {
        return <FormattedMessage {...messages.userRoleNameApartmentLoginLessPIN} />;
      }
      if (credentials[0] === 'RFID') {
        return <FormattedMessage {...messages.userRoleNameApartmentLoginLessRFID} />;
      }
    }
    return <EmptyValue />;
  }

  return <FormattedMessage {...messages.userRoleNameApartmentUser} />;
}
