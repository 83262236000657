import { SITE_TYPE } from '../../../config/sites';
import { isStandardMVType } from '../../../helpers/mobileVideo';

export function isFreeLicenceAlert(
  freeLicenceCount: number,
  type?: string,
  status?: string,
  siteType?: string,
  freeMVFFEnable?: boolean
) {
  return siteType !== SITE_TYPE.OTHER && freeMVFFEnable && freeLicenceCount > 0 && isStandardMVType(type, status);
}
