import { all, call, put, select, take, takeLatest } from 'redux-saga/effects';
import * as Api from '../../api/accessSettingsApi';
import { getAccessGroupUsersNew } from '../../api/accessSettingsApiTS';
import { getSiteApartments } from '../../api/apartmentApi';
import { getSiteUsersList } from '../../api/userApiTS';
import { selectors as apartmentsSelectors } from '../apartments';
import { addApartment } from '../apartments/actions';
import apartmentTypes from '../apartments/types';
import { selectors as companySelectors } from '../company';
import { errorHelper, responseValidateStatusOrThrow } from '../helpers';
import { addSnackbarSuccess } from '../notifications/actions';
import { selectors as sitesSelectors } from '../sites';
import { createSiteUser } from '../user/saga';
import { selectors, types } from './index';
import messages from './messages';

export function* createAccessGroup({ params }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(sitesSelectors.getSiteId);
    const response = yield call(Api.createAccessGroup, companyId, siteId, params);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({ payload: data, type: types.CREATE_ACCESS_GROUP_SUCCESS });
    yield put(addSnackbarSuccess(messages.accessSnackbarGroupAddedSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.CREATE_ACCESS_GROUP_FAILURE, error));
  }
}

export function* deleteAccessGroup({ groupId }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(sitesSelectors.getSiteId);
    const response = yield call(Api.deleteAccessGroup, companyId, siteId, groupId);
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.DELETE_ACCESS_GROUP_SUCCESS });
    yield put(addSnackbarSuccess(messages.accessSnackbarGroupDeletedSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.DELETE_ACCESS_GROUP_FAILURE, error));
  }
}

export function* getAccessGroup({ companyId, groupId, siteId }) {
  try {
    const response = yield call(Api.getAccessGroup, companyId, siteId, groupId);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({
      payload: data,
      type: types.GET_ACCESS_GROUP_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_ACCESS_GROUP_FAILURE, error));
  }
}

export function* updateAccessGroup({ companyId, groupId, params, siteId }) {
  try {
    const response = yield call(Api.updateAccessGroup, companyId, siteId, groupId, params);
    yield responseValidateStatusOrThrow(response);
    yield put({
      type: types.UPDATE_ACCESS_GROUP_SUCCESS,
    });
    yield put(addSnackbarSuccess(messages.accessSnackbarGroupUpdatedSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.UPDATE_ACCESS_GROUP_FAILURE, error));
  }
}

export function* addAccessGroupApartments({ apartments, groupId, newApartment }) {
  const companyId = yield select(companySelectors.getCompanyId);
  const siteId = yield select(sitesSelectors.getSiteId);
  const floors = yield select(apartmentsSelectors.getFloors);
  try {
    let addFloorResponse;
    if (newApartment) {
      yield put(addApartment(companyId, siteId, floors, newApartment, false));
      addFloorResponse = yield take([apartmentTypes.ADD_APARTMENT_SUCCESS]);
    }

    const response = yield call(
      Api.addAccessGroupApartments,
      companyId,
      siteId,
      groupId,
      addFloorResponse?.payload?.id ? [...apartments, addFloorResponse?.payload?.id] : [...apartments]
    );
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.ADD_ACCESS_GROUP_APARTMENTS_SUCCESS });
    yield put(addSnackbarSuccess(messages.accessSnackbarApartmentsAddedSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.ADD_ACCESS_GROUP_APARTMENTS_FAILURE, error));
  }
}

export function* removeAccessGroupApartment({ apartmentId }) {
  const companyId = yield select(companySelectors.getCompanyId);
  const siteId = yield select(sitesSelectors.getSiteId);
  const groupId = yield select(selectors.getAccessGroupId);
  try {
    const response = yield call(Api.removeAccessGroupApartment, companyId, siteId, groupId, apartmentId);
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.REMOVE_ACCESS_GROUP_APARTMENT_SUCCESS });
    yield put(addSnackbarSuccess(messages.accessSnackbarApartmentRemovedSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.REMOVE_ACCESS_GROUP_APARTMENT_FAILURE, error));
  }
}

export function* getAccessGroupApartmentsAll({ groupId, params }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(sitesSelectors.getSiteId);
    const response = yield call(Api.getAccessGroupApartments, companyId, siteId, groupId, params);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({
      payload: data,
      type: types.GET_ACCESS_GROUP_APARTMENTS_ALL_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_ACCESS_GROUP_APARTMENTS_ALL_FAILURE, error));
  }
}

export function* getAccessGroupApartments({ companyId, groupId, order, page, params, rowsPerPage, siteId }) {
  try {
    const response = yield call(Api.getAccessGroupApartments, companyId, siteId, groupId, params);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({
      order,
      page,
      payload: data,
      rowsPerPage,
      type: types.GET_ACCESS_GROUP_APARTMENTS_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_ACCESS_GROUP_APARTMENTS_FAILURE, error));
  }
}

export function* getAccessGroupDevices({ companyId, groupId, order, page, params, rowsPerPage, siteId }) {
  try {
    const response = yield call(Api.getAccessGroupDevices, companyId, siteId, groupId, params);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({
      order,
      page,
      payload: data,
      rowsPerPage,
      type: types.GET_ACCESS_GROUP_DEVICES_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_ACCESS_GROUP_DEVICES_FAILURE, error));
  }
}

export function* getAccessGroupDevicesAll({ groupId, params }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(sitesSelectors.getSiteId);
    const response = yield call(Api.getAccessGroupDevices, companyId, siteId, groupId, params);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({
      payload: data,
      type: types.GET_ACCESS_GROUP_DEVICES_ALL_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_ACCESS_GROUP_DEVICES_ALL_FAILURE, error));
  }
}

export function* addAccessGroupDevice({ deviceId, groupId }) {
  const companyId = yield select(companySelectors.getCompanyId);
  const siteId = yield select(sitesSelectors.getSiteId);
  try {
    const response = yield call(Api.addAccessGroupDevice, companyId, siteId, groupId, deviceId);
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.ADD_ACCESS_GROUP_DEVICE_SUCCESS });
    yield put(addSnackbarSuccess(messages.accessSnackbarDeviceAddedSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.ADD_ACCESS_GROUP_DEVICE_FAILURE, error));
  }
}

export function* removeAccessGroupDevice({ deviceId }) {
  const companyId = yield select(companySelectors.getCompanyId);
  const siteId = yield select(sitesSelectors.getSiteId);
  const groupId = yield select(selectors.getAccessGroupId);
  try {
    const response = yield call(Api.removeAccessGroupDevice, companyId, siteId, groupId, deviceId);
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.REMOVE_ACCESS_GROUP_DEVICE_SUCCESS });
    yield put(addSnackbarSuccess(messages.accessSnackbarDeviceRemovedSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.REMOVE_ACCESS_GROUP_DEVICE_FAILURE, error));
  }
}

export function* activateRacAccessGroupDevice({ deviceId, params }) {
  const companyId = yield select(companySelectors.getCompanyId);
  const siteId = yield select(sitesSelectors.getSiteId);
  try {
    const response = yield call(Api.setAccessControlSettings, companyId, siteId, deviceId, params);
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.ACTIVATE_RAC_ACCESS_GROUP_DEVICE_SUCCESS });
    yield put(addSnackbarSuccess(messages.accessSnackbarDeviceAccessControlActivatedSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.ACTIVATE_RAC_ACCESS_GROUP_DEVICE_FAILURE, error));
  }
}

export function* deactivateRacAccessGroupDevice({ deviceId, params }) {
  const companyId = yield select(companySelectors.getCompanyId);
  const siteId = yield select(sitesSelectors.getSiteId);
  try {
    const response = yield call(Api.setAccessControlSettings, companyId, siteId, deviceId, params);
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.DEACTIVATE_RAC_ACCESS_GROUP_DEVICE_SUCCESS });
    yield put(addSnackbarSuccess(messages.accessSnackbarDeviceAccessControlDeactivatedSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.DEACTIVATE_RAC_ACCESS_GROUP_DEVICE_FAILURE, error));
  }
}

export function* setLogAccessGroupDevice({ deviceId, params }) {
  const companyId = yield select(companySelectors.getCompanyId);
  const siteId = yield select(sitesSelectors.getSiteId);
  try {
    const response = yield call(Api.setAccessControlLogSettings, companyId, siteId, deviceId, params);
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.SET_LOG_ACCESS_GROUP_DEVICE_SUCCESS });
    yield put(
      addSnackbarSuccess(
        params.active
          ? messages.accessSnackbarDeviceLoggingActivatedSuccessfully
          : messages.accessSnackbarDeviceLoggingDeactivatedSuccessfully
      )
    );
  } catch (error) {
    yield put(errorHelper(types.SET_LOG_ACCESS_GROUP_DEVICE_FAILURE, error));
  }
}

export function* getAccessGroupUsers({ companyId, groupId, order, page, params, rowsPerPage, siteId }) {
  try {
    const response = yield call(getAccessGroupUsersNew, { companyId, siteId }, groupId, params);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({
      order,
      page,
      payload: { data: data.groupUsers, ...data },
      rowsPerPage,
      type: types.GET_ACCESS_GROUP_USERS_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_ACCESS_GROUP_USERS_FAILURE, error));
  }
}

export function* getAccessGroupUsersAll({ groupId, params }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(sitesSelectors.getSiteId);
    const response = yield call(getAccessGroupUsersNew, { companyId, siteId }, groupId, params);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({
      payload: data,
      type: types.GET_ACCESS_GROUP_USERS_ALL_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_ACCESS_GROUP_USERS_ALL_FAILURE, error));
  }
}

export function* addAccessGroupUser({ groupId, userId }) {
  const companyId = yield select(companySelectors.getCompanyId);
  const siteId = yield select(sitesSelectors.getSiteId);
  try {
    const response = yield call(Api.addAccessGroupUser, companyId, siteId, groupId, userId);
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.ADD_ACCESS_GROUP_USER_SUCCESS });
    yield put(addSnackbarSuccess(messages.accessSnackbarUserCreatedSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.ADD_ACCESS_GROUP_USER_FAILURE, error));
  }
}

export function* addAccessGroupUsers({ groupId, usersUUId }) {
  const companyId = yield select(companySelectors.getCompanyId);
  const siteId = yield select(sitesSelectors.getSiteId);
  try {
    const response = yield call(Api.addAccessGroupUsers, companyId, siteId, groupId, usersUUId);
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.ADD_ACCESS_GROUP_USER_SUCCESS });
    yield put(addSnackbarSuccess(messages.accessSnackbarUserAddedSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.ADD_ACCESS_GROUP_USER_FAILURE, error));
  }
}

export function* addAccessGroupUserWithCreate({ groupId, user }) {
  try {
    const response = yield call(createSiteUser, user);
    const { data } = response;
    yield responseValidateStatusOrThrow(response);
    yield call(addAccessGroupUser, { groupId, userId: data.id });
    yield put({ type: types.CREATE_ADD_ACCESS_GROUP_USER_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.CREATE_ADD_ACCESS_GROUP_USER_FAILURE, error));
  }
}

export function* removeAccessGroupUser({ userId }) {
  const companyId = yield select(companySelectors.getCompanyId);
  const siteId = yield select(sitesSelectors.getSiteId);
  const groupId = yield select(selectors.getAccessGroupId);
  try {
    const response = yield call(Api.removeAccessGroupUser, companyId, siteId, groupId, userId);
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.REMOVE_ACCESS_GROUP_USER_SUCCESS });
    yield put(addSnackbarSuccess(messages.accessSnackbarUserRemovedSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.REMOVE_ACCESS_GROUP_USER_FAILURE, error));
  }
}

export function* getAccessGroupsList({ companyId, order, page, params, rowsPerPage, siteId }) {
  try {
    const response = yield call(Api.getAccessGroupsList, companyId, siteId, params);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({
      order,
      page,
      payload: data,
      rowsPerPage,
      type: types.GET_ACCESS_GROUPS_LIST_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_ACCESS_GROUPS_LIST_FAILURE, error));
  }
}

export function* getAccessGroupsListAll() {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(sitesSelectors.getSiteId);
    const response = yield call(Api.getAccessGroupsList, companyId, siteId, {});
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({
      payload: data,
      type: types.GET_ACCESS_GROUPS_LIST_ALL_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_ACCESS_GROUPS_LIST_ALL_FAILURE, error));
  }
}

export function* getUserAccessGroupsList({ companyId, siteId, userId }) {
  try {
    const response = yield call(Api.getUserAccessGroupsList, companyId, siteId, userId);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({
      payload: data,
      type: types.GET_USER_ACCESS_GROUPS_LIST_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_USER_ACCESS_GROUPS_LIST_FAILURE, error));
  }
}

export function* getUserApartmentsAccessGroupsList({ companyId, siteId, userId }) {
  try {
    const response = yield call(Api.getUserApartmentsAccessGroupsList, companyId, siteId, userId);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({
      payload: data,
      type: types.GET_USER_APARTMENTS_ACCESS_GROUPS_LIST_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_USER_APARTMENTS_ACCESS_GROUPS_LIST_FAILURE, error));
  }
}

export function* addUserAccessGroups({ groups, userId }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(sitesSelectors.getSiteId);
    const response = yield call(Api.addUserAccessGroups, companyId, siteId, userId, groups);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({
      payload: data,
      type: types.ADD_USER_ACCESS_GROUPS_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.ADD_USER_ACCESS_GROUPS_FAILURE, error));
  }
}

export function* addUserAccessGroupWithCreate({ groups, newGroupName, userId }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(sitesSelectors.getSiteId);
    if (newGroupName) {
      const response = yield call(Api.createAccessGroup, companyId, siteId, {
        description: '',
        name: newGroupName,
      });
      const { data } = response;
      yield call(addUserAccessGroups, { groups: [data.id, ...groups], userId });
      yield responseValidateStatusOrThrow(response);
    } else {
      yield call(addUserAccessGroups, { groups: [...groups], userId });
    }
    yield put({ type: types.CREATE_ADD_USER_ACCESS_GROUPS_SUCCESS });
    yield put(addSnackbarSuccess(messages.accessSnackbarAccessGroupsSetSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.CREATE_ADD_USER_ACCESS_GROUPS_FAILURE, error));
  }
}

export function* getApartmentAccessGroupsList({ apartmentId }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(sitesSelectors.getSiteId);
    const response = yield call(Api.getApartmentAccessGroupsList, companyId, siteId, apartmentId);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({
      payload: data,
      type: types.GET_APARTMENT_ACCESS_GROUPS_LIST_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_APARTMENT_ACCESS_GROUPS_LIST_FAILURE, error));
  }
}

export function* addApartmentAccessGroups({ apartmentId, groups }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(sitesSelectors.getSiteId);
    const response = yield call(Api.addApartmentAccessGroups, companyId, siteId, apartmentId, groups);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({
      payload: data,
      type: types.ADD_APARTMENT_ACCESS_GROUPS_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.ADD_APARTMENT_ACCESS_GROUPS_FAILURE, error));
  }
}

export function* addApartmentAccessGroupWithCreate({ apartmentId, groups, newGroupName }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(sitesSelectors.getSiteId);
    if (newGroupName) {
      const response = yield call(Api.createAccessGroup, companyId, siteId, {
        description: '',
        name: newGroupName,
      });
      const { data } = response;
      yield call(addApartmentAccessGroups, { apartmentId, groups: [data.id, ...groups] });
      yield responseValidateStatusOrThrow(response);
    } else {
      yield call(addApartmentAccessGroups, { apartmentId, groups: [...groups] });
    }
    yield put({ type: types.CREATE_ADD_APARTMENT_ACCESS_GROUPS_SUCCESS });
    yield put(addSnackbarSuccess(messages.accessSnackbarAccessGroupsSetSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.CREATE_ADD_APARTMENT_ACCESS_GROUPS_FAILURE, error));
  }
}

export function* getDeviceAccessGroupsList({ deviceId }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(sitesSelectors.getSiteId);
    const response = yield call(Api.getDeviceAccessGroupsList, companyId, siteId, deviceId);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({
      payload: data,
      type: types.GET_DEVICE_ACCESS_GROUPS_LIST_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_DEVICE_ACCESS_GROUPS_LIST_FAILURE, error));
  }
}

export function* addDeviceAccessGroups({ deviceId, groups }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(sitesSelectors.getSiteId);
    const response = yield call(Api.addDeviceAccessGroups, companyId, siteId, deviceId, groups);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({
      payload: data,
      type: types.ADD_DEVICE_ACCESS_GROUPS_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.ADD_DEVICE_ACCESS_GROUPS_FAILURE, error));
  }
}

export function* addDeviceAccessGroupWithCreate({ deviceId, groups, newGroupName }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(sitesSelectors.getSiteId);
    if (newGroupName) {
      const response = yield call(Api.createAccessGroup, companyId, siteId, {
        description: '',
        name: newGroupName,
      });
      const { data } = response;
      yield call(addDeviceAccessGroups, { deviceId, groups: [data.id, ...groups] });
      yield responseValidateStatusOrThrow(response);
    } else {
      yield call(addDeviceAccessGroups, { deviceId, groups: [...groups] });
    }
    yield put({ type: types.CREATE_ADD_DEVICE_ACCESS_GROUPS_SUCCESS });
    yield put(addSnackbarSuccess(messages.accessSnackbarAccessGroupsSetSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.CREATE_ADD_DEVICE_ACCESS_GROUPS_FAILURE, error));
  }
}

export function* getAccessControlDevices({ groupId }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(sitesSelectors.getSiteId);
    const response = yield call(Api.getAccessControlDevices, companyId, siteId);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield call(getAccessGroupDevicesAll, { groupId, params: { limit: 1000, offset: 0 } });
    yield put({
      payload: data,
      type: types.GET_ACCESS_CONTROL_DEVICES_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_ACCESS_CONTROL_DEVICES_FAILURE, error));
  }
}

export function* getAccessControlSettings({ deviceId }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(sitesSelectors.getSiteId);
    const response = yield call(Api.getAccessControlSettings, companyId, siteId, deviceId);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({
      payload: data,
      type: types.GET_ACCESS_CONTROL_SETTINGS_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_ACCESS_CONTROL_SETTINGS_FAILURE, error));
  }
}

export function* setAccessControlSettings({ deviceId, params }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(sitesSelectors.getSiteId);
    const response = yield call(Api.setAccessControlSettings, companyId, siteId, deviceId, params);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({
      payload: data,
      type: types.SET_ACCESS_CONTROL_SETTINGS_SUCCESS,
    });
    yield put(addSnackbarSuccess(messages.accessSnackbarAccessSettingsSetSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.SET_ACCESS_CONTROL_SETTINGS_FAILURE, error));
  }
}

export function* setAccessControlLogSettings({ deviceId, params }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(sitesSelectors.getSiteId);
    const response = yield call(Api.setAccessControlLogSettings, companyId, siteId, deviceId, params);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({
      payload: data,
      type: types.SET_ACCESS_CONTROL_LOG_SETTINGS_SUCCESS,
    });
    yield put(addSnackbarSuccess(messages.accessSnackbarAccessSettingsSetSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.SET_ACCESS_CONTROL_LOG_SETTINGS_FAILURE, error));
  }
}

export function* getAccessControlUsers({ groupId }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(sitesSelectors.getSiteId);
    const response = yield call(
      getSiteUsersList,
      { companyId, siteId },
      {
        limit: 1000,
      }
    );
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield call(getAccessGroupUsersAll, { groupId, params: { limit: 1000, offset: 0 } });
    yield put({
      payload: data.users,
      type: types.GET_ACCESS_CONTROL_USERS_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_ACCESS_CONTROL_USERS_FAILURE, error));
  }
}

export function* getAccessControlApartments({ groupId }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(sitesSelectors.getSiteId);
    const response = yield call(getSiteApartments, { company: companyId, params: { limit: 1000 }, site: siteId });
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield call(getAccessGroupApartmentsAll, { groupId, params: { limit: 1000, offset: 0 } });
    yield put({
      payload: data,
      type: types.GET_ACCESS_CONTROL_APARTMENTS_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_ACCESS_CONTROL_APARTMENTS_FAILURE, error));
  }
}

export default function* accessSettingsSagas() {
  yield all([
    takeLatest(types.ACTIVATE_RAC_ACCESS_GROUP_DEVICE_REQUEST, activateRacAccessGroupDevice),
    takeLatest(types.ADD_ACCESS_GROUP_APARTMENTS_REQUEST, addAccessGroupApartments),
    takeLatest(types.ADD_ACCESS_GROUP_DEVICE_REQUEST, addAccessGroupDevice),
    takeLatest(types.ADD_ACCESS_GROUP_USER_REQUEST, addAccessGroupUser),
    takeLatest(types.ADD_ACCESS_GROUP_USERS_REQUEST, addAccessGroupUsers),
    takeLatest(types.ADD_APARTMENT_ACCESS_GROUPS_REQUEST, addApartmentAccessGroups),
    takeLatest(types.ADD_DEVICE_ACCESS_GROUPS_REQUEST, addDeviceAccessGroups),
    takeLatest(types.ADD_USER_ACCESS_GROUPS_REQUEST, addUserAccessGroups),
    takeLatest(types.CREATE_ACCESS_GROUP_REQUEST, createAccessGroup),
    takeLatest(types.CREATE_ADD_ACCESS_GROUP_USER_REQUEST, addAccessGroupUserWithCreate),
    takeLatest(types.CREATE_ADD_APARTMENT_ACCESS_GROUPS_REQUEST, addApartmentAccessGroupWithCreate),
    takeLatest(types.CREATE_ADD_DEVICE_ACCESS_GROUPS_REQUEST, addDeviceAccessGroupWithCreate),
    takeLatest(types.CREATE_ADD_USER_ACCESS_GROUPS_REQUEST, addUserAccessGroupWithCreate),
    takeLatest(types.DEACTIVATE_RAC_ACCESS_GROUP_DEVICE_REQUEST, deactivateRacAccessGroupDevice),
    takeLatest(types.DELETE_ACCESS_GROUP_REQUEST, deleteAccessGroup),
    takeLatest(types.GET_ACCESS_CONTROL_APARTMENTS_REQUEST, getAccessControlApartments),
    takeLatest(types.GET_ACCESS_CONTROL_DEVICES_REQUEST, getAccessControlDevices),
    takeLatest(types.GET_ACCESS_CONTROL_SETTINGS_REQUEST, getAccessControlSettings),
    takeLatest(types.GET_ACCESS_CONTROL_USERS_REQUEST, getAccessControlUsers),
    takeLatest(types.GET_ACCESS_GROUP_APARTMENTS_ALL_REQUEST, getAccessGroupApartmentsAll),
    takeLatest(types.GET_ACCESS_GROUP_APARTMENTS_REQUEST, getAccessGroupApartments),
    takeLatest(types.GET_ACCESS_GROUP_DEVICES_ALL_REQUEST, getAccessGroupDevicesAll),
    takeLatest(types.GET_ACCESS_GROUP_DEVICES_REQUEST, getAccessGroupDevices),
    takeLatest(types.GET_ACCESS_GROUP_REQUEST, getAccessGroup),
    takeLatest(types.GET_ACCESS_GROUP_USERS_ALL_REQUEST, getAccessGroupUsersAll),
    takeLatest(types.GET_ACCESS_GROUP_USERS_REQUEST, getAccessGroupUsers),
    takeLatest(types.GET_ACCESS_GROUPS_LIST_ALL_REQUEST, getAccessGroupsListAll),
    takeLatest(types.GET_ACCESS_GROUPS_LIST_REQUEST, getAccessGroupsList),
    takeLatest(types.GET_APARTMENT_ACCESS_GROUPS_LIST_REQUEST, getApartmentAccessGroupsList),
    takeLatest(types.GET_DEVICE_ACCESS_GROUPS_LIST_REQUEST, getDeviceAccessGroupsList),
    takeLatest(types.GET_USER_ACCESS_GROUPS_LIST_REQUEST, getUserAccessGroupsList),
    takeLatest(types.GET_USER_APARTMENTS_ACCESS_GROUPS_LIST_REQUEST, getUserApartmentsAccessGroupsList),
    takeLatest(types.REMOVE_ACCESS_GROUP_APARTMENT_REQUEST, removeAccessGroupApartment),
    takeLatest(types.REMOVE_ACCESS_GROUP_DEVICE_REQUEST, removeAccessGroupDevice),
    takeLatest(types.REMOVE_ACCESS_GROUP_USER_REQUEST, removeAccessGroupUser),
    takeLatest(types.SET_ACCESS_CONTROL_LOG_SETTINGS_REQUEST, setAccessControlLogSettings),
    takeLatest(types.SET_ACCESS_CONTROL_SETTINGS_REQUEST, setAccessControlSettings),
    takeLatest(types.SET_LOG_ACCESS_GROUP_DEVICE_REQUEST, setLogAccessGroupDevice),
    takeLatest(types.UPDATE_ACCESS_GROUP_REQUEST, updateAccessGroup),
  ]);
}
