import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import CreateMultiAutocomplete from '../CreateMultiAutocomplete';
import messages from './messages';
import MultiAutocompleteOption from './MultiAutocompleteOption';
import UsersForm from './UsersForm';

function UsersAutocomplete({ hasFormItemKey, isDirty, newItemId, newItemWithoutLoginId, optionsData }) {
  const { watch } = useFormContext();
  const { formatMessage } = useIntl();

  const options = useMemo(
    () => [
      {
        id: newItemId,
        label: formatMessage(messages.usersAutocompleteNewItemLabel),
        tag: formatMessage(messages.usersAutocompleteNewItemShortLabel),
      },
      {
        id: newItemWithoutLoginId,
        label: formatMessage(messages.usersAutocompleteNewItemWithoutLoginLabel),
        tag: formatMessage(messages.usersAutocompleteNewItemWithoutLoginShortLabel),
      },
      ...optionsData,
    ],
    [optionsData]
  );

  return (
    <CreateMultiAutocomplete
      createForm={watch(hasFormItemKey) && <UsersForm />}
      createFormVisibilityKey={hasFormItemKey}
      customOption={(renderProps, option) => (
        <MultiAutocompleteOption
          key={option.id}
          newItemId={newItemId}
          newItemWithoutLoginId={newItemWithoutLoginId}
          option={option}
          optionLabelKey="label"
          renderProps={renderProps}
        />
      )}
      helperText={formatMessage(messages.usersAutocompleteHelperText)}
      label={formatMessage(messages.usersAutocompleteLabel)}
      name="users"
      newItemId={newItemId}
      newItemId2={newItemWithoutLoginId}
      optionLabelKey="label"
      options={options}
      placeholder={!isDirty ? formatMessage(messages.usersAutocompletePlaceholder) : ''}
    />
  );
}

UsersAutocomplete.propTypes = {
  hasFormItemKey: PropTypes.string.isRequired,
  isDirty: PropTypes.number.isRequired,
  newItemId: PropTypes.string.isRequired,
  newItemWithoutLoginId: PropTypes.string.isRequired,
  optionsData: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string,
      firstName: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
      lastName: PropTypes.string,
    })
  ).isRequired,
};

export default UsersAutocomplete;
