import CheckIcon from '@mui/icons-material/Check';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import ActionModal from '../_DesignSystemWrappers/Modal/ActionModal';
import TextField from '../HookFormFields/TextField';
import { useGetGlobalHooks } from './hooks/getGlobalHooks';
import messages from './messages';

interface IChangeUserRoleModal {
  values: {
    email: string;
    firstName: string;
    id: string | number;
    lastName: string;
    isCurrentUser: boolean;
  };
  onClose: () => void;
  onSetRole: (updated: IFormData) => void;
  open: boolean;
  resetRole: () => void;
}

interface IFormData {
  email: string;
  firstName: string;
  lastName: string;
}

function ChangeUserRoleModal({ onClose, onSetRole, open, resetRole, values }: IChangeUserRoleModal) {
  const { formatMessage, formMethods } = useGetGlobalHooks();

  useEffect(() => {
    if (open) {
      formMethods.reset({
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
      });
    }
  }, [open]);

  function handleSubmit({ email, firstName, lastName }: IFormData) {
    onSetRole({ email, firstName, lastName });
    onClose();
  }

  return (
    <ActionModal
      icon={<CheckIcon />}
      isPrimaryButtonDisabled={!formMethods.formState.isValid}
      muiDialogProps={{ maxWidth: 'xs' }}
      onClose={() => {
        resetRole();
        onClose();
      }}
      open
      primaryButtonAction={formMethods.handleSubmit(handleSubmit)}
      primaryButtonText={formatMessage(messages.changeUserRoleModalChangeRole)}
      secondaryButtonText={formatMessage(messages.changeUserRoleModalCancel)}
      title={formatMessage(messages.changeUserRoleModalTitle)}
    >
      <Stack direction="column" spacing={2} sx={{ mt: 2 }}>
        <Typography variant="body1">
          <FormattedMessage
            {...messages.changeUserRoleModalDescriptionPt1}
            values={{
              b: (chunks) => <b>{chunks}</b>,
            }}
          />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage {...messages.changeUserRoleModalDescriptionPt2} />
        </Typography>
      </Stack>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
          <Stack direction="column" spacing={2} sx={{ my: 4 }}>
            <TextField
              fullWidth
              helperText={formatMessage(messages.changeUserRoleModalEmailHelperText)}
              label={formatMessage(messages.changeUserRoleModalEmailLabel)}
              name="email"
              required
            />
            <TextField
              fullWidth
              label={formatMessage(messages.changeUserRoleModalFirstNameLabel)}
              name="firstName"
              required
            />
            <TextField
              fullWidth
              helperText={formatMessage(messages.changeUserRoleModalSurnameHelperText)}
              label={formatMessage(messages.changeUserRoleModalSurnameLabel)}
              name="lastName"
              required
            />
          </Stack>
        </form>
      </FormProvider>
    </ActionModal>
  );
}

export default ChangeUserRoleModal;
