import { AlertTitle } from '@mui/material';
import Alert from '@mui/material/Alert';
import Link from '@mui/material/Link';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useModal } from '../../../hooks/useModal';
import HelpModal from '../IntroStep/HelpModal';
import messages from './messages';

function SummaryWarning() {
  const { Modal, onOpen } = useModal({ Modal: HelpModal });
  return (
    <>
      <Alert severity="warning">
        <AlertTitle>
          <FormattedMessage {...messages.SiteUsersImportSummaryStepNumberOfBeforeImport} />
        </AlertTitle>
        <FormattedMessage
          {...messages.SiteUsersImportSummaryStepNumberOfBeforeImportText}
          values={{
            LinkModal: (chunks) => (
              <Link color="primary" component="button" onClick={onOpen} variant="body2">
                {chunks}
              </Link>
            ),
          }}
        />
      </Alert>
      <Modal />
    </>
  );
}

export default SummaryWarning;
