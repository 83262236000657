import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { FieldArray, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import { LoadingButton } from '../../../../components';
import { GP_WEBPAY, INVOICE, PROMO } from '../../constants';
import PromoTotalPriceBar from '../Common/PromoTotalPriceBar';
import { PROMO_TYPES, SUBSCRIPTION_TYPE } from '../consts';
import { getSubscriptionTypeBasedOnProduct, getVoucherValue } from '../helpers';
import { orderItemPropTypes, pricesPropTypes } from '../types';
import AddBatchItem from './AddBatchItem';
import messages from './messages';
import PromoBatchForm from './PromoBatchForm';

const minYears = 1;
const maxYears = 10;
const minSubscriptions = 1;
const maxSubscriptions = 3000;

const OneStep = ({
  canBeInvoiced,
  currency,
  isSiteMdu,
  onSetPromoCodes,
  onStepChange,
  orderContent,
  prices,
  promoCodesOrderIsLoading,
}) => {
  const defaultBatch = {
    id: 0,
    name: `Voucher ${new Date().getFullYear()}`,
    subscriptions: 10,
    subscriptionType: SUBSCRIPTION_TYPE.DEFAULT,
    years: 1,
  };

  const transformCurrentBatch = (order) => {
    const batch = [];

    if (order.length) {
      order.forEach((item) =>
        batch.push({
          id: item.id,
          name: item.name,
          subscriptions: item.configuration.devices,
          subscriptionType: getSubscriptionTypeBasedOnProduct(item.configuration.type),
          years: item.configuration.years,
        })
      );
    }

    return batch.length ? batch : [{ ...defaultBatch }];
  };

  const [currentBatch] = useState(transformCurrentBatch(orderContent));
  const [id, setId] = useState(defaultBatch.id);

  const getValidationSchema = () => {
    const minYearLength = <FormattedMessage values={{ year: minYears }} {...messages.validationMinYearLength} />;
    const maxYearLength = <FormattedMessage values={{ year: maxYears }} {...messages.validationMaxYearLength} />;
    const minSubscriptionLength = (
      <FormattedMessage values={{ subscription: minSubscriptions }} {...messages.validationMinSubscriptionLength} />
    );
    const maxSubscriptionLength = (
      <FormattedMessage values={{ subscription: maxSubscriptions }} {...messages.validationMaxSubscriptionLength} />
    );
    const requiredSubscriptionType = <FormattedMessage {...messages.validationRequiredSubscriptionType} />;
    const requiredName = <FormattedMessage {...messages.validationRequiredName} />;
    const required = <FormattedMessage {...messages.validationRequired} />;
    return Yup.object().shape({
      array: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required(requiredName),
          subscriptions: Yup.number()
            .required(required)
            .typeError(required)
            .max(maxSubscriptions, maxSubscriptionLength)
            .min(minYears, minSubscriptionLength),
          subscriptionType: Yup.string().required(requiredSubscriptionType),
          years: Yup.number()
            .required(required)
            .typeError(required)
            .max(maxYears, maxYearLength)
            .min(minYears, minYearLength),
        })
      ),
    });
  };

  const getFullPrice = (values) => {
    let unitsCountTypeDevice = 0;
    let unitsCountTypeApartment = 0;
    values.forEach((item) => {
      if (item.subscriptionType === SUBSCRIPTION_TYPE.DEVICE) {
        unitsCountTypeDevice += getVoucherValue(item.years, item.subscriptions);
      } else if (item.subscriptionType === SUBSCRIPTION_TYPE.APARTMENT) {
        unitsCountTypeApartment += getVoucherValue(item.years, item.subscriptions);
      }
    });

    return {
      price:
        unitsCountTypeDevice * prices?.MOBILE_VIDEO_POOL_CREDIT_YEAR_STANDARD?.unitFullPrice +
        unitsCountTypeApartment * prices?.MOBILE_VIDEO_POOL_CREDIT_YEAR_APARTMENT?.unitFullPrice,
      priceVat:
        unitsCountTypeDevice * prices?.MOBILE_VIDEO_POOL_CREDIT_YEAR_STANDARD?.unitFullPriceVat +
        unitsCountTypeApartment * prices?.MOBILE_VIDEO_POOL_CREDIT_YEAR_APARTMENT?.unitFullPriceVat,
      subscriptionsCount: unitsCountTypeDevice + unitsCountTypeApartment,
    };
  };

  const onHandleSubmit = (values) => {
    const promo = {
      content: [],
      paymentMethod: canBeInvoiced ? INVOICE : GP_WEBPAY,
    };

    values.array.forEach((item) => {
      promo.content.push({
        configuration: {
          devices: item.subscriptions,
          type:
            item.subscriptionType === SUBSCRIPTION_TYPE.DEVICE
              ? PROMO_TYPES.MOBILE_VIDEO_POOL_CREDIT_YEAR_STANDARD
              : PROMO_TYPES.MOBILE_VIDEO_POOL_CREDIT_YEAR_APARTMENT,
          years: item.years,
        },
        name: item.name,
        size: 1,
        type: PROMO,
      });
    });
    onSetPromoCodes(promo);
    onStepChange(2);
  };

  return (
    <Formik
      initialValues={{ array: currentBatch }}
      isInitialValid={!!orderContent.length}
      onSubmit={onHandleSubmit}
      render={({ handleSubmit, isValid, values }) => (
        <FieldArray
          name="array"
          render={(arrayHelpers) => (
            <Form noValidate onSubmit={handleSubmit}>
              {values.array.map((item, index) => (
                <div key={item.id}>
                  <Grid alignItems="center" container direction="row">
                    <Grid item md xs={12}>
                      <PromoBatchForm
                        batch={item}
                        batchIndex={index}
                        currency={currency}
                        formikValues={values}
                        helperFunctions={arrayHelpers}
                        isSiteMdu={isSiteMdu}
                        maxSubscriptions={maxSubscriptions}
                        maxYears={maxYears}
                        minSubscriptions={minYears}
                        minYears={minYears}
                        name={`array[${index}]`}
                        unitFullPrice={
                          item.subscriptionType === SUBSCRIPTION_TYPE.DEVICE
                            ? prices?.MOBILE_VIDEO_POOL_CREDIT_YEAR_STANDARD?.unitFullPrice
                            : prices?.MOBILE_VIDEO_POOL_CREDIT_YEAR_APARTMENT?.unitFullPrice
                        }
                      />
                      <Box>
                        <Box py={3}>
                          <Divider />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </div>
              ))}
              <AddBatchItem
                onAddBatch={() => {
                  const newId = id + 1;
                  arrayHelpers.push({ ...defaultBatch, id: newId });
                  setId(newId);
                }}
              />
              <Box mt={2}>
                <PromoTotalPriceBar
                  currency={currency}
                  price={getFullPrice(values.array).price}
                  priceVat={getFullPrice(values.array).priceVat}
                  subscriptionsCount={getFullPrice(values.array).subscriptionsCount}
                />
              </Box>
              <Box component="div" width="100%">
                <Box display="flex" justifyContent="flex-end" pt={2}>
                  <LoadingButton
                    color="primary"
                    disabled={!isValid}
                    isLoading={promoCodesOrderIsLoading}
                    type="submit"
                    variant="contained"
                  >
                    <FormattedMessage {...messages.submitButtonLabel} />
                  </LoadingButton>
                </Box>
              </Box>
            </Form>
          )}
        />
      )}
      validationSchema={getValidationSchema()}
    />
  );
};

OneStep.propTypes = {
  canBeInvoiced: PropTypes.bool.isRequired,
  currency: PropTypes.string.isRequired,
  isSiteMdu: PropTypes.bool.isRequired,
  onSetPromoCodes: PropTypes.func.isRequired,
  onStepChange: PropTypes.func.isRequired,
  orderContent: PropTypes.arrayOf(orderItemPropTypes),
  prices: pricesPropTypes.isRequired,
  promoCodesOrderIsLoading: PropTypes.bool.isRequired,
};

OneStep.defaultProps = {
  orderContent: [],
};

export default OneStep;
