import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import RowMenuItem from '../../DataGrid/RowMenuItem';
import messages from '../messages';

const Reverify = ({ device, onVerify }) => (
  <>
    <RowMenuItem
      colorIcon="primary.main"
      colorText="primary.main"
      icon={<CheckCircleIcon />}
      label={<FormattedMessage {...messages.deviceDataGridActionDeviceRowMenuReverify} />}
      onClick={() => onVerify(device)}
    />
    <Divider />
  </>
);

Reverify.propTypes = {
  device: PropTypes.shape({}).isRequired,
  onVerify: PropTypes.func.isRequired,
};

export default Reverify;
