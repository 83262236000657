import Box from '@mui/material/Box';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const DefaultNoRowsOverlay = () => (
  <Box
    sx={{
      alignItems: 'center',
      color: 'text.secondary',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      my: 2,
      typography: 'body1',
    }}
  >
    <FormattedMessage {...messages.DataGridNoRows} />
  </Box>
);

export default DefaultNoRowsOverlay;
