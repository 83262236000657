import { CALL_SETTINGS } from '../../../config/consts';
import { DTMFSwitchAction, FEATURES, FIRMWARE_STATUS, SERVICE, VERIFICATION_STATUS } from '../../../config/devices';
import { getDeviceMaxButtonCount } from './callSettings/devicesCallSettingsHelper';

export const isActiveService = (device, service) => Boolean(device?.services?.[service]?.active);

export const isNotVerified = (verificationStatus) =>
  [VERIFICATION_STATUS.NOT_VERIFIED, VERIFICATION_STATUS.PENDING, VERIFICATION_STATUS.ZOMBIE].includes(
    verificationStatus
  );

export const showErrorBadge = (firmwareStatus, verificationStatus) =>
  firmwareStatus === FIRMWARE_STATUS.UNSUPPORTED || isNotVerified(verificationStatus);

export const showWarningBadge = (firmwareStatus, verificationStatus) =>
  firmwareStatus === FIRMWARE_STATUS.DEPRECATED && !isNotVerified(verificationStatus);

export const getFeaturesDevice = (device) => device?.features || [];

export const getFeatureByType = (type) => (features) => {
  if (features) {
    // eslint-disable-next-line react/destructuring-assignment
    const feature = features.find((item) => item.type === FEATURES[type]);
    return feature || null;
  }
  return null;
};

// eslint-disable-next-line max-statements
export function getIntercomList(items, contacts, displays, deviceDetail, fillEmpty) {
  const deviceMaxButtonCount = getDeviceMaxButtonCount(deviceDetail);
  const defaultButtonCount = deviceMaxButtonCount || CALL_SETTINGS.INTERCOM.DEFAULT_LIST_COUNT;

  const createdItems = [];
  const maximumIndex = Math.max(...items.buttons.map((item) => item.index));

  for (let i = 0; i < maximumIndex || i < defaultButtonCount; i += 1) {
    const currentButton = items?.buttons?.find((item) => item.index === i + 1);
    if (currentButton) {
      createdItems.push({
        button: currentButton,
        contact: contacts?.contacts?.find((item) => currentButton.externalId === item.externalId),
        display: displays?.buttons?.find((item) => currentButton.externalId === item.externalId),
      });
    } else {
      if (fillEmpty) {
        createdItems.push({ ...CALL_SETTINGS.INTERCOM.DEFAULT_OBJECT });
      }
    }
  }
  return createdItems;
}

export function getEmptyIntercomList(deviceDetail, fillEmpty) {
  const createdItems = [];
  const deviceMaxButtonCount = getDeviceMaxButtonCount(deviceDetail);
  const defaultButtonCount = deviceMaxButtonCount || CALL_SETTINGS.INTERCOM.DEFAULT_LIST_COUNT;

  if (fillEmpty) {
    for (let i = 0; i < defaultButtonCount; i += 1) {
      createdItems.push({ ...CALL_SETTINGS.INTERCOM.DEFAULT_OBJECT });
    }
  } else {
    createdItems.push({ ...CALL_SETTINGS.INTERCOM.DEFAULT_OBJECT });
  }
  return createdItems;
}

export function shouldShowAddButton(buttonList, deviceMaxButtonCount) {
  return deviceMaxButtonCount === undefined ? true : buttonList.length < deviceMaxButtonCount;
}

export const getSelectedDevice = (devices, ids) => {
  let list = [];
  if (ids && Array.isArray(ids)) {
    list = ids;
  } else if (ids) {
    list.push(ids);
  }
  return devices.filter((device) => list.includes(device?.services?.MOBILE_VIDEO?.id));
};

export const getIntercomButtonItems = (buttons, devices) => {
  const items = [];
  if (buttons) {
    buttons.forEach((item, index) => {
      const selectedDeviceId =
        item.button?.contacts?.[0]?.contactItems?.find((button) => button.type === SERVICE.RINGING_GROUP)?.members ||
        [];

      const ringingGroup =
        item.button?.contacts?.[0]?.contactItems?.find((button) => button.type === SERVICE.RINGING_GROUP)?.sipNumber ||
        null;

      items.push({
        button: item.button,
        contact: item.contact,
        display: item.display,
        index,
        name: item.button?.name || '',
        ringingGroup,
        selectedDevices: getSelectedDevice(devices, selectedDeviceId),
      });
    });
  }
  return items;
};

export const findItemInDeviceTypeTemplate = (deviceTypes, type, prop) => {
  const item = deviceTypes.find((row) => row?.name === type);
  return item?.template?.[prop] || undefined;
};

export const manageSwitch = (featureCreated, switchItem) => ({
  ...switchItem,
  action: featureCreated && switchItem?.id ? DTMFSwitchAction.UPDATE_DTMF_SWITCH : DTMFSwitchAction.CREATE_DTMF_SWITCH,
  code: switchItem.code,
  enabled: switchItem.enabled,
  ...(featureCreated && { id: switchItem.id || null }),
  index: switchItem.index,
  managed: switchItem.managed,
  name: switchItem.name,
});
