import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReadOnlyField } from '../../../components';
import { SERVICE } from '../../../config/devices';
import messages from '../messages';
import { sharedStyles } from '../styles';

const styles = {
  migratedPassword: {
    color: 'secondary.main',
    display: 'flex',
    flexDirection: 'column',
  },
};

function DeviceCredentialsDetails({ deviceServices }) {
  return (
    <Grid container direction="column" item lg={3} sm={6} spacing={2}>
      <Grid item>
        <ReadOnlyField
          label={<FormattedMessage {...messages.userLoserDeviceDeviceId} />}
          value={
            <Box component="span" sx={sharedStyles.bold}>
              {deviceServices[SERVICE.MOBILE_VIDEO].sipNumber}
            </Box>
          }
        />
      </Grid>
      <Grid item>
        <ReadOnlyField
          label={<FormattedMessage {...messages.userLoserDeviceDevicePassword} />}
          value={
            deviceServices[SERVICE.NOTIFICATION].legacyAccessEnabled ? (
              <Box component="span" sx={sharedStyles.bold}>
                {deviceServices[SERVICE.MOBILE_VIDEO].sipPassword}
              </Box>
            ) : (
              <Box sx={styles.migratedPassword}>
                <FormattedMessage {...messages.userLoserDeviceDevicePasswordNotNeeded} />
                <Typography variant="caption">
                  <FormattedMessage {...messages.userLoserDeviceLogInToApp} values={{ br: <br /> }} />
                </Typography>
              </Box>
            )
          }
        />
      </Grid>
    </Grid>
  );
}

DeviceCredentialsDetails.propTypes = {
  deviceServices: PropTypes.shape({
    [SERVICE.MOBILE_VIDEO]: PropTypes.shape({
      sipNumber: PropTypes.string,
      sipPassword: PropTypes.string,
    }),
    [SERVICE.NOTIFICATION]: PropTypes.shape({
      legacyAccessEnabled: PropTypes.bool,
    }),
  }).isRequired,
};

export default DeviceCredentialsDetails;
