import { defineMessages } from 'react-intl';

export default defineMessages({
  userApartmentsAccessGroupsListEmpty: {
    defaultMessage: 'No access groups',
    description: 'User access groups list - empty placeholder',
    id: 'userApartmentsAccessGroupsList.empty',
  },
  userApartmentsAccessGroupsListLabel: {
    defaultMessage: 'Apartment settings',
    description: 'User apartments access groups list - list label',
    id: 'userApartmentsAccessGroupsList.label',
  },
});
