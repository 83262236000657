import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReadOnlyField } from '../../../components';
import DeviceStatus from '../../../components/DeviceStatus';
import messages from '../messages';
import { sharedStyles } from '../styles';

function DeviceBasicDetails({ device }) {
  return (
    <Grid container direction="column" item lg={3} sm={6} spacing={2}>
      <Grid item>
        <ReadOnlyField
          label={<FormattedMessage {...messages.userLoserDeviceDeviceName} />}
          value={
            <Box component="span" sx={sharedStyles.bold}>
              {device.name}
            </Box>
          }
        />
      </Grid>
      <Grid item>
        <ReadOnlyField
          label={<FormattedMessage {...messages.userLoserDeviceDeviceStatus} />}
          value={
            <Box component="span" sx={sharedStyles.bold}>
              <DeviceStatus device={device} />
            </Box>
          }
        />
      </Grid>
    </Grid>
  );
}

DeviceBasicDetails.propTypes = {
  device: PropTypes.shape({
    name: PropTypes.string,
  }).isRequired,
};

export default DeviceBasicDetails;
