import { defineMessages } from 'react-intl';

export default defineMessages({
  deviceAccessSettingsAlertNoGroups: {
    defaultMessage: "Users won't have access to this device until you set Access groups.",
    description: 'Device access settings - info no groups',
    id: 'deviceAccessSettingsAlertNoGroups',
  },
  deviceAccessSettingsAlertRacActiveWithoutGroups: {
    defaultMessage:
      'Access is available to all apartment users. If you now set an access group for the device, its users will also have access to the apartment.',
    description: 'Device access settings - info active without groups',
    id: 'deviceAccessSettingsAlertRacActiveWithoutGroups',
  },
  deviceAccessSettingsAlertRacInactive: {
    defaultMessage:
      'To set up access to this device you have to first activate' +
      ' Residential Access service and set Access groups.',
    description: 'Device access settings - info inactive',
    id: 'deviceAccessSettingsAlertRacInactive',
  },
  deviceAccessSettingsHelperText: {
    defaultMessage:
      'Device is available to users in ' + '{count, plural, =1 {this Access group} other {these Access groups}}',
    description: 'Device access settings - helper text',
    id: 'deviceAccessSettingsHelperText',
  },
  deviceAccessSettingsHelperTextWithApartment: {
    defaultMessage:
      'Device is available to all apartment users and users in ' +
      '{count, plural, =1 {this Access group} other {these Access groups}}',
    description: 'Device access settings - helper text',
    id: 'deviceAccessSettingsHelperTextWithApartment',
  },
});
