import { defineMessages } from 'react-intl';

export default defineMessages({
  accessGroupAddApartmentModalAdd: {
    defaultMessage: 'Add to group',
    description: 'Add apartment modal - add button',
    id: 'accessGroupAddApartmentModal.add',
  },
  accessGroupAddApartmentModalText: {
    defaultMessage:
      'Access groups define which users have access via which devices.' +
      '<p>Apartment users with set credentials will have access via all devices' +
      ' in selected Access groups. Access is activated within seconds.</p>',
    description: 'Add apartment modal - text',
    id: 'accessGroupAddApartmentModal.text',
  },
  accessGroupAddApartmentModalTitle: {
    defaultMessage: 'Add apartments to Access group',
    description: 'Add apartment modal - title',
    id: 'accessGroupAddApartmentModal.title',
  },
  accessGroupAddApartmentModalValidationApartment: {
    defaultMessage: 'Value is mandatory.',
    description: 'Apartment data form - number is exist',
    id: 'accessGroupAddApartmentModal.validation.apartment',
  },
  accessGroupAddApartmentModalValidationExistNumber: {
    defaultMessage: 'An apartment with this number already exists.',
    description: 'Apartment data form - number is exist',
    id: 'accessGroupAddApartmentModal.validation.existNumber',
  },
  accessGroupAddApartmentModalValidationRequiredFloor: {
    defaultMessage: 'Enter the floor number',
    description: 'Apartment data form - floor is required',
    id: 'accessGroupAddApartmentModal.validation.requiredFloor',
  },
  accessGroupAddApartmentModalValidationRequiredName: {
    defaultMessage: 'Enter the apartment name',
    description: 'Apartment data form - name is required',
    id: 'accessGroupAddApartmentModal.validation.requiredName',
  },
  accessGroupAddApartmentModalValidationRequiredNumber: {
    defaultMessage: 'Enter the apartment number',
    description: 'Apartment data form - number is required',
    id: 'accessGroupAddApartmentModal.validation.requiredNumber',
  },
});
