import { defineMessages } from 'react-intl';

export default defineMessages({
  mobileVideoServiceMobileVideo: {
    defaultMessage: '2N<sup>®</sup> Mobile Video',
    description: 'Activated services - mobile video',
    id: 'mobileVideoService.mobileVideo',
  },
  mobileVideoServiceModalActivate: {
    defaultMessage:
      'Do you want to activate Mobile Video service for this device?' +
      " Don't forget to assign this device to buttons in Call settings section.",
    description: 'Activated services - text in dialog',
    id: 'deviceDetail.mobileVideoService.modal.activate',
  },
  mobileVideoServiceModalActivateIPIntercom: {
    defaultMessage: 'Do you want to activate Mobile Video service for this device?',
    description: 'Activated services - text in dialog',
    id: 'deviceDetail.mobileVideoService.modal.activateIPIntercom',
  },
  mobileVideoServiceModalCancelButton: {
    defaultMessage: 'Cancel',
    description: 'Cancel button',
    id: 'mobileVideoService.modal.cancelButton',
  },
  mobileVideoServiceModalConfirmButton: {
    defaultMessage: 'OK',
    description: 'Confirm button',
    id: 'mobileVideoService.modal.confirmButton',
  },
  mobileVideoServiceModalDeactivate: {
    defaultMessage:
      'Do you want to deactivate Mobile Video service for this device?' +
      ' All button assignments for this device will be removed.',
    description: 'Activated services - text in dialog',
    id: 'deviceDetail.mobileVideoService.modal.deactivate',
  },
  mobileVideoServiceModalDeactivateIPIntercom: {
    defaultMessage: 'Do you want to deactivate Mobile Video service for this device?',
    description: 'Activated services - text in dialog',
    id: 'deviceDetail.mobileVideoService.modal.deactivateIPIntercom',
  },
  mobileVideoStatusStatusActive: {
    defaultMessage: 'Active',
    description: 'Status active',
    id: 'mobileVideoStatus.status.active',
  },
  mobileVideoStatusStatusActiveUntil: {
    defaultMessage: '<strong>Active</strong> until {until}',
    description: 'Status active until',
    id: 'mobileVideoStatus.status.activeUntil',
  },
  mobileVideoStatusStatusNotActive: {
    defaultMessage: 'Inactive',
    description: 'Status not active',
    id: 'mobileVideoStatus.status.inactive',
  },
  mobileVideoStatusStatusTrialActive: {
    defaultMessage: '<strong>Active trial</strong> until {until}',
    description: 'Status active trial',
    id: 'mobileVideoStatus.status.trialActive',
  },
  mobileVideoStatusStatusTrialExpired: {
    defaultMessage: '<strong>Trial expired</strong>',
    description: 'Status trial expired',
    id: 'mobileVideoStatus.status.trialExpired',
  },
  phonebooksDevicePhonebook: {
    defaultMessage: 'Device phonebook',
    description: 'Phonebook tab - device phonebook',
    id: 'phonebooks.devicePhonebook',
  },
  phonebooksEditPhonebook: {
    defaultMessage: 'Edit phonebook',
    description: 'Phonebook tab - edit phonebook',
    id: 'phonebooks.editPhonebook',
  },
  phonebooksSave: {
    defaultMessage: 'Save',
    description: 'Phonebook tab - save button',
    id: 'phonebooks.save',
  },
  phonebooksTitle: {
    defaultMessage: 'Phonebook',
    description: 'Phonebook tab - phonebook',
    id: 'phonebooks.title',
  },
  serviceBoxConfigurationModeAutomatic: {
    defaultMessage: 'Automatic',
    description: 'Service box - automatic configuration mode',
    id: 'serviceBox.configurationMode.automatic',
  },
  serviceBoxConfigurationModeFaqLink: {
    defaultMessage: 'FAQ',
    description: 'Service box - FAQ link',
    id: 'serviceBox.configurationMode.faqLink',
  },
  serviceBoxConfigurationModeInfo: {
    defaultMessage: `<strong>Automatic configuration mode</strong> may overwrite some of your
    2N product settings! It presumes that you will not make any changes directly in not
    make any changes directly in the 2N product configuration interface. Please see`,
    description: 'Service box - automatic configuration mode description',
    id: 'serviceBox.configurationMode.info',
  },
  serviceBoxConfigurationModeInfoEnding: {
    defaultMessage: 'for more details.',
    description: 'Service box - info ending',
    id: 'serviceBox.configurationMode.infoEnding',
  },
  serviceBoxConfigurationModeManual: {
    defaultMessage: 'Manual',
    description: 'Service box - manual configuration mode',
    id: 'serviceBox.configurationMode.manual',
  },
  serviceBoxConfigurationModeTitle: {
    defaultMessage: 'Configuration Mode',
    description: 'Service box - configuration mode',
    id: 'serviceBox.configurationMode.title',
  },
  serviceBoxConsumeButton: {
    defaultMessage: 'Use available credit',
    description: 'Service box - Use available credit button',
    id: 'serviceBox.consumeButton',
  },
  serviceBoxPurchaseButton: {
    defaultMessage: 'Purchase',
    description: 'Service box - purchase button',
    id: 'serviceBox.purchaseButton',
  },
  serviceBoxStatusInfo: {
    defaultMessage: 'Status info',
    description: 'Service box - Status Info',
    id: 'serviceBox.statusInfo',
  },
});
