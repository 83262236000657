import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import RowMenuItem from '../../DataGrid/RowMenuItem';
import messages from './messages';

const AddUserToGroup = ({ onClick }: { onClick: () => void }) => (
  <RowMenuItem
    icon={<AddIcon />}
    label={<FormattedMessage {...messages.AccessSettingsDataGridTableRowActionsAddUsers} />}
    onClick={onClick}
  />
);

export default AddUserToGroup;
