import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import Divider from '@mui/material/Divider';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import RowMenuItem from '../DataGrid/RowMenuItem';
import messages from './messages';

interface IUseAvailableCreditMenuItem {
  onOpenAvailableCreditModal: () => void;
}

const UseAvailableCreditMenuItem = ({ onOpenAvailableCreditModal }: IUseAvailableCreditMenuItem) => (
  <>
    <RowMenuItem
      colorIcon="primary.main"
      colorText="primary.main"
      icon={<ConfirmationNumberIcon />}
      label={<FormattedMessage {...messages.useAvailableCreditMenuItemUseAvailableCredit} />}
      onClick={onOpenAvailableCreditModal}
    />
    <Divider />
  </>
);

export default UseAvailableCreditMenuItem;
