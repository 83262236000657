import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  box: (theme) => ({
    [theme.breakpoints.down('lg')]: {
      height: '68px',
      mr: 3,
      width: '68px',
    },
    '& img': {
      [theme.breakpoints.down('lg')]: {
        width: '48px',
      },
      bottom: 0,
      left: 0,
      margin: 'auto',
      position: 'absolute',
      right: 0,
      top: 0,
      width: '70px',
    },
    bgcolor: 'info.dark',
    borderRadius: '10px',
    display: 'inline-block',
    height: '98px',
    mr: 4.5,
    position: 'relative',
    verticalAlign: 'middle',
    width: '98px',
  }),
  root: (theme) => ({
    [theme.breakpoints.up('sm')]: {
      my: 4,
    },
    '&:hover': {
      '& div': {
        bgcolor: 'primary.main',
      },
      '& span': {
        color: 'primary.main',
      },
      cursor: 'pointer',
    },
    my: 1,
    outline: 'none',
    textAlign: 'left',
  }),
  text: (theme) => ({
    [theme.breakpoints.down('lg')]: {
      fontSize: '18px',
    },
    display: 'inline-block',
    fontSize: '20px',
    verticalAlign: 'middle',
    width: '200px',
  }),
};

const LandingInfoService = ({ image, onClick, text }) => (
  <Box onClick={onClick} sx={styles.root}>
    <Box component="div" sx={styles.box}>
      {image}
    </Box>
    <Box component="span" sx={styles.text}>
      {text}
    </Box>
  </Box>
);

LandingInfoService.propTypes = {
  image: PropTypes.element.isRequired,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.element.isRequired,
};

export default LandingInfoService;
