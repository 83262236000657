import ElevatorIcon from '@mui/icons-material/Elevator';
import Stack from '@mui/material/Stack';
import React, { useEffect } from 'react';
import { IDeviceElevatorIntegration } from '../../api/elevatorIntegrationTS';
import actions from '../../modules/elevatorIntegration';
import DeviceElevatorIntegrationMainSettings from '../DeviceElevatorIntegrationMainSettings';
import ElevatorIntegrationSwitchSettings from '../ElevatorIntegrationSwitchSettings';
import RowSection from '../RowSection';
import { useGetDeviceElevatorIntegrationData } from './hooks/useGetDeviceElevatorIntegrationData';
import { useGetGlobalHooks } from './hooks/useGetGlobalHooks';
import messages from './messages';

function DeviceElevatorIntegrationSettings() {
  const { dispatch, formatMessage } = useGetGlobalHooks();
  const {
    data,
    isInvalid,
    urlParams: { companyId, deviceId, siteId },
  } = useGetDeviceElevatorIntegrationData();

  useEffect(() => {
    if (isInvalid && deviceId !== undefined) {
      dispatch(actions.getDeviceElevatorIntegration(companyId, siteId, deviceId));
    }
  }, [isInvalid]);

  useEffect(() => {
    if (deviceId !== undefined) {
      dispatch(actions.getDeviceElevatorIntegration(companyId, siteId, deviceId));
    }
  }, []);

  function handleElevatorIntegrationSet(landingCall: boolean, destinationCall: boolean): void {
    if (data) {
      const setData = { ...data };

      delete (setData as Partial<IDeviceElevatorIntegration>).integrationActive;

      if (companyId !== undefined && siteId !== undefined && deviceId !== undefined) {
        dispatch(
          actions.setDeviceElevatorIntegration(companyId, siteId, deviceId, {
            ...setData,
            destinationCallEnabled: !landingCall ? false : destinationCall,
            landingCallEnabled: landingCall,
          })
        );
      }
    }
  }

  return (
    <RowSection
      label={formatMessage(messages.DeviceElevatorIntegrationSettingsLabel)}
      icon={<ElevatorIcon />}
      hasDivider
    >
      <Stack direction="column" spacing={{ md: 0.5, xs: 1 }}>
        <DeviceElevatorIntegrationMainSettings isActive={!!data?.integrationActive} />
        {!!data?.integrationActive && (
          <ElevatorIntegrationSwitchSettings
            currentSettings={{
              destinationCallEnabled: data.destinationCallEnabled,
              landingCallEnabled: data.landingCallEnabled,
            }}
            onElevatorIntegrationSet={handleElevatorIntegrationSet}
          />
        )}
      </Stack>
    </RowSection>
  );
}

export default DeviceElevatorIntegrationSettings;
