import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import SvgLoader from '../components/SvgLoader';
import { PATHS } from '../config/consts';
import AboutPage from '../containers/AboutPage';
import SupportPage from '../containers/SupportPage';
import UserProfile from '../containers/UserProfile';
import { isJwtExpired } from '../helpers/checkJwtExpiration';
import { getAccessTokenSelector, isLoadingSelector, isLoggedInSelector } from '../modules/auth/selectors';
import { Roles } from '../modules/commonTypes';
import { getCurrentUser } from '../modules/user/actions';
import { getCurrentUserSelector } from '../modules/user/selectors';
import {
  didInvalidCurrentUser as didInvalidCurrentUserSelector,
  getCurrentUserIsFetching,
} from '../modules/user/selectorsTS';
import { useOryUserLogged } from '../ory/hooks/oryUser/useOryUserLogged';
import { OryLoginStatus } from '../ory/types';
import Layout from '../pages/_components/Layout';
import FallbackPage from '../pages/FallbackPage';
import { ApartmentAdminRoutes } from './ApartmentAdminRoutes';
import { CompanyRoutes } from './CompanyRoutes';
import { PrivateRoute } from './components/PrivateRoute';

export function AppRoutes() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accessToken = useSelector(getAccessTokenSelector);
  const loggedIn = useSelector(isLoggedInSelector);
  const user = useSelector(getCurrentUserSelector);
  const isFetching = useSelector(isLoadingSelector);
  const isCurrentUserFetching = useSelector(getCurrentUserIsFetching);
  const didInvalidCurrentUser = useSelector(didInvalidCurrentUserSelector);
  const expiredAccessToken = isJwtExpired(accessToken);

  const { isOryLoginVerificationDone, oryLoginStatus } = useOryUserLogged({ accessToken, currentUser: user });

  useEffect(() => {
    if (!loggedIn && isFetching === false && user?.id !== undefined && isOryLoginVerificationDone) {
      navigate(PATHS.LOGIN, { replace: true });
    } else if (expiredAccessToken && isFetching === false) {
      navigate(PATHS.LOGOUT, { replace: true });
    }
  }, [accessToken, loggedIn, isFetching, user?.id]);

  useEffect(() => {
    if (accessToken !== undefined) {
      const interval = setInterval(() => {
        if (isJwtExpired(accessToken)) {
          navigate(PATHS.LOGOUT, { replace: true });
        }
      }, 300000);
      return () => clearInterval(interval);
    }

    return undefined;
  }, [accessToken]);

  useEffect(() => {
    if (
      didInvalidCurrentUser ||
      (loggedIn && !isCurrentUserFetching && (user.data === undefined || user.data.id === undefined))
    ) {
      dispatch(getCurrentUser());
    }
  }, [accessToken, loggedIn, didInvalidCurrentUser]);

  if (
    accessToken === undefined &&
    oryLoginStatus !== OryLoginStatus.SessionConfirmed &&
    !isFetching &&
    isOryLoginVerificationDone
  ) {
    return <Navigate to={PATHS.LOGIN} />;
  }

  if (!loggedIn || isCurrentUserFetching || didInvalidCurrentUser || !isOryLoginVerificationDone) {
    return <SvgLoader />;
  }

  return (
    <Routes>
      <Route path="/*" element={<Layout />}>
        <Route path="companies/:companyId/*" element={<CompanyRoutes />} />
        <Route path="my-apartment/*" element={<ApartmentAdminRoutes />} />

        <Route
          path={'profile'}
          element={
            <PrivateRoute allowedRoles={{ contextRoles: [Roles.ANY] }}>
              <UserProfile />
            </PrivateRoute>
          }
        />
        <Route
          element={
            <PrivateRoute allowedRoles={{ contextRoles: [Roles.ANY] }}>
              <AboutPage />
            </PrivateRoute>
          }
          path={'about'}
        />
        <Route
          element={
            <PrivateRoute
              allowedRoles={{
                contextRoles: [Roles.ANY],
              }}
            >
              <SupportPage />
            </PrivateRoute>
          }
          path={'help'}
        />
      </Route>

      <Route path="*" element={<FallbackPage />} />
    </Routes>
  );
}
