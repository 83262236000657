import { defineMessages } from 'react-intl';

export default defineMessages({
  configureMy2NaccessModal2Text1: {
    defaultMessage: 'Verifying the current status. Please wait.',
    id: 'configure.My2NAccess.Modal2.text1',
  },
  configureMy2NaccessModal2Text2: {
    defaultMessage: 'Details for the detection?',
    id: 'configure.My2NAccess.Modal2.text2',
  },
  configureMy2NaccessModal2Title: {
    defaultMessage: 'Configure My2N app access',
    id: 'configure.My2NAccess.Modal2.title',
  },
});
