import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';

const TextList = ({ children }) => (
  <Box
    sx={{
      ul: {
        mx: 2.5,
        my: 0,
        p: 0,
      },
    }}
  >
    <ul>{children}</ul>
  </Box>
);

TextList.propTypes = {
  children: PropTypes.any.isRequired,
};

export default TextList;
