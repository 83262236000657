import CreditCardIcon from '@mui/icons-material/CreditCard';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import RowMenuItem from '../../DataGrid/RowMenuItem';
import messages from '../messages';

const AssignNewCard = ({ disabled, onOpenAssignNewCardModal, user }) => (
  <RowMenuItem
    disabled={disabled}
    icon={<CreditCardIcon />}
    label={<FormattedMessage {...messages.siteUsersRowMenuAssignNewCard} />}
    onClick={() => onOpenAssignNewCardModal(user)}
  />
);

AssignNewCard.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onOpenAssignNewCardModal: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
};

export default AssignNewCard;
