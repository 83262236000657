import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DEVICE_TYPE } from '../../config/devices';
import { DeviceType } from '../../modules/commonTypes';
import messages from './messages';

interface IDeviceTypeLabelParams {
  type: DeviceType;
}

function DeviceTypeLabel({ type }: IDeviceTypeLabelParams) {
  return (
    <FormattedMessage
      tagName="span"
      values={{
        sub: (chunks) => <sub>{chunks}</sub>,
        sup: (chunks) => <sup>{chunks}</sup>,
      }}
      {...deviceTypeTranslationMap[type]}
    />
  );
}
export default DeviceTypeLabel;

const deviceTypeTranslationMap = {
  [DEVICE_TYPE.ACCESS_UNIT]: messages.deviceTypeAccessUnit,
  [DEVICE_TYPE.ACCESS_UNIT_M]: messages.deviceTypeAccessUnitM,
  [DEVICE_TYPE.CHAMELEON]: messages.deviceTypeChameleon,
  [DEVICE_TYPE.CLIP]: messages.deviceTypeClip,
  [DEVICE_TYPE.DOOR_STATION]: messages.deviceTypeDoorStation,
  [DEVICE_TYPE.HIPMO]: messages.deviceTypeHipmo,
  [DEVICE_TYPE.IDT]: messages.deviceTypeIdt,
  [DEVICE_TYPE.ID_COMPACT]: messages.deviceTypeIdCompact,
  [DEVICE_TYPE.ID_TALK]: messages.deviceTypeIdTalk,
  [DEVICE_TYPE.ID_VIEW]: messages.deviceTypeIdView,
  [DEVICE_TYPE.IP_INTERCOM]: messages.deviceTypeIpIntercom,
  [DEVICE_TYPE.IP_INTERCOM_V2]: messages.deviceTypeIpIntercomV2,
  [DEVICE_TYPE.IP_ONE_INTERCOM]: messages.deviceTypeIpOneIntercom,
  [DEVICE_TYPE.IP_STYLE]: messages.deviceTypeIpStyle,
  [DEVICE_TYPE.THIRD_PARTY]: messages.deviceTypeThirdParty,
};
