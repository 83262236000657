import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { getLinkFromVersion } from '../helper';
import GTCDescription from './GTCDescription';
import GTCTitle from './GTCTitle';
import GTCVersionList from './GTCVersionList';

const styles = {
  root: {
    borderTop: '2px solid',
    borderTopColor: 'secondary.light',
    height: 'auto',
    mx: 2,
    my: 1,
    p: 2,
    width: 300,
  },
};

const GTCItem = ({ basePath, description, name, versions }) => {
  const link = getLinkFromVersion(versions);
  return (
    <Box sx={styles.root}>
      <GTCTitle link={basePath.replace(':slug', link.path).replace(':version', `v${link.version}`)} title={name} />
      <GTCDescription html={description} />
      <GTCVersionList basePath={basePath} versions={versions} />
    </Box>
  );
};

GTCItem.propTypes = {
  basePath: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  name: PropTypes.string.isRequired,
  versions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default GTCItem;
