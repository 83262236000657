import { types } from '.';
import { CallSettingsRequestObject } from './helpers/saveDeviceHelper';
import { DeviceFeatureType, IDevice } from './store/deviceStore';

export interface IRequestContact {
  externalId?: string;
  id?: number;
  name: string;
  index: number;
  action: string;
  contactItems?: any[];
  contacts?: any[];
  type?: string;
}

export interface IRequestContactsFeature extends IRequestFeatureBase {
  contacts: IRequestContact[];
}

export interface IRequestButtonsFeature extends IRequestFeatureBase {
  buttons: IRequestContact[];
}

export interface IRequestFeatureBase {
  id: number;
  type: string;
  externalId?: string;
  virtualNumbersEnabled?: boolean;
  name?: string;
}

export const saveDeviceCallSettingsRequest = (values: Map<DeviceFeatureType, IRequestContactsFeature>) => ({
  payload: { values },
  type: types.SAVE_DEVICE_CALL_SETTINGS_REQUEST,
});

export const saveMultipleDevicesCallSettingsRequest = (
  values: {
    device: IDevice;
    requestObjects: Map<DeviceFeatureType, CallSettingsRequestObject>;
  }[]
) => ({
  payload: { values },
  type: types.SAVE_MULTIPLE_DEVICES_CALL_SETTINGS_REQUEST,
});
