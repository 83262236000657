import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { useGetActualCompanyId } from '../../../hooks/companyHooks';
import * as siteActions from '../../../modules/sites/actions';
import * as siteSelectors from '../../../modules/sites/selectorsTS';
import { getCreateSiteValidationSchema } from '../../../validations/createSiteValidationSchema';
import { IChangeSiteNameFormValues } from '../EditSiteModal';

export function useGetGlobalHooks() {
  const { formatMessage } = useIntl();
  const companyId = useGetActualCompanyId();
  const dispatch = useDispatch();
  const onClose = () => dispatch(siteActions.editSiteHideModal());
  const open = useSelector(siteSelectors.isModalOpen);
  const isLoading = useSelector(siteSelectors.isModalDataFetching);

  const formMethods = useForm<IChangeSiteNameFormValues>({
    defaultValues: {
      name: '',
    },
    mode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        ...getCreateSiteValidationSchema(companyId, formatMessage),
      })
    ),
  });

  return {
    dispatch,
    formatMessage,
    formMethods,
    isLoading,
    onClose,
    open,
    siteActions,
  };
}
