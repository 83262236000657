import { defineMessages } from 'react-intl';

export default defineMessages({
  resetPasswordBackToLogin: {
    defaultMessage: 'Back to Log in',
    description: 'Reset password - Back to Log in',
    id: 'resetPassword.backToLogin',
  },
  resetPasswordEmailInvalid: {
    defaultMessage: 'Wrong format of the e-mail.',
    description: 'Reset password - invalid email',
    id: 'resetPassword.email.invalid',
  },
  resetPasswordEmailLabel: {
    defaultMessage: 'E-mail',
    description: 'Reset password - E-mail',
    id: 'resetPassword.email.label',
  },
  resetPasswordEmailRequired: {
    defaultMessage: 'Value is mandatory',
    description: 'Reset password - email required',
    id: 'resetPassword.email.required',
  },
  resetPasswordSubmit: {
    defaultMessage: 'Send request',
    description: 'Reset password - Send new password',
    id: 'resetPassword.submit',
  },
});
