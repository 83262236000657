import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import { IMultistepDialogRefAttributes, MultistepDialog } from '@my2n/multistep-dialog';
import React, { MutableRefObject, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import {
  SiteBluetoothEnabledDto,
  useUpdateSiteBluetoothMutation,
  useUpdateSiteCustomLocationMutation,
} from '../../apiRtk/accessControlApi';
import { useGetLoadedParams } from '../../hooks/paramsHooks';
import { useGetGlobalInfo } from '../../hooks/useGetGlobalInfo';
import { IModalBase } from '../../modules/commonTypes';
import { TYPE_DESCRIPTOR } from '../../modules/notifications';
import { addSnackbarError, addSnackbarSuccess } from '../../modules/notifications/actions';
import { InitialWarning } from './InitialWarning';
import messages from './messages';
import Step2 from './Step2/Step2';
import Step3 from './Step3/Step3';
import { getRadioGroupBooleanValue } from './Step4/Questions2/helpers/getRadioGroupBooleanValue';
import Step4 from './Step4/Step4';
import { FormFieldNames, getDefaultValues, getValidationSchema } from './validationSchema';

interface IConfigureMy2NAccessModal extends IModalBase {
  refetchSiteData: () => void;
}

const ConfigureMy2NAccessModal = ({ onClose, refetchSiteData }: IConfigureMy2NAccessModal) => {
  const [offlinePairingData, setOfflinePairingData] = useState({});
  const { dispatch, formatMessage } = useGetGlobalInfo();
  const { companyId, siteId } = useGetLoadedParams();
  const [updateSiteBluetooth] = useUpdateSiteBluetoothMutation();
  const [updateSiteCustomLocation] = useUpdateSiteCustomLocationMutation();
  const ref: MutableRefObject<IMultistepDialogRefAttributes | undefined> = useRef();

  const formMethods = useForm({
    defaultValues: getDefaultValues(offlinePairingData),
    mode: 'onChange',
    resolver: yupResolver(getValidationSchema()),
  });

  const getLocationIdValue = () => {
    const customLocationIdValue = formMethods.getValues(FormFieldNames.CustomId);
    const selectIdValue = formMethods.getValues(FormFieldNames.SelectId);

    if (selectIdValue === 'GenerateNew') {
      return siteId!.toString();
    } else if (selectIdValue === 'OwnId') {
      return customLocationIdValue;
    } else {
      return selectIdValue;
    }
  };

  function onSubmit() {
    const siteBLEvalue = getRadioGroupBooleanValue(formMethods.getValues(FormFieldNames.ManageBLE));
    const locationIdValue = getLocationIdValue();
    const promises = [];

    if (siteBLEvalue && locationIdValue) {
      promises.push(
        updateSiteCustomLocation({
          companyId: companyId!.toString(),
          siteCustomLocationDto: {
            locationId: locationIdValue,
          },
          siteId: siteId!.toString(),
        }).unwrap()
      );
    }

    promises.push(
      updateSiteBluetooth({
        companyId: companyId!.toString(),
        siteBluetoothEnabledDto: {
          bluetoothEnabled: siteBLEvalue,
        } as SiteBluetoothEnabledDto,
        siteId: siteId!.toString(),
      }).unwrap()
    );

    Promise.all(promises)
      .then(() => {
        dispatch(addSnackbarSuccess({ ...messages.siteSettingsAccessMy2NappMsgSucc }));
        refetchSiteData();
      })
      .catch(() => {
        dispatch(addSnackbarError({ ...messages.siteSettingsAccessMy2NappMsgErr }, TYPE_DESCRIPTOR));
      });
  }

  const handleSubmit = () => {
    onSubmit();
    onClose();
  };

  return (
    <FormProvider {...formMethods}>
      <MultistepDialog
        ref={ref}
        title={formatMessage(messages.configureMy2NAccessModalTitle)}
        backButtonLabel={formatMessage(messages.configureMy2NAccessModalBack)}
        cancelButtonLabel={formatMessage(messages.configureMy2NAccessModalCancel)}
        nextButtonLabel={formatMessage(messages.configureMy2NAccessModalNext)}
        orderedSteps={[
          {
            children: <InitialWarning />,
          },
          {
            children: (
              <Step2
                onNextStep={() => {
                  ref.current?.handleClickNext();
                }}
                setOfflinePairingData={setOfflinePairingData}
              />
            ),
            isNextDisabled: true,
          },
          {
            children: <Step3 offlinePairingData={offlinePairingData} />,
          },
          {
            children: <Step4 offlinePairingData={offlinePairingData} />,
          },
        ]}
        submitButton={{
          icon: <CheckIcon />,
          isDisabled: !formMethods.formState.isValid,
          label: formatMessage(messages.configureMy2NAccessModalSubmit),
        }}
        onClose={onClose}
        onSubmit={handleSubmit}
        backSkip={[1]}
      />
    </FormProvider>
  );
};

export default ConfigureMy2NAccessModal;
