import HighlightOff from '@mui/icons-material/HighlightOff';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import ActionModalError from '../../../../../components/_DesignSystemWrappers/Modal/ActionModalError';
import * as actions from '../../../../../modules/apartmentAdmin/actions';
import { IModalBase, TId } from '../../../../../modules/commonTypes';
import { IDevice } from '../../../../../modules/devices/store/deviceStore';
import messages from '../messages';

interface IADeactivateMVModal extends IModalBase {
  apartmentId: TId;
  companyId: TId;
  device: IDevice;
  siteId: TId;
}

function DeactivateMVModal({ apartmentId, companyId, device, onClose, open, siteId }: IADeactivateMVModal) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const onSubmit = () => {
    dispatch(actions.deleteDeviceWithinApartment(companyId, siteId, apartmentId, device.id));
    onClose();
  };

  return (
    <ActionModalError
      icon={<HighlightOff />}
      isPrimaryButtonDisabled={false}
      muiDialogProps={{ maxWidth: 'xs' }}
      primaryButtonAction={onSubmit}
      primaryButtonText={formatMessage(messages.AAModalMobileVideoDeactivateSubmit)}
      secondaryButtonText={formatMessage(messages.AAModalMobileVideoDeactivateCancel)}
      onClose={onClose}
      open={open}
      title={formatMessage(messages.AAModalMobileVideoDeactivateTitle)}
    >
      <Typography py={3}>{formatMessage(messages.AAModalMobileVideoDeactivateText)}</Typography>
    </ActionModalError>
  );
}

export default DeactivateMVModal;
