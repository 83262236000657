export const SUPPORT = 'customercare@2n.com';
export const EMAIL_SALES = 'sales@2n.com';
export const WIKI_2N = 'https://2nwiki.2n.cz';

export const FAQ = 'https://faq.2n.cz/pages/viewpage.action?pageId=60261019';
export const PRODUCT_BOARD = 'https://portal.productboard.com/uef9lw6yumoa4gcpd53z34px/tabs/6-released';

export const COOKIES_BROWSER = {
  CHROME: 'https://support.google.com/accounts/answer/61416?hl=cs',
  FIREFOX: 'https://support.mozilla.org/en-US/kb/disable-third-party-cookies',
  SAFARI: 'https://support.apple.com/guide/safari/manage-cookies',
};

export const GOOGLE_ANALYTICS = 'https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage';

export const FAQ_PHONEBOOK = 'https://2nwiki.2n.cz/display/FAQ/Phonebook+-+How+to+setup+alternative+phonebook';

export const IMPROVEMENTS = 'https://portal.productboard.com/uef9lw6yumoa4gcpd53z34px/tabs/6-released/submit-idea';

export const DRIVER_LINK = 'https://www.2n.com/cs_CZ/documents/22902/463808/2N+Driver+for+External+USB+Readers';

export const USERS_IMPORT_TEMPLATE = '/assets/My2N_Import_users_with_apartment.xlsx';
export const USERS_IMPORT_TEMPLATE_PREDEFINED = '/assets/My2N_Import_users_with_apartment_help.xlsx';

export const INTEGRATE_WITH_2N_REQUEST = 'https://www.2n.com/en_GB/solutions/integrations-with-2n/integration-request';
