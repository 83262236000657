import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { FORM } from '../../config/consts';
import actions from '../../modules/accessSettings';
import { getAccessGroupUsersAll } from '../../modules/accessSettings/selectors';
import { ActionModal } from '../_DesignSystemWrappers/Modal';
import UsersAutocomplete from '../UsersAutocomplete';
import { useGetGlobalHooks } from './hooks/getGlobalHooks';
import messages from './messages';

function AccessGroupAddUsersModal({ groupId, onClose, open }) {
  const { dispatch, formatMessage, formMethods, handleSubmit, isDirty, isValid, users } = useGetGlobalHooks(
    FORM.HAS_FORM
  );
  const accessGroupCurrentUsers = useSelector(getAccessGroupUsersAll);

  useEffect(() => {
    dispatch(actions.getAccessControlUsers(groupId));
  }, []);

  function handleOnSubmit() {
    const data = formMethods.getValues();
    const usersUUID = data.users
      .filter((item) => !(item.id === FORM.NEW_ITEM_ID || item.id === FORM.NEW_ITEM_WITHOUT_LOGIN_ID))
      .flatMap((item) => item.uuid);

    if (data.lastName) {
      const { email, firstName, lastName } = data;
      dispatch(actions.addAccessGroupUserWithCreate(groupId, { email, firstName, lastName }));
    }

    if (usersUUID.length) {
      if (accessGroupCurrentUsers && accessGroupCurrentUsers.length) {
        usersUUID.push(...accessGroupCurrentUsers.map((user) => user.uuid));
      }
      dispatch(actions.addAccessGroupUsers(groupId, usersUUID));
    }

    onClose();
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <ActionModal
          icon={<AddIcon />}
          isPrimaryButtonDisabled={!isDirty || !isValid}
          muiDialogProps={{
            maxWidth: 'xs',
          }}
          onClose={onClose}
          open={open}
          primaryButtonAction={handleSubmit(handleOnSubmit)}
          primaryButtonText={formatMessage(messages.accessGroupAddUserModalAdd)}
          secondaryButtonText={formatMessage(messages.accessGroupAddUserModalCancel)}
          title={formatMessage(messages.accessGroupAddUsersModalTitle)}
        >
          <Typography variant="body1">
            <FormattedMessage {...messages.accessGroupAddUserModalText} values={{ br: <br /> }} />
          </Typography>
          <UsersAutocomplete
            hasFormItemKey={FORM.HAS_FORM}
            isDirty={formMethods.getValues().users.length}
            newItemId={FORM.NEW_ITEM_ID}
            newItemWithoutLoginId={FORM.NEW_ITEM_WITHOUT_LOGIN_ID}
            optionsData={users}
          />
        </ActionModal>
      </form>
    </FormProvider>
  );
}

AccessGroupAddUsersModal.propTypes = {
  groupId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

AccessGroupAddUsersModal.defaultProps = {
  groupId: '',
};

export default AccessGroupAddUsersModal;
