import {
  IDeviceElevatorIntegration,
  IElevatorIntegrationFloorMapping,
  ISiteElevatorIntegration,
  IUserElevatorIntegration,
} from '../../api/elevatorIntegrationTS';
import { CommonTypes, IError, IReducers, IReduxAction } from '../reduxTypes';
import { getInitialElevatorIntegrationStore, IElevatorIntegrationStore } from './store';
import { types } from './types';

const reducers: IReducers<IElevatorIntegrationStore, CommonTypes> = {
  [types.GET_SITE_ELEVATOR_INTEGRATION_FAILURE]: (
    state: IElevatorIntegrationStore,
    error: IError
  ): IElevatorIntegrationStore => ({
    ...state,
    site: {
      ...state.site,
      error,
      isFetching: false,
    },
  }),

  [types.GET_SITE_ELEVATOR_INTEGRATION_SUCCESS]: (
    state: IElevatorIntegrationStore,
    payload: ISiteElevatorIntegration
  ): IElevatorIntegrationStore => ({
    ...state,
    site: {
      ...state.site,
      data: payload,
      isFetching: false,
    },
  }),

  [types.GET_SITE_ELEVATOR_INTEGRATION_REQUEST]: (state: IElevatorIntegrationStore): IElevatorIntegrationStore => ({
    ...state,
    site: {
      ...state.site,
      error: null,
      isFetching: true,
    },
  }),

  [types.SET_SITE_ELEVATOR_INTEGRATION_FAILURE]: (
    state: IElevatorIntegrationStore,
    error: IError
  ): IElevatorIntegrationStore => ({
    ...state,
    site: {
      ...state.site,
      set: {
        ...state.site.set,
        error,
        isFetching: false,
      },
    },
  }),
  [types.SET_SITE_ELEVATOR_INTEGRATION_SUCCESS]: (state: IElevatorIntegrationStore): IElevatorIntegrationStore => ({
    ...state,
    site: {
      ...state.site,
      set: {
        ...state.site.set,
        isFetching: false,
      },
    },
  }),

  [types.SET_SITE_ELEVATOR_INTEGRATION_REQUEST]: (state: IElevatorIntegrationStore): IElevatorIntegrationStore => ({
    ...state,
    site: {
      ...state.site,
      set: {
        ...state.site.set,
        error: null,
        isFetching: true,
      },
    },
  }),

  [types.GET_USER_ELEVATOR_INTEGRATION_FAILURE]: (
    state: IElevatorIntegrationStore,
    error: IError
  ): IElevatorIntegrationStore => ({
    ...state,
    user: {
      ...state.user,
      didInvalidate: false,
      error,
      isFetching: false,
    },
  }),
  [types.GET_USER_ELEVATOR_INTEGRATION_SUCCESS]: (
    state: IElevatorIntegrationStore,
    data: IUserElevatorIntegration
  ): IElevatorIntegrationStore => ({
    ...state,
    user: {
      ...state.user,
      data,
      didInvalidate: false,
      isFetching: false,
    },
  }),

  [types.GET_USER_ELEVATOR_INTEGRATION_REQUEST]: (state: IElevatorIntegrationStore): IElevatorIntegrationStore => ({
    ...state,
    user: {
      ...state.user,
      error: null,
      isFetching: true,
    },
  }),

  [types.SET_USER_ELEVATOR_INTEGRATION_FAILURE]: (
    state: IElevatorIntegrationStore,
    error: IError
  ): IElevatorIntegrationStore => ({
    ...state,
    user: {
      ...state.user,
      set: {
        ...state.user.set,
        error,
        isFetching: false,
      },
    },
  }),
  [types.SET_USER_ELEVATOR_INTEGRATION_SUCCESS]: (state: IElevatorIntegrationStore): IElevatorIntegrationStore => ({
    ...state,
    user: {
      ...state.user,
      didInvalidate: true,
      set: {
        ...state.user.set,
        isFetching: false,
      },
    },
  }),

  [types.SET_USER_ELEVATOR_INTEGRATION_REQUEST]: (state: IElevatorIntegrationStore): IElevatorIntegrationStore => ({
    ...state,
    user: {
      ...state.user,
      set: {
        ...state.user.set,
        error: null,
        isFetching: true,
      },
    },
  }),

  [types.GET_DEVICE_ELEVATOR_INTEGRATION_FAILURE]: (
    state: IElevatorIntegrationStore,
    error: IError
  ): IElevatorIntegrationStore => ({
    ...state,
    device: {
      ...state.device,
      didInvalidate: false,
      error,
      isFetching: false,
    },
  }),
  [types.GET_DEVICE_ELEVATOR_INTEGRATION_SUCCESS]: (
    state: IElevatorIntegrationStore,
    data: IDeviceElevatorIntegration
  ): IElevatorIntegrationStore => ({
    ...state,
    device: {
      ...state.device,
      data,
      didInvalidate: false,
      isFetching: false,
    },
  }),

  [types.GET_DEVICE_ELEVATOR_INTEGRATION_REQUEST]: (state: IElevatorIntegrationStore): IElevatorIntegrationStore => ({
    ...state,
    device: {
      ...state.device,
      error: null,
      isFetching: true,
    },
  }),

  [types.SET_DEVICE_ELEVATOR_INTEGRATION_FAILURE]: (
    state: IElevatorIntegrationStore,
    error: IError
  ): IElevatorIntegrationStore => ({
    ...state,
    device: {
      ...state.device,
      set: {
        ...state.device.set,
        error,
        isFetching: false,
      },
    },
  }),
  [types.SET_DEVICE_ELEVATOR_INTEGRATION_SUCCESS]: (state: IElevatorIntegrationStore): IElevatorIntegrationStore => ({
    ...state,
    device: {
      ...state.device,
      didInvalidate: true,
      set: {
        ...state.device.set,
        isFetching: false,
      },
    },
  }),

  [types.SET_DEVICE_ELEVATOR_INTEGRATION_REQUEST]: (state: IElevatorIntegrationStore): IElevatorIntegrationStore => ({
    ...state,
    device: {
      ...state.device,
      set: {
        ...state.device.set,
        error: null,
        isFetching: true,
      },
    },
  }),

  [types.GET_ELEVATOR_INTEGRATION_FLOOR_MAPPING_FAILURE]: (
    state: IElevatorIntegrationStore,
    error: IError
  ): IElevatorIntegrationStore => ({
    ...state,
    site: {
      ...state.site,
      floorMapping: {
        ...state.site.floorMapping,
        error,
        isFetching: false,
      },
    },
  }),
  [types.GET_ELEVATOR_INTEGRATION_FLOOR_MAPPING_SUCCESS]: (
    state: IElevatorIntegrationStore,
    data: IElevatorIntegrationFloorMapping
  ): IElevatorIntegrationStore => ({
    ...state,
    site: {
      ...state.site,
      floorMapping: {
        ...state.site.floorMapping,
        data,
        isFetching: false,
      },
    },
  }),

  [types.GET_ELEVATOR_INTEGRATION_FLOOR_MAPPING_REQUEST]: (
    state: IElevatorIntegrationStore
  ): IElevatorIntegrationStore => ({
    ...state,
    site: {
      ...state.site,
      floorMapping: {
        ...state.site.floorMapping,
        error: null,
        isFetching: true,
      },
    },
  }),
};

export function elevatorIntegrationReducers<TValue extends CommonTypes>(
  state = getInitialElevatorIntegrationStore(),
  action: IReduxAction<TValue>
): IElevatorIntegrationStore {
  if (reducers[action.type]) {
    if (action.error !== undefined) {
      return reducers[action.type](state, action.error);
    }
    return reducers[action.type](state, action.payload);
  }

  return state;
}
