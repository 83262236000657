import { defineMessages } from 'react-intl';

export default defineMessages({
  axisDoorStationFormMacAddressLabel: {
    defaultMessage: 'MAC address (i. e. the Serial Number)',
    description: 'Device Modal AxisDoorStation - mac address label',
    id: 'axisDoorStationForm.macAddress.label',
  },
  axisDoorStationFormMacAddressNoValid: {
    defaultMessage: 'Specified MAC address is invalid',
    description: 'Device Modal AxisDoorStation - mac address is not valid',
    id: 'axisDoorStationForm.macAddress.noValid',
  },
  axisDoorStationFormNameExample: {
    defaultMessage: 'We recommend naming the device after its location, such as Main' + ' Entrance.',
    description: 'Device Modal AxisDoorStation - name example',
    id: 'axisDoorStationForm.name.example',
  },
  axisDoorStationFormNameExist: {
    defaultMessage: 'This device name is already used.',
    description: 'Device Modal AxisDoorStation - name exist',
    id: 'axisDoorStationForm.name.exist',
  },
  axisDoorStationFormNameLabel: {
    defaultMessage: 'Device name',
    description: 'Device Modal AxisDoorStation - name label',
    id: 'axisDoorStationForm.name.label',
  },
  axisDoorStationFormRequired: {
    defaultMessage: 'Value is mandatory',
    description: 'Device Modal - required value',
    id: 'axisDoorStationForm.required',
  },
  axisDoorStationFormTooLong: {
    defaultMessage: 'Value is too long',
    description: 'Device Modal - value is too long',
    id: 'axisDoorStationForm.tooLong',
  },
  formActionBackBtn: {
    defaultMessage: 'Back',
    description: 'Device Modal Form action - back button',
    id: 'axisDoor.formAction.backBtn',
  },
  formActionCancelBtn: {
    defaultMessage: 'Cancel',
    description: 'Device Modal Form action - cancel button',
    id: 'axisDoor.formAction.cancelBtn',
  },
  formActionNextBtn: {
    defaultMessage: 'Next',
    description: 'Device Modal Form action - next button',
    id: 'axisDoor.formAction.next',
  },
  userSelectFieldDescriptionHipmo: {
    defaultMessage: `The authorization credentials for the mobile application
          will be sent to this user's e-mail address.`,
    description: 'Device Modal UserSelectField - hipmo description',
    id: 'userSelectField.description.hipmo',
  },
  userSelectFieldDescriptionThirdParty: {
    defaultMessage: "to this user's e-mail address.",
    description: 'Device Modal UserSelectField - third party description',
    id: 'userSelectField.description.thirdParty',
  },
  userSelectFieldLabel: {
    defaultMessage: 'Add to user',
    description: 'Device Modal UserSelectField - label',
    id: 'userSelectField.label',
  },
  userSelectFieldNewUserCreate: {
    defaultMessage: 'Create new user',
    description: 'Device Modal UserSelectField - Create new user button',
    id: 'userSelectField.newUser.create',
  },
  userSelectFieldNewUserEmail: {
    defaultMessage: 'E-mail',
    description: 'Device Modal UserSelectField - new user email label',
    id: 'userSelectField.newUser.email',
  },
  userSelectFieldNewUserFirstName: {
    defaultMessage: 'First name',
    description: 'Device Modal UserSelectField - new user first name label',
    id: 'axisDoor.userSelectField.newUser.firstName',
  },
  userSelectFieldNewUserLastName: {
    defaultMessage: 'Surname',
    description: 'Device Modal UserSelectField - new user last name label',
    id: 'axisDoor.userSelectField.newUser.lastName',
  },
  userSelectFieldUserLabel: {
    defaultMessage: 'Choose an existing user',
    description: 'Device Modal UserSelectField - user label',
    id: 'axisDoor.userSelectField.user.label',
  },
  userSelectFieldUserPlaceholder: {
    defaultMessage: 'User',
    description: 'Device Modal UserSelectField - user placeholder',
    id: 'axisDoor.userSelectField.user.placeholder',
  },
});
