import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { CURRENCY } from '../../../../config/consts';
import { BILLING_TYPE } from '../../../../config/subscription';
import { BillingType } from '../../../../modules/commonTypes';
import ActionsSection from '../ActionsSection';
import CreditInfo from '../CreditInfo';
import SectionHeader from '../SectionHeader';
import ActivationAgreement from './ActivationAgreement';
import CodeSummary from './CodeSummary';
import CreditSummaryAlert from './CreditSummaryAlert';
import messages from './messages';
import TotalValueBar from './TotalValueBar';

const TwoStep = ({
  codeCreditState,
  codeData,
  codeExpirationState,
  currency,
  isCodeActivationLoading,
  isMobileVideoAllowed,
  isVatVerified,
  mobileVideoLicense,
  onActivateCode,
  onStepChange,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleNextStep = () => {
    onStepChange((prevStep) => prevStep + 1);
    onActivateCode();
  };

  return (
    <Grid container spacing={2}>
      <Grid container direction="column" item md={8} spacing={2}>
        <Grid item>
          <SectionHeader variant="h6">
            <FormattedMessage {...messages.twoStepHeader} />
          </SectionHeader>
        </Grid>
        <Grid item>
          <TotalValueBar credits={codeData.configuration.credit} />
        </Grid>
        <Grid item>
          <Typography>
            <FormattedMessage {...messages.codeSummaryHeader} />
          </Typography>
        </Grid>
        <Grid container item spacing={2}>
          <Grid item md={6} xs={12}>
            <CodeSummary codeData={codeData} currency={currency} mobileVideoLicense={mobileVideoLicense} />
          </Grid>
          <Grid item md={6} xs={12}>
            {mobileVideoLicense.billingType === BILLING_TYPE.PER_APARTMENT &&
              codeData.activation.unlicensedCount !== 0 && <CreditSummaryAlert codeData={codeData} />}
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={4} xs={12}>
        <CreditInfo
          codeCreditState={codeCreditState}
          codeExpirationState={codeExpirationState}
          mobileVideoLicense={mobileVideoLicense}
        />
      </Grid>
      <Grid item xs={12}>
        <ActivationAgreement
          billingType={mobileVideoLicense.billingType}
          consumption={codeData.activation.initialConsumption}
          credit={codeData.configuration.credit}
          onSetCheck={() => setIsChecked(!isChecked)}
        />
      </Grid>
      <Grid container item justifyContent="space-between">
        <Grid item xs>
          <ActionsSection
            isLoading={isCodeActivationLoading}
            isMobileVideoAllowed={isMobileVideoAllowed}
            isNextStepDisabled={!isChecked}
            isVatVerified={isVatVerified}
            onNextStep={handleNextStep}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

TwoStep.propTypes = {
  codeCreditState: PropTypes.arrayOf(PropTypes.string).isRequired,
  codeData: PropTypes.shape({
    activation: PropTypes.shape({
      initialConsumption: PropTypes.number,
      licencesPool: PropTypes.number,
      unlicensedCount: PropTypes.number,
    }),
    configuration: PropTypes.shape({
      credit: PropTypes.number,
    }),
  }).isRequired,
  codeExpirationState: PropTypes.string.isRequired,
  currency: PropTypes.oneOf(Object.keys(CURRENCY)).isRequired,
  isCodeActivationLoading: PropTypes.bool.isRequired,
  isMobileVideoAllowed: PropTypes.bool.isRequired,
  isVatVerified: PropTypes.bool.isRequired,
  mobileVideoLicense: PropTypes.shape({
    billingType: PropTypes.oneOf(Object.keys(BillingType)),
  }).isRequired,
  onActivateCode: PropTypes.func.isRequired,
  onStepChange: PropTypes.func.isRequired,
};

export default TwoStep;
