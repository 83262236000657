import { TUuid } from '../commonTypes';
import { IRootStore } from '../store';
import { IUserDetail } from './store';

export const getUserSelector = ({ user }: IRootStore) => user;

export const getUserDetailUuid = ({ user }: IRootStore): TUuid => user.userDetail.data?.uuid || '';

export function getCurrentUserIsFetching({ user }: IRootStore): boolean {
  return user.isFetching;
}

export function didInvalidCurrentUser({ user }: IRootStore): boolean | undefined {
  return user.didInvalid;
}

export function didInvalidUserDetail({ user }: IRootStore): boolean | undefined {
  return user.userDetail.didInvalid;
}

export function didInvalidUserDetailRfid({ user }: IRootStore): boolean {
  return user.userRfidCard?.didInvalid ?? false;
}

export function didInvalidUserDetailMy2nAppStatus({ user }: IRootStore): boolean {
  return user.my2NAppAccessStatus?.didInvalid ?? false;
}

export function getUserDetail({ user }: IRootStore): IUserDetail | undefined {
  return user.userDetail?.data;
}
