import MailIcon from '@mui/icons-material/MailOutline';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FE_ROLES } from '../../config/consts';
import { IApartment } from '../../modules/apartments/store';
import { IUser } from '../../modules/user/store';
import UserIcon from '../UserIcon';

function getApartmentInfo(apartment: { number: string; name?: string }) {
  if (apartment?.name) {
    return `${apartment.number} – ${apartment.name}`;
  }
  return apartment.number;
}

function UserInfo({ apartments, user }: { user: IUser; apartments?: IApartment[] }) {
  let apartmentsList;
  if (apartments !== undefined) {
    apartmentsList = apartments;
  } else {
    apartmentsList = user.apartments;
  }

  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="row">
        <UserIcon color="secondary" sx={{ mr: 2 }} userRole={user.role} />
        <Typography fontWeight="bold" sx={{ pr: 0.5 }} variant="body1">
          {user.firstName ? `${user.firstName} ${user.lastName}` : user.lastName}
        </Typography>
        {apartmentsList.length > 0 && (
          <Typography sx={{ color: 'text.secondary' }} variant="body1">
            {`(${getApartmentInfo(apartmentsList[0])}) ${
              apartmentsList.length - 1 > 0 ? ` +${apartmentsList.length - 1}` : ''
            }`}
          </Typography>
        )}
      </Stack>
      {user.role !== FE_ROLES.LOGIN_LESS && (
        <Stack direction="row">
          <MailIcon color="secondary" sx={{ mr: 2 }} />
          <Typography variant="body1">{user.email}</Typography>
        </Stack>
      )}
    </Stack>
  );
}

export default UserInfo;
