import { defineMessages } from 'react-intl';

export default defineMessages({
  AccessSettingsOverviewAddGroupButton: {
    defaultMessage: 'Create access group',
    description: 'Add access group button title',
    id: 'accessSettingsOverview.addGroupButton',
  },
  AccessSettingsOverviewAddGroupButtonMobile: {
    defaultMessage: 'Access group',
    description: 'Add access group button title for mobile devices',
    id: 'accessSettingsOverview.addGroupButtonMobile',
  },
  AccessSettingsOverviewTitle: {
    defaultMessage: 'Access settings',
    description: 'Access Settings - title page',
    id: 'accessSettingsOverview.title',
  },
});
