import { defineMessages } from 'react-intl';

export default defineMessages({
  AAmodalDeleteUserPINCancel: {
    defaultMessage: 'Cancel',
    id: 'AAmodal.deleteUserPIN.cancel',
  },
  AAmodalDeleteUserPINMsgErr: {
    defaultMessage: 'There was an error, please try again',
    id: 'AAmodal.deleteUserPIN.msgErr',
  },
  AAmodalDeleteUserPINMsgSucc: {
    defaultMessage: 'PIN deleted successfully',
    id: 'AAmodal.deleteUserPIN.msgSucc',
  },
  AAmodalDeleteUserPINSubmit: {
    defaultMessage: 'Delete PIN',
    id: 'AAmodal.deleteUserPIN.submit',
  },
  AAmodalDeleteUserPINText: {
    defaultMessage:
      'When you delete the PIN code, it can no longer be used for access associated with this apartment.<p>If the PIN user is a member of other apartments they are not deleted from those.</p>',
    id: 'AAmodal.deleteUserPIN.text',
  },
  AAmodalDeleteUserPINTitle: {
    defaultMessage: 'Delete PIN',
    id: 'AAmodal.deleteUserPIN.title',
  },
});
