import { IRootStore } from '../store';
import { ICreateUWLWithPinModal, IUserDetail, IUsersInApartments } from './store';

export const getUsersInApartmentsSelector = ({ apartmentAdmin }: IRootStore): IUsersInApartments =>
  apartmentAdmin.usersInApartments;

export const getCreateUWLWithPinModalSelector = ({ apartmentAdmin }: IRootStore): ICreateUWLWithPinModal =>
  apartmentAdmin.createUWLWithPinModal;
export const getDevicesWithinApartmentSelector = ({
  apartmentAdmin,
}: IRootStore): IUserDetail['devicesWithinApartment'] => apartmentAdmin.userDetail.devicesWithinApartment;

export const getApartmentAccessGroupsSelector = ({
  apartmentAdmin,
}: IRootStore): IUserDetail['accessSettings']['apartmentGroups'] =>
  apartmentAdmin.userDetail.accessSettings.apartmentGroups;

export const getUserAccessGroupsSelector = ({
  apartmentAdmin,
}: IRootStore): IUserDetail['accessSettings']['userGroups'] => apartmentAdmin.userDetail.accessSettings.userGroups;

export const getUserPinSelector = ({ apartmentAdmin }: IRootStore): IUserDetail['accessSettings']['pin'] =>
  apartmentAdmin.userDetail.accessSettings.pin;

export const getUserRfidSelector = ({ apartmentAdmin }: IRootStore): IUserDetail['accessSettings']['rfid'] =>
  apartmentAdmin.userDetail.accessSettings.rfid;
