import RemoveIcon from '@mui/icons-material/RemoveCircleOutline';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import RowMenuItem from '../../../../components/DataGrid/RowMenuItem';
import messages from './messages';

const RemoveUser = ({ onClick }) => (
  <RowMenuItem
    icon={<RemoveIcon />}
    label={<FormattedMessage {...messages.accessGroupUsersDataGridDataGridRowMenuRemove} />}
    onClick={onClick}
  />
);

RemoveUser.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default RemoveUser;
