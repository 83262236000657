import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const styles = {
  root: {
    '& span': {
      fontWeight: 'bold',
      mr: 0.5,
    },
    alignItems: 'center',
    color: 'black',
    display: 'flex',
    fontSize: 24,
    textDecoration: 'none',
  },
};

const GTCTitle = ({ link, title }) => (
  <Typography component={Link} sx={styles.root} target="_blank" title={title} to={link} variant="inherit">
    <span>{title}</span>
    <KeyboardArrowRightIcon color="primary" fontSize="large" />
  </Typography>
);

GTCTitle.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default GTCTitle;
