import { configureStore, Middleware } from '@reduxjs/toolkit';
import { throttle } from 'lodash';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { AnyAction, applyMiddleware, CombinedState, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { ToolkitStore } from '@reduxjs/toolkit/dist/configureStore';
import { setApiKey } from '../api';
import { enhancedAccessControlApi } from '../apiRtk/accessControlApiEnhancer';
import { enhancedBe4FEApi } from '../apiRtk/be4feApiEnhancer';
import { elevatorIntegrationApi } from '../apiRtk/elevatorIntegrationApi';
import { gatewayApi } from '../apiRtk/gatewayApi';
import { enhancedLegacyFeApi } from '../apiRtk/legacyFeApiEnhancer';
import { selectors as authSelector } from './auth';
import { saveState } from './helpers';
import { getAllReducers } from './reducer';
import rootSaga from './saga';
import { IRootStore } from './store';
import { getPersistedStore, PersistKeys } from './storeHelpers';

const rootReducer = combineReducers({
  ...getAllReducers(),
});

const resetAbleRootReducer = (state: ReturnType<typeof rootReducer>, action: AnyAction) => {
  if (action.type === 'store/reset') {
    return rootReducer(undefined, action);
  }

  return rootReducer(state, action);
};

function createStore() {
  const sagaMiddleware = createSagaMiddleware();
  const elevatorIntegrationMiddleware: Middleware = elevatorIntegrationApi.middleware;
  const be4feMiddleware: Middleware = enhancedBe4FEApi.middleware;
  const accessControlMiddleware: Middleware = enhancedAccessControlApi.middleware;
  const gatewayMiddleware: Middleware = gatewayApi.middleware;
  const legacyFeMiddleware: Middleware = enhancedLegacyFeApi.middleware;
  const appliedMiddleware = applyMiddleware(sagaMiddleware);
  const composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });
  const enhancer = process.env.REDUX_DEBUG === '1' ? composeEnhancers(appliedMiddleware) : appliedMiddleware;
  const preloadedState = getPersistedStore();

  const store = configureStore({
    enhancers: [enhancer],
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
        serializableCheck: false,
      }).concat([
        elevatorIntegrationMiddleware,
        be4feMiddleware,
        accessControlMiddleware,
        gatewayMiddleware,
        legacyFeMiddleware,
      ]),
    preloadedState,
    reducer: resetAbleRootReducer,
  }) as ToolkitStore<CombinedState<IRootStore>>;

  store.subscribe(() => setApiKey(authSelector.getAccessTokenSelector(store.getState())));

  store.subscribe(
    throttle(() => {
      saveState({
        [PersistKeys.ACCESS_TOKEN]: store.getState().auth.accessToken,
        [PersistKeys.CURRENT_USER_DATA]: store.getState().user.data,
        [PersistKeys.CURRENT_USER_LANGUAGE]: store.getState().localization.currentUserLang,
      });
    }, 1000)
  );

  sagaMiddleware.run(rootSaga);

  return store;
}

export type RootState = ReturnType<typeof store.getState>;
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
export const store: ToolkitStore<CombinedState<IRootStore>> = createStore();
