import SettingsIcon from '@mui/icons-material/Settings';
import RenewIcon from '@mui/icons-material/SettingsBackupRestore';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import serviceRc from '../../../assets/service-rc.svg';
import { ConfirmDialog, RCModal, ServicePanel } from '../../../components';
import { SERVICE } from '../../../config/devices';
import messages from './messages';
import RemoteConfigurationStatus from './RemoteConfigurationStatus';

const RemoteConfigurationService = ({
  device,
  isRcActivable,
  isRCConfigurationDisabled,
  onActivateRemoteConfiguration,
  onCreateServiceConfiguration,
  onOpenRCModal,
  onRenewRemoteConfiguration,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const { active } = device?.services?.[SERVICE.REMOTE_CONFIGURATION] || false;

  const handleCancel = () => setIsVisible(false);
  const handleConfirm = () => {
    onRenewRemoteConfiguration(device.id);
    setIsVisible(false);
  };

  const handleActivate = () => {
    if (!device?.services?.[SERVICE.REMOTE_CONFIGURATION] && isRcActivable) {
      onCreateServiceConfiguration(device.id, SERVICE.REMOTE_CONFIGURATION);
    } else {
      onActivateRemoteConfiguration(device.id, !active);
    }
  };

  const getConfirmInfo = () =>
    active ? (
      <FormattedMessage {...messages.remoteConfigurationServiceActivationDisable} />
    ) : (
      <FormattedMessage {...messages.remoteConfigurationServiceActivationEnable} />
    );

  const getConfirmTitle = () =>
    active ? (
      <FormattedMessage {...messages.remoteConfigurationServiceActivationTitleDisable} />
    ) : (
      <FormattedMessage {...messages.remoteConfigurationServiceActivationTitleEnable} />
    );

  const getStateInfo = () => {
    if (device.hasDefaultPassword) {
      return <FormattedMessage tagName="strong" {...messages.DeviceDetailRemoteConfigStatusChangePassword} />;
    } else if (active) {
      return <FormattedMessage tagName="strong" {...messages.remoteConfigurationServiceStatusActive} />;
    } else if (!active) {
      return <FormattedMessage tagName="strong" {...messages.remoteConfigurationServiceStatusNotActive} />;
    }
  };

  return (
    <>
      <ConfirmDialog onCancel={handleCancel} onConfirm={handleConfirm} open={isVisible}>
        <Typography>
          <FormattedMessage {...messages.remoteConfigurationServiceModalText} />
        </Typography>
      </ConfirmDialog>
      <ServicePanel
        hasDefaultPassword={device.hasDefaultPassword}
        active={active}
        disabled={!device?.services?.[SERVICE.REMOTE_CONFIGURATION]}
        icon={<img alt="Remote configuration service" src={serviceRc} />}
        isServiceAvailable={isRcActivable}
        name={
          <FormattedMessage
            tagName="strong"
            values={{ sup: (chunks) => <sup>{chunks}</sup> }}
            {...messages.remoteConfigurationServiceRemoteConfiguration}
          />
        }
        onToggleConfirm={handleActivate}
        state={getStateInfo()}
        toggleConfirmInfo={<Typography>{getConfirmInfo()}</Typography>}
        toggleConfirmTitle={getConfirmTitle()}
      >
        <Grid container spacing={4}>
          <Grid item lg={4} md={6} xs={12}>
            <RemoteConfigurationStatus
              hasDefaultPassword={device.hasDefaultPassword}
              status={device?.services?.[SERVICE.REMOTE_CONFIGURATION]?.status || ''}
            />
            <Box pt={4}>
              <Button
                color="primary"
                data-gaid="deviceDetailOpenConfigurationBtn"
                disabled={isRCConfigurationDisabled}
                onClick={() => onOpenRCModal(device)}
                startIcon={<SettingsIcon />}
                variant="contained"
              >
                <FormattedMessage {...messages.remoteConfigurationServiceButtonsOpenConfiguration} />
              </Button>
            </Box>
          </Grid>
          <Grid item lg={4} md={6} xs={12}>
            <Button
              color="inherit"
              disabled={isRCConfigurationDisabled}
              onClick={() => setIsVisible(true)}
              startIcon={<RenewIcon color="primary" />}
              variant="contained"
            >
              <Box color={isRCConfigurationDisabled ? 'text.disabled' : 'primary.main'}>
                <FormattedMessage {...messages.remoteConfigurationServiceButtonsRenewDeviceServiceSettings} />
              </Box>
            </Button>
            <Box py={2}>
              <Typography color="textSecondary" variant="body2">
                <FormattedMessage {...messages.remoteConfigurationServiceButtonsRenewDeviceServiceHelperText} />
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </ServicePanel>
      <RCModal />
    </>
  );
};

RemoteConfigurationService.propTypes = {
  device: PropTypes.shape({
    hasDefaultPassword: PropTypes.bool,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    serialNumber: PropTypes.string,
    services: PropTypes.shape({
      [SERVICE.REMOTE_CONFIGURATION]: PropTypes.shape({
        hash: PropTypes.string,
        status: PropTypes.string,
      }),
    }),
    type: PropTypes.string,
  }).isRequired,
  isRcActivable: PropTypes.bool.isRequired,
  isRCConfigurationDisabled: PropTypes.bool.isRequired,
  onActivateRemoteConfiguration: PropTypes.func.isRequired,
  onCreateServiceConfiguration: PropTypes.func.isRequired,
  onOpenRCModal: PropTypes.func.isRequired,
  onRenewRemoteConfiguration: PropTypes.func.isRequired,
};

export default RemoteConfigurationService;
