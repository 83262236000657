import { defineMessages } from 'react-intl';

export default defineMessages({
  localCallsIdentifierFieldDeviceId: {
    defaultMessage: 'Device ID',
    description: 'Device ID',
    id: 'localCallsIdentifierField.deviceId',
  },
  localCallsIdentifierFieldHelper: {
    defaultMessage: 'Device ID = the name set on the device',
    description: 'Device ID - helper',
    id: 'localCallsIdentifierField.helper',
  },
});
