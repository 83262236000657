import { defineMessages } from 'react-intl';

export default defineMessages({
  userModalAddAdmin: {
    defaultMessage: 'Create new company admin',
    description: 'User modal - add new admin',
    id: 'userModal.add.admin',
  },
  userModalAddSiteUser: {
    defaultMessage: 'Create new site user',
    description: 'User modal - add new site user',
    id: 'userModal.add.siteUser',
  },
  userModalEditAdmin: {
    defaultMessage: 'Edit company admin',
    description: 'User modal - edit admin',
    id: 'userModal.edit.admin',
  },
  userModalEditProfile: {
    defaultMessage: 'Edit user profile',
    description: 'User modal - edit profile',
    id: 'userModal.edit.profile',
  },
  userModalEditSiteUser: {
    defaultMessage: 'Edit user profile',
    description: 'User modal - edit site user',
    id: 'userModal.edit.siteUser',
  },
  userModalEmailExistAdmin: {
    defaultMessage: 'Admin with this e-mail address already exists in this Company.',
    description: 'User modal - admin email exists',
    id: 'userModal.email.exist.admin',
  },
  userModalEmailExistUser: {
    defaultMessage: 'User with this e-mail address already exists in this Site.',
    description: 'User modal - user email exists',
    id: 'userModal.email.exist.user',
  },
  userModalEmailValid: {
    defaultMessage: 'Wrong format of the e-mail.',
    description: 'User modal - email is not valid',
    id: 'userModal.email.valid',
  },
  userModalFormActionsAdd: {
    defaultMessage: 'Add',
    description: 'User modal - add submit button',
    id: 'userModal.formActions.add',
  },
  userModalFormActionsCancel: {
    defaultMessage: 'Cancel',
    description: 'User modal - cancel button',
    id: 'userModal.formActions.cancel',
  },
  userModalFormActionsCreateCompanyAdmin: {
    defaultMessage: 'Create admin',
    description: 'User modal - create company admin submit button',
    id: 'userModal.formActions.createCompanyAdmin',
  },
  userModalFormActionsCreateSiteUser: {
    defaultMessage: 'Create user',
    description: 'User modal - create site user submit button',
    id: 'userModal.formActions.createSiteUser',
  },
  userModalFormActionsSave: {
    defaultMessage: 'Save changes',
    description: 'User modal - edit submit button',
    id: 'userModal.formActions.save',
  },
  userModalLabelEmail: {
    defaultMessage: 'User e-mail',
    description: 'User modal - email label',
    id: 'userModal.label.email',
  },
  userModalLabelFirstName: {
    defaultMessage: 'First name',
    description: 'User modal - first name label',
    id: 'userModal.label.firstName',
  },
  userModalLabelLastName: {
    defaultMessage: 'Surname',
    description: 'User modal - last name label',
    id: 'userModal.label.lastName',
  },
  userModalLabelNewsletters: {
    defaultMessage: 'I agree to receive occasional newsletters',
    description: 'User modal - newsletters label',
    id: 'userModal.label.newsletters',
  },
  userModalMaxLength: {
    defaultMessage: 'Name is too long.',
    description: 'User modal - maxLength value',
    id: 'userModal.maxLength',
  },
  userModalRequired: {
    defaultMessage: 'Value is mandatory',
    description: 'User modal - required value',
    id: 'userModal.required',
  },
});
