import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useUrlParams } from '../../helpers/urlParams';
import actions, { selectors } from '../../modules/accessSettings';
import userActions, { selectors as userSelectors } from '../../modules/user';
import UserAccessGroupsAlert from '../UserAccessGroupsAlert';
import UserAccessGroupsList from '../UserAccessGroupsList';
import UserAccessGroupsModal from '../UserAccessGroupsModal';
import UserAccessSettingsInfoModal from '../UserAccessSettingsInfoModal';
import UserApartmentsAccessGroupsList from '../UserApartmentsAccessGroupsList';
import UserApartmentsList from '../UserApartmentsList';

function SectionAccessGroups({ isRacActive }) {
  const dispatch = useDispatch();
  const { companyId, siteId, userId } = useUrlParams();
  const {
    apartments,
    apartmentsGroups,
    groups,
    hasApartmentRacDevice,
    isApartmentsGroupsLoading,
    isListInvalid,
    userRole,
  } = {
    apartments: useSelector(selectors.getActiveApartmentsList),
    apartmentsGroups: useSelector(selectors.getUserApartmentsAccessGroupsList),
    groups: useSelector(selectors.getUserAccessGroupsList),
    hasApartmentRacDevice: useSelector(selectors.hasUserApartmentRacDevice),
    isApartmentsGroupsLoading: useSelector(selectors.isUserApartmentsAccessGroupsListFetching),
    isListInvalid: useSelector(selectors.isUserAccessGroupsListInvalid),
    userRole: useSelector(userSelectors.getCurrentUserDetailRole),
  };

  function loadUserAccessGroups() {
    dispatch(actions.getUserAccessGroupsList(companyId, siteId, userId));
  }

  useEffect(() => {
    loadUserAccessGroups();
  }, []);

  useEffect(() => {
    if (isListInvalid) {
      loadUserAccessGroups();
    }
  }, [isListInvalid]);

  function showWarning() {
    const hasGroups = !!groups.length;
    const hasApartmentsGroups = !!apartmentsGroups.length;

    return (
      (hasGroups && !isRacActive) ||
      (hasApartmentsGroups && !isRacActive) ||
      (hasApartmentRacDevice && !isRacActive) ||
      (!hasGroups && !hasApartmentsGroups && !hasApartmentRacDevice && isRacActive)
    );
  }

  return (
    <>
      <Stack direction="column">
        {!isApartmentsGroupsLoading && showWarning() && (
          <Box pb={1}>
            <UserAccessGroupsAlert hasApartmentRacDevice={hasApartmentRacDevice} isRacActive={isRacActive} />
          </Box>
        )}
        <UserAccessGroupsList
          buttonAction={() => dispatch(userActions.accessGroupsModalOpen(userId))}
          groups={groups}
          userRole={userRole}
        />
        <UserApartmentsAccessGroupsList groups={apartmentsGroups} />
        {!!apartments.length && <UserApartmentsList apartments={apartments} />}
        <Stack alignItems="flex-start">
          <UserAccessSettingsInfoModal />
        </Stack>
      </Stack>
      <UserAccessGroupsModal userId={userId} />
    </>
  );
}

SectionAccessGroups.propTypes = {
  isRacActive: PropTypes.bool.isRequired,
};

export default SectionAccessGroups;
