import { IntlShape } from 'react-intl';
import * as Yup from 'yup';
import { headSiteData } from '../../../../api/siteRequest';
import { ROLES } from '../../../../config/consts';
import { TId } from '../../../../modules/commonTypes';
import messages from '../../../../validations/messages';
import {
  getBasicEmailValidation,
  getFirstNameValidation,
  getLastNameValidation,
  isMeetingBaseEmailValidation,
} from '../../../../validations/user/userCommonValidations';

export interface INewUserForm {
  userId: TId;
  email: string;
  firstName: string;
  lastName: string;
  existUser: boolean;
}

export function getNewUserValidationSchema(formatMessage: IntlShape['formatMessage'], companyId: TId, siteId: TId) {
  return {
    email: Yup.string().when('existUser', {
      is: false,
      otherwise: (schema) => schema.notRequired(),
      then: () =>
        getBasicEmailValidation(
          formatMessage(messages.createUserValidationSchemaInvalidEmail),
          formatMessage(messages.createUserValidationSchemaRequiredEmail)
        ).test({
          message: formatMessage(messages.createUserValidationSchemaExistsEmail),
          name: 'isExistEmail',
          test: async (email) => {
            let state = true;
            if (isMeetingBaseEmailValidation(email)) {
              await headSiteData({ companyId, siteId }, 'users', { email, roles: ROLES.USER }, false).then(
                (response) => {
                  state = response.status === 404;
                }
              );
            }

            return state;
          },
        }),
    }),
    firstName: Yup.string().when('existUser', {
      is: false,
      otherwise: (schema) => schema.notRequired(),
      then: () => getFirstNameValidation(true, formatMessage),
    }),
    lastName: Yup.string().when('existUser', {
      is: false,
      otherwise: (schema) => schema.notRequired(),
      then: () => getLastNameValidation(formatMessage),
    }),
  };
}
