import { all, call, put, SagaReturnType, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import * as oldApi from '../../api/userApi';
import * as Api from '../../api/userApiTS';
import { IUserInput } from '../../api/userApiTS';
import { TContext } from '../../components/UserDeleteModal/UserDeleteModal';
import { PATHS } from '../../config/consts';
import apartmentActions, { selectors as apartmentSelectors, types as apartmentTypes } from '../apartments';
import { addApartment } from '../apartments/actions';
import { TId, TPaginationParams, TUuid } from '../commonTypes';
import { selectors as companySelectors } from '../company';
import { ICompany } from '../company/store';
import { errorHelper, responseValidateStatusOrThrow } from '../helpers';
import { types as infraTypes } from '../infrastructure/types';
import { types as localizationTypes } from '../localization';
import { addSnackbarSuccess } from '../notifications/actions';
import { processError } from '../sagaHelpers';
import { selectors as siteSelectors } from '../sites';
import { ISite } from '../sites/store';
import { TUserActionContext, UserEditValues } from './actionsTS';
import { getRedirectPathForDeletedUser } from './helpersTS';
import { types } from './index';
import messages from './messages';
import { ICurrentUser, IUser, IUserBase } from './store';

export function* enableUserMy2NAppAccess(action: {
  type: string;
  my2NAppAccessState: boolean;
  companyId: TId;
  siteId: TId;
  userId: TId;
  context: TUserActionContext;
}) {
  const { companyId, context, my2NAppAccessState, siteId, userId } = action;
  try {
    const response: SagaReturnType<typeof Api.enableUserMy2NAppAccess> = my2NAppAccessState
      ? yield call(Api.enableUserMy2NAppAccess, { companyId, siteId }, userId)
      : yield call(Api.disableUserMy2NAppAccess, { companyId, siteId }, userId);

    yield responseValidateStatusOrThrow(response);
    yield put({
      context,
      type: types.ACTIVATE_USER_MY2N_APP_ACCESS_SUCCESS,
    });
    yield put(
      addSnackbarSuccess(
        my2NAppAccessState
          ? messages.userSnackbarMy2NAppAccessEnabledSuccessfully
          : messages.userSnackbarMy2NAppAccessDisabledSuccessfully
      )
    );
  } catch (error) {
    yield processError(types.ACTIVATE_USER_MY2N_APP_ACCESS_FAILURE, error);
  }
}

export function* getUserMy2NAppAccessStatus(action: { type: string; companyId: TId; siteId: TId; userId: TId }) {
  const { companyId, siteId, userId } = action;
  try {
    const response: SagaReturnType<typeof Api.getUserMy2NAppAccessStatus> = yield call(
      Api.getUserMy2NAppAccessStatus,
      { companyId, siteId },
      userId
    );

    yield responseValidateStatusOrThrow(response);
    yield put({
      payload: response.data,
      type: types.GET_USER_MY2N_APP_ACCESS_STATUS_SUCCESS,
    });
  } catch (error: any) {
    if (error.status === 404) {
      yield put({ payload: null, type: types.GET_USER_MY2N_APP_ACCESS_STATUS_SUCCESS });
    } else {
      yield processError(types.GET_USER_MY2N_APP_ACCESS_STATUS_FAILURE, error);
    }
  }
}

export function* getSiteUsersList(action: {
  companyId: TId;
  params: TPaginationParams;
  siteId: TId;
  type: string;
  page: number;
  filter: string;
  order: string;
  rowsPerPage: number;
}) {
  const { companyId, filter, order, page, params, rowsPerPage, siteId } = action;
  try {
    const response: SagaReturnType<typeof Api.getSiteUsersList> = yield call(
      Api.getSiteUsersList,
      { companyId, siteId },
      params
    );

    yield responseValidateStatusOrThrow(response);
    const { resultsFiltered, resultsTotal, users } = response.data;
    yield put({
      filter,
      order,
      page,
      payload: {
        data: users,
        resultsFiltered,
        resultsTotal,
      },
      rowsPerPage,
      type: types.GET_SITE_USERS_LIST_SUCCESS,
    });
  } catch (error) {
    yield processError(types.GET_SITE_USERS_LIST_FAILURE, error);
  }
}

export function* getUserPin(action: { type: string; companyId: TId; siteId: TId; userId: TId }) {
  try {
    const { companyId, siteId, userId } = action;
    const response: SagaReturnType<typeof Api.getUserPin> = yield call(Api.getUserPin, { companyId, siteId }, userId);
    yield responseValidateStatusOrThrow(response);
    yield put({ payload: response.data, type: types.GET_USER_PIN_SUCCESS });
  } catch (error: any) {
    if (error.status === 404) {
      yield put({ payload: null, type: types.GET_USER_PIN_SUCCESS });
    } else {
      yield processError(types.GET_USER_PIN_FAILURE, error);
    }
  }
}

export function* generateUserPin(action: { type: string; userId: TId }) {
  try {
    const { userId } = action;
    const companyId: TId = yield select(companySelectors.getCompanyId);
    const siteId: TId = yield select(siteSelectors.getSiteId);
    const response: SagaReturnType<typeof Api.generateUserPin> = yield call(
      Api.generateUserPin,
      { companyId, siteId },
      userId
    );
    yield responseValidateStatusOrThrow(response);
    yield put({ payload: response.data, type: types.GENERATE_USER_PIN_SUCCESS });
  } catch (error) {
    yield processError(types.GENERATE_USER_PIN_FAILURE, error);
  }
}

export function* saveUserPin(action: { type: string; userId: TId; pinId: TUuid; context: TUserActionContext }) {
  try {
    const { context, pinId, userId } = action;
    const companyId: TId = yield select(companySelectors.getCompanyId);
    const siteId: TId = yield select(siteSelectors.getSiteId);
    const response: SagaReturnType<typeof Api.saveUserPin> = yield call(
      Api.saveUserPin,
      { companyId, siteId },
      userId,
      pinId
    );
    yield responseValidateStatusOrThrow(response);
    yield put(addSnackbarSuccess(messages.userSnackbarPinSavedSuccessfully));
    yield put({ context, type: types.SAVE_USER_PIN_SUCCESS });
  } catch (error) {
    yield processError(types.SAVE_USER_PIN_FAILURE, error);
  }
}

export function* createUserRfid(action: {
  type: string;
  rfidCode: string;
  companyId: TId;
  siteId: TId;
  userId: TId;
  context: TUserActionContext;
}) {
  try {
    const { companyId, context, rfidCode, siteId, userId } = action;
    const response: SagaReturnType<typeof Api.createUserRfid> = yield call(
      Api.createUserRfid,
      { companyId, siteId },
      userId,
      rfidCode
    );
    yield responseValidateStatusOrThrow(response);
    yield put({ context, type: types.CREATE_USER_RFID_SUCCESS });
    yield put(addSnackbarSuccess(messages.userSnackbarRfidCardAssignedSuccessfully));
  } catch (error) {
    yield processError(types.CREATE_USER_RFID_FAILURE, error);
  }
}

export function* getUserRfid(action: { type: string; companyId: TId; siteId: TId; userId: TId }) {
  try {
    const { companyId, siteId, userId } = action;
    const response: SagaReturnType<typeof Api.getUserRfid> = yield call(Api.getUserRfid, { companyId, siteId }, userId);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({
      payload: Array.isArray(data) && data.length > 0 ? data[0] : null,
      type: types.GET_USER_RFID_CARD_SUCCESS,
    });
  } catch (error: any) {
    if (error.status === 404) {
      yield put({ payload: null, type: types.GET_USER_RFID_CARD_SUCCESS });
    } else {
      yield processError(types.GET_USER_RFID_CARD_FAILURE, error);
    }
  }
}

export function* deleteSiteUserRequest(action: { type: string; user: IUser; context: TContext }) {
  let response: SagaReturnType<typeof Api.deleteUser>;
  const { context, user } = action;
  const company: ICompany = yield select(companySelectors.getCompanyData);
  const site: ISite = yield select(siteSelectors.getSiteData);
  try {
    response = yield call(
      Api.deleteUser,
      {
        companyId: company.id,
        siteId: site.id,
      },
      user
    );
    yield responseValidateStatusOrThrow(response);
    yield put(addSnackbarSuccess(messages.userSnackbarRemovedSuccessfully));
    yield put({ context, type: types.DELETE_USER_SUCCESS });
    yield put({
      payload: {
        to: getRedirectPathForDeletedUser(company.id, site?.id),
      },
      type: infraTypes.SET_REDIRECT_DATA,
    });
  } catch (error) {
    yield processError(types.DELETE_USER_FAILURE, error, context);
  }
}

export function* editSiteUser(action: {
  type: string;
  context: TContext;
  isCurrentUser: boolean;
  values: UserEditValues;
}) {
  try {
    const { context, values } = action;
    const companyId: number | null = yield select(companySelectors.getCompanyId);
    const siteId: number | null = yield select(siteSelectors.getSiteId);
    const { apartments, newApartment } = values;
    const floors: { id: number; name: string }[] = yield select(apartmentSelectors.getFloors);

    if (newApartment && companyId && siteId) {
      yield put(addApartment(companyId, siteId, floors, newApartment, false));
      const addApartmentResponse: { payload: { id: number } } = yield take([apartmentTypes.ADD_APARTMENT_SUCCESS]);
      yield put(apartmentActions.getApartmentsList(companyId, siteId));
      apartments.push(addApartmentResponse?.payload?.id);
    }

    const editData: IUserInput = {
      apartments,
      email: values.email || undefined,
      firstName: values.firstName || undefined,
      lastName: values.lastName,
      locale: values.lang,
      role: values.role,
    };

    type Response = {
      status: number;
      data: {
        id: number;
        locale: string;
        uuid: string;
        apartments: number[];
        email: string;
        firstname: string;
        lastName: string;
        role: string;
      };
    };

    type TCurrentUserResponse = {
      status: number;
      data: ICurrentUser;
    };

    if (!companyId || !siteId) {
      Error('Company or site not found.');
      return;
    }

    const response: Response = yield call(Api.updateSiteUser, { companyId, siteId }, values.id, editData);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;

    if (values.id !== data.id) {
      yield put({
        payload: {
          to: PATHS.SITE_USER_DETAIL.replace(':companyId', companyId.toString())
            .replace(':siteId', siteId.toString())
            .replace(':userId', data.id.toString()),
        },
        type: infraTypes.SET_REDIRECT_DATA,
      });
    } else {
      yield put({ context, payload: data, type: types.SET_USER_DETAIL });
      yield put({ type: types.SET_APARTMENT_TO_USER_SUCCESS });
    }

    if (action.isCurrentUser) {
      const currentUserResponse: TCurrentUserResponse = yield call(oldApi.getUser, 'current');
      yield responseValidateStatusOrThrow(currentUserResponse);
      yield put({ payload: currentUserResponse.data, type: types.SET_IDENTITY });
      yield put({
        payload: currentUserResponse.data?.locale,
        type: localizationTypes.GET_CURRENT_USER_LANGUAGE_SUCCESS,
      });
    }

    yield put(addSnackbarSuccess(messages.userSnackbarDataSavedSuccessfully));
  } catch (error) {
    yield processError(types.USER_SAVE_FAILURE, error);
  }
}

export function* editUserPassword(action: { type: string; values: { currentUser: IUserBase; password: string } }) {
  try {
    const { currentUser, password } = action.values;
    const response: Response = yield call(oldApi.updatePassword, {
      action: 'PASSWORD_CHANGE',
      email: currentUser.email,
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
      password: password,
    });
    yield responseValidateStatusOrThrow(response);
    yield put(addSnackbarSuccess(messages.userSnackbarPasswordChangedSuccessfully));
    yield put({ type: types.EDIT_USER_PASSWORD_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.EDIT_USER_PASSWORD_FAILURE, error));
  }
}

export default function* userSagas() {
  yield all([
    takeLatest(types.GET_SITE_USERS_LIST_REQUEST, getSiteUsersList),
    takeEvery(types.DELETE_SITE_USER_REQUEST, deleteSiteUserRequest),
    takeLatest(types.ACTIVATE_USER_MY2N_APP_ACCESS_REQUEST, enableUserMy2NAppAccess),
    takeLatest(types.EDIT_USER_PASSWORD_REQUEST, editUserPassword),
    takeLatest(types.GET_USER_MY2N_APP_ACCESS_STATUS_REQUEST, getUserMy2NAppAccessStatus),
    takeLatest(types.GENERATE_USER_PIN_REQUEST, generateUserPin),
    takeLatest(types.GET_USER_PIN_REQUEST, getUserPin),
    takeLatest(types.SAVE_USER_PIN_REQUEST, saveUserPin),
    takeLatest(types.CREATE_USER_RFID_REQUEST, createUserRfid),
    takeLatest(types.GET_USER_RFID_CARD_REQUEST, getUserRfid),
    takeLatest(types.EDIT_SITE_USER_REQUEST, editSiteUser),
  ]);
}
