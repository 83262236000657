import { FolderShared, Label, LocationOn } from '@mui/icons-material';
import DialpadIcon from '@mui/icons-material/Dialpad';
import React from 'react';
import { IntlFormatters } from 'react-intl';
import videoServiceImage from '../../../../../assets/mvideo.svg';
import { UserIcon } from '../../../../../components';
import DevicesColumn from '../../../../../components/ApartmentDataGrid/DevicesColumn';
import Status from '../../../../../components/ApartmentDataGrid/Status';
import { CellContentComponentType } from '../../../../../components/DataGridAggregated/cellContentComponentsData';
import { IAggregatedColumn } from '../../../../../components/DataGridAggregated/data';
import { FE_ROLES } from '../../../../../config/consts';
import { ApartmentUserWithMVStatus, IAggregatedApartmentUsers } from '../../../../../modules/apartmentAdmin/data';
import { getApartmentContactLabel, getApartmentNumberAndName } from '../../../../../modules/apartments/utils';
import { getUserName } from '../../../../../modules/user/utils';

export function getColumns({
  formatMessage,
  upLg,
  upSm,
}: {
  upSm: boolean;
  upLg: boolean;
  formatMessage: IntlFormatters['formatMessage'];
}): IAggregatedColumn<IAggregatedApartmentUsers, ApartmentUserWithMVStatus>[] {
  return [
    {
      aggregatedRowContent: (apartment) => ({
        componentType: CellContentComponentType.IconAndName,
        data: {
          icon: <FolderShared />,
          name: getApartmentNumberAndName({ name: apartment.name, number: apartment.number }),
        },
      }),
      content: (user) => ({
        componentType: CellContentComponentType.IconAndName,
        data: {
          icon: user.role === FE_ROLES.APARTMENT_GUEST ? <DialpadIcon /> : <UserIcon userRole={user.role} />,
          name: getUserName({ firstName: user.firstName, lastName: user.lastName }),
        },
      }),
      flex: 1.5,
      id: 'Name',
    },
    {
      aggregatedRowContent: (apartment) => ({
        componentType: CellContentComponentType.IconAndName,
        data: { icon: <LocationOn />, name: apartment.site.name },
      }),
      content: (user) => ({
        componentType: CellContentComponentType.Email,
        data: { email: user.email, isEnabled: user.enabled },
      }),
      id: 'Email',
      isRendered: upLg,
    },
    {
      aggregatedRowContent: (apartment) => ({
        componentType: CellContentComponentType.IconAndName,
        data: { icon: <Label />, name: getApartmentContactLabel({ formatMessage, labels: apartment.contactLabels }) },
      }),
      content: (user) => ({
        componentType: CellContentComponentType.ApartmentUserRoleName,
        data: { apartmentUserRole: user.role, credentials: user.credentials },
      }),
      id: 'User Role',
      isRendered: upLg,
    },
    {
      aggregatedRowContent: (apartment) => <Status status={apartment.apartmentMobileVideoState} />,
      content: (user) => ({
        componentType: CellContentComponentType.ServiceItem,
        data: {
          active: user.mobileVideoActivated,
          image: videoServiceImage,
          service: 'MOBILE_VIDEO',
        },
      }),
      id: 'Service',
      isRendered: upSm,
    },
    {
      aggregatedRowContent: (apartment) => (
        <DevicesColumn
          apartmentDeviceLimit={apartment.paidDevicesLimit}
          devicesCount={apartment.devicesCount}
          isMDU={true}
          usageDevices={apartment.paidDevicesCount}
        />
      ),
      content: (user) => ({
        componentType: CellContentComponentType.UserAccessStatusIcon,
        data: {
          credentials: user.credentials,
          hiddenStatuses: ['BT'],
          isTimeLimitedAccess: !!(user.validFrom || user.validTo),
        },
      }),
      id: 'Access',
      isRendered: upSm,
    },
  ];
}
