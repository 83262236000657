import { useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { getOryErrorMessageText } from '../../errors/oryErrorMessages';
import { isOryErrorMessage, OryErrorMessageShowMethod, OrySuccess } from '../../types';
import {
  IFormAlertMessage,
  IParseOryResponseParams,
  IParseOryResponseWithDataParams,
  IUseOryResponseParser,
  OryResponseParserResult,
} from './types';

export function useOryResponseParser(): IUseOryResponseParser {
  const { formatMessage } = useIntl();
  const [formAlertMessage, setFormAlertMessage] = useState<IFormAlertMessage | undefined>();

  const parseOryResponse = <FormFields extends FieldValues>({
    onError,
    onSuccess,
    response,
    validationField,
  }: IParseOryResponseParams<FormFields>): OryResponseParserResult => {
    if (response === OrySuccess) {
      onSuccess && onSuccess();
      return true;
    }
    if (isOryErrorMessage(response)) {
      const message = getOryErrorMessageText(response.id, formatMessage, response.context);
      if (response.showMethod === OryErrorMessageShowMethod.FieldValidation && validationField) {
        validationField.formData.setError(validationField.fieldName, {
          message,
        });
      }
      if (response.showMethod === OryErrorMessageShowMethod.FormAlertNotification) {
        setFormAlertMessage({ context: response.context, id: response.id, message });
      }
      onError && onError(response);
      return false;
    }

    onError && onError();
    return false;
  };

  const getFormAlertMessage: IUseOryResponseParser['getFormAlertMessage'] = () => {
    return formAlertMessage;
  };

  const clearFormAlertMessage = () => {
    setFormAlertMessage(undefined);
  };

  const parseOryResponseWithData = <TData extends object, FormFields extends FieldValues>({
    onError,
    onSuccess,
    response,
    validationField,
  }: IParseOryResponseWithDataParams<TData, FormFields>): OryResponseParserResult => {
    if (typeof response === 'object' && !isOryErrorMessage(response)) {
      onSuccess(response);
      return true;
    }

    return parseOryResponse({ onError, onSuccess: () => {}, response, validationField });
  };

  return {
    clearFormAlertMessage,
    getFormAlertMessage,
    parseOryResponse,
    parseOryResponseWithData,
  };
}
