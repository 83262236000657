import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getCountCodes, getCountCodesByName } from '../DetailBatchDialog/helpers';
import { VIEW } from './consts';
import messages from './messages';
import SwitchPanelItem from './SwitchPanelItem';

const BatchSwitchPanel = ({ currentView, onSelectView, stats, views }) => {
  const allCodes = getCountCodes(stats);
  const created = getCountCodesByName(stats, 'CREATED');
  const used = getCountCodesByName(stats, 'USED');
  const cancelled = getCountCodesByName(stats, 'CANCELLED');
  return (
    <Paper
      sx={{
        background: 'secondary.light',
        boxShadow: 'none',
        mx: 0,
        my: 2,
      }}
    >
      <Tabs
        allowScrollButtonsMobile
        indicatorColor="primary"
        onChange={(_, value) => onSelectView(value)}
        scrollButtons
        textColor="primary"
        value={currentView}
      >
        {views.includes(VIEW.INFO) && (
          <SwitchPanelItem label={<FormattedMessage {...messages.switchPanelItemInfo} />} value={VIEW.INFO} />
        )}
        {views.includes(VIEW.ACTIVE) && (
          <SwitchPanelItem
            icon={<Chip color="primary" label={created} size="small" />}
            label={<FormattedMessage {...messages.switchPanelItemActive} />}
            value={VIEW.ACTIVE}
          />
        )}
        {views.includes(VIEW.USED) && (
          <SwitchPanelItem
            icon={
              <Chip
                label={used}
                size="small"
                sx={{
                  backgroundColor: 'success.main',
                  color: 'common.white',
                }}
              />
            }
            label={<FormattedMessage {...messages.switchPanelItemUsed} />}
            value={VIEW.USED}
          />
        )}
        {views.includes(VIEW.CANCELED) && (
          <SwitchPanelItem
            icon={<Chip label={cancelled} size="small" />}
            label={<FormattedMessage {...messages.switchPanelItemCanceled} />}
            value={VIEW.CANCELED}
          />
        )}
        {views.includes(VIEW.ALL) && (
          <SwitchPanelItem
            icon={<Chip color="primary" label={allCodes} size="small" />}
            label={<FormattedMessage {...messages.switchPanelItemAllCodes} />}
            value={VIEW.ALL}
          />
        )}
      </Tabs>
    </Paper>
  );
};

BatchSwitchPanel.propTypes = {
  currentView: PropTypes.oneOf(Object.values(VIEW)).isRequired,
  onSelectView: PropTypes.func.isRequired,
  stats: PropTypes.shape({
    CANCELLED: PropTypes.number,
    CREATED: PropTypes.number,
    USED: PropTypes.number,
  }).isRequired,
  views: PropTypes.arrayOf(PropTypes.oneOf(Object.values(VIEW))),
};

BatchSwitchPanel.defaultProps = {
  views: Object.values(VIEW),
};

export default BatchSwitchPanel;
