import { defineMessages } from 'react-intl';

export default defineMessages({
  apartmentRowMenuAddApartmentGroup: {
    defaultMessage: 'Set access groups',
    description: 'Apartments datagrid action menu - add group',
    id: 'apartmentRowMenu.addApartmentGroup',
  },
  apartmentRowMenuDeleteApartment: {
    defaultMessage: 'Delete apartment',
    description: 'Apartments datagrid action menu - delete apartment',
    id: 'apartmentRowMenu.deleteApartment',
  },
  apartmentRowMenuEdit: {
    defaultMessage: 'Edit apartment',
    description: 'Apartments datagrid action menu - edit apartment',
    id: 'apartmentRowMenu.edit',
  },
  apartmentRowMenuPurchase: {
    defaultMessage: 'Purchase',
    description: 'Apartments datagrid - purchase',
    id: 'apartmentRowMenu.purchase',
  },
  apartmentRowMenuUseAvailableCredit: {
    defaultMessage: 'Use available credit',
    description: 'Apartments datagrid - use available credit',
    id: 'apartmentRowMenu.useAvailableCredit',
  },
});
