import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { addSnackbarSuccess } from '../../modules/notifications/actions';
import messages from './messages';
import Panel from './Panel';

const styles = {
  buttons: {
    '& > button': {
      m: 0,
      ml: 1,
      px: 1.3,
    },
    textAlign: 'right',
    width: {
      lg: '100px',
    },
  },
  errorMessage: {
    color: 'error.main',
    pt: 1,
    textAlign: 'center',
  },
  rfid: {
    overflow: 'hidden',
    width: {
      lg: 'calc(100% - 100px)',
    },
  },
  rfidNormal: {
    fontWeight: 400,
    m: 0,
  },
  rfidSmall: {
    fontSize: 24,
    letterSpacing: 0,
    mt: 0.5,
  },
  root: {
    background: '#E5ECF0',
    border: '1px solid #CDD9E1',
    display: {
      lg: 'flex',
    },
    my: 2,
    p: 2,
    position: 'relative',
  },
  rootError: {
    borderColor: 'error.main',
  },
};

const PanelRfid = ({ error, rfidCode }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const onCopyToClipboard = (event) => {
    event.preventDefault();
    if (rfidCode && copy(rfidCode)) {
      dispatch(addSnackbarSuccess(messages.userAccessFormRfidCopied));
    }
  };

  return (
    <Panel errorMessage={formatMessage(messages.userAccessFormRfidErrorMessage)} isError={error}>
      <Box sx={styles.rfid}>
        <Typography sx={[styles.rfidNormal, rfidCode?.length > 10 ? styles.rfidSmall : {}]} variant="h4">
          {rfidCode}
        </Typography>
      </Box>
      <Box sx={styles.buttons}>
        <Button disabled={!rfidCode} onClick={onCopyToClipboard} startIcon={<FileCopyOutlinedIcon />}>
          <FormattedMessage {...messages.userAccessFormRfidCopy} />
        </Button>
      </Box>
    </Panel>
  );
};

PanelRfid.propTypes = {
  error: PropTypes.bool,
  rfidCode: PropTypes.string,
};

PanelRfid.defaultProps = {
  error: false,
  rfidCode: null,
};

export default PanelRfid;
