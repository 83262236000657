import './assets/fonts/my2n.scss';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { ThemeProvider } from '@2n/design-system';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import InfoIcon from '@mui/icons-material/Info';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import WarningIcon from '@mui/icons-material/Warning';
import CssBaseline from '@mui/material/CssBaseline';
import { Localization } from '@mui/material/locale';
import { LicenseInfo } from '@mui/x-license-pro';
import moment from 'moment';
import { SnackbarProvider } from 'notistack';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { IntlProvider } from 'react-intl';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ScrollToTop, ServiceAlert } from './components';
import { store } from './modules/configureStore';
import { selectors as localizationSelectors } from './modules/localization';
import { setIsFetchingMessages } from './modules/localization/actions';
import App from './pages/App';
import * as serviceWorker from './serviceWorker';
import { themeApplicationOptions, themeOptions } from './theme';
import { getDefaultRichTextElements, getMessagesByLocale, getMuiLocalizations } from './translations';

LicenseInfo.setLicenseKey(
  '69ea8cc5a6d08a90788b0ca71b3c54c1Tz05MTQyMyxFPTE3NDg0NjYwNTkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI='
);

function Index({ currentLanguage }: { currentLanguage: string }): JSX.Element {
  const [messages, setMessages] = useState<any>(undefined);
  const dispatch = useDispatch();

  useEffect(() => {
    async function getMessagesByCurrentLang() {
      const loadedMessages = await getMessagesByLocale(currentLanguage);
      moment.locale(currentLanguage);
      setMessages(loadedMessages);
      dispatch(setIsFetchingMessages(false));
    }

    dispatch(setIsFetchingMessages(true));
    getMessagesByCurrentLang();
  }, [currentLanguage]);

  if (messages === undefined) {
    return <>{false}</>;
  }

  return (
    <IntlProvider
      locale={currentLanguage}
      messages={messages}
      defaultRichTextElements={getDefaultRichTextElements()}
      onWarn={(warning: string) => {
        if (!warning.includes('pre-compiled')) {
          // eslint-disable-next-line no-console
          console.warn(warning);
        }
      }}
    >
      <BrowserRouter>
        <SnackbarProvider
          anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
          iconVariant={{
            error: <ErrorOutlineIcon sx={{ mr: 1 }} />,
            info: <InfoIcon sx={{ mr: 1 }} />,
            success: <TaskAltIcon sx={{ mr: 1 }} />,
            warning: <WarningIcon sx={{ mr: 1 }} />,
          }}
          maxSnack={3}
        >
          <ScrollToTop>
            <ServiceAlert show={false} />
            <App />
          </ScrollToTop>
        </SnackbarProvider>
      </BrowserRouter>
    </IntlProvider>
  );
}

const Providers = () => {
  const [muiLocalizations, setMuiLocalizations] = useState<Localization>({});
  const currentUserLanguage = useSelector(localizationSelectors.getCurrentUserLanguage);
  const currentLanguage = currentUserLanguage?.replace('_', '-') ?? 'en';

  useEffect(() => {
    setMuiLocalizations(getMuiLocalizations(currentLanguage));
  }, [currentUserLanguage]);

  return (
    <ThemeProvider
      theme="myToN"
      themeApplicationOptions={[{ ...themeApplicationOptions, ...muiLocalizations }]}
      themeOptions={themeOptions}
    >
      <CssBaseline />
      <Index currentLanguage={currentLanguage} />
    </ThemeProvider>
  );
};

ReactDOM.render(
  <Provider store={store}>
    <Providers />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
