import Grid from '@mui/material/Grid';
import React from 'react';
import { useModal } from '../../../hooks/useModal';
import SectionWrapper from '../SectionWrapper';
import About from './About';
import Detailed from './Detailed';
import HelpModal from './HelpModal';
import IntroWarning from './IntroWarning';
import Steps from './Steps';

function IntroStep() {
  const { Modal, onOpen } = useModal({ Modal: HelpModal });
  return (
    <SectionWrapper>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <IntroWarning />
        </Grid>
        <Grid container direction={{ md: 'row', xs: 'column' }} item spacing={2}>
          <Grid item xs>
            <About onHelpModalOpen={onOpen} />
          </Grid>
          <Grid item xs>
            <Steps />
          </Grid>
          <Grid item xs>
            <Detailed onHelpModalOpen={onOpen} />
          </Grid>
        </Grid>
      </Grid>
      <Modal />
    </SectionWrapper>
  );
}

export default IntroStep;
