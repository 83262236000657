import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import QRCodeReader from '../../components/QRCodeReader';
import { QR_CODE_DEVICE_MASK } from '../../config/devices';
import CloseButton from './CloseButton';
import CloseIcon from './CloseIcon';
import messages from './messages';
import QRScanArea from './QRScanArea';
import ScanAlerts from './ScanAlerts';

const styles = {
  content: {
    px: 3,
  },
  footer: {
    bottom: 0,
    position: 'fixed',
    px: 3,
    width: '100%',
    zIndex: 2,
  },
  header: {
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 2,
  },
  root: {
    backgroundColor: 'black',
    bottom: 0,
    left: 0,
    position: 'fixed',
    right: 0,
    top: 0,
    zIndex: 1,
  },
  title: {
    '& h6': {
      pb: 0.5,
    },
    color: 'white',
    pb: 4,
    textAlign: 'center',
  },
};

const DeviceMobileScanQR = ({ onClose, onSuccess }) => {
  const [error, setError] = useState('');

  const onScanSuccess = (decodedText) => {
    const match = decodedText?.match(QR_CODE_DEVICE_MASK);
    if (match && match.length >= 6) {
      onSuccess({
        securityCode: match[5],
        serialNumber: match[2],
      });
    }
  };

  return (
    <Box sx={styles.root}>
      <QRCodeReader
        error={error}
        onError={setError}
        onSuccess={onScanSuccess}
        videoStyle={{
          bottom: 0,
          left: 0,
          objectFit: 'cover',
          position: 'fixed',
          right: 0,
          top: 0,
          zIndex: -1,
        }}
      >
        <Box sx={styles.header}>
          <CloseIcon onClick={onClose} />
          <Box sx={styles.content}>
            <Box sx={styles.title}>
              <Typography variant="h6">
                <FormattedMessage {...messages.deviceMobileScanQRTitlesScanCode} />
              </Typography>
              <Typography variant="body2">
                <FormattedMessage {...messages.deviceMobileScanQRTitlesScanCodeDescription} />
              </Typography>
            </Box>
          </Box>
        </Box>

        <QRScanArea />

        <Box sx={styles.footer}>
          <ScanAlerts error={error} />
          <CloseButton onClick={onClose} />
        </Box>
      </QRCodeReader>
    </Box>
  );
};

DeviceMobileScanQR.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default DeviceMobileScanQR;
