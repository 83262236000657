import AddIcon from '@mui/icons-material/Add';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { DataGridActionButton, DataGridSearchField, DeviceDataGrid, SecurityCodeAlert, Title } from '../../components';
import { changeFilter, useButtonText } from '../../helpers/dataGrid';
import useBreakpoints from '../../helpers/useBreakpoints';
import { useGetLoadedParams } from '../../hooks/paramsHooks';
import { useModal } from '../../hooks/useModal';
import { ApplicationContext } from '../../modules/commonTypes';
import actions from '../../modules/devices';
import DeviceModal from '../DeviceModal';
import VerifyDeviceModal from '../VerifyDeviceModal';
import DefaultPasswordAlert from './DefaultPasswordAlert';
import { useGetDispatchActions, useGetStateData } from './deviceOverviewHooks';
import messages from './messages';
import WaitingDevicesDataGrid from './WaitingDevicesDataGrid';

export function DevicesOverview(): JSX.Element {
  const dispatchActions = useGetDispatchActions();
  const stateData = useGetStateData();
  const { companyId, siteId } = useGetLoadedParams(ApplicationContext.Site);
  const { downSm } = useBreakpoints();
  const [fulltext, setFulltext] = useState(stateData.devicesTable.filter.fulltext || '');

  const { Modal: DecoratedDeviceModal, onOpen } = useModal({
    Modal: DeviceModal,
  });

  useEffect(() => {
    if (companyId && siteId) {
      dispatchActions.onLoadNotVerifiedDevices(companyId, siteId);
      dispatchActions.onLoadDefaultPasswordDevices(companyId, siteId);
    }
  }, [companyId, siteId]);

  useEffect(() => {
    if (companyId && siteId && stateData.notVerifiedDevicesTable.didInvalid) {
      dispatchActions.onLoadNotVerifiedDevices(companyId, siteId);
    }
  }, [companyId, siteId, stateData.notVerifiedDevicesTable.didInvalid]);

  useEffect(() => {
    if (companyId && siteId && stateData.devicesTable.didInvalid) {
      dispatchActions.onLoadDefaultPasswordDevices(companyId, siteId);
    }
  }, [companyId, siteId, stateData.devicesTable.didInvalid]);

  const onChangeFilter = ({ target }: { target: { name: string; value: string } }) => {
    const { name, value } = target;
    setFulltext(value);
    changeFilter(dispatchActions.onSearch, actions.getDevicesListRequest, {
      ...stateData.devicesTable,
      companyId,
      siteId,
    })(name, value);
  };

  return (
    <>
      <Title
        heading={<FormattedMessage {...messages.DevicesOverviewTitle} />}
        rightSide={<DataGridSearchField fulltext={fulltext} onChangeFilter={onChangeFilter} />}
        rightSideActions={
          <DataGridActionButton onClick={onOpen} startIcon={<AddIcon />} variant={downSm ? 'text' : 'contained'}>
            {useButtonText(messages.DevicesOverviewAddDevice, messages.DevicesOverviewAddDeviceMobile)}
          </DataGridActionButton>
        }
      />
      {stateData.notVerifiedDevicesTable.data.length > 0 && (
        <SecurityCodeAlert
          companyId={companyId}
          devices={stateData.notVerifiedDevicesTable.data}
          onButtonClick={
            stateData.notVerifiedDevicesTable.data.length === 1
              ? (items) => dispatchActions.onVerifyDeviceShowDialog(items)
              : null
          }
          siteId={siteId}
          type="verify"
        />
      )}
      {stateData.hasDefaultPasswordDevices && <DefaultPasswordAlert />}
      <WaitingDevicesDataGrid
        {...stateData.waitingDevicesTable}
        companyId={companyId}
        onLoadDevices={dispatchActions.onLoadWaitingDevices}
        siteId={siteId}
      />
      <DeviceDataGrid
        {...stateData.devicesTable}
        companyId={companyId}
        onLoadDevices={(...callbackArgs: any[]) =>
          dispatchActions.onLoadDevices(
            callbackArgs,
            stateData.hasSiteElevatorIntegration ? 'elevator-integration' : undefined
          )
        }
        siteId={siteId}
      />
      <DecoratedDeviceModal />
      <VerifyDeviceModal companyId={companyId} siteId={siteId} />
    </>
  );
}
