import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import mvInfo01 from '../../assets/mv_info_01.svg';
import mvInfo02 from '../../assets/mv_info_02.svg';
import mvInfo03 from '../../assets/mv_info_03b.svg';
import mvInfo04 from '../../assets/mv_info_04.svg';
import phone from '../../assets/phone-my2n_app.png';
import LandingInfoService from './LandingInfoService';
import messages from './messages';

const styles = {
  content: {
    '& strong': {
      color: 'primary.main',
    },
    maxWidth: '1050px',
    mx: 'auto',
    my: 0,
    p: 1,
    pb: 1,
    position: 'relative',
    pt: 0,
    width: '100%',
  },
  root: (theme) => ({
    '& h1': {
      [theme.breakpoints.down('md')]: {
        fontSize: '36px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '32px',
      },
      color: 'text.default',
      fontSize: '36px',
      fontWeight: 'regular',
      my: 0,
    },
    bgcolor: 'background.default',
    lineHeight: 1.5,
    minHeight: '170px',
    position: 'relative',
    pt: 6,
    textAlign: 'center',
    width: '100%',
  }),
  services: (theme) => ({
    [theme.breakpoints.down('lg')]: {
      maxWidth: '1050px',
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      pb: 3,
    },
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '1200px',
    mx: 'auto',
    my: 0,
    p: 2,
    pb: 5,
    pt: 0,
    width: '100%',
  }),
  servicesImage: (theme) => ({
    [theme.breakpoints.down('lg')]: {
      width: '130px',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    '& img': {
      maxWidth: '100%',
    },
  }),
  servicesItem: {
    alignItems: 'center',
    display: 'flex',
    flexBasis: 'auto',
    flexDirection: 'column',
    flexGrow: 1,
    flexShrink: 0,
    justifyContent: 'center',
  },
};

const LandingInfo = ({ ref3thParty, refIntercomAndMobilePhone, refRealtimeSynchronization, refSecureCloudService }) => {
  const onScroll = (reference) => (event) => {
    event.preventDefault();
    reference.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.content}>
        <h1>
          <FormattedMessage {...messages.mvideoLandingInfoTitle} values={{ sup: (text) => <sup>{text}</sup> }} />
        </h1>
      </Box>
      <Box sx={styles.services}>
        <Box sx={styles.servicesItem}>
          <LandingInfoService
            image={<img alt="Reliable and secure cloud service" src={mvInfo01} />}
            onClick={onScroll(refSecureCloudService)}
            text={<FormattedMessage {...messages.mvideoLandingInfoServicesInfo01} />}
          />
          <LandingInfoService
            image={<img alt="Real-time synchronization" src={mvInfo02} />}
            onClick={onScroll(refRealtimeSynchronization)}
            text={<FormattedMessage {...messages.mvideoLandingInfoServicesInfo03} />}
          />
        </Box>
        <Box sx={styles.servicesImage}>
          <img alt="My2N Mobile app" src={phone} />
        </Box>
        <Box sx={styles.servicesItem}>
          <LandingInfoService
            image={<img alt="All you need is an intercom and a mobile phone" src={mvInfo03} />}
            onClick={onScroll(refIntercomAndMobilePhone)}
            text={<FormattedMessage {...messages.mvideoLandingInfoServicesInfo02} />}
          />
          <LandingInfoService
            image={<img alt="Integration with 3rd party smart home apps" src={mvInfo04} />}
            onClick={onScroll(ref3thParty)}
            text={<FormattedMessage {...messages.mvideoLandingInfoServicesInfo04} />}
          />
        </Box>
      </Box>
    </Box>
  );
};

LandingInfo.propTypes = {
  ref3thParty: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
    .isRequired,
  refIntercomAndMobilePhone: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  refRealtimeSynchronization: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  refSecureCloudService: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
};

export default LandingInfo;
