import { defineMessages } from 'react-intl';

export default defineMessages({
  accessLogCheckboxInfo: {
    defaultMessage: 'Data are stored in My2N for 90 days only',
    description: 'Access log checkbox - info',
    id: 'accessLogCheckbox.info',
  },
  accessLogCheckboxLabel: {
    defaultMessage: 'Logging of access via this device',
    description: 'Access log checkbox - label',
    id: 'accessLogCheckbox.label',
  },
});
