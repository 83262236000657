import * as yup from 'yup';
import { headSiteData } from '../api/siteRequest';
import { ROLES } from '../config/consts';
import messages from './messages';
import { isMeetingBaseEmailValidation } from './user/userCommonValidations';

export function getUserValidationSchema(companyId, siteId, formatMessage) {
  return {
    email: yup
      .string()
      .required(formatMessage(messages.createUserValidationSchemaRequiredEmail))
      .email(formatMessage(messages.createUserValidationSchemaInvalidEmail))
      .test({
        message: formatMessage(messages.createUserValidationSchemaExistsEmail),
        name: 'isExistEmail',
        test: async (email) => {
          let state = true;
          if (isMeetingBaseEmailValidation(email)) {
            await headSiteData({ companyId, siteId }, 'users', { email, roles: ROLES.USER }, false).then((response) => {
              state = response.status === 404;
            });
          }

          return state;
        },
      }),
    firstName: yup
      .string()
      .required(formatMessage(messages.createUserValidationSchemaRequiredFirstName))
      .max(255, formatMessage(messages.createUserValidationSchemaMaxLengthFirstName))
      .matches(/^(?!\s+$)/, formatMessage(messages.createUserValidationSchemaInvalidValue)),
    lastName: yup
      .string()
      .required(formatMessage(messages.createUserValidationSchemaRequiredLastName))
      .max(255, formatMessage(messages.createUserValidationSchemaMaxLengthLastName))
      .matches(/^\s*\S.*$/, formatMessage(messages.createUserValidationSchemaInvalidValue)),
  };
}
