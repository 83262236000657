import CheckIcon from '@mui/icons-material/Check';
import React from 'react';
import { useIntl } from 'react-intl';
import { ActionModal } from '../_DesignSystemWrappers/Modal';
import messages from './messages';

interface IActivateIntegrationModal {
  onClose: () => void;
  open: boolean;
  onSubmit: () => void;
}

function ActivateIntegrationModal({ onClose, onSubmit, open }: IActivateIntegrationModal) {
  const { formatMessage } = useIntl();

  function handleSubmit() {
    onClose();
    onSubmit();
  }

  return (
    <ActionModal
      onClose={onClose}
      open={open}
      muiDialogProps={{ maxWidth: 'xs' }}
      title={formatMessage({ ...messages.elevatorIntegrationModalActivateTitle })}
      secondaryButtonText={formatMessage({ ...messages.elevatorIntegrationModalActivateCancel })}
      primaryButtonText={formatMessage({ ...messages.elevatorIntegrationModalActivateSubmit })}
      primaryButtonAction={handleSubmit}
      icon={<CheckIcon />}
      isPrimaryButtonDisabled={false}
    >
      {formatMessage({ ...messages.elevatorIntegrationModalActivateText1 })}
    </ActionModal>
  );
}

export default ActivateIntegrationModal;
