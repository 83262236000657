import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import actions, { selectors } from '../../modules/accessSettings';
import DangerModal from '../DangerModal';
import messages from './messages';

const AccessGroupApartmentRemoveModal = ({ apartmentId, apartmentName, onClose, open, users }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const groupName = useSelector(selectors.getAccessGroupName);
  const isLoading = useSelector(selectors.getIsRemoveAccessGroupApartmentFetching);

  const handleRemoveApartment = () => {
    dispatch(actions.removeAccessGroupApartment(apartmentId));
    onClose();
  };

  const getIsOpen = useMemo(() => open || isLoading, [open, isLoading]);

  return (
    getIsOpen && (
      <DangerModal
        actionButtonLabel={formatMessage(messages.accessGroupApartmentRemoveModalRemove)}
        isLoading={isLoading}
        name="access-group-apartment-remove-modal"
        onClose={onClose}
        onSubmit={handleRemoveApartment}
        open={getIsOpen}
        title={formatMessage(messages.accessGroupApartmentRemoveModalTextTitle)}
      >
        <Typography component="span" variant="body1">
          <FormattedMessage
            {...(users
              ? messages.accessGroupApartmentRemoveModalTextUsers
              : messages.accessGroupApartmentRemoveModalTextNoUsers)}
            values={{
              apartmentName,
              groupName,
              p: (chunks) => <p>{chunks}</p>,
              strong: (chunks) => <strong>{chunks}</strong>,
              users,
            }}
          />
        </Typography>
      </DangerModal>
    )
  );
};

AccessGroupApartmentRemoveModal.propTypes = {
  apartmentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  apartmentName: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  users: PropTypes.number,
};

AccessGroupApartmentRemoveModal.defaultProps = {
  apartmentId: '',
  apartmentName: '',
  users: 0,
};

export default AccessGroupApartmentRemoveModal;
