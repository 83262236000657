import currencyJs from 'currency.js';
import PropTypes from 'prop-types';
import { CURRENCY } from '../../config/consts';

const USD = (value) =>
  currencyJs(value, {
    decimal: ',',
    formatWithSymbol: true,
    precision: 2,
    separator: ' ',
    symbol: '$ ',
  });

const EUR = (value) =>
  currencyJs(value, {
    decimal: ',',
    formatWithSymbol: true,
    precision: 2,
    separator: ' ',
    symbol: '€ ',
  });

const PriceFormat = ({ currency, price }) => {
  let value;
  if (currency === CURRENCY.USD) {
    value = USD(price);
  } else if (currency === CURRENCY.EUR) {
    value = EUR(price);
  } else {
    return null;
  }

  return value.format(true);
};

PriceFormat.propTypes = {
  currency: PropTypes.oneOf([CURRENCY.EUR, CURRENCY.USD]).isRequired,
  price: PropTypes.number,
};

PriceFormat.defaultProps = {
  price: 0,
};

export default PriceFormat;
