import { createSelector } from 'reselect';
import { CURRENCY } from '../../config/consts';

export const getCompanyData = createSelector(
  ({ company }) => company.data,
  (data) => data
);
export const getCompanyId = createSelector(getCompanyData, (data) => data?.id ?? undefined);
export const getCompanyName = (state) => getCompanyData(state)?.name || '';
export const getCurrentCompanyCurrencySelector = (state) => getCompanyData(state)?.currency || CURRENCY.EUR;
export const getCurrentCompanyRoles = (state) => getCompanyData(state)?.roles || {};
export const getMvSdkSettingsIsDefault = (state) => state.company.mvSdk.isDefault;
export const getMvSdkSettingsIsLoading = (state) => state.company.mvSdk.isFetching;
export const getMvSdkSettingsSelector = ({ company }) => ({
  authKey: company.mvSdk.data?.apnConfiguration?.authKey || '',
  bundleId: company.mvSdk.data?.apnConfiguration?.bundleId || '',
  databaseName: company.mvSdk.data?.fcmConfiguration?.databaseName || '',
  keyId: company.mvSdk.data?.apnConfiguration?.keyId || '',
  serviceJson: company.mvSdk.data?.fcmConfiguration?.serviceJson || '',
  teamId: company.mvSdk.data?.apnConfiguration?.teamId || '',
});
export const isCompanyFetching = ({ company }) => company.isFetching;

export const isCompanyError = (state) => !!state.company.error;

export const didInvalid = (state) => state.company.isDidInvalid;
