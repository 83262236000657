import { Done } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

export interface ISaveChangesBtnProps {
  onClick?: () => void;
  disabled?: boolean;
  isInline?: boolean;
}

export function SaveChangesBtn(props: ISaveChangesBtnProps) {
  return (
    <Box marginTop={2} component={props.isInline ? 'span' : undefined}>
      <Button
        color="primary"
        disabled={props.disabled}
        startIcon={<Done />}
        type="submit"
        variant="contained"
        onClick={props.onClick}
      >
        <FormattedMessage {...messages.buttonsSaveChanges} />
      </Button>
    </Box>
  );
}
