import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { animateScroll, Link } from 'react-scroll';
import messages from './messages';

const styles = {
  '& a.active': {
    color: 'primary.main',
    fontWeight: 'bold',
  },
  '& a:hover, dd span:hover': {
    color: 'primary.main',
    cursor: 'pointer',
  },
  '& dd': {
    marginInlineStart: 0,
    pt: 2,
    userSelect: 'none',
  },
};

const SidebarMenu = ({ data }) => (
  <Box component="dl" sx={styles}>
    <dd key="sidebar-heading">
      <Box
        color="secondary.main"
        component="span"
        fontSize="1.5rem"
        onClick={() => animateScroll.scrollToTop({ duration: 1000, offset: -70, smooth: true })}
      >
        <FormattedMessage {...messages.gtcPageDetailCompanySidebarMenuHeading} />
      </Box>
    </dd>
    {data.map((item, index) => (
      <dd key={`sidebar-item-${index + 1}`}>
        <Link activeClass="active" duration={1000} offset={-70} smooth spy to={item.scrollLink}>
          {item.title}
        </Link>
      </dd>
    ))}
  </Box>
);

SidebarMenu.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      scrollLink: PropTypes.string,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    })
  ).isRequired,
};

export default SidebarMenu;
