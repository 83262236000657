import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import getFilteredCountries from '../../../../config/countries';
import messages from '../../messages';

const styles = {
  billingInfo: {
    fontWeight: 'bold',
  },
  companyInfoWrapper: {
    mb: 0.75,
  },
  title: {
    color: 'text.secondary',
    whiteSpace: 'break-spaces',
  },
};

const BillingCompany = ({ company, countriesList }) => (
  <Grid alignItems="baseline" container direction="row" justifyContent="flex-start" sx={styles.companyInfoWrapper}>
    <Grid item sm={4} xs={12}>
      <Typography sx={styles.title} variant="subtitle2">
        <FormattedMessage {...messages.billingInfoCompany} />
      </Typography>
    </Grid>
    <Grid container direction="column" item sm={6} xs={12}>
      <Grid item>
        <Typography id="companyname" sx={styles.billingInfo} variant="body2">
          {company.name}
        </Typography>
      </Grid>
      <Grid item>
        <Typography id="street" sx={styles.billingInfo} variant="body2">
          {`${company.address.postalCode} ${company.address.street}`.trim()}
        </Typography>
      </Grid>
      <Grid item>
        <Typography id="city" sx={styles.billingInfo} variant="body2">
          {company.address.city}
        </Typography>
      </Grid>
      <Grid item>
        <Typography id="country" sx={styles.billingInfo} variant="body2">
          {countriesList.find((country) => country.key === company.address.country).name}
        </Typography>
      </Grid>
    </Grid>
  </Grid>
);

BillingCompany.propTypes = {
  company: PropTypes.shape({
    address: PropTypes.shape({
      city: PropTypes.string,
      country: PropTypes.string,
      postalCode: PropTypes.string,
      street: PropTypes.string,
    }),
    name: PropTypes.string,
  }).isRequired,
  countriesList: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    })
  ),
};

BillingCompany.defaultProps = {
  countriesList: getFilteredCountries,
};

export default BillingCompany;
