import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Paper } from '../../../components';
import { IMPORT_USERS_ALERT_COOKIE } from '../../../config/consts';
import { useCookie } from '../../../helpers/cookies';
import { useUrlParams } from '../../../helpers/urlParams';
import { useModal } from '../../../hooks/useModal';
import actions from '../../../modules/user';
import ContentSwitch from '../ContentSwitch';
import Control from '../Control';
import { useImportDataContext } from '../ImportDataProvider';
import BlockModal from '../ProcessingImport/BlockModal';
import ImportModal from '../ProcessingImport/ImportModal';
import Stepper from '../Stepper';

function Content() {
  const dispatch = useDispatch();
  const { companyId, siteId } = useUrlParams();
  const [activeStep, setActiveStep] = useState(0);
  const { setFunctionalCookie } = useCookie();
  const { importData } = useImportDataContext();
  const { Modal: ErrorModal, onOpen: blockModalOnOpen } = useModal({
    Modal: BlockModal,
  });
  const { Modal: ProgressModal, onOpen: progressModalOnOpen } = useModal({
    Modal: ImportModal,
  });

  function startImport() {
    dispatch(
      actions.importUsersStart(
        companyId,
        siteId,
        importData,
        () => {
          setFunctionalCookie(IMPORT_USERS_ALERT_COOKIE, true);
          progressModalOnOpen();
        },
        () => {
          blockModalOnOpen();
        }
      )
    );
  }

  return (
    <>
      <Paper tabTable>
        <Stack direction="column">
          <Box pb={2} px={2}>
            <Stepper activeStep={activeStep} />
          </Box>
          <Divider />
          <Box>
            <Box pb={2}>
              <ContentSwitch activeStep={activeStep} />
            </Box>
            <Box pb={2} px={2}>
              <Control activeStep={activeStep} onSetStep={setActiveStep} startImport={startImport} />
            </Box>
          </Box>
        </Stack>
      </Paper>
      <ProgressModal setActiveStep={() => setActiveStep(4)} />
      <ErrorModal />
    </>
  );
}

export default Content;
