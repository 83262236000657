import Box from '@mui/material/Box';
import { DataGrid as MuiDataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import * as React from 'react';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import useBreakpoints from '../../helpers/useBreakpoints';
import { defineRowClassName, handleSortModelChange } from './dataGridHelpers';
import DefaultNoRowsOverlay from './DefaultNoRowsOverlay';
import messages from './messages';
import Pagination from './Pagination';

const styles = {
  root: {
    '& .clickableRow': {
      cursor: 'pointer',
    },
    '& .disabledRow': {
      color: 'text.disabled',
    },
    backgroundColor: 'common.white',
    width: '100%',
  },
};

const DataGrid = ({
  columns,
  components,
  componentsProps,
  customToolbar,
  defaultRowsNumber,
  getRowClassName,
  hideFooterPagination,
  loading,
  noRowsOverlay,
  onOrderBy,
  onRowsPerPageChange,
  onSelectPage,
  order,
  page,
  resultsFiltered,
  resultsTotal,
  rows,
  rowsPerPage,
  ...props
}) => {
  const { downSm } = useBreakpoints();

  const sortingFieldName = useMemo(() => {
    if (order !== null && order[0] === '-') {
      return order.substring(1, order.length);
    }
    return order;
  }, [order]);

  const [sortModel, setSortModel] = React.useState([
    { field: sortingFieldName, sort: order !== null && order[0] === '-' ? 'desc' : 'asc' },
  ]);

  const defaultRows = [...Array(defaultRowsNumber)].map((el, i) => ({
    id: i,
  }));

  return (
    <Box sx={styles.root}>
      <MuiDataGrid
        {...props}
        autoHeight
        columns={columns}
        getRowClassName={(params) => defineRowClassName(params, getRowClassName)}
        hideFooterPagination={hideFooterPagination || resultsFiltered === 0}
        loading={false}
        localeText={{
          footerRowSelected: (count) => (
            <FormattedMessage
              {...messages.DataGridFooterRows}
              values={{
                count,
              }}
            />
          ),
        }}
        onSortModelChange={(newModel) => handleSortModelChange(onOrderBy, setSortModel, sortModel, newModel)}
        rows={loading ? defaultRows : rows}
        slotProps={{
          ...componentsProps,
          pagination: {
            colSpan: columns.length,
            labelRowsPerPage: (
              <FormattedMessage
                {...(downSm ? messages.DataGridMobilePaginationLabel : messages.DataGridDesktopPaginationLabel)}
              />
            ),
            onRowsPerPageChange,
            onSelectPage,
            page,
            resultsFiltered,
            resultsTotal,
            rowsPerPage,
          },
        }}
        slots={{
          ...components,
          noRowsOverlay: noRowsOverlay ? () => noRowsOverlay : DefaultNoRowsOverlay,
          pagination: Pagination,
          toolbar: customToolbar,
        }}
        sortingMode="server"
        sortModel={sortModel}
        sx={{
          ...(rows?.length &&
            !loading && {
              '& .MuiDataGrid-overlay': {
                height: 'fit-content !important',
                position: 'relative !important',
              },
              '& .MuiDataGrid-row, .MuiDataGrid-cell': {
                maxHeight: 'fit-content !important',
              },
              '& .MuiDataGrid-virtualScrollerContent': {
                height: 'auto !important',
              },
              '& .MuiDataGrid-virtualScrollerRenderZone': {
                maxHeight: 'fit-content !important',
                position: 'relative !important',
              },
            }),
          ...(!rows?.length &&
            !loading && {
              '& .MuiDataGrid-main div:first-of-type': {
                position: 'relative !important',
              },
              '& .MuiDataGrid-overlayWrapper, & .MuiDataGrid-overlayWrapperInner': {
                height: 'fit-content !important',
              },
              '& .MuiDataGrid-virtualScrollerContent': {
                display: 'none',
              },
            }),
        }}
      />
    </Box>
  );
};

DataGrid.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  components: PropTypes.shape({}),
  componentsProps: PropTypes.shape({}),
  customToolbar: PropTypes.node,
  defaultRowsNumber: PropTypes.number,
  getRowClassName: PropTypes.func,
  hideFooterPagination: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  noRowsOverlay: PropTypes.node,
  onOrderBy: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  onSelectPage: PropTypes.func,
  order: PropTypes.string,
  page: PropTypes.number,
  resultsFiltered: PropTypes.number,
  resultsTotal: PropTypes.number,
  rows: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rowsPerPage: PropTypes.number,
};

DataGrid.defaultProps = {
  components: {},
  componentsProps: {},
  customToolbar: null,
  defaultRowsNumber: 7,
  getRowClassName: undefined,
  noRowsOverlay: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onOrderBy: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onRowsPerPageChange: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSelectPage: () => {},
  order: '',
  page: 1,
  resultsFiltered: 0,
  resultsTotal: 0,
  rowsPerPage: 10,
};

export default DataGrid;
