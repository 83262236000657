import { defineMessages } from 'react-intl';

export default defineMessages({
  AvailableCreditModalCancelBtn: {
    defaultMessage: 'cancel',
    description: 'Cancel button',
    id: 'availableCreditModalCancelBtn',
  },
  AvailableCreditModalConfirmBtn: {
    defaultMessage: 'Use credits',
    description: 'Confirm button',
    id: 'availableCreditModalConfirmBtn',
  },
  AvailableCreditModalMessage1: {
    defaultMessage:
      '{count, plural, one {Your credits will cover' +
      ' the subscription for each active smartphone (# in total)}' +
      ' other {Your credits will cover the subscription' +
      ' for each active smartphone (# in total)}}',
    description: '',
    id: 'availableCreditModalMessage1',
  },
  AvailableCreditModalMessage2: {
    defaultMessage: 'The amount deducted from your credit pool will' + ' be based on the days remaining in the month.',
    description: 'Confirm message 2nd row',
    id: 'availableCreditModalMessage2',
  },
  AvailableCreditModalModalTitle: {
    defaultMessage: 'Use credits',
    description: 'Modal title',
    id: 'availableCreditModalModalTitle',
  },
});
