import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ListHeaderLabel } from '../../../../components';
import messages from './messages';

const DeviceInfoTitle = ({ type }) => (
  <ListHeaderLabel>
    {type === 'device' && <FormattedMessage {...messages.titleDevice} />}
    {type === 'my2n' && <FormattedMessage {...messages.titleMy2n} />}
    {type === 'network' && <FormattedMessage {...messages.titleNetwork} />}
  </ListHeaderLabel>
);

DeviceInfoTitle.propTypes = {
  type: PropTypes.oneOf(['device', 'my2n', 'network']).isRequired,
};

export default DeviceInfoTitle;
