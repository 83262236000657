import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { LANGUAGES } from '../../config/consts';
import { OutlinedTextField } from '../HookFormFields/TextField';
import messages from './messages';

function SiteLanguageSelect({ ...other }) {
  return (
    <OutlinedTextField
      {...other}
      fullWidth
      label={<FormattedMessage {...messages.SiteLanguageSelectLabel} />}
      id="lang"
      name="lang"
      select
    >
      {Object.values(LANGUAGES).map((lang) => (
        <MenuItem key={lang.locale} value={lang.locale}>
          <Typography>
            <FormattedMessage {...lang.translated} />
          </Typography>
        </MenuItem>
      ))}
    </OutlinedTextField>
  );
}

export default SiteLanguageSelect;
