import { defineMessages } from 'react-intl';

export default defineMessages({
  accessGroupCreateModalCreate: {
    defaultMessage: 'Create group',
    description: 'Access group create modal - create button',
    id: 'accessGroupCreateModal.create',
  },
  accessGroupCreateModalInfo: {
    defaultMessage: 'Access groups define which users have access to which devices.',
    description: 'Access group create modal - info',
    id: 'accessGroupCreateModal.info',
  },
  accessGroupCreateModalInputDescription: {
    defaultMessage: 'Access group description',
    description: 'Access group create modal - description input label',
    id: 'accessGroupCreateModal.input.description',
  },
  accessGroupCreateModalInputDescriptionMaxLength: {
    defaultMessage: 'Description is too long',
    description: 'Access group create modal - description input maxLength',
    id: 'accessGroupCreateModal.input.descriptionMaxLength',
  },
  accessGroupCreateModalInputNameHelperText: {
    defaultMessage: 'Use simple name, e. g. Garage',
    description: 'Access group create modal - name input helperText',
    id: 'accessGroupCreateModal.input.name.helperText',
  },
  accessGroupCreateModalInputNameLabel: {
    defaultMessage: 'Access group name',
    description: 'Access group create modal - name input label',
    id: 'accessGroupCreateModal.input.name.label',
  },
  accessGroupCreateModalInputNameMaxLength: {
    defaultMessage: 'Name is too long',
    description: 'Access group create modal - name input maxLength',
    id: 'accessGroupCreateModal.input.nameMaxLength',
  },
  accessGroupCreateModalInputNamePlaceholder: {
    defaultMessage: 'Main entrance, Garage, Swimming pool,...',
    description: 'Access group create modal - name input placeholder',
    id: 'accessGroupCreateModal.input.name.placeholder',
  },
  accessGroupCreateModalInputRequired: {
    defaultMessage: 'Group name is required',
    description: 'Access group create modal - input required',
    id: 'accessGroupCreateModal.input.required',
  },
  accessGroupCreateModalTitle: {
    defaultMessage: 'Create new access group',
    description: 'Access group create modal - title',
    id: 'accessGroupCreateModal.title',
  },
});
