import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Alert from '../Alert';
import Link from '../Link';
import messages from './messages';

const AlertProcessing = ({ onClose, onShowImportModal }) => {
  const onClick = (event) => {
    onShowImportModal();
    event.preventDefault();
  };

  return (
    <Alert onClose={onClose} severity="warning">
      <FormattedMessage
        {...messages.SiteUsersImportAlertStatusAlertProcessingContent}
        values={{
          a: (text) => (
            <Link onClick={onClick} to="/">
              {text}
            </Link>
          ),
        }}
      />
    </Alert>
  );
};

AlertProcessing.propTypes = {
  onClose: PropTypes.func.isRequired,
  onShowImportModal: PropTypes.func.isRequired,
};

export default AlertProcessing;
