import AccessIcon from '@mui/icons-material/GppGood';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import RowMenuItem from '../../../../components/DataGrid/RowMenuItem';
import messages from './messages';

const SetAccessGroups = ({ onClick }) => (
  <RowMenuItem
    icon={<AccessIcon />}
    label={<FormattedMessage {...messages.accessGroupUsersDataGridDataGridRowMenuAccessGroups} />}
    onClick={onClick}
  />
);

SetAccessGroups.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default SetAccessGroups;
