import CreateIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import CreateMultiAutocomplete from '../CreateMultiAutocomplete';
import GroupForm from './GroupForm';
import messages from './messages';

function AccessGroupAutocomplete({ hasFormItemKey, newItemId, optionsData }) {
  const { control, watch } = useFormContext();
  const { formatMessage } = useIntl();

  const options = useMemo(
    () => [
      {
        id: newItemId,
        name: formatMessage(messages.accessGroupAutocompleteSelectNewItemLabel),
      },
      ...optionsData,
    ],
    [optionsData]
  );

  return (
    <CreateMultiAutocomplete
      createForm={watch(hasFormItemKey) && <GroupForm control={control} />}
      createFormVisibilityKey={hasFormItemKey}
      createItemIcon={<CreateIcon />}
      helperText={formatMessage(messages.accessGroupAutocompleteSelectHelperText)}
      label={formatMessage(messages.accessGroupAutocompleteSelectLabel)}
      name="group"
      newItemId={newItemId}
      optionLabelKey="name"
      options={options}
      placeholder={formatMessage(messages.accessGroupAutocompleteSelectPlaceholder)}
    />
  );
}

AccessGroupAutocomplete.propTypes = {
  hasFormItemKey: PropTypes.string.isRequired,
  newItemId: PropTypes.string.isRequired,
  optionsData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default AccessGroupAutocomplete;
