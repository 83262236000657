import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import Alert from '../../../../../components/Alert';
import { Autocomplete } from '../../../../../components/HookFormFields/Autocomplete';
import Checkbox from '../../../../../components/HookFormFields/Checkbox';
import { OutlinedTextField } from '../../../../../components/HookFormFields/TextField';
import { IFloor } from '../../../../../modules/apartments/store';
import { BillingType, MobileVideoStatus, MobileVideoType } from '../../../../../modules/commonTypes';
import { IMVLicenceModel } from '../../../../../modules/sites/store';
import { IApartmentFormProps } from '../../../../ApartmentsOverview/hooks/useGetApartmentFormData';
import messages from '../messages';

interface IApartmentBasicInfoSection {
  floors: Array<IFloor>;
  formMethods: UseFormReturn<IApartmentFormProps>;
  formWrapperProps?: object;
  isSiteMdu: boolean;
  isModal: boolean;
  licenceModel: IMVLicenceModel;
}

function ApartmentBasicInfoSection({
  floors,
  formWrapperProps,
  isModal,
  isSiteMdu,
  licenceModel,
}: IApartmentBasicInfoSection) {
  const { formatMessage } = useIntl();

  const activatePaidServicesCaption = useMemo(() => {
    if (licenceModel.type !== MobileVideoType.STANDARD) {
      return messages.apartmentCreateModalActivatePaidServicesCaptionActivableAndExpirable;
    }
    if (licenceModel.status === MobileVideoStatus.TRIAL_ACTIVE) {
      return messages.apartmentCreateModalActivatePaidServicesCaptionTrial;
    } else {
      return messages.apartmentCreateModalActivatePaidServicesCaption;
    }
  }, [licenceModel]);

  return (
    <Grid {...formWrapperProps}>
      {!isModal && (
        <Typography fontWeight={700} mb={3}>
          {formatMessage(messages.apartmentDataFormApartmentInfoTitle)}
        </Typography>
      )}
      <Grid container spacing={3}>
        <Grid item sm={6} xs={12}>
          <Autocomplete
            disableClearable
            fullWidth
            id="floor"
            inputSettings={{
              autoFocus: isSiteMdu && isModal,
              helperText: formatMessage(messages.apartmentDataFormHelperTextFloor),
              InputLabelProps: {
                shrink: true,
              },
              label: formatMessage(messages.apartmentFloorAutocompleteFloor),
              required: isSiteMdu,
            }}
            name="floor"
            options={floors.map((floor) => floor.name)}
          />
        </Grid>
        <Grid item sm={6} sx={{ paddingBottom: { sm: 'inherit', xs: 0 } }} xs={12}>
          <OutlinedTextField
            id="number"
            helperText={formatMessage(messages.apartmentDataFormHelperTextNumber)}
            label={formatMessage(messages.apartmentDataFormNumber)}
            name="number"
            required
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container sx={{ display: { sm: 'block', xs: 'none' } }}>
        <Grid item sm={12}>
          <Alert severity="info">{formatMessage(messages.apartmentDataFormFloorDescription)}</Alert>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item sm={12} sx={{ paddingTop: { sm: 'inherit', xs: 0 } }} xs={12}>
          <OutlinedTextField
            id="name"
            helperText={formatMessage(messages.apartmentDataFormHelperTextName)}
            hasTopAndBottomMargin
            label={formatMessage(messages.apartmentDataFormName)}
            name="name"
            fullWidth
          />
        </Grid>
      </Grid>
      {isModal && licenceModel.billingType === BillingType.PER_APARTMENT && (
        <Grid container mt={0} spacing={2}>
          <Grid item xs={12}>
            <Checkbox
              label={<Typography>{formatMessage(messages.apartmentCreateModalActivatePaidServices)}</Typography>}
              name="paidServicesActive"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography color="secondary.main" variant="body2">
              {formatMessage(activatePaidServicesCaption)}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}

export default ApartmentBasicInfoSection;
