import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';

export interface IAccessGroupProps {
  label: React.JSX.Element,
  value: React.JSX.Element
}

export function AccessGroupItem({ label, value }: IAccessGroupProps ) {
  return (
    <Stack spacing={1}>
      <Typography color="secondary" variant="subtitle2">
        {label}
      </Typography>
      <Stack>
        {value}
      </Stack>
    </Stack>
  )
}
