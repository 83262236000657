import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const ItemName = ({ name }) => (
  <Grid item lg={3} sm={6} xl={4} xs={12}>
    <Typography>{name}</Typography>
  </Grid>
);

ItemName.propTypes = {
  name: PropTypes.string.isRequired,
};

export default ItemName;
