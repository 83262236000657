import { all, call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import * as Api from '../../api/apartmentApi';
import * as userApi from '../../api/userApi';
import { PATHS } from '../../config/consts';
import { createExternalId } from '../../helpers/common';
import siteActions, { selectors as siteSelectors } from '../../modules/sites';
import { helpers } from '../apartments';
import { BillingType, Roles, RolesType } from '../commonTypes';
import { errorHelper, handleErrorLocation, responseValidateStatusOrThrow } from '../helpers';
import { types as infraTypes } from '../infrastructure/types';
import { addSnackbarSuccess } from '../notifications/actions';
import { createSiteUser } from '../user/saga';
import actions, { types } from './index';
import messages from './messages';

export function* getSiteApartmentsRequest(action) {
  const { companyId, filter, order, page, params, rowsPerPage, siteId } = action;
  try {
    const response = yield call(Api.getSiteApartments, {
      company: companyId,
      params: { ...params, limit: rowsPerPage },
      site: siteId,
    });
    yield responseValidateStatusOrThrow(response);
    yield put(siteActions.getCurrentSiteLicenseModel());
    yield put({
      filter,
      order,
      page,
      payload: response.data,
      rowsPerPage,
      type: types.GET_SITE_APARTMENTS_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_SITE_APARTMENTS_FAILURE, error));
  }
}

export function* addApartment({ companyId, floors, siteId, toast, values }) {
  const { floor, name, number, paidServicesActive } = values;
  const licenceModel = yield select(siteSelectors.getSitesServiceMobileVideoLicenceModel);
  let floorData = {
    externalId: null,
    id: null,
    name: '',
  };

  try {
    const filteredFloor = floors.find((fl) => fl.name === floor);
    if (!filteredFloor) {
      if (floor) {
        yield put(actions.addFloor(companyId, siteId, { name: floor }));
        const addFloorResponse = yield take([types.ADD_FLOOR_SUCCESS]);
        floorData = addFloorResponse.payload;
      }
    } else {
      floorData = filteredFloor;
    }
    const response = yield call(Api.addApartment, companyId, siteId, {
      externalId: createExternalId(),
      floorId: floorData?.id,
      name,
      number,
      ...(licenceModel.billingType === BillingType.PER_APARTMENT && { paidServicesActive }),
    });
    yield responseValidateStatusOrThrow(response);
    yield put({ payload: response.data, type: types.ADD_APARTMENT_SUCCESS });
    if (toast) {
      yield put(addSnackbarSuccess(messages.apartmentSnackbarAddedSuccessfully));
    }
  } catch (error) {
    yield put(errorHelper(types.ADD_APARTMENT_FAILURE, error));
  }
}

export function* addFloor({ companyId, siteId, values }) {
  try {
    const response = yield call(Api.addFloor, companyId, siteId, {
      ...values,
      externalId: createExternalId(),
    });
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({ payload: data, type: types.ADD_FLOOR_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.ADD_FLOOR_FAILURE, error));
  }
}

export function* getSiteFloorsRequest({ companyId, siteId }) {
  try {
    const response = yield call(Api.getSiteFloors, companyId, siteId, {
      limit: 1000,
      offset: 0,
    });
    yield responseValidateStatusOrThrow(response);
    yield put({
      payload: response.data,
      type: types.GET_SITE_FLOORS_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_SITE_FLOORS_FAILURE, error));
  }
}

export function* getApartmentDetail({ apartmentId, companyId, siteId }) {
  try {
    const response = yield call(Api.getApartmentDetail, companyId, siteId, apartmentId);
    yield responseValidateStatusOrThrow(response);
    yield put({
      payload: response.data,
      type: types.GET_APARTMENT_DETAIL_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_APARTMENT_DETAIL_FAILURE, error));
    if (handleErrorLocation(error?.status)) {
      yield put({
        payload: {
          locationSubRoutesCheck: ['/apartments'],
        },
        type: infraTypes.SET_REDIRECT_DATA,
      });
    }
  }
}

export function* deleteApartment({ apartmentId, companyId, siteId }) {
  try {
    const response = yield call(Api.deleteApartment, companyId, siteId, apartmentId);
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.DELETE_APARTMENT_SUCCESS });
    yield put({
      payload: {
        to: PATHS.APARTMENTS_OVERVIEW.replace(':companyId', companyId).replace(':siteId', siteId),
      },
      type: infraTypes.SET_REDIRECT_DATA,
    });
    yield put(addSnackbarSuccess(messages.apartmentSnackbarDeletedSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.DELETE_APARTMENT_FAILURE, error));
  }
}

export function* getApartmentDevices({ apartmentId, companyId, filter, order, page, params, rowsPerPage, siteId }) {
  try {
    const response = yield call(Api.getApartmentDevices, companyId, siteId, apartmentId, params);
    yield responseValidateStatusOrThrow(response);
    yield put({
      filter,
      order,
      page,
      payload: response.data,
      rowsPerPage,
      type: types.GET_APARTMENT_DEVICES_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_APARTMENT_DEVICES_FAILURE, error));
  }
}

export function* addApartmentUsersWithCreate({ apartmentId, newUser, users }) {
  try {
    const newUserResponse = newUser ? yield call(createSiteUser, newUser) : null;
    const responses = yield all(
      [...(newUserResponse?.data?.id ? [newUserResponse?.data?.id] : []), ...users].map((user) =>
        call(
          userApi.setRole,
          user,
          helpers.apartmentUserHandler(apartmentId, RolesType.GRANTED_ROLES, [Roles.RESIDENT])
        )
      )
    );

    if (newUserResponse) {
      yield responseValidateStatusOrThrow(newUserResponse);
    }

    for (const response of responses) {
      yield responseValidateStatusOrThrow(response);
    }

    yield put({ type: types.CREATE_ADD_APARTMENT_USERS_SUCCESS });
    yield put(addSnackbarSuccess(messages.userSnackbarUsersAddedSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.CREATE_ADD_APARTMENT_USERS_FAILURE, error));
  }
}

export function* editApartment({ apartment, companyId, floors, siteId, values }) {
  const { floor, name, number, paidServicesActive } = values;
  let floorData = {
    externalId: null,
    id: null,
    name: '',
  };
  try {
    const filteredFloor = floors.find((fl) => fl.name === floor);
    if (!filteredFloor) {
      if (floor) {
        yield put(actions.addFloor(companyId, siteId, { name: floor }));
        const addFloorResponse = yield take([types.ADD_FLOOR_SUCCESS]);
        floorData = addFloorResponse.payload;
      }
    } else {
      floorData = filteredFloor;
    }
    const response = yield call(Api.editApartment, companyId, siteId, apartment.id, {
      externalId: apartment.externalId,
      floorId: floorData?.id,
      name,
      number,
      paidServicesActive,
    });
    yield responseValidateStatusOrThrow(response);
    yield put(siteActions.getCurrentSiteLicenseModel());
    yield put({
      payload: response.data,
      type: types.EDIT_APARTMENT_SUCCESS,
    });
    yield put(addSnackbarSuccess(messages.apartmentSnackbarEditedSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.EDIT_APARTMENT_FAILURE, error));
  }
}

export function* getApartmentsList({ companyId, siteId }) {
  try {
    const response = yield call(Api.getSiteApartments, {
      company: companyId,
      params: { limit: 1000, offset: 0 },
      site: siteId,
    });
    yield responseValidateStatusOrThrow(response);
    yield put({
      payload: response?.data?.results || [],
      type: types.GET_APARTMENTS_LIST_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_APARTMENTS_LIST_FAILURE, error));
  }
}

export default function* apartmentsSagas() {
  yield all([
    takeLatest(types.GET_SITE_APARTMENTS_REQUEST, getSiteApartmentsRequest),
    takeEvery(types.ADD_APARTMENT_REQUEST, addApartment),
    takeEvery(types.ADD_FLOOR_REQUEST, addFloor),
    takeLatest(types.GET_SITE_FLOORS_REQUEST, getSiteFloorsRequest),
    takeLatest(types.GET_APARTMENT_DETAIL_REQUEST, getApartmentDetail),
    takeLatest(types.DELETE_APARTMENT_REQUEST, deleteApartment),
    takeLatest(types.GET_APARTMENT_DEVICES_REQUEST, getApartmentDevices),
    takeLatest(types.CREATE_ADD_APARTMENT_USERS_REQUEST, addApartmentUsersWithCreate),
    takeLatest(types.EDIT_APARTMENT_REQUEST, editApartment),
    takeLatest(types.GET_APARTMENTS_LIST_REQUEST, getApartmentsList),
  ]);
}
