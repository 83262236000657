export const moduleName = 'payments';

export default {
  GET_PAYMENT_DETAIL_FAILURE: `${moduleName}/GET_PAYMENT_DETAIL_FAILURE`,
  GET_PAYMENT_DETAIL_REQUEST: `${moduleName}/GET_PAYMENT_DETAIL_REQUEST`,
  GET_PAYMENT_DETAIL_SUCCESS: `${moduleName}/GET_PAYMENT_DETAIL_SUCCESS`,
  GET_PAYMENTS_FAILURE: `${moduleName}/GET_PAYMENTS_FAILURE`,
  GET_PAYMENTS_REQUEST: `${moduleName}/GET_PAYMENTS_REQUEST`,
  GET_PAYMENTS_SUCCESS: `${moduleName}/GET_PAYMENTS_SUCCESS`,
  PROMO_DETAIL_HIDE_MODAL: `${moduleName}/PROMO_DETAIL_HIDE_MODAL`,
  PROMO_DETAIL_SHOW_MODAL: `${moduleName}/PROMO_DETAIL_SHOW_MODAL`,
  START_TRIAL_FAILURE: `${moduleName}/START_TRIAL_FAILURE`,
  START_TRIAL_REQUEST: `${moduleName}/START_TRIAL_REQUEST`,
  START_TRIAL_SUCCESS: `${moduleName}/START_TRIAL_SUCCESS`,
};
