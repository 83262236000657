import { ArrowForward } from '@mui/icons-material';
import { FormControl, Stack } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PasswordInput } from '../../components/HookFormFields/PasswordInput';
import OutlinedTextField from '../../components/HookFormFields/TextField';
import { PATHS } from '../../config/consts';
import useBreakpoints from '../../helpers/useBreakpoints';
import { RouterLink } from '../ResetPasswordPage/components/Link/PrimaryLink';
import messages from './messages';

export interface IAuthLoginFormData {
  isSubmitDisabled: boolean;
}

export function AuthLoginForm({ isSubmitDisabled }: IAuthLoginFormData) {
  const { downSm } = useBreakpoints();
  const buttonsDirection = downSm ? 'column' : 'row';
  const buttonsSpacing = downSm ? 2 : 1;

  return (
    <FormControl fullWidth>
      <OutlinedTextField
        id="username-input"
        label={<FormattedMessage {...messages.authLoginEmailLabel} />}
        name="email"
        required
        type="email"
        hasTopAndBottomMargin
      />
      <PasswordInput
        id="password-input"
        label={<FormattedMessage {...messages.authLoginPasswordLabel} />}
        name="password"
        required
        type="password"
      />
      <Stack
        direction={buttonsDirection}
        spacing={buttonsSpacing}
        justifyContent="space-between"
        alignItems="center"
        mt={3}
      >
        <RouterLink to={PATHS.PASSWORD_RESET}>
          <FormattedMessage {...messages.authLoginForgottenPassword} />
        </RouterLink>

        <Button
          startIcon={<ArrowForward />}
          color="primary"
          disabled={isSubmitDisabled}
          id="login-button"
          size="large"
          type="submit"
          variant="contained"
        >
          <FormattedMessage {...messages.authLoginSubmit} />
        </Button>
      </Stack>
    </FormControl>
  );
}
