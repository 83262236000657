import { AlertColor } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { OfflinePairingDto } from '../../../../apiRtk/accessControlApi';
import { FormFieldNames } from '../../validationSchema';
import { getRadioGroupBooleanValue } from './helpers/getRadioGroupBooleanValue';
import { RadioGroupVariant } from './RadioGroupVariant/RadioGroupVariant';

interface IQuestions {
  offlinePairingDto: OfflinePairingDto;
}

function Questions2({ offlinePairingDto }: IQuestions) {
  const formMethods = useFormContext();
  const locationIds = offlinePairingDto.locationIds;
  const [noBLEvalue, setNoBLEvalue] = useState<boolean | undefined>(
    getRadioGroupBooleanValue(formMethods.getValues(FormFieldNames.NoBLE))
  );
  const [acomValue, setAcomValue] = useState<boolean | undefined>(
    getRadioGroupBooleanValue(formMethods.getValues(FormFieldNames.Acom))
  );
  const [manageBLEValue, setManageBLEValue] = useState<boolean | undefined>(
    getRadioGroupBooleanValue(formMethods.getValues(FormFieldNames.ManageBLE))
  );

  useEffect(() => {
    const subscription = formMethods.watch((values) => {
      setNoBLEvalue(getRadioGroupBooleanValue(values[FormFieldNames.NoBLE]));
      setAcomValue(getRadioGroupBooleanValue(values[FormFieldNames.Acom]));
      setManageBLEValue(getRadioGroupBooleanValue(values[FormFieldNames.ManageBLE]));
    });
    return () => subscription.unsubscribe();
  }, [formMethods]);

  const getRadioGroupVariantProps = () => {
    if (noBLEvalue === false) {
      return { hideAlert: true, hideSelectId: true };
    }
    if (acomValue === true) {
      return {
        alertVariant: 'info' as AlertColor,
        firstOptionDisabled: true,
        hideAlert: manageBLEValue,
        selectIdDisabled: true,
      };
    }
    if (
      noBLEvalue === true ||
      acomValue === false ||
      (offlinePairingDto.offlinePairing === true &&
        offlinePairingDto.acom === false &&
        locationIds &&
        locationIds.length <= 3)
    ) {
      return {
        alertVariant: 'warning' as AlertColor,
        hideAlert: manageBLEValue,
        locationIds: offlinePairingDto.locationIds,
        selectIdDisabled: !manageBLEValue,
      };
    }
    if (
      offlinePairingDto.offlinePairing === true &&
      offlinePairingDto.acom === false &&
      locationIds &&
      locationIds.length > 3
    ) {
      return { alertVariant: 'warning' as AlertColor, hideAlert: manageBLEValue, selectIdDisabled: !manageBLEValue };
    }
    return {};
  };

  return <RadioGroupVariant {...getRadioGroupVariantProps()} />;
}

export default Questions2;
