import { MOBILE_VIDEO_STATUS } from '../../config/consts';
import { noPaidDevices } from '../../helpers/dashboard';
import { getIsValidUntil } from '../../helpers/mobileVideo';

export const getStyles = (is2nDevice, licenceModel, subscribed) => {
  if (is2nDevice || noPaidDevices(licenceModel)) {
    return 'success';
  }
  if (
    [
      MOBILE_VIDEO_STATUS.ENABLED,
      MOBILE_VIDEO_STATUS.ACTIVE,
      MOBILE_VIDEO_STATUS.TRIAL_ACTIVE,
      MOBILE_VIDEO_STATUS.STANDARD,
      MOBILE_VIDEO_STATUS.STANDARD_RENEWAL,
    ].includes(licenceModel?.status)
  ) {
    if (getIsValidUntil(licenceModel?.status, licenceModel, subscribed)) {
      return 'warning';
    }
    return 'success';
  }
  return 'error';
};
