import { PATHS } from '../../config/consts';
import { TId } from '../../modules/commonTypes';
import { replaceURLParams } from './pathParamsReplacer';

export function getUsersInApartmentsUserDetailPath({
  apartmentId,
  companyId,
  siteId,
  userId,
}: {
  userId: TId;
  apartmentId: TId;
  companyId: TId;
  siteId: TId;
}): string {
  return replaceURLParams({
    params: { apartmentId, companyId, siteId, userId },
    url: PATHS.USERS_IN_APARTMENTS_DETAIL,
  });
}
