import { TContext } from '../../components/UserDeleteModal/UserDeleteModal';
import { getOffset } from '../../helpers/dataGrid';
import { TId, TUuid } from '../commonTypes';
import { IReduxAction } from '../reduxTypes';
import { IUser, IUserBase, UserRoles } from './store';
import types from './types';

export type TUserActionContext =
  | 'SITE_USERS_LIST'
  | 'SITE_USER_DETAIL'
  | 'ACCESS_SETTINGS_USERS_LIST'
  | 'APARTMENTS_USERS_LIST';

export type UserEditValues = {
  apartments: number[];
  id: TId;
  newApartment?: {
    floor: string;
    name: string;
    number: string;
  };
  email?: string;
  firstName?: string;
  lastName: string;
  lang: string;
  role: UserRoles;
};

export function enableUserMy2NAppAccess(
  companyId: TId,
  siteId: TId,
  userId: TId,
  my2NAppAccessState: boolean,
  context: TUserActionContext
) {
  return {
    companyId,
    context,
    my2NAppAccessState,
    siteId,
    type: types.ACTIVATE_USER_MY2N_APP_ACCESS_REQUEST,
    userId,
  };
}

export function getUserMy2NAppAccessStatus(companyId: TId, siteId: TId, userId: TId) {
  return {
    companyId,
    siteId,
    type: types.GET_USER_MY2N_APP_ACCESS_STATUS_REQUEST,
    userId,
  };
}

export function getSiteUsersList(
  companyId: TId,
  siteId: TId,
  page: number,
  rowsPerPage: number,
  filter: { fulltext: string },
  order: string
) {
  return {
    companyId,
    filter,
    order,
    page,
    params: {
      filter: filter.fulltext,
      limit: rowsPerPage,
      offset: getOffset(page, rowsPerPage),
      order,
    },
    rowsPerPage,
    siteId,
    type: types.GET_SITE_USERS_LIST_REQUEST,
  };
}

export const getUserPin = (companyId: TId, siteId: TId, userId: TId) => ({
  companyId,
  siteId,
  type: types.GET_USER_PIN_REQUEST,
  userId,
});

export const generateUserPin = (userId: TId) => ({
  type: types.GENERATE_USER_PIN_REQUEST,
  userId,
});

export const saveUserPin = (userId: TId, pinId: TUuid, context: TUserActionContext) => ({
  context,
  pinId,
  type: types.SAVE_USER_PIN_REQUEST,
  userId,
});

export const deleteUserRequest = (user: IUser, context: TContext) => ({
  context,
  type: types.DELETE_USER_REQUEST,
  user,
});

export const deleteSiteUserRequest = (user: IUser, context: TContext) => ({
  context,
  type: types.DELETE_SITE_USER_REQUEST,
  user,
});

export const detailUserRequest = (context: TContext) => (companyId: TId, siteId: TId | undefined, userId: TId) => ({
  companyId,
  context,
  siteId,
  type: types.DETAIL_USER_REQUEST,
  userId,
});

export const createUserRfid = (
  companyId: TId,
  siteId: TId,
  userId: TId,
  rfidCode: string,
  context: TUserActionContext
) => ({
  companyId,
  context,
  rfidCode,
  siteId,
  type: types.CREATE_USER_RFID_REQUEST,
  userId,
});

export const editSiteUser = (context: TContext, isCurrentUser: boolean, values: UserEditValues) => ({
  context,
  isCurrentUser,
  type: types.EDIT_SITE_USER_REQUEST,
  values,
});

export const editUserPassword = (values: { currentUser: IUserBase; password: string }) => ({
  type: types.EDIT_USER_PASSWORD_REQUEST,
  values,
});

export function invalidateCurrentUser(): IReduxAction {
  return {
    type: types.INVALIDATE_CURRENT_USER,
  };
}
