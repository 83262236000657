import { useParams } from 'react-router-dom';
import { IApartmentIdsInfo } from '../modules/apartments/store';
import { parseApartmentComposedId } from '../routes/composedIds/composedIds';
import { apartmentComposedIdName } from '../routes/composedIds/config';

export function useApartmentComposedIdParam(): IApartmentIdsInfo | undefined {
  const params = useParams();

  if (params[apartmentComposedIdName]) {
    const result = parseApartmentComposedId(params[apartmentComposedIdName]);
    if (result) {
      return result;
    }
  }
  return undefined;
}
