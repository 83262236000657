import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import serviceAc from '../../../assets/service-ac.svg';
import { ServicePanel } from '../../../components';
import { SERVICE } from '../../../config/devices';
import messages from './messages';

const AccessControlService = ({
  device,
  isAcActivable,
  isRCConfigurationDisabled,
  onCreateServiceConfiguration,
  onOpenRCModal,
  onUpdateServiceConfiguration,
}) => {
  const { active } = device?.services?.[SERVICE.ACCESS_CONTROL] || false;

  const handleActivate = () => {
    if (!device?.services?.[SERVICE.ACCESS_CONTROL] && isAcActivable) {
      onCreateServiceConfiguration(device.id, SERVICE.ACCESS_CONTROL);
    } else {
      onUpdateServiceConfiguration({
        acValue: {
          active: !active,
          type: SERVICE.ACCESS_CONTROL,
        },
      });
    }
  };

  return (
    <ServicePanel
      active={active}
      disabled={!device?.services?.[SERVICE.ACCESS_CONTROL]}
      icon={<img alt="Residential access service" src={serviceAc} />}
      isServiceAvailable={isAcActivable}
      name={
        <FormattedMessage
          tagName="strong"
          values={{ sup: (chunks) => <sup>{chunks}</sup> }}
          {...messages.acServiceTitle}
        />
      }
      onToggleConfirm={handleActivate}
      state={
        <FormattedMessage
          tagName="strong"
          {...(active ? messages.acServiceStatusActive : messages.acServiceStatusInactive)}
        />
      }
      toggleConfirmInfo={
        <Typography>
          <FormattedMessage
            {...(active
              ? messages.acServiceActivationDialogMessageDeactivate
              : messages.acServiceActivationDialogMessageActivate)}
          />
        </Typography>
      }
      toggleConfirmTitle={
        <FormattedMessage
          {...(active
            ? messages.acServiceActivationDialogTitleDeactivate
            : messages.acServiceActivationDialogTitleActivate)}
        />
      }
    >
      <Grid container item md={5} sm={6}>
        <Typography color="textSecondary" variant="body2">
          <FormattedMessage
            values={{
              br: <br />,
              link: isRCConfigurationDisabled ? (
                <FormattedMessage {...messages.acServiceLink} />
              ) : (
                <Link href="#" onClick={() => onOpenRCModal(device)}>
                  <FormattedMessage {...messages.acServiceLink} />
                </Link>
              ),
            }}
            {...messages.acServiceText}
          />
        </Typography>
      </Grid>
    </ServicePanel>
  );
};

AccessControlService.propTypes = {
  device: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    services: PropTypes.shape({
      [SERVICE.ACCESS_CONTROL]: PropTypes.shape({
        active: PropTypes.bool,
      }),
    }),
  }).isRequired,
  isAcActivable: PropTypes.bool.isRequired,
  isRCConfigurationDisabled: PropTypes.bool.isRequired,
  onCreateServiceConfiguration: PropTypes.func.isRequired,
  onOpenRCModal: PropTypes.func.isRequired,
  onUpdateServiceConfiguration: PropTypes.func.isRequired,
};

export default AccessControlService;
