import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const LoggingStatus = ({ isActive, isRacActive }) => {
  const getTooltipTitle = () => {
    if (isActive && isRacActive) {
      return <FormattedMessage {...messages.accessGroupDevicesDataGridNoRowsOverlayLoggingStatusTooltipActive} />;
    }

    if (isActive && !isRacActive) {
      return (
        <FormattedMessage
          {...messages.accessGroupDevicesDataGridNoRowsOverlayLoggingStatusTooltipRacInactive}
          values={{ br: <br /> }}
        />
      );
    }

    if (!isActive && isRacActive) {
      return <FormattedMessage {...messages.accessGroupDevicesDataGridNoRowsOverlayLoggingStatusTooltipLogInactive} />;
    }

    return <FormattedMessage {...messages.accessGroupDevicesDataGridNoRowsOverlayLoggingStatusTooltipInactive} />;
  };

  return (
    <Tooltip title={getTooltipTitle()}>
      <Box
        component="span"
        sx={{
          display: 'inline-flex',
          verticalAlign: 'middle',
        }}
      >
        {isActive ? (
          <CheckIcon color={isRacActive ? 'success' : 'disabled'} fontSize="small" />
        ) : (
          <CancelIcon color={isRacActive ? 'error' : 'disabled'} fontSize="small" />
        )}
        <Typography sx={{ pl: 1 }} variant="body2">
          <FormattedMessage
            {...(isActive
              ? messages.accessGroupDevicesDataGridNoRowsOverlayLoggingStatusOn
              : messages.accessGroupDevicesDataGridNoRowsOverlayLoggingStatusOff)}
          />
        </Typography>
      </Box>
    </Tooltip>
  );
};

LoggingStatus.propTypes = {
  isActive: PropTypes.bool.isRequired,
  isRacActive: PropTypes.bool.isRequired,
};

export default LoggingStatus;
