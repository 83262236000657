import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';

const DataGridActionButton = ({ children, onClick, startIcon, variant, ...other }) => (
  <Button {...other} color="primary" onClick={onClick} startIcon={startIcon} variant={variant}>
    {children}
  </Button>
);

DataGridActionButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
  startIcon: PropTypes.element.isRequired,
  variant: PropTypes.string,
};

DataGridActionButton.defaultProps = {
  variant: 'contained',
};

export default DataGridActionButton;
