import { ApartmentUser, ApartmentUserRole } from '../../../modules/apartmentAdmin/data';

export enum UWLUserType {
  UWLWithPinOnly = 'UWL-with-pin-only',
  UWLWithRFIDOnly = 'UWL-with-rfid-only',
  UWLWithoutCredentials = 'UWL-without-credentials',
  UWLWithMoreCredentials = 'UWL-with-more-credentials',
  NoUWL = 'no-UWL',
}

export function getUWLUserType(user: ApartmentUser): UWLUserType {
  if (user.role === ApartmentUserRole.APARTMENT_LOGIN_LESS) {
    if (user.credentials && user.credentials.length === 1) {
      if (user.credentials[0] === 'PIN') {
        return UWLUserType.UWLWithPinOnly;
      }
      if (user.credentials[0] === 'RFID') {
        return UWLUserType.UWLWithRFIDOnly;
      }
    }
    if (!user.credentials || (user.credentials && !user.credentials.length)) {
      return UWLUserType.UWLWithoutCredentials;
    }
    return UWLUserType.UWLWithMoreCredentials;
  }
  return UWLUserType.NoUWL;
}

export function isUWLUser(user: ApartmentUser): boolean {
  return user.role === ApartmentUserRole.APARTMENT_LOGIN_LESS;
}
