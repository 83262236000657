import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonDanger, Modal } from '../../../../components';
import messages from './messages';

const DeleteOrderModal = ({ onClose, onDelete, open }) => (
  <Modal
    actions={
      <>
        <Button onClick={onClose}>
          <FormattedMessage {...messages.twoStepButtonCancel} />
        </Button>
        <ButtonDanger onClick={onDelete} startIcon={<DeleteIcon />}>
          <FormattedMessage {...messages.twoStepButtonOk} />
        </ButtonDanger>
      </>
    }
    headerVariant="danger"
    maxWidth="xs"
    name="delete-order-modal"
    onClose={onClose}
    open={open}
    title={<FormattedMessage {...messages.twoStepTitle} />}
  >
    <Typography>
      <FormattedMessage {...messages.twoStepContent} />
    </Typography>
  </Modal>
);

DeleteOrderModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default DeleteOrderModal;
