import { defineMessages } from 'react-intl';

export default defineMessages({
  userAccessSettingsInfoModalButton: {
    defaultMessage: 'Ok',
    description: 'User access settings info modal - button',
    id: 'userAccessSettingsInfoModal.button',
  },
  userAccessSettingsInfoModalContent: {
    defaultMessage:
      '<p>Access rights are typically set using access groups. They define which users have access via which devices.</p>' +
      'There are multiple ways to set access rights for user:' +
      '<ul><li><strong>User settings</strong> - Access groups are set only for an individual user.</li>' +
      '<li><strong>Apartment settings</strong> - Access groups are set for an apartment, which gives access to all apartment users.' +
      ' When user is removed from the apartment, the apartment access rights are removed as well.</li>' +
      '<li><strong>Apartment as an access group</strong> - If an apartment contains an access-allowing device (access unit or intercom), all apartment users will have access there.</li></ul>' +
      'The resulting access rights are a combination of these settings.' +
      '<p>For access to work, user also must be given credentials (PIN or RFID card/badge).</p>',
    description: 'User access settings info modal - content',
    id: 'userAccessSettingsInfoModal.content',
  },
  userAccessSettingsInfoModalContentWithBLE: {
    defaultMessage:
      '<p>Access rights are typically set using access groups. They define which users have access via which devices.</p>' +
      'There are multiple ways to set access rights for user:' +
      '<ul><li><strong>User settings</strong> - Access groups are set only for an individual user.</li>' +
      '<li><strong>Apartment settings</strong> - Access groups are set for an apartment, which gives access to all apartment users.' +
      ' When user is removed from the apartment, the apartment access rights are removed as well.</li>' +
      '<li><strong>Apartment as an access group</strong> - If an apartment contains an access-allowing device (access unit or intercom), all apartment users will have access there.</li></ul>' +
      'The resulting access rights are a combination of these settings.' +
      '<p>For access to work, user also must be given credentials (PIN, RFID card/badge or My2N app access).</p>',
    description: 'User access settings info modal - content',
    id: 'userAccessSettingsInfoModal.contentWithBle',
  },
  userAccessSettingsInfoModalTitle: {
    defaultMessage: 'Access settings help',
    description: 'User access settings info modal - title',
    id: 'userAccessSettingsInfoModal.title',
  },
});
