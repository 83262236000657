export const moduleName = 'elevatorIntegration';

export const types = {
  GET_DEVICE_ELEVATOR_INTEGRATION_FAILURE: `${moduleName}/GET_DEVICE_ELEVATOR_INTEGRATION_FAILURE`,
  GET_DEVICE_ELEVATOR_INTEGRATION_REQUEST: `${moduleName}/GET_DEVICE_ELEVATOR_INTEGRATION_REQUEST`,
  GET_DEVICE_ELEVATOR_INTEGRATION_SUCCESS: `${moduleName}/GET_DEVICE_ELEVATOR_INTEGRATION_SUCCESS`,
  GET_ELEVATOR_INTEGRATION_FLOOR_MAPPING_FAILURE: `${moduleName}/GET_ELEVATOR_INTEGRATION_FLOOR_MAPPING_FAILURE`,
  GET_ELEVATOR_INTEGRATION_FLOOR_MAPPING_REQUEST: `${moduleName}/GET_ELEVATOR_INTEGRATION_FLOOR_MAPPING_REQUEST`,
  GET_ELEVATOR_INTEGRATION_FLOOR_MAPPING_SUCCESS: `${moduleName}/GET_ELEVATOR_INTEGRATION_FLOOR_MAPPING_SUCCESS`,
  GET_SITE_ELEVATOR_INTEGRATION_FAILURE: `${moduleName}/GET_SITE_ELEVATOR_INTEGRATION_FAILURE`,
  GET_SITE_ELEVATOR_INTEGRATION_REQUEST: `${moduleName}/GET_SITE_ELEVATOR_INTEGRATION_REQUEST`,
  GET_SITE_ELEVATOR_INTEGRATION_SUCCESS: `${moduleName}/GET_SITE_ELEVATOR_INTEGRATION_SUCCESS`,
  GET_USER_ELEVATOR_INTEGRATION_FAILURE: `${moduleName}/GET_USER_ELEVATOR_INTEGRATION_FAILURE`,
  GET_USER_ELEVATOR_INTEGRATION_REQUEST: `${moduleName}/GET_USER_ELEVATOR_INTEGRATION_REQUEST`,
  GET_USER_ELEVATOR_INTEGRATION_SUCCESS: `${moduleName}/GET_USER_ELEVATOR_INTEGRATION_SUCCESS`,
  SET_DEVICE_ELEVATOR_INTEGRATION_FAILURE: `${moduleName}/SET_DEVICE_ELEVATOR_INTEGRATION_FAILURE`,
  SET_DEVICE_ELEVATOR_INTEGRATION_REQUEST: `${moduleName}/SET_DEVICE_ELEVATOR_INTEGRATION_REQUEST`,
  SET_DEVICE_ELEVATOR_INTEGRATION_SUCCESS: `${moduleName}/SET_DEVICE_ELEVATOR_INTEGRATION_SUCCESS`,
  SET_SITE_ELEVATOR_INTEGRATION_FAILURE: `${moduleName}/SET_SITE_ELEVATOR_INTEGRATION_FAILURE`,
  SET_SITE_ELEVATOR_INTEGRATION_REQUEST: `${moduleName}/SET_SITE_ELEVATOR_INTEGRATION_REQUEST`,
  SET_SITE_ELEVATOR_INTEGRATION_SUCCESS: `${moduleName}/SET_SITE_ELEVATOR_INTEGRATION_SUCCESS`,
  SET_USER_ELEVATOR_INTEGRATION_FAILURE: `${moduleName}/SET_USER_ELEVATOR_INTEGRATION_FAILURE`,
  SET_USER_ELEVATOR_INTEGRATION_REQUEST: `${moduleName}/SET_USER_ELEVATOR_INTEGRATION_REQUEST`,
  SET_USER_ELEVATOR_INTEGRATION_SUCCESS: `${moduleName}/SET_USER_ELEVATOR_INTEGRATION_SUCCESS`,
};
