import React from 'react';
import { APARTMENT_STATUS, MOBILE_VIDEO_TYPE } from '../../../config/consts';
import { SERVICE } from '../../../config/devices';
import { IApartmentDetail } from '../../../modules/apartments/store';
import { IMVLicenceModel } from '../../../modules/sites/store';

interface IApartmentDataGridAction {
  apartment: IApartmentDetail;
  licenceModel: IMVLicenceModel;
  purchaseComponent: React.ReactElement;
}

export default function ApartmentDataGridAction({
  apartment,
  licenceModel,
  purchaseComponent,
}: IApartmentDataGridAction) {
  const isApartmentUnlicenced = apartment?.services?.[SERVICE.MOBILE_VIDEO]?.status === APARTMENT_STATUS.UNLICENSED;

  if (licenceModel.type === MOBILE_VIDEO_TYPE.STANDARD && isApartmentUnlicenced && apartment?.paidServicesActive) {
    return purchaseComponent;
  } else {
    return <>{false}</>;
  }
}
