import Box from '@mui/material/Box';
import List from '@mui/material/List';
import React, { useState } from 'react';
import { shouldShowAddButton } from '../../../modules/devices/helpers/helpers';
import { IButtonRenderData } from '../../DeviceDetail/CallSettings/Intercom/hooks/useGetStateData';
import AddButtonRow from './AddButtonRow';
import HeaderSettingsList from './HeaderSettingsList';
import ListItem from './ListItem';

const rootStyle = {
  backgroundColor: 'primary.create',
  borderRadius: 'shape.borderRadius',
  mx: 0,
  my: 2,
  p: 2,
};

export interface ISettingsData {
  addedDeviceId: number;
  deviceMaxButtonCount?: number;
  deviceButtonsData: IButtonRenderData[];
  onAddButton: () => void;
  onChange: (index: number, value: string) => void;
  onClear: (index: number, optionKey: number) => void;
  onAddCallDestination: (index: number, addDevice: boolean) => void;
  ringingGroupSizeLimit: number;
  hasSelectedApartment: boolean;
}

const Settings = (data: ISettingsData) => {
  const [opened, setOpened] = useState(true);
  return (
    <Box sx={rootStyle}>
      <HeaderSettingsList handleOpen={setOpened} opened={opened} />
      {opened && (
        <>
          <List sx={{ mt: 1 }}>
            {data.deviceButtonsData.map((item) => (
              <ListItem
                addedDeviceId={data.addedDeviceId}
                buttonData={item}
                hasSelectedApartment={data.hasSelectedApartment}
                key={item.index}
                onAdd={(addDevice: boolean) => data.onAddCallDestination(item.index, addDevice)}
                onChange={(value) => data.onChange(item.index, value)}
                onClear={(optionKey) => data.onClear(item.index, optionKey)}
                ringingGroupSizeLimit={data.ringingGroupSizeLimit}
              />
            ))}
          </List>
          {shouldShowAddButton(data.deviceButtonsData, data.deviceMaxButtonCount) && (
            <AddButtonRow onAdd={data.onAddButton} />
          )}
        </>
      )}
    </Box>
  );
};

Settings.defaultProps = {
  deviceMaxButtonCount: undefined,
};

export default Settings;
