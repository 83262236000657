import { defineMessages } from 'react-intl';

export default defineMessages({
  AAmodalDeleteUserCancel: {
    defaultMessage: 'Cancel',
    id: 'AAmodal.deleteUser.cancel',
  },
  AAmodalDeleteUserMsgErr: {
    defaultMessage: 'There was an error, please try again',
    id: 'AAmodal.deleteUser.msgErr',
  },
  AAmodalDeleteUserMsgSucc: {
    defaultMessage: 'User deleted successfully',
    id: 'AAmodal.deleteUser.msgSucc',
  },
  AAmodalDeleteUserSubmit: {
    defaultMessage: 'Delete user',
    id: 'AAmodal.deleteUser.submit',
  },

  AAmodalDeleteUserText1: {
    defaultMessage: 'By deleting, the user will lose access.',
    id: 'AAmodal.deleteUser.text1',
  },
  AAmodalDeleteUserText2: {
    defaultMessage: 'If the user is a member of other apartments they are not deleted from those.',
    id: 'AAmodal.deleteUser.text2',
  },
  AAmodalDeleteUserTitle: {
    defaultMessage: 'Delete user',
    id: 'AAmodal.deleteUser.title',
  },
});
