import Avatar from '@mui/material/Avatar';
import React from 'react';
import UserIcon from '../UserIcon';
import { ApartmentAdminBadge } from './ApartmentAdminBadge';

export function ApartmentAdminAvatarIcon() {
  return (
    <ApartmentAdminBadge borderColor={'#f1f4f9'}>
      <Avatar
        sx={{
          bgcolor: 'button.info.background',
          color: 'secondary.main',
          height: 48,
          width: 48,
        }}
      >
        <UserIcon userRole={'SITE_USER'} />
      </Avatar>
    </ApartmentAdminBadge>
  );
}
