import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import SelectField from '../../../../components/SelectField';
import { CONNECTION_MODE, DEVICE_TYPE } from '../../../../config/devices';
import ConnectionModeChangeDialog, { switchingModes } from './ConnectionModeChangeDialog';
import messages from './messages';

const ConnectionModeField = ({ deviceType, disabled, isLocalModeInit, onChange, value }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleOnChange = (event) => {
    if (canOpenWarningDialog(isLocalModeInit, value, deviceType)) {
      setIsOpen(true);
    } else {
      onChange(event);
    }
  };
  const handleConfirm = () => {
    const newValue = deviceType !== DEVICE_TYPE.IDT ? CONNECTION_MODE.LOCAL : CONNECTION_MODE.CLOUD;

    onChange({ target: { name: 'connectionMode', value: newValue } });
    setIsOpen(false);
  };

  return (
    <>
      <ConnectionModeChangeDialog
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onConfirm={handleConfirm}
        switchingMode={getSwitchingModeByDeviceType(deviceType)}
      />
      <Box mt={1} pb={2}>
        <SelectField
          disabled={disabled}
          fullWidth
          helperText=""
          label={<FormattedMessage {...messages.Label} />}
          margin="none"
          name="connectionMode"
          onChange={handleOnChange}
          options={[
            {
              label: <FormattedMessage {...messages.optionsLocal} />,
              value: CONNECTION_MODE.LOCAL,
            },
            {
              label: <FormattedMessage {...messages.optionsCloud} />,
              value: CONNECTION_MODE.CLOUD,
            },
          ]}
          value={value}
          variant="outlined"
        />
      </Box>
    </>
  );
};

ConnectionModeField.propTypes = {
  deviceType: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  isLocalModeInit: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOf([CONNECTION_MODE.CLOUD, CONNECTION_MODE.LOCAL]).isRequired,
};

ConnectionModeField.defaultProps = {
  isLocalModeInit: false,
};

export default ConnectionModeField;

function canOpenWarningDialog(isLocalModeInit, value, deviceType) {
  if (deviceType === DEVICE_TYPE.IDT) {
    return value === CONNECTION_MODE.LOCAL;
  }

  return !isLocalModeInit && value === CONNECTION_MODE.CLOUD;
}

function getSwitchingModeByDeviceType(deviceType) {
  return deviceType === DEVICE_TYPE.IDT ? switchingModes.TO_CLOUD : switchingModes.TO_LOCAL;
}
