import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import WarningIcon from '@mui/icons-material/Warning';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ACCESS_LOG_RESULT } from '../../../config/consts';
import messages from '../messages';
import { AccessLogPropType } from '../type';

const styles = {
  baseIcon: {
    color: 'error.dark',
  },
};

const AccessLogResultIcon = ({ accessLog }) => {
  const renderTooltip = (title, icon) => (
    <Tooltip placement="right" title={title}>
      {icon}
    </Tooltip>
  );

  if ([ACCESS_LOG_RESULT.GRANTED, ACCESS_LOG_RESULT.PUBLIC].includes(accessLog?.result)) {
    return renderTooltip(
      <FormattedMessage {...messages.accessLogResultIconSuccessful} />,
      <CheckCircleIcon sx={{ ...styles.baseIcon, color: 'secondary.main' }} />
    );
  }

  if (accessLog?.result === ACCESS_LOG_RESULT.UNAUTHORIZED) {
    return renderTooltip(
      <FormattedMessage {...messages.accessLogResultIconUnauthorized} />,
      <WarningIcon sx={styles.baseIcon} />
    );
  }

  return renderTooltip(
    <FormattedMessage {...messages.accessLogResultIconDenied} />,
    <DoDisturbIcon sx={styles.baseIcon} />
  );
};

AccessLogResultIcon.propTypes = {
  accessLog: AccessLogPropType.isRequired,
};

export default AccessLogResultIcon;
