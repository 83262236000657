export const getCompanyDataSelector = ({ company }) => {
  const { data } = company;
  const address = typeof data.address === 'object' ? data.address : {};
  const values = {
    address: getAddress(address),
    billingInfoEditable: data.billingInfoEditable || false,
    currency: data.currency || null,
    name: data.name || null,
    vatId: data.vatId || null,
  };

  return {
    data: values,
    isFilled: isFilled(values),
  };
};

const getAddress = (address) => ({
  city: address && address.city ? address.city : '',
  country: address && address.country ? address.country : '',
  postalCode: address && address.postalCode ? address.postalCode : '',
  street: address && address.street ? address.street : '',
});

const isFilled = (values) =>
  !!(
    values.name &&
    values.currency &&
    values.address.city &&
    values.address.country &&
    values.address.street &&
    values.address.postalCode
  );
