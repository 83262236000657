import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ApartmentNameChip from '../../../../components/ApartmentNameChip';
import DataGrid from '../../../../components/DataGrid';
import NameColumn from '../../../../components/DataGrid/NameColumn';
import DeviceModelName from '../../../../components/DeviceModelName';
import DeviceStatus from '../../../../components/DeviceStatus';
import DeviceTypeIcon from '../../../../components/DeviceTypeIcon';
import IconServices from '../../../../components/IconServices';
import { PATHS } from '../../../../config/consts';
import { useDataGrid } from '../../../../helpers/dataGrid';
import { useUrlParams } from '../../../../helpers/urlParams';
import useBreakpoints from '../../../../helpers/useBreakpoints';
import { isNotVerified } from '../../../../modules/devices/helpers/helpers';
import { isFetchingMessages } from '../../../../modules/localization/selectors';
import DataGridRowMenu from './DataGridRowMenu/DataGridRowMenu';
import messages from './messages';

const SiteUserDevicesDataGrid = ({
  data,
  didInvalid,
  filter,
  isFetching,
  onLoadDevices,
  order,
  page,
  resultsFiltered,
  resultsTotal,
  rowsPerPage,
}) => {
  const { companyId, siteId, userId } = useUrlParams();
  const { onOrderBy, onRowsPerPageChange, onSelectPage } = useDataGrid(
    onLoadDevices,
    {
      companyId,
      filter,
      order,
      page,
      rowsPerPage,
      siteId,
      userId,
    },
    didInvalid,
    isFetching
  );

  const { upMd, upSm } = useBreakpoints();
  const isLocalizationLoaded = useSelector(isFetchingMessages);

  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const getDeviceDetailLink = (deviceCompanyId, deviceSiteId, deviceId) =>
    PATHS.DEVICE_DETAIL.replace(':companyId', deviceCompanyId)
      .replace(':siteId', deviceSiteId)
      .replace(':deviceId', deviceId);

  const loadingWrapper = (children) => (isFetching ? <Skeleton animation="wave" height={25} width="80%" /> : children);

  const columns = useMemo(
    () => [
      {
        disableColumnMenu: true,
        field: 'name',
        flex: 1,
        headerName: formatMessage(messages.siteUserDevicesDataGridColumnsDeviceName),
        renderCell: (device) =>
          loadingWrapper(
            device?.value && (
              <Tooltip title={<DeviceModelName deviceType={device.value?.deviceType} type={device.value?.type} />}>
                <Box
                  component="span"
                  sx={{
                    alignItems: 'center',
                    display: 'flex',
                    overflow: 'hidden',
                  }}
                >
                  <NameColumn
                    iconComponent={
                      <DeviceTypeIcon
                        deviceType={device.value?.deviceType}
                        error={isNotVerified(device.value?.verificationStatus)}
                        small
                        type={device.value?.type}
                      />
                    }
                    name={device.value?.name}
                  />
                </Box>
              </Tooltip>
            )
          ),
        sortable: true,
      },
      {
        disableColumnMenu: true,
        field: 'apartment',
        flex: 1,
        headerName: formatMessage(messages.siteUserDevicesDataGridColumnsApartment),
        renderCell: (device) =>
          loadingWrapper(
            device?.value && (
              <ApartmentNameChip name={device.value?.apartment?.name} number={device.value?.apartment?.number} />
            )
          ),
        sortable: false,
      },
      {
        disableColumnMenu: true,
        field: 'services',
        flex: 1,
        headerName: formatMessage(messages.siteUserDevicesDataGridColumnsActiveServices),
        renderCell: (device) => loadingWrapper(device?.value && <IconServices item={device.value} />),
        sortable: false,
      },
      {
        disableColumnMenu: true,
        field: 'status',
        headerName: formatMessage(messages.siteUserDevicesDataGridColumnsDeviceStatus),
        renderCell: (device) => loadingWrapper(device?.value && <DeviceStatus device={device.value} />),
        sortable: false,
        width: 170,
      },
      {
        disableColumnMenu: true,
        field: 'rowMenu',
        headerName: '',
        renderCell: (device) =>
          loadingWrapper(
            device?.value && (
              <DataGridRowMenu
                deviceDetailLink={getDeviceDetailLink(
                  device.value?.site?.company?.id,
                  device.value?.site?.id,
                  device?.id
                )}
              />
            )
          ),
        sortable: false,
        width: 65,
      },
    ],
    [upSm, upMd, isFetching, isLocalizationLoaded]
  );

  const rows = useMemo(
    () =>
      data.map((device) => ({
        apartment: device,
        id: device.id,
        isClickable: true,
        name: device,
        rowMenu: device,
        services: device,
        status: device,
      })),
    [data]
  );

  return (
    <DataGrid
      columns={columns}
      columnVisibilityModel={{
        apartment: upSm,
        services: upMd,
      }}
      disableRowSelectionOnClick
      hideFooterPagination={isFetching}
      loading={isFetching}
      onCellClick={(params) => {
        if (params.field !== 'rowMenu') {
          navigate(getDeviceDetailLink(params.value?.site?.company?.id, params.value?.site?.id, params?.id));
        }
      }}
      onOrderBy={onOrderBy}
      onRowsPerPageChange={onRowsPerPageChange}
      onSelectPage={onSelectPage}
      order={order}
      page={page}
      resultsFiltered={resultsFiltered}
      resultsTotal={resultsTotal}
      rows={rows}
      rowsPerPage={rowsPerPage}
    />
  );
};

SiteUserDevicesDataGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  didInvalid: PropTypes.bool.isRequired,
  filter: PropTypes.shape({ fulltext: PropTypes.string }).isRequired,
  isFetching: PropTypes.bool.isRequired,
  onLoadDevices: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  resultsFiltered: PropTypes.number.isRequired,
  resultsTotal: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default SiteUserDevicesDataGrid;
