import { defineMessages } from 'react-intl';

export default defineMessages({
  deviceAccessGroupsModalAddButtonLabel: {
    defaultMessage: 'Set Groups',
    description: 'Device access groups modal - add button label',
    id: 'deviceAccessGroupsModal.addButtonLabel',
  },
  deviceAccessGroupsModalHelperText: {
    defaultMessage: 'One device can be included in one or more Access groups',
    description: 'Access group select helperText',
    id: 'deviceAccessGroupsModal.helperText',
  },
  deviceAccessGroupsModalText: {
    defaultMessage:
      'Access groups define which users have access to which devices.' +
      '<p>All users in selected groups with valid credentials will have access' +
      ' to this device. Access is activated within seconds.</p>',
    description: 'Device access groups modal - text',
    id: 'deviceAccessGroupsModal.text',
  },
  deviceAccessGroupsModalTitle: {
    defaultMessage: 'Set Access groups for device',
    description: 'Device access groups modal - title',
    id: 'deviceAccessGroupsModal.title',
  },
});
