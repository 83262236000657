import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PATHS } from '../../config/consts';
import { useUrlParams } from '../../helpers/urlParams';
import ButtonLink from '../ButtonLink';
import messages from './messages';
import { styles } from './styles/content';

const PaymentsContent = ({ companyName, isBillingInfo }) => {
  const { companyId, siteId } = useUrlParams();
  return (
    <Grid alignItems="center" container direction="column" spacing={2} sx={styles.content}>
      {!isBillingInfo ? (
        <>
          <Grid item>
            <Typography sx={styles.textColor} variant="body2">
              <FormattedMessage
                {...messages.noPaymentsContentPaymentsContentNoBillingInfoPayments}
                values={{
                  br: <br />,
                }}
              />
            </Typography>
          </Grid>
          <Grid item>
            <Typography component="div" sx={styles.textColor} variant="body2">
              <Box fontWeight="bold">
                <FormattedMessage
                  {...messages.noPaymentsContentPaymentsContentContactAdminInfo}
                  values={{
                    br: <br />,
                    companyName,
                  }}
                />
              </Box>
            </Typography>
          </Grid>
        </>
      ) : (
        <>
          <Grid item>
            <Typography sx={styles.textColor} variant="body2">
              <FormattedMessage {...messages.noPaymentsContentPaymentsContentNoBillingInfo} />
            </Typography>
          </Grid>
          <Grid container item justifyContent="center" spacing={2}>
            <Grid item>
              <ButtonLink
                color="primary"
                to={PATHS.SUBSCRIPTION_MOBILE_VIDEO.replace(':companyId', companyId).replace(':siteId', siteId)}
                variant="outlined"
              >
                <FormattedMessage {...messages.noPaymentsContentPaymentsContentBuySubscription} />
              </ButtonLink>
            </Grid>
            <Grid item>
              <ButtonLink
                color="primary"
                to={PATHS.SUBSCRIPTION_ACTIVATION_CODE.replace(':companyId', companyId).replace(':siteId', siteId)}
                variant="outlined"
              >
                <FormattedMessage {...messages.contentNoPaymentsContentUseVoucher} />
              </ButtonLink>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
};

PaymentsContent.propTypes = {
  companyName: PropTypes.string.isRequired,
  isBillingInfo: PropTypes.bool.isRequired,
};

export default PaymentsContent;
