import { defineMessages } from 'react-intl';

export default defineMessages({
  StepperStep1: {
    defaultMessage: 'Intro',
    description: 'Site users import step 1 label',
    id: 'stepperStep1',
  },
  StepperStep2: {
    defaultMessage: 'Upload data',
    description: 'Site users import step 2 label',
    id: 'stepperStep2',
  },
  StepperStep3: {
    defaultMessage: 'Check data',
    description: 'Site users import step 3 label',
    id: 'stepperStep3',
  },
  StepperStep4: {
    defaultMessage: 'Summary',
    description: 'Site users import step 3 label',
    id: 'stepperStep4',
  },
  StepperStep5: {
    defaultMessage: 'Result',
    description: 'Site users import step 3 label',
    id: 'stepperStep5',
  },
});
