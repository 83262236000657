import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Stack from '@mui/material/Stack';
import React from 'react';
import { TypographyWithEllipsisTooltip } from '../TypographyWithEllipsisTooltip';

interface IUserData {
  email: string;
  firstName: string;
  lastName: string;
}

function StandardUser({ email, firstName, lastName }: IUserData) {
  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="row" spacing={2}>
        <AccountCircleIcon sx={{ color: 'secondary.main' }} />
        <TypographyWithEllipsisTooltip sx={{ fontWeight: 'bold' }} variant="body1" data-testid="name">
          {`${firstName} ${lastName}`}
        </TypographyWithEllipsisTooltip>
      </Stack>
      <Stack direction="row" spacing={2}>
        <MailOutlineIcon sx={{ color: 'secondary.main' }} />
        <TypographyWithEllipsisTooltip variant="body1" data-testid="email">
          {email}
        </TypographyWithEllipsisTooltip>
      </Stack>
    </Stack>
  );
}

export default StandardUser;
