import List from '@mui/material/List';
import PropTypes from 'prop-types';
import React from 'react';
import ButtonListHeader from './ButtonListHeader';
import ButtonListItem from './ButtonListItem';

const ButtonList = ({
  deviceChipOptions,
  isDeviceDetailEditing,
  list,
  onChangeItem,
  ringingGroupSizeLimit,
  setDeviceEditing,
}) => (
  <>
    <ButtonListHeader />
    <List>
      {list.map((item) => (
        <ButtonListItem
          data={item}
          deviceChipOptions={deviceChipOptions}
          isDeviceDetailEditing={isDeviceDetailEditing}
          key={item.index}
          onChangeItem={onChangeItem}
          ringingGroupSizeLimit={ringingGroupSizeLimit}
          setDeviceEditing={setDeviceEditing}
        />
      ))}
    </List>
  </>
);

ButtonList.propTypes = {
  deviceChipOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  isDeviceDetailEditing: PropTypes.bool.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number,
      name: PropTypes.string,
      ringingGroup: PropTypes.string,
      selectedDevices: PropTypes.arrayOf(PropTypes.object),
    })
  ).isRequired,
  onChangeItem: PropTypes.func.isRequired,
  ringingGroupSizeLimit: PropTypes.number.isRequired,
  setDeviceEditing: PropTypes.func.isRequired,
};

export default ButtonList;
