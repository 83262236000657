import { Tooltip } from '@mui/material';
import React, { ReactElement } from 'react';
import { useRef, useState } from 'react';
import useBreakpoints from '../../helpers/useBreakpoints';
import { isEllipsisActive } from './utils';

export interface ITooltipForEllipsisElementProps {
  children: ReactElement;
  /**
   * By default first children node is used.
   *  But if you want to specify another or deeper children text element where ellipsis is active,
   *  you can return proper element in callback
   */
  onGetTextElementWithEllipsis?: (child: HTMLElement | Element | undefined) => HTMLElement | Element | undefined;
  /**
   * By default title is getting via textContent property on that component.
   * If you need another title in tooltip you can specify it.
   */
  title?: string;
}

export function TooltipForEllipsisElement(props: ITooltipForEllipsisElementProps) {
  const childrenRef = useRef<HTMLElement | Element | undefined>(undefined);
  const { downSm } = useBreakpoints();
  const [hasActiveEllipsis, setHasActiveEllipsis] = useState<boolean>(false);

  return (
    <Tooltip
      title={(hasActiveEllipsis && (props.title || childrenRef?.current?.textContent)) || ''}
      ref={childrenRef}
      enterTouchDelay={downSm ? 0 : undefined}
      onMouseEnter={() => {
        if (childrenRef && childrenRef.current) {
          const element = props.onGetTextElementWithEllipsis
            ? props.onGetTextElementWithEllipsis(childrenRef.current)
            : childrenRef.current;
          element && setHasActiveEllipsis(isEllipsisActive(element));
        }
      }}
    >
      {props.children}
    </Tooltip>
  );
}
