import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DatePicker from '@mui/lab/DatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { addMonths } from 'date-fns';
import { getIn } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { styles } from './styles';

const DateRangePicker = ({
  field: { value },
  form: { errors, setFieldValue },
  fromLabel,
  fromName,
  helperFromText,
  helperToText,
  inputFromProps,
  inputToProps,
  monthsName,
  toLabel,
  toName,
}) => {
  const months = getIn(value, monthsName);
  const from = getIn(value, fromName);
  const to = getIn(value, toName);

  const errorTo = getIn(errors, toName);
  const errorFrom = getIn(errors, fromName);

  const handleDateChange = (date) => {
    setFieldValue(toName, date);
    setFieldValue(monthsName, 0);
  };

  const handleMonthsChange = (numberOfMonths) => {
    if (numberOfMonths !== months) {
      setFieldValue(monthsName, numberOfMonths);
      setFieldValue(toName, addMonths(from, numberOfMonths));
    }
  };

  return (
    <>
      <Grid alignItems="center" container item sx={styles.root}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Grid container sx={styles.datePickersGrid}>
            <FormControl>
              {fromLabel && (
                <FormLabel component="legend" error={errorFrom !== undefined} sx={styles.formLabel}>
                  {fromLabel}
                </FormLabel>
              )}
              <DatePicker
                disabled
                disableToolbar
                inputFormat="dd/MM/yyyy"
                inputVariant="outlined"
                margin="normal"
                maxDate={from}
                minDate={from}
                name="dateCreated"
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                onChange={() => {}}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...inputFromProps}
                    error={errorFrom !== undefined}
                    helperText={errorFrom !== undefined ? errorFrom : helperFromText}
                  />
                )}
                sx={styles.datePickerField}
                value={from}
                variant="inline"
              />
            </FormControl>
          </Grid>
          <Grid container sx={styles.datePickersGrid}>
            <FormControl>
              {toLabel && (
                <FormLabel component="legend" error={errorTo !== undefined} sx={styles.formLabel}>
                  {toLabel}
                </FormLabel>
              )}
              <DatePicker
                disableToolbar
                inputFormat="dd/MM/yyyy"
                inputVariant="outlined"
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                margin="normal"
                maxDate={addMonths(from, 36)}
                minDate={from}
                name="validityTo"
                onChange={handleDateChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...inputToProps}
                    error={errorTo !== undefined}
                    helperText={errorTo !== undefined ? errorTo : helperToText}
                  />
                )}
                sx={styles.datePickerField}
                value={to}
                variant="inline"
              />
            </FormControl>
          </Grid>
          <Grid container item sx={styles.dateIntervalsButtonsGrid} xs={4}>
            <Button color={months === 1 ? 'primary' : 'secondary'} onClick={() => handleMonthsChange(1)}>
              <FormattedMessage {...messages.intervalButtonsOneMonth} />
            </Button>
            <Divider orientation="vertical" />
            <Button color={months === 6 ? 'primary' : 'secondary'} onClick={() => handleMonthsChange(6)}>
              <FormattedMessage {...messages.intervalButtonsSixMonth} />
            </Button>
            <Divider orientation="vertical" />
            <Button color={months === 12 ? 'primary' : 'secondary'} onClick={() => handleMonthsChange(12)}>
              <FormattedMessage {...messages.intervalButtonsOneYear} />
            </Button>
            <Divider orientation="vertical" />
            <Button color={months === 24 ? 'primary' : 'secondary'} onClick={() => handleMonthsChange(24)}>
              <FormattedMessage {...messages.intervalButtonsTwoYears} />
            </Button>
            <Divider orientation="vertical" />
            <Button color={months === 36 ? 'primary' : 'secondary'} onClick={() => handleMonthsChange(36)}>
              <FormattedMessage {...messages.intervalButtonsThreeYears} />
            </Button>
          </Grid>
        </LocalizationProvider>
      </Grid>
    </>
  );
};

DateRangePicker.propTypes = {
  field: PropTypes.shape({
    value: PropTypes.shape({}),
  }).isRequired,
  form: PropTypes.shape({
    errors: PropTypes.shape({}),
    setFieldValue: PropTypes.func,
  }).isRequired,
  fromLabel: PropTypes.node,
  fromName: PropTypes.string.isRequired,
  helperFromText: PropTypes.node,
  helperToText: PropTypes.node,
  inputFromProps: PropTypes.shape({}),
  inputToProps: PropTypes.shape({}),
  monthsName: PropTypes.string.isRequired,
  toLabel: PropTypes.node,
  toName: PropTypes.string.isRequired,
};

DateRangePicker.defaultProps = {
  fromLabel: null,
  helperFromText: null,
  helperToText: null,
  inputFromProps: {},
  inputToProps: {},
  toLabel: null,
};

export default DateRangePicker;
