import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DeleteDeviceModal } from '../../../components';
import messages from './messages';

const styles = {
  deleteButton: {
    '&:hover': {
      color: 'secondary.main',
    },
  },
  list: {
    typography: 'body1',
  },
  text: {
    mb: 1.5,
  },
};

const DeleteDevice = ({ deviceId, onDelete }) => (
  <>
    <Box sx={{ display: { md: 'block', xs: 'none' } }}>
      <Button color="secondary" onClick={() => onDelete(deviceId)} startIcon={<DeleteIcon />} sx={styles.deleteButton}>
        <FormattedMessage {...messages.deleteDeviceDeleteButton} />
      </Button>
    </Box>
    <Box sx={{ display: { md: 'none', xs: 'block' } }}>
      <IconButton onClick={() => onDelete(deviceId)} sx={styles.deleteButton}>
        <DeleteIcon />
      </IconButton>
    </Box>
    <DeleteDeviceModal />
  </>
);

DeleteDevice.propTypes = {
  deviceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default DeleteDevice;
