import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReadOnlyField } from '../../../../components';
import messages from './messages';

const ApplicationVersionField = ({ deviceApp }) => {
  const name = `${deviceApp?.name || ''} ${deviceApp?.version || ''}`.trim();
  return (
    <Box pb={2}>
      <ReadOnlyField
        label={<FormattedMessage {...messages.applicationVersionFieldLabel} />}
        value={
          <Box display="flex">
            <Typography>{name || <FormattedMessage {...messages.applicationVersionFieldUnknown} />}</Typography>
            {deviceApp?.os && (
              <Typography color="secondary" sx={{ ml: 1 }}>
                {`(${deviceApp.os})`.trim()}
              </Typography>
            )}
          </Box>
        }
      />
    </Box>
  );
};

ApplicationVersionField.propTypes = {
  deviceApp: PropTypes.shape({
    name: PropTypes.string,
    os: PropTypes.string,
    version: PropTypes.string,
  }).isRequired,
};

export default ApplicationVersionField;
