import Box from '@mui/material/Box';
import React from 'react';
import { IMVLicenceModel } from '../../../../modules/sites/store';
import { ICodeData } from '../../../../modules/subscription/store';
import ActivationStatusError from './ActivationStatusError';
import ActivationStatusSuccess from './ActivationStatusSuccess';

interface IThreeStep {
  codeData: ICodeData;
  error: number;
  mobileVideoLicence: IMVLicenceModel;
}

const ThreeStep = ({ codeData, error, mobileVideoLicence }: IThreeStep) => (
  <Box py={5}>
    {!!error && <ActivationStatusError error={error} />}
    {!error && <ActivationStatusSuccess codeData={codeData} mobileVideoLicence={mobileVideoLicence} />}
  </Box>
);

export default ThreeStep;
