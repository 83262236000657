import PropTypes from 'prop-types';
import React from 'react';
import RowMenu from '../../DataGrid/RowMenu';
import EditDevice from './EditDevice';

const DataGridRowMenu = ({ deviceDetailLink }) => (
  <RowMenu
    menuItems={
      <div>
        <EditDevice deviceDetailLink={deviceDetailLink} />
      </div>
    }
  />
);

DataGridRowMenu.propTypes = {
  deviceDetailLink: PropTypes.string.isRequired,
};

export default DataGridRowMenu;
