export default {
  root: {
    bgcolor: 'common.white',
    height: '100%',
    p: {
      lg: 3,
      xs: 2,
    },
    width: '100%',
  },
};
