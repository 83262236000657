import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';

function Option({ createItemIcon, itemIcon, newItemId, newItemId2, option, optionLabelKey, renderProps }) {
  const isNewItem = option.id === newItemId;
  const isNewItemId2 = option.id === newItemId2;
  return (
    <Box
      component="li"
      key={option.id}
      sx={{ color: isNewItem || isNewItemId2 ? 'primary.main' : 'text.primary' }}
      {...renderProps}
    >
      {isNewItem || isNewItemId2 ? createItemIcon : itemIcon}
      <Box pl={(((isNewItem || isNewItemId2) && createItemIcon) || ((!isNewItem || !isNewItemId2) && itemIcon)) && 2}>
        {option?.[optionLabelKey] || ''}
      </Box>
    </Box>
  );
}

Option.propTypes = {
  createItemIcon: PropTypes.element,
  itemIcon: PropTypes.element,
  newItemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  newItemId2: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  option: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  optionLabelKey: PropTypes.string.isRequired,
  renderProps: PropTypes.shape({}).isRequired,
};

Option.defaultProps = {
  createItemIcon: null,
  itemIcon: null,
  newItemId2: '',
};

export default Option;
