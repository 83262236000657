import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  root: (theme) => ({
    [theme.breakpoints.down('sm')]: {
      mb: 5,
      mt: 3,
      mx: 1.5,
    },
    '& .MuiButton-outlined': {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      fontWeight: 'regular',
      justifyContent: 'space-between',
    },
    '& .MuiButton-startIcon': {
      '& > *:first-of-type': {
        fontSize: theme.typography.fontSize * 5,
      },
      color: 'secondary.main',
      marginLeft: 0,
      marginRight: 0,
    },
    '&:hover': {
      '& .MuiButton-startIcon, & span': {
        color: 'primary.main',
      },
      borderColor: 'primary.main',
    },
    borderColor: 'secondary.main',
    borderRadius: 0,
    flexBasis: 0,
    flexGrow: 1,
    flexShrink: 1,
    height: '120px',
    mb: 6,
    mt: 2,
    mx: 1.5,
    padding: 2,
    position: 'relative',
    textAlign: 'center',
    textTransform: 'none',
    width: '120px',
  }),
  title: {
    lineHeight: '1.5rem',
    paddingTop: '30px',
    position: 'absolute',
    top: '95px',
  },
};

const Device = ({ icon, onSelectDevice, title, value }) => (
  <Button onClick={() => onSelectDevice(value)} startIcon={icon} sx={styles.root} variant="outlined">
    <Box component="span" sx={styles.title}>
      {title}
    </Box>
  </Button>
);

Device.propTypes = {
  icon: PropTypes.node.isRequired,
  onSelectDevice: PropTypes.func.isRequired,
  title: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
};

export default Device;
