import { getOffset } from '../../helpers/dataGrid';
import types from './types';

export const loadSite = (companyId, siteId) => ({
  companyId,
  siteId,
  type: types.LOAD_SITE_REQUEST,
});
export const addSiteFormRequest = (values) => ({ type: types.ADD_SITE_FORM_REQUEST, values });
export const deleteSiteConfirm = (site) => ({ site, type: types.DELETE_SITE_REQUEST });
export const deleteSiteHideModal = () => ({ type: types.DELETE_SITE_HIDE_MODAL });
export const deleteSiteShowModal = (site) => ({ site, type: types.DELETE_SITE_SHOW_MODAL });
export const editSiteFormRequest = (values) => ({ type: types.EDIT_SITE_FORM_REQUEST, values });
export const editSiteHideModal = () => ({ type: types.EDIT_SITE_HIDE_MODAL });
export const editSiteShowModal = (site) => ({ site, type: types.EDIT_SITE_SHOW_MODAL });
export const getSitesUsersNames = () => ({ type: types.GET_SITES_USERS_REQUEST });
export const setSiteTypeModalClearData = () => ({ type: types.SET_SITE_TYPE_MODAL_CLEAR_DATA });
export const setTypeSite = (siteType, site) => ({
  site,
  siteType,
  type: types.SET_SITE_TYPE_REQUEST,
});
export const verifyNameSiteRequest = (name) => ({ name, type: types.VERIFY_NAME_SITE_REQUEST });

export const getSitesList = (companyId, page = 1, rowsPerPage = 15, filter = { fulltext: '' }, order = 'name') => ({
  companyId,
  context: 'list',
  filter,
  order,
  page,
  params: {
    filter: filter.fulltext,
    limit: rowsPerPage,
    offset: getOffset(page, rowsPerPage),
    order,
  },
  rowsPerPage,
  type: types.GET_SITES_REQUEST,
});

export const getSitesRecentList = (
  companyId,
  page = 1,
  rowsPerPage = 5,
  filter = { fulltext: '' },
  order = '-lastEventDate'
) => ({
  companyId,
  context: 'recentSites',
  filter,
  order,
  page,
  params: {
    filter: filter.fulltext,
    limit: rowsPerPage,
    offset: getOffset(page, rowsPerPage),
    order,
  },
  rowsPerPage,
  type: types.GET_SITES_RECENT_REQUEST,
});

export const getSiteDashboard = (companyId, siteId) => ({
  companyId,
  siteId,
  type: types.GET_SITE_DASHBOARD_REQUEST,
});

export const getCurrentSiteLicenseModel = () => ({
  type: types.GET_CURRENT_SITE_LICENSE_MODEL_REQUEST,
});

export const setLastVisitedSite = (siteId) => ({
  siteId,
  type: types.SET_LAST_VISITED_SITE_REQUEST,
});

export function clearSiteData() {
  return {
    type: types.CLEAR_SITE_DATA,
  };
}

export function finishSetSiteType() {
  return {
    type: types.SET_SITE_TYPE_SUCCESS,
  };
}
