import { lighten, styled } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../config/consts';
import { SERVICE } from '../../config/devices';
import featureFlags from '../../config/featureFlags';
import { useDataGrid } from '../../helpers/dataGrid';
import useBreakpoints from '../../helpers/useBreakpoints';
import { useModal } from '../../hooks/useModal';
import { isNotVerified, showErrorBadge, showWarningBadge } from '../../modules/devices/helpers/helpers';
import { selectors as elevatorIntegrationSelectors } from '../../modules/elevatorIntegration';
import { isFetchingMessages } from '../../modules/localization/selectors';
import ApartmentNameChip from '../ApartmentNameChip';
import AvailableCreditDialog from '../AvailableCreditModal';
import DataGrid from '../DataGrid';
import NameColumn from '../DataGrid/NameColumn';
import RowMenu from '../DataGrid/RowMenu';
import DeleteDeviceModal from '../DeleteDeviceModal';
import DeviceModelName from '../DeviceModelName';
import DeviceStatus from '../DeviceStatus';
import DeviceTypeIcon from '../DeviceTypeIcon';
import { useHasFeature } from '../Feature/Feature';
import IconServiceElevatorIntegration from '../IconServiceElevatorIntegration';
import IconServices from '../IconServices';
import RCModal from '../RCModal';
import ActionButton from './ActionButton';
import DeviceRowMenu from './DeviceRowMenu/DeviceRowMenu';
import { isActive } from './helpers';
import messages from './messages';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .dataGrid-form-row-warn': {
    '&:hover': {
      backgroundColor: lighten(theme.palette.warning.light, 0.8),
    },
    backgroundColor: lighten(theme.palette.warning.light, 0.9),
  },
  cursor: 'pointer',
}));

const DeviceDataGrid = ({
  companyId,
  context,
  data,
  didInvalid,
  filter,
  isFetching,
  onLoadDevices,
  order,
  page,
  resultsFiltered,
  resultsTotal,
  rowsPerPage,
  siteId,
}) => {
  const { onOrderBy, onRowsPerPageChange, onSelectPage } = useDataGrid(
    onLoadDevices,
    {
      companyId,
      context,
      emptySite: siteId === null,
      filter,
      order,
      page,
      rowsPerPage,
      siteId,
    },
    didInvalid,
    isFetching
  );

  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { upLg, upMd, upSm } = useBreakpoints();
  const isLocalizationLoaded = useSelector(isFetchingMessages);
  const isElevatorIntegrationFFEnable = useHasFeature(featureFlags.ELEVATOR_INTEGRATION);
  const hasSiteElevatorIntegration = useSelector(elevatorIntegrationSelectors.isSiteElevatorIntegrationActive);

  const { Modal: AvailableCreditModal, onOpen: onOpenAvailableCreditModal } = useModal({
    Modal: AvailableCreditDialog,
  });

  const columns = useMemo(
    () => [
      {
        disableColumnMenu: true,
        field: 'name',
        flex: 1,
        headerName: formatMessage(messages.deviceDataGridColumnsDeviceName),
        renderCell: (device) =>
          isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <Tooltip title={<DeviceModelName deviceType={device.value?.deviceType} type={device.value?.type} />}>
              <Box
                component="span"
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  overflow: 'hidden',
                }}
              >
                <NameColumn
                  iconComponent={
                    <DeviceTypeIcon
                      deviceType={device.value?.deviceType}
                      error={isNotVerified(device.value?.verificationStatus)}
                      showErrorBadge={showErrorBadge(
                        device.value?.services?.[SERVICE.ACS]?.firmwareStatus,
                        device.value?.verificationStatus
                      )}
                      showWarningBadge={showWarningBadge(
                        device.value?.services?.[SERVICE.ACS]?.firmwareStatus,
                        device.value?.verificationStatus
                      )}
                      small
                      type={device.value?.type}
                    />
                  }
                  name={device.value?.name}
                />
              </Box>
            </Tooltip>
          ),
        sortable: true,
      },
      {
        disableColumnMenu: true,
        field: 'apartment',
        flex: 1,
        headerName: formatMessage(messages.deviceDataGridColumnsApartment),
        renderCell: (device) =>
          isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <ApartmentNameChip name={device.value?.apartment?.name} number={device.value?.apartment?.number} />
          ),
        sortable: false,
      },
      {
        disableColumnMenu: true,
        field: 'services',
        flex: 1,
        headerName: formatMessage(messages.deviceDataGridColumnsActiveServices),
        renderCell: (device) =>
          isFetching ? <Skeleton animation="wave" height={25} width="80%" /> : <IconServices item={device.value} />,
        sortable: false,
      },
      {
        disableColumnMenu: true,
        field: 'integrations',
        flex: 1,
        headerName: formatMessage(messages.deviceDataGridColumnsIntegrations),
        renderCell: (device) =>
          isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <IconServiceElevatorIntegration
              elevatorIntegrationService={device.value.services?.[SERVICE.ELEVATOR_INTEGRATION]}
            />
          ),
        sortable: false,
      },
      {
        disableColumnMenu: true,
        field: 'status',
        flex: 1,
        headerName: formatMessage(messages.deviceDataGridColumnsDeviceStatus),
        renderCell: (device) =>
          isFetching
            ? <Skeleton animation="wave" height={25} width="80%" />
            : <DeviceStatus device={device.value} />,
        sortable: false,
      },
      {
        align: 'right',
        disableColumnMenu: true,
        field: 'buttons',
        flex: 1,
        headerName: '',
        renderCell: (device) =>
          isActive(device.value?.services) &&
          (isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <ActionButton device={device.value} onOpenAvailableCreditModal={onOpenAvailableCreditModal} />
          )),
        sortable: false,
      },
      {
        disableColumnMenu: true,
        field: 'rowMenu',
        headerName: '',
        renderCell: (device) =>
          isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <RowMenu
              menuItems={
                <DeviceRowMenu
                  device={device.value}
                  onOpenAvailableCreditModal={onOpenAvailableCreditModal}
                  showActionButton={isActive(device.value?.services) && !upLg}
                />
              }
            />
          ),
        sortable: false,
        width: 65,
      },
    ],
    [upSm, upMd, upLg, isFetching, isLocalizationLoaded]
  );

  const rows = useMemo(
    () =>
      data.map((device) => ({
        apartment: device,
        buttons: device,
        id: device.id,
        integrations: device,
        isClickable: isActive(device?.services),
        name: device,
        rowMenu: device,
        services: device,
        status: device,
      })),
    [data]
  );

  return (
    <>
      <StyledDataGrid
        columns={columns}
        columnVisibilityModel={{
          apartment: upSm,
          buttons: upLg,
          integrations: upMd && isElevatorIntegrationFFEnable && hasSiteElevatorIntegration,
          services: upMd,
        }}
        disableRowSelectionOnClick
        hideFooterPagination={isFetching}
        loading={isFetching}
        onCellClick={(params) => {
          if (params.field !== 'rowMenu') {
            if (isActive(params.value?.services)) {
              navigate(
                PATHS.DEVICE_DETAIL.replace(':companyId', params.value?.site?.company?.id)
                  .replace(':siteId', params.value?.site?.id)
                  .replace(':deviceId', params.value?.id)
              );
            }
          }
        }}
        onOrderBy={onOrderBy}
        onRowsPerPageChange={onRowsPerPageChange}
        onSelectPage={onSelectPage}
        order={order}
        page={page}
        resultsFiltered={resultsFiltered}
        resultsTotal={resultsTotal}
        rows={rows}
        rowsPerPage={rowsPerPage}
      />
      <RCModal />
      <AvailableCreditModal />
      <DeleteDeviceModal />
    </>
  );
};

DeviceDataGrid.propTypes = {
  companyId: PropTypes.number.isRequired,
  context: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  didInvalid: PropTypes.bool.isRequired,
  filter: PropTypes.shape({ fulltext: PropTypes.string }).isRequired,
  isFetching: PropTypes.bool.isRequired,
  onLoadDevices: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  resultsFiltered: PropTypes.number.isRequired,
  resultsTotal: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  siteId: PropTypes.number,
};

DeviceDataGrid.defaultProps = {
  context: '',
  siteId: null,
};

export default DeviceDataGrid;
