import { defineMessages } from 'react-intl';

export default defineMessages({
  racDeactivateModalDeactivate: {
    defaultMessage: 'Deactivate',
    description: 'RAC deactivate modal - activate button',
    id: 'racDeactivateModal.deactivate',
  },
  racDeactivateModalGroupInfoEmpty: {
    defaultMessage: 'At the moment, no users will lose access via this device.',
    description: 'RAC deactivate modal - info empty',
    id: 'racDeactivateModal.groupInfo.empty',
  },
  racDeactivateModalGroupInfoGroup: {
    defaultMessage: 'Users in these Access groups will lose access via this device:',
    description: 'RAC deactivate modal - info group',
    id: 'racDeactivateModal.groupInfo.group',
  },
  racDeactivateModalText: {
    defaultMessage: 'You won’t be able to remotely manage access rights on this device.' + '{br}{br}{info}',
    description: 'RAC deactivate modal - text',
    id: 'racDeactivateModal.text',
  },
  racDeactivateModalTitle: {
    defaultMessage: 'Deactivate Residential Access',
    description: 'RAC deactivate modal - title',
    id: 'racDeactivateModal.title',
  },
});
