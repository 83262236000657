import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert, CustomDate } from '../../../../components';
import messages from './messages';

const TrialModeWarningAlert = ({ expirationDate }) => (
  <Alert severity="warning" title={<FormattedMessage {...messages.codeVerificationWarningTrialHeader} />}>
    <FormattedMessage
      {...messages.codeVerificationWarningTrialText}
      values={{
        expirationDate: <CustomDate dateValue={expirationDate} />,
      }}
    />
  </Alert>
);

TrialModeWarningAlert.propTypes = {
  expirationDate: PropTypes.string.isRequired,
};

export default TrialModeWarningAlert;
