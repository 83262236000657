import { Box, Stack } from '@mui/material';
import React, { ReactNode } from 'react';
import { TypographyWithEllipsisTooltip } from '../../TypographyWithEllipsisTooltip';

export interface IIConAndNameProps {
  icon: ReactNode;
  name: string;
}

export function IconAndName(props: IIConAndNameProps) {
  return (
    <Stack alignItems="center" spacing={1} direction="row" sx={{ maxWidth: '100%' }}>
      <Box
        sx={{
          color: 'secondary.main',
          fontSize: 0,
        }}
      >
        {props.icon}
      </Box>
      <TypographyWithEllipsisTooltip component="span" fontWeight="bold" sx={{ pt: '2px' }} variant="body2">
        {props.name}
      </TypographyWithEllipsisTooltip>
    </Stack>
  );
}
