import React from 'react';
import Link from '../Link';

const styles = {
  link: {
    '&:hover': {
      color: 'rgba(5,55,93,.87)',
      textDecoration: 'underline',
    },
    color: 'black',
    fontWeight: 'bold',
    textDecoration: 'none',
  },
};

const DataGridLink = ({ ...other }) => <Link sx={styles.link} {...other} />;

export default DataGridLink;
