import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useUrlParams } from '../../../../helpers/urlParams';

export function useGetGlobalHooks() {
  const dispatch = useDispatch();
  const { companyId, siteId } = useUrlParams();

  const formMethods = useForm({
    defaultValues: {
      lang: '',
    },
    mode: 'onChange',
    shouldUnregister: true,
  });

  return {
    companyId,
    dispatch,
    formMethods,
    siteId,
  };
}
