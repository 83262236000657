import { defineMessages } from 'react-intl';

export default defineMessages({
  AAApartmentUserListContextGrant: {
    defaultMessage: 'Grant admin rights',
    description: 'Site Users Row Menu - grant admin rights',
    id: 'AA.apartmentUserList.context.grant',
  },
  AAApartmentUserListContextRevoke: {
    defaultMessage: 'Revoke admin rights',
    description: 'Site Users Row Menu - revoke admin rights',
    id: 'AA.apartmentUserList.context.revoke',
  },
  siteUsersDataGridColumnsAccess: {
    defaultMessage: 'Access',
    description: 'Site Users Data Grid - access column',
    id: 'siteUsersDataGrid.columns.access',
  },
  siteUsersDataGridColumnsApartment: {
    defaultMessage: 'Apartment',
    description: 'Site Users Data Grid - apartment column',
    id: 'siteUsersDataGrid.columns.apartment',
  },
  siteUsersDataGridColumnsEmail: {
    defaultMessage: 'E-mail',
    description: 'Site Users Data Grid - email column',
    id: 'siteUsersDataGrid.columns.email',
  },
  siteUsersDataGridColumnsName: {
    defaultMessage: 'Name',
    description: 'Site Users Data Grid - name column',
    id: 'siteUsersDataGrid.columns.name',
  },
  siteUsersDataGridColumnsRole: {
    defaultMessage: 'User Role',
    description: 'Site Users Data Grid - role column',
    id: 'siteUsersDataGrid.columns.role',
  },
  siteUsersDataGridCompanyAdmins: {
    defaultMessage: 'The complete list of users with Company admin role can be found in {link}.',
    description: 'Site Users Data Grid - company admins',
    id: 'siteUsersDataGrid.companyAdmins',
  },
  siteUsersDataGridCompanyAdminsLink: {
    defaultMessage: 'Company admins',
    description: 'Site Users Data Grid - company admins link',
    id: 'siteUsersDataGrid.companyAdminsLink',
  },
  siteUsersDataGridRoleCompanyAdmin: {
    defaultMessage: 'Company admin',
    description: 'Site Users Data Grid - company admin role label',
    id: 'siteUsersDataGrid.role.companyAdmin',
  },
  siteUsersDataGridRoleSiteAdmin: {
    defaultMessage: 'Site admin',
    description: 'Site Users Data Grid - site admin role label',
    id: 'siteUsersDataGrid.role.siteAdmin',
  },
  siteUsersDataGridRoleSiteAdminPayment: {
    defaultMessage: 'Site admin (payments permission)',
    description: 'Site Users Data Grid - site admin payment role label',
    id: 'siteUsersDataGrid.role.siteAdminPayment',
  },
  siteUsersDataGridRoleUser: {
    defaultMessage: 'User',
    description: 'Site Users Data Grid - user role label',
    id: 'siteUsersDataGrid.role.user',
  },
  siteUsersRowMenuAssignNewCard: {
    defaultMessage: 'Assign new card',
    description: 'Site Users Row Menu - assign new card',
    id: 'siteUsersRowMenu.assignNewCard',
  },
  siteUsersRowMenuDeleteUser: {
    defaultMessage: 'Delete user',
    description: 'Site Users Row Menu - delete user',
    id: 'siteUsersRowMenu.deleteUser',
  },
  siteUsersRowMenuEditUser: {
    defaultMessage: 'Edit user',
    description: 'Site Users Row Menu - edit user',
    id: 'siteUsersRowMenu.editUser',
  },
  siteUsersRowMenuGenerateNewPin: {
    defaultMessage: 'Generate new PIN',
    description: 'Site Users Row Menu - generate new pin',
    id: 'siteUsersRowMenu.generateNewPin',
  },
  siteUsersRowMenuSetAccessGroups: {
    defaultMessage: 'Set Access groups',
    description: 'Site Users Row Menu - set access groups',
    id: 'siteUsersRowMenu.setAccessGroups',
  },
  siteUsersRowMenuSetApartment: {
    defaultMessage: 'Set apartment',
    description: 'Site Users Row Menu - set apartment to user',
    id: 'siteUsersRowMenu.setApartment',
  },
  UserListUserGuestIconTooltip: {
    defaultMessage: 'Time-limited access',
    id: 'userList.userGuest.icon.tooltip',
  },
});
