import { IError } from './reduxTypes';

export interface IReduxStoreBase<TData> extends IReduxBase {
  data: TData | undefined;
  isEditing: boolean;
}

export interface IReduxListStoreBase<TData> extends IReduxBase {
  data: TData[] | undefined;
}

export interface IReduxTableStoreBase<TData> extends IReduxListStoreBase<TData> {
  order: string | undefined;
  page: number;
  resultsFiltered: number;
  resultsTotal: number;
  rowsPerPage: number;
  filter: any;
}

interface IReduxBase {
  didInvalid?: boolean;
  isFetching: boolean;
  error?: IError;
}

export interface IModalBase {
  open: boolean;
  onClose: () => void;
}

export type TId = number;
export type TUuid = string;

export interface TPaginationParams {
  filter: string;
  limit: number;
  offset: number;
  order: string;
}

export interface IPin {
  active: boolean;
  id: TUuid;
  pin: string;
}

export enum Roles {
  ADMIN = 'ADMIN',
  ANY = 'ANY', // site role (default)
  APARTMENT_ADMIN = 'APARTMENT_ADMIN',
  COMPANY_ADMIN = 'COMPANY_ADMIN', // user role
  COMPANY_CREATOR = 'COMPANY_CREATOR', // user role
  CUSTOMER_CARE = 'CUSTOMER_CARE', // company role (default)
  GREEN_ADMIN = 'GREEN_ADMIN', // company role
  IP_INTERCOM_BUYER = 'IP_INTERCOM_BUYER', // user role
  MARKETING = 'MARKETING', // company role
  MOBILE_VIDEO_BUYER = 'MOBILE_VIDEO_BUYER', // company role
  RESIDENT = 'RESIDENT', // user role
  STANDARD = 'STANDARD', // company role
  SUPERADMIN = 'SUPERADMIN', // user role
  USER = 'USER',
}

export enum FERoles {
  APARTMENT_ADMIN = 'APARTMENT_ADMIN',
  COMPANY_ADMIN = 'COMPANY_ADMIN',
  LOGIN_LESS = 'LOGIN_LESS',
  SITE_ADMIN = 'SITE_ADMIN',
  SITE_ADMIN_PAYMENT = 'SITE_ADMIN_PAYMENT',
  SITE_USER = 'SITE_USER',
}

export enum RolesType {
  GRANTED_ROLES = 'grantedRoles',
  REVOKED_ROLES = 'revokedRoles',
}

export function getInitialReduxBaseStore<TData>(): IReduxStoreBase<TData> {
  return {
    data: undefined,
    didInvalid: false,
    isEditing: false,
    isFetching: false,
  };
}

export enum ApplicationContext {
  None = 'None',
  Company = 'Company',
  Site = 'Site',
  ApartmentAdmin = 'ApartmentAdmin',
  AccessGroupDetail = 'AccessGroupDetail',
  ApartmentDetail = 'ApartmentDetail',
  DeviceDetail = 'DeviceDetail',
  PhoneBookDetail = 'PhoneBookDetail',
  UserDetail = 'UserDetail',
}

export enum UserCredentials {
  BT = 'BT',
  PIN = 'PIN',
  RFID = 'RFID',
}

export enum MobileVideoStatus {
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
  EXPIRED = 'EXPIRED',
  INITIAL = 'INITIAL',
  STANDARD = 'STANDARD',
  STANDARD_DEACTIVATED = 'STANDARD_DEACTIVATED',
  STANDARD_EXPIRED = 'STANDARD_EXPIRED',
  STANDARD_LICENCE_SHORTAGE = 'STANDARD_LICENCE_SHORTAGE',
  STANDARD_RENEWAL = 'STANDARD_RENEWAL',
  TEMPORARY = 'TEMPORARY',
  TRIAL_ACTIVE = 'TRIAL_ACTIVE',
  TRIAL_EXPIRED = 'TRIAL_EXPIRED',
}

export enum MobileVideoType {
  ACTIVABLE = 'ACTIVABLE',
  EXPIRABLE = 'EXPIRABLE',
  STANDARD = 'STANDARD',
}

export enum ApartmentStatus {
  ACTIVE_FOR_FREE = 'ACTIVE_FOR_FREE',
  ACTIVE_SUBSCRIPTIONS = 'ACTIVE_SUBSCRIPTIONS',
  NO_SERVICE = 'NO_SERVICE',
  OTHER = 'OTHER',
  UNLICENSED = 'UNLICENSED',
}

export enum DeviceType {
  ACCESS_UNIT = 'ACCESS_UNIT',
  ACCESS_UNIT_M = 'ACCESS_UNIT_M',
  CHAMELEON = 'CHAMELEON',
  CLIP = 'CLIP',
  DOOR_STATION = 'DOOR_STATION',
  HIPMO = 'HIPMO',
  ID_COMPACT = 'ID_COMPACT',
  ID_TALK = 'ID_TALK',
  ID_VIEW = 'ID_VIEW',
  IDT = 'IDT',
  IP_INTERCOM = 'IP_INTERCOM',
  IP_INTERCOM_V2 = 'IP_INTERCOM_V2',
  IP_ONE_INTERCOM = 'IP_ONE_INTERCOM',
  IP_STYLE = 'IP_STYLE',
  THIRD_PARTY = 'THIRD_PARTY',
}

export enum DeviceStatus {
  ERROR = 'ERROR',
  INACTIVE = 'INACTIVE',
  OK = 'OK',
  WARN = 'WARN',
}

export enum BillingType {
  PER_APARTMENT = 'PER_APARTMENT',
  PER_DEVICE = 'PER_DEVICE',
}

export enum VisitorModalContext {
  USER_ACCESS_VALIDITY_EDITMODAL = 'UserAccessValidityEditmodal',
  USER_LIST_NEW_GUESTMODAL = 'UserListNewGuestmodal',
  APARTMENT_USERS_NEW_GUESTMODAL = 'ApartmentUsersNewGuestmodal',
  AA_MODAL_NEW_GUEST_PIN = 'AAmodalNewGuestPIN',
}
