import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Loader, Stepper, Title } from '../../../components';
import { TYPE } from '../../../config/subscription';
import billingActions from '../../../modules/billing';
import { selectors as companySelectors } from '../../../modules/company';
import { selectors as siteSelectors } from '../../../modules/sites';
import actions, { selectors } from '../../../modules/subscription';
import ThreeStep from '../Common/ThreeStep';
import { getSubscriptionStepsIntl } from '../helpers';
import messages from '../messages';
import styles from '../styles';
import { purchasePaymentResultPropTypes } from '../unitProps';
import ErrorAlertBox from './Common/ErrorAlertBox';
import OneStep from './OneStep/OneStep';
import TwoStep from './TwoStep/TwoStep';
import { orderItemPropTypes, pricesPropTypes } from './types';

const PromoCode = ({
  company,
  isSiteMdu,
  onCreatePromoInvoicePayment,
  onDeletePromoCodesPayment,
  onEditBilling,
  onLoadPromoCodes,
  onSetPayments,
  onSetPromoCodes,
  promoCodeData,
  promoCodesDataIsLoading,
  promoCodesError,
  promoCodesOrderData,
  promoCodesOrderIsLoading,
  purchasePaymentResult,
}) => {
  useEffect(() => {
    onLoadPromoCodes();
  }, []);
  const [step, setStep] = useState(1);
  const { formatMessage } = useIntl();
  return (
    <>
      <Title heading={<FormattedMessage {...messages.subscriptionPROMO_CODETitle} />} />
      <Stepper activeStep={step} steps={getSubscriptionStepsIntl(formatMessage)} />
      <Paper sx={styles.root}>
        {promoCodesError ? (
          <ErrorAlertBox />
        ) : (
          <>
            {(step === 1 || !promoCodesOrderData?.purchaseOrder?.orderContent?.length) &&
              (!promoCodesDataIsLoading && promoCodeData?.prices ? (
                <OneStep
                  canBeInvoiced={promoCodeData?.canBeInvoiced}
                  currency={promoCodeData?.currency}
                  isSiteMdu={isSiteMdu}
                  onSetPromoCodes={onSetPromoCodes}
                  onStepChange={setStep}
                  orderContent={promoCodesOrderData?.purchaseOrder?.orderContent}
                  prices={promoCodeData?.prices}
                  promoCodesOrderIsLoading={promoCodesOrderIsLoading}
                />
              ) : (
                <Loader alignItems="center" size={60} />
              ))}
            {step === 2 && promoCodesOrderData?.purchaseOrder?.orderContent.length > 0 && (
              <TwoStep
                canBeInvoiced={promoCodeData?.canBeInvoiced}
                company={company}
                creditLimit={promoCodeData?.creditLimit}
                currency={promoCodeData?.currency}
                isLoading={promoCodesDataIsLoading}
                onCreatePayment={onCreatePromoInvoicePayment}
                onDeletePayment={onDeletePromoCodesPayment}
                onEditBilling={onEditBilling}
                onSetPayments={onSetPayments}
                onStepChange={setStep}
                orderContent={promoCodesOrderData.purchaseOrder.orderContent}
                paymentLocation={promoCodesOrderData.location}
                promoCodesOrder={promoCodesOrderData.purchaseOrder}
                purchasePaymentResult={purchasePaymentResult}
              />
            )}
            {step === 3 && (
              <ThreeStep
                paymentMethod={promoCodesOrderData.purchaseOrder.paymentMethod}
                purchasePaymentResult={purchasePaymentResult}
                subscription={TYPE.PROMO_CODE}
              />
            )}
          </>
        )}
      </Paper>
    </>
  );
};

PromoCode.propTypes = {
  company: PropTypes.shape({}).isRequired,
  isSiteMdu: PropTypes.bool.isRequired,
  onCreatePromoInvoicePayment: PropTypes.func.isRequired,
  onDeletePromoCodesPayment: PropTypes.func.isRequired,
  onEditBilling: PropTypes.func.isRequired,
  onLoadPromoCodes: PropTypes.func.isRequired,
  onSetPayments: PropTypes.func.isRequired,
  onSetPromoCodes: PropTypes.func.isRequired,
  promoCodeData: PropTypes.shape({
    canBeInvoiced: PropTypes.bool,
    creditLimit: PropTypes.number,
    currency: PropTypes.string,
    prices: pricesPropTypes,
  }).isRequired,
  promoCodesDataIsLoading: PropTypes.bool.isRequired,
  promoCodesError: PropTypes.bool.isRequired,
  promoCodesOrderData: PropTypes.shape({
    id: PropTypes.string,
    location: PropTypes.string,
    purchaseOrder: PropTypes.shape({
      orderContent: PropTypes.arrayOf(orderItemPropTypes),
      paymentMethod: PropTypes.string,
      price: PropTypes.number,
      priceVat: PropTypes.number,
    }),
  }).isRequired,
  promoCodesOrderIsLoading: PropTypes.bool.isRequired,
  purchasePaymentResult: purchasePaymentResultPropTypes,
};

PromoCode.defaultProps = {
  purchasePaymentResult: null,
};

const mapStateToProps = (state) => ({
  company: companySelectors.getCompanyData(state),
  isSiteMdu: siteSelectors.isSiteMdu(state),
  promoCodeData: selectors.getPromoCodeData(state),
  promoCodesDataIsLoading: selectors.getPromoCodesDataIsLoading(state),
  promoCodesError: selectors.getPromoCodesError(state),
  promoCodesOrderData: selectors.getPromoCodesOrderData(state),
  promoCodesOrderIsLoading: selectors.getPromoCodesOrderIsLoading(state),
  purchasePaymentResult: selectors.getPurchasePaymentResult(state),
});

const mapDispatchToProps = {
  onCreatePromoInvoicePayment: actions.createPromoInvoicePayment,
  onDeletePromoCodesPayment: actions.deletePromoOrderContent,
  onEditBilling: billingActions.editBillingShowModal,
  onLoadPromoCodes: actions.getPromoCodes,
  onSetPayments: actions.setPromoCodesPayments,
  onSetPromoCodes: actions.setPromoCodesOrder,
};

export default connect(mapStateToProps, mapDispatchToProps)(PromoCode);
