import Skeleton from '@mui/material/Skeleton';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  AccessGroupsTableChipList,
  ApartmentNameChip,
  DataGrid as DataGridComponent,
  UserAccessSettingsStatus,
} from '../../../components';
import NameColumn from '../../../components/DataGrid/NameColumn';
import UserIcon from '../../../components/UserIcon';
import { PATHS, USERS_CREDENTIALS } from '../../../config/consts';
import { useDataGrid } from '../../../helpers/dataGrid';
import useBreakpoints from '../../../helpers/useBreakpoints';
import { isFetchingMessages } from '../../../modules/localization/selectors';
import DataGridRowMenu from './DataGridRowMenu/DataGridRowMenu';
import messages from './messages';
import NoRowsOverlay from './NoRowsOverlay';

function DataGrid({
  companyId,
  data,
  groupId,
  isFetching,
  isInvalid,
  loadAccessGroupUsers,
  onRemove,
  onSetAccessGroup,
  siteId,
  usersDataGrid,
}) {
  const { onOrderBy, onRowsPerPageChange, onSelectPage } = useDataGrid(
    loadAccessGroupUsers,
    {
      companyId,
      groupId,
      order: usersDataGrid.order,
      page: usersDataGrid.page,
      rowsPerPage: usersDataGrid.rowsPerPage,
      siteId,
    },
    isInvalid
  );
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const { upMd } = useBreakpoints();
  const isLocalizationLoaded = useSelector(isFetchingMessages);

  const loadingWrapper = (children) => (isFetching ? <Skeleton animation="wave" height={25} width="80%" /> : children);

  const columns = useMemo(
    () => [
      {
        disableColumnMenu: true,
        field: 'lastName',
        flex: 0.5,
        headerName: formatMessage(messages.accessGroupUsersDataGridColumnsName),
        renderCell: (user) =>
          loadingWrapper(
            user?.value && (
              <NameColumn
                iconComponent={<UserIcon userRole={user?.value?.role} />}
                isActive={!!user?.value?.credentials?.length}
                name={
                  user?.value?.firstName ? `${user?.value?.firstName} ${user?.value?.lastName}` : user?.value?.lastName
                }
              />
            )
          ),
        sortable: true,
      },
      {
        disableColumnMenu: true,
        field: 'apartments',
        flex: 0.5,
        headerName: formatMessage(messages.accessGroupUsersDataGridColumnsApartment),
        renderCell: (user) =>
          loadingWrapper(
            user?.value && (
              <ApartmentNameChip
                count={user.value?.apartments?.length}
                name={user.value?.apartments?.[0]?.name}
                number={user.value?.apartments?.[0]?.number}
              />
            )
          ),
        sortable: false,
      },
      {
        disableColumnMenu: true,
        field: 'access',
        headerName: formatMessage(messages.accessGroupUsersDataGridColumnsAccess),
        renderCell: (user) =>
          loadingWrapper(user?.value && <UserAccessSettingsStatus credentials={user?.value?.credentials} />),
        sortable: false,
        width: upMd ? 200 : 140,
      },
      {
        disableColumnMenu: true,
        field: 'groups',
        flex: 1,
        headerName: formatMessage(messages.accessGroupUsersDataGridColumnsGroups),
        renderCell: (user) =>
          loadingWrapper(
            user?.value && (
              <AccessGroupsTableChipList
                companyId={companyId}
                currentGroupId={groupId}
                groups={user?.value?.groups}
                siteId={siteId}
              />
            )
          ),
        sortable: false,
      },
      {
        disableColumnMenu: true,
        field: 'rowMenu',
        headerName: '',
        renderCell: (user) =>
          loadingWrapper(
            user?.value && (
              <DataGridRowMenu
                companyId={companyId}
                isPinSet={user?.value?.credentials.includes(USERS_CREDENTIALS.PIN)}
                isRfidSet={user?.value?.credentials.includes(USERS_CREDENTIALS.RFID)}
                onRemove={() => onRemove(user?.value)}
                onSetAccessGroup={() => onSetAccessGroup(user?.value)}
                siteId={siteId}
                user={user?.value}
              />
            )
          ),
        sortable: false,
        width: 65,
      },
    ],
    [upMd, isFetching, isLocalizationLoaded]
  );

  const rows = useMemo(
    () =>
      data.map((user) => ({
        access: user,
        apartments: user,
        groups: user,
        id: user.id,
        isClickable: true,
        lastName: user,
        rowMenu: user,
      })),
    [data]
  );

  return (
    <DataGridComponent
      columns={columns}
      columnVisibilityModel={{
        apartments: upMd,
        groups: upMd,
      }}
      disableRowSelectionOnClick
      hideFooterPagination={isFetching}
      loading={isFetching}
      noRowsOverlay={<NoRowsOverlay />}
      onCellClick={(params) => {
        if (params.field !== 'rowMenu') {
          navigate(
            PATHS.SITE_USER_ACCESS.replace(':companyId', companyId)
              .replace(':siteId', siteId)
              .replace(':userId', params.value?.id)
          );
        }
      }}
      onOrderBy={onOrderBy}
      onRowsPerPageChange={onRowsPerPageChange}
      onSelectPage={onSelectPage}
      order={usersDataGrid.order}
      page={usersDataGrid.page}
      resultsFiltered={usersDataGrid.resultsFiltered}
      resultsTotal={usersDataGrid.resultsTotal}
      rows={rows}
      rowsPerPage={usersDataGrid.rowsPerPage}
    />
  );
}

DataGrid.propTypes = {
  companyId: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  groupId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isFetching: PropTypes.bool.isRequired,
  isInvalid: PropTypes.bool.isRequired,
  loadAccessGroupUsers: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSetAccessGroup: PropTypes.func.isRequired,
  siteId: PropTypes.number.isRequired,
  usersDataGrid: PropTypes.shape({
    order: PropTypes.string.isRequired,
    page: PropTypes.number.isRequired,
    resultsFiltered: PropTypes.number.isRequired,
    resultsTotal: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  }).isRequired,
};

export default DataGrid;
