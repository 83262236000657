import { Stack } from '@mui/material';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { TitleWithSkeleton } from '../../../components/TitleWithSkeleton';
import { getUsersInApartments } from '../../../modules/apartmentAdmin/actions';
import { getUsersInApartmentsSelector } from '../../../modules/apartmentAdmin/selectors';
import { getSkeletonDataOrFetchedData } from './components/Skeleton/skeleton';
import { UsersInApartmentDataGrid } from './components/UsersInApartmentDataGrid/UsersInApartmentDataGrid';
import messages from './messages';

export function ApartmentAdminUsersOverviewPage(): React.JSX.Element {
  const dispatch = useDispatch();
  const {
    data: usersInApartments,
    didInvalid,
    error,
    isFetching: isFetchingUsers,
  } = useSelector(getUsersInApartmentsSelector);

  useEffect(() => {
    dispatch(getUsersInApartments());
  }, []);

  useEffect(() => {
    if (didInvalid) {
      dispatch(getUsersInApartments());
    }
  }, [didInvalid]);

  return (
    <>
      <TitleWithSkeleton isFetching={isFetchingUsers}>
        <FormattedMessage {...messages.usersInApartmentsHeader} values={{ count: usersInApartments?.length || 0 }} />
      </TitleWithSkeleton>
      <Stack spacing={3}>
        {getSkeletonDataOrFetchedData({
          fetchedApartmentUsers: usersInApartments,
          hasError: !!error,
          isFetching: isFetchingUsers,
        }).map((apartment, index) => {
          return <UsersInApartmentDataGrid apartment={apartment} key={index} isFetching={isFetchingUsers} />;
        })}
      </Stack>
    </>
  );
}
