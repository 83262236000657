import DeactivateIcon from '@mui/icons-material/CancelOutlined';
import VerifiedIcon from '@mui/icons-material/VerifiedUser';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import actions, { selectors } from '../../modules/accessSettings';
import DangerModal from '../DangerModal';
import messages from './messages';

const RacDeactivateModal = ({ deviceId, groups, isLoading, onClose, onRacDeactivate, open }) => {
  const sortedGroups = sortBy(groups, ['name']);
  const handleSubmit = () => {
    onRacDeactivate(deviceId);
    onClose();
  };

  const getIsOpen = open || isLoading;
  return (
    getIsOpen && (
      <DangerModal
        actionButtonLabel={<FormattedMessage {...messages.racDeactivateModalDeactivate} />}
        buttonIcon={<DeactivateIcon />}
        isActionButtonDisabled={false}
        isLoading={isLoading}
        name="rac-deactivate-modal"
        onClose={onClose}
        onSubmit={handleSubmit}
        open={getIsOpen}
        title={<FormattedMessage {...messages.racDeactivateModalTitle} />}
      >
        <Typography variant="body1">
          <FormattedMessage
            {...messages.racDeactivateModalText}
            values={{
              br: <br />,
              info: (
                <FormattedMessage
                  {...(groups?.length
                    ? messages.racDeactivateModalGroupInfoGroup
                    : messages.racDeactivateModalGroupInfoEmpty)}
                />
              ),
            }}
          />
        </Typography>
        {!!sortedGroups.length && (
          <Box component="div" pt={2}>
            {sortedGroups.map((group) => (
              <Chip
                color="primary"
                icon={<VerifiedIcon />}
                key={group.id}
                label={group.name}
                sx={{ mb: 0.5, mr: 1 }}
                variant="outlined"
              />
            ))}
          </Box>
        )}
      </DangerModal>
    )
  );
};

RacDeactivateModal.propTypes = {
  deviceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    })
  ),
  isLoading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onRacDeactivate: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

RacDeactivateModal.defaultProps = {
  deviceId: '',
  groups: [],
};

const mapStateToProps = (state) => ({
  isLoading: selectors.getIsDeactivateRacAccessGroupDeviceFetching(state),
});

const mapDispatchToProps = {
  onRacDeactivate: actions.deactivateRacAccessGroupDevice,
};

export default connect(mapStateToProps, mapDispatchToProps)(RacDeactivateModal);
