import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../../messages';

const ChangeBillingInfo = () => (
  <Grid item xs={12}>
    <Box pt={2}>
      <Typography variant="body2">
        <FormattedMessage
          {...messages.billingInfoLink}
          values={{
            link: (
              <Link href="mailto:customercare@2n.com">
                <FormattedMessage {...messages.billingInfoLinkAddress} />
              </Link>
            ),
          }}
        />
      </Typography>
    </Box>
  </Grid>
);

export default ChangeBillingInfo;
