import { DEVICE_TYPE } from '../../config/devices';
import actions from '../../modules/devices';

export const add2NDevice = (values, floors, onStep) => actions.addDevice(values, 'X2N', undefined, floors, onStep);

export const addAxisDoorStationDevice = (values) => actions.addDevice(values, DEVICE_TYPE.DOOR_STATION, undefined);

export const addHipmoDevice = (values, floors) =>
  actions.addDevice(values, DEVICE_TYPE.HIPMO, undefined, floors, () => {});

export const addThirdPartyDevice = (values, floors) =>
  actions.addDevice(values, DEVICE_TYPE.THIRD_PARTY, undefined, floors, () => {});
