import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import { DeviceStopDto } from '../../apiRtk/elevatorIntegrationApi';
import { AREA_ID, BUILDING_ELEVATOR_GROUP, DEFAULT_DEVICE_STOP, WALKING_TIME } from '../../config/consts';
import { getElevatorGroupSelectItems } from '../../containers/SiteSettings/ElevatorIntegration/FloorConfiguration/helpers';
import { IModalBase } from '../../modules/commonTypes';
import actions from '../../modules/elevatorIntegration';
import { selectors } from '../../modules/elevatorIntegration';
import { ActionModal } from '../_DesignSystemWrappers/Modal';
import DeviceElevatorIntegrationItem from './DeviceElevatorIntegrationItem/DeviceElevatorIntegrationItem';
import { disableSelectedElevatorGroups, getDeviceStops, getStopDefaultValues } from './helpers/helpers';
import { useGetDeviceElevatorIntegrationData } from './hooks/useGetDeviceElevatorIntegrationData';
import { useGetGlobalHooks } from './hooks/useGetGlobalHooks';
import messages from './messages';
import SettingsFormCheckboxes from './SettingsFormCheckboxes';

interface IDeviceElevatorIntegrationFormProps {
  landingCall: boolean;
  destinationCall: boolean;
}

function DeviceElevatorIntegrationModal({ onClose, open }: IModalBase) {
  const { urlParams } = useGetDeviceElevatorIntegrationData();
  const { deviceElevatorIntegration, dispatch, formatMessage } = useGetGlobalHooks();
  const [deviceStops, setDeviceStops] = useState(
    deviceElevatorIntegration?.stops?.length ? deviceElevatorIntegration.stops : [DEFAULT_DEVICE_STOP]
  );
  const defaultValues = {
    destinationCall: deviceElevatorIntegration?.destinationCallEnabled || false,
    landingCall: deviceElevatorIntegration?.landingCallEnabled || false,
    ...getStopDefaultValues(deviceStops),
  };
  const buildingTopologyBuildings = useSelector(selectors.getBuildingTopologyBuildings);
  const elevatorGroupSelectItems = getElevatorGroupSelectItems(buildingTopologyBuildings);
  const filteredElevatorGroupSelectItems = disableSelectedElevatorGroups(deviceStops, elevatorGroupSelectItems);
  const formMethods = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(validationSchema()),
  });
  const {
    formState: { isValid },
  } = formMethods;

  function validationSchema() {
    return yup.object({
      destinationCall: yup.bool(),
      landingCall: yup.bool(),
      ...getStopValidators(deviceStops),
    });
  }

  function updateDeviceStopsFormData(deviceStops: DeviceStopDto[]) {
    const values = {
      destinationCall: formMethods.watch('destinationCall'),
      landingCall: formMethods.watch('landingCall'),
      ...getStopDefaultValues(deviceStops),
    };
    formMethods.reset(values);
  }

  function addEmptyStop() {
    const newDeviceStops = [...deviceStops, DEFAULT_DEVICE_STOP];
    setDeviceStops(newDeviceStops);
    updateDeviceStopsFormData(newDeviceStops);
  }

  function getStopValidators(deviceStops: DeviceStopDto[]) {
    const stopsValidatorSchema: Record<
      string,
      yup.StringSchema<string | undefined, yup.AnyObject, undefined, ''> | yup.NumberSchema<number>
    > = {};
    deviceStops.forEach((_stop, index) => {
      stopsValidatorSchema[`${index}${BUILDING_ELEVATOR_GROUP}`] = yup.string().required();
      stopsValidatorSchema[`${index}${AREA_ID}`] = yup.string().required();
      stopsValidatorSchema[`${index}${WALKING_TIME}`] = yup.string().required();
    });
    return stopsValidatorSchema;
  }

  useEffect(() => {
    dispatch(actions.getElevatorIntegrationFloorMapping(urlParams.companyId!, urlParams.siteId!));
  }, []);

  function handleSubmit(data: IDeviceElevatorIntegrationFormProps) {
    const deviceStops = getDeviceStops(data);
    dispatch(
      actions.setDeviceElevatorIntegration(urlParams.companyId!, urlParams.siteId!, urlParams.deviceId!, {
        destinationCallEnabled: data.destinationCall,
        integrationActive: !!deviceStops.length,
        landingCallEnabled: data.landingCall,
        stops: deviceStops,
      })
    );
    onClose();
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
        <ActionModal
          open={open}
          onClose={onClose}
          icon={<DoneIcon />}
          isPrimaryButtonDisabled={!isValid}
          primaryButtonAction={formMethods.handleSubmit(handleSubmit)}
          primaryButtonText={formatMessage(messages.confirmButton)}
          secondaryButtonText={formatMessage(messages.cancelButton)}
          title={formatMessage(messages.modalTitle)}
          muiDialogProps={{
            maxWidth: 'xs',
          }}
        >
          <Stack direction="column" spacing={3} pb={3}>
            <SettingsFormCheckboxes />
            {deviceStops.map((_deviceStop, deviceStopIndex) => {
              return (
                <DeviceElevatorIntegrationItem
                  key={deviceStopIndex}
                  deviceStopIndex={deviceStopIndex}
                  setDeviceStops={setDeviceStops}
                  deviceStops={deviceStops}
                  updateDeviceStopsFormData={updateDeviceStopsFormData}
                  elevatorGroupSelectItems={filteredElevatorGroupSelectItems}
                  buildingTopologyBuildings={buildingTopologyBuildings}
                />
              );
            })}
            <Divider />
            <Box>
              <Button
                onClick={addEmptyStop}
                startIcon={<AddIcon />}
                variant="outlined"
                disabled={filteredElevatorGroupSelectItems.every((group) => group.disabled) || !isValid}
              >
                <FormattedMessage {...messages.newConfigurationButton} />
              </Button>
            </Box>
          </Stack>
        </ActionModal>
      </form>
    </FormProvider>
  );
}

export default DeviceElevatorIntegrationModal;
