export const styles = {
  buttonWrapper: {
    mt: 2.5,
  },
  content: {
    pt: { md: 5.25, xs: 2.5 },
    textAlign: 'center',
  },
  textColor: {
    color: 'text.primary',
  },
  textWrapper: {
    '& a': {
      color: 'primary.main',
    },
    color: 'text.secondary',
    mt: { md: 5, xs: 2 },
  },
};
