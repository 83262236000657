import types from './types';

export const addCompanyRequest = (values) => ({ type: types.ADD_COMPANY_REQUEST, values });

export const loadCompany = (companyId) => ({ companyId, type: types.LOAD_COMPANY_REQUEST });

export const deleteCompanyShowModal = (company) => ({
  company,
  type: types.DELETE_COMPANY_SHOW_MODAL,
});

export const deleteCompanyHideModal = () => ({ type: types.DELETE_COMPANY_HIDE_MODAL });
export const deleteCompanyConfirm = (company) => ({ company, type: types.DELETE_COMPANY_REQUEST });

export const addCompanyRole = (company, role) => ({
  company,
  role,
  type: types.ADD_COMPANY_ROLE_REQUEST,
});

export const deleteCompanyRole = (company, role) => ({
  company,
  role,
  type: types.DELETE_COMPANY_ROLE_REQUEST,
});

export const changeCompanySettings = (company, values) => ({
  company,
  type: types.CHANGE_SETTINGS_REQUEST,
  values,
});

export const getMvSdkSettings = () => ({ type: types.GET_MV_SDK_SETTINGS_REQUEST });

export const updateMvSdkSetings = (values, formActions) => ({
  formActions,
  type: types.UPDATE_MV_SDK_SETTINGS_REQUEST,
  values,
});

export const resetMvSdkSettings = () => ({ type: types.RESET_MV_SDK_SETTINGS_REQUEST });

export const clearCompany = () => ({ type: types.CLEAR_COMPANY });

export const getCompanyDeviceTypes = (companyId) => ({
  companyId,
  type: types.GET_COMPANY_DEVICE_TYPES_REQUEST,
});
