import CardHeader from '@mui/material/CardHeader';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  root: {
    '& .MuiCardHeader-title': {
      color: 'card.header',
      fontWeight: 'bold',
    },
    borderBottom: '1px solid',
    borderBottomColor: 'divider',
    p: 3,
  },
};

const DashboardCardHeader = ({ action, title }) => (
  <CardHeader
    action={action}
    sx={styles.root}
    title={title}
    titleTypographyProps={{
      variant: 'body1',
    }}
  />
);

DashboardCardHeader.propTypes = {
  action: PropTypes.node,
  title: PropTypes.node.isRequired,
};

DashboardCardHeader.defaultProps = {
  action: null,
};

export default DashboardCardHeader;
