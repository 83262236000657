import { defineMessages } from 'react-intl';

export default defineMessages({
  accessSnackbarAccessGroupsSetSuccessfully: {
    defaultMessage: 'Access groups set successfully.',
    description: 'Access snackbar - Access groups set successfully',
    id: 'accessSnackbarAccessGroupsSetSuccessfully',
  },
  accessSnackbarAccessSettingsSetSuccessfully: {
    defaultMessage: 'Access settings set successfully.',
    description: 'Access snackbar - Access settings set successfully',
    id: 'accessSnackbarAccessSettingsSetSuccessfully',
  },
  accessSnackbarApartmentRemovedSuccessfully: {
    defaultMessage: 'Apartment removed successfully.',
    description: 'Access snackbar - Apartment removed successfully',
    id: 'accessSnackbarApartmentRemovedSuccessfully',
  },
  accessSnackbarApartmentsAddedSuccessfully: {
    defaultMessage: 'Apartments added successfully.',
    description: 'Access snackbar - Apartments added successfully',
    id: 'accessSnackbarApartmentsAddedSuccessfully',
  },
  accessSnackbarDeviceAccessControlActivatedSuccessfully: {
    defaultMessage: 'Device access control activated successfully.',
    description: 'Access snackbar - Device access control activated successfully',
    id: 'accessSnackbarDeviceAccessControlActivatedSuccessfully',
  },
  accessSnackbarDeviceAccessControlDeactivatedSuccessfully: {
    defaultMessage: 'Device access control deactivated successfully.',
    description: 'Access snackbar - Device access control deactivated successfully',
    id: 'accessSnackbarDeviceAccessControlDeactivatedSuccessfully',
  },
  accessSnackbarDeviceAddedSuccessfully: {
    defaultMessage: 'Device added successfully.',
    description: 'Access snackbar - Device added successfully',
    id: 'accessSnackbarDeviceAddedSuccessfully',
  },
  accessSnackbarDeviceLoggingActivatedSuccessfully: {
    defaultMessage: 'Device logging activated successfully.',
    description: 'Access snackbar - Device logging activated successfully',
    id: 'accessSnackbarDeviceLoggingActivatedSuccessfully',
  },
  accessSnackbarDeviceLoggingDeactivatedSuccessfully: {
    defaultMessage: 'Device logging deactivated successfully.',
    description: 'Access snackbar - Device logging deactivated successfully',
    id: 'accessSnackbarDeviceLoggingDeactivatedSuccessfully',
  },
  accessSnackbarDeviceRemovedSuccessfully: {
    defaultMessage: 'Device removed successfully.',
    description: 'Access snackbar - Device removed successfully',
    id: 'accessSnackbarDeviceRemovedSuccessfully',
  },
  accessSnackbarGroupAddedSuccessfully: {
    defaultMessage: 'Access group added successfully.',
    description: 'Access snackbar - Access group added successfully',
    id: 'accessSnackbarGroupAddedSuccessfully',
  },
  accessSnackbarGroupDeletedSuccessfully: {
    defaultMessage: 'Access group deleted successfully.',
    description: 'Access snackbar - Access group deleted successfully',
    id: 'accessSnackbarGroupDeletedSuccessfully',
  },
  accessSnackbarGroupUpdatedSuccessfully: {
    defaultMessage: 'Access group updated successfully.',
    description: 'Access snackbar - Access group updated successfully',
    id: 'accessSnackbarGroupUpdatedSuccessfully',
  },
  accessSnackbarUserAddedSuccessfully: {
    defaultMessage: 'User added successfully.',
    description: 'Access snackbar - User added successfully',
    id: 'accessSnackbarUserAddedSuccessfully',
  },
  accessSnackbarUserCreatedSuccessfully: {
    defaultMessage: 'User created successfully.',
    id: 'accessGroupAddUserModal.msgSucc',
  },
  accessSnackbarUserRemovedSuccessfully: {
    defaultMessage: 'User removed successfully.',
    description: 'Access snackbar - User removed successfully',
    id: 'accessSnackbarUserRemovedSuccessfully',
  },
});
