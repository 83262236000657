import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import messages from './messages';
import NewUserFields from './NewUserFields';

function AddUserAutocomplete({ hasFormItemKey, newItemId, users }) {
  const { control, setValue, watch } = useFormContext();
  const { field } = useController({ control, name: 'user' });
  const { formatMessage } = useIntl();

  return (
    <>
      <Autocomplete
        {...field}
        autoHighlight
        fullWidth
        getOptionLabel={(option) => option?.label || ''}
        id="access-group-userAdd-select"
        isOptionEqualToValue={(option, val) => isEqual(option, val)}
        onChange={(e, data) => {
          setValue(hasFormItemKey, data?.id === newItemId);
          field.onChange(data);
        }}
        options={users}
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'off',
            }}
            label={formatMessage(messages.addUserAutocompleteLabel)}
            placeholder={formatMessage(messages.addUserAutocompletePlaceholder)}
          />
        )}
        renderOption={(props, option) => (
          <Box component="li" sx={{ color: option === users[0] ? 'primary.main' : 'text.primary' }} {...props}>
            {option === users[0] ? <PersonAddIcon /> : <AccountCircleIcon />}
            <Box pl={2}>{option.label}</Box>
          </Box>
        )}
        sx={{ py: 5 }}
      />
      {watch(hasFormItemKey) && <NewUserFields />}
    </>
  );
}

AddUserAutocomplete.propTypes = {
  hasFormItemKey: PropTypes.string.isRequired,
  newItemId: PropTypes.string.isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default AddUserAutocomplete;
