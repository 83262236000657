import { getOffset } from '../../helpers/dataGrid';
import types from './types';

export const loadPhonebooks = (
  companyId,
  siteId,
  page = 1,
  rowsPerPage = 10,
  filter = { fulltext: '' },
  order = 'name'
) => ({
  companyId,
  filter,
  order,
  page,
  params: {
    filter: filter.fulltext,
    limit: rowsPerPage,
    offset: getOffset(page, rowsPerPage),
    order,
  },
  rowsPerPage,
  siteId,
  type: types.LOAD_PHONEBOOKS_LIST_REQUEST,
});

export const loadPhonebooksNames = () => ({ type: types.LOAD_PHONEBOOKS_NAMES_REQUEST });

export const detailPhonebook = (companyId, siteId, phonebookId) => ({
  companyId,
  phonebookId,
  siteId,
  type: types.PHONEBOOK_DETAIL_REQUEST,
});

export const deletePhonebookShowModal = (phonebook) => ({
  phonebook,
  type: types.DELETE_PHONEBOOK_SHOW_MODAL,
});

export const deletePhonebookHideModal = () => ({
  type: types.DELETE_PHONEBOOK_HIDE_MODAL,
});

export const deletePhonebookConfirm = (phonebook) => ({
  phonebook,
  type: types.DELETE_PHONEBOOK_REQUEST,
});

export const addPhonebookShowModal = () => ({
  isEdit: false,
  type: types.ADD_PHONEBOOK_SHOW_MODAL,
});

export const editPhonebookShowModal = (phonebook) => ({
  isEdit: true,
  phonebook: {
    ...phonebook,
    members: phonebook.members.map((item) => item.id),
  },
  type: types.EDIT_PHONEBOOK_SHOW_MODAL,
});

export const phonebookHideModal = () => ({
  type: types.PHONEBOOK_HIDE_MODAL,
});

export const phonebookSave = (companyId, siteId, values, formActions, isEdit) => ({
  companyId,
  formActions,
  isEdit,
  siteId,
  type: types.SAVE_PHONEBOOK_REQUEST,
  values,
});

export const loadMembers = (
  companyId,
  siteId,
  page = 0,
  rowsPerPage = 10,
  filter = { fulltext: '' },
  order = 'device.name'
) => ({
  companyId,
  filter,
  order,
  page,
  params: {
    filter: filter.fulltext,
    limit: rowsPerPage,
    offset: getOffset(page, rowsPerPage),
    order,
  },
  rowsPerPage,
  siteId,
  type: types.LOAD_MEMBERS_LIST_REQUEST,
});
