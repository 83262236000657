import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import landingMainArrowImage from '../../assets/landing_arrows.png';

const styles = {
  button: {
    '&:after': {
      backgroundImage: `url(${landingMainArrowImage})`,
      bottom: 0,
      content: '""',
      display: 'block',
      height: '18px',
      left: 0,
      margin: 'auto',
      position: 'absolute',
      right: 0,
      top: 0,
      width: '12px',
    },
    backgroundColor: '#0091ea',
    border: 'none',
    borderRadius: '100%',
    color: '#fff',
    cursor: 'pointer',
    display: 'block',
    fontSize: '16px',
    fontWeight: 700,
    height: { md: '58px', xs: '40px' },
    letterSpacing: '0.02em',
    lineHeight: { md: '58px', xs: '40px' },
    margin: '0 auto',
    outline: 'none',
    position: 'relative',
    textAlign: 'center',
    textDecoration: 'none',
    textTransform: 'uppercase',
    width: { md: '58px', xs: '40px' },
  },
  root: {
    bottom: { md: '-29px', xs: '-20px' },
    position: 'absolute',
    textAlign: 'center',
    width: '100%',
    zIndex: 10,
  },
};

const RoundScrollButton = ({ reference }) => {
  const onScroll = (event) => {
    event.preventDefault();
    reference.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  return (
    <Box sx={styles.root}>
      <Box component="button" onClick={onScroll} sx={styles.button} type="button" />
    </Box>
  );
};

RoundScrollButton.propTypes = {
  reference: PropTypes.shape({
    current: PropTypes.oneOfType([PropTypes.object, PropTypes.instanceOf(Element)]),
  }).isRequired,
};

export default RoundScrollButton;
