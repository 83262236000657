import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BILLING_TYPE } from '../../../../config/subscription';
import { BillingType } from '../../../../modules/commonTypes';
import messages from './messages';

interface IActivationAgreement {
  billingType: BillingType;
  consumption: number;
  credit: number;
  onSetCheck: () => void;
}

function ActivationAgreement({ billingType, consumption, credit, onSetCheck }: IActivationAgreement) {
  const isApartmentBillingType = billingType === BILLING_TYPE.PER_APARTMENT;

  return (
    <Box py={2}>
      <Grid container direction="column" spacing={2}>
        <Grid item xs>
          <Divider />
        </Grid>
        <Grid item xs>
          <Grid alignItems="center" container spacing={2}>
            <Grid item>
              <Checkbox color="primary" onChange={onSetCheck} />
            </Grid>
            <Grid item xs>
              <Typography>
                <FormattedMessage
                  {...(isApartmentBillingType ? messages.AgreementInsufficientCredit : messages.agreement)}
                  values={{
                    consumption: consumption.toFixed(1),
                    credit: credit.toFixed(1),
                  }}
                />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ActivationAgreement;
