import Box from '@mui/material/Box';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { PATHS } from '../../config/consts';
import { useUrlParams } from '../../helpers/urlParams';
import ChipLink from '../ChipLink';

function AccessApartmentsChipList({ apartments }) {
  const { companyId, siteId } = useUrlParams();
  const sortedApartments = sortBy(apartments, ['name']);

  return sortedApartments.map((item) => (
    <Box item key={item.id} sx={{ display: 'inline-grid' }}>
      <ChipLink
        icon={<Box fontWeight="bold">{item.number}</Box>}
        label={item.name}
        link={PATHS.APARTMENT_DETAIL.replace(':companyId', companyId)
          .replace(':siteId', siteId)
          .replace(':apartmentId', item.id)}
      />
    </Box>
  ));
}

AccessApartmentsChipList.propTypes = {
  apartments: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      number: PropTypes.string,
    })
  ).isRequired,
};

export default AccessApartmentsChipList;
