import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { addMonths, format } from 'date-fns';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import Modal from '../Modal';
import FormComponent from './FormComponent';
import messages from './messages';
import styles from './styles';

// eslint-disable-next-line max-statements
const CreateBatchDialog = ({ onClose, onSave, show, username }) => {
  const [step, setStep] = useState(1);
  const [valid, setValid] = useState(false);
  const months = 36;
  const now = new Date();
  const today = format(now, 'yyyy-MM-dd');
  const maxDate = addMonths(now, months);

  const initialValues = {
    channel: 'PURCHASE',
    credit: '',
    dateCreated: now,
    description: '',
    invoice: '',
    months,
    name: '',
    size: '',
    utm: '',
    validityTo: maxDate,
  };

  const validationSchema = Yup.object().shape({
    credit: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .test(
        'maxDigits',
        <FormattedMessage {...messages.createBatchDialogCreditMaxDigits} />,
        (value) => value && value.toString().length <= 6
      )
      .required(<FormattedMessage {...messages.createBatchDialogCreditHelperText} />),
    dateCreated: Yup.date()
      .min(today, <FormattedMessage {...messages.createBatchDialogDateCreatedMin} />)
      .max(now, <FormattedMessage {...messages.createBatchDialogDateCreatedMax} />)
      .typeError(<FormattedMessage {...messages.createBatchDialogDateCreatedValid} />),
    name: Yup.string().required(<FormattedMessage {...messages.createBatchDialogNameHelperText} />),
    size: Yup.number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .max(5000, <FormattedMessage {...messages.createBatchDialogSizeMax} />)
      .required(<FormattedMessage {...messages.createBatchDialogSizeHelperText} />),
    validityTo: Yup.date()
      .min(today, <FormattedMessage {...messages.createBatchDialogValidityToMin} />)
      .max(maxDate, <FormattedMessage {...messages.createBatchDialogValidityToMax} />)
      .typeError(<FormattedMessage {...messages.createBatchDialogValidityToValid} />),
  });

  const getActions = (onSubmit) => {
    if (step === 1) {
      return (
        <>
          <Button onClick={onClose}>
            <FormattedMessage {...messages.createBatchDialogClose} />
          </Button>
          <Button
            color="primary"
            disabled={!valid}
            onClick={() => setStep((prevState) => prevState + 1)}
            startIcon={<ArrowForwardIcon />}
            variant="contained"
          >
            <FormattedMessage {...messages.createBatchDialogSetDetails} />
          </Button>
        </>
      );
    }

    if (step === 2) {
      return (
        <Grid container item spacing={0} sx={styles.actions}>
          <Grid item sx={styles.actionsLeft}>
            <Button onClick={() => setStep((prevState) => prevState - 1)} startIcon={<EditIcon />}>
              <FormattedMessage {...messages.recapBatchEditDetails} />
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={onClose}>
              <FormattedMessage {...messages.recapBatchClose} />
            </Button>
            <Button color="primary" onClick={onSubmit} startIcon={<CheckIcon />} type="submit" variant="contained">
              <FormattedMessage {...messages.recapBatchGenerateCodess} />
            </Button>
          </Grid>
        </Grid>
      );
    }

    return null;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSave}
      render={(formikProps) => (
        <Modal
          actions={getActions(formikProps?.handleSubmit)}
          maxWidth="md"
          name="create-batch-dialog"
          onClose={onClose}
          open={show}
          sx={step === 1 && styles.modalFirstStep}
          title={<FormattedMessage {...messages.createBatchDialogTitle} values={{ step, steps: 2 }} />}
        >
          <FormComponent
            {...formikProps}
            onClose={onClose}
            setStep={setStep}
            setValid={setValid}
            step={step}
            username={username}
          />
        </Modal>
      )}
      validationSchema={validationSchema}
    />
  );
};

CreateBatchDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  username: PropTypes.string.isRequired,
};

export default CreateBatchDialog;
