import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import deviceActions, { selectors } from '../../../../modules/devices';
import messages from '../messages';

export interface IUnlockSettings {
  code: string;
}

export function useUnlockSettings() {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const code = useSelector(selectors.getDtmfCode);

  const formMethods = useForm<IUnlockSettings>({
    defaultValues: {
      code: '',
    },
    mode: 'onChange',
    resolver: yupResolver(
      Yup.object().shape({
        code: Yup.string()
          .matches(/^[\d*#]{1,15}$/, { message: formatMessage(messages.unlockSettingsErrorText) })
          .required(),
      })
    ),
  });

  useEffect(() => {
    if (code) {
      formMethods.reset({ code });
    }
  }, [code]);

  const watchFields = formMethods.watch();

  const disableSubmit = useMemo(
    () => !Object.keys(formMethods.formState.dirtyFields).length || !formMethods.formState.isValid,
    [watchFields]
  );

  const onSubmit = (values: IUnlockSettings) => {
    dispatch(deviceActions.updateDtmfCode(values.code));
  };

  return {
    disableSubmit,
    formatMessage,
    formMethods,
    onSubmit,
  };
}
