import Autocomplete from '@mui/material/Autocomplete';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { FORM } from '../../config/consts';
import InputField from './InputField';
import Option from './Option';
import Tag from './Tag';

function CreateMultiAutocomplete({
  createForm,
  createFormVisibilityKey,
  createItemIcon,
  customOption,
  customTag,
  helperText,
  itemIcon,
  label,
  name,
  newItemId,
  newItemId2,
  optionGroupKey,
  optionLabelKey,
  options,
  placeholder,
  ...other
}) {
  const { control, setValue, watch } = useFormContext();
  const { field, fieldState } = useController({ control, name });
  const watchField = watch(name);

  useEffect(() => {
    setValue(createFormVisibilityKey, !!watchField?.some((item) => item.id === newItemId || item.id === newItemId2));
  }, [watchField]);

  const newItemOption2 = options.find((option) => option.id === newItemId2);
  const newItemOption = options.find((option) => option.id === newItemId);

  return (
    <>
      <Autocomplete
        {...other}
        {...field}
        autoHighlight
        disableCloseOnSelect
        getOptionDisabled={(option) =>
          (field.value.some((selectedItem) => selectedItem.id === FORM.NEW_ITEM_ID) && option === newItemOption2) ||
          (field.value.some((selectedItem) => selectedItem.id === FORM.NEW_ITEM_WITHOUT_LOGIN_ID) &&
            option === newItemOption)
        }
        getOptionLabel={(option) => option?.[optionLabelKey]}
        groupBy={(option) => option?.[optionGroupKey]}
        isOptionEqualToValue={(option, val) => isEqual(option, val)}
        multiple
        onChange={(_e, data) => {
          const newItem = data?.find((item) => item.id === newItemId);
          const newItem2 = data?.find((item) => item.id === newItemId2);
          if (newItem) {
            field.onChange([...new Set([newItem, ...data])]);
          } else if (newItem2) {
            field.onChange([...new Set([newItem2, ...data])]);
          } else {
            field.onChange(data);
          }
        }}
        options={options}
        renderInput={(params) => (
          <InputField
            fieldState={fieldState}
            helperText={helperText}
            label={label}
            params={params}
            placeholder={placeholder}
          />
        )}
        renderOption={(renderProps, option) =>
          customOption(renderProps, option) || (
            <Option
              createItemIcon={createItemIcon}
              itemIcon={itemIcon}
              key={option?.id}
              newItemId={newItemId}
              newItemId2={newItemId2}
              option={option}
              optionLabelKey={optionLabelKey}
              renderProps={renderProps}
            />
          )
        }
        renderTags={(tagValue, getTagProps) =>
          customTag(tagValue, getTagProps) || (
            <Tag
              getTagProps={getTagProps}
              newItemId={newItemId}
              newItemId2={newItemId2}
              newItemId2Label={newItemOption2?.tag || newItemOption2?.[optionLabelKey]}
              newItemIdLabel={newItemOption?.tag || newItemOption?.[optionLabelKey]}
              optionLabelKey={optionLabelKey}
              tagValue={tagValue}
            />
          )
        }
        sx={{
          '& .MuiAutocomplete-tag': {
            maxWidth: '100%',
          },
          py: 3,
        }}
      />
      {createForm}
    </>
  );
}

CreateMultiAutocomplete.propTypes = {
  createForm: PropTypes.node,
  createFormVisibilityKey: PropTypes.string,
  createItemIcon: PropTypes.element,
  customOption: PropTypes.func,
  customTag: PropTypes.func,
  helperText: PropTypes.string,
  itemIcon: PropTypes.element,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  newItemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  newItemId2: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  optionGroupKey: PropTypes.string,
  optionLabelKey: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  placeholder: PropTypes.string,
};

CreateMultiAutocomplete.defaultProps = {
  createForm: null,
  createFormVisibilityKey: '',
  createItemIcon: null,
  customOption: () => null,
  customTag: () => null,
  helperText: ' ',
  itemIcon: null,
  label: '',
  newItemId2: null,
  optionGroupKey: '',
  optionLabelKey: '',
  placeholder: '',
};

export default CreateMultiAutocomplete;
