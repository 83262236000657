import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MOBILE_VIDEO_TYPE } from '../../config/consts';
import messages from './messages';
import { styles } from './styles';

const CreditsRequirement = ({ count, licenceModelType, multiplier }) =>
  licenceModelType === MOBILE_VIDEO_TYPE.STANDARD ? (
    <Typography sx={{ mt: { sm: '20px', xs: '11px' } }} variant="body2">
      <FormattedMessage
        values={{
          count: count * multiplier,
          span: (chunks) => (
            <Box component="span" sx={styles.number}>
              {chunks}
            </Box>
          ),
          text: (chunks) => (
            <Box component="span" sx={styles.textColor}>
              {chunks}
            </Box>
          ),
        }}
        {...messages.creditPoolCardRequirementForNextMonth}
      />
    </Typography>
  ) : null;

CreditsRequirement.propTypes = {
  count: PropTypes.number,
  licenceModelType: PropTypes.oneOf(Object.keys(MOBILE_VIDEO_TYPE)),
  multiplier: PropTypes.number,
};

CreditsRequirement.defaultProps = {
  count: 0,
  licenceModelType: null,
  multiplier: 1,
};

export default CreditsRequirement;
