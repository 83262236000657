import { Box } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { ISiteIdsInfo } from '../../modules/sites/store';
import { getCurrentUserSelector } from '../../modules/user/selectors';
import { IUserTitleData } from './data';
import RemoveUser from './RemoveUser';

export interface IRemoveButtonProps {
  user: IUserTitleData;
  siteIdsInfo?: ISiteIdsInfo;
}

export function RemoveButton(props: IRemoveButtonProps) {
  const currentUser = useSelector(getCurrentUserSelector);

  return (
    <Box sx={{ marginLeft: 'auto', pl: 2, whiteSpace: 'nowrap' }}>
      <RemoveUser currentUser={currentUser} user={props.user} siteIdsInfo={props.siteIdsInfo} />
    </Box>
  );
}
