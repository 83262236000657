import { uniq } from 'lodash';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { ACCESS_LOG_CREDENTIAL_TYPE, ACCESS_LOG_RESULT } from '../../config/consts';
import messages from './messages';

const AccessLogAccessType = ({ accessLog }) => {
  const { formatMessage } = useIntl();

  const types = accessLog.credentials?.map((credential) => {
    switch (credential?.type) {
      case ACCESS_LOG_CREDENTIAL_TYPE.MOBILE_KEY:
      case ACCESS_LOG_CREDENTIAL_TYPE.CARD:
      case ACCESS_LOG_CREDENTIAL_TYPE.FINGER_PRINT:
      case ACCESS_LOG_CREDENTIAL_TYPE.CODE:
      case ACCESS_LOG_CREDENTIAL_TYPE.QR_CODE:
      case ACCESS_LOG_CREDENTIAL_TYPE.REMOTE:
      case ACCESS_LOG_CREDENTIAL_TYPE.REX:
      case ACCESS_LOG_CREDENTIAL_TYPE.LICENSE_PLATE:
        return formatMessage(messagesMap[credential?.type]);
      default:
        return formatMessage(messages.accessTypeUnknown);
    }
  });

  if (accessLog.result === ACCESS_LOG_RESULT.UNAUTHORIZED) {
    return formatMessage(messages.accessTypeUnauthorizedAccess);
  }

  if (!types || types.length === 0) {
    return formatMessage(messages.accessTypeUnknown);
  }

  return uniq(types).join(', ');
};

AccessLogAccessType.propTypes = {
  accessLog: PropTypes.shape({
    credentials: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
      })
    ),
    result: PropTypes.string,
  }).isRequired,
};

export default AccessLogAccessType;

const messagesMap = {
  [ACCESS_LOG_CREDENTIAL_TYPE.LICENSE_PLATE]: messages.accessTypeLicensePlate,
  [ACCESS_LOG_CREDENTIAL_TYPE.MOBILE_KEY]: messages.accessTypeMobileKey,
  [ACCESS_LOG_CREDENTIAL_TYPE.CARD]: messages.accessTypeCard,
  [ACCESS_LOG_CREDENTIAL_TYPE.FINGER_PRINT]: messages.accessTypeFingerPrint,
  [ACCESS_LOG_CREDENTIAL_TYPE.CODE]: messages.accessTypeCode,
  [ACCESS_LOG_CREDENTIAL_TYPE.QR_CODE]: messages.accessTypeQrCode,
  [ACCESS_LOG_CREDENTIAL_TYPE.REMOTE]: messages.accessTypeRemote,
  [ACCESS_LOG_CREDENTIAL_TYPE.REX]: messages.accessTypeRex,
};
