import { Dialog } from '@2n/design-system';
import Box from '@mui/material/Box';
import React, { PropsWithChildren } from 'react';
import { IActionButtonData, IDSModalBase } from '../designSystemUnexported';

export interface IGenericModal extends IDSModalBase {
  primaryButton?: IActionButtonData;
  secondaryButton?: IActionButtonData;
  ternaryButton?: IActionButtonData;
}

// for use with useModal hook: open if mounted
const GenericModal = (data: PropsWithChildren<IGenericModal>) => (
  <Dialog
    footerOptions={{
      primaryButton: data.primaryButton,
      secondaryButton: data.secondaryButton,
      ternaryButton: data.ternaryButton,
    }}
    muiDialogProps={data.muiDialogProps}
    onClose={data.onClose}
    open
    title={data.title}
  >
    <Box data-testid={data.testId}>{data.children}</Box>
  </Dialog>
);

export default GenericModal;
