import { defineMessages } from 'react-intl';

export default defineMessages({
  Label: {
    defaultMessage: 'Last synchronization',
    description: 'Last synchronization field label',
    id: 'lastSynchronizationField.Label',
  },
  NeverSynchronized: {
    defaultMessage: 'Never synchronized',
    description: 'Last synchronization - never synchronized value',
    id: 'lastSynchronizationField.NeverSynchronized',
  },
});
