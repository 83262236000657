import { call } from 'redux-saga/effects';
import * as Api from '../../../api/deviceApi';
import { DEVICE_TYPE } from '../../../config/devices';
import { getTypeFromDeviceType } from '../../../helpers/devices';
import { responseValidateStatusOrThrow } from '../../../modules/helpers';
import { createApartmentIfFilled, getDevicesTypesSettings, getServices } from './helpers';

// eslint-disable-next-line max-statements
export default function* x2nDeviceSaga({ company, floors, formActions, onStep, site, values }) {
  const deviceType = yield getTypeFromDeviceType(values.deviceType);
  const settings = yield getDevicesTypesSettings(company.id, deviceType);
  const services = yield getServices(settings, values);
  const apartmentId = yield createApartmentIfFilled(company.id, site.id, values, floors);

  let extendData;
  if (deviceType === DEVICE_TYPE.IDT) {
    extendData = {
      type: DEVICE_TYPE.IDT,
    };
  } else {
    extendData = {
      securityCode: values.securityCode,
    };
  }

  const response = yield call(Api.addDevice, company.id, site.id, {
    name: values.name,
    serialNumber: values.serialNumber,
    storeHash: values.storeHash,
    ...extendData,
    apartmentId,
    services,
  });

  if (response.status === 400 && response?.data?.code === 'IV59') {
    yield onStep(1);
    throw new Error('skip');
  } else if (response.status === 400 && response?.data?.code === 'IV12') {
    yield onStep(1);
    throw new Error('skip');
  } else {
    yield responseValidateStatusOrThrow(response);
  }

  return response.data || {};
}
