import MuiCloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  icon: {
    color: 'white',
    cursor: 'pointer',
  },
  root: {
    p: 2,
    textAlign: 'right',
  },
};

const CloseIcon = ({ onClick }) => (
  <Box sx={styles.root}>
    <IconButton onClick={onClick}>
      <MuiCloseIcon sx={styles.icon} />
    </IconButton>
  </Box>
);

CloseIcon.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default CloseIcon;
