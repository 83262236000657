import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { useIntl } from 'react-intl';
import { SERVICE } from '../../config/devices';
import messages from './messages';

const style = {
  '& img': {
    '&.active': {
      filter: 'grayscale(25%)',
      opacity: '1',
    },
    filter: 'grayscale(100%)',
    height: '24px',
    opacity: 0.25,
    width: '24px',
  },
  '&:first-of-type': {
    marginLeft: 0,
  },
  '&:last-of-type': {
    marginRight: 0,
  },
  mx: 0.25,
};

export interface IItemServiceProps {
  active: boolean;
  customTitle?: string;
  image: string;
  service: 'MOBILE_VIDEO' | 'ACCESS_CONTROL' | 'REMOTE_CONFIGURATION' | 'ELEVATOR_INTEGRATION';
}

export const ItemService = ({ active, customTitle, image, service }: IItemServiceProps) => {
  const { formatMessage } = useIntl();
  const state = formatMessage(
    active ? messages.iconServicesIconServicesEnabled : messages.iconServicesIconServicesDisabled
  );
  const title = formatMessage(serviceMap[service], {
    br: <br />,
    state,
  });
  const altTitle = formatMessage(serviceMap[service], {
    br: ' - ',
    state,
  });

  return (
    <Tooltip title={customTitle || title}>
      <Box sx={style}>
        <img alt={customTitle || altTitle} className={active ? 'active' : 'inactive'} src={image} />
      </Box>
    </Tooltip>
  );
};

ItemService.defaultProps = {
  customTitle: '',
};

export default ItemService;

const serviceMap = {
  [SERVICE.MOBILE_VIDEO]: messages.iconServicesMobileVideo,
  [SERVICE.ACCESS_CONTROL]: messages.iconServicesAccessControl,
  [SERVICE.REMOTE_CONFIGURATION]: messages.iconServicesRemoteConfiguration,
  [SERVICE.ELEVATOR_INTEGRATION]: messages.iconServicesElevatorIntegration,
};
