import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useUrlParams } from '../../../../helpers/urlParams';
import actions, { selectors } from '../../../../modules/accessSettings';
import { getValidationSchema } from '../validationSchema';

export interface IAccessGroupDetailFormValues {
  description?: string;
  name: string;
}

export function useGetAccessGroupDetailFormData() {
  const dispatch = useDispatch();
  const { companyId, groupId, siteId } = useUrlParams();

  const data = useSelector(selectors.getAccessGroup);

  const formMethods = useForm<IAccessGroupDetailFormValues>({
    defaultValues: {
      description: '',
      name: '',
    },
    mode: 'onChange',
    resolver: yupResolver<IAccessGroupDetailFormValues>(getValidationSchema),
  });

  useEffect(() => {
    formMethods.reset({
      description: data.description,
      name: data.name,
    });
  }, []);

  const onSubmit = (updatedData: IAccessGroupDetailFormValues) => {
    dispatch(actions.updateAccessGroup(companyId, siteId, groupId, updatedData));
  };

  return {
    data,
    formMethods,
    onSubmit,
  };
}
