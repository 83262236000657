import { LoginFlow, RecoveryFlow } from '@ory/client';
import { AxiosError } from 'axios';

export type RequireField<T, K extends keyof T> = T & Required<Pick<T, K>>;

export interface IOryRecoveryFlow {
  id: string;
  csrfToken: string;
}

export interface IOryGeneralError {
  id?: string;
  code: number;
  reason: string;
  details?: IOryGeneralErrorDetailWithNewFlowId;
}

export interface IOryGeneralErrorDetailWithNewFlowId {
  continue_with?: {
    flow?: {
      id?: string;
    };
  }[];
}

export enum OryErrorDetailType {
  RecoveryCodeMaxAttempts = `recoveryCodeMaxAttempts`,
}

export enum OryErrorNamedMessageId {
  Security_csrf_violation = `security_csrf_violation`,
  Session_refresh_required = `session_refresh_required`,
  Session_already_available = `session_already_available`,
  Session_inactive = `session_inactive`,
  Session_self_service_flow_expired = `self_service_flow_expired`,
  Recovery_flow_expired_4060005 = 4060005,
  Recovery_flow_invalid_code_4060006 = 4060006,
  Login_flow_invalid_credentials_4000006 = 4000006,
  Login_flow_not_verified_email_4000010 = 4000010,
  Recover_flow_leaked_password_4000034 = 4000034,
  Recover_flow_similar_password_4000031 = 4000031,
}

export enum OryErrorCustomNamedMessageId {
  RecoveryCodeMaxAttempts = `recovery_code_max_attempts`,
}

export enum OryErrorMessageShowMethod {
  FieldValidation = 'field-validation',
  AppNotification = 'app-notification',
  FormAlertNotification = 'form-alert-notification',
  None = 'none',
}

export enum OryLoginStatus {
  SessionNeedsToBeChecked = 'session-needs-to-be-checked',
  SessionConfirmed = 'session-confirmed',
}

export interface IOryErrorMessageDefinition {
  message: string;
  showMethod: OryErrorMessageShowMethod;
  contexts?: IOryErrorMessageContextDefinition[];
}

export interface IOryErrorMessageContextDefinition {
  context: OryContext;
  definition: Omit<IOryErrorMessageDefinition, 'contexts'>;
}

export enum OryContext {
  ChangeCurrentUserPassword = 'change-current-user-password',
  Login = 'login',
}

export type OryAxiosError = { error: IOryGeneralError };

export type OryAxiosErrorResponse = AxiosError<LoginFlow | RecoveryFlow | OryAxiosError>;

export type OryErrorMessageId = number | string;

export type OryErrorMessage = {
  id: OryErrorMessageId;
  showMethod: OryErrorMessageShowMethod;
  detail?: IOryErrorDetail;
  context?: OryContext;
};

export type OrySuccessResponse = true;

export type OryError = false;

//TODO - from communication with ORY only one error message is returning
// but maybe in future with another requests there can be more messages
//export type OryErrorMessageIds = OryErrorMessageId[];

// export type OryErrorMessage = OryErrorMessageId; // | OryErrorMessageIds;

export type OryResponseWithData<T> = T | OryErrorMessage | OryError;

export type OryResponse = OryErrorMessage | OrySuccessResponse | OryError;

export type OryCancelableResponse = OryResponseCancelled | OryResponse;

export type OryResponseCancelled = undefined;

export const OryCancelled: OryResponseCancelled = undefined;
export const OrySuccess: OrySuccessResponse = true;

export interface IOryErrorDetail {
  type: OryErrorDetailType;
  data: object;
}

export interface IOryRecoveryCodeMaxAttemptsError extends OryErrorMessage {
  detail: {
    type: OryErrorDetailType.RecoveryCodeMaxAttempts;
    data: {
      newFlowId: string;
    };
  };
}

export function isOryErrorMessage(response: OryResponse | OryResponseWithData<any>): response is OryErrorMessage {
  return !!((response as OryErrorMessage).id && (response as OryErrorMessage).showMethod);
}

export function isOryAxiosError(object: LoginFlow | RecoveryFlow | OryAxiosError): object is OryAxiosError {
  return !!(object as OryAxiosError).error;
}

export function isIOryRecoveryCodeMaxAttempts(
  errorWithDetail: OryErrorMessage
): errorWithDetail is IOryRecoveryCodeMaxAttemptsError {
  return !!(errorWithDetail.detail && errorWithDetail.detail.type === OryErrorDetailType.RecoveryCodeMaxAttempts);
}
