import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const DoorStationHeader = () => (
  <Typography>
    <FormattedMessage {...messages.doorStationHeader} />
  </Typography>
);

export default DoorStationHeader;
