import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useUrlParams } from '../../../helpers/urlParams';
import { getValidationSchema } from '../../../validations/accessGroupAddUserModalValidationSchema';
import { useGetUserFormData } from './useGetUserFormData';

export function useGetGlobalHooks(hasFormItemKey) {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { companyId, siteId } = useUrlParams();
  const { users } = useGetUserFormData();

  const formMethods = useForm({
    defaultValues: {
      email: '',
      firstName: '',
      [hasFormItemKey]: false,
      lastName: '',
      users: [],
    },
    mode: 'onChange',
    resolver: yupResolver(getValidationSchema(companyId, siteId, formatMessage)),
    shouldUnregister: true,
  });

  return {
    dispatch,
    formatMessage,
    formMethods,
    handleSubmit: formMethods.handleSubmit,
    isDirty: formMethods.formState.isDirty,
    isValid: formMethods.formState.isValid,
    users,
  };
}
