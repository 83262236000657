import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ListHeaderLabel } from '../../../components';
import messages from './messages';

export interface IHeaderSettingsListData {
  handleOpen: (value: boolean) => void;
  opened: boolean;
}

const HeaderSettingsList = ({ handleOpen, opened }: IHeaderSettingsListData) => (
  <Grid container spacing={2}>
    <Grid item sx={{ textAlign: 'center' }} xs={1}>
      <ListHeaderLabel>
        <FormattedMessage {...messages.headerSettingsListButton} />
      </ListHeaderLabel>
    </Grid>
    <Grid item sm={5} xs={9}>
      <ListHeaderLabel>
        <FormattedMessage {...messages.headerSettingsListButtonName} />
      </ListHeaderLabel>
    </Grid>
    <Box component={Grid} item sx={{ display: { sm: 'block', xs: 'none' } }} xs={5}>
      <ListHeaderLabel>
        <FormattedMessage {...messages.headerSettingsListCallDestinations} />
      </ListHeaderLabel>
    </Box>
    <Grid item pb={0} sm={1} sx={{ pb: 0, textAlign: 'center' }} xs={2}>
      <IconButton onClick={() => handleOpen(!opened)} sx={{ m: 0, p: 0 }}>
        {opened ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </IconButton>
    </Grid>
  </Grid>
);

export default HeaderSettingsList;
