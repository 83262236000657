import { defineMessages } from 'react-intl';

export default defineMessages({
  PhonebooksMembersColumnsDeviceId: {
    defaultMessage: 'Device ID',
    description: 'Phonebook tab - device id column',
    id: 'phonebooks.members.columns.deviceId',
  },
  PhonebooksMembersColumnsName: {
    defaultMessage: 'Device name',
    description: 'Phonebook tab - device name column',
    id: 'phonebooks.members.columns.name',
  },
  PhonebooksMembersColumnsType: {
    defaultMessage: 'Type',
    description: 'Phonebook tab - type column',
    id: 'phonebooks.members.columns.type',
  },
});
