import { composedIdsConfig } from '../composedIds/config';

export interface IPossibleURLParams {
  userId?: string | number;
  apartmentId?: string | number;
  companyId?: string | number;
  siteId?: string | number;
  groupId?: string | number;
}

export function replaceURLParams({ params, url }: { url: string; params: IPossibleURLParams }): string {
  const composedIdsNames = Object.keys(composedIdsConfig);
  const paramsKeys = Object.keys(params);
  let finalUrl = url;

  composedIdsNames.forEach((name) => {
    if (url.indexOf(name) > 0) {
      finalUrl = finalUrl.replace(`:${name}`, composedIdsConfig[name]);
    }
  });

  paramsKeys.forEach((key: keyof IPossibleURLParams) => {
    if (params[key] !== undefined) {
      finalUrl = finalUrl.replace(`:${key}`, params[key]!.toString());
    }
  });

  return finalUrl;
}
