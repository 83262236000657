import Skeleton from '@mui/material/Skeleton';
import React from 'react';

interface IRenderCell {
  isFetching: boolean;
  children: React.ReactNode;
}

function RenderCell({ children, isFetching }: IRenderCell) {
  return isFetching ? <Skeleton animation="wave" height={25} width="80%" /> : children;
}

export default RenderCell;
