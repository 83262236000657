import { Theme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import React from 'react';
import LanguageSelect from '../../components/HeaderPublic/LanguageSelect';
import useBreakpoints from '../../helpers/useBreakpoints';
import { useGetActualCompanyId } from '../../hooks/companyHooks';

const styles = {
  innerContent: {
    position: 'relative',
    width: '100%',
  },
  parentContent: {
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 1201,
  },
  rightSide: {
    justifyContent: 'space-between',
    width: { md: 'inherit', sm: 160, xs: 80 },
  },
  sideMenu: {
    color: 'common.white',
    pl: 1,
  },
  withVersionWarning: {
    marginTop: (theme: Theme) => ({
      md: theme.appNotificationHeightLargeDevice / 8,
      xs: theme.appNotificationHeightSmallDevice / 8,
    }),
  },
};

export interface INavbarLayout {
  appIcon: React.JSX.Element;
  companyMenu: React.JSX.Element;
  isVersionWarningDisplayed: boolean;
  sidebarMobile: React.JSX.Element;
  siteMenu: React.JSX.Element;
  userMenu: React.JSX.Element;
}

export function NavbarLayout({
  appIcon,
  companyMenu,
  isVersionWarningDisplayed,
  sidebarMobile,
  siteMenu,
  userMenu,
}: INavbarLayout): React.JSX.Element {
  const { downLg, downMd } = useBreakpoints();
  const companyId = useGetActualCompanyId();

  return (
    <Box sx={styles.parentContent}>
      <Box
        sx={{
          ...styles.innerContent,
          ...(isVersionWarningDisplayed && styles.withVersionWarning),
        }}
      >
        <AppBar position="absolute">
          <Toolbar disableGutters>
            <Grid container>
              <Grid alignItems="center" container item xs>
                <Grid id="navbar-sidebar-mobile" item>
                  {sidebarMobile}
                </Grid>
                {!downMd && <Grid item>{companyMenu}</Grid>}
                {companyId !== undefined && <Grid item>{siteMenu}</Grid>}
              </Grid>
              <Grid alignItems="center" container item md sx={styles.rightSide}>
                <Box sx={{ display: downLg ? 'none' : 'flex' }}>
                  <Grid item>{appIcon}</Grid>
                </Box>
                <Grid alignItems="center" container flexWrap="nowrap" item justifyContent="flex-end" md>
                  <Grid item>
                    <LanguageSelect />
                  </Grid>
                  <Grid item>{userMenu}</Grid>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </Box>
    </Box>
  );
}
