import Box from '@mui/material/Box';
import MuiInputLabel from '@mui/material/InputLabel';
import PropTypes from 'prop-types';
import React from 'react';

const InputLabel = ({ children, ...other }) => (
  <MuiInputLabel shrink {...other}>
    <Box component="span" whiteSpace="nowrap">
      {children}
    </Box>
  </MuiInputLabel>
);

InputLabel.propTypes = {
  children: PropTypes.element.isRequired,
};

export default InputLabel;
