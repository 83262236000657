import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import React from 'react';
import RowMenu from '../../../../components/DataGrid/RowMenu';
import EditApartment from './EditApartment';
import RemoveApartment from './RemoveApartment';
import SetAccessGroups from './SetAccessGroups';

function DataGridRowMenu({ apartmentId, companyId, onRemove, onSetAccessGroup, siteId }) {
  const handleMenuClick = (fn) => {
    if (fn) {
      fn();
    }
  };

  return (
    <RowMenu
      menuItems={
        <div>
          <SetAccessGroups onClick={() => handleMenuClick(onSetAccessGroup)} />
          <RemoveApartment onClick={() => handleMenuClick(onRemove)} />
          <Divider />
          <EditApartment apartmentId={apartmentId} companyId={companyId} siteId={siteId} />
        </div>
      }
    />
  );
}

DataGridRowMenu.propTypes = {
  apartmentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onRemove: PropTypes.func.isRequired,
  onSetAccessGroup: PropTypes.func.isRequired,
  siteId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default DataGridRowMenu;
