import { IntlShape } from 'react-intl';
import * as Yup from 'yup';
import { headSiteData } from '../../../api/siteRequest';
import { TId } from '../../../modules/commonTypes';
import messages from './messages';

export function getAxisDoorStationValidationSchema(
  formatMessage: IntlShape['formatMessage'],
  companyId: TId,
  siteId: TId
) {
  return Yup.object().shape({
    macAddress: Yup.string()
      .required(formatMessage(messages.axisDoorStationFormRequired))
      .test('isRegExp', formatMessage(messages.axisDoorStationFormMacAddressNoValid), (value) =>
        new RegExp(/^(00-?40-?8C|AC-?CC-?8E|B8-?A4-?4F|E8-?27-?25)(-?([A-F0-9]{2})){3}$/i).test(value)
      ),
    name: Yup.string()
      .required(formatMessage(messages.axisDoorStationFormRequired))
      .max(50, formatMessage(messages.axisDoorStationFormTooLong))
      .test({
        message: formatMessage(messages.axisDoorStationFormNameExist),
        name: 'isExistName',
        test: async (name) => {
          try {
            let state = true;
            if (!name || name.length === 0) {
              return true;
            }
            await headSiteData({ companyId, siteId }, 'devices', { name }, false).then((response) => {
              state = response.status === 404;
            });

            return state;
          } catch (error) {
            return true;
          }
        },
      }),
  });
}
