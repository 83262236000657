import { defineMessages } from 'react-intl';

export default defineMessages({
  verificationStatusNotVerified: {
    defaultMessage: 'Not verified',
    description: 'Verification Status - Not verified',
    id: 'deviceVerificationStatus.NOT_VERIFIED',
  },
  verificationStatusPending: {
    defaultMessage: 'Waiting for response',
    description: 'Verification Status - Pending',
    id: 'deviceVerificationStatus.PENDING',
  },
  verificationStatusUnknown: {
    defaultMessage: 'Unknown',
    description: 'Verification Status - unknown',
    id: 'deviceVerificationStatus.unknown',
  },
  verificationStatusVerified: {
    defaultMessage: 'Verified',
    description: 'Verification Status - Verified',
    id: 'deviceVerificationStatus.VERIFIED',
  },
  verificationStatusZombie: {
    defaultMessage: 'Unable to verify',
    description: 'Verification Status - Zombie',
    id: 'deviceVerificationStatus.ZOMBIE',
  },
});
