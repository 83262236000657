import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RLink } from 'react-router-dom';
import Checkbox from '../../components/HookFormFields/Checkbox';
import { PATHS } from '../../config/consts';
import commonActions, { selectors as commonSelectors } from '../../modules/common';
import { useAppContext } from '../App/AppContextProvider';
import messages from './messages';

const styles = {
  newsletters: {
    '& .MuiCheckbox-root': {
      pt: 0,
    },
    alignItems: 'flex-start',
    mt: 1,
  },
  newslettersLabel: {
    display: 'flex',
    flexDirection: 'column',
  },
};

export function RegisterGtc() {
  const { currentUserLang } = useAppContext();
  const dispatch = useDispatch();
  const helperText = useSelector(commonSelectors.getGtcOptOutHelperText);
  const label = useSelector(commonSelectors.getGtcOptOutLabel);

  useEffect(() => {
    dispatch(commonActions.getGtcOptOutText());
  }, [currentUserLang]);

  return (
    <>
      <Checkbox
        id="agreement-checkbox"
        label={
          <FormattedMessage
            values={{
              linkcloud: (chunks) => (
                <Link component={RLink} target="_blank" to={PATHS.CLOUD_AGREEMENT}>
                  {chunks}
                </Link>
              ),
              linkinfo: (chunks) => (
                <Link component={RLink} target="_blank" to={PATHS.INFORMATION_FOR_CONSUMERS}>
                  {chunks}
                </Link>
              ),
            }}
            {...messages.registerAgreeLabel}
          />
        }
        name="agree"
        required
      />
      <br />
      {label && (
        <Checkbox
          id="newsletter-checkbox"
          label={
            <Box sx={styles.newslettersLabel}>
              {label}
              {helperText && <Typography variant="caption">{helperText}</Typography>}
            </Box>
          }
          name="newsletters"
          sx={styles.newsletters}
        />
      )}
    </>
  );
}
