import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ListHeaderLabel } from '../../../components';
import { SERVICE } from '../../../config/devices';
import messages from './messages';

const MobileVideoAppConnection = ({ device }) => (
  <Grid container direction="column">
    <Grid item>
      <ListHeaderLabel>
        <FormattedMessage {...getTitle(device)} />
      </ListHeaderLabel>
    </Grid>
    <Grid container direction="column" item spacing={1}>
      <Grid item>
        <Typography>
          <FormattedMessage {...getMessage(device)} />
        </Typography>
      </Grid>
      {device?.services?.[SERVICE.NOTIFICATION]?.qrCodeUrl && (
        <Grid
          item
          sx={{
            '& img': {
              border: '1px solid',
              borderColor: 'divider',
              height: '130px',
              width: '130px',
            },
          }}
        >
          <img alt="QR Code" src={device.services[SERVICE.NOTIFICATION].qrCodeUrl} />
        </Grid>
      )}
    </Grid>
  </Grid>
);

MobileVideoAppConnection.propTypes = {
  device: PropTypes.shape({
    services: PropTypes.shape({
      [SERVICE.NOTIFICATION]: PropTypes.shape({
        qrCodeUrl: PropTypes.string,
      }),
    }),
    type: PropTypes.string,
  }),
};

MobileVideoAppConnection.defaultProps = {
  device: {},
};

export default MobileVideoAppConnection;

function getTitle(device) {
  return device.services[SERVICE.NOTIFICATION].legacyAccessEnabled
    ? messages.mobileVideoAppConnectionBeforeMigrationTitle
    : messages.mobileVideoAppConnectionAfterMigrationTitle;
}

function getMessage(device) {
  return device.services[SERVICE.NOTIFICATION].legacyAccessEnabled
    ? messages.mobileVideoAppConnectionBeforeMigrationMessage
    : messages.mobileVideoAppConnectionAfterMigrationMessage;
}
