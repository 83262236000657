import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';

const contentStyle = {
  '& a': {
    color: 'primary.main',
  },
  '& h1': {
    fontSize: '2rem',
    fontWeight: 'bold',
    letterSpacing: 0,
    lineHeight: 1.334,
    m: 0,
    mb: 2,
  },
  '& h2': {
    m: 0,
    px: 0,
    py: 2,
  },
  '& ins': {
    pr: 1,
    textDecoration: 'none',
  },
  '& ol': {
    '& ol': {
      listStyleType: 'upper-alpha',
    },
    listStyleType: 'upper-roman',
    m: 0,
  },
  '& p': {
    m: 0,
    p: 0,
    pb: 1,
  },
  '& table': {
    '& td': {
      pl: 0,
      pr: 4,
      py: 1,
    },
    '& th': {
      fontWeight: 'bold',
      pl: 0,
      pr: 4,
      py: 1,
      textAlign: 'left',
    },
    '& tr': {
      borderBottom: '1px solid',
      borderBottomColor: 'divider',
    },
    borderCollapse: 'collapse',
    borderTop: '1px solid',
    borderTopColor: 'divider',
    mx: 0,
    my: 2,
    width: '100%',
  },
  '& ul.noflex': {
    mx: 3,
    my: 0,
    px: 0,
    py: 1,
  },
  '& ul:not(.noflex) li': {
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'start',
  },
  display: 'block',
  py: 4,
};

const Content = ({ html }) => <Box dangerouslySetInnerHTML={{ __html: html }} sx={contentStyle} />;

Content.propTypes = {
  html: PropTypes.string.isRequired,
};

export default Content;
