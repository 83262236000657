import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import Skeleton from '@mui/material/Skeleton';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DataGrid } from '../../../components';
import NameColumn from '../../../components/DataGrid/NameColumn';
import RowMenu from '../../../components/DataGrid/RowMenu';
import { PATHS } from '../../../config/consts';
import { useDataGrid } from '../../../helpers/dataGrid';
import useBreakpoints from '../../../helpers/useBreakpoints';
import { isFetchingMessages } from '../../../modules/localization/selectors';
import messages from '../messages';
import PhonebooksRowMenu from './PhonebooksRowMenu';

const PhonebooksDataGrid = ({
  companyId,
  data,
  didInvalid,
  filter,
  isFetching,
  onLoadPhonebooks,
  order,
  page,
  resultsFiltered,
  resultsTotal,
  rowsPerPage,
  siteId,
}) => {
  const { onOrderBy, onRowsPerPageChange, onSelectPage } = useDataGrid(
    onLoadPhonebooks,
    {
      companyId,
      filter,
      order,
      page,
      rowsPerPage,
      siteId,
    },
    didInvalid,
    isFetching
  );

  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { downSm } = useBreakpoints();
  const isLocalizationLoaded = useSelector(isFetchingMessages);

  const columns = useMemo(
    () => [
      {
        disableColumnMenu: true,
        field: 'name',
        flex: 1,
        headerName: formatMessage(
          downSm ? messages.phonebooksDataGridColumnsNameMobile : messages.phonebooksDataGridColumnsNameDesktop
        ),
        renderCell: (cell) =>
          isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <NameColumn iconComponent={<ContactPhoneIcon />} name={cell.value.name} />
          ),
        sortable: true,
      },
      {
        disableColumnMenu: true,
        field: 'deviceCount',
        flex: 1,
        headerName: formatMessage(messages.phonebooksDataGridColumnsDeviceCount),
        renderCell: (cell) =>
          isFetching ? <Skeleton animation="wave" height={25} width="80%" /> : cell.value.deviceCount,
        sortable: false,
      },
      {
        disableColumnMenu: true,
        field: 'rowMenu',
        headerName: '',
        renderCell: (cell) =>
          isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <RowMenu menuItems={<PhonebooksRowMenu companyId={companyId} phonebook={cell.value} siteId={siteId} />} />
          ),
        sortable: false,
        width: 65,
      },
    ],
    [downSm, isFetching, isLocalizationLoaded]
  );

  const rows = useMemo(
    () =>
      data.map((phonebook) => ({
        deviceCount: phonebook,
        id: phonebook.id,
        isClickable: true,
        name: phonebook,
        rowMenu: phonebook,
      })),
    [data]
  );

  return (
    <DataGrid
      columns={columns}
      disableRowSelectionOnClick
      hideFooterPagination={isFetching}
      loading={isFetching}
      onCellClick={(params) => {
        if (params.field !== 'rowMenu') {
          navigate(
            PATHS.PHONEBOOK_DETAIL.replace(':companyId', companyId)
              .replace(':siteId', siteId)
              .replace(':phonebookId', params.id)
          );
        }
      }}
      onOrderBy={onOrderBy}
      onRowsPerPageChange={onRowsPerPageChange}
      onSelectPage={onSelectPage}
      order={order}
      page={page}
      resultsFiltered={resultsFiltered}
      resultsTotal={resultsTotal}
      rows={rows}
      rowsPerPage={rowsPerPage}
    />
  );
};

PhonebooksDataGrid.propTypes = {
  companyId: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  didInvalid: PropTypes.bool.isRequired,
  filter: PropTypes.shape({ fulltext: PropTypes.string }).isRequired,
  isFetching: PropTypes.bool.isRequired,
  onLoadPhonebooks: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  resultsFiltered: PropTypes.number.isRequired,
  resultsTotal: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  siteId: PropTypes.number.isRequired,
};

export default PhonebooksDataGrid;
