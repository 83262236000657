import { Box, Stack, Typography } from '@mui/material';
import React from 'react';

interface IRowSectionTitle {
  icon: React.ReactNode;
  label: string;
  labelWidth?: number;
}

function RowSectionTitle({ icon, label, labelWidth }: IRowSectionTitle) {
  return (
    <Stack
      alignItems="flex-start"
      direction="row"
      spacing={1}
      sx={{ minWidth: labelWidth, width: { sm: labelWidth, xs: '100%' } }}
    >
      <Box alignItems="center" color="secondary.main" display="flex">
        {icon}
      </Box>
      <Typography fontWeight="medium" variant="body1">
        {label}
      </Typography>
    </Stack>
  );
}

RowSectionTitle.defaultProps = {
  labelWidth: 200,
};

export default RowSectionTitle;
