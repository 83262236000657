import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, Loader } from '../../../components';
import { PATHS } from '../../../config/consts';
import { IDevice } from '../../../modules/devices/store/deviceStore';
import { DevicesSettings } from './components/DevicesSettings';
import { IDeviceCallSettings } from './helpers/types';
import { IDeviceWithCallSettingsDetails } from './hooks/useGetCallSettingsState';
import messages from './messages';

const styles = {
  heading: {
    mb: 3,
    pr: 3,
  },
  tooltip: {
    '& .MuiTooltip-tooltip': {
      maxWidth: '200px',
    },
    color: 'secondary.dark',
    position: 'absolute',
    right: 0,
    top: 0,
  },
  wrapper: {
    mb: 2,
    position: 'relative',
  },
};

export interface ICallSettingsFormData {
  availableDevices: IDeviceWithCallSettingsDetails[];
  selectedDevices: IDeviceCallSettings[];
  ringingGroupSizeLimit: number;
  addedDevice: IDevice;
  onClearDevice: (deviceId: number) => void;
  onSelectDevice: (deviceId: number, previousDeviceId?: number) => void;
  onAddDevice: () => void;
  onAddButton: (deviceId: number) => void;
  onAddCallDestination: (deviceId: number, buttonIndex: number, addDevice: boolean) => void;
  onChangeButtonName: (deviceId: number, buttonIndex: number, name: string) => void;
  onClearDestination: (deviceId: number, buttonIndex: number, optionKey: number) => void;
  companyId: number;
  isFiveAndMoreDevices: boolean;
  siteId: number;
  isDeviceFetching: boolean;
}

function Form(data: ICallSettingsFormData): JSX.Element {
  return (
    <Box sx={styles.wrapper}>
      <Typography gutterBottom paragraph sx={styles.heading} variant="body2">
        {data.isFiveAndMoreDevices ? (
          <FormattedMessage {...messages.thirdStepFiveAndMoreHeading} />
        ) : (
          <FormattedMessage {...messages.thirdStepHeading} />
        )}
        <Tooltip
          placement="right"
          sx={styles.tooltip}
          title={
            data.isFiveAndMoreDevices ? (
              <FormattedMessage {...messages.thirdStepFiveAndMoreHeadingTooltip} />
            ) : (
              <FormattedMessage {...messages.thirdStepHeadingTooltip} />
            )
          }
        >
          <InfoOutlinedIcon />
        </Tooltip>
      </Typography>
      <DevicesSettings
        addedDeviceApartmentId={data.addedDevice.apartmentId}
        addedDeviceId={data.addedDevice.id}
        availableDevices={data.availableDevices}
        onAddButton={data.onAddButton}
        onAddCallDestination={data.onAddCallDestination}
        onAddDevice={data.onAddDevice}
        onChangeButtonName={data.onChangeButtonName}
        onClearDestination={data.onClearDestination}
        onClearDevice={data.onClearDevice}
        onSelectDevice={data.onSelectDevice}
        ringingGroupSizeLimit={data.ringingGroupSizeLimit}
        selectedDevices={data.selectedDevices}
      />
      {data.isDeviceFetching && (
        <Box sx={{ m: 2 }}>
          <Loader />
        </Box>
      )}
      {data.isFiveAndMoreDevices && data.selectedDevices.length === 1 && data.selectedDevices[0].selected === null && (
        <Typography gutterBottom paragraph sx={{ mt: 3 }} variant="body2">
          <FormattedMessage
            {...messages.thirdStepFiveAndMoreContent}
            values={{
              link: (
                <Link
                  to={PATHS.CALL_SETTINGS_INTERCOMS.replace(':companyId', data.companyId.toString()).replace(
                    ':siteId',
                    data.siteId.toString()
                  )}
                >
                  <FormattedMessage {...messages.thirdStepFiveAndMoreCallSettings} />
                </Link>
              ),
            }}
          />
        </Typography>
      )}
    </Box>
  );
}

export default Form;
