import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Alert from '../Alert';
import InfoColumn from './InfoColumn';
import { getItems, types } from './items';
import messages from './messages';
import SiteTypeItem from './SiteTypeItem';

const itemStyles = {
  '&:first-of-type': {
    pl: 0,
  },
  '&:last-of-type': {
    pr: 0,
  },
  maxWidth: '33.33%',
  px: 1,
  py: 2,
  width: '33.33%',
};

const alertStyle = {
  '& .MuiPaper-root': {
    mb: 1,
    mt: 0,
  },
};

const SelectedItemType = ({ company, site, siteType }) => {
  const currentItem = getItems(company, site).find((item) => item.value === siteType);
  return (
    <>
      <Typography paragraph variant="body2">
        <FormattedMessage {...messages.selectedItemTypeDescription} />
      </Typography>
      <SiteTypeItem
        currentValue={siteType}
        description={currentItem.description}
        icon={currentItem.icon}
        name="siteType"
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onChange={() => {}}
        title={currentItem.title}
        value={currentItem.value}
      />
      <Box display="flex">
        <Box sx={itemStyles}>
          <InfoColumn
            items={currentItem.info.paymentModel}
            title={<FormattedMessage {...messages.selectedItemTypePaymentModel} />}
          />
        </Box>
        <Box sx={itemStyles}>
          <InfoColumn
            items={currentItem.info.administration}
            title={<FormattedMessage {...messages.selectedItemTypeAdministration} />}
          />
        </Box>
        <Box sx={itemStyles}>
          <InfoColumn
            items={currentItem.info.limits}
            title={<FormattedMessage {...messages.selectedItemTypeLimits} />}
          />
        </Box>
      </Box>
      <Box sx={alertStyle}>
        <Alert severity="warning">
          <FormattedMessage {...messages.infoBoxDescription} />
        </Alert>
      </Box>
    </>
  );
};

SelectedItemType.propTypes = {
  company: PropTypes.shape({}).isRequired,
  site: PropTypes.shape({}).isRequired,
  siteType: PropTypes.oneOf(Object.values(types)),
};

export default SelectedItemType;
