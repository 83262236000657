import { useCookies } from 'react-cookie';
import { COOKIE_OPTIONS, COOKIES, FUNCTIONAL_COOKIES, MARKETING_COOKIES } from '../config/consts';

export const useCookieConsent = () => {
  const [cookies, setCookie] = useCookies();

  const clearCookies = (cookiesCollection) => {
    Object.keys(cookiesCollection).forEach((item) => {
      Object.keys(cookies).forEach((cookie) => {
        if (cookie.includes(cookiesCollection[item])) {
          setCookie(cookie, '', { ...COOKIE_OPTIONS, maxAge: 1 });
        }
      });
    });
  };

  return {
    cookieConsent: cookies?.[COOKIES.CONSENT] || null,
    functional: cookies?.[COOKIES.CONSENT]?.[COOKIES.FUNCTIONAL] || null,
    marketing: cookies?.[COOKIES.CONSENT]?.[COOKIES.MARKETING] || null,
    setCookieConsent: (settings) => {
      if (!settings?.[COOKIES.FUNCTIONAL]) {
        clearCookies(FUNCTIONAL_COOKIES);
      }
      if (!settings?.[COOKIES.MARKETING]) {
        clearCookies(MARKETING_COOKIES);
      }
      setCookie(COOKIES.CONSENT, settings, COOKIE_OPTIONS);
    },
    system: cookies?.[COOKIES.CONSENT]?.[COOKIES.SYSTEM] || null,
  };
};
