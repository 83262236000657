import { defineMessages } from 'react-intl';

export default defineMessages({
  SiteUsersImportBlockModalOk: {
    defaultMessage: 'Ok',
    description: 'Ok button of block dialog',
    id: 'siteUsersImportBlockModalOk',
  },
  SiteUsersImportImportModalStopImport: {
    defaultMessage: 'stop import',
    description: 'Stop import button of import users dialog',
    id: 'siteUsersImportImportModalStopImport',
  },
  SiteUsersImportModalImportUsers: {
    defaultMessage: 'Import users',
    description: 'Dialog title of import users dialog',
    id: 'siteUsersImportImportModalImportUsers',
  },
  SiteUsersImportProcessingImportBlockImportAnotherImportInProgress: {
    defaultMessage: 'Another import is in progress',
    description: 'Block modal - another import is in progress',
    id: 'siteUsersImportProcessingImportBlockImportAnotherImportInProgress',
  },
  SiteUsersImportProcessingImportBlockImportCouldNotStarted: {
    defaultMessage: 'Import couldn’t be started.',
    description: 'Block modal - could not started',
    id: 'siteUsersImportProcessingImportBlockImportCouldNotStarted',
  },
  SiteUsersImportProcessingImportBlockImportTryLaterText: {
    defaultMessage: 'Make sure the data hasn’t already been imported' + ' before you start.',
    description: 'Blocked step - try later text',
    id: 'siteUsersImportProcessingImportBlockImportTryLaterText',
  },
  SiteUsersImportProcessingImportBlockImportTryLaterTitle: {
    defaultMessage: 'Try again later.',
    description: 'Blocked step - try later title',
    id: 'siteUsersImportProcessingImportBlockImportTryLaterTitle',
  },
  SiteUsersImportProcessingImportProcessing: {
    defaultMessage: 'We’re processing your data.',
    description: 'Processing step - processing',
    id: 'siteUsersImportProcessingImportProcessing',
  },
  SiteUsersImportProcessingImportResume: {
    defaultMessage: '{total} {total, plural, one {record} other {records}} entered, {processed} processed',
    description: 'Processing step - resume',
    id: 'siteUsersImportProcessingImportResume',
  },
});
