import { getInitialReduxBaseStore, IReduxStoreBase } from '../commonTypes';
import { initialState } from './reducer';

export interface ICompany {
  id: number;
}

export interface ICompanyStore extends IReduxStoreBase<ICompany> {}

export function getInitialCompanyStore(): ICompanyStore {
  return {
    ...initialState,
    ...getInitialReduxBaseStore<ICompany>(),
  };
}
