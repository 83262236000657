import { defineMessages } from 'react-intl';

export default defineMessages({
  userAccessGroupsModalAddButtonLabel: {
    defaultMessage: 'Set Groups',
    description: 'User access groups modal - add button label',
    id: 'userAccessGroupsModal.addButtonLabel',
  },
  userAccessGroupsModalHelperText: {
    defaultMessage: 'You can set one or more Access groups for the user',
    description: 'Access group select helperText',
    id: 'userAccessGroupsModal.helperText',
  },
  userAccessGroupsModalText: {
    defaultMessage:
      'Access groups define which users have access via which devices.' +
      '<p>User with set credentials will have access via all devices in selected access groups.' +
      ' Access is activated within seconds.</p>',
    description: 'User access groups modal - text',
    id: 'userAccessGroupsModal.text',
  },
  userAccessGroupsModalTitle: {
    defaultMessage: 'Set access groups for user',
    description: 'User access groups modal - title',
    id: 'userAccessGroupsModal.title',
  },
});
