import { Delete } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ActionModalError } from '../../../../../../../components/_DesignSystemWrappers/Modal';
import { FE_ROLES } from '../../../../../../../config/consts';
import { ApartmentUser } from '../../../../../../../modules/apartmentAdmin/data';
import { IApartmentIdsInfo } from '../../../../../../../modules/apartments/store';
import { IModalBase } from '../../../../../../../modules/commonTypes';
import { AAUserInfo } from '../../../../../components/AAUserInfo';
import { useDeletePin } from '../../hooks/useDeletePin';
import { TestID } from '../../testIds';
import messages from './messages';

export interface IDeletePinModal extends IModalBase {
  user: ApartmentUser;
  apartmentIdsInfo: IApartmentIdsInfo;
}

export function DeleteQRModal({
  apartmentIdsInfo: { apartmentId, companyId, siteId },
  onClose,
  open,
  user,
}: IDeletePinModal) {
  const { formatMessage } = useIntl();

  const { isSubmitting, onDeleteHandler } = useDeletePin({
    apartmentIdsInfo: { apartmentId, companyId, siteId },
    onClose,
    user,
  });

  return (
    <ActionModalError
      icon={<Delete />}
      muiDialogProps={{
        maxWidth: 'xs',
      }}
      onClose={onClose}
      open={open}
      testId={TestID.DeletePinModal}
      primaryButtonAction={onDeleteHandler}
      primaryButtonText={formatMessage(messages.AAmodalDeleteGuestQrSubmit)}
      isPrimaryButtonDisabled={isSubmitting}
      secondaryButtonText={formatMessage(messages.AAmodalDeleteGuestQrCancel)}
      title={formatMessage(messages.AAmodalDeleteGuestQrTitle)}
    >
      <Box sx={{ mt: 2 }}>
        <Stack spacing={1}>
          <Typography fontWeight={700}>{user.lastName}</Typography>
          <Typography variant="body2" component="span">
            <FormattedMessage {...messages.AAmodalDeleteGuestQrText} />
          </Typography>
          <Stack spacing={2}>
            {user.role !== FE_ROLES.APARTMENT_GUEST && (
              <>
                <AAUserInfo user={user} />
                <Typography variant="body2" component="span">
                  <FormattedMessage {...messages.AAmodalDeleteGuestQrText2} />
                </Typography>
              </>
            )}
          </Stack>
        </Stack>
      </Box>
    </ActionModalError>
  );
}
