import { Dialog } from '@2n/design-system';
import Box from '@mui/material/Box';
import React from 'react';
import { IDSModalBase } from '../designSystemUnexported';

export interface IInfoModalData extends IDSModalBase {
  buttonText: string;
}

export function InfoModal(data: IInfoModalData) {
  return (
    <Dialog
      footerOptions={{
        primaryButton: {
          action: () => data.onClose(false),
          text: data.buttonText,
          variant: 'text',
        },
      }}
      hasBodyContentPadding
      muiDialogProps={data.muiDialogProps}
      hideCloseButton={false}
      onClose={data.onClose}
      open={!!data.open}
      title={data.title}
    >
      <Box data-testid={data.testId}>{data.children}</Box>
    </Dialog>
  );
}

export default InfoModal;
