import { defineMessages } from 'react-intl';

export default defineMessages({
  firmwareModalCloseBtn: {
    defaultMessage: 'Cancel',
    description: 'Firmware modal - close button',
    id: 'firmwareModal.closeBtn',
  },
  firmwareModalNewVersion: {
    defaultMessage: 'Update firmware',
    description: 'Firmware modal - new version',
    id: 'firmwareModal.newVersion',
  },
  firmwareModalUpgradeBtn: {
    defaultMessage: 'Update firmware',
    description: 'Firmware modal - upgrade button',
    id: 'firmwareModal.upgradeBtn',
  },
  firmwareModalVersionChanges: {
    defaultMessage: 'Version {version} includes the following changes:',
    description: 'Firmware modal - version changes',
    id: 'firmwareModal.versionChanges',
  },
  versionNotificationAvqWarningFwNoSupportUpgradeAvailableDescription: {
    defaultMessage:
      'Enabling Adaptive Video Quality together with secured calls will' +
      ' be available from firmware version {version}, which will be released soon',
    description: 'Fw with AVQ was not released yet - description',
    id: 'versionNotification.avqWarning.fwNoSupport.upgradeAvailable.description',
  },
  versionNotificationAvqWarningFwNoSupportUpgradeAvailableTitle: {
    defaultMessage: 'A new firmware version is available ({version})',
    description: 'Fw with AVQ was not released yet - title',
    id: 'versionNotification.avqWarning.fwNoSupport.upgradeAvailable.title',
  },
  versionNotificationAvqWarningFwNoSupportUpgradeNotAvailableDescription: {
    defaultMessage: 'It will be available from firmware version {version}, which will be released soon',
    description: 'Fw is latest but does not support AVQ - description',
    id: 'versionNotification.avqWarning.fwNoSupport.upgradeNotAvailable.description',
  },
  versionNotificationAvqWarningFwNoSupportUpgradeNotAvailableTitle: {
    defaultMessage: 'Adaptive Video Quality is unavailable while secured calls are active',
    description: 'Fw is latest but does not support AVQ - title',
    id: 'versionNotification.avqWarning.fwNoSupport.upgradeNotAvailable.title',
  },
  versionNotificationAvqWarningFwSupportDescription: {
    defaultMessage:
      'Upgrade to the higher version for the option to have Adaptive' +
      ' Video Quality enabled together with secured calls',
    description: 'Fw supports AVQ - description',
    id: 'versionNotification.avqWarning.fwSupport.description',
  },
  versionNotificationAvqWarningFwSupportTitle: {
    defaultMessage: 'A new firmware version is available ({version})',
    description: 'Fw supports AVQ - title',
    id: 'versionNotification.avqWarning.fwSupport.title',
  },
  versionNotificationDeprecatedDescription: {
    defaultMessage:
      'Update to a supported version for greater security' + ' and functionality. <open>See the benefits</open>',
    description: 'Version notification - deprecated description',
    id: 'versionNotification.deprecated.description',
  },
  versionNotificationDeprecatedTitle: {
    defaultMessage: 'The device is running an outdated firmware' + ' version for which support will end soon',
    description: 'Version notification - deprecated title',
    id: 'versionNotification.deprecated.title',
  },
  versionNotificationNewerDescription: {
    defaultMessage:
      'Update to the recommended version for greater' + ' security and functionality. <open>See the benefits</open>',
    description: 'Version notification - newer description',
    id: 'versionNotification.newer.description',
  },
  versionNotificationNewerTitle: {
    defaultMessage: 'A new firmware version is available ({version})',
    description: 'Version notification - newer title',
    id: 'versionNotification.newer.title',
  },
  versionNotificationUnsupportedDescription: {
    defaultMessage:
      'Update the firmware version manually to continue' + ' using My2N services. <open>Check the manual</open>',
    description: 'Version notification - unsupported description',
    id: 'versionNotification.unsupported.description',
  },
  versionNotificationUnsupportedTitle: {
    defaultMessage: 'The device is running an UNSUPPORTED firmware version.',
    description: 'Version notification - unsupported title',
    id: 'versionNotification.unsupported.title',
  },
  versionNotificationUpdateBtn: {
    defaultMessage: 'Update device',
    id: 'versionNotification.updateBtn',
  },
});
