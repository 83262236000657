import { defineMessages } from 'react-intl';

export default defineMessages({
  waitingDevicesDataGridWaitingDevicesRowMenuApprove: {
    defaultMessage: 'Approve device',
    description: 'Waiting devices data grid - approve button',
    id: 'waitingDevicesDataGrid.waitingDevicesRowMenu.approve',
  },
  waitingDevicesDataGridWaitingDevicesRowMenuReject: {
    defaultMessage: 'Reject device',
    description: 'Waiting devices data grid - reject button',
    id: 'waitingDevicesDataGrid.waitingDevicesRowMenu.reject',
  },
});
