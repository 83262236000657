import { defineMessages } from 'react-intl';

export default defineMessages({
  errorAlertBoxError: {
    defaultMessage:
      'We cannot calculate your purchase. The data we need is not available right' +
      ' now. We apologize for the inconvenience. Please try again later.',
    description: 'Error promo codes info',
    id: 'errorAlertBoxError',
  },
  removeBatchButtonLabel: {
    defaultMessage: 'Delete',
    description: 'Remove batch item button label',
    id: 'removeBatchButton.label',
  },
  removeBatchModalActionClose: {
    defaultMessage: 'Close',
    description: 'Remove batch modal close action',
    id: 'removeBatchModal.action.close',
  },
  removeBatchModalActionRemove: {
    defaultMessage: 'Delete voucher',
    description: 'Remove batch modal remove action',
    id: 'removeBatchModal.action.remove',
  },
  removeBatchModalText: {
    defaultMessage: 'You will remove the voucher from your order.',
    description: 'Remove batch modal text',
    id: 'removeBatchModal.text',
  },
  removeBatchModalTitle: {
    defaultMessage: 'Delete voucher',
    description: 'Remove batch modal title',
    id: 'removeBatchModal.title',
  },
});
