import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Modal from '../../../../components/Modal';
import { FAQ } from '../../../../config/address';
import messages from './messages';

export const switchingModes = {
  TO_CLOUD: 'TO_CLOUD',
  TO_LOCAL: 'TO_LOCAL',
};

const ConnectionModeChangeDialog = ({ isOpen, onClose, onConfirm, switchingMode }) => (
  <Modal
    actions={
      <Box>
        <Button color="primary" onClick={onClose}>
          <FormattedMessage {...messagesByMode[switchingMode].cancel} />
        </Button>
        <Button color="primary" onClick={onConfirm}>
          <FormattedMessage {...messagesByMode[switchingMode].confirm} />
        </Button>
      </Box>
    }
    headerVariant="primary"
    maxWidth="xs"
    name="local-mode-dialog"
    onClose={onClose}
    open={isOpen}
    title={<FormattedMessage {...messagesByMode[switchingMode].title} />}
  >
    <Typography component="p" sx={{ mb: 3 }}>
      <FormattedMessage {...messagesByMode[switchingMode].row1} />
    </Typography>
    <Typography component="p">
      <FormattedMessage
        {...messagesByMode[switchingMode].row2}
        values={{
          link: (
            <Link href={FAQ} rel="noopener noreferrer" target="_blank">
              {'2N FAQ'}
            </Link>
          ),
        }}
      />
    </Typography>
  </Modal>
);

ConnectionModeChangeDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  switchingMode: PropTypes.string.isRequired,
};

export default ConnectionModeChangeDialog;

const messagesByMode = [];
messagesByMode[switchingModes.TO_LOCAL] = {
  cancel: messages.networkModeChangeDialogLocalModeDialogCancel,
  confirm: messages.networkModeChangeDialogLocalModeDialogConfirm,
  row1: messages.networkModeChangeDialogLocalModeDialogWarningMessageRow1,
  row2: messages.networkModeChangeDialogLocalModeDialogWarningMessageRow2,
  title: messages.networkModeChangeDialogLocalModeDialogTitle,
};
messagesByMode[switchingModes.TO_CLOUD] = {
  cancel: messages.networkModeChangeDialogCloudModeDialogCancel,
  confirm: messages.networkModeChangeDialogCloudModeDialogConfirm,
  row1: messages.networkModeChangeDialogCloudModeDialogWarningMessageRow1,
  row2: messages.networkModeChangeDialogCloudModeDialogWarningMessageRow2,
  title: messages.networkModeChangeDialogCloudModeDialogTitle,
};
