import LinearProgress from '@mui/material/LinearProgress';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React from 'react';

const TableLoader = ({ colSpan, isLoading }) =>
  isLoading && (
    <TableRow sx={{ display: 'contents' }}>
      <TableCell colSpan={colSpan} sx={{ p: 0 }}>
        <LinearProgress />
      </TableCell>
    </TableRow>
  );

TableLoader.propTypes = {
  colSpan: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default TableLoader;
