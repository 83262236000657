import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { DEVICE_TYPE } from '../../../config/devices';
import { getIs2nDevice } from '../../../helpers/devices';
import messages from './messages';

export interface IEmptyFormData {
  deviceType: string;
}

const EmptyForm = ({ deviceType }: IEmptyFormData) => {
  const message = useMemo(() => {
    if (getIs2nDevice(deviceType)) {
      return {
        content: messages.thirdStepEmptyFormAnsweringUnitContent,
        heading: messages.thirdStepEmptyFormAnsweringUnitHeading,
      };
    }
    if (deviceType === DEVICE_TYPE.HIPMO) {
      return {
        content: messages.thirdStepEmptyFormSmartphoneContent,
        heading: messages.thirdStepEmptyFormSmartphoneHeading,
      };
    }
    return {
      content: messages.thirdStepEmptyFormOtherDevicesContent,
      heading: messages.thirdStepEmptyFormOtherDevicesHeading,
    };
  }, [deviceType]);

  return deviceType ? (
    <Box sx={{ height: 200 }}>
      <Typography sx={{ mb: 2 }} variant="body2">
        <FormattedMessage {...message?.heading} />
      </Typography>
      <Typography variant="body2">
        <FormattedMessage {...message?.content} />
      </Typography>
    </Box>
  ) : (
    <>{false}</>
  );
};

export default EmptyForm;
