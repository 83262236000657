import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { createRef, forwardRef, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import messages from './messages';

const DTMFCodeMaskedInput = forwardRef(({ ...props }, ref) => {
  const inputRef = createRef(ref);
  const mask = createNumberMask({
    allowDecimal: false,
    allowLeadingZeroes: true,
    allowNegative: false,
    includeThousandsSeparator: false,
    integerLimit: 15,
    prefix: '',
    suffix: '*',
  });
  return <MaskedInput {...props} mask={mask} ref={inputRef} />;
});

const DTMFCodeInput = ({ disabled, index, items, onChange, value }) => {
  const { formatMessage } = useIntl();

  const [showWarn, setShowWarn] = useState(false);

  useEffect(() => {
    let isUnique = true;
    const i = items.findIndex((item) => item.index === index);
    const initialError = items[i].codeWarn;
    items.forEach((item) => {
      if (
        item.index !== index &&
        item.code &&
        (item.code === value || item.code === value?.replace('*', '')) &&
        value !== ''
      ) {
        isUnique = false;
        setShowWarn(true);
      }
    });
    if (isUnique) {
      setShowWarn(false);
    }
    if ((initialError && isUnique) || (!initialError && !isUnique)) {
      onChange({ target: { name: 'code', value } }, !isUnique);
    }
  }, [items, value]);

  return (
    <TextField
      disabled={disabled}
      fullWidth
      helperText={
        <Typography
          component="span"
          sx={{
            color: 'warning.main',
            visibility: showWarn ? 'visible' : 'hidden',
          }}
          variant="body2"
        >
          <FormattedMessage {...messages.switchCodesUniqueWarning} />
        </Typography>
      }
      InputProps={{
        inputComponent: DTMFCodeMaskedInput,
      }}
      margin="none"
      name="code"
      onChange={onChange}
      placeholder={formatMessage(messages.switchCodesCodePlaceholder)}
      value={value}
    />
  );
};

DTMFCodeInput.propTypes = {
  disabled: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

DTMFCodeInput.defaultProps = {
  value: null,
};

export default DTMFCodeInput;
