import { useDispatch } from 'react-redux';
import { setOryLoginStatus } from '../../../modules/auth/actions';
import { getCurrentUser } from '../../../modules/user/actions';
import { containsOneOfSpecificErrors } from '../../errors/errorParser';
import { OryErrorNamedMessageId, OryLoginStatus, OrySuccess } from '../../types';
import { useOryLoginApi } from '../oryApi';
import { IUseOryResponseParser, useOryResponseParser } from '../oryResponseParser';

export interface IUseOryUserLogin {
  isOryApiFetching: boolean;
  loginOryUser: (values: { email: string; password: string }) => Promise<void>;
  getFormAlertMessage: IUseOryResponseParser['getFormAlertMessage'];
}

export function useOryUserLogin(): IUseOryUserLogin {
  const dispatch = useDispatch();
  const { getOryCurrentUserSession, isOryApiFetching, loginToOry } = useOryLoginApi();
  const { clearFormAlertMessage, getFormAlertMessage, parseOryResponse } = useOryResponseParser();

  const checkOrySessionAndGetCurrentUser = async () => {
    const currentUserSessionResponse = await getOryCurrentUserSession();
    if (parseOryResponse({ response: currentUserSessionResponse }) === OrySuccess) {
      dispatch(setOryLoginStatus(OryLoginStatus.SessionConfirmed));
      dispatch(getCurrentUser());
    }
  };

  const refreshLoginForCurrentUser = async (values: { email: string; password: string }) => {
    const response = await loginToOry({
      email: values.email,
      password: values.password,
      sessionRefresh: true,
    });
    if (parseOryResponse({ response }) === OrySuccess) {
      await checkOrySessionAndGetCurrentUser();
    }
  };

  const loginOryUser = async (values: { email: string; password: string }) => {
    clearFormAlertMessage();
    const response = await loginToOry({ email: values.email, password: values.password });
    parseOryResponse({
      onError: async (error) => {
        if (error && containsOneOfSpecificErrors(error, [OryErrorNamedMessageId.Session_already_available])) {
          await refreshLoginForCurrentUser(values);
        }
      },
      onSuccess: async () => {
        await checkOrySessionAndGetCurrentUser();
      },
      response,
    });
  };

  return {
    getFormAlertMessage,
    isOryApiFetching,
    loginOryUser,
  };
}
