import { defineMessages } from 'react-intl';

export default defineMessages({
  AAmodalDeleteRFIDCancel: {
    defaultMessage: 'Cancel',
    id: 'AAmodal.deleteRFID.cancel',
  },
  AAmodalDeleteRFIDSubmit: {
    defaultMessage: 'Delete key fob',
    id: 'AAmodal.deleteRFID.submit',
  },
  AAmodalDeleteRFIDText1: {
    defaultMessage: 'When you delete the RFID key fob, the user won’t be able to use it for access.',
    id: 'AAmodal.deleteRFID.text1',
  },
  AAmodalDeleteRFIDText2: {
    defaultMessage:
      'The RFID key fob is also deleted for other apartments the user may be in. <p>If the user has other access methods available, they can continue using them.</p>',
    id: 'AAmodal.deleteRFID.text2',
  },
  AAmodalDeleteRFIDTitle: {
    defaultMessage: 'Delete RFID key fob',
    id: 'AAmodal.deleteRFID.title',
  },
});
