import { defineMessages } from 'react-intl';

export default defineMessages({
  registerAgreeLabel: {
    defaultMessage:
      'I agree with <linkcloud>My2N Cloud Agreement</linkcloud>' +
      ' and I have read <linkinfo>Information for Consumers</linkinfo>',
    description: 'Register - agree label',
    id: 'register.agree.label',
  },
  registerAgreeLabelOld: {
    defaultMessage: 'I agree to <url>My2N Terms & Conditions</url> *',
    description: 'Register - old agree label',
    id: 'register.agree.labelOld',
  },
  registerAgreeRequired: {
    defaultMessage: 'You must agree to the terms.',
    description: 'Register - agree required label',
    id: 'register.agree.required',
  },
  registerCompanyNameLabel: {
    defaultMessage: 'Company name / Family name',
    description: 'Register - company name label',
    id: 'register.companyName.label',
  },
  registerEmailInvalid: {
    defaultMessage: 'Wrong format of the e-mail.',
    description: 'Register - email invalid',
    id: 'register.email.invalid',
  },
  registerEmailLabel: {
    defaultMessage: 'Your e-mail',
    description: 'Register - email label',
    id: 'register.email.label',
  },
  registerEmailUsed: {
    defaultMessage: `This e-mail is already used. Please choose a different one or
        log in to your existing account.`,
    description: 'Register - email already used',
    id: 'register.email.used',
  },
  registerFirstNameLabel: {
    defaultMessage: 'First name',
    description: 'Register - first name label',
    id: 'register.firstName.label',
  },
  registerLastNameLabel: {
    defaultMessage: 'Surname',
    description: 'Register - surname label',
    id: 'register.lastName.label',
  },
  registerLoginAction: {
    defaultMessage: 'Log in',
    description: 'Register - Log in',
    id: 'register.login.action',
  },
  registerLoginText: {
    defaultMessage: 'Already have an account?',
    description: 'Register - Already have an account?',
    id: 'register.login.text',
  },
  registerNewslettersHelperText: {
    defaultMessage:
      '(if you leave this checkbox blank, we will have the right to send' +
      ' you occasional e-mail marketing messages about news in our products)',
    description: 'Register - newsletters helper text',
    id: 'register.newsletters.helperText',
  },
  registerNewslettersLabel: {
    defaultMessage: 'I do not wish to receive e-mail marketing messages',
    description: 'Register - newsletters label',
    id: 'register.newsletters.label',
  },
  registerNewslettersLabelOld: {
    defaultMessage: 'I agree to receive occasional newsletters',
    description: 'Register - old newsletters label',
    id: 'register.newsletters.labelOld',
  },
  registerPasswordLabel: {
    defaultMessage: 'Password',
    description: 'Register - password label',
    id: 'register.password.label',
  },
  registerPasswordNoValid: {
    defaultMessage: 'Password does not conform to policy',
    description: 'Register - password no valid field',
    id: 'register.password.noValid',
  },
  registerReferrerLabel: {
    defaultMessage: 'Where did you find out about My2N?',
    description: 'Register - referrer label',
    id: 'register.referrer.label',
  },
  registerReferrerOptionsADVERTISEMENT: {
    defaultMessage: 'Internet advertisement',
    description: 'Register - Internet advertisement',
    id: 'register.referrer.options.ADVERTISEMENT',
  },
  registerReferrerOptionsARTICLE: {
    defaultMessage: 'Internet or print article',
    description: 'Register - Article',
    id: 'register.referrer.options.ARTICLE',
  },
  registerReferrerOptionsCONVENTION: {
    defaultMessage: 'At a convention',
    description: 'Register - At a convention',
    id: 'register.referrer.options.CONVENTION',
  },
  registerReferrerOptionsNEWSLETTER: {
    defaultMessage: 'Newsletter',
    description: 'Register - Newsletter',
    id: 'register.referrer.options.NEWSLETTER',
  },
  registerReferrerOptionsOTHER: {
    defaultMessage: 'Other',
    description: 'Register - Other',
    id: 'register.referrer.optionsOther',
  },
  registerReferrerOptionsPAPER_CARD: {
    defaultMessage: 'Paper card in product package',
    description: 'Register - Paper card in product package',
    id: 'register.referrer.options.PAPER_CARD',
  },
  registerReferrerOptionsPRODUCT_SELLER: {
    defaultMessage: 'From the seller of product',
    description: 'Register - From the seller of product',
    id: 'register.referrer.options.PRODUCT_SELLER',
  },
  registerReferrerOptionsSOCIAL_NETWORKS: {
    defaultMessage: 'Social networks',
    description: 'Register - Social networks',
    id: 'register.referrer.options.SOCIAL_NETWORKS',
  },
  registerRepeatPasswordLabel: {
    defaultMessage: 'Repeat password',
    description: 'Register - Repeat password label',
    id: 'register.repeatPassword.label',
  },
  registerRepeatPasswordSame: {
    defaultMessage: 'Passwords is not same',
    description: 'Register - passwords are not the same',
    id: 'register.repeatPassword.same',
  },
  registerRequired: {
    defaultMessage: 'Value is mandatory',
    description: 'Register - Value is mandatory',
    id: 'register.firstName.required',
  },
  registerSubtitle: {
    defaultMessage: 'Simply fill in the form to get best out of 2N products',
    description: 'Register - subtitle',
    id: 'register.subtitle',
  },
  registerSuccessDescription: {
    defaultMessage: 'Confirmation e-mail was sent.{br}' + 'Please check your e-mail box and follow the instructions.',
    description: '',
    id: 'register.success.description',
  },
  registerSuccessTitle: {
    defaultMessage: 'Registration successful!',
    description: '',
    id: 'register.success.title',
  },
  registerTitle: {
    defaultMessage: 'Create My2N account',
    description: 'Register - title',
    id: 'register.title',
  },
});
