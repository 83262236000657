import React, { createRef, forwardRef } from 'react';
import MaskedInput from 'react-text-mask';

const CodeMaskedInput = forwardRef(({ ...props }, ref) => {
  const inputRef = createRef(ref);
  return (
    <MaskedInput
      {...props}
      mask={[
        /[0-9,a-z,A-Z]/,
        /[0-9,a-z,A-Z]/,
        /[0-9,a-z,A-Z]/,
        /[0-9,a-z,A-Z]/,
        '-',
        /[0-9,a-z,A-Z]/,
        /[0-9,a-z,A-Z]/,
        /[0-9,a-z,A-Z]/,
        /[0-9,a-z,A-Z]/,
        '-',
        /[0-9,a-z,A-Z]/,
        /[0-9,a-z,A-Z]/,
        /[0-9,a-z,A-Z]/,
        /[0-9,a-z,A-Z]/,
        '-',
        /[0-9,a-z,A-Z]/,
        /[0-9,a-z,A-Z]/,
        /[0-9,a-z,A-Z]/,
        /[0-9,a-z,A-Z]/,
      ]}
      ref={inputRef}
    />
  );
});

export default CodeMaskedInput;
