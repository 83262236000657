import PropTypes from 'prop-types';
import React from 'react';
import SideBarMenuButton from './SideBarMenuButton';

const SideBarMenu = ({
  buttonEmptyLabel,
  buttonItemLabel,
  buttonStartIcon,
  buttonValue,
  children,
  onButtonClick,
  open,
}) => (
  <>
    <SideBarMenuButton
      emptyLabel={buttonEmptyLabel}
      itemLabel={buttonItemLabel}
      onClick={onButtonClick}
      open={open}
      startIcon={buttonStartIcon}
      value={buttonValue}
    />
    {open && children}
  </>
);

SideBarMenu.propTypes = {
  buttonEmptyLabel: PropTypes.element,
  buttonItemLabel: PropTypes.element,
  buttonStartIcon: PropTypes.element,
  buttonValue: PropTypes.string,
  children: PropTypes.node.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  open: PropTypes.bool,
};

SideBarMenu.defaultProps = {
  buttonEmptyLabel: <span />,
  buttonItemLabel: <span />,
  buttonStartIcon: <span />,
  buttonValue: '',
  open: false,
};

export default SideBarMenu;
