import { defineMessages } from 'react-intl';

export default defineMessages({
  configurationModeTextAutomatic: {
    defaultMessage:
      'The automatic configuration ensures maximum security,' +
      ' especially when your device is running the latest firmware version.',
    description: 'Automatic configuration mode text',
    id: 'configurationModeText.automatic',
  },
  configurationModeTextAutomaticAlert: {
    defaultMessage:
      'You are about to set your device to the <strong>automatic</strong> configuration mode.' +
      ' This might overwrite any changes previously made on the device locally. For details, <url>see FAQs</url>.',
    description: 'Automatic configuration alert info',
    id: 'configurationModeText.automaticAlert',
  },
  configurationModeTextFaq: {
    defaultMessage: 'For details, <url>see FAQs</url>.',
    description: 'Dialog faq text',
    id: 'configurationModeText.faq',
  },
  configurationModeTextManualAlert: {
    defaultMessage:
      'The <strong>manual</strong> configuration mode allows changing settings only locally on the device.' +
      ' Changes made in My2N will not be reflected.' +
      ' We strongly recommend using the <strong>automatic</strong> configuration mode.',
    description: 'Manual configuration alert info',
    id: 'configurationModeText.manualAlert',
  },
});
