import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import actions, { selectors } from '../../modules/apartments';
import { selectors as companySelectors } from '../../modules/company';
import { selectors as siteSelectors } from '../../modules/sites';
import ButtonDanger from '../ButtonDanger';
import Modal from '../Modal';
import messages from './messages';

const ApartmentDeleteConfirmModal = ({ apartmentId, companyId, isFetching, onClose, onDelete, open, siteId }) => (
  <Modal
    actions={
      <>
        <Button color="secondary" onClick={onClose}>
          <FormattedMessage {...messages.apartmentDeleteConfirmModalCancelButton} />
        </Button>
        <ButtonDanger
          isLoading={isFetching}
          onClick={() => onDelete(companyId, siteId, apartmentId)}
          startIcon={<DeleteIcon />}
        >
          <FormattedMessage {...messages.apartmentDeleteConfirmModalConfirmButton} />
        </ButtonDanger>
      </>
    }
    headerVariant="danger"
    maxWidth="xs"
    name="delete-apartment"
    onClose={onClose}
    open={open}
    title={<FormattedMessage {...messages.apartmentDeleteConfirmModalTitle} />}
  >
    <Typography paragraph>
      <FormattedMessage {...messages.apartmentDeleteConfirmModalText} />
    </Typography>
  </Modal>
);

ApartmentDeleteConfirmModal.propTypes = {
  apartmentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isFetching: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  siteId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

ApartmentDeleteConfirmModal.defaultProps = {
  apartmentId: null,
};

const mapStateToProps = (state) => ({
  apartmentId: selectors.getApartmentIdFromDeleteModal(state),
  companyId: companySelectors.getCompanyId(state),
  isFetching: selectors.isDeleteApartmentFetching(state),
  open: selectors.isDeleteModalOpen(state),
  siteId: siteSelectors.getSiteId(state),
});

const mapDispatchToProps = {
  onClose: actions.deleteApartmentHideModal,
  onDelete: actions.deleteApartment,
};

export default connect(mapStateToProps, mapDispatchToProps)(ApartmentDeleteConfirmModal);
