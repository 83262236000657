import { defineMessages } from 'react-intl';

export default defineMessages({
  deviceTypeAccessUnit: {
    defaultMessage: '2N Access unit',
    description: 'Device type - access unit',
    id: 'deviceType.ACCESS_UNIT',
  },
  deviceTypeAccessUnitM: {
    defaultMessage: '2N Access unit M',
    description: 'Device type - access unit M',
    id: 'deviceType.ACCESS_UNIT_M',
  },
  deviceTypeChameleon: {
    defaultMessage: '2N Access Unit QR',
    description: 'Device type - 2N Access unit QR',
    id: 'deviceType.CHAMELEON',
  },
  deviceTypeClip: {
    defaultMessage: '2N<sup>®</sup> Clip',
    description: 'Device type - 2N Clip',
    id: 'deviceType.CLIP',
  },
  deviceTypeDoorStation: {
    defaultMessage: 'AXIS Intercom',
    description: 'Device type - AXIS Intercom',
    id: 'deviceType.DOOR_STATION',
  },
  deviceTypeHipmo: {
    defaultMessage: 'Smartphone or tablet',
    description: 'Device type - Smartphone or tablet',
    id: 'deviceType.HIPMO',
  },
  deviceTypeIdCompact: {
    defaultMessage: '2N<sup>®</sup> Indoor Compact',
    description: 'Device type - 2N Indoor Compact',
    id: 'deviceType.ID_COMPACT',
  },
  deviceTypeIdt: {
    defaultMessage: '2N<sup>®</sup> Indoor Touch',
    description: 'Device type - 2N Indoor Touch',
    id: 'deviceType.IDT',
  },
  deviceTypeIdTalk: {
    defaultMessage: '2N<sup>®</sup> Indoor Talk',
    description: 'Device type - 2N Indoor Talk',
    id: 'deviceType.ID_TALK',
  },
  deviceTypeIdView: {
    defaultMessage: '2N<sup>®</sup> Indoor View',
    description: 'Device type - indoor view',
    id: 'deviceType.ID_VIEW',
  },
  deviceTypeIpIntercom: {
    defaultMessage: '2N IP Intercom',
    description: 'Device type - 2N IP Intercom',
    id: 'deviceType.IP_INTERCOM',
  },
  deviceTypeIpIntercomV2: {
    defaultMessage: '2N<sup>®</sup> IP Verso 2.0',
    description: 'Device type - 2N IP Intercom v2',
    id: 'deviceType.IP_INTERCOM_V2',
  },
  deviceTypeIpOneIntercom: {
    defaultMessage: '2N<sup>®</sup> IP One',
    description: 'Device type - 2N IP One',
    id: 'deviceType.IP_ONE_INTERCOM',
  },
  deviceTypeIpStyle: {
    defaultMessage: '2N<sup>®</sup> IP Style',
    description: 'Device type - IP Style',
    id: 'deviceType.IP_STYLE',
  },
  deviceTypeThirdParty: {
    defaultMessage: '3<sup>rd</sup> party device',
    description: 'Device type - 3rd party device',
    id: 'deviceType.THIRD_PARTY',
  },
});
