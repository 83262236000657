import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { LANGUAGES, PATHS } from '../../config/consts';
import actions from '../../modules/localization';
import ActionModal from '../_DesignSystemWrappers/Modal/ActionModal';
import InfoAlert from '../InfoAlert/InfoAlert';
import { useGetGlobalHooks } from './hooks/getGlobalHooks';
import messages from './messages';

function UserLanguageModal({ onClose, open }) {
  const { currentUserLang, dispatch, formatMessage, formMethods } = useGetGlobalHooks();
  const selectedLang = formMethods.watch('language');

  useEffect(() => {
    formMethods.reset({
      language: currentUserLang,
    });
  }, []);

  const onSubmit = (data) => {
    dispatch(actions.setUserModalLanguage(data.language));

    onClose();
  };

  return (
    <ActionModal
      testId="change-user-language-modal"
      icon={<CheckIcon />}
      isPrimaryButtonDisabled={selectedLang === currentUserLang}
      muiDialogProps={{ maxWidth: 'xs' }}
      onClose={onClose}
      open={open}
      primaryButtonAction={formMethods.handleSubmit(onSubmit)}
      primaryButtonText={formatMessage(messages.UserLanguageModalPrimaryButtonTextChange)}
      secondaryButtonText={formatMessage(messages.UserLanguageModalSecondaryButtonText)}
      title={formatMessage(messages.UserLanguageModalTitleChange)}
    >
      <InfoAlert>
        <FormattedMessage {...messages.userLanguageModalAlert} />
      </InfoAlert>
      <Box sx={{ my: 2 }}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <FormControl>
            <Controller
              control={formMethods.control}
              name="language"
              render={({ field }) => (
                <RadioGroup {...field}>
                  {Object.values(LANGUAGES).map((lang) => (
                    <FormControlLabel
                      control={<Radio />}
                      key={lang.nonTranslated}
                      label={lang.nonTranslated}
                      value={lang.locale}
                    />
                  ))}
                </RadioGroup>
              )}
            />
          </FormControl>
        </form>
      </Box>
    </ActionModal>
  );
}

UserLanguageModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default UserLanguageModal;
