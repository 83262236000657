import { CALLS_STATUS, RC_STATUS } from '../../../config/devices';
import { ISite } from '../../sites/store';
import { DeviceTypeEnum } from './storeTypes';

export interface IDevice {
  apartmentId?: number;
  callsStatus?: CALLS_STATUS;
  hasDefaultPassword?: boolean;
  id: number;
  name: string;
  services?: IDeviceServices;
  site: ISite;
  type: string;
  deviceType: string;
  userId: number;
  verificationStatus?: VerificationStatus;
  features?: IDeviceFeature[];
}

export type VerificationStatus = 'NOT_VERIFIED' | 'PENDING' | 'VERIFIED' | 'ZOMBIE';
export type DeviceFeatureType = 'BUTTON_CONFIGURATION' | 'CONTACT_LIST' | 'DISPLAY' | 'DTMF_CODE' | 'DTMF_SWITCH_LIST';
export type ContactType = 'RINGING_GROUP' | 'APARTMENT' | 'LOCAL_CALLS' | 'GSM_CALLS' | 'MOBILE_VIDEO';

export interface IDeviceFeature {
  id: number;
  type: DeviceFeatureType;
  externalId: string;
}

export interface IDTMFSwitchFeature extends IDeviceFeature {
  switches: IDTMFSwitchCode[];
}

export interface IDisplayConfiguration extends IButtonConfiguration {
  name: string;
}

export interface IDTMFSwitchCode {
  code: string;
  name: string;
  managed: boolean;
  enabled: boolean;
  id: number;
  index: number;
}

export interface IButtonConfiguration extends IDeviceFeature {
  buttons: IButtonAndDisplayContact[];
}

export interface IContactListConfiguration extends IDeviceFeature {
  contacts: IContactListContact[];
}

export interface IContactBase {
  externalId: string;
  id: number;
  name: string;
  virtualNumber?: string;
}

export interface IButtonAndDisplayContact extends IContactBase {
  contacts: IContactListContact[];
  index: number;
}

export interface IContactListContact extends IContactBase {
  contactItems: ContactItemType[];
}

export interface IContactItem {
  externalId?: string;
  id?: number;
  type: ContactType;
}

export type ContactItemType =
  | IRingingGroupContact
  | IApartmentContact
  | ILocalCallsContact
  | IGsmCallsContact
  | IMobileVideoContact;

export interface IRingingGroupContact extends IContactItem {
  sipNumber?: string;
  members: number[];
  gsmFallback: IGsmFallback | undefined;
}

export interface IGsmFallback {
  deviceConfigId: number;
  gatewayId: number;
}

export interface IApartmentContact extends IContactItem {
  sipNumber?: string;
  apartmentId: number;
}

export interface ILocalCallsContact extends IContactItem {
  deviceConfigId: number;
}

export interface IGsmCallsContact extends IContactItem {
  deviceConfigId: number;
  gatewayId: number;
}

export interface IMobileVideoContact extends IContactItem {
  deviceConfigId: number;
}

export interface IDeviceServices {
  ACS?: IAcsService;
  LOCAL_CALLS?: ILocalCallsService;
  REMOTE_CONFIGURATION?: IRemoteConfigurationService;
  MOBILE_VIDEO?: IMobileVideoService;
  RINGING_GROUP?: IRingingGroupService;
  GSM_CALLS?: IGsmCallsContact;
  NOTIFICATION?: INotification;
  ACCESS_CONTROL?: IAccessControlService;
}
export interface IAccessControlService extends IDeviceService {}

export interface INotification extends IDeviceService {
  qrCodeUrl: string;
}

export interface IMobileVideoService extends IDeviceService {
  hasLicence: boolean;
}

export interface IRingingGroupService extends IDeviceService {
  members: number[];
  sipNumber: string;
}

export interface IAcsService extends IDeviceService {
  firmware: string;
  firmwareStatus?: string;
  lastSeen?: string;
}

export interface ILocalCallsService extends IDeviceService {
  name: string;
}

export interface IGsmCallsService extends IDeviceService {
  name: string;
}

export interface IRemoteConfigurationService extends IDeviceService {
  status: RC_STATUS;
}

export interface IDeviceService {
  id: number;
  active: boolean;
  dateCreated?: Date;
  lastUpdated?: Date;
  type?: DeviceTypeEnum;
}

export function isButtonOrDisplayConfigurationFeature(feature: IDeviceFeature): feature is IButtonConfiguration {
  return feature.type === 'BUTTON_CONFIGURATION' || feature.type === 'DISPLAY';
}

export function isButtonConfigurationFeature(feature: IDeviceFeature): feature is IButtonConfiguration {
  return feature.type === 'BUTTON_CONFIGURATION';
}

export function isContactListConfigurationFeature(feature: IDeviceFeature): feature is IContactListConfiguration {
  return (feature as IContactListConfiguration)?.contacts !== undefined;
}

export function isRingingGroupContactItem(contactItem: IContactItem | undefined): contactItem is IRingingGroupContact {
  return contactItem !== undefined && (contactItem as IRingingGroupContact).type === 'RINGING_GROUP';
}

export function isApartmentContactItem(contactItem: IContactItem | undefined): contactItem is IApartmentContact {
  return contactItem !== undefined && (contactItem as IApartmentContact).type === 'APARTMENT';
}

export function isDisplayConfiguration(feature: IDeviceFeature): feature is IDisplayConfiguration {
  return (feature as IDisplayConfiguration).name !== undefined;
}
