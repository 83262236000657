import { defineMessages } from 'react-intl';

export default defineMessages({
  backButtonLabelFull: {
    defaultMessage: 'Back to edit details',
    description: 'back button label full',
    id: 'backButton.labelFull',
  },
  backButtonLabelShort: {
    defaultMessage: 'Back',
    description: 'back button label short',
    id: 'backButton.labelShort',
  },
  orderListHeader: {
    defaultMessage: 'Your order',
    description: 'Batch order list section header',
    id: 'orderList.header',
  },
  orderListMobileVideoPoolCreditYearApartmentMore: {
    defaultMessage: 'apartments',
    description: 'Batch count apartments',
    id: 'orderList.MOBILE_VIDEO_POOL_CREDIT_YEAR_APARTMENTMore',
  },
  orderListMobileVideoPoolCreditYearApartmentOne: {
    defaultMessage: 'apartment',
    description: 'Batch count apartment',
    id: 'orderList.MOBILE_VIDEO_POOL_CREDIT_YEAR_APARTMENTOne',
  },
  orderListMobileVideoPoolCreditYearApartmentTotalValueMore: {
    defaultMessage: 'years/apartment',
    description: 'Batch count years/apartment value',
    id: 'orderList.MOBILE_VIDEO_POOL_CREDIT_YEAR_APARTMENT.totalValueMore',
  },
  orderListMobileVideoPoolCreditYearApartmentTotalValueOne: {
    defaultMessage: 'year/apartment',
    description: 'Batch count year/apartment value',
    id: 'orderList.MOBILE_VIDEO_POOL_CREDIT_YEAR_APARTMENT.totalValueOne',
  },
  orderListMobileVideoPoolCreditYearStandartMore: {
    defaultMessage: 'devices',
    description: 'Batch count value devices',
    id: 'orderList.MOBILE_VIDEO_POOL_CREDIT_YEAR_STANDARDMore',
  },
  orderListMobileVideoPoolCreditYearStandartOne: {
    defaultMessage: 'device',
    description: 'Batch count value device',
    id: 'orderList.MOBILE_VIDEO_POOL_CREDIT_YEAR_STANDARDOne',
  },
  orderListMobileVideoPoolCreditYearStandartTotalValueMore: {
    defaultMessage: 'years/device',
    description: 'Batch count years/device value',
    id: 'orderList.MOBILE_VIDEO_POOL_CREDIT_YEAR_STANDARD.totalValueMore',
  },
  orderListMobileVideoPoolCreditYearStandartTotalValueOne: {
    defaultMessage: 'year/device',
    description: 'Batch count year/device value',
    id: 'orderList.MOBILE_VIDEO_POOL_CREDIT_YEAR_STANDARD.totalValueOne',
  },
  orderListValue: {
    defaultMessage: 'Total voucher value',
    description: 'Batch value label',
    id: 'orderList.value',
  },
  orderListYear: {
    defaultMessage: 'year',
    description: 'Batch count value year',
    id: 'orderList.year',
  },
  orderListYears: {
    defaultMessage: 'years',
    description: 'Batch count value years',
    id: 'orderList.years',
  },
});
