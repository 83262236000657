import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import React from 'react';
import { FAQ } from '../../../config/address';

function FaqLink({ children }) {
  return (
    <Link color="primary" href={FAQ} target="_blank" variant="body1">
      {children}
    </Link>
  );
}

FaqLink.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FaqLink;
