import { defineMessages } from 'react-intl';

export default defineMessages({
  formActionBackBtn: {
    defaultMessage: 'Back',
    description: 'Device Modal Form action - back button',
    id: 'thirdParty.formAction.backBtn',
  },
  formActionCancelBtn: {
    defaultMessage: 'Cancel',
    description: 'Device Modal Form action - cancel button',
    id: 'thirdParty.formAction.cancelBtn',
  },
  formActionNextBtn: {
    defaultMessage: 'Next',
    description: 'Device Modal Form action - next button',
    id: 'thirdParty.formAction.nextBtn',
  },
  oneStepDescription: {
    defaultMessage:
      'Each device needs to be assigned to a user. Select an existing' +
      ' user or create a new one. New users will receive an email notification to' +
      ' confirm their accounts.',
    description: 'Description',
    id: 'oneStep.description',
  },
  oneStepNameHelperText: {
    defaultMessage: 'The name appears on the device list and phone book',
    description: 'Device name helper text',
    id: 'oneStep.name.helperText',
  },
  oneStepNameLabel: {
    defaultMessage: 'Device name',
    description: 'Device name label',
    id: 'oneStep.name.label',
  },
});
