import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import DTMFCodeInput from './DTMFCodeInput';
import { ISwitchItem } from './helpers';
import messages from './messages';

export interface IRowItem {
  data: ISwitchItem;
  isDeviceDetailEditing: boolean;
  isDoorStation: boolean;
  items: ISwitchItem[];
  onChange: (index: number, name: string, value: string | boolean, codeWarn?: boolean) => void;
  setDeviceEditing: (isEditing: boolean) => void;
}

function RowItem({ data, isDeviceDetailEditing, isDoorStation, items, onChange, setDeviceEditing }: IRowItem) {
  const { formatMessage } = useIntl();
  const isDisabled = !data.managed && !isDoorStation;

  function handleChange(event: React.ChangeEvent<HTMLInputElement>, codeWarn: boolean = false): void {
    if (!isDeviceDetailEditing) {
      setDeviceEditing(true);
    }

    const { checked, name, type, value } = event.target;
    onChange(data.index, name, type === 'checkbox' ? checked : value, codeWarn);
  }

  return (
    <ListItem divider>
      <Grid alignItems="center" container spacing={2}>
        <Grid item xs={1}>
          <Typography>{data.index}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Checkbox checked={data.enabled} disabled={isDisabled} onChange={handleChange} name="enabled" />
        </Grid>
        <Grid item mt={2.75} xs={isDoorStation ? 4 : 3}>
          <DTMFCodeInput
            disabled={isDisabled}
            index={data.index}
            items={items}
            onChange={handleChange}
            value={data.code}
          />
        </Grid>
        <Grid item mt={2.75} xs={isDoorStation ? 6 : 4}>
          <TextField
            disabled={isDisabled}
            error={Boolean(data.code && !data.name)}
            helperText={
              <Typography
                component="span"
                sx={{
                  color: 'error.main',
                  visibility: data.code && !data.name ? 'visible' : 'hidden',
                }}
                variant="body2"
              >
                <FormattedMessage {...messages.switchCodesNameNotFilledError} />
              </Typography>
            }
            fullWidth
            inputProps={{ maxLength: 35 }}
            margin="none"
            name="name"
            onChange={handleChange}
            placeholder={formatMessage(messages.switchCodesNamePlaceholder)}
            value={data.name}
          />
        </Grid>
        {!isDoorStation && (
          <Grid item xs={3}>
            <FormControlLabel
              control={<Switch checked={data.managed} onChange={handleChange} name="managed" />}
              label={
                <Typography>
                  <FormattedMessage
                    {...(data.managed ? messages.switchCodesSwitchManaged : messages.switchCodesSwitchNotManaged)}
                  />
                </Typography>
              }
            />
          </Grid>
        )}
      </Grid>
    </ListItem>
  );
}

export default RowItem;
