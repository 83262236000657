import { defineMessages } from 'react-intl';

export default defineMessages({
  agreeGTCLabel: {
    defaultMessage:
      'I agree with <cloudLink>My2N Cloud Agreement</cloudLink> and' +
      ' I have read <consumerLink>Information for Consumers</consumerLink>',
    id: 'agreeGTC.label',
  },
  alreadyBoughtLicenseHeader: {
    defaultMessage: 'Already bought licenses',
    description: 'Already bought license header',
    id: 'alreadyBoughtLicense.header',
  },
  alreadyBoughtLicenseHeaderWithInfo: {
    defaultMessage: 'Already bought licenses <link>(more info)</link>',
    description: 'Already bought license header with info',
    id: 'alreadyBoughtLicense.headerWithInfo',
  },
  availableLicenseButton: {
    defaultMessage: 'Close',
    description: 'Available license button',
    id: 'availableLicense.button',
  },
  availableLicenseModalHeader: {
    defaultMessage: '{type} license details',
    description: 'Available license modal header',
    id: 'availableLicense.modalHeader',
  },
  availableLicenseTypeLICENCE_ACCESS_UNIT_LIFT_MODULE: {
    defaultMessage: 'enables the configuration of lift floor access',
    description: 'AvailableLicense - lift en',
    id: 'availableLicense.type.LICENCE_ACCESS_UNIT_LIFT_MODULE.EN',
  },
  availableLicenseTypeLICENCE_ENHANCED_AUDIO: {
    defaultMessage: 'enables User sounds, Automatic audio test and Noise detection',
    description: 'AvailableLicense - audio en',
    id: 'availableLicense.type.LICENCE_ENHANCED_AUDIO.EN',
  },
  availableLicenseTypeLICENCE_ENHANCED_INTEGRATION: {
    defaultMessage:
      'enables Extended switch setting options, HTTP API (Camera and Switch), ' +
      'Automation functions, E-mail sending (SMTP Client), Automatic update (TFTP/HTTP ' +
      'Client), FTP client, SNMP client and TR-069',
    description: 'AvailableLicense - integration en',
    id: 'availableLicense.type.LICENCE_ENHANCED_INTEGRATION.EN',
  },
  availableLicenseTypeLICENCE_ENHANCED_SECURITY: {
    defaultMessage: 'enables 802.1x support and SIPS (TLS) support',
    description: 'AvailableLicense - security en',
    id: 'availableLicense.type.LICENCE_ENHANCED_SECURITY.EN',
  },
  availableLicenseTypeLICENCE_ENHANCED_VIDEO: {
    defaultMessage:
      'enables Audio/video streaming (RTSP Server), External IP camera ' +
      'support, ONVIF support, PTZ function support and Motion detection support',
    description: 'AvailableLicense - video en',
    id: 'availableLicense.type.LICENCE_ENHANCED_VIDEO.EN',
  },
  availableLicenseTypeLICENCE_GOLD: {
    defaultMessage:
      'enables all features included in NFC, Enhanced integration, Enhanced ' +
      'video, Enhanced audio and Enhanced security licenses',
    description: 'AvailableLicense - gold en',
    id: 'availableLicense.type.LICENCE_GOLD.EN',
  },
  availableLicenseTypeLICENCE_INFORMCAST: {
    defaultMessage: 'allows the SingleWire InformaCast protocol to be used',
    description: 'AvailableLicense - informacast en',
    id: 'availableLicense.type.LICENCE_INFORMCAST.EN',
  },
  availableLicenseTypeLICENCE_INTERCOM_LIFT_MODULE: {
    defaultMessage: 'enables the configuration of lift floor access',
    description: 'AvailableLicense - lift en',
    id: 'availableLicense.type.LICENCE_INTERCOM_LIFT_MODULE.EN',
  },
  availableLicenseTypeLICENCE_NFC: {
    defaultMessage:
      'enables authentication via NFC-equipped mobile phones for selected ' + '13Mhz RFID card reader models',
    description: 'AvailableLicense - nfc en',
    id: 'availableLicense.type.LICENCE_NFC.EN',
  },
  availableLicenseTypeLICENCE_NFC_ACCESS_UNIT: {
    defaultMessage:
      'enables authentication via NFC-equipped mobile phones for selected ' + '13Mhz RFID card reader models',
    description: 'AvailableLicense - nfc en',
    id: 'availableLicense.type.LICENCE_NFC_ACCESS_UNIT.EN',
  },
  billingInfoCompany: {
    defaultMessage: 'Company/Family Address',
    description: 'Billing info company name',
    id: 'billingInfo.company',
  },
  billingInfoEdit: {
    defaultMessage: 'Edit',
    description: 'Billing info edit button label',
    id: 'billingInfo.edit',
  },
  billingInfoHeader: {
    defaultMessage: 'Billing information',
    description: 'Billing info header',
    id: 'billingInfo.header',
  },
  billingInfoLabel: {
    defaultMessage: 'Your purchase order',
    description: 'Billing info label',
    id: 'billingInfo.label',
  },
  billingInfoLink: {
    defaultMessage: 'If you need to change your Billing info, ' + 'please contact Customer Care at {link}',
    description: 'Billing info customer care link',
    id: 'billingInfo.link',
  },
  billingInfoLinkAddress: {
    defaultMessage: ' customercare@2n.com',
    description: 'Billing info customer care linkAddress',
    id: 'billingInfo.linkAddress',
  },
  billingInfoPlaceholder: {
    defaultMessage: 'PO number placed on invoice',
    description: 'Billing info placeholder',
    id: 'billingInfo.placeholder',
  },
  creditLimitError: {
    defaultMessage:
      '<bold>The order cannot be processed because you have reached your' +
      ' credit limit.</bold> Please review the balance of your 2N open invoices. In case' +
      ' you need a complete statement of your invoices, please contact our Customer' +
      ' Care department at <url>customercare@2n.com</url>.',
    description: 'Credit limit error text',
    id: 'creditLimitError',
  },
  licenseSelectionAccessUnit: {
    defaultMessage:
      'The license model is made simpler now. Upgrade firmware to version' +
      ' 2.35 or higher to get all features for free. <link>Learn more</link>',
    description: 'OneStep - no license info for access unit',
    id: 'licenseSelection.accessUnit',
  },
  licenseSelectionFormLabelText: {
    defaultMessage: 'Available licenses <link>(more info)</link>',
    description: 'License form label',
    id: 'licenseSelection.formLabel.text',
  },
  licenseSelectionNoLicense: {
    defaultMessage: 'There are no available license for this device type',
    description: 'OneStep - no license info',
    id: 'licenseSelection.noLicense',
  },
  licenseSelectionNoLicenseNew: {
    defaultMessage: 'No available licenses for this device type',
    description: 'OneStep - no license info new message',
    id: 'licenseSelection.noLicenseNew',
  },
  licenseSelectionTypeLICENCE_ACCESS_UNIT_LIFT_MODULE: {
    defaultMessage: '9160401 - 2N Access Unit - Lift module license',
    description: 'OneStep - nfc',
    id: 'licenseSelection.type.LICENCE_ACCESS_UNIT_LIFT_MODULE',
  },
  licenseSelectionTypeLICENCE_ENHANCED_AUDIO: {
    defaultMessage: '9137905 - 2N IP Intercom - Enhanced Audio license',
    description: 'OneStep - audio',
    id: 'licenseSelection.type.LICENCE_ENHANCED_AUDIO',
  },
  licenseSelectionTypeLICENCE_ENHANCED_INTEGRATION: {
    defaultMessage: '9137907 - 2N IP Intercom - Enhanced Integration license',
    description: 'OneStep - integration',
    id: 'licenseSelection.type.LICENCE_ENHANCED_INTEGRATION',
  },
  licenseSelectionTypeLICENCE_ENHANCED_SECURITY: {
    defaultMessage: '9137908 - 2N IP Intercom - Enhanced Security license',
    description: 'OneStep - security',
    id: 'licenseSelection.type.LICENCE_ENHANCED_SECURITY',
  },
  licenseSelectionTypeLICENCE_ENHANCED_VIDEO: {
    defaultMessage: '9137906 - 2N IP Intercom - Enhanced Video license',
    description: 'OneStep - video',
    id: 'licenseSelection.type.LICENCE_ENHANCED_VIDEO',
  },
  licenseSelectionTypeLICENCE_GOLD: {
    defaultMessage: '9137909 - 2N IP Intercom - Gold license',
    description: 'OneStep - gold',
    id: 'licenseSelection.type.LICENCE_GOLD',
  },
  licenseSelectionTypeLICENCE_INFORMCAST: {
    defaultMessage: '9137910 - 2N IP Intercom - Informacast license',
    description: 'OneStep - informacast',
    id: 'licenseSelection.type.LICENCE_INFORMCAST',
  },
  licenseSelectionTypeLICENCE_INTERCOM_LIFT_MODULE: {
    defaultMessage: '9137916 - 2N IP Intercom - Lift module license',
    description: 'OneStep - lift',
    id: 'licenseSelection.type.LICENCE_INTERCOM_LIFT_MODULE',
  },
  licenseSelectionTypeLICENCE_NFC: {
    defaultMessage: '9137915 - 2N IP Intercom - NFC license',
    description: 'OneStep - nfc',
    id: 'licenseSelection.type.LICENCE_NFC',
  },
  licenseSelectionTypeLICENCE_NFC_ACCESS_UNIT: {
    defaultMessage: '916012 - 2N Access Unit - NFC license',
    description: 'OneStep - nfc',
    id: 'licenseSelection.type.LICENCE_NFC_ACCESS_UNIT',
  },
  mobileVideoLicenseAnnualSubscriptionApartment: {
    defaultMessage: 'Annual subscription per apartment',
    description: 'mobileVideoLicense - annualSubscription per apartment',
    id: 'mobileVideoLicense.annualSubscription.apartment',
  },
  mobileVideoLicenseAnnualSubscriptionDevices: {
    defaultMessage: 'Annual subscription per device',
    description: 'mobileVideoLicense - annualSubscription per devices',
    id: 'mobileVideoLicense.annualSubscription.devices',
  },
  mobileVideoLicenseMonthlySubscriptionApartment: {
    defaultMessage: 'Monthly subscription per apartment',
    description: 'mobileVideoLicense - monthlySubscription per apartment',
    id: 'mobileVideoLicense.monthlySubscription.apartment',
  },
  mobileVideoLicenseMonthlySubscriptionDevices: {
    defaultMessage: 'Monthly subscription per device',
    description: 'mobileVideoLicense - monthlySubscription per devices',
    id: 'mobileVideoLicense.monthlySubscription.devices',
  },
  mobileVideoLicenseNextPayment: {
    defaultMessage: 'Next scheduled payment:' + ' <strong>{price} ({priceVat} incl. VAT)</strong>',
    description: 'mobileVideoLicense - nextPayment',
    id: 'mobileVideoLicense.nextPayment',
  },
  mobileVideoLicenseNextPaymentLicensePool: {
    defaultMessage: '<strong> + {licencesPool} credits</strong>',
    description: 'mobileVideoLicense - nextPayment licencesPool',
    id: 'mobileVideoLicense.nextPaymentLicensePool',
  },
  mobileVideoLicenseNextScheduledPayment: {
    defaultMessage: 'Next scheduled payment on: <strong>{date}</strong>',
    description: 'mobileVideoLicense - nextScheduledPayment',
    id: 'mobileVideoLicense.nextScheduledPayment',
  },
  mobileVideoLicenseNumberOfLicencedDevicesApartment: {
    defaultMessage: 'Number of licenced apartments: <strong>{number}</strong>',
    description: 'mobileVideoLicense - numberOfLicencedDevices',
    id: 'mobileVideoLicense.numberOfLicencedDevices.apartment',
  },
  mobileVideoLicenseNumberOfLicencedDevicesDevices: {
    defaultMessage: 'Number of licenced devices: <strong>{number}</strong>',
    description: 'mobileVideoLicense - numberOfLicencedDevices',
    id: 'mobileVideoLicense.numberOfLicencedDevices.devices',
  },
  mobileVideoLicenseNumberOfLicencesApartment: {
    defaultMessage: '<strong>{licences}</strong> apartments',
    description: 'mobileVideoLicense - numberOfLicences apartment',
    id: 'mobileVideoLicense.numberOfLicences.apartment',
  },
  mobileVideoLicenseNumberOfLicencesDevices: {
    defaultMessage: '<strong>{licences}</strong> devices',
    description: 'mobileVideoLicense - numberOfLicences devices',
    id: 'mobileVideoLicense.numberOfLicences.devices',
  },
  mobileVideoLicenseNumberOfLicencesTitle: {
    defaultMessage: 'Number of licences',
    description: 'mobileVideoLicense - numberOfLicencesTitle',
    id: 'mobileVideoLicense.numberOfLicencesTitle',
  },
  mobileVideoLicensePaymentPrepared: {
    defaultMessage:
      'I agree that this purchase involves immediate download of digital content;' +
      ' and I lose my right of withdrawal once the purchase is complete. The previous' +
      ' provision does not affect my right to terminate the contract at my discretion.',
    description: 'Payment prepared message',
    id: 'mobileVideoLicense.paymentPrepared',
  },
  mobileVideoLicenseRecurringPaymentDescription: {
    defaultMessage: 'The subscription fee will be charged regularly. ' + 'You can cancel the subscription at any time.',
    description: 'mobileVideoLicense - recurringPaymentDescription',
    id: 'mobileVideoLicense.recurringPaymentDescription',
  },
  mobileVideoLicenseRecurringPaymentTitle: {
    defaultMessage: 'Recurring payment',
    description: 'mobileVideoLicense - recurringPaymentTitle',
    id: 'mobileVideoLicense.recurringPaymentTitle',
  },
  mobileVideoLicenseRegularAnnualPayment: {
    defaultMessage: 'Recurring annual payment: <strong>{price} ({priceVat} incl. VAT)</strong>',
    description: 'mobileVideoLicense - nextScheduledPayment annual',
    id: 'mobileVideoLicense.regularAnnualPayment',
  },
  mobileVideoLicenseRegularMonthlyPayment: {
    defaultMessage: 'Recurring monthly payment: <strong>{price} ({priceVat} incl. VAT)</strong>',
    description: 'mobileVideoLicense - nextScheduledPayment monthly',
    id: 'mobileVideoLicense.regularMonthlyPayment',
  },
  mobileVideoLicenseSubscriptionPricePriceVat: {
    defaultMessage: '({priceVat} incl. VAT)',
    description: 'mobileVideoLicense - subscriptionPrice - priceVat',
    id: 'mobileVideoLicense.subscriptionPrice.priceVat',
  },
  mobileVideoLicenseTitle: {
    defaultMessage: 'Subscription fee ',
    description: 'mobileVideoLicense - title',
    id: 'mobileVideoLicense.title',
  },
  mobileVideoLicenseTitleLink: {
    defaultMessage: '(show pricing)',
    description: 'mobileVideoLicense - titleLink',
    id: 'mobileVideoLicense.titleLink',
  },
  mobileVideoLicenseWarning: {
    defaultMessage: 'There is no device that would need an additional subscription.',
    description: 'mobileVideoLicense - warning',
    id: 'mobileVideoLicense.warning',
  },
  nextStepButton: {
    defaultMessage: 'Next step',
    description: 'Next step button label',
    id: 'serialNumberList.nextStepButton',
  },
  oneStepAddButton: {
    defaultMessage: 'Add Serial number',
    description: 'OneStep - add button',
    id: 'oneStep.addButton',
  },
  oneStepSerialNumberInputInvalid: {
    defaultMessage: 'Invalid Serial number',
    description: 'OneStep - invalid',
    id: 'oneStep.serialNumberInput.invalid',
  },
  oneStepSerialNumberInputLabel: {
    defaultMessage: 'Serial number',
    description: 'OneStep - title',
    id: 'oneStep.serialNumberInput.label',
  },
  oneStepSerialNumberInputRequired: {
    defaultMessage: 'Value is mandatory',
    description: 'OneStep - required',
    id: 'oneStep.serialNumberInput.required',
  },
  oneStepTitle: {
    defaultMessage: 'Fill in the serial number of all 2N devices you want to buy licenses for',
    description: 'OneStep - title',
    id: 'oneStep.title',
  },
  orderSubmitInvoiceButton: {
    defaultMessage: 'Submit order',
    description: 'Payment information next step button - invoice',
    id: 'orderSubmit.invoiceButton',
  },
  orderSubmitPaymentConfirm: {
    defaultMessage:
      'I agree that this purchase involves immediate download of digital ' +
      'content; and I lose my right of withdrawal once the purchase is complete. ' +
      'The previous provision does not affect my right to terminate the contract ' +
      'at my discretion.',
    description: 'Device license payment confirmation',
    id: 'orderSubmit.paymentConfirm',
  },
  orderSubmitWebPayButton: {
    defaultMessage: 'Order with obligation to pay',
    description: 'Payment information next step button - web pay',
    id: 'orderSubmit.webPayButton',
  },
  paymentMethodHeader: {
    defaultMessage: 'Payment method',
    description: 'Payment method header text',
    id: 'paymentMethod.header',
  },
  paymentMethodInvoice: {
    defaultMessage: 'Invoice with standard payment terms',
    description: 'Invoice method label',
    id: 'paymentMethod.invoice',
  },
  paymentMethodWebPay: {
    defaultMessage: 'Credit card',
    description: 'WebPay method label',
    id: 'paymentMethod.webPay',
  },
  paymentStateCustomerCare: {
    defaultMessage: 'Customer care team',
    description: 'Customer care team label',
    id: 'paymentState.customerCare',
  },
  paymentStateFailedCardError: {
    defaultMessage:
      'Your payment didn’t go through due to' + '{br}an expired credit card.' + '{br}Try again with a different card.',
    description: 'Payment failed description - Card error',
    id: 'paymentState.failed.CARD_ERROR',
  },
  paymentStateFailedCardIssuerError: {
    defaultMessage:
      'The payment gateway was unavailable, so your payment' + '{br}didn’t go through.' + '{br}Try again later.',
    description: 'Payment failed description - Card issuer error',
    id: 'paymentState.failed.CARD_ISSUER_ERROR',
  },
  paymentStateFailedCreditLimitExceeded: {
    defaultMessage:
      'Your payment didn’t go through.' + '{br}You’ve exceeded your credit card limit. Please contact {mailToLink}.',
    description: 'Payment failed description - Credit limit exceeded',
    id: 'paymentState.failed.CREDIT_LIMIT_EXCEEDED',
  },
  paymentStateFailedDefault: {
    defaultMessage: 'Your payment didn’t go through.' + '{br}Try again later or contact our {mailToLink}.',
    description: 'Payment failed description - DEFAULT',
    id: 'paymentState.failed.DEFAULT',
  },
  paymentStateFailedGatewayError: {
    defaultMessage: 'An issue with your credit card company prevented your' + '{br}payment from being processed.',
    description: 'Payment failed description - Gateway error',
    id: 'paymentState.failed.GATEWAY_ERROR',
  },
  paymentStateFailedInvoicePaymentError: {
    defaultMessage:
      'Sorry, we couldn’t process your payment.' +
      '{br}Please, check your Mobile Video credit balance. Contact' +
      '{br}our {mailToLink} if the payment hasn’t gone' +
      '{br}through.',
    description: 'Payment failed description - invoice payment error',
    id: 'paymentState.failed.INVOICE_PAYMENT_ERROR',
  },
  paymentStateFailedLabel: {
    defaultMessage: 'Your payment has failed.',
    description: 'Payment failed',
    id: 'paymentState.failed.label',
  },
  paymentStateFailedMY2NError: {
    defaultMessage:
      'Sorry, we were unable to process your payment.' +
      '{br}We’re working on fixing the issue.' +
      '{br}Please, try again later.',
    description: 'Payment failed description - MY2N error',
    id: 'paymentState.failed.MY2N_ERROR',
  },
  paymentStateFailedNotProcessed: {
    defaultMessage: 'Your payment didn’t go through because you canceled' + '{br}the process.',
    description: 'Payment failed description - Not processed',
    id: 'paymentState.failed.NOT_PROCESSED',
  },
  paymentStateFailedSessionExpired: {
    defaultMessage: 'The payment session timed out.' + '{br}Try to make the payment again.',
    description: 'Payment failed description - Session expired',
    id: 'paymentState.failed.SESSION_EXPIRED',
  },
  paymentStateFailedUserError: {
    defaultMessage: 'Your payment didn’t go through because of incorrect' + '{br}credit card details. Try again.',
    description: 'Payment failed description - User error',
    id: 'paymentState.failed.USER_ERROR',
  },
  paymentStateInProgressDescription: {
    defaultMessage: 'Complete the purchase by proceeding to the payments gate in the' + ' new window and return here.',
    description: 'Payment in progress description',
    id: 'paymentState.inProgress.description',
  },
  paymentStateInProgressLabel: {
    defaultMessage: 'We’re processing your payment',
    description: 'We’re processing your payment',
    id: 'paymentState.inProgress.label',
  },
  paymentStatePaidDescription: {
    defaultMessage: 'Please don’t purchase further subscriptions while we’re' + '{br}processing your order.',
    description: 'Payment paid description',
    id: 'paymentState.paid.description',
  },
  paymentStatePaidLabel: {
    defaultMessage: 'Payment successful' + '{br}Wait for your services to be activated',
    description: 'Payment paid',
    id: 'paymentState.paid.label',
  },
  paymentStateSuccessCreditCardLabel: {
    defaultMessage: 'Payment successful!',
    description: 'Payment success - credit card',
    id: 'paymentState.success.creditCard.label',
  },
  paymentStateSuccessDeviceLicenseDescription: {
    defaultMessage: 'Your IP Intercom services have been activated.' + '{br}We have emailed you an invoice.',
    description: 'Payment device license success description',
    id: 'paymentState.successDeviceLicense.description',
  },
  paymentStateSuccessInvoiceLabel: {
    defaultMessage: 'Your purchase was successful.',
    description: 'Payment success - invoice',
    id: 'paymentState.success.invoice.label',
  },
  paymentStateSuccessMobileVideoDescription: {
    defaultMessage: 'Your Mobile Video services have been activated.' + '{br}We have emailed you an invoice.',
    description: 'Payment success mobile video description',
    id: 'paymentState.successMobileVideo.description',
  },
  paymentStateSuccessVouchersDescription: {
    defaultMessage: 'You can find the voucher in <url>Company payments</url> section.',
    description: 'Payment success vouchers description',
    id: 'paymentState.successVouchers.description',
  },
  promoCodesTotalPriceNote: {
    defaultMessage: '(for total {subscriptionsCount} subscriptions)',
    description: 'promoCodes - total price note',
    id: 'promoCodes.totalPriceNote',
  },
  serialNumberListAddAlreadyExist: {
    defaultMessage: 'This serial number was already added to selection.',
    description: 'Add serial number item - already exist error',
    id: 'serialNumberList.add.alreadyExist',
  },
  serialNumberListErrorNotFound: {
    defaultMessage: 'Highlighted serial number was not found in our database.',
    description: 'serialNumberList - error not found',
    id: 'serialNumberList.error.notFound',
  },
  serialNumberListRemoveSuccess: {
    defaultMessage: 'Serial number was removed from selection.',
    description: 'Remove serial number item - success',
    id: 'serialNumberList.remove.success',
  },
  serialNumberListRemoveTooltip: {
    defaultMessage: 'Remove this serial number from selection.',
    description: 'Remove serial number item - tooltip',
    id: 'serialNumberList.remove.tooltip',
  },
  serialNumberListSerialNumberNotFound: {
    defaultMessage: 'Serial number was not found in our database',
    description: 'Serial number card - serial number not found',
    id: 'serialNumberList.serialNumber.notFound',
  },
  serialNumberListSerialNumberPrimaryText: {
    defaultMessage: 'Serial number: {serialNumber}',
    description: 'Serial number card - serial number',
    id: 'serialNumberList.serialNumber.primaryText',
  },
  serialNumberListSerialNumberSecondaryText: {
    defaultMessage: 'Type: {type}',
    description: 'Serial number card - device type',
    id: 'serialNumberList.serialNumber.secondaryText',
  },
  serialNumberListTitle: {
    defaultMessage: 'Your devices',
    description: 'serialNumberList - title',
    id: 'serialNumberList.title',
  },
  subscriptionButtonNext: {
    defaultMessage: 'Next',
    description: 'Next button - title',
    id: 'subscription.button.next',
  },
  subscriptionButtonSecurePayment: {
    defaultMessage: 'Order with obligation to pay',
    description: 'pay button - title',
    id: 'subscription.button.securePayment',
  },
  subscriptionChooseService: {
    defaultMessage: 'Choose the type of service you want to purchase',
    description: 'Subscription - choose service',
    id: 'subscription.chooseService',
  },
  subscriptionContactForBillingInfo: {
    defaultMessage:
      'If you want to buy a subscription, you need complete billing information.' +
      '{br}Contact the {companyName} company administrator and ask them to fill in the' +
      ' billing information.',
    description: 'Subscription - contact for billing info message',
    id: 'subscription.contactForBillingInfo',
  },
  subscriptionDescriptionActivation: {
    defaultMessage: 'Do you have a voucher? {br} Use it for 2N<sup>®</sup> Mobile Video service.',
    description: 'Subscription - description of activation',
    id: 'subscription.descriptionActivation',
  },
  subscriptionDescriptionLicenses: {
    defaultMessage: 'Additional licenses for your {br} intercom devices',
    description: 'Subscription - description of licenses',
    id: 'subscription.descriptionLicenses',
  },
  subscriptionDescriptionPromo: {
    defaultMessage: 'Create codes for your {br} customers',
    description: 'Subscription - description of promo codes',
    id: 'subscription.descriptionPromo',
  },
  subscriptionDescriptionSubscription: {
    defaultMessage: 'Purchase subscription for the {br} 2N<sup>®</sup> Mobile Video service.',
    description: 'Subscription - description of subscription',
    id: 'subscription.descriptionSubscription',
  },
  subscriptionEditBillingInfo: {
    defaultMessage: 'billing information',
    description: 'Subscription - edit billing info button',
    id: 'subscription.editBillingInfo',
  },
  subscriptionInformationIncomplete: {
    defaultMessage: 'Please complete the {link} to enable payments.',
    description: 'Subscription - information incomplete',
    id: 'subscription.informationIncomplete',
  },
  subscriptionIntercomLicensesTitle: {
    defaultMessage: '2N IP Intercom licenses',
    description: 'Intercom licenses - title',
    id: 'subscription.INTERCOM_LICENSES.title',
  },
  subscriptionLicenses: {
    defaultMessage: 'Licenses',
    description: 'Subscription - licenses',
    id: 'subscription.licenses',
  },
  subscriptionMOBILE_VIDEO_CODETitle: {
    defaultMessage: 'Mobile Video Subscription',
    description: 'Mobile Video Subscription - title',
    id: 'subscription.MOBILE_VIDEO_CODE.title',
  },
  subscriptionMOBILE_VIDEO_LICENSETitle: {
    defaultMessage: 'Mobile Video Subscription',
    description: 'Mobile Video Subscription - title',
    id: 'subscription.MOBILE_VIDEO_LICENSE.title',
  },
  subscriptionMobileVideo: {
    defaultMessage: '2N<sup>®</sup> Mobile Video',
    description: 'Subscription - 2N Mobile Video',
    id: 'subscription.mobileVideo',
  },
  subscriptionPromo: {
    defaultMessage: '2N<sup>®</sup> Mobile Video {br} vouchers',
    description: 'Subscription - promo',
    id: 'subscription.promo',
  },
  subscriptionPROMO_CODETitle: {
    defaultMessage: 'Mobile Video Vouchers',
    description: 'Mobile Video Vouchers - title',
    id: 'subscription.PROMO_CODE.title',
  },
  subscriptionStep1PurchaseType: {
    defaultMessage: 'Purchase type',
    description: 'Subscription stepper - step 1: Purchase Type',
    id: 'subscription.step1.PurchaseType',
  },
  subscriptionStep2PurchaseDetails: {
    defaultMessage: 'Purchase details',
    description: 'Subscription stepper - step 2: Purchase Details',
    id: 'subscription.step2.PurchaseDetails',
  },
  subscriptionStep3PaymentInformation: {
    defaultMessage: 'Payment information',
    description: 'Subscription stepper - step 3: Payment information',
    id: 'subscription.step3.PaymentInformation',
  },
  subscriptionStep4Confirmation: {
    defaultMessage: 'Confirmation',
    description: 'Subscription stepper - step 4: Confirmation',
    id: 'subscription.step4.Confirmation',
  },
  subscriptionTitle: {
    defaultMessage: 'Subscriptions, licenses',
    description: 'Subscription - title',
    id: 'subscription.title',
  },
  totalPriceBarAdditionalNote: {
    defaultMessage:
      'The minimum credit card payment is {priceVat}, the difference between' +
      ' actual cost and minimum payment will be added to your credits.',
    description: 'totalPriceBar - additionalNote',
    id: 'totalPriceBar.additionalNote',
  },
  totalPriceBarMonthsNote: {
    defaultMessage: '+ 12 months',
    description: 'totalPriceBar - 12 months note',
    id: 'totalPriceBar.monthsNote',
  },
  totalPriceBarPriceVatInfo: {
    defaultMessage: '({priceVat} incl. VAT)',
    description: 'totalPriceBar - price VAT info',
    id: 'totalPriceBar.priceVatInfo',
  },
  totalPriceBarTotalPriceTitle: {
    defaultMessage: '<strong>Total price</strong>',
    description: 'mobileVideoLicense - totalPriceTitle',
    id: 'totalPriceBar.totalPriceTitle',
  },
  totalPriceBarTotalPriceTitleNote: {
    defaultMessage: '(for the rest of current month {monthsNote})',
    description: 'totalPriceBar - totalPriceTitleЕxplanation',
    id: 'totalPriceBar.totalPriceTitleNote',
  },
  twoStepOrderListAPARTMENT_MOBILE_VIDEO_MONTH: {
    defaultMessage: 'Subscription for the current month {percent} per apartment',
    description: 'Mobile video license order list month apartment product',
    id: 'twoStep.orderList.APARTMENT_MOBILE_VIDEO_MONTH',
  },
  twoStepOrderListAPARTMENT_MOBILE_VIDEO_YEAR: {
    defaultMessage: 'Annual subscription {percent} per apartment',
    description: 'Mobile video license order list month apartment product',
    id: 'twoStep.orderList.APARTMENT_MOBILE_VIDEO_YEAR',
  },
  twoStepOrderListCountApartment: {
    defaultMessage: 'Number of apartments',
    description: 'Count of apartments',
    id: 'twoStep.orderList.count.apartment',
  },
  twoStepOrderListCountDevices: {
    defaultMessage: 'Number of devices',
    description: 'Count of devices',
    id: 'twoStep.orderList.count.devices',
  },
  twoStepOrderListHeader: {
    defaultMessage: 'Your order',
    description: 'Mobile video license order list header',
    id: 'twoStep.orderList.header',
  },
  twoStepOrderListMOBILE_VIDEO_MONTH: {
    defaultMessage: 'Subscription for the current month {percent} per device',
    description: 'Mobile video license order list month device product',
    id: 'twoStep.orderList.MOBILE_VIDEO_MONTH',
  },
  twoStepOrderListMOBILE_VIDEO_YEAR: {
    defaultMessage: 'Annual subscription {percent} per device',
    description: 'Mobile video license order list month device product',
    id: 'twoStep.orderList.MOBILE_VIDEO_YEAR',
  },
  twoStepOrderListMonthPercent: {
    defaultMessage: ' ({percent} of standard monthly period)',
    description: 'Mobile video license order list month percent',
    id: 'twoStep.orderList.monthPercent',
  },
  twoStepOrderListYearPercent: {
    defaultMessage: ' ({percent} of current month + 12 months)',
    description: 'Mobile video license order list month percent',
    id: 'twoStep.orderList.yearPercent',
  },
  twoStepPaymentPrepared: {
    defaultMessage:
      'I agree that this purchase involves immediate download of digital content; and I' +
      ' acknowledge that by this I lose my right of withdrawal once the purchase is complete.',
    description: 'Payment prepared message',
    id: 'twoStep.paymentPrepared',
  },
});
