import Grid from '@mui/material/Grid';
import React from 'react';
import { CURRENCY, MOBILE_VIDEO_STATUS } from '../../../../config/consts';
import { IMVLicenceModel } from '../../../../modules/sites/store';
import { ICodeData } from '../../../../modules/subscription/store';
import CurrentMonthSummary from './CurrentMonthSummary';
import NextPaymentSummary from './NextPaymentSummary';
import SiteStatusSummary from './SiteStatusSummary';

interface ICodeSummary {
  codeData: ICodeData;
  currency: keyof typeof CURRENCY;
  mobileVideoLicense: IMVLicenceModel;
}

const CodeSummary = ({ codeData, currency, mobileVideoLicense }: ICodeSummary) => (
  <Grid container spacing={2}>
    <Grid container direction="column" item spacing={2}>
      <Grid item>
        <CurrentMonthSummary billingType={mobileVideoLicense.billingType} codeData={codeData} />
      </Grid>
      {[
        MOBILE_VIDEO_STATUS.TRIAL_ACTIVE,
        MOBILE_VIDEO_STATUS.TRIAL_EXPIRED,
        MOBILE_VIDEO_STATUS.STANDARD_EXPIRED,
      ].includes(mobileVideoLicense.status) && (
        <Grid item>
          <SiteStatusSummary status={mobileVideoLicense.status} />
        </Grid>
      )}
      {codeData.activation.nextPayment && (
        <Grid item>
          <NextPaymentSummary
            currency={currency}
            expiration={codeData.activation.expiration}
            nextPayment={codeData.activation.nextPayment}
          />
        </Grid>
      )}
    </Grid>
  </Grid>
);

export default CodeSummary;
