import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Modal from '../Modal';
import DeviceInfo from './components/DeviceInfo';
import messages from './messages';

const styles = {
  code: {
    '& img': {
      border: 1,
      borderColor: 'secondary.main',
      borderRadius: '3px',
      height: '110px',
      width: '110px',
    },
    mr: 2,
    width: '110px',
  },
  content: {
    mt: 3,
  },
};

const QrDialog = ({ deviceId, devicePassword, legacyAccessEnabled, onCancel, open, src }) => (
  <Modal
    actions={
      <Button color="primary" onClick={onCancel}>
        <FormattedMessage {...messages.qrDialogButton} />
      </Button>
    }
    fullWidth
    maxWidth="xs"
    name="qr-dialog"
    onClose={onCancel}
    open={open}
    title={
      <div>
        <FormattedMessage {...getTitle(legacyAccessEnabled)} />
      </div>
    }
  >
    <Box>
      <Typography variant="body2">
        <FormattedMessage {...getDescription(legacyAccessEnabled)} />
      </Typography>
      <Grid container sx={styles.content}>
        <Grid item sx={styles.code}>
          <Typography>
            <img alt="" src={src} />
          </Typography>
        </Grid>
        {legacyAccessEnabled && <DeviceInfo deviceId={deviceId} devicePassword={devicePassword} />}
      </Grid>
    </Box>
  </Modal>
);

QrDialog.propTypes = {
  deviceId: PropTypes.string.isRequired,
  devicePassword: PropTypes.string.isRequired,
  legacyAccessEnabled: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  src: PropTypes.string.isRequired,
};

export default QrDialog;

function getTitle(legacyAccessEnabled) {
  return legacyAccessEnabled ? messages.qrDialogBeforeMigrationTitle : messages.qrDialogAfterMigrationTitle;
}

function getDescription(legacyAccessEnabled) {
  return legacyAccessEnabled ? messages.qrDialogBeforeMigrationDescription : messages.qrDialogAfterMigrationDescription;
}
