import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PATHS } from '../config/consts';
import DeviceLicense from '../containers/Subscription/DeviceLicense';
import MobileVideoCode from '../containers/Subscription/MobileVideoCode';
import MobileVideoLicense from '../containers/Subscription/MobileVideoLicense';
import PromoCode from '../containers/Subscription/PromoCode';
import { SubscriptionCards } from '../containers/Subscription/SubscriptionCards';
import { useGetLoadedParams } from '../hooks/paramsHooks';
import { ApplicationContext } from '../modules/commonTypes';
import { getCompanyData } from '../modules/company/selectors';
import { getVatVerification } from '../modules/subscription/actions';
import { getIsVatVerified, getIsVatVerifiedFetching } from '../modules/subscription/selectors';
import { useAppContext } from '../pages/App/AppContextProvider';

export function SubscriptionRoutes(): React.JSX.Element {
  const { feSiteRoles } = useAppContext();
  const { companyId, siteId } = useGetLoadedParams(ApplicationContext.Site);
  const dispatch = useDispatch();
  const company = useSelector(getCompanyData) as { address: string };
  const isFetching = useSelector(getIsVatVerifiedFetching);
  const isVatVerified = useSelector(getIsVatVerified);

  useEffect(() => {
    if (company?.address && (feSiteRoles.isCompanyAdmin || feSiteRoles.isSiteAdminPayment)) {
      dispatch(getVatVerification());
    }
  }, [company?.address, feSiteRoles.isCompanyAdmin, feSiteRoles.isSiteAdminPayment]);

  if (isFetching) {
    return <>{false}</>;
  }

  return (
    <Routes>
      <Route element={<MobileVideoCode />} path={'/activation-code'} />
      {isVatVerified && <Route element={<MobileVideoLicense />} path={'/mobile-video'} />}
      <Route element={<DeviceLicense />} path={'/licenses'} />
      <Route element={<PromoCode />} path={'/vouchers'} />
      <Route element={<SubscriptionCards />} path={'/'} />
      <Route
        path={'/*'}
        element={
          <Navigate
            replace
            to={PATHS.SUBSCRIPTION.replace(':companyId', companyId.toString()).replace(':siteId', siteId.toString())}
          />
        }
      />
    </Routes>
  );
}
