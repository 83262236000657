import CreditCardIcon from '@mui/icons-material/CreditCard';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import RowMenuItem from '../../../../components/DataGrid/RowMenuItem';
import messages from './messages';

const AssignCard = ({ isRfidSet, onClick }) => (
  <RowMenuItem
    disabled={isRfidSet}
    icon={<CreditCardIcon />}
    label={<FormattedMessage {...messages.accessGroupUsersDataGridDataGridRowMenuCard} />}
    onClick={onClick}
  />
);

AssignCard.propTypes = {
  isRfidSet: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default AssignCard;
