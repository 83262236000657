import React, { createRef, forwardRef } from 'react';
import MaskedInput from 'react-text-mask';

const SecurityCodeField = forwardRef(({ ...props }, ref) => {
  const inputRef = createRef(ref);
  return (
    <MaskedInput
      {...props}
      mask={[
        /[A-Z0-9]/i,
        /[A-Z0-9]/i,
        /[A-Z0-9]/i,
        /[A-Z0-9]/i,
        '-',
        /[A-Z0-9]/i,
        /[A-Z0-9]/i,
        /[A-Z0-9]/i,
        /[A-Z0-9]/i,
        '-',
        /[A-Z0-9]/i,
        /[A-Z0-9]/i,
        /[A-Z0-9]/i,
        /[A-Z0-9]/i,
        '-',
        /[A-Z0-9]/i,
        /[A-Z0-9]/i,
        /[A-Z0-9]/i,
        /[A-Z0-9]/i,
      ]}
      placeholderChar="*"
      ref={inputRef}
    />
  );
});

export default SecurityCodeField;
