import MuiAutocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import React from 'react';
import { Controller, FieldPath, FieldValues, useFormContext } from 'react-hook-form';

export interface IAutoCompleteData<T extends FieldValues, TOption>
  extends Omit<AutocompleteProps<TOption, true, true, false>, 'renderInput' | 'onChange' | 'onInputChange'> {
  inputSettings: TextFieldProps;
  name: FieldPath<T>;
  options: TOption[];
  disableHelperText?: boolean;
  onChange?: (currentValue: (TOption | string)[], value: (TOption | string)[]) => TOption[];
  inputAsFilter?: boolean;
}

const aggregatedDataGridStyle = () => ({
  [`& > .MuiInputBase-root:before`]: {
    borderBottom: 'none',
  },
});

export function Autocomplete<T extends FieldValues, TOption>({
  disableHelperText,
  inputAsFilter,
  inputSettings,
  name,
  onChange,
  options,
  ...other
}: IAutoCompleteData<T, TOption>) {
  const { control, getValues, watch } = useFormContext<T>();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => {
        return (
          <MuiAutocomplete
            freeSolo
            options={options}
            {...other}
            {...field}
            onChange={(_e, value) =>
              field.onChange(onChange !== undefined ? onChange(getValues(name), value) : value || '')
            }
            onInputChange={
              inputAsFilter
                ? undefined
                : (_, data) => {
                    field.onChange(data || '');
                  }
            }
            value={watch(name)}
            renderInput={(params) => {
              return (
                <TextField
                  sx={aggregatedDataGridStyle}
                  {...inputSettings}
                  {...params}
                  error={!!fieldState?.error}
                  helperText={
                    disableHelperText ? undefined : fieldState?.error?.message || inputSettings.helperText || ' '
                  }
                  InputLabelProps={{
                    ...params.InputLabelProps,
                    ...inputSettings?.InputLabelProps,
                  }}
                  inputRef={field.ref}
                />
              );
            }}
          />
        );
      }}
    />
  );
}
