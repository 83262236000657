import { IntlFormatters } from 'react-intl';
import messages from './messages';
import { ResetPasswordFormStep } from './types';

export function getResetPasswordPageTitle(
  step: ResetPasswordFormStep,
  formatMessage: IntlFormatters['formatMessage']
): string {
  switch (step) {
    case ResetPasswordFormStep.ORYRecoveryCodeForm:
      return formatMessage(messages.ImsResetPasswordTitleRecoverAccess);
    case ResetPasswordFormStep.ORYNewPasswordForm:
      return formatMessage(messages.ImsNewPasswordTitle);

    default:
      return formatMessage(messages.resetPasswordTitle);
  }
}
