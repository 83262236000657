import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useUrlParams } from '../../../helpers/urlParams';

export function useGetGlobalHooks() {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { companyId, siteId } = useUrlParams();

  return {
    companyId,
    dispatch,
    formatMessage,
    siteId,
  };
}
