import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useGetLoadedParams } from '../../../hooks/paramsHooks';
import { ApplicationContext } from '../../../modules/commonTypes';
import { selectors } from '../../../modules/elevatorIntegration';
import { selectorsTS as userSelectors } from '../../../modules/user';

export function useGetGlobalHooks() {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { companyId, siteId } = useGetLoadedParams(ApplicationContext.Site);
  const elevatorIntegrationData = useSelector(selectors.getUserElevatorIntegrationData);
  const isInvalid = useSelector(selectors.isUserElevatorIntegrationInvalid);
  const userUuid = useSelector(userSelectors.getUserDetailUuid);

  return {
    dispatch,
    elevatorIntegrationData,
    formatMessage,
    isInvalid,
    urlParams: {
      companyId,
      siteId,
    },
    userUuid,
  };
}
