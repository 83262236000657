export const sortByNewModel = (model, onOrderBy, setSortModel) => {
  const sort = model[0]?.sort;
  const field = model[0]?.field;
  if (sort === 'asc') {
    onOrderBy(field);
  } else if (sort === 'desc') {
    onOrderBy(`-${field}`);
  }
  setSortModel(model);
};

export const sortByCurrentModel = (model, onOrderBy, setSortModel) => {
  const sort = model[0]?.sort;
  const field = model[0]?.field;
  let newSort = '';
  if (sort === 'asc') {
    onOrderBy(`-${field}`);
    newSort = 'desc';
  } else if (sort === 'desc') {
    onOrderBy(field);
    newSort = 'asc';
  }
  setSortModel([{ field, sort: newSort }]);
};

export const handleSortModelChange = (onOrderBy, setSortModel, currentModel, newModel) => {
  if (newModel.length === 0) {
    sortByCurrentModel(currentModel, onOrderBy, setSortModel);
  } else {
    sortByNewModel(newModel, onOrderBy, setSortModel);
  }
};

export const defineRowClassName = (gridRowClassNameParams, getCustomRowClassName) => {
  if (getCustomRowClassName) {
    return getCustomRowClassName(gridRowClassNameParams);
  }
  if (gridRowClassNameParams.row.isClickable) {
    return 'clickableRow';
  }
  if (gridRowClassNameParams.row?.isDisabled) {
    return 'disabledRow';
  }
  return '';
};
