import { defineMessages } from 'react-intl';

export default defineMessages({
  elevatorIntegrationCancel: {
    defaultMessage: 'Cancel',
    id: 'elevatorIntegration.cancel',
  },
  elevatorIntegrationMsgError: {
    defaultMessage: 'There was an error, please try again',
    id: 'elevatorIntegration.msgError',
  },
  elevatorIntegrationMsgSucc: {
    defaultMessage: 'The configuration was successfully saved',
    id: 'elevatorIntegration.msgSucc',
  },
  elevatorIntegrationSubmit: {
    defaultMessage: 'Save changes',
    id: 'elevatorIntegration.submit',
  },
});
