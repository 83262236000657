import NewReleasesIcon from '@mui/icons-material/NewReleases';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Alert, Device2NIcon } from '../../../../components';
import ButtonScanQRCode from '../../../../components/ButtonScanQRCode';
import OutlinedTextField from '../../../../components/HookFormFields/TextField';
import { DEVICE_SERIAL_CODE_MASK, DEVICE_TYPE } from '../../../../config/devices';
import { SITE_TYPE } from '../../../../config/sites';
import { BILLING_TYPE } from '../../../../config/subscription';
import { IFloor } from '../../../../modules/apartments/store';
import { TId } from '../../../../modules/commonTypes';
import { selectors as deviceSelectors } from '../../../../modules/devices';
import DeviceDesktopScanQR from '../../../DeviceDesktopScanQR';
import DeviceMobileScanQR from '../../../DeviceMobileScanQR';
import { ApartmentFormField } from '../../components/ApartmentFormField/ApartmentFormField';
import { NewApartmentForm } from '../../components/NewApartmentForm/NewApartmentForm';
import { isAvailableForApartment } from '../../helpers';
import { isFreeLicenceAlert } from '../helpers';
import { IFormData } from '../validationSchema';
import messages from './messages';
import { SecurityCodeControl } from './SecurityCodeControl';
import { SerialNumberControl } from './SerialNumberControl';
import { StoreHashControl } from './StoreHashControl';

export interface IOneStep {
  billingType: string;
  companyId: TId;
  floors: IFloor[];
  freeMVFFEnable?: boolean;
  isMobileView: boolean;
  isShowApartmentField: boolean;
  my2nId: string;
  siteMVStatus: string;
  siteMVType: string;
  siteType: SITE_TYPE;
}

export function OneStep({
  billingType,
  companyId,
  floors,
  freeMVFFEnable,
  isMobileView,
  isShowApartmentField,
  my2nId,
  siteMVStatus,
  siteMVType,
  siteType,
}: IOneStep) {
  const { formatMessage } = useIntl();
  const [showScanCode, setShowScanCode] = useState(isMobileView);
  const deviceTypes = useSelector(deviceSelectors.getCompanyDeviceTypes);
  const availableForApartment = isAvailableForApartment(deviceTypes, DEVICE_TYPE.THIRD_PARTY);
  const { getValues, setValue, watch } = useFormContext<IFormData>();
  const existApartment = watch('hasForm');
  const serialNumber = watch('serialNumber');
  const deviceType = watch('deviceType');
  const isIDT = watch('isIDT');
  const freeLicenceCount = watch('freeLicenceCount');
  const isDeviceIntercomOrAccessUnit = getValues('isDeviceIntercomOrAccessUnit');
  const isSiteMdu = siteType === SITE_TYPE.MDU;

  const onCloseScanCode = () => setShowScanCode(false);

  const onToggleScanCode = () => setShowScanCode((prev) => !prev);

  const onScanCodeSuccess = (options: { serialNumber: string; securityCode: string }) => {
    if (options) {
      setValue('serialNumber', options.serialNumber);
      setValue('securityCode', options.securityCode);
      setShowScanCode(false);
    }
  };

  useEffect(() => {
    if (isMobileView !== showScanCode) {
      setShowScanCode(isMobileView);
    }
  }, [isMobileView]);

  if (isMobileView && showScanCode) {
    return <DeviceMobileScanQR onClose={onCloseScanCode} onSuccess={onScanCodeSuccess} />;
  }

  return (
    <>
      <Typography>
        <FormattedMessage {...messages.x2nDeviceOneStepSerialNumberParagraph} />
      </Typography>
      {!showScanCode ? (
        <Grid container spacing={1} sx={{ mt: 2 }}>
          <Grid item xs>
            <SerialNumberControl companyId={companyId} />
            <SecurityCodeControl
              disabled={isIDT}
              helperText={formatMessage(
                isIDT
                  ? messages.x2nDeviceOneStepSecurityCodeThisDeviceNotRequiredHint
                  : messages.x2nDeviceOneStepSecurityCodeSomeDevicesNotRequiredHint
              )}
            />
          </Grid>
          <Grid item sx={{ display: { sm: 'block', xs: 'none' } }} xs={4}>
            <Box display="flex" justifyContent="flex-end">
              <Device2NIcon
                deviceType={deviceType}
                noFound={
                  serialNumber.length === 12 &&
                  new RegExp(DEVICE_SERIAL_CODE_MASK).test(serialNumber) &&
                  deviceType === ''
                }
              />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <DeviceDesktopScanQR onSuccess={onScanCodeSuccess} />
      )}
      <Box sx={{ mb: 2 }}>
        <ButtonScanQRCode onClick={onToggleScanCode} open={showScanCode} />
      </Box>
      {isFreeLicenceAlert(freeLicenceCount, siteMVType, siteMVStatus, siteType, freeMVFFEnable) && (
        <Alert severity="success" icon={<NewReleasesIcon />} variant="outlined">
          <FormattedMessage
            {...(billingType === BILLING_TYPE.PER_DEVICE
              ? messages.X2nAddDeviceModalDeviceModelFreeLicenseFirstStep
              : messages.X2nAddDeviceModalApartmentModelFreeLicenseFirstStep)}
            values={{ count: freeLicenceCount }}
          />
        </Alert>
      )}
      <StoreHashControl />
      <OutlinedTextField
        helperText={<FormattedMessage {...messages.x2nDeviceOneStepNameHelperText} />}
        label={<FormattedMessage {...messages.x2nDeviceOneStepNameLabel} />}
        name="name"
        required
        fullWidth
        hasTopAndBottomMargin
      />
      {isShowApartmentField && (
        <>
          <ApartmentFormField
            disabled={!(availableForApartment || isDeviceIntercomOrAccessUnit || false)}
            required={!isDeviceIntercomOrAccessUnit && isSiteMdu}
          />
          {existApartment && (
            <Box my={2}>
              <NewApartmentForm floors={floors} isSiteMdu={isSiteMdu} />
            </Box>
          )}
        </>
      )}
      {isIDT !== true && (
        <Alert severity="info">
          <FormattedMessage
            {...messages.x2nDeviceOneStepSecurityCodeInfoBox}
            values={{
              my2nId,
              url: (chunks) => (
                <Link
                  href="https://2nwiki.2n.cz/pages/viewpage.action?pageId=60261026"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {chunks}
                </Link>
              ),
            }}
          />
        </Alert>
      )}
    </>
  );
}
