import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert } from '../../../components';
import { DEVICE_TYPE, SERVICE } from '../../../config/devices';
import { helpers } from '../../../modules/devices';
import messages from './messages';

const SettingsWarning = ({ deviceType, services }) => {
  const getMobileVideoWarning = () => {
    if (deviceType === DEVICE_TYPE.ID_COMPACT || deviceType === DEVICE_TYPE.ID_VIEW) {
      return <FormattedMessage {...messages.settingsWarningMobileVideoCompact} />;
    }
    if (deviceType === DEVICE_TYPE.ID_TALK || deviceType === DEVICE_TYPE.CLIP) {
      return <FormattedMessage {...messages.settingsWarningMobileVideoTalk} />;
    }
    return null;
  };

  const getLocalCallsWarning = () => {
    if (deviceType === DEVICE_TYPE.ID_COMPACT || deviceType === DEVICE_TYPE.ID_VIEW) {
      return <FormattedMessage {...messages.settingsWarningLocalCallsCompact} />;
    }
    if (deviceType === DEVICE_TYPE.ID_TALK || deviceType === DEVICE_TYPE.CLIP) {
      return <FormattedMessage {...messages.settingsWarningLocalCallsTalk} />;
    }

    return null;
  };

  return !helpers.isActiveService({ services }, SERVICE.ACS) &&
    (helpers.isActiveService({ services }, SERVICE.MOBILE_VIDEO) ||
      helpers.isActiveService({ services }, SERVICE.LOCAL_CALLS)) ? (
    <Alert severity="warning">
      {helpers.isActiveService({ services }, SERVICE.MOBILE_VIDEO) && getMobileVideoWarning()}
      {helpers.isActiveService({ services }, SERVICE.LOCAL_CALLS) && getLocalCallsWarning()}
    </Alert>
  ) : null;
};

SettingsWarning.propTypes = {
  deviceType: PropTypes.string.isRequired,
  services: PropTypes.shape({}).isRequired,
};

export default SettingsWarning;
