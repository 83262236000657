import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import RemoveBatchButton from '../../Common/RemoveBatchButton';
import RemoveBatchModal from '../../Common/RemoveBatchModal';
import { formikValuesPropTypes, helperFunctionsPropTypes } from '../../types';
import SubscriptionTypeSelect from './Fields/SubscriptionTypeSelect';

const RowOne = ({ batchIndex, formikValues, helperFunctions, name }) => {
  const [removeDialog, setRemoveDialog] = useState({ index: 0, open: false });
  return (
    <Grid alignItems="center" container item justifyContent="space-between" xs={12}>
      <Grid item lg={5} sm={9} xl={5} xs>
        <SubscriptionTypeSelect name={name} subscriptionType={formikValues.array[batchIndex].subscriptionType} />
      </Grid>
      <Grid item sx={{ mr: -1 }}>
        <RemoveBatchButton
          disabled={!(formikValues.array.length > 1)}
          onClick={() => setRemoveDialog({ index: batchIndex, open: true })}
        />
      </Grid>
      <RemoveBatchModal
        onClose={() => setRemoveDialog({ index: 0, open: false })}
        onRemoveBatch={() => helperFunctions.remove(removeDialog.index)}
        open={removeDialog.open}
      />
    </Grid>
  );
};

RowOne.propTypes = {
  batchIndex: PropTypes.number.isRequired,
  formikValues: formikValuesPropTypes.isRequired,
  helperFunctions: helperFunctionsPropTypes.isRequired,
  name: PropTypes.string.isRequired,
};

export default RowOne;
