import { defineMessages } from 'react-intl';

export default defineMessages({
  siteSnackbarAddedSuccessfully: {
    defaultMessage: 'Site added successfully.',
    description: 'Site snackbar - Site added successfully',
    id: 'siteSnackbarAddedSuccessfully',
  },
  siteSnackbarDeletedSuccessfully: {
    defaultMessage: 'Site deleted successfully.',
    description: 'Site snackbar - Site deleted successfully',
    id: 'siteSnackbarDeletedSuccessfully',
  },
  siteSnackbarEditedSuccessfully: {
    defaultMessage: 'Site edited successfully.',
    description: 'Site snackbar - Site edited successfully',
    id: 'siteSnackbarEditedSuccessfully',
  },
});
