import { all } from 'redux-saga/effects';
import accessLogSagas from './accessLog/saga';
import accessSettings from './accessSettings/saga';
import apartmentAdminSagas from './apartmentAdmin/saga';
import apartmentsSagas from './apartments/saga';
import apartmentsSagasTS from './apartments/sagaTS';
import authSagas from './auth/saga';
import billingSagas from './billing/saga';
import commonSagas from './common/saga';
import companySagas from './company/saga';
import devicesSagas from './devices/saga';
import saveDevicesSagas from './devices/saveDeviceSaga';
import elevatorIntegrationSagas from './elevatorIntegration/saga';
import localizationSagas from './localization/saga';
import notificationSagas from './notifications/saga';
import paymentsSagas from './payments/saga';
import phonebooksSagas from './phonebooks/saga';
import siteSagas from './sites/saga';
import subscriptionSagas from './subscription/saga';
import userSagas from './user/saga';
import userSagasTS from './user/sagaTS';
import websocketSagas from './websocket/saga';

export default function* sagas() {
  yield all([
    accessLogSagas(),
    accessSettings(),
    apartmentsSagas(),
    apartmentsSagasTS(),
    apartmentAdminSagas(),
    authSagas(),
    billingSagas(),
    commonSagas(),
    companySagas(),
    devicesSagas(),
    elevatorIntegrationSagas(),
    localizationSagas(),
    notificationSagas(),
    paymentsSagas(),
    phonebooksSagas(),
    saveDevicesSagas(),
    siteSagas(),
    subscriptionSagas(),
    userSagas(),
    userSagasTS(),
    websocketSagas(),
  ]);
}
