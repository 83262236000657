import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PATHS } from '../../../config/consts';
import RowMenuItem from '../../DataGrid/RowMenuItem';
import messages from './messages';

const EditApartment = ({ apartmentId, companyId, siteId }) => (
  <RowMenuItem
    component={Link}
    icon={<EditIcon />}
    label={<FormattedMessage {...messages.apartmentRowMenuEdit} />}
    to={PATHS.APARTMENT_DETAIL.replace(':companyId', companyId)
      .replace(':siteId', siteId)
      .replace(':apartmentId', apartmentId)}
  />
);

EditApartment.propTypes = {
  apartmentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  siteId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default EditApartment;
