import { Box } from '@mui/material';
import React from 'react';

export interface IMobileVideoDevicesLicenseIndicatorProps {
  apartmentDeviceLimit: number;
  usedDevices: number;
}

const styles = {
  active: {
    backgroundColor: 'success.main'
  },
  dash: {
    '&:first-of-type': {
      ml: 0,
    },
    '&:last-of-type': {
      mr: 0,
    },
    backgroundColor: 'grey.400',
    height: '16px',
    margin: '0 1px',
    width: '48px',
  },
  line: {
    display: 'flex',
    ml: 1,
  },
};

export function MobileVideoDevicesLicenseIndicator({ apartmentDeviceLimit, usedDevices }: IMobileVideoDevicesLicenseIndicatorProps) {
 return (
   <>
    <Box sx={styles.line}>
      {Array.from({ length: apartmentDeviceLimit }, (_, i) =>
        i < usedDevices ? (
          // eslint-disable-next-line react/jsx-key
          <Box sx={{ ...styles.dash, ...styles.active }} />
        ) : (
          <Box key={i} sx={styles.dash} />
        )
      )}
    </Box>
   </>
 )
}
