import { DEVICE_TYPE } from '../../../../config/devices';

export const hasSerialNumberField = (deviceType: string) =>
  [
    DEVICE_TYPE.ACCESS_UNIT,
    DEVICE_TYPE.ACCESS_UNIT_M,
    DEVICE_TYPE.CHAMELEON,
    DEVICE_TYPE.CLIP,
    DEVICE_TYPE.ID_COMPACT,
    DEVICE_TYPE.ID_TALK,
    DEVICE_TYPE.ID_VIEW,
    DEVICE_TYPE.IP_INTERCOM,
    DEVICE_TYPE.IP_INTERCOM_V2,
    DEVICE_TYPE.IP_ONE_INTERCOM,
    DEVICE_TYPE.IP_STYLE,
  ].includes(deviceType);

export const hasFirmwareField = (deviceType: string) =>
  [
    DEVICE_TYPE.ACCESS_UNIT,
    DEVICE_TYPE.ACCESS_UNIT_M,
    DEVICE_TYPE.CHAMELEON,
    DEVICE_TYPE.CLIP,
    DEVICE_TYPE.ID_COMPACT,
    DEVICE_TYPE.ID_TALK,
    DEVICE_TYPE.ID_VIEW,
    DEVICE_TYPE.IP_INTERCOM,
    DEVICE_TYPE.IP_INTERCOM_V2,
    DEVICE_TYPE.IP_ONE_INTERCOM,
    DEVICE_TYPE.IP_STYLE,
  ].includes(deviceType);

export const hasSynchronizationField = (deviceType: string) =>
  [
    DEVICE_TYPE.ACCESS_UNIT,
    DEVICE_TYPE.ACCESS_UNIT_M,
    DEVICE_TYPE.CHAMELEON,
    DEVICE_TYPE.CLIP,
    DEVICE_TYPE.ID_COMPACT,
    DEVICE_TYPE.ID_TALK,
    DEVICE_TYPE.ID_VIEW,
    DEVICE_TYPE.IP_INTERCOM,
    DEVICE_TYPE.IP_INTERCOM_V2,
    DEVICE_TYPE.IP_ONE_INTERCOM,
    DEVICE_TYPE.IP_STYLE,
  ].includes(deviceType);
