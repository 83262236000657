import { defineMessages } from 'react-intl';

export default defineMessages({
  aboutImporvementButton: {
    defaultMessage: 'Submit new idea',
    description: 'About page - imporvement button',
    id: 'about.imporvement.button',
  },
  aboutImporvementInfo: {
    defaultMessage: "'We’d like to know how we can advance My2N services.' + ' Tell us what you think.'",
    description: 'About page - imporvement info',
    id: 'about.imporvement.info',
  },
  aboutImporvementTitle: {
    defaultMessage: 'Suggest an improvement',
    description: 'About page - imporvement title',
    id: 'about.imporvement.title',
  },
  aboutInfo: {
    defaultMessage: 'Keep up with My2N developments. There’s always something new.',
    description: 'About page - info',
    id: 'about.info',
  },
  aboutProductBoardLink: {
    defaultMessage: 'Check out our Product Board',
    description: 'About page - product board link',
    id: 'about.productBoardLink',
  },
  aboutTitle: {
    defaultMessage: 'News, releases',
    description: 'About page - title',
    id: 'about.title',
  },
});
