import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PATHS } from '../../config/consts';
import Alert from '../Alert';
import ButtonLink from '../ButtonLink';
import messages from './messages';

const SecurityCodeAlert = ({ companyId, detail, devices, onButtonClick, siteId }) => {
  const buttonTypography = (
    <>
      {devices.length === 1 && <FormattedMessage {...messages.securityCodeAlertButtonOneDevice} />}
      {devices.length > 1 && <FormattedMessage {...messages.securityCodeAlertButtonMoreDevices} />}
    </>
  );

  return (
    <Alert
      action={
        onButtonClick ? (
          <Button onClick={() => onButtonClick(devices[0])} size="small">
            {buttonTypography}
          </Button>
        ) : (
          <ButtonLink
            size="small"
            to={PATHS.NOT_VERIFIED_DEVICES.replace(':companyId', companyId).replace(':siteId', siteId)}
          >
            {buttonTypography}
          </ButtonLink>
        )
      }
      severity="error"
    >
      {devices.length === 1 && !detail && (
        <FormattedMessage
          {...messages.securityCodeAlertListText}
          values={{
            deviceName: devices[0].name,
            strong: (chunks) => <strong>{chunks}</strong>,
          }}
        />
      )}
      {devices.length === 1 && detail && <FormattedMessage {...messages.securityCodeAlertDetailText} />}
      {devices.length > 1 && (
        <FormattedMessage
          {...messages.securityCodeAlertMoreDevicesText}
          values={{
            count: devices.length,
            devices: devices.map((item) => item.name).join(', '),
          }}
        />
      )}
    </Alert>
  );
};

SecurityCodeAlert.propTypes = {
  companyId: PropTypes.number.isRequired,
  detail: PropTypes.bool,
  devices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      serialNumber: PropTypes.string,
    })
  ).isRequired,
  onButtonClick: PropTypes.func,
  siteId: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['verify']).isRequired,
};

SecurityCodeAlert.defaultProps = {
  detail: false,
  onButtonClick: null,
};

export default SecurityCodeAlert;
