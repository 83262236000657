import { IReduxStoreBase, IReduxTableStoreBase } from '../../commonTypes';
import { initialState } from '../reducer';
import { IDevice } from './deviceStore';

export interface IDevicesStore {
  addDeviceModal: IAddDeviceModalStore;
  callSettingsDevices: ICallSettingsDevices;
  detailDevice: IReduxStoreBase<IDevice>;
  hasDefaultPasswordDevices: boolean;
  userProfile: IUserProfile;
  list: IReduxTableStoreBase<IDevice>;
}

export interface ICallSettingsDevices extends IReduxTableStoreBase<IDevice> {
  chipOptions: IReduxTableStoreBase<any>;
}

export interface IAddDeviceModalStore extends IReduxStoreBase<IDevice> {
  show: boolean;
}

export interface IUserProfile extends IReduxTableStoreBase<IDevice> {
  isFirstTimeLoaded: boolean;
}

export function getInitialDevicesStore(): IDevicesStore {
  return {
    ...initialState,
    addDeviceModal: {
      data: undefined,
      didInvalid: false,
      error: undefined,
      isEditing: false,
      isFetching: false,
      show: false,
    },
    callSettingsDevices: {
      ...initialState.callSettingsDevices,
      chipOptions: {
        data: undefined,
        didInvalid: false,
        filter: undefined,
        isFetching: false,
        order: undefined,
        page: 0,
        resultsFiltered: 0,
        resultsTotal: 0,
        rowsPerPage: 0,
      },
      data: undefined,
      didInvalid: false,
      filter: {
        types: '',
      },
      isFetching: false,
      order: 'name',
      page: 1,
      resultsFiltered: 0,
      resultsTotal: 0,
      rowsPerPage: 10,
    },
    detailDevice: {
      ...initialState.detailDevice,
      data: undefined,
    },
    hasDefaultPasswordDevices: false,
  };
}
