import { yupResolver } from '@hookform/resolvers/yup';
import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Link } from '../../components';
import { PATHS } from '../../config/consts';
import { ICompanyRoles } from '../../hooks/rolesHooks';
import actions from '../../modules/auth';
import {
  getAccessTokenSelector,
  getResendEmail,
  getThrottlingRetryAfterDate,
  isLoggedInSelector,
} from '../../modules/auth/selectors';
import { isSiteVersionWarningDisplayed } from '../../modules/common/selectors';
import { TId } from '../../modules/commonTypes';
import {
  getCurrentUserSelector,
  isMarketer as isMarketerSelector,
  isSuperadmin as isSuperadminSelector,
} from '../../modules/user/selectors';
import { OryErrorBar } from '../../ory/components/OryErrorBar';
import { useOryUserLogged } from '../../ory/hooks/oryUser/useOryUserLogged';
import { useOryUserLogin } from '../../ory/hooks/oryUserLogin/useOryUserLogin';
import { getBasicEmailValidation } from '../../validations/user/userCommonValidations';
import { PublicFormWrapper } from '../_components/PublicFormWrapper';
import { LoginAttemptsAlert } from './LoginAttemptsAlert';
import { AuthLoginForm } from './LoginPageForm';
import messages from './messages';
import { getRedirectPathForCurrentUser } from './redirectPath';
import { useSetAccessToken } from './useSetAccessToken';

const styles = {
  alert: {
    position: 'absolute',
    top: (theme: Theme) => ({
      md: theme.appNotificationHeightLargeDevice,
      xs: theme.appNotificationHeightSmallDevice,
    }),
    width: '100%',
  },
  sub: {
    '& a': {
      color: 'common.white',
      textDecoration: 'underline',
      textTransform: 'lowercase',
    },
    color: 'common.white',
    mb: 9,
    mt: 10,
    textAlign: 'center',
  },
};

interface ILoginForm {
  email: string;
  password: string;
}

export function LoginPage() {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const accessToken = useSelector(getAccessTokenSelector);

  useSetAccessToken();
  const isSuperadmin = useSelector(isSuperadminSelector);
  const isMarketer = useSelector(isMarketerSelector);
  const currentUser = useSelector(getCurrentUserSelector) as {
    id: TId;
    lastVisitedSiteId?: number | null;
    lastVisitedCompanyId?: number | null;
    companies: ICompanyRoles[];
  };
  const resendEmail = useSelector(getResendEmail);
  const loggedIn = useSelector(isLoggedInSelector);
  const retryAfterDate = useSelector(getThrottlingRetryAfterDate);
  const isAlertShown = useSelector(isSiteVersionWarningDisplayed);

  const { isOryUserLoggedIn, oryLoginStatus } = useOryUserLogged({ accessToken, currentUser });
  const { getFormAlertMessage, isOryApiFetching, loginOryUser } = useOryUserLogin();

  useEffect(() => {
    if ((currentUser.id !== undefined && loggedIn) || isOryUserLoggedIn) {
      if (isSuperadmin) {
        navigate(PATHS.SUPERADMIN_DASHBOARD);
      }

      if (isMarketer) {
        navigate(PATHS.MARKETER_ACTIVATION_CODES);
      }

      if (currentUser.id !== undefined) {
        const redirectPath = getRedirectPathForCurrentUser({
          companies: currentUser.companies,
          lastVisitedCompanyId: currentUser.lastVisitedCompanyId,
          lastVisitedSiteId: currentUser.lastVisitedSiteId,
        });
        navigate(redirectPath);
      }
    }
  }, [currentUser.id, oryLoginStatus]);

  useEffect(() => {
    if ((resendEmail?.length ?? 0) > 0) {
      navigate(PATHS.RESEND);
    }
  }, [resendEmail]);

  const formData = useForm<ILoginForm>({
    defaultValues: { email: '', password: '' },
    mode: 'onChange',
    resolver: yupResolver(
      Yup.object().shape({
        email: getBasicEmailValidation(
          formatMessage(messages.authLoginEmailInvalid),
          formatMessage(messages.authLoginEmailRequired)
        ),
        password: Yup.string().required(formatMessage(messages.authLoginPasswordRequired)),
      })
    ),
  });

  const registerLink = (
    <Box sx={styles.sub}>
      <Typography variant="body1">
        <FormattedMessage {...messages.authLoginUnregisteredText} />
        <Link to={PATHS.REGISTER}>
          <FormattedMessage {...messages.authLoginUnregisteredAction} />
        </Link>
      </Typography>
    </Box>
  );

  return (
    <Box sx={{ ...(isAlertShown && styles.alert) }}>
      <PublicFormWrapper title={formatMessage(messages.authLoginTitle)} subContent={registerLink}>
        <Box>
          <OryErrorBar error={getFormAlertMessage()} />
          {retryAfterDate && (
            <Box pb={3}>
              <LoginAttemptsAlert throttlingRetryAfter={retryAfterDate} />
            </Box>
          )}
        </Box>
        <FormProvider {...formData}>
          <form
            onSubmit={formData.handleSubmit((values: ILoginForm) =>
              //TODO HIPCS-22198: move this function to separated hook to be callable from account activation page
              dispatch(
                actions.loginRequest({
                  email: values.email,
                  onUserIsInOry: async () => await loginOryUser(values),
                  password: values.password,
                })
              )
            )}
          >
            <AuthLoginForm isSubmitDisabled={!formData.formState.isValid || isOryApiFetching} />
          </form>
        </FormProvider>
      </PublicFormWrapper>
    </Box>
  );
}
