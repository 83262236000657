import CheckIcon from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { ActionModal } from '../../components/_DesignSystemWrappers/Modal';
import OutlinedTextField from '../../components/HookFormFields/TextField';
import { useGetGlobalHooks } from './hooks/getGlobalHooks';
import messages from './messages';

export interface IChangeSiteNameFormValues {
  name: string;
}

function EditSiteModal() {
  const { dispatch, formatMessage, formMethods, isLoading, onClose, open, siteActions } = useGetGlobalHooks();

  const handleEdit = (values: IChangeSiteNameFormValues) => {
    dispatch(siteActions.editSiteFormRequest(values));
    formMethods.reset();
  };

  const handleClose = () => {
    onClose();
    formMethods.reset();
  };

  return (
    <ActionModal
      icon={<CheckIcon />}
      isPrimaryButtonDisabled={!formMethods.formState.isValid || isLoading}
      muiDialogProps={{ maxWidth: 'xs' }}
      onClose={handleClose}
      open={open}
      primaryButtonAction={formMethods.handleSubmit(handleEdit)}
      primaryButtonText={formatMessage(messages.editSiteModalSubmit)}
      secondaryButtonText={formatMessage(messages.editSiteModalCancel)}
      title={formatMessage(messages.editSiteModalTitle)}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleEdit)}>
          <Typography sx={{ mb: 3 }}>
            <FormattedMessage {...messages.editSiteModalDescription} />
          </Typography>
          <OutlinedTextField
            helperText={formatMessage(messages.editSiteModalHelperTextLabel)}
            id="text-field"
            label={formatMessage(messages.editSiteModalLabel)}
            margin="dense"
            name="name"
            required
            fullWidth
            type="text"
          />
        </form>
      </FormProvider>
    </ActionModal>
  );
}

export default EditSiteModal;
