import { FileDownload } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../QRPanel/messages';

export interface DownloadButtonProps {
  fileData: Blob;
  disabled: boolean;
}

export function DownloadButton({ disabled, fileData }: DownloadButtonProps) {
  function download() {
    const a = document.createElement('a');
    document.body.appendChild(a);
    const url = URL.createObjectURL(fileData);
    a.href = url;
    a.download = 'QR.png';
    a.click();
    URL.revokeObjectURL(url);
  }

  return (
    <Button color="secondary" size="small" startIcon={<FileDownload />} onClick={download} disabled={disabled}>
      <FormattedMessage {...messages.AAmodalPinformDownload} />
    </Button>
  );
}
