import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { alpha } from '@mui/material/styles';
import { Form } from 'formik';
import { snakeCase } from 'lodash';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import isScrollable from '../helpers/isScrollable';
import DialogHeader from './DialogHeader';

const styles = {
  actions: {
    '& button': {
      ml: '10px',
    },
    pb: 3,
    pt: 2,
    px: 3,
  },
  actionsBorderTop: {
    '&.MuiDialogActions-root': {
      borderTop: '1px solid',
      borderTopColor: (theme) => alpha(theme.palette.common.black, 0.12),
    },
  },
  content: {
    '&.MuiDialogContent-root': {
      pb: 3,
      pt: 2.25,
      px: 3,
    },
  },
};

const Modal = ({
  actions,
  canClose,
  children,
  headerVariant,
  isForm,
  name,
  noCloseReasons,
  onClose,
  open,
  title,
  ...rest
}) => {
  const contentRef = useRef(null);
  const dialogContentRef = useRef(null);

  const scrollable = isScrollable(
    dialogContentRef?.current?.getBoundingClientRect()?.height,
    contentRef?.current?.getBoundingClientRect()?.height
  );

  const getHtmlId = (section) => `${snakeCase(name)}-${snakeCase(section)}`;

  const onReallyClose = () => {
    if (canClose !== false) {
      onClose();
    }
  };

  return (
    <Dialog
      aria-describedby="alert-dialog-slide-description"
      aria-labelledby="alert-dialog-slide-title"
      fullWidth
      keepMounted
      maxWidth="sm"
      onClose={(event, reason) => {
        if (!noCloseReasons.includes(reason)) {
          onReallyClose();
        }
      }}
      open={open}
      PaperProps={{ component: isForm ? Form : 'div' }}
      scroll="paper"
      sx={styles.dialog}
      {...rest}
    >
      {title && (
        <DialogHeader
          canClose={canClose}
          headerVariant={headerVariant}
          id={getHtmlId('title')}
          onCancel={onClose}
          title={title}
        />
      )}
      <DialogContent id={getHtmlId('content')} ref={dialogContentRef} sx={styles.content}>
        <div ref={contentRef}>{children}</div>
      </DialogContent>
      {actions && (
        <DialogActions
          id={getHtmlId('actions')}
          sx={{
            ...styles.actions,
            ...(scrollable && styles.actionsBorderTop),
          }}
        >
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
};

Modal.propTypes = {
  actions: PropTypes.node,
  canClose: PropTypes.bool,
  children: PropTypes.node,
  headerVariant: PropTypes.oneOf(['primary', 'danger']),
  isForm: PropTypes.bool,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md']),
  name: PropTypes.string.isRequired,
  noCloseReasons: PropTypes.arrayOf(PropTypes.oneOf(['escapeKeyDown', 'backdropClick'])),
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

Modal.defaultProps = {
  actions: null,
  canClose: true,
  children: null,
  headerVariant: null,
  isForm: false,
  maxWidth: 'sm',
  noCloseReasons: [],
  open: false,
  title: null,
};

export default Modal;
