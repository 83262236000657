import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import ErrorAlertCreditLimit from '../../Common/ErrorAlertCreditLimit';
import OrderPayment from '../../Common/OrderPayment';
import OrderSubmit from '../../Common/OrderSubmit';
import TotalPriceBar from '../../Common/TotalPriceBar';
import { INVOICE } from '../../constants';
import { isCreditLimitReached } from '../../helpers';
import { purchasePaymentResultPropTypes } from '../../unitProps';
import OrderList from './OrderList';

const TwoStep = ({
  company,
  deviceLicenseOrders,
  deviceLicensePurchaseOrder,
  onCreateLicenseInvoicePayment,
  onDeleteOrderLicense,
  onEditBilling,
  onSetPayments,
  onStepChange,
  purchasePaymentResult,
  snLicenseData,
  snLicenseIsLoading,
}) => {
  const [isSubmitButtonLoading, setIsSubmitButtonLoading] = useState(false);

  useEffect(() => {
    if (!snLicenseIsLoading && deviceLicenseOrders.length === 0) {
      onStepChange(1);
    }
  }, [deviceLicenseOrders]);

  useEffect(() => {
    if (purchasePaymentResult !== null && deviceLicensePurchaseOrder.paymentMethod === INVOICE) {
      setIsSubmitButtonLoading(false);
      onPaymentGateway();
    }
  }, [purchasePaymentResult, deviceLicensePurchaseOrder.paymentMethod]);

  const handleOrderNumberChange = (orderNumber) =>
    onSetPayments({
      customerOrderNumber: orderNumber,
      paymentMethod: deviceLicensePurchaseOrder.paymentMethod,
    });

  const onPaymentGateway = () => {
    onStepChange((prevStep) => prevStep + 1);
  };

  const onInvoicePayment = () => {
    onCreateLicenseInvoicePayment();
    setIsSubmitButtonLoading(true);
  };

  const limitReached = useMemo(
    () =>
      isCreditLimitReached(
        deviceLicensePurchaseOrder.paymentMethod,
        deviceLicensePurchaseOrder.priceVat,
        snLicenseData.creditLimit
      ),
    [deviceLicensePurchaseOrder.paymentMethod, deviceLicensePurchaseOrder.priceVat, snLicenseData.creditLimit]
  );

  return (
    <Grid container direction="column">
      {limitReached && <ErrorAlertCreditLimit />}
      <OrderPayment
        company={company}
        currentMethod={deviceLicensePurchaseOrder.paymentMethod}
        invoiceDisabled={!snLicenseData.canBeInvoiced}
        onEditBilling={onEditBilling}
        onOrderNumberChange={handleOrderNumberChange}
        onSetMethod={({ target }) => onSetPayments({ paymentMethod: target.value })}
      />
      <Grid item xs={12}>
        <OrderList
          chargeVat={deviceLicensePurchaseOrder.chargeVat}
          currency={deviceLicensePurchaseOrder.currency}
          deviceLicenseOrders={deviceLicenseOrders}
          onDeleteOrderLicense={onDeleteOrderLicense}
          onStepChange={onStepChange}
          snLicenseData={snLicenseData}
        />
      </Grid>
      <Grid item xs={12}>
        <TotalPriceBar
          currency={deviceLicensePurchaseOrder.currency}
          price={deviceLicensePurchaseOrder.price}
          priceVat={deviceLicensePurchaseOrder.priceVat}
        />
      </Grid>
      <Grid item xs={12}>
        <OrderSubmit
          companyId={company.id}
          isCreditLimitReached={limitReached}
          isLoading={snLicenseIsLoading || isSubmitButtonLoading}
          onInvoicePayment={onInvoicePayment}
          onPaymentGateway={onPaymentGateway}
          paymentLocation={deviceLicensePurchaseOrder.location}
          paymentMethod={deviceLicensePurchaseOrder.paymentMethod}
        />
      </Grid>
    </Grid>
  );
};

TwoStep.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  deviceLicenseOrders: PropTypes.arrayOf(PropTypes.object).isRequired,
  deviceLicensePurchaseOrder: PropTypes.shape({
    chargeVat: PropTypes.bool,
    currency: PropTypes.string,
    customerOrderNumber: PropTypes.string,
    location: PropTypes.string,
    paymentMethod: PropTypes.string,
    price: PropTypes.number,
    priceVat: PropTypes.number,
  }).isRequired,
  onCreateLicenseInvoicePayment: PropTypes.func.isRequired,
  onDeleteOrderLicense: PropTypes.func.isRequired,
  onEditBilling: PropTypes.func.isRequired,
  onSetPayments: PropTypes.func.isRequired,
  onStepChange: PropTypes.func.isRequired,
  purchasePaymentResult: purchasePaymentResultPropTypes,
  snLicenseData: PropTypes.shape({
    canBeInvoiced: PropTypes.bool,
    creditLimit: PropTypes.number,
  }).isRequired,
  snLicenseIsLoading: PropTypes.bool.isRequired,
};

TwoStep.defaultProps = {
  purchasePaymentResult: null,
};

export default TwoStep;
