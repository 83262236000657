import ElevatorIcon from '@mui/icons-material/Elevator';
import React, { useCallback, useEffect } from 'react';
import actions from '../../modules/elevatorIntegration';
import ElevatorIntegrationSwitchSettings from '../ElevatorIntegrationSwitchSettings';
import RowSection from '../RowSection';
import { useGetGlobalHooks } from './hooks/useGetGlobalHooks';
import messages from './messages';

function UserElevatorIntegrationSettings() {
  const {
    dispatch,
    elevatorIntegrationData,
    formatMessage,
    isInvalid,
    urlParams: { companyId, siteId },
    userUuid,
  } = useGetGlobalHooks();

  useEffect(() => {
    if (isInvalid) {
      dispatch(actions.getUserElevatorIntegration(companyId, siteId, userUuid));
    }
  }, [isInvalid]);

  useEffect(() => {
    if (userUuid) {
      dispatch(actions.getUserElevatorIntegration(companyId, siteId, userUuid));
    }
  }, [userUuid]);

  const setElevatorIntegration = useCallback((landingCall, destinationCall) => {
    dispatch(
      actions.setUserElevatorIntegration(companyId, siteId, userUuid, {
        destinationCallEnabled: destinationCall,
        landingCallEnabled: landingCall,
      })
    );
  }, []);

  return (
    <RowSection icon={<ElevatorIcon />} label={formatMessage(messages.userElevatorIntegrationSettingsLabel)} hasDivider>
      <ElevatorIntegrationSwitchSettings
        currentSettings={elevatorIntegrationData}
        onElevatorIntegrationSet={setElevatorIntegration}
      />
    </RowSection>
  );
}

export default UserElevatorIntegrationSettings;
