import { defineMessages } from 'react-intl';

export default defineMessages({
  securityCodeAlertButtonMoreDevices: {
    defaultMessage: 'Show',
    description: 'Devices verify alert more devices button',
    id: 'securityCodeAlert.button.moreDevices',
  },
  securityCodeAlertButtonOneDevice: {
    defaultMessage: 'Verify',
    description: 'Devices verify alert one device button',
    id: 'securityCodeAlert.button.oneDevice',
  },
  securityCodeAlertDetailText: {
    defaultMessage: 'This device is not verified. Please, verify it again.',
    description: 'Devices verify alert text',
    id: 'securityCodeAlert.detail.text',
  },
  securityCodeAlertListText: {
    defaultMessage:
      'The Security Code verification for device <strong>{deviceName}</strong>' + ' has failed. Please, verify again.',
    description: 'Devices verify alert text',
    id: 'securityCodeAlert.list.text',
  },
  securityCodeAlertMoreDevicesText: {
    defaultMessage:
      'The Security Code verification for' +
      ' {count, plural, one {# device} other {# devices}} ({devices}) has failed.',
    description: 'Devices verify alert more devices text',
    id: 'securityCodeAlert.moreDevices.text',
  },
});
