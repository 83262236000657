import { encode } from '.';
import { AxiosResponse } from 'axios';
import { IDevice } from '../modules/devices/store/deviceStore';
import { getSiteData, ISiteGenericRequestData } from './siteRequest';

export async function getDeviceDetail(
  siteData: ISiteGenericRequestData,
  deviceId: number
): Promise<AxiosResponse<IDevice>> {
  return getSiteData<IDevice>(siteData, `devices/${encode(deviceId.toString())}`);
}
