export const styles = {
  leftSpace: {
    marginLeft: 0.5,
  },
  number: {
    fontWeight: 'bold',
  },
  space: {
    marginLeft: 0.5,
    marginRight: 0.5,
  },
  text: {
    marginTop: '3px',
  },
  textColor: {
    color: '#00000089',
  },
};
