import { defineMessages } from 'react-intl';

export default defineMessages({
  AAApartmentUserListTitle: {
    defaultMessage: 'Apartment admin',
    description: 'Apartment Users Data Grid - apartment admin column',
    id: 'AA.apartmentUserList.title',
  },
  AAuserListApartmentUsersCreateGuest: {
    defaultMessage: 'Create visitor',
    id: 'AA.userList.createGuest',
  },
  AAuserListApartmentUsersCreateGuestXs: {
    defaultMessage: 'Visitor',
    id: 'AA.userList.createGuest.xs',
  },
  apartmentDetailButtonCancel: {
    defaultMessage: 'Cancel',
    description: 'Apartment detail - cancel button',
    id: 'apartmentDetail.button.cancel',
  },
  apartmentDevicesActiveServices: {
    defaultMessage: 'Active services',
    description: 'Apartment devices - active services',
    id: 'apartmentDevicesActiveServices',
  },
  apartmentDevicesDeviceName: {
    defaultMessage: 'Device name',
    description: 'Apartment devices - device name',
    id: 'apartmentDevicesDeviceName',
  },
  apartmentDevicesDeviceStatus: {
    defaultMessage: 'Device status',
    description: 'Apartment devices - device status',
    id: 'apartmentDevicesDeviceStatus',
  },
  apartmentDevicesRowMenuDeleteDevice: {
    defaultMessage: 'Delete device',
    description: 'Apartment devices - delete device',
    id: 'apartmentDevicesRowMenuDeleteDevice',
  },
  apartmentDevicesRowMenuEditDevice: {
    defaultMessage: 'Edit device',
    description: 'Apartment devices - edit device',
    id: 'apartmentDevicesRowMenuEditDevice',
  },
  apartmentHeaderFloor: {
    defaultMessage: 'Floor {floorName}',
    description: 'Apartment header - floor',
    id: 'apartmentHeaderFloor',
  },
  apartmentTabPanelAccess: {
    defaultMessage: 'Access',
    description: 'Apartment tab panel - access tab',
    id: 'apartmentTabPanelAccess',
  },
  apartmentTabPanelDetail: {
    defaultMessage: 'Apartment detail',
    description: 'Apartment tab panel - detail tab',
    id: 'apartmentTabPanelDetail',
  },
  apartmentTabPanelDetailXs: {
    defaultMessage: 'Detail',
    description: 'Apartment tab panel - detail tab for mobile devices',
    id: 'apartmentTabPanelDetail.xs',
  },
  apartmentTabPanelDevices: {
    defaultMessage: 'Devices',
    description: 'Apartment tab panel - devices tab',
    id: 'apartmentTabPanelDevices',
  },
  apartmentTabPanelUsers: {
    defaultMessage: 'Users',
    description: 'Apartment tab panel - users tab',
    id: 'apartmentTabPanelUsers',
  },
  apartmentUsersControlPanelAddUsers: {
    defaultMessage: 'Add users',
    description: 'Apartment users control panel - add users',
    id: 'apartmentUsersControlPanelAddUsers',
  },
  apartmentUsersControlPanelAddUsersXs: {
    defaultMessage: 'Users',
    description: 'Apartment users control panel - add users',
    id: 'apartmentUsersControlPanelAddUsers.xs',
  },
  apartmentUsersControlPanelCreateUser: {
    defaultMessage: 'Create user',
    description: 'Apartment users control panel - create user',
    id: 'apartmentUsersControlPanelCreateUser',
  },
  apartmentUsersCreateMultipleUsers: {
    defaultMessage: 'Create multiple users',
    description: 'Apartment users control panel - create multiple users',
    id: 'apartmentUsersCreateMultipleUsers',
  },
  apartmentUsersCreateMultipleUsersXs: {
    defaultMessage: 'Create users',
    description: 'Apartment users control panel - create multiple users mobile',
    id: 'apartmentUsersCreateMultipleUsers.xs',
  },
  apartmentUsersDataGridGridColumnsAccess: {
    defaultMessage: 'Access',
    description: 'Apartment Users Data Grid - access column',
    id: 'apartmentUsersDataGridGridColumnsAccess',
  },
  apartmentUsersDataGridGridColumnsApartment: {
    defaultMessage: 'Apartment',
    description: 'Apartment Users Data Grid - apartment column',
    id: 'apartmentUsersDataGridGridColumnsApartment',
  },
  apartmentUsersDataGridGridColumnsEmail: {
    defaultMessage: 'E-mail',
    description: 'Apartment Users Data Grid - email column',
    id: 'apartmentUsersDataGridGridColumnsEmail',
  },
  apartmentUsersDataGridGridColumnsName: {
    defaultMessage: 'Name',
    description: 'Apartment Users Data Grid - name column',
    id: 'apartmentUsersDataGridGridColumnsName',
  },
  apartmentUsersDataGridGridColumnsRole: {
    defaultMessage: 'User Role',
    description: 'Apartment Users Data Grid - role column',
    id: 'apartmentUsersDataGridGridColumnsRole',
  },
  apartmentUsersDataGridRoleCompanyAdmin: {
    defaultMessage: 'Company admin',
    description: 'Apartment Users Data Grid - company admin role label',
    id: 'apartmentUsersDataGridRoleCompanyAdmin',
  },
  apartmentUsersDataGridRoleLoginLess: {
    defaultMessage: 'User without login',
    description: 'Apartment Users Data Grid - login less role label',
    id: 'apartmentUsersDataGridRoleLoginLess',
  },
  apartmentUsersDataGridRoleSiteAdmin: {
    defaultMessage: 'Site admin',
    description: 'Apartment Users Data Grid - site admin role label',
    id: 'apartmentUsersDataGridRoleSiteAdmin',
  },
  apartmentUsersDataGridRoleSiteAdminPayment: {
    defaultMessage: 'Site admin (payments permission)',
    description: 'Apartment Users Data Grid - site admin payment role label',
    id: 'apartmentUsersDataGridRoleSiteAdminPayment',
  },
  apartmentUsersDataGridRoleUser: {
    defaultMessage: 'User',
    description: 'Apartment Users Data Grid - user role label',
    id: 'apartmentUsersDataGridRoleUser',
  },
  deleteApartmentDelete: {
    defaultMessage: 'Delete apartment',
    description: 'Delete apartment - delete apartment',
    id: 'deleteApartmentDelete',
  },
});
