import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Currency, CustomDate } from '../../../../components';
import { CURRENCY } from '../../../../config/consts';
import messages from './messages';

const NextPaymentSummary = ({ currency, expiration, nextPayment }) => (
  <Typography>
    <FormattedMessage
      {...messages.nextPaymentSummaryNextPayment}
      values={{
        expiration: <CustomDate dateValue={expiration} />,
        nextPayment: <Currency currency={currency} price={nextPayment} />,
      }}
    />
  </Typography>
);

NextPaymentSummary.propTypes = {
  currency: PropTypes.oneOf(Object.keys(CURRENCY)).isRequired,
  expiration: PropTypes.string.isRequired,
  nextPayment: PropTypes.number.isRequired,
};

export default NextPaymentSummary;
