import { IntlFormatters, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { useUrlParams } from '../helpers/urlParams';

export function useGetGlobalInfo(): {
  apartmentId: number | undefined;
  dispatch: Dispatch;
  formatMessage: IntlFormatters['formatMessage'];
} {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { apartmentId } = useUrlParams();

  return {
    apartmentId,
    dispatch,
    formatMessage,
  };
}
