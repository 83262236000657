import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import appleStoreIcon from '../../../../assets/apple-store-icon.svg';
import googlePlayIcon from '../../../../assets/google-play-icon.svg';
import qrAndroidSvg from '../../../../assets/qr-android.svg';
import qrIosSvg from '../../../../assets/qr-ios.svg';
import { QRCode } from '../../../../components';
import { MOBILE_APP_LINKS } from '../../../../config/consts';
import messages from './messages';
import QRCodeItem from './QRCodeItem';

const styles = {
  flex: (theme) => ({
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'start',
    },
  }),
  label: {
    color: '#556D83',
    fontSize: 'small',
    paddingTop: 2,
  },
  paragraph: {
    px: 2,
    py: 0,
  },
  title: {
    color: 'primary.main',
    fontWeight: 'bold',
  },
  userQr: {
    marginTop: 2,
  },
};

const TwoStep = ({ deviceId, devicePassword, isOwner, userId, userQr, users }) => {
  const { APP_STORE_LINK, GOOGLE_PLAY_LINK } = MOBILE_APP_LINKS;
  let fullname = '';
  if (!isOwner) {
    const user = users.find((item) => item?.id === userId);
    fullname = `${user?.firstName || ''} ${user?.lastName || ''}`.trim();
  }

  return (
    <Box>
      <Typography paragraph variant="body2">
        <FormattedMessage {...messages.twoStepDescription1Description} />
      </Typography>
      <Typography paragraph variant="body2">
        {isOwner ? (
          <FormattedMessage {...messages.twoStepDescription1Owner} />
        ) : (
          <FormattedMessage {...messages.twoStepDescription1User} values={{ fullname }} />
        )}
      </Typography>
      <Box sx={styles.flex}>
        <QRCodeItem qrCode={<QRCode alt="Android download app" isInstallApp src={qrAndroidSvg} />}>
          <Typography sx={styles.title} variant="subtitle1">
            <FormattedMessage {...messages.twoStepAndroidLabel} />
          </Typography>
          <Link
            href={GOOGLE_PLAY_LINK}
            rel="noopener noreferrer"
            sx={{ '& img': { height: '32px' } }}
            target="_blank"
            title="Get it on Google Play"
          >
            <img alt="Get it on Google Play" src={googlePlayIcon} />
          </Link>
        </QRCodeItem>
        <QRCodeItem qrCode={<QRCode alt="iOS download app" isInstallApp src={qrIosSvg} />}>
          <Typography sx={styles.title} variant="subtitle1">
            <FormattedMessage {...messages.twoStepIosLabel} />
          </Typography>
          <Link
            href={APP_STORE_LINK}
            rel="noopener noreferrer"
            sx={{ '& img': { height: '32px' } }}
            target="_blank"
            title="Get it on Apple store"
          >
            <img alt="Get it on Apple store" src={appleStoreIcon} />
          </Link>
        </QRCodeItem>
      </Box>
      {userQr && (
        <>
          <Typography variant="body2">
            <FormattedMessage {...messages.twoStepUserQrDescription} />
          </Typography>
          <Box sx={styles.userQr}>
            <Box sx={styles.flex}>
              <QRCodeItem qrCode={<QRCode alt="OR" isInstallApp src={userQr} />}>
                <Box>
                  <Box sx={styles.label}>
                    <FormattedMessage {...messages.twoStepUserQrDeviceId} />
                  </Box>
                  <Box id="device-id">{deviceId}</Box>
                </Box>
                <Box>
                  <Box sx={styles.label}>
                    <FormattedMessage {...messages.twoStepUserQrDevicePassword} />
                  </Box>
                  <Box id="device-password">{devicePassword}</Box>
                </Box>
              </QRCodeItem>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

TwoStep.propTypes = {
  deviceId: PropTypes.string,
  devicePassword: PropTypes.string,
  isOwner: PropTypes.bool,
  userId: PropTypes.number.isRequired,
  userQr: PropTypes.string,
  users: PropTypes.arrayOf(PropTypes.object),
};

TwoStep.defaultProps = {
  deviceId: null,
  devicePassword: null,
  isOwner: false,
  userQr: '',
  users: [],
};

export default TwoStep;
