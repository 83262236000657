export const styles = {
  drawerFooter: {
    marginTop: 'auto',
    mb: (theme) => `${theme.navBarHeight}px`,
  },
  innerContent: {
    position: 'relative',
    width: '100%',
  },
  parentContent: {
    left: 0,
    position: 'fixed',
    top: 0,
    width: '100%',
    zIndex: 'appBar',
  },
  root: {
    '& .MuiDrawer-paper': {
      top: (theme) => theme.navBarHeight,
      transition: 'width .4s ease-in-out',
    },
  },
  toolbarServiceAlert: (serviceAlertHeight) => ({
    height: (theme) => `${serviceAlertHeight + theme.navBarHeight}px`,
  }),
  withVersionWarning: {
    '& .MuiDrawer-paper': {
      height: (theme) => ({
        md: `calc(100vh - ${theme.appNotificationHeightLargeDevice}px)`,
        xs: `calc(100vh - ${theme.appNotificationHeightSmallDevice}px)`,
      }),
      top: (theme) => ({
        md: theme.navBarHeight + theme.appNotificationHeightLargeDevice,
        xs: theme.navBarHeight + theme.appNotificationHeightSmallDevice,
      }),
    },
  },
};
