import { useLocation, useNavigate } from 'react-router-dom';

export interface INavigateWithStateParams<T extends object> {
  navigateTo: string;
  state: T;
}

export interface IUseLocationState<T extends object> {
  getLocationState: () => T | undefined;
  navigateWithState: (params: INavigateWithStateParams<T>) => void;
}

export function useLocationState<T extends object>(): IUseLocationState<T> {
  const { state } = useLocation();
  const navigate = useNavigate();

  const getState = () => {
    return (state as T) || undefined;
  };
  const navigateWithState = (params: INavigateWithStateParams<T>) => {
    return navigate(params.navigateTo, { state: params.state });
  };

  return {
    getLocationState: getState,
    navigateWithState,
  };
}
