import { defineMessages } from 'react-intl';

export default defineMessages({
  AAmodalDeleteGuestPinText: {
    id: 'AAmodal.deleteGuestPIN.text',
  },
  revokeUserPinModalBottomDescription: {
    defaultMessage:
      'If the user has other access methods at their disposal, they may use' + ' these (e.g., an RFID card).',
    description: 'Revoke user pin - bottom description',
    id: 'revokeUserPinModal.bottomDescription',
  },
  revokeUserPinModalCancel: {
    defaultMessage: 'Cancel',
    description: 'Revoke user pin - cancel',
    id: 'revokeUserPinModal.cancel',
  },
  revokeUserPinModalDescription: {
    defaultMessage:
      'When you delete the PIN code, the user won’t be able to use any door' +
      ' devices once they synchronize with My2N. This usually takes several seconds.',
    description: 'Revoke user pin - description',
    id: 'revokeUserPinModal.description',
  },
  revokeUserPinModalSubmit: {
    defaultMessage: 'Delete PIN',
    description: 'Revoke user pin - submit',
    id: 'revokeUserPinModal.submit',
  },
  revokeUserPinModalTitle: {
    defaultMessage: 'Delete PIN',
    description: 'Revoke user pin - delete pin',
    id: 'revokeUserPinModal.title',
  },
  revokeUserQrModalSubmit: {
    defaultMessage: 'Delete code',
    description: 'Revoke user qr - submit',
    id: 'revokeUserQrModal.submit',
  },
  revokeUserQrModalText: {
    defaultMessage:
      'When you delete the access code, its user won’t be able to use it for access.{br}{br}' +
      'The access code is also deleted for other apartments the user may be member of.',
    description: 'Delete guest qr - text',
    id: 'revokeUserQrModal.text',
  },
  revokeUserQrModalTitle: {
    defaultMessage: 'Delete access code',
    description: 'Revoke user qr - delete qr',
    id: 'revokeUserQrModal.title',
  },
});
