import CreditCardIcon from '@mui/icons-material/CreditCard';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PATHS } from '../../../config/consts';
import RowMenuItem from '../../DataGrid/RowMenuItem';
import messages from '../messages';

const PurchaseSubscription = ({ deviceSite }) => (
  <>
    <RowMenuItem
      colorIcon="primary.main"
      colorText="primary.main"
      component={Link}
      icon={<CreditCardIcon />}
      label={<FormattedMessage {...messages.deviceDataGridActionDeviceRowMenuPurchase} />}
      to={PATHS.SUBSCRIPTION_MOBILE_VIDEO.replace(':companyId', deviceSite.company.id).replace(
        ':siteId',
        deviceSite.id
      )}
    />
    <Divider />
  </>
);

PurchaseSubscription.propTypes = {
  deviceSite: PropTypes.shape({
    company: PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
};

export default PurchaseSubscription;
