import { AxiosError } from 'axios';
import { IntlFormatters } from 'react-intl';
import { Dispatch } from 'redux';
import { OryApiRequestCancelTokenId } from '../../../config/const';
import { oryApi } from '../../../config/oryFrontendApi';
import { processOryApiError } from '../../../errors/errorParser';
import { IOryGeneralError, OryCancelableResponse, OryContext, OryResponse } from '../../../types';
import { getCSRFToken } from '../../../utils/CSRFToken';
import { hasResponseOKStatus } from '../../../utils/responseStatus';

export async function loginToOry(
  params: { email: string; password: string; sessionRefresh?: boolean; context?: OryContext },
  fetchingState: React.Dispatch<React.SetStateAction<boolean>>,
  dispatch: Dispatch,
  formatMessage: IntlFormatters['formatMessage']
): Promise<OryResponse> {
  fetchingState(true);
  try {
    const loginFlowInit = await oryApi.createBrowserLoginFlow({ refresh: params.sessionRefresh });

    const csrfToken = getCSRFToken(loginFlowInit.data);

    const loginFlow = await oryApi.updateLoginFlow({
      flow: loginFlowInit.data.id,
      updateLoginFlowBody: {
        csrf_token: csrfToken,
        identifier: params.email,
        method: 'password',
        password: params.password,
      },
    });

    fetchingState(false);

    if (hasResponseOKStatus(loginFlow)) {
      return true;
    }

    return false;
  } catch (e) {
    fetchingState(false);
    return processOryApiError({
      axiosErrorResponse: e as AxiosError<{ error: IOryGeneralError }>,
      context: params.context || OryContext.Login,
      dispatch,
      formatMessage,
    });
  }
}

export async function getCurrentUserOrySession(
  fetchingState: React.Dispatch<React.SetStateAction<boolean>>,
  dispatch: Dispatch,
  formatMessage: IntlFormatters['formatMessage'],
  params?: { cancelToken?: any }
): Promise<OryCancelableResponse> {
  fetchingState(true);
  try {
    const session = await oryApi.toSession(undefined, { cancelToken: params?.cancelToken });

    fetchingState(false);

    if (hasResponseOKStatus(session)) {
      return true;
    }

    return false;
  } catch (e: any) {
    if (e && e.message && e.message === OryApiRequestCancelTokenId) {
      return undefined;
    }
    fetchingState(false);
    return processOryApiError({
      axiosErrorResponse: e as AxiosError<{ error: IOryGeneralError }>,
      dispatch,
      formatMessage,
      hideNotificationErrors: true,
    });
  }
}

export async function logoutFromOry(
  fetchingState: React.Dispatch<React.SetStateAction<boolean>>,
  dispatch: Dispatch,
  formatMessage: IntlFormatters['formatMessage'],
  params?: { cancelToken?: any }
): Promise<OryCancelableResponse> {
  fetchingState(true);
  try {
    const logoutFlowInit = await oryApi.createBrowserLogoutFlow(undefined, { cancelToken: params?.cancelToken });
    const logoutFlow = await oryApi.updateLogoutFlow(
      { token: logoutFlowInit.data.logout_token },
      { cancelToken: params?.cancelToken }
    );

    fetchingState(false);

    if (hasResponseOKStatus(logoutFlow)) {
      return true;
    }

    return false;
  } catch (e: any) {
    if (e && e.message && e.message === OryApiRequestCancelTokenId) {
      return undefined;
    }
    fetchingState(false);
    return processOryApiError({
      axiosErrorResponse: e as AxiosError<{ error: IOryGeneralError }>,
      dispatch,
      formatMessage,
      hideNotificationErrors: true,
    });
  }
}
