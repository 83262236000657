import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  content: {
    maxWidth: 1050,
    py: 4,
  },
  items: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  title: {
    fontSize: 48,
    m: 0,
    mb: 2,
  },
};

const GTCWrapper = ({ children, flex, title }) => (
  <Grid container>
    <Grid item xs>
      <Container maxWidth="lg" sx={styles.content}>
        <Box component="h1" sx={styles.title}>
          {title}
        </Box>
        <Box sx={{ ...(flex && styles.items) }}>{children}</Box>
      </Container>
    </Grid>
  </Grid>
);

GTCWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  flex: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

GTCWrapper.defaultProps = {
  flex: true,
};

export default GTCWrapper;
