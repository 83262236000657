import { defineMessages } from 'react-intl';

export default defineMessages({
  apartmentCreateModalCancel: {
    defaultMessage: 'Cancel',
    description: 'Apartment create modal - cancel button',
    id: 'apartmentCreateModal.cancel',
  },
  apartmentCreateModalCreate: {
    defaultMessage: 'Create apartment',
    description: 'Apartment create modal - create button',
    id: 'apartmentCreateModal.create',
  },
  apartmentCreateModalTitle: {
    defaultMessage: 'Create new apartment',
    description: 'Apartment create modal - title',
    id: 'apartmentCreateModal.title',
  },
});
