import { useTheme } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useGetActualCompanyId } from '../../../../hooks/companyHooks';
import { useModal } from '../../../../hooks/useModal';
import actions, { selectors } from '../../../../modules/common';
import { clearCompany } from '../../../../modules/company/actions';
import { setRedirectData } from '../../../../modules/infrastructure/actions';
import { clearSiteData } from '../../../../modules/sites/actions';
import { redirectUser } from '../../../../modules/user/helpersTS';
import CreateCompanyDialog from '../../../CreateCompanyModal';
import CompanyButton from './CompanyButton';
import CompanySwitcher from './CompanySwitcher';

const CompanyMenu = ({ active, data, didInvalid, isFetching, isSideBar, onLoad }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const companyId = useGetActualCompanyId();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const location = useLocation();

  const { Modal: CreateCompanyModal, onOpen: onOpenCreateCompanyModal } = useModal({
    Modal: CreateCompanyDialog,
  });

  const addCompany = () => {
    setOpen(false);
    onOpenCreateCompanyModal();
  };

  const selectCompany = (id) => {
    setOpen(false);

    const redirectPath = redirectUser({ data, id, location });
    if (redirectPath !== undefined) {
      dispatch(clearSiteData());
      dispatch(clearCompany());
      dispatch(setRedirectData(redirectPath));
    }
  };

  useEffect(() => {
    if (!isFetching && didInvalid && companyId) {
      dispatch(onLoad());
    }
  }, [companyId, didInvalid]);

  return (
    <div id="company-menu">
      <CompanyButton
        active={active}
        anchorRef={anchorRef}
        companyName={data.find((item) => item.id === companyId)?.name || ''}
        onClickButton={() => setOpen((prevState) => !prevState)}
        open={open}
      />
      <Popper
        anchorEl={anchorRef.current}
        open={open}
        placement="bottom-start"
        style={{ zIndex: theme.zIndex.drawer }}
        transition
      >
        {({ placement, TransitionProps }) => (
          <Fade
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper elevation={24} id="company-menu-list-grow" sx={{ ml: 1, pt: 1 }}>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <div>
                  <CompanySwitcher
                    currentId={companyId}
                    data={data}
                    isFetching={isFetching}
                    isSideBar={isSideBar}
                    onAdd={addCompany}
                    onSelect={selectCompany}
                  />
                </div>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
      <CreateCompanyModal />
    </div>
  );
};

CompanyMenu.propTypes = {
  active: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      roles: PropTypes.arrayOf(PropTypes.string),
      sites: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          name: PropTypes.string,
          roles: PropTypes.arrayOf(PropTypes.string),
        })
      ),
    })
  ).isRequired,
  didInvalid: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isSideBar: PropTypes.bool,
  onLoad: PropTypes.func.isRequired,
};

CompanyMenu.defaultProps = {
  isSideBar: false,
};

const mapStateToProps = (state) => ({
  data: selectors.getDataCompanySwitcher(state),
  didInvalid: selectors.didInvalidCompanySwitcher(state),
  isFetching: selectors.isFetchingCompanySwitcher(state),
});

const mapDispatchToProps = {
  onLoad: actions.loadDataCompanySwitcher,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyMenu);
