import RenewIcon from '@mui/icons-material/SettingsBackupRestore';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { DEVICE_TYPE, SERVICE } from '../../../config/devices';
import { IDevice } from '../../../modules/devices/store/deviceStore';
import { isFirmwareStatusUnsupportedOrDeprecated } from '../helpers';
import messages from './messages';
import RenewServiceDialog from './RenewServiceDialog';

export interface IMobileVideoRenewSettingsProps {
  device: IDevice;
  onRenewConfiguration: () => void;
}

export function MobileVideoRenewSettings({ device, onRenewConfiguration }: IMobileVideoRenewSettingsProps) {
  const [isOpen, setIsOpen] = useState(false);
  const handleConfirm = () => {
    onRenewConfiguration();
    setIsOpen(false);
  };

  const acsInactiveOrUnsupported = useMemo(
    () =>
      !device?.services?.[SERVICE.ACS]?.active ||
      !device?.services?.[SERVICE.ACS]?.lastSeen ||
      (device?.services?.[SERVICE.ACS]?.firmwareStatus &&
        isFirmwareStatusUnsupportedOrDeprecated(device?.services?.[SERVICE.ACS]?.firmwareStatus)),
    [
      device?.services?.[SERVICE.ACS]?.active,
      device?.services?.[SERVICE.ACS]?.lastSeen,
      device?.services?.[SERVICE.ACS]?.firmwareStatus,
    ]
  );

  return [
    DEVICE_TYPE.ID_TALK,
    DEVICE_TYPE.CLIP,
    DEVICE_TYPE.IP_INTERCOM,
    DEVICE_TYPE.IP_INTERCOM_V2,
    DEVICE_TYPE.IP_ONE_INTERCOM,
    DEVICE_TYPE.IP_STYLE,
    DEVICE_TYPE.ID_COMPACT,
    DEVICE_TYPE.ID_VIEW,
  ].includes(device.type) ? (
    <Grid alignItems="flex-start" container direction="row" spacing={2}>
      <Grid item>
        <Button
          color="inherit"
          disabled={!!acsInactiveOrUnsupported}
          onClick={() => setIsOpen(true)}
          startIcon={<RenewIcon color="primary" />}
          variant="contained"
        >
          <Box color={!acsInactiveOrUnsupported ? 'primary.main' : 'text.disabled'}>
            <FormattedMessage {...messages.mobileVideoRenewSettingsTitle} />
          </Box>
        </Button>
        <RenewServiceDialog handleCancel={() => setIsOpen(false)} handleConfirm={handleConfirm} open={isOpen} />
      </Grid>
      <Grid item>
        <Typography variant="body2">
          <FormattedMessage {...messages.mobileVideoRenewSettingsInfo} />
        </Typography>
      </Grid>
    </Grid>
  ) : (
    <>{false}</>
  );
}
