import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import CellBox from './CellBox';
import messages from './messages';

function ApartmentCellValidator({ otherCellFilled, value }) {
  function getNoValue() {
    if (otherCellFilled) {
      return (
        <CellBox color={'error.main'}>
          <FormattedMessage {...messages.siteUsersImportCheckStepDataGridCellEmpty} />
        </CellBox>
      );
    }

    return <CellBox color={'text.disabled'}>{'–'}</CellBox>;
  }

  return value ? <CellBox>{value}</CellBox> : getNoValue();
}

ApartmentCellValidator.propTypes = {
  otherCellFilled: PropTypes.bool.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default memo(ApartmentCellValidator);
