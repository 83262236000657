import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IMPORT_USERS_FAILED_ROW_RESULT } from '../../../config/consts';
import messages from './messages';

const FailedResultStatus = ({ result }) => {
  if (
    result.status in IMPORT_USERS_FAILED_ROW_RESULT &&
    result.status !== IMPORT_USERS_FAILED_ROW_RESULT.INTERNAL_ERROR
  ) {
    return <FormattedMessage {...importResultMap[result.status]} />;
  }
  if (result.detail) {
    return result.detail;
  }
  return <FormattedMessage {...importResultMap[IMPORT_USERS_FAILED_ROW_RESULT.INTERNAL_ERROR]} />;
};

FailedResultStatus.propTypes = {
  result: PropTypes.shape({
    detail: PropTypes.string,
    status: PropTypes.oneOf(Object.values(IMPORT_USERS_FAILED_ROW_RESULT)),
  }).isRequired,
};

export default FailedResultStatus;

const importResultMap = {
  [IMPORT_USERS_FAILED_ROW_RESULT.ABORTED]: messages.siteUsersImportResultStepFailedStatusAborted,
  [IMPORT_USERS_FAILED_ROW_RESULT.EMPTY]: messages.siteUsersImportResultStepFailedStatusEmpty,
  [IMPORT_USERS_FAILED_ROW_RESULT.INTERNAL_ERROR]: messages.siteUsersImportResultStepFailedStatusInternalError,
  [IMPORT_USERS_FAILED_ROW_RESULT.OK]: messages.siteUsersImportResultStepFailedStatusOk,
  [IMPORT_USERS_FAILED_ROW_RESULT.UNKNOWN]: messages.siteUsersImportResultStepFailedStatusUnknown,
  [IMPORT_USERS_FAILED_ROW_RESULT.WRONG_FORMAT]: messages.siteUsersImportResultStepFailedStatusWrongFormat,
  OTHER: messages.siteUsersImportResultStepFailedStatusInternalError,
};
