import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { DEVICE_TYPE, SERVICE } from '../../../../config/devices';
import { getTypeFromDeviceType } from '../../../../helpers/devices';
import { selectors as deviceSelectors } from '../../../../modules/devices';
import { IFormData } from '../validationSchema';
import AccessUnitSetup from './AccessUnitSetup';
import { ConfigurationModeSetup } from './ConfigurationModeSetup';
import { IdtIdentifier } from './IdtIdentifier';

export interface ITwoStep {
  onSetNextStep: () => void;
}

export function TwoStep({ onSetNextStep }: ITwoStep) {
  const deviceTypes = useSelector(deviceSelectors.getCompanyDeviceTypes);
  const { watch } = useFormContext<IFormData>();
  const deviceType = watch('deviceType');
  const configurationMode = watch('configurationMode');

  function hasAccessControlService() {
    const type = getTypeFromDeviceType(deviceType);
    switch (type) {
      case DEVICE_TYPE.ACCESS_UNIT:
      case DEVICE_TYPE.ACCESS_UNIT_M:
      case DEVICE_TYPE.CHAMELEON:
        return !!deviceTypes
          .find((item: { name: string }) => item.name === DEVICE_TYPE.ACCESS_UNIT)
          ?.template?.availableServices.includes(SERVICE.ACCESS_CONTROL);
      case DEVICE_TYPE.IP_INTERCOM:
      case DEVICE_TYPE.IP_INTERCOM_V2:
      case DEVICE_TYPE.IP_ONE_INTERCOM:
      case DEVICE_TYPE.IP_STYLE:
        return !!deviceTypes
          .find((item: { name: string }) => item.name === type)
          ?.template?.availableServices.includes(SERVICE.ACCESS_CONTROL);
      default:
        return false;
    }
  }

  switch (getTypeFromDeviceType(deviceType)) {
    case DEVICE_TYPE.ACCESS_UNIT:
    case DEVICE_TYPE.ACCESS_UNIT_M:
    case DEVICE_TYPE.CHAMELEON:
      if (hasAccessControlService()) {
        return <AccessUnitSetup />;
      }
      onSetNextStep();
      return null;
    case DEVICE_TYPE.IDT:
      return <IdtIdentifier />;
    default:
      return (
        <ConfigurationModeSetup
          configurationMode={configurationMode}
          deviceType={deviceType}
          hasAccessControl={hasAccessControlService()}
        />
      );
  }
}
