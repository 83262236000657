import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ApartmentDeleteConfirmModal } from '../../../../components';
import { IApartmentDetail } from '../../../../modules/apartments/store';
import messages from '../../messages';

interface IDeleteApartment {
  apartment: IApartmentDetail;
  onOpenDeleteApartmentModal: (id: number) => void;
}

const DeleteApartment = ({ apartment, onOpenDeleteApartmentModal }: IDeleteApartment) => (
  <Grid item>
    <Box display={{ lg: 'block', xs: 'none' }}>
      <Button
        color="secondary"
        disabled={apartment.devicesCount !== 0}
        onClick={() => onOpenDeleteApartmentModal(apartment.id)}
        startIcon={<DeleteIcon />}
      >
        <FormattedMessage {...messages.deleteApartmentDelete} />
      </Button>
    </Box>
    <Box display={{ lg: 'none', xs: 'block' }}>
      <IconButton
        color="secondary"
        disabled={apartment.devicesCount !== 0}
        onClick={() => onOpenDeleteApartmentModal(apartment.id)}
      >
        <DeleteIcon />
      </IconButton>
    </Box>
    <ApartmentDeleteConfirmModal />
  </Grid>
);

export default DeleteApartment;
