import Box from '@mui/system/Box';
import React from 'react';

const styles = {
  deviceForm: {
    '& img': {
      border: '1px solid #CDD9E1',
      height: '130px',
      width: '130px',
    },
  },
  installApp: {
    '& img': {
      height: '104px',
      width: '104px',
    },
    border: '1px solid',
    borderColor: 'primary.light',
    height: '120px',
    p: 1,
    width: '120px',
  },
};

interface IQRCode {
  alt: string;
  isInstallApp: boolean;
  src: string;
}

function QRCode({ alt, isInstallApp, src, ...rest }: IQRCode) {
  return (
    <Box sx={isInstallApp ? styles.installApp : styles.deviceForm}>
      <img alt={alt} src={src} {...rest} />
    </Box>
  );
}

export default QRCode;
