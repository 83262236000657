import { getOffset } from '../../helpers/dataGrid';
import types from './types';

export const getCurrentUser = () => ({
  type: types.GET_CURRENT_USER_REQUEST,
});

export const clearIdentity = () => ({ type: types.CLEAR_IDENTITY });

export const getUsersList =
  (context) =>
  (
    apartmentId = null,
    companyId,
    siteId = null,
    page = 1,
    rowsPerPage = 10,
    filter = { fulltext: '' },
    order = 'lastName'
  ) => ({
    apartmentId,
    companyId,
    context,
    filter,
    order,
    page,
    params: {
      filter: filter.fulltext,
      include: 'rac',
      limit: rowsPerPage,
      offset: getOffset(page, rowsPerPage),
      order,
    },
    rowsPerPage,
    siteId,
    type: types.GET_USERS_LIST_REQUEST,
  });

export const getUserPin = (companyId, siteId, userId) => ({
  companyId,
  siteId,
  type: types.GET_USER_PIN_REQUEST,
  userId,
});

export const generateUserPin = (userId) => ({
  type: types.GENERATE_USER_PIN_REQUEST,
  userId,
});

export const revokeUserPinRequest = (userId) => ({
  type: types.REVOKE_USER_PIN_REQUEST,
  userId,
});

export const deleteUserShowModal = (context) => (user) => ({
  context,
  type: types.DELETE_USER_SHOW_MODAL,
  user,
});

export const deleteUserHideModal = () => ({
  type: types.DELETE_USER_HIDE_MODAL,
});

export const createUser = (values, context, grantedRoles = {}, formActions = {}) => ({
  context,
  formActions,
  grantedRoles,
  type: types.CREATE_USER_REQUEST,
  values,
});

export const editUser = (values, context, isCurrentUser, formActions = {}, userId = undefined) => ({
  context,
  formActions,
  isCurrentUser,
  type: types.EDIT_USER_REQUEST,
  userId,
  values,
});

export const setSiteUserRole = (userId, data) => ({
  data,
  type: types.SET_USER_ROLE_REQUEST,
  userId,
});

export const setApartmentToUser = (userId, apartments, newApartment, toast = false) => ({
  apartments,
  newApartment,
  toast,
  type: types.SET_APARTMENT_TO_USER_REQUEST,
  userId,
});

export const setApartmentToUserShowModal = (user) => ({
  type: types.SET_APARTMENT_TO_USER_SHOW_MODAL,
  user,
});

export const getUserRfidCard = (companyId, siteId, userId) => ({
  companyId,
  siteId,
  type: types.GET_USER_RFID_CARD_REQUEST,
  userId,
});

export const removeUserRfidCard = (companyId, siteId, userId, rfidId) => ({
  companyId,
  rfidId,
  siteId,
  type: types.REMOVE_USER_RFID_CARD_REQUEST,
  userId,
});

export const getImportUsersStatus = (companyId, siteId) => ({
  companyId,
  siteId,
  type: types.IMPORT_USERS_GET_STATUS_REQUEST,
});

export const importUsersStart = (companyId, siteId, data, successCallback, failedCallback) => ({
  companyId,
  data,
  failedCallback,
  siteId,
  successCallback,
  type: types.IMPORT_USERS_START_REQUEST,
});

export const importUsersAbort = (companyId, siteId) => ({
  companyId,
  siteId,
  type: types.IMPORT_USERS_ABORT_REQUEST,
});

export const importUsersFinish = () => ({
  type: types.IMPORT_USERS_FINISH,
});

export const accessGroupsModalOpen = (userId) => ({
  type: types.SET_ACCESS_GROUPS_SHOW_MODAL,
  userId,
});

export const accessGroupsModalClose = () => ({
  type: types.SET_ACCESS_GROUPS_HIDE_MODAL,
});

export const getSiteUserDevicesList = (
  companyId,
  siteId,
  userId,
  page = 1,
  rowsPerPage = 15,
  filter = { fulltext: '' },
  order = 'name'
) => ({
  companyId,
  filter,
  order,
  page,
  params: {
    filter: filter.fulltext,
    limit: rowsPerPage,
    offset: getOffset(page, rowsPerPage),
    order,
  },
  rowsPerPage,
  siteId,
  type: types.GET_SITE_USER_DEVICES_REQUEST,
  userId,
});
