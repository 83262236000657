import ErrorIcon from '@mui/icons-material/Error';
import ReportProblem from '@mui/icons-material/ReportProblem';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert } from '../../../components';
import { FIRMWARE_STATUS } from '../../../config/devices';
import FirmwareModal from './FirmwareModal';
import messages from './messages';
import UpgradeButton from './UpgradeButton';

// eslint-disable-next-line max-len
const FAQ_URL = 'https://2nwiki.2n.cz/display/FAQ/Firmware+upgrade+-+How+to+do+firmware+update+on+2N+IP+intercoms';

const UpgradeNotification = ({ firmware, firmwareStatus, onUpdateFirmware }) => {
  const {
    info: { newFirmware },
    isUpdating,
  } = firmware;
  const [openFWUpdateModal, setOpenFWUpdateModal] = useState(false);

  const statusProps = useMemo(() => {
    if (firmwareStatus === FIRMWARE_STATUS.UNSUPPORTED) {
      return {
        buttonColor: 'text.disabled',
        description: messages.versionNotificationUnsupportedDescription,
        icon: <ErrorIcon />,
        link: FAQ_URL,
        severity: 'error',
        title: messages.versionNotificationUnsupportedTitle,
      };
    }
    if (firmwareStatus === FIRMWARE_STATUS.DEPRECATED) {
      return {
        buttonColor: 'warning.text',
        description: messages.versionNotificationDeprecatedDescription,
        icon: <ReportProblem />,
        severity: 'warning',
        title: messages.versionNotificationDeprecatedTitle,
      };
    }
    return {
      buttonColor: 'info.text',
      description: messages.versionNotificationNewerDescription,
      icon: <ReportProblem />,
      severity: 'info',
      title: messages.versionNotificationNewerTitle,
    };
  }, [firmwareStatus]);

  return (
    <>
      <Alert
        action={
          <UpgradeButton
            buttonColor={statusProps.buttonColor}
            disabled={firmwareStatus === FIRMWARE_STATUS.UNSUPPORTED || isUpdating}
            onClick={setOpenFWUpdateModal}
          />
        }
        icon={statusProps.icon}
        severity={statusProps.severity}
        title={<FormattedMessage values={{ version: newFirmware.version }} {...statusProps.title} />}
        variant="standard"
      >
        <FormattedMessage
          values={{
            open: (chunks) =>
              statusProps?.link ? (
                <Link href={FAQ_URL} rel="noopener noreferrer" target="_blank">
                  {chunks}
                </Link>
              ) : (
                <Link href="#" onClick={() => setOpenFWUpdateModal(true)}>
                  {chunks}
                </Link>
              ),
          }}
          {...statusProps.description}
        />
      </Alert>
      <FirmwareModal
        disabled={firmwareStatus === FIRMWARE_STATUS.UNSUPPORTED}
        onClose={() => setOpenFWUpdateModal(false)}
        onUpdateFirmware={onUpdateFirmware}
        open={openFWUpdateModal}
        releaseNotes={newFirmware.releaseNotes}
        version={newFirmware.version}
      />
    </>
  );
};

UpgradeNotification.propTypes = {
  firmware: PropTypes.shape({
    info: PropTypes.shape({
      haveUpdate: PropTypes.bool,
      newFirmware: PropTypes.shape({
        releaseNotes: PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string,
            version: PropTypes.string,
          })
        ),
        version: PropTypes.string,
      }),
    }),
    isUpdating: PropTypes.bool,
  }),
  firmwareStatus: PropTypes.string,
  onUpdateFirmware: PropTypes.func.isRequired,
};

UpgradeNotification.defaultProps = {
  firmware: {
    info: {
      haveUpdate: false,
      newFirmware: {
        releaseNotes: [],
        version: '',
      },
    },
    isUpdating: false,
  },
  firmwareStatus: '',
};

export default UpgradeNotification;
