import { MessageDescriptor } from 'react-intl';
import { FE_ROLES } from '../../config/consts';
import { isAdmin } from '../../helpers/user';
import { UserRoles } from '../../modules/user/store';
import messages from './messages';

export interface ISwitchTooltipParams {
  userRole: UserRoles;
  isSwitchChecked: boolean;
}

export function getSwitchTooltipMessage({ isSwitchChecked, userRole }: ISwitchTooltipParams): MessageDescriptor {
  const isUserAdmin = isAdmin(userRole);
  let title = isSwitchChecked
    ? messages.AAApartmentUserListSwitchTooltipApartmentAdmin
    : messages.AAApartmentUserListSwitchTooltipSiteUser;

  if (isUserAdmin) {
    title = messages.AAApartmentUserListSwitchTooltipAdminRole;
  }

  if (userRole === FE_ROLES.LOGIN_LESS || userRole === FE_ROLES.GUEST) {
    title = messages.AAApartmentUserListSwitchTooltipUwL;
  }

  return title;
}
