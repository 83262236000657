import { defineMessages } from 'react-intl';

export default defineMessages({
  ControlButtonBack: {
    defaultMessage: 'Back',
    description: 'Import control button back',
    id: 'controlButtonBack',
  },
  ControlButtonCancel: {
    defaultMessage: 'Cancel',
    description: 'Import control button cancel',
    id: 'controlButtonCancel',
  },
  ControlButtonCloseImport: {
    defaultMessage: 'Close import',
    description: 'Import control button close import',
    id: 'controlButtonCloseImport',
  },
  ControlButtonImport: {
    defaultMessage: 'Start import',
    description: 'Import control button start',
    id: 'controlButtonImport',
  },
  ControlButtonNext: {
    defaultMessage: 'Next',
    description: 'Import control button next',
    id: 'controlButtonNext',
  },
  ControlButtonStop: {
    defaultMessage: 'Stop import',
    description: 'Import control button stop',
    id: 'controlButtonStop',
  },
});
