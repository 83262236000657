import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

const Circle = () => (
  <Box sx={{ position: 'relative', width: 100 }}>
    <CircularProgress size={100} sx={{ color: 'grey.300' }} thickness={3} value={100} variant="determinate" />
    <CircularProgress
      size={100}
      sx={{
        '& .MuiCircularProgress-circle': {
          strokeLinecap: 'round',
        },
        animationDuration: '2000ms',
        color: 'primary.main',
        left: 0,
        position: 'absolute',
      }}
      thickness={3}
      variant="indeterminate"
    />
  </Box>
);

export default Circle;
