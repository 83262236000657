import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { AutocompleteChips } from '../../../../components';
import { getIsChipWarning } from '../helpers';
import TextFieldRow from '../TextFieldRow';
import messages from './messages';

const ContactListItem = ({ data, deviceChipOptions, isDeviceDetailEditing, onChangeItem, setDeviceEditing }) => {
  const { formatMessage } = useIntl();
  const handleChangeContactName = ({ target }) => {
    if (!isDeviceDetailEditing) {
      setDeviceEditing(true);
    }
    onChangeItem({ ...data, name: target.value });
  };
  const handleChangeChips = (value) => {
    if (!isDeviceDetailEditing) {
      setDeviceEditing(true);
    }
    onChangeItem({ ...data, selectedDevices: value });
  };

  return (
    <ListItem
      sx={{
        borderColor: 'grey.300',
        borderTop: '1px solid',
        pl: 0,
      }}
    >
      <Grid alignItems="center" container>
        <Grid item md={3} xs={12}>
          <TextFieldRow
            handleChangeName={handleChangeContactName}
            index={data.index}
            placeholder={formatMessage(messages.placeholderContactName)}
            value={data.name}
          />
        </Grid>
        <Grid item md={9} xs={12}>
          <AutocompleteChips
            getIsChipWarning={getIsChipWarning}
            maxLength={1}
            onChange={handleChangeChips}
            onClear={handleChangeChips}
            options={deviceChipOptions}
            placeholder={formatMessage(messages.placeholderAddDevice)}
            selected={data.selectedDevices}
            titleKey="name"
            warningKey="warning"
          />
        </Grid>
      </Grid>
    </ListItem>
  );
};

ContactListItem.propTypes = {
  data: PropTypes.shape({
    index: PropTypes.number,
    name: PropTypes.string,
    selectedDevices: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  deviceChipOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  isDeviceDetailEditing: PropTypes.bool.isRequired,
  onChangeItem: PropTypes.func.isRequired,
  setDeviceEditing: PropTypes.func.isRequired,
};

export default ContactListItem;
