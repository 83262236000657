import { ElIntStop } from '../../../../../../apiRtk/be4feApi';

export function getElevatorGroupId(floorMapping: ElIntStop) {
  return floorMapping.buildingId && floorMapping.elevatorGroupId
    ? `${floorMapping.buildingId}|${floorMapping.elevatorGroupId}`
    : 'null';
}

export function getDestinationId(floorMapping: ElIntStop) {
  return floorMapping.areaId ? floorMapping.areaId : 'null';
}
