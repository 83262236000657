import { defineMessages } from 'react-intl';

export default defineMessages({
  usersAutocompleteHelperText: {
    defaultMessage: 'You can add multiple users',
    description: 'Apartment users add users modal - helper text',
    id: 'usersAutocompleteHelperText',
  },
  usersAutocompleteLabel: {
    defaultMessage: 'Users',
    description: 'Apartment users add users modal - label',
    id: 'usersAutocompleteLabel',
  },
  usersAutocompleteNewItemLabel: {
    defaultMessage: 'Create new user',
    description: 'Apartment users add users modal - new item label',
    id: 'usersAutocompleteNewItemLabel',
  },
  usersAutocompleteNewItemShortLabel: {
    defaultMessage: 'New user',
    description: 'Apartment users add users modal - new item label',
    id: 'usersAutocompleteNewItemShortLabel',
  },
  usersAutocompleteNewItemWithoutLoginLabel: {
    defaultMessage: 'Create new user without login',
    description: 'Apartment users add users modal - new item label',
    id: 'usersAutocompleteNewItemWithoutLoginLabel',
  },
  usersAutocompleteNewItemWithoutLoginShortLabel: {
    defaultMessage: 'New user without login',
    description: 'Apartment users add users modal - new item label',
    id: 'usersAutocompleteNewItemWithoutLoginShortLabel',
  },
  usersAutocompletePlaceholder: {
    defaultMessage: 'Select, or create a new one',
    description: 'Apartment users add users modal - input placeholder',
    id: 'usersAutocompletePlaceholder',
  },

  usersAutocompleteUsersFormEmail: {
    defaultMessage: 'New user email',
    description: 'Users form field - email',
    id: 'usersAutocompleteUsersFormEmail',
  },
  usersAutocompleteUsersFormFirstName: {
    defaultMessage: 'First name',
    description: 'Users form field - first name',
    id: 'usersAutocompleteUsersFormFirstName',
  },
  usersAutocompleteUsersFormLastName: {
    defaultMessage: 'Surname',
    description: 'Users form field - surname',
    id: 'usersAutocompleteUsersFormLastName',
  },
  usersAutocompleteUseUsersFormValidationSchemaValidationMaxLengthFirstName: {
    defaultMessage: 'First name is too long.',
    description: 'User data form - new user first name is too long',
    id: 'usersAutocompleteUseUsersFormValidationSchemaValidationMaxLengthFirstName',
  },
  usersAutocompleteUseUsersFormValidationSchemaValidationMaxLengthLastName: {
    defaultMessage: 'Surname is too long.',
    description: 'User data form - new user surname is too long',
    id: 'usersAutocompleteUseUsersFormValidationSchemaValidationMaxLengthLastName',
  },
  usersAutocompleteUseUsersFormValidationSchemaValidationRequiredEmail: {
    defaultMessage: 'Enter the e-mail.',
    description: 'User data form - e-mail is required',
    id: 'usersAutocompleteUseUsersFormValidationSchemaValidationRequiredEmail',
  },
  usersAutocompleteUseUsersFormValidationSchemaValidationRequiredFirstName: {
    defaultMessage: 'Enter the first name',
    description: 'User data form - first name is required',
    id: 'usersAutocompleteUseUsersFormValidationSchemaValidationRequiredFirstName',
  },
  usersAutocompleteUseUsersFormValidationSchemaValidationRequiredLastName: {
    defaultMessage: 'Enter the surname',
    description: 'User data form - surname is required',
    id: 'usersAutocompleteUseUsersFormValidationSchemaValidationRequiredLastName',
  },
  usersAutocompleteUseUsersFormValidationSchemaValidationUser: {
    defaultMessage: 'Value is mandatory.',
    description: 'User data form - user is required',
    id: 'usersAutocompleteUseUsersFormValidationSchemaValidationUser',
  },
  usersAutocompleteUseUsersFormValidationSchemaValidationUserEmailExistUser: {
    defaultMessage: 'User with this e-mail address already exists in this Site.',
    description: 'User data form - the e-mail is already registered',
    id: 'usersAutocompleteUseUsersFormValidationSchemaValidationUserEmailExistUser',
  },
  usersAutocompleteUseUsersFormValidationSchemaValidationUserEmailValid: {
    defaultMessage: 'Wrong format of the e-mail.',
    description: 'User data form - e-mail is not valid ',
    id: 'usersAutocompleteUseUsersFormValidationSchemaValidationUserEmailValid',
  },
});
