import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { RC_STATUS, SERVICE } from '../../config/devices';
import actions, { selectors } from '../../modules/devices';
import { isActiveService } from '../../modules/devices/helpers/helpers';
import Loader from '../Loader';
import Modal from '../Modal';
import messages from './messages';

const isConnected = (device) =>
  isActiveService(device, SERVICE.REMOTE_CONFIGURATION) &&
  device?.services?.[SERVICE.REMOTE_CONFIGURATION]?.status === RC_STATUS.CONNECTED &&
  device?.services?.[SERVICE.REMOTE_CONFIGURATION]?.url;
const RCModal = ({ device, onClose, onConnect, show }) => {
  useEffect(() => {
    if (show && device.id) {
      if (isConnected(device)) {
        window.open(device.services[SERVICE.REMOTE_CONFIGURATION].url, '_blank');
        onClose();
      } else {
        onConnect(device.id);
        setTimeout(() => onClose(), 30 * 1000);
      }
    }
  }, [show, device]);

  return (
    <Modal
      canClose={false}
      maxWidth="xs"
      name="rc-modal"
      onClose={onClose}
      open={show}
      title={<FormattedMessage {...messages.rcModalTitle} />}
    >
      <Box alignItems="center" display="flex" flexDirection="column" justifyContent="center" sx={{ minHeight: 200 }}>
        <Typography component="p" sx={{ mb: 3 }}>
          <FormattedMessage {...messages.rcModalWaitForConnection} />
        </Typography>
        <Loader alignItems="center" size={80} />
      </Box>
    </Modal>
  );
};

RCModal.propTypes = {
  device: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    services: PropTypes.shape({
      [SERVICE.REMOTE_CONFIGURATION]: PropTypes.shape({
        active: PropTypes.bool,
        status: PropTypes.string,
        url: PropTypes.string,
      }),
    }),
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  onConnect: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  device: selectors.getDeviceRCModal(state),
  show: selectors.isRCModalOpen(state),
});

const mapDispatchToProps = {
  onClose: actions.rcHideModal,
  onConnect: actions.connectToRCWebsocket,
};

export default connect(mapStateToProps, mapDispatchToProps)(RCModal);
