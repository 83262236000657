export default () => ({
  '& tbody tr': {
    '&:hover': {
      backgroundColor: 'grey.200',
    },
  },
  '& tbody tr:nth-of-type(odd)': {
    backgroundColor: 'grey.100',
  },
  '& thead tr th:not(.MuiTableCell-paddingCheckbox)': {
    '&, & span, & svg': {
      color: 'primary.dark',
      fontSize: 'small',
      fontWeight: 700,
      py: 0,
      whiteSpace: 'nowrap',
    },
  },
});
