import { getUniqueKey } from '../helpers';
import types, { TYPE_DESCRIPTOR, TYPE_TEXT } from './types';

const addSnackbar = (messageData, messageType = TYPE_TEXT) => ({
  message: {
    ...messageData,
    key: getUniqueKey(),
    type: messageType,
  },
  type: types.ADD_SNACKBAR,
});

export const addSnackbarSuccess = (message) =>
  addSnackbar({ message, options: { variant: 'success' } }, TYPE_DESCRIPTOR);
export const addSnackbarInfo = (message) => addSnackbar({ message, options: { variant: 'info' } });
export const addSnackbarWarning = (message) => addSnackbar({ message, options: { variant: 'warning' } });
export const addSnackbarError = (message, messageType = TYPE_TEXT) =>
  addSnackbar({ message, options: { variant: 'error' } }, messageType);
export const removeSnackbar = (key) => ({ key, type: types.REMOVE_SNACKBAR });
