import { useReducer } from 'react';
import { ANSWERING_MODE, CONFIGURATION_MODE, FEATURES, SERVICE } from '../../../config/devices';
import types from './types';

// eslint-disable-next-line max-statements
const useServiceBoxProps = (device) => {
  const isAnswerMode = () => device?.services?.[SERVICE.MOBILE_VIDEO]?.answerMode !== ANSWERING_MODE.OFF;
  const faceTrackingMode = () =>
    device?.features?.find((item) => item.type === FEATURES.FACE_TRACKING)?.faceTrackingMode ?? undefined;
  const getCameraSwitchAllowed = () => !!device.services?.[SERVICE.MOBILE_VIDEO]?.cameraSwitchAllowed;
  const getAdaptiveVideoQuality = () => device?.services?.[SERVICE.MOBILE_VIDEO]?.adaptiveVideoQuality || false;
  const adaptiveVideoQualitySupported =
    device?.services?.[SERVICE.MOBILE_VIDEO]?.adaptiveVideoQualitySupported || false;
  const getConfigurationMode = () =>
    device?.services?.[SERVICE.ACS]?.active ? CONFIGURATION_MODE.AUTOMATIC : CONFIGURATION_MODE.MANUAL;

  const initialState = {
    adaptiveVideoQuality: getAdaptiveVideoQuality(),
    adaptiveVideoQualityChanged: false,
    adaptiveVideoQualitySupported,
    answerMode: isAnswerMode(),
    answerModeChanged: false,
    cameraSwitchAllowed: getCameraSwitchAllowed(),
    cameraSwitchAllowedChanged: false,
    configurationMode: getConfigurationMode(),
    configurationModeChanged: false,
    faceTrackingMode: faceTrackingMode(),
    faceTrackingModeChanged: false,
  };

  const reducers = {
    [types.CAMERA_SWITCH_ALLOWED]: (state, { payload }) => ({
      ...state,
      cameraSwitchAllowed: payload,
      cameraSwitchAllowedChanged: payload !== getCameraSwitchAllowed(),
    }),
    [types.ADAPTIVE_VIDEO_QUALITY]: (state, { payload }) => ({
      ...state,
      adaptiveVideoQuality: payload,
      adaptiveVideoQualityChanged: payload !== getAdaptiveVideoQuality(),
    }),
    [types.ANSWER_MODE]: (state, { payload }) => ({
      ...state,
      answerMode: payload,
      answerModeChanged: payload !== isAnswerMode(),
    }),
    [types.FACE_TRACKING_MODE]: (state, { payload }) => ({
      ...state,
      faceTrackingMode: payload,
      faceTrackingModeChanged: payload !== faceTrackingMode(),
    }),
    [types.CONFIGURATION_MODE]: (state, { payload }) => ({
      ...state,
      configurationMode: payload,
      configurationModeChanged: payload !== getConfigurationMode(),
    }),
    [types.RESET]: (state) => ({
      ...state,
      adaptiveVideoQualityChanged: false,
      answerModeChanged: false,
      cameraSwitchAllowedChanged: false,
      configurationModeChanged: false,
      faceTrackingModeChanged: false,
    }),
  };

  const reducer = (state = initialState, action = {}) => {
    if (reducers[action.type]) {
      return reducers[action.type](state, action);
    }
    return state;
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const serviceBoxDispatch = (action) => dispatch({ ...action });

  return [state, serviceBoxDispatch];
};

export default useServiceBoxProps;
