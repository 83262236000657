import { defineMessages } from 'react-intl';

export default defineMessages({
  resendBackToLogin: {
    defaultMessage: 'Back to Log in',
    description: 'Resend Unconfirmed email - Back to Log in',
    id: 'resend.backToLogin',
  },
  resendBtnResend: {
    defaultMessage: 'Resend confirmation mail',
    description: 'Resend Unconfirmed email - button resend',
    id: 'resend.btnResend',
  },
  resendDescription: {
    defaultMessage: "Your email address hasn't been confirmed yet. Please check your inbox and",
    description: 'Resend Unconfirmed email - description',
    id: 'resend.description',
  },
  resendLogin: {
    defaultMessage: 'LOG IN',
    description: 'Resend Unconfirmed email - login title',
    id: 'resend.login',
  },
  resendTitle: {
    defaultMessage: 'Unconfirmed email',
    description: 'Resend Unconfirmed email - title',
    id: 'resend.title',
  },
});
