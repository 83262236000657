import { defineMessages } from 'react-intl';

export default defineMessages({
  apartmentCreateModalActivatePaidServices: {
    defaultMessage: 'Activate paid services',
    description: 'Apartment create modal - checkbox label',
    id: 'apartmentCreateModal.activatePaidServices',
  },
  apartmentCreateModalActivatePaidServicesCaption: {
    defaultMessage: 'Activates paid services for the apartment and immediately uses available credits',
    description: 'Apartment create modal - checkbox description',
    id: 'apartmentCreateModal.activatePaidServices.caption',
  },
  apartmentCreateModalActivatePaidServicesCaptionActivableAndExpirable: {
    defaultMessage: 'Activates paid services for the apartment',
    id: 'apartmentCreateModal.activatePaidServices.caption.activableAndExpirable',
  },
  apartmentCreateModalActivatePaidServicesCaptionTrial: {
    defaultMessage:
      'Activates paid services for the apartment without consuming any credits, as you are currently in a trial period',
    id: 'apartmentCreateModal.activatePaidServices.caption.trial',
  },
  apartmentDataFormApartmentInfoTitle: {
    defaultMessage: 'Apartment basic info',
    id: 'apartmentDataForm.apartmentInfo.title',
  },
  apartmentDataFormApartmentStatusActivatePaidServices: {
    defaultMessage: 'Activate paid services',
    id: 'apartmentDataForm.apartmentStatus.activatePaidServices',
  },
  apartmentDataFormApartmentStatusInfoActiveForFree: {
    defaultMessage: 'Activating paid services for the apartment{br}immediately uses available credits',
    id: 'apartmentDataForm.apartmentStatus.infoActiveForFree',
  },
  apartmentDataFormApartmentStatusInfoActiveForFreeActivableAndExpirable: {
    defaultMessage: 'Activates paid services for the apartment',
    id: 'apartmentDataForm.apartmentStatus.infoActiveForFree.activableAndExpirable',
  },
  apartmentDataFormApartmentStatusInfoActiveForFreeTrial: {
    defaultMessage: 'Activating paid services for the apartment{br}doesn’t use credits during your trial period',
    id: 'apartmentDataForm.apartmentStatus.infoActiveForFree.trial',
  },
  apartmentDataFormApartmentStatusInfoNoService: {
    defaultMessage: 'Activating paid services for the apartment{br}immediately uses available credits',
    id: 'apartmentDataForm.apartmentStatus.infoNoService',
  },
  apartmentDataFormApartmentStatusInfoNoServiceActivableAndExpirable: {
    defaultMessage: 'Activates paid services for the apartment',
    id: 'apartmentDataForm.apartmentStatus.infoNoService.activableAndExpirable',
  },
  apartmentDataFormApartmentStatusInfoNoServiceTrial: {
    defaultMessage: 'Activating paid services for the apartment{br}doesn’t use credits during your trial period',
    id: 'apartmentDataForm.apartmentStatus.infoNoService.trial',
  },
  apartmentDataFormApartmentStatusInfoUnlicensedActivated: {
    defaultMessage: 'Purchase credits to make paid services available',
    id: 'apartmentDataForm.apartmentStatus.infoUnlicensedActivated',
  },
  apartmentDataFormApartmentStatusInfoUnlicensedNotActivated: {
    defaultMessage: 'Activating paid services for the apartment{br}immediately uses available credits',
    id: 'apartmentDataForm.apartmentStatus.infoUnlicensedNotActivated',
  },
  apartmentDataFormApartmentStatusInfoUnlicensedNotActivatedActivableAndExpirable: {
    defaultMessage: 'Activates paid services for the apartment',
    id: 'apartmentDataForm.apartmentStatus.infoUnlicensedNotActivated.activableAndExpirable',
  },
  apartmentDataFormApartmentStatusInfoUnlicensedNotActivatedTrial: {
    defaultMessage: 'Activating paid services for the apartment{br}doesn’t use credits during your trial period',
    id: 'apartmentDataForm.apartmentStatus.infoUnlicensedNotActivated.trial',
  },
  apartmentDataFormApartmentStatusTitle: {
    defaultMessage: 'Apartment status',
    id: 'apartmentDataForm.apartmentStatus.title',
  },
  apartmentDataFormExistNumber: {
    defaultMessage: 'An apartment with this number already exists.',
    description: 'Apartment data form - apartment number already exists',
    id: 'apartmentDataForm.existNumber',
  },
  apartmentDataFormFloorDescription: {
    defaultMessage: 'We recommend grouping apartments by floor.',
    description: 'Apartment data form - floor description',
    id: 'apartmentDataForm.floorDescription',
  },
  apartmentDataFormHelperTextFloor: {
    defaultMessage: 'Usually a group of apartments.',
    description: 'Apartment data form - apartment floor helper text',
    id: 'apartmentDataForm.helperTextFloor',
  },
  apartmentDataFormHelperTextName: {
    defaultMessage: 'You can name the apartment after the family name.',
    description: 'Apartment data form - apartment name helper text',
    id: 'apartmentDataForm.helperTextName',
  },
  apartmentDataFormHelperTextNumber: {
    defaultMessage: 'Use a unique number, e.g. 27, 4A or 2.01',
    description: 'Apartment data form - apartment number helper text',
    id: 'apartmentDataForm.helperTextNumber',
  },
  apartmentDataFormName: {
    defaultMessage: 'Apartment name',
    description: 'Apartment data form - apartment name',
    id: 'apartmentDataForm.name',
  },
  apartmentDataFormNumber: {
    defaultMessage: 'Number',
    description: 'Apartment data form - number',
    id: 'apartmentDataForm.number',
  },
  apartmentDataFormRequiredFloor: {
    defaultMessage: 'Enter the floor number',
    description: 'Apartment data form - floor is required',
    id: 'apartmentDataForm.requiredFloor',
  },
  apartmentDataFormRequiredNumber: {
    defaultMessage: 'Enter the apartment number',
    description: 'Apartment data form - number is required',
    id: 'apartmentDataForm.requiredNumber',
  },
  apartmentFloorAutocompleteFloor: {
    defaultMessage: 'Floor',
    description: 'Apartment data form - floor',
    id: 'apartmentDataForm.floor',
  },
});
