import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import TotalPriceBar from '../../Common/TotalPriceBar';
import messages from '../../messages';

const PromoTotalPriceBar = ({ currency, price, priceVat, subscriptionsCount }) => (
  <TotalPriceBar
    currency={currency}
    price={price}
    priceVat={priceVat}
    totalPriceNote={
      <FormattedMessage
        {...messages.promoCodesTotalPriceNote}
        values={{
          subscriptionsCount,
        }}
      />
    }
  />
);

PromoTotalPriceBar.propTypes = {
  currency: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  priceVat: PropTypes.number.isRequired,
  subscriptionsCount: PropTypes.number.isRequired,
};

export default PromoTotalPriceBar;
