import { Theme } from '@mui/material/styles';
import { Box } from '@mui/system';
import React from 'react';
import { ReactSVG } from 'react-svg';

const iconStyle = {
  '& svg': (theme: Theme) => ({
    fill: theme.palette.secondary.main,
  }),
};

export interface IStyledIconProps {
  src: string,
}

export function StyledIcon({ src }: IStyledIconProps): React.JSX.Element {
  return (
    <Box sx={iconStyle}>
      <ReactSVG src={src}>
      </ReactSVG>
    </Box>
  )
}
