import { defineMessages } from 'react-intl';

export default defineMessages({
  AAmodalShowRFIDClose: {
    defaultMessage: 'close',
    id: 'AAmodal.showRFID.close',
  },
  AAmodalShowRFIDText1: {
    defaultMessage: 'The RFID key fob allows to open doors through which the user is granted access.',
    id: 'AAmodal.showRFID.text1',
  },
  AAmodalShowRFIDText2: {
    defaultMessage: 'This key fob is assigned to:',
    id: 'AAmodal.showRFID.text2',
  },
  AAmodalShowRFIDTitle: {
    defaultMessage: 'RFID key fob',
    id: 'AAmodal.showRFID.title',
  },
});
