import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { addSnackbarSuccess } from '../../modules/notifications/actions';
import messages from './messages';
import Panel from './Panel';

const styles = {
  buttons: {
    '& > button': {
      m: 0,
      ml: 1,
    },
    textAlign: 'right',
    width: {
      lg: '140px',
    },
  },
  errorMessage: {
    color: 'error.main',
    pt: 1,
    textAlign: 'center',
  },
  iconButton: {
    px: 1,
  },
  pin: {
    overflow: 'hidden',
    width: {
      lg: 'calc(100% - 140px)',
    },
  },
  pinNormal: {
    fontWeight: 400,
    m: 0,
  },
  progress: {
    backgroundColor: 'primary.light',
    bottom: 0,
    height: '2px',
    left: 0,
    position: 'absolute',
    right: 0,
  },
  root: {
    background: '#E5ECF0',
    border: '1px solid #CDD9E1',
    display: {
      lg: 'flex',
    },
    my: 2,
    p: 2,
    position: 'relative',
  },
  rootError: {
    borderColor: 'error.main',
  },
  smallPin: {
    fontSize: 24,
    letterSpacing: 0,
  },
};

const PanelPIN = ({ concealable, error, pin }) => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [hide, setHide] = useState(concealable);

  const toggleHide = () => {
    setHide(!hide);
  };

  const onCopyToClipboard = (event) => {
    event.preventDefault();
    if (pin && copy(pin)) {
      dispatch(addSnackbarSuccess(messages.userAccessFormPinCopied));
    }
  };

  return (
    <Panel errorMessage={formatMessage(messages.userAccessFormPinErrorMessage)} isError={error}>
      <Box data-pin={pin} sx={styles.pin}>
        <Typography sx={[styles.pinNormal, pin?.length > 6 ? styles.smallPin : {}]} variant="h4">
          {hide ? ''.padEnd(pin.length, '#') : pin}
        </Typography>
      </Box>
      <Box sx={styles.buttons}>
        {concealable && (
          <IconButton onClick={toggleHide} sx={styles.iconButton}>
            {hide ? <VisibilityOutlinedIcon /> : <VisibilityOffOutlinedIcon />}
          </IconButton>
        )}
        <Button disabled={!pin} onClick={onCopyToClipboard} startIcon={<FileCopyOutlinedIcon />} sx={{ px: 1.3 }}>
          <FormattedMessage {...messages.userAccessFormPinCopy} />
        </Button>
      </Box>
    </Panel>
  );
};

PanelPIN.propTypes = {
  concealable: PropTypes.bool,
  error: PropTypes.bool,
  pin: PropTypes.string,
};

PanelPIN.defaultProps = {
  concealable: false,
  error: false,
  pin: null,
};

export default PanelPIN;
