import { CancelToken } from 'axios';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { OryCancelableResponse, OryContext, OryResponse } from '../../../types';
import { getCurrentUserOrySession, loginToOry, logoutFromOry } from './loginApi';

export interface ILoginToOryProps {
  email: string;
  password: string;
  sessionRefresh?: boolean;
  context?: OryContext;
}

export interface ICancelTokenProps {
  cancelToken?: CancelToken;
}

export interface IUseLoginApi {
  loginToOry: (props: ILoginToOryProps) => Promise<OryResponse>;
  logoutFromOry: (props?: ICancelTokenProps) => Promise<OryCancelableResponse>;
  getOryCurrentUserSession: (props?: ICancelTokenProps) => Promise<OryCancelableResponse>;
  isOryApiFetching: boolean;
}

export function useOryLoginApi(): IUseLoginApi {
  const [isFetching, setIsFetching] = useState(false);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  return {
    getOryCurrentUserSession: (props) => getCurrentUserOrySession(setIsFetching, dispatch, formatMessage, props),
    isOryApiFetching: isFetching,
    loginToOry: (props) => loginToOry(props, setIsFetching, dispatch, formatMessage),
    logoutFromOry: (props) => logoutFromOry(setIsFetching, dispatch, formatMessage, props),
  };
}
