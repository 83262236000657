import Link from '@mui/material/Link';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import styles from './styles';

const PromoCodesDetail = ({ colSpan, isOdd, onOpenPromoDetail, orderResults, payment }) => {
  const orderResultesButtons = () =>
    orderResults.map((item, index) => (
      <span key={item.id}>
        {index > 0 && <span>{' | '}</span>}
        <Link component="button" onClick={() => onOpenPromoDetail(payment, item.id, item.name)} variant="body2">
          <span>{item.name}</span>
        </Link>
      </span>
    ));

  return (
    <TableRow sx={isOdd && styles.rowOdd}>
      <TableCell colSpan={colSpan}>
        <FormattedMessage
          {...messages.paymentsTableServicePROMODetail}
          values={{
            codes: <span>{orderResultesButtons()}</span>,
            count: orderResults.length,
          }}
        />
      </TableCell>
    </TableRow>
  );
};

PromoCodesDetail.propTypes = {
  colSpan: PropTypes.number.isRequired,
  isOdd: PropTypes.bool.isRequired,
  onOpenPromoDetail: PropTypes.func.isRequired,
  orderResults: PropTypes.arrayOf(PropTypes.object).isRequired,
  payment: PropTypes.shape({}).isRequired,
};

export default PromoCodesDetail;
