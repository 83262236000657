import { defineMessages } from 'react-intl';

export default defineMessages({
  elevatorIntegrationInactiveText1: {
    defaultMessage:
      '<p><strong>About KONE cloud integration</strong></p>\n\n<p>Elevator integration is available for KONE elevators only.</p>\n\n<p>Setting it up involves a complex process requiring close collaboration between you, 2N, and KONE. This process includes various contracts and agreements.</p>\n\n<p>If you\'ve installed KONE elevators on your site and are keen on integrating them with our cloud service, we\'re here to help guide you every step of the way. Please get in touch with us at  sales@2n.com.</p>',
    id: 'elevatorIntegration.inactive.text01',
  },
  elevatorIntegrationInactiveText2: {
    defaultMessage:
      '<p><strong>Available features</strong></p>\n\n<ul><li><strong>Landing Call:</strong> Automatically call the elevator to the floor where a resident enters the building.</li>\n\n<li><strong>Destination Call:</strong> In addition to the Landing Call functionality, automatically preset the elevator to stop at the floor where the resident\'s apartment is located.</li></ul>',
    id: 'elevatorIntegration.inactive.text02',
  },
  elevatorIntegrationInactiveText3: {
    defaultMessage:
      ' ',
    id: 'elevatorIntegration.inactive.text03',
  },
});
