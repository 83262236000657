import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { useUrlParams } from '../../../helpers/urlParams';
import getMultilineUsersArray from '../helpers/getMultilineUsersArray';

export interface IFormProps {
  pin: boolean;
  users: string;
}

export function useGetGlobalHooks() {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { apartmentId, companyId, siteId } = useUrlParams();

  const formMethods = useForm<IFormProps>({
    defaultValues: {
      pin: false,
      users: '',
    },
    resolver: yupResolver<any>(
      yup.object().shape({
        pin: yup.bool(),
        users: yup
          .string()
          .test({
            test: (value: string) => {
              const usersArr = getMultilineUsersArray(value);
              return usersArr.length > 0;
            },
          })
          .required(),
      })
    ),
  });

  return {
    apartmentId,
    companyId,
    control: formMethods.control,
    dispatch,
    formatMessage,
    formMethods,
    handleSubmit: formMethods.handleSubmit,
    isDirty: formMethods.formState.isDirty,
    isValid: formMethods.formState.isValid,
    siteId,
  };
}
