import { defineMessages } from 'react-intl';

export default defineMessages({
  userPinModalAssigneeText: {
    defaultMessage: 'This PIN code is assigned to:',
    description: 'User pin modal - assignee text',
    id: 'userPinModalAssigneeText',
  },
  userPinModalCancelButton: {
    defaultMessage: 'Cancel',
    description: 'User pin modal - cancel button',
    id: 'userPinModalCancelButton',
  },
  userPinModalConfirmButton: {
    defaultMessage: 'Save & Send pin',
    description: 'User pin modal - confirm button',
    id: 'userPinModalConfirmButton',
  },
  userPinModalConfirmButtonLoginLess: {
    defaultMessage: 'Save pin',
    description: 'User pin modal - confirm button LoginLess',
    id: 'userPinModalConfirmButtonLoginLess',
  },
  userPinModalInfoText: {
    defaultMessage: 'Don’t forget to pass the assigned PIN to the user.',
    description: 'User pin modal - info text',
    id: 'userPinModalInfoText',
  },
  userPinModalTitle: {
    defaultMessage: 'Generate new PIN',
    description: 'User pin modal - title',
    id: 'userPinModalTitle',
  },
});
