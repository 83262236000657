import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { UserRfidModalNew } from '../../../../components';
import { useModal } from '../../../../hooks/useModal';
import { getUsersRfid } from '../../../../modules/user/selectors';
import { IUser } from '../../../../modules/user/store';
import messages from './messages';

function CreateRfidCardButton({ user }: { user: IUser }) {
  const { Modal: UserRfidModal, onOpen: onOpenUserRfidModal } = useModal({
    Modal: UserRfidModalNew,
  });

  const hasRfid = !!useSelector(getUsersRfid);

  return (
    <>
      <Button
        data-testid="CreateRfidCardButton"
        disabled={hasRfid}
        onClick={onOpenUserRfidModal}
        startIcon={<AddIcon />}
        variant="text"
      >
        <FormattedMessage {...messages.accessAndSecurityAssignRfidCard} />
      </Button>
      <UserRfidModal context="SITE_USER_DETAIL" user={user} />
    </>
  );
}

export default CreateRfidCardButton;
