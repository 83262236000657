import { defineMessages } from 'react-intl';

export default defineMessages({
  rcModalConnectionEstablished: {
    defaultMessage: 'Secure connection established.',
    description: '',
    id: 'rcModal.connectionEstablished',
  },
  rcModalOpenInNewWindow: {
    defaultMessage: 'Open in new window',
    description: '',
    id: 'rcModal.openInNewWindow',
  },
  rcModalTitle: {
    defaultMessage: 'Open Remote Configuration',
    description: '',
    id: 'rcModal.title',
  },
  rcModalWaitForConnection: {
    defaultMessage: `We’re establishing a secure connection. This may take
    about 10 seconds.`,
    description: '',
    id: 'rcModal.waitForConnection',
  },
});
