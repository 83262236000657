import { getOffset } from '../../helpers/dataGrid';
import types from './types';

export const startTrialVersion = () => ({ type: types.START_TRIAL_REQUEST });

export const getPaymentsList = (
  companyId,
  siteId,
  page = 1,
  rowsPerPage = 10,
  filter = { fulltext: '' },
  order = '-dateCreated',
  context
) => ({
  companyId,
  context,
  filter,
  order,
  page,
  params: {
    filter: filter.fulltext,
    limit: rowsPerPage,
    offset: getOffset(page, rowsPerPage),
    order,
  },
  rowsPerPage,
  siteId,
  type: types.GET_PAYMENTS_REQUEST,
});

export const openPromoDetailModal = (payment, id, name) => ({
  id,
  name,
  payment,
  type: types.PROMO_DETAIL_SHOW_MODAL,
});

export const closePromoDetailModal = () => ({ type: types.PROMO_DETAIL_HIDE_MODAL });

export const getPaymentPromoDetail = (paymentId, promoId) => ({
  paymentId,
  promoId,
  type: types.GET_PAYMENT_DETAIL_REQUEST,
});
