import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormikFields } from '../../../../../../components';
import { SUBSCRIPTION_TYPE } from '../../../consts';
import commonMessages from '../../../messages';
import messages from '../messages';

const styles = {
  content: {
    overflow: 'hidden',
  },
  helperText: {
    display: 'none',
  },
  input: (theme) => ({
    color: `${theme.palette.text.primary} !important`,
  }),
  label: {
    width: 'max-content',
  },
};

const SubscriptionTypeSelect = ({ name, subscriptionType }) => (
  <FormikFields.Select
    FormHelperTextProps={{
      sx: styles.helperText,
    }}
    InputLabelProps={{
      shrink: true,
      sx: styles.label,
    }}
    InputProps={{
      sx: subscriptionType !== SUBSCRIPTION_TYPE.DEFAULT ? styles.input : {},
    }}
    label={
      <>
        <Box component="span" fontWeight="bold">
          <FormattedMessage {...messages.promoBatchFormSelectLabelSubscriptionType} />
        </Box>{' '}
        <Box component="span" fontWeight="normal">
          <FormattedMessage {...messages.promoBatchFormSelectLabelDeviceApartment} />
        </Box>
      </>
    }
    margin="dense"
    name={`${name}.subscriptionType`}
    options={[
      {
        disabled: true,
        label: <FormattedMessage {...messages.promoBatchFormSelectPlaceholder} />,
        value: SUBSCRIPTION_TYPE.DEFAULT,
      },
      {
        label: <FormattedMessage {...commonMessages.promoCodeProductMobileVideoPoolCreditYearStandard} />,
        value: SUBSCRIPTION_TYPE.DEVICE,
      },
      {
        label: <FormattedMessage {...commonMessages.promoCodeProductMobileVideoPoolCreditYearApartment} />,
        value: SUBSCRIPTION_TYPE.APARTMENT,
      },
    ]}
    size="small"
  />
);

SubscriptionTypeSelect.propTypes = {
  name: PropTypes.string.isRequired,
  subscriptionType: PropTypes.string.isRequired,
};

export default SubscriptionTypeSelect;
