import { createSelector } from 'reselect';
import { MOBILE_VIDEO_CODE, MOBILE_VIDEO_STATUS } from '../../config/consts';

export const getSerialNumberLicenseIsLoading = (state) => state.subscription.license.isFetching;

export const getSerialNumberLicenseData = (state) => {
  const { data } = state.subscription.license;
  return {
    canBeInvoiced: data?.canBeInvoiced || false,
    creditLimit: data?.creditLimit || 0,
    currency: data?.currency || '',
    paymentTerm: data?.paymentTerm || '',
    prices: data?.prices || null,
    products: data?.products || null,
    serialNumbers: data?.serialNumbers || [],
  };
};

export const getDeviceLicensePurchaseOrder = (state) => {
  const { purchaseOrder } = state.subscription.license.paymentData;
  return {
    chargeVat: purchaseOrder?.chargeVat || false,
    currency: purchaseOrder?.currency || '',
    customerOrderNumber: purchaseOrder?.customerOrderNumber || '',
    location: state.subscription.license.paymentData.location || '',
    paymentMethod: purchaseOrder?.paymentMethod || '',
    price: purchaseOrder?.price || 0,
    priceVat: purchaseOrder?.priceVat || 0,
  };
};

export const getRegularPayment = (state) => state.subscription.videoMobileLicense.data?.regularPayment || null;

export const getCalculation = (state) => state.subscription.videoMobileLicense.data?.calculation;

export const getNextPayment = (state) => state.subscription.videoMobileLicense.data?.nextPayment;

export const getMonthPercentage = (state) => state.subscription.videoMobileLicense.data?.monthPercentage;

export const getVideoMobileLicenseIsLoading = (state) => state.subscription.videoMobileLicense.isFetching;

export const getVideoMobileLicenseIsFetched = (state) => {
  if (
    getVideoMobileLicenseIsLoading(state) ||
    !getRegularPayment(state) ||
    state.subscription.videoMobileLicense.error
  ) {
    return false;
  }

  return true;
};

export const getMobileVideoStartPurchase = (state) => {
  if (getVideoMobileLicenseIsFetched(state) && getRegularPayment(state)) {
    if (
      getCalculation(state) &&
      getNextPayment(state) &&
      Object.keys(getRegularPayment(state)).length &&
      Object.keys(getCalculation(state)).length &&
      Object.keys(getNextPayment(state)).length
    ) {
      return 'start';
    }
    if (!Object.keys(getNextPayment(state)).length) {
      return 'stop';
    }
  }
  return null;
};

export const getMobileVideoPurchaseCalculation = (state) =>
  state.subscription.videoMobileLicense?.data?.calculation || null;

export const getMobileVideoPurchasePrices = (state) => state.subscription.videoMobileLicense.prices?.data || null;

export const getDeviceLicensePurchaseId = (state) => state.subscription.license.paymentData?.id || '';

const getLicenseOrderContent = (state) => {
  if (state.subscription.license.paymentData.purchaseOrder) {
    return state.subscription.license.paymentData.purchaseOrder.orderContent;
  }
  return [];
};

const getLicenseOrderDevices = createSelector(getLicenseOrderContent, (orders) => [
  ...new Set(orders.map((order) => order.serialNumber)),
]);

export const getLicenseOrders = createSelector(getLicenseOrderContent, getLicenseOrderDevices, (orders, devices) => {
  const licenseOrders = [];
  devices.forEach((device) => {
    licenseOrders.push({
      orders: orders.filter((order) => order.serialNumber === device),
      serialNumber: device,
      type: orders.find((order) => order.serialNumber === device).type,
    });
  });
  return licenseOrders;
});

export const getMobileVideoPaymentIsLoading = (state) => state.subscription.videoMobileLicense.paymentData.isFetching;

export const getMobileVideoPayment = (state) => state.subscription.videoMobileLicense.paymentData.data;

export const getVMOrderContent = createSelector(getMobileVideoPayment, (paymentData) => {
  if (
    paymentData &&
    paymentData.purchaseOrder &&
    paymentData.purchaseOrder.orderContent &&
    paymentData.purchaseOrder.orderContent[0]
  ) {
    return paymentData.purchaseOrder.orderContent[0];
  }
  return null;
});

export const getPurchasePaymentResult = (state) => state.subscription.purchasePaymentResult;

export const getMobileVideoPurchaseId = createSelector(getMobileVideoPayment, (paymentData) => {
  if (paymentData && paymentData.id) {
    return paymentData.id;
  }
  return null;
});

export const getMobileVideoPurchaseLocation = createSelector(getMobileVideoPayment, (paymentData) => {
  if (paymentData && paymentData.location) {
    return paymentData.location;
  }
  return '';
});

export const getCodeVerificationError = (state) => {
  const { error } = state.subscription.videoMobileCode;

  return {
    data: error?.data || {},
    status: error?.status || null,
  };
};

export const getIsCodeVerificationLoading = (state) => state.subscription.videoMobileCode.isFetching;

const getIsMobileVideoSubscribed = (state) => state.sites?.dashboard?.data?.services?.MOBILE_VIDEO?.subscribed;

export const getMobileVideoLicense = (state) =>
  state.sites?.dashboard?.data?.services?.MOBILE_VIDEO?.licenceModel || {};

const getIsCodeExpirationState = createSelector(
  getMobileVideoLicense,
  (mobileVideoLicense) =>
    mobileVideoLicense?.status === MOBILE_VIDEO_STATUS.STANDARD ||
    mobileVideoLicense?.status === MOBILE_VIDEO_STATUS.STANDARD_RENEWAL ||
    (mobileVideoLicense?.licencesPool > 0 &&
      (mobileVideoLicense?.status === MOBILE_VIDEO_STATUS.TEMPORARY ||
        mobileVideoLicense?.status === MOBILE_VIDEO_STATUS.STANDARD_EXPIRED))
);

export const getCodeExpirationState = createSelector(
  getIsMobileVideoSubscribed,
  getMobileVideoLicense,
  getIsCodeExpirationState,
  (subscribed, mobileVideoLicense, isExpirationState) => {
    if (isExpirationState) {
      if (subscribed && mobileVideoLicense?.nextPayment) {
        return MOBILE_VIDEO_CODE.EXPIRATION.NEXT_PAYMENT;
      }

      if (!subscribed && mobileVideoLicense.poolExpiration) {
        return MOBILE_VIDEO_CODE.EXPIRATION.POOL_EXPIRATION;
      }

      if (!subscribed && !mobileVideoLicense.poolExpiration) {
        return MOBILE_VIDEO_CODE.EXPIRATION.VALIDITY;
      }
    }

    return '';
  }
);

export const getCodeCreditState = createSelector(getMobileVideoLicense, (mobileVideoLicense) => {
  const states = [];
  if (
    mobileVideoLicense?.licencesPool === 0 &&
    [
      MOBILE_VIDEO_STATUS.TRIAL_ACTIVE,
      MOBILE_VIDEO_STATUS.TRIAL_EXPIRED,
      MOBILE_VIDEO_STATUS.TEMPORARY,
      MOBILE_VIDEO_STATUS.STANDARD_EXPIRED,
    ].includes(mobileVideoLicense?.status)
  ) {
    states.push(MOBILE_VIDEO_CODE.CREDIT.EMPTY);
  }

  if (
    [MOBILE_VIDEO_STATUS.STANDARD, MOBILE_VIDEO_STATUS.STANDARD_RENEWAL].includes(mobileVideoLicense?.status) ||
    (mobileVideoLicense?.licencesPool > 0 &&
      [MOBILE_VIDEO_STATUS.TEMPORARY, MOBILE_VIDEO_STATUS.STANDARD_EXPIRED].includes(mobileVideoLicense?.status))
  ) {
    states.push(MOBILE_VIDEO_CODE.CREDIT.REMAINING);
  }

  if (mobileVideoLicense?.licencesPool > 0) {
    states.push(MOBILE_VIDEO_CODE.CREDIT.POOL);
  }

  return states;
});

export const getCodeData = (state) => state.subscription.videoMobileCode.data;

export const getVerifiedCode = (state) => state.subscription.videoMobileCode.code;

export const getCodeActivationError = (state) => state.subscription.videoMobileCode.activation.error;

export const getIsCodeActivationLoading = (state) => state.subscription.videoMobileCode.activation.isFetching;

export const getIsVatVerified = (state) => state.subscription.vatVerification.isVerified;

export const getIsVatVerifiedFetching = (state) => state.subscription.vatVerification.isFetching;

export const getPromoCodeData = (state) => state.subscription.promoCodes.data;

export const getPromoCodesDataIsLoading = (state) => state.subscription.promoCodes.isFetching;

export const getPromoCodesOrderIsLoading = (state) => state.subscription.promoCodes.paymentData.isFetching;

export const getPromoCodesOrderData = (state) => state.subscription.promoCodes.paymentData.data;

export const getPromoCodesOrderId = (state) => state.subscription.promoCodes.paymentData.data?.id || '';

export const getPromoCodesError = (state) => !!state.subscription.promoCodes.error;
