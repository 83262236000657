import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Paper } from '../../../components';
import AccessGroupAddUsersModal from '../../../components/AccessGroupAddUsersModal';
import AccessGroupUserRemoveModal from '../../../components/AccessGroupUserRemoveModal';
import TableControlPanel from '../../../components/TableControlPanel';
import UserAccessGroupsModal from '../../../components/UserAccessGroupsModal';
import { getDataGridSelector } from '../../../helpers/dataGrid';
import { useUrlParams } from '../../../helpers/urlParams';
import { useModal } from '../../../hooks/useModal';
import actions, { NAME as ACCESS_SETTINGS_MODULE, selectors } from '../../../modules/accessSettings';
import usersActions from '../../../modules/user';
import DataGrid from './DataGrid';
import messages from './messages';

const AccessGroupUsersDataGrid = () => {
  const dispatch = useDispatch();
  const { companyId, groupId, siteId } = useUrlParams();
  const [modalUser, setModalUser] = useState(null);
  const { data, isFetching, isInvalid, usersDataGrid } = {
    data: useSelector(selectors.getAccessGroupUsers),
    isFetching: useSelector(selectors.isAccessGroupUsersFetching),
    isInvalid: useSelector(selectors.isAccessGroupUsersInvalid),
    usersDataGrid: useSelector((state) => getDataGridSelector(state, ACCESS_SETTINGS_MODULE, 'groupDetail.users')),
  };
  const { Modal: RemoveUserModal, onOpen: onOpenRemoveUserModal } = useModal({
    Modal: AccessGroupUserRemoveModal,
  });
  const { Modal: AccessGroupAddUsersUModal, onOpen: onOpenAccessGroupAddUsersUModal } = useModal({
    Modal: AccessGroupAddUsersModal,
  });

  function handleOpenRemoveUserModal(user) {
    setModalUser(user);
    onOpenRemoveUserModal();
  }

  return (
    <Paper tabContent tabTable>
      <TableControlPanel
        addButtonAction={onOpenAccessGroupAddUsersUModal}
        addButtonLabel={<FormattedMessage {...messages.accessGroupUsersDataGridAddUsers} />}
        tabToolbar
      />
      <DataGrid
        companyId={companyId}
        data={data}
        groupId={groupId}
        isFetching={isFetching}
        isInvalid={isInvalid}
        loadAccessGroupUsers={(...callbackArgs) => dispatch(actions.getAccessGroupUsers(...callbackArgs))}
        onRemove={(user) => handleOpenRemoveUserModal(user)}
        onSetAccessGroup={(user) => dispatch(usersActions.accessGroupsModalOpen(user.id))}
        siteId={siteId}
        usersDataGrid={usersDataGrid}
      />
      <RemoveUserModal
        userId={modalUser?.id}
        userName={modalUser?.firstName ? `${modalUser?.firstName} ${modalUser?.lastName}` : modalUser?.lastName}
      />
      <AccessGroupAddUsersUModal groupId={groupId} />
      <UserAccessGroupsModal groupId={groupId} />
    </Paper>
  );
};

export default AccessGroupUsersDataGrid;
