import { useSelector } from 'react-redux';
import { IError } from '../../../modules/reduxTypes';
import { selectors } from '../../../modules/sites';

export type GetSelectTypeData = {
  isSubmit: boolean;
  error: IError;
};
const useGetSelectSiteTypeData = (): GetSelectTypeData => {
  const isSubmit = useSelector(selectors.isSubmitSiteTypeModal);
  const error = useSelector(selectors.getErrorSiteTypeModal);

  return {
    error,
    isSubmit,
  };
};

export default useGetSelectSiteTypeData;
