import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SITE_TYPE } from '../../config/sites';
import FormikFields from '../FormikFields';
import messages from './messages';

const ApartmentFloorAutocomplete = ({
  floors,
  helperText,
  initFloor,
  inputName,
  inputRef,
  onChange,
  siteType,
  typedFloor,
}) => {
  const handleChangeInput = (event, newValue) => {
    let value;
    const item = floors.find((floor) => floor.name === newValue);
    if (!item) {
      value = newValue;
    } else {
      value = item.name;
    }
    return onChange({
      ...event,
      target: {
        name: inputName,
        value,
      },
    });
  };

  const handleChangeAutocompleteValue = (event, newValue) => {
    let value;
    if (!newValue) {
      value = initFloor;
    } else {
      value = newValue;
    }
    return onChange({
      ...event,
      target: {
        name: inputName,
        value,
      },
    });
  };

  return (
    <Autocomplete
      clearIcon={null}
      freeSolo
      isOptionEqualToValue={(option) => option === typedFloor}
      onChange={(event, newValue) => handleChangeAutocompleteValue(event, newValue)}
      onInputChange={(event, newValue) => handleChangeInput(event, newValue)}
      options={floors.map((fl) => fl.name)}
      popupIcon={null}
      renderInput={(params) => (
        <FormikFields.Input
          {...params}
          helperText={helperText}
          InputLabelProps={{ shrink: true }}
          inputProps={{ ...params.inputProps, value: typedFloor }}
          inputRef={inputRef}
          label={<FormattedMessage {...messages.apartmentFloorAutocompleteFloor} />}
          margin="normal"
          name={inputName}
          required={siteType === SITE_TYPE.MDU}
        />
      )}
    />
  );
};

ApartmentFloorAutocomplete.propTypes = {
  floors: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ).isRequired,
  helperText: PropTypes.node,
  initFloor: PropTypes.string,
  inputName: PropTypes.string.isRequired,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.instanceOf(Element),
    }),
  ]),
  onChange: PropTypes.func.isRequired,
  siteType: PropTypes.oneOf(Object.keys(SITE_TYPE)).isRequired,
  typedFloor: PropTypes.string.isRequired,
};

ApartmentFloorAutocomplete.defaultProps = {
  helperText: null,
  initFloor: '',
  inputRef: null,
};

export default ApartmentFloorAutocomplete;
