import { lightBlue } from '@mui/material/colors';
import RadioMUI from '@mui/material/Radio';
import React from 'react';

const Radio = (props) => (
  <RadioMUI
    color="default"
    sx={{
      '&.Mui-checked': {
        color: lightBlue.A700,
      },
      color: lightBlue.A700,
    }}
    {...props}
  />
);

export default Radio;
