import { defineMessages } from 'react-intl';

export default defineMessages({
  AAModalInstallAppClose: {
    defaultMessage: 'Close',
    description: 'User detail Mobile Video - install My2N app modal close button',
    id: 'AAmodal.installApp.close',
  },
  AAModalInstallAppTitle: {
    defaultMessage: 'Install My2N app',
    description: 'User detail Mobile Video - install My2N app modal title',
    id: 'AAmodal.installApp.title',
  },
  AAModalMobileVideoActivateCancel: {
    defaultMessage: 'Cancel',
    description: 'User detail activate Mobile Video modal - cancel button',
    id: 'AAmodal.mobileVideoActivate.cancel',
  },
  AAModalMobileVideoActivateSubmit: {
    defaultMessage: 'Activate',
    description: 'User detail activate Mobile Video modal - activate button',
    id: 'AAmodal.mobileVideoActivate.submit',
  },
  AAModalMobileVideoActivateText: {
    defaultMessage:
      'This action activates access to the My2N mobile app for this user.{br}The app let’s you see who’s entering the building and open the door remotely.{br}User will be notified via email.',
    description: 'User detail activate Mobile Video modal - text',
    id: 'AAmodal.mobileVideoActivate.text',
  },
  AAModalMobileVideoActivateTitle: {
    defaultMessage: 'Activate Mobile Video',
    description: 'User detail activate Mobile Video modal - title',
    id: 'AAmodal.mobileVideoActivate.title',
  },
  AAmodalMobileVideoActivateUwLCancel: {
    defaultMessage: 'Activate',
    id: 'AAmodal.mobileVideoActivate.UwL.cancel',
  },
  AAmodalMobileVideoActivateUwLError: {
    defaultMessage: 'There was an error, please try again',
    id: 'AAmodal.mobileVideoActivate.UwL.error',
  },
  AAmodalMobileVideoActivateUwLFirstNameLabel: {
    defaultMessage: 'First name',
    id: 'AAmodal.mobileVideoActivate.UwL.firstNameLabel',
  },
  AAmodalMobileVideoActivateUwLMailHelper: {
    defaultMessage: 'User email',
    id: 'AAmodal.mobileVideoActivate.UwL.mailHelper',
  },
  AAmodalMobileVideoActivateUwLMailLabel: {
    defaultMessage: 'User email',
    id: 'AAmodal.mobileVideoActivate.UwL.mailLabel',
  },
  AAmodalMobileVideoActivateUwLSubmit: {
    defaultMessage: 'Activate',
    id: 'AAmodal.mobileVideoActivate.UwL.submit',
  },
  AAmodalMobileVideoActivateUwLSuccess: {
    defaultMessage: 'Mobile Video service activated successfully',
    id: 'AAmodal.mobileVideoActivate.UwL.success',
  },
  AAmodalMobileVideoActivateUwLSurnameHelper: {
    defaultMessage: 'Surname',
    id: 'AAmodal.mobileVideoActivate.UwL.surnameHelper',
  },
  AAmodalMobileVideoActivateUwLSurnameLabel: {
    defaultMessage: 'Surname',
    id: 'AAmodal.mobileVideoActivate.UwL.surnameLabel',
  },
  AAmodalMobileVideoActivateUwLText: {
    defaultMessage:
      'Enter email to activate the Mobile Video service in the My2N mobile app for this user.\n' +
      'The app lets you see who’s entering the building and open the door remotely.',
    id: 'AAmodal.mobileVideoActivate.UwL.text',
  },
  AAmodalMobileVideoActivateUwLTitle: {
    defaultMessage: 'Activate Mobile Video',
    id: 'AAmodal.mobileVideoActivate.UwL.title',
  },
  AAModalMobileVideoDeactivateCancel: {
    defaultMessage: 'Cancel',
    description: 'User detail deactivate Mobile Video modal - cancel button',
    id: 'AAmodal.mobileVideoDeactivate.cancel',
  },
  AAModalMobileVideoDeactivateSubmit: {
    defaultMessage: 'Deactivate',
    description: 'User detail deactivate Mobile Video modal - deactivate button',
    id: 'AAmodal.mobileVideoDeactivate.submit',
  },
  AAModalMobileVideoDeactivateText: {
    defaultMessage: 'This action will deactivate the My2N mobile app for this user.',
    description: 'User detail deactivate Mobile Video modal - text',
    id: 'AAmodal.mobileVideoDeactivate.text',
  },
  AAModalMobileVideoDeactivateTitle: {
    defaultMessage: 'Deactivate Mobile Video',
    description: 'User detail deactivate Mobile Video modal - title',
    id: 'AAmodal.mobileVideoDeactivate.title',
  },
  AAUserDetailMobileVideoActivate: {
    defaultMessage: 'activate service',
    description: 'User detail Mobile Video devices - activate button',
    id: 'AA.userDetail.mobileVideo.activate',
  },
  AAUserDetailMobileVideoActivateMultiple: {
    defaultMessage: 'activate',
    description: 'User detail Mobile Video multiple devices - activate button',
    id: 'AA.userDetail.mobileVideo.activateMultiple',
  },
  AAUserDetailMobileVideoAppLink: {
    defaultMessage: 'install the My2N app',
    description: 'User detail Mobile Video - app link',
    id: 'AA.userDetail.mobileVideo.appLink',
  },
  AAUserDetailMobileVideoAppText: {
    defaultMessage:
      'If you haven’t already, please {link} and log in using the QR code or your My2N account login details.',
    description: 'User detail Mobile Video - app text',
    id: 'AA.userDetail.mobileVideo.appText',
  },
  AAUserDetailMobileVideoAppTitle: {
    defaultMessage: 'My2N app login',
    description: 'User detail Mobile Video - app title',
    id: 'AA.userDetail.mobileVideo.appTitle',
  },
  AAUserDetailMobileVideoDeactivate: {
    defaultMessage: 'deactivate service',
    description: 'User detail Mobile Video devices - deactivate button',
    id: 'AA.userDetail.mobileVideo.deactivate',
  },
  AAUserDetailMobileVideoDeactivateMultiple: {
    defaultMessage: 'deactivate',
    description: 'User detail Mobile Video multiple devices - deactivate button',
    id: 'AA.userDetail.mobileVideo.deactivateMultiple',
  },
  AAUserDetailMobileVideoLimitExceeded: {
    defaultMessage: 'Limit of mobile devices with Mobile Video reached',
    id: 'AA.userDetail.mobileVideo.MVlimitReached',
  },
  AAUserDetailMobileVideoMultipleAppCollapsed: {
    defaultMessage: 'My2N app login info',
    description: 'User detail Mobile Video - collapse title',
    id: 'AA.userDetail.mobileVideoMultiple.appCollapsed',
  },
  AAUserDetailMobileVideoMultipleTitle: {
    defaultMessage: 'Mobile Video on ',
    description: 'User detail Mobile Video multiple devices - title',
    id: 'AA.userDetail.mobileVideoMultiple.title',
  },
  AAUserDetailMobileVideoServiceActive: {
    defaultMessage: 'Service active',
    description: 'User detail Mobile Video service status - service active',
    id: 'AA.userDetail.mobileVideo.serviceActive',
  },
  AAUserDetailMobileVideoServiceInactive: {
    defaultMessage: 'Service inactive',
    description: 'User detail Mobile Video service status - service inactive',
    id: 'AA.userDetail.mobileVideo.serviceInactive',
  },
  AAUserDetailMobileVideoTitle: {
    defaultMessage: 'Mobile Video service',
    description: 'User detail Mobile Video - title',
    id: 'AA.userDetail.mobileVideo.title',
  },
  AAUserDetailMobileVideoUnlicensed: {
    defaultMessage: 'Mobile Video service is not available for this apartment. Contact your facility manager.',
    id: 'AA.userDetail.mobileVideo.MVnotAvailable',
  },
});
