import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import Alert from '../../../components/Alert';
import { PATHS } from '../../../config/consts';
import { useGetLoadedParams } from '../../../hooks/paramsHooks';
import { ApplicationContext } from '../../../modules/commonTypes';
import messages from './messages';

function Devices() {
  const { companyId, siteId, userId } = useGetLoadedParams(ApplicationContext.UserDetail);
  const navigate = useNavigate();

  return (
    <Paper sx={{ pb: 4, pt: 2, px: 3 }}>
      <Alert severity="info">
        <FormattedMessage
          {...messages.siteUserDetailDevicesInfoAlert}
          values={{
            link: (
              <Link
                onClick={() =>
                  navigate(
                    PATHS.SITE_USER_DETAIL.replace(':companyId', companyId.toString())
                      .replace(':siteId', siteId.toString())
                      .replace(':userId', userId.toString())
                  )
                }
                sx={{ cursor: 'pointer' }}
              >
                <FormattedMessage {...messages.siteUserDetailDevicesInfoAlertLink} />
              </Link>
            ),
          }}
        />
      </Alert>
    </Paper>
  );
}

export default Devices;
