import { defineMessages } from 'react-intl';

export default defineMessages({
  alertBoxActivateRecurringPayment: {
    defaultMessage: 'Activate recurring payment',
    description: 'Alert Box - activate recurring payment',
    id: 'alertBox.activateRecurringPayment',
  },
  alertBoxActivateService: {
    defaultMessage: 'Activate service',
    description: 'Alert Box - activate service button',
    id: 'alertBox.activateService',
  },
  alertBoxContactCustomerCare: {
    defaultMessage: 'Contact customer care',
    description: 'Alert Box - contact customer care',
    id: 'alertBox.contactCustomerCare',
  },
  alertBoxFreeDevicesSeeNewPricing: {
    defaultMessage: 'See new pricing',
    description: 'Alert Box Free Devices - see new pricing',
    id: 'alertBoxFreeDevices.seeNewPricing',
  },
  alertBoxFreeDevicesSubtitle: {
    defaultMessage: 'You only pay for mobile phones subscriptions.',
    description: 'Alert Box Free Devices - subtitle',
    id: 'alertBoxFreeDevices.subtitle',
  },
  alertBoxFreeDevicesTitle: {
    defaultMessage: 'Mobile Video on 2N devices is free',
    description: 'Alert Box Free Devices - title',
    id: 'alertBoxFreeDevices.title',
  },
  alertBoxFreeTrialExpired: {
    defaultMessage: 'Your Free trial has expired.',
    description: 'Alert Box - free trial expired',
    id: 'alertBox.freeTrialExpired',
  },
  alertBoxFreeTrialLastDay: {
    defaultMessage: 'Last day of your Free trial!',
    description: 'Alert Box - last day of free trial',
    id: 'alertBox.freeTrialLastDay',
  },
  alertBoxFreeTrialLastDays: {
    defaultMessage: 'Last {time} days of your Free trial!',
    description: 'Alert Box - last days of free trial',
    id: 'alertBox.freeTrialLastDays',
  },
  alertBoxKeepUsingDevicesForFree: {
    defaultMessage: 'You can keep using 2N devices for free',
    description: 'Alert Box - keep using 2n devices for free',
    id: 'alertBox.keepUsingDevicesForFree',
  },
  alertBoxMvNotActivatedApartment: {
    defaultMessage: "Mobile Video hasn't been activated for {count} {count, plural, =1 {apartment} other {apartments}}",
    description: 'Alert Box - mv service not activated Mdu',
    id: 'alertBox.mvNotActivatedApartment',
  },
  AlertBoxMvNotActivatedDevice: {
    defaultMessage: "Mobile Video hasn't been activated for {count} {count, plural, =1 {device} other {devices}}",
    id: 'alertBox.mvNotActivatedDevice',
  },
  alertBoxMvNotActivatedHelperText: {
    defaultMessage: `Please use your available credits to activate the service for the newly
    added devices.`,
    description: 'Alert Box - mv service not activated helper text',
    id: 'alertBox.mvNotActivatedHelperText',
  },
  alertBoxMvServiceLastDay: {
    defaultMessage: 'Last day of Mobile Video service!',
    description: 'Alert Box - mv service last day',
    id: 'alertBox.mvServiceLastDay',
  },
  alertBoxMvServiceLastDays: {
    defaultMessage: 'Last {time} days of Mobile Video service!',
    description: 'Alert Box - mv service last days',
    id: 'alertBox.mvServiceLastDays',
  },
  alertBoxMvServiceLastHour: {
    defaultMessage: 'Last hour of Mobile Video service!',
    description: 'Alert Box - mv service will last hour',
    id: 'alertBox.mvServiceLastHour',
  },
  alertBoxMvServiceLastHours: {
    defaultMessage: 'Last {time} hours of Mobile Video service!',
    description: 'Alert Box - mv service last hours',
    id: 'alertBox.mvServiceLastHours',
  },
  alertBoxMvServiceSoonTerminateDay: {
    defaultMessage: `Mobile Video service will be terminated in 1 day. Please,
    contact customer care.`,
    description: 'Alert Box - mv service will be terminate in one day',
    id: 'alertBox.mvServiceSoonTerminateDay',
  },
  alertBoxMvServiceSoonTerminateDays: {
    defaultMessage: `Mobile Video service will be terminated in {time} days. Please,
    contact customer care.`,
    description: 'Alert Box - mv service will be terminate in days',
    id: 'alertBox.mvServiceSoonTerminateDays',
  },
  alertBoxMvServiceSoonTerminateHour: {
    defaultMessage: `Mobile Video service will be terminated in 1 hour. Please,
    contact customer care.`,
    description: 'Alert Box - mv service will be terminate in one hour',
    id: 'alertBox.mvServiceSoonTerminateHour',
  },
  alertBoxMvServiceSoonTerminateHours: {
    defaultMessage: `Mobile Video service will be terminated in {time} hours. Please,
    contact customer care.`,
    description: 'Alert Box - mv service will be terminate in hours',
    id: 'alertBox.mvServiceSoonTerminateHours',
  },
  alertBoxNotVerifiedDeviceMore: {
    defaultMessage:
      '{count, plural, one {<strong>#</strong> device is not}' +
      ' other {<strong>#</strong> devices are not}} verified. Please, verify it again.',
    description: 'Alert Box - more not verified devices',
    id: 'alertBox.notVerifiedDeviceMore',
  },
  alertBoxNotVerifiedDeviceOne: {
    defaultMessage: `The device <strong>{name}</strong> is not verified. Please, verify it again.`,
    description: 'Alert Box - one not verified device',
    id: 'alertBox.notVerifiedDeviceOne',
  },
  alertBoxNotVerifiedDevicesHelperText: {
    defaultMessage: `The provided serial number or My2N Security Code is invalid. This device
    can not be used on your My2N Site.`,
    description: 'Alert Box - not verified devices helper text',
    id: 'alertBox.notVerifiedDevicesHelperText',
  },
  alertBoxNotVerifiedDeviceTwo: {
    defaultMessage: `The devices <strong>{name}</strong> are not verified. Please,
    verify it again.`,
    description: 'Alert Box - two not verified devices',
    id: 'alertBox.notVerifiedDeviceTwo',
  },
  alertBoxPlanDisabled: {
    defaultMessage: 'Your plan was disabled. Please, contact Customer Care.',
    description: 'Alert Box - plan disabled',
    id: 'alertBox.planDisabled',
  },
  alertBoxPlanExpired: {
    defaultMessage: 'Your plan expired. Please, contact Customer Care.',
    description: 'Alert Box - plan expired',
    id: 'alertBox.planExpired',
  },
  alertBoxPurchaseSubscription: {
    defaultMessage: 'Purchase subscription',
    description: 'Alert Box - purchase subscription',
    id: 'alertBox.purchaseSubscription',
  },
  alertBoxPurchaseSubscriptionHelperText: {
    defaultMessage: `You can purchase the subscription directly at My2N or you can buy a voucher
    (activation code) from your 2N distributor.`,
    description: 'Alert Box - purchase subscription helper text',
    id: 'alertBox.purchaseSubscriptionHelperText',
  },
  alertBoxRecurringPaymentHelperText: {
    defaultMessage: `You can activate a safe recurring payment using your credit card or
    you can buy a voucher (activation code) from your 2N distributor.`,
    description: 'Alert Box - recurring payment helper text',
    id: 'alertBox.recurringPaymentHelperText',
  },
  alertBoxSubscriptionExpired: {
    defaultMessage: 'Mobile Video subscription has expired!',
    description: 'Alert Box - subscription expired',
    id: 'alertBox.subscriptionExpired',
  },
  alertBoxTemporary: {
    defaultMessage: `Mobile Video subscription has expired and the service will be terminated
    in {time} hours!`,
    description: 'Alert Box - temporary',
    id: 'alertBox.temporary',
  },
  alertBoxTemporaryHelperText: {
    defaultMessage: `You have only {time} hours to purchase the subscription directly at My2N or
    you can buy a voucher (activation code) from your 2N distributor.`,
    description: 'Alert Box - temporary',
    id: 'alertBox.temporaryHelperText',
  },
  alertBoxUseAvailableCredits: {
    defaultMessage: 'Use available credits',
    description: 'Alert Box - use available credits',
    id: 'alertBox.useAvailableCredits',
  },
  alertBoxVerifyDevice: {
    defaultMessage: 'Verify device',
    description: 'Alert Box - verify device',
    id: 'alertBox.verifyDevice',
  },
  alertBoxVerifyDevices: {
    defaultMessage: 'Verify devices',
    description: 'Alert Box - verify devices',
    id: 'alertBox.verifyDevices',
  },
  linksSectionLinkAddUser: {
    defaultMessage: 'Add user',
    description: 'Links Box - add user link label',
    id: 'linksSection.link.addUser',
  },
  linksSectionLinkAnsweringUnits: {
    defaultMessage: 'Answering units',
    description: 'Links Box - answering units link label',
    id: 'linksSection.link.answeringUnits',
  },
  linksSectionLinkAssignRfidCard: {
    defaultMessage: 'Assign RFID card',
    description: 'Links Box - assign rfid card link label',
    id: 'linksSection.link.assignRfidCard',
  },
  linksSectionLinkCreateNewPin: {
    defaultMessage: 'Create new PIN',
    description: 'Links Box - create new pin link label',
    id: 'linksSection.link.createNewPin',
  },
  linksSectionLinkIntercoms: {
    defaultMessage: 'Intercoms',
    description: 'Links Box - intercoms link label',
    id: 'linksSection.link.intercoms',
  },
  linksSectionLinkPhonebooks: {
    defaultMessage: 'Phonebooks',
    description: 'Links Box - phonebook link label',
    id: 'linksSection.link.phonebooks',
  },
  linksSectionLinkPurchase: {
    defaultMessage: 'Purchase subscription',
    description: 'Links Box - purchase link label',
    id: 'linksSection.link.purchase',
  },
  linksSectionLinkUsers: {
    defaultMessage: 'See all users',
    description: 'Links Box - users link label',
    id: 'linksSection.link.users',
  },
  linksSectionLinkVoucher: {
    defaultMessage: 'Use voucher',
    description: 'Links Box - voucher link label',
    id: 'linksSection.link.voucher',
  },
  linksSectionNameCallSettings: {
    defaultMessage: 'Call settings',
    description: 'Links Box - call settings name',
    id: 'linksSection.name.callSettings',
  },
  linksSectionNameSiteUsers: {
    defaultMessage: 'Site users',
    description: 'Links Box - site users name',
    id: 'linksSection.name.siteUsers',
  },
  linksSectionNameSubscription: {
    defaultMessage: 'Subscriptions',
    description: 'Links Box - subscription name',
    id: 'linksSection.name.subscription',
  },
  noDevicesBoxAddDevice: {
    defaultMessage: 'Add new device',
    description: 'No Devices Box - add new device',
    id: 'noDevicesBox.addDevice',
  },
  noDevicesBoxDescription: {
    defaultMessage: `Open your door easily and remotely with the My2N app.`,
    description: 'No Devices Box - description',
    id: 'noDevicesBox.description',
  },
  noDevicesBoxHeaderTitle: {
    defaultMessage: 'Devices',
    description: 'No Devices Box - header title',
    id: 'noDevicesBox.headerTitle',
  },
  noDevicesBoxSeeAllDevices: {
    defaultMessage: 'See all devices',
    description: 'No Devices Box - see all devices',
    id: 'noDevicesBox.seeAllDevices',
  },
  noDevicesBoxTitle: {
    defaultMessage: 'No devices yet',
    description: 'No Devices Box - title',
    id: 'noDevicesBox.title',
  },
  siteDashboardCustom: {
    defaultMessage: 'Custom',
    description: 'Site dashboard - custom',
    id: 'siteDashboard.custom',
  },
});
