import types from './types';

export const createLicenseInvoicePayment = () => ({
  type: types.CREATE_LICENSE_INVOICE_PAYMENT_REQUEST,
});

export const invalidateCodeData = () => ({
  type: types.INVALIDATE_CODE_DATA,
});

export const invalidatePaymentStatus = () => ({
  type: types.INVALIDATE_PAYMENT_STATUS,
});

export const getSerialNumberLicenses = (serialNumber) => ({
  serialNumber,
  type: types.GET_SERIAL_NUMBER_LICENSES_REQUEST,
});

export const getPayments = () => ({
  type: types.GET_PAYMENTS_REQUEST,
});

export const setSerialNumberLicenses = (licensesData) => ({
  licensesData,
  type: types.SET_SERIAL_NUMBER_LICENSES_REQUEST,
});

export const setPayments = (paymentsData) => ({
  paymentsData,
  type: types.SET_PAYMENTS_REQUEST,
});

export const setPromoCodesPayments = (paymentsData) => ({
  paymentsData,
  type: types.SET_PROMO_CODES_ORDER_REQUEST,
});

export const deleteOrederLicense = (licenseId) => ({
  licenseId,
  type: types.DELETE_ORDER_LICENSE_REQUEST,
});

export const getMobileVideoLicenseRequest = () => ({
  type: types.GET_MOBILE_VIDEO_LICENSE_REQUEST,
});

export const useAvailableCreditRequest = () => ({
  type: types.USE_AVAILABLE_CREDIT_REQUEST,
});

export const setMobileVideoProduct = (mobileVideoData) => ({
  mobileVideoData,
  type: types.SET_MOBILE_VIDEO_PRODUCT_REQUEST,
});

export const showLicenseDetailModal = (invoice) => ({
  invoice,
  type: types.SHOW_LICENSE_DETAIL_MODAL,
});

export const hideLicenseDetailModal = () => ({ type: types.HIDE_LICENSE_DETAIL_MODAL });

export const clearSubscription = () => ({ type: types.CLEAR_SUBSCRIPTION });

export const verifyActivationCode = (code) => ({
  code,
  type: types.VERIFY_ACTIVATION_CODE_REQUEST,
});

export const activateActivationCode = () => ({
  type: types.ACTIVATE_ACTIVATION_CODE_REQUEST,
});

export const deactivateRecurringPayment = () => ({
  type: types.DEACTIVATE_RECURRING_PAYMENT_REQUEST,
});

export const getPrices = (filterMonth, filterYear) => ({
  filterMonth,
  filterYear,
  type: types.GET_MOBILE_VIDEO_LICENSE_PRICES_REQUEST,
});

export const activateMobileVideoLicence = () => ({
  type: types.ACTIVATE_MOBILE_VIDEO_LICENSE_REQUEST,
});

export const getVatVerification = () => ({
  type: types.GET_VAT_VERIFICATION_REQUEST,
});

export const getPromoCodes = () => ({
  type: types.GET_PROMO_CODES_REQUEST,
});

export const setPromoCodesOrder = (promoCodeData) => ({
  promoCodeData,
  type: types.SET_PROMO_CODES_REQUEST,
});

export const createPromoInvoicePayment = () => ({
  type: types.CREATE_PROMO_INVOICE_PAYMENT_REQUEST,
});

export const deletePromoOrderContent = (id) => ({
  id,
  type: types.DELETE_PROMO_CODES_ORDER_CONTENT_REQUEST,
});
