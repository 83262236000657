export const CARD_READER_STATE = {
  CONNECT: 'CONNECT',
  DRIVER_AVAILABLE: 'DRIVER_AVAILABLE',
  DRIVER_UNAVAILABLE: 'DRIVER_UNAVAILABLE',
  INIT: 'INIT',
  READING: 'READING',
  TEST: 'TEST',
};

export const SOCKET_ERROR = {
  CARD_ERR: 'CARD_ERR',
  CONNECTION_DEVICE_ERR: 'CONNECTION_DEVICE_ERR',
  CONNECTION_ERR: 'CONNECTION_ERR',
  DEVICE_ERR: 'DEVICE_ERR',
  DRIVER_ERR: 'DRIVER_ERR',
  READING_ERR: 'READING_ERR',
};

export const DRIVER_ADDRESS = 'wss://localhost.2n.cz:8181/';

export const DRIVER_COMMAND = {
  BEGIN: 'Begin',
  CANCEL: 'Cancel',
  INIT_OBJECT: {
    MsgType: 'InitReq',
    Params: {
      ReaderType: 'card',
      Timeout: 30,
      Version: 1,
    },
  },
};
