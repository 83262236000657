import { IRootStore } from '../store';

export const getCurrentSiteLanguage = ({ localization }: IRootStore): string | undefined =>
  localization.currentSiteLang;

export const getCurrentUserLanguage = ({ localization }: IRootStore): string => localization.currentUserLang;

export const getLandingPageSelectError = ({ localization }: IRootStore) => localization.landingPageSelect.error;

export const isLandingPageSelectFetching = ({ localization }: IRootStore): boolean =>
  localization.landingPageSelect.isFetching;

export const getUserLanguageModalError = ({ localization }: IRootStore) => localization.userLanguageModal.error;

export const isUserLanguageModalFetching = ({ localization }: IRootStore): boolean =>
  localization.userLanguageModal.isFetching;

export const isFetchingMessages = ({ localization }: IRootStore): boolean => localization.isFetchingMessages;
