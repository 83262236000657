import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { APARTMENT_STATUS } from '../../../config/consts';
import messages from './messages';

const getColor = (status) => {
  if ([APARTMENT_STATUS.ACTIVE_FOR_FREE, APARTMENT_STATUS.ACTIVE_SUBSCRIPTIONS].includes(status)) {
    return 'success';
  }
  if ([APARTMENT_STATUS.UNLICENSED].includes(status)) {
    return 'error';
  }
  return 'secondary';
};

const Status = ({ status }) => (
  <Chip
    color={getColor(status)}
    label={<FormattedMessage {...(statusMap[status] ? statusMap[status] : messages.statusOther)} />}
    size="small"
    variant="outlined"
  />
);

Status.propTypes = {
  status: PropTypes.oneOf(Object.keys(APARTMENT_STATUS)).isRequired,
};

export default Status;

const statusMap = {
  [APARTMENT_STATUS.ACTIVE_FOR_FREE]: messages.statusActiveForFree,
  [APARTMENT_STATUS.ACTIVE_SUBSCRIPTIONS]: messages.statusActiveSubscriptions,
  [APARTMENT_STATUS.NO_SERVICE]: messages.statusNoService,
  [APARTMENT_STATUS.UNLICENSED]: messages.statusUnlicensed,
};
