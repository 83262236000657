import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

function DataGridSummary({ dataLength, errorsLength, onSetViewErrors, viewErrors }) {
  return (
    <Grid alignItems="center" container sx={{ mb: 3 }}>
      <Grid item xs={8}>
        <Typography sx={{ my: 1.5 }}>
          <FormattedMessage
            {...messages.siteUsersImportCheckStepRecordsStepDescription}
            values={{
              entered: dataLength,
            }}
          />
          {errorsLength > 0 && (
            <Box component="span" sx={{ color: 'error.main' }}>
              <FormattedMessage
                {...messages.siteUsersImportCheckStepRecordsStepDescriptionErrors}
                values={{
                  errors: errorsLength,
                }}
              />
            </Box>
          )}
        </Typography>
      </Grid>
      <Grid item sx={{ textAlign: 'right' }} xs={4}>
        {errorsLength > 0 && (
          <FormControlLabel
            control={<Checkbox checked={viewErrors} color="primary" name="viewErrors" onChange={onSetViewErrors} />}
            label={
              <Typography>
                <FormattedMessage {...messages.siteUsersImportCheckStepRecordsStepViewErrors} />
              </Typography>
            }
            sx={{ mr: 0 }}
          />
        )}
      </Grid>
    </Grid>
  );
}

DataGridSummary.propTypes = {
  dataLength: PropTypes.number.isRequired,
  errorsLength: PropTypes.number.isRequired,
  onSetViewErrors: PropTypes.func.isRequired,
  viewErrors: PropTypes.bool.isRequired,
};

export default DataGridSummary;
