import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import MaterialLink from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import mVideoSvg from '../../assets/mvideo.svg';
import { PATHS, PRODUCT } from '../../config/consts';
import { PAYMENT_CONTEXT, PAYMENT_ERROR, PAYMENT_STATUS } from '../../config/payments';
import { LICENSE, PROMO } from '../../config/subscription';
import { useDataGrid } from '../../helpers/dataGrid';
import CustomDate from '../CustomDate';
import IconMaterial from '../IconMaterial';
import PriceFormat from '../PriceFormat';
import TableFooter from '../TableFooter';
import { getPaymentStatusStyles } from './helpers';
import LicenseDetailModal from './LicenseDetailModal';
import messages from './messages';
import MoreDetailsButton from './MoreDetailsButton';
import PromoCodesDetail from './PromoCodesDetail';
import PromoCodesService from './PromoCodesService';
import PromoDetailModal from './PromoDetailModal';
import styles from './styles';

// eslint-disable-next-line max-statements,complexity
const PaymentsTable = ({
  action,
  companyId,
  context,
  data,
  filter,
  isFetching,
  isPaymentDetailLoading,
  isPromoDetailModalOpen,
  loadPromoPaymentData,
  onClosePromoDetailModal,
  onDetailCompany,
  onLoadPayments,
  onOpenPromoDetailModal,
  order,
  page,
  paymentDetailData,
  promoDetailModalData,
  resultsFiltered,
  resultsTotal,
  rowsPerPage,
  showLicenseKeys,
  siteId,
  text,
  ...props
}) => {
  const { onOrderBy, onRowsPerPageChange, onSelectPage } = useDataGrid(onLoadPayments, {
    companyId,
    context,
    emptyCompany: companyId === null,
    emptySite: siteId === null,
    filter,
    order,
    page,
    rowsPerPage,
    siteId,
  });

  const [showErrorInfo, setShowErrorInfo] = useState({ errorData: [] });
  const [deviceLicenseData, setDeviceLicenseData] = useState(null);
  const [showPromoDetail, setShowPromoDetail] = useState([]);
  const togglePromoDetail = (id) => {
    const index = showPromoDetail.indexOf(id);
    const array = [...showPromoDetail];
    if (index < 0) {
      setShowPromoDetail([...array, id]);
    } else {
      array.splice(index, 1);
      setShowPromoDetail(array);
    }
  };
  const toggleModal = (licenceData = null) => setDeviceLicenseData(licenceData);
  const itemShowError = (id) => showErrorInfo.errorData.filter((el) => el === id).length > 0;
  const handleErrorInfo = (event, id) => {
    event.preventDefault();
    let { errorData } = showErrorInfo;
    if (!itemShowError(id)) {
      errorData.push(id);
    } else {
      errorData = showErrorInfo.errorData.filter((el) => el !== id);
    }
    setShowErrorInfo({ ...showErrorInfo, errorData });
  };

  const getService = (service) => (tableServiceMap[service] ? <FormattedMessage {...tableServiceMap[service]} /> : '');

  const getSubscription = (license) => {
    let type = '';
    if (license === LICENSE.MOBILE_VIDEO_MONTH) {
      type = 'monthlySubscription';
    } else if (license === LICENSE.MOBILE_VIDEO_YEAR) {
      type = 'annualSubscription';
    }
    return type !== '' ? <FormattedMessage {...paymentsTableTypeMap[type]} /> : '';
  };

  const getErrorInfo = (error) => {
    const errorStatus = paymentsTableErrorMap[error] ? error : PAYMENT_ERROR.GENERIC;
    return <FormattedMessage {...paymentsTableErrorMap[errorStatus]} />;
  };

  // eslint-disable-next-line max-statements
  const colSpan = () => {
    if (context === PAYMENT_CONTEXT.RECENT_PAYMENTS) {
      return 5;
    }
    if (context === PAYMENT_CONTEXT.COMPANY_PAYMENTS) {
      return 8;
    }
    if (context === PAYMENT_CONTEXT.SITE_PAYMENTS) {
      return 7;
    }
    if (context === PAYMENT_CONTEXT.SUPERADMIN_PAYMENTS) {
      return 9;
    }
    return 0;
  };

  const getPayments = () => (context === PAYMENT_CONTEXT.RECENT_PAYMENTS ? data.slice(0, 5) : data);

  const getNoResultsText = () => {
    if (context === PAYMENT_CONTEXT.COMPANY_PAYMENTS) {
      return <FormattedMessage {...messages.paymentsTableNoResultsCompany} />;
    }
    if (context === PAYMENT_CONTEXT.SITE_PAYMENTS) {
      return (
        <Typography>
          <FormattedMessage
            {...messages.paymentsTableNoResultsSites}
            values={{
              purchaseSubscriptionUrl: (chunks) => (
                <MaterialLink
                  component={Link}
                  to={PATHS.SUBSCRIPTION.replace(':companyId', companyId).replace(':siteId', siteId)}
                >
                  {chunks}
                </MaterialLink>
              ),
              sup: (chunks) => <sup>{chunks}</sup>,
            }}
          />
        </Typography>
      );
    }
    if (context === PAYMENT_CONTEXT.SUPERADMIN_PAYMENTS) {
      return <FormattedMessage {...messages.paymentsTableNoResultsSuperadmin} />;
    }
    return null;
  };

  if (context === PAYMENT_CONTEXT.RECENT_PAYMENTS && data.length <= 0) {
    return null;
  }

  const isOdd = (number) => !(number % 2);

  return (
    <>
      <Paper sx={styles.root}>
        {!isFetching && data.length <= 0 && resultsTotal === 0 ? (
          getNoResultsText()
        ) : (
          <Box sx={styles.tableWrapper}>
            <Table size="medium" sx={{ ...styles.table, ...props.styles }}>
              <TableHead>
                <TableRow>
                  <TableCell component="th">
                    <TableSortLabel
                      active={['dateCreated', '-dateCreated'].includes(order)}
                      direction={order === 'dateCreated' ? 'asc' : 'desc'}
                      href="#"
                      onClick={() => onOrderBy(order === 'dateCreated' ? '-dateCreated' : 'dateCreated')}
                    >
                      <FormattedMessage {...messages.paymentsTableColumnsDate} />
                    </TableSortLabel>
                  </TableCell>
                  {context !== PAYMENT_CONTEXT.RECENT_PAYMENTS && (
                    <TableCell component="th">
                      <FormattedMessage {...messages.paymentsTableColumnsInvoiceNumber} />
                    </TableCell>
                  )}
                  {context === PAYMENT_CONTEXT.SUPERADMIN_PAYMENTS && (
                    <>
                      <TableCell component="th">
                        <FormattedMessage {...messages.paymentsTableColumnsPaymentNumber} />
                      </TableCell>
                      <TableCell component="th">
                        <FormattedMessage {...messages.paymentsTableColumnsCompany} />
                      </TableCell>
                    </>
                  )}
                  <TableCell component="th">
                    <FormattedMessage {...messages.paymentsTableColumnsService} />
                  </TableCell>
                  <TableCell component="th">
                    <FormattedMessage {...messages.paymentsTableColumnsStatus} />
                  </TableCell>
                  {context !== PAYMENT_CONTEXT.RECENT_PAYMENTS && (
                    <TableCell component="th">
                      <FormattedMessage {...messages.paymentsTableColumnsPoNumber} />
                    </TableCell>
                  )}
                  <TableCell component="th">
                    <FormattedMessage {...messages.paymentsTableColumnsPrice} />
                  </TableCell>
                  {context === PAYMENT_CONTEXT.COMPANY_PAYMENTS && (
                    <TableCell component="th">
                      <FormattedMessage {...messages.paymentsTableColumnsLicenseKeys} />
                    </TableCell>
                  )}
                  {action && <TableCell component="th" />}
                </TableRow>
              </TableHead>
              <TableBody>
                {!isFetching && data.length === 0 && (
                  <TableRow sx={styles.noResults}>
                    <TableCell colSpan={colSpan()} size="small">
                      <FormattedMessage {...messages.paymentsTableNoResultsFound} />
                    </TableCell>
                  </TableRow>
                )}
                {/* eslint-disable-next-line complexity */}
                {data.length > 0 &&
                  // eslint-disable-next-line complexity
                  getPayments().map((payment, index) => (
                    <Fragment key={payment.id}>
                      <TableRow sx={isOdd(index) ? styles.rowOdd : null}>
                        <TableCell size="small">
                          <Box sx={styles.dateWrapper}>
                            <CustomDate
                              dateValue={payment.dateCreated}
                              showTime={context !== PAYMENT_CONTEXT.RECENT_PAYMENTS}
                            />
                          </Box>
                        </TableCell>
                        {context !== PAYMENT_CONTEXT.RECENT_PAYMENTS && (
                          <TableCell size="small">{payment.invoiceNumber}</TableCell>
                        )}
                        {context === PAYMENT_CONTEXT.SUPERADMIN_PAYMENTS && (
                          <>
                            <TableCell size="small">{payment.paymentNumber}</TableCell>
                            <TableCell size="small">
                              <ButtonBase
                                disableRipple
                                onClick={() => onDetailCompany(payment.companyId)}
                                sx={styles.name}
                              >
                                {payment.companyName}
                              </ButtonBase>
                              <Box component="span" sx={styles.details}>
                                {`(${payment.companyId})`}
                              </Box>
                            </TableCell>
                          </>
                        )}
                        <TableCell size="small">
                          <Grid sx={styles.service}>
                            {payment.orderContent?.[0]?.type === PRODUCT.MOBILE_VIDEO && (
                              <CardMedia alt="Mobile Video" image={mVideoSvg} sx={styles.mobileVideoImg} />
                            )}
                            <div>
                              <Box component="span" sx={styles.mVideo}>
                                {payment.orderContent?.[0]?.type === PROMO ? (
                                  <PromoCodesService orderResultsLength={payment.orderResults.length} />
                                ) : (
                                  getService(payment.orderContent?.[0]?.type)
                                )}
                              </Box>
                              {context !== PAYMENT_CONTEXT.SITE_PAYMENTS && payment.orderContent?.[0]?.site?.name && (
                                <Box component="span" sx={styles.siteName(context)}>
                                  {payment.orderContent?.[0].site.name}
                                </Box>
                              )}
                            </div>
                          </Grid>
                        </TableCell>
                        <TableCell size="small">
                          <Box
                            component="span"
                            sx={{
                              ...getPaymentStatusStyles(PAYMENT_STATUS[payment.purchaseState], styles),
                              ...styles.errorInfoIcon,
                            }}
                          >
                            <FormattedMessage {...messages[`paymentStatus${payment.purchaseState}`]} />
                            {payment.paymentResult && payment.paymentResult !== 'OK' && (
                              <ButtonBase onClick={(event) => handleErrorInfo(event, payment.id)}>
                                {itemShowError(payment.id) ? (
                                  <IconMaterial name="arrow_drop_up" />
                                ) : (
                                  <IconMaterial name="arrow_drop_down" />
                                )}
                              </ButtonBase>
                            )}
                          </Box>
                          {context === PAYMENT_CONTEXT.SUPERADMIN_PAYMENTS && (
                            <Tooltip
                              sx={styles.infoIcon}
                              title={
                                <FormattedMessage
                                  {...messages.paymentsTableStatusInfo}
                                  values={{
                                    paymentResult: payment.paymentResult,
                                    primaryCode: payment.primaryCode,
                                    secondaryCode: payment.secondaryCode,
                                  }}
                                />
                              }
                            >
                              <InfoIcon />
                            </Tooltip>
                          )}
                        </TableCell>
                        {context !== PAYMENT_CONTEXT.RECENT_PAYMENTS && (
                          <TableCell size="small">{payment.customerOrderNumber}</TableCell>
                        )}
                        <TableCell size="small">
                          <div style={{ width: 'max-content' }}>
                            <strong>
                              <PriceFormat currency={payment.currency} price={payment.price} />
                            </strong>
                            {payment.priceVat && (
                              <Box component="span" sx={styles.price}>
                                <FormattedMessage
                                  {...messages.paymentsTablePriceVat}
                                  values={{
                                    price: <PriceFormat currency={payment.currency} price={payment.priceVat} />,
                                  }}
                                />
                              </Box>
                            )}
                            <Box sx={styles.subscription}>{getSubscription(payment.orderContent?.[0]?.product)}</Box>
                          </div>
                        </TableCell>
                        {context === PAYMENT_CONTEXT.COMPANY_PAYMENTS && (
                          <TableCell size="small">
                            {payment.purchaseState === 'COMPLETED' &&
                              showLicenseKeys &&
                              payment.orderResults.length > 0 &&
                              (payment.orderContent?.[0]?.type !== PROMO ? (
                                <>
                                  <Button color="primary" onClick={() => toggleModal(payment)}>
                                    <FormattedMessage {...messages.paymentsTableDetail} />
                                  </Button>
                                </>
                              ) : (
                                <MoreDetailsButton
                                  isOpen={showPromoDetail.includes(payment.id)}
                                  onClick={() => togglePromoDetail(payment.id)}
                                />
                              ))}
                          </TableCell>
                        )}
                        {action && <TableCell size="small">{action(payment)}</TableCell>}
                      </TableRow>
                      {showPromoDetail.includes(payment.id) && (
                        <PromoCodesDetail
                          colSpan={colSpan()}
                          isOdd={isOdd(index)}
                          onOpenPromoDetail={onOpenPromoDetailModal}
                          orderResults={payment.orderResults}
                          payment={payment}
                        />
                      )}
                      {itemShowError(payment.id) && (
                        <TableRow>
                          <TableCell colSpan={colSpan()} size="small">
                            <Box
                              component="span"
                              sx={getPaymentStatusStyles(PAYMENT_STATUS[payment.purchaseState], styles)}
                            >
                              {getErrorInfo(payment.paymentResult)}
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                    </Fragment>
                  ))}
              </TableBody>
              {context !== PAYMENT_CONTEXT.RECENT_PAYMENTS && (
                <TableFooter
                  colSpan={colSpan()}
                  onRowsPerPageChange={onRowsPerPageChange}
                  onSelectPage={onSelectPage}
                  page={page}
                  resultsFiltered={resultsFiltered}
                  rowsPerPage={rowsPerPage}
                />
              )}
            </Table>
          </Box>
        )}
        {text && <Box sx={styles.bottomText}>{text}</Box>}
      </Paper>
      {deviceLicenseData && (
        <LicenseDetailModal invoice={deviceLicenseData} onClose={() => toggleModal()} show={!!deviceLicenseData} />
      )}
      <PromoDetailModal
        data={promoDetailModalData}
        isPaymentDataLoading={isPaymentDetailLoading}
        loadPaymentData={loadPromoPaymentData}
        onClose={onClosePromoDetailModal}
        paymentData={paymentDetailData}
        show={isPromoDetailModalOpen}
      />
    </>
  );
};

PaymentsTable.propTypes = {
  action: PropTypes.func,
  companyId: PropTypes.number,
  context: PropTypes.oneOf([
    PAYMENT_CONTEXT.RECENT_PAYMENTS,
    PAYMENT_CONTEXT.COMPANY_PAYMENTS,
    PAYMENT_CONTEXT.SITE_PAYMENTS,
    PAYMENT_CONTEXT.SUPERADMIN_PAYMENTS,
  ]).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  filter: PropTypes.shape({ fulltext: PropTypes.string }).isRequired,
  isFetching: PropTypes.bool.isRequired,
  isPaymentDetailLoading: PropTypes.bool,
  isPromoDetailModalOpen: PropTypes.bool,
  loadPromoPaymentData: PropTypes.func,
  onClosePromoDetailModal: PropTypes.func,
  onDetailCompany: PropTypes.func,
  onLoadPayments: PropTypes.func.isRequired,
  onOpenPromoDetailModal: PropTypes.func,
  order: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  paymentDetailData: PropTypes.shape({}),
  promoDetailModalData: PropTypes.shape({}),
  resultsFiltered: PropTypes.number.isRequired,
  resultsTotal: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  showLicenseKeys: PropTypes.bool,
  siteId: PropTypes.number,
  styles: PropTypes.shape({}),
  text: PropTypes.node,
};

PaymentsTable.defaultProps = {
  action: null,
  companyId: null,
  isPaymentDetailLoading: false,
  isPromoDetailModalOpen: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  loadPromoPaymentData: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClosePromoDetailModal: () => {},
  onDetailCompany: () => null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onOpenPromoDetailModal: () => {},
  paymentDetailData: {},
  promoDetailModalData: {},
  showLicenseKeys: false,
  siteId: null,
  styles: {},
  text: null,
};

export default PaymentsTable;

const tableServiceMap = {
  IP_INTERCOM: messages.paymentsTableServiceIP_INTERCOM,
  MOBILE_VIDEO: messages.paymentsTableServiceMOBILE_VIDEO,
};

const paymentsTableTypeMap = {
  annualSubscription: messages.paymentsTableAnnualSubscription,
  monthlySubscription: messages.paymentsTableMonthlySubscription,
};

const paymentsTableErrorMap = {
  [PAYMENT_ERROR.CARD_ERROR]: messages.paymentsTableErrorCARD_ERROR,
  [PAYMENT_ERROR.CARD_ISSUER_ERROR]: messages.paymentsTableErrorCARD_ISSUER_ERROR,
  [PAYMENT_ERROR.CREDIT_LIMIT_EXCEEDED]: messages.paymentsTableErrorCREDIT_LIMIT_EXCEEDED,
  [PAYMENT_ERROR.GATEWAY_ERROR]: messages.paymentsTableErrorGATEWAY_ERROR,
  [PAYMENT_ERROR.GENERIC]: messages.paymentsTableErrorGENERIC,
  [PAYMENT_ERROR.INVOICE_PAYMENT_ERROR]: messages.paymentsTableErrorINVOICE_PAYMENT_ERROR,
  [PAYMENT_ERROR.MY2N_ERROR]: messages.paymentsTableErrorMY2N_ERROR,
  [PAYMENT_ERROR.NOT_PROCESSED]: messages.paymentsTableErrorNOT_PROCESSED,
  [PAYMENT_ERROR.SESSION_EXPIRED]: messages.paymentsTableErrorSESSION_EXPIRED,
  [PAYMENT_ERROR.USER_ERROR]: messages.paymentsTableErrorUSER_ERROR,
};
