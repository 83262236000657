import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import TextField from '../HookFormFields/TextField';
import messages from './messages';

function GroupForm({ control }) {
  const { formatMessage } = useIntl();

  return (
    <Box
      sx={{
        bgcolor: 'background.default',
        p: 2,
      }}
    >
      <TextField
        autoFocus
        control={control}
        fullWidth
        helperText={formatMessage(messages.groupFormFieldNameHelperText)}
        label={formatMessage(messages.groupFormFieldNameLabel)}
        name="name"
        required
      />
    </Box>
  );
}

GroupForm.propTypes = {
  control: PropTypes.shape({}).isRequired,
};

export default GroupForm;
