import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import actions from '../../../modules/company';
import messages from '../messages';

export interface ICreateCompanyFormValues {
  agree: boolean;
  name: string;
}

export function useGetCreateCompanyData() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const formMethods = useForm<ICreateCompanyFormValues>({
    defaultValues: {
      agree: false,
      name: '',
    },
    mode: 'onChange',
    resolver: yupResolver(
      Yup.object().shape({
        agree: Yup.bool()
          .required(formatMessage(messages.addCompanyModalAgreeRequired))
          .oneOf([true], formatMessage(messages.addCompanyModalAgreeRequired)),
        name: Yup.string()
          .max(255, formatMessage(messages.addCompanyModalVerificationMaxLength))
          .required(formatMessage(messages.addCompanyModalVerificationRequired)),
      })
    ),
  });

  const onSubmit = (values: ICreateCompanyFormValues) => {
    dispatch(actions.addCompanyRequest(values));
  };

  return {
    formatMessage,
    formMethods,
    onSubmit,
  };
}
