import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import CustomDate from '../CustomDate';
import messages from './messages';
import { styles } from './styles';

const NextConsumption = ({ licencesPool, multiplier, requirePaymentCount, validityTo }) =>
  licencesPool > 0 && licencesPool >= requirePaymentCount * multiplier ? (
    <Typography sx={styles.text} variant="body2">
      <FormattedMessage
        values={{
          date: <CustomDate dateValue={validityTo} />,
          span: (chunks) => (
            <Box component="span" sx={styles.number}>
              {chunks}
            </Box>
          ),
          text: (chunks) => (
            <Box component="span" sx={styles.textColor}>
              {chunks}
            </Box>
          ),
        }}
        {...messages.creditPoolCardNextConsumptionDate}
      />
    </Typography>
  ) : null;

NextConsumption.propTypes = {
  licencesPool: PropTypes.number,
  multiplier: PropTypes.number,
  requirePaymentCount: PropTypes.number,
  validityTo: PropTypes.string,
};

NextConsumption.defaultProps = {
  licencesPool: null,
  multiplier: 1,
  requirePaymentCount: null,
  validityTo: null,
};

export default NextConsumption;
