import { getInitialStore, IRootStore } from './store';

export enum PersistKeys {
  ACCESS_TOKEN = 'accessToken',
  CURRENT_USER_DATA = 'currentUserData',
  CURRENT_USER_LANGUAGE = 'currentUserLanguage',
}

export function getPersistedStore(): IRootStore | undefined {
  const initialStore = getInitialStore();

  try {
    const serializedState = JSON.parse(localStorage.getItem('state')!);

    if (serializedState[PersistKeys.ACCESS_TOKEN] !== undefined) {
      initialStore.auth.accessToken = serializedState[PersistKeys.ACCESS_TOKEN];
    }

    if (serializedState[PersistKeys.CURRENT_USER_LANGUAGE] !== undefined) {
      initialStore.localization.currentUserLang = serializedState[PersistKeys.CURRENT_USER_LANGUAGE];
    }

    if (serializedState[PersistKeys.CURRENT_USER_DATA] !== undefined) {
      initialStore.user.data = serializedState[PersistKeys.CURRENT_USER_DATA];
    }
  } catch (err) {
    return undefined;
  }

  return initialStore;
}
