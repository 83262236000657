import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const styles = {
  root: {
    background: 'common.white',
    margin: '0 auto',
    px: 0,
    py: 2,
    textAlign: 'center',
    width: '100%',
  },
};

const FooterPublic = () => {
  const yearTo = new Date().getFullYear().toString();

  return (
    <Box component="footer" sx={styles.root}>
      <Typography component="span" variant="body2">
        <FormattedMessage {...messages.foooterPublicHeadline} values={{ yearTo }} />
      </Typography>
    </Box>
  );
};

export default FooterPublic;
