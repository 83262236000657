import { defineMessages } from 'react-intl';

export default defineMessages({
  phonebookSnackbarAdded: {
    defaultMessage: 'Phonebook added successfully.',
    description: 'Phonebook snackbar - Phonebook added successfully',
    id: 'phonebookSnackbarAdded',
  },
  phonebookSnackbarDeleted: {
    defaultMessage: 'Phonebook removed successfully.',
    description: 'Phonebook snackbar - Phonebook removed successfully',
    id: 'phonebookSnackbarDeleted',
  },
  phonebookSnackbarUpdated: {
    defaultMessage: 'Phonebook updated successfully.',
    description: 'Phonebook snackbar - Phonebook updated successfully',
    id: 'phonebookSnackbarUpdated',
  },
});
