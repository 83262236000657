import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

export function SuccessMessage() {
  return (
    <div>
      <Typography align="center" gutterBottom variant="h5">
        <FormattedMessage {...messages.registerSuccessTitle} />
      </Typography>
      <Box mt={6}>
        <Typography paragraph>
          <FormattedMessage {...messages.registerSuccessDescription} values={{ br: <br /> }} />
        </Typography>
      </Box>
    </div>
  );
}
