const headerHeight = 48;
const skeletonHeight = 16;

export const styles = {
  skeletonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: `calc(50% - ${headerHeight - skeletonHeight / 2}px)`,
  },
};
