import List from '@mui/material/List';
import React from 'react';
import { ISwitchItem } from './helpers';
import ListHeader from './ListHeader';
import RowItem from './RowItem';

interface IWrapperItems {
  isDeviceDetailEditing: boolean;
  isDoorStation: boolean;
  items: ISwitchItem[];
  onChange: (index: number, name: string, value: string | boolean, codeWarn?: boolean) => void;
  setDeviceEditing: (isEditing: boolean) => void;
}

const WrapperItems = ({ isDeviceDetailEditing, isDoorStation, items, onChange, setDeviceEditing }: IWrapperItems) => (
  <List disablePadding>
    <ListHeader isDoorStation={isDoorStation} />
    {items.map((item: ISwitchItem) => (
      <RowItem
        data={item}
        isDeviceDetailEditing={isDeviceDetailEditing}
        isDoorStation={isDoorStation}
        items={items}
        key={item.index}
        onChange={onChange}
        setDeviceEditing={setDeviceEditing}
      />
    ))}
  </List>
);

export default WrapperItems;
