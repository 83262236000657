import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import FormikFields from '../FormikFields';
import Loader from '../Loader';
import getUsers from './helpers/getUsers';
import messages from './messages';
import UserSelectFieldOption from './UserSelectFieldOption';

/**
 * @usage Use only inside the Formik component
 */
const UserSelectField = ({
  currentUser,
  disableCreateNewUser,
  existUser,
  helperText,
  label,
  onChange,
  onSurNameBlur,
  users,
  ...props
}) => {
  const refEmail = useRef(null);

  const onChangeUserExist = (e) => {
    onChange({
      target: {
        name: 'existUser',
        value: e.target.value !== -1,
      },
    });
    onChange(e);
  };

  useEffect(() => {
    if (existUser === false && refEmail.current !== null) {
      refEmail.current.focus();
    }
  }, [existUser]);

  return (
    <>
      {users.length > 0 ? (
        <FormikFields.Select
          component={UserSelectFieldOption}
          helperText={
            helperText !== null ? helperText : <FormattedMessage {...messages.userSelectFieldUserHelperText} />
          }
          label={label !== null ? label : <FormattedMessage {...messages.userSelectFieldUserLabel} />}
          name="user"
          onChange={onChangeUserExist}
          options={getUsers(users, currentUser, !disableCreateNewUser)}
          required
          {...props}
        />
      ) : (
        <Loader />
      )}
      {!existUser && !disableCreateNewUser && (
        <Box
          sx={{
            bgcolor: 'background.default',
            pb: 0,
            pt: 2,
            px: 2,
          }}
        >
          <FormGroup row>
            <FormikFields.Input
              helperText={<FormattedMessage {...messages.userSelectFieldNewUserEmailHelperText} />}
              inputRef={refEmail}
              label={<FormattedMessage {...messages.userSelectFieldNewUserEmailLabel} />}
              name="newUser.email"
              required
            />
          </FormGroup>
          <FormGroup row>
            <Grid container spacing={2}>
              <Grid item xs>
                <FormikFields.Input
                  label={<FormattedMessage {...messages.userSelectFieldNewUserFirstName} />}
                  name="newUser.firstName"
                  required
                />
              </Grid>
              <Grid item xs>
                <FormikFields.Input
                  label={<FormattedMessage {...messages.userSelectFieldNewUserLastName} />}
                  name="newUser.lastName"
                  onBlur={onSurNameBlur}
                  required
                />
              </Grid>
            </Grid>
          </FormGroup>
        </Box>
      )}
    </>
  );
};

UserSelectField.propTypes = {
  currentUser: PropTypes.shape({
    firstName: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lastName: PropTypes.string,
  }).isRequired,
  disableCreateNewUser: PropTypes.bool,
  existUser: PropTypes.bool,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func.isRequired,
  onSurNameBlur: PropTypes.func,
  users: PropTypes.arrayOf(
    PropTypes.shape({
      firstName: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      lastName: PropTypes.string,
    })
  ).isRequired,
};

UserSelectField.defaultProps = {
  disableCreateNewUser: false,
  existUser: true,
  helperText: null,
  label: null,
  onSurNameBlur: null,
};

export default UserSelectField;
