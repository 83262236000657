export const styles = {
  dateIntervalsButtonsGrid: {
    '& button': {
      fontSize: '14px',
      lineHeight: 1.5,
      minWidth: 'auto',
      textTransform: 'none',
    },
    '& hr': {
      height: 3,
      width: 2,
    },
    alignItems: 'center',
    paddingBottom: 1,
  },
  datePickerField: {
    '& input': {
      fontSize: '14px',
      padding: 1.75,
    },
    marginTop: 1,
  },
  datePickersGrid: {
    '& > div': {
      marginBottom: 0,
      marginTop: 0,
    },
    '& fieldset': {
      border: '2px solid',
      borderColor: 'grey.300',
      borderRadius: '4px',
    },
    alignContent: 'center',
    width: '45%',
  },
  formLabel: {
    fontSize: '14px',
    fontWeight: '600',
  },
  root: {
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
  },
};
