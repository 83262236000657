import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import mvideoSvg from '../../assets/mvideo.svg';
import MobileVideoCardStatus from './MobileVideoCardStatus';

const styles = {
  imageWrapper: {
    '& img': {
      height: '48px',
      width: '48px',
    },
    mr: 2,
  },
  root: {
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
};

const StatusInfo = ({ devices, licenceModel, mvService }) => (
  <Grid container item sx={styles.root}>
    <Grid item sx={styles.imageWrapper}>
      <img alt="2N Mobile Video" src={mvideoSvg} />
    </Grid>
    <Grid item sx={{ mb: { sm: 'inherit', xs: 2 } }}>
      <Typography sx={{ lineHeight: 1.43, mb: { sm: 1, xs: 'inherit' } }} variant="h6">
        <MobileVideoCardStatus devices={devices} licenceModel={licenceModel} mvService={mvService} />
      </Typography>
    </Grid>
  </Grid>
);
StatusInfo.propTypes = {
  devices: PropTypes.arrayOf(PropTypes.object).isRequired,
  licenceModel: PropTypes.shape({
    additional: PropTypes.number,
    licencesPool: PropTypes.number,
    status: PropTypes.string,
    unlicensedCount: PropTypes.number,
    validityTo: PropTypes.string,
  }).isRequired,
  mvService: PropTypes.shape({
    subscribed: PropTypes.bool,
  }).isRequired,
};

export default StatusInfo;
