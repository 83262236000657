import CheckIcon from '@mui/icons-material/Check';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useMigrateUserWithDeviceActivationMutation } from '../../../../../apiRtk/be4feApi';
import ActionModal from '../../../../../components/_DesignSystemWrappers/Modal/ActionModal';
import { OutlinedTextField } from '../../../../../components/HookFormFields/TextField';
import { ApartmentUser } from '../../../../../modules/apartmentAdmin/data';
import { IModalBase, TId } from '../../../../../modules/commonTypes';
import { TYPE_DESCRIPTOR } from '../../../../../modules/notifications';
import { addSnackbarError, addSnackbarSuccess } from '../../../../../modules/notifications/actions';
import { getUsersInApartmentsUserDetailPath } from '../../../../../routes/helpers/paths';
import messages from '../messages';
import { IActivateMVForUWLFormData, useActivateMVForUWL } from './useActivateMVForUWL';

interface IActivateMVForUWLModal extends IModalBase {
  apartmentId: TId;
  companyId: TId;
  siteId: TId;
  user: ApartmentUser;
}

function ActivateMVForUWLModal({ apartmentId, companyId, onClose, open, siteId, user }: IActivateMVForUWLModal) {
  const [migrateUserWithDeviceActivation, { isLoading }] = useMigrateUserWithDeviceActivationMutation();
  const { dispatch, formatMessage, formMethods, navigate, upSm } = useActivateMVForUWL(
    apartmentId,
    companyId,
    siteId,
    user
  );

  async function handleSubmit({ email, firstName, lastName }: IActivateMVForUWLFormData): Promise<void> {
    await migrateUserWithDeviceActivation({
      apartmentId,
      companyId,
      siteId,
      userId: user.id,
      userNameWithEmail: {
        email,
        firstName,
        lastName,
      },
    })
      .unwrap()
      .then((response) => {
        if (user.id !== response.user.id) {
          navigate(getUsersInApartmentsUserDetailPath({ apartmentId, companyId, siteId, userId: response.user.id }));
        }
        dispatch(addSnackbarSuccess({ ...messages.AAmodalMobileVideoActivateUwLSuccess }));
      })
      .catch(() => {
        dispatch(addSnackbarError({ ...messages.AAmodalMobileVideoActivateUwLError }, TYPE_DESCRIPTOR));
      });

    onClose();
  }

  return (
    <ActionModal
      icon={<CheckIcon />}
      isPrimaryButtonDisabled={!formMethods.formState.isValid || isLoading}
      muiDialogProps={{ maxWidth: 'xs' }}
      onClose={onClose}
      open={open}
      primaryButtonAction={formMethods.handleSubmit(handleSubmit)}
      primaryButtonText={formatMessage(messages.AAmodalMobileVideoActivateUwLSubmit)}
      secondaryButtonText={formatMessage(messages.AAmodalMobileVideoActivateUwLCancel)}
      title={formatMessage(messages.AAmodalMobileVideoActivateUwLTitle)}
    >
      <Typography component="div" sx={{ mt: 2 }} variant="body1">
        <FormattedMessage {...messages.AAmodalMobileVideoActivateUwLText} />
      </Typography>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
          <Stack spacing={2} sx={{ my: 3 }}>
            <OutlinedTextField
              fullWidth
              helperText={formatMessage(messages.AAmodalMobileVideoActivateUwLMailHelper)}
              label={formatMessage(messages.AAmodalMobileVideoActivateUwLMailLabel)}
              name="email"
              required
            />
            <Stack direction={upSm ? 'row' : 'column'} spacing={2}>
              <OutlinedTextField
                label={formatMessage(messages.AAmodalMobileVideoActivateUwLFirstNameLabel)}
                name="firstName"
                required
              />
              <OutlinedTextField
                helperText={formatMessage(messages.AAmodalMobileVideoActivateUwLSurnameHelper)}
                label={formatMessage(messages.AAmodalMobileVideoActivateUwLSurnameLabel)}
                name="lastName"
                required
              />
            </Stack>
          </Stack>
        </form>
      </FormProvider>
    </ActionModal>
  );
}

export default ActivateMVForUWLModal;
