import AddIcon from '@mui/icons-material/Add';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { TMultipleUsers } from '../../api/apartmentApiTS';
import { addMultipleApartmentUsers } from '../../modules/apartments/actions';
import { ActionModal } from '../_DesignSystemWrappers/Modal';
import Checkbox from '../HookFormFields/Checkbox';
import TextField from '../HookFormFields/TextField';
import getMultilineUsersArray from './helpers/getMultilineUsersArray';
import { useGetGlobalHooks } from './hooks/useGetGlobalHooks';
import messages from './messages';

interface IApartmentAddMultipleUsersModal {
  onClose: () => void;
  open: boolean;
}

export interface IFormProps {
  pin: boolean;
  users: string;
}

function ApartmentAddMultipleUsersModal({ onClose, open }: IApartmentAddMultipleUsersModal) {
  const { apartmentId, companyId, dispatch, formatMessage, formMethods, handleSubmit, isDirty, isValid, siteId } =
    useGetGlobalHooks();

  function handleOnSubmit({ pin, users }: IFormProps) {
    let usersNames: TMultipleUsers = [];
    usersNames = getMultilineUsersArray(users).map((item) => ({ lastName: item }));

    if (companyId !== undefined && siteId !== undefined && apartmentId !== undefined) {
      dispatch(addMultipleApartmentUsers(companyId, siteId, apartmentId, { generatePin: pin, users: usersNames }));
    }
    onClose();
  }

  return (
    <ActionModal
      icon={<AddIcon />}
      isPrimaryButtonDisabled={!isDirty || !isValid}
      muiDialogProps={{ maxWidth: 'xs' }}
      onClose={onClose}
      open={open}
      primaryButtonAction={handleSubmit(handleOnSubmit)}
      primaryButtonText={formatMessage(messages.apartmentAddMultipleUsersModalCreateButton)}
      secondaryButtonText={formatMessage(messages.apartmentAddMultipleUsersModalCancelButton)}
      title={formatMessage(messages.apartmentAddMultipleUsersModalTitle)}
    >
      <form noValidate onSubmit={handleSubmit(handleOnSubmit)}>
        <FormProvider {...formMethods}>
          <Typography component="div" variant="body1">
            <FormattedMessage
              values={{ p: (chunks) => <p>{chunks}</p>, strong: (chunks) => <strong>{chunks}</strong> }}
              {...messages.apartmentAddMultipleUsersModalText}
            />
          </Typography>
          <TextField
            autoFocus
            fullWidth
            helperText={formatMessage(messages.apartmentAddMultipleUsersModalInputHelperText)}
            label={formatMessage(messages.apartmentAddMultipleUsersModalInputLabel)}
            multiline
            name="users"
            placeholder={formatMessage(messages.apartmentAddMultipleUsersModalInputPlaceholder)}
            rows={4}
          />
          <FormGroup>
            <Checkbox name="pin" label={formatMessage(messages.apartmentAddMultipleUsersModalCheckbox)} />
          </FormGroup>
        </FormProvider>
      </form>
    </ActionModal>
  );
}

export default ApartmentAddMultipleUsersModal;
