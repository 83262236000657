import DeactivateIcon from '@mui/icons-material/CancelOutlined';
import ActivateLogIcon from '@mui/icons-material/PlayCircleOutline';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import RowMenuItem from '../../../../components/DataGrid/RowMenuItem';
import messages from './messages';

const LogActivation = ({ isLogActive, onClick }) => (
  <RowMenuItem
    icon={isLogActive ? <DeactivateIcon /> : <ActivateLogIcon />}
    label={
      <FormattedMessage
        {...(isLogActive
          ? messages.accessGroupDevicesDataGridDataGridRowMenuLogDeactivate
          : messages.accessGroupDevicesDataGridDataGridRowMenuLogActivate)}
      />
    }
    onClick={onClick}
  />
);

LogActivation.propTypes = {
  isLogActive: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default LogActivation;
