import { IButtonRenderData } from '../../../../containers/DeviceDetail/CallSettings/Intercom/hooks/useGetStateData';
import { IApartment } from '../../../apartments/store';
import * as consts from '../../../constants';
import { IRequestButtonsFeature, IRequestContact, IRequestContactsFeature } from '../../saveActions';
import {
  ContactType,
  DeviceFeatureType,
  IDevice,
  isContactListConfigurationFeature,
  isDisplayConfiguration,
} from '../../store/deviceStore';
import { createNewContact, createUpdatedContact } from './callSettingsContactCreator';
import { CallSettingsDataManager } from './callSettingsDataManager';
import { ExternalIdGenerator } from './externalIdGenerator';

export function getRequestDataForFeature(
  callSettingsDataManager: CallSettingsDataManager,
  updatedCallSettings: IButtonRenderData[],
  device: IDevice,
  featureType: DeviceFeatureType,
  copyExternalIds: boolean,
  externalIdGenerator: ExternalIdGenerator,
  availableApartments: IApartment[]
): IRequestContactsFeature | IRequestButtonsFeature {
  const contacts: IRequestContact[] = [];

  updatedCallSettings.forEach((buttonData) => {
    const contactType = featureType === consts.display ? consts.display : '';

    if (buttonData.selectedOptions === undefined || buttonData.selectedOptions.length === 0) {
      if (buttonData.externalId === undefined || buttonData.index !== 0) {
        return;
      }

      if (doesOriginalContactExist(device, 'RINGING_GROUP', buttonData.externalId)) {
        contacts.push(
          createUpdatedContact(callSettingsDataManager, featureType, buttonData, availableApartments, true, contactType)
        );
      } else {
        contacts.push(
          createNewContact(
            callSettingsDataManager,
            featureType,
            copyExternalIds,
            externalIdGenerator,
            buttonData,
            true,
            contactType,
            availableApartments
          )
        );
      }
    } else {
      const isDevice = buttonData.selectedOptions[0].optionKind === 'Devices';

      if (buttonData.externalId !== undefined && !didSwapDestinationTypes(device, buttonData)) {
        contacts.push(
          createUpdatedContact(
            callSettingsDataManager,
            featureType,
            buttonData,
            availableApartments,
            isDevice,
            contactType
          )
        );
      } else {
        contacts.push(
          createNewContact(
            callSettingsDataManager,
            featureType,
            copyExternalIds,
            externalIdGenerator,
            buttonData,
            isDevice,
            contactType,
            availableApartments
          )
        );
      }
    }
  });

  const featureId = getFeatureId(device, featureType)!;

  if (featureType === consts.contactList) {
    return createContactListFeatureRequestObject(featureId, featureType, contacts);
  }
  return createButtonsAndDisplayFeatureRequestObject(featureId, featureType, contacts);
}

function didSwapDestinationTypes(device: IDevice, currentButtonData: IButtonRenderData): boolean {
  if (currentButtonData.selectedOptions?.[0].optionKind === 'Devices') {
    return doesOriginalContactExist(device, 'APARTMENT', currentButtonData.externalId);
  }

  return doesOriginalContactExist(device, 'RINGING_GROUP', currentButtonData.externalId);
}

function doesOriginalContactExist(device: IDevice, contactType: ContactType, externalId?: string): boolean {
  let found = false;
  device.features?.forEach((feature) => {
    if (isContactListConfigurationFeature(feature)) {
      feature.contacts?.forEach((contact) => {
        contact.contactItems?.forEach((contactItem) => {
          if (contactItem.type === contactType && contact.externalId === externalId) {
            found = true;
          }
        });
      });
    }
  });

  return found;
}

function getFeatureId(device: IDevice, featureType: DeviceFeatureType): number | undefined {
  let featureId;
  device.features?.forEach((feature) => {
    if (feature.type === featureType) {
      featureId = feature.id;
    }
  });
  return featureId;
}

export function getDisplayName(device: IDevice): string | undefined {
  let name;

  device.features?.forEach((feature) => {
    if (isDisplayConfiguration(feature)) {
      name = feature.name;
    }
  });

  return name;
}

function createContactListFeatureRequestObject(
  featureId: number,
  featureType: DeviceFeatureType,
  contacts: IRequestContact[]
): IRequestContactsFeature {
  return {
    contacts,
    id: featureId,
    type: featureType,
  };
}

function createButtonsAndDisplayFeatureRequestObject(
  featureId: number,
  featureType: DeviceFeatureType,
  buttons: IRequestContact[]
): IRequestButtonsFeature {
  return {
    buttons,
    id: featureId,
    type: featureType,
  };
}
