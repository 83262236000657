export const apartment = 'APARTMENT';
export const contactList = 'CONTACT_LIST';
export const buttonConfiguration = 'BUTTON_CONFIGURATION';
export const display = 'DISPLAY';

export const defaultDeviceButtonLabel = 'Button';

export const update = 'UPDATE';
export const create = 'CREATE';

export const createRingingGroup = 'CREATE_RINGING_GROUP';
export const createApartment = 'CREATE_APARTMENT';
export const ringingGroup = 'RINGING_GROUP';
export const updateRingingGroup = 'UPDATE_RINGING_GROUP';
export const updateApartment = 'UPDATE_APARTMENT';

export type ContactItemAction =
  | 'CREATE_RINGING_GROUP'
  | 'CREATE_APARTMENT'
  | 'UPDATE_RINGING_GROUP'
  | 'UPDATE_APARTMENT'
  | 'CREATE_LOCAL_CALLS'
  | 'CREATE_GSM_CALLS';

export const localCallsType = 'LOCAL_CALLS';
export const localCallsAction = 'CREATE_LOCAL_CALLS';

export const gsmCallsType = 'GSM_CALLS';
export const gsmCallsAction = 'CREATE_GSM_CALLS';

export const contactListAction = '_INTERCOM_CONTACT';
export const buttonConfigAction = '_BUTTON_CONFIGURATION_BUTTON';
export const displayAction = '_DISPLAY_BUTTON';
