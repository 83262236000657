import Link from '@mui/material/Link';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import HouseIcon from '../../assets/sitetype_house.svg';
import ApartmentIcon from '../../assets/sitetype_mdu.svg';
import OtherIcon from '../../assets/sitetype_other.svg';
import { SERVICE } from '../../config/devices';
import messages from './messages';

export const types = {
  MDU: 'MDU',
  OTHER: 'OTHER',
  SINGLE_VILLA: 'SINGLE_VILLA',
};

export const getItems = (company, site) => {
  const apartmentDeviceLimit = site?.apartmentDeviceLimit || '';
  const mobileVideoService = site?.services?.[SERVICE.MOBILE_VIDEO] || {};
  const ringingGroupSizeLimit = mobileVideoService?.ringingGroupSizeLimit || '';
  const deviceLimit = mobileVideoService?.deviceLimit || '';

  return [
    {
      description: <FormattedMessage {...messages.selectSiteTypeItemsSingleVillaDescription} />,
      icon: HouseIcon,
      info: {
        administration: [
          <FormattedMessage key={0} {...messages.selectSiteTypeItemsSingleVillaAdministrationItem1} />,
          <FormattedMessage key={1} {...messages.selectSiteTypeItemsSingleVillaAdministrationItem2} />,
        ],
        limits: [
          <FormattedMessage key={0} {...messages.selectSiteTypeItemsSingleVillaLimitsItem1} />,
          <FormattedMessage
            key={1}
            values={{ ringingGroupSizeLimit }}
            {...messages.selectSiteTypeItemsSingleVillaLimitsItem2}
          />,
        ],
        paymentModel: [
          <FormattedMessage key={0} {...messages.selectSiteTypeItemsSingleVillaPaymentModelItem1} />,
          <FormattedMessage key={1} {...messages.selectSiteTypeItemsSingleVillaPaymentModelItem2} />,
        ],
      },
      title: <FormattedMessage {...messages.selectSiteTypeItemsSingleVillaTitle} />,
      value: types.SINGLE_VILLA,
    },
    {
      description: <FormattedMessage {...messages.selectSiteTypeItemsMduDescription} />,
      icon: ApartmentIcon,
      info: {
        administration: [
          <FormattedMessage key={0} {...messages.selectSiteTypeItemsMduAdministrationItem1} />,
          <FormattedMessage key={1} {...messages.selectSiteTypeItemsMduAdministrationItem2} />,
        ],
        limits: [
          <FormattedMessage
            key={0}
            values={{ apartmentDeviceLimit }}
            {...messages.selectSiteTypeItemsMduLimitsItem1}
          />,
          <FormattedMessage key={1} {...messages.selectSiteTypeItemsMduLimitsItem2} />,
        ],
        paymentModel: [
          <FormattedMessage key={0} {...messages.selectSiteTypeItemsMduPaymentModelItem1} />,
          <FormattedMessage key={1} {...messages.selectSiteTypeItemsMduPaymentModelItem2} />,
        ],
      },
      title: <FormattedMessage {...messages.selectSiteTypeItemsMduTitle} />,
      value: types.MDU,
    },
    {
      description: <FormattedMessage {...messages.selectSiteTypeItemsOtherDescription} />,
      icon: OtherIcon,
      info: {
        administration: [
          <FormattedMessage key={0} {...messages.selectSiteTypeItemsOtherAdministrationItem1} />,
          <FormattedMessage key={1} {...messages.selectSiteTypeItemsOtherAdministrationItem2} />,
        ],
        limits: [
          <FormattedMessage key={0} values={{ deviceLimit }} {...messages.selectSiteTypeItemsOtherLimitsItem1} />,
          <FormattedMessage
            key={1}
            values={{
              url: (chunks) => (
                <Link
                  href={
                    `mailto:sales@2n.com?subject=Raise the phone limit for site ${site.name}` +
                    '&body=Hello, I need to increase the limit of smartphones for' +
                    ` site ${site.name} to [requested number of phones].`
                  }
                >
                  {chunks}
                </Link>
              ),
            }}
            {...messages.selectSiteTypeItemsOtherLimitsItem2}
          />,
        ],
        paymentModel: [
          <FormattedMessage key={0} {...messages.selectSiteTypeItemsOtherPaymentModelItem1} />,
          <FormattedMessage key={1} {...messages.selectSiteTypeItemsOtherPaymentModelItem2} />,
        ],
      },
      title: <FormattedMessage {...messages.selectSiteTypeItemsOtherTitle} />,
      value: types.OTHER,
    },
  ];
};
