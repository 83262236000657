import { defineMessages } from 'react-intl';

export default defineMessages({
  device2NIconLoading: {
    defaultMessage: 'Device recognition in progress',
    id: 'device2NIcon.loading',
  },
  device2NIconNotBeAdded: {
    defaultMessage: "We couldn't add your device",
    id: 'device2NIcon.notBeAdded',
  },
  device2NIconNoTypeMessage: {
    defaultMessage:
      'We check that the serial number and security code match to prevent' + ' unauthorized use of your device.',
    id: 'device2NIcon.noTypeMessage',
  },
});
