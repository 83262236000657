import authTypes from '../auth/types';
import types from './types';

export const initialState = {
  data: {},
  deleteModal: {
    company: {},
    show: false,
  },
  deviceTypes: {
    data: null,
    isFetching: false,
  },
  error: undefined,
  isDidInvalid: false,
  isFetching: false,
  mvSdk: {
    data: null,
    error: null,
    isDefault: true,
    isFetching: false,
  },
};

const reducers = {
  [types.LOAD_COMPANY_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
  }),

  [types.LOAD_COMPANY_SUCCESS]: (state, { payload }) => ({
    ...initialState,
    data: payload,
  }),

  [types.LOAD_COMPANY_FAILURE]: (state, { error }) => ({
    ...state,
    error,
    isFetching: false,
  }),

  [types.DELETE_COMPANY_SHOW_MODAL]: (state, { company }) => ({
    ...state,
    deleteModal: {
      company,
      show: true,
    },
  }),

  [types.DELETE_COMPANY_HIDE_MODAL]: (state) => ({
    ...state,
    deleteModal: {
      ...state.deleteModal,
      show: false,
    },
  }),

  [types.DELETE_COMPANY_SUCCESS]: (state) => ({
    ...state,
    deleteModal: initialState.deleteModal,
    isDidInvalid: true,
  }),

  [types.ADD_COMPANY_ROLE_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: {
      ...state.data,
      roles: {
        ...state.data.roles,
        [payload.type]: [payload.id],
      },
    },
    isFetching: false,
  }),

  [types.ADD_COMPANY_ROLE_FAILURE]: (state, { error }) => ({
    ...state,
    error,
    isFetching: false,
  }),

  [types.DELETE_COMPANY_ROLE_SUCCESS]: (state, { role }) => {
    const updatedRoles = state.data.roles;
    delete updatedRoles[role];
    return {
      ...state,
      data: {
        ...state.data,
        roles: updatedRoles,
      },
      isFetching: false,
    };
  },

  [types.CHANGE_SETTINGS_SUCCESS]: (state, { payload }) => ({
    ...initialState,
    data: payload,
  }),

  [types.GET_MV_SDK_SETTINGS_REQUEST]: (state) => ({
    ...state,
    mvSdk: {
      ...initialState.mvSdk,
      isFetching: true,
    },
  }),

  [types.GET_MV_SDK_SETTINGS_SUCCESS]: (state, { payload }) => ({
    ...state,
    mvSdk: {
      data: payload,
      isDefault: false,
      isFetching: false,
    },
  }),

  [types.GET_MV_SDK_SETTINGS_FAILURE]: (state, { error }) => ({
    ...state,
    mvSdk: {
      ...initialState.mvSdk,
      error,
    },
  }),

  [types.UPDATE_MV_SDK_SETTINGS_SUCCESS]: (state, { payload }) => ({
    ...state,
    mvSdk: {
      ...state.mvSdk,
      data: payload,
      isDefault: false,
    },
  }),

  [types.UPDATE_MV_SDK_SETTINGS_FAILURE]: (state, { error }) => ({
    ...state,
    mvSdk: {
      ...state.mvSdk,
      error,
      isFetching: false,
    },
  }),

  [types.RESET_MV_SDK_SETTINGS_SUCCESS]: (state) => ({
    ...state,
    mvSdk: initialState.mvSdk,
  }),

  [types.RESET_MV_SDK_SETTINGS_FAILURE]: (state, { error }) => ({
    ...state,
    mvSdk: {
      ...state.mvSdk,
      error,
      isFetching: false,
    },
  }),

  [types.GET_COMPANY_DEVICE_TYPES_REQUEST]: (state) => ({
    ...state,
    deviceTypes: {
      ...state.deviceTypes,
      isFetching: true,
    },
  }),

  [types.GET_COMPANY_DEVICE_TYPES_SUCCESS]: (state, { payload }) => ({
    ...state,
    deviceTypes: {
      data: payload,
      isFetching: false,
    },
  }),

  [types.GET_COMPANY_DEVICE_TYPES_FAILURE]: (state) => ({
    ...state,
    deviceTypes: {
      ...initialState.deviceTypes,
    },
  }),

  [types.CLEAR_COMPANY]: (state) => ({
    ...state,
    data: initialState.data,
    isDidInvalid: true,
  }),
};

const reducer = (state = initialState, action = {}) => {
  if (reducers[action.type]) {
    return reducers[action.type](state, action);
  }
  return state;
};

export default reducer;
