export enum DeviceTypeEnum {
  ACCESS_UNIT = 'ACCESS_UNIT',
  ACCESS_UNIT_M = 'ACCESS_UNIT_M',
  CHAMELEON = 'CHAMELEON',
  CLIP = 'CLIP',
  DOOR_STATION = 'DOOR_STATION',
  HIPMO = 'HIPMO',
  ID_COMPACT = 'ID_COMPACT',
  ID_TALK = 'ID_TALK',
  ID_VIEW = 'ID_VIEW',
  IDT = 'IDT',
  IP_INTERCOM = 'IP_INTERCOM',
  IP_INTERCOM_V2 = 'IP_INTERCOM_V2',
  IP_ONE_INTERCOM = 'IP_ONE_INTERCOM',
  IP_STYLE = 'IP_STYLE',
  THIRD_PARTY = 'THIRD_PARTY',
}
