import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import actions, { selectors as devicesSelectors } from '../../modules/devices';
import { getDeleteDeviceContext } from '../../modules/devices/helpers/deleteDeviceHelpers';
import ButtonDanger from '../ButtonDanger';
import Modal from '../Modal';
import messages from './messages';

const styles = {
  deleteButton: {
    '&:hover': {
      color: 'secondary.main',
    },
    color: 'action.disabled',
  },
  list: {
    typography: 'body1',
  },
  text: {
    mb: 1.5,
  },
};

function DeleteDeviceModal({ deviceId, onClose, onConfirm, open, processing }) {
  const location = useLocation();
  const context = getDeleteDeviceContext(location);

  if (!open) return <>{false}</>;

  return (
    <Modal
      actions={
        <>
          <Button onClick={onClose}>
            <FormattedMessage {...messages.deleteDeviceCancelButton} />
          </Button>
          <ButtonDanger disabled={processing} onClick={() => onConfirm(deviceId, context)} startIcon={<DeleteIcon />}>
            <FormattedMessage {...messages.deleteDeviceConfirmButton} />
          </ButtonDanger>
        </>
      }
      headerVariant="danger"
      maxWidth="sm"
      name="delete-device"
      onClose={onClose}
      open
      title={<FormattedMessage {...messages.deleteDeviceTitle} />}
    >
      <Typography paragraph sx={styles.text}>
        <FormattedMessage {...messages.deleteDeviceTextRow1} />
      </Typography>
      <Typography paragraph sx={styles.text}>
        <FormattedMessage {...messages.deleteDeviceTextRow2} />
      </Typography>
      <Box component="ul" sx={styles.list}>
        <Typography {...messages.deleteDeviceTextList1} component={FormattedMessage} tagName="li" />
        <Typography {...messages.deleteDeviceTextList2} component={FormattedMessage} tagName="li" />
        <Typography {...messages.deleteDeviceTextList3} component={FormattedMessage} tagName="li" />
      </Box>
      <Typography paragraph sx={styles.text}>
        <FormattedMessage {...messages.deleteDeviceTextRow3} />
      </Typography>
    </Modal>
  );
}

DeleteDeviceModal.propTypes = {
  deviceId: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  processing: PropTypes.bool.isRequired,
};

DeleteDeviceModal.defaultProps = {
  deviceId: null,
};

const mapStateToProps = (state) => ({
  deviceId: devicesSelectors.getDeleteModalDeviceId(state),
  open: devicesSelectors.getDeleteModalShow(state),
  processing: devicesSelectors.getDeleteModalProcessing(state),
});

const mapDispatchToProps = {
  onClose: actions.deleteDeviceModalHide,
  onConfirm: actions.deleteDevice,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeleteDeviceModal);
