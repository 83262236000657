import { Alert, Box, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

export function InitialWarning() {
  return (
    <Box px={3} py={2}>
      <Typography variant="body1" pb={2}>
        <FormattedMessage {...messages.configureMy2NAccessModal1Text1} />
      </Typography>
      <Alert severity="warning">
        <FormattedMessage {...messages.configureMy2NAccessModal1Warn} />
      </Alert>
      <Typography variant="body1" pt={2}>
        <FormattedMessage {...messages.configureMy2NAccessModal1Text2} />
      </Typography>
    </Box>
  );
}
