import { defineMessages } from 'react-intl';

export default defineMessages({
  actionQrInfo: {
    defaultMessage: 'See info',
    description: 'Device List Table action - qr info',
    id: 'userProfileDevicesDataGrid.actionQrInfo',
  },
  columnsApartment: {
    defaultMessage: 'Apartment',
    description: 'Device List Table columns - apartment',
    id: 'userProfileDevicesDataGrid.columnsApartment',
  },
  columnsDeviceId: {
    defaultMessage: 'Device ID',
    description: 'Device List Table columns - device Id',
    id: 'userProfileDevicesDataGrid.columnsDeviceId',
  },
  columnsDeviceNameDesktop: {
    defaultMessage: 'Device name',
    description: 'Device List Table columns - device name for desktop',
    id: 'userProfileDevicesDataGrid.columnsDeviceNameDesktop',
  },
  columnsDeviceNameMobile: {
    defaultMessage: 'Device',
    description: 'Device List Table columns - device name for mobile',
    id: 'userProfileDevicesDataGrid.columnsDeviceNameMobile',
  },
  columnsDeviceStatusDesktop: {
    defaultMessage: 'Device status',
    description: 'Device List Table columns - device status for desktop',
    id: 'userProfileDevicesDataGrid.columnsDeviceStatusDesktop',
  },
  columnsDeviceStatusMobile: {
    defaultMessage: 'Status',
    description: 'Device List Table columns - device status for mobile',
    id: 'userProfileDevicesDataGrid.columnsDeviceStatusMobile',
  },
  columnsDeviceType: {
    defaultMessage: 'Type',
    description: 'Device List Table columns - device type',
    id: 'userProfileDevicesDataGrid.columnsDeviceType',
  },
  columnsLoginAfterMigration: {
    defaultMessage: 'My2N app login',
    description: 'Device List Table columns - login info',
    id: 'userProfileDevicesDataGrid.columnsLoginAfterMigration',
  },
  columnsLoginInfo: {
    defaultMessage: 'Login info',
    description: 'Device List Table columns - login info',
    id: 'userProfileDevicesDataGrid.columnsLoginInfo',
  },
  columnsPassword: {
    defaultMessage: 'Password',
    description: 'Device List Table columns - password',
    id: 'userProfileDevicesDataGrid.columnsPassword',
  },
  columnsSite: {
    defaultMessage: 'Site',
    description: 'Device List Table columns - site',
    id: 'userProfileDevicesDataGrid.columnsSite',
  },
  noRowsHeaderText: {
    defaultMessage: 'No devices yet',
    description: 'Device List Table no rows - header text',
    id: 'userProfileDevicesDataGrid.noRowsHeaderText',
  },
  noRowsText: {
    defaultMessage: 'Devices assigned to this user will appear here',
    description: 'Device List Table no rows - text',
    id: 'userProfileDevicesDataGrid.noRowsText',
  },
  qrDialogAfterMigrationDescription: {
    defaultMessage: 'Use the QR code to log in to the My2N mobile application.',
    description: 'QR dialog description',
    id: 'qrDialog.afterMigration.description',
  },
  qrDialogAfterMigrationTitle: {
    defaultMessage: 'My2N mobile app login',
    description: 'QR dialog title',
    id: 'qrDialog.afterMigration.title',
  },
  qrDialogBeforeMigrationDescription: {
    defaultMessage: 'Use the QR code to link this device with the My2N app.',
    description: 'QR dialog description',
    id: 'qrDialog.beforeMigration.description',
  },
  qrDialogBeforeMigrationTitle: {
    defaultMessage: 'Log in to My2N mobile app',
    description: 'QR dialog title',
    id: 'qrDialog.beforeMigration.title',
  },
  qrDialogButton: {
    defaultMessage: 'Close',
    description: 'QR dialog ok button',
    id: 'qrDialog.button',
  },
  qrDialogDeviceId: {
    defaultMessage: 'Device ID',
    description: 'QR dialog deviceId',
    id: 'qrDialog.deviceId',
  },
  qrDialogDeviceName: {
    defaultMessage: '<strong>Device name:</strong> {deviceName}',
    description: 'QR dialog device name',
    id: 'qrDialog.deviceName',
  },
  qrDialogDevicePassword: {
    defaultMessage: 'Device password',
    description: 'QR dialog devicePassword',
    id: 'qrDialog.devicePassword',
  },
});
