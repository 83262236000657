import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ReactSVG } from 'react-svg';
import Radio from '../Radio';

const styles = {
  active: {
    '&:hover': {
      cursor: 'default',
    },
    backgroundColor: 'background.default',
    borderColor: 'primary.main',
    color: 'primary.main',
  },
  root: {
    '& .wrapperSvg': {
      '& svg': {
        width: '72px',
      },
      display: 'block',
      width: '72px',
    },
    '&:hover': {
      borderColor: 'primary.main',
      color: 'primary.main',
      cursor: 'pointer',
    },
    border: 1,
    borderColor: 'secondary.light',
    color: 'secondary.main',
    mx: 0,
    my: 1,
    padding: 1,
    width: '100%',
  },
  wrapper: {
    flex: 1,
    px: 3,
    py: 0,
  },
};

const SiteTypeItem = ({ currentValue, description, icon, name, onChange, title, value }) => {
  const [hover, setHover] = useState(false);
  const checked = currentValue === value;
  const onClickHandler = () => {
    if (!checked) {
      onChange(name, value);
    }
  };

  return (
    <Box
      alignItems="flex-start"
      display="flex"
      onClick={onClickHandler}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      sx={{
        ...styles.root,
        ...(checked && styles.active),
      }}
    >
      <ReactSVG className="wrapperSvg" src={icon} wrapper="div" />
      <Box alignSelf="baseline" display="flex" flexDirection="column" sx={styles.wrapper}>
        <Typography gutterBottom variant="subtitle1">
          {title}
        </Typography>
        <Typography variant="body2">{description}</Typography>
      </Box>
      <Box>
        <Radio checked={checked} disabled={!hover} onChange={onClickHandler} />
      </Box>
    </Box>
  );
};

SiteTypeItem.propTypes = {
  currentValue: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  icon: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  value: PropTypes.string.isRequired,
};

SiteTypeItem.defaultProps = {
  currentValue: null,
};

export default SiteTypeItem;
