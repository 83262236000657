import { UseFormReturn } from 'react-hook-form';
import { IntlFormatters } from 'react-intl';
import { Dispatch } from 'redux';
import { useFinishRegistrationMutation } from '../../../../apiRtk/legacyFeApi';
import { setFirstLogin, setPreferUserLogin } from '../../../../modules/auth/actions';
import { getCurrentUser } from '../../../../modules/user/actions';
import { IUseLoginApi } from '../../../../ory/hooks/oryApi';
import { IUseOryResponseParser } from '../../../../ory/hooks/oryResponseParser';
import { handleFinishRegistrationErrors, RegistrationError } from './utils';
import { IActivateAccountFormData } from './validationScheme';

export function getOnFormSubmit({
  dispatch,
  email,
  finishRegistration,
  formatMessage,
  formData,
  loginToOry,
  operation,
  parseOryResponse,
  preferUserLogin,
  registrationId,
  setIsRegistrationFinished,
}: {
  finishRegistration: ReturnType<typeof useFinishRegistrationMutation>[0];
  operation: string;
  registrationId: string;
  email: string;
  preferUserLogin: boolean;
  loginToOry: IUseLoginApi['loginToOry'];
  setIsRegistrationFinished: React.Dispatch<React.SetStateAction<boolean>>;
  dispatch: Dispatch;
  parseOryResponse: IUseOryResponseParser['parseOryResponse'];
  formatMessage: IntlFormatters['formatMessage'];
  formData: UseFormReturn<IActivateAccountFormData, any, undefined>;
}): (values: IActivateAccountFormData) => Promise<void> {
  return async function (values: IActivateAccountFormData) {
    try {
      await finishRegistration({
        body: { operation: operation!, password: values.password },
        id: registrationId!,
      }).unwrap();

      //TODO HIPCS-22198: After finish registration we need to login user probably via some shared function from login page and when login will be finished redirect to login page

      const response = await loginToOry({
        email: email!,
        password: values.password,
        sessionRefresh: true,
      });

      parseOryResponse({
        onSuccess: async () => {
          if (preferUserLogin) {
            dispatch(setPreferUserLogin({ preferUserLogin: preferUserLogin }));
          }
          dispatch(setFirstLogin(true));
          setIsRegistrationFinished(true);
          dispatch(getCurrentUser());
        },
        response,
      });
    } catch (error) {
      handleFinishRegistrationErrors({
        dispatch,
        error: error as RegistrationError,
        formatMessage,
        formData,
        parseOryResponse,
      });
      return;
    }
  };
}
