import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link, matchPath, matchRoutes, useLocation, useParams } from 'react-router-dom';
import { useUrlParams } from '../../helpers/urlParams';
import { useGetLoadedParams } from '../../hooks/paramsHooks';
import { isLocationInActivePaths } from './pathLocationMatcher';

const SidebarItem = ({
  activePaths,
  disabled,
  icon,
  link,
  showOnlyTitle,
  showSelected,
  showTitleOnHover,
  title,
  ...other
}) => {
  const { formatMessage } = useIntl();
  const location = useLocation();
  const params = useGetLoadedParams();

  return (
    <ListItem
      {...other}
      button
      component={Link}
      dense
      disabled={disabled}
      disableGutters
      selected={showSelected && isLocationInActivePaths(activePaths, location)}
      sx={{ px: 2, py: 0 }}
      title={
        title?.props?.id !== undefined && showTitleOnHover
          ? formatMessage({ defaultMessage: title.props.defaultMessage, id: title.props.id })
          : null
      }
      to={link}
    >
      {!showOnlyTitle && <ListItemIcon sx={{ pr: 2 }}>{icon}</ListItemIcon>}
      <ListItemText primary={title} />
    </ListItem>
  );
};

SidebarItem.propTypes = {
  activePaths: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  icon: PropTypes.element,
  link: PropTypes.string.isRequired,
  showOnlyTitle: PropTypes.bool,
  showSelected: PropTypes.bool,
  showTitleOnHover: PropTypes.bool,
  title: PropTypes.element.isRequired,
};

SidebarItem.defaultProps = {
  activePaths: [],
  disabled: false,
  icon: null,
  showOnlyTitle: false,
  showSelected: true,
  showTitleOnHover: true,
};

export default SidebarItem;
