import { Box } from '@mui/material';
import React from 'react';
import { conditionalArray } from '../../helpers/array';
import useBreakpoints from '../../helpers/useBreakpoints';
import { UserCredentials } from '../../modules/commonTypes';
import ApartmentNameChip from '../ApartmentNameChip';
import UserAccessSettingsStatus from '../UserAccessSettingsStatus';
import { IApartmentsTitleData, IUserAccessTitleData } from './data';
import { getApartmentInfoLeftPadding } from './utils';

export interface IApartmentAndAccessSettingsInfoProps {
  hasUserInfoBackButton: boolean;
  apartments?: IApartmentsTitleData;
  isApartmentChipLinkDisabled?: boolean;
  userAccessDetails: IUserAccessTitleData;
}

export function ApartmentAndAccessSettingsInfo(props: IApartmentAndAccessSettingsInfoProps) {
  const { downSm } = useBreakpoints();

  return (
    <Box
      pl={getApartmentInfoLeftPadding(downSm, props.hasUserInfoBackButton)}
      sx={{ alignItems: 'center', display: 'inline-flex' }}
    >
      {props.apartments !== undefined && (
        <Box pr={2}>
          <ApartmentNameChip
            count={props.apartments.count}
            name={props.apartments.name}
            number={props.apartments.number}
            size="default"
            link={props.isApartmentChipLinkDisabled ? false : true}
            id={props.apartments.id}
          />
        </Box>
      )}
      <UserAccessSettingsStatus
        hiddenStatuses={props.userAccessDetails.hiddenStatuses}
        credentials={conditionalArray([
          !!props.userAccessDetails.hasPin && UserCredentials.PIN,
          !!props.userAccessDetails.hasRfid && UserCredentials.RFID,
          !!props.userAccessDetails.hasBt && UserCredentials.BT,
        ])}
      />
    </Box>
  );
}
