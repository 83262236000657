import FolderShared from '@mui/icons-material/FolderShared';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Title } from '../../../components';
import { IApartmentDetail } from '../../../modules/apartments/store';
import { TId } from '../../../modules/commonTypes';
import messages from '../messages';
import DeleteApartment from './DeleteApartment';

interface IApartmentHeader {
  apartment: IApartmentDetail;
  onOpenDeleteApartmentModal: (id: TId) => void;
}

const ApartmentHeader = ({ apartment, onOpenDeleteApartmentModal }: IApartmentHeader) => (
  <Title
    heading={
      <Stack sx={{ alignItems: 'center', flexFlow: 'row wrap', gap: 2 }}>
        <Avatar sx={{ backgroundColor: 'info.light' }}>
          <FolderShared color="secondary" />
        </Avatar>
        <Box color="primary.main" component={Typography} fontWeight={700} variant="h4">
          {apartment.number}
        </Box>
        {apartment.name && (
          <Box component={Typography} variant="h4">
            {apartment.name}
          </Box>
        )}
        {apartment.floor?.name && (
          <Chip
            label={
              <FormattedMessage
                values={{
                  floorName: (
                    <Box color="primary.main" component="span" fontWeight={700}>
                      {apartment?.floor?.name}
                    </Box>
                  ),
                }}
                {...messages.apartmentHeaderFloor}
              />
            }
            sx={{ backgroundColor: 'info.light' }}
          />
        )}
      </Stack>
    }
    rightSideActions={<DeleteApartment apartment={apartment} onOpenDeleteApartmentModal={onOpenDeleteApartmentModal} />}
  />
);

export default ApartmentHeader;
