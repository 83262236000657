import CheckIcon from '@mui/icons-material/Check';
import { FormControlLabel } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useGetCreditCostUntilEndOfMonthQuery } from '../../../../apiRtk/be4feApi';
import actions from '../../../../modules/apartments';
import { IApartmentDetail, IFloor } from '../../../../modules/apartments/store';
import { IModalBase, TId } from '../../../../modules/commonTypes';
import { ActionModal } from '../../../_DesignSystemWrappers/Modal';
import { editApartmentValues } from '../helpers';
import messages from './messages';

interface IActivePaidServicesModalProps extends IModalBase {
  apartmentValue: IApartmentDetail;
  companyId: TId;
  siteId: TId;
  floors: Array<IFloor>;
}

function ActivatePaidServicesModal({
  apartmentValue,
  companyId,
  floors,
  onClose,
  open,
  siteId,
}: IActivePaidServicesModalProps) {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const countData = useGetCreditCostUntilEndOfMonthQuery({
    companyId: companyId,
    licencesCount: 1,
    siteId: siteId,
  });

  const onChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDontShowAgain(e.target.checked);
  };

  const onSubmit = () => {
    if (dontShowAgain) {
      sessionStorage.setItem('paidModal', 'dontShowPaidServiceModalAgain');
    }
    dispatch(
      actions.editApartment(companyId, siteId, apartmentValue, floors, editApartmentValues(true, apartmentValue))
    );
    onClose();
  };

  return (
    <ActionModal
      icon={<CheckIcon />}
      open={open}
      isPrimaryButtonDisabled={false}
      muiDialogProps={{ maxWidth: 'xs' }}
      primaryButtonAction={onSubmit}
      primaryButtonText={formatMessage(messages.ActivatePaidServicesModalSubmit)}
      secondaryButtonText={formatMessage(messages.ActivatePaidServicesModalCancel)}
      onClose={onClose}
      title={formatMessage(messages.ActivatePaidServicesModalTitle)}
      testId="active-paid-services-modal"
    >
      <FormattedMessage
        {...messages.ActivatePaidServicesModalText}
        values={{
          apartment: apartmentValue.name ? `${apartmentValue.number} — ${apartmentValue.name}` : apartmentValue.number,
          br: <br />,
          credits: countData?.data?.creditsNeeded.toFixed(1),
        }}
      />
      <FormControlLabel
        control={<Checkbox name="dont-show-again-checkbox" onChange={onChecked} />}
        label={formatMessage(messages.ActivatePaidServicesModalCheckbox)}
      />
    </ActionModal>
  );
}

export default ActivatePaidServicesModal;
