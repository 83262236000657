import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { batchPropTypes } from '../../types';
import NumberOfSubscriptions from './Fields/NumberOfSubscriptions';
import NumberOfYears from './Fields/NumberOfYears';
import TotalPrice from './Fields/TotalPrice';
import TotalValue from './Fields/TotalValue';
import VoucherName from './Fields/VoucherName';

const RowTwo = ({
  batch,
  currency,
  isSiteMdu,
  maxSubscriptions,
  maxYears,
  minSubscriptions,
  minYears,
  name,
  unitFullPrice,
}) => (
  <Grid container item justifyContent={{ lg: 'flex-start', sm: 'between-space' }} spacing={3}>
    <Grid item lg={3} sm={4} xs={12}>
      <VoucherName name={name} />
    </Grid>
    <Grid item lg={2} sm={3} xs={6}>
      <NumberOfYears maxYears={maxYears} minYears={minYears} name={name} />
    </Grid>
    <Grid item lg={2} sm={5} xs={6}>
      <NumberOfSubscriptions
        isSiteMdu={isSiteMdu}
        maxSubscriptions={maxSubscriptions}
        minSubscriptions={minSubscriptions}
        name={name}
        subscriptionType={batch.subscriptionType}
      />
    </Grid>
    <Grid
      item
      lg={0}
      sm={4}
      sx={{
        display: {
          lg: 'none',
          md: 'block',
          sm: 'block',
          xs: 'none',
        },
      }}
      xs={0}
    >
      <span>{''}</span>
    </Grid>
    <Grid item lg={3} sm={4} xs={6}>
      <TotalValue batch={batch} isSiteMdu={isSiteMdu} />
    </Grid>
    <Grid item lg={2} sm={4} xs={6}>
      <TotalPrice batch={batch} currency={currency} unitFullPrice={unitFullPrice} />
    </Grid>
  </Grid>
);

RowTwo.propTypes = {
  batch: batchPropTypes.isRequired,
  currency: PropTypes.string.isRequired,
  isSiteMdu: PropTypes.bool.isRequired,
  maxSubscriptions: PropTypes.number.isRequired,
  maxYears: PropTypes.number.isRequired,
  minSubscriptions: PropTypes.number.isRequired,
  minYears: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  unitFullPrice: PropTypes.number.isRequired,
};

export default RowTwo;
