import { defineMessages } from 'react-intl';

export default defineMessages({
  callSettingsHeaderLocalCallsCompact: {
    defaultMessage:
      'Assign devices to the display of this device. Only devices located on' +
      ' the same local area network can be reached from this device, as it is configured' +
      ' for Local connection mode.',
    description: 'Call settings header text',
    id: 'callSettingsHeader.localCalls.compact',
  },
  callSettingsHeaderLocalCallsTalk: {
    defaultMessage:
      'Assign devices to the buttons of this device. Only devices located on' +
      ' the same local area network can be reached from this device, as it is configured' +
      ' for Local connection mode.',
    description: 'Talk local settings warning',
    id: 'callSettingsHeader.localCalls.talk',
  },
  callSettingsHeaderMobileVideoCompact: {
    defaultMessage: 'Assign devices to the display of this device.',
    description: 'Compact mobile video warning',
    id: 'callSettingsHeader.mobileVideo.compact',
  },
  callSettingsHeaderMobileVideoTalk: {
    defaultMessage: 'Assign devices to the buttons of this device.',
    description: 'Talk mobile video warning',
    id: 'callSettingsHeader.mobileVideo.talk',
  },
  callSettingsListActionsButtonSave: {
    defaultMessage: 'Save',
    description: 'Save button label',
    id: 'callSettingsListActions.button.save',
  },
  connectionWarningAlertWarning: {
    defaultMessage:
      'Some devices cannot be set because they are configured for Cloud connection' +
      ' mode and this device ({deviceName}) is configured for Local connection mode.',
    description: 'Connection alert text',
    id: 'connectionWarningAlert.text',
  },
  settingsWarningLocalCallsCompact: {
    defaultMessage:
      'These call settings will NOT be uploaded into the device, as the' +
      ' Automatic configuration mode is turned off for this device. Please configure' +
      ' Directory in your device manually.',
    description: 'Compact local settings warning',
    id: 'settingsWarning.localCalls.compact',
  },
  settingsWarningLocalCallsTalk: {
    defaultMessage:
      'These call settings will NOT be uploaded into the device, as the Automatic' +
      ' configuration mode is turned off for this device. Please configure Directory and' +
      ' Buttons in your device manually.',
    description: 'Talk local settings warning',
    id: 'settingsWarning.localCalls.talk',
  },
  settingsWarningMobileVideoCompact: {
    defaultMessage:
      'These call settings will NOT be uploaded into the device, as the' +
      ' Automatic configuration mode is turned off for this device. The settings below will' +
      ' affect only the configuration of the SIP proxy server. There will be a Ring group' +
      ' created on the SIP proxy server as to represent the group of devices assigned to the' +
      ' specific button. Please configure Directory in your device manually using Ring Group' +
      ' numbers below.',
    description: 'Compact mobile video warning',
    id: 'settingsWarning.mobileVideo.compact',
  },
  settingsWarningMobileVideoTalk: {
    defaultMessage:
      'These call settings will NOT be uploaded into the device, as the' +
      ' Automatic configuration mode is turned off for this device. The settings below' +
      ' will affect only the configuration of the SIP proxy server. There will be a Ring' +
      ' group created on the SIP proxy server as to represent the group of devices' +
      ' assigned to the specific button. Please configure Directory and Buttons in your' +
      ' device manually using Ring Group numbers below.',
    description: 'Talk mobile video warning',
    id: 'settingsWarning.mobileVideo.talk',
  },
  unlicensedDevicesWarningAlertWarning: {
    defaultMessage: 'Some devices cannot be called because they are unlicensed.',
    description: 'Unlicensed devices alert text',
    id: 'unlicensedDevicesWarningAlert.text',
  },
});
