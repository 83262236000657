import { Alert } from '@mui/material';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from './messages';

export function LoginAttemptsAlert ({throttlingRetryAfter}: { throttlingRetryAfter: Date} ) {
  const { formatTime }= useIntl();
  const time = formatTime(throttlingRetryAfter)

  return (
    <Alert severity="error">
      <FormattedMessage {...messages.AuthLoginAlertTooManyAttempts}
          values={{ count: time }}
      />
    </Alert>
 )
}
