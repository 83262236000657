import { defineMessages } from 'react-intl';

export default defineMessages({
  mvSdkSettingsAppleApn: {
    defaultMessage: 'Apple APN',
    description: 'MV SDK Settings - apple apn',
    id: 'mvSdkSettings.appleApn',
  },
  mvSdkSettingsAuthKey: {
    defaultMessage: 'Auth key',
    description: 'MV SDK Settings - auth key',
    id: 'mvSdkSettings.authKey',
  },
  mvSdkSettingsBundleId: {
    defaultMessage: 'Bundle Id',
    description: 'MV SDK Settings - bundle id',
    id: 'mvSdkSettings.bundleId',
  },
  mvSdkSettingsDatabaseName: {
    defaultMessage: 'Database name',
    description: 'MV SDK Settings - database name',
    id: 'mvSdkSettings.databaseName',
  },
  mvSdkSettingsDefaultValues: {
    defaultMessage: `Push notification server currently uses default values of the My2N app.`,
    description: 'MV SDK Settings - default values message',
    id: 'mvSdkSettings.defaultValues',
  },
  mvSdkSettingsGoogleFcm: {
    defaultMessage: 'Google FCM',
    description: 'MV SDK Settings - google fcm',
    id: 'mvSdkSettings.googleFcm',
  },
  mvSdkSettingsHiddenValue: {
    defaultMessage: 'Value is hidden. You must fill it in again for editing.',
    description: 'MV SDK Settings - hidden value',
    id: 'mvSdkSettings.hiddenValue',
  },
  mvSdkSettingsInvalidFormat: {
    defaultMessage: 'Value is not valid JSON object.',
    description: 'MV SDK Settings - invalid format value',
    id: 'mvSdkSettings.invalidFormat',
  },
  mvSdkSettingsKeyId: {
    defaultMessage: 'Key Id',
    description: 'MV SDK Settings - key id',
    id: 'mvSdkSettings.keyId',
  },
  mvSdkSettingsRequired: {
    defaultMessage: 'Please fill in this value.',
    description: 'MV SDK Settings - required value',
    id: 'mvSdkSettings.required',
  },
  mvSdkSettingsResetToDefault: {
    defaultMessage: 'Reset to default',
    description: 'MV SDK Settings - reset to default button',
    id: 'mvSdkSettings.resetToDefault',
  },
  mvSdkSettingsSave: {
    defaultMessage: 'Save',
    description: 'MV SDK Settings - save button',
    id: 'mvSdkSettings.save',
  },
  mvSdkSettingsServiceJson: {
    defaultMessage: 'Service JSON',
    description: 'MV SDK Settings - service json',
    id: 'mvSdkSettings.serviceJson',
  },
  mvSdkSettingsSetCustom: {
    defaultMessage: 'Set custom',
    description: 'MV SDK Settings - set custom button',
    id: 'mvSdkSettings.setCustom',
  },
  mvSdkSettingsTeamId: {
    defaultMessage: 'Team Id',
    description: 'MV SDK Settings - team id',
    id: 'mvSdkSettings.teamId',
  },
  mvSdkSettingsTitle: {
    defaultMessage: 'Mobile Video SDK settings',
    description: 'MV SDK Settings - title',
    id: 'mvSdkSettings.title',
  },
  resetSettingsDialogCancel: {
    defaultMessage: 'Cancel',
    description: 'ResetSettingsDialog - cancel button',
    id: 'resetSettingsDialog.cancel',
  },
  resetSettingsDialogConfirm: {
    defaultMessage: 'Clear settings',
    description: 'ResetSettingsDialog - confirm button',
    id: 'resetSettingsDialog.confirm',
  },
  resetSettingsDialogContent: {
    defaultMessage: 'You will clear your Mobile Video SDK settings.',
    description: 'ResetSettingsDialog - content',
    id: 'resetSettingsDialog.content',
  },
  resetSettingsDialogTitle: {
    defaultMessage: 'Clear custom MV SDK settings',
    description: 'ResetSettingsDialog - body',
    id: 'resetSettingsDialog.title',
  },
});
