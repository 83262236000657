import { IButtonItem } from '../../../containers/DeviceDetail/CallSettings/Intercom/hooks/useGetStateData';
import { IApartment } from '../../apartments/store';
import {
  IButtonAndDisplayContact,
  IContactListContact,
  IDevice,
  isApartmentContactItem,
  isRingingGroupContactItem,
} from '../store/deviceStore';

export interface IParsedButtonsData {
  button: IButtonAndDisplayContact;
  contact: IContactListContact;
  display: IButtonAndDisplayContact;
}

export function getIntercomButtonItems(
  buttons: IParsedButtonsData[],
  devices: IDevice[],
  apartments: IApartment[]
): IButtonItem[] {
  const items: IButtonItem[] = [];
  if (buttons) {
    buttons.forEach((item, index) => {
      let selectedOptionsIds = [];
      let ringingGroup;
      let selectedOptions: IDevice[] | IApartment | undefined;
      const contactItem = item.button?.contacts?.[0]?.contactItems?.find(
        (button) => button.type === 'RINGING_GROUP' || button.type === 'APARTMENT'
      );
      if (isRingingGroupContactItem(contactItem)) {
        selectedOptionsIds = contactItem.members;
        ringingGroup = contactItem.sipNumber;
        selectedOptions = getSelectedDevicesMobileVideoIds(devices, selectedOptionsIds);
      } else if (isApartmentContactItem(contactItem)) {
        selectedOptionsIds.push(contactItem.apartmentId);
        ringingGroup = contactItem.sipNumber;
        selectedOptions = apartments.find((apartment) => apartment.id === contactItem.apartmentId);
      }

      items.push({
        button: item.button,
        contact: item.contact,
        display: item.display,
        index,
        name: item.button?.name || '',
        ringingGroup,
        selectedOptions: selectedOptions ?? [],
      });
    });
  }
  return items;
}

function getSelectedDevicesMobileVideoIds(devices: IDevice[], id: number | number[]): IDevice[] | undefined {
  let list: number[] = [];
  if (id && Array.isArray(id)) {
    list = id;
  } else if (id) {
    list.push(id as number);
  }

  return devices.filter((device) => device?.services?.MOBILE_VIDEO && list.includes(device.services.MOBILE_VIDEO.id));
}
