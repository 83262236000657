import { InfoBar, InfoBarType, InfoBarVariant } from '@2n/design-system';
import React from 'react';

export interface IInfoBarData {
  children: React.ReactNode;
}

export function FilledInfoBar(data: IInfoBarData) {
  return (
    <InfoBar
      variant={InfoBarVariant.filled}
      type={InfoBarType.info}
      sx={{
        backgroundColor: 'subscription.backgroundLight',
        color: 'secondary.main',
        my: 2,
      }}
    >
      {data.children}
    </InfoBar>
  );
}
