import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FE_ROLES } from '../../config/consts';
import { useAppContext } from '../../pages/App/AppContextProvider';
import { IconBox, UserIcon } from '../index';
import messages from './messages';
import UserRoleInfo from './UserRoleInfo';

const UserRoleSelect = ({ companyName, defaultUserRole, onChange, userRole }) => {
  const { feSiteRoles } = useAppContext();
  const selectRef = useRef();
  const { formatMessage } = useIntl();
  const handleChange = (event) => {
    onChange(event.target.value);
  };

  const isEditedCompanyAdmin = userRole === FE_ROLES.COMPANY_ADMIN;

  return (
    <>
      <Select
        fullWidth
        input={<TextField inputRef={selectRef} label={formatMessage(messages.userRoleSelectLabel)} select />}
        onChange={handleChange}
        renderValue={(selected) => formatMessage(userRoleMap[selected])}
        value={userRole}
        variant="outlined"
      >
        <MenuItem disabled={isEditedCompanyAdmin} value={FE_ROLES.SITE_USER}>
          <IconBox icon={<UserIcon color="secondary" userRole={FE_ROLES.SITE_USER} />}>
            <FormattedMessage {...messages.userRoleSelectItemSiteUser} />
          </IconBox>
        </MenuItem>
        <MenuItem disabled={isEditedCompanyAdmin} value={FE_ROLES.LOGIN_LESS}>
          <IconBox icon={<UserIcon color="secondary" userRole={FE_ROLES.LOGIN_LESS} />}>
            <FormattedMessage {...messages.userRoleSelectItemSiteUserWithoutLogin} />
          </IconBox>
        </MenuItem>

        <MenuItem disabled={isEditedCompanyAdmin} value={FE_ROLES.SITE_ADMIN}>
          <IconBox icon={<UserIcon color="secondary" userRole={FE_ROLES.SITE_ADMIN} />}>
            <FormattedMessage {...messages.userRoleSelectItemSiteAdmin} />
          </IconBox>
        </MenuItem>
        <MenuItem
          disabled={isEditedCompanyAdmin || (!feSiteRoles.isCompanyAdmin && !isEditedCompanyAdmin)}
          value={FE_ROLES.SITE_ADMIN_PAYMENT}
        >
          <IconBox icon={<UserIcon color="secondary" userRole={FE_ROLES.SITE_ADMIN_PAYMENT} />}>
            <FormattedMessage {...messages.userRoleSelectItemSiteAdminPayment} />
          </IconBox>
        </MenuItem>
        <MenuItem disabled={!isEditedCompanyAdmin} value={FE_ROLES.COMPANY_ADMIN}>
          <IconBox icon={<UserIcon color="secondary" userRole={FE_ROLES.COMPANY_ADMIN} />}>
            <FormattedMessage {...messages.userRoleSelectItemCompanyAdmin} />
          </IconBox>
        </MenuItem>
      </Select>
      <FormHelperText sx={{ ml: 2 }}>
        <FormattedMessage {...messages.userRoleSelectHelperText} />
      </FormHelperText>
      {userRole !== defaultUserRole && <UserRoleInfo companyName={companyName} userRole={userRole} />}
    </>
  );
};

UserRoleSelect.propTypes = {
  companyName: PropTypes.string.isRequired,
  defaultUserRole: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  userRole: PropTypes.string.isRequired,
};

UserRoleSelect.defaultProps = {
  defaultUserRole: null,
};

export default UserRoleSelect;

const userRoleMap = {
  [FE_ROLES.SITE_USER]: messages.userRoleInfoSITE_USERSelected,
  [FE_ROLES.COMPANY_ADMIN]: messages.userRoleInfoCOMPANY_ADMINSelected,
  [FE_ROLES.SITE_ADMIN]: messages.userRoleInfoSITE_ADMINSelected,
  [FE_ROLES.SITE_ADMIN_PAYMENT]: messages.userRoleInfoSITE_ADMIN_PAYMENTSelected,
  [FE_ROLES.LOGIN_LESS]: messages.userRoleInfoLOGIN_LESSSelected,
};
