import { defineMessages } from 'react-intl';

export default defineMessages({
  accessGroupAddDeviceModalAdd: {
    defaultMessage: 'Add to group',
    description: 'Add device modal - add button',
    id: 'accessGroupAddDeviceModal.add',
  },
  accessGroupAddDeviceModalAddDeviceAutocompleteLabel: {
    defaultMessage: 'Device',
    description: 'Add device autocomplete - label',
    id: 'addDeviceAutocomplete.label',
  },
  accessGroupAddDeviceModalAddDeviceAutocompletePlaceholder: {
    defaultMessage: 'Select device',
    description: 'Add device autocomplete - placeholder',
    id: 'addDeviceAutocomplete.placeholder',
  },
  accessGroupAddDeviceModalText: {
    defaultMessage:
      'Access groups define which users have access to which devices.' +
      '{br}{br}Selected device will be available to all users in the Access group.' +
      ' Access is activated within seconds.',
    description: 'Add device modal - text',
    id: 'accessGroupAddDeviceModal.text',
  },
  accessGroupAddDeviceModalTitle: {
    defaultMessage: 'Add device to Access group',
    description: 'Add device modal - title',
    id: 'accessGroupAddDeviceModal.title',
  },
});
