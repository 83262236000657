import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { APARTMENT_STATUS, MOBILE_VIDEO_STATUS } from '../../../../../config/consts';
import { SERVICE } from '../../../../../config/devices';
import { useGetActualCompanyId } from '../../../../../hooks/companyHooks';
import { useGetActualSiteId } from '../../../../../hooks/siteHooks';
import { IApartmentDetail } from '../../../../../modules/apartments/store';
import { MobileVideoStatus, MobileVideoType } from '../../../../../modules/commonTypes';
import { IMVLicenceModel } from '../../../../../modules/sites/store';
import messages from '../messages';

export function useApartmentStatusSection(apartmentDetailData: IApartmentDetail, licenceModel: IMVLicenceModel) {
  const { formatMessage } = useIntl();
  const companyId = useGetActualCompanyId();
  const siteId = useGetActualSiteId();

  const MVStatus = apartmentDetailData?.services?.[SERVICE.MOBILE_VIDEO]?.status;

  const shouldRenderActivateServicesDescription =
    MVStatus &&
    [APARTMENT_STATUS.ACTIVE_FOR_FREE, APARTMENT_STATUS.NO_SERVICE, APARTMENT_STATUS.UNLICENSED].includes(MVStatus) &&
    !apartmentDetailData.paidServicesActive;

  const shouldRenderPurchaseDescription =
    licenceModel.status === MOBILE_VIDEO_STATUS.STANDARD &&
    MVStatus === APARTMENT_STATUS.UNLICENSED &&
    apartmentDetailData.paidServicesActive;

  const apartmentStatusInfoCaption = useMemo(() => {
    if (licenceModel.type !== MobileVideoType.STANDARD) {
      switch (MVStatus) {
        case APARTMENT_STATUS.NO_SERVICE:
          return messages.apartmentDataFormApartmentStatusInfoNoServiceActivableAndExpirable;
        case APARTMENT_STATUS.ACTIVE_FOR_FREE:
          return messages.apartmentDataFormApartmentStatusInfoActiveForFreeActivableAndExpirable;
        case APARTMENT_STATUS.UNLICENSED:
          return messages.apartmentDataFormApartmentStatusInfoUnlicensedNotActivatedActivableAndExpirable;
        default:
          return {};
      }
    }

    if (licenceModel.status === MobileVideoStatus.TRIAL_ACTIVE) {
      switch (MVStatus) {
        case APARTMENT_STATUS.NO_SERVICE:
          return messages.apartmentDataFormApartmentStatusInfoNoServiceTrial;
        case APARTMENT_STATUS.ACTIVE_FOR_FREE:
          return messages.apartmentDataFormApartmentStatusInfoActiveForFreeTrial;
        case APARTMENT_STATUS.UNLICENSED:
          return messages.apartmentDataFormApartmentStatusInfoUnlicensedNotActivatedTrial;
        default:
          return {};
      }
    } else {
      switch (MVStatus) {
        case APARTMENT_STATUS.NO_SERVICE:
          return messages.apartmentDataFormApartmentStatusInfoNoService;
        case APARTMENT_STATUS.ACTIVE_FOR_FREE:
          return messages.apartmentDataFormApartmentStatusInfoActiveForFree;
        case APARTMENT_STATUS.UNLICENSED:
          return messages.apartmentDataFormApartmentStatusInfoUnlicensedNotActivated;
        default:
          return {};
      }
    }
  }, [licenceModel, MVStatus]);

  return {
    apartmentStatusInfoCaption,
    companyId,
    formatMessage,
    MVStatus,
    shouldRenderActivateServicesDescription,
    shouldRenderPurchaseDescription,
    siteId,
  };
}
