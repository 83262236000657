import Box from '@mui/material/Box';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonLink } from '../../components';
import { PATHS } from '../../config/consts';
import messages from './messages';

const styles = {
  root: {
    backgroundColor: 'landingPage.background',
    lineHeight: 1.5,
    minHeight: '360px',
    padding: '70px 10px 0',
    textAlign: 'center',
  },
  wrapper: (theme) => ({
    '& h2': {
      [theme.breakpoints.down('lg')]: {
        fontSize: '38px',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '36px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '34px',
      },
      color: 'text.primary',
      fontSize: '40px',
      fontWeight: 400,
      marginBottom: '30px',
      marginTop: 0,
      padding: '0 20px',
      textAlign: 'center',
    },
    '& p': {
      [theme.breakpoints.down('md')]: {
        fontSize: '24px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '22px',
      },
      fontSize: '26px',
      fontWeight: 300,
      marginBottom: '35px',
      marginTop: '15px',
    },
    margin: '0 auto',
    maxWidth: '1050px',
    paddingBottom: '35px',
    width: '100%',
  }),
};

const Service = () => (
  <Box sx={styles.root}>
    <Box sx={styles.wrapper}>
      <h2>
        <FormattedMessage {...messages.mvideoServiceTitle} values={{ sup: (chunks) => <sup>{chunks}</sup> }} />
      </h2>
      <p>
        <FormattedMessage
          {...messages.mvideoServiceDescription}
          values={{
            br: <br />,
            sup: (chunks) => <sup>{chunks}</sup>,
          }}
        />
      </p>
      <ButtonLink color="primary" size="large" to={PATHS.REGISTER} variant="contained">
        <FormattedMessage {...messages.mvideoCreateAccount} />
      </ButtonLink>
    </Box>
  </Box>
);

export default Service;
