import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useUrlParams } from '../../helpers/urlParams';
import CompanySwitcher from './CompanySwitcher';
import SideBarNavigation from './SideBarNavigation';
import SiteSwitcherMobile from './SiteSwitcherMobile';
import { styles } from './styles/Sidebar';

const SidebarMobile = ({ isVersionWarningDisplayed, onClose, onOpenCreateCompanyModal, onOpenCreateSiteModal }) => {
  const { companyId } = useUrlParams();
  const defaultOpenState = { company: false, site: false };
  const [open, setOpen] = useState(defaultOpenState);

  return (
    <Drawer
      onClose={onClose}
      open
      sx={{
        ...(isVersionWarningDisplayed && styles.withVersionWarning),
      }}
      variant="temporary"
    >
      <Box
        display={{ md: 'none', xs: 'block' }}
        py={1}
        sx={{
          ...(isVersionWarningDisplayed && styles.withVersionWarning),
        }}
      >
        <CompanySwitcher
          onDrawerClose={onClose}
          onSetOpen={(state) => setOpen({ ...defaultOpenState, company: state })}
          open={open.company}
          onOpenCreateCompanyModal={onOpenCreateCompanyModal}
        />
        {open.company && <Divider sx={{ mb: 1 }} />}
        {companyId !== null && (
          <SiteSwitcherMobile
            onDrawerClose={onClose}
            onOpenCreateSiteModal={onOpenCreateSiteModal}
            onSetOpen={(state) => setOpen({ ...defaultOpenState, site: state })}
            open={open.site}
          />
        )}
      </Box>
      <Divider />
      <SideBarNavigation onCloseSidebarMobile={onClose} />
    </Drawer>
  );
};

SidebarMobile.propTypes = {
  isVersionWarningDisplayed: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onOpenCreateCompanyModal: PropTypes.func.isRequired,
  onOpenCreateSiteModal: PropTypes.func.isRequired,
};

SidebarMobile.defaultProps = {
  isVersionWarningDisplayed: false,
};

export default SidebarMobile;
