import * as yup from 'yup';
import http from '../../api';
import { ROLES } from '../../config/consts';
import messages from './messages';

export function getValidationSchema(companyId, siteId, formatMessage) {
  return yup.object().shape({
    email: yup.string().when('hasForm', {
      is: true,
      then: (schema) =>
        schema
          .email(formatMessage(messages.validationEmailValid))
          .test({
            message: formatMessage(messages.validationEmailExistUser),
            name: 'isExistEmail',
            test: async (email) => {
              let state = true;
              await http
                .head(`/companies/${companyId}/sites/${siteId}/users`, {
                  params: { email, roles: ROLES.USER },
                })
                .then((response) => {
                  state = response.status === 404;
                });

              return state;
            },
          })
          .required(formatMessage(messages.validationRequired)),
    }),
    firstName: yup.string().when('hasForm', {
      is: true,
      then: (schema) =>
        schema
        .required(formatMessage(messages.validationRequired))
        .max(255, formatMessage(messages.validationMaxLength)),
    }),
    hasForm: yup.boolean(),
    lastName: yup.string().when('hasForm', {
      is: true,
      then: (schema) =>
        schema
          .required(formatMessage(messages.validationRequired))
          .max(255, formatMessage(messages.validationMaxLength)),
    }),
    user: yup
      .object()
      .nullable()
      .when('hasForm', {
        is: false,
        then: (schema) =>
          schema.nullable().shape({
            id: yup.string().required(),
            label: yup.string().required(),
          }),
      }),
  });
}
