import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { InfoModal } from '../../../components/_DesignSystemWrappers/Modal';
import FaqLink from './FaqLink';
import messages from './messages';

function HelpModal({ onClose, open }) {
  const { formatMessage } = useIntl();
  return (
    <InfoModal
      buttonText={formatMessage(messages.siteUsersImportIntroStepHelpModalButton)}
      onClose={onClose}
      open={open}
      title={formatMessage(messages.siteUsersImportIntroStepHelpModalTitle)}
    >
      <Typography component="span" variant="body1">
        <FormattedMessage
          {...messages.siteUsersImportIntroStepHelpModalText}
          values={{
            li: (chunks) => <li>{chunks}</li>,
            strong: (chunks) => <strong>{chunks}</strong>,
            ul: (chunks) => <ul>{chunks}</ul>,
          }}
        />
      </Typography>
    </InfoModal>
  );
}

HelpModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default HelpModal;
