import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';

function SectionTemplate({ children, title }) {
  return (
    <Stack direction="column" spacing={2}>
      {title && (
        <Typography fontWeight="bold" variant="body1">
          {title}
        </Typography>
      )}
      {children}
    </Stack>
  );
}

SectionTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

SectionTemplate.defaultProps = {
  title: '',
};

export default SectionTemplate;
