import { yupResolver } from '@hookform/resolvers/yup';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { MessageDescriptor, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ApartmentUser } from '../../../../../modules/apartmentAdmin/data';
import { isUserGuest } from '../../../../../modules/user/utils';
import { getUWLUserType, UWLUserType } from '../../../utils/UWLUserType';
import { isUserUWLOrGuest } from '../../utils/utils';
import messages from './messages';
import { getValidationSchema, IFormData } from './validationScheme';

export function useUserDetail(user: ApartmentUser) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const formMethods = useForm<IFormData>({
    defaultValues: {
      firstName: user.firstName || '',
      lastName: user.lastName,
    },
    mode: 'onChange',
    resolver: yupResolver(getValidationSchema(formatMessage, !isUserUWLOrGuest(user))),
  });

  const lastNameLabel: MessageDescriptor = useMemo(() => {
    const UWLType = getUWLUserType(user);

    if (isUserGuest(user.role) && !user.firstName) {
      return messages.AAUserDetailVisitorName;
    } else if (!user.firstName && UWLType !== UWLUserType.NoUWL) {
      if (UWLType === UWLUserType.UWLWithRFIDOnly) {
        return messages.AAUserDetailUwLRFIDName;
      } else if (UWLType === UWLUserType.UWLWithPinOnly) {
        return messages.AAUserDetailUwLPINName;
      } else {
        return messages.AAUserDetailUwLName;
      }
    } else {
      return messages.AAUserDetailSurnameLabel;
    }
  }, [user]);

  return {
    dispatch,
    formatMessage,
    formMethods,
    isUserUWLOrGuest: isUserUWLOrGuest(user),
    lastNameLabel,
  };
}
