import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import ActivatePaidServicesDialog from '../../../../../components/ApartmentDataGrid/PaidServicesSwitch/ActivatePaidServicesModal';
import apartmentDataGridMessages from '../../../../../components/ApartmentDataGrid/PurchaseButton/messages';
import Status from '../../../../../components/ApartmentDataGrid/Status';
import ButtonLinkNew from '../../../../../components/ButtonLinkNew';
import { PATHS } from '../../../../../config/consts';
import { useModal } from '../../../../../hooks/useModal';
import { IApartmentDetail, IFloor } from '../../../../../modules/apartments/store';
import { IMVLicenceModel } from '../../../../../modules/sites/store';
import { IApartmentFormProps } from '../../../../ApartmentsOverview/hooks/useGetApartmentFormData';
import ActivatePaidServicesCheckbox from '../components/ActivatePaidServicesCheckbox';
import messages from '../messages';
import { useApartmentStatusSection } from './useApartmentStatusSection';

interface IApartmentStatusSection {
  apartmentDetailData: IApartmentDetail;
  floors: Array<IFloor>;
  formMethods: UseFormReturn<IApartmentFormProps>;
  formWrapperProps?: object;
  licenceModel: IMVLicenceModel;
}

function ApartmentStatusSection({
  apartmentDetailData,
  floors,
  formMethods,
  formWrapperProps,
  licenceModel,
}: IApartmentStatusSection) {
  const {
    apartmentStatusInfoCaption,
    companyId,
    formatMessage,
    MVStatus,
    shouldRenderActivateServicesDescription,
    shouldRenderPurchaseDescription,
    siteId,
  } = useApartmentStatusSection(apartmentDetailData, licenceModel);

  const { Modal: ActivatePaidServicesModal, onOpen: onOpenActivatePaidServicesModal } = useModal({
    Modal: ActivatePaidServicesDialog,
    onClose: () => formMethods.resetField('paidServicesActive'),
  });

  return (
    <Grid alignContent="flex-start" rowSpacing={1} {...formWrapperProps}>
      <Typography fontWeight={700} mb={1}>
        {formatMessage(messages.apartmentDataFormApartmentStatusTitle)}
      </Typography>
      <Grid item xs={12}>
        <ActivatePaidServicesCheckbox
          formMethods={formMethods}
          label={formatMessage(messages.apartmentDataFormApartmentStatusActivatePaidServices)}
          licenceModel={licenceModel}
          onOpenActivatePaidServicesModal={onOpenActivatePaidServicesModal}
          initialPaidServicesActiveValue={apartmentDetailData.paidServicesActive}
        />
      </Grid>
      {shouldRenderActivateServicesDescription && (
        <Grid item mb={1} xs={12}>
          <Typography color="text.secondary" variant="body2">
            {formatMessage(apartmentStatusInfoCaption, { br: <br /> })}
          </Typography>
        </Grid>
      )}
      {MVStatus && (
        <Grid item xs={12}>
          <Status status={MVStatus} />
        </Grid>
      )}
      {shouldRenderPurchaseDescription && (
        <Grid item xs={12}>
          <ButtonLinkNew
            link={PATHS.SUBSCRIPTION_MOBILE_VIDEO.replace(':companyId', companyId).replace(':siteId', siteId)}
            message={formatMessage(apartmentDataGridMessages.actionButtonPurchase)}
          />
          <Typography color="text.secondary" mt={1} variant="body2">
            {formatMessage(messages.apartmentDataFormApartmentStatusInfoUnlicensedActivated)}
          </Typography>
        </Grid>
      )}
      <ActivatePaidServicesModal
        companyId={companyId}
        apartmentValue={apartmentDetailData}
        floors={floors}
        siteId={siteId}
      />
    </Grid>
  );
}

export default ApartmentStatusSection;
