import { defineMessages } from 'react-intl';

export default defineMessages({
  actionsSectionNextStepButtonLabel: {
    defaultMessage: 'Next',
    description: 'Next step button label',
    id: 'actionsSection.nextStepButton.label',
  },
  actionsSectionPurchaseSubscriptionButtonLabel: {
    defaultMessage: 'Purchase subscription',
    description: 'Purchase order button label',
    id: 'actionsSection.purchaseSubscriptionButton.label',
  },
  creditInfoCreditEmpty: {
    defaultMessage: 'Your credit pool is empty at the moment.',
    description: 'Credit info empty credit state',
    id: 'creditInfo.credit.empty',
  },
  creditInfoCreditRemaining: {
    defaultMessage: '<strong>Credit pool:</strong> {credit}' + ' {count, plural, one {credit} other {credits}}',
    description: 'Credit info remaining credit state',
    id: 'creditInfo.credit.remaining',
  },
  creditInfoExpirationNextPayment: {
    defaultMessage:
      'Your Mobile Video is active till {poolExpiration}' +
      ' and it will be automatically extended by the scheduled recurring payment.',
    description: 'Credit info expiration next payment state',
    id: 'creditInfo.expiration.nextPayment',
  },
  creditInfoExpirationPoolExpiration: {
    defaultMessage: 'Your Mobile Video is active till {poolExpiration}',
    description: 'Credit info expiration pool expiration state',
    id: 'creditInfo.expiration.poolExpiration',
  },
  creditInfoExpirationValidity: {
    defaultMessage: 'Your Mobile Video is active till {validity}',
    description: 'Credit info expiration validity state',
    id: 'creditInfo.expiration.validity',
  },
});
