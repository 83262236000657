import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import landingMobilePhone from '../../assets/landing_phone-my2n_app.png';
import landingRCImage from '../../assets/landing_rc.png';
import landingSites from '../../assets/landing_sites.png';
import landingApi from '../../assets/partner-api.png';
import { ButtonLink } from '../../components';
import { INTEGRATE_WITH_2N_REQUEST } from '../../config/address';
import { PATHS } from '../../config/consts';
import messages from './messages';

const styles = {
  api: {
    background: 'white',
  },
  apiBg: (theme) => ({
    [theme.breakpoints.only('xs')]: {
      left: 0,
      m: 'auto',
      opacity: 0.1,
      right: 0,
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      right: '-14px',
      width: '52%',
    },
    background: `url(${landingApi}) no-repeat`,
    backgroundPosition: '100% 100%',
    backgroundSize: 'contain',
    bottom: 0,
    height: '432px',
    maxWidth: '560px',
    mr: 1,
    position: 'absolute',
    right: 0,
    top: 0,
    width: '45%',
    zIndex: 1,
  }),
  mVideo: {
    background: 'white',
  },
  mVideoBg: (theme) => ({
    [theme.breakpoints.down('md')]: {
      right: '-160px',
    },
    [theme.breakpoints.only('xs')]: {
      left: 0,
      m: 'auto',
      opacity: 0.1,
      right: 0,
      width: '100%',
    },
    background: `url(${landingMobilePhone}) no-repeat`,
    backgroundPosition: '100% 100%',
    backgroundSize: 'contain',
    bottom: '30px',
    height: '521px',
    maxWidth: '560px',
    mr: 1,
    position: 'absolute',
    right: 0,
    width: '100%',
    zIndex: 1,
  }),
  rc: {
    bgcolor: 'grey.100',
  },
  rcBg: (theme) => ({
    [theme.breakpoints.only('xs')]: {
      left: 0,
      m: 'auto',
      opacity: 0.1,
      right: 0,
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      left: '-185px',
      width: '60%',
    },
    background: `url(${landingRCImage}) no-repeat`,
    backgroundPosition: '50% 50%',
    backgroundSize: 'contain',
    bottom: 0,
    height: '510px',
    left: 0,
    maxWidth: '628px',
    mr: 1,
    position: 'absolute',
    top: 0,
    width: '50%',
    zIndex: 1,
  }),
  rcContent: (theme) => ({
    [theme.breakpoints.down('lg')]: {
      pl: '45%',
    },
    [theme.breakpoints.only('xs')]: {
      float: 'none',
      pl: 1,
    },
    pl: 59,
  }),
  root: {
    lineHeight: 1.5,
    width: '100%',
  },
  service: (theme) => ({
    [theme.breakpoints.down('md')]: {
      overflow: 'hidden',
    },
    [theme.breakpoints.up('xs')]: {
      minHeight: '570px',
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: '550px',
    },
    '& h2': {
      '& strong': {
        color: 'primary.main',
      },
      color: 'grey.800',
      fontSize: '32px',
      fontWeight: 'regular',
      letterSpacing: 0,
      m: 0,
      mb: 4,
      mx: 1,
      textAlign: 'left',
    },
    boxSizing: 'border-box',
    float: 'left',
    position: 'relative',
    width: '100%',
  }),
  serviceContent: (theme) => ({
    [theme.breakpoints.up('xs')]: {
      minHeight: '570px',
    },
    [theme.breakpoints.up('sm')]: {
      minHeight: '550px',
    },
    [theme.breakpoints.up('md')]: {
      pt: 8,
    },
    [theme.breakpoints.up('xl')]: {
      pt: 12,
    },
    [theme.breakpoints.only('xs')]: {
      fontSize: '.8em',
      m: 0,
      p: 2.5,
      pt: 5.5,
    },
    '& > *': {
      position: 'relative',
      zIndex: 2,
    },
    '& li': {
      [theme.breakpoints.only('xs')]: {
        mb: 2.5,
      },
      '&:before': {
        color: 'primary.main',
        content: '"keyboard_arrow_right"',
        display: 'inline-block',
        fontFamily: 'Material Icons',
        fontSize: '20px',
        fontStyle: 'normal',
        fontVariant: 'normal',
        fontWeight: 'normal',
        left: 0,
        lineHeight: '1em',
        MozOsxFontSmoothing: 'grayscale',
        position: 'absolute',
        speak: 'none',
        textAlign: 'center',
        textDecoration: 'inherit',
        textTransform: 'none',
        top: '4px',
        WebkitFontSmoothing: 'antialiased',
        width: '1em',
      },
      fontSize: '18px',
      fontWeight: 'regular',
      lineHeight: '21px',
      mb: 2.5,
      pl: 5,
      position: 'relative',
      textAlign: 'left',
    },
    '& p': {
      color: 'grey.700',
      fontSize: '26px',
      fontWeight: 'light',
      letterSpacing: 0,
      m: 0,
      mb: 4,
      ml: 1,
      opacity: 0.7,
      textAlign: 'left',
    },
    '& ul': {
      listStyleType: 'none',
      m: 0,
      ml: 1,
      p: 0,
    },
    bgcolor: 'transparent',
    height: '100%',
    maxWidth: '1050px',
    mx: 'auto',
    my: 0,
    position: 'relative',
    pt: 7,
    px: 1,
    width: '100%',
  }),
  sites: {
    bgcolor: 'grey.100',
  },
  sitesBg: (theme) => ({
    [theme.breakpoints.down('md')]: {
      width: '42%',
    },
    [theme.breakpoints.only('xs')]: {
      left: 0,
      m: 'auto',
      opacity: 0.1,
      right: 0,
      width: '100%',
    },
    [theme.breakpoints.up('lg')]: {
      left: '-165px',
      width: '60%',
    },
    background: `url(${landingSites}) no-repeat`,
    backgroundPosition: '50% 50%',
    backgroundSize: 'contain',
    bottom: 0,
    height: '432px',
    left: 0,
    maxWidth: '560px',
    mr: 1,
    mt: 8,
    position: 'absolute',
    top: 0,
    width: '50%',
    zIndex: 1,
  }),
  sitesContent: (theme) => ({
    [theme.breakpoints.down('lg')]: {
      pl: '45%',
    },
    [theme.breakpoints.only('xs')]: {
      float: 'none',
      pl: 1,
    },
    pl: 59,
  }),
  submit: (theme) => ({
    [theme.breakpoints.only('xs')]: {
      height: '140px',
      left: 0,
      textAlign: 'center',
      width: '100%',
    },
    bottom: 0,
    mb: 3,
    mt: 6,
    textAlign: 'left',
  }),
};

const Services = ({ refMobileVideoService, refPartnerApi, refRemoteConfigurationService, refSiteManagement }) => (
  <Box sx={styles.root}>
    <Box ref={refMobileVideoService} sx={styles.service}>
      <Box sx={styles.mVideo}>
        <Box sx={styles.serviceContent}>
          <h2>
            <FormattedMessage
              {...messages.servicesTitle}
              values={{
                br: <br />,
                strong: (text) => <strong>{text}</strong>,
                sup: (text) => <sup>{text}</sup>,
              }}
            />
          </h2>
          <ul>
            <li>
              <FormattedMessage {...messages.servicesListVideoCall} />
            </li>
            <li>
              <FormattedMessage {...messages.servicesListAvailable} />
            </li>
            <li>
              <FormattedMessage {...messages.servicesListUnlock} />
            </li>
          </ul>
          <Box sx={styles.mVideoBg} />
          <Box sx={styles.submit}>
            <ButtonLink color="primary" size="large" to={PATHS.LANDING_MY2N_APP} variant="outlined">
              <FormattedMessage {...messages.servicesMoreInfo} />
            </ButtonLink>
          </Box>
        </Box>
      </Box>
    </Box>
    <Box ref={refRemoteConfigurationService} sx={styles.service}>
      <Box sx={styles.rc}>
        <Box sx={styles.serviceContent}>
          <Box sx={styles.rcContent}>
            <h2>
              <FormattedMessage
                {...messages.remoteConfigurationTitle}
                values={{
                  br: <br />,
                  strong: (chunks) => <strong>{chunks}</strong>,
                  sup: (chunks) => <sup>{chunks}</sup>,
                }}
              />
            </h2>
            <ul>
              <li>
                <FormattedMessage {...messages.remoteConfigurationListSaveCosts} />
              </li>
              <li>
                <FormattedMessage {...messages.remoteConfigurationListProvideAssistance} />
              </li>
              <li>
                <FormattedMessage {...messages.remoteConfigurationListNoNeed} />
              </li>
            </ul>
            <Box sx={styles.submit}>
              <ButtonLink color="primary" size="large" to={PATHS.LANDING_REMOTE_CONFIGURATION} variant="outlined">
                <FormattedMessage {...messages.MoreInfo} />
              </ButtonLink>
            </Box>
          </Box>
          <Box sx={styles.rcBg} />
        </Box>
      </Box>
    </Box>
    <Box ref={refPartnerApi} sx={styles.service}>
      <Box sx={styles.api}>
        <Box sx={styles.serviceContent}>
          <h2>
            <FormattedMessage
              {...messages.servicesApiTitle}
              values={{
                br: <br />,
                strong: (text) => <strong>{text}</strong>,
                sup: (text) => <sup>{text}</sup>,
              }}
            />
          </h2>
          <ul>
            <li>
              <FormattedMessage {...messages.servicesApiListVideoCall} />
            </li>
            <li>
              <FormattedMessage {...messages.servicesApiListAvailable} />
            </li>
            <li>
              <FormattedMessage {...messages.servicesApiListUnlock} />
            </li>
          </ul>
          <Box sx={styles.apiBg} />
          <Box sx={styles.submit}>
            <Button color="primary" href={INTEGRATE_WITH_2N_REQUEST} size="large" variant="outlined">
              <FormattedMessage {...messages.MoreInfo} />
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
    <Box ref={refSiteManagement} sx={styles.service}>
      <Box sx={styles.sites}>
        <Box sx={styles.serviceContent}>
          <Box sx={styles.sitesContent}>
            <h2>
              <FormattedMessage
                {...messages.title}
                values={{
                  br: <br />,
                  strong: (chunks) => <strong>{chunks}</strong>,
                }}
              />
            </h2>
            <ul>
              <li>
                <FormattedMessage {...messages.listSaveCosts} />
              </li>
              <li>
                <FormattedMessage {...messages.listProvideAssistance} />
              </li>
              <li>
                <FormattedMessage {...messages.listNoNeed} />
              </li>
            </ul>
          </Box>
          <Box sx={styles.sitesBg} />
        </Box>
      </Box>
    </Box>
  </Box>
);

Services.propTypes = {
  refMobileVideoService: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  refPartnerApi: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
    .isRequired,
  refRemoteConfigurationService: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  refSiteManagement: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.instanceOf(Element) })])
    .isRequired,
};

export default Services;
