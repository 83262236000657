import { Delete } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ActionModalError } from '../../../../../../../components/_DesignSystemWrappers/Modal';
import { ApartmentUser } from '../../../../../../../modules/apartmentAdmin/data';
import { IApartmentIdsInfo } from '../../../../../../../modules/apartments/store';
import { IModalBase } from '../../../../../../../modules/commonTypes';
import { AAUserInfo } from '../../../../../components/AAUserInfo';
import { useDeleteRfid } from '../../hooks/useDeleteRfid';
import { TestID } from '../../testIds';
import messages from './messages';

export interface IDeleteRfidModal extends IModalBase {
  user: ApartmentUser;
  apartmentIdsInfo: IApartmentIdsInfo;
}

export function DeleteRfidModal({
  apartmentIdsInfo: { apartmentId, companyId, siteId },
  onClose,
  open,
  user,
}: IDeleteRfidModal) {
  const { formatMessage } = useIntl();

  const { isSubmitting, onDeleteHandler } = useDeleteRfid({
    apartmentIdsInfo: { apartmentId, companyId, siteId },
    onClose,
    user,
  });

  return (
    <ActionModalError
      icon={<Delete />}
      muiDialogProps={{
        maxWidth: 'xs',
      }}
      onClose={onClose}
      testId={TestID.DeleteRfidModal}
      open={open}
      primaryButtonAction={onDeleteHandler}
      primaryButtonText={formatMessage(messages.AAmodalDeleteRFIDSubmit)}
      isPrimaryButtonDisabled={isSubmitting}
      secondaryButtonText={formatMessage(messages.AAmodalDeleteRFIDCancel)}
      title={formatMessage(messages.AAmodalDeleteRFIDTitle)}
    >
      <Box sx={{ mt: 2 }}>
        <Stack spacing={1}>
          <Typography variant="body2" component="span">
            <FormattedMessage {...messages.AAmodalDeleteRFIDText1} />
          </Typography>
          <Stack spacing={2}>
            <AAUserInfo user={user} />
            <Typography variant="body2" component="span">
              <FormattedMessage {...messages.AAmodalDeleteRFIDText2} />
            </Typography>
          </Stack>
        </Stack>
      </Box>
    </ActionModalError>
  );
}
