import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';
import React from 'react';
import ApartmentNameChip from '../ApartmentNameChip';

function Tag({ getTagProps, newItemId, tagValue }) {
  return tagValue.map((option, index) =>
    option.id === newItemId ? (
      <Chip color="primary" key={option.id} label={option.label || ''} {...getTagProps({ index })} />
    ) : (
      <ApartmentNameChip
        key={option.id}
        name={option.name}
        number={option.number}
        size="default"
        {...getTagProps({ index })}
      />
    )
  );
}

Tag.propTypes = {
  getTagProps: PropTypes.func.isRequired,
  newItemId: PropTypes.string.isRequired,
  tagValue: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default Tag;
