import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Status } from '../../../../components';
import { SUPPORT } from '../../../../config/address';
import messages from './messages';

const ActivationStatusError = ({ error }) => {
  const mailToLink = (
    <a href={`mailto:${SUPPORT}`}>
      <FormattedMessage {...messages.activationStatusErrorLink} />
    </a>
  );

  return error === 409 ? (
    <Status
      info={
        <Typography align="center">
          <FormattedMessage
            {...messages.activationStatusErrorInfoUsedMessage}
            values={{
              br: <br />,
              link: mailToLink,
            }}
          />
        </Typography>
      }
      label={<FormattedMessage {...messages.activationStatusErrorLabel} />}
      type="error"
    />
  ) : (
    <Status
      info={
        <Typography align="center">
          <FormattedMessage
            {...messages.activationStatusErrorInfoUnknownMessage}
            values={{
              br: <br />,
              link: mailToLink,
            }}
          />
        </Typography>
      }
      label={<FormattedMessage {...messages.activationStatusErrorLabel} />}
      type="error"
    />
  );
};

ActivationStatusError.propTypes = {
  error: PropTypes.number.isRequired,
};

export default ActivationStatusError;
