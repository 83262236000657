import Typography from '@mui/material/Typography';
import React from 'react';

export interface IListItemNumberData {
  number: number;
}

const ListItemNumber = ({ number }: IListItemNumberData) => <Typography variant="body2">{`${number}`}</Typography>;

export default ListItemNumber;
