import SettingsIcon from '@mui/icons-material/Settings';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useModal } from '../../hooks/useModal';
import DeviceElevatorIntegrationModal from '../DeviceElevatorIntegrationModal';
import messages from './messages';

function DeviceElevatorIntegrationMainSettings({ isActive }: { isActive: boolean }) {
  const { Modal: ElevatorIntegrationModal, onOpen: onOpenElevatorIntegrationModal } = useModal({
    Modal: DeviceElevatorIntegrationModal,
  });

  return (
    <>
      <Stack direction={{ md: 'row', xs: 'column' }} justifyContent="space-between">
        {isActive ? (
          <Typography variant="body1" fontWeight="bold">
            <FormattedMessage {...messages.DeviceElevatorIntegrationMainSettingsActive} />
          </Typography>
        ) : (
          <Typography variant="body1" color="error" fontWeight="bold">
            <FormattedMessage
              {...messages.DeviceElevatorIntegrationMainSettingsInactive}
              values={{
                box: (chunks) => (
                  <Box component="span" color="text.secondary" fontWeight="regular">
                    {chunks}
                  </Box>
                ),
              }}
            />
          </Typography>
        )}
        <Box>
          <Button
            variant={isActive ? 'outlined' : 'contained'}
            startIcon={<SettingsIcon />}
            onClick={onOpenElevatorIntegrationModal}
          >
            <FormattedMessage {...messages.DeviceElevatorIntegrationMainSettingsButton} />
          </Button>
          <ElevatorIntegrationModal />
        </Box>
      </Stack>
    </>
  );
}

export default DeviceElevatorIntegrationMainSettings;
