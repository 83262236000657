import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { table } from '../../config/styles';
import { dataGridProps } from '../../helpers/dataGrid';
import useBreakpoints from '../../helpers/useBreakpoints';
import { isFetchingMessages } from '../../modules/localization/selectors';
import DataGrid from '../DataGrid';
import EllipsisText from '../EllipsisText';
import AccessLogAccessType from './components/AccessLogAccessType';
import AccessLogStartTime from './components/AccessLogStartTime';
import AccessLogUser from './components/AccessLogUser';
import FilterHead from './components/FilterHead';
import messages from './messages';
import NoRowsOverlay from './NoRowsOverlay';
import NoRowsSearchOverlay from './NoRowsSearchOverlay';

const styles = {
  noResults: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  root: {
    px: 0,
    py: 2,
  },
  table: table(),
};

const AccessLogDataGrid = ({
  accessLogsTable,
  data,
  isFetching,
  onAccessLogShowDetail,
  onChangeFilter,
  onOrderBy,
  onRowsPerPageChange,
  onSelectPage,
}) => {
  const { formatMessage } = useIntl();
  const { upLg, upMd, upSm } = useBreakpoints();
  const isLocalizationLoaded = useSelector(isFetchingMessages);

  const newColumns = useMemo(
    () => [
      {
        disableColumnMenu: true,
        field: 'startAt',
        headerName: upSm ? formatMessage(messages.accessTypeTooltipColumnsTime) : '',
        renderCell: (accessLog) =>
          isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <AccessLogStartTime accessLog={accessLog.value} displayTime={upSm} />
          ),
        sortable: true,
        width: upSm ? 200 : 50,
      },
      {
        disableColumnMenu: true,
        field: 'user',
        flex: 1,
        headerName: formatMessage(messages.accessTypeTooltipColumnsUser),
        renderCell: (accessLog) =>
          isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <AccessLogUser accessLog={accessLog.value} />
          ),
        sortable: false,
      },
      {
        disableColumnMenu: true,
        field: 'device',
        flex: 1,
        headerName: formatMessage(messages.accessTypeTooltipColumnsDevice),
        renderCell: (accessLog) =>
          isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <EllipsisText>{accessLog.value?.device.name}</EllipsisText>
          ),
        sortable: false,
      },
      {
        disableColumnMenu: true,
        field: 'accessMethod',
        headerName: formatMessage(
          upLg
            ? messages.accessTypeTooltipColumnsAccessMethodDesktop
            : messages.accessTypeTooltipColumnsAccessMethodMobile
        ),
        renderCell: (accessLog) =>
          isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <AccessLogAccessType accessLog={accessLog.value} />
          ),
        sortable: false,
        width: upLg ? 120 : 80,
      },
      {
        disableColumnMenu: true,
        field: 'detail',
        headerName: formatMessage(messages.accessTypeTooltipColumnsDetail),
        renderCell: (accessLog) =>
          isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <EllipsisText>{accessLog.value?.detail?.log || '—'}</EllipsisText>
          ),
        sortable: false,
        width: upLg ? 256 : 200,
      },
    ],
    [upSm, upMd, upLg, isFetching, isLocalizationLoaded]
  );

  const rows = useMemo(
    () =>
      data.map((accessLog) => ({
        accessMethod: accessLog,
        detail: accessLog,
        device: accessLog,
        id: accessLog.id,
        isClickable: true,
        startAt: accessLog,
        user: accessLog,
      })),
    [data]
  );

  return (
    <Paper sx={styles.root}>
      <FilterHead accessLogsTable={accessLogsTable} onChangeFilter={onChangeFilter} />
      <DataGrid
        columns={newColumns}
        columnVisibilityModel={{
          accessMethod: upSm,
          detail: upLg,
        }}
        disableRowSelectionOnClick
        hideFooterPagination={false}
        loading={isFetching}
        noRowsOverlay={
          accessLogsTable.filter?.fulltext !== '' ? (
            <NoRowsSearchOverlay
              onClearSearch={() =>
                onChangeFilter(
                  {
                    target: {
                      name: 'fulltext',
                      value: '',
                    },
                  },
                  true
                )
              }
              searchText={accessLogsTable.filter.fulltext}
            />
          ) : (
            <NoRowsOverlay />
          )
        }
        onCellClick={(params) => onAccessLogShowDetail(params.value.id)}
        onOrderBy={onOrderBy}
        onRowsPerPageChange={onRowsPerPageChange}
        onSelectPage={onSelectPage}
        order={accessLogsTable.order}
        page={accessLogsTable.page}
        resultsFiltered={accessLogsTable.filter?.results ? accessLogsTable.resultsAlert : accessLogsTable.resultsTotal}
        resultsTotal={accessLogsTable.resultsTotal}
        rows={rows}
        rowsPerPage={accessLogsTable.rowsPerPage}
      />
    </Paper>
  );
};

AccessLogDataGrid.propTypes = {
  accessLogsTable: dataGridProps.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFetching: PropTypes.bool.isRequired,
  onAccessLogShowDetail: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onOrderBy: PropTypes.func.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  onSelectPage: PropTypes.func.isRequired,
};

export default AccessLogDataGrid;
