import { defineMessages } from 'react-intl';

export default defineMessages({
  setMy2NAppAccessMenuItemDisableMy2NAccess: {
    defaultMessage: 'Disable My2N app access',
    description: 'Site Users Row Menu - disable My2Naccess',
    id: 'setMy2NAppAccessMenuItemDisableMy2NAccess',
  },
  setMy2NAppAccessMenuItemEnableMy2NAccess: {
    defaultMessage: 'Enable My2N app access',
    description: 'Site Users Row Menu - enable My2N access',
    id: 'setMy2NAppAccessMenuItemEnableMy2NAccess',
  },
});
