import { defineMessages } from 'react-intl';

export default defineMessages({
  warningSelectSiteBoxAction: {
    defaultMessage: 'Set type of site',
    description: '',
    id: 'warningSelectSiteBox.action',
  },
  warningSelectSiteBoxDescription: {
    defaultMessage: 'It’s a necessary step that will make site administration and settings easier for you.',
    description: '',
    id: 'warningSelectSiteBox.description',
  },
  warningSelectSiteBoxTitle: {
    defaultMessage: 'Select your site type',
    description: '',
    id: 'warningSelectSiteBox.title',
  },
});
