import TooltipMD, { TooltipProps } from '@mui/material/Tooltip';
import React, { forwardRef } from 'react';

export interface ITooltipProps {
  children: React.ReactElement;
  placement?: TooltipProps['placement'];
  title: React.ReactNode;
}

const Tooltip = forwardRef(({ children, placement, title, ...rest }: ITooltipProps, ref) => {
  return (
    <TooltipMD {...rest} placement={placement ?? 'right'} ref={ref} title={title}>
      {children}
    </TooltipMD>
  );
});

export default Tooltip;
