import Box from '@mui/material/Box';
import React from 'react';
import logoIcon from '../../../../assets/my2n_logo.svg';
import logoMinIcon from '../../../../assets/my2n_logo_minimized.svg';

const Logo = () => (
  <Box lineHeight={1}>
    <Box display={{ md: 'block', xs: 'none' }}>
      <img alt="LogoMy2n" src={logoIcon} />
    </Box>
    <Box display={{ md: 'none', xs: 'block' }}>
      <img alt="LogoMinMy2n" src={logoMinIcon} />
    </Box>
  </Box>
);

export default Logo;
