import AddIcon from '@mui/icons-material/Add';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { FORM } from '../../config/consts';
import actions from '../../modules/apartments';
import { IModalBase } from '../../modules/commonTypes';
import siteActions from '../../modules/sites';
import { IUserBase } from '../../modules/user/store';
import { ActionModal } from '../_DesignSystemWrappers/Modal';
import UsersAutocomplete from '../UsersAutocomplete';
import { useGetGlobalHooks } from './hooks/getGlobalHooks';
import messages from './messages';

interface IFormData {
  email: string;
  firstName: string;
  hasForm: boolean;
  lastName: string;
  users: Array<IUserBase>;
}

function AddApartmentUsersModal({ onClose, open }: IModalBase) {
  const {
    apartmentId,
    dispatch,
    formatMessage,
    formMethods,
    isAddApartmentUserLoading,
    isDirty,
    isValid,
    optionsData,
  } = useGetGlobalHooks();

  useEffect(() => {
    if (open) {
      dispatch(siteActions.getSitesUsersNames());
    }
  }, [open]);

  function handleSubmit(data: IFormData) {
    const newUser = {
      email: data?.email,
      firstName: data?.firstName,
      lastName: data?.lastName,
    };

    const currentUsers = data.users
      .filter(
        (item) => !(item.id.toString() === FORM.NEW_ITEM_ID || item.id.toString() === FORM.NEW_ITEM_WITHOUT_LOGIN_ID)
      )
      .map((item) => item.id);

    if (apartmentId !== undefined) {
      dispatch(actions.addApartmentUsersWithCreate(apartmentId, currentUsers, data.hasForm ? newUser : undefined));
    }

    onClose();
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
        <ActionModal
          icon={<AddIcon />}
          isPrimaryButtonDisabled={!isDirty || !isValid || isAddApartmentUserLoading}
          muiDialogProps={{
            maxWidth: 'xs',
          }}
          onClose={onClose}
          open={open}
          primaryButtonAction={formMethods.handleSubmit(handleSubmit)}
          primaryButtonText={formatMessage(messages.addApartmentUsersModalAddUsersButton)}
          secondaryButtonText={formatMessage(messages.addApartmentUsersModalCancelButton)}
          title={formatMessage(messages.addApartmentUsersModalHeader)}
        >
          <Stack direction="column" spacing={2}>
            <Typography component="span" variant="body1">
              <FormattedMessage {...messages.addApartmentUsersModalDescription} />
            </Typography>
            <UsersAutocomplete
              hasFormItemKey={FORM.HAS_FORM}
              isDirty={formMethods.getValues().users?.length || 0}
              newItemId={FORM.NEW_ITEM_ID}
              newItemWithoutLoginId={FORM.NEW_ITEM_WITHOUT_LOGIN_ID}
              optionsData={optionsData}
            />
          </Stack>
        </ActionModal>
      </form>
    </FormProvider>
  );
}

export default AddApartmentUsersModal;
