import { VerifiedUser } from '@mui/icons-material';
import { Box, Chip, Stack } from '@mui/material';
import React from 'react';

export interface IAccessGroupListProps {
  groups: string[];
}

export function AccessGroupList({ groups }: IAccessGroupListProps) {
  return (
    <Stack direction="row" flexWrap="wrap" sx={{ width: '100%' }}>
      {groups.map((group: string, index: number) => {
        return (
          <Box sx={{ boxSizing: 'border-box', maxWidth: '100%', pb: 0.5, pr: 1 }} key={index}>
            <Chip icon={<VerifiedUser />} variant="outlined" color="primary" sx={{ fontWeight: 700 }} label={group} />
          </Box>
        );
      })}
    </Stack>
  );
}
