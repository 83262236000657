import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../messages';

const styles = {
  device: {
    '& > div': {
      mt: 1,
    },
    fontSize: '15px',
  },
  label: {
    color: 'text.secondary',
    fontSize: '12px',
  },
};

function DeviceInfo({ deviceId, devicePassword }) {
  return (
    <Grid item sx={styles.device}>
      <div>
        <Box sx={styles.label}>
          <FormattedMessage {...messages.qrDialogDeviceId} />
        </Box>
        <div id="device-id">{deviceId}</div>
      </div>
      <div>
        <Box sx={styles.label}>
          <FormattedMessage {...messages.qrDialogDevicePassword} />
        </Box>
        <div id="device-password">{devicePassword}</div>
      </div>
    </Grid>
  );
}

export default DeviceInfo;

DeviceInfo.propTypes = {
  deviceId: PropTypes.string.isRequired,
  devicePassword: PropTypes.string.isRequired,
};
