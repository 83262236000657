import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../config/consts';
import { useGetActualCompanyId } from '../../hooks/companyHooks';
import { useGetActualSiteId } from '../../hooks/siteHooks';
import commonActions, { selectors as commonSelectors } from '../../modules/common';
import { selectors as userSelectors } from '../../modules/user';
import Logo from './components/appLogo/Logo';
import CompanyMenu from './components/companyMenu/CompanyMenu';
import SidebarMobileButton from './components/sidebarMobileButton/SidebarMobileButton';
import SiteMenu from './components/siteMenu/SiteMenu';
import UserMenu from './components/userMenu/UserMenu';
import { NavbarLayout } from './NavbarLayout';

const Navbar = ({
  companySwitcherInvalid,
  isCompanyAdmin,
  isSiteAdmin,
  isVersionWarningDisplayed,
  onLoadCompanySwitcherData,
  onLoadSiteSwitcherData,
  siteSwitcherInvalid,
  user,
}) => {
  const companyId = useGetActualCompanyId();
  const siteId = useGetActualSiteId();
  const navigate = useNavigate();

  useEffect(() => {
    onLoadCompanySwitcherData();
  }, []);

  useEffect(() => {
    if (companySwitcherInvalid) {
      onLoadCompanySwitcherData();
    }
  }, [companySwitcherInvalid]);

  useEffect(() => {
    if (companyId > 0) {
      onLoadSiteSwitcherData(companyId);
    }
  }, [companyId]);

  useEffect(() => {
    if (companyId && siteSwitcherInvalid) {
      onLoadSiteSwitcherData(companyId);
    }
  }, [companyId, siteSwitcherInvalid]);

  return (
    <NavbarLayout
      appIcon={<Logo />}
      companyMenu={<CompanyMenu active={Boolean(companyId && !siteId)} />}
      isVersionWarningDisplayed={isVersionWarningDisplayed}
      sidebarMobile={<SidebarMobileButton isVersionWarningDisplayed={isVersionWarningDisplayed} />}
      siteMenu={
        <SiteMenu active={Boolean(companyId && siteId)} isCompanyAdmin={isCompanyAdmin} isSiteAdmin={isSiteAdmin} />
      }
      userMenu={<UserMenu onLogout={() => navigate(PATHS.LOGOUT)} user={user} />}
    />
  );
};

Navbar.propTypes = {
  companySwitcherInvalid: PropTypes.bool.isRequired,
  isCompanyAdmin: PropTypes.bool.isRequired,
  isSiteAdmin: PropTypes.bool.isRequired,
  isVersionWarningDisplayed: PropTypes.bool,
  onLoadCompanySwitcherData: PropTypes.func.isRequired,
  onLoadSiteSwitcherData: PropTypes.func.isRequired,
  siteSwitcherInvalid: PropTypes.bool.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
};

Navbar.defaultProps = {
  isVersionWarningDisplayed: false,
};

const mapStateToProps = (state) => ({
  companySwitcherInvalid: commonSelectors.isDidInvalidCompanySwitcher(state),
  siteSwitcherInvalid: commonSelectors.isDidInvalidSiteSwitcher(state),
  user: userSelectors.getCurrentUserSelector(state),
});

const mapDispatchToProps = {
  onLoadCompanySwitcherData: commonActions.loadDataCompanySwitcher,
  onLoadSiteSwitcherData: commonActions.loadDataSiteSwitcher,
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
