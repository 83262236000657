import PropTypes from 'prop-types';
import * as React from 'react';
import { useMemo } from 'react';
import DevicesInfoChip from '../DevicesInfoChip';
import MobileVideoStatus from '../MobileVideoStatus';
import { getStyles } from '../MobileVideoStatus/helpers';

const SiteStatus = ({ licenceModel }) => {
  const styles = useMemo(() => getStyles(false, licenceModel, false), [licenceModel]);
  return (
    <DevicesInfoChip
      error={styles === 'error'}
      label={<MobileVideoStatus licenceModel={licenceModel} showDate={false} />}
      size="small"
      success={styles === 'success'}
      variant="outlined"
      warning={styles === 'warning'}
    />
  );
};

SiteStatus.propTypes = {
  licenceModel: PropTypes.shape({}).isRequired,
};

export default SiteStatus;
