import http from './index';

const loginRequest = async ({ email, password }) =>
  http.post('/auth', {
    password,
    username: email,
  });

const registerRequest = async (data) => http.post('/registration', data);

const resetPasswordRequest = async (data) => http.post('password', data);

const resendConfirmationEmailRequest = async (data) =>
  http.get('registration/resendConfirmationEmail', {
    params: {
      ...data,
    },
  });

export { loginRequest, registerRequest, resendConfirmationEmailRequest, resetPasswordRequest };
