import { defineMessages } from 'react-intl';

export default defineMessages({
  siteUsersImportResultStepAllUsersImported: {
    defaultMessage: 'All <strong>{count}</strong> {count, plural, one {user} other {users}} imported',
    description: '',
    id: 'siteUsersImportResultStepAllUsersImported',
  },
  siteUsersImportResultStepFailedStatusAborted: {
    defaultMessage: 'Canceled',
    description: 'Result step - failed status - ABORTED',
    id: 'siteUsersImportResultStepFailedStatusABORTED',
  },
  siteUsersImportResultStepFailedStatusEmpty: {
    defaultMessage: 'Data is not valid',
    description: 'Result step - failed status - EMPTY',
    id: 'siteUsersImportResultStepFailedStatusEMPTY',
  },
  siteUsersImportResultStepFailedStatusInternalError: {
    defaultMessage: 'Unknown error',
    description: 'Result step - failed status - INTERNAL_ERROR',
    id: 'siteUsersImportResultStepFailedStatusINTERNAL_ERROR',
  },
  siteUsersImportResultStepFailedStatusOk: {
    defaultMessage: 'OK',
    description: 'Result step - failed status - OK',
    id: 'siteUsersImportResultStepFailedStatusOK',
  },
  siteUsersImportResultStepFailedStatusUnknown: {
    defaultMessage: 'Unknown error',
    description: 'Result step - failed status - UNKNOWN',
    id: 'siteUsersImportResultStepFailedStatusUNKNOWN',
  },
  siteUsersImportResultStepFailedStatusWrongFormat: {
    defaultMessage: 'Data is not valid',
    description: 'Result step - failed status - WRONG_FORMAT',
    id: 'siteUsersImportResultStepFailedStatusWRONG_FORMAT',
  },
  siteUsersImportResultStepImportProcessResult: {
    defaultMessage: 'Import process result:',
    description: 'Section title of import process result.',
    id: 'siteUsersImportResultStepImportProcessResult',
  },
  siteUsersImportResultStepListOfImportErrors: {
    defaultMessage: 'List of import errors',
    description: '',
    id: 'siteUsersImportResultStepListOfImportErrors',
  },
  siteUsersImportResultStepRecordsCouldNotBeImported: {
    defaultMessage: '<strong>{count}</strong> {count, plural, one {record} other {records}} couldn’t be imported',
    description: '',
    id: 'siteUsersImportResultStepRecordsCouldNotBeImported',
  },
  siteUsersImportResultStepUsersImported: {
    defaultMessage: '<strong>{count}</strong> {count, plural, one {user} other {users}} imported',
    description: '',
    id: 'siteUsersImportResultStepUsersImported',
  },
  siteUsersImportResultStepWereCanceledAtRow: {
    defaultMessage: '<strong>{count}</strong> {count, plural, one {was} other {were}} canceled at row {rowNumber}',
    description: '',
    id: 'siteUsersImportResultStepWereCanceledAtRow',
  },
});
