import Box from '@mui/material/Box';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import landing1 from '../../assets/landing1.svg';
import landing2 from '../../assets/landing2.svg';
import landing3 from '../../assets/landing3.svg';
import messages from './messages';

const styles = {
  content: {
    height: '100%',
    maxWidth: '1050px',
    mx: 'auto',
    my: 0,
    position: 'relative',
    width: '100%',
  },
  list: (theme) => ({
    [theme.breakpoints.down('sm')]: {
      mt: 7,
    },
    '& li': {
      [theme.breakpoints.down('md')]: {
        '&:nth-of-type(3n)': {
          clear: 'both',
          width: '100%',
        },
        display: 'block',
        float: 'left',
        width: '50%',
      },
      '& img': {
        [theme.breakpoints.down('md')]: {
          maxHeight: '70px',
          maxWidth: '90px',
          mt: 3,
        },
        height: '110px',
        m: 'auto',
        maxHeight: '110px',
        maxWidth: '130px',
        mb: 4,
        mt: 0,
      },
      '& span': {
        [theme.breakpoints.down('lg')]: {
          fontSize: '18px',
          maxWidth: '195px',
        },
        [theme.breakpoints.down('md')]: {
          fontSize: '16px',
          maxWidth: '170px',
        },
        [theme.breakpoints.down('sm')]: {
          fontSize: '14px',
          px: 1,
          py: 0,
        },
        '& i': {
          [theme.breakpoints.down('xl')]: {
            left: '-25px',
          },
          [theme.breakpoints.down('md')]: {
            top: '-80px',
          },
          [theme.breakpoints.down('sm')]: {
            display: 'none',
          },
          bgcolor: 'primary.main',
          borderRadius: '100%',
          color: 'white',
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: 'regular',
          height: '44px',
          left: '-15px',
          lineHeight: '44px',
          position: 'absolute',
          textAlign: 'center',
          top: '-100px',
          width: '44px',
        },
        color: 'grey.900',
        display: 'block',
        fontSize: '20px',
        maxWidth: '150px',
        mx: 'auto',
        my: 0,
        position: 'relative',
        textAlign: 'center',
      },
      display: 'table-cell',
      m: 'auto',
      mb: 4,
      textAlign: 'center',
      width: 'auto',
    },
    display: 'table',
    flexWrap: 'wrap',
    justifyContent: 'center',
    listStyleType: 'none',
    m: 'auto',
    mb: 0,
    mt: 9,
    p: 0,
    width: '100%',
  }),
  root: (theme) => ({
    [theme.breakpoints.up('xl')]: {
      minHeight: '320px',
    },
    bgcolor: 'white',
    lineHeight: 1.5,
    p: 0,
    pb: 6,
    pt: 5,
    width: '100%',
  }),
  title: (theme) => ({
    [theme.breakpoints.down('md')]: {
      fontSize: '36px',
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      height: '32px',
    },
    '& strong': {
      color: 'primary.main',
    },
    color: 'grey.900',
    fontSize: '36px',
    fontWeight: 'regular',
    mb: 2,
    mt: 0,
    textAlign: 'center',
  }),
};

const Steps = () => (
  <Box sx={styles.root}>
    <Box sx={styles.content}>
      <Box component="h1" sx={styles.title}>
        <FormattedMessage values={{ strong: (text) => <strong>{text}</strong> }} {...messages.stepsTitle} />
      </Box>
      <Box component="ul" sx={styles.list}>
        <li>
          <img alt="Create My2N account" src={landing1} />
          <span>
            <FormattedMessage values={{ i: (chunks) => <i>{chunks}</i> }} {...messages.stepsCreateAccount} />
          </span>
        </li>
        <li>
          <img alt="Add 2N products" src={landing2} />
          <span>
            <FormattedMessage values={{ i: (chunks) => <i>{chunks}</i> }} {...messages.stepsAddProducts} />
          </span>
        </li>
        <li>
          <img alt="Start using My2N services" src={landing3} />
          <span>
            <FormattedMessage values={{ i: (chunks) => <i>{chunks}</i> }} {...messages.stepsActivateServices} />
          </span>
        </li>
      </Box>
    </Box>
  </Box>
);

export default Steps;
