import * as yup from 'yup';
import { getRfidCardOwner } from '../api/userApi';
import messages from './messages';

export function assignRfidCardValidationSchema(companyId, siteId, formatMessage, assignedRef) {
  return yup.object().shape({
    card: yup
      .string()
      .min(6, formatMessage(messages.assignRfidCardErrorWrongLength))
      .max(32, formatMessage(messages.assignRfidCardErrorWrongLength))
      .matches(/^[0-9A-F]+$/, formatMessage(messages.assignRfidCardErrorWrongFormat))
      .test({
        message: formatMessage(messages.assignRfidCardErrorCardAssigned),
        name: 'isAssigned',
        test: async (card) => {
          try {
            if (!card || card.length === 0) {
              return true;
            }
            const response = await getRfidCardOwner(companyId, siteId, card);
            if (response.status === 200) {
              if (!response.data?.userId) {
                return true;
              }
              // eslint-disable-next-line no-param-reassign
              assignedRef.current = response.data.userId;
            } else {
              // eslint-disable-next-line no-param-reassign
              assignedRef.current = '';
            }
            return response.status === 404;
          } catch (error) {
            return true;
          }
        },
      }),
  });
}
