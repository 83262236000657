import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { COOKIES, PATHS } from '../../config/consts';
import { useCookieConsent } from '../../helpers/cookieConsent';
import actions, { selectors } from '../../modules/cookies';
import CookieConsentComponent from './CookieConsentComponent';
import messages from './messages';

const getSettings = (system, functional, marketing) => [
  {
    checked: system || true,
    cookieName: COOKIES.SYSTEM,
    editable: false,
    info: <FormattedMessage {...messages.cookieConsentSettingsNecessaryInfo} />,
    title: <FormattedMessage {...messages.cookieConsentSettingsNecessaryLabel} />,
  },
  {
    checked: functional || false,
    cookieName: COOKIES.FUNCTIONAL,
    editable: true,
    info: <FormattedMessage {...messages.cookieConsentSettingsFunctionalInfo} />,
    title: <FormattedMessage {...messages.cookieConsentSettingsFunctionalLabel} />,
  },
  {
    checked: marketing || false,
    cookieName: COOKIES.MARKETING,
    editable: true,
    info: <FormattedMessage {...messages.cookieConsentSettingsMarketingInfo} />,
    title: <FormattedMessage {...messages.cookieConsentSettingsMarketingLabel} />,
  },
];

const CookieConsentPanel = ({ isSettingsOpen, setOpenCookieConsent }) => {
  const { cookieConsent, functional, marketing, setCookieConsent, system } = useCookieConsent();
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [screenHeight, setScreenHeight] = useState();
  const [settings, setSettings] = useState(getSettings(system, functional, marketing));

  useEffect(() => {
    if (isSettingsOpen) {
      setSettings(getSettings(system, functional, marketing));
    }
  }, [isSettingsOpen]);

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleAcceptAll = () => {
    const cookieSettings = {};
    settings.forEach((item) => {
      cookieSettings[item.cookieName] = true;
    });
    setCookieConsent(cookieSettings);

    setOpenCookieConsent(false);
  };

  const handleSaveSettings = () => {
    const cookieSettings = {};
    settings.forEach((item) => {
      cookieSettings[item.cookieName] = item.checked;
    });
    setCookieConsent(cookieSettings);

    setOpenCookieConsent(false);
  };

  const handleSettingsChange = (event) => {
    const index = settings.findIndex((item) => item.cookieName === event.target.name);
    const settingsArr = [...settings];
    settingsArr[index].checked = !settingsArr[index].checked;

    setSettings(settingsArr);
  };

  return (
    (!cookieConsent || isSettingsOpen) && (
      <CookieConsentComponent
        acceptButtonLabel={<FormattedMessage {...messages.cookieConsentButtonAcceptAll} />}
        fullHeightSettings
        info={<FormattedMessage {...messages.cookieConsentInfo} />}
        links={[
          {
            link: PATHS.COOKIE_POLICY_V2,
            title: <FormattedMessage {...messages.cookieConsentLinkCookies} />,
          },
        ]}
        maxSettingsHeight={screenHeight > 750 ? '360px' : '250px'}
        onAccept={handleAcceptAll}
        onSettingsChange={handleSettingsChange}
        onSettingsClick={cookieConsent ? () => setOpenCookieConsent(false) : () => setSettingsOpen(!settingsOpen)}
        onSettingsSave={handleSaveSettings}
        saveSettingsButtonLabel={<FormattedMessage {...messages.cookieConsentButtonSave} />}
        settings={settings}
        settingsButtonLabel={
          cookieConsent ? (
            <FormattedMessage {...messages.cookieConsentButtonClose} />
          ) : (
            <FormattedMessage {...messages.cookieConsentButtonSettings} />
          )
        }
        settingsOpen={cookieConsent ? isSettingsOpen : settingsOpen}
      />
    )
  );
};

CookieConsentPanel.propTypes = {
  isSettingsOpen: PropTypes.bool.isRequired,
  setOpenCookieConsent: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isSettingsOpen: selectors.getIsCookieSettingsOpen(state),
});

const mapDispatchToProps = {
  setOpenCookieConsent: actions.setCookieSettingsOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(CookieConsentPanel);
