import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const styles = {
  button: {
    mr: -1,
  },
  textWrapper: (theme) => ({
    [theme.breakpoints.down('xs')]: {
      marginBottom: 1,
    },
  }),
  wrapper: (theme) => ({
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  }),
};

const AddBatchItem = ({ onAddBatch }) => (
  <Grid alignItems="center" container sx={styles.wrapper}>
    <Grid item md={8} sm={6} sx={styles.textWrapper}>
      <Typography>
        <FormattedMessage
          {...messages.addBatchItemInfoText}
          values={{
            add: (
              <Box
                color="primary.main"
                component="span"
                onClick={onAddBatch}
                style={{
                  cursor: 'pointer',
                }}
              >
                <FormattedMessage {...messages.addBatchItemInfoAdd} />
              </Box>
            ),
          }}
        />
      </Typography>
    </Grid>
    <Grid container item justifyContent="flex-end" md={4} sm={6}>
      <Grid item sx={styles.button}>
        <Button color="primary" onClick={onAddBatch} startIcon={<AddIcon />}>
          <FormattedMessage {...messages.addBatchItemButton} />
        </Button>
      </Grid>
    </Grid>
  </Grid>
);

AddBatchItem.propTypes = {
  onAddBatch: PropTypes.func.isRequired,
};

export default AddBatchItem;
