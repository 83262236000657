import Grid from '@mui/material/Grid';
import React from 'react';
import { useSelector } from 'react-redux';
import { IMPORT_USERS_STATES } from '../../../config/consts';
import { selectors } from '../../../modules/user';
import SectionWrapper from '../SectionWrapper';
import ImportResult from './ImportResult';
import ImportResultDataGrid from './ImportResultDataGrid';

function ResultStep() {
  const currentImportUsersData = useSelector(selectors.currentImportUsersData);
  return (
    <SectionWrapper>
      <Grid container>
        <Grid item lg={4} md={12} xs={12}>
          <ImportResult />
        </Grid>
        {currentImportUsersData?.state !== IMPORT_USERS_STATES.SUCCESS && (
          <Grid item lg={8} md={12} xs={12}>
            <ImportResultDataGrid />
          </Grid>
        )}
      </Grid>
    </SectionWrapper>
  );
}

export default ResultStep;
