import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Stepper, Title } from '../../../components';
import { CURRENCY } from '../../../config/consts';
import { useUrlParams } from '../../../helpers/urlParams';
import { selectors as companySelectors } from '../../../modules/company';
import siteActions from '../../../modules/sites';
import actions, { selectors } from '../../../modules/subscription';
import { getMobileVideoAllowed, getSubscriptionStepsIntl } from '../helpers';
import messages from '../messages';
import styles from '../styles';
import OneStep from './OneStep/OneStep';
import ThreeStep from './ThreeStep/ThreeStep';
import TwoStep from './TwoStep/TwoStep';

const MobileVideoCode = ({
  activationCodeError,
  codeActivationError,
  codeCreditState,
  codeData,
  codeExpirationState,
  currency,
  invalidateCodeData,
  isCodeActivationLoading,
  isCodeVerificationLoading,
  isMobileVideoAllowed,
  isVatVerified,
  mobileVideoLicense,
  onActivateActivationCode,
  onCodeVerification,
  onLoadSiteSubscribed,
}) => {
  const { companyId, siteId } = useUrlParams();

  useEffect(() => {
    onLoadSiteSubscribed(companyId, siteId);

    return () => invalidateCodeData();
  }, [companyId, siteId]);

  const [step, setStep] = useState(1);
  const { formatMessage } = useIntl();
  return (
    <>
      <Title heading={<FormattedMessage {...messages.subscriptionMOBILE_VIDEO_CODETitle} />} />
      <Stepper activeStep={step} steps={getSubscriptionStepsIntl(formatMessage)} />
      <Paper sx={styles.root}>
        <>
          {step === 1 && (
            <OneStep
              activationCodeError={activationCodeError}
              codeCreditState={codeCreditState}
              codeData={codeData}
              codeExpirationState={codeExpirationState}
              isCodeVerificationLoading={isCodeVerificationLoading}
              isMobileVideoAllowed={isMobileVideoAllowed}
              isVatVerified={isVatVerified}
              mobileVideoLicense={mobileVideoLicense}
              onCodeVerification={onCodeVerification}
              onStepChange={setStep}
            />
          )}
          {(step === 2 || (step === 3 && isCodeActivationLoading)) && (
            <TwoStep
              codeCreditState={codeCreditState}
              codeData={codeData}
              codeExpirationState={codeExpirationState}
              currency={currency}
              isCodeActivationLoading={isCodeActivationLoading}
              isMobileVideoAllowed={isMobileVideoAllowed}
              isVatVerified={isVatVerified}
              mobileVideoLicense={mobileVideoLicense}
              onActivateCode={onActivateActivationCode}
              onStepChange={setStep}
            />
          )}
          {step === 3 && !isCodeActivationLoading && (
            <ThreeStep mobileVideoLicence={mobileVideoLicense} codeData={codeData} error={codeActivationError} />
          )}
        </>
      </Paper>
    </>
  );
};

MobileVideoCode.propTypes = {
  activationCodeError: PropTypes.shape({}).isRequired,
  codeActivationError: PropTypes.number.isRequired,
  codeCreditState: PropTypes.arrayOf(PropTypes.string).isRequired,
  codeData: PropTypes.shape({}).isRequired,
  codeExpirationState: PropTypes.string.isRequired,
  currency: PropTypes.oneOf(Object.keys(CURRENCY)).isRequired,
  invalidateCodeData: PropTypes.func.isRequired,
  isCodeActivationLoading: PropTypes.bool.isRequired,
  isCodeVerificationLoading: PropTypes.bool.isRequired,
  isMobileVideoAllowed: PropTypes.bool.isRequired,
  isVatVerified: PropTypes.bool.isRequired,
  mobileVideoLicense: PropTypes.shape({
    billingType: PropTypes.string,
  }).isRequired,
  onActivateActivationCode: PropTypes.func.isRequired,
  onCodeVerification: PropTypes.func.isRequired,
  onLoadSiteSubscribed: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  activationCodeError: selectors.getCodeVerificationError(state),
  codeActivationError: selectors.getCodeActivationError(state),
  codeCreditState: selectors.getCodeCreditState(state),
  codeData: selectors.getCodeData(state),
  codeExpirationState: selectors.getCodeExpirationState(state),
  currency: companySelectors.getCurrentCompanyCurrencySelector(state),
  isCodeActivationLoading: selectors.getIsCodeActivationLoading(state),
  isCodeVerificationLoading: selectors.getIsCodeVerificationLoading(state),
  isMobileVideoAllowed: getMobileVideoAllowed(state),
  isVatVerified: selectors.getIsVatVerified(state),
  mobileVideoLicense: selectors.getMobileVideoLicense(state),
});

const mapDispatchToProps = {
  invalidateCodeData: actions.invalidateCodeData,
  onActivateActivationCode: actions.activateActivationCode,
  onCodeVerification: actions.verifyActivationCode,
  onLoadSiteSubscribed: siteActions.getSiteDashboard,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileVideoCode);
