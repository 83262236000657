import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CallStatus, ListHeaderLabel } from '../../../components';
import messages from './messages';

const MobileVideoStatusInfo = ({ device }) => (
  <>
    <ListHeaderLabel>
      <FormattedMessage {...messages.mobileVideoStatusInfoLabel} />
    </ListHeaderLabel>
    <Box>
      <CallStatus size="small" status={device.callsStatus || ''} />
    </Box>
  </>
);

MobileVideoStatusInfo.propTypes = {
  device: PropTypes.shape({
    callsStatus: PropTypes.string,
  }).isRequired,
};

export default MobileVideoStatusInfo;
