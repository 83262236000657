import { MessageDescriptor } from 'react-intl';
import { ICallSettingOption } from '../hooks/useGetStateData';
import messages from './messages';

export function getPlaceholder(
  maxLength: number,
  formatMessage: (desc: MessageDescriptor) => string,
  isApartmentInCallSettingsFFEnabled: boolean,
  selectedOptions?: ICallSettingOption[]
): string | undefined {
  if (!isApartmentInCallSettingsFFEnabled) {
    return formatMessage(messages.placeholderAddDevice);
  }

  if (selectedOptions === undefined || selectedOptions.length === 0) {
    return formatMessage(messages.placeholderEmpty);
  }

  if (selectedOptions.length > 1 && selectedOptions.length < maxLength) {
    return formatMessage(messages.placeholderAddDevice);
  }

  if (selectedOptions[0].optionKind === 'Devices') {
    return formatMessage(messages.placeholderAddDevice);
  }

  return formatMessage(messages.placeholderSwapApartment);
}

export function getChipColor(option: ICallSettingOption) {
  if (option.optionState !== undefined && option.optionState === 'warning') {
    return 'warning';
  }

  if (option.hasUpdated) {
    return 'primary';
  }

  return 'default';
}
