import React from 'react';
import { DEVICE_TYPE } from '../../../config/devices';
import CallSettingsDataGrid from '../CallSettingsDataGrid';

const Intercoms = () => (
  <CallSettingsDataGrid
    types={[
      DEVICE_TYPE.DOOR_STATION,
      DEVICE_TYPE.IP_INTERCOM,
      DEVICE_TYPE.IP_INTERCOM_V2,
      DEVICE_TYPE.IP_ONE_INTERCOM,
      DEVICE_TYPE.IP_STYLE,
    ]}
  />
);

export default Intercoms;
