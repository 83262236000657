import React from 'react';
import { useIntl } from 'react-intl';
import NoRowsOverlayDataGrid from '../../../components/DataGrid/NoRowsOverlay';
import messages from './messages';

function NoRowsOverlay() {
  const { formatMessage } = useIntl();
  return (
    <NoRowsOverlayDataGrid
      headerText={formatMessage(messages.accessGroupApartmentsDataGridNoRowsOverlayHeaderText)}
      text={formatMessage(messages.accessGroupApartmentsDataGridNoRowsOverlayText, { br: <br /> })}
    />
  );
}

export default NoRowsOverlay;
