import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { CURRENCY } from '../../config/consts';

const Currency = ({ currency, price }) => {
  const { formatNumber } = useIntl();
  return formatNumber(price, { currency, style: 'currency' });
};

Currency.propTypes = {
  currency: PropTypes.oneOf(Object.keys(CURRENCY)).isRequired,
  price: PropTypes.number.isRequired,
};

export default Currency;
