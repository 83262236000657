import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { AREA_ID, WALKING_TIME } from '../../config/consts';
import Slider from '../HookFormFields/Slider';
import messages from './messages';

function DistanceMessage({ disabled, time }: { time: number; disabled: boolean }) {
  function getMessage() {
    switch (time) {
      case 0:
        return messages.distanceVeryShort;
      case 1:
        return messages.distanceShort;
      case 2:
        return messages.distanceLong;
      default:
        return messages.distanceVeryLong;
    }
  }

  return (
    <FormattedMessage
      {...getMessage()}
      values={{
        box: (chunks) => (
          <Box component="span" color={disabled ? 'text.disabled' : 'text.secondary'}>
            {chunks}
          </Box>
        ),
      }}
    />
  );
}

interface ISettingsFormSlider {
  deviceStopIndex: number;
}

function SettingsFormSlider({ deviceStopIndex }: ISettingsFormSlider) {
  const { watch } = useFormContext();
  const areaId = watch(`${deviceStopIndex}${AREA_ID}`);
  const walkingTime = watch(`${deviceStopIndex}${WALKING_TIME}`);
  const sliderName = `${deviceStopIndex}${WALKING_TIME}` as never;

  return (
    <Stack direction="column">
      <Typography gutterBottom>
        <FormattedMessage {...messages.settingsFormDistanceLabel} />
      </Typography>
      <Stack direction="row" spacing={3} alignItems="center">
        <Slider
          name={sliderName}
          sx={{ width: 164 }}
          marks
          max={3}
          min={0}
          step={1}
          valueLabelDisplay="off"
          disabled={!areaId}
        />
        <Box color={!areaId ? 'text.disabled' : 'inherit'}>
          <DistanceMessage time={walkingTime} disabled={!areaId} />
        </Box>
      </Stack>
    </Stack>
  );
}

export default SettingsFormSlider;
