import { ICallSettingOption } from '../../../DeviceDetail/CallSettings/Intercom/hooks/useGetStateData';
import { IDeviceWithCallSettingsDetails } from '../hooks/useGetCallSettingsState';
import { IDeviceCallSettings } from './types';

export function canAddCallDestination(
  currentDeviceId: number,
  ringingGroupSizeLimit: number,
  selectedOptions?: ICallSettingOption[]
): boolean {
  if (selectedOptions === undefined || selectedOptions.length === 0) {
    return true;
  }

  if (selectedOptions[0].optionKind === 'Apartments') {
    return false;
  }

  if (selectedOptions.findIndex((option) => option.optionKey === currentDeviceId) >= 0) {
    return false;
  }

  if (selectedOptions.length >= ringingGroupSizeLimit) {
    return false;
  }

  return true;
}

export function canAddDevice(
  devicesCallSettings: IDeviceCallSettings[],
  availableDevices: IDeviceWithCallSettingsDetails[]
): boolean {
  const availableDevicesCount = getDevicesWithCallSettings(availableDevices).length;
  if (isSomeDeviceSelected(devicesCallSettings)) {
    return devicesCallSettings.length < availableDevicesCount;
  }
  return availableDevicesCount > 1;
}

export function getDevicesWithCallSettings(availableDevices: IDeviceWithCallSettingsDetails[]) {
  return availableDevices.filter((device) => device.canSetCallSettings);
}

function isSomeDeviceSelected(devicesCallSettings: IDeviceCallSettings[]): boolean {
  return devicesCallSettings.some((device) => device.selected);
}

export function shouldDisableAddDeviceButton(devicesCallSettings: IDeviceCallSettings[]): boolean {
  return devicesCallSettings.some((device) => !device.selected);
}
