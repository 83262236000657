import { useDispatch, useSelector } from 'react-redux';
import { getDataGridSelector } from '../../helpers/dataGrid';
import commonActions from '../../modules/common';
import actions from '../../modules/devices';
import { devicesSelectors, NAME as DEVICE_MODULE } from '../../modules/devices';
import { selectors as elevatorIntegrationSelectors } from '../../modules/elevatorIntegration';

export function useGetStateData() {
  const devicesTable = useSelector((state) => getDataGridSelector(state, DEVICE_MODULE, 'list'));
  const hasSiteElevatorIntegration = useSelector(elevatorIntegrationSelectors.isSiteElevatorIntegrationActive);
  const notVerifiedDevicesTable = useSelector((state) =>
    getDataGridSelector(state, DEVICE_MODULE, 'notVerifiedDevices')
  );
  const waitingDevicesTable = useSelector((state) => getDataGridSelector(state, DEVICE_MODULE, 'waitingDevices'));
  const hasDefaultPasswordDevices = useSelector(devicesSelectors.getDefaultPasswordDevices);

  return {
    devicesTable,
    hasDefaultPasswordDevices,
    hasSiteElevatorIntegration,
    notVerifiedDevicesTable,
    waitingDevicesTable,
  };
}

export function useGetDispatchActions() {
  const dispatch = useDispatch();

  const onLoadDevices = (args: any[], hasElevatorIntegration?: string) =>
    dispatch(actions.getDevicesListRequest(...args, hasElevatorIntegration));
  const onLoadNotVerifiedDevices = (companyId: number, siteId: number) =>
    dispatch(actions.getNotVerifiedDevicesRequest(companyId, siteId));
  const onLoadDefaultPasswordDevices = (companyId: number, siteId: number) =>
    dispatch(actions.getDefaultPasswordDevices(companyId, siteId));
  const onLoadWaitingDevices = (companyId: number, siteId: number) =>
    dispatch(actions.getWaitingDevicesListRequest(companyId, siteId));
  const onSearch = (onLoadMethod: () => void, data: object) => dispatch(commonActions.search(onLoadMethod, data));
  const onVerifyDeviceShowDialog = (items: any) => dispatch(actions.verifyDeviceShowModal(items));

  return {
    onLoadDefaultPasswordDevices,
    onLoadDevices,
    onLoadNotVerifiedDevices,
    onLoadWaitingDevices,
    onSearch,
    onVerifyDeviceShowDialog,
  };
}
