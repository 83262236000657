import types from './types';

export const editBillingShowModal = () => ({
  type: types.EDIT_BILLING_SHOW_MODAL,
});
export const editBillingHideModal = () => ({
  type: types.EDIT_BILLING_HIDE_MODAL,
});
export const editBillingFormRequest = (values) => ({
  type: types.EDIT_BILLING_FORM_REQUEST,
  values,
});
