import { defineMessages } from 'react-intl';

export default defineMessages({
  addCompanySnackbarCompanyAddedSuccessfully: {
    defaultMessage: 'Company added successfully.',
    description: 'Add company snackbar - Company added successfully',
    id: 'addCompanySnackbarCompanyAddedSuccessfully',
  },
  companySnackbarDeletedSuccessfully: {
    defaultMessage: 'Company deleted successfully.',
    description: 'Company snackbar - Company deleted successfully',
    id: 'companySnackbarDeletedSuccessfully',
  },
  companySnackbarMVSDKSettingsRemovedSuccessfully: {
    defaultMessage: 'Custom Mobile Video SDK settings removed successfully.',
    description: 'Company snackbar - Custom Mobile Video SDK settings removed successfully',
    id: 'companySnackbarMVSDKSettingsRemovedSuccessfully',
  },
  companySnackbarMVSDKSettingsSavedSuccessfully: {
    defaultMessage: 'Mobile Video SDK settings saved successfully.',
    description: 'Company snackbar - Mobile Video SDK settings saved successfully',
    id: 'companySnackbarMVSDKSettingsSavedSuccessfully',
  },
  companySnackbarSettingsSavedSuccessfully: {
    defaultMessage: 'Company settings were saved.',
    description: 'Company snackbar - Company settings were saved',
    id: 'companySnackbarSettingsSavedSuccessfully',
  },
});
