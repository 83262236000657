import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem, { MenuItemProps } from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import React from 'react';

interface IRowMenuProps extends MenuItemProps {
  colorIcon?: string;
  colorText?: string;
  icon: JSX.Element;
  label: React.ReactNode;
}

const RowMenuItem = ({ colorIcon, colorText, icon, label, ...other }: IRowMenuProps) => (
  <MenuItem {...other} sx={{ minWidth: '280px' }}>
    <ListItemIcon
      sx={{
        '&.MuiListItemIcon-root': {
          color: other?.disabled ? 'text.disabled' : colorIcon,
        },
      }}
    >
      {icon}
    </ListItemIcon>
    <ListItemText>
      <Typography sx={{ color: other?.disabled ? 'text.disabled' : colorText }} variant="subtitle2">
        {label}
      </Typography>
    </ListItemText>
  </MenuItem>
);

RowMenuItem.defaultProps = {
  colorIcon: 'secondary.main',
  colorText: 'inherit',
};

export default RowMenuItem;
