import Stack from '@mui/material/Stack';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { FORM } from '../../config/consts';
import TextField from '../HookFormFields/TextField';
import messages from './messages';

function UsersForm() {
  const { control, getValues } = useFormContext();
  const { formatMessage } = useIntl();
  const isNewItemWithoutLogin = getValues().users.some((formValue) => formValue.id === FORM.NEW_ITEM_WITHOUT_LOGIN_ID);
  return (
    <Stack direction="column" spacing={2} sx={{ bgcolor: 'background.default', p: 2 }}>
      <TextField
        autoFocus // closes UsersAutocomplete
        control={control}
        label={formatMessage(messages.usersAutocompleteUsersFormEmail)}
        name="email"
        required
        sx={isNewItemWithoutLogin ? { display: 'none' } : {}}
      />
      <Stack direction="row" spacing={2}>
        <TextField
          autoFocus={isNewItemWithoutLogin} // closes UsersAutocomplete
          control={control}
          fullWidth
          label={formatMessage(messages.usersAutocompleteUsersFormFirstName)}
          name="firstName"
          required={!isNewItemWithoutLogin}
        />
        <TextField
          control={control}
          fullWidth
          label={formatMessage(messages.usersAutocompleteUsersFormLastName)}
          name="lastName"
          required
        />
      </Stack>
    </Stack>
  );
}

export default UsersForm;
