import http, { encode } from './index';

export const startTrialVersion = async (company, site) =>
  http.post(`/companies/${encode(company)}/sites/${encode(site)}/services/MOBILE_VIDEO/licence`, {
    action: 'ACTIVATE_TRIAL',
  });

export const getSitePayments = async (company, site, params) =>
  http.get(`/companies/${encode(company)}/sites/${encode(site)}/invoices`, { params });

export const getCompanyPayments = async (company, params) =>
  http.get(`/companies/${encode(company)}/invoices`, { params });

export const getSuperadminPayments = async (params) => http.get('/private/payments', { params });

export const getPaymentPromoDetail = async (company, paymentId, promoId) =>
  http.get(`/companies/${encode(company)}/purchase/payments/${encode(paymentId)}/results/${encode(promoId)}`);
