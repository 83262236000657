import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { USERS_IMPORT_TEMPLATE_PREDEFINED } from '../../../config/address';
import SectionTemplate from '../SectionTemplate';
import FaqLink from './FaqLink';
import messages from './messages';

function Detailed({ onHelpModalOpen }) {
  const { formatMessage } = useIntl();
  return (
    <SectionTemplate title={formatMessage(messages.siteUsersImportIntroStepDetailedTitle)}>
      <Typography component="div" variant="body1">
        <FormattedMessage
          values={{
            li: (chunks) => <li>{chunks}</li>,
            LinkModal: (chunks) => (
              <Link color="primary" component="button" onClick={onHelpModalOpen} variant="body1">
                {chunks}
              </Link>
            ),
            LinkTemplate: (chunks) => (
              <Link
                color="primary"
                href={`${process.env.PUBLIC_URL}${USERS_IMPORT_TEMPLATE_PREDEFINED}`}
                variant="body1"
              >
                {chunks}
              </Link>
            ),
            p: (chunks) => <p>{chunks}</p>,
            ul: (chunks) => <ul>{chunks}</ul>,
          }}
          {...messages.siteUsersImportIntroStepDetailedInfo}
        />
      </Typography>
    </SectionTemplate>
  );
}

Detailed.propTypes = {
  onHelpModalOpen: PropTypes.func.isRequired,
};

export default Detailed;
