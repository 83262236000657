import { Dialog } from '@2n/design-system';
import Box from '@mui/material/Box';
import React from 'react';
import { IActionModalData } from './ActionModal';

export function ActionModalError(data: IActionModalData) {
  return (
    <Dialog
      footerOptions={{
        primaryButton:
          data.primaryButtonAction && data.primaryButtonText !== undefined
            ? {
                action: data.primaryButtonAction,
                color: 'error',
                icon: {
                  icon: data.icon,
                  iconPosition: 'start',
                },
                isDisabled: data.isPrimaryButtonDisabled,
                text: data.primaryButtonText,
                variant: 'outlined',
              }
            : undefined,
        secondaryButton:
          data.secondaryButtonText !== undefined
            ? {
                action: data.onClose,
                color: 'secondary',
                text: data.secondaryButtonText,
                variant: 'text',
              }
            : undefined,
      }}
      muiDialogProps={{
        ...data.muiDialogProps,
        sx: {
          ...data.muiDialogProps?.sx,
          '& .MuiDialogTitle-root': {
            color: 'error.main',
          },
        },
      }}
      onClose={data.onClose}
      open={!!data.open}
      title={data.title}
    >
      <Box data-testid={data.testId}>{data.children}</Box>
    </Dialog>
  );
}

export default ActionModalError;
