import { defineMessages } from 'react-intl';

export default defineMessages({
  deviceRejectModalCancel: {
    defaultMessage: 'Cancel',
    description: 'Device reject modal - cancel button',
    id: 'deviceRejectModal.cancel',
  },
  deviceRejectModalQuestion: {
    defaultMessage: 'Device will not be added to My2N.',
    description: 'Device reject modal - question',
    id: 'deviceRejectModal.question',
  },
  deviceRejectModalSubmit: {
    defaultMessage: 'Reject device',
    description: 'Device reject modal - submit button',
    id: 'deviceRejectModal.submit',
  },
  deviceRejectModalTitle: {
    defaultMessage: 'Reject device',
    description: 'Device reject modal - title',
    id: 'deviceRejectModal.title',
  },
});
