import { InfoBar, InfoBarType, InfoBarVariant } from '@2n/design-system';
import React from 'react';

export interface IErrorBarData {
  title?: string;
  children: React.ReactNode;
}

export function FilledErrorBar(data: IErrorBarData) {
  return (
    <InfoBar
      variant={InfoBarVariant.standard}
      type={InfoBarType.error}
      title={data.title || undefined}
      sx={{
        backgroundColor: 'error.alert.background',
        color: 'error.text',
      }}
    >
      {data.children}
    </InfoBar>
  );
}
