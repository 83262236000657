import { RolesType, TId } from '../commonTypes';
import { TApartmentRole } from './store';

export const apartmentUserHandler = (apartmentId: TId, rolesType: RolesType, roles: Array<TApartmentRole>) => ({
  [rolesType]: {
    apartmentRoles: [
      {
        apartmentId,
        types: roles,
      },
    ],
  },
});
