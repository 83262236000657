import { ArrowBack } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import React from 'react';
import useBreakpoints from '../../helpers/useBreakpoints';
import { ApartmentUserRole } from '../../modules/apartmentAdmin/data';
import { IUser } from '../../modules/user/store';
import { getUserName } from '../../modules/user/utils';
import { TypographyWithEllipsisTooltip } from '../TypographyWithEllipsisTooltip';
import UserAvatarIcon from './UserAvatarIcon';
import { isAvatarIconVisible } from './utils';

export interface IUserInfoProps {
  role: IUser['role'] | ApartmentUserRole;
  firstName?: string;
  lastName: string;
  onBackButtonClick: (() => void) | undefined;
}

export function UserInfo(props: IUserInfoProps) {
  const { downSm } = useBreakpoints();

  return (
    <>
      {props.onBackButtonClick && (
        <Box pr={0.5}>
          <IconButton size="medium" onClick={props.onBackButtonClick}>
            <ArrowBack />
          </IconButton>
        </Box>
      )}
      {isAvatarIconVisible(downSm, !!props.onBackButtonClick) && (
        <Box pr={2}>
          <UserAvatarIcon userRole={props.role} />
        </Box>
      )}
      <TypographyWithEllipsisTooltip sx={{ mb: 0 }} variant="h4">
        {getUserName({ firstName: props.firstName, lastName: props.lastName || '' })}
      </TypographyWithEllipsisTooltip>
    </>
  );
}
