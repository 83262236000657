import { useSelector } from 'react-redux';
import { getOryLoginStatusSelector } from '../../../modules/auth/selectors';
import { OryLoginStatus } from '../../types';

export interface IUseOryChangeProfileModalOpenerProps {
  openModalForOryUser: () => void;
  openModalForMy2nUser: () => void;
}

export interface IUseOryChangeProfileModalOpener {
  openChangeProfileModal: () => void;
}

export function useOryChangeProfileModalOpener(
  props: IUseOryChangeProfileModalOpenerProps
): IUseOryChangeProfileModalOpener {
  const oryLoginStatus = useSelector(getOryLoginStatusSelector);

  const openChangeProfileModal = () => {
    if (oryLoginStatus === OryLoginStatus.SessionConfirmed) {
      props.openModalForOryUser();
      return;
    }

    props.openModalForMy2nUser();
  };

  return {
    openChangeProfileModal,
  };
}
