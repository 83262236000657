import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import IconMaterial from '../../../IconMaterial';
import messages from '../../messages';
import { styles as buttonStyles } from '../../styles/commonButton';

const styles = {
  mainButton: {
    height: '100%',
    maxWidth: { md: 320, xs: 200 },
    p: 2,
    pl: 3,
  },
  wrapperActive: {
    backgroundColor: 'appBar.companyActive',
  },
};

const CompanyButton = ({ active, anchorRef, companyName, onClickButton, open }) => (
  <Box
    sx={{
      ...buttonStyles.wrapper,
      ...(active && styles.wrapperActive),
    }}
  >
    <ButtonBase
      aria-controls="company-menu-list"
      aria-haspopup="true"
      disableRipple
      margin="none"
      onClick={onClickButton}
      ref={anchorRef}
      sx={styles.mainButton}
    >
      <Box
        sx={{
          ...buttonStyles.icon,
          ...buttonStyles.commonColor,
          ...(!active && buttonStyles.inactiveOpacity),
        }}
      >
        <IconMaterial name="business" />
      </Box>
      <Box sx={buttonStyles.mainButtonHeading}>
        {companyName ? (
          <>
            <Box sx={{ display: { lg: 'block', xs: 'none' } }}>
              <Typography>
                <FormattedMessage {...messages.companyButtonCompany} />
              </Typography>
            </Box>
            <Typography
              sx={{
                ...buttonStyles.mainName,
                ...buttonStyles.commonColor,
                ...(!active && buttonStyles.inactiveOpacity),
              }}
            >
              {companyName}
            </Typography>
          </>
        ) : (
          <Typography>
            <FormattedMessage {...messages.companyButtonEmptyCompany} />
          </Typography>
        )}
      </Box>
      {companyName && (
        <Box
          sx={{
            ...buttonStyles.commonColor,
            ...buttonStyles.inactiveOpacity,
          }}
        >
          {open ? <IconMaterial name="arrow_drop_up" /> : <IconMaterial name="arrow_drop_down" />}
        </Box>
      )}
    </ButtonBase>
  </Box>
);

CompanyButton.propTypes = {
  active: PropTypes.bool.isRequired,
  anchorRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({
      current: PropTypes.instanceOf(Element),
    }),
  ]).isRequired,
  companyName: PropTypes.string.isRequired,
  onClickButton: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default CompanyButton;
