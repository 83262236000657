import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { IMPORT_USERS_STATES } from '../../../config/consts';
import { useUrlParams } from '../../../helpers/urlParams';
import actions, { selectors } from '../../../modules/user';
import messages from './messages';

const styles = {
  linearProgress: (theme) => ({
    height: theme.spacing(4),
  }),
  resume: {
    color: 'secondary.main',
    pb: 6,
  },
};

function ProcessingImport({ onDone, setActiveStep }) {
  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);
  const timer = useRef(null);
  const { companyId, siteId } = useUrlParams();
  const currentImportUsersData = useSelector(selectors.currentImportUsersData);

  useEffect(() => {
    if (currentImportUsersData?.state !== IMPORT_USERS_STATES.PROCESSING) {
      dispatch(actions.importUsersFinish());
      setTimeout(() => {
        onDone();
        setActiveStep();
      }, 1000);
    }
  }, [currentImportUsersData]);

  useEffect(() => {
    timer.current = setInterval(() => {
      checkImportStatus();
    }, 2000);
    return () => {
      setProgress(0);
      clearInterval(timer.current);
    };
  }, []);

  function checkImportStatus() {
    dispatch(actions.getImportUsersStatus(companyId, siteId));
  }

  const sumTotal = () => currentImportUsersData?.total || 0;

  const sumProcessed = () => (currentImportUsersData?.processed || 0) + (currentImportUsersData?.failed || 0);

  useEffect(() => {
    if (sumTotal() > 0) {
      setProgress(Math.round((sumProcessed() / sumTotal()) * 100));
    }
  }, [currentImportUsersData]);

  return (
    <Stack spacing={1}>
      <Typography>
        <FormattedMessage {...messages.SiteUsersImportProcessingImportProcessing} />
      </Typography>
      <LinearProgress color="success" sx={styles.linearProgress} value={progress} variant="determinate" />
      {sumTotal() > 0 && (
        <Typography sx={styles.resume}>
          <FormattedMessage
            {...messages.SiteUsersImportProcessingImportResume}
            values={{
              processed: sumProcessed(),
              total: sumTotal(),
            }}
          />
        </Typography>
      )}
    </Stack>
  );
}

ProcessingImport.propTypes = {
  onDone: PropTypes.func.isRequired,
  setActiveStep: PropTypes.func.isRequired,
};

export default ProcessingImport;
