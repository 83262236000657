import { defineMessages } from 'react-intl';

export default defineMessages({
  PaidServicesRowMenuTooltipDisable: {
    defaultMessage: 'Deactivates paid services for the apartment{br}Another apartment can be activated instead',
    id: 'paidServicesRowMenu.tooltip.disable',
  },
  paidServicesRowMenuTooltipDisableActivableAndExpirable: {
    defaultMessage: 'Deactivates paid services{br}for the apartment',
    id: 'paidServicesRowMenu.tooltip.disable.activableAndExpirable',
  },
  paidServicesRowMenuTooltipDisableTrial: {
    defaultMessage: 'Deactivates paid services{br}for the apartment',
    id: 'paidServicesRowMenu.tooltip.disable.trial',
  },
  PaidServicesRowMenuTooltipEnable: {
    defaultMessage: 'Activates paid services for the apartment{br}and immediately uses available credits',
    id: 'paidServicesRowMenu.tooltip.enable',
  },
  paidServicesRowMenuTooltipEnableActivableAndExpirable: {
    defaultMessage: 'Activates paid services{br}for the apartment',
    id: 'paidServicesRowMenu.tooltip.enable.activableAndExpirable',
  },
  paidServicesRowMenuTooltipEnableTrial: {
    defaultMessage: 'Activates paid services without using{br}credits during your trial period',
    id: 'paidServicesRowMenu.tooltip.enable.trial',
  },
});
