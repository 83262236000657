import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import ProgressModal from '../../../components/_DesignSystemWrappers/Modal/ProgressModal';
import { useUrlParams } from '../../../helpers/urlParams';
import actions from '../../../modules/user';
import ProcessingImport from './index';
import messages from './messages';

function ImportModal({ onClose, open, setActiveStep }) {
  const dispatch = useDispatch();
  const { companyId, siteId } = useUrlParams();
  const { formatMessage } = useIntl();

  function stopImport() {
    dispatch(actions.importUsersAbort(companyId, siteId));
    return onClose;
  }

  return (
    <ProgressModal
      buttonColor="error"
      primaryButtonText={formatMessage(messages.SiteUsersImportImportModalStopImport)}
      onClose={stopImport}
      open={open}
      title={formatMessage(messages.SiteUsersImportModalImportUsers)}
    >
      <ProcessingImport onDone={onClose} setActiveStep={setActiveStep} />
    </ProgressModal>
  );
}

ImportModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  setActiveStep: PropTypes.func.isRequired,
};

export default ImportModal;
