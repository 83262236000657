import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DataGrid, DeviceModelName, DeviceTypeIcon } from '../../../components';
import NameColumn from '../../../components/DataGrid/NameColumn';
import { PATHS } from '../../../config/consts';
import { dataGridProps, getDataGridSelector, useDataGrid } from '../../../helpers/dataGrid';
import { useUrlParams } from '../../../helpers/urlParams';
import deviceActions, { NAME as DEVICE_MODULE } from '../../../modules/devices';
import { isFetchingMessages } from '../../../modules/localization/selectors';
import messages from '../messages';
import NoRowsOverlay from './NoRowsOverlay';

const CallSettingsDataGrid = ({ dataGrid, onLoadDevices, types }) => {
  const { companyId, siteId } = useUrlParams();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const isLocalizationLoaded = useSelector(isFetchingMessages);

  const { onOrderBy, onRowsPerPageChange, onSelectPage } = useDataGrid(
    onLoadDevices,
    {
      companyId,
      filter: { types: types.join(',') },
      order: dataGrid.order,
      page: dataGrid.page,
      rowsPerPage: dataGrid.rowsPerPage,
      siteId,
    },
    dataGrid.didInvalid,
    dataGrid.isFetching
  );

  const columns = useMemo(
    () => [
      {
        disableColumnMenu: true,
        field: 'name',
        flex: 1,
        headerName: formatMessage(messages.callSettingsDataGridColumnsDeviceName),
        renderCell: (cell) =>
          dataGrid.isFetching ? (
            <Skeleton animation="wave" height={25} width="30%" />
          ) : (
            <Tooltip title={<DeviceModelName deviceType={cell.value?.deviceType} type={cell.value?.type} />}>
              <Box
                component="span"
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  overflow: 'hidden',
                }}
              >
                <NameColumn
                  iconComponent={<DeviceTypeIcon deviceType={cell.value?.deviceType} small type={cell.value?.type} />}
                  name={cell.value?.name}
                />
              </Box>
            </Tooltip>
          ),
        sortable: true,
      },
    ],
    [dataGrid.isFetching, isLocalizationLoaded]
  );

  const rows = useMemo(
    () =>
      dataGrid.data.map((device) => ({
        id: device.id,
        isClickable: true,
        name: device,
      })),
    [dataGrid.data]
  );

  return (
    <DataGrid
      columns={columns}
      disableRowSelectionOnClick
      hideFooterPagination={dataGrid.isFetching}
      loading={dataGrid.isFetching}
      noRowsOverlay={<NoRowsOverlay />}
      onCellClick={(params) => {
        if (params.field !== 'rowMenu') {
          navigate(
            PATHS.DEVICE_CALL_SETTINGS.replace(':companyId', companyId)
              .replace(':siteId', siteId)
              .replace(':deviceId', params.value?.id)
          );
        }
      }}
      onOrderBy={onOrderBy}
      onRowsPerPageChange={onRowsPerPageChange}
      onSelectPage={onSelectPage}
      order={dataGrid.order}
      page={dataGrid.page}
      resultsFiltered={dataGrid.resultsFiltered}
      resultsTotal={dataGrid.resultsTotal}
      rows={rows}
      rowsPerPage={dataGrid.rowsPerPage}
    />
  );
};

CallSettingsDataGrid.propTypes = {
  dataGrid: dataGridProps.isRequired,
  onLoadDevices: PropTypes.func.isRequired,
  types: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (state) => ({
  dataGrid: getDataGridSelector(state, DEVICE_MODULE, 'callSettingsDevices'),
});

const mapDispatchToProps = {
  onLoadDevices: deviceActions.getCallSettingsDevices,
};

export default connect(mapStateToProps, mapDispatchToProps)(CallSettingsDataGrid);
