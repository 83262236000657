import { Delete } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ActionModalError } from '../../../../../../../components/_DesignSystemWrappers/Modal';
import { ApartmentUser } from '../../../../../../../modules/apartmentAdmin/data';
import { IApartmentIdsInfo } from '../../../../../../../modules/apartments/store';
import { IModalBase } from '../../../../../../../modules/commonTypes';
import { UWLName } from '../../../../../components/UWLName';
import { useDeleteRfid } from '../../hooks/useDeleteRfid';
import { TestID } from '../../testIds';
import messages from './messages';

export interface IUWLDeleteRFIDModalProps extends IModalBase {
  user: ApartmentUser;
  apartmentIdsInfo: IApartmentIdsInfo;
}

export function UWLDeleteRFIDModal({
  apartmentIdsInfo: { apartmentId, companyId, siteId },
  onClose,
  open,
  user,
}: IUWLDeleteRFIDModalProps) {
  const { formatMessage } = useIntl();
  const { isSubmitting, onDeleteHandler } = useDeleteRfid({
    apartmentIdsInfo: { apartmentId, companyId, siteId },
    onClose,
    user,
  });

  return (
    <ActionModalError
      icon={<Delete />}
      onClose={onClose}
      testId={TestID.UWLDeleteRfidModal}
      muiDialogProps={{
        maxWidth: 'xs',
      }}
      open={open}
      primaryButtonAction={onDeleteHandler}
      primaryButtonText={formatMessage(messages.AAmodalUwLDeleteRFIDSubmit)}
      secondaryButtonText={formatMessage(messages.AAmodalUwLDeleteRFIDCancel)}
      isPrimaryButtonDisabled={isSubmitting}
      title={formatMessage(messages.AAmodalUwLDeleteRFIDTitle)}
    >
      <Stack spacing={1}>
        <UWLName name={{ firstName: user.firstName, lastName: user.lastName }} />
        <Typography variant="body2" component="span">
          <FormattedMessage {...messages.AAmodalUwLDeleteRFIDText} />
        </Typography>
      </Stack>
    </ActionModalError>
  );
}
