import { defineMessages } from 'react-intl';

export default defineMessages({
  userAccessGroupsListEmpty: {
    defaultMessage: 'No access groups',
    description: 'User access groups list - empty placeholder',
    id: 'userAccessGroupsList.empty',
  },
  userAccessGroupsListLabel: {
    defaultMessage: 'User settings',
    description: 'User access groups list - list label',
    id: 'userAccessGroupsList.label',
  },
});
