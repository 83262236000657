import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert } from '../../../../components';
import messages from './messages';

const ManualWarningAlert = () => (
  <Alert severity="warning">
    <FormattedMessage {...messages.manualWarningAlertWarning} />
  </Alert>
);

export default ManualWarningAlert;
