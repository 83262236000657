import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert } from '../../../components';
import { AVQ_SUPPORT } from '../../../config/consts';
import { supportsAVQ } from '../helpers';
import FirmwareModal from './FirmwareModal';
import messages from './messages';
import UpgradeButton from './UpgradeButton';

const AdaptiveVideoQualityNotification = ({ firmware, onUpdateFirmware }) => {
  const {
    info: { haveUpdate, newFirmware },
    isUpdating,
  } = firmware;
  const [openFWUpdateModal, setOpenFWUpdateModal] = useState(false);

  const statusProps = useMemo(() => {
    const firmwareSupportsAVQ = newFirmware?.version ? supportsAVQ(newFirmware.version) : false;

    if (firmwareSupportsAVQ) {
      return {
        description: messages.versionNotificationAvqWarningFwSupportDescription,
        title: messages.versionNotificationAvqWarningFwSupportTitle,
      };
    }
    if (haveUpdate) {
      return {
        description: messages.versionNotificationAvqWarningFwNoSupportUpgradeAvailableDescription,
        title: messages.versionNotificationAvqWarningFwNoSupportUpgradeAvailableTitle,
      };
    }
    return {
      description: messages.versionNotificationAvqWarningFwNoSupportUpgradeNotAvailableDescription,
      title: messages.versionNotificationAvqWarningFwNoSupportUpgradeNotAvailableTitle,
    };
  }, [newFirmware?.version, haveUpdate]);

  return (
    <>
      <Alert
        action={haveUpdate ? <UpgradeButton disabled={isUpdating} onClick={setOpenFWUpdateModal} /> : null}
        severity="warning"
        title={<FormattedMessage values={{ version: newFirmware?.version }} {...statusProps.title} />}
        variant="outlined"
      >
        <FormattedMessage values={{ version: AVQ_SUPPORT }} {...statusProps.description} />
      </Alert>
      <FirmwareModal
        disabled={false}
        onClose={() => setOpenFWUpdateModal(false)}
        onUpdateFirmware={onUpdateFirmware}
        open={openFWUpdateModal}
        releaseNotes={newFirmware?.releaseNotes}
        version={newFirmware?.version}
      />
    </>
  );
};

AdaptiveVideoQualityNotification.propTypes = {
  firmware: PropTypes.shape({
    info: PropTypes.shape({
      haveUpdate: PropTypes.bool,
      newFirmware: PropTypes.shape({
        releaseNotes: PropTypes.arrayOf(
          PropTypes.shape({
            text: PropTypes.string,
            version: PropTypes.string,
          })
        ),
        version: PropTypes.string,
      }),
    }),
    isUpdating: PropTypes.bool,
  }),
  onUpdateFirmware: PropTypes.func.isRequired,
};

AdaptiveVideoQualityNotification.defaultProps = {
  firmware: {
    info: {
      haveUpdate: false,
      newFirmware: {
        releaseNotes: [],
        version: '',
      },
    },
    isUpdating: false,
  },
};

export default AdaptiveVideoQualityNotification;
