import { isEqual } from 'lodash';
import { IMPORT_USERS_LIMIT } from '../../../config/consts';

export const IMPORT_KEYS = {
  apartmentFloor: 'apartmentFloor',
  apartmentName: 'apartmentName',
  apartmentNumber: 'apartmentNumber',
  comment: 'comment',
  detail: 'detail',
  email: 'email',
  firstName: 'firstName',
  lastName: 'lastName',
  result: 'result',
  rowNumber: 'rowNumber',
};

const header = {
  [IMPORT_KEYS.apartmentFloor]: 'Floor',
  [IMPORT_KEYS.apartmentNumber]: 'Apartment number',
  [IMPORT_KEYS.apartmentName]: 'Apartment name',
  [IMPORT_KEYS.firstName]: 'First name',
  [IMPORT_KEYS.lastName]: 'Surname',
  [IMPORT_KEYS.email]: 'E-mail',
  [IMPORT_KEYS.comment]: 'Comment',
  [IMPORT_KEYS.rowNumber]: 1,
};

export const transformJsonData = (jsonData) =>
  jsonData
    .map((item, index) => ({
      [IMPORT_KEYS.apartmentName]: (item[2] || '').toString(),
      [IMPORT_KEYS.comment]: (item[6] || '').toString(),
      [IMPORT_KEYS.email]: (item[5] || '').toString().trim(),
      [IMPORT_KEYS.firstName]: (item[3] || '').toString(),
      [IMPORT_KEYS.apartmentFloor]: (item[0] || '').toString(),
      [IMPORT_KEYS.lastName]: (item[4] || '').toString(),
      [IMPORT_KEYS.apartmentNumber]: (item[1] || '').toString(),
      [IMPORT_KEYS.rowNumber]: index + 1,
    }))
    .filter((item, index) => !(index === 0 && isEqual(item, header)))
    .slice(0, IMPORT_USERS_LIMIT)
    .filter(
      (item) =>
        !(
          item[IMPORT_KEYS.firstName] === '' &&
          item[IMPORT_KEYS.lastName] === '' &&
          item[IMPORT_KEYS.email] === '' &&
          item[IMPORT_KEYS.apartmentName] === '' &&
          item[IMPORT_KEYS.apartmentFloor] === '' &&
          item[IMPORT_KEYS.apartmentNumber] === ''
        )
    );
