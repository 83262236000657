import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ListHeaderLabel } from '../../../../components';
import messages from './messages';

const ContactListHeader = () => (
  <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
    <Grid container>
      <Grid item xs={3}>
        <ListHeaderLabel>
          <FormattedMessage {...messages.labelContactName} />
        </ListHeaderLabel>
      </Grid>
      <Grid item xs={9}>
        <ListHeaderLabel>
          <FormattedMessage {...messages.labelDevicesToCall} />
        </ListHeaderLabel>
      </Grid>
    </Grid>
  </Box>
);

export default ContactListHeader;
