import PropTypes from 'prop-types';
import React from 'react';
import RowMenu from '../../../../components/DataGrid/RowMenu';
import ApproveDevice from './ApproveDevice';
import RejectDevice from './RejectDevice';

const WaitingDevicesRowMenu = ({ onApprove, onReject }) => (
  <RowMenu
    menuItems={
      <div>
        <ApproveDevice onApprove={onApprove} />
        <RejectDevice onReject={onReject} />
      </div>
    }
  />
);

WaitingDevicesRowMenu.propTypes = {
  onApprove: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
};

export default WaitingDevicesRowMenu;
