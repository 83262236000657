import { all, call, put, takeLatest } from 'redux-saga/effects';
import { PATCH_OPERATIONS } from '../../api';
import * as localizationApi from '../../api/localizationApi';
import * as siteApi from '../../api/siteApi';
import { errorHelper, responseValidateStatusOrThrow } from '../helpers';
import { addSnackbarSuccess } from '../notifications/actions';
import { types as siteTypes } from '../sites';
import { LOCALE_PATH } from './helpers';
import { types } from './index';
import messages from './messages';

export function* getCurrentSiteLanguage({ companyId, siteId }) {
  try {
    const response = yield call(siteApi.getSite, companyId, siteId);
    yield responseValidateStatusOrThrow(response);
    yield put({ payload: response.data?.locale, type: types.GET_CURRENT_SITE_LANGUAGE_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.GET_CURRENT_SITE_LANGUAGE_FAILURE, error));
  }
}

export function* getCurrentUserLanguage() {
  try {
    const response = yield call(localizationApi.getCurrentUser);
    yield responseValidateStatusOrThrow(response);
    yield put({ payload: response.data?.locale, type: types.GET_CURRENT_USER_LANGUAGE_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.GET_CURRENT_USER_LANGUAGE_FAILURE, error));
  }
}

export function* setCurrentSiteLanguage({ companyId, siteId, values }) {
  try {
    const response = yield call(siteApi.editSite, companyId, siteId, values);
    yield responseValidateStatusOrThrow(response);
    yield put({ payload: response.data?.locale, type: types.GET_CURRENT_SITE_LANGUAGE_SUCCESS });
    yield put({ payload: response.data?.locale, type: siteTypes.SET_SITE_LANGUAGE_SUCCESS });
    yield put(addSnackbarSuccess(messages.localizationSnackbarLanguageSetSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.GET_CURRENT_SITE_LANGUAGE_FAILURE, error));
  }
}

export function* setLandingPageLanguage({ lang, loggedIn }) {
  try {
    let response;
    if (loggedIn) {
      response = yield call(localizationApi.setCurrentUserLang, [
        { op: PATCH_OPERATIONS.REPLACE, path: LOCALE_PATH, value: lang },
      ]);
      yield responseValidateStatusOrThrow(response);
      yield put({ payload: response.data?.locale, type: types.SET_LANDING_PAGE_LANGUAGE_SUCCESS });
    } else {
      yield put({ payload: lang, type: types.SET_LANDING_PAGE_LANGUAGE_SUCCESS });
    }
  } catch (error) {
    yield put(errorHelper(types.SET_LANDING_PAGE_LANGUAGE_FAILURE, error));
  }
}

export function* setUserModalLanguage({ lang }) {
  try {
    const response = yield call(localizationApi.setCurrentUserLang, [
      { op: PATCH_OPERATIONS.REPLACE, path: LOCALE_PATH, value: lang },
    ]);
    yield responseValidateStatusOrThrow(response);
    yield put({ payload: response.data?.locale, type: types.SET_USER_MODAL_LANGUAGE_SUCCESS });
    yield put(addSnackbarSuccess(messages.localizationSnackbarLanguageSetSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.SET_USER_MODAL_LANGUAGE_FAILURE, error));
  }
}

export default function* localizationSagas() {
  yield all([
    takeLatest(types.GET_CURRENT_SITE_LANGUAGE_REQUEST, getCurrentSiteLanguage),
    takeLatest(types.GET_CURRENT_USER_LANGUAGE_REQUEST, getCurrentUserLanguage),
    takeLatest(types.SET_CURRENT_SITE_LANGUAGE_REQUEST, setCurrentSiteLanguage),
    takeLatest(types.SET_LANDING_PAGE_LANGUAGE_REQUEST, setLandingPageLanguage),
    takeLatest(types.SET_USER_MODAL_LANGUAGE_REQUEST, setUserModalLanguage),
  ]);
}
