import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import ErrorIconMessage from '../ErrorIconMessage';
import ProgressIconMessage from '../ProgressIconMessage';
import SuccessIconMessage from '../SuccessIconMessage';
import WarningIconMessage from '../WarningIconMessage';

const styles = {
  info: {
    pb: 6,
    pt: 2,
    textAlign: 'center',
  },
  label: {
    textAlign: 'center',
  },
  root: {
    pt: 2,
  },
};

interface IStatus {
  info: React.ReactNode;
  label: any;
  type: 'error' | 'success' | 'warning' | 'progress';
}

function Status({ info, label, type }: IStatus) {
  const getIconMessage = () => {
    switch (type) {
      case 'warning':
        return <WarningIconMessage>{label}</WarningIconMessage>;
      case 'progress':
        return <ProgressIconMessage>{label}</ProgressIconMessage>;
      case 'error':
        return <ErrorIconMessage>{label}</ErrorIconMessage>;
      case 'success':
      default:
        return <SuccessIconMessage>{label}</SuccessIconMessage>;
    }
  };

  return (
    <Grid alignItems="center" container direction="column" item justifyContent="center" sx={styles.root}>
      <Grid item lg={5} sm={8} sx={styles.label}>
        {getIconMessage()}
      </Grid>
      <Grid item lg={5} sm={8} sx={styles.info}>
        <Typography component="span" id="statusinfo" variant="body2">
          {info}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Status;
