import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Price } from '../../../../components';
import { GOLD_LICENSE, GOLD_LICENSE_PACK } from '../../constants';
import messages from '../../messages';

const styles = {
  licenseText: {
    pr: 0.5,
    typography: {
      md: 'body1',
      xs: 'body2',
    },
  },
  price: {
    textAlign: {
      md: 'left',
      xs: 'right',
    },
  },
};

const LicenseSelectionItem = ({ license, onChange, selected, snLicenseData }) => {
  const isDisabled = (item) =>
    GOLD_LICENSE in selected && selected[GOLD_LICENSE] === true && GOLD_LICENSE_PACK.includes(item);

  const isSelected = (item) => {
    if (isDisabled(item)) {
      return true;
    }

    if (item in selected) {
      return selected[item];
    }

    return false;
  };

  return (
    <Box alignItems="center" display="flex" key={license}>
      <FormControlLabel
        control={
          <Checkbox
            checked={isSelected(license)}
            color="primary"
            disabled={isDisabled(license)}
            name={license}
            onChange={onChange}
          />
        }
        disableTypography
        key={license}
        label={
          <Typography sx={styles.licenseText}>
            <FormattedMessage values={{ sup: (chunks) => <sup>{chunks}</sup> }} {...licenceSelectionTypeMap[license]} />
          </Typography>
        }
      />
      <Price
        currency={snLicenseData.currency}
        fullPrice={snLicenseData.prices[license]?.unitFullPrice}
        fullPriceVat={snLicenseData.prices[license]?.unitFullPriceVat}
        isDisabled={isDisabled(license)}
        isVat={snLicenseData.prices[license]?.chargeVat}
      />
    </Box>
  );
};

LicenseSelectionItem.propTypes = {
  license: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.shape({}).isRequired,
  snLicenseData: PropTypes.shape({
    currency: PropTypes.string,
    prices: PropTypes.shape({}),
    serialNumbers: PropTypes.arrayOf(
      PropTypes.shape({
        available: PropTypes.arrayOf(PropTypes.string),
        capable: PropTypes.arrayOf(PropTypes.string),
        deviceType: PropTypes.string,
        purchased: PropTypes.arrayOf(PropTypes.string),
      })
    ),
  }).isRequired,
};

export default LicenseSelectionItem;

const licenceSelectionTypeMap = {
  LICENCE_ACCESS_UNIT_LIFT_MODULE: messages.licenseSelectionTypeLICENCE_ACCESS_UNIT_LIFT_MODULE,
  LICENCE_ENHANCED_AUDIO: messages.licenseSelectionTypeLICENCE_ENHANCED_AUDIO,
  LICENCE_ENHANCED_INTEGRATION: messages.licenseSelectionTypeLICENCE_ENHANCED_INTEGRATION,
  LICENCE_ENHANCED_SECURITY: messages.licenseSelectionTypeLICENCE_ENHANCED_SECURITY,
  LICENCE_ENHANCED_VIDEO: messages.licenseSelectionTypeLICENCE_ENHANCED_VIDEO,
  LICENCE_GOLD: messages.licenseSelectionTypeLICENCE_GOLD,
  LICENCE_INFORMCAST: messages.licenseSelectionTypeLICENCE_INFORMCAST,
  LICENCE_INTERCOM_LIFT_MODULE: messages.licenseSelectionTypeLICENCE_INTERCOM_LIFT_MODULE,
  LICENCE_NFC: messages.licenseSelectionTypeLICENCE_NFC,
  LICENCE_NFC_ACCESS_UNIT: messages.licenseSelectionTypeLICENCE_NFC_ACCESS_UNIT,
};
