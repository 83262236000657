import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { VERIFICATION_STATUS } from '../../../config/devices';
import NoVerifiedMessage from './NoVerifiedMessage';
import SuccessMessage from './SuccessMessage';
import WaitingMessage from './WaitingMessage';

const FinishedStep = ({ device, onClose }) => {
  let result = <SuccessMessage onClose={onClose} />;
  if (device.verificationStatus === VERIFICATION_STATUS.PENDING) {
    result = <WaitingMessage onClose={onClose} />;
  } else if ([VERIFICATION_STATUS.NOT_VERIFIED, VERIFICATION_STATUS.ZOMBIE].includes(device.verificationStatus)) {
    result = <NoVerifiedMessage />;
  }

  return <Box textAlign="center">{result}</Box>;
};

FinishedStep.propTypes = {
  device: PropTypes.shape({
    deviceType: PropTypes.string,
    verificationStatus: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FinishedStep;
