export default {
  editBillingInfoBtn: {
    float: 'right',
  },
  header: {
    mb: 2,
  },
  link: {
    '& a': {
      ml: 0.5,
    },
  },
  list: (theme) => ({
    [theme.breakpoints.down('xs')]: {
      display: 'grid',
    },
    '& dd': {
      [theme.breakpoints.down('xs')]: {
        marginInlineStart: 'initial',
      },
    },
    '& dt': {
      clear: 'left',
      float: 'left',
      fontWeight: 'bold',
      width: '200px',
    },
    '& dt, dd': {
      [theme.breakpoints.down('xs')]: {
        height: 'auto',
        lineHeight: '27px',
      },
      height: '40px',
      lineHeight: '40px',
    },
    padding: '0.5rem',
  }),
  marginHeadline: {
    my: 1,
  },
  wrapper: {
    p: 4,
  },
};
