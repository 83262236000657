import { defineMessages } from 'react-intl';

export default defineMessages({
  vatInfoTextText: {
    defaultMessage:
      'Prices for EU customers include VAT unless your VAT number' +
      ' has been manually verified. To verify your VAT number, ' +
      ' contact our Customer Care at {mailToLink}.',
    description: 'VAT info text',
    id: 'vatInfoText.text',
  },
});
