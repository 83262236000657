import { Theme } from '@mui/material';
import { alpha } from '@mui/system';
import { palette } from '../../theme';

export const AggregatedRowClassName = 'aggregated-row';
export const CellWithOffsetClassName = 'cell-with-offset';
export const ClickableRowClassName = 'clickable-row';
export const DataGridWithFooterActionsClassName = 'datagrid-with-footer-actions';
export const CellWithMenuClassName = 'cell-with-menu';

export const RowHeight = 56;
export const AggregatedRowHeight = 72;
const tableBorderRadius = '4px';

const cellWithMenuPadding = 3;
const offsetCellPadding = {
  default: 3,
  upSM: 5,
};
const cellPadding = {
  default: 2,
  upSM: 2,
};

export const aggregatedDataGridStyle = (theme: Theme) => ({
  [`&.${DataGridWithFooterActionsClassName}`]: {
    ['.MuiDataGrid-main']: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
    },
  },

  [`.${AggregatedRowClassName}, .${AggregatedRowClassName}: hover`]: {
    background: alpha(palette.primary.main, 0.2),
  },
  [`.${ClickableRowClassName}`]: {
    cursor: 'pointer',
  },
  '.MuiDataGrid-cell': {
    [theme.breakpoints.up('sm')]: {
      px: cellPadding.upSM,
    },
    [`&.${CellWithMenuClassName}`]: {
      pl: cellWithMenuPadding,
    },
    [`&.${CellWithOffsetClassName}`]: {
      [theme.breakpoints.up('sm')]: {
        pl: offsetCellPadding.upSM,
      },
      pl: offsetCellPadding.default,
    },
    px: cellPadding.default,
  },
  '.MuiDataGrid-main': {
    background: palette.background.paper,
    borderRadius: tableBorderRadius,
  },
  border: 'none',
  boxShadow: '0px 1px 3px 0px #0000001F, 0px 1px 1px 0px #00000024, 0px 2px 1px -1px #00000033',
});

export const footerStyle = (theme: Theme) => ({
  [theme.breakpoints.up('sm')]: {
    px: offsetCellPadding.upSM,
  },
  background: palette.background.paper,
  borderBottomLeftRadius: tableBorderRadius,
  borderBottomRightRadius: tableBorderRadius,
  pb: 4,
  pt: 2,
  px: offsetCellPadding.default,
});
