/* eslint-disable max-len */
import http, { encode } from './index';

export const listPhonebooks = async (company, site, params = {}) =>
  http.get(`/companies/${encode(company)}/sites/${encode(site)}/services/MOBILE_VIDEO/phonebooks`, {
    params,
  });

export const listPhonebooksNames = async (company, site) =>
  http.get(`/companies/${encode(company)}/sites/${encode(site)}/services/MOBILE_VIDEO/phonebooks/names`);

export const listMembers = async (company, site, params = {}) =>
  http.get(`/companies/${encode(company)}/sites/${encode(site)}/services/MOBILE_VIDEO/configurations`, {
    params,
  });

export const detailPhonebook = async (company, site, phonebook) =>
  http.get(`/companies/${encode(company)}/sites/${encode(site)}/services/MOBILE_VIDEO/phonebooks/${encode(phonebook)}`);

export const deletePhonebook = async (company, site, phonebook) =>
  http.delete(
    `/companies/${encode(company)}/sites/${encode(site)}/services/MOBILE_VIDEO/phonebooks/${encode(phonebook)}`
  );

export const updatePhonebook = async (company, site, phonebook, values) =>
  http.put(
    `/companies/${encode(company)}/sites/${encode(site)}/services/MOBILE_VIDEO/phonebooks/${encode(phonebook)}`,
    values
  );

export const createPhonebook = async (company, site, values) =>
  http.post(`/companies/${encode(company)}/sites/${encode(site)}/services/MOBILE_VIDEO/phonebooks`, values);
