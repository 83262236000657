import { defineMessages } from 'react-intl';

export default defineMessages({
  userSnackbarApartmentsSetSuccessfully: {
    defaultMessage: 'Apartments set successfully.',
    description: 'User snackbar - Apartments set successfully',
    id: 'userSnackbarApartmentsSetSuccessfully',
  },
  userSnackbarDataSavedSuccessfully: {
    defaultMessage: 'User data was successfully saved.',
    description: 'User snackbar - User data was successfully saved',
    id: 'userSnackbarDataSavedSuccessfully',
  },
  userSnackbarMy2NAppAccessDisabledSuccessfully: {
    defaultMessage: 'My2N App Access was successfully disabled.',
    description: 'User snackbar - My2N App Access was successfully disabled',
    id: 'userSnackbarMy2NAppAccessDisabledSuccessfully',
  },
  userSnackbarMy2NAppAccessEnabledSuccessfully: {
    defaultMessage: 'My2N App Access was successfully enabled.',
    description: 'User snackbar - My2N App Access was successfully enabled',
    id: 'userSnackbarMy2NAppAccessEnabledSuccessfully',
  },
  userSnackbarPasswordChangedSuccessfully: {
    defaultMessage: 'Password changed successfully.',
    description: 'User snackbar - Password changed successfully',
    id: 'userSnackbarPasswordChangedSuccessfully',
  },
  userSnackbarPinRemovedSuccessfully: {
    defaultMessage: 'PIN was successfully removed.',
    description: 'User snackbar - PIN was successfully removed',
    id: 'userSnackbarPinRemovedSuccessfully',
  },
  userSnackbarPinSavedSuccessfully: {
    defaultMessage: 'PIN was successfully saved.',
    description: 'User snackbar - PIN was successfully saved',
    id: 'userSnackbarPinSavedSuccessfully',
  },
  userSnackbarRemovedSuccessfully: {
    defaultMessage: 'User was successfully removed.',
    description: 'User snackbar - User was successfully removed',
    id: 'userSnackbarRemovedSuccessfully',
  },
  userSnackbarRfidCardAssignedSuccessfully: {
    defaultMessage: 'RFID card (badge) assigned.',
    description: 'User snackbar - RFID card (badge) assigned',
    id: 'userSnackbarRfidCardAssignedSuccessfully',
  },
  userSnackbarRfidCardRemovedSuccessfully: {
    defaultMessage: 'RFID card was successfully removed.',
    description: 'User snackbar - RFID card was successfully removed',
    id: 'userSnackbarRfidCardRemovedSuccessfully',
  },
});
