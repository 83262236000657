import { ApartmentUser, ApartmentUserRole } from '../../modules/apartmentAdmin/data';
import { IApartmentBase } from '../../modules/apartments/store';
import { IUser } from '../../modules/user/store';
import {
  ApartmentUserRole as ApartmentUserRoleType,
  ApartmentUserWithCredentials,
  RacCredentialsTypes,
  SiteUserWithLocaleAndApartmentResponse,
} from '../be4feApi';

export interface IApartmentAdminUserDetailInfo {
  user: ApartmentUser;
  apartmentInfo: IApartmentBase;
}

export function getApartmentAdminUserDetailInfo(
  data?: SiteUserWithLocaleAndApartmentResponse
): IApartmentAdminUserDetailInfo | undefined {
  if (!data) {
    return undefined;
  }
  return {
    apartmentInfo: data.apartment,
    user: getApartmentUser(data.user),
  };
}

export function getApartmentUser(user: ApartmentUserWithCredentials): ApartmentUser {
  return {
    credentials: getApartmentUserCredentials(user.credentials),
    email: user.email,
    enabled: user.enabled,
    firstName: user.firstName,
    id: user.id,
    lastName: user.lastName,
    role: getApartmentUserRole(user.role),
    uuid: user.uuid,
    validFrom: user.validFrom,
    validTo: user.validTo,
  };
}

export function getApartmentUserRole(credentials: ApartmentUserRoleType): ApartmentUserRole {
  switch (credentials) {
    case 'APARTMENT_ADMIN':
      return ApartmentUserRole.APARTMENT_ADMIN;
    case 'APARTMENT_ADMIN_INHERITED':
      return ApartmentUserRole.APARTMENT_ADMIN_INHERITED;
    case 'APARTMENT_LOGIN_LESS':
      return ApartmentUserRole.APARTMENT_LOGIN_LESS;
    case 'APARTMENT_USER':
      return ApartmentUserRole.APARTMENT_USER;
    case 'APARTMENT_GUEST':
      return ApartmentUserRole.APARTMENT_GUEST;
    default:
      return ApartmentUserRole.APARTMENT_USER;
  }
}

export function getApartmentUserCredentials(credentials: RacCredentialsTypes[]): IUser['credentials'] {
  //TODO right object for apartment user credentials should be UserCredentials - will be replaced in next iteration
  return credentials.filter((c) => c !== 'LPR') as IUser['credentials'];
}
