import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DEVICE_TYPE } from '../../../config/devices';
import messages from './messages';

const DeviceTitle = ({ device }) => {
  const type = deviceTypeAddTitleMap[device] ? device : 'GENERAL';
  return <FormattedMessage values={{ sup: (chunks) => <sup>{chunks}</sup> }} {...deviceTypeAddTitleMap[type]} />;
};

DeviceTitle.propTypes = {
  device: PropTypes.oneOf(['DOOR_STATION', 'GENERAL', 'HIPMO', 'THIRD_PARTY', 'X2N']),
};

DeviceTitle.defaultProps = {
  device: 'GENERAL',
};

export default DeviceTitle;

const deviceTypeAddTitleMap = {
  [DEVICE_TYPE.DOOR_STATION]: messages.doorStationAddTitle,
  [DEVICE_TYPE.HIPMO]: messages.hipmoAddTitle,
  [DEVICE_TYPE.THIRD_PARTY]: messages.thirdPartyAddTitle,
  GENERAL: messages.generalAddTitle,
  X2N: messages.x2NAddTitle,
};
