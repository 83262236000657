import Box from '@mui/material/Box';
import { grey } from '@mui/material/colors';
import PropTypes from 'prop-types';
import React from 'react';
import { getLinkFromVersion } from '../../GTCPage/helper';
import GTCDescription from './GTCDescription';
import GTCTitle from './GTCTitle';
import GTCVersionList from './GTCVersionList';

const styles = {
  root: {
    bgcolor: 'white',
    color: grey[700],
    height: '100%',
    minWidth: 300,
    p: 3,
  },
};

const GTCItem = ({ agree, basePath, cookieSettings, description, name, versions }) => {
  const link = getLinkFromVersion(versions);
  return (
    <Box boxShadow={1} sx={styles.root}>
      <GTCTitle
        agree={agree}
        cookieSettings={cookieSettings}
        link={basePath.replace(':slug', link?.path).replace(':version', `v${link?.version}`)}
        title={name}
      />
      <GTCDescription html={description} />
      <GTCVersionList basePath={basePath} versions={versions} />
    </Box>
  );
};

GTCItem.propTypes = {
  agree: PropTypes.bool.isRequired,
  basePath: PropTypes.string.isRequired,
  cookieSettings: PropTypes.bool.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  name: PropTypes.string.isRequired,
  versions: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default GTCItem;
