import { defineMessages } from 'react-intl';

export default defineMessages({
  promoCodeProductMobileVideoPoolCreditYearApartment: {
    defaultMessage: '9137958 — My2N Annual Apartment Subscription',
    description: 'Product type apartment',
    id: 'promoCode.product.MOBILE_VIDEO_POOL_CREDIT_YEAR_APARTMENT',
  },
  promoCodeProductMobileVideoPoolCreditYearStandard: {
    defaultMessage: '9137957 — My2N Annual Device Subscription',
    description: 'Product type device',
    id: 'promoCode.product.MOBILE_VIDEO_POOL_CREDIT_YEAR_STANDARD',
  },
});
