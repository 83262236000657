import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import { useGetSiteQuery } from '../../apiRtk/be4feApi';
import { FE_ROLES } from '../../config/consts';
import featureFlags from '../../config/featureFlags';
import { useUrlParams } from '../../helpers/urlParams';
import { selectors as elevatorIntegrationSelectors } from '../../modules/elevatorIntegration';
import { isUserGuest } from '../../modules/user/utils';
import { useHasFeature } from '../Feature/Feature';
import UserAccessValidity from '../UserAccessValidity/UserAccessValidity';
import UserElevatorIntegrationSettings from '../UserElevatorIntegrationSettings';
import SectionAccessGroups from './SectionAccessGroups';
import SectionMy2NAppAccess from './SectionMy2NAppAccess';
import SectionPin from './SectionPin';
import SectionQr from './SectionQr';
import SectionRfid from './SectionRfid';
import styles from './styles/UserAccessForm';

function UserAccessForm({ user, userMy2NAppAccessEnabledStatus, userPin, userRfidCard }) {
  const { companyId, siteId } = useUrlParams();
  const isUserBleCredentialFFEnable = useHasFeature(featureFlags.USER_BLE_CREDENTIAL);
  const isElevatorIntegrationFFEnable = useHasFeature(featureFlags.ELEVATOR_INTEGRATION);
  const hasSiteElevatorIntegration = useSelector(elevatorIntegrationSelectors.isSiteElevatorIntegrationActive);
  const { data: siteData } = useGetSiteQuery({ companyId: companyId, siteId: siteId });
  const isQrEnabled = siteData?.services.ACCESS_CONTROL.guestShowQr;

  return (
    <Paper sx={styles.root}>
      <Grid container direction="column">
        {isUserGuest(user.role) && userPin.data && (
          <Grid>
            <UserAccessValidity user={user} />
          </Grid>
        )}
        <Grid item sx={{ pb: isElevatorIntegrationFFEnable && hasSiteElevatorIntegration ? 0 : 7.5, pt: 1 }}>
          <SectionAccessGroups
            isRacActive={!!userPin?.data?.pin || !!userRfidCard?.data?.rfidCode || userMy2NAppAccessEnabledStatus}
          />
        </Grid>
        {isElevatorIntegrationFFEnable && hasSiteElevatorIntegration && (
          <Grid item sx={{ pb: 7.5, pt: 1 }}>
            <UserElevatorIntegrationSettings />
          </Grid>
        )}
        <Grid item>
          <Grid container spacing={4}>
            {(!isUserGuest(user.role) || !isQrEnabled) && (
              <Grid item lg={4} sm={6} xs={12}>
                <SectionPin user={user} userPinData={userPin?.data} />
              </Grid>
            )}
            {isUserGuest(user.role) && isQrEnabled && (
              <Grid item lg={4} sm={6} xs={12}>
                <SectionQr user={user} userPinData={userPin?.data} siteData={siteData} />
              </Grid>
            )}
            {!isUserGuest(user.role) && (
              <Grid item lg={4} sm={6} xs={12}>
                <SectionRfid user={user} userRfidCardData={userRfidCard?.data} />
              </Grid>
            )}
            {isUserBleCredentialFFEnable && !isUserGuest(user.role) && user.role !== FE_ROLES.LOGIN_LESS && (
              <Grid item lg={4} sm={6} xs={12}>
                <SectionMy2NAppAccess user={user} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
}

UserAccessForm.propTypes = {
  user: PropTypes.shape({
    apartments: PropTypes.arrayOf(
      PropTypes.shape({
        floor: PropTypes.string,
        id: PropTypes.number,
        label: PropTypes.string,
        name: PropTypes.string,
        number: PropTypes.string,
      })
    ),
    email: PropTypes.string,
    firstName: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    lastName: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
  userMy2NAppAccessEnabledStatus: PropTypes.bool,
  userPin: PropTypes.shape({
    data: PropTypes.shape({
      active: PropTypes.bool,
      id: PropTypes.string,
      pin: PropTypes.string,
    }),
    isFetching: PropTypes.bool,
  }),
  userRfidCard: PropTypes.shape({
    data: PropTypes.shape({
      rfidCode: PropTypes.string,
    }),
  }),
};

UserAccessForm.defaultProps = {
  userPin: null,
  userRfidCard: null,
};

export default UserAccessForm;
