import axios from 'axios';
import { getGlobalFeatures } from '../../api/commonApi';

export async function tryCheckAppVersion(showWarning) {
  const http = axios.create({
    baseURL: process.env.SERVER_BASE_URL,
    withCredentials: true,
  });

  http
    .get('/build.version')
    .then((response) => {
      if (response.data.buildVersion.trim() !== process.env.CURRENT_COMMIT_HASH.trim()) {
        showWarning();
      }
    })
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    .catch(() => {});

  return process.env.CURRENT_COMMIT_HASH;
}

export async function tryCheckActiveFeatureFlags(loadedFeatureFlags, showWarning) {
  getGlobalFeatures()
    .then((activeGlobalFeatures) => {
      if (activeGlobalFeatures.data.length !== loadedFeatureFlags.length) {
        showWarning();
      } else {
        activeGlobalFeatures.data.forEach((featureFlag) => {
          if (loadedFeatureFlags.find(({ name }) => name === featureFlag.name) === undefined) {
            showWarning();
          }
        });
      }
    })
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    .catch(() => {});
}
