import { defineMessages } from 'react-intl';

export default defineMessages({
  userLoserDeviceAppLoginInfoLink: {
    defaultMessage: 'Install the My2N app',
    description: 'App login info - link',
    id: 'userLoserDevice.appLoginInfo.link',
  },
  userLoserDeviceAppLoginInfoMessage: {
    defaultMessage:
      'If you haven’t already, please {link} and log in using the QR code or your My2N account login details.',
    description: 'Info for user about login to mobile app',
    id: 'userLoserDevice.appLoginInfo.message',
  },
  userLoserDeviceAppLoginInfoTitle: {
    defaultMessage: 'Does your My2N app work?',
    description: 'Device app information title',
    id: 'userLoserDevice.appLoginInfo.title',
  },
  userLoserDeviceCompany: {
    defaultMessage: 'Company',
    description: 'User loser device - company',
    id: 'userLoserDevice.company',
  },
  userLoserDeviceDeviceId: {
    defaultMessage: 'Device ID',
    description: 'User loser device - device id',
    id: 'userLoserDevice.deviceId',
  },
  userLoserDeviceDeviceName: {
    defaultMessage: 'Device name',
    description: 'User loser device - device name',
    id: 'userLoserDevice.deviceName',
  },
  userLoserDeviceDevicePassword: {
    defaultMessage: 'Device password',
    description: 'User loser device - device password',
    id: 'userLoserDevice.devicePassword',
  },
  userLoserDeviceDevicePasswordNotNeeded: {
    defaultMessage: 'Password is not needed',
    description: 'User loser device - device password not needed',
    id: 'userLoserDevice.devicePasswordNotNeeded',
  },
  userLoserDeviceDeviceStatus: {
    defaultMessage: 'Device status',
    description: 'User loser device - device status',
    id: 'userLoserDevice.deviceStatus',
  },
  userLoserDeviceLogInToApp: {
    defaultMessage: 'Log in to the My2N app{br}with your account',
    description: 'User loser device - log in to app',
    id: 'userLoserDevice.logInToApp',
  },
  userLoserDeviceMy2nId: {
    defaultMessage: '(My2N ID)',
    description: 'User loser device - my2n id',
    id: 'userLoserDevice.my2nId',
  },
  userLoserDeviceQrAfterMigrationTitle: {
    defaultMessage: 'My2N app login',
    description: 'User loser device - title above qr code.',
    id: 'userLoserDevice.qr.afterMigration.title',
  },
  userLoserDeviceQrAfterMigrationTooltip: {
    defaultMessage: '<p>Scan the QR code to log in to {br} the My2N mobile application.</p>',
    description: 'User loser device - qr after migration',
    id: 'userLoserDevice.qr.afterMigration.tooltip',
  },
  userLoserDeviceQrBeforeMigration: {
    defaultMessage: 'Use the QR code to link this device with the My2N mobile application.',
    description: 'User loser device - qr before migration',
    id: 'userLoserDevice.qr.beforeMigration',
  },
  userLoserDeviceSite: {
    defaultMessage: 'Site',
    description: 'User loser device - site',
    id: 'userLoserDevice.site',
  },
});
