import { IButtonRenderData } from '../../../../containers/DeviceDetail/CallSettings/Intercom/hooks/useGetStateData';
import { IApartment } from '../../../apartments/store';
import * as consts from '../../../constants';
import { IRequestContact } from '../../saveActions';
import { DeviceFeatureType, IContactItem } from '../../store/deviceStore';
import { getNewContactItems, getUpdatedContactItems } from './callSettingsContactItemCreator';
import { CallSettingsDataManager } from './callSettingsDataManager';
import { ExternalIdGenerator } from './externalIdGenerator';

export function createUpdatedContact(
  callSettingsDataManager: CallSettingsDataManager,
  featureType: DeviceFeatureType,
  buttonData: IButtonRenderData,
  availableApartments: IApartment[],
  isDevice?: boolean,
  contactType?: string
): IRequestContact {
  const contactData = callSettingsDataManager.getContactDataByExternalIdAndFeature(
    featureType,
    buttonData.externalId!
  )!;
  const contactItems = getUpdatedContactItems(
    callSettingsDataManager,
    buttonData,
    featureType,
    !!isDevice,
    contactData
  );

  let state = consts.update;
  if (contactData && contactData.contactId === undefined) {
    state = consts.create;
  }

  return createContactRequestObject(
    tryGetDefaultButtonLabel(buttonData, availableApartments, isDevice),
    buttonData.index,
    getRequestActionByFeatureType(featureType, state),
    contactItems,
    buttonData.externalId,
    contactData?.contactId,
    contactType
  );
}

export function createNewContact(
  callSettingsDataManager: CallSettingsDataManager,
  featureType: DeviceFeatureType,
  copyExternalIds: boolean,
  externalIdGenerator: ExternalIdGenerator,
  buttonData: IButtonRenderData,
  isDevice: boolean,
  contactType: string,
  availableApartments: IApartment[]
): IRequestContact {
  const newExternalId = externalIdGenerator.getExternalId(copyExternalIds, buttonData.index);
  const contactItems = getNewContactItems(callSettingsDataManager, buttonData, featureType, isDevice);

  return createContactRequestObject(
    tryGetDefaultButtonLabel(buttonData, availableApartments, isDevice),
    buttonData.index,
    getRequestActionByFeatureType(featureType, consts.create),
    contactItems,
    newExternalId,
    undefined,
    contactType
  );
}

function tryGetDefaultButtonLabel(
  buttonData: IButtonRenderData,
  availableApartments: IApartment[],
  isDevice?: boolean
): string {
  if (buttonData.buttonName !== undefined && buttonData.buttonName !== '') {
    return buttonData.buttonName;
  }

  if (isDevice || isDevice === undefined) {
    return `${consts.defaultDeviceButtonLabel} ${buttonData.index + 1}`;
  }

  const selectedApartment = availableApartments.find(
    (apartment) => apartment.id === buttonData.selectedOptions?.[0].optionKey
  );
  return selectedApartment?.name || '';
}

function getRequestActionByFeatureType(featureType: DeviceFeatureType, state: string): string {
  switch (featureType) {
    case consts.contactList:
      return `${state}${consts.contactListAction}`;

    case consts.buttonConfiguration:
      return `${state}${consts.buttonConfigAction}`;

    case consts.display:
      return `${state}${consts.displayAction}`;

    default:
      break;
  }

  return '';
}

function createContactRequestObject(
  name: string,
  index: number,
  action: string,
  contactItems?: IContactItem[],
  externalId?: string,
  contactId?: number,
  type?: string
): IRequestContact {
  return {
    action,
    contactItems,
    externalId,
    id: contactId,
    index,
    name,
    type,
  };
}
