import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import React from 'react';
import AccessGroupsList from '../AccessGroupsList';
import SetAccessGroupButton from '../SetAccessGroupButton';

const AccessGroupsSettings = ({ buttonAction, groups, helperText, isRacActive, noGroupsLabel, racInactiveLabel }) => (
  <Stack direction={{ md: 'row', xs: 'column' }} spacing={{ md: 10, xs: 2 }}>
    <Box sx={{ flexGrow: 1 }}>
      <AccessGroupsList
        groups={groups}
        helperText={helperText}
        isRacActive={isRacActive}
        noGroupsLabel={noGroupsLabel}
        racInactiveLabel={racInactiveLabel}
      />
    </Box>
    <Box>
      <SetAccessGroupButton onClick={buttonAction} />
    </Box>
  </Stack>
);

AccessGroupsSettings.propTypes = {
  buttonAction: PropTypes.func.isRequired,
  groups: PropTypes.arrayOf(PropTypes.object).isRequired,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  isRacActive: PropTypes.bool.isRequired,
  noGroupsLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  racInactiveLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

export default AccessGroupsSettings;
