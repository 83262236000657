import { defineMessages } from 'react-intl';

export default defineMessages({
  companyAdminDeleteDialogBodyRow1: {
    defaultMessage: 'By deleting, the user will lose access via devices on this site.',
    description: 'CompanyAdminDeleteDialog - body row 1',
    id: 'companyAdminDeleteDialog.bodyRow1',
  },
  companyAdminDeleteDialogBodyRow2: {
    defaultMessage: 'Devices attached to the user will be transferred to the company admin.',
    description: 'CompanyAdminDeleteDialog - body row 2',
    id: 'companyAdminDeleteDialog.bodyRow2',
  },
  loginLessDialogTitle: {
    defaultMessage: 'Delete user without login',
    description: 'loginLessDeleteDialog - title',
    id: 'loginLessDeleteDialog.title'
  },
  userDeleteDialogBodyRow1: {
    defaultMessage: 'By deleting, the user will lose access via devices on this site.',
    description: 'UserDeleteDialog - body row 1',
    id: 'userDeleteDialog.bodyRow1',
  },
  userDeleteDialogBodyRow2: {
    defaultMessage: 'Devices attached to the user will be transferred to the company admin.',
    description: 'UserDeleteDialog - body row 2',
    id: 'userDeleteDialog.bodyRow2',
  },
  userDeleteDialogCancel: {
    defaultMessage: 'Cancel',
    description: 'UserDeleteDialog - cancel button',
    id: 'userDeleteDialog.cancel',
  },
  userDeleteDialogConfirm: {
    defaultMessage: 'Delete user',
    description: 'UserDeleteDialog - confirm button',
    id: 'userDeleteDialog.confirm',
  },
  userDeleteDialogTitle: {
    defaultMessage: 'Delete user',
    description: 'UserDeleteDialog - title',
    id: 'userDeleteDialog.title',
  },
});
