/* eslint-disable react/require-default-props */
import Box from '@mui/material/Box';
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress';
import React from 'react';

export interface ILoaderData {
  alignItems?: string;
  muiLoaderProps?: CircularProgressProps;
}

const Loader = ({ alignItems, ...other }: ILoaderData) => (
  <Box width="100%">
    <Box display="flex" justifyContent={alignItems || 'flex-start'}>
      <CircularProgress {...other} color="primary" />
    </Box>
  </Box>
);

export default Loader;
