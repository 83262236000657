import { defineMessages } from 'react-intl';

export default defineMessages({
  AAUserListApartmentUsersCreateGuest: {
    defaultMessage: 'create visitor',
    id: 'AA.userList.createGuest',
  },
  AAUserListApartmentUsersCreateGuestXs: {
    defaultMessage: 'visitor',
    id: 'AA.userList.apartmentUsersCreateGuest.xs',
  },
  SiteUsersTitle: {
    defaultMessage: 'Site users',
    description: 'Site users - title',
    id: 'siteUsers.title',
  },
  SiteUsersTitleMobile: {
    defaultMessage: 'Users',
    description: 'Site users - title for mobile devices',
    id: 'siteUsers.titleMobile',
  },
  SiteUsersToolsAddNewUser: {
    defaultMessage: 'Create user',
    description: 'Site users tools - create user',
    id: 'siteUsers.tools.addNewUser',
  },
  SiteUsersToolsAddNewUserMobile: {
    defaultMessage: 'User',
    description: 'Site users tools - create user for mobile devices',
    id: 'siteUsers.tools.addNewUserMobile',
  },
});
