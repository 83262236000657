import AddIcon from '@mui/icons-material/Add';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import RowMenuItem from '../../DataGrid/RowMenuItem';
import messages from './messages';

const AddApartmentToGroup = ({ onClick }) => (
  <RowMenuItem
    icon={<AddIcon />}
    label={<FormattedMessage {...messages.AccessSettingsDataGridTableRowActionsAddApartment} />}
    onClick={onClick}
  />
);

AddApartmentToGroup.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default AddApartmentToGroup;
