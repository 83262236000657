import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FE_ROLES } from '../../config/consts';
import actions from '../../modules/apartments';
import { IModalBase } from '../../modules/commonTypes';
import { IUser } from '../../modules/user/store';
import { ActionModalError } from '../_DesignSystemWrappers/Modal';
import { useGetGlobalHooks } from './hooks/getGlobalHooks';
import messages from './messages';

interface IUserDeleteDialog extends IModalBase {
  user: IUser;
}

const ApartmentUserDeleteModal = ({ onClose, open, user }: IUserDeleteDialog) => {
  const { dispatch, formatMessage } = useGetGlobalHooks();

  function handleOnDelete() {
    dispatch(actions.deleteApartmentUser(user));
    onClose();
  }

  return (
    <ActionModalError
      icon={<DeleteIcon />}
      isPrimaryButtonDisabled={false}
      muiDialogProps={{
        maxWidth: 'xs',
      }}
      onClose={onClose}
      open={open}
      primaryButtonAction={handleOnDelete}
      primaryButtonText={formatMessage(messages.userDeleteDialogConfirm)}
      secondaryButtonText={formatMessage(messages.userDeleteDialogCancel)}
      title={formatMessage(messages.userDeleteDialogTitle)}
    >
      <Typography sx={{ mb: 1.5 }}>
        <FormattedMessage {...messages.userDeleteDialogBodyRow1} />
      </Typography>
      {user.role !== FE_ROLES.LOGIN_LESS && (
        <Typography>
          <FormattedMessage {...messages.companyAdminDeleteDialogBodyRow2} />
        </Typography>
      )}
    </ActionModalError>
  );
};

export default ApartmentUserDeleteModal;
