import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import RowMenuItem from '../../../../components/DataGrid/RowMenuItem';
import { PATHS } from '../../../../config/consts';
import messages from './messages';

const EditDevice = ({ companyId, deviceId, siteId }) => (
  <>
    <RowMenuItem
      component={Link}
      icon={<EditIcon />}
      label={<FormattedMessage {...messages.accessGroupDevicesDataGridDataGridRowMenuEdit} />}
      to={PATHS.DEVICE_ACCESS_SETTINGS.replace(':companyId', companyId)
        .replace(':siteId', siteId)
        .replace(':deviceId', deviceId)}
    />
  </>
);

EditDevice.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  deviceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  siteId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default EditDevice;
