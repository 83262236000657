import PersonAddAlt1 from '@mui/icons-material/PersonAddAlt1';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { UserRoles } from '../../modules/user/store';
import { getUserName } from '../../modules/user/utils';
import { UserIcon } from '../index';
import messages from './messages';

export type UserSelectOptionLabelType = 'normal' | 'me' | 'new';
export type UserSelectOptionLabelUser = {
  apartments: {
    name: string;
    number: string;
  }[];
  id: number;
  role: UserRoles;
  firstName?: string;
  lastName: string;
};
export interface IUserSelectOptionLabel {
  type: UserSelectOptionLabelType;
  user?: UserSelectOptionLabelUser;
  apartments?: string;
}

const UserSelectOptionLabel = ({ apartments, type = 'normal', user }: IUserSelectOptionLabel) => {
  if (type === 'new') {
    return (
      <Stack direction="row" spacing={2} sx={{ color: 'primary.main' }}>
        <PersonAddAlt1 />
        <Typography variant="body1">
          <FormattedMessage {...messages.userSelectFieldCreateNewUser} />
        </Typography>
      </Stack>
    );
  }

  const name = getUserName({ firstName: user?.firstName, lastName: user?.lastName || '' });

  return (
    <Stack direction="row" sx={{ alignItems: 'center' }}>
      {user && <UserIcon userRole={user.role} sx={{ color: 'secondary.main' }} />}
      <Typography variant="body1" sx={{ ml: 2 }}>
        {type === 'me' ? (
          <FormattedMessage
            {...messages.userSelectFieldAssignToMe}
            values={{
              currentUser: name,
            }}
          />
        ) : (
          name
        )}
      </Typography>
      <Typography variant="body2" sx={{ color: 'text.secondary', ml: 1 }}>
        {apartments}
      </Typography>
    </Stack>
  );
};

export default UserSelectOptionLabel;
