import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../config/consts';
import { PublicFormWrapper } from '../_components/PublicFormWrapper';
import { AccountActivationForm } from './components/AccountActivationForm';
import messages from './components/AccountActivationForm/messages';

export function AccountActivationPage() {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  return (
    <PublicFormWrapper title={formatMessage(messages.ImsActivateAccountTitle)}>
      <AccountActivationForm
        onSuccess={() => {
          navigate(PATHS.LOGIN);
        }}
      />
    </PublicFormWrapper>
  );
}
