import { defineMessages } from 'react-intl';

export default defineMessages({
  mobileVideoStatusMobileVideoStatusActive: {
    defaultMessage: '<status>Active</status> <span>until {date}</span>',
    description: 'MobileVideoStatus - status active',
    id: 'mobileVideoStatus.ACTIVE',
  },
  mobileVideoStatusMobileVideoStatusDisabled: {
    defaultMessage: '<status>Disabled</status>',
    description: 'MobileVideoStatus  - status disabled',
    id: 'mobileVideoStatus.DISABLED',
  },
  mobileVideoStatusMobileVideoStatusEnabled: {
    defaultMessage: '<status>Enabled</status>',
    description: 'MobileVideoStatus - status enabled',
    id: 'mobileVideoStatus.ENABLED',
  },
  mobileVideoStatusMobileVideoStatusExpired: {
    defaultMessage: '<status>Expired</status> <span>on {date}</span>',
    description: 'MobileVideoStatus - status expired',
    id: 'mobileVideoStatus.EXPIRED',
  },
  mobileVideoStatusMobileVideoStatusInitial: {
    defaultMessage: '<status>Not active</status>',
    description: 'MobileVideoStatus - status initial',
    id: 'mobileVideoStatus.INITIAL',
  },
  mobileVideoStatusMobileVideoStatusStandard: {
    defaultMessage: '<status>Active</status>',
    description: 'MobileVideoStatus - status standard',
    id: 'mobileVideoStatus.STANDARD',
  },
  mobileVideoStatusMobileVideoStatusStandardExpired: {
    defaultMessage: '<status>Expired</status> <span>on {date}</span>',
    description: 'MobileVideoStatus - status standard expired',
    id: 'mobileVideoStatus.STANDARD_EXPIRED',
  },
  mobileVideoStatusMobileVideoStatusStandardNotSubscribed: {
    defaultMessage: '<status>Active</status> <span>until {date}</span>',
    description: 'MobileVideoStatus - status standard not subscribed',
    id: 'mobileVideoStatus.STANDARD_NOT_SUBSCRIBED',
  },
  mobileVideoStatusMobileVideoStatusStandardRenewal: {
    defaultMessage: '<status>Active</status>',
    description: 'MobileVideoStatus - status standard renewal',
    id: 'mobileVideoStatus.STANDARD_RENEWAL',
  },
  mobileVideoStatusMobileVideoStatusTemporary: {
    defaultMessage: '<status>Subscription</status> expired',
    description: 'MobileVideoStatus - status temporary',
    id: 'mobileVideoStatus.TEMPORARY',
  },
  mobileVideoStatusMobileVideoStatusTrialActive: {
    defaultMessage: '<status>Free trial</status> <span>until {date}</span>',
    description: 'MobileVideoStatus - status trial',
    id: 'mobileVideoStatus.TRIAL_ACTIVE',
  },
  mobileVideoStatusMobileVideoStatusTrialExpired: {
    defaultMessage: '<status>Free trial expired</status> <span>on {date}</span>',
    description: 'MobileVideoStatus - status trial expired',
    id: 'mobileVideoStatus.TRIAL_EXPIRED',
  },
});
