import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MOBILE_VIDEO_STATUS, ROLES } from '../../config/consts';
import { SERVICE } from '../../config/devices';
import { LICENSE } from '../../config/subscription';
import { selectors as siteSelectors } from '../../modules/sites';
import { INVOICE } from './constants';
import messages from './messages';

const getMobileVideo = (state) => siteSelectors.getSiteService(state, SERVICE.MOBILE_VIDEO);

export const getMobileVideoAllowed = (state) => {
  const mobileVideo = getMobileVideo(state);
  if (mobileVideo && mobileVideo.licenceModel) {
    if (
      mobileVideo.licenceModel.status !== MOBILE_VIDEO_STATUS.INITIAL &&
      mobileVideo.licenceModel.status !== MOBILE_VIDEO_STATUS.STANDARD_RENEWAL
    ) {
      return true;
    }
  }
  return false;
};

export const isMVCardActive = (userRoles) =>
  userRoles.includes(ROLES.MOBILE_VIDEO_BUYER) && userRoles.includes(ROLES.GREEN_ADMIN);

export const getOptions = (regularPayment) =>
  [
    {
      label: <FormattedMessage {...messages.mobileVideoLicenseMonthlySubscriptionDevices} />,
      value: LICENSE.MOBILE_VIDEO_MONTH,
    },
    {
      label: <FormattedMessage {...messages.mobileVideoLicenseAnnualSubscriptionDevices} />,
      value: LICENSE.MOBILE_VIDEO_YEAR,
    },
    {
      label: <FormattedMessage {...messages.mobileVideoLicenseMonthlySubscriptionApartment} />,
      value: LICENSE.APARTMENT_MOBILE_VIDEO_MONTH,
    },
    {
      label: <FormattedMessage {...messages.mobileVideoLicenseAnnualSubscriptionApartment} />,
      value: LICENSE.APARTMENT_MOBILE_VIDEO_YEAR,
    },
  ].filter(({ value }) => value in regularPayment);

export const getUseAvailableCredit = (state) => {
  const licenceModel = siteSelectors.getSitesServiceMobileVideoLicenceModel(state);
  if (
    licenceModel &&
    licenceModel.unlicensedCount !== 0 &&
    licenceModel.additional !== 0 &&
    licenceModel.licencesPool !== 0 &&
    licenceModel.additional <= licenceModel.licencesPool
  ) {
    return true;
  }
  return false;
};

export function getSubscriptionStepsIntl(formatMessage) {
  return [
    formatMessage(messages.subscriptionStep1PurchaseType),
    formatMessage(messages.subscriptionStep2PurchaseDetails),
    formatMessage(messages.subscriptionStep3PaymentInformation),
    formatMessage(messages.subscriptionStep4Confirmation),
  ];
}

export const isCreditLimitReached = (paymentMethod, priceVat, creditLimit) =>
  paymentMethod === INVOICE && priceVat > creditLimit;
