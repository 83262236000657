import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import ApartmentFloorAutocomplete from '../../../components/ApartmentFloorAutocomplete';
import FormikFields from '../../../components/FormikFields';
import { SITE_TYPE } from '../../../config/sites';
import messages from './messages';

const NewApartmentForm = ({ floors, onChange, siteType, values }) => {
  const refFloor = useRef(null);
  useEffect(() => {
    if (refFloor.current !== null) {
      refFloor.current.focus();
    }
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        mt: 2,
        pl: 2,
        pr: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item sm={3}>
          <ApartmentFloorAutocomplete
            floors={floors}
            helperText={<FormattedMessage {...messages.newApartmentFloorHelperText} />}
            inputName="newApartment.floor"
            inputRef={refFloor}
            onChange={onChange}
            siteType={siteType}
            typedFloor={values?.newApartment?.floor}
          />
        </Grid>
        <Grid item sm={3}>
          <FormikFields.Input
            helperText={<FormattedMessage {...messages.newApartmentNumberHelperText} />}
            label={<FormattedMessage {...messages.newApartmentNumberLabel} />}
            name="newApartment.number"
            required
          />
        </Grid>
        <Grid item sm={6}>
          <FormikFields.Input
            helperText={<FormattedMessage {...messages.newApartmentNameHelperText} />}
            label={<FormattedMessage {...messages.newApartmentNameLabel} />}
            name="newApartment.name"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

NewApartmentForm.propTypes = {
  floors: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  siteType: PropTypes.oneOf(Object.keys(SITE_TYPE)).isRequired,
  values: PropTypes.shape({
    existUser: PropTypes.bool,
    floor: PropTypes.string,
    newApartment: PropTypes.shape({
      floor: PropTypes.string,
      name: PropTypes.string,
      number: PropTypes.string,
    }),
  }).isRequired,
};

export default NewApartmentForm;
