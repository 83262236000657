import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const NoRowsOverlay = ({ action, headerText, text }) => (
  <Box
    sx={{
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      mb: 6,
      mt: 9,
      mx: 3,
    }}
  >
    {headerText && (
      <Typography color="secondary" variant="h5">
        {headerText}
      </Typography>
    )}
    {text && (
      <Typography color="text.secondary" sx={{ mt: 3.5 }} textAlign="center" variant="body1">
        {text}
      </Typography>
    )}
    {action && <Box sx={{ mt: 3 }}>{action}</Box>}
  </Box>
);

NoRowsOverlay.propTypes = {
  action: PropTypes.node,
  headerText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

NoRowsOverlay.defaultProps = {
  action: null,
  headerText: null,
  text: null,
};

export default NoRowsOverlay;
