import { defineMessages } from 'react-intl';

export default defineMessages({
  removeUserRfidCardModalBottomDescription: {
    defaultMessage:
      'If the user has other access methods at their disposal, they may use' +
      ' these (e.g., PIN, mobile application, biometrics).',
    description: 'Revoke user RFID card - bottom description',
    id: 'removeUserRfidCardModal.bottomDescription',
  },
  removeUserRfidCardModalCancel: {
    defaultMessage: 'Cancel',
    description: 'Revoke user RFID card - cancel',
    id: 'removeUserRfidCardModal.cancel',
  },
  removeUserRfidCardModalDescription: {
    defaultMessage:
      'When you remove the card, the user won’t be able to use any door' +
      ' devices once they synchronize with My2N. This usually takes several seconds.',
    description: 'Revoke user RFID card - description',
    id: 'removeUserRfidCardModal.description',
  },
  removeUserRfidCardModalSubmit: {
    defaultMessage: 'Remove CARD',
    description: 'Revoke user RFID card - submit',
    id: 'removeUserRfidCardModal.submit',
  },
  removeUserRfidCardModalTitle: {
    defaultMessage: 'Remove RFID card',
    description: 'Revoke user RFID card - remove RFID card',
    id: 'removeUserRfidCardModal.title',
  },
});
