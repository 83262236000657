import { Dialog } from '@2n/design-system';
import Box from '@mui/material/Box';
import React from 'react';
import { IDSModalBase } from '../designSystemUnexported';

export interface IActionModalData extends IDSModalBase {
  icon: React.ReactNode;
  isPrimaryButtonDisabled: boolean;
}

export function ActionModal(data: IActionModalData) {
  return (
    <Dialog
      footerOptions={{
        primaryButton:
          data.primaryButtonAction && data.primaryButtonText !== undefined
            ? {
                action: data.primaryButtonAction,
                icon: {
                  icon: data.icon,
                  iconPosition: 'start',
                },
                isDisabled: data.isPrimaryButtonDisabled,
                text: data.primaryButtonText,
                variant: 'contained',
              }
            : undefined,
        secondaryButton:
          data.secondaryButtonText !== undefined
            ? {
                action: data.onClose,
                color: 'secondary',
                text: data.secondaryButtonText,
                variant: 'text',
              }
            : undefined,
      }}
      muiDialogProps={data.muiDialogProps}
      onClose={data.onClose}
      open={!!data.open}
      title={data.title}
    >
      <Box data-testid={data.testId}>{data.children}</Box>
    </Dialog>
  );
}

export default ActionModal;
