import { defineMessages } from 'react-intl';

export default defineMessages({
  CompanyAdminTitle: {
    defaultMessage: 'Company admins',
    description: 'Company admin - title',
    id: 'companyAdmin.title',
  },
  CompanyAdminToolsAddNewAdmin: {
    defaultMessage: 'Create admin',
    description: 'Company admin tools - add new admin button',
    id: 'companyAdmin.tools.addNewAdmin',
  },
  CompanyAdminToolsAddNewAdminMobile: {
    defaultMessage: 'Admin',
    description: 'Company admin tools - add new admin button on mobile devices',
    id: 'companyAdmin.tools.addNewAdminMobile',
  },
});
