import { defineMessages } from 'react-intl';

export default defineMessages({
  AAmodalDeletePINMsgErr: {
    defaultMessage: 'There was an error, please try again',
    id: 'AAmodal.deletePIN.msgErr',
  },
  AAmodalDeletePINMsgSucc: {
    defaultMessage: 'PIN deleted successfully',
    id: 'AAmodal.deletePIN.msgSucc',
  },
  AAmodalDeleteRFIDMsgErr: {
    defaultMessage: 'There was an error, please try again',
    id: 'AAmodal.deleteRFID.msgErr',
  },
  AAmodalDeleteRFIDMsgSucc: {
    defaultMessage: 'RFID key fob deleted successfully',
    id: 'AAmodal.deleteRFID.msgSucc',
  },
  AAmodalGeneratePINMsgErr: {
    defaultMessage: 'There was an error, please try again',
    id: 'AAmodal.generatePIN.msgErr',
  },
  AAmodalGeneratePINMsgSucc: {
    defaultMessage: 'PIN generated successfully',
    id: 'AAmodal.generatePIN.msgSucc',
  },
  AAmodalNewUserMsgErr: {
    defaultMessage: 'There was an error, please try again',
    description: 'Apartment admin snackbar - Apartment user created unsuccessfully',
    id: 'AAmodal.newUser.msgErr',
  },
  AAmodalNewUserMsgSucc: {
    defaultMessage: 'User created successfully',
    description: 'Apartment admin snackbar - Apartment user created successfully',
    id: 'AAmodal.newUser.msgSucc',
  },
  AASnackbarPinGeneratedFailed: {
    defaultMessage: 'There was an error, please try again',
    id: 'AAmodal.newPIN.msgErr',
  },
  AASnackbarPinGeneratedSuccessfully: {
    defaultMessage: 'PIN generated successfully',
    id: 'AAmodal.newPIN.msgSucc',
  },
});
