import { yupResolver } from '@hookform/resolvers/yup';
import { Send } from '@mui/icons-material';
import { Button, Stack } from '@mui/material';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import OutlinedTextField from '../../../../components/HookFormFields/TextField';
import { PATHS } from '../../../../config/consts';
import useBreakpoints from '../../../../helpers/useBreakpoints';
import { RouterLink } from '../Link/PrimaryLink';
import messages from './messages';
import { defaultValues, getEmailFormValidationSchema, IEmailFormData } from './validationScheme';

export interface IEmailFormProps {
  isDisabled: boolean;
  initEmail?: string;
  onSubmit: (values: IEmailFormData) => void;
}

export function EmailForm({ initEmail, isDisabled, onSubmit }: IEmailFormProps) {
  const { formatMessage } = useIntl();
  const { downSm } = useBreakpoints();

  const formData = useForm<IEmailFormData>({
    defaultValues: initEmail ? { ...defaultValues, email: initEmail } : defaultValues,
    mode: 'onChange',
    resolver: yupResolver(getEmailFormValidationSchema(formatMessage)),
  });

  const buttonsDirection = downSm ? 'column' : 'row';
  const buttonsSpacing = downSm ? 2 : 1;

  return (
    <FormProvider {...formData}>
      <form onSubmit={formData.handleSubmit((formValues: IEmailFormData) => onSubmit(formValues))}>
        <Stack direction="column" spacing={3}>
          <div>
            <OutlinedTextField
              label={<FormattedMessage {...messages.resetPasswordEmailLabel} />}
              name="email"
              required
              type="email"
              fullWidth
              sx={{ mb: 1, mt: 2 }}
            />
          </div>

          <Stack
            direction={buttonsDirection}
            spacing={buttonsSpacing}
            justifyContent="space-between"
            alignItems="center"
          >
            <RouterLink to={PATHS.LOGIN}>
              <FormattedMessage {...messages.resetPasswordBackToLogin} />
            </RouterLink>

            <Button
              startIcon={<Send />}
              color="primary"
              disabled={!formData.formState.isValid || isDisabled}
              size="large"
              type="submit"
              variant="contained"
            >
              <FormattedMessage {...messages.resetPasswordSubmit} />
            </Button>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
}
