import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

function Loader() {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', minWidth: { sm: '125px', xs: '70px' } }}>
      <CircularProgress size={30} />
    </Box>
  );
}

export default Loader;
