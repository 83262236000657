import InfoIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReadOnlyField, Tooltip } from '../../../components';
import { DEVICE_TYPE } from '../../../config/devices';
import messages from './messages';

export interface ISecuredCallsProps {
  deviceType: keyof typeof DEVICE_TYPE;
  sipsSupported?: boolean;
  srtpEnabled?: boolean;
}

const getUnavailableMessage = (deviceType: ISecuredCallsProps['deviceType']) => {
  if (deviceType === DEVICE_TYPE.HIPMO) {
    return messages.mobileVideoProxyInfoSecuredCallsUnavailableOldAppVersionText;
  }
  if (
    [
      DEVICE_TYPE.IP_INTERCOM,
      DEVICE_TYPE.ID_TALK,
      DEVICE_TYPE.CLIP,
      DEVICE_TYPE.ID_COMPACT,
      DEVICE_TYPE.ID_VIEW,
      DEVICE_TYPE.IP_STYLE,
      DEVICE_TYPE.IP_INTERCOM_V2,
      DEVICE_TYPE.IP_ONE_INTERCOM,
    ].includes(deviceType)
  ) {
    return messages.mobileVideoProxyInfoSecuredCallsUnavailableOldFwVersionText;
  }
  return messages.mobileVideoProxyInfoSecuredCallsUnavailableUnsupportedDeviceText;
};

export function SecuredCalls({ deviceType, sipsSupported, srtpEnabled }: ISecuredCallsProps) {
  return (
    <Grid item>
      <ReadOnlyField
        label={<FormattedMessage {...messages.mobileVideoProxyInfoSecuredCallsTitle} />}
        value={
          sipsSupported === true ? (
            <>
              <FormattedMessage {...messages.mobileVideoProxyInfoSecuredCallsSipsActive} />
              {', '}
              <FormattedMessage
                {...(srtpEnabled
                  ? messages.mobileVideoProxyInfoSecuredCallsSrtpActive
                  : messages.mobileVideoProxyInfoSecuredCallsSrtpInactive)}
              />
            </>
          ) : (
            <Box component="span">
              <FormattedMessage {...getUnavailableMessage(deviceType)} />
              <Tooltip
                title={<FormattedMessage values={{ br: <br /> }} {...getUnavailableMessageTooltip(deviceType)} />}
              >
                <InfoIcon
                  sx={{
                    color: 'info.main',
                    cursor: 'pointer',
                    ml: 1,
                    verticalAlign: 'text-bottom',
                  }}
                />
              </Tooltip>
            </Box>
          )
        }
      />
    </Grid>
  );
}

function getUnavailableMessageTooltip(deviceType: ISecuredCallsProps['deviceType']) {
  if (deviceType === DEVICE_TYPE.HIPMO) {
    return messages.mobileVideoProxyInfoSecuredCallsUnavailableOldAppVersionTooltip;
  }
  if (
    [
      DEVICE_TYPE.IP_INTERCOM,
      DEVICE_TYPE.ID_TALK,
      DEVICE_TYPE.CLIP,
      DEVICE_TYPE.ID_COMPACT,
      DEVICE_TYPE.ID_VIEW,
      DEVICE_TYPE.IP_STYLE,
      DEVICE_TYPE.IP_INTERCOM_V2,
      DEVICE_TYPE.IP_ONE_INTERCOM,
    ].includes(deviceType)
  ) {
    return messages.mobileVideoProxyInfoSecuredCallsUnavailableOldFwVersionTooltip;
  }
  return messages.mobileVideoProxyInfoSecuredCallsUnavailableUnsupportedDeviceTooltip;
}
