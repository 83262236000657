import { defineMessages } from 'react-intl';

export default defineMessages({
  titleDevice: {
    defaultMessage: 'Device info',
    description: 'Device info title',
    id: 'deviceInfoTitle.titleDevice',
  },
  titleMy2n: {
    defaultMessage: 'My2N settings',
    description: 'My2N info title',
    id: 'deviceInfoTitle.titleMy2n',
  },
  titleNetwork: {
    defaultMessage: 'Network settings',
    description: 'Network info title',
    id: 'deviceInfoTitle.titleNetwork',
  },
});
