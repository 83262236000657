import Typography from '@mui/material/Typography';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import Checkbox from '../../../../../components/HookFormFields/Checkbox';
import { MobileVideoType } from '../../../../../modules/commonTypes';
import { IMVLicenceModel } from '../../../../../modules/sites/store';
import { IApartmentFormProps } from '../../../../ApartmentsOverview/hooks/useGetApartmentFormData';

interface IActivatePaidServicesCheckbox {
  formMethods: UseFormReturn<IApartmentFormProps>;
  initialPaidServicesActiveValue: boolean;
  label: string;
  licenceModel: IMVLicenceModel;
  onOpenActivatePaidServicesModal: () => void;
}

function ActivatePaidServicesCheckbox({
  formMethods,
  initialPaidServicesActiveValue,
  label,
  licenceModel,
  onOpenActivatePaidServicesModal,
}: IActivatePaidServicesCheckbox) {
  const { licencesLimit, paidCount, type } = licenceModel;
  const isMVTypeStandard = type === MobileVideoType.STANDARD;

  const handleChange = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const isChecked: boolean = checked && checked !== initialPaidServicesActiveValue;
    if (isChecked && !sessionStorage.getItem('paidModal') && isMVTypeStandard && paidCount >= licencesLimit) {
      onOpenActivatePaidServicesModal();
    } else {
      formMethods.setValue('paidServicesActive', checked, { shouldDirty: true, shouldValidate: true });
    }
  };

  return <Checkbox label={<Typography>{label}</Typography>} onChange={handleChange} name="paidServicesActive" />;
}

export default ActivatePaidServicesCheckbox;
