import CloseIcon from '@mui/icons-material/Close';
import AlertMD, { AlertProps } from '@mui/material/Alert';
import AlertTitleMD from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import React from 'react';

const styles = {
  hideMobileIcon: {
    '& .MuiAlert-icon': {
      display: {
        md: 'flex',
        xs: 'none',
      },
    },
  },
  root: {
    my: 2,
  },
};

export interface IAlert extends AlertProps {
  action?: JSX.Element;
  children: React.ReactNode;
  hideMobileIcon?: boolean;
  icon?: React.ReactNode;
  onClose?: () => void;
  severity: 'error' | 'info' | 'success' | 'warning';
  title?: string;
  variant?: 'standard' | 'filled' | 'outlined';
}

const Alert = ({ action, children, hideMobileIcon, icon, onClose, severity, title, variant, ...other }: IAlert) => (
  <AlertMD
    {...other}
    action={
      action && onClose ? (
        <>
          <Box>{action}</Box>
          <Box sx={{ ml: 1 }}>
            <IconButton onClick={onClose} size="small">
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </>
      ) : (
        action
      )
    }
    icon={icon}
    onClose={onClose}
    severity={severity}
    sx={{
      ...other.sx,
      ...styles.root,
      ...(hideMobileIcon && styles.hideMobileIcon),
    }}
    variant={variant}
  >
    {title && <AlertTitleMD>{title}</AlertTitleMD>}
    {children}
  </AlertMD>
);

Alert.defaultProps = {
  action: null,
  hideMobileIcon: false,
  icon: null,
  onClose: null,
  title: null,
  variant: 'standard',
};

export default Alert;
