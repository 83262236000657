import { defineMessages } from 'react-intl';

export default defineMessages({
  codesTextCopyFieldAllInformation: {
    defaultMessage: 'All information',
    description: 'Radio label - All information',
    id: 'codesTextCopyField.allInformation',
  },
  codesTextCopyFieldCopyButton: {
    defaultMessage: 'Copy displayed',
    description: 'Copy button',
    id: 'codesTextCopyField.copyButton',
  },
  codesTextCopyFieldOnlyCodes: {
    defaultMessage: 'Only codes',
    description: 'Radio label - Only codes',
    id: 'codesTextCopyField.onlyCodes',
  },
});
