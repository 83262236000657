import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { HeaderPublic } from '../../components';

const GTCLayout = ({ children }) => (
  <ThemeProvider
    theme={(globalTheme) => ({
      ...globalTheme,
      palette: { ...globalTheme.palette, background: { default: 'white' } },
    })}
  >
    <CssBaseline />
    <HeaderPublic />
    <Container maxWidth="xl">{children}</Container>
  </ThemeProvider>
);

GTCLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GTCLayout;
