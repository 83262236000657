import { Location } from 'react-router-dom';
import { isLocationInActivePaths } from '../../../components/Sidebar/pathLocationMatcher';
import { PATHS } from '../../../config/consts';
import devicesTypes from '../../../modules/devices/types';
import { replaceURLParams } from '../../../routes/helpers/pathParamsReplacer';
import apartmentTypes from '../../apartments/types';
import { TId } from '../../commonTypes';

export enum DeleteDeviceContext {
  ApartmentDevices,
  DeviceDetail,
  SiteDevices,
}

export function getDeleteDeviceContext(location: Location): DeleteDeviceContext {
  if (isLocationInActivePaths([PATHS.APARTMENT_DEVICES], location)) {
    return DeleteDeviceContext.ApartmentDevices;
  } else if (isLocationInActivePaths([PATHS.DEVICES_OVERVIEW], location)) {
    return DeleteDeviceContext.SiteDevices;
  }
  return DeleteDeviceContext.DeviceDetail;
}

export function getDeleteDeviceSuccessRedirectPath(
  context: DeleteDeviceContext | undefined,
  ids: { apartmentId?: TId; companyId?: TId; siteId?: TId }
): string | undefined {
  switch (context) {
    case DeleteDeviceContext.ApartmentDevices:
      return replaceURLParams({ params: ids, url: PATHS.APARTMENT_DEVICES });
    case DeleteDeviceContext.SiteDevices:
    case DeleteDeviceContext.DeviceDetail:
      return replaceURLParams({ params: ids, url: PATHS.DEVICES_OVERVIEW });
    default:
      return undefined;
  }
}

export function getDeleteDeviceSuccessActionName(context: DeleteDeviceContext | undefined): string | undefined {
  switch (context) {
    case DeleteDeviceContext.ApartmentDevices:
      return apartmentTypes.DELETE_APARTMENT_DEVICE_SUCCESS;
    case DeleteDeviceContext.SiteDevices:
    case DeleteDeviceContext.DeviceDetail:
      return devicesTypes.DELETE_DEVICE_SUCCESS;
    default:
      return undefined;
  }
}
