import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ElIntBuilding } from '../../../../../apiRtk/be4feApi';
import messages from '../messages';

interface IBuildingIds {
  buildings: ElIntBuilding[] | undefined;
}

function BuildingIds({ buildings }: IBuildingIds) {
  return (
    <>
      {buildings &&
        buildings.map((building, index) => {
          return (
            <FormattedMessage
              key={index}
              {...messages.siteSettingsKoneCloudConnectionInfoText}
              values={{
                br: <br />,
                buildingId: building.id,
                index: index + 1,
              }}
            />
          );
        })}
    </>
  );
}

export default BuildingIds;
