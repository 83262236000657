import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { VERIFICATION_STATUS } from '../../config/devices';
import DevicesInfoChip from '../DevicesInfoChip';
import messages from './messages';

const DeviceVerificationStatus = ({ status }) => {
  let styles = {};
  if (status === VERIFICATION_STATUS.VERIFIED) {
    styles = 'success';
  } else if (status === VERIFICATION_STATUS.PENDING) {
    styles = 'warning';
  } else if ([VERIFICATION_STATUS.NOT_VERIFIED, VERIFICATION_STATUS.ZOMBIE].includes(status)) {
    styles = 'error';
  }

  const message = verificationStatusMap[status] ? verificationStatusMap[status] : messages.verificationStatusUnknown;

  return (
    <DevicesInfoChip
      error={styles === 'error'}
      label={<FormattedMessage {...message} />}
      size="small"
      success={styles === 'success'}
      variant="outlined"
      warning={styles === 'warning'}
    />
  );
};

DeviceVerificationStatus.propTypes = {
  status: PropTypes.string.isRequired,
};

export default DeviceVerificationStatus;

const verificationStatusMap = {
  [VERIFICATION_STATUS.VERIFIED]: messages.verificationStatusVerified,
  [VERIFICATION_STATUS.NOT_VERIFIED]: messages.verificationStatusNotVerified,
  [VERIFICATION_STATUS.PENDING]: messages.verificationStatusPending,
  [VERIFICATION_STATUS.ZOMBIE]: messages.verificationStatusZombie,
};
