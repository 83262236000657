import { defineMessages } from 'react-intl';

export default defineMessages({
  accessLogSwitchInfo: {
    defaultMessage: 'Data are stored in My2N for 90 days only',
    description: 'Access log switch - state on',
    id: 'accessLogSwitch.info',
  },
  accessLogSwitchSectionLabel: {
    defaultMessage: 'Access log',
    description: 'Access log switch - label',
    id: 'accessLogSwitch.sectionLabel',
  },
  accessLogSwitchStateOff: {
    defaultMessage: 'Logging is off',
    description: 'Access log switch - state off',
    id: 'accessLogSwitch.state.off',
  },
  accessLogSwitchStateOn: {
    defaultMessage: 'Logging is on',
    description: 'Access log switch - state on',
    id: 'accessLogSwitch.state.on',
  },
});
