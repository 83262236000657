import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import actions, { selectors } from '../../../modules/company';
import { IMvSdkSettingsFormData } from '../MvSdkSettingsForm';
import { getDefaultValues, getValidationSchema } from '../validationSchema';

export function useGetGlobalHooks() {
  const companyActions = actions;
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const data = useSelector(selectors.getMvSdkSettingsSelector);
  const isDefault = useSelector(selectors.getMvSdkSettingsIsDefault);
  const isLoading = useSelector(selectors.getMvSdkSettingsIsLoading);
  const formMethods = useForm<IMvSdkSettingsFormData>({
    defaultValues: getDefaultValues(data),
    mode: 'onChange',
    resolver: yupResolver(getValidationSchema(formatMessage)),
  });

  return {
    companyActions,
    data,
    dispatch,
    formatMessage,
    formMethods,
    isDefault,
    isLoading,
  };
}
