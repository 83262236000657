import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

const StyledRingingGroup = styled(Box)(({ theme }) => ({
  color: theme.palette.text.secondary,
}));

export interface IRingingGroupData {
  ringingGroup?: string;
}

export function RingingGroup(data: IRingingGroupData): JSX.Element {
  return <StyledRingingGroup>{data.ringingGroup || '–'}</StyledRingingGroup>;
}
