import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import React from 'react';

function Group({ group, items }) {
  return (
    <li>
      {group && (
        <Stack direction="row" px={2} py={1} spacing={1}>
          <Box color="text.secondary" fontStyle="italic">
            {group}
          </Box>
        </Stack>
      )}
      <Box>{items}</Box>
    </li>
  );
}

Group.propTypes = {
  group: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default Group;
