import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { LoadingButton, Modal } from '../../components';
import actions from '../../modules/sites';
import messages from './messages';

const DeleteSiteModal = ({ isFetching, onClose, onConfirm, open, site }) =>
  open ? (
    <Modal
      actions={
        <div>
          <Button onClick={onClose}>
            <FormattedMessage {...messages.deleteSiteModalCancel} />
          </Button>
          <LoadingButton
            color="error"
            disabled={isFetching}
            isLoading={isFetching}
            onClick={() => onConfirm(site)}
            startIcon={<DeleteIcon />}
            variant="outlined"
          >
            <FormattedMessage {...messages.deleteSiteModalConfirm} />
          </LoadingButton>
        </div>
      }
      headerVariant="danger"
      maxWidth="xs"
      name="delete-site-modal"
      onClose={onClose}
      open={open}
      title={<FormattedMessage values={{ siteName: site.name }} {...messages.deleteSiteModalTitle} />}
    >
      <Typography>
        <FormattedMessage {...messages.deleteSiteModalDescriptionTitle} />
      </Typography>
      <ul>
        <Typography component="li">
          <FormattedMessage {...messages.deleteSiteModalDescriptionListOneItem} />
        </Typography>
        <Typography component="li">
          <FormattedMessage {...messages.deleteSiteModalDescriptionListTwoItem} />
        </Typography>
        <Typography component="li">
          <FormattedMessage {...messages.deleteSiteModalDescriptionListThreeItem} />
        </Typography>
      </ul>
      <Typography>
        <FormattedMessage {...messages.deleteSiteModalDescriptionBottom} />
      </Typography>
    </Modal>
  ) : null;

DeleteSiteModal.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool,
  site: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
  }).isRequired,
};

DeleteSiteModal.defaultProps = {
  open: false,
};

const mapStateToProps = ({ sites }) => ({
  isFetching: sites.deleteModal.isFetching,
  open: sites.deleteModal.show,
  site: sites.deleteModal.site,
});

export default connect(mapStateToProps, {
  onClose: actions.deleteSiteHideModal,
  onConfirm: actions.deleteSiteConfirm,
})(DeleteSiteModal);
