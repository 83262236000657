import { defineMessages } from 'react-intl';

export default defineMessages({
  AAmodalDeleteGuestPinText: {
    id: 'AAmodal.deleteGuestPIN.text',
  },
  AAmodalDeletePINCancel: {
    defaultMessage: 'Cancel',
    id: 'AAmodal.deletePIN.cancel',
  },
  AAmodalDeletePINSubmit: {
    defaultMessage: 'Delete PIN',
    id: 'AAmodal.deletePIN.submit',
  },
  AAmodalDeletePINText1: {
    defaultMessage: 'When you delete the PIN code, the user won’t be able to use it for access.',
    id: 'AAmodal.deletePIN.text1',
  },
  AAmodalDeletePINText2: {
    defaultMessage:
      'The PIN is also deleted for other apartments the user may be in.<p>If the user has other access methods available, they can continue using them.</p>',
    id: 'AAmodal.deletePIN.text2',
  },
  AAmodalDeletePINTitle: {
    defaultMessage: 'Delete PIN',
    id: 'AAmodal.deletePIN.title',
  },
});
