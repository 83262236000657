import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import actions, { selectors, TYPE_CODE, TYPE_DESCRIPTOR } from '../../modules/notifications';
import messages from './messages';

let displayed = [];

const useNotification = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(selectors.getNotificationMessagesSelector);
  const { closeSnackbar, enqueueSnackbar } = useSnackbar();
  const { formatMessage } = useIntl();

  const storeDisplayed = (id) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  const translateCodeMessage = ({ action, code }, variant) => {
    if (errorCodesMap[code]) {
      return formatMessage(errorCodesMap[code]);
    }
    if (errorActionMap[action]) {
      return formatMessage(errorActionMap[action]);
    }

    const matches = code.toString().match(/^([A-Z]+)(\d+)$/);
    if (matches !== null) {
      const [, prefix] = matches;
      if (errorPrefixesMap[prefix]) {
        return formatMessage(errorPrefixesMap[prefix]);
      }
    }

    return formatMessage(messages.errorDefault);
  };

  useEffect(() => {
    notifications.forEach(({ dismissed = false, key, message, options = {}, type }) => {
      if (dismissed) {
        closeSnackbar(key);
        return;
      }

      if (displayed.includes(key)) {
        return;
      }

      if (message !== undefined && message.action !== 'LOGIN_FAILURE' && message.code?.toString() === '401') {
        return;
      }

      const getMessage = () => {
        if (type === TYPE_CODE) {
          return translateCodeMessage(message, options.variant);
        }
        if (type === TYPE_DESCRIPTOR) {
          return formatMessage(message);
        }
        return message;
      };

      enqueueSnackbar(getMessage(), {
        key,
        ...options,
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (event, myKey) => {
          dispatch(actions.removeSnackbar(myKey));
          removeDisplayed(myKey);
        },
      });

      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);
};

export default useNotification;

const errorActionMap = {
  LOGIN_FAILURE: messages.errorActionsLoginFailure,
  REGISTER_FAILURE: messages.errorActionsRegisterFailure,
  RESEND_INVITATION_EMAIL_FAILURE: messages.errorActionsResendInvitationEmailFailure,
  RESET_PASSWORD_FAILURE: messages.errorActionsResetPasswordFailure,
};

const errorCodesMap = {
  ER08: messages.errorCodesER08,
  ER09: messages.errorCodesER09,
  IV65: messages.errorCodesIV65,
  IV66: messages.errorCodesIV66,
};

const errorPrefixesMap = {
  HG: messages.errorActionsCodesPrefixCodesHG,
  IV: messages.errorActionsCodesPrefixCodesIV,
  RO: messages.errorActionsCodesPrefixCodesRO,
};
