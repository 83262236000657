import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import RowMenuItem from '../../DataGrid/RowMenuItem';
import messages from '../messages';

const DeleteDevice = ({ deviceId, onOpenModal }) => (
  <RowMenuItem
    colorIcon="error.dark"
    colorText="error.dark"
    icon={<DeleteIcon />}
    label={<FormattedMessage {...messages.deviceDataGridActionDeviceRowMenuDeleteDevice} />}
    onClick={() => onOpenModal(deviceId)}
  />
);

DeleteDevice.propTypes = {
  deviceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onOpenModal: PropTypes.func.isRequired,
};

export default DeleteDevice;
