import { PASSWORD_STATUS, VERIFICATION_STATUS } from '../config/devices';
import { CONTEXT } from '../modules/user';
import http from './index';

const encode = (value) => encodeURIComponent(value);

export const countDeviceType = async (company, site, deviceType) =>
  http.get(`/companies/${encode(company)}/sites/${encode(site)}/device-counts/${encode(deviceType)}`);

export const addDevice = async (company, site, values) =>
  http.post(`companies/${encode(company)}/sites/${encode(site)}/devices`, values);

export const addDeviceComposite = async (values) => http.post('composite/user', values);

export const getDevicesByType = async (company, site, deviceType) =>
  http.get(`companies/${encode(company)}/sites/${encode(site)}/devices`, {
    params: {
      filter: deviceType,
    },
  });

export const getCompanyAdminDevicesRequest = async (company, user, params) =>
  http.get(`companies/${encode(company)}/users/${encode(user)}/devices`, {
    params: {
      ...params,
    },
  });

export const getDevices = async (company, site, type, context, user, params) => {
  let url = `companies/${encode(company)}/sites/${encode(site)}`;
  switch (context) {
    case CONTEXT.SITE_USERS:
      url = `${url}/users/${encode(user)}/devices`;
      break;
    case CONTEXT.COMPANY_ADMINS:
      url = `companies/${encode(company)}/users/${encode(user)}/devices`;
      break;
    case CONTEXT.CURRENT_USER:
      url = 'users/current/devices';
      break;
    default:
      switch (type) {
        case 'MOBILE_VIDEO':
          url = `${url}/devices/MOBILE_VIDEO`;
          break;
        case 'LOCAL_CALLS':
          url = `${url}/services/REMOTE_CONFIGURATION/configurations`;
          break;
        default:
          url = `${url}/devices`;
          break;
      }
  }
  return http.get(url, { params });
};

export const getUserProfileDevices = async (params) => http.get('users/current/devices', { params });

export const getNotVerifiedDevices = async (company, site, params) =>
  http.get(`companies/${encode(company)}/sites/${encode(site)}/devices/verificationDevices`, {
    params: {
      ...params,
      status: VERIFICATION_STATUS.NOT_VERIFIED,
    },
  });

export const getDefaultPasswordDevices = async (company, site, params) =>
  http.get(`companies/${encode(company)}/sites/${encode(site)}/devices/passwordStatusDevices`, {
    params: {
      ...params,
      passwordStatus: PASSWORD_STATUS.DEFAULT_PASSWORD,
    },
  });

export const getDeviceDetail = async (company, site, deviceId) =>
  http.get(`companies/${encode(company)}/sites/${encode(site)}/devices/${encode(deviceId)}`);

export const getUsersDeviceDetail = async (device) => http.get(`users/current/devices/${encode(device)}`);

export const addUser = async (values) => http.post('users', values);

export const editDevice = async (company, site, device, values) =>
  http.put(`/companies/${encode(company)}/sites/${encode(site)}/devices/${encode(device)}`, values);

export const editDeviceMode = async (company, site, device, service, values) =>
  http.put(`/companies/${encode(company)}/sites/${encode(site)}/devices/${encode(device)}/services/${service}`, values);

export const getFirmwareVersion = async (company, site, device) =>
  http.get(`companies/${encode(company)}/sites/${encode(site)}/devices/${encode(device)}/firmware/recommended`);

export const updateFirmwareVersion = async (company, site, device, values) =>
  http.post(
    `companies/${encode(company)}/sites/${encode(site)}/devices/${encode(device)}/firmware/recommended`,
    values
  );

export const renewMobileVideoConfiguration = async (company, site, device) =>
  http.post(
    `/companies/${encode(company)}/sites/${encode(site)}/devices/${encode(device)}/services/MOBILE_VIDEO/action`,
    { action: 'RENEW_CONFIGURATION' }
  );

export const updateService = async (company, site, device, values, service) =>
  http.put(
    `companies/${encode(company)}/sites/${encode(site)}/devices/${encode(device)}/services/${encode(service)}`,
    values
  );

export const deleteDevice = async (company, site, device) =>
  http.delete(`companies/${company}/sites/${site}/devices/${device}`);

export const getWaitingDevices = async (company, site, params) =>
  http.get(`companies/${encode(company)}/sites/${encode(site)}/waiting-devices`, { params });

export const approveDevice = async (company, site, device, values) =>
  http.put(`companies/${encode(company)}/sites/${encode(site)}/waiting-devices/${encode(device)}`, values);

export const rejectDevice = async (company, site, device) =>
  http.delete(`companies/${encode(company)}/sites/${encode(site)}/waiting-devices/${encode(device)}`);

export const openRemoteConfiguration = async (company, site, device, action) =>
  http.post(
    `companies/${encode(company)}/sites/${encode(site)}/devices/${encode(device)}/services/REMOTE_CONFIGURATION/action`,
    action
  );

export const renewRemoteConfiguration = async (company, site, device) =>
  http.post(
    `/companies/${encode(company)}/sites/${encode(site)}/devices/${encode(device)}` +
      '/services/REMOTE_CONFIGURATION/action',
    { action: 'RENEW_CONFIGURATION' }
  );

export const activateRemoteConfiguration = async (company, site, device, active) =>
  http.put(
    `/companies/${encode(company)}/sites/${encode(site)}/devices/${encode(device)}` + '/services/REMOTE_CONFIGURATION',
    { active, type: 'REMOTE_CONFIGURATION' }
  );

export const createServiceConfiguration = async (company, site, device, service) =>
  http.post(`/companies/${encode(company)}/sites/${encode(site)}/devices/${encode(device)}` + '/services', {
    active: true,
    type: service,
  });

export const getCallSettingsDevices = async (company, site, params) =>
  http.get(`/companies/${encode(company)}/sites/${encode(site)}/devices/callSettingsDevices`, {
    params,
  });

export const createFeatureDevice = async (company, site, device, values) =>
  http.post(`/companies/${encode(company)}/sites/${encode(site)}/devices/${encode(device)}/features`, values);

export const updateFeatureDevice = async (company, site, device, type, id, values) =>
  http.put(
    `/companies/${encode(company)}/sites/${encode(site)}/devices/${encode(device)}/features/${encode(type)}/${encode(
      id
    )}`,
    values
  );

export const updateDTMFSwitchList = async (company, site, device, switchList, values) =>
  http.put(
    `/companies/${encode(company)}/sites/${encode(site)}/devices/${encode(device)}/features/DTMF_SWITCH_LIST/${encode(
      switchList
    )}`,
    values
  );

export const getLoadUsersNames = async (company, site) =>
  http.get(`/companies/${encode(company)}/sites/${encode(site)}/users/names`);

export const updateDtmfCode = async (company, site, device, type, id, values) =>
  http.put(
    `/companies/${encode(company)}/sites/${encode(site)}/devices/${encode(device)}/features/${encode(type)}/${encode(
      id
    )}`,
    values
  );

export const getDeviceTypesSettings = async (company) => http.get(`/companies/${encode(company)}/device-types`);
