import { GridColDef } from '@mui/x-data-grid';
import { MuiRow } from '../aggregatedCellContentRenderer';
import { IDataGridAggregatedRowMenuActions } from '../data';
import { CellWithMenuClassName } from '../styles';
import { renderRowMenuColumnContent } from './rowMenuColumnContent';

const columnWithMenuBaseDefinition: GridColDef = {
  align: 'center',
  cellClassName: CellWithMenuClassName,
  disableColumnMenu: true,
  field: 'datagrid-menu-field',
  flex: undefined,
  headerName: 'datagrid-menu-field-header',
  minWidth: undefined,
  sortable: false,
  type: '',
  width: 80,
};

export function getRowMenuColumnDefinition<TAggregatedItem extends object, TItem extends object>({
  rowMenuActions,
}: {
  rowMenuActions: IDataGridAggregatedRowMenuActions<TAggregatedItem, TItem>;
}): GridColDef<MuiRow<TItem | TAggregatedItem>> {
  return {
    ...columnWithMenuBaseDefinition,
    renderCell: (row) => {
      return renderRowMenuColumnContent({ row: row.row, rowMenuActions });
    },
  };
}
