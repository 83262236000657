import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

interface IDetailItemInfo {
  count: number | string;
  label: string | React.ReactNode;
}

const DetailItemInfo = ({ count, label }: IDetailItemInfo) => (
  <Box sx={{ alignItems: 'baseline', display: 'flex' }}>
    <Box sx={{ color: 'primary.main', fontSize: 34 }}>{count}</Box>
    <Typography sx={{ px: 1 }} variant="body1">
      {label}
    </Typography>
  </Box>
);

export default DetailItemInfo;
