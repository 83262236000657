import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { TabsContainer } from '../../../components';
import { PATHS } from '../../../config/consts';
import { useUrlParams } from '../../../helpers/urlParams';
import useBreakpoints from '../../../helpers/useBreakpoints';
import {
  showAccessSettings,
  showCallSettings,
  showPhonebooks,
  showSwitchCodes,
  showUnlockSettings,
} from '../routeHelpers';
import messages from './messages';

const getUrl = (path, companyId, siteId, deviceId) =>
  path.replace(':companyId', companyId).replace(':siteId', siteId).replace(':deviceId', deviceId);

const TabPanel = ({ device }) => {
  const { downSm } = useBreakpoints();
  const location = useLocation();
  const { companyId, deviceId, siteId } = useUrlParams();

  const tabs = [
    {
      condition: () => true,
      message: downSm ? messages.tabPanelDeviceDetailXs : messages.tabPanelDeviceDetail,
      pathTemplate: PATHS.DEVICE_DETAIL,
    },
    {
      condition: showCallSettings,
      message: messages.tabPanelCallSettings,
      pathTemplate: PATHS.DEVICE_CALL_SETTINGS,
    },
    {
      condition: showUnlockSettings,
      message: messages.tabPanelUnlockSettings,
      pathTemplate: PATHS.DEVICE_UNLOCK_SETTINGS,
    },
    {
      condition: showPhonebooks,
      message: messages.tabPanelPhonebooks,
      pathTemplate: PATHS.DEVICE_PHONEBOOKS,
    },
    {
      condition: showSwitchCodes,
      message: messages.tabPanelSwitchCode,
      pathTemplate: PATHS.DEVICE_SWITCH_CODES,
    },
    {
      condition: showAccessSettings,
      message: messages.tabPanelAccessSettings,
      pathTemplate: PATHS.DEVICE_ACCESS_SETTINGS,
    },
  ];

  const validValues = [];
  const validTabs = [];
  tabs.forEach(({ condition, message, pathTemplate }) => {
    if (condition(device)) {
      const path = getUrl(pathTemplate, companyId, siteId, deviceId);
      validTabs.push({
        message,
        path,
      });
      validValues.push(path);
    }
  });

  const value = validValues.includes(location?.pathname) ? location?.pathname : null;

  return value ? (
    <TabsContainer value={value}>
      {validTabs.map((item) => (
        <Tab
          component={Link}
          key={item.path}
          label={<FormattedMessage {...item.message} />}
          to={item.path}
          value={item.path}
        />
      ))}
    </TabsContainer>
  ) : (
    <>{false}</>
  );
};

TabPanel.propTypes = {
  device: PropTypes.shape({
    services: PropTypes.shape({
      ACCESS_CONTROL: PropTypes.shape({}),
      ACS: PropTypes.shape({}),
      LOCAL_CALLS: PropTypes.shape({
        active: PropTypes.bool,
      }),
      MOBILE_VIDEO: PropTypes.shape({
        active: PropTypes.bool,
      }),
    }),
    type: PropTypes.string,
  }).isRequired,
};

export default TabPanel;
