import React from 'react';
import { canAddDevice, shouldDisableAddDeviceButton } from '../helpers/helpers';
import { IDeviceCallSettings } from '../helpers/types';
import { IDeviceWithCallSettingsDetails } from '../hooks/useGetCallSettingsState';
import { DeviceCallSettings } from './DeviceCallSettings';

export interface IDevicesSettingsData {
  addedDeviceId: number;
  addedDeviceApartmentId?: number;
  availableDevices: IDeviceWithCallSettingsDetails[];
  selectedDevices: IDeviceCallSettings[];
  ringingGroupSizeLimit: number;
  onClearDevice: (deviceId: number) => void;
  onSelectDevice: (deviceId: number, previousDeviceId?: number) => void;
  onAddDevice: () => void;
  onAddButton: (deviceId: number) => void;
  onAddCallDestination: (deviceId: number, buttonIndex: number, addDevice: boolean) => void;
  onChangeButtonName: (deviceId: number, buttonIndex: number, name: string) => void;
  onClearDestination: (deviceId: number, buttonIndex: number, optionKey: number) => void;
}

export function DevicesSettings(data: IDevicesSettingsData): JSX.Element {
  const allDevices: React.ReactElement[] = [];
  for (let i = 0; i < data.selectedDevices.length; i++) {
    allDevices.push(
      <div key={data.selectedDevices[i].device?.id ?? 'empty-'}>
        <DeviceCallSettings
          addedDeviceApartmentId={data.addedDeviceApartmentId}
          addedDeviceId={data.addedDeviceId}
          availableDevices={data.availableDevices}
          canAddDevice={canAddDevice(data.selectedDevices, data.availableDevices)}
          currentSelectedDevice={data.selectedDevices[i]}
          isAddingDeviceDisabled={shouldDisableAddDeviceButton(data.selectedDevices)}
          isDeviceSelected={(deviceId) =>
            data.selectedDevices.some((selectedDevice) => selectedDevice.device?.id === deviceId)
          }
          onAddButton={data.onAddButton}
          onAddCallDestination={data.onAddCallDestination}
          onAddDevice={data.onAddDevice}
          onChangeButtonName={data.onChangeButtonName}
          onClearDestination={data.onClearDestination}
          onClearDevice={data.onClearDevice}
          onSelectDevice={data.onSelectDevice}
          ringingGroupSizeLimit={data.ringingGroupSizeLimit}
        />
      </div>
    );
  }

  return <>{allDevices}</>;
}
