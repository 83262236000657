import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  OutlinedInputProps,
} from '@mui/material';
import React, { ReactNode, useState } from 'react';
import { Controller, FieldPath, FieldValues, useFormContext } from 'react-hook-form';

export interface IPasswordInput<T extends FieldValues> extends OutlinedInputProps {
  id: string;
  name: FieldPath<T>;
  helperText?: ReactNode;
}

export function PasswordInput<T extends FieldValues>({ helperText, id, label, name, ...other }: IPasswordInput<T>) {
  const [show, setShow] = useState(false);
  const { control } = useFormContext<T>();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <FormControl sx={{ mb: 1, mt: 2 }}>
          <InputLabel error={!!fieldState?.error} disableAnimation required shrink htmlFor={id} variant="outlined">
            {label}
          </InputLabel>
          <OutlinedInput
            {...other}
            {...field}
            aria-describedby="password-helper-text"
            id={id}
            type={show ? 'text' : 'password'}
            label={label}
            notched
            disabled={other.disabled}
            error={!!fieldState?.error}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={() => setShow((prevState) => !prevState)}>
                  {show ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
          <FormHelperText id="password-helper-text" error={!!fieldState?.error}>
            {fieldState?.error?.message || helperText || ' '}
          </FormHelperText>
        </FormControl>
      )}
    />
  );
}
