import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Paper, Title } from '../../components';
import { FAQ } from '../../config/address';
import { useUrlParams } from '../../helpers/urlParams';
import actions from '../../modules/common';
import messages from './messages';
import SupportForm from './SupportForm';

const Support = ({ onSubmit }) => {
  const { formatMessage } = useIntl();
  const { companyId, siteId } = useUrlParams();

  const handleFormikSubmit = (values, formActions) => {
    onSubmit({
      ...values,
      companyId,
      siteId,
    });
    formActions.resetForm();
    formActions.setSubmitting(false);
  };

  return (
    <>
      <Title heading={<FormattedMessage {...messages.supportTitle} />} />
      <Paper>
        <Box maxWidth={600}>
          <Typography>
            <FormattedMessage {...messages.supportInfo} />
          </Typography>
          <Typography>
            <FormattedMessage
              {...messages.supportFaq}
              values={{
                link: (
                  <Link href={FAQ} rel="noopener noreferrer" target="_blank">
                    <FormattedMessage {...messages.supportFaqCheck} />
                  </Link>
                ),
              }}
            />
          </Typography>
          <Box pb={2} pt={4}>
            <Box pb={1}>
              <Typography variant="h5">
                <FormattedMessage {...messages.supportReportTitle} />
              </Typography>
            </Box>
            <Typography color="textSecondary" variant="body2">
              <FormattedMessage {...messages.supportPlaceholder} />
            </Typography>
            <Formik
              initialValues={{ message: '' }}
              onSubmit={handleFormikSubmit}
              render={(props) => <SupportForm {...props} />}
              validate={(values) => {
                const errors = {};
                if (!values.message) {
                  errors.message = formatMessage(messages.supportTextValidate);
                }
                return errors;
              }}
            />
          </Box>
        </Box>
      </Paper>
    </>
  );
};

Support.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  onSubmit: actions.sendSupport,
};

export default connect(null, mapDispatchToProps)(Support);
