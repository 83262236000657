import { defineMessages } from 'react-intl';

export default defineMessages({
  siteUserDetailDevicesInfoAlert: {
    defaultMessage: 'User without login can’t be assigned a device. You can change the user role on the {link}.',
    description: 'Site user detail info alert',
    id: 'siteUserDetailDevicesInfoAlert',
  },
  siteUserDetailDevicesInfoAlertLink: {
    defaultMessage: 'user detail tab',
    description: 'Site user detail info alert',
    id: 'siteUserDetailDevicesInfoAlertLink',
  },
});
