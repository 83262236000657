import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DEVICE_TYPE, SERVICE } from '../../../config/devices';
import { helpers } from '../../../modules/devices';
import messages from './messages';

export interface ICallSettingsHeaderProps {
  deviceType: string;
  services: Record<string, { active?: boolean }>;
}

export function CallSettingsHeader({ deviceType, services }: ICallSettingsHeaderProps) {
  const getMobileVideoHeader = () => {
    if (deviceType === DEVICE_TYPE.ID_COMPACT || deviceType === DEVICE_TYPE.ID_VIEW) {
      return <FormattedMessage {...messages.callSettingsHeaderMobileVideoCompact} />;
    }
    if (deviceType === DEVICE_TYPE.ID_TALK || deviceType === DEVICE_TYPE.CLIP) {
      return <FormattedMessage {...messages.callSettingsHeaderMobileVideoTalk} />;
    }
    return undefined;
  };

  const getLocalCallsHeader = () => {
    if (deviceType === DEVICE_TYPE.ID_COMPACT || deviceType === DEVICE_TYPE.ID_VIEW) {
      return <FormattedMessage {...messages.callSettingsHeaderLocalCallsCompact} />;
    }
    if (deviceType === DEVICE_TYPE.ID_TALK || deviceType === DEVICE_TYPE.CLIP) {
      return <FormattedMessage {...messages.callSettingsHeaderLocalCallsTalk} />;
    }

    return undefined;
  };

  return (
    <>
      {helpers.isActiveService({ services }, SERVICE.ACS) && (
        <Typography>
          {helpers.isActiveService({ services }, SERVICE.MOBILE_VIDEO) && getMobileVideoHeader()}
          {helpers.isActiveService({ services }, SERVICE.LOCAL_CALLS) && getLocalCallsHeader()}
        </Typography>
      )}
    </>
  );
}
