import { Checkbox as MCheckbox, FormControlLabel, Radio as MRadio } from '@mui/material';
import { Field } from 'formik';
import React, { forwardRef } from 'react';
import DateRangePicker from '../DateRangePicker';
import RadioListFilled from '../RadioListFilled';
import SelectField from '../SelectField';
import TextField from '../TextField';

const Input = forwardRef((props, ref) => (
  <Field component={TextField} fullWidth ref={ref} variant="outlined" {...props} />
));

const Select = forwardRef((props, ref) => (
  <Field component={SelectField} fullWidth ref={ref} variant="outlined" {...props} />
));

const Checkbox = forwardRef((props, ref) => (
  <Field
    component={({ field, ...rest }) => (
      <FormControlLabel
        control={<MCheckbox {...field} checked={Boolean(field.value)} color="primary" />}
        ref={ref}
        {...rest}
      />
    )}
    {...props}
  />
));

const Radio = forwardRef((props, ref) => (
  <Field
    component={({ field, ...rest }) => (
      <FormControlLabel
        control={<MRadio {...field} checked={Boolean(field.value)} color="primary" />}
        ref={ref}
        {...rest}
      />
    )}
    {...props}
  />
));

const RadioList = forwardRef((props, ref) => <Field component={RadioListFilled} ref={ref} {...props} />);

const DateRange = forwardRef((props, ref) => <Field component={DateRangePicker} ref={ref} {...props} />);

export default {
  Checkbox,
  DateRange,
  Input,
  Radio,
  RadioList,
  Select,
};
