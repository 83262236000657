import { defineMessages } from 'react-intl';

export default defineMessages({
  racActivateModalActivate: {
    defaultMessage: 'Activate',
    description: 'RAC activate modal - activate button',
    id: 'racActivateModal.activate',
  },
  racActivateModalText: {
    defaultMessage: 'Manage access rights for your users remotely.',
    description: 'RAC activate modal - text',
    id: 'racActivateModal.text',
  },
  racActivateModalTitle: {
    defaultMessage: 'Activate Residential Access',
    description: 'RAC activate modal - title',
    id: 'racActivateModal.title',
  },
});
