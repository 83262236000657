import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  defaultValues,
  INewPasswordFormData,
} from '../../../pages/ResetPasswordPage/components/NewPasswordForm/validationScheme';
import { useOryLoginApi, useOrySettingsApi } from '../../hooks/oryApi';
import { IUseOryResponseParser, useOryResponseParser } from '../../hooks/oryResponseParser';
import { IUpdatePasswordParams, refreshSession, tryToUpdatePassword } from './useChangeOryPasswordModalUtils';
import { getChangePasswordFormValidationSchema, IChangePasswordFormData } from './validationSchema';

export interface IUseOryUserSettings {
  updatePassword: (values: IChangePasswordFormData) => Promise<void>;
  getFormAlertMessage: IUseOryResponseParser['getFormAlertMessage'];
  formData: UseFormReturn<INewPasswordFormData, any, undefined>;
  isOryApiFetching: boolean;
  isPasswordHelperTextVisible: boolean;
  isSessionRefreshRequired: boolean;
}

export interface IUseOryUserSettingsProps {
  email: string;
  onSuccess: () => void;
}

export function useChangePasswordModal(props: IUseOryUserSettingsProps): IUseOryUserSettings {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const [isSessionRefreshRequired, setIsSessionRefreshRequired] = useState(false);
  const [wrongCurrentPasswordAttempts, setWrongCurrentPasswordAttempts] = useState(0);
  const dispatch = useDispatch();
  const { isOryApiFetching, updatePassword } = useOrySettingsApi();
  const { isOryApiFetching: isOryLoginApiFetching, loginToOry } = useOryLoginApi();
  const { clearFormAlertMessage, getFormAlertMessage, parseOryResponse } = useOryResponseParser();

  const formData = useForm<IChangePasswordFormData>({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(getChangePasswordFormValidationSchema(formatMessage, isSessionRefreshRequired)),
  });

  const { isDirty: isPasswordValidationDirty } = formData.getFieldState('password');
  const isPasswordHelperTextVisible = !isPasswordValidationDirty;

  const updatePasswordOrRefreshSession = async (values: IChangePasswordFormData) => {
    clearFormAlertMessage();

    const tryToUpdatePasswordParams: IUpdatePasswordParams = {
      dispatch,
      formData,
      navigate,
      onSuccess: props.onSuccess,
      parseOryResponse,
      password: values.password,
      setIsSessionRefreshRequired,
      updatePassword,
    };

    if (isSessionRefreshRequired && values.currentPassword) {
      await refreshSession({
        currentPassword: values.currentPassword,
        dispatch,
        email: props.email,
        formData,
        loginToOry,
        navigate,
        onSuccess: async () => {
          setIsSessionRefreshRequired(false);
          await tryToUpdatePassword(tryToUpdatePasswordParams);
        },
        parseOryResponse,
        setWrongCurrentPasswordAttempts,
        wrongCurrentPasswordAttempts,
      });
    } else {
      await tryToUpdatePassword(tryToUpdatePasswordParams);
    }
  };

  return {
    formData,
    getFormAlertMessage,
    isOryApiFetching: isOryApiFetching || isOryLoginApiFetching,
    isPasswordHelperTextVisible,
    isSessionRefreshRequired,
    updatePassword: updatePasswordOrRefreshSession,
  };
}
