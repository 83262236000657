import { defineMessages } from 'react-intl';

export default defineMessages({
  phonebookDetailColumnsDeviceId: {
    defaultMessage: 'Device ID',
    description: 'Phonebook detail - device id column',
    id: 'phonebookDetail.columns.deviceId',
  },
  phonebookDetailColumnsName: {
    defaultMessage: 'Name',
    description: 'Phonebook detail - name column',
    id: 'phonebookDetail.columns.name',
  },
  phonebookDetailColumnsType: {
    defaultMessage: 'Type',
    description: 'Phonebook detail - type column',
    id: 'phonebookDetail.columns.type',
  },
  phonebookDetailEdit: {
    defaultMessage: 'Edit Phonebook',
    description: 'Phonebook detail - edit phonebook button',
    id: 'phonebookDetail.edit',
  },
});
