import { defineMessages } from 'react-intl';

export default defineMessages({
  documentDateInfoDocumentDateInfoEffective: {
    defaultMessage: 'effective: {date}',
    description: 'Effective info',
    id: 'documentDateInfo.effective',
  },
  documentDateInfoDocumentDateInfoInfo: {
    defaultMessage: 'Posted: {postedDate}{span}|{span}Effective: {effectiveDate}',
    description: 'Common info',
    id: 'documentDateInfo.info',
  },
  documentDateInfoDocumentDateInfoPosted: {
    defaultMessage: 'Posted: {date}',
    description: 'Posted info',
    id: 'documentDateInfo.posted',
  },
});
