import { defineMessages } from 'react-intl';

export default defineMessages({
  createBatchDialogChannelLabel: {
    defaultMessage: 'Code Type',
    description: 'Channel label',
    id: 'createBatchDialog.channel.label',
  },
  createBatchDialogChannelTitleLeftButton: {
    defaultMessage: 'Paid',
    description: 'Channel title left button - Paid',
    id: 'createBatchDialog.channel.title.leftButton',
  },
  createBatchDialogChannelTitleRightButton: {
    defaultMessage: 'Promo',
    description: 'Channel title right button - Promo',
    id: 'createBatchDialog.channel.title.rightButton',
  },
  createBatchDialogClose: {
    defaultMessage: 'Close',
    description: 'CreateBatchDialog - close button',
    id: 'createBatchDialog.close',
  },
  createBatchDialogCreditAdornment: {
    defaultMessage: 'm/device',
    description: 'Credit placeholder',
    id: 'createBatchDialog.credit.adornment',
  },
  createBatchDialogCreditHelperText: {
    defaultMessage: 'Required',
    description: 'Credit helperText',
    id: 'createBatchDialog.credit.helperText',
  },
  createBatchDialogCreditLabel: {
    defaultMessage: 'Credits for 1 code',
    description: 'Credit for one code label',
    id: 'createBatchDialog.credit.label',
  },
  createBatchDialogCreditMaxDigits: {
    defaultMessage: 'The maximum length is 6 digits',
    description: 'Credit max digits',
    id: 'createBatchDialog.credit.maxDigits',
  },
  createBatchDialogDateCreatedHelperText: {
    defaultMessage: 'Creation date',
    description: 'DateCreated helperText',
    id: 'dataRangePicker.dateCreated.helperText',
  },
  createBatchDialogDateCreatedLabel: {
    defaultMessage: 'Valid from',
    description: 'DateCreated label',
    id: 'dataRangePicker.dateCreated.label',
  },
  createBatchDialogDateCreatedMax: {
    defaultMessage: 'The date cannot be different from today.',
    description: 'The date cannot be different from today',
    id: 'dataRangePicker.dateCreated.max',
  },
  createBatchDialogDateCreatedMin: {
    defaultMessage: 'The date cannot be different from today.',
    description: 'The date cannot be different from today',
    id: 'dataRangePicker.dateCreated.min',
  },
  createBatchDialogDateCreatedValid: {
    defaultMessage: 'Wrong date format.',
    description: 'Date format is not valid',
    id: 'dataRangePicker.dateCreated.valid',
  },
  createBatchDialogDescriptionLabel: {
    defaultMessage: 'Notes, comments, instructions',
    description: 'Description label',
    id: 'createBatchDialog.description.label',
  },
  createBatchDialogGenerateCodes: {
    defaultMessage: 'Generate codes',
    description: 'CreateBatchDialog - submit button',
    id: 'createBatchDialog.generateCodes',
  },
  createBatchDialogInvoiceHelperText: {
    defaultMessage: 'Very recommended for paid batch',
    description: 'Invoice helperText',
    id: 'createBatchDialog.invoice.helperText',
  },
  createBatchDialogInvoiceLabel: {
    defaultMessage: 'Invoice number',
    description: 'Invoice label',
    id: 'createBatchDialog.invoice.label',
  },
  createBatchDialogNameHelperText: {
    defaultMessage: 'Required. Batch Name help you manage codes.',
    description: 'BatchName helperText',
    id: 'createBatchDialog.name.helperText',
  },
  createBatchDialogNameLabel: {
    defaultMessage: 'Batch Name',
    description: 'BatchName label',
    id: 'createBatchDialog.name.label',
  },
  createBatchDialogSetDetails: {
    defaultMessage: 'Set details',
    description: 'CreateBatchDialog - set details button',
    id: 'createBatchDialog.setDetails',
  },
  createBatchDialogSizeAdornment: {
    defaultMessage: 'codes',
    description: 'Size placeholder',
    id: 'createBatchDialog.size.adornment',
  },
  createBatchDialogSizeHelperText: {
    defaultMessage: 'Required',
    description: 'Size helperText',
    id: 'createBatchDialog.size.helperText',
  },
  createBatchDialogSizeLabel: {
    defaultMessage: 'Number of codes',
    description: 'Size label',
    id: 'createBatchDialog.size.label',
  },
  createBatchDialogSizeMax: {
    defaultMessage: 'Maximal count is 5000 codes',
    description: 'Max size helperText',
    id: 'createBatchDialog.size.max',
  },
  createBatchDialogTitle: {
    defaultMessage: 'Create new batch of Activation Codes ({step}/{steps})',
    description: 'Create new batch title',
    id: 'createBatchDialog.title',
  },
  createBatchDialogTotalAdornment: {
    defaultMessage: 'm/device',
    description: 'Total placeholder',
    id: 'createBatchDialog.total.adornment',
  },
  createBatchDialogTotalHelperText: {
    defaultMessage: 'Month for 1 device',
    description: 'Total helperText',
    id: 'createBatchDialog.total.helperText',
  },
  createBatchDialogTotalLabel: {
    defaultMessage: 'Total Batch Value',
    description: 'Total label',
    id: 'createBatchDialog.total.label',
  },
  createBatchDialogUtmHelperText: {
    defaultMessage: 'Group batches by purpose, source, etc.',
    description: 'Utm helperText',
    id: 'createBatchDialog.utm.helperText',
  },
  createBatchDialogUtmLabel: {
    defaultMessage: 'Tags',
    description: 'Utm label',
    id: 'createBatchDialog.utm.label',
  },
  createBatchDialogValidityToHelperText: {
    defaultMessage: 'Set date of code expiration',
    description: 'ValidityTo unit',
    id: 'createBatchDialog.validityTo.helperText',
  },
  createBatchDialogValidityToLabel: {
    defaultMessage: 'Valid to',
    description: 'ValidityTo label',
    id: 'dataRangePicker.validityTo.label',
  },
  createBatchDialogValidityToMax: {
    defaultMessage: 'Date cannot be later than three years.',
    description: 'Date cannot be later than three years',
    id: 'dataRangePicker.validityTo.max',
  },
  createBatchDialogValidityToMin: {
    defaultMessage: 'Date cannot be in the past.',
    description: 'Date cannot be in the past',
    id: 'dataRangePicker.validityTo.min',
  },
  createBatchDialogValidityToValid: {
    defaultMessage: 'Wrong date format.',
    description: 'Date format is not valid',
    id: 'dataRangePicker.validityTo.valid',
  },
  recapBatchChannelLabel: {
    defaultMessage: 'Code Type',
    description: 'Channel label',
    id: 'recapBatch.channel.label',
  },
  recapBatchChannelPromo: {
    defaultMessage: 'Promo',
    description: 'Channel value PROMO',
    id: 'recapBatch.channel.promo',
  },
  recapBatchChannelPurchase: {
    defaultMessage: 'Purchase',
    description: 'Channel value PURCHASE',
    id: 'recapBatch.channel.purchase',
  },
  recapBatchClose: {
    defaultMessage: 'Close',
    description: 'recapBatch - close button',
    id: 'recapBatch.close',
  },
  recapBatchCreator: {
    defaultMessage: 'Creator',
    description: 'Creator label',
    id: 'recapBatch.creator',
  },
  recapBatchCreditAdornment: {
    defaultMessage: 'm/device',
    description: 'Credit adornment',
    id: 'recapBatch.credit.adornment',
  },
  recapBatchCreditLabel: {
    defaultMessage: 'Credits',
    description: 'Credit for one code label',
    id: 'recapBatch.credit.label',
  },
  recapBatchDateCreatedHelperText: {
    defaultMessage: '(Creation date)',
    description: 'DateCreated helperText',
    id: 'recapBatch.dateCreated.helperText',
  },
  recapBatchDateCreatedLabel: {
    defaultMessage: 'Valid from',
    description: 'DateCreated label',
    id: 'recapBatch.dateCreated.label',
  },
  recapBatchDescriptionLabel: {
    defaultMessage: 'Notes, comments, instructions',
    description: 'Description label',
    id: 'recapBatch.description.label',
  },
  recapBatchEditDetails: {
    defaultMessage: 'Edit details',
    description: 'recapBatch - edit details button',
    id: 'recapBatch.editDetails',
  },
  recapBatchGenerateCodes: {
    defaultMessage: 'Generate codes',
    description: 'recapBatch - generate details button',
    id: 'recapBatch.generateCodes',
  },
  recapBatchInvoice: {
    defaultMessage: 'Invoice',
    description: 'Invoice label',
    id: 'recapBatch.invoice',
  },
  recapBatchNameLabel: {
    defaultMessage: 'Batch Name',
    description: 'BatchName label',
    id: 'recapBatch.name.label',
  },
  recapBatchSizeAdornment: {
    defaultMessage: 'codes',
    description: 'Size adornment',
    id: 'recapBatch.size.adornment',
  },
  recapBatchSizeLabel: {
    defaultMessage: 'Codes',
    description: 'Size label',
    id: 'recapBatch.size.label',
  },
  recapBatchTotalAdornment: {
    defaultMessage: 'm/device',
    description: 'Total adornment',
    id: 'recapBatch.total.adornment',
  },
  recapBatchTotalLabel: {
    defaultMessage: 'Summary',
    description: 'Total label',
    id: 'recapBatch.total.label',
  },
  recapBatchUtm: {
    defaultMessage: 'Tags',
    description: 'Utm label',
    id: 'recapBatch.utm',
  },
  recapBatchValidityToHelperText: {
    defaultMessage: '({value} year(s))',
    description: 'ValidityTo unit',
    id: 'recapBatch.validityTo.helperText',
  },
  recapBatchValidityToLabel: {
    defaultMessage: 'Valid to',
    description: 'ValidityTo label',
    id: 'dataRangePicker.validityTo.label',
  },
});
