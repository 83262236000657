import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import Checkbox from '../../../../components/HookFormFields/Checkbox';
import { IFormData } from '../validationSchema';
import messages from './messages';

export function StoreHashControl() {
  const { setValue, watch } = useFormContext<IFormData>();
  const isIDT = watch('isIDT');
  const storeHash = watch('storeHash');
  useEffect(() => {
    if (isIDT) {
      setValue('storeHash', false);
    }
  }, [isIDT]);

  return (
    <>
      <Checkbox
        disabled={isIDT}
        label={<FormattedMessage {...messages.storeHashControlStoreHashLabel} />}
        name="storeHash"
        onClick={() => {
          if (!isIDT) {
            setValue('storeHash', storeHash);
          }
        }}
        onPointerDown={(event) => event.preventDefault()}
      />
      <Tooltip
        placement="right"
        sx={{
          '& .MuiTooltip-tooltip': {
            maxWidth: '200px',
          },
        }}
        title={<FormattedMessage {...messages.storeHashControlStoreHashTooltip} />}
      >
        <InfoOutlinedIcon />
      </Tooltip>
    </>
  );
}
