import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { FE_ROLES } from '../../../config/consts';
import featureFlags from '../../../config/featureFlags';
import { useModal } from '../../../hooks/useModal';
import { getApartmentDevices } from '../../../modules/apartments/selectors';
import { VisitorModalContext } from '../../../modules/commonTypes';
import { getSiteData } from '../../../modules/sites/selectors';
import userActions, { CONTEXT, selectors as userSelectors } from '../../../modules/user';
import { isUserGuest } from '../../../modules/user/utils';
import VisitorModal from '../../../pages/apartmentAdmin/ApartmentAdminUsersOverviewPage/components/VisitorModal/VisitorModal';
import RowMenu from '../../DataGrid/RowMenu';
import { useHasFeature } from '../../Feature/Feature';
import { UserDeleteModal } from '../../index';
import My2NAppAccessModalComponent from '../../My2NAppAccessModal';
import SetMy2NAppAccessMenuItem from '../../SetMy2NAppAccessMenuItem';
import UserPinModalNewComponent from '../../UserPinModalNew';
import UserRfidModalNewComponent from '../../UserRfidModalNew';
import AssignNewCard from './AssignNewCard';
import DeleteUser from './DeleteUser';
import EditUser from './EditUser';
import GenerateNewPin from './GenerateNewPin';
import SetAccessGroups from './SetAccessGroups';
import SetApartment from './SetApartment';

function SiteUsersRowMenu({
  companyId,
  currentUser,
  onOpenAccessGroupsModal,
  onOpenUserApartmentModal,
  setUserApartmentModalOpen,
  siteId,
  user,
}) {
  const apartmentDevices = useSelector(getApartmentDevices).data || [];
  const isUserBleCredentialFFEnable = useHasFeature(featureFlags.USER_BLE_CREDENTIAL);
  const siteData = useSelector(getSiteData);

  const { Modal: My2NAppAccessModal, onOpen: onOpenActivateSetMy2NAppAccess } = useModal({
    Modal: My2NAppAccessModalComponent,
  });
  const { Modal: UserPinModalNew, onOpen: onOpenUserPinModal } = useModal({
    Modal: UserPinModalNewComponent,
  });
  const { Modal: UserRfidModalNew, onOpen: onOpenUserRfidModal } = useModal({
    Modal: UserRfidModalNewComponent,
  });
  const { Modal: UserDeleteUModal, onOpen: onOpenUserDeleteUModal } = useModal({
    Modal: UserDeleteModal,
  });
  const { Modal: VisitorUModal, onOpen: onOpenVisitorUModal } = useModal({
    Modal: VisitorModal,
  });
  const isSiteBluetoothEnabled = siteData.services.ACCESS_CONTROL?.bluetoothEnabled;

  return (
    <>
      <RowMenu
        menuItems={
          <div>
            <EditUser companyId={companyId} siteId={siteId} userId={user.id} />
            <SetApartment
              onOpenUserApartmentModal={onOpenUserApartmentModal}
              setUserApartmentModalOpen={setUserApartmentModalOpen}
              user={user}
            />
            <Divider />
            <SetAccessGroups onOpenAccessGroupsModal={onOpenAccessGroupsModal} userId={user.id} />
            <GenerateNewPin
              disabled={user.credentials.includes('PIN')}
              onOpenGenerateNewPinModal={onOpenUserPinModal}
              onOpenVisitorModal={onOpenVisitorUModal}
              user={user}
            />
            {!isUserGuest(user.role) && (
              <AssignNewCard
                disabled={user.credentials.includes('RFID')}
                onOpenAssignNewCardModal={onOpenUserRfidModal}
                user={user}
              />
            )}
            {isUserBleCredentialFFEnable && !isUserGuest(user.role) && (
              <SetMy2NAppAccessMenuItem
                disabled={!isSiteBluetoothEnabled || user.role === FE_ROLES.LOGIN_LESS}
                isBleActive={user.credentials.includes('BT')}
                onClick={onOpenActivateSetMy2NAppAccess}
              />
            )}
            <Divider />
            <DeleteUser
              currentUser={currentUser}
              onOpenDeleteUserModal={onOpenUserDeleteUModal}
              user={user}
              siteIdsInfo={{ companyId, siteId }}
            />
          </div>
        }
      />
      {isUserBleCredentialFFEnable && (
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        <My2NAppAccessModal context="SITE_USERS_LIST" onActivate={() => {}} user={user} />
      )}
      <UserPinModalNew context="SITE_USERS_LIST" user={user} />
      <UserRfidModalNew context="SITE_USERS_LIST" user={user} />
      <UserDeleteUModal context={CONTEXT.SITE_USERS} user={user} />
      <VisitorUModal
        msgContext={VisitorModalContext.AA_MODAL_NEW_GUEST_PIN}
        apartmentInfo={{ companyId: companyId, siteId: siteId }}
        apartmentDevices={user?.apartments.length ? apartmentDevices : []}
        siteName={siteData.name}
        user={user}
        isRegenerate
      />
    </>
  );
}

SiteUsersRowMenu.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  onOpenAccessGroupsModal: PropTypes.func.isRequired,
  onOpenUserApartmentModal: PropTypes.func.isRequired,
  setUserApartmentModalOpen: PropTypes.func.isRequired,
  siteId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  user: PropTypes.shape({
    apartments: PropTypes.arrayOf(PropTypes.object),
    companies: PropTypes.arrayOf(PropTypes.object),
    credentials: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.number,
    role: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  }).isRequired,
};

const mapStateToProps = (state) => ({
  currentUser: userSelectors.getCurrentUserSelector(state),
});

const mapDispatchToProps = {
  onOpenAccessGroupsModal: userActions.accessGroupsModalOpen,
  onOpenUserApartmentModal: userActions.setApartmentToUserShowModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteUsersRowMenu);
