import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { IOryRecoveryFlow, OryResponse, OryResponseWithData } from '../../../types';
import { confirmOryRecoveryFlow, createOrGetOryRecoveryFlow, resendRecoveryCode } from './recoveryApi';

export interface ICreateOrGetOryRecoveryFlowProps {
  email: string;
  existingFlowId?: string;
}

export interface IConfirmOryRecoveryFlowProps {
  code: string;
  csrfToken: string;
  id: string;
}

export interface IResendRecoveryCodeProps {
  csrfToken: string;
  id: string;
  email: string;
}

export interface IUseRecoveryApi {
  createOrGetOryRecoveryFlow: (
    props: ICreateOrGetOryRecoveryFlowProps
  ) => Promise<OryResponseWithData<IOryRecoveryFlow>>;
  confirmOryRecoveryFlow: (props: IConfirmOryRecoveryFlowProps) => Promise<OryResponse>;
  resendRecoveryCode: (props: IResendRecoveryCodeProps) => Promise<OryResponse>;
  isOryApiFetching: boolean;
}

export function useOryRecoveryApi(): IUseRecoveryApi {
  const [isFetching, setIsFetching] = useState(false);
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  return {
    confirmOryRecoveryFlow: (props) => {
      return confirmOryRecoveryFlow(props, setIsFetching, dispatch, formatMessage);
    },
    createOrGetOryRecoveryFlow: (props) => {
      return createOrGetOryRecoveryFlow(props, setIsFetching, dispatch, formatMessage);
    },
    isOryApiFetching: isFetching,
    resendRecoveryCode: (props) => {
      return resendRecoveryCode(props, setIsFetching, dispatch, formatMessage);
    },
  };
}
