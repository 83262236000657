import CheckIcon from '@mui/icons-material/Check';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IModalBase } from '../../modules/commonTypes';
import { ActionModalError } from '../_DesignSystemWrappers/Modal';
import Alert from '../Alert';
import OutlinedTextField from '../HookFormFields/TextField';
import useGetGlobalHooks from './hooks/getGlobalHooks';
import messages from './messages';

interface IDeactivateMy2NAccessModal extends IModalBase {
  onSubmit: () => void;
}

function DeactivateMy2NAccessModal({ onClose, onSubmit, open }: IDeactivateMy2NAccessModal) {
  const { error, formatMessage, formMethods } = useGetGlobalHooks();

  function handleDeactivateMy2NAccess() {
    onSubmit();
    onClose();
  }

  return (
    <ActionModalError
      icon={<CheckIcon />}
      isPrimaryButtonDisabled={!formMethods.formState.isValid}
      muiDialogProps={{ fullWidth: true, maxWidth: 'xs' }}
      onClose={onClose}
      open={open}
      primaryButtonAction={formMethods.handleSubmit(handleDeactivateMy2NAccess)}
      primaryButtonText={formatMessage(messages.deactivateMy2NAccessModalSubmit)}
      secondaryButtonText={formatMessage(messages.deactivateMy2NAccessModalCancel)}
      title={formatMessage(messages.deactivateMy2NAccessModalTitle)}
    >
      <Typography component="span" variant="body2" pb={2}>
        <FormattedMessage {...messages.deactivateMy2NAccessModalText1} />
      </Typography>
      <Alert severity="warning" variant="standard">
        <FormattedMessage {...messages.deactivateMy2NAccessModalWarning} />
      </Alert>
      <Typography component="span" variant="body2" pt={2}>
        <FormattedMessage
          {...messages.deactivateMy2NAccessModalText2}
          values={{ theWord: formatMessage(messages.deactivateMy2NAccessModalInput) }}
        />
      </Typography>
      <Box display="block" pt={2}>
        <OutlinedTextField
          name="deactivate"
          required
          control={formMethods.control}
          error={formMethods.formState.isDirty && !!error}
          helperText={formMethods.formState.isDirty && error ? error.message : ''}
        />
      </Box>
    </ActionModalError>
  );
}

export default DeactivateMy2NAccessModal;
