import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useGetActualCompanyId } from '../../../../hooks/companyHooks';
import { useGetActualSiteId } from '../../../../hooks/siteHooks';
import apartmentActions from '../../../../modules/apartments';
import actions, { selectors as deviceSelectors } from '../../../../modules/devices';

export function useFetchCallSettingsData() {
  const dispatch = useDispatch();
  const companyId = useGetActualCompanyId();
  const siteId = useGetActualSiteId();

  const apartmentId = useSelector(deviceSelectors.getFinishedDataDeviceModal)?.apartmentId;

  useEffect(() => {
    if (apartmentId !== undefined) {
      dispatch(apartmentActions.getApartmentsList(companyId, siteId));
    }
  }, [apartmentId]);

  useEffect(() => {
    dispatch(actions.getCallSettingsDevicesChips(companyId, siteId));
  }, []);

  return {};
}
