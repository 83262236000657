export default {
  formIcon: {
    mr: 1,
  },
  formTitle: {
    fontSize: '16px !important',
    mb: 1,
  },
  infoIcon: {
    cursor: 'pointer',
    ml: 3,
  },
  root: {
    px: 3,
    py: 4,
    textAlign: 'left',
  },
  sectionHeader: {
    mb: 3,
  },
};
