import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DEVICE_TYPE } from '../../../../config/devices';
import messages from './messages';

export interface ILocalCallsIdentifierFieldProps {
  deviceType: string;
  disabled: boolean;
  isLocalCallsActive: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
}

export function LocalCallsIdentifierField(props: ILocalCallsIdentifierFieldProps) {
  return (
    <Box mt={1} pb={2}>
      <TextField
        disabled={props.disabled}
        fullWidth
        helperText={<FormattedMessage {...messages.localCallsIdentifierFieldHelper} />}
        InputProps={{ readOnly: props.deviceType !== DEVICE_TYPE.IDT && !props.isLocalCallsActive }}
        label={<FormattedMessage {...messages.localCallsIdentifierFieldDeviceId} />}
        name="localCallsIdentifier"
        onChange={props.onChange}
        value={props.value}
        variant="outlined"
      />
    </Box>
  );
}
