import { useEffect } from 'react';
import { IntlFormatters, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../../../modules/apartmentAdmin/actions';
import * as selectors from '../../../../modules/apartmentAdmin/selectors';
import { TId } from '../../../../modules/commonTypes';
import { IDevice } from '../../../../modules/devices/store/deviceStore';
import { DeviceTypeEnum } from '../../../../modules/devices/store/storeTypes';

export function useApartmentAdminUserMVService(
  apartmentId: TId,
  companyId: TId,
  siteId: TId,
  userId: TId
): {
  devices: Array<IDevice> | undefined;
  formatMessage: IntlFormatters['formatMessage'];
  isFetching: boolean;
} {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { data, didInvalid, isFetching } = useSelector(selectors.getDevicesWithinApartmentSelector);
  const devices = data?.filter((device: IDevice) => device?.type === DeviceTypeEnum.HIPMO);

  useEffect(() => {
    dispatch(actions.getDevicesWithinApartment(companyId, siteId, apartmentId, userId));
  }, [didInvalid]);

  return {
    devices,
    formatMessage,
    isFetching,
  };
}
