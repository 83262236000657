import { defineMessages } from 'react-intl';

export default defineMessages({
  accessGroupApartmentsDataGridDataGridRowMenuAccessGroups: {
    defaultMessage: 'Set access groups',
    description: 'Table row actions - access groups',
    id: 'accessGroupApartmentsDataGrid.dataGridRowMenu.accessGroups',
  },
  accessGroupApartmentsDataGridDataGridRowMenuEdit: {
    defaultMessage: 'Edit apartment',
    description: 'Table row actions - edit',
    id: 'accessGroupApartmentsDataGrid.dataGridRowMenu.edit',
  },
  accessGroupApartmentsDataGridDataGridRowMenuRemove: {
    defaultMessage: 'Remove from group',
    description: 'Table row actions - remove',
    id: 'accessGroupApartmentsDataGrid.dataGridRowMenu.remove',
  },
});
