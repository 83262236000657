import { defineMessages } from 'react-intl';

export default defineMessages({
  companyAdminDevicesDataGridColumnsActiveServices: {
    defaultMessage: 'Active services',
    description: 'Device Data Grid columns - active services',
    id: 'companyAdminDevicesDataGrid.columnsActiveServices',
  },
  companyAdminDevicesDataGridColumnsApartment: {
    defaultMessage: 'Apartment',
    description: 'Device Data Grid columns - apartment',
    id: 'companyAdminDevicesDataGrid.columnsApartment',
  },
  companyAdminDevicesDataGridColumnsDeviceName: {
    defaultMessage: 'Device name',
    description: 'Device Data Grid columns - device name',
    id: 'companyAdminDevicesDataGrid.columnsDeviceName',
  },
  companyAdminDevicesDataGridColumnsDeviceStatus: {
    defaultMessage: 'Device status',
    description: 'Device Data Grid columns - device status',
    id: 'companyAdminDevicesDataGrid.columnsDeviceStatus',
  },
  noRowsOverlayHeaderText: {
    defaultMessage: 'No devices yet',
    description: 'Company admin devices no rows - headerText',
    id: 'companyAdminDevicesDataGrid.noRowsOverlay.headerText',
  },
  noRowsOverlayText: {
    defaultMessage: 'Devices assigned to this user will appear here',
    description: 'Company admin devices no rows - text',
    id: 'companyAdminDevicesDataGrid.noRowsOverlay.text',
  },
});
