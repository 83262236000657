import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { useButtonText } from '../../helpers/dataGrid';
import useBreakpoints from '../../helpers/useBreakpoints';
import messages from './messages';

function UsersImportButton({ disabled, onClick }) {
  const { downSm } = useBreakpoints();

  return (
    <Button
      color="primary"
      disabled={disabled}
      onClick={onClick}
      startIcon={<CloudUploadIcon />}
      sx={{ mr: 2 }}
      variant={downSm ? 'text' : 'outlined'}
    >
      {useButtonText(messages.UsersImportButtonLabel, messages.UsersImportButtonLabelMobile)}
    </Button>
  );
}

UsersImportButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

UsersImportButton.defaultProps = {
  disabled: false,
};

export default UsersImportButton;
