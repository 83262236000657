import FormGroup from '@mui/material/FormGroup';
import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import Checkbox from '../HookFormFields/Checkbox';
import { useGetGlobalHooks } from './hooks/useGetGlobalHooks';
import messages from './messages';

function SettingsFormCheckboxes() {
  const { setValue, watch } = useFormContext();
  const { formatMessage } = useGetGlobalHooks();
  const landingCallValue = watch('landingCall');

  useEffect(() => {
    if (!landingCallValue) {
      setValue('destinationCall', false);
    }
  }, [landingCallValue]);

  return (
    <FormGroup>
      <Checkbox name="landingCall" label={formatMessage(messages.settingsFormLandingCall)} />
      <Checkbox
        name="destinationCall"
        disabled={!landingCallValue}
        label={formatMessage(messages.settingsFormDestinationCall)}
      />
    </FormGroup>
  );
}

export default SettingsFormCheckboxes;
