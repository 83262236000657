import LocationOnIcon from '@mui/icons-material/LocationOn';
import Skeleton from '@mui/material/Skeleton';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../config/consts';
import { useDataGrid } from '../../helpers/dataGrid';
import useBreakpoints from '../../helpers/useBreakpoints';
import { useModal } from '../../hooks/useModal';
import { isFetchingMessages } from '../../modules/localization/selectors';
import * as siteActions from '../../modules/sites/actions';
import DataGrid from '../DataGrid';
import NameColumn from '../DataGrid/NameColumn';
import EllipsisText from '../EllipsisText';
import { SelectSiteTypeModal } from '../index';
import SiteStatus from '../SiteStatus';
import SiteType from '../SiteType';
import DataGridRowMenu from './DataGridRowMenu/DataGridRowMenu';
import messages from './messages';
import NoRowsOverlay from './NoRowsOverlay';
import NoRowsSearchOverlay from './NoRowsSearchOverlay';

const SitesDataGrid = ({
  companyId,
  context,
  data,
  didInvalid,
  filter,
  isFetching,
  onAddSite,
  onChangeFilter,
  onLoadSites,
  order,
  page,
  resultsFiltered,
  rowsPerPage,
}) => {
  const { formatMessage } = useIntl();
  const { downSm, upLg, upSm } = useBreakpoints();
  const isLocalizationLoaded = useSelector(isFetchingMessages);

  const { onOrderBy, onRowsPerPageChange, onSelectPage } = useDataGrid(
    onLoadSites,
    {
      companyId,
      context,
      filter,
      order,
      page,
      rowsPerPage,
    },
    didInvalid,
    isFetching
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Modal: SelectSiteType, onOpen: openSelectSiteTypeModal } = useModal({
    Modal: SelectSiteTypeModal,
    onClose: () => {
      dispatch(siteActions.setSiteTypeModalClearData());
    },
  });
  const [siteTypeModalSite, setSiteTypeModalSite] = useState(undefined);
  const openSelectSiteType = (site) => {
    setSiteTypeModalSite({ id: site.id, name: site.name, type: site.type });
    openSelectSiteTypeModal();
  };

  const columns = useMemo(
    () => [
      {
        disableColumnMenu: true,
        field: 'name',
        flex: 1,
        headerName: formatMessage(messages.sitesDataGridColumnsName),
        renderCell: (site) =>
          isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <NameColumn iconComponent={<LocationOnIcon />} name={site.value?.name} />
          ),
        sortable: true,
        width: 200,
      },
      {
        disableColumnMenu: true,
        field: 'my2nId',
        flex: 1,
        headerName: formatMessage(messages.sitesDataGridColumnsMy2nId),
        renderCell: (site) =>
          isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <EllipsisText>{site.value?.my2nId}</EllipsisText>
          ),
        sortable: false,
      },
      {
        disableColumnMenu: true,
        field: 'type',
        flex: 1,
        headerName: formatMessage(messages.sitesDataGridColumnsType),
        renderCell: (site) =>
          isFetching ? <Skeleton animation="wave" height={25} width="80%" /> : <SiteType type={site.value?.type} />,
        sortable: false,
      },
      {
        disableColumnMenu: true,
        field: 'status',
        flex: 1,
        headerName: formatMessage(
          downSm
            ? messages.sitesDataGridColumnsMobileVideoStatusMobile
            : messages.sitesDataGridColumnsMobileVideoStatusDesktop
        ),
        renderCell: (site) =>
          isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <SiteStatus licenceModel={site.value?.mobileVideo.licenceModel} />
          ),
        sortable: false,
      },
      {
        disableColumnMenu: true,
        field: 'rowMenu',
        headerName: '',
        renderCell: (site) =>
          isFetching ? (
            <Skeleton animation="wave" height={25} width="80%" />
          ) : (
            <DataGridRowMenu onSetSiteType={openSelectSiteType} site={site.value} />
          ),
        sortable: false,
        width: 65,
      },
    ],
    [downSm, isFetching, upLg, upSm, isLocalizationLoaded]
  );

  const rows = useMemo(
    () =>
      data.map((site) => ({
        id: site.id,
        isClickable: true,
        my2nId: site,
        name: site,
        rowMenu: site,
        status: site,
        type: site,
      })),
    [data]
  );

  const onClearSearch = () => {
    onChangeFilter({
      target: {
        name: 'fulltext',
        value: '',
      },
    });
  };

  return (
    <>
      <DataGrid
        columns={columns}
        columnVisibilityModel={{
          my2nId: upLg,
          type: upSm,
        }}
        disableRowSelectionOnClick
        hideFooterPagination={isFetching}
        loading={isFetching}
        noRowsOverlay={
          filter.fulltext !== '' ? (
            <NoRowsSearchOverlay onClearSearch={onClearSearch} searchText={filter.fulltext} />
          ) : (
            <NoRowsOverlay onCreateSite={onAddSite} />
          )
        }
        onCellClick={(site) => {
          if (site.field !== 'rowMenu') {
            navigate(PATHS.SITE_DASHBOARD.replace(':companyId', companyId).replace(':siteId', site.value?.id));
          }
        }}
        onOrderBy={onOrderBy}
        onRowsPerPageChange={onRowsPerPageChange}
        onSelectPage={onSelectPage}
        order={order}
        page={page}
        resultsFiltered={resultsFiltered}
        resultsTotal={resultsFiltered}
        rows={rows}
        rowsPerPage={rowsPerPage}
      />
      <SelectSiteType company={{ id: companyId }} site={siteTypeModalSite} />
    </>
  );
};

SitesDataGrid.propTypes = {
  companyId: PropTypes.number.isRequired,
  context: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  didInvalid: PropTypes.bool,
  filter: PropTypes.shape({ fulltext: PropTypes.string }).isRequired,
  isFetching: PropTypes.bool.isRequired,
  onAddSite: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onLoadSites: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  resultsFiltered: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

SitesDataGrid.defaultProps = {
  context: '',
  didInvalid: false,
};

export default SitesDataGrid;
