import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { PATHS } from '../../config/consts';
import actions, { selectors as commonSelectors } from '../../modules/common';
import GTCItem from './GTCItem';
import GTCLayout from './GTCLayout';
import GTCWrapper from './GTCWrapper';
import { getGTCListData } from './helper';
import messages from './messages';

const GTCPage = ({ gtcData, onLoadGTCList }) => {
  useEffect(() => {
    onLoadGTCList();
  }, []);

  return (
    <GTCLayout>
      <GTCWrapper title={<FormattedMessage {...messages.gtcTitle} />}>
        {getGTCListData(gtcData).map((item) => (
          <GTCItem
            basePath={PATHS.GTC_PAGE_DETAIL}
            description={item.description}
            key={item.id}
            name={item.name}
            versions={item.versions}
          />
        ))}
      </GTCWrapper>
    </GTCLayout>
  );
};

GTCPage.propTypes = {
  gtcData: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      id: PropTypes.number,
      name: PropTypes.string,
      nextId: PropTypes.number,
      path: PropTypes.string,
    })
  ).isRequired,
  onLoadGTCList: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  gtcData: commonSelectors.getGTCList(state),
});

const dispatchToProps = {
  onLoadGTCList: actions.loadGTCList,
};

export default connect(mapStateToProps, dispatchToProps)(GTCPage);
