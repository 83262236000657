import Box from '@mui/material/Box';
import { blueGrey } from '@mui/material/colors';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const styles = {
  description: {
    color: 'action.active',
    fontSize: 16,
    fontWeight: 'regular',
    lineHeight: 1.5,
  },
  root: {
    '& .icon': {
      backgroundColor: blueGrey[300],
      border: 1,
      borderColor: blueGrey[300],
      borderRadius: '100%',
      display: 'block',
      height: '100px',
      mb: 2.5,
      mt: 0,
      mx: 'auto',
      opacity: 0.8,
      p: 2,
      width: '100px',
    },
    '&:hover': {
      '& .icon': {
        backgroundColor: 'primary.main',
      },
      border: 1,
      borderColor: 'primary.main',
    },
    backgroundColor: 'common.white',
    border: 1,
    borderColor: 'common.white',
    boxShadow: '0 1px 2px rgba(0,0,0,.38)',
    color: 'text.primary',
    display: 'block',
    height: '100%',
    p: 4,
    textAlign: 'center',
    textDecoration: 'none',
  },
  title: {
    color: 'text.primary',
    fontSize: 24,
    fontWeight: 'regular',
    lineHeight: 1.5,
  },
};

const DashboardLink = ({ children, icon, title, to, ...other }) => (
  <Box {...other} component={Link} sx={styles.root} to={to}>
    <Box className="icon">
      <img alt={title} src={icon} />
    </Box>
    <Typography sx={styles.title} variant="h2">
      {title}
    </Typography>
    <Typography sx={styles.description}>{children}</Typography>
  </Box>
);

DashboardLink.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.node.isRequired,
  to: PropTypes.string.isRequired,
};

export default DashboardLink;
