import { createExternalId } from '../../../../helpers/common';

export class ExternalIdGenerator {
  _storedIds: Map<number, string> = new Map<number, string>();

  public getExternalId(copyExternalIds: boolean, index: number): string | undefined {
    if (copyExternalIds) {
      return this._storedIds.get(index);
    }

    const externalId = createExternalId();
    this._storedIds.set(index, externalId);
    return externalId;
  }
}
