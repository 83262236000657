import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import ReportProblem from '@mui/icons-material/ReportProblem';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import DeviceForFreeTooltip from '../../../components/DeviceForFreeTooltip';

const styles = {
  cell: {
    alignItems: 'center',
    display: 'flex',
  },
  errorIcon: {
    '& svg': {
      color: 'error.main',
    },
  },
  icon: {
    height: '24px',
    mr: 1,
    width: '24px',
  },
  noDevicesIcon: {
    '& svg': {
      color: 'text.disabled',
    },
  },
  successIcon: {
    '& svg': {
      color: 'success.main',
    },
  },
  titleDevices: {
    color: 'text.primary',
  },
  titleNoDevices: {
    color: 'text.disabled',
  },
  warnIcon: {
    '& svg': {
      color: 'warning.main',
    },
  },
};

const Device = ({
  children,
  count,
  freeLicence,
  isError,
  isLastChild,
  isSmartphoneOrTablet,
  isTooltip,
  isWarn,
  status,
}) => {
  const getIcon = () => {
    if (count === 0) {
      return <CancelIcon />;
    }
    if (isError) {
      return <ErrorIcon />;
    }
    if (isWarn) {
      return <ReportProblem />;
    }
    return <CheckCircleIcon />;
  };
  return (
    <Box
      alignItems="center"
      display="flex"
      mb={
        !isLastChild && {
          sm: 1.5,
          xs: 0.5,
        }
      }
    >
      <Box
        sx={{
          ...styles.icon,
          ...styles.cell,
          ...(count === 0 && styles.noDevicesIcon),
          ...(count !== 0 && isError && styles.errorIcon),
          ...(count !== 0 && !isError && isWarn && styles.warnIcon),
          ...(count !== 0 && !isError && !isWarn && styles.successIcon),
        }}
      >
        {getIcon()}
      </Box>
      <DeviceForFreeTooltip
        freeLicence={freeLicence}
        isSmartphoneOrTablet={isSmartphoneOrTablet}
        isTooltip={isTooltip}
        status={status}
      >
        <Typography
          component="div"
          noWrap
          sx={{
            ...styles.cell,
            ...(count !== 0 && styles.titleDevices),
            ...(count === 0 && styles.titleNoDevices),
          }}
          variant="body2"
        >
          {children}
        </Typography>
      </DeviceForFreeTooltip>
    </Box>
  );
};

Device.propTypes = {
  children: PropTypes.element.isRequired,
  count: PropTypes.number,
  freeLicence: PropTypes.number,
  isError: PropTypes.bool,
  isLastChild: PropTypes.bool,
  isSmartphoneOrTablet: PropTypes.bool,
  isTooltip: PropTypes.bool,
  isWarn: PropTypes.bool,
  status: PropTypes.string.isRequired,
};

Device.defaultProps = {
  count: 0,
  isError: false,
  isLastChild: false,
  isTooltip: false,
  isWarn: false,
};

export default Device;
