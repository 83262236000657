import { yupResolver } from '@hookform/resolvers/yup';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useUrlParams } from '../../helpers/urlParams';
import actions, { selectors } from '../../modules/accessSettings';
import userActions, { selectors as userSelectors } from '../../modules/user';
import AccessGroupAutocomplete from '../AccessGroupAutocomplete';
import useAccessGroupFormValidationSchema from '../AccessGroupAutocomplete/useAccessGroupFormValidationSchema';
import PrimaryModal from '../PrimaryModal';
import messages from './messages';

// eslint-disable-next-line max-statements
const UserAccessGroupsModal = () => {
  const hasFormItemKey = 'hasForm';
  const newItemId = 'newItemId';
  const { formatMessage } = useIntl();
  const { companyId, siteId } = useUrlParams();
  const dispatch = useDispatch();

  const data = {
    groups: useSelector(selectors.getAccessGroupsListAll),
    isLoading: useSelector(selectors.isCreateAddUserAccessGroupsFetching),
    isLoadingUserGroups: useSelector(selectors.getAccessGroupUserGroupsFetching),
    open: useSelector(userSelectors.getUserAccessGroupsModalShow),
    selectedGroups: useSelector(selectors.getUserAccessGroupsList),
    userId: useSelector(userSelectors.getUserAccessGroupsModalUserId),
  };

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(useAccessGroupFormValidationSchema()),
    shouldUnregister: true,
  });

  const { isDirty, isValid } = methods.formState;

  useEffect(() => {
    methods.reset({
      group: data.selectedGroups,
      [hasFormItemKey]: false,
      name: '',
    });
  }, [data.selectedGroups]);

  useEffect(() => {
    if (data.open) {
      dispatch(actions.getAccessGroupsListAll());
      dispatch(actions.getUserAccessGroupsList(companyId, siteId, data.userId));
    }
  }, [data.open]);

  function handleAddGroups(form) {
    const newGroupName = form?.name;
    const currentGroups = [];
    form?.group?.forEach((item) => {
      if (item.id !== newItemId) {
        currentGroups.push(item.id);
      }
    });
    dispatch(actions.addUserAccessGroupsWithCreate(data.userId, newGroupName, currentGroups));

    onClose();
  }

  function onClose() {
    dispatch(userActions.accessGroupsModalClose());
  }

  return (
    !data.isLoadingUserGroups && (
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleAddGroups)}>
          <PrimaryModal
            actionButtonLabel={formatMessage(messages.userAccessGroupsModalAddButtonLabel)}
            isActionButtonDisabled={!isDirty || !isValid}
            isLoading={data.isLoading}
            name="addUser-access-groups-dialog"
            onClose={onClose}
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            onCreate={() => {}}
            open={data.open || data.isLoading}
            title={formatMessage(messages.userAccessGroupsModalTitle)}
          >
            <ModalText />
            <AccessGroupAutocomplete
              hasFormItemKey={hasFormItemKey}
              newItemId={newItemId}
              optionsData={[...data.groups]}
            />
          </PrimaryModal>
        </form>
      </FormProvider>
    )
  );
};

function ModalText() {
  return (
    <Typography component="span" variant="body1">
      <FormattedMessage {...messages.userAccessGroupsModalText} values={{ p: (chunks) => <p>{chunks}</p> }} />
    </Typography>
  );
}

export default UserAccessGroupsModal;
