import { defineMessages } from 'react-intl';

export default defineMessages({
  devicesSnackbarApprovedSuccessfully: {
    defaultMessage: 'Device was successfully approved.',
    description: 'Device snackbar - Device was successfully approved',
    id: 'devicesSnackbarApprovedSuccessfully',
  },
  devicesSnackbarButtonConfigurationUpdated: {
    defaultMessage: 'Button configuration was updated successfully.',
    description: 'Device snackbar - Button configuration was updated successfully',
    id: 'devicesSnackbarButtonConfigurationUpdated',
  },
  devicesSnackbarContactListUpdated: {
    defaultMessage: 'Contact list was successfully updated.',
    description: 'Device snackbar - Contact list was updated successfully',
    id: 'devicesSnackbarContactListUpdated',
  },
  devicesSnackbarDeletedSuccessfully: {
    defaultMessage: 'Device was successfully deleted.',
    description: 'Device snackbar - Device was successfully deleted',
    id: 'devicesSnackbarDeletedSuccessfully',
  },
  devicesSnackbarDeviceServiceSettingsRenewal: {
    defaultMessage: 'The request for renewal of the device service settings was sent.',
    description: 'Device snackbar - The request for renewal of the device service settings was sent',
    id: 'devicesSnackbarDeviceServiceSettingsRenewal',
  },
  devicesSnackbarFirmwareUpdateRequestSent: {
    defaultMessage: 'Device firmware update request was sent.',
    description: 'Device snackbar - Device firmware update request was sent',
    id: 'devicesSnackbarFirmwareUpdateRequestSent',
  },
  devicesSnackbarPhonebookChangedSuccessfully: {
    defaultMessage: 'Device phonebook was changed successfully.',
    description: 'Device snackbar - Device phonebook was changed successfully',
    id: 'devicesSnackbarPhonebookChangedSuccessfully',
  },
  devicesSnackbarRejected: {
    defaultMessage: 'Device was rejected.',
    description: 'Device snackbar - Device was rejected',
    id: 'devicesSnackbarRejected',
  },
  devicesSnackbarStatusChangedSuccessfully: {
    defaultMessage: 'Device status was successfully changed.',
    description: 'Device snackbar - Device status was changed successfully',
    id: 'devicesSnackbarStatusChangedSuccessfully',
  },
  devicesSnackbarUpdatedSuccessfully: {
    defaultMessage: 'Device was successfully updated.',
    description: 'Device snackbar - Device was successfully updated',
    id: 'devicesSnackbarUpdatedSuccessfully',
  },
  dtmfListSaveFailure: {
    defaultMessage: 'There was an error, please try again',
    description: 'Fallback message when FE validation is not strict as BE',
    id: 'dtmf.list.save.failure',
  },
});
