import types from './types';

const initialPaymentsList = {
  data: [],
  error: null,
  filter: { fulltext: '' },
  isFetching: false,
  order: '-dateCreated',
  page: 1,
  promoDetailModal: {
    data: {},
    detail: {},
    error: null,
    isFetching: false,
    show: false,
  },
  resultsFiltered: 0,
  resultsTotal: 0,
  rowsPerPage: 10,
};

export const initialState = {
  data: null,
  error: null,
  isFetching: false,
  list: initialPaymentsList,
};

const reducers = {
  [types.GET_PAYMENTS_REQUEST]: (state) => ({
    ...state,
    list: {
      ...state.list,
      isFetching: true,
    },
  }),

  [types.GET_PAYMENTS_SUCCESS]: (state, { payload, ...action }) => ({
    ...state,
    list: {
      ...state.list,
      data: payload.payments || [],
      error: null,
      filter: action.filter,
      isFetching: false,
      order: action.order,
      page: action.page,
      resultsFiltered: payload.resultsFiltered,
      resultsTotal: payload.resultsTotal,
      rowsPerPage: action.rowsPerPage,
    },
  }),

  [types.GET_PAYMENTS_FAILURE]: (state, { error }) => ({
    ...state,
    list: {
      ...initialPaymentsList,
      error,
    },
  }),

  [types.PROMO_DETAIL_SHOW_MODAL]: (state, { id, name, payment }) => ({
    ...state,
    list: {
      ...state.list,
      promoDetailModal: {
        ...state.list.promoDetailModal,
        detail: {
          id,
          name,
          payment,
        },
        show: true,
      },
    },
  }),

  [types.PROMO_DETAIL_HIDE_MODAL]: (state) => ({
    ...state,
    list: {
      ...state.list,
      promoDetailModal: {
        ...state.list.promoDetailModal,
        show: false,
      },
    },
  }),

  [types.GET_PAYMENT_DETAIL_REQUEST]: (state) => ({
    ...state,
    list: {
      ...state.list,
      promoDetailModal: {
        ...state.list.promoDetailModal,
        isFetching: true,
      },
    },
  }),

  [types.GET_PAYMENT_DETAIL_SUCCESS]: (state, { payload }) => ({
    ...state,
    list: {
      ...state.list,
      promoDetailModal: {
        ...state.list.promoDetailModal,
        data: payload,
        isFetching: false,
      },
    },
  }),

  [types.GET_PAYMENT_DETAIL_FAILURE]: (state, { error }) => ({
    ...state,
    list: {
      ...state.list,
      promoDetailModal: {
        ...state.list.promoDetailModal,
        error,
        isFetching: false,
      },
    },
  }),
};

const reducer = (state = initialState, action = {}) => {
  if (reducers[action.type]) {
    return reducers[action.type](state, action);
  }
  return state;
};

export default reducer;
