import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

export interface IAddButtonDeviceData {
  disabled: boolean;
  onAdd: () => void;
}

const AddButtonDevice = ({ disabled, onAdd }: IAddButtonDeviceData) => (
  <Button color="primary" disabled={disabled} onClick={onAdd} startIcon={<AddIcon />} type="button">
    <FormattedMessage {...messages.addButtonDevice} />
  </Button>
);

export default AddButtonDevice;
