import { Box } from '@mui/material';
import React from 'react';
import { DeviceTypeIcon } from '../../../../components';

export interface IOptionIconData {
  stringAsIcon?: string;
  deviceAsIcon?: IDeviceIcon;
  isNew: boolean;
}

export interface IDeviceIcon {
  deviceType: string;
  type: string;
}

const styles = {
  apartmentIcon: { fontSize: 16, fontWeight: 'bold', pl: 1 },
  deviceIcon: { pl: 1 },
  newApartment: { color: 'common.white', fontSize: 16, fontWeight: 'bold', pl: 1 },
  selectedApartment: { color: 'primary.main', fontSize: 16, fontWeight: 'bold', pl: 1 },
};

export function CallSettingOptionIcon(data: IOptionIconData): JSX.Element {
  if (data.deviceAsIcon !== undefined) {
    return (
      <Box sx={styles.deviceIcon}>
        <DeviceTypeIcon deviceType={data.deviceAsIcon.deviceType} small type={data.deviceAsIcon.type} />
      </Box>
    );
  }

  if (data.stringAsIcon !== undefined) {
    return (
      <Box
        sx={{
          ...styles.apartmentIcon,
          ...(data.isNew ? styles.newApartment : styles.selectedApartment),
        }}
      >
        {data.stringAsIcon}
      </Box>
    );
  }

  return <>{false}</>;
}
