import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormikFields, Link } from '../../../components';
import { PATHS } from '../../../config/consts';
import messages from '../messages';

const styles = {
  label: {
    '& span': {
      paddingTop: 0,
    },
    alignItems: 'flex-start',
    display: 'flex',
  },
  root: {
    mb: 3,
    pt: 0,
  },
};

const GTCAgree = ({ companyId, name }) => (
  <Grid item sx={styles.root}>
    <FormikFields.Checkbox
      id="recurringpayment-agree-checkbox"
      label={
        <Typography variant="body2">
          <FormattedMessage
            {...messages.agreeGTCLabel}
            values={{
              cloudLink: (chunks) => (
                <Link target="_blank" to={PATHS.CLOUD_AGREEMENT_WITH_COMPANY.replace(':companyId', companyId)}>
                  {chunks}
                </Link>
              ),
              consumerLink: (chunks) => (
                <Link
                  target="_blank"
                  to={PATHS.INFORMATION_FOR_CONSUMERS_WITH_COMPANY.replace(':companyId', companyId)}
                >
                  {chunks}
                </Link>
              ),
            }}
          />
        </Typography>
      }
      name={name}
      required
      sx={styles.label}
      validate={(value) => !value}
    />
  </Grid>
);

GTCAgree.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
};

export default GTCAgree;
