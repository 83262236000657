import NewReleasesRoundedIcon from '@mui/icons-material/NewReleasesRounded';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../../messages';
import WikipediaLink from './WikipediaLink';

const AccessUnit = () => (
  <Box
    display="flex"
    sx={{
      border: '1px solid',
      borderColor: 'success.main',
      borderRadius: 1,
      pl: 2,
      pr: {
        sm: 6,
        xs: 2,
      },
      py: 1,
      width: 'fit-content',
    }}
  >
    <NewReleasesRoundedIcon color="success" />
    <Typography color="success.main" sx={{ ml: 2 }}>
      <FormattedMessage
        {...messages.licenseSelectionAccessUnit}
        values={{ link: (chunks) => <WikipediaLink color="success.main">{chunks}</WikipediaLink> }}
      />
    </Typography>
  </Box>
);

export default AccessUnit;
