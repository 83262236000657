export const GOLD_LICENSE_PACK = [
  'LICENCE_ENHANCED_SECURITY',
  'LICENCE_ENHANCED_AUDIO',
  'LICENCE_ENHANCED_VIDEO',
  'LICENCE_ENHANCED_INTEGRATION',
  'LICENCE_NFC',
];

export const GOLD_LICENSE = 'LICENCE_GOLD';

export const GP_WEBPAY = 'GP_WEBPAY';
export const INVOICE = 'INVOICE';

export const MOBILE_VIDEO_POOL_CREDIT = 'MOBILE_VIDEO_POOL_CREDIT';
export const PROMO = 'PROMO';

export const INTERCOM_LICENCES = {
  LICENCE_ACCESS_UNIT_LIFT_MODULE: 'LICENCE_ACCESS_UNIT_LIFT_MODULE',
  LICENCE_ENHANCED_AUDIO: 'LICENCE_ENHANCED_AUDIO',
  LICENCE_ENHANCED_INTEGRATION: 'LICENCE_ENHANCED_INTEGRATION',
  LICENCE_ENHANCED_SECURITY: 'LICENCE_ENHANCED_SECURITY',
  LICENCE_ENHANCED_VIDEO: 'LICENCE_ENHANCED_VIDEO',
  LICENCE_G729: 'LICENCE_G729',
  LICENCE_GOLD: 'LICENCE_GOLD',
  LICENCE_INFORMCAST: 'LICENCE_INFORMCAST',
  LICENCE_INTERCOM_LIFT_MODULE: 'LICENCE_INTERCOM_LIFT_MODULE',
  LICENCE_NFC: 'LICENCE_NFC',
  LICENCE_NFC_ACCESS_UNIT: 'LICENCE_NFC_ACCESS_UNIT',
};
