import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from '../../../../components';
import messages from '../../messages';
import LicenseInfoNew from './LicenseInfoNew';
import messagesOneStep from './messages';

const CapableLicenseModal = ({ isOpen, onClose }) => (
  <Modal
    actions={
      <Button color="primary" onClick={onClose}>
        <FormattedMessage {...messages.availableLicenseButton} />
      </Button>
    }
    fullWidth
    maxWidth="xs"
    name="capable-license-modal"
    onClose={onClose}
    open={isOpen}
    title={<FormattedMessage {...messagesOneStep.licenseInfoNewTitle} />}
  >
    <LicenseInfoNew />
  </Modal>
);

CapableLicenseModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default CapableLicenseModal;
