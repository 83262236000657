import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';
import React from 'react';
import ButtonLink from '../ButtonLink';

function ChipLink({ label, link, ...other }) {
  return (
    <Chip
      {...other}
      color="primary"
      component={ButtonLink}
      label={label}
      sx={{
        '&:hover': {
          cursor: 'pointer',
        },
        '&:last-of-type': {
          mr: 2,
        },
        mb: 0.5,
        mr: 1,
        textTransform: 'none',
      }}
      to={link}
      variant="outlined"
    />
  );
}

ChipLink.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default ChipLink;
