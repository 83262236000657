import { AxiosResponse } from 'axios';
import { SERVICE } from '../config/devices';
import {
  IAggregatedApartmentUsers,
  IApartmentWithAccessGroups,
  ICreateApartmentUser,
} from '../modules/apartmentAdmin/data';
import { IPin, TId, TUuid } from '../modules/commonTypes';
import { IDevice, IMobileVideoService } from '../modules/devices/store/deviceStore';
import { DeviceTypeEnum } from '../modules/devices/store/storeTypes';
import { IGetApartmentUsersData } from './apartmentApiTS';
import http, { encode } from './index';
import { deleteSiteData, getSiteData, ISiteGenericRequestData, postSiteData, putSiteData } from './siteRequest';
import { IRfid, IUWLUserAssignedPin } from './userApiTS';

export enum AARequestType {
  DELETE = 'delete',
  GET = 'get',
  HEAD = 'head',
  POST = 'post',
  PUT = 'put',
}

export const getAvailablePin = async (
  siteData: ISiteGenericRequestData,
  apartmentId: TId
): Promise<AxiosResponse<{ pin: string }>> => {
  return getSiteData(siteData, `apartments/${encode(apartmentId.toString())}/credentials/available-pin`);
};

export const getDevicesWithinApartment = async (
  siteData: ISiteGenericRequestData,
  apartmentId: TId,
  userId: TId
): Promise<AxiosResponse<{ results: IDevice[] }>> => {
  return getSiteData(
    siteData,
    `apartments/${encode(apartmentId.toString())}/users/${encode(userId.toString())}/devices`
  );
};

export const createDeviceWithinApartment = async (
  siteData: ISiteGenericRequestData,
  apartmentId: TId,
  values: { type: DeviceTypeEnum; userId: TId }
): Promise<AxiosResponse<IDevice>> => {
  return postSiteData(siteData, `apartments/${encode(apartmentId.toString())}/devices`, values);
};

export const deleteDeviceWithinApartment = async (
  siteData: ISiteGenericRequestData,
  apartmentId: TId,
  deviceId: TId
): Promise<AxiosResponse> => {
  return deleteSiteData(
    siteData,
    `apartments/${encode(apartmentId.toString())}/devices/${encode(deviceId.toString())}`
  );
};

export const changeDeviceMVStatus = async (
  siteData: ISiteGenericRequestData,
  apartmentId: TId,
  deviceId: TId,
  values: IMobileVideoService
): Promise<AxiosResponse<IDevice>> => {
  return putSiteData(
    siteData,
    `apartments/${encode(apartmentId.toString())}/devices/${encode(deviceId.toString())}/services/${
      SERVICE.MOBILE_VIDEO
    }`,
    values
  );
};

export const createUWLUserAssignPin = async (
  siteData: ISiteGenericRequestData,
  userAssignedPin: IUWLUserAssignedPin,
  apartmentId: number
): Promise<AxiosResponse> => {
  return setAARequest(
    AARequestType.POST,
    `${getSiteUrl(siteData)}/apartments/${encode(apartmentId.toString())}/create-user-with-pin`,
    userAssignedPin
  );
};

export const getUsersInApartments = async (): Promise<AxiosResponse<IAggregatedApartmentUsers[]>> =>
  setAARequest(AARequestType.GET, 'users-in-apartments');

export const createApartmentUser = async (
  generalInfo: { apartmentId: TId; companyId: TId; siteId: TId },
  values: ICreateApartmentUser
): Promise<AxiosResponse<IGetApartmentUsersData>> =>
  setAARequest(
    AARequestType.POST,
    `${getSiteUrl({ companyId: generalInfo.companyId, siteId: generalInfo.siteId })}/apartments/${encode(
      generalInfo.apartmentId.toString()
    )}/users`,
    values
  );

export const getApartmentAccessGroups = async (
  siteData: ISiteGenericRequestData,
  apartmentId: TId,
  userId: TId
): Promise<AxiosResponse<IApartmentWithAccessGroups[]>> => {
  return getSiteData(
    siteData,
    `apartments/${encode(apartmentId.toString())}/users/${encode(userId.toString())}/apartments`
  );
};

export const getUserAccessGroups = async (
  siteData: ISiteGenericRequestData,
  apartmentId: TId,
  userId: TId
): Promise<AxiosResponse<IApartmentWithAccessGroups[]>> => {
  return getSiteData(
    siteData,
    `apartments/${encode(apartmentId.toString())}/users/${encode(userId.toString())}/groups`
  );
};

export const getUserPin = async (
  siteData: ISiteGenericRequestData,
  apartmentId: TId,
  userId: TId
): Promise<AxiosResponse<IPin>> => {
  return getSiteData(
    siteData,
    `apartments/${encode(apartmentId.toString())}/users/${encode(userId.toString())}/credentials/pin`
  );
};

export const generateUserPin = async (
  siteData: ISiteGenericRequestData,
  apartmentId: TId,
  userId: TId
): Promise<AxiosResponse<IPin>> => {
  return postSiteData(
    siteData,
    `apartments/${encode(apartmentId.toString())}/users/${encode(userId.toString())}/credentials/pin`,
    undefined
  );
};

export const activateUserPin = async (
  siteData: ISiteGenericRequestData,
  apartmentId: TId,
  userId: TId,
  pinId: TUuid
): Promise<AxiosResponse<IPin>> => {
  return putSiteData(
    siteData,
    `apartments/${encode(apartmentId.toString())}/users/${encode(userId.toString())}/credentials/pin/${encode(
      pinId
    )}/activate`,
    undefined
  );
};

export const deleteUserPin = async (
  siteData: ISiteGenericRequestData,
  apartmentId: TId,
  userId: TId
): Promise<AxiosResponse<void>> => {
  return deleteSiteData(
    siteData,
    `apartments/${encode(apartmentId.toString())}/users/${encode(userId.toString())}/credentials/pin`
  );
};

export const getUserRfid = async (
  siteData: ISiteGenericRequestData,
  apartmentId: TId,
  userId: TId
): Promise<AxiosResponse<IRfid[]>> => {
  return getSiteData(
    siteData,
    `apartments/${encode(apartmentId.toString())}/users/${encode(userId.toString())}/credentials/rfid`
  );
};

export const deleteUserRfid = async (
  siteData: ISiteGenericRequestData,
  apartmentId: TId,
  userId: TId
): Promise<AxiosResponse<void>> => {
  return deleteSiteData(
    siteData,
    `apartments/${encode(apartmentId.toString())}/users/${encode(userId.toString())}/credentials/rfid`
  );
};

export async function setAARequest<TResponse, TRequest>(
  type: AARequestType,
  url: string,
  data?: TRequest
): Promise<TResponse> {
  const baseUrl = 'fe/apartment-admin/';

  switch (type) {
    case AARequestType.DELETE:
      return http.delete(`${baseUrl}${url}`);
    case AARequestType.GET:
      return data ? http.get(`${baseUrl}${url}`, { data }) : http.get(`${baseUrl}${url}`);
    case AARequestType.HEAD:
      return http.head(`${baseUrl}${url}`, { headers: { Accept: '*/*' }, params: data });
    case AARequestType.POST:
      return data ? http.post(`${baseUrl}${url}`, data) : http.post(`${baseUrl}${url}`);
    case AARequestType.PUT:
      return data ? http.put(`${baseUrl}${url}`, data) : http.put(`${baseUrl}${url}`);
  }
}

function getSiteUrl({ companyId, siteId }: ISiteGenericRequestData): string {
  return `companies/${encode(companyId.toString())}/sites/${encode(siteId.toString())}`;
}
