import axios from 'axios';
import { Dispatch } from 'redux';
import { setOryLoginStatus } from '../../../modules/auth/actions';
import { OryApiRequestCancelTokenId } from '../../config/const';
import { OryCancelled, OryLoginStatus, OrySuccess } from '../../types';
import { IUseLoginApi } from '../oryApi';
import { IUseOryUserLoggedProps } from './data';

export function checkOrySessionIfNeeded({
  accessToken,
  currentUser,
  dispatch,
  getOryCurrentUserSession,
  oryLoginStatus,
  setIsOrySessionCheckResolved,
}: {
  getOryCurrentUserSession: IUseLoginApi['getOryCurrentUserSession'];
  dispatch: Dispatch;
  setIsOrySessionCheckResolved: React.Dispatch<React.SetStateAction<boolean>>;
  accessToken: IUseOryUserLoggedProps['accessToken'];
  currentUser: IUseOryUserLoggedProps['currentUser'];
  oryLoginStatus: OryLoginStatus | undefined;
}) {
  const cancelTokenSource = axios.CancelToken.source();
  const tryToGetOrySession = async () => {
    const result = await getOryCurrentUserSession({ cancelToken: cancelTokenSource.token });
    if (result === OrySuccess) {
      dispatch(setOryLoginStatus(OryLoginStatus.SessionConfirmed));
    }
    if (result !== OryCancelled) {
      setIsOrySessionCheckResolved(true);
    }
  };

  if (
    isOryUserPotentiallyAlreadyLoggedIn(accessToken, currentUser) &&
    oryLoginStatus !== OryLoginStatus.SessionConfirmed
  ) {
    tryToGetOrySession();
  } else {
    setIsOrySessionCheckResolved(true);
  }

  return () => {
    cancelTokenSource.cancel(OryApiRequestCancelTokenId);
  };
}

export function isOryUserPotentiallyAlreadyLoggedIn(
  accessToken: IUseOryUserLoggedProps['accessToken'],
  currentUser: IUseOryUserLoggedProps['currentUser']
): boolean {
  return !!(!accessToken && currentUser && currentUser.id);
}
