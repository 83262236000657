import * as yup from 'yup';
import { headSiteData } from '../api/siteRequest';
import messages from '../components/AccessGroupAddUsersModal/messages';
import { FORM, ROLES } from '../config/consts';
import { getBasicEmailValidation, getFirstNameValidation, getLastNameValidation } from './user/userCommonValidations';

export function getValidationSchema(companyId, siteId, formatMessage) {
  return yup.object().shape({
    email: yup.string().when([FORM.HAS_FORM, 'users'], {
      is: (hasForm, users) => hasForm && users?.some((u) => u.id === FORM.NEW_ITEM_ID),
      then: (_schema) =>
        getBasicEmailValidation(
          formatMessage(messages.validationRequired),
          formatMessage(messages.validationEmailValid)
        ).test({
          message: formatMessage(messages.validationEmailExistUser),
          name: 'isExistEmail',
          test: async (email) => {
            let state = true;
            await headSiteData({ companyId, siteId }, 'users', { email, roles: ROLES.USER }, false).then((response) => {
              state = response.status === 404;
            });

            return state;
          },
        }),
    }),
    firstName: yup
      .string()
      .when([FORM.HAS_FORM, 'users'], {
        is: (hasForm, users) => hasForm && users?.some((u) => u.id === FORM.NEW_ITEM_ID),
        then: (_schema) => getFirstNameValidation(true, formatMessage),
      })
      .when([FORM.HAS_FORM, 'users'], {
        is: (hasForm, users) => hasForm && users?.some((u) => u.id === FORM.NEW_ITEM_WITHOUT_LOGIN_ID),
        then: (_schema) => getFirstNameValidation(false, formatMessage),
      }),
    hasForm: yup.boolean(),
    lastName: yup.string().when(FORM.HAS_FORM, {
      is: true,
      then: (_schema) => getLastNameValidation(formatMessage),
    }),
    users: yup.array().of(
      yup.object().shape({
        id: yup.string().required(),
        label: yup.string().required(),
        uuid: yup.string().optional(),
      })
    ),
  });
}
