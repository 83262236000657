import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';

export interface IItemProps {
  icon: React.JSX.Element,
  label: React.JSX.Element,
  value: React.JSX.Element | string,
}

export function Item({ icon, label, value }: IItemProps) {
  return (
    <Stack spacing={0.1}>
      <Typography color="secondary" variant="subtitle2">
        {label}
      </Typography>
      <Stack direction="row" spacing={2}>
        {icon}
        <Typography>
          {value}
        </Typography>
      </Stack>
    </Stack>
  )
}
