import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { Loader } from '../../components';
import { PATHS } from '../../config/consts';
import actions, { selectors } from '../../modules/common';
import { getTitleContent } from '../GTCPageDetail/helper';
import Content from './Content';
import DocumentDateInfo from './DocumentDateInfo';
import { getAgreeDocument, getHtmlContent, getSidebarData } from './helper';
import SidebarMenu from './SidebarMenu';

const navBarHeight = 64; // @TODO use navBarHeight from mui5 theme

const styles = {
  content: (theme) => ({
    [theme.breakpoints.up('lg')]: {
      width: 'calc(100% - 325px)',
    },
    color: 'grey.700',
  }),
  sidebar: {
    background: 'common.white',
    borderLeft: '1px solid',
    borderLeftColor: 'divider',
    color: 'grey.700',
    height: `calc(100% - ${navBarHeight}px)`,
    m: 0,
    overflowY: 'auto',
    position: 'fixed',
    px: 3,
    py: 0,
    right: 0,
    top: navBarHeight,
    width: 325,
  },
  title: {
    color: 'common.black',
    m: 0,
    mb: 2,
  },
};

const GTCPageDetailCompany = ({ agreements, data, isFetching, notFound, onLoadGTCAgreements, onLoadGTCDetail }) => {
  const { companyId, slug, version = null } = useParams();
  const fullPath = encodeURI((version ? `${slug}/${version}` : slug).trim());

  useEffect(() => {
    if (!notFound) {
      onLoadGTCDetail(fullPath);
    }
  }, [fullPath]);

  useEffect(() => {
    if (!notFound) {
      onLoadGTCAgreements(companyId);
    }
  }, [companyId]);

  if (notFound && !isFetching && !data?.html) {
    return <Navigate replace to={PATHS.GTC_PAGES_COMPANY_CONTEXT.replace(':companyId', companyId)} />;
  }

  const html = data?.html ? getHtmlContent(data.html, companyId) : '';
  const sidebarData = getSidebarData(html);

  return (
    <>
      {sidebarData.length > 0 && (
        <Box display={{ lg: 'block', xs: 'none' }}>
          <Box sx={styles.sidebar}>
            <SidebarMenu data={sidebarData} />
          </Box>
        </Box>
      )}
      <Container maxWidth="lg">
        <Box sx={styles.content}>
          {isFetching ? (
            <Loader />
          ) : (
            <>
              <Box component="h1" dangerouslySetInnerHTML={{ __html: getTitleContent(data?.name) }} sx={styles.title} />
              {data && (
                <DocumentDateInfo
                  agreeDocument={getAgreeDocument(agreements, data?.id)}
                  effective={data?.dateEffective}
                  posted={data?.dateCreated}
                />
              )}
              <Content html={html} />
            </>
          )}
        </Box>
      </Container>
    </>
  );
};

GTCPageDetailCompany.propTypes = {
  agreements: PropTypes.arrayOf(
    PropTypes.shape({
      authorId: PropTypes.number,
      companyId: PropTypes.number,
      dateCreated: PropTypes.string,
      id: PropTypes.number,
      termsId: PropTypes.number,
    })
  ),
  data: PropTypes.shape({
    confirmationType: PropTypes.string,
    dateCreated: PropTypes.string,
    dateEffective: PropTypes.string,
    html: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    nextId: PropTypes.number,
    parentId: PropTypes.number,
    showType: PropTypes.string,
  }),
  isFetching: PropTypes.bool.isRequired,
  notFound: PropTypes.bool.isRequired,
  onLoadGTCAgreements: PropTypes.func.isRequired,
  onLoadGTCDetail: PropTypes.func.isRequired,
};

GTCPageDetailCompany.defaultProps = {
  agreements: [],
  data: null,
};

const mapStateToProps = (state) => ({
  agreements: selectors.getGTCAgreements(state),
  data: selectors.getGTCDetail(state),
  isFetching: selectors.isFetchingGTCDetail(state),
  notFound: selectors.isNotFoundGTCDetail(state),
});

const mapDispatchToProps = {
  onLoadGTCAgreements: actions.loadGTCAgreements,
  onLoadGTCDetail: actions.loadGTCDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(GTCPageDetailCompany);
