import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { CallSettingsDataManager } from '../../../../../modules/devices/helpers/callSettings/callSettingsDataManager';
import { didInvalidDeviceDetail } from '../../../../../modules/devices/selectors';
import { isUnlicensedDevicesWarning } from '../../helpers';
import { transformSelectedOptions } from '../helpers/renderHelpers';
import { IButtonRenderData, IStateData } from './useGetStateData';

export interface IMemoizedData {
  isUnlicensedWarning: boolean;
  callSettingsDataManager: CallSettingsDataManager | undefined;
  buttonsRenderData: IButtonRenderData[];
}

export function useMemoizedData(stateData: IStateData, currentButtonData: IButtonRenderData[]): IMemoizedData {
  const isUnlicensedWarning = useMemo(() => isUnlicensedDevicesWarning(currentButtonData), [currentButtonData]);
  const didInvalid = useSelector(didInvalidDeviceDetail);

  const callSettingsDataManager = useMemo(() => {
    if (stateData.isFetching) {
      return undefined;
    }
    return new CallSettingsDataManager(
      stateData.devicesChipsOptions,
      stateData.apartmentsChipsOptions,
      stateData.device
    );
  }, [stateData.isFetching, stateData.buttonList, didInvalid]);

  const buttonsRenderData: IButtonRenderData[] = useMemo(() => {
    const updatedButtonsRenderData: IButtonRenderData[] = [];
    stateData.buttonList.forEach((buttonData) => {
      updatedButtonsRenderData.push({
        buttonName: buttonData.name,
        externalId: buttonData.button?.externalId,
        index: buttonData.index,
        ringingGroup: buttonData.ringingGroup,
        selectedOptions: transformSelectedOptions(buttonData.selectedOptions),
      });
    });
    return updatedButtonsRenderData;
  }, [stateData.buttonList]);

  return {
    buttonsRenderData,
    callSettingsDataManager,
    isUnlicensedWarning,
  };
}
