import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import {
  My2NAppAccessModal as My2NAppAccessModalComponent,
  UserPinModalNew as UserPinModalNewComponent,
  UserRfidModalNew as UserRfidModalNewComponent,
} from '../../../../components';
import RowMenu from '../../../../components/DataGrid/RowMenu';
import { useHasFeature } from '../../../../components/Feature/Feature';
import SetMy2NAppAccessMenuItem from '../../../../components/SetMy2NAppAccessMenuItem';
import { FE_ROLES } from '../../../../config/consts';
import featureFlags from '../../../../config/featureFlags';
import { useModal } from '../../../../hooks/useModal';
import * as siteSelectors from '../../../../modules/sites/selectors';
import { isUserGuest } from '../../../../modules/user/utils';
import AssignCard from './AssignCard';
import EditUser from './EditUser';
import GeneratePin from './GeneratePin';
import RemoveUser from './RemoveUser';
import SetAccessGroups from './SetAccessGroups';

const DataGridRowMenu = ({ companyId, isPinSet, isRfidSet, onRemove, onSetAccessGroup, siteId, user }) => {
  const isUserBleCredentialFFEnable = useHasFeature(featureFlags.USER_BLE_CREDENTIAL);
  const { Modal: My2NAppAccessModal, onOpen: onOpenActiveteSetMy2NAppAccess } = useModal({
    Modal: My2NAppAccessModalComponent,
  });
  const { Modal: UserPinModalNew, onOpen: onOpenUserPinModal } = useModal({
    Modal: UserPinModalNewComponent,
  });
  const { Modal: UserRfidModalNew, onOpen: onOpenUserRfidModal } = useModal({
    Modal: UserRfidModalNewComponent,
  });
  const siteData = useSelector(siteSelectors.getSiteData);
  const isSiteBluetoothEnabled = siteData?.services?.ACCESS_CONTROL?.bluetoothEnabled;

  const handleMenuClick = (fn) => {
    if (fn) {
      fn();
    }
  };

  return (
    <>
      <RowMenu
        menuItems={
          <div>
            <SetAccessGroups onClick={() => handleMenuClick(onSetAccessGroup)} />
            <RemoveUser onClick={() => handleMenuClick(onRemove)} />
            <Divider />
            <GeneratePin isPinSet={isPinSet} onClick={onOpenUserPinModal} />
            {!isUserGuest(user.role) && <AssignCard isRfidSet={isRfidSet} onClick={onOpenUserRfidModal} />}
            {isUserBleCredentialFFEnable && !isUserGuest(user.role) && (
              <SetMy2NAppAccessMenuItem
                disabled={!isSiteBluetoothEnabled || user.role === FE_ROLES.LOGIN_LESS}
                isBleActive={user.credentials.includes('BT')}
                onClick={onOpenActiveteSetMy2NAppAccess}
              />
            )}
            <Divider />
            <EditUser companyId={companyId} siteId={siteId} userId={user.id} />
          </div>
        }
      />
      {isUserBleCredentialFFEnable && (
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        <My2NAppAccessModal context="ACCESS_SETTINGS_USERS_LIST" onActivate={() => {}} user={user} />
      )}
      <UserPinModalNew context="ACCESS_SETTINGS_USERS_LIST" user={user} />
      <UserRfidModalNew context="ACCESS_SETTINGS_USERS_LIST" user={user} />
    </>
  );
};

DataGridRowMenu.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isPinSet: PropTypes.bool.isRequired,
  isRfidSet: PropTypes.bool.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSetAccessGroup: PropTypes.func.isRequired,
  siteId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  user: PropTypes.shape({
    credentials: PropTypes.array,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    role: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  }).isRequired,
};

export default DataGridRowMenu;
