import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { Resolver, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import useBreakpoints from '../../../../../helpers/useBreakpoints';
import { ApartmentUser } from '../../../../../modules/apartmentAdmin/data';
import { TId } from '../../../../../modules/commonTypes';
import { IUserBase } from '../../../../../modules/user/store';
import { getApartmentUserValidationSchema } from '../../../../../validations/createApartmentUserValidationSchema';
export interface IActivateMVForUWLFormData extends Omit<IUserBase, 'id'> {
  mobileVideoActivated?: boolean;
}

export function useActivateMVForUWL(apartmentId: TId, companyId: TId, siteId: TId, user: ApartmentUser) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { upSm } = useBreakpoints();
  const { formatMessage } = useIntl();

  const formMethods = useForm<IActivateMVForUWLFormData>({
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
    },
    mode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        ...getApartmentUserValidationSchema(apartmentId, companyId, siteId, formatMessage),
      })
    ) as unknown as Resolver<IActivateMVForUWLFormData>,
  });

  useEffect(() => {
    formMethods.reset({
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
    });
  }, [user]);

  return {
    dispatch,
    formatMessage,
    formMethods,
    navigate,
    upSm,
  };
}
