import { defineMessages } from 'react-intl';

export default defineMessages({
  companyAdminDataGridColumnsAccess: {
    defaultMessage: 'Access',
    description: 'Users Table - access column',
    id: 'companyAdminDataGrid.columns.access',
  },
  companyAdminDataGridColumnsEmail: {
    defaultMessage: 'E-mail',
    description: 'Users Table - email column',
    id: 'companyAdminDataGrid.columns.email',
  },
  companyAdminDataGridColumnsName: {
    defaultMessage: 'Name',
    description: 'Users Table - name column',
    id: 'companyAdminDataGrid.columns.name',
  },
  companyAdminDataGridColumnsRole: {
    defaultMessage: 'User role',
    description: 'Users Table - role column',
    id: 'companyAdminDataGrid.columns.role',
  },
  companyAdminDataGridEmailConfirmed: {
    defaultMessage: 'E-mail confirmed',
    description: 'Users Table - email confirmed',
    id: 'companyAdminDataGrid.email.confirmed',
  },
  companyAdminDataGridEmailUnconfirmed: {
    defaultMessage: 'E-mail unconfirmed',
    description: 'Users Table - email unconfirmed',
    id: 'companyAdminDataGrid.email.unconfirmed',
  },
  companyAdminDataGridRoleCompanyAdmin: {
    defaultMessage: 'Company admin',
    description: 'Users Table - company admin role label',
    id: 'companyAdminDataGrid.role.companyAdmin',
  },
  userRowMenuDelete: {
    defaultMessage: 'Delete user',
    description: 'Users Table - delete user',
    id: 'userRowMenu.delete',
  },
  userRowMenuViewProfile: {
    defaultMessage: 'View profile',
    description: 'Users Table - view profile',
    id: 'userRowMenu.viewProfile',
  },
});
