import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { selectors } from '../../modules/common';
import SideBarNavigation from './SideBarNavigation';
import { styles } from './styles/Sidebar';

const Sidebar = ({ isServiceAlertOpen, isVersionWarningDisplayed, serviceAlertHeight }) => (
  <Box
    sx={{
      ...(isVersionWarningDisplayed && styles.withVersionWarning),
    }}
  >
    <Drawer variant="permanent">
      <Box
        sx={{
          ...(isServiceAlertOpen && serviceAlertHeight !== null && styles.toolbarServiceAlert(serviceAlertHeight)),
        }}
      />
      <SideBarNavigation />
    </Drawer>
  </Box>
);

Sidebar.propTypes = {
  isServiceAlertOpen: PropTypes.bool.isRequired,
  isVersionWarningDisplayed: PropTypes.bool,
  serviceAlertHeight: PropTypes.number,
};

Sidebar.defaultProps = {
  isVersionWarningDisplayed: false,
  serviceAlertHeight: null,
};

const mapStateToProps = (state) => ({
  isServiceAlertOpen: selectors.isServiceAlertOpen(state),
  serviceAlertHeight: selectors.getServiceAlertHeight(state),
});

export default connect(mapStateToProps)(Sidebar);
