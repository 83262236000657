import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import ApartmentFreeTooltip from './ApartmentFreeTooltip';
import { Icon } from './Icon';

export interface IApartmentProps {
  children: React.JSX.Element;
  count: number;
  freeLicence?: number;
  isError?: boolean;
  isLastChild?: boolean;
  tooltip?: boolean;
}

function Apartment({ children, count, freeLicence, isError, isLastChild, tooltip }: IApartmentProps) {
  return (
    <Box
      alignItems="center"
      display="flex"
      mb={
        !isLastChild
          ? {
              sm: 1.5,
              xs: 0.5,
            }
          : undefined
      }
    >
      <Box alignItems="center" display="flex" sx={{ mr: 1 }}>
        <Icon count={count} isError={isError} />
      </Box>
      {tooltip ? (
        <ApartmentFreeTooltip count={count} freeLicence={freeLicence}>
          {children}
        </ApartmentFreeTooltip>
      ) : (
        <Typography color={count > 0 ? 'text.primary' : 'text.disabled'} component="div" noWrap variant="body2">
          {children}
        </Typography>
      )}
    </Box>
  );
}

export default Apartment;
