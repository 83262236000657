import { all, call, put, SagaReturnType, takeLatest } from 'redux-saga/effects';
import * as ApartmentAdminApi from '../../api/apartmentAdminApi';
import { IUWLUserAssignedPin } from '../../api/userApiTS';
import { TId, TUuid } from '../commonTypes';
import { IMobileVideoService } from '../devices/store/deviceStore';
import { DeviceTypeEnum } from '../devices/store/storeTypes';
import { errorHelper, responseValidateStatusOrThrow } from '../helpers';
import { TYPE_DESCRIPTOR } from '../notifications';
import { addSnackbarError, addSnackbarSuccess } from '../notifications/actions';
import { IError } from '../reduxTypes';
import { isStandardError, processError } from '../sagaHelpers';
import { ICurrentUser } from '../user/store';
import { ICreateApartmentUser } from './data';
import messages from './messages';
import { types } from './types';

export function* getAvailablePin(action: { type: string; companyId: TId; siteId: TId; apartmentId: TId }) {
  try {
    const { apartmentId, companyId, siteId } = action;
    const response: SagaReturnType<typeof ApartmentAdminApi.getAvailablePin> = yield call(
      ApartmentAdminApi.getAvailablePin,
      { companyId, siteId },
      apartmentId
    );
    yield responseValidateStatusOrThrow(response);
    yield put({ payload: response.data, type: types.GET_AVAILABLE_PIN_SUCCESS });
  } catch (error) {
    yield put({ error, type: types.GET_AVAILABLE_PIN_FAILURE });
    yield put(addSnackbarError(messages.AASnackbarPinGeneratedFailed, TYPE_DESCRIPTOR));
  }
}

export function* getApartmentAccessGroups(action: {
  type: string;
  companyId: TId;
  siteId: TId;
  apartmentId: TId;
  userId: TId;
}) {
  try {
    const { apartmentId, companyId, siteId, userId } = action;
    const response: SagaReturnType<typeof ApartmentAdminApi.getApartmentAccessGroups> = yield call(
      ApartmentAdminApi.getApartmentAccessGroups,
      { companyId, siteId },
      apartmentId,
      userId
    );
    yield responseValidateStatusOrThrow(response);
    yield put({ payload: response.data, type: types.GET_APARTMENT_GROUPS_SUCCESS });
  } catch (error) {
    yield put({ error, type: types.GET_APARTMENT_GROUPS_FAILURE });
  }
}

export function* getUserAccessGroups(action: {
  type: string;
  companyId: TId;
  siteId: TId;
  apartmentId: TId;
  userId: TId;
}) {
  try {
    const { apartmentId, companyId, siteId, userId } = action;
    const response: SagaReturnType<typeof ApartmentAdminApi.getUserAccessGroups> = yield call(
      ApartmentAdminApi.getUserAccessGroups,
      { companyId, siteId },
      apartmentId,
      userId
    );
    yield responseValidateStatusOrThrow(response);
    yield put({ payload: response.data, type: types.GET_USER_GROUPS_SUCCESS });
  } catch (error) {
    yield put({ error, type: types.GET_USER_GROUPS_FAILURE });
  }
}

export function* getUserPin(action: { type: string; companyId: TId; siteId: TId; apartmentId: TId; userId: TId }) {
  try {
    const { apartmentId, companyId, siteId, userId } = action;
    const response: SagaReturnType<typeof ApartmentAdminApi.getUserPin> = yield call(
      ApartmentAdminApi.getUserPin,
      { companyId, siteId },
      apartmentId,
      userId
    );
    yield responseValidateStatusOrThrow(response);
    yield put({ payload: response.data, type: types.GET_USER_PIN_SUCCESS });
  } catch (error) {
    yield put({ error, type: types.GET_USER_PIN_FAILURE });
  }
}

export function* generateUserPin(action: { type: string; companyId: TId; siteId: TId; apartmentId: TId; userId: TId }) {
  try {
    const { apartmentId, companyId, siteId, userId } = action;
    const response: SagaReturnType<typeof ApartmentAdminApi.generateUserPin> = yield call(
      ApartmentAdminApi.generateUserPin,
      { companyId, siteId },
      apartmentId,
      userId
    );
    yield responseValidateStatusOrThrow(response);
    yield put({ payload: response.data, type: types.GENERATE_USER_PIN_SUCCESS });
  } catch (error) {
    yield put({ error, type: types.GET_USER_PIN_FAILURE });
    yield put(addSnackbarError(messages.AAmodalGeneratePINMsgErr, TYPE_DESCRIPTOR));
  }
}

export function* activateUserPin(action: {
  type: string;
  companyId: TId;
  siteId: TId;
  apartmentId: TId;
  userId: TId;
  pinId: TUuid;
  onSuccess: () => void;
}) {
  try {
    const { apartmentId, companyId, pinId, siteId, userId } = action;
    const response: SagaReturnType<typeof ApartmentAdminApi.activateUserPin> = yield call(
      ApartmentAdminApi.activateUserPin,
      { companyId, siteId },
      apartmentId,
      userId,
      pinId
    );
    yield responseValidateStatusOrThrow(response);
    yield put({ payload: response.data, type: types.ACTIVATE_USER_PIN_SUCCESS });
    yield put(addSnackbarSuccess(messages.AAmodalGeneratePINMsgSucc));
    yield action.onSuccess();
  } catch (error) {
    yield put({ error, type: types.ACTIVATE_USER_PIN_FAILURE });
    yield put(addSnackbarError(messages.AAmodalGeneratePINMsgErr, TYPE_DESCRIPTOR));
  }
}

export function* deleteUserPin(action: {
  type: string;
  companyId: TId;
  siteId: TId;
  apartmentId: TId;
  userId: TId;
  onSuccess: () => void;
}) {
  try {
    const { apartmentId, companyId, siteId, userId } = action;
    const response: SagaReturnType<typeof ApartmentAdminApi.deleteUserPin> = yield call(
      ApartmentAdminApi.deleteUserPin,
      { companyId, siteId },
      apartmentId,
      userId
    );
    yield responseValidateStatusOrThrow(response);
    yield put({ payload: response.data, type: types.DELETE_USER_PIN_SUCCESS });
    yield action.onSuccess();
    yield put(addSnackbarSuccess(messages.AAmodalDeletePINMsgSucc));
  } catch (error) {
    yield put({ error, type: types.DELETE_USER_PIN_FAILURE });
    yield put(addSnackbarError(messages.AAmodalDeletePINMsgErr, TYPE_DESCRIPTOR));
  }
}

export function* getUserRfid(action: { type: string; companyId: TId; siteId: TId; apartmentId: TId; userId: TId }) {
  try {
    const { apartmentId, companyId, siteId, userId } = action;
    const response: SagaReturnType<typeof ApartmentAdminApi.getUserRfid> = yield call(
      ApartmentAdminApi.getUserRfid,
      { companyId, siteId },
      apartmentId,
      userId
    );
    yield responseValidateStatusOrThrow(response);
    yield put({ payload: response.data, type: types.GET_USER_RFID_SUCCESS });
  } catch (error) {
    yield put({ error, type: types.GET_USER_RFID_FAILURE });
  }
}

export function* deleteUserRfid(action: {
  type: string;
  companyId: TId;
  siteId: TId;
  apartmentId: TId;
  userId: TId;
  onSuccess: () => void;
}) {
  try {
    const { apartmentId, companyId, siteId, userId } = action;
    const response: SagaReturnType<typeof ApartmentAdminApi.deleteUserRfid> = yield call(
      ApartmentAdminApi.deleteUserRfid,
      { companyId, siteId },
      apartmentId,
      userId
    );
    yield responseValidateStatusOrThrow(response);
    yield put({ payload: response.data, type: types.DELETE_USER_RFID_SUCCESS });
    yield action.onSuccess();
    yield put(addSnackbarSuccess(messages.AAmodalDeleteRFIDMsgSucc));
  } catch (error) {
    yield put({ error, type: types.DELETE_USER_RFID_FAILURE });
    yield put(addSnackbarError(messages.AAmodalDeleteRFIDMsgSucc, TYPE_DESCRIPTOR));
  }
}

export function* createApartmentUser(action: {
  apartmentId: TId;
  companyId: TId;
  siteId: TId;
  type: string;
  values: ICreateApartmentUser;
}) {
  try {
    const { apartmentId, companyId, siteId, values } = action;
    const response: { status: number; data: ICurrentUser } = yield call(
      ApartmentAdminApi.createApartmentUser,
      { apartmentId, companyId, siteId },
      values
    );
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.CREATE_APARTMENT_USER_SUCCESS });
    yield put(addSnackbarSuccess(messages.AAmodalNewUserMsgSucc));
  } catch (error: unknown) {
    if (isStandardError(error) && error.data?.code === 'IV66') {
      yield processError(types.CREATE_APARTMENT_USER_FAILURE, error);
      return;
    }

    yield processError(types.CREATE_APARTMENT_USER_FAILURE, error, {
      ...(error as IError).data,
      message: messages.AAmodalNewUserMsgErr,
    });
  }
}

export function* createUWLUserAssignPin(action: {
  type: string;
  companyId: TId;
  siteId: TId;
  apartmentId: TId;
  userAssignedPin: IUWLUserAssignedPin;
  onSuccess: () => void;
}) {
  try {
    const { apartmentId, companyId, siteId, userAssignedPin } = action;
    const response: SagaReturnType<typeof ApartmentAdminApi.createUWLUserAssignPin> = yield call(
      ApartmentAdminApi.createUWLUserAssignPin,
      {
        companyId,
        siteId,
      },
      userAssignedPin,
      apartmentId
    );
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.CREATE_UWL_ASSIGN_PIN_SUCCESS });
    yield put(addSnackbarSuccess(messages.AASnackbarPinGeneratedSuccessfully));
    yield action.onSuccess();
  } catch (error) {
    yield put(errorHelper(types.CREATE_UWL_ASSIGN_PIN_FAILURE, error));
    yield put(addSnackbarError(messages.AASnackbarPinGeneratedFailed, TYPE_DESCRIPTOR));
  }
}

export function* getUsersInApartments() {
  try {
    const response: SagaReturnType<typeof ApartmentAdminApi.getUsersInApartments> = yield call(
      ApartmentAdminApi.getUsersInApartments
    );
    yield responseValidateStatusOrThrow(response);
    yield put({
      payload: response.data,
      type: types.GET_USERS_IN_APARTMENTS_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_USERS_IN_APARTMENTS_FAILURE, error));
  }
}

export function* getDevicesWithinApartment(action: {
  type: string;
  companyId: TId;
  siteId: TId;
  apartmentId: TId;
  userId: TId;
}) {
  const { apartmentId, companyId, siteId, userId } = action;
  try {
    const response: SagaReturnType<typeof ApartmentAdminApi.getDevicesWithinApartment> = yield call(
      ApartmentAdminApi.getDevicesWithinApartment,
      { companyId, siteId },
      apartmentId,
      userId
    );
    yield responseValidateStatusOrThrow(response);
    yield put({
      payload: response.data.results,
      type: types.GET_DEVICES_WITHIN_APARTMENT_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_DEVICES_WITHIN_APARTMENT_FAILURE, error));
  }
}

export function* createDeviceWithinApartment(action: {
  type: string;
  companyId: TId;
  siteId: TId;
  apartmentId: TId;
  values: { type: DeviceTypeEnum; userId: TId };
}) {
  const { apartmentId, companyId, siteId, values } = action;
  try {
    const response: SagaReturnType<typeof ApartmentAdminApi.createDeviceWithinApartment> = yield call(
      ApartmentAdminApi.createDeviceWithinApartment,
      { companyId, siteId },
      apartmentId,
      values
    );
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.CREATE_DEVICE_WITHIN_APARTMENT_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.CREATE_DEVICE_WITHIN_APARTMENT_FAILURE, error));
  }
}

export function* deleteDeviceWithinApartment(action: {
  type: string;
  companyId: TId;
  siteId: TId;
  apartmentId: TId;
  deviceId: TId;
}) {
  const { apartmentId, companyId, deviceId, siteId } = action;
  try {
    const response: SagaReturnType<typeof ApartmentAdminApi.deleteDeviceWithinApartment> = yield call(
      ApartmentAdminApi.deleteDeviceWithinApartment,
      { companyId, siteId },
      apartmentId,
      deviceId
    );
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.DELETE_DEVICE_WITHIN_APARTMENT_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.DELETE_DEVICE_WITHIN_APARTMENT_FAILURE, error));
  }
}

export function* changeDeviceMVStatus(action: {
  type: string;
  companyId: TId;
  siteId: TId;
  apartmentId: TId;
  deviceId: TId;
  values: IMobileVideoService;
}) {
  const { apartmentId, companyId, deviceId, siteId, values } = action;
  try {
    const response: SagaReturnType<typeof ApartmentAdminApi.changeDeviceMVStatus> = yield call(
      ApartmentAdminApi.changeDeviceMVStatus,
      { companyId, siteId },
      apartmentId,
      deviceId,
      values
    );
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.CHANGE_DEVICE_MV_STATUS_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.CHANGE_DEVICE_MV_STATUS_FAILURE, error));
  }
}

export default function* apartmentAdminSagas() {
  yield all([
    takeLatest(types.GET_AVAILABLE_PIN_REQUEST, getAvailablePin),
    takeLatest(types.CREATE_APARTMENT_USER_REQUEST, createApartmentUser),
    takeLatest(types.CREATE_UWL_ASSIGN_PIN_REQUEST, createUWLUserAssignPin),
    takeLatest(types.GET_USERS_IN_APARTMENTS_REQUEST, getUsersInApartments),
    takeLatest(types.GET_DEVICES_WITHIN_APARTMENT_REQUEST, getDevicesWithinApartment),
    takeLatest(types.CREATE_DEVICE_WITHIN_APARTMENT_REQUEST, createDeviceWithinApartment),
    takeLatest(types.DELETE_DEVICE_WITHIN_APARTMENT_REQUEST, deleteDeviceWithinApartment),
    takeLatest(types.CHANGE_DEVICE_MV_STATUS_REQUEST, changeDeviceMVStatus),
    takeLatest(types.GET_APARTMENT_GROUPS_REQUEST, getApartmentAccessGroups),
    takeLatest(types.GET_USER_GROUPS_REQUEST, getUserAccessGroups),
    takeLatest(types.GET_USER_PIN_REQUEST, getUserPin),
    takeLatest(types.GET_USER_RFID_REQUEST, getUserRfid),
    takeLatest(types.DELETE_USER_PIN_REQUEST, deleteUserPin),
    takeLatest(types.DELETE_USER_RFID_REQUEST, deleteUserRfid),
    takeLatest(types.GENERATE_USER_PIN_REQUEST, generateUserPin),
    takeLatest(types.ACTIVATE_USER_PIN_REQUEST, activateUserPin),
  ]);
}
