import { all, put, takeEvery } from 'redux-saga/effects';
import { SERVICE as WS_SERVICE } from '../../config/websocket';
import { types as deviceTypes } from '../devices';
import { types as subscriptionTypes } from '../subscription';
import TYPES from './types';

export function* listeningFromSocket({ data, service }) {
  if (service === WS_SERVICE.SUBSCRIPTION_PAYMENT) {
    yield put({ data, type: subscriptionTypes.PAYMENT_RESULT_FROM_SOCKET });
  } else {
    yield put({
      context: service,
      data,
      type: deviceTypes.CHANGE_DEVICE_FROM_SOCKET,
    });
  }
}

export default function* websocketSagas() {
  yield all([takeEvery(TYPES.LISTENING_FROM_SOCKET_DATA, listeningFromSocket)]);
}
