import { defineMessages } from 'react-intl';

export default defineMessages({
  deleteDeviceDeleteButton: {
    defaultMessage: 'Delete device',
    description: 'Delete device from My2N',
    id: 'deleteDevice.deleteButton',
  },
  deviceServicesActiveService: {
    defaultMessage: 'Active service',
    description: 'Active service',
    id: 'deviceServices.activeService',
  },
  deviceServicesAcTooltip: {
    defaultMessage: '2N<sup>®</sup> Residential Access',
    description: 'AC tooltip',
    id: 'deviceServices.ac.tooltip',
  },
  deviceServicesInactiveService: {
    defaultMessage: 'Inactive service',
    description: 'Inactive service',
    id: 'deviceServices.inactiveService',
  },
  deviceServicesMobileVideoTooltip: {
    defaultMessage: '2N<sup>®</sup> Mobile Video',
    description: 'Mobile video tooltip',
    id: 'deviceServices.mobileVideo.tooltip',
  },
  deviceServicesRemoteConfigurationTooltip: {
    defaultMessage: '2N<sup>®</sup> Remote Configuration',
    description: 'Remote configuration tooltip',
    id: 'deviceServices.remoteConfiguration.tooltip',
  },
});
