import { ROLES } from '../../config/consts';

export const EMAIL_PATH = '/email';
export const FIRST_NAME_PATH = '/firstName';
export const LAST_NAME_PATH = '/lastName';

export const getFeUserRoles = (siteRoles, companyRoles) => {
  const roles = {
    isCompanyAdmin: false,
    isSiteAdmin: false,
    isSiteAdminPayment: false,
    isSiteUser: false,
  };

  if (
    companyRoles.includes(ROLES.MOBILE_VIDEO_BUYER) &&
    siteRoles.includes(ROLES.USER) &&
    siteRoles.includes(ROLES.ADMIN)
  ) {
    roles.isSiteAdminPayment = true;
  } else if (
    !companyRoles.includes(ROLES.MOBILE_VIDEO_BUYER) &&
    siteRoles.includes(ROLES.USER) &&
    siteRoles.includes(ROLES.ADMIN)
  ) {
    roles.isSiteAdmin = true;
  } else if (
    !companyRoles.includes(ROLES.MOBILE_VIDEO_BUYER) &&
    siteRoles.includes(ROLES.USER) &&
    !siteRoles.includes(ROLES.ADMIN)
  ) {
    roles.isSiteUser = true;
  }

  if (companyRoles.includes(ROLES.COMPANY_ADMIN)) {
    roles.isCompanyAdmin = true;
  }

  return roles;
};

export const getUsersOrderField = (orderField) => {
  const validFields = [
    'name',
    '-name',
    'email',
    '-email',
    'firstName',
    '-firstName',
    'lastName',
    '-lastName',
    'dateCreated',
    '-dateCreated',
    'enabled',
    '-enabled',
  ];
  if (validFields.includes(orderField)) {
    return orderField;
  }
  return 'lastName';
};
