import { DateTimePicker as MuiDateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers-pro';
import { merge } from 'lodash';
import React from 'react';
import { Controller, FieldPath, FieldValues, useFormContext } from 'react-hook-form';

export interface IDateTimePicker<T extends FieldValues> extends DateTimePickerProps<any> {
  name: FieldPath<T>;
  helperText?: string;
  placeholder?: string;
}

function DateTimePicker<T extends FieldValues>({ name, ...other }: IDateTimePicker<T>): React.JSX.Element {
  const { control } = useFormContext<T>();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error, invalid } }) => {
        return (
          <MuiDateTimePicker
            {...other}
            {...field}
            value={field.value || null}
            slotProps={merge(other.slotProps, {
              textField: {
                error: invalid,
                helperText: error?.message || other.helperText || ' ',
                placeholder: other.placeholder,
              },
            })}
            onError={(error) => control.setError(name, { message: error! })}
          />
        );
      }}
    />
  );
}

export default DateTimePicker;
