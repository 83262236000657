import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const CallSettingsListActions = ({ addButtonLabel, onAdd, onSave }) => (
  <Grid container justifyContent="space-between">
    <Grid item>
      {addButtonLabel && (
        <Button color="primary" onClick={onAdd} startIcon={<AddIcon />}>
          {addButtonLabel}
        </Button>
      )}
    </Grid>
    <Grid item>
      <Button color="primary" onClick={onSave} variant="contained">
        <FormattedMessage {...messages.callSettingsListActionsButtonSave} />
      </Button>
    </Grid>
  </Grid>
);

CallSettingsListActions.propTypes = {
  addButtonLabel: PropTypes.any,
  onAdd: PropTypes.func,
  onSave: PropTypes.func.isRequired,
};

CallSettingsListActions.defaultProps = {
  addButtonLabel: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onAdd: () => {},
};

export default CallSettingsListActions;
