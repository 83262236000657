import GppGoodIcon from '@mui/icons-material/GppGood';
import Tab from '@mui/material/Tab';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { TabBadge, TabsContainer, Title } from '../../components';
import { PATHS } from '../../config/consts';
import { useUrlParams } from '../../helpers/urlParams';
import { useGetLoadedParams } from '../../hooks/paramsHooks';
import actions, { selectors } from '../../modules/accessSettings';
import { ApplicationContext } from '../../modules/commonTypes';
import { replaceURLParams } from '../../routes/helpers/pathParamsReplacer';
import DeleteGroupButton from './DeleteGroupButton';
import messages from './messages';

function setPath(path: string, companyId: number, siteId: number, groupId: string) {
  return replaceURLParams({ params: { companyId, groupId, siteId }, url: path });
}

function AccessSettingsDetail() {
  const dispatch = useDispatch();
  const { data, isInvalid } = {
    data: useSelector(selectors.getAccessGroup),
    isInvalid: useSelector(selectors.isAccessGroupInvalid),
  };

  const { companyId, siteId } = useGetLoadedParams(ApplicationContext.Site);
  const { groupId } = useUrlParams();

  const location = useLocation();

  const tabItem = useMemo(
    () =>
      groupId !== undefined
        ? {
            apartments: setPath(PATHS.ACCESS_SETTINGS_APARTMENTS, companyId, siteId, groupId),
            detail: setPath(PATHS.ACCESS_SETTINGS_DETAIL, companyId, siteId, groupId),
            devices: setPath(PATHS.ACCESS_SETTINGS_DEVICES, companyId, siteId, groupId),
            users: setPath(PATHS.ACCESS_SETTINGS_USERS, companyId, siteId, groupId),
          }
        : undefined,
    [location, groupId]
  );

  const value = useMemo(
    () =>
      tabItem && [tabItem.apartments, tabItem.detail, tabItem.devices, tabItem.users].includes(location?.pathname)
        ? location?.pathname
        : null,
    [location, groupId]
  );

  useEffect(() => {
    if (isInvalid) {
      dispatch(actions.getAccessGroup(companyId, siteId, groupId));
    }
  }, [isInvalid]);

  return (
    <>
      <Title
        heading={data.name}
        leftSide={<GppGoodIcon color="action" sx={{ fontSize: 40, mr: 1 }} />}
        rightSide={<DeleteGroupButton companyId={companyId} group={data} siteId={siteId} />}
      />
      {value && tabItem ? (
        <TabsContainer isXs={false} value={value}>
          <Tab
            component={Link}
            label={<FormattedMessage {...messages.AccessSettingsDetailTabGroupDetail} />}
            to={tabItem.detail}
            value={tabItem.detail}
          />
          <Tab
            component={Link}
            label={
              <TabBadge badgeContent={data.devices.length} isTabActive={location?.pathname === tabItem.devices}>
                <FormattedMessage {...messages.AccessSettingsDetailTabDevices} />
              </TabBadge>
            }
            to={tabItem.devices}
            value={tabItem.devices}
          />
          <Tab
            component={Link}
            label={
              <TabBadge badgeContent={data.users} isTabActive={location?.pathname === tabItem.users}>
                <FormattedMessage {...messages.AccessSettingsDetailTabUsers} />
              </TabBadge>
            }
            to={tabItem.users}
            value={tabItem.users}
          />
          <Tab
            component={Link}
            label={
              <TabBadge badgeContent={data.apartments} isTabActive={location?.pathname === tabItem.apartments}>
                <FormattedMessage {...messages.AccessSettingsDetailTabApartments} />
              </TabBadge>
            }
            to={tabItem.apartments}
            value={tabItem.apartments}
          />
        </TabsContainer>
      ) : (
        <>{false}</>
      )}
      <Outlet />
    </>
  );
}

export default AccessSettingsDetail;
