import Grid from '@mui/material/Grid';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import { LoadingButton } from '../../../components';
import { INVOICE } from '../constants';
import messages from '../messages';
import GTCAgree from './GTCAgree';
import PaymentConfirm from './PaymentConfirm';

const OrderSubmit = ({
  companyId,
  isCreditLimitReached,
  isLoading,
  onInvoicePayment,
  onPaymentGateway,
  paymentLocation,
  paymentMethod,
  secondaryAction,
}) => {
  const validationSchema = Yup.object().shape({
    gtcAgree: Yup.bool().when('isGTCAgree', {
      is: true,
      then: (schema) => schema.required().test((value) => value === true),
    }),
    paymentPrepared: Yup.bool().when('isRecurringPayment', {
      is: true,
      then: (schema) => schema.required().test((value) => value === true),
    }),
  });

  return (
    <Formik
      initialValues={{
        gtcAgree: false,
        paymentPrepared: false,
      }}
      render={(form) => (
        <Form autoComplete="off" noValidate>
          <Grid item lg={10} xs={12}>
            <PaymentConfirm
              message={<FormattedMessage {...messages.orderSubmitPaymentConfirm} />}
              name="paymentPrepared"
            />
          </Grid>
          <Grid item lg={10} xs={12}>
            <GTCAgree companyId={companyId} name="gtcAgree" />
          </Grid>
          <Grid alignItems="center" container justifyContent={secondaryAction ? 'space-between' : 'flex-end'}>
            {secondaryAction && <Grid item>{secondaryAction}</Grid>}
            <Grid item>
              {paymentMethod === INVOICE ? (
                <LoadingButton
                  color="primary"
                  disabled={!form.isValid || isCreditLimitReached}
                  id="invoice-button"
                  isLoading={isLoading}
                  onClick={onInvoicePayment}
                  variant="contained"
                >
                  <FormattedMessage {...messages.orderSubmitInvoiceButton} />
                </LoadingButton>
              ) : (
                <LoadingButton
                  color="primary"
                  disabled={!form.isValid}
                  href={paymentLocation}
                  id="webpay-button"
                  isLoading={isLoading}
                  onClick={onPaymentGateway}
                  target="_blank"
                  variant="contained"
                >
                  <FormattedMessage {...messages.orderSubmitWebPayButton} />
                </LoadingButton>
              )}
            </Grid>
          </Grid>
        </Form>
      )}
      validationSchema={validationSchema}
    />
  );
};

OrderSubmit.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  isCreditLimitReached: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onInvoicePayment: PropTypes.func.isRequired,
  onPaymentGateway: PropTypes.func.isRequired,
  paymentLocation: PropTypes.string.isRequired,
  paymentMethod: PropTypes.string.isRequired,
  secondaryAction: PropTypes.node,
};

OrderSubmit.defaultProps = {
  secondaryAction: null,
};

export default OrderSubmit;
