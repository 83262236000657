import React from 'react';
import { FormattedMessage } from 'react-intl';
import DevicesInfoChip from '../DevicesInfoChip';
import messages from './messages';

const DeactivatedStatus = () => (
  <DevicesInfoChip
    disabled
    label={<FormattedMessage {...messages.deviceStatusDeactivated} />}
    size="small"
    variant="outlined"
  />
);

export default DeactivatedStatus;
