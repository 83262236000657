import React from 'react';
import { CookieConsentPanel, CookieSettingsButton } from '../../components';
import { useAppContext } from '../../pages/App/AppContextProvider';

const CookieConsent = () => {
  const { loggedIn } = useAppContext();
  return (
    <>
      {!loggedIn && <CookieSettingsButton floating iconColor="grey.300" />}
      <CookieConsentPanel />
    </>
  );
};

export default CookieConsent;
