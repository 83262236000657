import { defineMessages } from 'react-intl';

export default defineMessages({
  apartmentAutocompleteHelperText: {
    defaultMessage: 'Add user to an apartment for easier administration.',
    description: 'Add apartment autocomplete - helper text',
    id: 'apartmentAutocompleteHelperText',
  },
  ApartmentAutocompleteSelectLabel: {
    defaultMessage: 'Apartment',
    description: 'Add apartment autocomplete - label',
    id: 'apartmentAutocompleteSelectLabel',
  },
  ApartmentAutocompleteSelectNewItemLabel: {
    defaultMessage: 'Create new apartment',
    description: 'Add apartment autocomplete - new item label',
    id: 'apartmentAutocompleteSelectNewItemLabel',
  },
  apartmentAutocompleteSelectPlaceholder: {
    defaultMessage: 'Select apartments, or create a new one',
    description: 'Add apartment autocomplete - placeholder',
    id: 'apartmentAutocompleteSelectPlaceholder',
  },
  ApartmentFormFieldFloor: {
    defaultMessage: 'Floor',
    description: 'Apartment form field - floor',
    id: 'apartmentFormFieldFloor',
  },
  ApartmentFormFieldName: {
    defaultMessage: 'Apartment name',
    description: 'Apartment form field - name',
    id: 'apartmentFormFieldName',
  },
  ApartmentFormFieldNumber: {
    defaultMessage: 'Number',
    description: 'Apartment form field - number',
    id: 'apartmentFormFieldNumber',
  },
  ApartmentFormHelperTextFloor: {
    defaultMessage: 'Usually a group of apartments',
    description: 'Apartment form helper text - floor',
    id: 'apartmentFormHelperTextFloor',
  },
  ApartmentFormHelperTextName: {
    defaultMessage: 'You can name the apartment after the family name.',
    description: 'Apartment form helper text - name',
    id: 'apartmentFormHelperTextName',
  },
  ApartmentFormHelperTextNumber: {
    defaultMessage: 'Use a unique number, like 27, 4A or 2.01',
    description: 'Apartment form helper text - number',
    id: 'apartmentFormHelperTextNumber',
  },
});
