import { Box, Paper, Skeleton, Typography } from '@mui/material';
import React from 'react';
import { styles } from './styles';

export interface IDetailItemProps {
  title: string | React.ReactNode;
  titleIcon?: React.ReactNode;
  isFetching?: boolean;
  children: React.JSX.Element;
}

export function DetailItem(props: IDetailItemProps) {
  return (
    <Paper sx={{ minHeight: '100px', p: 3 }}>
      {!props.titleIcon && (
        <Typography variant="body1" fontWeight={700} sx={{ pb: 3 }}>
          {props.title}
        </Typography>
      )}
      {props.titleIcon && (
        <Box display="flex" flexWrap="wrap">
          <Typography variant="body1" fontWeight={700} sx={{ pb: 3 }}>
            {props.title}
          </Typography>
          {props.titleIcon}
        </Box>
      )}
      {props.isFetching ? (
        <Box sx={styles.skeletonWrapper}>
          <Skeleton width={'75%'} />
        </Box>
      ) : (
        props.children
      )}
    </Paper>
  );
}
