import types from './types';

export const initialPhonebooksList = {
  data: [],
  didInvalid: false,
  filter: {
    fulltext: '',
  },
  isFetching: false,
  order: 'name',
  page: 1,
  resultsFiltered: 0,
  resultsTotal: 0,
  rowsPerPage: 10,
};

export const initialMembersList = {
  data: [],
  didInvalid: false,
  error: null,
  filter: { fulltext: '' },
  isFetching: false,
  order: 'device.name',
  page: 0,
  resultsFiltered: 0,
  resultsTotal: 0,
  rowsPerPage: 10,
};

export const initialPhonebookModal = {
  data: {
    externalId: null,
    members: [],
    name: '',
  },
  isEdit: false,
  open: false,
};

export const initialState = {
  deleteModal: {
    isFetching: false,
    phonebook: {},
    show: false,
  },
  detail: {
    data: {
      externalId: null,
      id: null,
      isDefault: false,
      members: [],
      name: '',
    },
    error: null,
    isFetching: false,
  },
  list: initialPhonebooksList,
  membersList: initialMembersList,
  phonebookModal: initialPhonebookModal,
};

const reducers = {
  [types.ADD_PHONEBOOK_SHOW_MODAL]: (state) => ({
    ...state,
    phonebookModal: {
      ...initialPhonebookModal,
      open: true,
    },
  }),

  [types.EDIT_PHONEBOOK_SHOW_MODAL]: (state, { phonebook }) => ({
    ...state,
    phonebookModal: {
      data: phonebook,
      isEdit: true,
      open: true,
    },
  }),

  [types.PHONEBOOK_HIDE_MODAL]: (state) => ({
    ...state,
    phonebookModal: initialPhonebookModal,
  }),

  [types.DELETE_PHONEBOOK_SHOW_MODAL]: (state, { phonebook }) => ({
    ...state,
    deleteModal: {
      ...state.deleteModal,
      phonebook,
      show: true,
    },
  }),

  [types.DELETE_PHONEBOOK_HIDE_MODAL]: (state) => ({
    ...state,
    deleteModal: {
      ...initialState.deleteModal,
    },
  }),

  [types.DELETE_PHONEBOOK_REQUEST]: (state) => ({
    ...state,
    deleteModal: {
      ...state.deleteModal,
      isFetching: true,
    },
  }),

  [types.DELETE_PHONEBOOK_SUCCESS]: (state) => ({
    ...state,
    deleteModal: {
      ...state.deleteModal,
      isFetching: false,
    },
  }),

  [types.DELETE_PHONEBOOK_FAILURE]: (state) => ({
    ...state,
    deleteModal: {
      ...state.deleteModal,
      isFetching: false,
    },
  }),

  [types.SAVE_PHONEBOOK_SUCCESS]: (state, { payload }) => ({
    ...state,
    detail: {
      data: payload,
      isFetching: false,
    },
    phonebookModal: initialPhonebookModal,
  }),

  [types.PHONEBOOK_DETAIL_REQUEST]: (state) => ({
    ...state,
    detail: {
      ...state.detail,
      isFetching: true,
    },
  }),

  [types.PHONEBOOK_DETAIL_SUCCESS]: (state, { payload }) => ({
    ...state,
    detail: {
      data: payload,
      isFetching: false,
    },
  }),

  [types.PHONEBOOK_DETAIL_FAILURE]: (state, { error }) => ({
    ...state,
    detail: {
      ...initialState.detail,
      error,
    },
  }),

  [types.LOAD_MEMBERS_LIST_REQUEST]: (state) => ({
    ...state,
    membersList: {
      ...initialMembersList,
      data: state.membersList.data,
      isFetching: true,
    },
  }),

  [types.LOAD_MEMBERS_LIST_SUCCESS]: (state, { payload, ...action }) => ({
    ...state,
    membersList: {
      ...initialMembersList,
      data: payload.data || [],
      filter: action.filter,
      order: action.order,
      page: action.page,
      resultsFiltered: payload.resultsFiltered || 0,
      resultsTotal: payload.resultsTotal || 0,
      rowsPerPage: action.rowsPerPage,
    },
  }),

  [types.LOAD_MEMBERS_LIST_FAILURE]: (state, { error }) => ({
    ...state,
    membersList: {
      ...initialMembersList,
      error,
    },
  }),

  [types.LOAD_PHONEBOOKS_LIST_REQUEST]: (state) => ({
    ...state,
    list: {
      ...initialPhonebooksList,
      isFetching: true,
    },
  }),

  [types.LOAD_PHONEBOOKS_LIST_SUCCESS]: (state, { payload, ...action }) => ({
    ...state,
    list: {
      ...state.list,
      data: payload.data || [],
      didInvalid: false,
      filter: action.filter,
      isFetching: false,
      order: action.order,
      page: action.page,
      resultsFiltered: payload.resultsFiltered || 0,
      resultsTotal: payload.resultsTotal || 0,
      rowsPerPage: action.rowsPerPage,
    },
  }),

  [types.LOAD_PHONEBOOKS_LIST_FAILURE]: (state) => ({
    ...state,
    list: initialPhonebooksList,
  }),

  [types.LOAD_PHONEBOOKS_NAMES_REQUEST]: (state) => ({
    ...state,
    list: {
      ...initialPhonebooksList,
      isFetching: true,
    },
  }),

  [types.LOAD_PHONEBOOKS_NAMES_SUCCESS]: (state, { payload }) => ({
    ...state,
    list: {
      ...initialPhonebooksList,
      data: payload || [],
    },
  }),

  [types.LOAD_PHONEBOOKS_NAMES_FAILURE]: (state) => ({
    ...state,
    list: initialPhonebooksList,
  }),
};

const reducer = (state = initialState, action = {}) => {
  if (reducers[action.type]) {
    return reducers[action.type](state, action);
  }
  return state;
};

export default reducer;
