import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { SectionHeader } from '../../../../../components';
import RemoveBatchModal from '../../Common/RemoveBatchModal';
import { orderItemPropTypes } from '../../types';
import messages from '../messages';
import CountedOrderData from './CountedOrderData';
import Header from './Header';
import UserOrderData from './UserOrderData';

const styles = {
  itemWrapper: {
    pb: {
      sm: 2,
      xs: 3,
    },
    pt: {
      sm: 1,
      xs: 1.5,
    },
  },
  orderDataWrapper: {
    justifyContent: {
      lg: 'space-between',
      xs: 'flex-start',
    },
    pl: {
      sm: 0,
      xs: 1,
    },
  },
};

const OrderList = ({ currency, onDeletePayment, orderData }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const [removeDialog, setRemoveDialog] = useState({ index: 0, open: false });
  const closeRemoveDialog = () => setRemoveDialog({ open: false });

  return (
    <>
      <SectionHeader headerText={<FormattedMessage {...messages.orderListHeader} />} variant="h6" />
      <Box pb={4}>
        {orderData.map((item) => (
          <span key={item.id}>
            <Grid alignItems="center" container sx={styles.itemWrapper}>
              <Header
                isRemoveBatchDisabled={!(orderData.length > 1)}
                itemSubscriptionType={item.configuration.type}
                onRemoveBatch={() => setRemoveDialog({ index: item.id, open: true })}
              />
              <Grid container item spacing={matches ? 1 : 0} sx={styles.orderDataWrapper}>
                <UserOrderData item={item} />
                <CountedOrderData
                  currency={currency}
                  isRemoveBatchDisabled={!(orderData.length > 1)}
                  item={item}
                  onRemoveBatch={() => setRemoveDialog({ index: item.id, open: true })}
                />
              </Grid>
            </Grid>
          </span>
        ))}
      </Box>
      <RemoveBatchModal
        onClose={closeRemoveDialog}
        onRemoveBatch={() => onDeletePayment(removeDialog.index)}
        open={removeDialog.open}
      />
    </>
  );
};

OrderList.propTypes = {
  currency: PropTypes.string.isRequired,
  onDeletePayment: PropTypes.func.isRequired,
  orderData: PropTypes.arrayOf(orderItemPropTypes).isRequired,
};

export default OrderList;
