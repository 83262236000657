import React, { ReactNode } from 'react';
import UserSelectOptionLabel, { UserSelectOptionLabelType, UserSelectOptionLabelUser } from '../UserSelectOptionLabel';

type Option = {
  key: number | string;
  label: ReactNode | string;
  sx?: object;
  value: number;
};

const setOption = (user: UserSelectOptionLabelUser, currentUser: { id: number }): Option => {
  let type: UserSelectOptionLabelType = 'normal';
  if (user?.id === currentUser?.id) {
    type = 'me';
  }

  let optionApartmentData = user.apartments?.length
    ? `(${user.apartments[0].number}${user.apartments[0].name ? ` – ${user.apartments[0].name}` : ''})`
    : '';
  if (user.apartments?.length > 1) {
    optionApartmentData = `${optionApartmentData} + ${user.apartments.length - 1}`;
  }
  return {
    key: user.id,
    label: <UserSelectOptionLabel apartments={optionApartmentData} user={user} type={type} />,
    sx: {},
    value: user.id,
  };
};

const getUsers = (users: UserSelectOptionLabelUser[], currentUser: { id: number }, addNew: boolean) => {
  const sorted = users
    .sort((a: UserSelectOptionLabelUser, b: UserSelectOptionLabelUser) => {
      if (a.id === currentUser.id) {
        return -1;
      }
      if (b.id === currentUser.id) {
        return 1;
      }
      if (a.lastName < b.lastName) {
        return -1;
      }
      if (a.lastName > b.lastName) {
        return 1;
      }
      return 0;
    })
    .map((user) => setOption(user, currentUser));
  if (addNew) {
    sorted.unshift({
      key: 'createNewUser',
      label: <UserSelectOptionLabel type="new" />,
      value: -1,
    });
  }
  return sorted;
};

export default getUsers;
