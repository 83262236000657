import { defineMessages } from 'react-intl';

export default defineMessages({
  listHeaderDtmfCode: {
    defaultMessage: 'DTMF Code',
    description: 'List Header - DTMF Code',
    id: 'listHeader.dtmfCode',
  },
  listHeaderFunctionName: {
    defaultMessage: 'Function name <small>(will be shown in the My2N mobile app)</small>',
    description: 'List Header - Function name',
    id: 'listHeader.functionName',
  },
  listHeaderSwitch: {
    defaultMessage: 'Switch',
    description: 'List Header - switch',
    id: 'listHeader.switch',
  },
  listHeaderSwitchEnabled: {
    defaultMessage: 'Enabled',
    description: 'List Header - enabled',
    id: 'listHeader.switchEnabled',
  },
  listHeaderSwitchSettingsSource: {
    defaultMessage: 'Settings source',
    description: 'List Header - settings source',
    id: 'listHeader.switchSettingsSource',
  },
  switchCodesAlertInfo: {
    defaultMessage:
      'Switches provide control of device peripherals such as electric door locks, lighting, ' +
      'and additional signaling features',
    description: 'Switch codes - alert info',
    id: 'switchCodes.alert.info',
  },
  switchCodesAlertWarningEmpty: {
    defaultMessage: 'When all the switches are disabled or empty, the device can’t remotely open doors during a call',
    description: 'Switch codes - alert warning empty',
    id: 'switchCodes.alert.warning.empty',
  },
  switchCodesAlertWarningNotLoaded: {
    defaultMessage: 'Information about switches has not yet been loaded from the device',
    description: 'Switch codes - alert warning not loaded',
    id: 'switchCodes.alert.warning.notLoaded',
  },
  switchCodesCodePlaceholder: {
    defaultMessage: 'Code',
    description: 'Switch codes - code input placeholder',
    id: 'switchCodes.codePlaceholder',
  },
  switchCodesNameNotFilledError: {
    defaultMessage: 'DTMF code requires function name',
    description: 'Switch codes - name is not filled',
    id: 'switchCodes.nameNotFilledError',
  },
  switchCodesNamePlaceholder: {
    defaultMessage: 'Function name',
    description: 'Switch codes - function name input placeholder',
    id: 'switchCodes.namePlaceholder',
  },
  switchCodesSaveButton: {
    defaultMessage: 'Save',
    description: 'Switch codes - save button',
    id: 'switchCodes.saveButton',
  },
  switchCodesSwitchManaged: {
    defaultMessage: 'Managed by My2N',
    description: 'Switch codes - managed switch',
    id: 'switchCodes.switch.managed',
  },
  switchCodesSwitchNotManaged: {
    defaultMessage: 'Not managed by My2N',
    description: 'Switch codes - not managed switch',
    id: 'switchCodes.switch.notManaged',
  },
  switchCodesUniqueError: {
    defaultMessage: 'DTMF codes must be unique',
    description: 'Switch codes - code is not unique',
    id: 'switchCodes.uniqueError',
  },
  switchCodesUniqueWarning: {
    defaultMessage: 'This code is already used',
    description: 'Switch codes - code is not unique',
    id: 'switchCodes.uniqueWarning',
  },
});
