import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import iconStopSvg from '../../../assets/icon_stop.svg';
import messages from './messages';

const NoVerifiedMessage = () => (
  <>
    <img alt="" src={iconStopSvg} />
    <Typography color="error" paragraph>
      <FormattedMessage {...messages.noVerifiedMessageMessage} />
    </Typography>
  </>
);

export default NoVerifiedMessage;
