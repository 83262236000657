import { MessageDescriptor } from 'react-intl';
import { LANGUAGES } from '../../config/consts';
import messages from './messages';
import { ILang } from './types';

export const LOCALE_PATH = '/locale';

export const validateLocale = (locale?: string) => {
  const language = Object.values(LANGUAGES).find((l) => l.locale.substring(0, 2) === locale?.substring(0, 2));
  return language?.locale || LANGUAGES.english.locale;
};

export const getLangByLocale = (locale: string) =>
  Object.values(LANGUAGES).find((l) => l.locale === validateLocale(locale));

export const getLangSelectOptionLabel = (
  lang: ILang,
  siteLang: string,
  currentUserLang: string,
  formatMessage: (message: MessageDescriptor) => string
) => {
  if (lang.locale === siteLang) {
    return `${formatMessage(lang.translated)} (${formatMessage(messages.localizationHelpersSiteDefault)})`;
  }
  if (lang.locale === currentUserLang) {
    return `${formatMessage(lang.translated)} (${formatMessage(messages.localizationHelpersSetByUser)})`;
  }
  return formatMessage(lang.translated);
};
