import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import RowMenuItem from '../../../../components/DataGrid/RowMenuItem';
import { PATHS } from '../../../../config/consts';
import messages from './messages';

function EditApartment({ apartmentId, companyId, siteId }) {
  const { formatMessage } = useIntl();

  return (
    <RowMenuItem
      component={Link}
      icon={<EditIcon />}
      label={formatMessage(messages.accessGroupApartmentsDataGridDataGridRowMenuEdit)}
      to={PATHS.APARTMENT_DETAIL.replace(':companyId', companyId)
        .replace(':siteId', siteId)
        .replace(':apartmentId', apartmentId)}
    />
  );
}

EditApartment.propTypes = {
  apartmentId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  siteId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default EditApartment;
