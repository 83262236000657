import LoopIcon from '@mui/icons-material/Loop';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

interface IRefreshIconButton {
  disabled: boolean;
  onClick: () => void;
}

function RefreshIconButton({ disabled, onClick }: IRefreshIconButton) {
  return (
    <Tooltip
      placement="right"
      title={
        <FormattedMessage
          {...messages.refreshIconButtonTooltip}
          values={{
            br: <br />,
          }}
        />
      }
    >
      <div>
        <IconButton disabled={disabled} onClick={() => onClick()} size="small">
          <LoopIcon />
        </IconButton>
      </div>
    </Tooltip>
  );
}

export default RefreshIconButton;
