import { Share } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../QRPanel/messages';

export interface ShareButtonProps {
  fileData: Blob;
  disabled: boolean;
}

export function ShareButton({ disabled, fileData }: ShareButtonProps) {
  function getShareData() {
    const file = new File([fileData], 'qr-code.png', { type: 'image/png' });
    return {
      files: [file],
    };
  }

  function share() {
    navigator.share(getShareData());
  }

  function canShare() {
    try {
      navigator.canShare(getShareData());
      return true;
    } catch {
      return false;
    }
  }

  return canShare() ? (
    <Button color="secondary" size="small" startIcon={<Share />} onClick={share} disabled={disabled}>
      <FormattedMessage {...messages.AAmodalPinformShare} />
    </Button>
  ) : (
    <></>
  );
}
