import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import AddDeviceModal from '../AddDeviceModal';
import DevicesControl from '../DeviceControl';
import DeviceTitle from '../DeviceControl/DeviceTitle';
import { IDeviceModal } from '../types';
import messages from './messages';

export function DeviceSwitcherForm(props: IDeviceModal) {
  return (
    <AddDeviceModal
      title={<DeviceTitle device="GENERAL" />}
      actions={undefined}
      onClose={props.onClose}
      open={props.open}
    >
      <Typography>
        <FormattedMessage {...messages.deviceTypeSwitcherDescription} />
      </Typography>
      <DevicesControl onSelectDevice={props.onSelectDevice} />
    </AddDeviceModal>
  );
}
