import { IRfid } from '../../api/userApiTS';
import { IPin } from '../commonTypes';
import { IDevice } from '../devices/store/deviceStore';
import { CommonTypes, IError, IReducers, IReduxAction } from '../reduxTypes';
import { IAccessGroup, IAggregatedApartmentUsers, IApartmentWithAccessGroups } from './data';
import { getInitialApartmentAdminStore, IApartmentAdminStore, ICreateUWLWithPinModal } from './store';
import { getAccessGroupNames, getApartmentAccessGroupNames, getUserRfid } from './transform';
import { types } from './types';

const reducers: IReducers<IApartmentAdminStore, CommonTypes> = {
  [types.CREATE_UWL_ASSIGN_PIN_REQUEST]: (state): IApartmentAdminStore => ({
    ...state,
    createUWLWithPinModal: {
      ...state.createUWLWithPinModal,
      isFetching: true,
    },
  }),

  [types.CREATE_UWL_ASSIGN_PIN_SUCCESS]: (state): IApartmentAdminStore => ({
    ...state,
    createUWLWithPinModal: getInitialApartmentAdminStore().createUWLWithPinModal,
    usersInApartments: {
      ...state.usersInApartments,
      didInvalid: true,
    },
  }),

  [types.CREATE_UWL_ASSIGN_PIN_FAILURE]: (state, error: IError): IApartmentAdminStore => ({
    ...state,
    createUWLWithPinModal: {
      ...state.createUWLWithPinModal,
      error,
      isFetching: false,
    },
  }),

  [types.CREATE_APARTMENT_USER_SUCCESS]: (state) => ({
    ...state,
    usersInApartments: {
      ...state.usersInApartments,
      didInvalid: true,
    },
  }),

  [types.GET_USERS_IN_APARTMENTS_REQUEST]: (state): IApartmentAdminStore => ({
    ...state,
    usersInApartments: {
      ...getInitialApartmentAdminStore().usersInApartments,
      isFetching: true,
    },
  }),

  [types.GET_USERS_IN_APARTMENTS_SUCCESS]: (state, payload: IAggregatedApartmentUsers[]): IApartmentAdminStore => ({
    ...state,
    usersInApartments: {
      data: payload,
      didInvalid: false,
      error: undefined,
      isFetching: false,
    },
  }),

  [types.GET_USERS_IN_APARTMENTS_FAILURE]: (state, error: IError): IApartmentAdminStore => ({
    ...state,
    usersInApartments: {
      data: undefined,
      didInvalid: false,
      error,
      isFetching: false,
    },
  }),

  [types.GET_USERS_IN_APARTMENTS_INVALIDATE]: (state): IApartmentAdminStore => ({
    ...state,
    usersInApartments: {
      ...state.usersInApartments,
      didInvalid: true,
    },
  }),

  [types.GET_AVAILABLE_PIN_REQUEST]: (state): IApartmentAdminStore => ({
    ...state,
    createUWLWithPinModal: {
      ...state.createUWLWithPinModal,
      isFetching: true,
      pin: undefined,
    },
  }),

  [types.GET_AVAILABLE_PIN_CLEAN]: (state): IApartmentAdminStore => ({
    ...state,
    createUWLWithPinModal: getInitialApartmentAdminStore().createUWLWithPinModal,
  }),

  [types.GET_AVAILABLE_PIN_SUCCESS]: (state, payload: ICreateUWLWithPinModal['pin']): IApartmentAdminStore => ({
    ...state,
    createUWLWithPinModal: {
      error: undefined,
      isFetching: false,
      pin: payload,
    },
  }),

  [types.GET_AVAILABLE_PIN_FAILURE]: (state, error: IError) => ({
    ...state,
    createUWLWithPinModal: {
      ...state.createUWLWithPinModal,
      error,
      isFetching: false,
    },
  }),

  [types.GET_DEVICES_WITHIN_APARTMENT_SUCCESS]: (state, payload: IDevice[]): IApartmentAdminStore => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      devicesWithinApartment: {
        ...getInitialApartmentAdminStore().userDetail.devicesWithinApartment,
        data: payload,
      },
    },
  }),

  [types.GET_DEVICES_WITHIN_APARTMENT_FAILURE]: (state, error: IError): IApartmentAdminStore => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      devicesWithinApartment: {
        ...getInitialApartmentAdminStore().userDetail.devicesWithinApartment,
        error,
      },
    },
  }),

  [types.GET_DEVICES_WITHIN_APARTMENT_REQUEST]: (state): IApartmentAdminStore => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      devicesWithinApartment: {
        ...getInitialApartmentAdminStore().userDetail.devicesWithinApartment,
        isFetching: true,
      },
    },
  }),

  [types.CREATE_DEVICE_WITHIN_APARTMENT_SUCCESS]: (state): IApartmentAdminStore => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      devicesWithinApartment: {
        ...state.userDetail.devicesWithinApartment,
        didInvalid: true,
      },
    },
  }),

  [types.CREATE_DEVICE_WITHIN_APARTMENT_FAILURE]: (state, error: IError): IApartmentAdminStore => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      devicesWithinApartment: {
        ...state.userDetail.devicesWithinApartment,
        didInvalid: true,
        error,
      },
    },
  }),

  [types.CREATE_DEVICE_WITHIN_APARTMENT_REQUEST]: (state): IApartmentAdminStore => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      devicesWithinApartment: {
        ...getInitialApartmentAdminStore().userDetail.devicesWithinApartment,
        isFetching: true,
      },
    },
  }),

  [types.DELETE_DEVICE_WITHIN_APARTMENT_SUCCESS]: (state): IApartmentAdminStore => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      devicesWithinApartment: {
        ...state.userDetail.devicesWithinApartment,
        didInvalid: true,
      },
    },
  }),

  [types.DELETE_DEVICE_WITHIN_APARTMENT_FAILURE]: (state, error: IError): IApartmentAdminStore => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      devicesWithinApartment: {
        ...state.userDetail.devicesWithinApartment,
        didInvalid: true,
        error,
      },
    },
  }),

  [types.DELETE_DEVICE_WITHIN_APARTMENT_REQUEST]: (state): IApartmentAdminStore => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      devicesWithinApartment: {
        ...getInitialApartmentAdminStore().userDetail.devicesWithinApartment,
        isFetching: true,
      },
    },
  }),

  [types.CHANGE_DEVICE_MV_STATUS_SUCCESS]: (state): IApartmentAdminStore => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      devicesWithinApartment: {
        ...state.userDetail.devicesWithinApartment,
        didInvalid: true,
      },
    },
  }),

  [types.CHANGE_DEVICE_MV_STATUS_FAILURE]: (state, error: IError): IApartmentAdminStore => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      devicesWithinApartment: {
        ...state.userDetail.devicesWithinApartment,
        didInvalid: true,
        error,
      },
    },
  }),

  [types.CHANGE_DEVICE_MV_STATUS_FAILURE]: (state): IApartmentAdminStore => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      devicesWithinApartment: {
        ...getInitialApartmentAdminStore().userDetail.devicesWithinApartment,
        isFetching: true,
      },
    },
  }),

  [types.GET_APARTMENT_GROUPS_REQUEST]: (state): IApartmentAdminStore => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      accessSettings: {
        ...state.userDetail.accessSettings,
        apartmentGroups: {
          ...getInitialApartmentAdminStore().userDetail.accessSettings.apartmentGroups,
          isFetching: true,
        },
      },
    },
  }),

  [types.GET_APARTMENT_GROUPS_FAILURE]: (state, error: IError): IApartmentAdminStore => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      accessSettings: {
        ...state.userDetail.accessSettings,
        apartmentGroups: {
          ...getInitialApartmentAdminStore().userDetail.accessSettings.apartmentGroups,
          error: error,
          isFetching: false,
        },
      },
    },
  }),

  [types.GET_APARTMENT_GROUPS_SUCCESS]: (state, payload: IApartmentWithAccessGroups[]): IApartmentAdminStore => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      accessSettings: {
        ...state.userDetail.accessSettings,
        apartmentGroups: {
          ...getInitialApartmentAdminStore().userDetail.accessSettings.apartmentGroups,
          data: getApartmentAccessGroupNames(payload),
          isFetching: false,
        },
      },
    },
  }),

  [types.GET_USER_GROUPS_REQUEST]: (state): IApartmentAdminStore => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      accessSettings: {
        ...state.userDetail.accessSettings,
        userGroups: {
          ...getInitialApartmentAdminStore().userDetail.accessSettings.userGroups,
          isFetching: true,
        },
      },
    },
  }),

  [types.GET_USER_GROUPS_FAILURE]: (state, error: IError): IApartmentAdminStore => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      accessSettings: {
        ...state.userDetail.accessSettings,
        userGroups: {
          ...getInitialApartmentAdminStore().userDetail.accessSettings.userGroups,
          error: error,
          isFetching: false,
        },
      },
    },
  }),

  [types.GET_USER_GROUPS_SUCCESS]: (state, payload: IAccessGroup[]): IApartmentAdminStore => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      accessSettings: {
        ...state.userDetail.accessSettings,
        userGroups: {
          ...getInitialApartmentAdminStore().userDetail.accessSettings.userGroups,
          data: getAccessGroupNames(payload),
          isFetching: false,
        },
      },
    },
  }),

  [types.GET_USER_PIN_REQUEST]: (state): IApartmentAdminStore => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      accessSettings: {
        ...state.userDetail.accessSettings,
        pin: {
          ...getInitialApartmentAdminStore().userDetail.accessSettings.pin,
          isFetching: true,
        },
      },
    },
  }),

  [types.GET_USER_PIN_FAILURE]: (state, error: IError): IApartmentAdminStore => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      accessSettings: {
        ...state.userDetail.accessSettings,
        pin: {
          ...getInitialApartmentAdminStore().userDetail.accessSettings.pin,
          error: error,
          isFetching: false,
        },
      },
    },
  }),

  [types.GET_USER_PIN_SUCCESS]: (state, payload: IPin): IApartmentAdminStore => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      accessSettings: {
        ...state.userDetail.accessSettings,
        pin: {
          ...getInitialApartmentAdminStore().userDetail.accessSettings.pin,
          data: payload,
          isFetching: false,
        },
      },
    },
  }),

  [types.GENERATE_USER_PIN_REQUEST]: (state): IApartmentAdminStore => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      accessSettings: {
        ...state.userDetail.accessSettings,
        pin: {
          ...getInitialApartmentAdminStore().userDetail.accessSettings.pin,
          isFetching: true,
        },
      },
    },
  }),

  [types.GENERATE_USER_PIN_FAILURE]: (state, error: IError): IApartmentAdminStore => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      accessSettings: {
        ...state.userDetail.accessSettings,
        pin: {
          ...getInitialApartmentAdminStore().userDetail.accessSettings.pin,
          error: error,
          isFetching: false,
        },
      },
    },
  }),

  [types.GENERATE_USER_PIN_SUCCESS]: (state, payload: IPin): IApartmentAdminStore => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      accessSettings: {
        ...state.userDetail.accessSettings,
        pin: {
          ...getInitialApartmentAdminStore().userDetail.accessSettings.pin,
          data: payload,
          isFetching: false,
        },
      },
    },
  }),

  [types.ACTIVATE_USER_PIN_REQUEST]: (state): IApartmentAdminStore => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      accessSettings: {
        ...state.userDetail.accessSettings,
        pin: {
          ...getInitialApartmentAdminStore().userDetail.accessSettings.pin,
          isFetching: true,
        },
      },
    },
  }),

  [types.ACTIVATE_USER_PIN_FAILURE]: (state, error: IError): IApartmentAdminStore => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      accessSettings: {
        ...state.userDetail.accessSettings,
        pin: {
          ...getInitialApartmentAdminStore().userDetail.accessSettings.pin,
          error: error,
          isFetching: false,
        },
      },
    },
  }),

  [types.ACTIVATE_USER_PIN_SUCCESS]: (state): IApartmentAdminStore => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      accessSettings: {
        ...state.userDetail.accessSettings,
        pin: {
          ...getInitialApartmentAdminStore().userDetail.accessSettings.pin,
          isFetching: false,
        },
      },
    },
  }),

  [types.DELETE_USER_PIN_REQUEST]: (state): IApartmentAdminStore => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      accessSettings: {
        ...state.userDetail.accessSettings,
        pin: {
          ...getInitialApartmentAdminStore().userDetail.accessSettings.pin,
          isFetching: true,
        },
      },
    },
  }),

  [types.DELETE_USER_PIN_FAILURE]: (state, error: IError): IApartmentAdminStore => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      accessSettings: {
        ...state.userDetail.accessSettings,
        pin: {
          ...getInitialApartmentAdminStore().userDetail.accessSettings.pin,
          error: error,
          isFetching: false,
        },
      },
    },
  }),

  [types.DELETE_USER_PIN_SUCCESS]: (state): IApartmentAdminStore => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      accessSettings: {
        ...state.userDetail.accessSettings,
        pin: {
          ...getInitialApartmentAdminStore().userDetail.accessSettings.pin,
          isFetching: false,
        },
      },
    },
  }),

  [types.DELETE_USER_RFID_REQUEST]: (state): IApartmentAdminStore => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      accessSettings: {
        ...state.userDetail.accessSettings,
        rfid: {
          ...getInitialApartmentAdminStore().userDetail.accessSettings.rfid,
          isFetching: true,
        },
      },
    },
  }),

  [types.DELETE_USER_RFID_FAILURE]: (state, error: IError): IApartmentAdminStore => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      accessSettings: {
        ...state.userDetail.accessSettings,
        rfid: {
          ...getInitialApartmentAdminStore().userDetail.accessSettings.rfid,
          error: error,
          isFetching: false,
        },
      },
    },
  }),

  [types.DELETE_USER_RFID_SUCCESS]: (state): IApartmentAdminStore => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      accessSettings: {
        ...state.userDetail.accessSettings,
        rfid: {
          ...getInitialApartmentAdminStore().userDetail.accessSettings.rfid,
          isFetching: false,
        },
      },
    },
  }),

  [types.GET_USER_RFID_REQUEST]: (state): IApartmentAdminStore => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      accessSettings: {
        ...state.userDetail.accessSettings,
        rfid: {
          ...getInitialApartmentAdminStore().userDetail.accessSettings.rfid,
          isFetching: true,
        },
      },
    },
  }),

  [types.GET_USER_RFID_FAILURE]: (state, error: IError): IApartmentAdminStore => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      accessSettings: {
        ...state.userDetail.accessSettings,
        rfid: {
          ...getInitialApartmentAdminStore().userDetail.accessSettings.rfid,
          error: error,
          isFetching: false,
        },
      },
    },
  }),

  [types.GET_USER_RFID_SUCCESS]: (state, payload: IRfid[]): IApartmentAdminStore => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      accessSettings: {
        ...state.userDetail.accessSettings,
        rfid: {
          ...getInitialApartmentAdminStore().userDetail.accessSettings.rfid,
          data: getUserRfid(payload),
          isFetching: false,
        },
      },
    },
  }),
};

export function apartmentAdminReducers<TValue extends CommonTypes>(
  state = getInitialApartmentAdminStore(),
  action: IReduxAction<TValue>
): IApartmentAdminStore {
  if (reducers[action.type]) {
    if (action.error !== undefined) {
      return reducers[action.type](state, action.error);
    }
    return reducers[action.type](state, action.payload);
  }

  return state;
}
