import { createSelector } from 'reselect';

const getAccessLogDetail = (state) => state.accessLog.detail;

export const isAccessLogsListLoading = (state) => state.accessLog.list.isFetching;

export const getAccessLogsList = (state) => state.accessLog.list;

export const getAccessLogsListData = (state) => state.accessLog.list.data || [];

export const getAccessLogDetailData = createSelector(
  getAccessLogDetail,
  (accessLogDetail) =>
    accessLogDetail.data || {
      apartments: [],
      credentials: [],
      device: { name: '', sn: '', timezoneShift: 0 },
      direction: '',
      endAt: '',
      id: '',
      result: '',
      startAt: '',
      user: { id: '', name: '' },
    }
);

export const getAccessLogDetailIsFetching = createSelector(
  getAccessLogDetail,
  (accessLogDetail) => accessLogDetail.isFetching
);
