import { defineMessages } from 'react-intl';

export default defineMessages({
  footerCookies: {
    defaultMessage: 'Cookies policy',
    description: 'Cookies policy',
    id: 'footer.cookies',
  },
  footerHeadline: {
    defaultMessage: '© 2016-{yearTo} 2N Telekomunikace a.s. All rights reserved.',
    description: '© 2016-2022 2N Telekomunikace a.s. All rights reserved.',
    id: 'footer.headline',
  },
  footerPrivacy: {
    defaultMessage: 'Personal data protection',
    description: 'Personal data protection',
    id: 'footer.privacy',
  },
  footerTerms: {
    defaultMessage: 'Terms and Conditions',
    description: 'Terms and Conditions',
    id: 'footer.terms',
  },
  landingInfoServicesApi: {
    defaultMessage: '2N<sup>®</sup> Partner API',
    description: 'Landing Info - service api',
    id: 'landingInfo.services.api',
  },
  landingInfoServicesMv: {
    defaultMessage: 'My2N App',
    description: 'Landing Info - service mv',
    id: 'landingInfo.services.mv',
  },
  landingInfoServicesRc: {
    defaultMessage: '2N<sup>®</sup> Remote Configuration',
    description: 'Landing Info - service rc',
    id: 'landingInfo.services.rc',
  },
  landingInfoServicesSite: {
    defaultMessage: 'Site Management',
    description: 'Landing Info - service site',
    id: 'landingInfo.services.site',
  },
  landingInfoTitle: {
    defaultMessage: 'Explore the spectacular <strong>2N services</strong>',
    description: 'Landing Info - title',
    id: 'landingInfo.title',
  },
  landingMainDescription: {
    defaultMessage: 'with My2N trusted cloud platform',
    description: 'Landing Main - description',
    id: 'landingMain.description',
  },
  landingMainRegister: {
    defaultMessage: 'Create My2N account',
    description: 'Landing Main - create account button',
    id: 'landingMain.register',
  },
  landingMainTitle: {
    defaultMessage: 'Easily manage installation{nbsp}Sites, 2N products and services',
    description: 'Landing Main - title',
    id: 'landingMain.title',
  },
  listNoNeed: {
    defaultMessage: 'Invoice each Site separately',
    description: 'Services - no need list item',
    id: 'services.sites.list.noNeed',
  },
  listProvideAssistance: {
    defaultMessage: 'Manage multiple Sites within one My2N Company account',
    description: 'Services - provide assistance list item',
    id: 'services.sites.list.provideAssistance',
  },
  listSaveCosts: {
    defaultMessage: 'Create a separate Site for each installation project',
    description: 'Services - save costs list item',
    id: 'services.sites.list.saveCosts',
  },
  MoreInfo: {
    defaultMessage: 'Get more info',
    description: '',
    id: 'services.moreInfo',
  },
  newsDescription: {
    defaultMessage: 'We have many plans for My2N services. Keep in touch with us.',
    description: '',
    id: 'news.description',
  },
  newsProductboard: {
    defaultMessage: 'View us on Product Board >',
    description: 'View us on Product Board >',
    id: 'news.productboard',
  },
  newsTitle: {
    defaultMessage: "What's new",
    description: 'News - title',
    id: 'news.title',
  },
  remoteConfigurationListNoNeed: {
    defaultMessage: 'No need of complicated network configuration',
    description: 'Services - no need list item',
    id: 'services.remoteConfiguration.list.noNeed',
  },
  remoteConfigurationListProvideAssistance: {
    defaultMessage: 'Provide immediate assistance to your customers',
    description: 'Services - provide assistance list item',
    id: 'services.remoteConfiguration.list.provideAssistance',
  },
  remoteConfigurationListSaveCosts: {
    defaultMessage: 'Save costs of travelling to the installation site',
    description: 'Services - save costs list item',
    id: 'services.remoteConfiguration.list.saveCosts',
  },
  remoteConfigurationMoreInfo: {
    defaultMessage: 'Get more info',
    description: 'Services - get more info button',
    id: 'services.remoteConfiguration.moreInfo',
  },
  remoteConfigurationTitle: {
    defaultMessage:
      'Configure 2N products{br}over the internet with' + '{br}<strong>2N<sup>®</sup> Remote Configuration</strong>',
    description: '',
    id: 'services.remoteConfiguration.title',
  },
  servicesApiListAvailable: {
    defaultMessage: 'Manage 2N products from your software',
    description: 'Services - available list item',
    id: 'services.api.list.available',
  },
  servicesApiListUnlock: {
    defaultMessage: 'Keep the highest cybersecurity standards',
    description: 'Services - unlock list item',
    id: 'services.api.list.unlock',
  },
  servicesApiListVideoCall: {
    defaultMessage: 'Enhance your apps with trusted 2N services',
    description: 'Services - video call list item',
    id: 'services.api.list.videoCall',
  },
  servicesApiTitle: {
    defaultMessage:
      'Integrate easily 2N devices{br}with the 3rd party solutions{br}' +
      'online using <strong>2N<sup>®</sup> Partner API</strong>',
    description: '',
    id: 'services.api.title',
  },
  servicesListAvailable: {
    defaultMessage: 'Available for Android and iOS mobile devices',
    description: 'Services - available list item',
    id: 'services.mobileVideo.list.available',
  },
  servicesListUnlock: {
    defaultMessage: 'Unlock your door remotely',
    description: 'Services - unlock list item',
    id: 'services.mobileVideo.list.unlock',
  },
  servicesListVideoCall: {
    defaultMessage: 'Video call from IP intercom to your smartphone',
    description: 'Services - video call list item',
    id: 'services.mobileVideo.list.videoCall',
  },
  servicesMoreInfo: {
    defaultMessage: 'Get more info',
    description: 'Services - get more info button',
    id: 'services.mobileVideo.moreInfo',
  },
  servicesTitle: {
    defaultMessage: 'Keep up with your visitors{br}' + 'anywhere you are with{br}' + '<strong>My2N App</strong>',
    description: 'Services - mobile video title',
    id: 'services.mobileVideo.title',
  },
  stepsActivateServices: {
    defaultMessage: '<i>3</i> Start using My2N services',
    description: 'Steps - Add 2N products',
    id: 'steps.activateServices',
  },
  stepsAddProducts: {
    defaultMessage: '<i>2</i> Add 2N products',
    description: 'Steps - Add 2N products',
    id: 'steps.addProducts',
  },
  stepsCreateAccount: {
    defaultMessage: '<i>1</i> Create My2N account',
    description: 'Steps - Create My2N account',
    id: 'steps.createAccount',
  },
  stepsTitle: {
    defaultMessage: 'Effortless launch in <strong>3 steps</strong>',
    description: 'Steps - title',
    id: 'steps.title',
  },
  title: {
    defaultMessage: 'Multiple installation{br}<strong>Sites management</strong>',
    description: 'Multiple installation{br}<strong>Sites management</strong>',
    id: 'services.sites.title',
  },
});
