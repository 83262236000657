import { defineMessages } from 'react-intl';

export default defineMessages({
  siteUsersImportIntroStepAbout: {
    defaultMessage:
      'You can import users including their apartments.<p>Use the XLS template to make import easier.</p>',
    description: 'Site users import - intro step About',
    id: 'siteUsersImportIntroStepAbout',
  },
  siteUsersImportIntroStepAboutTitle: {
    defaultMessage: 'About import',
    description: 'Site users import template section About title',
    id: 'siteUsersImportIntroStepAboutTitle',
  },
  siteUsersImportIntroStepDetailedInfo: {
    defaultMessage:
      'Importing a complex site structure requires good data preparation and an understanding of specific rules. These resources will help you:' +
      '<ul><li><LinkModal>Import rules quick help</LinkModal></li>' +
      '<li><LinkTemplate>Pre-filled example spreadsheet</LinkTemplate></li></ul>',
    description: 'Site users import detailed section info',
    id: 'siteUsersImportIntroStepDetailedInfo',
  },
  siteUsersImportIntroStepDetailedTitle: {
    defaultMessage: 'Detailed information',
    description: 'Site users import detailed section title',
    id: 'siteUsersImportIntroStepDetailedTitle',
  },
  siteUsersImportIntroStepHelpModalButton: {
    defaultMessage: 'OK',
    description: 'Site users import - intro step help modal button',
    id: 'siteUsersImportIntroStepHelpModalButton',
  },
  siteUsersImportIntroStepHelpModalText: {
    defaultMessage:
      '<strong>User data</strong>' +
      '<ul><li>All data fields are required for site users.</li>' +
      '<li>The email serves as a unique identifier of the user. It cannot be changed later.</li></ul>' +
      '<strong>Apartment data</strong>' +
      '<ul><li>Floor and apartment number are required. Apartment name is optional.</li>' +
      '<li>The unique apartment identifier is a combination of floor and apartment number.</li></ul>' +
      '<strong>Import rules for newly created users</strong>' +
      '<ul><li>Each new user will receive an email notification asking them to verify their account..</li>' +
      '<li>A new user can be set an apartment (existing or created by import).</li></ul>' +
      '<strong>Import rules for existing users</strong>' +
      "<ul><li>It's not possible to make changes to existing users and their attributes. Lines that contain existing users are skipped.</li>" +
      '<li>If there are multiple identical users in the import, only the first one is imported.</li>' +
      '<li>For exceptional cases: if a user is located in another site or company within My2N, ' +
      'then they will be imported with their original name and last name. It can’t be changed by importing.</li></ul>',
    description: 'Site users import - intro step help modal text',
    id: 'siteUsersImportIntroStepHelpModalText',
  },
  siteUsersImportIntroStepHelpModalTitle: {
    defaultMessage: 'Import rules help',
    description: 'Site users import - intro step help modal title',
    id: 'siteUsersImportIntroStepHelpModalTitle',
  },
  siteUsersImportIntroStepStepsStep1: {
    defaultMessage: 'Prepare data (XLS, CSV, spreadsheet)',
    description: 'Site users import - intro step step1',
    id: 'siteUsersImportIntroStepStepsStep1',
  },
  siteUsersImportIntroStepStepsStep2: {
    defaultMessage: 'Upload data file (or copy and paste it)',
    description: 'Site users import - intro step step2',
    id: 'siteUsersImportIntroStepStepsStep2',
  },
  siteUsersImportIntroStepStepsStep3: {
    defaultMessage: 'Check data for accuracy',
    description: 'Site users import - intro step step3',
    id: 'siteUsersImportIntroStepStepsStep3',
  },
  siteUsersImportIntroStepStepsStep4: {
    defaultMessage: 'Start import',
    description: 'Site users import - intro step step4',
    id: 'siteUsersImportIntroStepStepsStep4',
  },
  siteUsersImportIntroStepStepsStep5: {
    defaultMessage: 'See result',
    description: 'Site users import - intro step step5',
    id: 'siteUsersImportIntroStepStepsStep5',
  },
  siteUsersImportIntroStepStepsTitle: {
    defaultMessage: 'How to import',
    description: 'Site users import - intro step - steps title',
    id: 'siteUsersImportIntroStepStepsTitle',
  },
  siteUsersImportIntroStepTemplateButton: {
    defaultMessage: 'Download template',
    description: 'Site users import - intro step template button',
    id: 'siteUsersImportIntroStepTemplateButton',
  },
  siteUsersImportIntroStepWarning: {
    defaultMessage:
      'Importing allows you to add data, not edit it. Therefore, it is best suited for initial site setup.',
    description: 'Site users import - intro step warning',
    id: 'siteUsersImportIntroStepWarning',
  },
});
