import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../../messages';

const styles = {
  inputLabel: {
    mb: {
      sm: 0,
      xs: 0.5,
    },
  },
  textField: {
    '& label': {
      fontWeight: 'normal',
    },
    minWidth: 250,
  },
};

const PurchaseOrderInput = ({ number, onInputChange }) => (
  <Grid item lg={9} xs={12}>
    <Grid alignItems="center" container direction="row" justifyContent="flex-start">
      <Grid item sm={4} sx={styles.inputLabel} xs={12}>
        <Typography color="text.secondary" variant="subtitle2">
          <FormattedMessage {...messages.billingInfoLabel} />
        </Typography>
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          fullWidth
          InputLabelProps={{ shrink: !!number }}
          label={<FormattedMessage {...messages.billingInfoPlaceholder} />}
          onChange={onInputChange}
          size="small"
          sx={styles.textField}
          value={number || ''}
          variant="outlined"
        />
      </Grid>
    </Grid>
  </Grid>
);

PurchaseOrderInput.propTypes = {
  number: PropTypes.string,
  onInputChange: PropTypes.func.isRequired,
};

PurchaseOrderInput.defaultProps = {
  number: null,
};

export default PurchaseOrderInput;
