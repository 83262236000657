import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SiteUsersDataGrid, UserModal } from '../../components';
import SiteUsersImportAlertStatus from '../../components/SiteUsersImportAlertStatus';
import ImportStatusModal from '../../components/SiteUsersImportAlertStatus/ImportStatusModal';
import { useUrlParams } from '../../helpers/urlParams';
import { useModal } from '../../hooks/useModal';
import commonActions from '../../modules/common';
import { VisitorModalContext } from '../../modules/commonTypes';
import { getSiteData } from '../../modules/sites/selectors';
import actions, { actionsTS, CONTEXT, selectors } from '../../modules/user';
import VisitorModal from '../../pages/apartmentAdmin/ApartmentAdminUsersOverviewPage/components/VisitorModal/VisitorModal';
import Tools from './Tools';

const SiteUsers = () => {
  const { apartmentId, companyId, siteId } = useUrlParams();
  const [addUserModal, toggleUserModal] = useState(false);
  const { Modal, onOpen } = useModal({ Modal: ImportStatusModal });
  const dispatch = useDispatch();
  const { currentImportData, currentImportRunning, siteUsersDataGrid } = {
    currentImportData: useSelector(selectors.currentImportUsersData),
    currentImportRunning: useSelector(selectors.currentImportUsersRunning),
    siteUsersDataGrid: useSelector(selectors.getSiteUsersDataGridProps),
  };
  const siteData = useSelector(getSiteData);
  const { Modal: VisitorUModal, onOpen: onOpenVisitorUModal } = useModal({
    Modal: VisitorModal,
    onClose: () => {
      dispatch(
        actionsTS.getSiteUsersList(
          companyId,
          siteId,
          siteUsersDataGrid.page,
          siteUsersDataGrid.rowsPerPage,
          siteUsersDataGrid.filter,
          siteUsersDataGrid.order
        )
      );
    },
  });

  return (
    <>
      <Tools
        companyId={companyId}
        currentImportRunning={currentImportRunning}
        onAddUser={() => toggleUserModal(true)}
        onAddVisitor={onOpenVisitorUModal}
        onLoadUsers={(...callbackArgs) => dispatch(actionsTS.getSiteUsersList(...callbackArgs))}
        onSearch={(...callbackArgs) => dispatch(commonActions.search(...callbackArgs))}
        siteId={siteId}
        siteUsersDataGrid={siteUsersDataGrid}
      />
      <SiteUsersImportAlertStatus
        companyId={companyId}
        currentImportData={currentImportData}
        didInvalid={siteUsersDataGrid.didInvalid}
        onLoadImportStatus={(...callbackArgs) => dispatch(actions.getImportUsersStatus(...callbackArgs))}
        onShowImportModal={onOpen}
        siteId={siteId}
      />
      <SiteUsersDataGrid
        {...siteUsersDataGrid}
        companyId={companyId}
        onLoadUsers={(...callbackArgs) => dispatch(actionsTS.getSiteUsersList(...callbackArgs))}
        siteId={siteId}
      />
      {addUserModal && (
        <UserModal
          context={CONTEXT.SITE_USERS}
          onClose={() => toggleUserModal(false)}
          open={addUserModal}
          showApartmentField
        />
      )}
      <Modal maxWidth="md" />
      <VisitorUModal
        msgContext={VisitorModalContext.USER_LIST_NEW_GUESTMODAL}
        apartmentInfo={{ apartmentId, companyId, siteId }}
        siteName={siteData?.name}
        isUserList
      />
    </>
  );
};

export default SiteUsers;
