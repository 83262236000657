import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React, { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import OutlinedTextField from '../../../../components/HookFormFields/TextField';
import { isIdt2 } from '../../../../helpers/devices';
import { IFormData } from '../validationSchema';
import messages from './messages';

export const FAQ_IDT = 'https://faq.2n.cz/pages/viewpage.action?pageId=85885089';

export function IdtIdentifier() {
  const { watch } = useFormContext<IFormData>();
  const deviceType = watch('deviceType');
  const isTypeIdt2 = useMemo(() => isIdt2(deviceType), [deviceType]);

  return (
    <>
      <Typography paragraph variant="body2">
        <FormattedMessage
          {...(isTypeIdt2 ? messages.idtIdentifierDescriptionIdt2 : messages.idtIdentifierDescriptionIdt1)}
          values={{
            br: <br />,
            sup: (chunks) => <sup>{chunks}</sup>,
          }}
        />
      </Typography>
      <OutlinedTextField
        helperText={
          <FormattedMessage {...(isTypeIdt2 ? messages.idtIdentifierExampleIdt2 : messages.idtIdentifierExampleIdt1)} />
        }
        label={
          <FormattedMessage {...(isTypeIdt2 ? messages.idtIdentifierLabelIdt2 : messages.idtIdentifierLabelIdt1)} />
        }
        name="identifier"
        fullWidth
      />
      {isTypeIdt2 && (
        <Typography sx={{ mt: 1 }} variant="body2">
          <FormattedMessage
            {...messages.idtIdentifierFaq}
            values={{
              url: (chunks) => (
                <Link href={FAQ_IDT} rel="noopener noreferrer" target="_blank">
                  {chunks}
                </Link>
              ),
            }}
          />
        </Typography>
      )}
    </>
  );
}
