import { defineMessages } from 'react-intl';

export default defineMessages({
  noCompanyBoxCreateCompany: {
    defaultMessage: 'Create company',
    description: 'User profile - create company button',
    id: 'noCompanyBox.createCompany',
  },
  noCompanyBoxDescription: {
    defaultMessage:
      'Contact the administrator of your company to regain access, or create your own. <link>What is a company?</link>',
    description: 'User profile - no company box alert - description',
    id: 'noCompanyBox.description',
  },
  noCompanyBoxTitle: {
    defaultMessage: 'You’re no longer a member of any company in My2N',
    description: 'User profile - no company box alert - title',
    id: 'noCompanyBox.title',
  },
});
