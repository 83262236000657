import accessUnitSvg from './icons/accessUnit.svg';
import accessUnit2Svg from './icons/accessUnit2.svg';
import accessUnitMSvg from './icons/accessUnitM.svg';
import audioConverterSvg from './icons/audioConverter.svg';
import auqrSvg from './icons/auqr.svg';
import clipSvg from './icons/clip.svg';
import easyGateIpSvg from './icons/easyGateIp.svg';
import easyGateIpMSvg from './icons/easyGateIpM.svg';
import indoorCompactSvg from './icons/indoorCompact.svg';
import indoorTalkSvg from './icons/indoorTalk.svg';
import indoorTouchSvg from './icons/indoorTouch.svg';
import indoorTouch2Svg from './icons/indoorTouch2.svg';
import indoorViewSvg from './icons/indoorView.svg';
import ipAudioKitSvg from './icons/ipAudioKit.svg';
import ipBaseSvg from './icons/ipBase.svg';
import ipForceSvg from './icons/ipForce.svg';
import ipOneIntercomSvg from './icons/ipOneIntercom.svg';
import ipSafetySvg from './icons/ipSafety.svg';
import ipSoloSvg from './icons/ipSolo.svg';
import ipStyleSvg from './icons/ipStyle.svg';
import ipUniSvg from './icons/ipUni.svg';
import ipVarioSvg from './icons/ipVario.svg';
import versoSvg from './icons/ipVerso.svg';
import ipVideoKitSvg from './icons/ipVideoKit.svg';
import liftGateSvg from './icons/liftGate.svg';
import liftIp2Svg from './icons/liftIp2.svg';
import sipSpeakerSvg from './icons/sipSpeaker.svg';
import unknownSvg from './icons/unknown.svg';

export const getSvgIcon = (type) => {
  /* eslint-disable quote-props */
  const generic = {
    '2.0': ipVarioSvg,
    2.3: ipForceSvg,
    2.5: ipSafetySvg,
    2.7: ipUniSvg,
    2.9: audioConverterSvg,
    '2.10': ipAudioKitSvg,
    2.12: ipVideoKitSvg,
    2.14: versoSvg,
    2.16: accessUnitSvg,
    2.18: sipSpeakerSvg,
    2.19: ipBaseSvg,
    2.21: ipSoloSvg,
    2.25: indoorTalkSvg,
    2.26: indoorCompactSvg,
    2.27: accessUnitMSvg,
    2.28: accessUnit2Svg,
    2.29: liftIp2Svg,
    '2.30': indoorViewSvg,
    2.31: ipStyleSvg,
    2.32: versoSvg,
    2.33: ipOneIntercomSvg,
    2.34: clipSvg,
    2.35: auqrSvg,
    2.38: indoorViewSvg,
    3.1: indoorTouchSvg,
    3.2: indoorTouch2Svg,
    5.1: easyGateIpSvg,
    5.2: easyGateIpMSvg,
    5.3: liftGateSvg,
  };
  /* eslint-enable quote-props */

  const genericType = type ? type.split('.').slice(0, 2).join('.') : null;
  if (generic[genericType]) {
    return generic[genericType];
  }
  return unknownSvg;
};
