import { IAggregatedApartmentUsers } from '../../../../../modules/apartmentAdmin/data';

const skeletonTablesCount = 1;

export function getSkeletonDataOrFetchedData({
  fetchedApartmentUsers,
  hasError,
  isFetching,
}: {
  fetchedApartmentUsers: IAggregatedApartmentUsers[] | undefined;
  isFetching: boolean;
  hasError: boolean;
}): IAggregatedApartmentUsers[] | undefined[] {
  if (hasError) {
    return [];
  }
  if (isFetching) {
    return new Array(skeletonTablesCount).fill(undefined).map((v) => v);
  }

  if (!fetchedApartmentUsers) {
    return [];
  }
  return fetchedApartmentUsers;
}
