import { defineMessages } from 'react-intl';

export default defineMessages({
  AAmodalDeleteGuestQrCancel: {
    defaultMessage: 'Cancel',
    id: 'AAmodal.deleteGuestQR.cancel',
  },
  AAmodalDeleteGuestQrSubmit: {
    defaultMessage: 'Delete PIN',
    id: 'AAmodal.deleteGuestQR.submit',
  },
  AAmodalDeleteGuestQrText: {
    defaultMessage:
      'When you delete the access code, its user won’t be able to use it for access. The access code is also deleted for other apartments the user may be member of.',
    id: 'AAmodal.deleteGuestQR.text',
  },
  AAmodalDeleteGuestQrText1: {
    defaultMessage: 'When you delete the PIN code, the user won’t be able to use it for access.',
    id: 'AAmodal.deleteGuestQR.text1',
  },
  AAmodalDeleteGuestQrText2: {
    defaultMessage:
      'The PIN is also deleted for other apartments the user may be in.<p>If the user has other access methods available, they can continue using them.</p>',
    id: 'AAmodal.deleteGuestQR.text2',
  },
  AAmodalDeleteGuestQrTitle: {
    defaultMessage: 'Delete PIN',
    id: 'AAmodal.deleteGuestQR.title',
  },
});
