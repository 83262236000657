import { defineMessages } from 'react-intl';

export default defineMessages({
  siteLanguageAlert: {
    defaultMessage:
      'The default site language will be set only for <b>new users</b>. ' +
      'The language will remain unchanged for your existing users.' +
      '{br}All users can select their preferred language at any time.',
    description: 'Site language - alert message',
    id: 'siteLanguage.alert',
  },
  siteLanguageChangeLanguageButton: {
    defaultMessage: 'Change language',
    description: 'Site language - change language button',
    id: 'siteLanguage.changeLanguageButton',
  },
  siteLanguageConfirmationModalCancel: {
    defaultMessage: 'Cancel',
    description: 'Site language - confirmation modal cancel button',
    id: 'siteLanguage.confirmationModalCancel',
  },
  siteLanguageConfirmationModalContent: {
    defaultMessage: 'Do you want to change the default language for all new users?',
    description: 'Site language - confirmation modal content',
    id: 'siteLanguage.confirmationModalContent',
  },
  siteLanguageConfirmationModalSubmit: {
    defaultMessage: 'Change language',
    description: 'Site language - confirmation modal submit button',
    id: 'siteLanguage.confirmationModalSubmit',
  },
  siteLanguageConfirmationModalTitle: {
    defaultMessage: 'Change site language',
    description: 'Site language - confirmation modal title',
    id: 'siteLanguage.confirmationModalTitle',
  },
  siteLanguageHeader: {
    defaultMessage: 'Set the language you want the site to communicate with your site users/My2N app users.',
    description: 'Site language - header',
    id: 'siteLanguage.header',
  },
  siteLanguageTitle: {
    defaultMessage: 'Site language',
    description: 'Site language - title',
    id: 'siteLanguage.title',
  },
});
