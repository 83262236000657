import FilterNoneIcon from '@mui/icons-material/FilterNone';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const VIEW = {
  ALL: 'all',
  ONLY_CODES: 'only_codes',
};

const styles = {
  copyButton: {
    '&.MuiButton-root': {
      float: 'right',
      mt: 1,
    },
  },
  input: {
    '& .MuiInputBase-root': {
      '& textarea': {
        fontFamily: 'monospace',
        whiteSpace: 'pre',
      },
    },
  },
  root: {
    mx: 0,
    my: 1,
  },
};

const getHeaderInfo = (userView) =>
  userView
    ? 'CODE\t\t\tCREATION\tVALID_TO\tCRED\tSTATE\tCLOSED_AT\tCOM_ID\tSITE_ID\tCOMPANY\t\tSITE\n'
    : 'CODE\t\t\tCREATION\tVALID_TO\tCRED\tSTATE\n';

const getInfoData = (data, userView) => {
  const getDate = (date) => new Date(date).toISOString().split('T')[0];
  return data
    .map((item) => {
      const basicData = [item.code, getDate(item.dateCreated), getDate(item.validityTo), item.credit, item.state];

      let extendData = [];
      if (userView) {
        extendData = [
          getDate(item.closedAt),
          item?.context?.company?.id,
          item?.context?.site?.id,
          item?.context?.company?.name,
          item?.context?.site?.name,
        ];
      }

      return [...basicData, ...extendData].join('\t');
    })
    .join('\n');
};

const CodesTextCopyField = ({ data, defaultView, usedView }) => {
  const [view, setView] = useState(defaultView);

  let text = null;
  if (view === VIEW.ONLY_CODES) {
    text = data.map((item) => item.code).join('\n');
  } else if (view === VIEW.ALL) {
    text = getHeaderInfo(usedView);
    text += getInfoData(data, usedView);
  }

  const copyHandler = (event) => {
    event.preventDefault();
    copy(text);
  };

  return (
    <Box sx={styles.root}>
      <RadioGroup onChange={(event) => setView(event.target.value)} row value={view}>
        <FormControlLabel
          control={<Radio color="primary" />}
          label={<FormattedMessage {...messages.codesTextCopyFieldOnlyCodes} />}
          value={VIEW.ONLY_CODES}
        />
        <FormControlLabel
          control={<Radio color="primary" />}
          label={<FormattedMessage {...messages.codesTextCopyFieldAllInformation} />}
          value={VIEW.ALL}
        />
      </RadioGroup>
      <TextField
        fullWidth
        InputProps={{
          maxRows: 10,
          minRows: 10,
          readOnly: true,
        }}
        multiline
        sx={styles.input}
        value={text}
        variant="outlined"
      />
      <Button
        color="primary"
        onClick={copyHandler}
        size="small"
        startIcon={<FilterNoneIcon />}
        sx={styles.copyButton}
        variant="outlined"
      >
        <FormattedMessage {...messages.codesTextCopyFieldCopyButton} />
      </Button>
    </Box>
  );
};

CodesTextCopyField.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      closedAt: PropTypes.string,
      code: PropTypes.string,
      context: PropTypes.shape({
        company: PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          name: PropTypes.string,
        }),
        site: PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          name: PropTypes.string,
        }),
      }),
      credit: PropTypes.number,
      dateCreated: PropTypes.string,
      state: PropTypes.string,
      validityTo: PropTypes.string,
    })
  ).isRequired,
  defaultView: PropTypes.oneOf(Object.values(VIEW)),
  usedView: PropTypes.bool,
};

CodesTextCopyField.defaultProps = {
  defaultView: VIEW.ONLY_CODES,
  usedView: false,
};

export default CodesTextCopyField;
