import { Location, matchPath } from 'react-router-dom';

export function isLocationInActivePaths(activePaths: string[], location: Location) {
  let selected = false;
  activePaths.forEach((activePath) => {
    if (selected) {
      return;
    }
    selected = !!matchPath(activePath, location.pathname);
  });
  return selected;
}
