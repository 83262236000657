import { defineMessages } from 'react-intl';

export default defineMessages({
  formActionBackBtn: {
    defaultMessage: 'Back',
    description: 'Form action - back button',
    id: 'hipmo.second.formAction.backBtn',
  },
  formActionCancelBtn: {
    defaultMessage: 'Cancel',
    description: 'Form action - cancel button',
    id: 'hipmo.second.formAction.cancelBtn',
  },
  formActionNextBtn: {
    defaultMessage: 'Next',
    description: 'Form action - next button',
    id: 'hipmo.second.formAction.nextBtn',
  },
  // description2: {
  //   owner: {
  //     id: 'twoStep.description2.owner',
  //     description: 'Two step - description owner view #2',
  //     defaultMessage: (
  //       'Launch the application on your mobile phone and scan this QR code with it,'
  //       + ' which will connect your mobile phone with your My2N account'
  //     ),
  //   },
  //   user: {
  //     id: 'twoStep.description2.user',
  //     description: 'Two step - description user view #2',
  //     defaultMessage: (
  //       'The following QR code is for {fullname}. The scan connects his mobile phone to'
  //       + ' the My2N account. The QR code was also sent to him by e-mail.'
  //     ),
  //   },
  // },
  twoStepAndroidLabel: {
    defaultMessage: 'Install My2N on Android',
    description: 'Two step - Android label',
    id: 'twoStep.android.label',
  },
  twoStepDescription1Description: {
    defaultMessage:
      "We've sent the user an email with credentials and instructions." +
      ' You can also find this information in the device detail.',
    description: 'Two step - description',
    id: 'twoStep.description1.description',
  },
  twoStepDescription1Owner: {
    defaultMessage: 'Install the My2N app on the device to get started.',
    description: 'Two step - description owner view #1',
    id: 'twoStep.description1.owner',
  },
  twoStepDescription1User: {
    defaultMessage:
      '{fullname} was sent an e-mail with a link to download the' + ' application and detailed instructions.',
    description: 'Two step - description user view #1',
    id: 'twoStep.description1.user',
  },
  twoStepIosLabel: {
    defaultMessage: 'Install My2N on iOS',
    description: 'Two step - iOS label',
    id: 'twoStep.ios.label',
  },
  twoStepTitle: {
    defaultMessage: 'Download mobile app',
    description: 'Download application - title',
    id: 'twoStep.title',
  },
  twoStepUserQrDescription: {
    defaultMessage:
      'Now you can log in to the My2N app on your smartphone or tablet.' +
      ' Scan the QR code after opening the app, or enter the credentials manually.',
    description: 'User QR - description',
    id: 'twoStep.userQr.description',
  },
  twoStepUserQrDeviceId: {
    defaultMessage: 'Device ID',
    description: 'Two step - User QR - device id label',
    id: 'twoStep.userQr.deviceId',
  },
  twoStepUserQrDevicePassword: {
    defaultMessage: 'Device password',
    description: 'Two step - User QR - device password label',
    id: 'twoStep.userQr.devicePassword',
  },
});
