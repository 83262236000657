import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ButtonDanger from '../ButtonDanger';
import Modal from '../Modal';
import messages from './messages';

const DangerModal = ({ actionButtonLabel, buttonIcon, children, isLoading, name, onClose, onSubmit, open, title }) =>
  open && (
    <Modal
      actions={
        <>
          <Button onClick={onClose}>
            <FormattedMessage {...messages.buttonCancel} />
          </Button>
          <ButtonDanger isLoading={isLoading} onClick={onSubmit} startIcon={buttonIcon || <DeleteIcon />} type="submit">
            {actionButtonLabel}
          </ButtonDanger>
        </>
      }
      fullWidth
      headerVariant="danger"
      maxWidth="xs"
      name={name}
      onClose={onClose}
      open={open}
      title={title}
    >
      {children}
    </Modal>
  );

DangerModal.propTypes = {
  actionButtonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  buttonIcon: PropTypes.element,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.any]).isRequired,
  isLoading: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

DangerModal.defaultProps = {
  buttonIcon: null,
  isLoading: false,
};

export default DangerModal;
