import { SERVICE } from '../../config/devices';
import { IRootStore } from '../store';
import { IDashboardStore, ISiteMobileVideoService } from './store';

export function didInvalidCurrentSite({ sites }: IRootStore): boolean | undefined {
  return sites.didInvalid;
}

export function getDashboardMvServiceLicenceModel({ sites }: IRootStore) {
  return getSiteMobileVideoService(sites.dashboard.data)?.licenceModel;
}

function getSiteMobileVideoService(dashboard?: IDashboardStore): ISiteMobileVideoService | undefined {
  return dashboard?.services?.[SERVICE.MOBILE_VIDEO];
}

export const isModalOpen = (state: IRootStore) => state.sites.editModal.show;

export const isModalDataFetching = (state: IRootStore) => state.sites.editModal.isFetching;
