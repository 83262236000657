import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import NoRowsOverlayDataGrid from '../DataGrid/NoRowsOverlay';
import messages from './messages';

const NoRowsOverlay = ({ onCreateGroup }) => (
  <NoRowsOverlayDataGrid
    action={
      <Button color="primary" onClick={onCreateGroup} startIcon={<AddIcon />} sx={{ mt: 3 }} variant="outlined">
        <FormattedMessage {...messages.accessSettingsDataGridNoRowsOverlayButton} />
      </Button>
    }
    headerText={<FormattedMessage {...messages.accessSettingsDataGridNoRowsOverlayHeaderText} />}
    text={
      <FormattedMessage
        {...messages.accessSettingsDataGridNoRowsOverlayText}
        values={{
          br: <br />,
        }}
      />
    }
  />
);

NoRowsOverlay.propTypes = {
  onCreateGroup: PropTypes.func.isRequired,
};

export default NoRowsOverlay;
