import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import DeviceTypeIcon from '../DeviceTypeIcon';

function DeleteDevicesList({ devices }) {
  return (
    <Box component="span" pt={1.5}>
      {devices.map((device) => (
        <Box
          component="span"
          key={device.deviceId}
          sx={{
            alignItems: 'center',
            display: 'flex',
            px: 2,
          }}
        >
          <DeviceTypeIcon deviceType={device?.deviceType} small />
          <Typography
            component="span"
            noWrap
            sx={{
              fontWeight: 'bold',
              pl: 2,
            }}
            variant="body2"
          >
            {device.deviceName}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}

DeleteDevicesList.propTypes = {
  devices: PropTypes.arrayOf(
    PropTypes.shape({
      deviceId: PropTypes.string,
      deviceName: PropTypes.string,
      deviceType: PropTypes.string,
    })
  ).isRequired,
};

export default DeleteDevicesList;
