import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import mvideoSvg from '../../../assets/mvideo.svg';
import serviceAc from '../../../assets/service-ac.svg';
import serviceRc from '../../../assets/service-rc.svg';
import { SERVICE } from '../../../config/devices';
import { isActiveService } from '../../../modules/devices/helpers/helpers';
import messages from './messages';

const tooltipStyle = {
  '& img': {
    height: 40,
    width: 40,
  },
  maxWidth: 200,
};

const disableTooltipStyle = {
  '& img': {
    filter: 'grayscale(100%)',
    height: 40,
    opacity: 0.38,
    width: 40,
  },
  maxWidth: 200,
};

const DeviceServices = ({ device }) => (
  <>
    <Box
      component="span"
      pr={1}
      sx={
        isActiveService(device, SERVICE.MOBILE_VIDEO) || isActiveService(device, SERVICE.LOCAL_CALLS)
          ? tooltipStyle
          : disableTooltipStyle
      }
    >
      <Tooltip
        title={
          <Box>
            <Box component="span" display="block">
              <FormattedMessage
                {...messages.deviceServicesMobileVideoTooltip}
                values={{ sup: (chunks) => <sup>{chunks}</sup> }}
              />
            </Box>
            <Box component="span" display="block">
              {isActiveService(device, SERVICE.MOBILE_VIDEO) || isActiveService(device, SERVICE.LOCAL_CALLS) ? (
                <FormattedMessage {...messages.deviceServicesActiveService} />
              ) : (
                <FormattedMessage {...messages.deviceServicesInactiveService} />
              )}
            </Box>
          </Box>
        }
      >
        <img alt="Mobile Video" src={mvideoSvg} />
      </Tooltip>
    </Box>
    <Box
      component="span"
      pr={1}
      sx={isActiveService(device, SERVICE.ACCESS_CONTROL) ? tooltipStyle : disableTooltipStyle}
    >
      <Tooltip
        title={
          <Box>
            <Box component="span" display="block">
              <FormattedMessage
                {...messages.deviceServicesAcTooltip}
                values={{ sup: (chunks) => <sup>{chunks}</sup> }}
              />
            </Box>
            <Box component="span" display="block">
              {isActiveService(device, SERVICE.ACCESS_CONTROL) ? (
                <FormattedMessage {...messages.deviceServicesActiveService} />
              ) : (
                <FormattedMessage {...messages.deviceServicesInactiveService} />
              )}
            </Box>
          </Box>
        }
      >
        <img alt="Residential access service" src={serviceAc} />
      </Tooltip>
    </Box>
    <Box
      component="span"
      sx={isActiveService(device, SERVICE.REMOTE_CONFIGURATION) ? tooltipStyle : disableTooltipStyle}
    >
      <Tooltip
        title={
          <Box>
            <Box component="span" display="block">
              <FormattedMessage
                {...messages.deviceServicesRemoteConfigurationTooltip}
                values={{ sup: (chunks) => <sup>{chunks}</sup> }}
              />
            </Box>
            <Box component="span" display="block">
              {isActiveService(device, SERVICE.REMOTE_CONFIGURATION) ? (
                <FormattedMessage {...messages.deviceServicesActiveService} />
              ) : (
                <FormattedMessage {...messages.deviceServicesInactiveService} />
              )}
            </Box>
          </Box>
        }
      >
        <img alt="Remote configuration service" src={serviceRc} />
      </Tooltip>
    </Box>
  </>
);

DeviceServices.propTypes = {
  device: PropTypes.shape({}).isRequired,
};

export default DeviceServices;
