import { QrCode, Warning } from '@mui/icons-material';
import MyProfileIcon from '@mui/icons-material/AccountCircle';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DialpadIcon from '@mui/icons-material/Dialpad';
import MyApartmentsIcon from '@mui/icons-material/FolderShared';
import { Alert, Box, Divider } from '@mui/material';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useGetApartmentDetailForAaQuery } from '../../../../../apiRtk/be4feApi';
import {
  useGetApartmentAdminCredentialsValidityQuery,
  useGetApartmentAdminPinSettingsQuery,
  useGetApartmentPinForUserQuery,
} from '../../../../../apiRtk/gatewayApi';
import { ReactComponent as TimeLimitedIcon } from '../../../../../assets/time-limited.svg';
import { CustomDate } from '../../../../../components';
import { isArrayEmpty } from '../../../../../helpers/array';
import { useModal } from '../../../../../hooks/useModal';
import { ApartmentUser } from '../../../../../modules/apartmentAdmin/data';
import {
  getApartmentAccessGroupsSelector,
  getUserAccessGroupsSelector,
} from '../../../../../modules/apartmentAdmin/selectors';
import { hasPIN, hasRFID } from '../../../../../modules/apartmentAdmin/utils';
import { getApartmentDevices } from '../../../../../modules/apartments/selectors';
import { IApartmentIdsInfo } from '../../../../../modules/apartments/store';
import { VisitorModalContext } from '../../../../../modules/commonTypes';
import { isUserGuest } from '../../../../../modules/user/utils';
import { palette } from '../../../../../theme';
import VisitorModal from '../../../ApartmentAdminUsersOverviewPage/components/VisitorModal/VisitorModal';
import { hasNoAccessGroup, isAccessSettingsWarningVisible } from './accessSettingsItemsVisibilityHelper';
import { AccessGroupList } from './components/AccessGroupList';
import { AccessSettingsHelpModal as AccessSettingsHelpModalComponent } from './components/AccessSettingsHelpModal';
import { DeletePinModal as DeletePinModalComponent } from './components/DeletePinModal';
import { DeleteQRModal as DeleteQRModalComponent } from './components/DeleteQRModal';
import { DeleteRfidModal as DeleteRFIDModalComponent } from './components/DeleteRFIDModal';
import { GeneratePinModal as GeneratePinModalComponent } from './components/GeneratePinModal';
import { IconWithContent } from './components/IconWithContent';
import { ShowPinModal as ShowPinModalComponent } from './components/ShowPinModal';
import { ShowQrModal as ShowQrModalComponent } from './components/ShowQrModal';
import { ShowRFIDModal as ShowRfidModalComponent } from './components/ShowRFIDModal';
import { UserAccessSettingsItem } from './components/UserAccessSettingsItem';
import { UWLDeletePinModal as UWLDeletePinModalComponent } from './components/UWLDeletePinModal';
import { UWLDeleteRFIDModal as UWLDeleteRfidModalComponent } from './components/UWLDeleteRFIDModal';
import messages from './messages';
import { getPinActions, getQrActions, getRfidActions, getValidityActions } from './pinAndRfidActions/pinAndRfidActions';
import { TestID } from './testIds';

export interface IAccessSettingsProps {
  apartmentIdsInfo: IApartmentIdsInfo;
  user: ApartmentUser;
}

export function UserDetailAccessSettings({ apartmentIdsInfo, user }: IAccessSettingsProps) {
  const { data: guestPinSettings } = useGetApartmentAdminPinSettingsQuery({
    apartmentId: apartmentIdsInfo.apartmentId.toString(),
    companyId: apartmentIdsInfo.companyId.toString(),
    siteId: apartmentIdsInfo.siteId.toString(),
  });
  const { formatMessage } = useIntl();
  const { data: apartmentDetail } = useGetApartmentDetailForAaQuery({
    apartmentId: apartmentIdsInfo.apartmentId,
    companyId: apartmentIdsInfo.companyId,
    siteId: apartmentIdsInfo.siteId,
  });
  const { data: apartmentAccessGroups } = useSelector(getApartmentAccessGroupsSelector);
  const { data: userAccessGroups } = useSelector(getUserAccessGroupsSelector);
  const apartmentDevices = useSelector(getApartmentDevices).data || [];
  const { Modal: AccessSettingsHelpModal, onOpen: openAccessSettingsHelpModal } = useModal({
    Modal: AccessSettingsHelpModalComponent,
  });
  const { Modal: ShowPinModal, onOpen: openShowPinModal } = useModal({ Modal: ShowPinModalComponent });
  const { Modal: ShowQrModal, onOpen: openShowQrModal } = useModal({ Modal: ShowQrModalComponent });
  const { Modal: ShowRfidModal, onOpen: openShowRfidModal } = useModal({ Modal: ShowRfidModalComponent });
  const { Modal: DeletePinModal, onOpen: openDeletePinModal } = useModal({ Modal: DeletePinModalComponent });
  const { Modal: DeleteQRModal, onOpen: openDeleteQRModal } = useModal({ Modal: DeleteQRModalComponent });
  const { Modal: DeleteRfidModal, onOpen: openDeleteRfidModal } = useModal({ Modal: DeleteRFIDModalComponent });
  const { Modal: UWLDeletePinModal, onOpen: openUWLDeletePinModal } = useModal({ Modal: UWLDeletePinModalComponent });
  const { Modal: UWLDeleteRfidModal, onOpen: openUWLDeleteRfidModal } = useModal({
    Modal: UWLDeleteRfidModalComponent,
  });
  const { Modal: GeneratePinModal, onOpen: openGeneratePinModal } = useModal({
    Modal: GeneratePinModalComponent,
  });
  const { Modal: VisitorUModal, onOpen: openShowVisitorModal } = useModal({
    Modal: VisitorModal,
  });
  const { Modal: VisitorRegenerateModal, onOpen: openVisitorRegenerateModal } = useModal({
    Modal: VisitorModal,
  });

  const { data: userCredentialsValidity, isSuccess: userCredentialsValidityIsSuccess } =
    useGetApartmentAdminCredentialsValidityQuery(
      {
        apartmentId: apartmentIdsInfo.apartmentId.toString(),
        companyId: apartmentIdsInfo.companyId.toString(),
        siteId: apartmentIdsInfo.siteId.toString(),
        userId: user?.id.toString() || '',
      },
      { refetchOnMountOrArgChange: true }
    );

  const { data: apartmentPinForUser } = useGetApartmentPinForUserQuery(
    {
      apartmentId: apartmentIdsInfo.apartmentId.toString(),
      companyId: apartmentIdsInfo.companyId.toString(),
      siteId: apartmentIdsInfo.siteId.toString(),
      userId: user.id.toString(),
    },
    { refetchOnMountOrArgChange: true, skip: !hasPIN(user) }
  );

  const isNoAccessGroupLabelVisible = hasNoAccessGroup({ apartmentAccessGroups, userAccessGroups });
  const isUserAccessGroupsSectionVisible = !isArrayEmpty(userAccessGroups);
  const isApartmentAccessGroupsSectionVisible = isNoAccessGroupLabelVisible || !isArrayEmpty(apartmentAccessGroups);
  const isVisitorValidityVisible = user.validFrom || user.validTo;

  return (
    <>
      {isAccessSettingsWarningVisible({ apartmentAccessGroups, user, userAccessGroups }) && (
        <UserAccessSettingsItem isWithoutTopPadding>
          {
            <Box sx={{ pb: 1 }}>
              <Alert severity="warning" icon={<Warning />}>
                <FormattedMessage {...messages.AAUserDetailAccessNoAccessGroupsCredentials} />
              </Alert>
            </Box>
          }
        </UserAccessSettingsItem>
      )}

      {isVisitorValidityVisible && (
        <UserAccessSettingsItem
          isWithoutTopPadding
          actions={getValidityActions({ formatMessage, openShowVisitorModal, openVisitorRegenerateModal, user })}
        >
          <IconWithContent Icon={TimeLimitedIcon} hasIconOffset>
            <Stack>
              {user.validFrom && (
                <div>
                  <FormattedMessage
                    {...messages.AAUserDetailAccessValidFrom}
                    values={{
                      typography: (chunk) => (
                        <Typography component="span" display="contents" color="secondary">
                          {chunk}
                        </Typography>
                      ),
                      validityStart: CustomDate({ dateValue: user.validFrom, showTime: true }),
                    }}
                  />
                </div>
              )}
              {user.validTo && (
                <div>
                  <FormattedMessage
                    {...messages.AAUserDetailAccessValidTo}
                    values={{
                      typography: (chunk) => (
                        <Typography component="span" display="contents" color="secondary">
                          {chunk}
                        </Typography>
                      ),
                      validityEnd: CustomDate({ dateValue: user.validTo, showTime: true }),
                    }}
                  />
                </div>
              )}
            </Stack>
          </IconWithContent>
        </UserAccessSettingsItem>
      )}

      {isUserAccessGroupsSectionVisible && (
        <UserAccessSettingsItem isWithoutTopPadding>
          <IconWithContent Icon={MyProfileIcon} hasIconOffset>
            <AccessGroupList groups={userAccessGroups!} />
          </IconWithContent>
        </UserAccessSettingsItem>
      )}

      {isApartmentAccessGroupsSectionVisible && (
        <UserAccessSettingsItem isWithoutTopPadding>
          <IconWithContent
            Icon={MyApartmentsIcon}
            isAlignCentered={isNoAccessGroupLabelVisible}
            hasIconOffset={!isArrayEmpty(apartmentAccessGroups)}
            textContent={
              isNoAccessGroupLabelVisible
                ? { content: formatMessage({ ...messages.AAUserDetailAccessNoAccessGroups }) }
                : undefined
            }
          >
            {!isArrayEmpty(apartmentAccessGroups) && (
              <AccessGroupList groups={apartmentAccessGroups!}></AccessGroupList>
            )}
          </IconWithContent>
        </UserAccessSettingsItem>
      )}

      <Divider />
      {isUserGuest(user.role) && guestPinSettings?.guestShowQr && (
        <UserAccessSettingsItem
          hasDivider
          actions={getQrActions({
            formatMessage,
            openDeleteQRModal,
            openGeneratePinModal,
            openShowQrModal,
            openVisitorRegenerateModal,
            user,
          })}
        >
          <IconWithContent
            isActive={hasPIN(user)}
            Icon={QrCode}
            textContent={{
              content: formatMessage({ ...messages.AAUserDetailAccessQR }),
              isBold: true,
            }}
          />
        </UserAccessSettingsItem>
      )}

      {userCredentialsValidityIsSuccess && (!isUserGuest(user.role) || !guestPinSettings?.guestShowQr) && (
        <UserAccessSettingsItem
          hasDivider
          actions={getPinActions({
            formatMessage,
            openDeletePinModal,
            openGeneratePinModal,
            openShowPinModal,
            openShowQrModal,
            openShowVisitorModal,
            openUWLDeletePinModal,
            openVisitorRegenerateModal,
            user,
          })}
        >
          <IconWithContent
            isActive={hasPIN(user)}
            Icon={DialpadIcon}
            textContent={{
              content: formatMessage({ ...messages.AAUserDetailAccessPIN }),
              isBold: true,
            }}
          />
        </UserAccessSettingsItem>
      )}

      {!isUserGuest(user.role) && (
        <UserAccessSettingsItem
          hasDivider
          actions={getRfidActions({
            formatMessage,
            openDeleteRfidModal,
            openShowRfidModal,
            openUWLDeleteRfidModal,
            user,
          })}
        >
          <IconWithContent
            isActive={hasRFID(user)}
            Icon={CreditCardIcon}
            textContent={{
              content: formatMessage({ ...messages.AAUserDetailAccessRFID }),
              isBold: true,
            }}
          />
        </UserAccessSettingsItem>
      )}

      <UserAccessSettingsItem>
        <Link
          component="button"
          onClick={openAccessSettingsHelpModal}
          test-id={TestID.OpenHelpModal}
          variant="body2"
          fontWeight={palette.fontWeight.bold}
        >
          <FormattedMessage {...messages.AAUserDetailAccessHelp} />
        </Link>
      </UserAccessSettingsItem>

      <AccessSettingsHelpModal />
      <ShowPinModal user={user} apartmentIdsInfo={apartmentIdsInfo} />
      <ShowQrModal qrUrl={apartmentPinForUser?.link ? apartmentPinForUser?.link : ''} />
      <ShowRfidModal user={user} apartmentIdsInfo={apartmentIdsInfo} />
      <DeletePinModal user={user} apartmentIdsInfo={apartmentIdsInfo} />
      <DeleteQRModal user={user} apartmentIdsInfo={apartmentIdsInfo} />
      <DeleteRfidModal user={user} apartmentIdsInfo={apartmentIdsInfo} />
      <UWLDeletePinModal user={user} apartmentIdsInfo={apartmentIdsInfo} />
      <UWLDeleteRfidModal user={user} apartmentIdsInfo={apartmentIdsInfo} />
      <GeneratePinModal user={user} apartmentIdsInfo={apartmentIdsInfo} />
      <VisitorUModal
        msgContext={VisitorModalContext.USER_ACCESS_VALIDITY_EDITMODAL}
        siteName={apartmentDetail?.siteName}
        apartmentDevices={apartmentDevices}
        apartmentInfo={apartmentIdsInfo}
        user={user}
        userCredentialsValidity={userCredentialsValidity}
      />
      <VisitorRegenerateModal
        msgContext={VisitorModalContext.AA_MODAL_NEW_GUEST_PIN}
        siteName={apartmentDetail?.siteName}
        apartmentDevices={apartmentDevices}
        apartmentInfo={apartmentIdsInfo}
        user={user}
        isRegenerate
      />
    </>
  );
}
