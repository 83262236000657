import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert, CustomDate, PrimaryValue } from '../../../components';
import { MOBILE_VIDEO_CODE } from '../../../config/consts';
import messages from './messages';

const CreditInfo = ({ codeCreditState, codeExpirationState, mobileVideoLicense }) => (
  <Alert severity="info">
    <Grid container direction="column">
      <Grid item>
        {codeExpirationState === MOBILE_VIDEO_CODE.EXPIRATION.NEXT_PAYMENT && (
          <FormattedMessage
            {...messages.creditInfoExpirationNextPayment}
            values={{
              poolExpiration: <CustomDate dateValue={mobileVideoLicense.poolExpiration} />,
            }}
          />
        )}
        {codeExpirationState === MOBILE_VIDEO_CODE.EXPIRATION.POOL_EXPIRATION && (
          <FormattedMessage
            {...messages.creditInfoExpirationPoolExpiration}
            values={{
              poolExpiration: <CustomDate dateValue={mobileVideoLicense.poolExpiration} />,
            }}
          />
        )}
        {codeExpirationState === MOBILE_VIDEO_CODE.EXPIRATION.VALIDITY && (
          <FormattedMessage
            {...messages.creditInfoExpirationValidity}
            values={{
              strong: (chunks) => <strong>{chunks}</strong>,
              validity: <CustomDate dateValue={mobileVideoLicense.validityTo} />,
            }}
          />
        )}
      </Grid>
      <Grid item>
        {codeCreditState.includes(MOBILE_VIDEO_CODE.CREDIT.EMPTY) && (
          <FormattedMessage {...messages.creditInfoCreditEmpty} />
        )}
        {codeCreditState.includes(MOBILE_VIDEO_CODE.CREDIT.REMAINING) && (
          <FormattedMessage
            {...messages.creditInfoCreditRemaining}
            values={{
              count: mobileVideoLicense.licencesPool,
              credit: <PrimaryValue value={mobileVideoLicense.licencesPool.toFixed(1)} />,
              strong: (chunks) => <strong>{chunks}</strong>,
            }}
          />
        )}
      </Grid>
    </Grid>
  </Alert>
);

CreditInfo.propTypes = {
  codeCreditState: PropTypes.arrayOf(PropTypes.string).isRequired,
  codeExpirationState: PropTypes.string.isRequired,
  mobileVideoLicense: PropTypes.shape({
    licencesPool: PropTypes.number,
    licensedCount: PropTypes.number,
    poolExpiration: PropTypes.string,
    validityTo: PropTypes.string,
  }).isRequired,
};

export default CreditInfo;
