import types from './types';

export const initialState = {
  license: {
    data: {},
    error: null,
    isFetching: false,
    paymentData: {},
  },
  licenseDetailModal: {
    data: {},
    show: false,
  },
  promoCodes: {
    data: {},
    error: null,
    isFetching: false,
    paymentData: {
      data: {},
      error: null,
      isFetching: false,
    },
  },
  purchasePaymentResult: null,
  vatVerification: {
    isFetching: false,
    isVerified: false,
  },
  videoMobileCode: {
    activation: {
      data: {},
      error: 0,
      isFetching: false,
    },
    code: '',
    data: {},
    error: null,
    isFetching: false,
  },
  videoMobileLicense: {
    data: null,
    didInvalid: false,
    error: null,
    isFetching: false,
    paymentData: {
      data: null,
      didInvalid: true,
      error: null,
      isFetching: false,
    },
    prices: {
      data: null,
      didInvalid: false,
      error: null,
      isFetching: false,
    },
  },
};

const reducers = {
  [types.GET_SERIAL_NUMBER_LICENSES_REQUEST]: (state) => ({
    ...state,
    license: {
      ...state.license,
      isFetching: true,
    },
  }),

  [types.GET_SERIAL_NUMBER_LICENSES_SUCCES]: (state, action) => ({
    ...state,
    license: {
      ...state.license,
      data: action.payload,
      isFetching: false,
    },
  }),

  [types.GET_SERIAL_NUMBER_LICENSES_FAILURE]: (state, action) => ({
    ...state,
    license: {
      ...state.license,
      error: action.error,
      isFetching: false,
    },
  }),

  [types.GET_PAYMENTS_REQUEST]: (state) => ({
    ...state,
    license: {
      ...state.license,
      isFetching: true,
    },
  }),

  [types.GET_PAYMENTS_SUCCESS]: (state, action) => ({
    ...state,
    license: {
      ...state.license,
      isFetching: false,
      paymentData: {
        ...state.license.paymentData,
        ...action.payload,
      },
    },
  }),

  [types.GET_PAYMENTS_FAILURE]: (state, action) => ({
    ...state,
    license: {
      ...state.license,
      error: action.error,
      isFetching: false,
    },
  }),

  [types.GET_VAT_VERIFICATION_REQUEST]: (state) => ({
    ...state,
    purchasePaymentResult: null,
    vatVerification: {
      ...state.vatVerification,
      isFetching: true,
    },
  }),

  [types.GET_VAT_VERIFICATION_SUCCES]: (state) => ({
    ...state,
    vatVerification: {
      ...state.vatVerification,
      isFetching: false,
      isVerified: true,
    },
  }),

  [types.GET_VAT_VERIFICATION_ERROR]: (state) => ({
    ...state,
    vatVerification: {
      ...state.vatVerification,
      isFetching: false,
      isVerified: false,
    },
  }),

  [types.SET_SERIAL_NUMBER_LICENSES_REQUEST]: (state) => ({
    ...state,
    license: {
      ...state.license,
      isFetching: true,
      paymentData: {},
    },
  }),

  [types.SET_SERIAL_NUMBER_LICENSES_SUCCESS]: (state, action) => ({
    ...state,
    license: {
      ...state.license,
      isFetching: false,
      paymentData: {
        ...state.license.paymentData,
        ...action.payload,
      },
    },
  }),

  [types.SET_SERIAL_NUMBER_LICENSES_FAILURE]: (state, action) => ({
    ...state,
    license: {
      ...state.license,
      error: action.error,
      isFetching: false,
      paymentData: {},
    },
  }),

  [types.CREATE_LICENSE_INVOICE_PAYMENT_REQUEST]: (state) => ({
    ...state,
    license: {
      ...state.license,
      isFetching: true,
    },
  }),

  [types.CREATE_LICENSE_INVOICE_PAYMENT_SUCCESS]: (state) => ({
    ...state,
    license: {
      ...state.license,
      isFetching: false,
    },
  }),

  [types.CREATE_LICENSE_INVOICE_PAYMENT_FAILURE]: (state, action) => ({
    ...state,
    license: {
      ...state.license,
      error: action.error,
      isFetching: false,
    },
  }),

  [types.SET_PAYMENTS_REQUEST]: (state) => ({
    ...state,
    license: {
      ...state.license,
      isFetching: true,
    },
  }),

  [types.SET_PAYMENTS_SUCCESS]: (state, action) => ({
    ...state,
    license: {
      ...state.license,
      isFetching: false,
      paymentData: {
        ...state.license.paymentData,
        ...action.payload,
      },
    },
  }),

  [types.SET_PAYMENTS_FAILURE]: (state, action) => ({
    ...state,
    license: {
      ...state.license,
      error: action.error,
      isFetching: false,
    },
  }),

  [types.DELETE_ORDER_LICENSE_REQUEST]: (state) => ({
    ...state,
    license: {
      ...state.license,
      isFetching: true,
    },
  }),

  [types.DELETE_ORDER_LICENSE_SUCCESS]: (state) => ({
    ...state,
    license: {
      ...state.license,
      isFetching: false,
    },
  }),

  [types.DELETE_ORDER_LICENSE_FAILURE]: (state, action) => ({
    ...state,
    license: {
      ...state.license,
      error: action.error,
      isFetching: false,
    },
  }),

  [types.GET_MOBILE_VIDEO_LICENSE_REQUEST]: (state) => ({
    ...state,
    videoMobileLicense: {
      ...state.videoMobileLicense,
      didInvalid: false,
      isFetching: true,
    },
  }),

  [types.GET_MOBILE_VIDEO_LICENSE_SUCCESS]: (state, { payload }) => ({
    ...state,
    videoMobileLicense: {
      ...state.videoMobileLicense,
      data: payload,
      didInvalid: true,
      error: null,
      isFetching: false,
    },
  }),

  [types.GET_MOBILE_VIDEO_LICENSE_FAILURE]: (state, { error }) => ({
    ...state,
    videoMobileLicense: {
      ...state.videoMobileLicense,
      error,
      isFetching: false,
    },
  }),

  [types.GET_MOBILE_VIDEO_LICENSE_PRICES_REQUEST]: (state) => ({
    ...state,
    videoMobileLicense: {
      ...state.videoMobileLicense,
      prices: {
        data: null,
        didInvalid: false,
        error: null,
        isFetching: true,
      },
    },
  }),

  [types.GET_MOBILE_VIDEO_LICENSE_PRICES_SUCCESS]: (state, { payload }) => ({
    ...state,
    videoMobileLicense: {
      ...state.videoMobileLicense,
      prices: {
        data: payload,
        didInvalid: true,
        error: null,
        isFetching: false,
      },
    },
  }),

  [types.GET_MOBILE_VIDEO_LICENSE_PRICES_FAILURE]: (state, { error }) => ({
    ...state,
    videoMobileLicense: {
      ...state.videoMobileLicense,
      prices: {
        data: null,
        didInvalid: false,
        error,
        isFetching: false,
      },
    },
  }),

  [types.SET_MOBILE_VIDEO_PRODUCT_REQUEST]: (state) => ({
    ...state,
    videoMobileLicense: {
      ...state.videoMobileLicense,
      paymentData: {
        data: null,
        didInvalid: false,
        error: null,
        isFetching: true,
      },
    },
  }),

  [types.SET_MOBILE_VIDEO_PRODUCT_SUCCESS]: (state, { payload }) => ({
    ...state,
    videoMobileLicense: {
      ...state.videoMobileLicense,
      paymentData: {
        data: payload,
        didInvalid: true,
        error: null,
        isFetching: false,
      },
    },
  }),

  [types.SET_MOBILE_VIDEO_PRODUCT_FAILURE]: (state, { error }) => ({
    ...state,
    videoMobileLicense: {
      ...state.videoMobileLicense,
      paymentData: {
        data: null,
        didInvalid: false,
        error,
        isFetching: false,
      },
    },
  }),

  [types.SET_PAYMENT_RESULT]: (state, { payload }) => ({
    ...state,
    purchasePaymentResult: payload,
  }),

  [types.SHOW_LICENSE_DETAIL_MODAL]: (state, { invoice }) => ({
    ...state,
    licenseDetailModal: {
      ...initialState.licenseDetailModal,
      data: invoice,
      show: true,
    },
  }),

  [types.HIDE_LICENSE_DETAIL_MODAL]: (state) => ({
    ...state,
    licenseDetailModal: {
      ...initialState.licenseDetailModal,
    },
  }),

  [types.VERIFY_ACTIVATION_CODE_REQUEST]: (state) => ({
    ...state,
    videoMobileCode: {
      ...initialState.videoMobileCode,
      isFetching: true,
    },
  }),

  [types.INVALIDATE_CODE_DATA]: (state) => ({
    ...state,
    videoMobileCode: initialState.videoMobileCode,
  }),

  [types.INVALIDATE_PAYMENT_STATUS]: (state) => ({
    ...state,
    purchasePaymentResult: initialState.purchasePaymentResult,
  }),

  [types.VERIFY_ACTIVATION_CODE_SUCCESS]: (state, { code, payload }) => ({
    ...state,
    videoMobileCode: {
      ...initialState.videoMobileCode,
      code,
      data: payload,
      isFetching: false,
    },
  }),

  [types.VERIFY_ACTIVATION_CODE_ERROR]: (state, { error }) => ({
    ...state,
    videoMobileCode: {
      ...initialState.videoMobileCode,
      error,
      isFetching: false,
    },
  }),

  [types.VERIFY_ACTIVATION_CODE_FAILURE]: (state, { error }) => ({
    ...state,
    videoMobileCode: {
      ...initialState.videoMobileCode,
      error,
      isFetching: false,
    },
  }),

  [types.ACTIVATE_ACTIVATION_CODE_REQUEST]: (state) => ({
    ...state,
    videoMobileCode: {
      ...state.videoMobileCode,
      activation: {
        ...initialState.videoMobileCode.activation,
        isFetching: true,
      },
    },
  }),

  [types.ACTIVATE_ACTIVATION_CODE_SUCCESS]: (state, { payload }) => ({
    ...state,
    videoMobileCode: {
      ...state.videoMobileCode,
      activation: {
        ...initialState.videoMobileCode.activation,
        data: payload,
        isFetching: false,
      },
    },
  }),

  [types.ACTIVATE_ACTIVATION_CODE_ERROR]: (state, { error }) => ({
    ...state,
    videoMobileCode: {
      ...state.videoMobileCode,
      activation: {
        ...initialState.videoMobileCode.activation,
        error: error.status,
        isFetching: false,
      },
    },
  }),

  [types.SET_PROMO_CODES_REQUEST]: (state) => ({
    ...state,
    promoCodes: {
      ...state.promoCodes,
      paymentData: {
        ...initialState.promoCodes.paymentData,
        isFetching: true,
      },
    },
  }),

  [types.SET_PROMO_CODES_SUCCESS]: (state, action) => ({
    ...state,
    promoCodes: {
      ...state.promoCodes,
      paymentData: {
        ...initialState.promoCodes.paymentData,
        data: action.payload,
        isFetching: false,
      },
    },
  }),

  [types.SET_PROMO_CODES_FAILURE]: (state, action) => ({
    ...state,
    promoCodes: {
      ...state.promoCodes,
      paymentData: {
        ...initialState.promoCodes.paymentData,
        error: action.error,
        isFetching: false,
      },
    },
  }),

  [types.GET_PROMO_CODES_ORDER_REQUEST]: (state) => ({
    ...state,
    promoCodes: {
      ...state.promoCodes,
      paymentData: {
        ...state.promoCodes.paymentData,
        isFetching: true,
      },
    },
  }),

  [types.GET_PROMO_CODES_ORDER_SUCCESS]: (state, action) => ({
    ...state,
    promoCodes: {
      ...state.promoCodes,
      paymentData: {
        ...initialState.promoCodes.paymentData,
        data: action.payload,
        isFetching: false,
      },
    },
  }),

  [types.GET_PROMO_CODES_ORDER_FAILURE]: (state, action) => ({
    ...state,
    promoCodes: {
      ...state.promoCodes,
      paymentData: {
        ...state.promoCodes.paymentData,
        error: action.error,
        isFetching: false,
      },
    },
  }),

  [types.DELETE_PROMO_CODES_ORDER_CONTENT_REQUEST]: (state) => ({
    ...state,
    promoCodes: {
      ...state.promoCodes,
      paymentData: {
        ...state.promoCodes.paymentData,
        isFetching: true,
      },
    },
  }),

  [types.DELETE_PROMO_CODES_ORDER_CONTENT_SUCCESS]: (state) => ({
    ...state,
    promoCodes: {
      ...state.promoCodes,
      paymentData: {
        ...state.promoCodes.paymentData,
        isFetching: false,
      },
    },
  }),

  [types.DELETE_PROMO_CODES_ORDER_CONTENT_FAILURE]: (state, action) => ({
    ...state,
    promoCodes: {
      ...state.promoCodes,
      paymentData: {
        ...state.promoCodes.paymentData,
        error: action.error,
        isFetching: false,
      },
    },
  }),

  [types.SET_PROMO_CODES_ORDER_REQUEST]: (state) => ({
    ...state,
    promoCodes: {
      ...state.promoCodes,
      paymentData: {
        ...state.promoCodes.paymentData,
        isFetching: true,
      },
    },
  }),

  [types.SET_PROMO_CODES_ORDER_SUCCESS]: (state, action) => ({
    ...state,
    promoCodes: {
      ...state.promoCodes,
      paymentData: {
        ...initialState.promoCodes.paymentData,
        data: {
          location: state.promoCodes.paymentData.data.location,
          ...action.payload,
        },
        isFetching: false,
      },
    },
  }),

  [types.SET_PROMO_CODES_ORDER_FAILURE]: (state, action) => ({
    ...state,
    promoCodes: {
      ...state.promoCodes,
      paymentData: {
        ...state.promoCodes.paymentData,
        error: action.error,
        isFetching: false,
      },
    },
  }),

  [types.GET_PROMO_CODES_REQUEST]: (state) => ({
    ...state,
    promoCodes: {
      ...state.promoCodes,
      isFetching: true,
    },
  }),

  [types.GET_PROMO_CODES_SUCCESS]: (state, action) => ({
    ...state,
    promoCodes: {
      ...state.promoCodes,
      data: action.payload,
      isFetching: false,
    },
  }),

  [types.GET_PROMO_CODES_FAILURE]: (state, action) => ({
    ...state,
    promoCodes: {
      ...state.promoCodes,
      error: action.error,
      isFetching: false,
    },
  }),

  [types.CREATE_PROMO_INVOICE_PAYMENT_REQUEST]: (state) => ({
    ...state,
    promoCodes: {
      ...state.promoCodes,
      paymentData: {
        ...state.promoCodes.paymentData,
        isFetching: true,
      },
    },
  }),

  [types.CREATE_PROMO_INVOICE_PAYMENT_SUCCESS]: (state) => ({
    ...state,
    promoCodes: {
      ...state.promoCodes,
      paymentData: {
        ...state.promoCodes.paymentData,
        isFetching: false,
      },
    },
  }),

  [types.CREATE_PROMO_INVOICE_PAYMENT_FAILURE]: (state, action) => ({
    ...state,
    promoCodes: {
      ...state.promoCodes,
      paymentData: {
        ...state.promoCodes.paymentData,
        error: action.error,
        isFetching: false,
      },
    },
  }),

  [types.CLEAR_SUBSCRIPTION]: (state) => ({
    ...initialState,
    vatVerification: {
      ...state.vatVerification,
    },
  }),
};

const reducer = (state = initialState, action = {}) => {
  if (reducers[action.type]) {
    return reducers[action.type](state, action);
  }
  return state;
};

export default reducer;
