import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ButtonLink from '../../../components/ButtonLink';
import DashboardCardHeader from '../../../components/DashboardCardHeader';
import { PATHS } from '../../../config/consts';
import { DEVICE_CATEGORY } from '../../../config/devices';
import { SITE_TYPE } from '../../../config/sites';
import { BILLING_TYPE } from '../../../config/subscription';
import { isStandardMVType } from '../../../helpers/mobileVideo';
import { useUrlParams } from '../../../helpers/urlParams';
import Device from './Device';
import messages from './messages';

const styles = {
  content: {
    height: '100%',
    pb: 3,
    pt: 0,
    px: 3,
    width: '100%',
  },
  count: {
    color: 'text.primary',
    fontSize: '68px',
    fontWeight: 300,
    lineHeight: '50px',
    pt: { sm: 2, xs: 0 },
  },
  leftBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 2,
    pr: { sm: 1, xs: 0 },
  },
  root: {
    height: '100%',
    width: '100%',
  },
};

const DevicesOverviewCard = ({
  billingType,
  devices,
  freeLicence,
  freeMVFFEnabled,
  onAddDevice,
  siteMVStatus,
  siteMVType,
  siteType,
}) => {
  const { companyId, siteId } = useUrlParams();

  const isFreeLicence = freeLicence > 0;
  const isPerDeviceBillingType = billingType === BILLING_TYPE.PER_DEVICE;
  const isFreeHIPMODevicesTooltipDisplayed =
    isFreeLicence &&
    isPerDeviceBillingType &&
    siteType !== SITE_TYPE.OTHER &&
    isStandardMVType(siteMVType, siteMVStatus) &&
    freeMVFFEnabled;

  const getCount = (type = 'count') => {
    let count = 0;
    Object.keys(devices).forEach((key) => {
      count += devices[key][type];
    });
    return count;
  };

  return (
    <Card sx={styles.root}>
      <DashboardCardHeader
        action={
          <ButtonLink
            color="primary"
            to={PATHS.DEVICES_OVERVIEW.replace(':companyId', companyId).replace(':siteId', siteId)}
            variant="text"
          >
            <FormattedMessage {...messages.devicesOverviewCardSeeAllDevices} />
          </ButtonLink>
        }
        title={<FormattedMessage {...messages.devicesOverviewCardTitle} />}
      />
      <Grid container sx={styles.content}>
        <Grid container item sm={5} sx={styles.leftBox} xs={12}>
          <Grid item sm={12} xs={6}>
            <Typography sx={styles.count}>{getCount()}</Typography>
            <Typography sx={{ mt: 1 }} variant="body2">
              <FormattedMessage {...messages.devicesOverviewCardAddedDevices} values={{ count: getCount() }} />
            </Typography>
          </Grid>
          <Grid item sm={12} sx={{ textAlign: { sm: 'left', xs: 'right' } }} xs={6}>
            <Button color="primary" onClick={onAddDevice} variant="outlined">
              <FormattedMessage {...messages.devicesOverviewCardAddDevice} />
            </Button>
          </Grid>
        </Grid>
        <Grid item sm={7} sx={{ mt: { sm: 2, xs: 1 } }} xs={12}>
          <Device
            count={devices[DEVICE_CATEGORY.intercom].count}
            iconName="intercom-small"
            isTooltip
            isError={devices[DEVICE_CATEGORY.intercom].fix > 0}
            isWarn={devices[DEVICE_CATEGORY.intercom].warn > 0}
            status={siteMVStatus}
          >
            <FormattedMessage
              values={{
                bold: (number) => <strong>{number}</strong>,
                count: devices[DEVICE_CATEGORY.intercom].count,
              }}
              {...messages.devicesOverviewCardIntercom}
            />
          </Device>
          <Device
            count={devices[DEVICE_CATEGORY.answeringUnit].count}
            iconName="idcompact-small"
            isTooltip
            isError={devices[DEVICE_CATEGORY.answeringUnit].fix > 0}
            isWarn={devices[DEVICE_CATEGORY.answeringUnit].warn > 0}
            status={siteMVStatus}
          >
            <FormattedMessage
              values={{
                bold: (number) => <strong>{number}</strong>,
                count: devices[DEVICE_CATEGORY.answeringUnit].count,
              }}
              {...messages.devicesOverviewCardAnsweringUnit}
            />
          </Device>
          <Device
            count={devices[DEVICE_CATEGORY.smartphoneOrTablet].count}
            iconName="mobile-small"
            isSmartphoneOrTablet
            isTooltip={isFreeHIPMODevicesTooltipDisplayed}
            isError={devices[DEVICE_CATEGORY.smartphoneOrTablet].fix > 0}
            isWarn={devices[DEVICE_CATEGORY.smartphoneOrTablet].warn > 0}
            status={siteMVStatus}
            freeLicence={freeLicence}
          >
            <FormattedMessage
              values={{
                bold: (number) => <strong>{number}</strong>,
                count: devices[DEVICE_CATEGORY.smartphoneOrTablet].count,
              }}
              {...messages.devicesOverviewCardSmartphoneOrTablet}
            />
          </Device>
          <Device
            count={devices[DEVICE_CATEGORY.accessUnit].count}
            iconName="access_unit-small"
            isError={devices[DEVICE_CATEGORY.accessUnit].fix > 0}
            isWarn={devices[DEVICE_CATEGORY.accessUnit].warn > 0}
            status={siteMVStatus}
          >
            <FormattedMessage
              values={{
                bold: (number) => <strong>{number}</strong>,
                count: devices[DEVICE_CATEGORY.accessUnit].count,
              }}
              {...messages.devicesOverviewCardAccessUnit}
            />
          </Device>
          <Device
            count={devices[DEVICE_CATEGORY.thirdParty].count}
            iconName="third-party-small"
            isError={devices[DEVICE_CATEGORY.thirdParty].fix > 0}
            isWarn={devices[DEVICE_CATEGORY.thirdParty].warn > 0}
            isLastChild
            status={siteMVStatus}
          >
            <FormattedMessage
              values={{
                bold: (number) => <strong>{number}</strong>,
                count: devices[DEVICE_CATEGORY.thirdParty].count,
              }}
              {...messages.devicesOverviewCardThirdParty}
            />
          </Device>
        </Grid>
      </Grid>
    </Card>
  );
};

DevicesOverviewCard.propTypes = {
  billingType: PropTypes.string,
  devices: PropTypes.shape({
    [DEVICE_CATEGORY.intercom]: PropTypes.shape({
      count: PropTypes.number,
      fix: PropTypes.number,
      warn: PropTypes.number,
    }),
    [DEVICE_CATEGORY.answeringUnit]: PropTypes.shape({
      count: PropTypes.number,
      fix: PropTypes.number,
      warn: PropTypes.number,
    }),
    [DEVICE_CATEGORY.smartphoneOrTablet]: PropTypes.shape({
      count: PropTypes.number,
      fix: PropTypes.number,
      warn: PropTypes.number,
    }),
    [DEVICE_CATEGORY.accessUnit]: PropTypes.shape({
      count: PropTypes.number,
      fix: PropTypes.number,
      warn: PropTypes.number,
    }),
    [DEVICE_CATEGORY.thirdParty]: PropTypes.shape({
      count: PropTypes.number,
      fix: PropTypes.number,
      warn: PropTypes.number,
    }),
  }),
  freeLicence: PropTypes.number,
  freeMVFFEnabled: PropTypes.bool,
  onAddDevice: PropTypes.func.isRequired,
  siteMVStatus: PropTypes.string.isRequired,
  siteMVType: PropTypes.string.isRequired,
  siteType: PropTypes.string,
};

DevicesOverviewCard.defaultProps = {
  devices: {
    [DEVICE_CATEGORY.intercom]: {
      count: 0,
      fix: 0,
      warn: 0,
    },
    [DEVICE_CATEGORY.answeringUnit]: {
      count: 0,
      fix: 0,
      warn: 0,
    },
    [DEVICE_CATEGORY.smartphoneOrTablet]: {
      count: 0,
      fix: 0,
      warn: 0,
    },
    [DEVICE_CATEGORY.accessUnit]: {
      count: 0,
      fix: 0,
      warn: 0,
    },
    [DEVICE_CATEGORY.thirdParty]: {
      count: 0,
      fix: 0,
      warn: 0,
    },
  },
};

export default DevicesOverviewCard;
