import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { DEVICE_TYPE } from '../../../../config/devices';
import { useGetActualCompanyId } from '../../../../hooks/companyHooks';
import { useGetActualSiteId } from '../../../../hooks/siteHooks';
import { selectors as apartmentSelectors } from '../../../../modules/apartments';
import { IApartment } from '../../../../modules/apartments/store';
import { selectors } from '../../../../modules/devices';
import { IDevice } from '../../../../modules/devices/store/deviceStore';
import { IState } from '../helpers/types';

export interface IDeviceWithCallSettingsDetails extends IDevice {
  warning: boolean;
  canSetCallSettings: boolean;
}

export interface IStateData {
  state: IState;
  setState: (state: IState) => void;
  ringingGroupSizeLimit: number;
  availableDevices: IDeviceWithCallSettingsDetails[];
  availableApartments: IApartment[];
}

export function useGetCallSettingsState(): IStateData {
  const allDevices: IDevice[] = useSelector(selectors.getCallSettingsAllDevicesOptions);
  const ringingGroupSizeLimit = useSelector(selectors.getRingingGroupSizeLimit);
  const availableApartments = useSelector(apartmentSelectors.getApartmentsList);
  const companyId = useGetActualCompanyId();
  const siteId = useGetActualSiteId();

  const availableDevices: IDeviceWithCallSettingsDetails[] = useMemo(() => {
    const processedDevices: IDeviceWithCallSettingsDetails[] = [];
    allDevices.forEach((availableDevice) => {
      processedDevices.push({
        ...availableDevice,
        canSetCallSettings: hasCallSettings(availableDevice.type),
        warning: false,
      });
    });
    return processedDevices;
  }, [allDevices]);

  const [state, setState] = useState<IState>({
    companyId,
    devicesCallSettings: [
      {
        buttons: [],
        device: undefined,
        selected: false,
      },
    ],
    isFetchingSelectedDevice: false,
    siteId,
  });

  return {
    availableApartments,
    availableDevices,
    ringingGroupSizeLimit,
    setState,
    state,
  };
}

function hasCallSettings(deviceType: string) {
  return [
    DEVICE_TYPE.DOOR_STATION,
    DEVICE_TYPE.IP_INTERCOM,
    DEVICE_TYPE.IP_INTERCOM_V2,
    DEVICE_TYPE.IP_ONE_INTERCOM,
    DEVICE_TYPE.IP_STYLE,
  ].includes(deviceType);
}
