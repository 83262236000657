import { defineMessages } from 'react-intl';

export default defineMessages({
  accessGroupDeviceRemoveModalRemove: {
    defaultMessage: 'Remove',
    description: 'Access group device remove modal - remove button',
    id: 'accessGroupDeviceRemoveModal.remove',
  },
  accessGroupDeviceRemoveModalText: {
    defaultMessage:
      '{users, plural, one {# user} other {# users}} from the' +
      ' <strong>{groupName}</strong> Access group will no longer have access via this device.',
    description: 'Access group device remove modal - text',
    id: 'accessGroupDeviceRemoveModal.text',
  },
  accessGroupDeviceRemoveModalTitle: {
    defaultMessage: 'Remove from Access group',
    description: 'Access group device remove modal - title',
    id: 'accessGroupDeviceRemoveModal.title',
  },
});
