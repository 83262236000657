import SendIcon from '@mui/icons-material/Send';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { TextField } from '../../components';
import messages from './messages';

const SupportForm = ({ errors, handleChange, handleSubmit, resetForm, values }) => (
  <form onSubmit={handleSubmit}>
    <TextField
      error={!!errors.message}
      helperText={errors.message ? errors.message : ' '}
      multiline
      name="message"
      onChange={handleChange}
      sx={{
        '& textarea': {
          minHeight: 200,
        },
      }}
      value={values.message}
    />
    <Button color="primary" startIcon={<SendIcon />} type="submit" variant="contained">
      <FormattedMessage {...messages.supportSend} />
    </Button>
    <Box display="inline-flex" px={2}>
      <Button color="secondary" onClick={() => resetForm()}>
        <FormattedMessage {...messages.supportClear} />
      </Button>
    </Box>
  </form>
);

SupportForm.propTypes = {
  errors: PropTypes.shape({
    message: PropTypes.node,
    text: PropTypes.node,
  }),
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  resetForm: PropTypes.func.isRequired,
  touched: PropTypes.shape({
    message: PropTypes.bool,
    text: PropTypes.bool,
  }),
  values: PropTypes.shape({
    message: PropTypes.string,
  }).isRequired,
};

SupportForm.defaultProps = {
  errors: {},
  touched: {},
};

export default SupportForm;
