import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon2N } from '../../../components';
import { DEVICE_TYPE } from '../../../config/devices';
import Device from './Device';
import messages from './messages';

const styles = {
  buttonGroup: (theme) => ({
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      mx: -1.5,
    },
    '& .MuiButtonGroup-grouped:not(:last-of-type)': {
      '&:hover': {
        borderRightColor: 'primary.main',
      },
      borderRightColor: 'inherit',
    },
  }),
  content: (theme) => ({
    [theme.breakpoints.down('sm')]: {
      margin: 'auto',
      textAlign: 'center',
    },
    display: 'flex',
    justifyContent: 'center',
  }),
};

const devices = [
  {
    icon: <Icon2N name="device-2nhw-new" />,
    title: <FormattedMessage {...messages.x2NTitle} />,
    value: 'X2N',
  },
  {
    icon: <Icon2N name="mobile" />,
    title: <FormattedMessage {...messages.hipmoTitle} />,
    value: DEVICE_TYPE.HIPMO,
  },
  {
    icon: <Icon2N name="axis-ds" />,
    title: <FormattedMessage {...messages.doorStationTitle} />,
    value: DEVICE_TYPE.DOOR_STATION,
  },
  {
    icon: <Icon2N name="third-party" />,
    title: <FormattedMessage values={{ sup: (chunks) => <sup>{chunks}</sup> }} {...messages.thirdPartyTitle} />,
    value: DEVICE_TYPE.THIRD_PARTY,
  },
];

const DevicesControl = ({ onSelectDevice }) => (
  <Grid sx={styles.content}>
    <ButtonGroup sx={styles.buttonGroup}>
      {devices.map((item) => (
        <Device
          icon={item.icon}
          key={item.value}
          onSelectDevice={onSelectDevice}
          title={item.title}
          value={item.value}
        />
      ))}
    </ButtonGroup>
  </Grid>
);

DevicesControl.propTypes = {
  onSelectDevice: PropTypes.func.isRequired,
};

export default DevicesControl;
