import { defineMessages } from 'react-intl';

export default defineMessages({
  iconServiceElevatorIntegrationActive: {
    defaultMessage: 'Integration active',
    description: 'Icon tooltip - elevator integration - active',
    id: 'iconServiceElevatorIntegration.active',
  },
  iconServiceElevatorIntegrationInActive: {
    defaultMessage: 'Integration inactive',
    description: 'Icon tooltip - elevator integration - inactive',
    id: 'iconServiceElevatorIntegration.inactive',
  },
  iconServiceElevatorIntegrationNotAvailable: {
    defaultMessage: 'Integration not available',
    description: 'Icon tooltip - elevator integration - not available',
    id: 'iconServiceElevatorIntegration.notAvailable',
  },
});
