import { MuiRow } from './aggregatedCellContentRenderer';
import { firstColumnId } from './aggregatedColumns';
import { DataGridAggregatedProps } from './data';
import {
  AggregatedRowClassName,
  AggregatedRowHeight,
  CellWithOffsetClassName,
  ClickableRowClassName,
  DataGridWithFooterActionsClassName,
  RowHeight,
} from './styles';
import { conditionalClassNames } from './utils';

export function getAggregatedRowHeight<TAggregatedItem extends object, TItem extends object>(
  row: MuiRow<TAggregatedItem | TItem>
) {
  return row.isAggregatedDataType ? AggregatedRowHeight : RowHeight;
}

export function getAggregatedCellClassName<TAggregatedItem extends object, TItem extends object>({
  columnId,
  row,
}: {
  row: MuiRow<TAggregatedItem | TItem>;
  columnId: string;
}) {
  const isCellWithOffset = columnId === firstColumnId && !row.isAggregatedDataType;
  return conditionalClassNames([isCellWithOffset && CellWithOffsetClassName]);
}

export function getAggregatedRowClassName<TAggregatedItem extends object, TItem extends object>({
  isFetching,
  onAggregatedRowClick,
  onRowClick,
  row,
}: {
  row: MuiRow<object>;
  isFetching?: boolean;
  onAggregatedRowClick?: DataGridAggregatedProps<TAggregatedItem, TItem>['onAggregatedRowClick'];
  onRowClick?: DataGridAggregatedProps<TAggregatedItem, TItem>['onRowClick'];
}) {
  return conditionalClassNames([
    row.isAggregatedDataType && AggregatedRowClassName,
    row.isAggregatedDataType && onAggregatedRowClick && !isFetching && ClickableRowClassName,
    !row.isAggregatedDataType && onRowClick && !isFetching && ClickableRowClassName,
  ]);
}

export function getAggregatedDataGridClassName<TAggregatedItem extends object, TItem extends object>({
  footerActions,
}: {
  footerActions?: DataGridAggregatedProps<TAggregatedItem, TItem>['footerActions'];
}) {
  return conditionalClassNames([footerActions && footerActions.length > 0 && DataGridWithFooterActionsClassName]);
}
