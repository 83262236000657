import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { FORM } from '../../../config/consts';
import { useUrlParams } from '../../../helpers/urlParams';
import { selectors as apartmentSelectors } from '../../../modules/apartments';
import { selectors as siteSelectors } from '../../../modules/sites';
import { IUserBase } from '../../../modules/user/store';
import useUsersFormValidationSchema from '../../UsersAutocomplete/useUsersFormValidationSchema';

export function useGetGlobalHooks() {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { apartmentId, companyId, siteId } = useUrlParams();
  const isAddApartmentUserLoading = useSelector(apartmentSelectors.isAddApartmentUsersModalFetching);
  const apartmentUsersId = useSelector(apartmentSelectors.getApartmentUsers)?.map((user: IUserBase) => user.id);
  const optionsData = useSelector(siteSelectors.getSiteUsersData).filter((user) => !apartmentUsersId.includes(user.id));
  const validationSchema = useUsersFormValidationSchema(companyId, siteId);
  const formMethods = useForm({
    defaultValues: {
      [FORM.HAS_FORM]: false,
      email: '',
      firstName: '',
      lastName: '',
      users: [],
    },
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
    shouldUnregister: true,
  });

  return {
    apartmentId,
    dispatch,
    formatMessage,
    formMethods,
    isAddApartmentUserLoading,
    isDirty: formMethods.formState.isDirty,
    isValid: formMethods.formState.isValid,
    optionsData,
  };
}
