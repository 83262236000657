import { defineMessages } from 'react-intl';

export default defineMessages({
  DeviceListDefaultPasswordError: {
    defaultMessage: "Some devices use the default password." +
      "Settings made in My2N won't take effect in these devices until you change it.",
    id: 'deviceList.defaultPasswordError',
  },
  deviceListDefaultPasswordWarning: {
    defaultMessage:
      'Highlighted devices use the default password.' +
      'Enhance security by changing it in the device’s configuration interface or via the Remote Configuration service.',
    description: 'Alert message',
    id: 'deviceList.defaultPasswordWarning',
  },
  DevicesOverviewAddDevice: {
    defaultMessage: 'Add device',
    description: 'Devices - add device button',
    id: 'devicesOverview.addDevice',
  },
  DevicesOverviewAddDeviceMobile: {
    defaultMessage: 'Device',
    description: 'Devices - add device button on mobile device',
    id: 'devicesOverview.addDeviceMobile',
  },
  DevicesOverviewTitle: {
    defaultMessage: 'Devices',
    description: 'Devices - title',
    id: 'devicesOverview.title',
  },
});
