import React from 'react';
import { useIntl } from 'react-intl';
import { Title } from '../../components';
import Content from './Content';
import ImportDataProvider from './ImportDataProvider';
import messages from './messages';

function SiteUsersImport() {
  const { formatMessage } = useIntl();
  return (
    <>
      <Title heading={formatMessage(messages.siteUsersImportTitle)} />
      <ImportDataProvider>
        <Content />
      </ImportDataProvider>
    </>
  );
}

export default SiteUsersImport;
