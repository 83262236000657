import AddCircle from '@mui/icons-material/AddCircle';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useGetGlobalInfo } from '../../hooks/useGetGlobalInfo';
import actions from '../../modules/apartments';
import { IUser } from '../../modules/user/store';
import { ActionModal } from '../_DesignSystemWrappers/Modal';
import StandardUser from '../UserApartmentModal/StandardUser';
import messages from './messages';

interface IGrantApartmentAdminRightsModal {
  onClose: () => void;
  open: boolean;
  selectedUser: IUser;
}

function GrantApartmentAdminRightsModal({ onClose, open, selectedUser }: IGrantApartmentAdminRightsModal) {
  const { apartmentId, dispatch, formatMessage } = useGetGlobalInfo();

  const handleSubmit = () => {
    if (apartmentId !== undefined) {
      dispatch(actions.grantApartmentAdmin(apartmentId, selectedUser.id));
    }
    onClose();
  };

  return (
    <ActionModal
      icon={<AddCircle />}
      isPrimaryButtonDisabled={false}
      muiDialogProps={{ maxWidth: 'xs' }}
      onClose={onClose}
      open={open}
      primaryButtonAction={handleSubmit}
      primaryButtonText={formatMessage(messages.AAModalGrantRightsSubmit)}
      secondaryButtonText={formatMessage(messages.AAModalGrantRightsCancel)}
      title={formatMessage(messages.AAModalGrantRightsTitle)}
      testId="grant-apartment-admin-rights-modal"
    >
      <Stack direction="column" spacing={2} sx={{ my: 2 }}>
        <Typography variant="body2">
          <FormattedMessage {...messages.AAModalGrantRightsText1} />
        </Typography>
        <StandardUser email={selectedUser.email} firstName={selectedUser.firstName!} lastName={selectedUser.lastName} />
        <Typography variant="body2">
          <FormattedMessage {...messages.AAModalGrantRightsText2} />
        </Typography>
      </Stack>
    </ActionModal>
  );
}

export default GrantApartmentAdminRightsModal;
