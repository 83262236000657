import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useUrlParams } from '../../../../helpers/urlParams';
import useBreakpoints from '../../../../helpers/useBreakpoints';
import { isSiteMdu } from '../../../../modules/sites/selectors';

export function useGetGlobalHooks() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { upLg, upSm } = useBreakpoints();
  const { apartmentId, companyId, siteId } = useUrlParams();
  const isSiteMDU = useSelector(isSiteMdu);

  return {
    breakpoints: {
      upLg,
      upSm,
    },
    dispatch,
    formatMessage,
    isSiteMDU,
    navigate,
    urlParams: {
      apartmentId,
      companyId,
      siteId,
    },
  };
}
