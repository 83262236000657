import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';

function SectionWrapper({ children }) {
  return <Box p={2}>{children}</Box>;
}

SectionWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SectionWrapper;
