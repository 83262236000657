import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import NoRowsOverlayDataGrid from '../DataGrid/NoRowsOverlay';
import messages from './messages';

const NoRowsOverlay = ({ onCreateSite }) => (
  <NoRowsOverlayDataGrid
    action={
      <Button color="primary" onClick={onCreateSite} startIcon={<AddIcon />} sx={{ mt: 3 }} variant="contained">
        <FormattedMessage {...messages.sitesDataGridNoRowsOverlayButton} />
      </Button>
    }
    headerText={<FormattedMessage {...messages.sitesDataGridNoRowsOverlayHeaderText} />}
    text={
      <FormattedMessage
        {...messages.sitesDataGridNoRowsOverlayText}
        values={{
          br: <br />,
        }}
      />
    }
  />
);

NoRowsOverlay.propTypes = {
  onCreateSite: PropTypes.func.isRequired,
};

export default NoRowsOverlay;
