import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert } from '../../../components';
import { SUPPORT } from '../../../config/address';
import messages from '../messages';

const ErrorAlertCreditLimit = () => (
  <Alert severity="error">
    <FormattedMessage
      {...messages.creditLimitError}
      values={{
        bold: (chunks) => <strong>{chunks}</strong>,
        url: (chunks) => <a href={`mailto:${SUPPORT}`}>{chunks}</a>,
      }}
    />
  </Alert>
);

export default ErrorAlertCreditLimit;
