import { CancelTokenSource } from 'axios';
import { NavigateFunction } from 'react-router-dom';
import { Dispatch } from 'redux';
import { PATHS } from '../../config/consts';
import { getUrlQueryParamValue, UrlQueryParamName } from '../../helpers/queryParams';
import { IUseLoginApi } from '../../ory/hooks/oryApi';
import { OryCancelled, OryLoginStatus } from '../../ory/types';

export const resetStoreAction = { type: 'store/reset' };

export async function logoutAndNavigate({
  cancelTokenSource,
  dispatch,
  logoutFromOry,
  navigate,
  navigatePathAfterLogout,
  oryLoginStatus,
}: {
  dispatch: Dispatch;
  navigate: NavigateFunction;
  oryLoginStatus: OryLoginStatus | undefined;
  logoutFromOry: IUseLoginApi['logoutFromOry'];
  cancelTokenSource: CancelTokenSource;
  navigatePathAfterLogout?: string;
}) {
  const isOrySessionActive = getUrlQueryParamValue({ paramName: UrlQueryParamName.IsOrySessionActive });

  const tryToLogoutFromOry = async () => {
    const result = await logoutFromOry({ cancelToken: cancelTokenSource.token });
    if (result !== OryCancelled) {
      dispatch(resetStoreAction);
      navigate(navigatePathAfterLogout || PATHS.LOGIN);
    }
  };
  if (oryLoginStatus === OryLoginStatus.SessionConfirmed || isOrySessionActive) {
    await tryToLogoutFromOry();
  } else {
    dispatch(resetStoreAction);
    navigate(navigatePathAfterLogout || PATHS.LOGIN);
  }
}
