import Tab from '@mui/material/Tab';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, Outlet } from 'react-router-dom';
import { useHasFeature } from '../../components/Feature/Feature';
import TabsContainer from '../../components/TabsContainer/TabsContainer';
import Title from '../../components/Title';
import featureFlags from '../../config/featureFlags';
import useBreakpoints from '../../helpers/useBreakpoints';
import { useGetGlobalHooks } from './hooks/getGlobalHooks';
import messages from './messages';

export function SiteSettings() {
  const { downSm } = useBreakpoints();
  const { elevatorIntegration, formatMessage, location, siteLanguage, siteSettingsAccess } = useGetGlobalHooks();
  const value = useMemo(
    () =>
      [elevatorIntegration, siteLanguage, siteSettingsAccess].includes(location?.pathname) ? location?.pathname : null,
    [location]
  );
  const isElevatorIntegrationFFEnabled = useHasFeature(featureFlags.ELEVATOR_INTEGRATION);
  const isQRCodeFFEnable = useHasFeature(featureFlags.QR_CODE);

  return (
    <>
      <Title heading={formatMessage(messages.siteSettingsTitle)} />
      {value && (
        <TabsContainer isXs={downSm} value={value}>
          <Tab
            component={Link}
            label={<FormattedMessage {...messages.siteSettingsSiteLanguageTabTitle} />}
            to={siteLanguage}
            value={siteLanguage}
          />
          {isQRCodeFFEnable && (
            <Tab
              component={Link}
              label={<FormattedMessage {...messages.siteSettingsAccessTabTitle} />}
              to={siteSettingsAccess}
              value={siteSettingsAccess}
            />
          )}
          {isElevatorIntegrationFFEnabled && (
            <Tab
              component={Link}
              label={<FormattedMessage {...messages.siteSettingsElevatorIntegrationTabTitle} />}
              to={elevatorIntegration}
              value={elevatorIntegration}
            />
          )}
        </TabsContainer>
      )}
      <Outlet />
    </>
  );
}
