import AddIcon from '@mui/icons-material/Add';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import CredentialAlert from './CredentialAlert';

export interface ICredentialSection {
  addCredentialButtonAction: () => void;
  addCredentialButtonLabel: string;
  deleteButtonAction: () => void;
  deleteButtonLabel: string;
  infoText: string;
  isActive?: boolean;
  panel: React.JSX.Element;
  startIcon: React.ElementType;
  title: string;
  customInactiveContent?: React.JSX.Element;
}

function CredentialSection({
  addCredentialButtonAction,
  addCredentialButtonLabel,
  customInactiveContent,
  deleteButtonAction,
  deleteButtonLabel,
  infoText,
  isActive,
  panel,
  startIcon,
  title,
}: ICredentialSection) {
  const [showInfo, setShowInfo] = useState(!isActive);

  return (
    <Stack sx={{ mb: 3 }}>
      <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
        <SvgIcon component={startIcon} inheritViewBox sx={{ color: isActive ? 'primary.main' : 'secondary.main' }} />
        <Typography color={isActive ? 'primary' : 'secondary'} fontSize={16} variant="h6">
          {title}
        </Typography>
        <Box alignItems="flex-start" display="flex" sx={{ pl: 2 }}>
          <InfoIcon color="disabled" onClick={() => setShowInfo(!showInfo)} sx={{ cursor: 'pointer' }} />
        </Box>
      </Stack>
      <Divider />
      <Stack mt={3} spacing={2}>
        {isActive ? (
          <Box>{panel}</Box>
        ) : (
          <Box>
            {customInactiveContent !== undefined ? (
              customInactiveContent
            ) : (
              <Button
                color="primary"
                onClick={addCredentialButtonAction}
                startIcon={<AddIcon />}
                sx={{ mb: 2 }}
                variant="outlined"
              >
                {addCredentialButtonLabel}
              </Button>
            )}
          </Box>
        )}
        {showInfo && <CredentialAlert infoText={infoText} />}
        {isActive && (
          <Box sx={{ pt: 2 }}>
            <Button color="error" onClick={deleteButtonAction} variant="text">
              {deleteButtonLabel}
            </Button>
          </Box>
        )}
      </Stack>
    </Stack>
  );
}

CredentialSection.defaultProps = {
  isActive: false,
};

export default CredentialSection;
