import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import React, { useState } from 'react';
import FormikFields from '../FormikFields';

const FormikPassword = (props) => {
  const [show, setShow] = useState(false);
  return (
    <FormikFields.Input
      {...props}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => setShow((prevState) => !prevState)}>
              {show ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
        sx: { pr: 0 },
      }}
      type={show ? 'text' : 'password'}
    />
  );
};

export default FormikPassword;
