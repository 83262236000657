import BusinessIcon from '@mui/icons-material/Business';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useGetActualCompanyId } from '../../hooks/companyHooks';
import actions, { selectors } from '../../modules/common';
import { clearCompany } from '../../modules/company/actions';
import { didInvalid } from '../../modules/company/selectors';
import { setRedirectData } from '../../modules/infrastructure/actions';
import { clearSiteData } from '../../modules/sites/actions';
import { redirectUser } from '../../modules/user/helpersTS';
import CompanySwitcherMenu from '../Navbar/components/companyMenu/CompanySwitcher';
import SideBarMenu from '../SideBarMenu';
import messages from './messages';

const styles = {
  switcher: {
    '& li': {
      py: 2,
    },
  },
};

const CompanySwitcher = ({
  data,
  didInvalid,
  isFetching,
  onDrawerClose,
  onLoad,
  onOpenCreateCompanyModal,
  onSetOpen,
  open,
}) => {
  const companyId = useGetActualCompanyId();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isFetching) {
      onLoad();
    }
  }, [companyId]);

  useEffect(() => {
    if (didInvalid) {
      onLoad();
    }
  }, [didInvalid]);

  const addCompany = () => {
    onDrawerClose(false);
    onSetOpen(false);
    onOpenCreateCompanyModal();
  };

  const selectCompany = (id) => {
    onDrawerClose(false);
    onSetOpen(false);

    const redirectPath = redirectUser({ data, id, location });
    if (redirectPath !== undefined) {
      dispatch(clearSiteData());
      dispatch(clearCompany());
      dispatch(setRedirectData(redirectPath));
    }
  };

  return (
    <SideBarMenu
      buttonEmptyLabel={<FormattedMessage {...messages.CompanySwitcherEmptyLabel} />}
      buttonItemLabel={<FormattedMessage {...messages.CompanySwitcherLabel} />}
      buttonStartIcon={<BusinessIcon />}
      buttonValue={data.find((item) => item.id === companyId)?.name || ''}
      onButtonClick={() => onSetOpen(!open)}
      open={open}
    >
      <Box sx={styles.switcher}>
        <CompanySwitcherMenu
          currentId={companyId}
          data={data}
          isFetching={isFetching}
          isSideBar
          onAdd={addCompany}
          onSelect={selectCompany}
        />
      </Box>
    </SideBarMenu>
  );
};

CompanySwitcher.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      roles: PropTypes.arrayOf(PropTypes.string),
      sites: PropTypes.arrayOf(
        PropTypes.shape({
          roles: PropTypes.arrayOf(PropTypes.string),
        })
      ),
    })
  ).isRequired,
  didInvalid: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onDrawerClose: PropTypes.func.isRequired,
  onLoad: PropTypes.func.isRequired,
  onOpenCreateCompanyModal: PropTypes.func.isRequired,
  onSetOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  data: selectors.getDataCompanySwitcher(state),
  didInvalid: didInvalid(state),
  isFetching: selectors.isFetchingCompanySwitcher(state),
});

const mapDispatchToProps = {
  onLoad: actions.loadDataCompanySwitcher,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanySwitcher);
