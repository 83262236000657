import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import ConfirmationNumberIconOutlined from '@mui/icons-material/ConfirmationNumberOutlined';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import serviceSvg from '../../assets/font-icons/service.svg';
import mvideoSvg from '../../assets/mvideo.svg';
import { PATHS } from '../../config/consts';
import { TYPE } from '../../config/subscription';
import { MobileVideoType, Roles } from '../../modules/commonTypes';
import { getDashboardMvServiceLicenceModel } from '../../modules/sites/selectors';
import Card from './Card';
import messages from './messages';

interface ICardData {
  description: React.ReactNode;
  icon: React.ReactNode;
  id: string;
  rightCornerIcon?: React.ReactNode;
  title: React.ReactNode;
  to: string;
  value: string;
  isDisabled: boolean;
}

export function SubscriptionControl({ companyId, isVatVerified, siteId, userRoles }: ISubscriptionControlData) {
  const licenceModel = useSelector(getDashboardMvServiceLicenceModel);
  const hasAllowedIntercomPurchase = userRoles.includes(Roles.IP_INTERCOM_BUYER);
  const hasAllowedPayments = userRoles.includes(Roles.ADMIN) && !userRoles.includes(Roles.MOBILE_VIDEO_BUYER);
  const hasFilledBillingInfo = userRoles.includes(Roles.GREEN_ADMIN);

  const cards = useMemo(() => {
    let cards: ICardData[] = [];

    if (licenceModel?.type === MobileVideoType.STANDARD) {
      cards = [voucherCard, getMobileVideoLicenceCardData(isVatVerified, hasFilledBillingInfo)];
      if (hasAllowedIntercomPurchase) {
        cards.push(deviceLicenceCard(hasFilledBillingInfo));
        cards.push(promoCodeCard(hasFilledBillingInfo));
      }
    } else {
      if (hasAllowedPayments) {
        cards = [voucherCard];
      } else {
        cards = [deviceLicenceCard(hasFilledBillingInfo), promoCodeCard(hasFilledBillingInfo)];
      }
    }

    return cards;
  }, [licenceModel, hasAllowedIntercomPurchase, hasAllowedPayments]);

  return (
    <Grid container direction="row" spacing={4}>
      {cards.map((item) => (
        <Grid item key={item.value} lg={3} md={6} xs={12}>
          <Card
            icon={item.icon}
            id={item.id}
            isDisabled={item.isDisabled}
            path={item.to.replace(':companyId', companyId.toString()).replace(':siteId', siteId.toString())}
            rightCornerIcon={item.rightCornerIcon}
            title={item.title}
          >
            {item.description}
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

export interface IFeSiteRoles {
  isCompanyAdmin?: boolean;
  isSiteAdmin?: boolean;
  isSiteAdminPayment?: boolean;
  isSiteUser?: boolean;
}

export interface ISubscriptionControlData {
  companyId: number;
  siteId: number;
  userRoles: Roles[];
  isVatVerified: boolean;
}

const voucherCard: ICardData = {
  description: (
    <FormattedMessage
      {...messages.subscriptionDescriptionActivation}
      values={{
        br: <br />,
        sup: (chunks) => <sup>{chunks}</sup>,
      }}
    />
  ),
  icon: <img alt="mobilevideocode" src={mvideoSvg} />,
  id: 'mobilevideocode-button',
  isDisabled: false,
  rightCornerIcon: <ConfirmationNumberIcon />,
  title: <FormattedMessage {...messages.subscriptionMobileVideo} values={{ sup: (chunks) => <sup>{chunks}</sup> }} />,
  to: PATHS.SUBSCRIPTION_ACTIVATION_CODE,
  value: TYPE.MOBILE_VIDEO_CODE,
};

function getMobileVideoLicenceCardData(isVatVerified: boolean, hasFilledBillingInfo: boolean): ICardData {
  return {
    description: (
      <FormattedMessage
        {...messages.subscriptionDescriptionSubscription}
        values={{
          br: <br />,
          sup: (chunks) => <sup>{chunks}</sup>,
        }}
      />
    ),
    icon: <img alt="mobilevideolicense" src={mvideoSvg} />,
    id: 'mobilevideolicense-button',
    isDisabled: !isVatVerified || !hasFilledBillingInfo,
    rightCornerIcon: <CreditCardIcon />,
    title: <FormattedMessage {...messages.subscriptionMobileVideo} values={{ sup: (chunks) => <sup>{chunks}</sup> }} />,
    to: PATHS.SUBSCRIPTION_MOBILE_VIDEO,
    value: TYPE.MOBILE_VIDEO_LICENSE,
  };
}

function deviceLicenceCard(hasFilledBillingInfo: boolean): ICardData {
  return {
    description: <FormattedMessage {...messages.subscriptionDescriptionLicenses} values={{ br: <br /> }} />,
    icon: <img alt="devicelicense" src={serviceSvg} />,
    id: 'devicelicense-button',
    isDisabled: !hasFilledBillingInfo,
    rightCornerIcon: undefined,
    title: <FormattedMessage {...messages.subscriptionLicenses} />,
    to: PATHS.SUBSCRIPTION_LICENSES,
    value: TYPE.INTERCOM_LICENSES,
  };
}

function promoCodeCard(hasFilledBillingInfo: boolean): ICardData {
  return {
    description: <FormattedMessage {...messages.subscriptionDescriptionPromo} values={{ br: <br /> }} />,
    icon: (
      <Box borderColor="primary.main">
        <Avatar
          style={{
            backgroundColor: 'inherit',
            border: '2px solid',
            borderColor: 'inherit',
            height: 50,
            width: 50,
          }}
        >
          <ConfirmationNumberIconOutlined color="primary" />
        </Avatar>
      </Box>
    ),
    id: 'promoCode-button',
    isDisabled: !hasFilledBillingInfo,
    rightCornerIcon: null,
    title: (
      <FormattedMessage
        {...messages.subscriptionPromo}
        values={{
          br: <br />,
          sup: (chunks) => <sup>{chunks}</sup>,
        }}
      />
    ),
    to: PATHS.SUBSCRIPTION_VOUCHERS,
    value: TYPE.PROMO_CODE,
  };
}
