import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PATHS } from '../../../config/consts';
import RowMenuItem from '../../DataGrid/RowMenuItem';
import messages from '../messages';

const EditUser = ({ companyId, siteId, userId }) => (
  <RowMenuItem
    component={Link}
    icon={<EditIcon />}
    label={<FormattedMessage {...messages.siteUsersRowMenuEditUser} />}
    to={PATHS.SITE_USER_DETAIL.replace(':companyId', companyId).replace(':siteId', siteId).replace(':userId', userId)}
  />
);

EditUser.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  siteId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default EditUser;
