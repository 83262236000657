import React from 'react';
import { useLocation } from 'react-router-dom';
import { Websocket } from '../../components';
import DeletePhonebookModal from '../../containers/CallSettings/Phonebooks/DeletePhonebookModal';
import DeleteSiteModal from '../../containers/Sites/DeleteSiteModal';
import EditSiteModal from '../../containers/Sites/EditSiteModal';
import { useAppContext } from './AppContextProvider';

const Dialogs = () => {
  const { loggedIn } = useAppContext();
  const { pathname } = useLocation();
  const isPublicRouter = new RegExp(/^\/(legal(\/.*)?|login|register|reset-password)$/).test(pathname);
  return loggedIn && !isPublicRouter ? (
    <>
      <DeletePhonebookModal />
      <DeleteSiteModal />
      <EditSiteModal />
      <Websocket />
    </>
  ) : null;
};

export default Dialogs;
