import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../config/consts';
import { useUrlParams } from '../../helpers/urlParams';
import { useGetActualCompanyId } from '../../hooks/companyHooks';
import { useGetActualSiteId } from '../../hooks/siteHooks';
import { EmptyValue } from '../EmptyValue';

const ApartmentNameChip = ({ count, id, link, name, number, size, ...other }) => {
  const companyId = useGetActualCompanyId();
  const siteId = useGetActualSiteId();
  const navigate = useNavigate();
  const countToRender = count > 1 ? count - 1 : undefined;
  const chipLink =
    link && id
      ? PATHS.APARTMENT_DETAIL.replace(':companyId', companyId.toString())
          .replace(':siteId', siteId.toString())
          .replace(':apartmentId', id.toString())
      : null;

  return number ? (
    <Stack direction="row" sx={{ minWidth: 0 }}>
      <Chip
        {...other}
        clickable={link}
        label={
          <Stack direction="row" spacing={1}>
            <Box fontWeight="bold" sx={{ color: 'primary.dark' }}>
              {number}
            </Box>
            {name && (
              <Box
                sx={{
                  color: 'chip.text',
                  display: { md: 'initial', xs: 'none' },
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {name}
              </Box>
            )}
          </Stack>
        }
        onClick={chipLink ? () => navigate(chipLink) : undefined}
        size={size}
        sx={{ backgroundColor: 'button.info.background', minWidth: 0 }}
      />
      {countToRender !== undefined && (
        <Typography
          sx={{
            alignItems: 'center',
            color: 'chip.text',
            display: 'flex',
            pl: 1,
          }}
          variant="body1"
        >
          {`+${countToRender}`}
        </Typography>
      )}
    </Stack>
  ) : (
    <EmptyValue />
  );
};

ApartmentNameChip.propTypes = {
  count: PropTypes.number,
  id: PropTypes.number,
  link: PropTypes.bool,
  name: PropTypes.string,
  number: PropTypes.string,
  size: PropTypes.string,
};

ApartmentNameChip.defaultProps = {
  count: 0,
  id: null,
  link: false,
  name: '',
  number: '',
  size: 'small',
};

export default ApartmentNameChip;
