export function getRadioGroupBooleanValue(value: boolean | string | undefined): boolean | undefined {
  switch (value) {
    case undefined:
      return undefined;
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return typeof value === 'boolean' ? value : undefined;
  }
}
