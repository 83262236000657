import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Paper } from '../../../components';
import { DEVICE_TYPE, FEATURES } from '../../../config/devices';
import actions, { selectors } from '../../../modules/devices';
import { IDeviceFeature } from '../../../modules/devices/store/deviceStore';
import { ISwitchItem } from './helpers';
import { useLoadSwitchList } from './hooks';
import messages from './messages';
import SwitchCodesStatusAlert from './SwitchCodesStatusAlert';
import WrapperItems from './WrapperItems';

function SwitchCodes() {
  const dispatch = useDispatch();

  const device = useSelector(selectors.getCurrentDeviceDataSelector);
  const isDeviceDetailEditing = useSelector(selectors.isDeviceEditing);

  const DTMFSwitchFeature = device.features?.find((f: IDeviceFeature) => f?.type === FEATURES.DTMF_SWITCH_LIST);
  const isDoorStation = device.type === DEVICE_TYPE.DOOR_STATION;

  const [items, onChange] = useLoadSwitchList(DTMFSwitchFeature);
  const isSaveDisabled = useMemo(
    () => !canBeSaved(items, isDeviceDetailEditing),
    [JSON.stringify(items), isDeviceDetailEditing]
  );

  function handleSave(): void {
    const data = items.map((item) => ({
      code: item.code?.replace('*', ''),
      enabled: item.enabled,
      id: item.id,
      index: item.index,
      managed: isDoorStation ? true : item.managed,
      name: item.name,
    }));

    dispatch(actions.saveDeviceSwitchCodes(data));
  }

  return (
    <Paper tabContent>
      <SwitchCodesStatusAlert isDoorStation={isDoorStation} switches={DTMFSwitchFeature?.switches} />
      <WrapperItems
        isDeviceDetailEditing={isDeviceDetailEditing}
        isDoorStation={isDoorStation}
        items={items}
        onChange={onChange}
        setDeviceEditing={(isEditing) => dispatch(actions.setDeviceEditing(isEditing))}
      />
      <Box display="flex" justifyContent="flex-end" sx={{ mr: 2, mt: 2 }}>
        <Button color="primary" disabled={isSaveDisabled} onClick={handleSave} type="button" variant="contained">
          <FormattedMessage {...messages.switchCodesSaveButton} />
        </Button>
      </Box>
    </Paper>
  );
}

export default SwitchCodes;

function canBeSaved(items: ISwitchItem[], isDeviceDetailEditing: boolean) {
  if (!isDeviceDetailEditing) {
    return false;
  }

  let isAbleToSave = true;
  items.forEach((item) => {
    if (isAbleToSave === false) {
      return;
    }

    isAbleToSave = isItemValid(item);
  });

  return isAbleToSave;
}

function isItemValid(item: ISwitchItem) {
  if (!isEmptyString(item.code) && isEmptyString(item.name)) {
    return false;
  }

  return true;
}

function isEmptyString(value: string | null | undefined) {
  return value === null || value === '' || value === undefined;
}
