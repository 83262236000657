import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FIRMWARE_STATUS } from '../../config/devices';
import DevicesInfoChip from '../DevicesInfoChip';
import messages from './messages';

const FirmwareStatus = ({ status, text }) => {
  let styles = {};
  if (status === FIRMWARE_STATUS.UNSUPPORTED) {
    styles = 'error';
  } else if (status === FIRMWARE_STATUS.DEPRECATED) {
    styles = 'warning';
  }

  return (
    <DevicesInfoChip
      error={styles === 'error'}
      label={text || <FormattedMessage {...firmwareStatusMap[status]} />}
      size="small"
      variant="outlined"
      warning={styles === 'warning'}
    />
  );
};

FirmwareStatus.propTypes = {
  status: PropTypes.string.isRequired,
  text: PropTypes.node,
};

FirmwareStatus.defaultProps = {
  text: null,
};

export default FirmwareStatus;

const firmwareStatusMap = {
  [FIRMWARE_STATUS.DEPRECATED]: messages.firmwareStatusFirmwareStatusDeprecated,
  [FIRMWARE_STATUS.UNSUPPORTED]: messages.firmwareStatusFirmwareStatusUnsupported,
};
