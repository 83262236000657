import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { FilledInfoBar } from '../../../../../components/_DesignSystemWrappers/InfoBar/FilledInfoBar';
import { ActionModal } from '../../../../../components/_DesignSystemWrappers/Modal';
import Checkbox from '../../../../../components/HookFormFields/Checkbox';
import TextField from '../../../../../components/HookFormFields/TextField';
import * as actions from '../../../../../modules/apartmentAdmin/actions';
import { ICreateApartmentUser } from '../../../../../modules/apartmentAdmin/data';
import { FERoles, IModalBase, TId } from '../../../../../modules/commonTypes';
import { hasApartmentExceededMVLimit } from '../../../utils/currentApartmentDetailData';
import { useGetGlobalHooks } from './hooks/getGlobalHooks';
import messages from './messages';

export interface ICreateUserModal extends IModalBase {
  apartmentInfo: {
    apartmentId: TId;
    companyId: TId;
    siteId: TId;
  };
  paidDevicesCount?: number;
  paidDevicesLimit?: number;
  isActiveSubscriptionsState: boolean;
}

function CreateUserModal({
  apartmentInfo,
  isActiveSubscriptionsState,
  onClose,
  open,
  paidDevicesCount,
  paidDevicesLimit,
}: ICreateUserModal) {
  const { apartmentId, companyId, siteId } = apartmentInfo;
  const { dispatch, formatMessage, formMethods } = useGetGlobalHooks(apartmentId, companyId, siteId);

  const onSubmit = (data: ICreateApartmentUser) => {
    dispatch(actions.createApartmentUser(companyId, siteId, apartmentId, { ...data, role: FERoles.SITE_USER }));
    onClose();
  };

  const hasExceededMobileVideoLicences = hasApartmentExceededMVLimit(paidDevicesCount, paidDevicesLimit);

  return (
    <FormProvider {...formMethods}>
      <ActionModal
        icon={<AddIcon />}
        isPrimaryButtonDisabled={!formMethods.formState.isValid}
        muiDialogProps={{ maxWidth: 'xs' }}
        onClose={onClose}
        open={open}
        primaryButtonAction={formMethods.handleSubmit(onSubmit)}
        primaryButtonText={formatMessage(messages.createUserModalSubmit)}
        secondaryButtonText={formatMessage(messages.createUserModalCancel)}
        title={formatMessage(messages.createUserModalTitle)}
      >
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Box py={3}>
            <Stack direction="column" spacing={3}>
              <TextField
                autoFocus
                fullWidth
                type="email"
                helperText={formatMessage(messages.createUserModalEmailHelperText)}
                label={formatMessage(messages.createUserModalEmailLabel)}
                name="email"
                required
              />
              <TextField
                fullWidth
                label={formatMessage(messages.createUserModalFirstNameLabel)}
                name="firstName"
                required
              />
              <TextField
                fullWidth
                label={formatMessage(messages.createUserModalLastNameLabel)}
                name="lastName"
                required
              />
              <Checkbox
                name="mobileVideoActivated"
                label={
                  <Typography
                    variant="body1"
                    color={
                      hasExceededMobileVideoLicences || !isActiveSubscriptionsState ? 'secondary.main' : 'primary.main'
                    }
                  >
                    <FormattedMessage {...messages.createUserModalMVLabel} />
                  </Typography>
                }
                sx={{ m: '0 0 0 -10px !important' }}
                disabled={hasExceededMobileVideoLicences || !isActiveSubscriptionsState}
              />
              {(hasExceededMobileVideoLicences || !isActiveSubscriptionsState) && (
                <FilledInfoBar>
                  <Typography variant="body1">
                    <FormattedMessage
                      {...(!isActiveSubscriptionsState
                        ? messages.createUserModalMVUnlicensed
                        : messages.createUserModalMVLimitExceeded)}
                    />
                  </Typography>
                </FilledInfoBar>
              )}
            </Stack>
          </Box>
        </form>
      </ActionModal>
    </FormProvider>
  );
}

export default CreateUserModal;
