import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { palette } from '../../theme';

function getBorderColor(isError: boolean, isActivated: boolean) {
  if (isError) {
    return 'error.main';
  }
  if (isActivated) {
    return 'success.main';
  }
  return palette.borderColor;
}

function Panel({
  children,
  errorMessage,
  isActivated,
  isError,
}: {
  children: React.ReactNode;
  isError: boolean;
  errorMessage: string;
  isActivated?: boolean;
}) {
  return (
    <>
      <Box
        sx={{
          bgcolor: isActivated ? 'success.light' : 'subscription.backgroundLight',
          border: `1px solid`,
          borderColor: getBorderColor(isError, isActivated ?? false),
          display: {
            lg: 'flex',
          },
          minHeight: 100,
          position: 'relative',
          px: 2,
          py: 3.5,
        }}
      >
        {children}
      </Box>
      {isError && (
        <Typography sx={{ color: 'error.main', pt: 1, textAlign: 'center' }} variant="body2">
          {errorMessage}
        </Typography>
      )}
    </>
  );
}

export default Panel;
