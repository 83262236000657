import Link from '@mui/material/Link';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DRIVER_LINK, WIKI_2N } from '../../config/address';
import Alert from '../Alert';
import TextList from '../TextList';
import messages from './messages';

function ReaderInfo() {
  const { formatMessage } = useIntl();
  return (
    <Alert severity="info" title={formatMessage(messages.readerInfoTitle)}>
      <TextList>
        <li>
          <FormattedMessage
            {...messages.readerInfoUse}
            values={{
              downloadLink: (
                <Link href={DRIVER_LINK}>
                  <FormattedMessage
                    {...messages.readerInfoDownloadLink}
                    values={{
                      sup: (chunks) => <sup>{chunks}</sup>,
                    }}
                  />
                </Link>
              ),
            }}
          />
        </li>
        <li>
          <FormattedMessage {...messages.readerInfoDownload} />
        </li>
        <li>
          <FormattedMessage
            {...messages.readerInfoGuide}
            values={{
              manualLink: (
                <Link href={`${WIKI_2N}/pages/viewpage.action?pageId=73466456`} target="_blank">
                  <FormattedMessage {...messages.readerInfoManualLink} />
                </Link>
              ),
            }}
          />
        </li>
      </TextList>
    </Alert>
  );
}

export default ReaderInfo;
