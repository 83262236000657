import AddIcon from '@mui/icons-material/Add';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Link } from '../../components';
import { PATHS } from '../../config/consts';
import { IModalBase } from '../../modules/commonTypes';
import ActionModal from '../_DesignSystemWrappers/Modal/ActionModal';
import Checkbox from '../HookFormFields/Checkbox';
import { OutlinedTextField } from '../HookFormFields/TextField';
import { useGetCreateCompanyData } from './hooks/useGetCreateCompanyData';
import messages from './messages';

function CreateCompanyModal({ onClose, open }: IModalBase) {
  const { formatMessage, formMethods, onSubmit } = useGetCreateCompanyData();

  return (
    <ActionModal
      icon={<AddIcon />}
      isPrimaryButtonDisabled={!formMethods.formState.isValid || formMethods.formState.isSubmitSuccessful}
      onClose={onClose}
      open={open}
      muiDialogProps={{ maxWidth: 'xs' }}
      primaryButtonAction={formMethods.handleSubmit(onSubmit)}
      primaryButtonText={formatMessage(messages.addCompanyModalSubmit)}
      secondaryButtonText={formatMessage(messages.addCompanyModalCancel)}
      title={formatMessage(messages.addCompanyModalTitle)}
    >
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Stack spacing={3} py={2}>
            <Typography>
              <FormattedMessage {...messages.addCompanyModalDescription} />
            </Typography>
            <OutlinedTextField
              fullWidth
              helperText={formatMessage(messages.addCompanyModalLabelHelperNameCompany)}
              id="name"
              label={formatMessage(messages.addCompanyModalLabelNameCompany)}
              name="name"
              required
            />
            <Checkbox
              id="agree"
              label={
                <FormattedMessage
                  {...messages.addCompanyModalAgreeLabel}
                  values={{
                    cloudLink: (chunks) => (
                      <Link target="_blank" to={PATHS.CLOUD_AGREEMENT} underline="hover">
                        {chunks}
                      </Link>
                    ),
                    consumerLink: (chunks) => (
                      <Link target="_blank" to={PATHS.INFORMATION_FOR_CONSUMERS} underline="hover">
                        {chunks}
                      </Link>
                    ),
                  }}
                />
              }
              name="agree"
              required
              sx={{
                '& .MuiCheckbox-root': { ml: -1.5 },
                '& .MuiStack-root': { display: 'block !important' },
              }}
            />
          </Stack>
        </form>
      </FormProvider>
    </ActionModal>
  );
}

export default CreateCompanyModal;
