import * as Yup from 'yup';
import http from '../api';
import messages from './messages';

export function getCreateSiteValidationSchema(companyId, formatMessage) {
  return {
    name: Yup.string()
      .max(255, formatMessage(messages.createSiteModalValidationSchemaMaxLengthName))
      .test({
        message: formatMessage(messages.createSiteModalValidationSchemaExistName),
        name: 'isExistName',
        test: async (name) => {
          try {
            if (!name || name.length === 0) {
              return true;
            }
            const response = await http.head(`/companies/${companyId}/sites`, { params: { name } });
            return response.status === 404;
          } catch (error) {
            return true;
          }
        },
      })
      .required(formatMessage(messages.createSiteModalValidationSchemaRequired)),
  };
}
