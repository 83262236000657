import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import mvAutomaticConfigImg from '../../assets/mv_automatic_config.svg';
import messages from './messages';

const styles = {
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    px: 2,
    py: 9,
  },
  description: {
    fontSize: '16px',
  },
  image: {
    display: {
      md: 'block',
      xs: 'none',
    },
    pl: {
      md: 4,
      xs: 0,
    },
  },
  root: {
    backgroundColor: 'background.default',
    width: '100%',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'medium',
    mb: 3,
  },
  wrapper: {
    maxWidth: '1050px',
    mx: 'auto',
    width: '100%',
  },
};

const RealTimeSynchronization = forwardRef((_, ref) => (
  <Box ref={ref} sx={styles.root}>
    <Box sx={styles.wrapper}>
      <Box sx={styles.content}>
        <Box>
          <Typography component="h3" sx={styles.title}>
            <FormattedMessage {...messages.mvideoRealTimeSynchronizationTitle} />
          </Typography>
          <Typography paragraph sx={styles.description}>
            <FormattedMessage
              {...messages.mvideoRealTimeSynchronizationDescription}
              values={{ sup: (chunks) => <sup>{chunks}</sup> }}
            />
          </Typography>
          <Typography paragraph sx={styles.description}>
            <FormattedMessage
              {...messages.mvideoRealTimeSynchronizationText}
              values={{
                strong: (chunks) => <strong>{chunks}</strong>,
                sup: (chunks) => <sup>{chunks}</sup>,
              }}
            />
          </Typography>
        </Box>
        <Box sx={styles.image}>
          <img alt="Real-time synchronization of each device" src={mvAutomaticConfigImg} />
        </Box>
      </Box>
    </Box>
  </Box>
));

export default RealTimeSynchronization;
