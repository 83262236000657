import { defineMessages } from 'react-intl';

export default defineMessages({
  suspiciousBody: {
    defaultMessage: 'You have been successfully unsubscribed from login notifications.',
    description: 'You have been successfully unsubscribed from login notifications.',
    id: 'suspicious.body',
  },
  suspiciousTitle: {
    defaultMessage: 'Login notifications unsubscribe',
    description: 'Login notifications unsubscribe',
    id: 'suspicious.title',
  },
});
