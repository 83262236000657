import { elevatorIntegrationApi } from './elevatorIntegrationApi';

export type ElevatorIntegrationTagTypes = 'elevator-integration';
export const elevatorIntegrationApiEnhancer = elevatorIntegrationApi.enhanceEndpoints<ElevatorIntegrationTagTypes>({
  addTagTypes: ['elevator-integration'],
  endpoints: {
    getSiteBuildingTopology: {
      providesTags: (_result, _error, { companyId, siteId }) => [
        {
          id: `${companyId}-${siteId}`,
          type: 'elevator-integration',
        },
      ],
    },
    refreshSiteBuildingTopology: {
      invalidatesTags: (_result, _error, { companyId, siteId }) => [
        {
          id: `${companyId}-${siteId}`,
          type: 'elevator-integration',
        },
      ],
    },
    updateSiteFloorMapping: {
      invalidatesTags: [{ id: 'floor-mapping', type: 'elevator-integration' }],
    },
  },
});
