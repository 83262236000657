import Box from '@mui/material/Box';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import CustomDate from '../CustomDate';
import messages from './messages';

interface IDocumentDateInfoProps {
  effective: string;
  posted: string;
}

const DocumentDateInfo = ({ effective, posted }: IDocumentDateInfoProps) => (
  <Box color="text.secondary" component="span">
    <FormattedMessage
      {...messages.documentDateInfoDocumentDateInfoInfo}
      values={{
        effectiveDate: (
          <Box color="text.primary" component="span">
            <CustomDate dateValue={effective} isLegalDocument />
          </Box>
        ),
        postedDate: (
          <Box color="text.primary" component="span">
            <CustomDate dateValue={posted} isLegalDocument />
          </Box>
        ),
        span: <Box component="span" px={2} />,
      }}
    />
  </Box>
);

export default DocumentDateInfo;
