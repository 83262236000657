import Badge from '@mui/material/Badge';
import PropTypes from 'prop-types';
import React from 'react';

const TabBadge = ({ badgeContent, children, isTabActive }) => (
  <Badge
    badgeContent={badgeContent}
    showZero
    sx={{
      '& .MuiBadge-badge': {
        bgcolor: isTabActive ? 'primary.main' : 'text.secondary',
        color: 'common.white',
        right: -16,
      },
    }}
  >
    {children}
  </Badge>
);

TabBadge.propTypes = {
  badgeContent: PropTypes.number.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.any]).isRequired,
  isTabActive: PropTypes.bool.isRequired,
};

export default TabBadge;
