import { useMediaQuery, useTheme } from '@mui/material';

export const useBreakpoints = () => {
  const theme = useTheme();

  return {
    downLg: useMediaQuery(theme.breakpoints.down('lg')),
    downMd: useMediaQuery(theme.breakpoints.down('md')),
    downSm: useMediaQuery(theme.breakpoints.down('sm')),
    downXl: useMediaQuery(theme.breakpoints.down('xl')),
    upLg: useMediaQuery(theme.breakpoints.up('lg')),
    upMd: useMediaQuery(theme.breakpoints.up('md')),
    upSm: useMediaQuery(theme.breakpoints.up('sm')),
    upXs: useMediaQuery(theme.breakpoints.up('xs')),
  };
};

export default useBreakpoints;
