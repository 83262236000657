import { useSelector } from 'react-redux';
import { Roles } from '../modules/commonTypes';
import { selectors } from '../modules/sites';
import { getCurrentUserSelector } from '../modules/user/selectors';

export function useGetActualSiteId() {
  return useSelector(selectors.getSiteId);
}

export function useGetCurrentUserSiteRoles(companyId?: number, siteId?: number) {
  const currentUser = useSelector(getCurrentUserSelector) as {
    companies: { id: number; sites: { id: number; roles: Roles[] }[] }[];
  };

  if (companyId === undefined || siteId === undefined) {
    return [];
  }

  return currentUser.companies?.find((company) => company.id === companyId)?.sites?.find((site) => site.id === siteId)
    ?.roles;
}
