import { useSelector } from 'react-redux';
import { Roles } from '../modules/commonTypes';
import { selectors } from '../modules/company';
import { getCurrentUserSelector } from '../modules/user/selectors';

export function useGetActualCompanyId() {
  return useSelector(selectors.getCompanyId);
}

export function useGetCurrentUserCompanyRoles(companyId?: number): Roles[] | undefined {
  const currentUser = useSelector(getCurrentUserSelector) as { companies: { roles: Roles[]; id: number }[] };
  if (companyId === undefined) {
    return [];
  }

  return currentUser.companies?.find((company) => company.id === companyId)?.roles;
}
