import { defineMessages } from 'react-intl';

export default defineMessages({
  supportClear: {
    defaultMessage: 'Clear',
    description: 'Support - clear button',
    id: 'support.clear',
  },
  supportFaq: {
    defaultMessage: 'Or you {link}, where you may find the answer right away.',
    description: 'Support - faq',
    id: 'support.faq',
  },
  supportFaqCheck: {
    defaultMessage: 'can check our FAQs',
    description: 'faq check',
    id: 'support.faqCheck',
  },
  supportInfo: {
    defaultMessage: "For assistance, contact our technical support. We'll reply within",
    description: 'Support - info',
    id: 'support.info',
  },
  supportPlaceholder: {
    defaultMessage: 'Please explain your problem in English.',
    description: 'Support - placeholder',
    id: 'support.placeholder',
  },
  supportReportTitle: {
    defaultMessage: 'Report an issue',
    description: 'Support - Report title',
    id: 'support.reportTitle',
  },
  supportSend: {
    defaultMessage: 'Send',
    description: 'Support - send button',
    id: 'support.send',
  },
  supportTextValidate: {
    defaultMessage: 'This field is mandatory.',
    description: 'Support - This field is mandatory.',
    id: 'support.textValidate',
  },
  supportTitle: {
    defaultMessage: 'Technical support',
    description: 'Support - title',
    id: 'support.title',
  },
});
