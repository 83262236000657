import { defineMessages } from 'react-intl';

export default defineMessages({
  switchPanelItemActive: {
    defaultMessage: 'Active',
    description: '',
    id: 'switchPanelItem.active',
  },
  switchPanelItemAllCodes: {
    defaultMessage: 'All codes',
    description: '',
    id: 'switchPanelItem.allCodes',
  },
  switchPanelItemCanceled: {
    defaultMessage: 'Canceled',
    description: '',
    id: 'switchPanelItem.canceled',
  },
  switchPanelItemInfo: {
    defaultMessage: 'Info',
    description: '',
    id: 'switchPanelItem.info',
  },
  switchPanelItemUsed: {
    defaultMessage: 'Used',
    description: '',
    id: 'switchPanelItem.used',
  },
});
