import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IMPORT_USERS_ALERT_COOKIE, IMPORT_USERS_STATES } from '../../config/consts';
import { useCookie } from '../../helpers/cookies';
import actions, { selectors } from '../../modules/user';
import AlertFinished from './AlertFinished';
import AlertProcessing from './AlertProcessing';

const SiteUsersImportAlertStatus = ({ companyId, didInvalid, onShowImportModal, siteId }) => {
  const dispatch = useDispatch();
  const { getCookie, setFunctionalCookie } = useCookie();
  const [isDisplayAlert, setDisplayAlert] = useState(getCookie(IMPORT_USERS_ALERT_COOKIE) !== 'false');
  const currentImportUsersData = useSelector(selectors.currentImportUsersData);

  const onCloseAlert = () => {
    setFunctionalCookie(IMPORT_USERS_ALERT_COOKIE, false);
    setDisplayAlert(false);
  };

  useEffect(() => {
    dispatch(actions.getImportUsersStatus(companyId, siteId));
  }, []);

  useEffect(() => {
    setDisplayAlert(getCookie(IMPORT_USERS_ALERT_COOKIE) !== 'false');
  }, [didInvalid]);

  if (isDisplayAlert === false) {
    return null;
  }

  if (currentImportUsersData) {
    if (currentImportUsersData.state === IMPORT_USERS_STATES.PROCESSING) {
      return <AlertProcessing onClose={onCloseAlert} onShowImportModal={onShowImportModal} />;
    }

    return <AlertFinished data={currentImportUsersData} onClose={onCloseAlert} onShowImportModal={onShowImportModal} />;
  }

  return null;
};

SiteUsersImportAlertStatus.propTypes = {
  companyId: PropTypes.number.isRequired,
  currentImportData: PropTypes.shape({
    failed: PropTypes.number,
    failedRows: PropTypes.arrayOf(
      PropTypes.shape({
        email: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        result: PropTypes.string,
        rowNumber: PropTypes.number,
      })
    ),
    processed: PropTypes.number,
    state: PropTypes.oneOf([
      IMPORT_USERS_STATES.PROCESSING,
      IMPORT_USERS_STATES.SUCCESS,
      IMPORT_USERS_STATES.FAILURE,
      IMPORT_USERS_STATES.ABORTED,
    ]),
    total: PropTypes.number,
  }),
  didInvalid: PropTypes.bool.isRequired,
  onShowImportModal: PropTypes.func.isRequired,
  siteId: PropTypes.number.isRequired,
};

SiteUsersImportAlertStatus.defaultProps = {
  currentImportData: null,
};

export default SiteUsersImportAlertStatus;
