import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import React from 'react';
import { useNotification } from '../../components';
import CookieConsent from '../../containers/CookieConsent';
import { Router } from '../../routes/Routes';
import AppContextProvider from './AppContextProvider';
import Dialogs from './Dialogs';

export function App() {
  useNotification();

  return (
    <AppContextProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CookieConsent />
        <Router />
        <Dialogs />
      </LocalizationProvider>
    </AppContextProvider>
  );
}
