import DoneIcon from '@mui/icons-material/Done';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import { CodeMaskedInput, FormikFields, LoadingButton } from '../../../../components';
import messages from './messages';

const CodeVerification = ({ isLoading, onCodeVerification }) => {
  const validationSchema = Yup.object().shape({
    code: Yup.string()
      .required(<FormattedMessage {...messages.codeVerificationCodeInputRequired} />)
      .matches(
        /^([0-9,a-z,A-Z]{4})-([0-9,a-z,A-Z]{4})-([0-9,a-z,A-Z]{4})-([0-9,a-z,A-Z]{4})$/,
        <FormattedMessage {...messages.codeVerificationCodeInputInvalid} />
      ),
  });

  const handleCodeVerification = ({ code }) => onCodeVerification(code);

  return (
    <Box py={2}>
      <Formik
        initialValues={{
          code: '',
        }}
        onSubmit={handleCodeVerification}
        render={({ errors, isValid, touched, values }) => (
          <Form>
            <Grid alignContent="stretch" container direction="column" spacing={2}>
              <Grid item xs>
                <Box maxWidth={256}>
                  <FormikFields.Input
                    error={touched.code && !isValid}
                    fullWidth
                    helperText={errors.code ? errors.code : null}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      style: {
                        fontFamily: 'monospace',
                        fontSize: 16,
                      },
                    }}
                    InputProps={{ inputComponent: CodeMaskedInput }}
                    label={<FormattedMessage {...messages.codeVerificationCodeInputLabel} />}
                    name="code"
                    placeholder="****-****-****-****"
                    required
                    value={values.code}
                  />
                </Box>
              </Grid>
              <Grid item>
                <LoadingButton
                  color="primary"
                  disabled={!isValid || isLoading}
                  isLoading={isLoading}
                  startIcon={<DoneIcon />}
                  type="submit"
                  variant="contained"
                >
                  <FormattedMessage {...messages.codeVerificationButton} />
                </LoadingButton>
              </Grid>
            </Grid>
          </Form>
        )}
        validationSchema={validationSchema}
      />
    </Box>
  );
};

CodeVerification.propTypes = {
  activationCodeError: PropTypes.shape({
    data: PropTypes.shape({}),
    status: PropTypes.number,
  }).isRequired,
  codeData: PropTypes.shape({
    configuration: PropTypes.shape({
      credit: PropTypes.number,
    }),
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  onCodeVerification: PropTypes.func.isRequired,
};

export default CodeVerification;
