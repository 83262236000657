import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import OutlinedTextField from '../../../../components/HookFormFields/TextField';
import { DEVICE_TYPE } from '../../../../config/devices';
import { SITE_TYPE } from '../../../../config/sites';
import { IFloor } from '../../../../modules/apartments/store';
import { selectors as deviceSelectors } from '../../../../modules/devices';
import { selectors as sitesSelectors } from '../../../../modules/sites';
import { IUser } from '../../../../modules/user/store';
import { ApartmentFormField } from '../../components/ApartmentFormField/ApartmentFormField';
import { NewApartmentForm } from '../../components/NewApartmentForm/NewApartmentForm';
import UserSelectField from '../../components/UserSelectField';
import { isAvailableForApartment } from '../../helpers';
import { IFormData } from '../validationSchema';
import messages from './messages';

export interface IOneStep {
  floors: IFloor[];
  siteType: SITE_TYPE;
}

export function OneStep({ floors, siteType }: IOneStep) {
  const deviceTypes = useSelector(deviceSelectors.getCompanyDeviceTypes);
  const availableForApartment = isAvailableForApartment(deviceTypes, DEVICE_TYPE.THIRD_PARTY);
  const users = useSelector(sitesSelectors.getSiteUsers) as IUser[];
  const { getValues, setValue, watch } = useFormContext<IFormData>();
  const isSiteMdu = siteType === SITE_TYPE.MDU;
  const firstName = getValues('firstName');
  const lastName = getValues('lastName');
  const name = getValues('name');
  const isCreatingApartment = watch('hasForm');
  const userId = getValues('userId');

  React.useEffect(() => {
    const user = users.find((u) => u.id === userId);
    if (user !== undefined && name === '') {
      setValue('name', `${user.lastName} ${user.firstName}`);
    }
  }, [userId]);

  const handleSurNameBlur = () => {
    if (lastName && firstName) {
      setValue('name', `${lastName} ${firstName}`);
    }
  };

  return (
    <>
      <Typography sx={{ mb: 2 }} variant="body2">
        <FormattedMessage {...messages.oneStepDescription} />
      </Typography>
      <UserSelectField onSurNameBlur={handleSurNameBlur} users={users} />
      <Grid container spacing={2}>
        <Grid item xs>
          <ApartmentFormField disabled={!availableForApartment} required={isSiteMdu} />
        </Grid>
        <Grid item xs>
          <OutlinedTextField
            autoFocus
            helperText={<FormattedMessage {...messages.oneStepNameHelperText} />}
            label={<FormattedMessage {...messages.oneStepNameLabel} />}
            name="name"
            required
            fullWidth
            margin="normal"
          />
        </Grid>
      </Grid>
      {isCreatingApartment && <NewApartmentForm floors={floors} isSiteMdu={isSiteMdu} />}
    </>
  );
}
