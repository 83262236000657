import { useSelector } from 'react-redux';
import * as apartmentSelectors from '../../../modules/apartments/selectors';
import * as userSelectors from '../../../modules/user/selectors';

export function useGetUserApartmentModalData() {
  const { initialData, isApartmentLoading, isModalFetching, optionsData, userData } = {
    initialData: useSelector(apartmentSelectors.getInitialApartmentsList),
    isApartmentLoading: useSelector(apartmentSelectors.getApartmentsListIsFetching),
    isModalFetching: useSelector(userSelectors.getUserApartmentModalIsFetching),
    optionsData: useSelector(apartmentSelectors.getApartmentsListOrdered),
    userData: useSelector(userSelectors.getUserApartmentModalData),
  };

  return {
    initialData,
    isApartmentLoading,
    isModalFetching,
    optionsData,
    userData,
  };
}
