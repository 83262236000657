import { defineMessages } from 'react-intl';

export default defineMessages({
  unlockSettingsButtonLabel: {
    defaultMessage: 'Save',
    description: 'Save button label',
    id: 'unlockSettings.Button.label',
  },
  unlockSettingsErrorText: {
    defaultMessage: 'The code can contain 1 to 15 digits including * or #',
    description: 'Unlock settings text field error text',
    id: 'unlockSettings.errorText',
  },
  unlockSettingsHeader: {
    defaultMessage:
      'This DTMF code will be sent to the intercom once the Unlock button on' + ' the answering unit is pressed.',
    description: 'Unlock settings header text',
    id: 'unlockSettings.header',
  },
  unlockSettingsHelperText: {
    defaultMessage: 'The code can contain 1 to 15 digits including * or #',
    description: 'Unlock settings text field helper text',
    id: 'unlockSettings.helperText',
  },
  unlockSettingsLabel: {
    defaultMessage: 'Default unlock code',
    description: 'Unlock settings text field label',
    id: 'unlockSettings.label',
  },
  unlockSettingsPlaceholder: {
    defaultMessage: 'Code',
    description: 'Unlock settings text field placeholder',
    id: 'unlockSettings.placeholder',
  },
});
