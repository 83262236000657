import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { dataGridProps, getDataGridSelector } from '../../helpers/dataGrid';
import { useUrlParams } from '../../helpers/urlParams';
import { useModal } from '../../hooks/useModal';
import actions, { NAME as ACCESS_SETTINGS_MODULE, selectors } from '../../modules/accessSettings';
import AccessGroupAddApartmentModal from '../AccessGroupAddApartmentModal';
import AccessGroupAddDeviceModal from '../AccessGroupAddDeviceModal';
import AccessGroupAddUsersModalComponent from '../AccessGroupAddUsersModal';
import AccessGroupDeleteModal from '../AccessGroupDeleteModal';
import DataGrid from './DataGrid';

const AccessSettingsDataGrid = ({
  accessSettingsDataGrid,
  data,
  isFetching,
  isInvalid,
  loadAccessGroups,
  onCreateGroup,
}) => {
  const { companyId, siteId } = useUrlParams();
  const [modalOpen, setModalOpen] = useState(null);
  const [modalGroup, setModalGroup] = useState();
  const [modalGroupId, setModalGroupId] = useState();
  const modalType = {
    ADD_APARTMENT: 'ADD_APARTMENT',
    ADD_DEVICE: 'ADD_DEVICE',
    ADD_USER: 'ADD_USER',
    DELETE: 'DELETE',
  };
  const { Modal: AccessGroupAddUsersModal, onOpen: onOpenAccessGroupAddUsersUModal } = useModal({
    Modal: AccessGroupAddUsersModalComponent,
  });

  function handleOpenModal(type, groupId) {
    if (type === modalType.ADD_USER) {
      onOpenAccessGroupAddUsersUModal();
    }
    setModalGroupId(groupId);
    setModalOpen(type);
  }

  function handleOpenDeleteModal(type, group) {
    setModalGroup(group);
    setModalOpen(type);
  }

  function handleCloseModal() {
    return setModalOpen(null);
  }

  return (
    <>
      <DataGrid
        accessSettingsDataGrid={accessSettingsDataGrid}
        companyId={companyId}
        data={data}
        isFetching={isFetching}
        isInvalid={isInvalid}
        loadAccessGroups={loadAccessGroups}
        onAddApartment={(group) => handleOpenModal(modalType.ADD_APARTMENT, group.id)}
        onAddDevice={(group) => handleOpenModal(modalType.ADD_DEVICE, group.id)}
        onAddUser={(group) => handleOpenModal(modalType.ADD_USER, group.id)}
        onCreateGroup={onCreateGroup}
        onDelete={(group) => handleOpenDeleteModal(modalType.DELETE, group)}
        siteId={siteId}
      />
      <AccessGroupDeleteModal group={modalGroup} onClose={handleCloseModal} open={modalOpen === modalType.DELETE} />
      <AccessGroupAddUsersModal groupId={modalGroupId} />
      <AccessGroupAddDeviceModal
        groupId={modalGroupId}
        onClose={handleCloseModal}
        open={modalOpen === modalType.ADD_DEVICE}
      />
      <AccessGroupAddApartmentModal
        groupId={modalGroupId}
        onClose={handleCloseModal}
        open={modalOpen === modalType.ADD_APARTMENT}
      />
    </>
  );
};

AccessSettingsDataGrid.propTypes = {
  accessSettingsDataGrid: dataGridProps.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  isFetching: PropTypes.bool.isRequired,
  isInvalid: PropTypes.bool.isRequired,
  loadAccessGroups: PropTypes.func.isRequired,
  onCreateGroup: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  accessSettingsDataGrid: getDataGridSelector(state, ACCESS_SETTINGS_MODULE, 'groupsList'),
  data: selectors.getAccessGroupsList(state),
  isFetching: selectors.isAccessGroupsListFetching(state),
  isInvalid: selectors.isAccessGroupsListInvalid(state),
});

const mapDispatchToProps = {
  loadAccessGroups: actions.getAccessGroupsList,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessSettingsDataGrid);
