import AddIcon from '@mui/icons-material/Add';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { AccessGroupCreateModal, AccessSettingsDataGrid, DataGridActionButton, Title } from '../../components';
import { useButtonText } from '../../helpers/dataGrid';
import useBreakpoints from '../../helpers/useBreakpoints';
import messages from './messages';

const AccessSettingsOverview = () => {
  const { downSm } = useBreakpoints();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <Title
        heading={<FormattedMessage {...messages.AccessSettingsOverviewTitle} />}
        rightSideActions={
          <DataGridActionButton
            onClick={() => setIsModalOpen(true)}
            startIcon={downSm ? <AddModeratorIcon /> : <AddIcon />}
            variant={downSm ? 'text' : 'contained'}
          >
            {useButtonText(
              messages.AccessSettingsOverviewAddGroupButton,
              messages.AccessSettingsOverviewAddGroupButtonMobile
            )}
          </DataGridActionButton>
        }
      />
      <AccessGroupCreateModal onClose={() => setIsModalOpen(false)} open={isModalOpen} />
      <AccessSettingsDataGrid onCreateGroup={() => setIsModalOpen(true)} />
    </>
  );
};

export default AccessSettingsOverview;
