import { all, call, put, SagaReturnType, select, takeLatest } from 'redux-saga/effects';
import * as Api from '../../api/apartmentApiTS';
import { ISiteGenericRequestData } from '../../api/siteRequest';
import * as oldUserApi from '../../api/userApi';
import * as userApi from '../../api/userApiTS';
import * as helpers from '../apartments/helpers';
import { Roles, RolesType, TId, TPaginationParams } from '../commonTypes';
import { selectors as companySelectors } from '../company';
import { ICompany } from '../company/store';
import { errorHelper, responseValidateStatusOrThrow } from '../helpers';
import { addSnackbarSuccess } from '../notifications/actions';
import { IError } from '../reduxTypes';
import { processError } from '../sagaHelpers';
import { selectors as siteSelectors } from '../sites';
import { ISite } from '../sites/store';
import userMessages from '../user/messages';
import { ICurrentUser, IUser } from '../user/store';
import { types } from './index';
import messages from './messages';

export function* createMultipleUsersSaga(action: {
  type: string;
  companyId: TId;
  siteId: TId;
  apartmentId: TId;
  data: Api.ICreateMultipleUsersData;
}) {
  const { apartmentId, companyId, data, siteId } = action;
  try {
    const response: SagaReturnType<typeof Api.createMultipleUsers> = yield call(
      Api.createMultipleUsers,
      { companyId, siteId },
      apartmentId,
      data
    );
    yield responseValidateStatusOrThrow(response);
    yield put({
      type: types.CREATE_MULTIPLE_APARTMENT_USERS_SUCCESS,
    });
    yield put(addSnackbarSuccess(messages.apartmentSnackbarUsersCreatedSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.CREATE_MULTIPLE_APARTMENT_USERS_FAILURE, error));
  }
}

export function* getApartmentUsersSaga(action: {
  apartmentId: TId;
  companyId: TId;
  filter: string;
  page: number;
  params: TPaginationParams;
  rowsPerPage: number;
  siteId: TId;
  order: string;
  type: string;
}) {
  const { apartmentId, companyId, filter, order, page, params, rowsPerPage, siteId } = action;
  try {
    const response: SagaReturnType<typeof Api.getApartmentUsers> = yield call(
      Api.getApartmentUsers,
      { companyId, siteId },
      apartmentId,
      params
    );
    yield responseValidateStatusOrThrow(response);
    const { resultsFiltered, resultsTotal, users } = response.data;
    yield put({
      filter,
      order,
      page,
      payload: {
        data: users,
        resultsFiltered,
        resultsTotal,
      },
      rowsPerPage,
      type: types.GET_APARTMENT_USERS_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_APARTMENT_USERS_FAILURE, error));
  }
}

export function* deleteApartmentUserSaga(action: { type: string; user: IUser }) {
  let response: SagaReturnType<typeof userApi.deleteUser>;
  const company: ICompany = yield select(companySelectors.getCompanyData);
  const site: ISite = yield select(siteSelectors.getSiteData);
  try {
    response = yield call(
      userApi.deleteUser,
      {
        companyId: company.id,
        siteId: site.id,
      } as ISiteGenericRequestData,
      action.user
    );
    yield responseValidateStatusOrThrow(response);
    yield put(addSnackbarSuccess(userMessages.userSnackbarRemovedSuccessfully));
    yield put({ type: types.DELETE_APARTMENT_USER_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.DELETE_APARTMENT_USER_FAILURE, error));
  }
}

export function* grantApartmentAdmin(action: { type: string; apartmentId: TId; userId: TId }) {
  try {
    const { apartmentId, userId } = action;
    const response: { status: number; data: ICurrentUser } = yield call(
      oldUserApi.setRole,
      userId,
      helpers.apartmentUserHandler(apartmentId, RolesType.GRANTED_ROLES, [Roles.APARTMENT_ADMIN])
    );
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.GRANT_APARTMENT_ADMIN_SUCCESS });
    yield put(addSnackbarSuccess(messages.AAModalGrantRightsMsgSucc));
  } catch (error) {
    yield processError(types.GRANT_APARTMENT_ADMIN_FAILURE, error, {
      ...(error as IError).data,
      message: messages.AAModalGrantRightsMsgErr,
    });
  }
}

export function* revokeApartmentAdmin(action: { type: string; apartmentId: TId; userId: TId }) {
  try {
    const { apartmentId, userId } = action;
    const response: { status: number; data: ICurrentUser } = yield call(
      oldUserApi.setRole,
      userId,
      helpers.apartmentUserHandler(apartmentId, RolesType.REVOKED_ROLES, [Roles.APARTMENT_ADMIN])
    );
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.REVOKE_APARTMENT_ADMIN_SUCCESS });
    yield put(addSnackbarSuccess(messages.AAModalRevokeRightsMsgSucc));
  } catch (error) {
    yield processError(types.REVOKE_APARTMENT_ADMIN_FAILURE, error, {
      ...(error as IError).data,
      message: messages.AAModalRevokeRightsMsgErr,
    });
  }
}

export default function* apartmentsSagas() {
  yield all([
    takeLatest(types.CREATE_MULTIPLE_APARTMENT_USERS_REQUEST, createMultipleUsersSaga),
    takeLatest(types.GET_APARTMENT_USERS_REQUEST, getApartmentUsersSaga),
    takeLatest(types.DELETE_APARTMENT_USER_REQUEST, deleteApartmentUserSaga),
    takeLatest(types.GRANT_APARTMENT_ADMIN_REQUEST, grantApartmentAdmin),
    takeLatest(types.REVOKE_APARTMENT_ADMIN_REQUEST, revokeApartmentAdmin),
  ]);
}
