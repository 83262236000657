import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

const styles = {
  disabled: {
    border: '1px solid',
    borderColor: 'action.disabled',
    cursor: 'default',
    opacity: '.54',
    pointerEvents: 'none',
  },
  rightCornerIcon: {
    backgroundColor: 'background.light',
    color: 'primary.main',
  },
  root: {
    '& .icon': {
      display: 'block',
      fontSize: 48,
      height: '50px',
      margin: '0 auto 0 auto',
      width: '50px',
    },
    '&:hover': {
      border: '1px solid',
      borderColor: 'primary.main',
    },
    alignItems: 'flex-start',
    backgroundColor: 'common.white',
    border: '1px solid',
    borderColor: 'action.active',
    color: 'text.primary',
    height: '100%',
    padding: 2,
    textDecoration: 'none',
    width: '100%',
  },
  title: {
    textTransform: 'uppercase',
  },
};

const Card = ({ children, icon, isDisabled, path, rightCornerIcon, title, ...other }) => (
  <ButtonBase {...other} component={Link} sx={[styles.root, isDisabled ? styles.disabled : {}]} to={path}>
    <Box>
      <Grid alignItems="center" container direction="column" spacing={2} sx={{ textAlign: 'center' }}>
        <Grid container direction="row" justifyContent="flex-end">
          <Grid item>
            {rightCornerIcon && (
              <Box sx={{ height: 20, pt: 2 }}>
                <Avatar sx={styles.rightCornerIcon}>{rightCornerIcon}</Avatar>
              </Box>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Box sx={{ pt: rightCornerIcon ? 0 : 2.5 }}>
            <Box sx={{ height: 50, width: 50 }}>{icon}</Box>
          </Box>
        </Grid>
        <Grid item>
          <Typography sx={styles.title} variant="h5">
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <Typography>{children}</Typography>
        </Grid>
      </Grid>
    </Box>
  </ButtonBase>
);

Card.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  icon: PropTypes.node.isRequired,
  isDisabled: PropTypes.bool,
  path: PropTypes.string.isRequired,
  rightCornerIcon: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

Card.defaultProps = {
  isDisabled: false,
  rightCornerIcon: null,
};

export default Card;
