import { call } from 'redux-saga/effects';
import * as Api from '../../../api/deviceApi';
import { ROLES } from '../../../config/consts';
import { CONNECTION_MODE } from '../../../config/devices';
import { createExternalId } from '../../../helpers/common';
import { responseValidateStatusOrThrow } from '../../../modules/helpers';
import { getDevicesTypesSettings, getSelectedUserData, getServices } from './helpers';

// eslint-disable-next-line max-statements
export default function* hipmoOrThirdPartyComposite({ company, deviceType, floors, site, values }) {
  const newDevice = {
    companyId: company.id,
    siteId: site.id,
  };

  const settings = yield getDevicesTypesSettings(company.id, deviceType);
  const services = yield getServices(settings, {
    ...values,
    connectionMode: CONNECTION_MODE.CLOUD,
    mobileVideo: true,
  });

  let userId = values.userId || null;
  if (!values.existUser) {
    newDevice.user = {
      email: values.email,
      firstName: values.firstName,
      grantedRoles: {
        companyRoles: [{ companyId: company.id, types: [ROLES.STANDARD] }],
        siteRoles: [{ siteId: site.id, types: [ROLES.USER] }],
        systemRoles: [],
      },
      lastName: values.lastName,
    };
  } else {
    const selectedUser = yield getSelectedUserData(values);

    newDevice.user = {
      email: selectedUser.email,
      firstName: selectedUser.firstName,
      grantedRoles: {},
      lastName: selectedUser.lastName,
    };

    userId = selectedUser.id;
  }

  const floorName = values.floor;
  const apartmentId = parseInt(values.apartmentId, 10) || null;
  if (values.hasForm) {
    let floorId = null;
    const filteredFloor = floors.find((fl) => fl.name === floorName);
    if (!filteredFloor && floorName) {
      newDevice.floor = {
        externalId: createExternalId(),
        name: floorName,
      };
    } else if (filteredFloor) {
      floorId = filteredFloor.id;
    }

    newDevice.apartment = {
      externalId: createExternalId(),
      floorId,
      name: values.apartmentName,
      number: values.number,
    };
  }

  newDevice.device = {
    apartmentId: apartmentId === -1 ? null : apartmentId,
    externalId: createExternalId(),
    name: values.name,
    services,
    type: deviceType,
    userId,
  };

  const response = yield call(Api.addDeviceComposite, newDevice);

  yield responseValidateStatusOrThrow(response);
  return response.data || {};
}
