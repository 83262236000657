import { TCredentials } from './types';

export function getVisibilityOfStatuses({
  hiddenStatuses,
  isUserBleCredentialFFEnable,
}: {
  isUserBleCredentialFFEnable: boolean;
  hiddenStatuses?: TCredentials;
}): { isPinStatusVisible: boolean; isRFIDStatusVisible: boolean; isBTStatusVisible: boolean } {
  const isPinStatusVisible = !hiddenStatuses || !hiddenStatuses?.includes('PIN');
  const isRFIDStatusVisible = !hiddenStatuses || !hiddenStatuses?.includes('RFID');
  const isBTStatusVisible = isUserBleCredentialFFEnable && (!hiddenStatuses || !hiddenStatuses?.includes('BT'));

  return { isBTStatusVisible, isPinStatusVisible, isRFIDStatusVisible };
}
