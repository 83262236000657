import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import CardMedia from '@mui/material/CardMedia';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Paper, Title } from '../../components';
import { PRODUCT_BOARD } from '../../config/address';
import messages from './messages';

const styles = {
  iframe: {
    border: '1px solid',
    borderColor: 'divider',
    height: 615,
    maxWidth: 1048,
    mb: 4,
    mt: 2,
    width: '100%',
  },
  improvementIcon: {
    mx: 0,
    my: 1.5,
  },
  improvementTitle: {
    mb: 2,
  },
};

const AboutPage = () => (
  <>
    <Title heading={<FormattedMessage {...messages.aboutTitle} />} />
    <Paper>
      <Typography>
        <FormattedMessage {...messages.aboutInfo} />
      </Typography>
      <Typography>
        <Link href={PRODUCT_BOARD} rel="noopener" target="productboardportal" underline="hover">
          <FormattedMessage {...messages.aboutProductBoardLink} />
        </Link>
      </Typography>
      <CardMedia component="iframe" frameBorder="0" src={PRODUCT_BOARD} sx={styles.iframe} title="productboardportal" />
      <Typography sx={styles.improvementTitle} variant="h5">
        <FormattedMessage {...messages.aboutImporvementTitle} />
      </Typography>
      <Typography>
        <FormattedMessage {...messages.aboutImporvementInfo} />
      </Typography>
      <Button
        color="primary"
        href={`${PRODUCT_BOARD}/submit-idea`}
        rel="noopener"
        startIcon={<AddIcon />}
        sx={styles.improvementIcon}
        target="productboardportal"
        variant="outlined"
      >
        <FormattedMessage {...messages.aboutImporvementButton} />
      </Button>
    </Paper>
  </>
);

export default AboutPage;
