import CheckIcon from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import ActionModal from '../../../../../components/_DesignSystemWrappers/Modal/ActionModal';
import * as actions from '../../../../../modules/apartmentAdmin/actions';
import { IModalBase, TId } from '../../../../../modules/commonTypes';
import { IDevice } from '../../../../../modules/devices/store/deviceStore';
import { DeviceTypeEnum } from '../../../../../modules/devices/store/storeTypes';
import messages from '../messages';

interface IActivateMVModal extends IModalBase {
  apartmentId: TId;
  companyId: TId;
  device: IDevice | undefined;
  siteId: TId;
  userId: TId;
}

function ActivateMVModal({ apartmentId, companyId, device, onClose, open, siteId, userId }: IActivateMVModal) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const onSubmit = () => {
    device && device.services?.MOBILE_VIDEO !== undefined
      ? dispatch(
          actions.changeDeviceMVStatus(companyId, siteId, apartmentId, device.id, {
            ...device.services.MOBILE_VIDEO,
            active: true,
          })
        )
      : dispatch(
          actions.createDeviceWithinApartment(companyId, siteId, apartmentId, { type: DeviceTypeEnum.HIPMO, userId })
        );
    onClose();
  };

  return (
    <ActionModal
      icon={<CheckIcon />}
      isPrimaryButtonDisabled={false}
      muiDialogProps={{ maxWidth: 'xs' }}
      primaryButtonAction={onSubmit}
      primaryButtonText={formatMessage(messages.AAModalMobileVideoActivateSubmit)}
      secondaryButtonText={formatMessage(messages.AAModalMobileVideoActivateCancel)}
      onClose={onClose}
      open={open}
      title={formatMessage(messages.AAModalMobileVideoActivateTitle)}
    >
      <Typography py={3}>
        <FormattedMessage values={{ br: <br /> }} {...messages.AAModalMobileVideoActivateText} />
      </Typography>
    </ActionModal>
  );
}

export default ActivateMVModal;
