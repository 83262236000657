import React, { forwardRef } from 'react';
import MaskedInput from 'react-text-mask';

export const MacAddressField = forwardRef(({ ...props }, _ref) => {
  return (
    <MaskedInput
      {...props}
      mask={[
        /[A-F0-9]/i,
        /[A-F0-9]/i,
        '-',
        /[A-F0-9]/i,
        /[A-F0-9]/i,
        '-',
        /[A-F0-9]/i,
        /[A-F0-9]/i,
        '-',
        /[A-F0-9]/i,
        /[A-F0-9]/i,
        '-',
        /[A-F0-9]/i,
        /[A-F0-9]/i,
        '-',
        /[A-F0-9]/i,
        /[A-F0-9]/i,
      ]}
    />
  );
});
