import { CommonTypes, IReducers, IReduxAction } from '../reduxTypes';
import { getInitialInfrastructureStore, IInfrastructureStore, IRedirectData } from './store';
import { types } from './types';

const reducers: IReducers<IInfrastructureStore, CommonTypes> = {
  [types.SET_REDIRECT_DATA]: (state: IInfrastructureStore, payload: IRedirectData): IInfrastructureStore => ({
    ...state,
    redirectData: payload,
  }),

  [types.CLEAR_REDIRECT_DATA]: (): IInfrastructureStore => getInitialInfrastructureStore(),
};

export function infrastructureReducers<TValue extends CommonTypes>(
  state = getInitialInfrastructureStore(),
  reducer: IReduxAction<TValue>
): IInfrastructureStore {
  if (reducers[reducer.type]) {
    if (reducer.error !== undefined) {
      return reducers[reducer.type](state, reducer.error);
    }
    return reducers[reducer.type](state, reducer.payload);
  }

  return state;
}
