// eslint-disable-next-line max-classes-per-file
import {
  ContactType,
  DeviceFeatureType,
  IContactItem,
  IDevice,
  IDeviceFeature,
  isApartmentContactItem,
  isButtonOrDisplayConfigurationFeature,
  isContactListConfigurationFeature,
  isRingingGroupContactItem,
} from '../../store/deviceStore';

export class EnrichedContactItem {
  _contactId: number;

  _contactItemIdsMap: Record<ContactType, number | undefined> = {
    APARTMENT: undefined,
    GSM_CALLS: undefined,
    LOCAL_CALLS: undefined,
    MOBILE_VIDEO: undefined,
    RINGING_GROUP: undefined,
  };

  _contactItemOptions: number[] = [];

  _contactItemSipNumber: string | undefined;

  constructor(contactItems: IContactItem[], contactId: number) {
    this._contactId = contactId;
    contactItems.forEach((contactItem) => {
      this._contactItemIdsMap[contactItem.type] = contactItem.id;
      if (isRingingGroupContactItem(contactItem)) {
        this._contactItemOptions = contactItem.members;
        this._contactItemSipNumber = contactItem.sipNumber;
      } else if (isApartmentContactItem(contactItem)) {
        this._contactItemOptions = [contactItem.apartmentId];
        this._contactItemSipNumber = contactItem.sipNumber;
      }
    });
  }
}

export class EnrichedContact {
  _contactToFeaturesMap: Record<DeviceFeatureType, IEnrichedContact | undefined> = {
    BUTTON_CONFIGURATION: undefined,
    CONTACT_LIST: undefined,
    DISPLAY: undefined,
    DTMF_CODE: undefined,
    DTMF_SWITCH_LIST: undefined,
  };

  constructor(editedDevice: IDevice, externalId: string) {
    editedDevice.features?.forEach((feature) => {
      const contactData = getContactItemsByExternalId(feature, externalId);
      this._contactToFeaturesMap[feature.type] = {
        contacts: new EnrichedContactItem(contactData.contactItems, contactData.contactId),
        featureId: feature.id,
      };
    });
  }
}

export interface IEnrichedContact {
  featureId: number;
  contacts: EnrichedContactItem;
}

function getContactItemsByExternalId(
  feature: IDeviceFeature,
  externalId: string
): { contactItems: IContactItem[]; contactId: number } {
  let contactItems: IContactItem[] = [];
  let contactId: number;
  if (isButtonOrDisplayConfigurationFeature(feature)) {
    feature.buttons.forEach((button) => {
      if (button.externalId === externalId) {
        contactItems = button.contacts[0] !== undefined ? button.contacts[0].contactItems : [];
        contactId = button.id;
      }
    });
  }

  if (isContactListConfigurationFeature(feature)) {
    feature.contacts.forEach((contact) => {
      if (contact.externalId === externalId) {
        contactItems = contact.contactItems;
        contactId = contact.id;
      }
    });
  }

  return {
    contactId: contactId!,
    contactItems,
  };
}
