import { defineMessages } from 'react-intl';

export default defineMessages({
  AamodalShowGuestQrSubmit: {
    defaultMessage: 'close',
    id: 'AAmodal.showGuestQR.submit',
  },
  AamodalShowGuestQrText1: {
    defaultMessage:
      'The access code allows your visitors to open doors. You can share it in whatever way is most convenient for you.',
    id: 'AAmodal.showGuestQR.text1',
  },
  AamodalShowGuestQrTitle: {
    defaultMessage: 'Access code',
    id: 'AAmodal.showGuestQR.title',
  },
});
