import { OryLoginStatus } from '../../ory/types';
import { IRootStore } from '../store';

export const isLoggedInSelector = ({ auth }: IRootStore) =>
  !!auth?.accessToken || auth?.oryLoginStatus === OryLoginStatus.SessionConfirmed;
export const getOryLoginStatusSelector = ({ auth }: IRootStore) => auth?.oryLoginStatus;
export const isFirstLogin = ({ auth }: IRootStore) => auth.isFirstLogin;
export const getThrottlingRetryAfterDate = ({ auth }: IRootStore) => auth?.throttlingRetryAfter || undefined;
export const getAccessTokenSelector = ({ auth }: IRootStore) => auth?.accessToken;
export const isLoadingSelector = ({ auth }: IRootStore) => auth?.isFetching;
export const isRegisteredSelector = ({ auth }: IRootStore) => auth.isRegistered;
export const preferUserLogin = ({ auth }: IRootStore) => auth.preferUserLogin;

export const isResendInvitationFetching = ({ auth }: IRootStore) => auth.resendInvitation.isFetching;

export const getResendEmail = ({ auth }: IRootStore) => auth.redirectToResendWithEmail;
