import { defineMessages } from 'react-intl';

export default defineMessages({
  AAApartmentUserListSwitchTooltipAdminRole: {
    defaultMessage: 'Apartment admin rights{br}granted by default',
    id: 'AA.apartmentUserList.switchTooltip.adminRole',
  },
  AAApartmentUserListSwitchTooltipApartmentAdmin: {
    defaultMessage: 'Apartment admin{br}rights granted',
    id: 'AA.apartmentUserList.switchTooltip.apartmentAdmin',
  },
  AAApartmentUserListSwitchTooltipSiteUser: {
    defaultMessage: 'Apartment admin rights{br}not yet granted',
    id: 'AA.apartmentUserList.switchTooltip.siteUser',
  },
  AAApartmentUserListSwitchTooltipUwL: {
    defaultMessage: 'Can’t be granted apartment{br}admin rights',
    id: 'AA.apartmentUserList.switchTooltip.UwL',
  },
});
