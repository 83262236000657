import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { MOBILE_VIDEO_STATUS, PATHS } from '../../config/consts';
import { VERIFICATION_STATUS } from '../../config/devices';
import devicesActions from '../../modules/devices';
import paymentsActions from '../../modules/payments';
import { getSitesServiceMobileVideoLicenceModel } from '../../modules/sites/selectors';
import ButtonLink from '../ButtonLink';
import DeviceDataGridAction from './DeviceDataGridAction';
import messages from './messages';

const ActionButton = ({ device, licenceModel, onOpenAvailableCreditModal, onOpenRCModal, onStartTrial, onVerify }) => {
  const onClick = (event, clickFun = null) => {
    event.stopPropagation();
    if (clickFun) {
      clickFun();
    }
  };

  return (
    <DeviceDataGridAction
      activateTrialComponent={
        <Button color="primary" onClick={(event) => onClick(event, onStartTrial)}>
          <FormattedMessage {...messages.deviceDataGridColumnsDeviceDataGridActionActivateTrial} />
        </Button>
      }
      device={device}
      changePasswordComponent={
        <Button color="primary" onClick={(event) => onClick(event, () => onOpenRCModal(device))}>
          <FormattedMessage {...messages.deviceListChangePasswordButton} />
        </Button>
      }
      licenceModel={licenceModel}
      purchaseComponent={
        <ButtonLink
          color="primary"
          onClick={(event) => onClick(event)}
          to={PATHS.SUBSCRIPTION_MOBILE_VIDEO.replace(':companyId', device?.site?.company?.id).replace(
            ':siteId',
            device?.site?.id
          )}
        >
          <FormattedMessage {...messages.deviceDataGridColumnsDeviceDataGridActionPurchase} />
        </ButtonLink>
      }
      reverifyComponent={
        <Button color="primary" onClick={(event) => onClick(event, () => onVerify(device))}>
          <FormattedMessage {...messages.deviceDataGridColumnsDeviceDataGridActionReverify} />
        </Button>
      }
      useAvailableCreditComponent={
        <Button color="primary" onClick={(event) => onClick(event, onOpenAvailableCreditModal)}>
          <FormattedMessage {...messages.deviceDataGridColumnsDeviceDataGridActionUseAvailableCredit} />
        </Button>
      }
    />
  );
};

ActionButton.propTypes = {
  device: PropTypes.shape({
    callsStatus: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    site: PropTypes.shape({
      company: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      status: PropTypes.string,
    }),
    verificationStatus: PropTypes.oneOf(Object.keys(VERIFICATION_STATUS)),
  }).isRequired,
  licenceModel: PropTypes.shape({
    additional: PropTypes.number,
    licencesPool: PropTypes.number,
    status: PropTypes.oneOf(Object.keys(MOBILE_VIDEO_STATUS)),
    unlicensedCount: PropTypes.number,
  }).isRequired,
  onOpenAvailableCreditModal: PropTypes.func.isRequired,
  onOpenRCModal: PropTypes.func.isRequired,
  onStartTrial: PropTypes.func.isRequired,
  onVerify: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  licenceModel: getSitesServiceMobileVideoLicenceModel(state),
});

const mapDispatchToProps = {
  onOpenRCModal: devicesActions.rcShowModal,
  onStartTrial: paymentsActions.startTrialVersion,
  onVerify: devicesActions.verifyDeviceShowModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActionButton);
