import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

export function useGetGlobalHooks() {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { handleSubmit } = useForm();

  return {
    dispatch,
    formatMessage,
    handleSubmit,
  };
}
