import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';

interface IChipListWrapper {
  children?: React.ReactNode;
  emptyPlaceholder?: string;
}

function ChipListWrapper({ children, emptyPlaceholder }: IChipListWrapper) {
  return (
    <Stack alignItems="flex-start" direction={{ sm: 'row', xs: 'column' }} spacing={1} sx={{ pb: 1 }}>
      {children ? (
        <Grid container>{children}</Grid>
      ) : (
        <Box alignItems="center" display="block">
          <Typography color="text.secondary" component="span" fontWeight="medium" variant="body2">
            {emptyPlaceholder}
          </Typography>
        </Box>
      )}
    </Stack>
  );
}

ChipListWrapper.defaultProps = {
  children: null,
  emptyPlaceholder: '',
};

export default ChipListWrapper;
