import { Skeleton } from '@mui/material';
import React, { ReactChild } from 'react';
import { skeletonTitleWidth } from '../../cssConstants';
import Title from '../Title/Title';

export interface ITitleWithSkeletonProps {
  children: ReactChild;
  isFetching: boolean;
}

export function TitleWithSkeleton(props: ITitleWithSkeletonProps) {
  return <Title heading={props.isFetching ? <Skeleton width={skeletonTitleWidth} /> : props.children} />;
}
