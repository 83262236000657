import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert } from '../../../../components';
import { BILLING_TYPE } from '../../../../config/subscription';
import messages from './messages';

const VerificationSuccessAlert = ({ credit, mobileVideoLicense }) => (
  <Alert severity="success" title={<FormattedMessage {...messages.codeVerificationSuccessHeader} />}>
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <Typography variant="body2">
          <FormattedMessage
            {...messages.codeVerificationSuccessCredits}
            values={{
              count: credit,
              credit: (
                <Box color="primary.main" component="span" fontSize="h5.fontSize">
                  {credit.toFixed(1)}
                </Box>
              ),
            }}
          />
        </Typography>
      </Grid>
      <Grid item>
        <Typography color="textSecondary" variant="body2">
          {mobileVideoLicense.billingType === BILLING_TYPE.PER_APARTMENT ? (
            <FormattedMessage
              {...messages.codeVerificationSuccessInfoPerApartment}
              values={{ count: mobileVideoLicense.combinedMultiplier }}
            />
          ) : (
            <FormattedMessage
              {...messages.codeVerificationSuccessInfoPerDevice}
              values={{ count: mobileVideoLicense.combinedMultiplier }}
            />
          )}
        </Typography>
      </Grid>
    </Grid>
  </Alert>
);

VerificationSuccessAlert.propTypes = {
  credit: PropTypes.number.isRequired,
  mobileVideoLicense: PropTypes.shape({
    billingType: PropTypes.string,
    combinedMultiplier: PropTypes.number,
  }).isRequired,
};

export default VerificationSuccessAlert;
