import { defineMessages } from 'react-intl';

export default defineMessages({
  configureMy2NAccessModal1Text1: {
    defaultMessage: `There are multiple ways to set up a Bluetooth connection. Choosing the right option now is crucial for proper functionality.`,
    id: 'configure.My2NAccess.Modal1.text1',
  },
  configureMy2NAccessModal1Text2: {
    defaultMessage: `If Bluetooth isn't currently in use on this site, the setup is straightforward. If it's already in use, you'll be asked a few additional questions. 
<p>Important details and tips:</p>
<ul><li>Managing access using both ACOM and My2N simultaneously is not supported, as it may cause conflicts.</li>
<li>If you use third-party apps, we’ll suggest a setup for compatibility, but flawless performance isn’t guaranteed.</li>
<li>For added safety, create device backups. Follow our guide.</li></ul>`,
    id: 'configure.My2NAccess.Modal1.text2',
  },
  configureMy2NAccessModal1Warn: {
    defaultMessage: `This setup will affect all users on the site. We’ll guide you through the settings, but proceed only if you're confident in your understanding of the consequences.`,
    id: 'configure.My2NAccess.Modal1.warning',
  },
  configureMy2NAccessModalBack: {
    defaultMessage: 'Back',
    id: 'configure.My2NAccess.Modal.back',
  },
  configureMy2NAccessModalCancel: {
    defaultMessage: 'Cancel',
    id: 'configure.My2NAccess.Modal.cancel',
  },
  configureMy2NAccessModalNext: {
    defaultMessage: 'Next',
    id: 'configure.My2NAccess.Modal.next',
  },
  configureMy2NAccessModalSubmit: {
    defaultMessage: 'Configure',
    id: 'configure.My2NAccess.Modal.submit',
  },
  configureMy2NAccessModalTitle: {
    defaultMessage: 'Configure My2N app access',
    id: 'configure.My2NAccess.Modal.title',
  },
  siteSettingsAccessMy2NappMsgErr: {
    defaultMessage: 'There was an error, please try again',
    id: 'siteSettings.access.My2Napp.msgErr',
  },
  siteSettingsAccessMy2NappMsgSucc: {
    defaultMessage: 'The configuration was successfully saved',
    id: 'siteSettings.access.My2Napp.msgSucc',
  },
});
