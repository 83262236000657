import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import DoneIcon from '@mui/icons-material/Done';
import React from 'react';
import { IntlShape } from 'react-intl';
import { IActionButtonData } from '../_DesignSystemWrappers/designSystemUnexported';
import messages from './messages';

const getPrimaryButton = (
  step: number,
  isSubmit: boolean,
  siteType: string | undefined,
  closeModal: () => void,
  stepIncrease: () => void,
  formatMessage: IntlShape['formatMessage'],
  handleSetType: () => void
): IActionButtonData => {
  const isDisabled = siteType === undefined;

  if (isSubmit) {
    return {
      action: closeModal,
      icon: {
        icon: <CheckIcon />,
        iconPosition: 'start',
      },
      isDisabled,
      text: formatMessage(messages.SelectSiteTypeModalCloseBtn),
    };
  }

  if (step === 2) {
    return {
      action: handleSetType,
      icon: {
        icon: <DoneIcon />,
        iconPosition: 'start',
      },
      isDisabled,
      text: formatMessage(messages.SelectSiteTypeModalSetTypeBtn),
    };
  }

  return {
    action: stepIncrease,
    icon: {
      icon: <ArrowForwardIcon />,
      iconPosition: 'end',
    },
    isDisabled,
    text: formatMessage(messages.SelectSiteTypeModalNextBtn),
  };
};

export default getPrimaryButton;
