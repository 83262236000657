import { IntlFormatters } from 'react-intl';
import { OryPasswordMaxLength } from '../ory/config/const';
import messages from './messages';
import { getNewPasswordValidationSchema } from './newPasswordValidationSchema';

export function getOryPasswordValidationScheme(formatMessage: IntlFormatters['formatMessage']) {
  return getNewPasswordValidationSchema(formatMessage).password.max(
    OryPasswordMaxLength,
    formatMessage(messages.validationMaxLength)
  );
}
