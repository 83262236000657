import { MenuItem } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ConfigurationModeText } from '../../../../components/ConfigurationModeText/ConfigurationModeText';
import Select from '../../../../components/HookFormFields/Select';
import { CONFIGURATION_MODE, CONNECTION_MODE, DEVICE_TYPE } from '../../../../config/devices';
import { getTypeFromDeviceType } from '../../../../helpers/devices';
import { AccessLogCheckbox } from './AccessLogCheckbox';
import messages from './messages';

export interface IConfigurationModeSetup {
  configurationMode: string;
  deviceType: string;
  hasAccessControl: boolean;
}

export function ConfigurationModeSetup({ configurationMode, deviceType, hasAccessControl }: IConfigurationModeSetup) {
  const { formatMessage } = useIntl();
  const type = getTypeFromDeviceType(deviceType);
  let accessConnectionMode = false;
  if (type !== undefined) {
    accessConnectionMode = [
      DEVICE_TYPE.ID_COMPACT,
      DEVICE_TYPE.CLIP,
      DEVICE_TYPE.ID_TALK,
      DEVICE_TYPE.ID_VIEW,
    ].includes(type);
  }

  return (
    <>
      <Typography sx={{ mb: 3 }}>
        <FormattedMessage {...messages.configurationModeSetupDescription} />
      </Typography>
      <Grid container spacing={2}>
        <Grid item sm={accessConnectionMode ? 6 : 12} xs={12}>
          <Select
            helperText={formatMessage(messages.configurationModeSetupConfigurationModeHelperText)}
            label={<FormattedMessage {...messages.configurationModeSetupConfigurationModeLabel} />}
            name="configurationMode"
            required
          >
            <MenuItem value={CONFIGURATION_MODE.AUTOMATIC}>
              <FormattedMessage {...messages.configurationModeSetupConfigurationModeAutomatic} />
            </MenuItem>
            <MenuItem value={CONFIGURATION_MODE.MANUAL}>
              <FormattedMessage {...messages.configurationModeSetupConfigurationModeManual} />
            </MenuItem>
          </Select>
        </Grid>
        {accessConnectionMode && (
          <Grid item sm={6} xs={12}>
            <Select
              helperText={formatMessage(messages.configurationModeSetupConnectionModeHelperText)}
              label={<FormattedMessage {...messages.configurationModeSetupConnectionModeLabel} />}
              name="connectionMode"
              required
            >
              <MenuItem value={CONNECTION_MODE.CLOUD}>
                <FormattedMessage {...messages.configurationModeSetupConnectionModeCloud} />
              </MenuItem>
              <MenuItem value={CONNECTION_MODE.LOCAL}>
                <FormattedMessage {...messages.configurationModeSetupConnectionModeLocal} />
              </MenuItem>
            </Select>
          </Grid>
        )}
      </Grid>
      {!accessConnectionMode && hasAccessControl && <AccessLogCheckbox />}
      <ConfigurationModeText mode={configurationMode} />
    </>
  );
}

ConfigurationModeSetup.propTypes = {
  configurationMode: PropTypes.string.isRequired,
  deviceType: PropTypes.string.isRequired,
  hasAccessControl: PropTypes.bool.isRequired,
};

export default ConfigurationModeSetup;
