import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IDeviceWithCallSettingsDetails } from './hooks/useGetCallSettingsState';
import messages from './messages';

export interface IDeviceSelectData {
  availableDevices: IDeviceWithCallSettingsDetails[];
  onClear: () => void;
  onSelect: (deviceId: number) => void;
  value: number | string;
  isDeviceSelected: (deviceId: number) => boolean;
}

const DeviceSelect = (data: IDeviceSelectData) => (
  <Box alignItems="center" display="flex">
    <FormControl sx={{ minWidth: 'calc(100% - 65px)' }} variant="outlined">
      <InputLabel>
        <FormattedMessage {...messages.intercomSelectLabel} />
      </InputLabel>
      <Select
        label={<FormattedMessage {...messages.intercomSelectLabel} />}
        MenuProps={{
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'bottom',
          },
        }}
        onChange={({ target }) => data.onSelect(target.value as number)}
        value={data.value}
      >
        {data.availableDevices
          .filter((device) => device.canSetCallSettings)
          .map((device) => (
            <MenuItem disabled={data.isDeviceSelected(device.id)} key={device.id} value={device.id}>
              {device.name}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
    {data.value && (
      <Box display="flex" sx={{ ml: 1 }}>
        <IconButton onClick={data.onClear}>
          <CloseIcon />
        </IconButton>
      </Box>
    )}
  </Box>
);

export default DeviceSelect;
