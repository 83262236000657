import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as Api from '../../api/billingApi';
import actions, { selectors as companySelectors } from '../company';
import { errorHelper, responseValidateStatusOrThrow } from '../helpers';
import { addSnackbarSuccess } from '../notifications/actions';
import actionsSubscriptions from '../subscription';
import messages from './messages';
import types from './types';

export function* editBillingShowModal() {
  const company = yield select(companySelectors.getCompanyData);
  const addr = (name) => (company.address && company.address[name] ? company.address[name] : '');
  yield put({
    billingData: {
      city: addr('city'),
      country: addr('country'),
      currency: company.currency || '',
      name: company.name || '',
      postalCode: addr('postalCode'),
      street: addr('street'),
      vatNumber: company.vatId || '',
    },
    type: types.EDIT_BILLING_SHOW_SAGA_MODAL,
  });
}

export function* editBillingFormRequest({ values }) {
  const data = {
    action: 'BILLING_INFO',
    address: {
      city: values.city || '',
      country: values.country || '',
      postalCode: values.postalCode || '',
      street: values.street || '',
    },
    currency: values.currency || '',
    name: values.name || '',
    vatId: values.vatNumber && values.vatNumber.length > 2 ? values.vatNumber : null,
  };

  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const response = yield call(Api.editBilling, companyId, data);
    yield responseValidateStatusOrThrow(response);
    yield put(actions.loadCompany(companyId));
    yield put(actionsSubscriptions.getVatVerification());
    yield put({ type: types.EDIT_BILLING_FORM_SUCCESS });
    yield put(addSnackbarSuccess(messages.billingSnackbarInfoEditedSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.EDIT_BILLING_FORM_FAILURE, error));
  }
}

export default function* billingSagas() {
  yield all([
    takeLatest(types.EDIT_BILLING_SHOW_MODAL, editBillingShowModal),
    takeLatest(types.EDIT_BILLING_FORM_REQUEST, editBillingFormRequest),
  ]);
}
