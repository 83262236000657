import { defineMessages } from 'react-intl';

export default defineMessages({
  acServiceActivationDialogMessageActivate: {
    defaultMessage: 'Manage access rights for your users remotely',
    description: '',
    id: 'acService.activationDialog.message.activate',
  },
  acServiceActivationDialogMessageDeactivate: {
    defaultMessage: 'You won’t be able to manage access rights for your users remotely',
    description: '',
    id: 'acService.activationDialog.message.deactivate',
  },
  acServiceActivationDialogTitleActivate: {
    defaultMessage: 'Activate Residential Access',
    description: '',
    id: 'acService.activationDialog.title.activate',
  },
  acServiceActivationDialogTitleDeactivate: {
    defaultMessage: 'Deactivate Residential Access',
    description: '',
    id: 'acService.activationDialog.title.deactivate',
  },
  acServiceLink: {
    defaultMessage: 'Remote Configuration Services',
    description: '',
    id: 'acService.link',
  },
  acServiceStatusActive: {
    defaultMessage: 'Active',
    description: '',
    id: 'acService.active',
  },
  acServiceStatusInactive: {
    defaultMessage: 'Inactive',
    description: '',
    id: 'acService.inactive',
  },
  acServiceText: {
    defaultMessage:
      'With this service, you can set up access rights for your users based on' +
      ' the door device type, your licenses, and various access methods.{br}{br}' +
      'Note that some devices support only certain access methods. To check your options,' +
      ' log in to Remote Configuration Services. {link}.',
    description: '',
    id: 'acService.text',
  },
  acServiceTitle: {
    defaultMessage: '2N<sup>®</sup> Residential Access',
    description: '',
    id: 'acService.title',
  },
});
