import { sortBy } from 'lodash';
import { CALL_SETTINGS } from '../../../config/consts';
import { SERVICE } from '../../../config/devices';
import { createExternalId } from '../../../helpers/common';

export const isConnectionWarningHandler = (items) => {
  if (items && Array.isArray(items)) {
    for (let i = 0; i < items.length; i += 1) {
      const isWarning = items[i]?.selectedDevices ? items[i].selectedDevices.find((item) => item.warning) : false;
      if (isWarning) {
        return true;
      }
    }
  }
  return false;
};

export const isUnlicensedDevicesWarning = (items) => {
  if (items && Array.isArray(items)) {
    for (let i = 0; i < items.length; i += 1) {
      const isWarning = items[i]?.selectedDevices
        ? items[i].selectedDevices.find(
            (item) =>
              item.services?.[SERVICE.MOBILE_VIDEO]?.hasLicence === false &&
              item?.services?.[SERVICE.LOCAL_CALLS]?.active === false
          )
        : false;
      if (isWarning) {
        return true;
      }
    }
  }
  return false;
};

export const handleAddListItem = (type, setter) => () => {
  setter((prevState) => [
    ...prevState,
    {
      ...CALL_SETTINGS[type].DEFAULT_OBJECT,
      index: prevState.length,
    },
  ]);
};

export const handleChangeListItem = (type, setter) => (data) => {
  const currentItemData = data;
  setter((prevState) => {
    if (
      prevState[data.index].name === '' &&
      data.selectedDevices.length > prevState[data.index].selectedDevices.length
    ) {
      currentItemData.name = `${CALL_SETTINGS[type].DEFAULT_CONTACT_NAME} ${data.index + 1}`;
    }

    return [...prevState.slice(0, data.index), currentItemData, ...prevState.slice(data.index + 1)];
  });
};

export const isNonWhiteSpaceString = (string) => new RegExp(/(\S)/g).test(string);

export const setValues = (contact, contactItems) => ({
  contactItems,
  externalId: contact.externalId,
  id: contact.id,
  name: contact.name,
  virtualNumber: contact.virtualNumber,
});

export const createNewContactItem = (contact) => {
  const contactServices = contact.selectedDevices[0]?.services;
  const contactItems = [];
  if (contactServices) {
    Object.keys(contactServices).forEach((key) => {
      if ([SERVICE.MOBILE_VIDEO, SERVICE.LOCAL_CALLS, SERVICE.GSM_CALLS].includes(key)) {
        contactItems.push({
          deviceConfigId: contactServices[key].id,
          type: key,
        });
      }
    });
  }
  return contactItems;
};

export const handleSaveListItem = (compactContactList, contactList, onSaveContactList) => {
  const values = [];
  const setDefaultContactName = (index) => `Contact ${index + 1}`;

  if (compactContactList.length === 0) {
    const contacts = contactList.filter((item) => item.selectedDevices.length > 0);
    if (contacts) {
      contacts.forEach((contact) => {
        if (contact.selectedDevices.length > 0) {
          values.push({
            contactItems: createNewContactItem(contact),
            name:
              contact.name && isNonWhiteSpaceString(contact.name)
                ? contact.name.trim()
                : setDefaultContactName(contact.index),
          });
        }
      });
    }
  }

  if (compactContactList.length > 0) {
    if (contactList) {
      contactList.forEach((contact) => {
        let contactItems = [];
        const name =
          contact.name && isNonWhiteSpaceString(contact.name)
            ? contact.name.trim()
            : setDefaultContactName(contact.index);

        if (contact.contactItems && contact.selectedDevices.length === 0) {
          values.push({
            ...setValues(contact, contactItems),
            name,
          });
        } else if (contact.contactItems && contact.selectedDevices.length > 0) {
          const prevContactItem = contact.contactItems.find(
            (prevItem) => prevItem.deviceConfigId === contact.selectedDevices[0].services.MOBILE_VIDEO.id
          );
          if (prevContactItem) {
            contactItems = [...contact.contactItems];
            values.push({
              ...setValues(contact, contactItems),
              name,
            });
          } else {
            values.push({ contactItems: createNewContactItem(contact), name });
          }
        } else if (contact.selectedDevices.length > 0) {
          values.push({ contactItems: createNewContactItem(contact), name });
        }
      });
    }
  }
  onSaveContactList(sortBy(values, ['index']));
};

export const createContactItem = (contact) => {
  const contactItems = [
    {
      members: [],
      type: SERVICE.RINGING_GROUP,
    },
  ];
  contact.selectedDevices.forEach((contactItem) => {
    const contactServices = contactItem?.services;
    if (contactServices) {
      Object.keys(contactServices).forEach((key) => {
        if ([SERVICE.LOCAL_CALLS, SERVICE.GSM_CALLS].includes(key)) {
          contactItems.push({
            deviceConfigId: contactServices[key].id,
            type: key,
          });
        }
        if (key === SERVICE.MOBILE_VIDEO) {
          contactItems[0].members = [...contactItems[0].members, contactServices[key].id];
        }
      });
    }
  });
  return contactItems;
};

export const updateContactItem = (button) => {
  const contactItems = [
    {
      members: [],
      type: SERVICE.RINGING_GROUP,
    },
  ];
  const prevContactItems = button.contact.contactItems;
  button.selectedDevices.forEach((selectedContactItem) => {
    const selectedContactServices = selectedContactItem?.services;
    if (selectedContactServices) {
      Object.keys(selectedContactServices).forEach((key) => {
        if ([SERVICE.LOCAL_CALLS, SERVICE.GSM_CALLS].includes(key)) {
          const foundService = prevContactItems.find(
            (prevContactItem) =>
              prevContactItem.type === key && prevContactItem.deviceConfigId === selectedContactServices[key].id
          );
          if (foundService) {
            contactItems.push(foundService);
          } else {
            contactItems.push({
              deviceConfigId: selectedContactServices[key].id,
              type: key,
            });
          }
        }
        if (key === SERVICE.MOBILE_VIDEO) {
          const foundMVService = prevContactItems.find(
            (prevContactItem) => prevContactItem.type === SERVICE.RINGING_GROUP
          );
          if (foundMVService) {
            contactItems[0] = {
              ...foundMVService,
              members: [...contactItems[0].members, selectedContactServices[key].id],
              type: SERVICE.RINGING_GROUP,
            };
          } else {
            contactItems[0].members = [...contactItems[0].members, selectedContactServices[key].id];
          }
        }
      });
    }
  });
  return contactItems;
};

export const handleSaveIntercomContacts = (ipIntercomButtonList, onSaveContactList) => {
  const values = [];
  if (ipIntercomButtonList.length > 0) {
    const buttons = ipIntercomButtonList.filter(
      (item) => (item.selectedDevices.length > 0 && item.name) || item.ringingGroup
    );
    if (buttons) {
      buttons.forEach((button) => {
        const name =
          button.name && isNonWhiteSpaceString(button.name) ? button.name.trim() : `Button ${button.index + 1}`;
        if (button.selectedDevices.length > 0 && !button?.contact?.externalId) {
          values.push({
            contactItems: createContactItem(button),
            externalId: createExternalId(),
            index: button.index,
            name,
          });
        } else if (button.selectedDevices.length > 0 && button?.contact?.externalId) {
          values.push({
            contactItems: updateContactItem(button),
            externalId: button.contact.externalId,
            id: button.contact.id,
            index: button.index,
            name,
            virtualNumber: button.contact.virtualNumber,
          });
        } else if (button.selectedDevices.length === 0 && button?.contact?.externalId) {
          const ringingGroup = button.contact.contactItems.find((item) => item.type === SERVICE.RINGING_GROUP);
          values.push({
            contactItems: [{ ...ringingGroup, members: [] }],
            externalId: button.contact.externalId,
            id: button.contact.id,
            index: button.index,
            name,
            virtualNumber: button.contact.virtualNumber,
          });
        }
      });
    }
  }
  onSaveContactList(sortBy(values, ['index']), SERVICE.RINGING_GROUP);
};

export const handleSaveIpTalkContacts = (talkButtonList, onSaveContactList) => {
  const values = [];
  if (talkButtonList.length > 0) {
    const buttons = talkButtonList.filter((item) => item.selectedDevices.length > 0 || item.ringingGroup);
    if (buttons) {
      buttons.forEach((button) => {
        if (button.selectedDevices.length > 0 && !button?.contact?.externalId) {
          values.push({
            contactItems: createContactItem(button),
            externalId: createExternalId(),
            index: button.index,
            name: `Button ${button.index + 1}`,
          });
        } else if (button.selectedDevices.length > 0 && button?.contact?.externalId) {
          values.push({
            contactItems: updateContactItem(button),
            externalId: button.contact.externalId,
            id: button.contact.id,
            index: button.index,
            name: `Button ${button.index + 1}`,
            virtualNumber: button.contact.virtualNumber,
          });
        } else if (button.selectedDevices.length === 0 && button?.contact?.externalId) {
          const ringingGroup = button.contact.contactItems.find((item) => item.type === SERVICE.RINGING_GROUP);
          values.push({
            contactItems: [{ ...ringingGroup, members: [] }],
            externalId: button.contact.externalId,
            id: button.contact.id,
            index: button.index,
            name: `Button ${button.index + 1}`,
            virtualNumber: button.contact.virtualNumber,
          });
        }
      });
    }
  }
  onSaveContactList(sortBy(values, ['index']), SERVICE.RINGING_GROUP);
};

export const getIsChipWarning = (item) =>
  !!(
    item?.warning ||
    (item?.services?.[SERVICE.MOBILE_VIDEO]?.hasLicence === false &&
      item?.services?.[SERVICE.LOCAL_CALLS]?.active === false)
  );
