import { ReactNode } from 'react';
import { IItemServiceProps } from '../IconServices/ItemService';
import { IUserAccessSettingsStatusProps } from '../UserAccessSettingsStatus/UserAccessSettingsStatus';
import { IUserEmailProps } from '../UserEmail/UserEmail';
import { IApartmentUserRoleNameProps } from '../UserRoleName/ApartmentUserRoleName';
import { IUserRoleNameProps } from '../UserRoleName/UserRoleName';
import { IIConAndNameProps } from './components/IconAndName';

export enum CellContentComponentType {
  IconAndName = 'IconAndName',
  Email = 'Email',
  UserRoleName = 'UserRoleName',
  ApartmentUserRoleName = 'ApartmentUserRoleName',
  ServiceItem = 'ServiceItem',
  UserAccessStatusIcon = 'UserAccessStatusIcon',
}

export interface ICellContentComponentBase {
  componentType: CellContentComponentType;
}

export interface ICellContentIconAndNameComponent extends ICellContentComponentBase {
  componentType: CellContentComponentType.IconAndName;
  data: IIConAndNameProps;
}

export interface ICellContentEmailComponent extends ICellContentComponentBase {
  componentType: CellContentComponentType.Email;
  data: IUserEmailProps;
}

export interface ICellContentUserRoleNameComponent extends ICellContentComponentBase {
  componentType: CellContentComponentType.UserRoleName;
  data: IUserRoleNameProps;
}

export interface ICellContentApartmentUserRoleNameComponent extends ICellContentComponentBase {
  componentType: CellContentComponentType.ApartmentUserRoleName;
  data: IApartmentUserRoleNameProps;
}

export interface ICellContentServiceItemComponent extends ICellContentComponentBase {
  componentType: CellContentComponentType.ServiceItem;
  data: IItemServiceProps;
}

export interface ICellContentUserAccessStatusIconComponent extends ICellContentComponentBase {
  componentType: CellContentComponentType.UserAccessStatusIcon;
  data: IUserAccessSettingsStatusProps;
}

export function isCellContentIconAndNameComponent(
  object: ReactNode | ICellContentComponentBase
): object is ICellContentIconAndNameComponent {
  return !!object && (object as ICellContentComponentBase).componentType === CellContentComponentType.IconAndName;
}

export function isCellContentEmailComponent(
  object: ReactNode | ICellContentComponentBase
): object is ICellContentEmailComponent {
  return !!object && (object as ICellContentComponentBase).componentType === CellContentComponentType.Email;
}

export function isCellContentUserRoleComponent(
  object: ReactNode | ICellContentComponentBase
): object is ICellContentUserRoleNameComponent {
  return !!object && (object as ICellContentComponentBase).componentType === CellContentComponentType.UserRoleName;
}

export function isCellContentApartmentUserRoleComponent(
  object: ReactNode | ICellContentComponentBase
): object is ICellContentApartmentUserRoleNameComponent {
  return (
    !!object && (object as ICellContentComponentBase).componentType === CellContentComponentType.ApartmentUserRoleName
  );
}

export function isCellContentServiceItemComponent(
  object: ReactNode | ICellContentComponentBase
): object is ICellContentServiceItemComponent {
  return !!object && (object as ICellContentComponentBase).componentType === CellContentComponentType.ServiceItem;
}

export function isCellContentUserAccessStatusIconComponent(
  object: ReactNode | ICellContentComponentBase
): object is ICellContentUserAccessStatusIconComponent {
  return (
    !!object && (object as ICellContentComponentBase).componentType === CellContentComponentType.UserAccessStatusIcon
  );
}
