import { uniqBy } from 'lodash';
import { ElIntBuilding } from '../../../../apiRtk/be4feApi';
import { IElevatorIntegrationFloorMappingStore } from '../../../../modules/my2nApi/elevatorIntegrationSlice';
import { ElevatorGroupSelectItem } from './FloorConfiguration';

export function getElevatorGroupSelectItems(buildingTopologyBuildings: ElIntBuilding[]) {
  const floorElevatorGroups: Array<ElevatorGroupSelectItem> = [];
  buildingTopologyBuildings.forEach((buildingTopologyBuilding) => {
    buildingTopologyBuilding.elevatorGroups.forEach((buildingTopologyBuildingElevatorGroup) => {
      floorElevatorGroups.push({
        buildingId: buildingTopologyBuilding.id,
        disabled: false,
        elevatorGroup: buildingTopologyBuildingElevatorGroup,
        id: `${buildingTopologyBuilding.id}|${buildingTopologyBuildingElevatorGroup.id}`,
      });
    });
  });
  return uniqBy(floorElevatorGroups, 'id');
}

export const disableSelectedElevatorGroups = (
  elevatorIntegrationFloorMappingStore: IElevatorIntegrationFloorMappingStore,
  elevatorGroups: ElevatorGroupSelectItem[],
  floorId: string
): ElevatorGroupSelectItem[] => {
  const { floors } = elevatorIntegrationFloorMappingStore.floorMapping;
  const initialSelectedGroups = floors
    .filter((floor) => floor.floorId === floorId)
    .flatMap((floor) => floor.mappings)
    .map((mapping) => mapping.buildingId);
  return elevatorGroups.map((group) => ({
    ...group,
    disabled: initialSelectedGroups.includes(group.buildingId),
  }));
};
