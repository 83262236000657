import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ListHeaderLabel, ReadOnlyField } from '../../../components';
import { DEVICE_TYPE, SERVICE } from '../../../config/devices';
import messages from './messages';
import { SecuredCalls } from './SecuredCalls';

const ProxyInfo = ({ device, site }) => (
  <Grid container direction="column">
    <Grid item>
      <ListHeaderLabel>
        <FormattedMessage {...messages.mobileVideoProxyInfoTitle} />
      </ListHeaderLabel>
    </Grid>
    <Grid container direction="column" item spacing={1}>
      {device?.services?.[SERVICE.MOBILE_VIDEO]?.sipNumber && (
        <Grid item>
          <ReadOnlyField
            label={<FormattedMessage {...messages.mobileVideoProxyInfoDeviceId} />}
            value={device.services[SERVICE.MOBILE_VIDEO].sipNumber}
          />
        </Grid>
      )}
      {device?.services?.[SERVICE.MOBILE_VIDEO]?.sipPassword &&
        device?.services?.[SERVICE.NOTIFICATION]?.legacyAccessEnabled !== false && (
          <Grid item>
            <ReadOnlyField
              label={<FormattedMessage {...messages.mobileVideoProxyInfoDevicePassword} />}
              value={device.services[SERVICE.MOBILE_VIDEO].sipPassword}
            />
          </Grid>
        )}
      {site?.services?.[SERVICE.MOBILE_VIDEO]?.proxy?.host && (
        <Grid item>
          <ReadOnlyField
            label={<FormattedMessage {...messages.mobileVideoProxyInfoProxyAddress} />}
            value={site.services[SERVICE.MOBILE_VIDEO].proxy.host}
          />
        </Grid>
      )}
      {site.services[SERVICE.MOBILE_VIDEO].sipsEnabled && device.services[SERVICE.MOBILE_VIDEO].sipsSupported ? (
        <Grid item>
          <ReadOnlyField
            label={<FormattedMessage {...messages.mobileVideoProxyInfoSipsPort} />}
            value={site.services[SERVICE.MOBILE_VIDEO].proxy.sipsPort}
          />
        </Grid>
      ) : (
        site?.services?.[SERVICE.MOBILE_VIDEO]?.proxy?.port && (
          <Grid item>
            <ReadOnlyField
              label={<FormattedMessage {...messages.mobileVideoProxyInfoProxyPort} />}
              value={site.services[SERVICE.MOBILE_VIDEO].proxy.port}
            />
          </Grid>
        )
      )}
      {site.services[SERVICE.MOBILE_VIDEO].sipsEnabled && (
        <SecuredCalls
          deviceType={device.type}
          sipsSupported={device.services[SERVICE.MOBILE_VIDEO].sipsSupported}
          srtpEnabled={site.services[SERVICE.MOBILE_VIDEO].srtpEnabled}
        />
      )}
    </Grid>
  </Grid>
);

ProxyInfo.propTypes = {
  device: PropTypes.shape({
    services: PropTypes.shape({
      [SERVICE.MOBILE_VIDEO]: PropTypes.shape({
        sipNumber: PropTypes.string,
        sipPassword: PropTypes.string,
        sipsSupported: PropTypes.bool,
      }),
      [SERVICE.NOTIFICATION]: PropTypes.shape({
        qrCodeUrl: PropTypes.string,
      }),
    }),
    type: PropTypes.oneOf(Object.keys(DEVICE_TYPE)),
  }).isRequired,
  site: PropTypes.shape({
    services: PropTypes.shape({
      [SERVICE.MOBILE_VIDEO]: PropTypes.shape({
        proxy: PropTypes.shape({
          host: PropTypes.string,
          port: PropTypes.number,
          sipsPort: PropTypes.number,
        }),
        sipsEnabled: PropTypes.bool,
        srtpEnabled: PropTypes.bool,
      }),
    }),
  }).isRequired,
};

export default ProxyInfo;
