import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { CookieSettingsButton } from '../../../components';

const styles = {
  agree: (theme) => ({
    '& svg': {
      fill: theme.palette.success.main,
    },
  }),
  root: {
    '& a': {
      color: 'black',
      fontWeight: 'bold',
      textDecoration: 'none',
    },
    alignItems: 'center',
    display: 'flex',
    fontSize: 20,
    fontWeight: 'bold',
    justifyContent: 'space-between',
  },
};

const GTCTitle = ({ agree, cookieSettings, link, title }) => (
  <Typography sx={styles.root} variant="inherit">
    <Link title={title} to={link}>
      {title}
    </Link>
    {agree && (
      <Box sx={styles.agree}>
        <CheckCircleIcon />
      </Box>
    )}
    {cookieSettings && <CookieSettingsButton tooltipPlacement="right" />}
  </Typography>
);

GTCTitle.propTypes = {
  agree: PropTypes.bool.isRequired,
  cookieSettings: PropTypes.bool.isRequired,
  link: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default GTCTitle;
