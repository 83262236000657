import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RefreshIcon from '@mui/icons-material/Refresh';
import ReportProblem from '@mui/icons-material/ReportProblem';
import { LoadingButton } from '@mui/lab';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useElevatorIntegrationFloorMappingQuery } from '../../../../apiRtk/be4feApi';
import { useRefreshSiteBuildingTopologyMutation } from '../../../../apiRtk/elevatorIntegrationApi';
import { useGetLoadedParams } from '../../../../hooks/paramsHooks';
import styles from '../../../SiteUserDetail/Detail/UserInfoForm/styles/UserInfoForm';
import BuildingIds from './BuildingIds';
import messages from './messages';

export function KoneCloudConnection() {
  const { companyId, siteId } = useGetLoadedParams();
  const [refreshBuildingTopology, { isLoading }] = useRefreshSiteBuildingTopologyMutation();
  const onRefreshBuildingTopology = async () => {
    await refreshBuildingTopology({ companyId: companyId!.toString(), siteId: siteId!.toString() });
  };
  const {
    data: siteBuildingTopologyData,
    isError: siteBuildingTopologyIsError,
    isLoading: siteBuildingTopologyIsLoading,
  } = useElevatorIntegrationFloorMappingQuery({
    companyId: companyId!,
    siteId: siteId!,
  });

  return (
    <>
      <Typography sx={styles.formTitle} variant="h6">
        <FormattedMessage {...messages.elevatorIntegrationKonecloudTitle} />
      </Typography>
      {!siteBuildingTopologyIsLoading && (
        <Alert
          icon={siteBuildingTopologyIsError ? <ReportProblem /> : <CheckCircleIcon />}
          severity={siteBuildingTopologyIsError ? 'warning' : 'success'}
          sx={{ color: 'white' }}
          variant="filled"
        >
          <AlertTitle>
            <FormattedMessage {...messages.elevatorIntegrationKonecloudAlertTitle} />
          </AlertTitle>
          <FormattedMessage {...messages.elevatorIntegrationKonecloudAlertText} />
          <BuildingIds buildings={siteBuildingTopologyData?.buildingTopology?.buildings} />
        </Alert>
      )}
      <LoadingButton
        onClick={onRefreshBuildingTopology}
        loading={isLoading}
        color="primary"
        startIcon={<RefreshIcon />}
        sx={{ mt: 2 }}
        variant="outlined"
      >
        <FormattedMessage {...messages.elevatorIntegrationKonecloudButtonRefresh} />
      </LoadingButton>
      <Divider sx={{ mb: 4, mt: 4 }} />
    </>
  );
}
