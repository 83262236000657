import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';

function CellDeleteButton({ onClick }) {
  const { formatMessage } = useIntl();
  return (
    <Tooltip title={formatMessage(messages.siteUsersImportCheckStepDataGridDeleteTooltip)}>
      <IconButton onClick={onClick} size="small">
        <CloseIcon fontSize="inherit" />
      </IconButton>
    </Tooltip>
  );
}

CellDeleteButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default memo(CellDeleteButton);
