import Stack from '@mui/material/Stack';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import TextField from '../HookFormFields/TextField';
import messages from './messages';

function NewUserFields() {
  const { control } = useFormContext();
  const { formatMessage } = useIntl();
  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        bgcolor: 'background.default',
        p: 2,
      }}
    >
      <TextField
        autoFocus
        control={control}
        fullWidth
        label={formatMessage(messages.newUserFieldNewUserEmailLabel)}
        name="email"
        required
      />
      <Stack direction="row" spacing={2}>
        <TextField
          control={control}
          label={formatMessage(messages.newUserFieldNewUserFirstName)}
          name="firstName"
          required
        />
        <TextField
          control={control}
          label={formatMessage(messages.newUserFieldNewUserLastName)}
          name="lastName"
          required
        />
      </Stack>
    </Stack>
  );
}

export default NewUserFields;
