import { useSelector } from 'react-redux';
import { useUrlParams } from '../helpers/urlParams';
import { Roles } from '../modules/commonTypes';
import { getCurrentUserSelector } from '../modules/user/selectors';
import { useAppContext } from '../pages/App/AppContextProvider';
import { IAllowedRoles } from '../routes/components/types';
import { useGetCurrentUserApartmentRoles } from './apartmentHooks';
import { useGetActualCompanyId, useGetCurrentUserCompanyRoles } from './companyHooks';
import { useGetLoadedParams } from './paramsHooks';
import { useGetActualSiteId, useGetCurrentUserSiteRoles } from './siteHooks';

export function useGetCurrentUserContextRoles(): Roles[] {
  const companyId = useGetActualCompanyId();
  const siteId = useGetActualSiteId();
  const { apartmentId } = useUrlParams();
  const { userRoles } = useAppContext();
  const companyRoles = useGetCurrentUserCompanyRoles(companyId);
  const siteRoles = useGetCurrentUserSiteRoles(companyId, siteId);
  const apartmentRoles = useGetCurrentUserApartmentRoles(companyId, siteId, apartmentId);
  return [...userRoles, ...(companyRoles ?? []), ...(siteRoles ?? []), ...(apartmentRoles ?? [])];
}

export interface IUserRoles extends IRoles {
  id: number;
  companies: ICompanyRoles[];
}

export interface ICompanyRoles extends IRoles {
  id: number;
  sites?: ISiteRoles[];
}

export interface ISiteRoles extends IRoles {
  id: number;
  apartments: IApartmentRoles[];
}

interface IApartmentRoles extends IRoles {
  id: number;
}

interface IRoles {
  roles: Roles[];
}

export function useCanUserAccessRoute(allowedRoles: IAllowedRoles): boolean {
  const { loggedIn } = useAppContext();
  const currentUser = useSelector(getCurrentUserSelector) as IUserRoles;
  const { apartmentId, companyId, siteId } = useGetLoadedParams();

  if (!loggedIn) {
    return false;
  }

  if (allowedRoles.contextRoles?.includes(Roles.ANY) || allowedRoles.deepCheckRoles?.includes(Roles.ANY)) {
    return true;
  }

  let foundAllowedRole = false;

  currentUser.companies.forEach((companyRoles) => {
    if (allowedRoles.contextRoles !== undefined && companyRoles.id === companyId) {
      if (companyRoles.roles.some((role) => allowedRoles.contextRoles?.includes(role))) {
        foundAllowedRole = true;
        return;
      }
    }

    if (
      allowedRoles.deepCheckRoles !== undefined &&
      companyRoles.roles.some((role) => allowedRoles.deepCheckRoles?.includes(role))
    ) {
      foundAllowedRole = true;
      return;
    }

    companyRoles.sites?.forEach((siteRoles) => {
      if (allowedRoles.contextRoles !== undefined && siteRoles.id === siteId) {
        if (siteRoles.roles.some((role) => allowedRoles.contextRoles?.includes(role))) {
          foundAllowedRole = true;
          return;
        }
      }

      if (
        allowedRoles.deepCheckRoles !== undefined &&
        siteRoles.roles.some((role) => allowedRoles.deepCheckRoles?.includes(role))
      ) {
        foundAllowedRole = true;
        return;
      }

      siteRoles.apartments.forEach((apartmentRoles) => {
        if (allowedRoles.contextRoles !== undefined && apartmentRoles.id === apartmentId) {
          if (apartmentRoles.roles.some((role) => allowedRoles.contextRoles?.includes(role))) {
            foundAllowedRole = true;
            return;
          }
        }

        if (
          allowedRoles.deepCheckRoles !== undefined &&
          apartmentRoles.roles.some((role) => allowedRoles.deepCheckRoles?.includes(role))
        ) {
          foundAllowedRole = true;
          return;
        }
      });

      if (foundAllowedRole) {
        return;
      }
    });

    if (foundAllowedRole) {
      return;
    }
  });

  return foundAllowedRole;
}
