import React from 'react';
import { HeaderPublic } from '../../components';
import Footer from '../HomePage/Footer';
import Features from './Features';
import RcMain from './RcMain';
import Scenario from './Scenario';
import ServiceFooter from './ServiceFooter';
import Steps from './Steps';

const RemoteConfigurationPage = () => (
  <>
    <HeaderPublic />
    <RcMain />
    <Features />
    <Steps />
    <Scenario />
    <ServiceFooter />
    <Footer />
  </>
);

export default RemoteConfigurationPage;
