import PropTypes from 'prop-types';
import { PROMO_TYPES } from './consts';

export const formikValuesPropTypes = PropTypes.shape({
  array: PropTypes.arrayOf(
    PropTypes.shape({
      count: PropTypes.number,
      id: PropTypes.number,
      name: PropTypes.string,
      subscription: PropTypes.number,
      subscriptionType: PropTypes.string,
    })
  ),
});

export const helperFunctionsPropTypes = PropTypes.shape({
  remove: PropTypes.func,
});

export const batchPropTypes = PropTypes.shape({
  name: PropTypes.string,
  subscriptions: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subscriptionType: PropTypes.string,
  years: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

export const orderItemPropTypes = PropTypes.shape({
  configuration: PropTypes.shape({
    devices: PropTypes.number,
    type: PropTypes.oneOf(Object.keys(PROMO_TYPES)),
    years: PropTypes.number,
  }),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  price: PropTypes.number,
});

export const orderItemConfigurationPropTypes = PropTypes.shape({
  devices: PropTypes.number,
  type: PropTypes.oneOf(Object.keys(PROMO_TYPES)),
  years: PropTypes.number,
});

export const pricesPropTypes = PropTypes.shape({
  MOBILE_VIDEO_POOL_CREDIT: PropTypes.shape({
    unitFullPrice: PropTypes.number,
    unitFullPriceVat: PropTypes.number,
  }),
  MOBILE_VIDEO_POOL_CREDIT_YEAR_APARTMENT: PropTypes.shape({
    unitFullPrice: PropTypes.number,
    unitFullPriceVat: PropTypes.number,
  }),
  MOBILE_VIDEO_POOL_CREDIT_YEAR_STANDARD: PropTypes.shape({
    unitFullPrice: PropTypes.number,
    unitFullPriceVat: PropTypes.number,
  }),
});
