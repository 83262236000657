import InfoIcon from '@mui/icons-material/Info';
import ReportProblem from '@mui/icons-material/ReportProblem';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Alert from '../../../components/Alert';
import { IDTMFSwitchCode } from '../../../modules/devices/store/deviceStore';
import messages from './messages';

interface ISwitchCodesStatusAlert {
  isDoorStation: boolean;
  switches: IDTMFSwitchCode[] | undefined;
}

function SwitchCodesStatusAlert({ isDoorStation, switches }: ISwitchCodesStatusAlert) {
  const enabledSwitches = switches?.find((s) => s?.enabled);

  if (!switches && !isDoorStation) {
    return (
      <Alert icon={<ReportProblem />} severity="warning" sx={{ m: '0 !important' }}>
        <Typography variant="body2">
          <FormattedMessage {...messages.switchCodesAlertWarningNotLoaded} />
        </Typography>
      </Alert>
    );
  }

  if (switches && (switches?.length === 0 || !enabledSwitches)) {
    return (
      <Alert icon={<ReportProblem />} severity="warning" sx={{ m: '0 !important' }}>
        <Typography variant="body2">
          <FormattedMessage {...messages.switchCodesAlertWarningEmpty} />
        </Typography>
      </Alert>
    );
  }

  return (
    <Alert icon={<InfoIcon />} severity="info" sx={{ m: '0 !important' }}>
      <Typography variant="body2">
        <FormattedMessage {...messages.switchCodesAlertInfo} />
      </Typography>
    </Alert>
  );
}

export default SwitchCodesStatusAlert;
