import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const boxStyles = {
  '& ul': {
    '& li': {
      px: 0,
      py: 1,
    },
    m: 0,
    pb: 0,
    pl: 2,
    pr: 0,
    pt: 1,
  },
};

const InfoColumn = ({ items, title }) => (
  <Box sx={boxStyles}>
    <Typography color="primary" variant="subtitle2">
      {title}
    </Typography>
    {items.length > 0 && (
      <ul>
        {items.map((item) => (
          <Typography component="li" key={item.key} variant="body2">
            {item}
          </Typography>
        ))}
      </ul>
    )}
  </Box>
);

InfoColumn.propTypes = {
  items: PropTypes.arrayOf(PropTypes.node).isRequired,
  title: PropTypes.node.isRequired,
};

export default InfoColumn;
