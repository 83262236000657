import { defineMessages } from 'react-intl';

export default defineMessages({
  licenseInfoNew: {
    defaultMessage: 'This licensing is valid for devices with 2N OS/firmware version 2.35 or higher.',
    description: '',
    id: 'licenseInfoNew.description',
  },
  licenseInfoNewFreeFeaturesFeature1Content: {
    defaultMessage: 'User sounds, Automatic audio test and Noise detection',
    description: '',
    id: 'licenseInfoNew.freeFeatures.feature1.content',
  },
  licenseInfoNewFreeFeaturesFeature1Help: {
    defaultMessage: 'Previously included in the Enhanced Audio license',
    description: '',
    id: 'licenseInfoNew.freeFeatures.feature1.help',
  },
  licenseInfoNewFreeFeaturesFeature2Content: {
    defaultMessage: 'HTTP API',
    description: '',
    id: 'licenseInfoNew.freeFeatures.feature2.content',
  },
  licenseInfoNewFreeFeaturesFeature2Help: {
    defaultMessage: 'Previously included in the Enhanced Integration license',
    description: '',
    id: 'licenseInfoNew.freeFeatures.feature2.help',
  },
  licenseInfoNewFreeFeaturesFeature3Content: {
    defaultMessage:
      '802.1x support, SIPS (TLS) support, SRTP support, Silent alarm, Limit unsuccessful access attempts, Switch blocking, Scrambled keypad and Anti-passback',
    description: '',
    id: 'licenseInfoNew.freeFeatures.feature3.content',
  },
  licenseInfoNewFreeFeaturesFeature3Help: {
    defaultMessage: 'Previously included in the Enhanced Security license',
    description: '',
    id: 'licenseInfoNew.freeFeatures.feature3.help',
  },
  licenseInfoNewFreeFeaturesFeature4Content: {
    defaultMessage: 'NFC support',
    description: '',
    id: 'licenseInfoNew.freeFeatures.feature4.content',
  },
  licenseInfoNewFreeFeaturesFeature4Help: {
    defaultMessage: 'Previously included in the NFC license',
    description: '',
    id: 'licenseInfoNew.freeFeatures.feature4.help',
  },
  licenseInfoNewFreeFeaturesTitle: {
    defaultMessage: 'Free features',
    description: '',
    id: 'licenseInfoNew.freeFeatures.title',
  },
  licenseInfoNewGoldLicenseDescription: {
    defaultMessage: '9137909 - 2N IP Intercom - Gold license',
    description: '',
    id: 'licenseInfoNew.goldLicense.description',
  },
  licenseInfoNewGoldLicenseFeature1Content: {
    defaultMessage:
      'Audio/video streaming (RTSP Server), External IP camera support, ONVIF support, Motion detection support',
    description: '',
    id: 'licenseInfoNew.goldLicense.feature1.content',
  },
  licenseInfoNewGoldLicenseFeature1Help: {
    defaultMessage: 'Previously included in the Enhanced Video license',
    description: '',
    id: 'licenseInfoNew.goldLicense.feature1.help',
  },
  licenseInfoNewGoldLicenseFeature2Content: {
    defaultMessage:
      'Extended switch setting options, Automation functions, E-mail sending (SMTP Client), Automatic update (TFTP/HTTP Client), FTP client, SNMP client, TR-069 and Genetex Synergis',
    description: '',
    id: 'licenseInfoNew.goldLicense.feature2.content',
  },
  licenseInfoNewGoldLicenseFeature2Help: {
    defaultMessage: 'Previously included in the Enhanced Integration license',
    description: '',
    id: 'licenseInfoNew.goldLicense.feature2.help',
  },
  licenseInfoNewGoldLicenseFeature3Conent: {
    defaultMessage: 'Lift Control',
    description: '',
    id: 'licenseInfoNew.goldLicense.feature3.content',
  },
  licenseInfoNewGoldLicenseFeature3Help: {
    defaultMessage: 'Previously included in the Lift module license',
    description: '',
    id: 'licenseInfoNew.goldLicense.feature3.help',
  },
  licenseInfoNewGoldLicenseTitle: {
    defaultMessage: 'Gold license',
    description: '',
    id: 'licenseInfoNew.goldLicense.title',
  },
  licenseInfoNewInformcastLicenseDescription: {
    defaultMessage: '9137910 - 2N IP Intercom - Informacast license',
    description: '',
    id: 'licenseInfoNew.informacastLicense.description',
  },
  licenseInfoNewInformcastLicenseFeatureContent: {
    defaultMessage: 'This license allows the SingleWire InformaCast protocol to be used',
    description: '',
    id: 'licenseInfoNew.informacastLicense.feature.content',
  },
  licenseInfoNewInformcastLicenseFeatureHelp: {
    defaultMessage: 'Previously included in the Informacast license',
    description: '',
    id: 'licenseInfoNew.informacastLicense.feature.help',
  },
  licenseInfoNewInformcastLicenseTitle: {
    defaultMessage: 'Informacast license',
    description: '',
    id: 'licenseInfoNew.informacastLicense.title',
  },
  licenseInfoNewLicenseEnabled: {
    defaultMessage: 'This license enables:',
    description: '',
    id: 'licenseInfoNew.licenseEnabled',
  },
  licenseInfoNewTitle: {
    defaultMessage: 'Licensing overview',
    description: '',
    id: 'licenseInfoNew.title',
  },
  licenseInfoNewUpgrade: {
    defaultMessage: 'Upgrade the firmware to get a full experience of free features.',
    description: '',
    id: 'licenseInfoNew.upgrade',
  },
  licenseInfoNewWikipedia: {
    defaultMessage: '<link>Visit our wikipedia</link> for details about the new licensing.',
    description: '',
    id: 'licenseInfoNew.wikipedia',
  },
  licenseLICENCE_ACCESS_UNIT_LIFT_MODULE: {
    defaultMessage: 'enables the configuration of lift floor access',
    description: 'AvailableLicense - access unit lift en',
    id: 'license.LICENCE_ACCESS_UNIT_LIFT_MODULE',
  },
  licenseLICENCE_ENHANCED_AUDIO: {
    defaultMessage: 'enables User sounds, Automatic audio test and Noise detection',
    description: 'AvailableLicense - audio en',
    id: 'license.LICENCE_ENHANCED_AUDIO',
  },
  licenseLICENCE_ENHANCED_INTEGRATION: {
    defaultMessage:
      'enables Extended switch setting options, HTTP API (Camera and Switch), ' +
      'Automation functions, E-mail sending (SMTP Client), Automatic update (TFTP/HTTP ' +
      'Client), FTP client, SNMP client and TR-069',
    description: 'AvailableLicense - integration en',
    id: 'license.LICENCE_ENHANCED_INTEGRATION',
  },
  licenseLICENCE_ENHANCED_SECURITY: {
    defaultMessage: 'enables 802.1x support and SIPS (TLS) support',
    description: 'AvailableLicense - security cz',
    id: 'license.LICENCE_ENHANCED_SECURITY',
  },
  licenseLICENCE_ENHANCED_VIDEO: {
    defaultMessage:
      'enables Audio/video streaming (RTSP Server), External IP camera support ' +
      ', ONVIF support, PTZ function support and Motion detection support',
    description: 'AvailableLicense - video en',
    id: 'license.LICENCE_ENHANCED_VIDEO',
  },
  licenseLICENCE_GOLD: {
    defaultMessage:
      'enables all features included in NFC, Enhanced integration, Enhanced ' +
      'video, Enhanced audio and Enhanced security licenses',
    description: 'AvailableLicense - gold en',
    id: 'license.LICENCE_GOLD',
  },
  licenseLICENCE_INFORMCAST: {
    defaultMessage: 'allows the SingleWire InformaCast protocol to be used',
    description: 'AvailableLicense - informacast en',
    id: 'license.LICENCE_INFORMCAST',
  },
  licenseLICENCE_INTERCOM_LIFT_MODULE: {
    defaultMessage: 'enables the configuration of lift floor access',
    description: 'AvailableLicense - lift en',
    id: 'license.LICENCE_INTERCOM_LIFT_MODULE',
  },
  licenseLICENCE_NFC: {
    defaultMessage:
      'enables authentication via NFC-equipped mobile phones for selected ' + '13Mhz RFID card reader models',
    description: 'AvailableLicense - nfc en',
    id: 'license.LICENCE_NFC',
  },
  licenseLICENCE_NFC_ACCESS_UNIT: {
    defaultMessage:
      'enables authentication via NFC-equipped mobile phones for selected ' + '13Mhz RFID card reader models',
    description: 'AvailableLicense - access unit nfc en',
    id: 'license.LICENCE_NFC_ACCESS_UNIT',
  },
});
