import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import EventIcon from '@mui/icons-material/Event';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LabelIcon from '@mui/icons-material/Label';
import LocalAtmSharpIcon from '@mui/icons-material/LocalAtmSharp';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import CustomDate from '../CustomDate';
import { dateDiff } from '../DetailBatchDialog/helpers';
import DetailLabel from '../DetailLabel';
import messages from './messages';

const styles = {
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  chip: {
    '&:first-of-type': {
      ml: 0,
    },
    mx: 0.25,
    my: 0,
  },
  gridDivider: {
    '& hr': {
      border: '2px #E0E0E0',
      width: '100%',
    },
    mx: 0,
    my: 1,
  },
  gridPadding: {
    px: 0,
    py: 0.5,
  },
  noteWrapper: {
    '& p': {
      color: 'secondary.main',
    },
    fontSize: '1rem',
    typography: 'caption',
  },
};

// eslint-disable-next-line complexity
const RecapBatch = ({ calculateTotalCredits, username, values }) => (
  <Grid columnSpacing={1} container>
    <Grid container direction="column" item>
      <DetailLabel
        label={<FormattedMessage {...messages.recapBatchNameLabel} />}
        minHeight={150}
        startIcon={<LocalOfferIcon htmlColor="#B6B6B6" />}
        value={values.name}
      />
    </Grid>
    <Grid container direction="column" item sx={styles.gridPadding}>
      {values.channel === 'PURCHASE' && (
        <DetailLabel
          label={<FormattedMessage {...messages.recapBatchChannelLabel} />}
          minHeight={150}
          startIcon={<MonetizationOnIcon color="primary" />}
          value={
            <strong>
              <FormattedMessage {...messages.recapBatchChannelPurchase} />
            </strong>
          }
        />
      )}
      {values.channel === 'PROMO' && (
        <DetailLabel
          label={<FormattedMessage {...messages.recapBatchChannelLabel} />}
          minHeight={150}
          startIcon={<WhatshotIcon htmlColor="orange" />}
          value={
            <strong>
              <FormattedMessage {...messages.recapBatchChannelPromo} />
            </strong>
          }
        />
      )}
    </Grid>
    {values.size && values.credit && (
      <Grid container item sx={styles.gridPadding}>
        <DetailLabel
          label={<FormattedMessage {...messages.recapBatchSizeLabel} />}
          minHeight={150}
          startIcon={<ConfirmationNumberIcon htmlColor="#B6B6B6" />}
          unit={<FormattedMessage {...messages.recapBatchSizeAdornment} />}
          value={values.size}
        />
        <DetailLabel
          label={<FormattedMessage {...messages.recapBatchCreditLabel} />}
          minHeight={110}
          startIcon={<LocalAtmSharpIcon htmlColor="#B6B6B6" />}
          unit={<FormattedMessage {...messages.recapBatchCreditAdornment} />}
          value={values.credit}
        />
        <DetailLabel
          label={<FormattedMessage {...messages.recapBatchTotalLabel} />}
          minHeight={110}
          startIcon={<InsertChartIcon htmlColor="#B6B6B6" />}
          unit={<FormattedMessage {...messages.recapBatchTotalAdornment} />}
          value={calculateTotalCredits(values.size, values.credit)}
        />
      </Grid>
    )}
    {values.dateCreated && (
      <>
        <Divider />
        <Grid container direction="column" item>
          <DetailLabel
            label={<FormattedMessage {...messages.recapBatchDateCreatedLabel} />}
            minHeight={150}
            startIcon={<EventIcon htmlColor="#B6B6B6" />}
            unit={<FormattedMessage {...messages.recapBatchDateCreatedHelperText} />}
            value={<CustomDate dateValue={values.dateCreated} />}
          />
        </Grid>
      </>
    )}
    {values.dateCreated && values.validityTo && (
      <Grid container direction="column" item>
        <DetailLabel
          label={<FormattedMessage {...messages.recapBatchValidityToLabel} />}
          minHeight={150}
          startIcon={<EventIcon htmlColor="#B6B6B6" />}
          unit={
            <FormattedMessage
              {...messages.recapBatchValidityToHelperText}
              values={{
                value: dateDiff(values.dateCreated, values.validityTo),
              }}
            />
          }
          value={<CustomDate dateValue={values.validityTo} />}
        />
      </Grid>
    )}
    {values.invoice && (
      <Grid container direction="column" item>
        <DetailLabel
          label={<FormattedMessage {...messages.recapBatchInvoice} />}
          minHeight={150}
          startIcon={<InsertDriveFileIcon htmlColor="#B6B6B6" />}
          value={values.invoice}
        />
      </Grid>
    )}
    {values.utm && (
      <Grid container direction="column" item>
        <DetailLabel
          label={<FormattedMessage {...messages.recapBatchUtm} />}
          minHeight={150}
          startIcon={<LabelIcon htmlColor="#B6B6B6" />}
          value={values.utm.split(',').map((tag) => (
            <Chip color="primary" deleteIcon={<LocalOfferIcon />} key={tag} label={tag} size="small" sx={styles.chip} />
          ))}
        />
      </Grid>
    )}
    {username && (
      <Grid container direction="column" item>
        <DetailLabel
          label={<FormattedMessage {...messages.recapBatchCreator} />}
          minHeight={150}
          startIcon={<AccountCircleIcon htmlColor="#B6B6B6" />}
          value={username}
        />
      </Grid>
    )}
    {values.description && (
      <>
        <Grid container direction="column" item sx={styles.gridDivider}>
          <Divider />
        </Grid>
        <Box sx={styles.noteWrapper}>
          <FormattedMessage tagName="p" {...messages.recapBatchDescriptionLabel} />
          <div>{values.description}</div>
        </Box>
      </>
    )}
  </Grid>
);

RecapBatch.propTypes = {
  calculateTotalCredits: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
  values: PropTypes.shape({
    channel: PropTypes.oneOf(['PROMO', 'PURCHASE']),
    credit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dateCreated: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    description: PropTypes.string,
    invoice: PropTypes.string,
    name: PropTypes.string,
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    utm: PropTypes.string,
    validityTo: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  }).isRequired,
};

export default RecapBatch;
