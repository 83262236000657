import DoneIcon from '@mui/icons-material/Done';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import mvideoSvg from '../../../../../assets/mvideo.svg';
import { CallStatus } from '../../../../../components';
import { FilledInfoBar } from '../../../../../components/_DesignSystemWrappers/InfoBar/FilledInfoBar';
import { SERVICE } from '../../../../../config/devices';
import { ApartmentUser } from '../../../../../modules/apartmentAdmin/data';
import { IDevice } from '../../../../../modules/devices/store/deviceStore';
import { isUWLUser } from '../../../utils/UWLUserType';
import { DetailItem } from '../../components/DetailItem';
import messages from '../messages';
import MVAppLoginInfo from '../MVAppLoginInfo';
import MVStatus from '../MVStatus';

const styles = {
  activeMVImage: {
    display: 'block',
    width: 40,
  },
  inactiveMVImage: {
    display: 'block',
    filter: 'grayscale(100%)',
    opacity: 0.6,
    width: 40,
  },
};

export interface IMVSingleDevice {
  device: IDevice | undefined;
  onOpenActivateMVForUWLModal: () => void;
  onOpenActivateMVModal: () => void;
  onOpenDeactivateMVModal: () => void;
  onOpenInfoModal: () => void;
  setCurrentDevice: (device: IDevice | undefined) => void;
  hasExceededMVLimit: boolean;
  isActiveSubscriptionsState: boolean;
  user: ApartmentUser;
}

function MVSingleDevice({
  device,
  hasExceededMVLimit,
  isActiveSubscriptionsState,
  onOpenActivateMVForUWLModal,
  onOpenActivateMVModal,
  onOpenDeactivateMVModal,
  onOpenInfoModal,
  setCurrentDevice,
  user,
}: IMVSingleDevice) {
  const isActive = !!device?.services?.[SERVICE.MOBILE_VIDEO]?.active;

  const onClick = (device: IDevice | undefined) => {
    if (isUWLUser(user)) {
      onOpenActivateMVForUWLModal();
    } else {
      isActive ? onOpenDeactivateMVModal() : onOpenActivateMVModal();
    }

    setCurrentDevice(device);
  };

  return (
    <DetailItem title={<FormattedMessage {...messages.AAUserDetailMobileVideoTitle} />}>
      <Stack spacing={3}>
        <Stack direction="row" alignItems="center" spacing={2}>
          <img alt="Mobile Video" src={mvideoSvg} style={isActive ? styles.activeMVImage : styles.inactiveMVImage} />
          <MVStatus device={device} isActive={isActive} />
          {device?.callsStatus && <CallStatus size="medium" status={device?.callsStatus} />}
        </Stack>
        <Button
          color={isActive ? 'info' : 'primary'}
          onClick={() => onClick(device)}
          startIcon={isActive ? <HighlightOffIcon /> : <DoneIcon />}
          sx={{ width: 'fit-content' }}
          variant="contained"
          disabled={(hasExceededMVLimit || !isActiveSubscriptionsState) && !isActive}
        >
          <FormattedMessage
            {...(isActive
              ? { ...messages.AAUserDetailMobileVideoDeactivate }
              : { ...messages.AAUserDetailMobileVideoActivate })}
          />
        </Button>
        {(hasExceededMVLimit || !isActiveSubscriptionsState) && !isActive && (
          <FilledInfoBar>
            <Typography variant="body1">
              <FormattedMessage
                {...(!isActiveSubscriptionsState
                  ? messages.AAUserDetailMobileVideoUnlicensed
                  : messages.AAUserDetailMobileVideoLimitExceeded)}
              />
            </Typography>
          </FilledInfoBar>
        )}
        {isActive && (
          <MVAppLoginInfo
            qrCodeUrl={device?.services?.[SERVICE.NOTIFICATION]?.qrCodeUrl}
            onOpenInfoModal={onOpenInfoModal}
          />
        )}
      </Stack>
    </DetailItem>
  );
}

export default MVSingleDevice;
