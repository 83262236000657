import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import Stack from '@mui/material/Stack';
import React from 'react';
import { TypographyWithEllipsisTooltip } from '../TypographyWithEllipsisTooltip';

interface IUserData {
  firstName: string;
  lastName: string;
}

function LoginLessUser({ firstName, lastName }: IUserData) {
  return (
    <Stack direction="row" spacing={2}>
      <PersonOutlineIcon sx={{ color: 'secondary.main' }} />
      <TypographyWithEllipsisTooltip sx={{ fontWeight: 'bold' }} variant="body1">
        {firstName ? `${firstName} ${lastName}` : lastName}
      </TypographyWithEllipsisTooltip>
    </Stack>
  );
}

export default LoginLessUser;
