import { defineMessages } from 'react-intl';

export default defineMessages({
  ImsLoginErrorIncorrectCredentials4000006: {
    defaultMessage: 'Email or password is incorrect. We can help you <link>reset your password.</link>',
    id: 'ims.login.error.incorrectCredentials.4000006',
  },
  ImsLoginErrorNotActivated4000010: {
    defaultMessage: 'Account is not active. Please, activate it using the email we sent you.',
    id: 'ims.login.error.notActivated.4000010',
  },
  ImsNewPasswordErrorSessionExpired: {
    defaultMessage: 'Your session for setting a new password has expired. Please, try password reset again',
    id: 'ims.newPassword.error.sessionExpired',
  },
  ImsNewPasswordInputHelperTextLeaked4000034: {
    defaultMessage: 'The password has been found in data breaches and must no longer be used',
    id: 'ims.newPassword.input.helperText.leaked.4000034',
  },
  ImsNewPasswordInputHelperTextSimilar4000031: {
    defaultMessage: 'The password can not be used because it is too similar to the identifier',
    id: 'ims.newPassword.input.helperText.similar.4000031',
  },
  ImsResetPasswordErrorGeneral: {
    defaultMessage: 'There was an error, please try again',
    id: 'ims.resetPassword.error.general',
  },
  ImsResetPasswordErrorLoggedIn: {
    defaultMessage: 'You are logged in to My2N. Please, log out to reset password',
    id: 'ims.resetPassword.error.loggedIn',
  },
  ImsResetPasswordRecoveryCodeHelperExpiredCode4060005: {
    defaultMessage: 'The recovery code has expired. Please, resend the code',
    id: 'ims.resetPassword.recoveryCode.helper.expiredCode.4060005',
  },
  ImsResetPasswordRecoveryCodeHelperInvalidCode4060006: {
    defaultMessage: 'The recovery code is invalid or has already been used. Please, try again',
    id: 'ims.resetPassword.recoveryCode.helper.invalidCode.4060006',
  },
  ImsResetPasswordRecoveryCodeHelperRecoveryCodeMaxAttempts: {
    defaultMessage: 'Too many incorrect attempts. Please, resend the code',
    id: 'ims.resetPassword.recoveryCode.helper.recoveryCodeMaxAttempts',
  },
  ImsResetPasswordRecoveryCodeHelperValueMandatory: {
    defaultMessage: 'Value is mandatory',
    id: 'ims.resetPassword.recoveryCode.helper.valueMandatory',
  },
  UserChangePasswordDialogImsErrorIncorrectPassword: {
    defaultMessage: 'Incorrect password',
    id: 'userChangePasswordDialog.ims.error.incorrectPassword',
  },
  UserChangePasswordDialogImsErrorSessionExpired: {
    defaultMessage: 'Your session has expired. Please, enter your current password to change it',
    id: 'userChangePasswordDialog.ims.error.sessionExpired',
  },
  UserEditProfileDialogImsErrorSessionExpired: {
    defaultMessage: 'Your session has expired. Please, <link>log in again</link> to edit your profile',
    id: 'userEditProfileDialog.ims.error.sessionExpired',
  },
});
