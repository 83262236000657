import { OryLoginStatus } from '../../ory/types';
import { CommonTypes, IError, IReducers, IReduxAction } from '../reduxTypes';
import { IAuthStore, initialAuthStore } from './store';
import types from './types';

const reducers: IReducers<IAuthStore, CommonTypes> = {
  [types.LOGIN_REQUEST]: (state: IAuthStore) =>
    ({
      ...state,
      accessToken: undefined,
      isFetching: true,
      throttlingRetryAfter: undefined,
    }) as IAuthStore,

  [types.LOGIN_SUCCESS]: (state: IAuthStore, values: { accessToken: string; preferUserLogin: boolean } | undefined) => {
    return {
      ...state,
      accessToken: values?.accessToken,
      isFetching: false,
      isRegistered: false,
      preferUserLogin: values?.preferUserLogin ?? false,
    };
  },

  [types.SET_ORY_LOGIN_STATUS]: (state: IAuthStore, values: OryLoginStatus) => {
    return {
      ...state,
      oryLoginStatus: values,
    };
  },

  [types.LOGIN_FAILURE]: (_state: IAuthStore, error: IError) => ({
    ...initialAuthStore(),
    error,
  }),

  [types.LOGIN_FAILURE_RESEND]: (_state: IAuthStore, values: { email: string }) => {
    return {
      ...initialAuthStore(),
      redirectToResendWithEmail: values.email,
    };
  },

  [types.LOGIN_RESET]: (_state: IAuthStore) => {
    return {
      ...initialAuthStore(),
    };
  },

  [types.LOGIN_THROTTLING]: (_state: IAuthStore, values: { throttlingRetryAfter: Date }) => ({
    ...initialAuthStore(),
    throttlingRetryAfter: values.throttlingRetryAfter,
  }),

  [types.FIRST_LOGIN]: (state: IAuthStore, values: { value: boolean }) => ({
    ...state,
    isFirstLogin: values.value,
  }),

  [types.REGISTER_REQUEST]: (state: IAuthStore) => ({
    ...state,
    isFetching: true,
    isRegistered: false,
  }),

  [types.REGISTER_SUCCESS]: () => ({
    ...initialAuthStore(),
    isRegistered: true,
  }),

  [types.REGISTER_FAILURE]: (_state: IAuthStore, error: IError) => ({
    ...initialAuthStore(),
    error,
  }),

  [types.RESET_PASSWORD_REQUEST]: (state: IAuthStore) => ({
    ...state,
    isFetching: true,
  }),

  [types.RESET_PASSWORD_SUCCESS]: () => initialAuthStore(),

  [types.RESET_PASSWORD_FAILURE]: (_state: IAuthStore, error: IError) => ({
    ...initialAuthStore(),
    error,
  }),

  [types.RESEND_INVITATION_EMAIL_REQUEST]: (state: IAuthStore) => ({
    ...state,
    resendInvitation: {
      ...state.resendInvitation,
      isFetching: true,
    },
  }),

  [types.RESEND_INVITATION_EMAIL_SUCCESS]: (state: IAuthStore) => ({
    ...state,
    resendInvitation: {
      ...state.resendInvitation,
      isFetching: false,
    },
  }),

  [types.RESEND_INVITATION_EMAIL_FAILURE]: (state: IAuthStore) => ({
    ...state,
    resendInvitation: {
      ...state.resendInvitation,
      error: true,
      isFetching: false,
    },
  }),

  [types.SET_PREFER_USER_LOGIN]: (state: IAuthStore, values: { preferUserLogin: boolean }) => ({
    ...state,
    preferUserLogin: values.preferUserLogin,
  }),
};

export function authReducers<TValue extends CommonTypes>(
  state = initialAuthStore(),
  reducer: IReduxAction<TValue>
): IAuthStore {
  if (reducers[reducer.type]) {
    if (reducer.error !== undefined) {
      return reducers[reducer.type](state, reducer.error);
    }

    return reducers[reducer.type](state, reducer.values);
  }

  return state;
}
