import { CONTEXT_REMOTE_CONFIGURATION, DEVICE_STATUS, DEVICE_TYPE, SERVICE } from '../../config/devices';
import apartmentTypes from '../apartments/types';
import types from './types';

const initialDevice = (name, count = 0) => ({
  count,
  status: DEVICE_STATUS.OK,
  type: name,
});

const initialService = {
  devices: [
    initialDevice(DEVICE_TYPE.ID_COMPACT),
    initialDevice(DEVICE_TYPE.THIRD_PARTY),
    initialDevice(DEVICE_TYPE.ID_TALK),
    initialDevice(DEVICE_TYPE.HIPMO),
    initialDevice(DEVICE_TYPE.DOOR_STATION),
    initialDevice(DEVICE_TYPE.IDT),
    initialDevice(DEVICE_TYPE.IP_INTERCOM),
  ],
};

export const initialState = {
  addDeviceModal: {
    data: null,
    didInvalidate: false,
    isAdvanceFinished: false,
    isFinished: false,
    show: false,
    wait: false,
  },
  callSettingsDevices: {
    chipOptions: {
      data: [],
      isFetching: false,
    },
    data: [],
    didInvalid: false,
    filter: {
      types: '',
    },
    isFetching: false,
    order: 'name',
    page: 1,
    resultsFiltered: 0,
    resultsTotal: 0,
    rowsPerPage: 10,
  },
  deleteDeviceModal: {
    id: null,
    processing: false,
    show: false,
  },
  detailDevice: {
    data: {},
    didInvalid: false,
    error: undefined,
    isEditing: false,
    isFetching: false,
  },
  deviceByType: {
    [DEVICE_TYPE.IP_INTERCOM]: [],
    [DEVICE_TYPE.ID_COMPACT]: [],
    [DEVICE_TYPE.ID_TALK]: [],
  },
  firmware: {
    error: null,
    info: null,
    isFetching: false,
    isUpdating: false,
  },
  hasDefaultPasswordDevices: false,
  isRemoteConfigurationActive: false,
  list: {
    data: [],
    didInvalid: false,
    filter: {
      fulltext: '',
    },
    isFetching: false,
    order: 'name',
    page: 1,
    resultsFiltered: 0,
    resultsTotal: 0,
    rowsPerPage: 10,
  },
  mobileVideoDashboard: {
    count: {
      [DEVICE_TYPE.IDT]: 0,
    },
    my2nId: null,
    services: {
      [SERVICE.ACS]: initialService,
      [SERVICE.MOBILE_VIDEO]: initialService,
      [SERVICE.GSM_CALLS]: initialService,
      [SERVICE.LOCAL_CALLS]: initialService,
      [SERVICE.NOTIFICATION]: initialService,
      [SERVICE.REMOTE_CONFIGURATION]: initialService,
    },
  },
  notVerifiedDevices: {
    data: [],
    didInvalid: false,
    filter: {
      fulltext: '',
    },
    isFetching: false,
    order: 'name',
    page: 1,
    resultsFiltered: 0,
    resultsTotal: 0,
    rowsPerPage: 10,
  },
  rcModal: {
    device: {},
    show: false,
  },
  rejectDeviceModal: {
    device: {},
    show: false,
  },
  userProfile: {
    data: [],
    didInvalid: false,
    filter: {
      fulltext: '',
    },
    isFetching: false,
    isFirstTimeLoaded: false,
    order: 'name',
    page: 1,
    resultsFiltered: 0,
    resultsTotal: 0,
    rowsPerPage: 10,
  },
  users: {
    data: null,
    didInvalid: false,
    isFetching: false,
  },
  verifyModal: {
    data: null,
    didInvalidate: false,
    isFinished: false,
    show: false,
    wait: false,
  },
  waitingDevices: {
    data: [],
    didInvalid: false,
    filter: {
      fulltext: '',
    },
    isFetching: false,
    order: null,
    page: 1,
    resultsFiltered: 0,
    resultsTotal: 0,
    rowsPerPage: 10,
  },
};

const reducers = {
  [types.GET_DEVICE_DETAIL_REQUEST]: (state) => ({
    ...state,
    detailDevice: {
      ...initialState.detailDevice,
      isEditing: false,
      isFetching: true,
    },
    firmware: initialState.firmware,
  }),

  [types.GET_DEVICE_DETAIL_SUCCESS]: (state, { payload }) => ({
    ...state,
    detailDevice: {
      ...initialState.detailDevice,
      data: payload,
    },
  }),

  [types.GET_DEVICE_DETAIL_FAILURE]: (state, { error }) => ({
    ...state,
    detailDevice: {
      ...initialState.detailDevice,
      error,
    },
  }),

  [types.ADD_DEVICE_WAIT]: (state, { payload }) => ({
    ...state,
    addDeviceModal: {
      ...state.addDeviceModal,
      data: payload,
      isFinished: false,
      wait: true,
    },
  }),

  [types.ADD_DEVICE_STOP_WAIT]: (state) => ({
    ...state,
    addDeviceModal: {
      ...state.addDeviceModal,
      isFinished: true,
      wait: false,
    },
    list: {
      ...state.list,
      didInvalid: true,
    },
    notVerifiedDevices: {
      ...state.notVerifiedDevices,
      didInvalid: true,
    },
  }),

  [types.SAVE_CALL_SETTINGS_MULTIPLE_REQUEST]: (state) => ({
    ...state,
    callSettingsDevices: {
      ...state.callSettingsDevices,
      isFetching: true,
    },
  }),

  [types.SAVE_CALL_SETTINGS_MULTIPLE_SUCCESS]: (state) => ({
    ...state,
    addDeviceModal: {
      ...state.addDeviceModal,
      isAdvanceFinished: true,
    },
    callSettingsDevices: {
      ...state.callSettingsDevices,
      isFetching: false,
    },
  }),

  [types.SAVE_CALL_SETTINGS_MULTIPLE_FAILURE]: (state) => ({
    ...state,
    callSettingsDevices: {
      ...state.callSettingsDevices,
      isFetching: false,
    },
  }),

  [types.ADD_DEVICE_SUCCESS]: (state, { payload }) => ({
    ...state,
    addDeviceModal: {
      ...state.addDeviceModal,
      data: payload,
      didInvalidate: true,
      isFinished: true,
      wait: false,
    },
    list: {
      ...state.list,
      didInvalid: true,
    },
    notVerifiedDevices: {
      ...state.notVerifiedDevices,
      didInvalid: true,
    },
  }),

  [types.ADD_DEVICE_CLEAR]: (state) => ({
    ...state,
    addDeviceModal: initialState.addDeviceModal,
  }),

  [types.VERIFY_SHOW_MODAL]: (state, { device }) => ({
    ...state,
    verifyModal: {
      ...initialState.verifyModal,
      data: device,
      show: true,
    },
  }),

  [types.VERIFY_DEVICE_WAIT]: (state) => ({
    ...state,
    verifyModal: {
      ...state.verifyModal,
      isFinished: false,
      wait: true,
    },
  }),

  [types.VERIFY_DEVICE_STOP_WAIT]: (state) => ({
    ...state,
    list: {
      ...state.list,
      didInvalid: true,
    },
    notVerifiedDevices: {
      ...state.notVerifiedDevices,
      didInvalid: true,
    },
    verifyModal: {
      ...state.verifyModal,
      isFinished: true,
      wait: false,
    },
  }),

  [types.VERIFY_HIDE_MODAL]: (state) => ({
    ...state,
    verifyModal: initialState.verifyModal,
  }),

  [types.VERIFY_DEVICE_SUCCESS]: (state, { payload }) => ({
    ...state,
    list: {
      ...state.list,
      didInvalid: true,
    },
    notVerifiedDevices: {
      ...state.notVerifiedDevices,
      didInvalid: true,
    },
    verifyModal: {
      ...state.verifyModal,
      data: payload,
      didInvalidate: true,
      isFinished: true,
      wait: false,
    },
  }),

  [types.DELETE_DEVICE_REQUEST]: (state) => ({
    ...state,
    deleteDeviceModal: {
      ...state.deleteDeviceModal,
      processing: true,
    },
  }),

  [types.DELETE_DEVICE_SUCCESS]: (state) => ({
    ...state,
    deleteDeviceModal: initialState.deleteDeviceModal,
    list: {
      ...state.list,
      didInvalid: true,
    },
    notVerifiedDevices: {
      ...state.notVerifiedDevices,
      didInvalid: true,
    },
  }),

  [apartmentTypes.DELETE_APARTMENT_DEVICE_SUCCESS]: (state) => ({
    ...state,
    deleteDeviceModal: initialState.deleteDeviceModal,
  }),

  [types.COUNT_DEVICE_REQUEST]: (state) => ({
    ...state,
    isRemoteConfigurationActive: false,
  }),

  [types.COUNT_DEVICE_SUCCESS]: (state, { payload, ...action }) => {
    if (action.context === CONTEXT_REMOTE_CONFIGURATION) {
      const data = payload || {};
      const deviceTypes = [DEVICE_TYPE.IP_INTERCOM, DEVICE_TYPE.ID_TALK, DEVICE_TYPE.ID_COMPACT];
      let count = 0;
      for (let i = 0; i < deviceTypes.length; i += 1) {
        count += data[deviceTypes[i]]?.active || 0;
      }
      return { ...state, isRemoteConfigurationActive: count > 0 };
    }
    return state;
  },

  [types.COUNT_DEVICE_FAILURE]: (state) => ({
    ...state,
    isRemoteConfigurationActive: false,
  }),

  [types.GET_COMPANY_ADMIN_DEVICES_REQUEST]: (state) => ({
    ...state,
    list: {
      ...state.list,
      isFetching: true,
    },
  }),

  [types.GET_COMPANY_ADMIN_DEVICES_SUCCESS]: (state, { payload, ...action }) => ({
    ...state,
    list: {
      ...state.list,
      data: payload.results || [],
      didInvalid: false,
      filter: action.filter,
      isFetching: false,
      order: action.order,
      page: action.page,
      resultsFiltered: payload.resultsFiltered || 0,
      resultsTotal: payload.resultsTotal || 0,
      rowsPerPage: action.rowsPerPage,
    },
  }),

  [types.GET_COMPANY_ADMIN_DEVICES_FAILURE]: (state) => ({
    ...state,
    list: initialState.list,
  }),

  [types.GET_DEVICES_REQUEST]: (state) => ({
    ...state,
    list: {
      ...state.list,
      isFetching: true,
    },
  }),

  [types.GET_DEVICES_SUCCESS]: (state, { payload, ...action }) => ({
    ...state,
    list: {
      ...state.list,
      data: payload.results || [],
      didInvalid: false,
      filter: action.filter,
      isFetching: false,
      order: action.order,
      page: action.page,
      resultsFiltered: payload.resultsFiltered || 0,
      resultsTotal: payload.resultsTotal || 0,
      rowsPerPage: action.rowsPerPage,
    },
  }),

  [types.GET_DEVICES_FAILURE]: (state) => ({
    ...state,
    list: initialState.list,
  }),

  [types.GET_USER_PROFILE_DEVICES_REQUEST]: (state) => ({
    ...state,
    userProfile: {
      ...state.userProfile,
      isFetching: true,
    },
  }),

  [types.GET_USER_PROFILE_DEVICES_SUCCESS]: (state, { payload, ...action }) => ({
    ...state,
    userProfile: {
      ...state.userProfile,
      data: payload.results || [],
      didInvalid: false,
      filter: action.filter,
      isFetching: false,
      isFirstTimeLoaded: true,
      order: action.order,
      page: action.page,
      resultsFiltered: payload.resultsFiltered || 0,
      resultsTotal: payload.resultsTotal || 0,
      rowsPerPage: action.rowsPerPage,
    },
  }),

  [types.GET_USER_PROFILE_DEVICES_FAILURE]: (state) => ({
    ...state,
    userProfile: initialState.userProfile,
  }),

  [types.CHANGE_DEVICE_LIST_FROM_SOCKET]: (state, { device }) => {
    const data = state.list.data.map((item) => {
      if (item?.id !== device?.id) {
        return item;
      }
      return {
        ...item,
        ...device,
        ...(item?.services?.[SERVICE.ACCESS_CONTROL] && {
          services: {
            ...device?.services,
            [SERVICE.ACCESS_CONTROL]: item.services[SERVICE.ACCESS_CONTROL],
          },
        }),
      };
    });
    return {
      ...state,
      list: {
        ...state.list,
        data,
      },
    };
  },

  [types.CHANGE_DEVICE_DETAIL_FROM_SOCKET]: (state, { device }) => {
    const rcModalDeviceServices = device?.services;
    if (state.rcModal.device?.services?.[SERVICE.ACCESS_CONTROL] && state.rcModal.device?.id === device?.id) {
      rcModalDeviceServices[SERVICE.ACCESS_CONTROL] = state.rcModal.device?.services?.[SERVICE.ACCESS_CONTROL];
    } else if (
      state.detailDevice.data?.services?.[SERVICE.ACCESS_CONTROL] &&
      state.detailDevice.data?.id === device?.id
    ) {
      rcModalDeviceServices[SERVICE.ACCESS_CONTROL] = state.detailDevice.data?.services?.[SERVICE.ACCESS_CONTROL];
    }

    const detailDevice = state.detailDevice.isEditing
      ? state.detailDevice
      : {
          ...state.detailDevice,
          data: {
            ...state.detailDevice.data,
            ...device,
            services: {
              ...device?.services,
              ...(state.detailDevice.data?.services?.[SERVICE.ACCESS_CONTROL] && {
                [SERVICE.ACCESS_CONTROL]: state.detailDevice.data?.services?.[SERVICE.ACCESS_CONTROL],
              }),
            },
          },
        };

    return {
      ...state,
      detailDevice,
      rcModal: {
        ...state.rcModal,
        device: {
          ...state.rcModal.device,
          ...device,
          services: rcModalDeviceServices,
        },
      },
    };
  },

  [types.UPDATE_DEVICE_SERVICE_REQUEST]: (state, { values }) => ({
    ...state,
    detailDevice: {
      ...state.detailDevice,
      data: {
        ...state.detailDevice.data,
        services: {
          ...state.detailDevice.data.services,
          [SERVICE.ACS]: {
            ...state.detailDevice.data.services?.[SERVICE.ACS],
            status: values.modeValue?.mode || null,
          },
          [SERVICE.MOBILE_VIDEO]: {
            ...state.detailDevice.data.services?.[SERVICE.MOBILE_VIDEO],
            cameraSwitchAllowed: values.camValue?.externalCamera || false,
          },
        },
      },
    },
  }),

  [types.UPDATE_DEVICE_PHONEBOOK_SUCCESS]: (state, { payload }) => ({
    ...state,
    detailDevice: {
      ...state.detailDevice,
      data: {
        ...state.detailDevice.data,
        services: {
          ...state.detailDevice.data.services,
          [SERVICE.MOBILE_VIDEO]: {
            ...payload,
          },
        },
      },
    },
  }),

  [types.CHANGE_DEVICE_SERVICE_STATUS_SUCCESS]: (state) => ({
    ...state,
    detailDevice: {
      ...state.detailDevice,
      didInvalid: true,
    },
  }),

  [types.GET_WAITING_DEVICES_REQUEST]: (state) => ({
    ...state,
    waitingDevices: {
      ...initialState.waitingDevices,
      isFetching: true,
    },
  }),

  [types.GET_WAITING_DEVICES_SUCCESS]: (state, { payload, ...action }) => ({
    ...state,
    waitingDevices: {
      ...initialState.waitingDevices,
      data: payload.results,
      didInvalid: false,
      filter: action.filter || {
        fulltext: '',
      },
      page: action.page || 1,
      resultsFiltered: payload.resultsFiltered,
      resultsTotal: payload.resultsTotal,
      rowsPerPage: action.rowsPerPage || 10,
    },
  }),

  [types.GET_WAITING_DEVICES_RELOAD]: (state) => ({
    ...state,
    waitingDevices: {
      ...state.waitingDevices,
      didInvalid: true,
    },
  }),

  [types.GET_WAITING_DEVICES_FAILURE]: (state, { error }) => ({
    ...state,
    waitingDevices: {
      ...initialState.waitingDevices,
      error,
    },
  }),

  [types.SAVE_DEVICE_SWITCH_CODES_SUCCESS]: (state) => ({
    ...state,
    detailDevice: {
      ...state.detailDevice,
      didInvalid: true,
      isEditing: false,
    },
  }),

  [types.UPDATE_DEVICE_SERVICE_SUCCESS]: (state) => ({
    ...state,
    detailDevice: {
      ...state.detailDevice,
      didInvalid: true,
    },
  }),

  [types.APPROVE_DEVICE_SUCCESS]: (state) => ({
    ...state,
    list: {
      ...state.list,
      didInvalid: true,
    },
    waitingDevices: {
      ...state.waitingDevices,
      didInvalid: true,
    },
  }),

  [types.REJECT_DEVICE_SUCCESS]: (state) => ({
    ...state,
    rejectDeviceModal: initialState.rejectDeviceModal,
    waitingDevices: {
      ...state.waitingDevices,
      didInvalid: true,
    },
  }),

  [types.RC_SHOW_MODAL]: (state, { device }) => ({
    ...state,
    rcModal: {
      ...initialState.rcModal,
      device,
      show: true,
    },
  }),

  [types.RC_HIDE_MODAL]: (state) => ({
    ...state,
    rcModal: initialState.rcModal,
  }),

  [types.RC_WEBSOCKET_KNOCK_FAILURE]: (state) => ({
    ...state,
    rcModal: initialState.rcModal,
  }),

  [types.RC_IFRAME_DEVICE_CHANGE]: (state, { payload }) => ({
    ...state,
    rcModal: {
      ...state.rcModal,
      device: {
        ...state.rcModal.device,
        services: {
          ...state.rcModal.device?.services,
          [SERVICE.REMOTE_CONFIGURATION]: payload,
        },
      },
    },
  }),

  [types.GET_CALL_SETTINGS_DEVICES_REQUEST]: (state) => ({
    ...state,
    callSettingsDevices: {
      ...state.callSettingsDevices,
      isFetching: true,
    },
  }),

  [types.GET_CALL_SETTINGS_DEVICES_SUCCESS]: (state, { payload, ...action }) => ({
    ...state,
    callSettingsDevices: {
      ...state.callSettingsDevices,
      data: payload.results || [],
      didInvalid: false,
      filter: action.filter,
      isFetching: false,
      order: action.order,
      page: action.page,
      resultsFiltered: payload.resultsFiltered || 0,
      resultsTotal: payload.resultsTotal || 0,
      rowsPerPage: action.rowsPerPage,
    },
  }),

  [types.GET_CALL_SETTINGS_DEVICES_FAILURE]: (state) => ({
    ...state,
    callSettingsDevices: initialState.callSettingsDevices,
  }),

  [types.GET_CALL_SETTINGS_DEVICES_CHIPS_REQUEST]: (state) => ({
    ...state,
    callSettingsDevices: {
      ...state.callSettingsDevices,
      chipOptions: {
        ...state.callSettingsDevices.chipOptions,
        isFetching: true,
      },
    },
  }),

  [types.GET_CALL_SETTINGS_DEVICES_CHIPS_SUCCESS]: (state, { payload }) => ({
    ...state,
    callSettingsDevices: {
      ...state.callSettingsDevices,
      chipOptions: {
        ...state.callSettingsDevices.chipOptions,
        data: payload.results,
        isFetching: false,
      },
    },
  }),

  [types.GET_CALL_SETTINGS_DEVICES_CHIPS_FAILURE]: (state) => ({
    ...state,
    callSettingsDevices: initialState.callSettingsDevices,
  }),

  [types.SAVE_DEVICE_CONTACT_LIST_SUCCESS]: (state) => ({
    ...state,
    detailDevice: {
      ...state.detailDevice,
      didInvalid: true,
      isEditing: false,
    },
  }),

  [types.EDIT_DEVICE_REQUEST]: (state) => ({
    ...state,
    detailDevice: {
      ...state.detailDevice,
      isEditing: true,
    },
  }),

  [types.EDIT_DEVICE_SUCCESS]: (state) => ({
    ...state,
    detailDevice: {
      ...state.detailDevice,
      didInvalid: true,
      isEditing: false,
    },
  }),

  [types.LOAD_USERS_REQUEST]: (state) => ({
    ...state,
    users: {
      ...state.users,
      isFetching: true,
    },
  }),

  [types.LOAD_USERS_SUCCESS]: (state, { payload }) => ({
    ...state,
    users: {
      ...state.users,
      data: payload,
      isFetching: false,
    },
  }),

  [types.LOAD_USERS_FAILURE]: (state, { error }) => ({
    ...state,
    users: {
      ...initialState.users,
      error,
    },
  }),

  [types.SAVE_DEVICE_BUTTON_CONFIGURATION_SUCCESS]: (state) => ({
    ...state,
    detailDevice: {
      ...state.detailDevice,
      didInvalid: true,
      isEditing: false,
    },
  }),

  [types.UPDATE_DTMF_CODE_REQUEST]: (state) => ({
    ...state,
    detailDevice: {
      ...state.detailDevice,
      isFetching: true,
    },
  }),

  [types.UPDATE_DTMF_CODE_SUCCESS]: (state) => ({
    ...state,
    detailDevice: {
      ...state.detailDevice,
      didInvalid: true,
      isFetching: false,
    },
  }),

  [types.UPDATE_DTMF_CODE_FAILURE]: (state, { error }) => ({
    ...state,
    detailDevice: {
      ...state.detailDevice,
      error,
      isFetching: false,
    },
  }),

  [types.GET_FIRMWARE_VERSION_REQUEST]: (state) => ({
    ...state,
    firmware: {
      ...state.firmware,
      isFetching: true,
    },
  }),

  [types.GET_FIRMWARE_VERSION_SUCCESS]: (state, { payload }) => ({
    ...state,
    firmware: {
      ...state.firmware,
      info: payload,
      isFetching: false,
    },
  }),

  [types.GET_FIRMWARE_VERSION_FAILURE]: (state, { error }) => ({
    ...state,
    firmware: {
      ...state.firmware,
      error,
      isFetching: false,
    },
  }),

  [types.UPDATE_FIRMWARE_SUCCESS]: (state) => ({
    ...state,
    firmware: {
      ...state.firmware,
      isUpdating: true,
    },
  }),

  [types.UPDATE_FIRMWARE_FAILURE]: (state, { error }) => ({
    ...state,
    firmware: {
      ...state.firmware,
      error,
    },
  }),

  [types.RC_ACTIVATE_SUCCESS]: (state) => ({
    ...state,
    detailDevice: {
      ...state.detailDevice,
      didInvalid: true,
    },
  }),

  [types.RC_CREATE_SUCCESS]: (state) => ({
    ...state,
    detailDevice: {
      ...state.detailDevice,
      didInvalid: true,
    },
  }),

  [types.GET_DEFAULT_PASSWORD_DEVICES_SUCCESS]: (state, { payload }) => ({
    ...state,
    hasDefaultPasswordDevices: payload,
  }),

  [types.GET_NOT_VERIFIED_DEVICES_REQUEST]: (state) => ({
    ...state,
    notVerifiedDevices: {
      ...state.notVerifiedDevices,
      isFetching: true,
    },
  }),

  [types.GET_NOT_VERIFIED_DEVICES_SUCCESS]: (state, { payload, ...action }) => ({
    ...state,
    notVerifiedDevices: {
      ...state.notVerifiedDevices,
      data: payload.results || [],
      didInvalid: false,
      filter: action.filter,
      isFetching: false,
      order: action.order,
      page: action.page,
      resultsFiltered: payload.resultsFiltered || 0,
      resultsTotal: payload.resultsTotal || 0,
      rowsPerPage: action.rowsPerPage,
    },
  }),

  [types.GET_NOT_VERIFIED_DEVICES_FAILURE]: (state) => ({
    ...state,
    notVerifiedDevices: initialState.notVerifiedDevices,
  }),

  [types.DELETE_DEVICE_MODAL_SHOW]: (state, { id }) => ({
    ...state,
    deleteDeviceModal: {
      ...initialState.deleteDeviceModal,
      id,
      show: true,
    },
  }),

  [types.DELETE_DEVICE_MODAL_HIDE]: (state) => ({
    ...state,
    deleteDeviceModal: initialState.deleteDeviceModal,
  }),

  [types.SET_DEVICE_EDITING]: (state, { isEditing }) => ({
    ...state,
    detailDevice: {
      ...state.detailDevice,
      isEditing,
    },
  }),
};

const reducer = (state = initialState, action = {}) => {
  if (reducers[action.type]) {
    return reducers[action.type](state, action);
  }
  return state;
};

export default reducer;
