import AddIcon from '@mui/icons-material/Add';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { CompanyAdminDataGrid, DataGridActionButton, DataGridSearchField, Title, UserModal } from '../../components';
import { changeFilter, useButtonText } from '../../helpers/dataGrid';
import { useUrlParams } from '../../helpers/urlParams';
import useBreakpoints from '../../helpers/useBreakpoints';
import commonActions from '../../modules/common';
import actions, { CONTEXT, selectors } from '../../modules/user';
import messages from './messages';

const CompanyAdmin = ({
  currentUser,
  data,
  didInvalid,
  filter,
  loading,
  onDeleteUser,
  onLoadUsers,
  onSearch,
  order,
  page,
  resultsFiltered,
  rowsPerPage,
}) => {
  const { companyId } = useUrlParams();
  const { downSm } = useBreakpoints();

  const [fulltext, setFulltext] = useState(filter.fulltext);
  const [addUserModal, toggleUserModal] = useState(false);

  const onChangeFilter = ({ target }) => {
    const { name, value } = target;
    setFulltext(value);
    changeFilter(onSearch, actions.getUsersList(CONTEXT.COMPANY_ADMINS), {
      companyId,
      emptyApartment: true,
      emptySite: true,
      filter,
      order,
      page,
      rowsPerPage,
    })(name, value);
  };

  return (
    <>
      <Title
        heading={<FormattedMessage {...messages.CompanyAdminTitle} />}
        rightSide={<DataGridSearchField fulltext={fulltext} onChangeFilter={onChangeFilter} />}
        rightSideActions={
          <DataGridActionButton
            onClick={() => toggleUserModal(true)}
            startIcon={downSm ? <PersonAddIcon /> : <AddIcon />}
            variant={downSm ? 'text' : 'contained'}
          >
            {useButtonText(messages.CompanyAdminToolsAddNewAdmin, messages.CompanyAdminToolsAddNewAdminMobile)}
          </DataGridActionButton>
        }
      />
      <CompanyAdminDataGrid
        companyId={companyId}
        currentUser={currentUser}
        didInvalid={didInvalid}
        filter={filter}
        isFetching={loading}
        onDeleteUser={onDeleteUser}
        onLoadUsers={onLoadUsers}
        order={order}
        page={page}
        resultsFiltered={resultsFiltered}
        rowsPerPage={rowsPerPage}
        users={data}
      />
      {addUserModal && (
        <UserModal context={CONTEXT.COMPANY_ADMINS} onClose={() => toggleUserModal(false)} open={addUserModal} />
      )}
    </>
  );
};

CompanyAdmin.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    })
  ).isRequired,
  didInvalid: PropTypes.bool.isRequired,
  filter: PropTypes.shape({
    fulltext: PropTypes.string,
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  onDeleteUser: PropTypes.func.isRequired,
  onLoadUsers: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  resultsFiltered: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => selectors.getUserListSelector(CONTEXT.COMPANY_ADMINS)(state);

const mapDispatchToProps = {
  onDeleteUser: actions.deleteUserShowModal(CONTEXT.COMPANY_ADMINS),
  onLoadUsers: actions.getUsersList(CONTEXT.COMPANY_ADMINS),
  onSearch: commonActions.search,
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyAdmin);
