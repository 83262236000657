import { defineMessages } from 'react-intl';

export default defineMessages({
  accessTypeApartments: {
    defaultMessage: 'Apartments',
    description: 'Apartments list',
    id: 'accessLogDetailModal.apartments',
  },
  accessTypeCard: {
    defaultMessage: 'RFID card or badge',
    description: 'Access Log Detail Modal - access type - CARD',
    id: 'accessLogDetailModal.accessType.CARD',
  },
  accessTypeClose: {
    defaultMessage: 'Close',
    description: 'Close button',
    id: 'accessLogDetailModal.close',
  },
  accessTypeCode: {
    defaultMessage: 'PIN or QR code',
    description: 'Access Log Detail Modal - access type - CODE',
    id: 'accessLogDetailModal.accessType.CODE',
  },
  accessTypeDetail: {
    defaultMessage: 'Detail',
    description: 'Detail',
    id: 'accessLogDetailModal.detail',
  },
  accessTypeDevice: {
    defaultMessage: 'Device',
    description: 'Device',
    id: 'accessLogDetailModal.device',
  },
  accessTypeFingerPrint: {
    defaultMessage: 'Fingerprint',
    description: 'Access Log Detail Modal - access type - FINGER_PRINT',
    id: 'accessLogDetailModal.accessType.FINGER_PRINT',
  },
  accessTypeLicensePlate: {
    defaultMessage: 'Car license plate',
    description: 'Access Log Detail Modal - access type - LICENSE_PLATE',
    id: 'accessLogDetailModal.accessType.LICENSE_PLATE',
  },
  accessTypeMethod: {
    defaultMessage: 'Method',
    description: 'Method',
    id: 'accessLogDetailModal.method',
  },
  accessTypeMobileKey: {
    defaultMessage: 'WaveKey',
    description: 'Access Log Detail Modal - access type - MOBILE_KEY',
    id: 'accessLogDetailModal.accessType.MOBILE_KEY',
  },
  accessTypeQrCode: {
    defaultMessage: 'QR code',
    description: 'Access Log Detail Modal - access type - QR_CODE',
    id: 'accessLogTable.accessType.QR_CODE',
  },
  accessTypeRemote: {
    defaultMessage: 'Remote call or API',
    description: 'Access Log Detail Modal - access type - QR_CODE',
    id: 'accessLogDetailModal.accessType.REMOTE',
  },
  accessTypeRex: {
    defaultMessage: 'Request to exit',
    description: 'Access Log Detail Modal - access type - REX',
    id: 'accessLogDetailModal.accessType.REX',
  },
  accessTypeTitle: {
    defaultMessage: 'Access record detail',
    description: 'Modal title',
    id: 'accessLogDetailModal.title',
  },
  accessTypeUnauthorizedAccess: {
    defaultMessage: 'Unauthorised access',
    description: 'Access Log Detail Modal - access type - unauthorized access',
    id: 'accessLogDetailModal.accessTypeUnauthorizedAccess',
  },
  accessTypeUnknown: {
    defaultMessage: 'Unknown type',
    description: 'Access Log Detail Modal - access type - unknown',
    id: 'accessLogDetailModal.accessTypeUnknown',
  },
  accessTypeUser: {
    defaultMessage: 'User',
    description: 'User name',
    id: 'accessLogDetailModal.user',
  },
});
