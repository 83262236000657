import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import ImportResultDataGrid from '../../containers/SiteUsersImport/ResultStep/ImportResultDataGrid';
import { selectors } from '../../modules/user';
import { InfoModal } from '../_DesignSystemWrappers/Modal';
import CustomDate from '../CustomDate';
import ImportResultStatus from './ImportResultStatus';
import messages from './messages';

function ImportStatusModal({ onClose, open, ...props }) {
  const { formatMessage } = useIntl();
  const currentImportUsersData = useSelector(selectors.currentImportUsersData);
  return (
    <InfoModal
      buttonText={formatMessage(messages.SiteUsersImportAlertStatusModalButton)}
      onClose={onClose}
      open={open}
      title={formatMessage(messages.SiteUsersImportAlertStatusModalTitle)}
      {...props}
    >
      <Stack>
        {currentImportUsersData?.updated && (
          <Box pb={2}>
            <Typography color="secondary">
              <FormattedMessage
                {...messages.SiteUsersImportAlertStatusLastImportTookPlace}
                values={{
                  lastImportIn: <CustomDate dateValue={currentImportUsersData.updated} showTime />,
                }}
              />
            </Typography>
          </Box>
        )}
        <ImportResultStatus inline />
        <ImportResultDataGrid />
      </Stack>
    </InfoModal>
  );
}

ImportStatusModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ImportStatusModal;
