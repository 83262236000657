import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import storeAndroidImg from '../../assets/store_android.png';
import storeIOSImg from '../../assets/store_ios.png';
import messages from './messages';

const styles = {
  description: {
    fontSize: '20px',
    fontWeight: 'light',
    pt: 2,
  },
  item: {
    alignItems: 'center',
    display: 'flex',
    px: {
      md: 8,
      xs: 0,
    },
    py: 4,
  },
  itemWrapper: {
    display: 'flex',
    flexDirection: 'column',
    pl: 4,
    width: '250px',
  },
  productText: {
    fontSize: '16px',
    fontWeight: 'light',
    maxWidth: '850px',
    px: {
      md: 0,
      xs: 2,
    },
    textAlign: 'center',
  },
  root: {
    backgroundColor: 'common.white',
    py: 2,
  },
  title: {
    fontSize: '26px',
    fontWeight: 'light',
    textAlign: 'center',
  },
  wrapper: {
    maxWidth: {
      lg: '1050px',
      md: '850px',
    },
    mx: 'auto',
    py: 2,
    width: '100%',
  },
};

const Apps = () => (
  <Box sx={styles.root}>
    <Box sx={styles.wrapper}>
      <Typography component="h3" sx={styles.title}>
        <FormattedMessage {...messages.mvideoAppsTitle} values={{ sup: (chunks) => <sup>{chunks}</sup> }} />
      </Typography>
      <Box
        alignItems="flex-start"
        display="flex"
        flexDirection={{
          md: 'row',
          xs: 'column',
        }}
        justifyContent="center"
      >
        <Box sx={styles.item}>
          <Box sx={styles.itemWrapper}>
            <a
              href="https://itunes.apple.com/us/app/2n-mobile-video/id1188403431?ls=1&mt=8"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img alt="Apple store" src={storeIOSImg} />
            </a>
            <Typography sx={styles.description}>
              <FormattedMessage {...messages.mvideoAppsIosLink} />
            </Typography>
          </Box>
        </Box>
        <Box sx={styles.item}>
          <Box sx={styles.itemWrapper}>
            <a
              href="https://play.google.com/store/apps/details?id=com.nn.my2ncommunicator"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img alt="Google play" src={storeAndroidImg} />
            </a>
            <Typography sx={styles.description}>
              <FormattedMessage {...messages.mvideoAppsAndroidLink} />
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center">
        <Typography paragraph sx={styles.productText}>
          <FormattedMessage {...messages.mvideoAppsProductText} values={{ sup: (chunks) => <sup>{chunks}</sup> }} />
        </Typography>
      </Box>
    </Box>
  </Box>
);

export default Apps;
