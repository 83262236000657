import { Grid } from '@mui/material';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { EMAIL_SALES } from '../../../../config/address';
import messages from './messages';

function ElevatorIntegrationInfo() {
  return (
    <>
      <Grid container spacing={2} sx={{ pb: 4 }}>
        <Grid item lg={4} md={12} xs={12}>
          <Typography variant="body1">
            <FormattedMessage {...messages.elevatorIntegrationInactiveText1}></FormattedMessage>
          </Typography>
        </Grid>
        <Grid item lg={4} md={12} xs={12}>
          <Typography variant="body1">
            <FormattedMessage
              values={{
                ul: (chunk) => <ul style={{ margin: 0, paddingLeft: '20px' }}>{chunk}</ul>,
              }}
              {...messages.elevatorIntegrationInactiveText2}
            ></FormattedMessage>
          </Typography>
        </Grid>
        <Grid item lg={4} md={12} xs={12}>
          <Typography variant="body1">
            <FormattedMessage
              {...messages.elevatorIntegrationInactiveText3}
              values={{
                link: <Link href={'mailto:' + EMAIL_SALES}>{EMAIL_SALES}</Link>,
              }}
            ></FormattedMessage>
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default ElevatorIntegrationInfo;
