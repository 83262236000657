import { defineMessages } from 'react-intl';

export default defineMessages({
  codeVerificationButton: {
    defaultMessage: 'Apply code',
    description: 'Button label',
    id: 'codeVerification.button',
  },
  codeVerificationCodeInputInvalid: {
    defaultMessage: 'Invalid code',
    description: 'Code validation message',
    id: 'codeVerification.codeInput.invalid',
  },
  codeVerificationCodeInputLabel: {
    defaultMessage: 'Code from voucher',
    description: 'Code input label',
    id: 'codeVerification.codeInput.label',
  },
  codeVerificationCodeInputRequired: {
    defaultMessage: 'Value is mandatory',
    description: 'Code required message',
    id: 'codeVerification.codeInput.required',
  },
  codeVerificationSuccessCredits: {
    defaultMessage: '{credit} {count, plural, one {credit} other {credits}}',
    description: 'Code verification success credits',
    id: 'codeVerification.success.credits',
  },
  codeVerificationSuccessHeader: {
    defaultMessage: 'Entered code contains',
    description: 'Code verification success header',
    id: 'codeVerification.success.header',
  },
  codeVerificationSuccessInfoPerApartment: {
    defaultMessage: '1 apartment subscription =' + ' {count, plural, one {# credit} other {# credits}}/month',
    description: 'Code verification success info',
    id: 'codeVerification.success.info.perApartment',
  },
  codeVerificationSuccessInfoPerDevice: {
    defaultMessage: '1 device subscription =' + ' {count, plural, one {# credit} other {# credits}}/month',
    description: 'Code verification success info',
    id: 'codeVerification.success.info.perDevice',
  },
  codeVerificationWarningExpiredHeader: {
    defaultMessage: 'The voucher has expired',
    description: 'Code verification expired warning header',
    id: 'codeVerification.warning.expired.header',
  },
  codeVerificationWarningExpiredText: {
    defaultMessage: 'Use another voucher or contact your distributor.',
    description: 'Code verification expired warning text',
    id: 'codeVerification.warning.expired.text',
  },
  codeVerificationWarningNotFoundHeader: {
    defaultMessage: 'Your code cannot be applied',
    description: 'Code verification not found warning header',
    id: 'codeVerification.warning.notFound.header',
  },
  codeVerificationWarningNotFoundText: {
    defaultMessage: 'Double-check and enter the code again.',
    description: 'Code verification not found warning text',
    id: 'codeVerification.warning.notFound.text',
  },
  codeVerificationWarningTrialHeader: {
    defaultMessage: 'Important: Free trial period',
    description: 'Code verification trial warning header',
    id: 'codeVerification.warning.trial.header',
  },
  codeVerificationWarningTrialText: {
    defaultMessage: 'Using a voucher will end your trial period,' + ' which lasts till {expirationDate}.',
    description: 'Code verification trial warning text',
    id: 'codeVerification.warning.trial.text',
  },
  codeVerificationWarningUsedContext: {
    defaultMessage: '<strong>{site}</strong> site ({company} company)',
    description: 'Code verification used warning context',
    id: 'codeVerification.warning.used.context',
  },
  codeVerificationWarningUsedHeader: {
    defaultMessage: 'This voucher has been used for {context}',
    description: 'Code verification used warning header',
    id: 'codeVerification.warning.used.header',
  },
  codeVerificationWarningUsedText: {
    defaultMessage: 'Use another voucher or contact your distributor.',
    description: 'Code verification used warning text',
    id: 'codeVerification.warning.used.text',
  },
  prepaidCodeHeader: {
    defaultMessage: 'Use your voucher',
    description: 'Prepaid code section header',
    id: 'prepaidCode.header',
  },
  prepaidCodeSubscription: {
    defaultMessage: 'Enter the code from your voucher to replenish your credit pool.',
    description: 'Prepaid code subscription',
    id: 'prepaidCode.subscription',
  },
});
