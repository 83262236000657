import { useSelector } from 'react-redux';
import { useGetActualCompanyId } from '../../../../../hooks/companyHooks';
import { useGetActualSiteId } from '../../../../../hooks/siteHooks';
import { selectors as apartmentSelectors } from '../../../../../modules/apartments';
import { IApartment } from '../../../../../modules/apartments/store';
import { devicesSelectors, selectors } from '../../../../../modules/devices';
import {
  IButtonAndDisplayContact,
  IContactListContact,
  IDevice,
} from '../../../../../modules/devices/store/deviceStore';
import { IOptionIconData } from '../CallSettingOptionIcon';

export interface IDeviceWithWarningState extends IDevice {
  warning: boolean;
}

export interface IButtonItem {
  index: number;
  name: string;
  ringingGroup?: string;
  contact: IContactListContact;
  display: IButtonAndDisplayContact;
  selectedOptions: IDevice[] | IApartment;
  button?: IButtonAndDisplayContact;
}

export interface IStateData {
  buttonList: IButtonItem[];
  device: IDevice;
  apartmentsChipsOptions: IApartment[];
  devicesChipsOptions: IDeviceWithWarningState[];
  isDeviceDetailEditing: boolean;
  isManualWarning: boolean;
  ringingGroupSizeLimit: number;
  companyId: number;
  siteId: number;
  isFetching: boolean;
}

export interface ICallSettingOption {
  optionKey: number;
  optionName?: string;
  optionKind: OptionKind;
  optionIcon: IOptionIconData;
  hasUpdated: boolean;
  optionState?: OptionColor;
}

export type OptionKind = 'Devices' | 'Apartments';
export type OptionColor = 'warning' | 'primary' | 'default';

export interface IButtonRenderData {
  selectedOptions?: ICallSettingOption[];
  index: number;
  buttonName: string;
  ringingGroup?: string;
  externalId?: string;
}

// eslint-disable-next-line max-statements
export function useGetStateData(): IStateData {
  const buttonList: IButtonItem[] = useSelector(devicesSelectors.getIntercomButtonList);
  const device = useSelector(selectors.getCurrentDeviceDataSelector);
  const isDeviceDetailEditing = useSelector(selectors.isDeviceEditing);
  const isManualWarning = useSelector(selectors.getIsManualIntercomWarning);
  const ringingGroupSizeLimit = useSelector(selectors.getRingingGroupSizeLimit);
  const companyId = useGetActualCompanyId();
  const siteId = useGetActualSiteId();

  const isFetchingDevices = useSelector(selectors.getCallSettingsDevicesIsFetching);
  const isFetchingApartments = useSelector(apartmentSelectors.isApartmentListFetching);

  const devicesChipsOptions = useSelector(selectors.getCallSettingsDevicesOptions);
  const apartmentsChipsOptions = useSelector(apartmentSelectors.getApartmentsListOrdered);

  const devicesDataAreLoaded = useSelector(selectors.getCallSettingsDevicesDataAreLoaded);

  return {
    apartmentsChipsOptions,
    buttonList,
    companyId,
    device,
    devicesChipsOptions,
    isDeviceDetailEditing,
    isFetching: isFetchingApartments || isFetchingDevices || !devicesDataAreLoaded,
    isManualWarning,
    ringingGroupSizeLimit,
    siteId,
  };
}
