import PropTypes from 'prop-types';
import React from 'react';
import { CALLS_STATUS, FIRMWARE_STATUS, RC_STATUS, SERVICE, VERIFICATION_STATUS } from '../../config/devices';
import { isActiveService, isNotVerified } from '../../modules/devices/helpers/helpers';
import CallStatus from '../CallStatus';
import DefaultPasswordStatus from '../DefaultPasswordStatus';
import DeviceRCStatus from '../DeviceRCStatus';
import DeviceVerificationStatus from '../DeviceVerificationStatus';
import FirmwareStatus from '../FirmwareStatus';
import DeactivatedStatus from './DeactivatedStatus';

// eslint-disable-next-line complexity,max-statements
const DeviceStatus = ({ device }) => {
  if (device?.services && Object.keys(device.services).length > 0) {
    if(
      device.hasDefaultPassword &&
      !isNotVerified(device.verificationStatus)
    ) {
      return <DefaultPasswordStatus />;
    }

    if (device.services?.[SERVICE.ACS]?.firmwareStatus === FIRMWARE_STATUS.UNSUPPORTED) {
      return <FirmwareStatus status={FIRMWARE_STATUS.UNSUPPORTED} />;
    }

    if (device.deviceType && device.verificationStatus && device.verificationStatus !== VERIFICATION_STATUS.VERIFIED) {
      return <DeviceVerificationStatus status={device.verificationStatus} />;
    }

    if (device.services?.[SERVICE.ACS]?.firmwareStatus === FIRMWARE_STATUS.DEPRECATED) {
      return <FirmwareStatus status={FIRMWARE_STATUS.DEPRECATED} />;
    }

    if (
      device.callsStatus === CALLS_STATUS.MISSING_CALL_SETTINGS ||
      device.callsStatus && device.services?.[SERVICE.REMOTE_CONFIGURATION]?.status !== RC_STATUS.OFFLINE) {
      return <CallStatus size="small" status={device.callsStatus} />;
    }

    if (
      isActiveService(device, SERVICE.REMOTE_CONFIGURATION) &&
      device.services?.[SERVICE.REMOTE_CONFIGURATION]?.status
    ) {
      return <DeviceRCStatus status={device.services[SERVICE.REMOTE_CONFIGURATION].status} />;
    }
  }

  return <DeactivatedStatus />;
};

DeviceStatus.propTypes = {
  device: PropTypes.shape({
    callsStatus: PropTypes.string,
    deviceType: PropTypes.string,
    hasDefaultPassword: PropTypes.bool,
    services: PropTypes.shape({
      [SERVICE.REMOTE_CONFIGURATION]: PropTypes.shape({
        active: PropTypes.bool,
        status: PropTypes.string,
      }),
      [SERVICE.ACS]: PropTypes.shape({
        firmwareStatus: PropTypes.oneOf(Object.keys(FIRMWARE_STATUS)).isRequired,
      }),
      [SERVICE.MOBILE_VIDEO]: PropTypes.shape({
        active: PropTypes.bool,
      }),
    }),
    verificationStatus: PropTypes.string,
  }).isRequired,
};

export default DeviceStatus;
