export const moduleName = 'user';

export const CONTEXT = {
  COMPANY_ADMINS: 'companyAdmins',
  CURRENT_USER: 'currentUser',
  SITE_USERS: 'usersSites',
};

export default {
  ACTIVATE_USER_MY2N_APP_ACCESS_FAILURE: 'ACTIVATE_USER_MY2N_APP_ACCESS_FAILURE',
  ACTIVATE_USER_MY2N_APP_ACCESS_REQUEST: 'ACTIVATE_USER_MY2N_APP_ACCESS_REQUEST',
  ACTIVATE_USER_MY2N_APP_ACCESS_SUCCESS: 'ACTIVATE_USER_MY2N_APP_ACCESS_SUCCESS',
  ADD_USER_SHOW_MODAL: `${moduleName}/ADD_USER_SHOW_MODAL`,
  CLEAR_IDENTITY: `${moduleName}/CLEAR_IDENTITY`,
  CREATE_USER_REQUEST: `${moduleName}/CREATE_USER_REQUEST`,
  CREATE_USER_RFID_FAILURE: `${moduleName}/CREATE_USER_RFID_FAILURE`,
  CREATE_USER_RFID_REQUEST: `${moduleName}/CREATE_USER_RFID_REQUEST`,
  CREATE_USER_RFID_SUCCESS: `${moduleName}/CREATE_USER_RFID_SUCCESS`,
  DELETE_SITE_USER_REQUEST: `${moduleName}/DELETE_SITE_USER_REQUEST`,
  DELETE_USER_FAILURE: `${moduleName}/DELETE_USER_FAILURE`,
  DELETE_USER_HIDE_MODAL: `${moduleName}/DELETE_USER_HIDE_MODAL`,
  DELETE_USER_REQUEST: `${moduleName}/DELETE_USER_REQUEST`,
  DELETE_USER_SHOW_MODAL: `${moduleName}/DELETE_USER_SHOW_MODAL`,
  DELETE_USER_SUCCESS: `${moduleName}/DELETE_USER_SUCCESS`,
  DETAIL_USER_FAILURE: `${moduleName}/DETAIL_USER_FAILURE`,
  DETAIL_USER_REQUEST: `${moduleName}/DETAIL_USER_REQUEST`,
  DETAIL_USER_SUCCESS: `${moduleName}/DETAIL_USER_SUCCESS`,
  EDIT_SITE_USER_REQUEST: `${moduleName}/EDIT_SITE_USER_REQUEST`,
  EDIT_USER_PASSWORD_FAILURE: `${moduleName}/EDIT_USER_PASSWORD_FAILURE`,
  EDIT_USER_PASSWORD_REQUEST: `${moduleName}/EDIT_USER_PASSWORD_REQUEST`,
  EDIT_USER_PASSWORD_SUCCESS: `${moduleName}/EDIT_USER_PASSWORD_SUCCESS`,
  EDIT_USER_REQUEST: `${moduleName}/EDIT_USER_REQUEST`,
  GENERATE_USER_PIN_FAILURE: `${moduleName}/GENERATE_USER_PIN_FAILURE`,
  GENERATE_USER_PIN_REQUEST: `${moduleName}/GENERATE_USER_PIN_REQUEST`,
  GENERATE_USER_PIN_SUCCESS: `${moduleName}/GENERATE_USER_PIN_SUCCESS`,
  GET_CURRENT_USER_FAILURE: `${moduleName}/GET_CURRENT_USER_FAILURE`,
  GET_CURRENT_USER_REQUEST: `${moduleName}/GET_CURRENT_USER_REQUEST`,
  GET_CURRENT_USER_SUCCESS: `${moduleName}/GET_CURRENT_USER_SUCCESS`,
  GET_SITE_USER_DEVICES_FAILURE: `${moduleName}/GET_SITE_USER_DEVICES_FAILURE`,
  GET_SITE_USER_DEVICES_REQUEST: `${moduleName}/GET_SITE_USER_DEVICES_REQUEST`,
  GET_SITE_USER_DEVICES_SUCCESS: `${moduleName}/GET_SITE_USER_DEVICES_SUCCESS`,
  GET_SITE_USERS_LIST_FAILURE: `${moduleName}/GET_SITE_USERS_LIST_FAILURE`,
  GET_SITE_USERS_LIST_REQUEST: `${moduleName}/GET_SITE_USERS_LIST_REQUEST`,
  GET_SITE_USERS_LIST_SUCCESS: `${moduleName}/GET_SITE_USERS_LIST_SUCCESS`,
  GET_USER_MY2N_APP_ACCESS_STATUS_FAILURE: 'GET_USER_MY2N_APP_ACCESS_STATUS_FAILURE',
  GET_USER_MY2N_APP_ACCESS_STATUS_REQUEST: 'GET_USER_MY2N_APP_ACCESS_STATUS_REQUEST',
  GET_USER_MY2N_APP_ACCESS_STATUS_SUCCESS: 'GET_USER_MY2N_APP_ACCESS_STATUS_SUCCESS',
  GET_USER_PIN_FAILURE: `${moduleName}/GET_USER_PIN_FAILURE`,
  GET_USER_PIN_REQUEST: `${moduleName}/GET_USER_PIN_REQUEST`,
  GET_USER_PIN_SUCCESS: `${moduleName}/GET_USER_PIN_SUCCESS`,
  GET_USER_RFID_CARD_FAILURE: `${moduleName}/GET_USER_RFID_CARD_FAILURE`,
  GET_USER_RFID_CARD_REQUEST: `${moduleName}/GET_USER_RFID_CARD_REQUEST`,
  GET_USER_RFID_CARD_SUCCESS: `${moduleName}/GET_USER_RFID_CARD_SUCCESS`,
  GET_USERS_LIST_FAILURE: `${moduleName}/GET_USERS_LIST_FAILURE`,
  GET_USERS_LIST_REQUEST: `${moduleName}/GET_USERS_LIST_REQUEST`,
  GET_USERS_LIST_SUCCESS: `${moduleName}/GET_USERS_LIST_SUCCESS`,
  IMPORT_USERS_ABORT_FAILURE: `${moduleName}/IMPORT_USERS_ABORT_FAILURE`,
  IMPORT_USERS_ABORT_REQUEST: `${moduleName}/IMPORT_USERS_ABORT_REQUEST`,
  IMPORT_USERS_ABORT_SUCCESS: `${moduleName}/IMPORT_USERS_ABORT_SUCCESS`,
  IMPORT_USERS_FINISH: `${moduleName}/IMPORT_USERS_FINISH`,
  IMPORT_USERS_GET_STATUS_FAILURE: `${moduleName}/IMPORT_USERS_GET_STATUS_FAILURE`,
  IMPORT_USERS_GET_STATUS_REQUEST: `${moduleName}/IMPORT_USERS_GET_STATUS_REQUEST`,
  IMPORT_USERS_GET_STATUS_SUCCESS: `${moduleName}/IMPORT_USERS_GET_STATUS_SUCCESS`,
  IMPORT_USERS_START_FAILURE: `${moduleName}/IMPORT_USERS_START_FAILURE`,
  IMPORT_USERS_START_REQUEST: `${moduleName}/IMPORT_USERS_START_REQUEST`,
  IMPORT_USERS_START_RUNNING: `${moduleName}/IMPORT_USERS_START_RUNNING`,
  IMPORT_USERS_START_SUCCESS: `${moduleName}/IMPORT_USERS_START_SUCCESS`,
  INVALIDATE_CURRENT_USER: `${moduleName}/INVALIDATE_CURRENT_USER`,
  REMOVE_USER_RFID_CARD_FAILURE: `${moduleName}/REMOVE_USER_RFID_CARD_FAILURE`,
  REMOVE_USER_RFID_CARD_REQUEST: `${moduleName}/REMOVE_USER_RFID_CARD_REQUEST`,
  REMOVE_USER_RFID_CARD_SUCCESS: `${moduleName}/REMOVE_USER_RFID_CARD_SUCCESS`,
  REVOKE_USER_PIN_FAILURE: `${moduleName}/REVOKE_USER_PIN_FAILURE`,
  REVOKE_USER_PIN_REQUEST: `${moduleName}/REVOKE_USER_PIN_REQUEST`,
  REVOKE_USER_PIN_SUCCESS: `${moduleName}/REVOKE_USER_PIN_SUCCESS`,
  SAVE_USER_PIN_FAILURE: `${moduleName}/SAVE_USER_PIN_FAILURE`,
  SAVE_USER_PIN_REQUEST: `${moduleName}/SAVE_USER_PIN_REQUEST`,
  SAVE_USER_PIN_SUCCESS: `${moduleName}/SAVE_USER_PIN_SUCCESS`,
  SET_ACCESS_GROUPS_HIDE_MODAL: `${moduleName}/SET_ACCESS_GROUPS_HIDE_MODAL`,
  SET_ACCESS_GROUPS_SHOW_MODAL: `${moduleName}/SET_ACCESS_GROUPS_SHOW_MODAL`,
  SET_APARTMENT_TO_USER_FAILURE: `${moduleName}/SET_APARTMENT_TO_USER_FAILURE`,
  SET_APARTMENT_TO_USER_REQUEST: `${moduleName}/SET_APARTMENT_TO_USER_REQUEST`,
  SET_APARTMENT_TO_USER_SHOW_MODAL: `${moduleName}/SET_APARTMENT_TO_USER_SHOW_MODAL`,
  SET_APARTMENT_TO_USER_SUCCESS: `${moduleName}/SET_APARTMENT_TO_USER_SUCCESS`,
  SET_IDENTITY: `${moduleName}/SET_IDENTITY`,
  SET_USER_DETAIL: `${moduleName}/SET_USER_DETAIL`,
  SET_USER_ROLE_FAILURE: `${moduleName}/SET_USER_ROLE_FAILURE`,
  SET_USER_ROLE_REQUEST: `${moduleName}/SET_USER_ROLE_REQUEST`,
  SET_USER_ROLE_SUCCESS: `${moduleName}/SET_USER_ROLE_SUCCESS`,
  USER_SAVE_FAILURE: `${moduleName}/USER_SAVE_FAILURE`,
  USER_SAVE_SUCCESS: `${moduleName}/USER_SAVE_SUCCESS`,
};
