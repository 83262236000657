import { defineMessages } from 'react-intl';

export default defineMessages({
  configureMy2NAccessModal3AcomNo: {
    defaultMessage: "No, we don't",
    id: 'configure.My2NAccess.Modal3.acom.no',
  },
  configureMy2NAccessModal3AcomYes: {
    defaultMessage: 'Yes, we do',
    id: 'configure.My2NAccess.Modal3.acom.yes',
  },
  configureMy2NAccessModal3DeviceNo: {
    defaultMessage: "No, I haven't",
    id: 'configure.My2NAccess.Modal3.device.no',
  },
  configureMy2NAccessModal3DeviceYes: {
    defaultMessage: 'Yes, I have',
    id: 'configure.My2NAccess.Modal3.device.yes',
  },
  configureMy2NAccessModal3QuestionAcom: {
    defaultMessage: 'Do you use ACOM for managing your site? ',
    id: 'configure.My2NAccess.Modal3.question.acom',
  },
  configureMy2NAccessModal3QuestionDevice: {
    defaultMessage: 'Have you used any physical device to grant My2N app access?',
    id: 'configure.My2NAccess.Modal3.question.device',
  },
  configureMy2NAccessModal3Text1Acom: {
    defaultMessage: 'It’s very likely that Bluetooth is managed via ACOM, but we need to be certain.',
    id: 'configure.My2NAccess.Modal3.text1.acom',
  },
  configureMy2NAccessModal3Text1NoBluetooth: {
    defaultMessage: 'There are no signs of using bluetooth in the site.',
    id: 'configure.My2NAccess.Modal3.text1.noBluetooth',
  },
});
