import { CredentialsValidityResponseDto, GetPinForUserApiResponse } from './accessControlApi';
import { api } from './api';

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    createApartmentGuestPinWithoutAccess: build.query<
      CreateApartmentGuestPinWithoutAccessApiResponse,
      CreateApartmentGuestPinWithoutAccessApiArg
    >({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/apartments/${queryArg.apartmentId}/credentials/available-guest-pin`,
      }),
    }),
    getApartmentAdminCredentialsValidity: build.query<
      GetApartmentAdminCredentialsValidityApiResponse,
      GetApartmentAdminCredentialsValidityApiArg
    >({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/apartments/${queryArg.apartmentId}/users/${queryArg.userId}/credentials`,
      }),
    }),
    getApartmentAdminPinSettings: build.query<
      GetApartmentAdminPinSettingsApiResponse,
      GetApartmentAdminPinSettingsApiArg
    >({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/apartments/${queryArg.apartmentId}/credentials/guest-pin-settings`,
      }),
    }),
    getApartmentPinForUser: build.query<GetPinForUserApiResponse, GetApartmentPinForUserApiArg>({
      query: (queryArg) => ({
        url: `/companies/${queryArg.companyId}/sites/${queryArg.siteId}/apartments/${queryArg.apartmentId}/users/${queryArg.userId}/credentials/pin`,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as gatewayApi };

export type CreateApartmentGuestPinWithoutAccessApiResponse = /** status 200 created Pin */ ApartmentPinValueDto;

export type ApartmentPinValueDto = {
  pin: string;
};

export type CreateApartmentGuestPinWithoutAccessApiArg = {
  siteId: string;
  companyId: string;
  apartmentId: string;
};

export type GetApartmentPinForUserApiArg = {
  siteId: string;
  userId: string;
  companyId: string;
  apartmentId: string;
};

export type GetApartmentAdminCredentialsValidityApiResponse =
  /** status 200 Apartment admin credentials validity in site */ CredentialsValidityResponseDto;
export type GetApartmentAdminCredentialsValidityApiArg = {
  siteId: string;
  userId: string;
  companyId: string;
  apartmentId: string;
};
export type GetApartmentAdminPinSettingsApiResponse = /** status 200 guest pin settings */ GuestPinSettingsDto;
export type GetApartmentAdminPinSettingsApiArg = {
  siteId: string;
  companyId: string;
  apartmentId: string;
};
export type GuestPinSettingsDto = {
  guestPinLength?: number;
  guestShowQr?: boolean;
};
export const {
  useCreateApartmentGuestPinWithoutAccessQuery,
  useGetApartmentAdminCredentialsValidityQuery,
  useGetApartmentAdminPinSettingsQuery,
  useGetApartmentPinForUserQuery,
} = injectedRtkApi;
