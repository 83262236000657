import { Typography, TypographyOwnProps } from '@mui/material';
import React, { ReactChild } from 'react';
import { TooltipForEllipsisElement } from '../TooltipForEllipsisElement';

export interface ITypographyWithEllipsisTooltipProps extends Omit<TypographyOwnProps, 'noWrap'> {
  children?: ReactChild;
  component?: React.ElementType;
}

export function TypographyWithEllipsisTooltip({
  children,
  ...typographySettings
}: ITypographyWithEllipsisTooltipProps) {
  return (
    <TooltipForEllipsisElement>
      <Typography noWrap {...typographySettings}>
        {children}
      </Typography>
    </TooltipForEllipsisElement>
  );
}
