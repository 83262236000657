import AddIcon from '@mui/icons-material/Add';
import DialpadIcon from '@mui/icons-material/Dialpad';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { DataGridActionButton, DataGridSearchField, Title, UsersImportButton } from '../../components';
import { useHasFeature } from '../../components/Feature/Feature';
import { PATHS } from '../../config/consts';
import featureFlags from '../../config/featureFlags';
import { changeFilter, dataGridProps, useButtonText } from '../../helpers/dataGrid';
import useBreakpoints from '../../helpers/useBreakpoints';
import messages from './messages';

const Tools = ({
  companyId,
  currentImportRunning,
  onAddUser,
  onAddVisitor,
  onLoadUsers,
  onSearch,
  siteId,
  siteUsersDataGrid,
}) => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { downLg, downSm } = useBreakpoints();
  const [fullText, setFullText] = useState(siteUsersDataGrid.filter.fulltext);
  const isGuestAccessFFEnabled = useHasFeature(featureFlags.GUEST_ACCESS);
  const visitorButtonText = useButtonText(
    messages.AAUserListApartmentUsersCreateGuest,
    messages.AAUserListApartmentUsersCreateGuestXs
  );

  const onChangeFilter = ({ target }) => {
    const { name, value } = target;
    setFullText(value);
    changeFilter(onSearch, onLoadUsers, {
      companyId,
      filter: siteUsersDataGrid.filter,
      order: siteUsersDataGrid.order,
      page: 1,
      rowsPerPage: siteUsersDataGrid.rowsPerPage,
      siteId,
    })(name, value);
  };

  const onAddUserEvent = (event) => {
    event.preventDefault();
    onAddUser();
  };

  const onAddVisitorEvent = (event) => {
    event.preventDefault();
    onAddVisitor();
  };

  return (
    <Title
      heading={formatMessage(downLg ? messages.SiteUsersTitleMobile : messages.SiteUsersTitle)}
      rightSide={<DataGridSearchField fulltext={fullText} onChangeFilter={onChangeFilter} sx={{ maxWidth: '200px' }} />}
      rightSideActions={
        <>
          <UsersImportButton
            disabled={currentImportRunning}
            onClick={() => {
              navigate(PATHS.SITE_USERS_IMPORT.replace(':companyId', companyId).replace(':siteId', siteId));
            }}
          />
          {isGuestAccessFFEnabled && (
            <DataGridActionButton
              onClick={onAddVisitorEvent}
              startIcon={downSm ? <DialpadIcon /> : <AddIcon />}
              variant={downSm ? 'text' : 'outlined'}
              sx={{ mr: 2 }}
            >
              {visitorButtonText}
            </DataGridActionButton>
          )}
          <DataGridActionButton
            onClick={onAddUserEvent}
            startIcon={downSm ? <PersonAddIcon /> : <AddIcon />}
            variant={downSm ? 'text' : 'contained'}
          >
            {useButtonText(messages.SiteUsersToolsAddNewUser, messages.SiteUsersToolsAddNewUserMobile)}
          </DataGridActionButton>
        </>
      }
    />
  );
};

Tools.propTypes = {
  companyId: PropTypes.number.isRequired,
  currentImportRunning: PropTypes.bool.isRequired,
  onAddUser: PropTypes.func.isRequired,
  onAddVisitor: PropTypes.func.isRequired,
  onLoadUsers: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  siteId: PropTypes.number.isRequired,
  siteUsersDataGrid: dataGridProps.isRequired,
};

export default Tools;
