import ApartmentIcon from '@mui/icons-material/FolderShared';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import AccessApartmentsChipList from '../AccessApartmentsChipList';
import ChipListWrapper from '../ChipListWrapper';
import RowSection from '../RowSection';
import messages from './messages';

function UserApartmentsList({ apartments }) {
  const { formatMessage } = useIntl();

  return (
    <RowSection icon={<ApartmentIcon />} label={formatMessage(messages.userApartmentsListLabel)}>
      <ChipListWrapper>
        {apartments.length ? <AccessApartmentsChipList apartments={apartments} /> : null}
      </ChipListWrapper>
    </RowSection>
  );
}

UserApartmentsList.propTypes = {
  apartments: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default UserApartmentsList;
