import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SUPPORT } from '../../config/address';
import messages from './messages';

const VatInfoText = (props) => (
  <Typography {...props}>
    <FormattedMessage
      {...messages.vatInfoTextText}
      values={{
        mailToLink: (
          <Link href={`mailto:${SUPPORT}`} target="_blank">
            {SUPPORT}
          </Link>
        ),
      }}
    />
  </Typography>
);

export default VatInfoText;
