import CreditCardIcon from '@mui/icons-material/CreditCard';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PATHS } from '../../../config/consts';
import RowMenuItem from '../../DataGrid/RowMenuItem';
import messages from './messages';

function PurchaseSubscription({ companyId, siteId }) {
  return (
    <>
      <RowMenuItem
        colorIcon="primary.main"
        colorText="primary.main"
        component={Link}
        icon={<CreditCardIcon />}
        label={<FormattedMessage {...messages.apartmentRowMenuPurchase} />}
        to={PATHS.SUBSCRIPTION.replace(':companyId', companyId).replace(':siteId', siteId)}
      />
      <Divider />
    </>
  );
}

PurchaseSubscription.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  siteId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default PurchaseSubscription;
