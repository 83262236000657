import PropTypes from 'prop-types';
import React from 'react';
import { MOBILE_VIDEO_STATUS } from '../../../../config/consts';
import TrialModeWarningAlert from './TrialModeWarningAlert';
import VerificationErrorInfo from './VerificationErrorInfo';
import VerificationSuccessAlert from './VerificationSuccessAlert';

const ValidationStatus = ({ activationCodeError, credit, mobileVideoLicense }) => (
  <>
    {mobileVideoLicense?.status === MOBILE_VIDEO_STATUS.TRIAL_ACTIVE && (
      <TrialModeWarningAlert expirationDate={mobileVideoLicense.validityTo} />
    )}
    {credit && <VerificationSuccessAlert credit={credit} mobileVideoLicense={mobileVideoLicense} />}
    {activationCodeError?.status && <VerificationErrorInfo activationCodeError={activationCodeError} />}
  </>
);

ValidationStatus.propTypes = {
  activationCodeError: PropTypes.shape({
    data: PropTypes.shape({}),
    status: PropTypes.number,
  }).isRequired,
  credit: PropTypes.number,
  mobileVideoLicense: PropTypes.shape({
    status: PropTypes.string,
    validityTo: PropTypes.string,
  }).isRequired,
};

ValidationStatus.defaultProps = {
  credit: null,
};

export default ValidationStatus;
