import { defineMessages } from 'react-intl';

export default defineMessages({
  ChangeCreditCardDialogClose: {
    defaultMessage: 'Cancel',
    id: 'changeCreditCardDialog.close',
  },
  ChangeCreditCardDialogDeactivate: {
    defaultMessage: 'Deactivate',
    id: 'changeCreditCardDialog.deactivate',
  },
  ChangeCreditCardDialogText: {
    defaultMessage: `Paid services will be deactivated from next month.' +
      '<p>To continue using the paid services, add new card details after deactivation by clicking the 'Activate' button.</p>`,
    id: 'changeCreditCardDialog.text',
  },
  ChangeCreditCardDialogTitle: {
    defaultMessage: 'Deactivate recurring payments',
    id: 'changeCreditCardDialog.title',
  },
  HowToChangeCreditCardDialogClose: {
    defaultMessage: 'Close',
    id: 'howToChangeCreditCardDialog.close',
  },
  HowToChangeCreditCardDialogText: {
    defaultMessage: 'Change or update your credit card for the next payment by following these steps:' +
      '<ol>' +
        '<li>Switch off the recurring payments by clicking the <b>DEACTIVATE</b> button.</li>' +
        '<li>Click the <b>ACTIVATE</b> button.</li>' +
        '<li>Extend your subscription and enter the new credit card details.</li>' +
      '</ol>' +
      '<p>For detailed instructions, <link>see documentation</link>.</p>',
    id: 'howToChangeCreditCardDialog.text',
  },
  HowToChangeCreditCardDialogTitle: {
    defaultMessage: 'How to change credit card',
    id: 'howToChangeCreditCardDialog.title',
  },
  mobileVideoPaymentActivate: {
    defaultMessage: 'Activate',
    description: 'Mobile video payment - activate',
    id: 'mobileVideoPayment.activate',
  },
  mobileVideoPaymentActive: {
    defaultMessage: 'Active',
    description: 'Mobile video payment - active',
    id: 'mobileVideoPayment.active',
  },
  mobileVideoPaymentChangeCreditCard: {
    defaultMessage: 'How to Change credit card',
    description: 'Mobile video payment - change credit card',
    id: 'mobileVideoPayment.changeCreditCard',
  },
  mobileVideoPaymentDeactivate: {
    defaultMessage: 'Deactivate',
    description: 'Mobile video payment - deactivate',
    id: 'mobileVideoPayment.deactivate',
  },
  mobileVideoPaymentNotActive: {
    defaultMessage: 'Not active',
    description: 'Mobile video payment - not active',
    id: 'mobileVideoPayment.notActive',
  },
  mobileVideoPaymentRecurringPayment: {
    defaultMessage: 'Recurring payment:',
    description: 'Mobile video payment - recurring payment',
    id: 'mobileVideoPayment.recurringPayment',
  },
  mobileVideoPaymentTitle: {
    defaultMessage: 'Payment settings',
    description: 'Mobile video payment - title',
    id: 'mobileVideoPayment.title',
  },
});
