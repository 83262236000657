import { defineMessages } from 'react-intl';

export default defineMessages({
  siteTypeMdu: {
    defaultMessage: 'Apartment building',
    description: '',
    id: 'siteType.mdu',
  },
  siteTypeOther: {
    defaultMessage: 'Other',
    description: '',
    id: 'siteTypeOther',
  },
  siteTypeSingleVilla: {
    defaultMessage: 'Family home',
    description: '',
    id: 'siteType.singleVilla',
  },
  siteTypeUnknown: {
    defaultMessage: 'Unknown',
    description: '',
    id: 'siteType.unknown',
  },
});
