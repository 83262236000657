import List from '@mui/material/List';
import PropTypes from 'prop-types';
import React from 'react';
import ContactListHeader from './ContactListHeader';
import ContactListItem from './ContactListItem';

const ContactList = ({ deviceChipOptions, isDeviceDetailEditing, list, onChangeItem, setDeviceEditing }) => (
  <>
    <ContactListHeader />
    <List>
      {list &&
        list.map((item) => (
          <ContactListItem
            data={item}
            deviceChipOptions={deviceChipOptions}
            isDeviceDetailEditing={isDeviceDetailEditing}
            key={`${item.index}-compact-row`}
            onChangeItem={onChangeItem}
            setDeviceEditing={setDeviceEditing}
          />
        ))}
    </List>
  </>
);

ContactList.propTypes = {
  deviceChipOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  isDeviceDetailEditing: PropTypes.bool.isRequired,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number,
      name: PropTypes.string,
    })
  ).isRequired,
  onChangeItem: PropTypes.func.isRequired,
  setDeviceEditing: PropTypes.func.isRequired,
};

export default ContactList;
