import camelcase from 'camelcase';
import { fast1a32hex } from 'fnv-plus';
import { call, select } from 'redux-saga/effects';
import * as ApartmentApi from '../../../api/apartmentApi';
import * as Api from '../../../api/deviceApi';
import { ROLES } from '../../../config/consts';
import { CONFIGURATION_MODE, CONNECTION_MODE, DEVICE_TYPE, SERVICE } from '../../../config/devices';
import { createExternalId } from '../../../helpers/common';
import { selectors as companySelectors } from '../../../modules/company';
import { responseValidateStatusOrThrow } from '../../../modules/helpers';
import { selectors as siteSelectors } from '../../../modules/sites';

export function* getDevicesTypesSettings(companyId, type) {
  try {
    const aliasType =
      type === DEVICE_TYPE.ACCESS_UNIT_M || type === DEVICE_TYPE.CHAMELEON ? DEVICE_TYPE.ACCESS_UNIT : null;
    const response = yield call(Api.getDeviceTypesSettings, companyId);
    yield responseValidateStatusOrThrow(response);
    return (response.data.results || []).find((row) => row.name === type || row.name === aliasType)?.template || null;
  } catch (error) {
    // ignore
  }
  return null;
}

export function* createUserIfFilled(companyId, siteId, values) {
  let userId = values.user || null;
  const newUser = values.newUser || {};
  if (!values.existUser) {
    const response = yield call(Api.addUser, {
      email: newUser?.email,
      firstName: newUser?.firstName,
      grantedRoles: {
        companyRoles: [{ companyId, types: [ROLES.STANDARD] }],
        siteRoles: [{ siteId, types: [ROLES.USER] }],
        systemRoles: [],
      },
      lastName: newUser?.lastName,
    });
    yield responseValidateStatusOrThrow(response);
    userId = response.data?.id || userId;
  }
  return userId;
}

// eslint-disable-next-line max-statements
export function* createApartmentIfFilled(companyId, siteId, values, floors) {
  let apartmentId = parseInt(values.apartmentId, 10) || 0;
  if (values.hasForm) {
    const floor = values.floor;
    const filteredFloor = floors.find((fl) => fl.name === floor);
    let floorId;
    if (!filteredFloor && floor) {
      const floorResponse = yield call(ApartmentApi.addFloor, companyId, siteId, {
        externalId: createExternalId(),
        name: floor,
      });
      floorId = floorResponse.data?.id || null;
    } else {
      floorId = filteredFloor?.id || null;
    }

    const responseApartment = yield call(ApartmentApi.addApartment, companyId, siteId, {
      externalId: createExternalId(),
      floorId,
      name: values.apartmentName,
      number: values.number,
    });
    apartmentId = responseApartment.data?.id || apartmentId;
  }
  return apartmentId > 0 ? apartmentId : null;
}

export const includeServices = [
  SERVICE.ACS,
  SERVICE.MOBILE_VIDEO,
  SERVICE.LOCAL_CALLS,
  SERVICE.REMOTE_CONFIGURATION,
  SERVICE.ACCESS_CONTROL,
];

export function* getRoles(values) {
  const currentCompanyRoles = yield select(companySelectors.getCurrentCompanyRoles);
  const companyRoles = Object.keys(currentCompanyRoles).filter((role) =>
    currentCompanyRoles[role].includes(values.user)
  );
  const currentSiteRoles = yield select(siteSelectors.getSiteRoles);
  const siteRoles = Object.keys(currentSiteRoles).filter((role) => currentSiteRoles[role].includes(values.user));

  return {
    companyRoles: companyRoles || [],
    siteRoles: siteRoles || [],
  };
}

export function* getSelectedUserData(values) {
  const siteUsers = yield select(siteSelectors.getSiteUsers);
  return siteUsers.find((user) => user.id === values.userId);
}

export const getAvailableServices = (data) =>
  (data?.availableServices || []).filter((item) => includeServices.includes(item));
export const isAvailableService = (data, service) => getAvailableServices(data).includes(service);
// eslint-disable-next-line complexity
export const isActiveService = (service, type = '', values = {}) =>
  (service === SERVICE.ACS && values?.configurationMode === CONFIGURATION_MODE.AUTOMATIC) ||
  service === SERVICE.REMOTE_CONFIGURATION ||
  (service === SERVICE.MOBILE_VIDEO &&
    ![DEVICE_TYPE.IP_INTERCOM, DEVICE_TYPE.IP_INTERCOM_V2, DEVICE_TYPE.IP_ONE_INTERCOM, DEVICE_TYPE.IP_STYLE].includes(
      type
    ) &&
    values?.connectionMode === CONNECTION_MODE.CLOUD) ||
  (service === SERVICE.MOBILE_VIDEO &&
    (type === DEVICE_TYPE.IP_INTERCOM ||
      type === DEVICE_TYPE.IP_INTERCOM_V2 ||
      type === DEVICE_TYPE.IP_ONE_INTERCOM ||
      type === DEVICE_TYPE.IP_STYLE)) ||
  (service === SERVICE.LOCAL_CALLS && values?.connectionMode === CONNECTION_MODE.LOCAL);

// eslint-disable-next-line max-statements
export const getServices = (data, values = {}) => {
  const services = [];
  for (const type of getAvailableServices(data)) {
    let extendData = {};

    if (type === SERVICE.LOCAL_CALLS) {
      if (
        [
          DEVICE_TYPE.IP_INTERCOM,
          DEVICE_TYPE.IP_INTERCOM_V2,
          DEVICE_TYPE.IP_ONE_INTERCOM,
          DEVICE_TYPE.IP_STYLE,
        ].includes(data.name)
      ) {
        continue;
      }
    }

    if (type === SERVICE.ACCESS_CONTROL) {
      services.push({
        accessLog: values.accessLog,
        type: SERVICE.ACCESS_CONTROL,
      });
    } else {
      services.push({
        ...extendData,
        active: isActiveService(type, data.name || '', values),
        enabled: true,
        type,
      });
    }
  }

  return services;
};
