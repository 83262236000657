import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import DeviceModelName from '../DeviceModelName';
import { getSvgIcon } from './helpers';
import messages from './messages';

const styles = {
  description: {
    p: 2,
    typography: 'caption',
  },
  icon: {
    '& img': {
      height: 'inherit',
    },
    height: 'inherit',
    width: 'inherit',
  },
  root: {
    alignItems: 'center',
    background: 'secondary.light',
    borderRadius: '168px',
    boxShadow: 3,
    display: 'flex',
    height: '168px',
    justifyContent: 'center',
    position: 'relative',
    textAlign: 'center',
    width: '168px',
  },
};

const Device2NIcon = ({ deviceType, noFound }) => (
  <Box sx={styles.root}>
    {!noFound && deviceType && (
      <Box sx={styles.icon}>
        <img alt={<DeviceModelName deviceType={deviceType} />} src={getSvgIcon(deviceType)} />
      </Box>
    )}
    {noFound && !deviceType && (
      <Typography color="error.main" component="span" sx={styles.description}>
        <FormattedMessage {...messages.device2NIconNotBeAdded} />
      </Typography>
    )}
    {!noFound && !deviceType && (
      <Typography color="secondary.main" component="span" sx={styles.description}>
        <FormattedMessage {...messages.device2NIconNoTypeMessage} />
      </Typography>
    )}
  </Box>
);

Device2NIcon.propTypes = {
  deviceType: PropTypes.string,
  noFound: PropTypes.bool,
};

Device2NIcon.defaultProps = {
  deviceType: null,
  noFound: false,
};

export default Device2NIcon;
