import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { SUBSCRIPTION_TYPE } from '../../../consts';
import { getPrice } from '../../../helpers';
import { batchPropTypes } from '../../../types';
import InputLabel from '../InputLabel';
import messages from '../messages';

const TotalPrice = ({ batch, currency, unitFullPrice }) => {
  const { formatNumber } = useIntl();
  return (
    <Grid alignContent={{ lg: 'flex-end', xs: 'stretch' }} alignItems="flex-end" container direction="column">
      <Grid item>
        <InputLabel sx={{ transformOrigin: 'top right' }}>
          <FormattedMessage {...messages.promoBatchFormInputPriceLabel} />
        </InputLabel>
      </Grid>
      <Grid item>
        <Typography>
          <strong>
            {batch.subscriptionType === SUBSCRIPTION_TYPE.DEFAULT
              ? '—'
              : formatNumber(getPrice(unitFullPrice, batch.years, batch.subscriptions), {
                  currency,
                  style: 'currency',
                })}
          </strong>
        </Typography>
      </Grid>
    </Grid>
  );
};

TotalPrice.propTypes = {
  batch: batchPropTypes.isRequired,
  currency: PropTypes.string.isRequired,
  unitFullPrice: PropTypes.number.isRequired,
};

export default TotalPrice;
