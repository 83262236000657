import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { ICreateApartmentUser } from '../../../../../../modules/apartmentAdmin/data';
import { TId } from '../../../../../../modules/commonTypes';
import { getApartmentUserValidationSchema } from '../../../../../../validations/createApartmentUserValidationSchema';

export function useGetGlobalHooks(apartmentId: TId, companyId: TId, siteId: TId) {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const formMethods = useForm({
    defaultValues: {
      email: '',
      firstName: '',
      lastName: '',
      mobileVideoActivated: false,
    } as ICreateApartmentUser,
    mode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        ...getApartmentUserValidationSchema(apartmentId, companyId, siteId, formatMessage),
      })
    ),
  });

  return {
    dispatch,
    formatMessage,
    formMethods,
  };
}
