import { Configuration, FrontendApi } from '@ory/client';

export const oryApi = new FrontendApi(
  new Configuration({
    baseOptions: {
      withCredentials: true,
    },
    basePath: process.env.ORY_PROXY,
  })
);
