import { all, call, put, SagaReturnType, takeLatest } from 'redux-saga/effects';
import * as Api from '../../api/elevatorIntegrationTS';
import { DEFAULT_DEVICE_DTO } from '../../config/consts';
import { TId, TUuid } from '../commonTypes';
import { responseValidateStatusOrThrow } from '../helpers';
import { addSnackbarSuccess } from '../notifications/actions';
import { isStandardError, processError } from '../sagaHelpers';
import { types } from './index';
import messages from './messages';

export function* getSiteElevatorIntegration(action: { type: string; companyId: TId; siteId: TId }) {
  try {
    const { companyId, siteId } = action;
    const response: SagaReturnType<typeof Api.getSiteElevatorIntegration> = yield call(Api.getSiteElevatorIntegration, {
      companyId,
      siteId,
    });
    yield responseValidateStatusOrThrow(response);
    yield put({ payload: response.data, type: types.GET_SITE_ELEVATOR_INTEGRATION_SUCCESS });
  } catch (error) {
    if (isStandardError(error) && error.status === 404) {
      yield put({ payload: null, type: types.GET_SITE_ELEVATOR_INTEGRATION_SUCCESS });
    } else {
      yield processError(types.GET_SITE_ELEVATOR_INTEGRATION_FAILURE, error);
    }
  }
}

export function* setSiteElevatorIntegration(action: {
  type: string;
  companyId: TId;
  siteId: TId;
  data: Api.ISiteElevatorIntegrationSettings;
}) {
  try {
    const { companyId, data, siteId } = action;
    const response: SagaReturnType<typeof Api.setSiteElevatorIntegration> = yield call(
      Api.setSiteElevatorIntegration,
      { companyId, siteId },
      data
    );
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.SET_SITE_ELEVATOR_INTEGRATION_SUCCESS });
  } catch (error) {
    yield processError(types.SET_SITE_ELEVATOR_INTEGRATION_FAILURE, error);
  }
}

export function* getUserElevatorIntegration(action: { type: string; companyId: TId; siteId: TId; userId: TUuid }) {
  try {
    const { companyId, siteId, userId } = action;
    const response: SagaReturnType<typeof Api.getUserElevatorIntegration> = yield call(
      Api.getUserElevatorIntegration,
      {
        companyId,
        siteId,
      },
      userId
    );
    yield responseValidateStatusOrThrow(response);
    yield put({ payload: response.data, type: types.GET_USER_ELEVATOR_INTEGRATION_SUCCESS });
  } catch (error) {
    yield processError(types.GET_USER_ELEVATOR_INTEGRATION_FAILURE, error);
  }
}

export function* setUserElevatorIntegration(action: {
  type: string;
  companyId: TId;
  siteId: TId;
  userId: TUuid;
  data: Api.IUserElevatorIntegration;
}) {
  try {
    const { companyId, data, siteId, userId } = action;
    const response: SagaReturnType<typeof Api.setUserElevatorIntegration> = yield call(
      Api.setUserElevatorIntegration,
      { companyId, siteId },
      userId,
      data
    );
    yield responseValidateStatusOrThrow(response);
    yield put(addSnackbarSuccess(messages.elevatorIntegrationSetSuccessfully));
    yield put({ type: types.SET_USER_ELEVATOR_INTEGRATION_SUCCESS });
  } catch (error) {
    yield processError(types.SET_USER_ELEVATOR_INTEGRATION_FAILURE, error);
  }
}

export function* getDeviceElevatorIntegration(action: { type: string; companyId: TId; siteId: TId; deviceId: TId }) {
  try {
    const { companyId, deviceId, siteId } = action;
    const response: SagaReturnType<typeof Api.getDeviceElevatorIntegration> = yield call(
      Api.getDeviceElevatorIntegration,
      {
        companyId,
        siteId,
      },
      deviceId
    );
    yield responseValidateStatusOrThrow(response);
    yield put({ payload: response.data, type: types.GET_DEVICE_ELEVATOR_INTEGRATION_SUCCESS });
  } catch (error) {
    if (isStandardError(error) && error.status === 404) {
      yield put({
        payload: DEFAULT_DEVICE_DTO,
        type: types.GET_DEVICE_ELEVATOR_INTEGRATION_SUCCESS,
      });
    } else {
      yield processError(types.GET_DEVICE_ELEVATOR_INTEGRATION_FAILURE, error);
    }
  }
}

export function* setDeviceElevatorIntegration(action: {
  type: string;
  companyId: TId;
  siteId: TId;
  deviceId: TId;
  data: Api.IDeviceElevatorIntegrationSet;
}) {
  try {
    const { companyId, data, deviceId, siteId } = action;
    const response: SagaReturnType<typeof Api.setDeviceElevatorIntegration> = yield call(
      Api.setDeviceElevatorIntegration,
      { companyId, siteId },
      deviceId,
      data
    );
    yield responseValidateStatusOrThrow(response);
    yield put(addSnackbarSuccess(messages.elevatorIntegrationSetSuccessfully));
    yield put({ type: types.SET_DEVICE_ELEVATOR_INTEGRATION_SUCCESS });
  } catch (error) {
    yield processError(types.SET_DEVICE_ELEVATOR_INTEGRATION_FAILURE, error);
  }
}

export function* getElevatorIntegrationFloorMapping(action: { type: string; companyId: TId; siteId: TId }) {
  try {
    const { companyId, siteId } = action;
    const response: SagaReturnType<typeof Api.getElevatorIntegrationFloorMapping> = yield call(
      Api.getElevatorIntegrationFloorMapping,
      {
        companyId,
        siteId,
      }
    );
    yield responseValidateStatusOrThrow(response);
    yield put({ payload: response.data, type: types.GET_ELEVATOR_INTEGRATION_FLOOR_MAPPING_SUCCESS });
  } catch (error) {
    yield processError(types.GET_ELEVATOR_INTEGRATION_FLOOR_MAPPING_FAILURE, error);
  }
}

export default function* elevatorIntegrationSagas() {
  yield all([
    takeLatest(types.GET_DEVICE_ELEVATOR_INTEGRATION_REQUEST, getDeviceElevatorIntegration),
    takeLatest(types.GET_ELEVATOR_INTEGRATION_FLOOR_MAPPING_REQUEST, getElevatorIntegrationFloorMapping),
    takeLatest(types.GET_SITE_ELEVATOR_INTEGRATION_REQUEST, getSiteElevatorIntegration),
    takeLatest(types.GET_USER_ELEVATOR_INTEGRATION_REQUEST, getUserElevatorIntegration),
    takeLatest(types.SET_DEVICE_ELEVATOR_INTEGRATION_REQUEST, setDeviceElevatorIntegration),
    takeLatest(types.SET_SITE_ELEVATOR_INTEGRATION_REQUEST, setSiteElevatorIntegration),
    takeLatest(types.SET_USER_ELEVATOR_INTEGRATION_REQUEST, setUserElevatorIntegration),
  ]);
}
