import { defineMessages } from 'react-intl';

export default defineMessages({
  AAmodalUwLDeletePINCancel: {
    defaultMessage: 'Cancel',
    id: 'AAmodal.UwL.deletePIN.cancel',
  },
  AAmodalUwLDeletePINSubmit: {
    defaultMessage: 'Delete PIN',
    id: 'AAmodal.UwL.deletePIN.submit',
  },
  AAmodalUwLDeletePINText: {
    defaultMessage:
      'When you delete the PIN code, its user won’t be able to use it for access.<p>The PIN is also deleted for other apartments the user may be member of.</p>',
    id: 'AAmodal.UwL.deletePIN.text',
  },
  AAmodalUwLDeletePINTitle: {
    defaultMessage: 'Delete PIN',
    id: 'AAmodal.UwL.deletePIN.title',
  },
});
