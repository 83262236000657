import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { deleteDeviceModalShow } from '../../modules/devices/actions';
import { getCurrentDeviceDetailSelector } from '../../modules/devices/selectors';
import DeviceTitle from './DeviceTitle';
import TabPanel from './TabPanel';

export function DeviceDetail() {
  const dispatch = useDispatch();
  const device = useSelector(getCurrentDeviceDetailSelector).data;
  return (
    <>
      {device?.id && (
        <>
          <DeviceTitle device={device} onDelete={() => dispatch(deleteDeviceModalShow(device.id))} />
          <TabPanel device={device} />
          <Outlet />
        </>
      )}
    </>
  );
}
