import { useSelector } from 'react-redux';
import { useGetActualCompanyId } from '../../../hooks/companyHooks';
import { useGetActualSiteId } from '../../../hooks/siteHooks';
import { selectors as apartmentSelectors } from '../../../modules/apartments';
import { selectors as companySelectors } from '../../../modules/company';
import { selectors as siteSelectors } from '../../../modules/sites';

export function useGetApartmentFormData() {
  const isSiteMdu = useSelector(siteSelectors.isSiteMdu);
  const optionsData = useSelector(apartmentSelectors.getApartmentsListOrdered);
  const initialData = useSelector(apartmentSelectors.getInitialApartmentsList);
  const companyId = useGetActualCompanyId();
  const companyName = useSelector(companySelectors.getCompanyName);
  const siteId = useGetActualSiteId();

  return {
    companyId,
    companyName,
    initialData,
    isSiteMdu,
    optionsData,
    siteId,
  };
}
