import DoneIcon from '@mui/icons-material/Done';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import InfoAlert from '../../../components/InfoAlert/InfoAlert';
import SiteLanguageSelect from '../../../components/SiteLanguageSelect';
import { useModal } from '../../../hooks/useModal';
import localizationActions from '../../../modules/localization';
import { validateLocale } from '../../../modules/localization/helpers';
import ConfirmationDialog from './ConfirmationModal';
import { useGetGlobalHooks } from './hooks/getGlobalHooks';
import { useGetSiteLanguageData } from './hooks/getSiteLanguageData';
import messages from './messages';

const styles = {
  changeLanguageButton: {
    '& button': {
      ml: 2,
    },
    textAlign: 'right',
  },
  siteLanguageSelect: {
    maxWidth: '376px',
    mb: 23,
    mt: 5,
  },
};

function SiteLanguage() {
  const { companyId, dispatch, formMethods, siteId } = useGetGlobalHooks();

  const { siteLanguage, siteName } = useGetSiteLanguageData();
  const { Modal: ConfirmationModal, onOpen: onOpenConfirmationModal } = useModal({
    Modal: ConfirmationDialog,
  });

  useEffect(() => {
    dispatch(localizationActions.getCurrentSiteLanguage(companyId, siteId));
  }, []);

  useEffect(() => {
    formMethods.reset({
      lang: validateLocale(siteLanguage),
    });
  }, [siteLanguage]);

  function handleSubmit(data) {
    dispatch(
      localizationActions.setCurrentSiteLanguage(companyId, siteId, {
        locale: data?.lang,
        name: siteName,
      })
    );
  }

  return (
    <>
      <Paper sx={{ p: 3 }}>
        <Typography color="text.primary" variant="body1">
          <FormattedMessage {...messages.siteLanguageHeader} />
        </Typography>
        <InfoAlert>
          <FormattedMessage
            {...messages.siteLanguageAlert}
            values={{
              b: (chunks) => <b>{chunks}</b>,
              br: <br />,
            }}
          />
        </InfoAlert>
        <FormProvider {...formMethods}>
          <form>
            <Box sx={styles.siteLanguageSelect}>
              <SiteLanguageSelect />
            </Box>
            <Box sx={styles.changeLanguageButton}>
              <Button
                color="primary"
                disabled={!formMethods.formState.isDirty || formMethods.formState.isSubmitted}
                onClick={onOpenConfirmationModal}
                startIcon={<DoneIcon />}
                variant="contained"
              >
                <FormattedMessage {...messages.siteLanguageChangeLanguageButton} />
              </Button>
            </Box>
          </form>
        </FormProvider>
      </Paper>
      <ConfirmationModal onSubmit={formMethods.handleSubmit(handleSubmit)} />
    </>
  );
}

export default SiteLanguage;
