import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { MOBILE_VIDEO_STATUS } from '../../config/consts';
import { noPaidDevices } from '../../helpers/dashboard';
import CustomDate from '../CustomDate';
import DeviceForFreeTooltip from '../DeviceForFreeTooltip';
import messages from './messages';

const MobileVideoStatus = ({
  dateStyles,
  is2nDevice,
  licenceModel,
  showDate,
  showTooltip,
  statusStyles,
  subscribed,
}) => {
  const status = useMemo(() => {
    if (is2nDevice || noPaidDevices(licenceModel)) {
      return 'STANDARD';
    }
    if (licenceModel?.status === MOBILE_VIDEO_STATUS.STANDARD && !subscribed) {
      return 'STANDARD_NOT_SUBSCRIBED';
    }

    return licenceModel?.status;
  }, [is2nDevice, licenceModel, subscribed]);

  const getValidityData = () => {
    if (licenceModel.poolExpiration) {
      return licenceModel.poolExpiration;
    }

    if (licenceModel.validityTo) {
      return licenceModel.validityTo;
    }

    return '';
  };

  const getMessageComponent = (children) => {
    if (showTooltip) {
      return (
        <DeviceForFreeTooltip isTooltip={is2nDevice} status={licenceModel?.status}>
          {children}
        </DeviceForFreeTooltip>
      );
    }
    return children;
  };

  return mobileVideoStatusMap[licenceModel?.status]
    ? getMessageComponent(
        <FormattedMessage
          {...mobileVideoStatusMap[status]}
          values={{
            date: <CustomDate dateValue={getValidityData()} showTime />,
            span: (chunks) => <span style={showDate ? { ...dateStyles } : { display: 'none' }}>{chunks}</span>,
            status: (chunks) => <span style={{ ...statusStyles }}>{chunks}</span>,
          }}
        />
      )
    : null;
};

MobileVideoStatus.propTypes = {
  dateStyles: PropTypes.shape({}),
  is2nDevice: PropTypes.bool,
  licenceModel: PropTypes.shape({
    poolExpiration: PropTypes.string,
    requirePaymentCount: PropTypes.number,
    status: PropTypes.string,
    validityTo: PropTypes.string,
  }).isRequired,
  showDate: PropTypes.bool,
  showTooltip: PropTypes.bool,
  statusStyles: PropTypes.shape({}),
  subscribed: PropTypes.bool,
};

MobileVideoStatus.defaultProps = {
  dateStyles: {},
  is2nDevice: false,
  showDate: true,
  showTooltip: false,
  statusStyles: {},
  subscribed: false,
};

export default MobileVideoStatus;

const mobileVideoStatusMap = {
  ACTIVE: messages.mobileVideoStatusMobileVideoStatusActive,
  DISABLED: messages.mobileVideoStatusMobileVideoStatusDisabled,
  ENABLED: messages.mobileVideoStatusMobileVideoStatusEnabled,
  EXPIRED: messages.mobileVideoStatusMobileVideoStatusExpired,
  INITIAL: messages.mobileVideoStatusMobileVideoStatusInitial,
  STANDARD: messages.mobileVideoStatusMobileVideoStatusStandard,
  STANDARD_EXPIRED: messages.mobileVideoStatusMobileVideoStatusStandardExpired,
  STANDARD_NOT_SUBSCRIBED: messages.mobileVideoStatusMobileVideoStatusStandardNotSubscribed,
  STANDARD_RENEWAL: messages.mobileVideoStatusMobileVideoStatusStandardRenewal,
  TEMPORARY: messages.mobileVideoStatusMobileVideoStatusTemporary,
  TRIAL_ACTIVE: messages.mobileVideoStatusMobileVideoStatusTrialActive,
  TRIAL_EXPIRED: messages.mobileVideoStatusMobileVideoStatusTrialExpired,
};
