import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import messages from '../../../../components/UserRoleSelect/messages';
import UserRoleInfo from '../../../../components/UserRoleSelect/UserRoleInfo';
import { FE_ROLES } from '../../../../config/consts';
import { useAppContext } from '../../../../pages/App/AppContextProvider';

function UserRoleSelect({
  companyName,
  control,
  currentUserRole,
  initialUserRole,
  isGuest,
  onOpenChangeUserRoleModal,
}) {
  const { feSiteRoles } = useAppContext();
  const isEditedUserCompanyAdmin = currentUserRole === FE_ROLES.COMPANY_ADMIN;
  const isEditedUserSiteAdminWithPayments = currentUserRole === FE_ROLES.SITE_ADMIN_PAYMENT;
  const isLoggedUserCompanyAdmin = feSiteRoles.isCompanyAdmin;

  function openChangeUserRoleModal() {
    if (initialUserRole === FE_ROLES.LOGIN_LESS) {
      onOpenChangeUserRoleModal();
    }
  }

  return (
    <>
      <Controller
        control={control}
        name="role"
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            error={!!fieldState?.error}
            fullWidth
            helperText={
              <FormattedMessage values={{ br: <br /> }} {...messages.userRoleSelectLoginLessUserHelperText} />
            }
            label={<FormattedMessage {...messages.userRoleSelectLabel} />}
            margin="normal"
            select
            value={currentUserRole}
            disabled={isGuest}
          >
            <MenuItem
              disabled={isEditedUserCompanyAdmin || (isEditedUserSiteAdminWithPayments && !isLoggedUserCompanyAdmin)}
              onClick={openChangeUserRoleModal}
              value={FE_ROLES.SITE_USER}
            >
              <FormattedMessage {...messages.userRoleSelectItemSiteUser} />
            </MenuItem>
            <MenuItem
              disabled={isEditedUserCompanyAdmin || initialUserRole !== FE_ROLES.LOGIN_LESS}
              value={FE_ROLES.LOGIN_LESS}
            >
              <FormattedMessage {...messages.userRoleSelectItemLoginLessUser} />
            </MenuItem>
            <MenuItem
              disabled={
                isEditedUserCompanyAdmin ||
                initialUserRole === FE_ROLES.LOGIN_LESS ||
                (isEditedUserSiteAdminWithPayments && !isLoggedUserCompanyAdmin)
              }
              onClick={openChangeUserRoleModal}
              value={FE_ROLES.SITE_ADMIN}
            >
              <FormattedMessage {...messages.userRoleSelectItemSiteAdmin} />
            </MenuItem>
            <MenuItem
              disabled={
                isEditedUserCompanyAdmin ||
                (!feSiteRoles.isCompanyAdmin && !isEditedUserCompanyAdmin) ||
                initialUserRole === FE_ROLES.LOGIN_LESS
              }
              onClick={openChangeUserRoleModal}
              value={FE_ROLES.SITE_ADMIN_PAYMENT}
            >
              <FormattedMessage {...messages.userRoleSelectItemSiteAdminPayment} />
            </MenuItem>
            <MenuItem disabled={!isEditedUserCompanyAdmin} value={FE_ROLES.COMPANY_ADMIN}>
              <FormattedMessage {...messages.userRoleSelectItemCompanyAdmin} />
            </MenuItem>
          </TextField>
        )}
      />
      {currentUserRole !== initialUserRole && initialUserRole !== FE_ROLES.LOGIN_LESS && (
        <Box sx={{ '& .MuiPaper-root': { mt: '0 !important' } }}>
          <UserRoleInfo companyName={companyName} userRole={currentUserRole} />
        </Box>
      )}
    </>
  );
}

UserRoleSelect.propTypes = {
  companyName: PropTypes.string.isRequired,
  control: PropTypes.shape({}).isRequired,
  currentUserRole: PropTypes.string.isRequired,
  initialUserRole: PropTypes.string.isRequired,
  isGuest: PropTypes.bool.isRequired,
  onOpenChangeUserRoleModal: PropTypes.func.isRequired,
};

export default UserRoleSelect;
