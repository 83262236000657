import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import acServiceSvg from '../../../assets/service-ac.svg';
import AccessGroupsTableChipList from '../../../components/AccessGroupsTableChipList';
import DataGridComponent from '../../../components/DataGrid/DataGrid';
import NameColumn from '../../../components/DataGrid/NameColumn';
import DeviceModelName from '../../../components/DeviceModelName';
import DeviceTypeIcon from '../../../components/DeviceTypeIcon';
import ItemService from '../../../components/IconServices/ItemService';
import { PATHS } from '../../../config/consts';
import { SERVICE } from '../../../config/devices';
import { useDataGrid } from '../../../helpers/dataGrid';
import useBreakpoints from '../../../helpers/useBreakpoints';
import { isFetchingMessages } from '../../../modules/localization/selectors';
import DataGridRowMenu from './DataGridRowMenu/DataGridRowMenu';
import LoggingStatus from './LoggingStatus';
import messages from './messages';
import NoRowsOverlay from './NoRowsOverlay';

const DataGrid = ({
  companyId,
  data,
  devicesDataGrid,
  groupId,
  isFetching,
  isInvalid,
  loadAccessGroupDevices,
  onLogActivation,
  onRacActivation,
  onRemove,
  siteId,
}) => {
  const { onOrderBy, onRowsPerPageChange, onSelectPage } = useDataGrid(
    loadAccessGroupDevices,
    {
      companyId,
      groupId,
      order: devicesDataGrid.order,
      page: devicesDataGrid.page,
      rowsPerPage: devicesDataGrid.rowsPerPage,
      siteId,
    },
    isInvalid
  );

  const { upMd, upSm } = useBreakpoints();
  const isLocalizationLoaded = useSelector(isFetchingMessages);

  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  const loadingWrapper = (children) => (isFetching ? <Skeleton animation="wave" height={25} width="80%" /> : children);

  const columns = useMemo(
    () => [
      {
        disableColumnMenu: true,
        field: 'deviceName',
        flex: 1,
        headerName: formatMessage(messages.accessGroupDevicesDataGridColumnsName),
        renderCell: (device) =>
          loadingWrapper(
            device?.value && (
              <NameColumn
                iconComponent={
                  <Tooltip title={<DeviceModelName deviceType={device?.value?.deviceType} />}>
                    <Box
                      component="span"
                      sx={{
                        textAlign: 'center',
                        width: '24px',
                      }}
                    >
                      <DeviceTypeIcon
                        deviceType={device?.value?.deviceType}
                        showAvatar={false}
                        small
                        type={device?.value?.type}
                      />
                    </Box>
                  </Tooltip>
                }
                isActive={!!device?.value?.accessControl}
                name={device?.value?.deviceName}
              />
            )
          ),
        sortable: true,
      },
      {
        disableColumnMenu: true,
        field: 'rac',
        headerName: upSm ? formatMessage(messages.accessGroupDevicesDataGridColumnsRac) : '',
        renderCell: (device) =>
          loadingWrapper(
            device?.value && (
              <Box sx={{ '& img': { verticalAlign: 'middle' }, display: 'flex' }}>
                <ItemService
                  active={device?.value?.accessControl}
                  image={acServiceSvg}
                  service={SERVICE.ACCESS_CONTROL}
                />
              </Box>
            )
          ),
        sortable: false,
        width: upSm ? 150 : 50,
      },
      {
        ...(upSm && { flex: 0.5 }),
        disableColumnMenu: true,
        field: 'loging',
        headerName: upSm ? formatMessage(messages.accessGroupDevicesDataGridColumnsLogging) : '',
        renderCell: (device) =>
          loadingWrapper(
            device?.value && (
              <LoggingStatus isActive={device?.value?.accessLog} isRacActive={device?.value?.accessControl} />
            )
          ),
        sortable: false,
        width: !upSm && 100,
      },
      {
        disableColumnMenu: true,
        field: 'groups',
        flex: 1,
        headerName: formatMessage(messages.accessGroupDevicesDataGridColumnsGroups),
        renderCell: (device) =>
          loadingWrapper(
            device?.value && (
              <AccessGroupsTableChipList
                companyId={companyId}
                currentGroupId={groupId}
                groups={device?.value?.groups}
                siteId={siteId}
              />
            )
          ),
        sortable: false,
      },
      {
        disableColumnMenu: true,
        field: 'rowMenu',
        headerName: '',
        renderCell: (device) =>
          loadingWrapper(
            device?.value && (
              <DataGridRowMenu
                companyId={companyId}
                deviceId={device?.value?.deviceId}
                isLogActive={device?.value?.accessLog}
                isRacActive={device?.value?.accessControl}
                onLogActivation={() => onLogActivation(device?.value)}
                onRacActivation={() => onRacActivation(device?.value)}
                onRemove={() => onRemove(device?.value)}
                siteId={siteId}
              />
            )
          ),
        sortable: false,
        width: 65,
      },
    ],
    [upMd, upSm, isFetching, isLocalizationLoaded]
  );

  const rows = useMemo(
    () =>
      data.map((device) => ({
        deviceName: device,
        groups: device,
        id: device.deviceId,
        isClickable: true,
        loging: device,
        rac: device,
        rowMenu: device,
      })),
    [data]
  );

  return (
    <>
      <DataGridComponent
        columns={columns}
        columnVisibilityModel={{
          groups: upMd,
        }}
        disableRowSelectionOnClick
        hideFooterPagination={isFetching}
        loading={isFetching}
        noRowsOverlay={<NoRowsOverlay />}
        onCellClick={(params) => {
          if (params.field !== 'rowMenu') {
            navigate(
              PATHS.DEVICE_ACCESS_SETTINGS.replace(':companyId', companyId)
                .replace(':siteId', siteId)
                .replace(':deviceId', params.value?.deviceId)
            );
          }
        }}
        onOrderBy={onOrderBy}
        onRowsPerPageChange={onRowsPerPageChange}
        onSelectPage={onSelectPage}
        order={devicesDataGrid.order}
        page={devicesDataGrid.page}
        resultsFiltered={devicesDataGrid.resultsFiltered}
        resultsTotal={devicesDataGrid.resultsTotal}
        rows={rows}
        rowsPerPage={devicesDataGrid.rowsPerPage}
      />
    </>
  );
};

DataGrid.propTypes = {
  companyId: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  devicesDataGrid: PropTypes.shape({
    order: PropTypes.string.isRequired,
    page: PropTypes.number.isRequired,
    resultsFiltered: PropTypes.number.isRequired,
    resultsTotal: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  }).isRequired,
  groupId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isFetching: PropTypes.bool.isRequired,
  isInvalid: PropTypes.bool.isRequired,
  loadAccessGroupDevices: PropTypes.func.isRequired,
  onLogActivation: PropTypes.func.isRequired,
  onRacActivation: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  siteId: PropTypes.number.isRequired,
};

export default DataGrid;
