import { useState } from 'react';
import { IntlFormatters, MessageDescriptor, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useApartmentAdminDeleteApartmentUserByIdMutation } from '../../../../apiRtk/be4feApi';
import { invalidateUsersInApartments } from '../../../../modules/apartmentAdmin/actions';
import { ApartmentUser } from '../../../../modules/apartmentAdmin/data';
import { IApartmentIdsInfo } from '../../../../modules/apartments/store';
import { TYPE_DESCRIPTOR } from '../../../../modules/notifications';
import { addSnackbarError, addSnackbarSuccess } from '../../../../modules/notifications/actions';
import { invalidateCurrentUser } from '../../../../modules/user/actionsTS';
import { getCurrentUserIdSelector } from '../../../../modules/user/selectors';

export interface IUseDeleteUserProps {
  successMessage: MessageDescriptor;
  errorMessage: MessageDescriptor;
  apartmentIdsInfo: IApartmentIdsInfo;
  user: ApartmentUser;
  onClose: () => void;
}

export interface IUseDeleteUser {
  onDeleteHandler: () => Promise<void>;
  isSubmitting: boolean;
  formatMessage: IntlFormatters['formatMessage'];
}

export function useDeleteUserFromApartment({
  apartmentIdsInfo,
  errorMessage,
  onClose,
  successMessage,
  user,
}: IUseDeleteUserProps): IUseDeleteUser {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const currentUserId: number | undefined = useSelector(getCurrentUserIdSelector);

  const [removeUser] = useApartmentAdminDeleteApartmentUserByIdMutation();

  const onDeleteHandler = async () => {
    setIsSubmitting(true);
    try {
      await removeUser({
        apartmentId: apartmentIdsInfo.apartmentId,
        companyId: apartmentIdsInfo.companyId,
        siteId: apartmentIdsInfo.siteId,
        userId: user.id,
      }).unwrap();

      dispatch(addSnackbarSuccess({ ...successMessage }));
      setIsSubmitting(false);
      dispatch(invalidateUsersInApartments());

      if (currentUserId !== undefined && currentUserId === user.id) {
        dispatch(invalidateCurrentUser());
      }

      onClose();
    } catch (e) {
      dispatch(addSnackbarError({ ...errorMessage }, TYPE_DESCRIPTOR));
      setIsSubmitting(false);
    }
  };

  return { formatMessage, isSubmitting, onDeleteHandler };
}
