import { MessageDescriptor } from 'react-intl';
import * as yup from 'yup';
import validationMessage from '../../../../../validations/messages';
import { LabelMaxLength } from '../../../../../validations/validationConstants';

export function labelValidationSchema(formatMessage: (message: MessageDescriptor) => string) {
  return yup.object().shape({
    label: yup
      .string()
      .required(formatMessage(validationMessage.AAmodalChangeLabelErrorMandatory))
      .trim()
      .max(LabelMaxLength, formatMessage(validationMessage.AAmodalChangeLabelErrorTooLong)),
  });
}
