import { defineMessages } from 'react-intl';

export default defineMessages({
  elevatorGroupSelectValue: {
    defaultMessage: '{buildingId}, group {groupName}',
    description: 'Value of elevator group select',
    id: '  elevatorGroupSelectValue',
  },
  elevatorIntegrationMappingElevatorCoreNull: {
    defaultMessage: 'No elevator core',
    id: 'elevatorIntegration.mapping.elevatorCore.null',
  },
});
