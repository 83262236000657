import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormikFields } from '../../../components';

const PaymentConfirm = ({ description, message, name }) => (
  <Grid item sx={{ mb: 3, pt: 0 }}>
    <FormikFields.Checkbox
      id="recurringpayment-checkbox"
      label={
        <Typography component="span" variant="body2">
          {message}
        </Typography>
      }
      name={name}
      required
      sx={{
        '& .MuiStack-root': {
          display: 'block !important',
        },
        '& span': {
          pt: 0,
        },
        alignItems: 'flex-start',
        display: 'flex',
      }}
      validate={(value) => !value}
    />
    {description && <Typography variant="body2">{description}</Typography>}
  </Grid>
);

PaymentConfirm.propTypes = {
  description: PropTypes.node,
  message: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
};

PaymentConfirm.defaultProps = {
  description: null,
};

export default PaymentConfirm;
