import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import RowMenuItem from '../../DataGrid/RowMenuItem';
import messages from '../messages';

const SetSiteType = ({ onSetSiteType, site }) => (
  <RowMenuItem
    icon={<CheckCircleIcon />}
    label={<FormattedMessage {...messages.sitesDataGridActionsSetSiteType} />}
    onClick={() => onSetSiteType(site)}
  />
);

SetSiteType.propTypes = {
  onSetSiteType: PropTypes.func.isRequired,
  site: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
};

export default SetSiteType;
