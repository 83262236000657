import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Select from '@mui/material/Select';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { PhonebookMembersDataGrid } from '../../../components';
import { SERVICE } from '../../../config/devices';
import { useUrlParams } from '../../../helpers/urlParams';
import deviceActions, { selectors as devicesSelectors } from '../../../modules/devices';
import actions, { selectors as phonebooksSelectors } from '../../../modules/phonebooks';
import PhonebookFormModal from '../../CallSettings/Phonebooks/PhonebookFormModal';
import messages from '../messages';

const Phonebooks = ({
  data,
  device,
  loading,
  onDetailPhonebook,
  onEditShowDialog,
  onLoadPhonebooksNames,
  onSave,
  phonebooks,
}) => {
  const { companyId, siteId } = useUrlParams();
  const id = device.services[SERVICE.MOBILE_VIDEO].phonebookId;
  useEffect(() => {
    onLoadPhonebooksNames();
    onDetailPhonebook(companyId, siteId, id);
  }, []);

  const [phonebookId, setPhonebookId] = useState(device.services[SERVICE.MOBILE_VIDEO].phonebookId);
  const [disabled, setDisabled] = useState(true);

  const handleChange = (event) => {
    if (event.target.value !== phonebookId) {
      setDisabled(false);
    }
    setPhonebookId(event.target.value);
    onDetailPhonebook(companyId, siteId, event.target.value);
  };

  const handleSave = () => {
    onSave({ phonebookId });
    setDisabled(true);
  };

  return (
    <>
      <Paper>
        <FormControl sx={{ mb: 2, p: 2 }} variant="outlined">
          <FormLabel>
            <FormattedMessage {...messages.phonebooksDevicePhonebook} />
          </FormLabel>
          <Select fullWidth onChange={handleChange} value={phonebooks.length > 0 ? phonebookId : ''}>
            {phonebooks.map((phonebook) => (
              <MenuItem key={phonebook.id} value={phonebook.id}>
                {phonebook.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <PhonebookMembersDataGrid loading={loading} members={data?.members} />
        <Box display="flex" justifyContent="space-between" sx={{ p: 2 }}>
          <Button color="primary" disabled={loading || data?.isDefault} onClick={() => onEditShowDialog(data)}>
            <FormattedMessage {...messages.phonebooksEditPhonebook} />
          </Button>
          <Button color="primary" disabled={disabled} onClick={handleSave} variant="contained">
            <FormattedMessage {...messages.phonebooksSave} />
          </Button>
        </Box>
      </Paper>
      <PhonebookFormModal companyId={companyId} siteId={siteId} />
    </>
  );
};

Phonebooks.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    isDefault: PropTypes.bool,
    members: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool,
        id: PropTypes.number,
        name: PropTypes.string,
        sipNumber: PropTypes.string,
        type: PropTypes.string,
      })
    ),
    name: PropTypes.string,
  }).isRequired,
  device: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    services: PropTypes.shape({
      [SERVICE.MOBILE_VIDEO]: PropTypes.shape({
        phonebookId: PropTypes.number,
      }),
    }),
  }).isRequired,
  loading: PropTypes.bool.isRequired,
  onDetailPhonebook: PropTypes.func.isRequired,
  onEditShowDialog: PropTypes.func.isRequired,
  onLoadPhonebooksNames: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  phonebooks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    })
  ).isRequired,
};

const mapStateToProps = (state) => ({
  data: phonebooksSelectors.getPhonebooksDetailData(state),
  device: devicesSelectors.getCurrentDeviceDataSelector(state),
  loading: phonebooksSelectors.getPhonebooksDetailIsLoading(state),
  phonebooks: phonebooksSelectors.getPhonebooksList(state),
});

const dispatchToProps = {
  onDetailPhonebook: actions.detailPhonebook,
  onEditShowDialog: actions.editPhonebookShowModal,
  onLoadPhonebooksNames: actions.loadPhonebooksNames,
  onSave: deviceActions.changePhonebook,
};

export default connect(mapStateToProps, dispatchToProps)(Phonebooks);
