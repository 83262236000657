import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const NoRowsOverlay = () => (
  <Box
    sx={{
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'center',
      mx: 3,
      my: 9,
    }}
  >
    <Typography color="secondary" variant="h5">
      <FormattedMessage {...messages.noRowsHeaderText} />
    </Typography>
    <Typography color="text.secondary" sx={{ mt: 2.5 }} variant="body1">
      <FormattedMessage {...messages.noRowsText} />
    </Typography>
  </Box>
);

export default NoRowsOverlay;
