import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { SelectField } from '../../../../components';
import { CONFIGURATION_MODE } from '../../../../config/devices';
import ConfigurationModeDialog from './ConfigurationModeDialog';
import messages from './messages';

const ConfigurationModeField = ({ disabled, onChange, value }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectEvent, setSelectEvent] = useState(null);

  const options = [
    {
      label: <FormattedMessage {...messages.optionsAutomatic} />,
      value: CONFIGURATION_MODE.AUTOMATIC,
    },
    {
      label: <FormattedMessage {...messages.optionsManual} />,
      value: CONFIGURATION_MODE.MANUAL,
    },
  ];

  const handleOnChange = (event) => {
    setIsOpen(true);
    setSelectEvent(event);
  };

  const handleConfirm = () => {
    onChange(selectEvent);
    setIsOpen(false);
  };

  return (
    <>
      {selectEvent?.target?.value && (
        <ConfigurationModeDialog
          isOpen={isOpen}
          mode={selectEvent.target.value}
          onClose={() => setIsOpen(false)}
          onConfirm={handleConfirm}
        />
      )}
      <Box mt={1} pb={2}>
        <SelectField
          disabled={disabled}
          fullWidth
          helperText=""
          label={<FormattedMessage {...messages.Label} />}
          margin="none"
          name="configurationMode"
          onChange={handleOnChange}
          options={options}
          value={value}
          variant="outlined"
        />
      </Box>
    </>
  );
};

ConfigurationModeField.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOf([CONFIGURATION_MODE.AUTOMATIC, CONFIGURATION_MODE.MANUAL]).isRequired,
};

export default ConfigurationModeField;
