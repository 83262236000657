import AddCircle from '@mui/icons-material/AddCircle';
import Cancel from '@mui/icons-material/Cancel';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FE_ROLES } from '../../../config/consts';
import { isAdmin } from '../../../helpers/user';
import { IUser } from '../../../modules/user/store';
import RowMenuItem from '../../DataGrid/RowMenuItem';
import messages from '../messages';

interface IManageAdminRights {
  onOpenGrantAdminRightsModal: () => void;
  onOpenRevokeAdminRightsModal: () => void;
  onSetSelectedUser: (user: IUser) => void;
  user: IUser;
}

function ManageAdminRights({
  onOpenGrantAdminRightsModal,
  onOpenRevokeAdminRightsModal,
  onSetSelectedUser,
  user,
}: IManageAdminRights) {
  const isUserAdmin = isAdmin(user.role);
  const isUserAdminOrApartmentAdmin = isUserAdmin || user.admin;

  return (
    <RowMenuItem
      disabled={isUserAdmin || user.role === FE_ROLES.LOGIN_LESS}
      icon={isUserAdminOrApartmentAdmin ? <Cancel /> : <AddCircle />}
      label={
        <FormattedMessage
          {...(isUserAdminOrApartmentAdmin
            ? { ...messages.AAApartmentUserListContextRevoke }
            : { ...messages.AAApartmentUserListContextGrant })}
        />
      }
      onClick={() => {
        onSetSelectedUser(user);
        isUserAdminOrApartmentAdmin ? onOpenRevokeAdminRightsModal() : onOpenGrantAdminRightsModal();
      }}
    />
  );
}

export default ManageAdminRights;
