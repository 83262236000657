import { defineMessages } from 'react-intl';

export default defineMessages({
  thirdPartyFormApartmentNumberExists: {
    defaultMessage: 'An apartment with this number already exists.',
    description: 'Device Modal - new apartment form - apartment number already exists',
    id: 'thirdPartyForm.apartment.numberExists',
  },
  thirdPartyFormApartmentRequired: {
    defaultMessage: "Choose an apartment','thirdPartyForm.apartment.required",
    description: '',
    id: 'thirdPartyForm.apartment.required',
  },
  thirdPartyFormEmailNoValid: {
    defaultMessage: 'Wrong format of the e-mail.',
    description: 'Validation - email is not valid',
    id: 'thirdPartyForm.email.noValid',
  },
  thirdPartyFormNameExample: {
    defaultMessage: "e.g. John's mobile phone",
    description: 'Validation - name example',
    id: 'thirdPartyForm.name.example',
  },
  thirdPartyFormNameExist: {
    defaultMessage: 'This device name is already used.',
    description: 'Validation - name exist',
    id: 'thirdPartyForm.name.exist',
  },
  thirdPartyFormNameLabel: {
    defaultMessage: 'Device name',
    description: 'Validation - name label',
    id: 'thirdPartyForm.name.label',
  },
  thirdPartyFormNameSubLabel: {
    defaultMessage: 'You will see it in the device list under this name.',
    description: 'Validation - name sub-label',
    id: 'thirdPartyForm.name.subLabel',
  },
  thirdPartyFormRequired: {
    defaultMessage: 'Value is mandatory',
    description: 'Validation - required value',
    id: 'thirdPartyForm.required',
  },
  thirdPartyFormTooLong: {
    defaultMessage: 'Value is too long',
    description: 'Validation - value is too long',
    id: 'thirdPartyForm.tooLong',
  },
});
