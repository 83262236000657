import { createSelector } from 'reselect';
import { selectors as companySelectors } from '../apartments';
import { IRootStore } from '../store';
import { getIntercomButtonItems } from './helpers/selectorHelpers';
import { getCallSettingsDevicesOptions, getDefaultIntercomList } from './selectors';

export const getIntercomButtonList = createSelector(
  getDefaultIntercomList,
  getCallSettingsDevicesOptions,
  companySelectors.getApartmentsList,
  getIntercomButtonItems
);

export const getUserDevices = (state: IRootStore) => state.devices.list;

export const getDefaultPasswordDevices = (state: IRootStore) => state.devices.hasDefaultPasswordDevices;
