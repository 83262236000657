import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import RowMenuItem from '../../DataGrid/RowMenuItem';
import messages from './messages';

const DeleteGroup = ({ onClick }) => (
  <RowMenuItem
    colorIcon="error.main"
    colorText="error.main"
    icon={<DeleteIcon />}
    label={<FormattedMessage {...messages.AccessSettingsDataGridTableRowActionsDelete} />}
    onClick={onClick}
  />
);

DeleteGroup.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default DeleteGroup;
