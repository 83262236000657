import { defineMessages } from 'react-intl';

export default defineMessages({
  cookieConsentButtonAcceptAll: {
    defaultMessage: 'Accept all',
    description: 'Button accept all label',
    id: 'CookieConsent.buttonAcceptAll',
  },
  cookieConsentButtonClose: {
    defaultMessage: 'Close',
    description: 'Button close label',
    id: 'CookieConsent.buttonClose',
  },
  cookieConsentButtonSave: {
    defaultMessage: 'Save cookies settings',
    description: 'Button save label',
    id: 'CookieConsent.buttonSave',
  },
  cookieConsentButtonSettings: {
    defaultMessage: 'Settings',
    description: 'Button settings label',
    id: 'CookieConsent.buttonSettings',
  },
  cookieConsentInfo: {
    defaultMessage:
      'This website uses cookies to improve site navigation,' +
      ' perform analytics and research, and conduct advertising. You can' +
      ' change your preferences below. You can revoke your consent at any' +
      ' time without any future consequences.',
    description: 'Cookie consent info',
    id: 'CookieConsent.info',
  },
  cookieConsentLinkCookies: {
    defaultMessage: 'Cookies policy',
    description: 'Link cookies',
    id: 'CookieConsent.link.cookies',
  },
  cookieConsentSettingsFunctionalInfo: {
    defaultMessage:
      'These cookies store your preferences on our' +
      ' website and enable personalization. If you don’t allow' +
      ' them, certain services may not work correctly.',
    description: 'Functional cookies info',
    id: 'CookieConsent.settings.functional.info',
  },
  cookieConsentSettingsFunctionalLabel: {
    defaultMessage: 'Functional cookies',
    description: 'Functional cookies label',
    id: 'CookieConsent.settings.functional.label',
  },
  cookieConsentSettingsMarketingInfo: {
    defaultMessage:
      'These cookies help us analyze how well our websites' +
      ' are performing and enable more personalized advertising.',
    description: 'Marketing cookies info',
    id: 'CookieConsent.settings.marketing.info',
  },
  cookieConsentSettingsMarketingLabel: {
    defaultMessage: 'Performance and marketing cookies',
    description: 'Marketing cookies label',
    id: 'CookieConsent.settings.marketing.label',
  },
  cookieConsentSettingsNecessaryInfo: {
    defaultMessage:
      'These cookies are essential for our website to' +
      ' function properly and cannot be disabled. They don’t store' +
      ' any personally identifiable data.',
    description: 'Necessary cookies info',
    id: 'CookieConsent.settings.necessary.info',
  },
  cookieConsentSettingsNecessaryLabel: {
    defaultMessage: 'Necessary cookies',
    description: 'Necessary cookies label',
    id: 'CookieConsent.settings.necessary.label',
  },
});
