import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import rcBg from '../../assets/rc_bg.jpg';
import messages from './messages';

const styles = {
  item: {
    '&:before': {
      color: 'primary.main',
      content: '"\\ea0d"',
      fontFamily: 'my2n',
      fontSize: '20px',
      left: 0,
      position: 'absolute',
      top: '4px',
    },
    listStyleType: 'none',
    mb: '20px',
    pl: '55px',
    position: 'relative',
  },
  list: {
    m: 0,
  },
  scenario: {
    backgroundColor: 'common.white',
    minHeight: {
      md: '510px',
      xs: 'auto',
    },
    p: 0,
    textAlign: 'left',
  },
  scenarioContent: {
    float: {
      md: 'right',
      xs: 'none',
    },
    pb: {
      md: 0,
      xs: '15px',
    },
    pl: {
      xs: '20px',
    },
    position: 'relative',
    pr: {
      md: 0,
      xs: '20px',
    },
    pt: {
      xs: '45px',
    },
    width: {
      md: '60%',
      xs: '100%',
    },
    zIndex: 2,
  },
  scenarioImg: {
    '& img': {
      maxWidth: {
        md: 'initial',
        xs: '400px',
      },
    },
    background: `url(${rcBg}) no-repeat`,
    backgroundPosition: {
      md: 'initial',
      xs: '50% 50%',
    },
    backgroundSize: {
      md: 'initial',
      xs: 'cover',
    },
    bottom: 0,
    height: {
      dm: 'inherit',
      xs: '100%',
    },
    left: {
      md: '-245px',
      xs: 0,
    },
    margin: 'auto',
    opacity: {
      md: 'initial',
      xs: 0.2,
    },
    position: 'absolute',
    right: 0,
    top: 0,
  },
  scenarioWrapper: {
    m: '0 auto',
    maxWidth: '1050px',
    minHeight: 'inherit',
    pb: '35px',
    position: 'relative',
    width: '100%',
  },
  title: {
    color: 'text.primary',
    fontSize: {
      lg: '38px',
      md: '36px',
      xs: '34px',
    },
    fontWeight: 400,
    mb: 8,
    mt: 0,
    px: 2.5,
    py: 0,
    textAlign: 'center',
  },
};

const Scenario = () => (
  <Box sx={styles.scenario}>
    <Box sx={styles.scenarioWrapper}>
      <Box sx={styles.scenarioImg} />
      <Box sx={styles.scenarioContent}>
        <Typography component="h3" sx={styles.title}>
          <FormattedMessage {...messages.rcLandingPageScenarioTitle} />
        </Typography>
        <Box component="ul" sx={styles.list}>
          <Box component="li" sx={styles.item}>
            <Typography variant="body2">
              <FormattedMessage
                values={{
                  br: <br />,
                  nbsp: <>&nbsp;</>,
                  sup: (chunks) => <sup>{chunks}</sup>,
                }}
                {...messages.rcLandingPageScenarioListFirstItem}
              />
            </Typography>
          </Box>
          <Box component="li" sx={styles.item}>
            <Typography variant="body2">
              <FormattedMessage {...messages.rcLandingPageScenarioListSecondItem} />
            </Typography>
          </Box>
          <Box component="li" sx={styles.item}>
            <Typography variant="body2">
              <FormattedMessage
                values={{
                  br: <br />,
                  nbsp: <>&nbsp;</>,
                  sup: (chunks) => <sup>{chunks}</sup>,
                }}
                {...messages.rcLandingPageScenarioListThirdItem}
              />
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  </Box>
);

export default Scenario;
