import { defineMessages } from 'react-intl';

export default defineMessages({
  AAmodalPinformDownload: {
    defaultMessage: 'Download',
    id: 'AAmodal.PINform.download',
  },
  AAmodalPinformShare: {
    defaultMessage: 'Share',
    id: 'AAmodal.PINform.share',
  },
  AAmodalShowGuestQRError: {
    defaultMessage: 'Show Guest QR Error',
    id: 'AAmodal.showGuestQR.error',
  }
});
