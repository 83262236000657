import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

interface MvSdkSettingsDefaultProps {
  setIsCustom: (isCustom: boolean) => void;
}

const styles = {
  buttonWrapper: { mt: 1, textAlign: 'center' },
};

export function MvSdkSettingsDefault({ setIsCustom }: MvSdkSettingsDefaultProps) {
  return (
    <>
      <Typography variant="subtitle1">
        <FormattedMessage {...messages.mvSdkSettingsDefaultValues} />
      </Typography>
      <Box sx={styles.buttonWrapper}>
        <Button color="primary" onClick={() => setIsCustom(true)} variant="contained">
          <FormattedMessage {...messages.mvSdkSettingsSetCustom} />
        </Button>
      </Box>
    </>
  );
}
