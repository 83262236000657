import { Check } from '@mui/icons-material';
import { Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { FilledInfoBar } from '../../../../components/_DesignSystemWrappers/InfoBar/FilledInfoBar';
import { PasswordInput } from '../../../../components/HookFormFields/PasswordInput';
import messages from './messages';
import { useNewPasswordForm } from './useNewPasswordForm';
import { FormFieldNames } from './validationScheme';

export interface INewPasswordFormProps {
  onSuccess: () => void;
  onRecoveryProcessRestart: () => void;
}

export function NewPasswordForm({ onRecoveryProcessRestart, onSuccess }: INewPasswordFormProps) {
  const { formData, isOryApiFetching, isPasswordHelperTextVisible, onFormSubmit } = useNewPasswordForm({
    onRecoveryProcessRestart,
    onSuccess,
  });

  return (
    <FormProvider {...formData}>
      <form onSubmit={formData.handleSubmit(onFormSubmit)}>
        <Stack direction="column" spacing={2}>
          <PasswordInput
            id="new-password"
            label={<FormattedMessage {...messages.ImsNewPasswordInputLabel} />}
            name={FormFieldNames.Password}
            required
            fullWidth
            helperText={isPasswordHelperTextVisible && <FormattedMessage {...messages.ImsNewPasswordInputHelperText} />}
          />

          <Stack direction="column" spacing={3}>
            <FilledInfoBar>
              <Typography>
                <FormattedMessage {...messages.ImsNewPasswordAlertBody} />
              </Typography>
            </FilledInfoBar>

            <Stack alignItems={'center'}>
              <Button
                disabled={!formData.formState.isValid || isOryApiFetching}
                color="primary"
                size="large"
                type="submit"
                variant="contained"
                startIcon={<Check />}
              >
                <FormattedMessage {...messages.ImsNewPasswordButtonSetPassword} />
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </form>
    </FormProvider>
  );
}
