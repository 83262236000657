import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Divider from '@mui/material/Divider';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { IDevice } from '../../../modules/devices/store/deviceStore';
import RowMenuItem from '../../DataGrid/RowMenuItem';
import messages from '../messages';

interface IChangePassword {
  device: IDevice;
  onOpenRCModal: (device: IDevice) => void;
}

function ChangePassword({ device, onOpenRCModal }: IChangePassword) {
  return (
    <>
      <RowMenuItem
        colorIcon="primary.main"
        colorText="primary.main"
        icon={<CheckCircleIcon />}
        label={<FormattedMessage {...messages.deviceListChangePasswordButton} />}
        onClick={() => onOpenRCModal(device)}
      />
      <Divider />
    </>
  );
}

export default ChangePassword;
