import Paper from '@mui/material/Paper';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import useBreakpoints from '../../helpers/useBreakpoints';

const TabsContainer = ({ isXs, ...other }) => {
  const { downSm } = useBreakpoints();
  const [isDownSm, setisDownSm] = useState(undefined);

  useEffect(() => {
    setisDownSm(isXs !== undefined ? isXs : downSm);
  }, [isXs, downSm]);

  const styles = {
    rootPaper: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      boxShadow: (theme) => `0px 1px 1px 0px ${theme.palette.divider}, 0px 1px 3px 0px rgb(0 0 0 / 12%)`,
      mb: '1px',
    },
    rootTabs: {
      '& button, a': {
        fontSize: 14,
        minWidth: 0,
        px: isDownSm ? 2 : 4,
        py: 2,
      },
      px: isDownSm ? 0 : 3,
      py: 0,
      zIndex: 'zIndex.modal',
    },
  };

  return (
    <Paper sx={styles.rootPaper}>
      <Tabs
        {...other}
        allowScrollButtonsMobile
        indicatorColor="primary"
        scrollButtons="auto"
        sx={styles.rootTabs}
        textColor="primary"
        variant="scrollable"
      />
    </Paper>
  );
};

TabsContainer.propTypes = {
  isXs: PropTypes.bool,
};

TabsContainer.defaultProps = {
  isXs: undefined,
};

export default TabsContainer;
