import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { SiteWithAcCredentialsSettingsResponse } from '../../apiRtk/be4feApi';
import { PATHS } from '../../config/consts';
import featureFlags from '../../config/featureFlags';
import { conditionalArray } from '../../helpers/array';
import { useGetLoadedParams } from '../../hooks/paramsHooks';
import { useModal } from '../../hooks/useModal';
import * as siteSelectors from '../../modules/sites/selectors';
import { selectors } from '../../modules/user';
import { AccessType, IUser } from '../../modules/user/store';
import { My2NAppAccessIcon } from '../_Icons';
import My2NAppAccessDisabledIcon from '../_Icons/My2NAppAccessDisabledIcon';
import { useHasFeature } from '../Feature/Feature';
import My2NAppAccessModalComponent from '../My2NAppAccessModal';
import CredentialSection from './CredentialSection';
import messages from './messages';
import PanelMy2NAppAccess from './PanelMy2NAppAccess';

function SectionMy2NAppAccess({ user }: { user: IUser }) {
  const { companyId, siteId } = useGetLoadedParams();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const userMy2NAppAccessStatus = useSelector(selectors.getUserMy2NAppAccessEnabledStatus);
  const siteData: SiteWithAcCredentialsSettingsResponse = useSelector(siteSelectors.getSiteData);
  const [isActivated, setIsActivated] = useState(false);
  const { Modal: My2NAppAccessModal, onOpen: onOpenMy2NAppAccessModal } = useModal({
    Modal: My2NAppAccessModalComponent,
  });
  const isSiteBleFFEnable = useHasFeature(featureFlags.SITE_BLE);

  return (
    <>
      {!isSiteBleFFEnable || siteData.services.ACCESS_CONTROL.bluetoothEnabled ? (
        <CredentialSection
          addCredentialButtonAction={onOpenMy2NAppAccessModal}
          addCredentialButtonLabel={formatMessage(messages.sectionMy2NAppAccessAddButtonLabel)}
          deleteButtonAction={onOpenMy2NAppAccessModal}
          deleteButtonLabel={formatMessage(messages.sectionMy2NAppAccessDeleteButtonLabel)}
          infoText={formatMessage(messages.sectionMy2NAppAccessInfo, {
            p: (chunks: string): any => <p>{chunks}</p>,
          })}
          isActive={userMy2NAppAccessStatus}
          panel={<PanelMy2NAppAccess isActivated={isActivated} />}
          startIcon={My2NAppAccessIcon}
          title={formatMessage(messages.sectionMy2NAppAccessTitle)}
        />
      ) : (
        <CredentialSection
          addCredentialButtonAction={() => {}}
          addCredentialButtonLabel=""
          deleteButtonAction={() => {}}
          deleteButtonLabel=""
          infoText={formatMessage(messages.sectionMy2NAppAccessInfoDisabled, {
            p: (chunks: string): any => <p>{chunks}</p>,
          })}
          isActive={false}
          panel={<PanelMy2NAppAccess isActivated={false} />}
          customInactiveContent={
            <>
              <Typography sx={{ pb: 2 }}>
                <FormattedMessage {...messages.sectionMy2NAppAccessDisabledText} />
              </Typography>
              <Button
                color="primary"
                onClick={() =>
                  navigate(
                    PATHS.SITE_SETTINGS_ACCESS.replace(':companyId', companyId!.toString()).replace(
                      ':siteId',
                      siteId!.toString()
                    )
                  )
                }
                variant="text"
              >
                {formatMessage(messages.sectionMy2NAppAccessDisabledButton)}
              </Button>
            </>
          }
          startIcon={My2NAppAccessDisabledIcon}
          title={formatMessage(messages.sectionMy2NAppAccessTitle)}
        />
      )}

      <My2NAppAccessModal
        context="SITE_USER_DETAIL"
        onActivate={setIsActivated}
        user={{
          ...user,
          credentials: conditionalArray([userMy2NAppAccessStatus && 'BT']) as AccessType[],
        }}
      />
    </>
  );
}

export default SectionMy2NAppAccess;
