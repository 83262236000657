import { QrCode } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useGetUserCredentialsValidityQuery } from '../../apiRtk/accessControlApi';
import { SiteWithAcCredentialsSettingsResponse } from '../../apiRtk/be4feApi';
import { useUrlParams } from '../../helpers/urlParams';
import { useModal } from '../../hooks/useModal';
import { getApartmentDevices } from '../../modules/apartments/selectors';
import { VisitorModalContext } from '../../modules/commonTypes';
import { IUser } from '../../modules/user/store';
import { isUserGuest } from '../../modules/user/utils';
import VisitorModal from '../../pages/apartmentAdmin/ApartmentAdminUsersOverviewPage/components/VisitorModal/VisitorModal';
import RevokeUserPinDialog from '../RevokeUserPinModal';
import UserPinModalNewComponent from '../UserPinModalNew';
import CredentialAlert from './CredentialAlert';
import { useGetGlobalHooks } from './hooks/getGlobalHooks';
import messages from './messages';
import PanelQr from './PanelQr';
import SectionTitle from './SectionTitle';

interface ISectionQr {
  user: IUser;
  userPinData?: {
    active: boolean;
    id: string;
    pin: string;
  };
  siteData: SiteWithAcCredentialsSettingsResponse;
}

export interface EnrichedIUser extends IUser {
  validFrom?: string;
  validTo?: string;
}

function SectionQr({ siteData, user, userPinData }: ISectionQr) {
  const { formatMessage, isCompanyAdmin, isSiteAdmin } = useGetGlobalHooks();
  const isPinEmpty = !userPinData?.pin;
  const { companyId, siteId, userId } = useUrlParams();
  const apartmentDevices = useSelector(getApartmentDevices).data || [];
  const isGuestQrEnabled = isUserGuest(user.role) && siteData?.services.ACCESS_CONTROL?.guestShowQr;

  const { Modal: RevokeUserPinModal, onOpen: onOpenRevokeUserPinModal } = useModal({
    Modal: RevokeUserPinDialog,
  });
  const { Modal: UserPinModalNew, onOpen: onOpenUserPinModal } = useModal({
    Modal: UserPinModalNewComponent,
  });
  const { Modal: VisitorUModal, onOpen: onOpenVisitorUModal } = useModal({
    Modal: VisitorModal,
  });

  const [displayInfoBox, setDisplayInfoBox] = useState(isPinEmpty);

  const toggleDisplayInfoBox = () => {
    setDisplayInfoBox((prevState) => !prevState);
  };

  const [enrichedUser, setEnrichedUser] = useState(user as EnrichedIUser);

  const { data: userCredentialsValidityData } = useGetUserCredentialsValidityQuery({
    companyId: companyId!.toString(),
    siteId: siteId!.toString(),
    userId: userId!.toString(),
  });

  useEffect(() => {
    setEnrichedUser({
      ...user,
      validFrom: userCredentialsValidityData?.validFrom,
      validTo: userCredentialsValidityData?.validTo,
    });
  }, [userCredentialsValidityData]);

  return (
    <>
      <SectionTitle
        isEmpty={isPinEmpty}
        onInfoClick={toggleDisplayInfoBox}
        startIcon={<QrCode color={isPinEmpty ? 'secondary' : 'primary'} />}
        title={formatMessage(messages.userAccessFormQrTitle)}
      />
      {isPinEmpty ? (
        <Button
          color="primary"
          onClick={isUserGuest(user.role) ? onOpenVisitorUModal : onOpenUserPinModal}
          startIcon={<AddIcon />}
          sx={{ mb: 2 }}
          variant="outlined"
        >
          <FormattedMessage {...messages.userAccessFormQrGenerateNewQr} />
        </Button>
      ) : (
        <PanelQr pin={userPinData?.pin} />
      )}
      {displayInfoBox && (
        <CredentialAlert
          infoText={formatMessage(messages.userAccessFormQrInfoDescription, {
            br: <br />,
          })}
        />
      )}
      {!isPinEmpty && (
        <Grid sx={{ mt: 4 }}>
          <Button color="error" disabled={!(isCompanyAdmin || isSiteAdmin)} onClick={onOpenRevokeUserPinModal}>
            <FormattedMessage {...messages.revokeUserQrButtonDeleteQr} />
          </Button>
        </Grid>
      )}
      <UserPinModalNew context="SITE_USER_DETAIL" user={user} />
      <RevokeUserPinModal user={user} isGuestQrEnabled={isGuestQrEnabled} />
      <VisitorUModal
        msgContext={VisitorModalContext.AA_MODAL_NEW_GUEST_PIN}
        apartmentInfo={{ companyId: companyId!, siteId: siteId! }}
        apartmentDevices={user?.apartments.length ? apartmentDevices : []}
        siteName={siteData.name}
        user={enrichedUser}
        isRegenerate
      />
    </>
  );
}

export default SectionQr;
