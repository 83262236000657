import { defineMessages } from 'react-intl';

export default defineMessages({
  AccessSettingsDataGridTableRowActionsAddApartment: {
    defaultMessage: 'Add apartment to group',
    description: 'Table row actions - add apartment',
    id: 'accessSettingsDataGridTableRowActionsAddApartment',
  },
  AccessSettingsDataGridTableRowActionsAddDevice: {
    defaultMessage: 'Add device to group',
    description: 'Table row actions - add device',
    id: 'accessSettingsDataGridTableRowActionsAddDevice',
  },
  AccessSettingsDataGridTableRowActionsAddUsers: {
    defaultMessage: 'Add users to group',
    description: 'Table row actions - add users',
    id: 'accessSettingsDataGridTableRowActionsAddUsers',
  },
  AccessSettingsDataGridTableRowActionsDelete: {
    defaultMessage: 'Delete access group',
    description: 'Table row actions - delete',
    id: 'accessSettingsDataGridTableRowActionsDelete',
  },
  AccessSettingsDataGridTableRowActionsEdit: {
    defaultMessage: 'Edit access group',
    description: 'Table row actions - edit',
    id: 'accessSettingsDataGridTableRowActionsEdit',
  },
});
