import { defineMessages } from 'react-intl';

export default defineMessages({
  AccessSettingsDetailDeleteButtonLabel: {
    defaultMessage: 'Delete access group',
    description: 'Access Settings - delete button label',
    id: 'accessSettingsDetailDeleteButtonLabel',
  },
  AccessSettingsDetailTabApartments: {
    defaultMessage: 'Apartments',
    description: 'Access Settings - apartments tab label',
    id: 'accessSettingsDetailTabApartments',
  },
  AccessSettingsDetailTabDevices: {
    defaultMessage: 'Devices',
    description: 'Access Settings - devices tab label',
    id: 'accessSettingsDetailTabDevices',
  },
  AccessSettingsDetailTabGroupDetail: {
    defaultMessage: 'Access group detail',
    description: 'Access Settings - group detail tab label',
    id: 'accessSettingsDetailTabGroupDetail',
  },
  AccessSettingsDetailTabUsers: {
    defaultMessage: 'Users',
    description: 'Access Settings - users tab label',
    id: 'accessSettingsDetailTabUsers',
  },
});
