import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  collapseHeader: {
    backgroundColor: 'primary.main',
  },
  collapseText: {
    color: 'grey.50',
    pl: 1,
  },
  icon: {
    color: 'grey.50',
  },
  unCollapseHeader: {
    px: 0,
    py: 1,
  },
};

const SectionHeader = ({ children, headerText, onCollapse, open, variant }) => (
  <Box py={1}>
    {onCollapse ? (
      <Box sx={styles.collapseHeader}>
        <Grid alignItems="center" container direction="row" justifyContent="center">
          <Grid item xs>
            <Typography sx={styles.collapseText} variant={variant}>
              {headerText}
            </Typography>
          </Grid>
          <Grid item xs>
            {children}
          </Grid>
          <Grid item xs={1}>
            <Grid alignItems="center" container direction="row" justifyContent="flex-end">
              <Grid item>
                <Box px={1}>
                  <IconButton onClick={onCollapse}>
                    {open ? <ExpandLess sx={styles.icon} /> : <ExpandMore sx={styles.icon} />}
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    ) : (
      <>
        <Grid alignItems="center" container direction="row" justifyContent="center">
          <Grid item xs>
            <Typography noWrap sx={styles.unCollapseHeader} variant={variant}>
              {headerText}
            </Typography>
          </Grid>
          <Grid item xs>
            {children}
          </Grid>
        </Grid>
        <Divider />
      </>
    )}
  </Box>
);

SectionHeader.propTypes = {
  children: PropTypes.node,
  headerText: PropTypes.element.isRequired,
  onCollapse: PropTypes.func,
  open: PropTypes.bool,
  variant: PropTypes.oneOf([
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'subtitle1',
    'subtitle2',
    'body1',
    'body2',
    'caption',
    'button',
    'overline',
    'inherit',
  ]),
};

SectionHeader.defaultProps = {
  children: null,
  onCollapse: null,
  open: false,
  variant: 'subtitle1',
};

export default SectionHeader;
