import React from 'react';
import { FormattedMessage } from 'react-intl';
import NoRowsOverlayDataGrid from '../../../components/DataGrid/NoRowsOverlay';
import messages from '../messages';

const NoRowsOverlay = () => (
  <NoRowsOverlayDataGrid
    headerText={<FormattedMessage {...messages.callSettingsDataGridNoRowsHeader} />}
    text={<FormattedMessage {...messages.callSettingsDataGridNoRowsText} />}
  />
);

export default NoRowsOverlay;
