import { MessageDescriptor } from 'react-intl';
import * as yup from 'yup';
import validationMessage from '../../validations/messages';
import { NameMaxLength } from '../../validations/validationConstants';

export interface IApartmentAdminPinModalFormData {
  name: string;
}

export enum FormFieldNames {
  Name = 'name',
}

export const defaultValues: IApartmentAdminPinModalFormData = {
  name: '',
};

export function getValidationSchema(formatMessage: (message: MessageDescriptor) => string) {
  return yup.object().shape({
    [FormFieldNames.Name]: yup
      .string()
      .required(formatMessage(validationMessage.validationRequired))
      .max(NameMaxLength, formatMessage(validationMessage.validationMaxLength)),
  });
}
