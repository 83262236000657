import List from '@mui/material/List';
import React from 'react';
import ButtonListHeader from './ButtonListHeader';
import ButtonListItem from './ButtonListItem';
import { IButtonRenderData, ICallSettingOption } from './hooks/useGetStateData';

export interface IButtonListData {
  list: IButtonRenderData[];
  options: ICallSettingOption[];
  isDeviceDetailEditing: boolean;
  ringingGroupSizeLimit: number;
  onChangeItem: (buttonRenderData: IButtonRenderData, dataUpdate?: ICallSettingOption[]) => void;
  onChangeButtonName: (buttonRenderData: IButtonRenderData, newName: string) => void;
  setDeviceEditing: (value: boolean) => void;
}

const ButtonList = (listData: IButtonListData) => (
  <>
    <ButtonListHeader />
    <List>
      {listData.list.map((item) => (
        <ButtonListItem
          buttonRenderData={item}
          isDeviceDetailEditing={listData.isDeviceDetailEditing}
          key={item.index}
          onChangeButtonName={listData.onChangeButtonName}
          onChangeItem={listData.onChangeItem}
          options={listData.options}
          ringingGroupSizeLimit={listData.ringingGroupSizeLimit}
          setDeviceEditing={listData.setDeviceEditing}
        />
      ))}
    </List>
  </>
);

export default ButtonList;
