import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MOBILE_VIDEO_STATUS } from '../../config/consts';
import { getCountMobileVideoFreeDevices } from '../../helpers/dashboard';
import MobileVideoStatus from '../MobileVideoStatus';
import { getStyles } from '../MobileVideoStatus/helpers';
import messages from './messages';

const MobileVideoCardStatus = ({ devices, licenceModel, mvService }) => {
  const isCorrectStatus = () =>
    licenceModel?.status === MOBILE_VIDEO_STATUS.STANDARD_RENEWAL ||
    licenceModel?.status === MOBILE_VIDEO_STATUS.STANDARD ||
    licenceModel?.status === MOBILE_VIDEO_STATUS.TEMPORARY ||
    licenceModel?.status === MOBILE_VIDEO_STATUS.TRIAL_ACTIVE;

  const countFreeDevices = getCountMobileVideoFreeDevices(devices).count;

  const onlyFreeDevicesWork = () => {
    if (countFreeDevices !== 0) {
      if (licenceModel?.requirePaymentCount === 0) {
        return true;
      }
      if (
        licenceModel?.requirePaymentCount > 0 &&
        licenceModel?.unlicensedCount > 0 &&
        licenceModel?.unlicensedCount === licenceModel?.requirePaymentCount
      ) {
        return true;
      }
    }
    return false;
  };

  return onlyFreeDevicesWork() && isCorrectStatus() ? (
    <Box color="success.main">
      <FormattedMessage
        values={{
          span: (info) => <span style={{ fontSize: '1.2rem', fontWeight: 400 }}>{info}</span>,
          status: (chunks) => <span style={{ fontWeight: 'bold' }}>{chunks}</span>,
        }}
        {...messages.mobileVideoCardActiveForFreeDevicesStatus}
      />
    </Box>
  ) : (
    <Box color={`${getStyles(false, licenceModel, mvService?.subscribed || false)}.main`}>
      <MobileVideoStatus
        dateStyles={{
          color: '#00000089',
          display: 'flex',
          fontSize: '14px',
          fontWeight: 400,
        }}
        licenceModel={licenceModel}
        statusStyles={{ fontWeight: 'bold' }}
        subscribed={mvService?.subscribed || false}
      />
    </Box>
  );
};

MobileVideoCardStatus.propTypes = {
  devices: PropTypes.arrayOf(PropTypes.object).isRequired,
  licenceModel: PropTypes.shape({
    additional: PropTypes.number,
    licencesPool: PropTypes.number,
    requirePaymentCount: PropTypes.number,
    status: PropTypes.string,
    unlicensedCount: PropTypes.number,
    validityTo: PropTypes.string,
  }).isRequired,
  mvService: PropTypes.shape({
    subscribed: PropTypes.bool,
  }).isRequired,
};

export default MobileVideoCardStatus;
