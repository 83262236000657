import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { PATHS } from '../../../config/consts';
import { useGetLoadedParams } from '../../../hooks/paramsHooks';
import { ApplicationContext } from '../../../modules/commonTypes';
import { replaceURLParams } from '../../../routes/helpers/pathParamsReplacer';

export function useGetGlobalHooks() {
  const location = useLocation();
  const { companyId, siteId } = useGetLoadedParams(ApplicationContext.Site);
  const { formatMessage } = useIntl();

  const siteSettingsAccess = replaceURLParams({
    params: { companyId, siteId },
    url: PATHS.SITE_SETTINGS_ACCESS,
  });

  const elevatorIntegration = replaceURLParams({
    params: { companyId, siteId },
    url: PATHS.SITE_SETTINGS_ELEVATOR_INTEGRATION,
  });

  const siteLanguage = replaceURLParams({
    params: { companyId, siteId },
    url: PATHS.SITE_SETTINGS_SITE_LANGUAGE,
  });

  return {
    companyId: companyId.toString(),
    elevatorIntegration,
    formatMessage,
    location,
    siteId: siteId.toString(),
    siteLanguage,
    siteSettingsAccess,
  };
}
