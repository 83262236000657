import { defineMessages } from 'react-intl';

export default defineMessages({
  apartmentsOverviewAddApartmentMobile: {
    defaultMessage: 'Apartment',
    description: 'Apartments - create apartment for mobile devices',
    id: 'apartmentsOverview.addApartmentMobile',
  },
  apartmentsOverviewCreateApartment: {
    defaultMessage: 'Create apartment',
    description: 'Apartments - create apartment',
    id: 'apartmentsOverview.createApartment',
  },
  apartmentsOverviewTitle: {
    defaultMessage: 'Apartments',
    description: 'Apartments - title',
    id: 'apartmentsOverview.title',
  },
  apartmentsOverviewWarning: {
    defaultMessage:
      'Sites with a large number of living units are best managed as' +
      ' apartment buildings where you pay per apartment. Each device needs' +
      ' to be assigned to a corresponding apartment with a limit of' +
      ' {limit} paid devices (phones, tablets, and 3rd party devices) per apartment.',
    id: 'apartmentsOverview.warning',
  },
  apartmentsOverviewWith2NIntercomUnusedLicensesMoreThanOne: {
    defaultMessage:
      'You have {count} unused licenses (including 1 free with a 2N intercom)' +
      'that you can use to activate paid services for apartments.',
    id: 'apartmentsOverview.with2Nintercom.unusedLicenses.moreThanOne',
  },
  apartmentsOverviewWith2NIntercomUnusedLicensesOne: {
    defaultMessage:
      'You have 1 unused license, free with a 2N intercom,' +
      'that you can use to activate paid services for an apartment.',
    id: 'apartmentsOverview.with2Nintercom.unusedLicenses.one',
  },
  apartmentsOverviewWithout2NIntercomUnusedLicensesMoreThanOne: {
    defaultMessage: 'You have {count} unused licenses' + 'that you can use to activate paid services for apartments.',
    id: 'apartmentsOverview.without2Nintercom.unusedLicenses.moreThanOne',
  },
  apartmentsOverviewWithout2NIntercomUnusedLicensesOne: {
    defaultMessage: 'You have 1 unused license that you can' + 'use to activate paid services for an apartment.',
    id: 'apartmentsOverview.without2Nintercom.unusedLicenses.one',
  },
});
