import React, { createRef } from 'react';
import { HeaderPublic } from '../../components';
import Footer from '../HomePage/Footer';
import AllYouNeed from './AllYouNeed';
import Apps from './Apps';
import Features from './Features';
import HowToStartUsing from './HowToStartUsing';
import Integration3rdParty from './Integration3rdParty';
import JustFourSteps from './JustFourSteps';
import LandingHeader from './LandingHeader';
import LandingInfo from './LandingInfo';
import RealTimeSynchronization from './RealTimeSynchronization';
import SecuredCloud from './SecuredCloud';
import Service from './Service';

const MVideo = () => {
  const refRealtimeSynchronization = createRef();
  const refSecureCloudService = createRef();
  const ref3thParty = createRef();
  const refIntercomAndMobilePhone = createRef();
  return (
    <>
      <HeaderPublic />
      <LandingHeader />
      <Features />
      <LandingInfo
        ref3thParty={ref3thParty}
        refIntercomAndMobilePhone={refIntercomAndMobilePhone}
        refRealtimeSynchronization={refRealtimeSynchronization}
        refSecureCloudService={refSecureCloudService}
      />
      <SecuredCloud ref={refSecureCloudService} />
      <RealTimeSynchronization ref={refRealtimeSynchronization} />
      <AllYouNeed ref={refIntercomAndMobilePhone} />
      <Integration3rdParty ref={ref3thParty} />
      <HowToStartUsing />
      <JustFourSteps />
      <Service />
      <Apps />
      <Footer />
    </>
  );
};

export default MVideo;
