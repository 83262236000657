import Box from '@mui/material/Box';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  AccessLogSwitch,
  DeviceAccessGroupsModal,
  DeviceAccessSettings,
  DeviceElevatorIntegrationSettings,
  Paper,
} from '../../../components';
import { useHasFeature } from '../../../components/Feature/Feature';
import featureFlags from '../../../config/featureFlags';
import { selectors as elevatorIntegrationSelectors } from '../../../modules/elevatorIntegration';

const AccessSettings = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isElevatorIntegrationFFEnabled = useHasFeature(featureFlags.ELEVATOR_INTEGRATION);
  const hasSiteElevatorIntegration = useSelector(elevatorIntegrationSelectors.isSiteElevatorIntegrationActive);

  return (
    <Paper>
      <DeviceAccessSettings buttonAction={() => setIsModalOpen(true)} />
      <Box pb={5} pt={3}>
        <AccessLogSwitch />
        {isElevatorIntegrationFFEnabled && hasSiteElevatorIntegration && <DeviceElevatorIntegrationSettings />}
      </Box>
      <DeviceAccessGroupsModal onClose={() => setIsModalOpen(false)} open={isModalOpen} />
    </Paper>
  );
};

export default AccessSettings;
