import { defineMessages } from 'react-intl';

export default defineMessages({
  AAModalGrantRightsAlert: {
    defaultMessage: 'This feature is currently in beta version',
    description: 'Grant apartment admin rights modal - alert',
    id: 'AA.modal.grantRights.alert',
  },
  AAModalGrantRightsCancel: {
    defaultMessage: 'Cancel',
    description: 'Grant apartment admin rights modal - cancel button',
    id: 'AA.modal.grantRights.cancel',
  },
  AAModalGrantRightsSubmit: {
    defaultMessage: 'Grant',
    description: 'Grant apartment admin rights modal - submit button',
    id: 'AA.modal.grantRights.submit',
  },
  AAModalGrantRightsText1: {
    defaultMessage: 'Apartment admin rights are granted to:',
    description: 'Grant apartment admin rights modal - header',
    id: 'AA.modal.grantRights.text1',
  },
  AAModalGrantRightsText2: {
    defaultMessage: 'User will be notified via email.',
    description: 'Grant apartment admin rights modal - footer',
    id: 'AA.modal.grantRights.text2',
  },
  AAModalGrantRightsTitle: {
    defaultMessage: 'Grant apartment admin rights',
    description: 'Grant apartment admin rights modal - title',
    id: 'AA.modal.grantRights.title',
  },
});
