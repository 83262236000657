import { defineMessages } from 'react-intl';

export default defineMessages({
  callStatusConfigurationNeeded: {
    defaultMessage: 'Needs configuration',
    description: 'Call Status - Needs configuration',
    id: 'callStatus.CONFIGURATION_NEEDED',
  },
  callStatusCredentialsNeeded: {
    defaultMessage: 'Credentials needed',
    description: 'Call Status - Credentials needed',
    id: 'callStatus.CREDENTIALS_NEEDED',
  },
  callStatusLocal: {
    defaultMessage: 'Device is local',
    description: 'Call Status - Device is local',
    id: 'callStatus.LOCAL',
  },
  callStatusMissingCallSettings: {
    defaultMessage: 'Missing call settings',
    description: 'Call Status - Missing call settings',
    id: 'callStatus.MISSING_CALL_SETTINGS',
  },
  callStatusNotRegistered: {
    defaultMessage: 'Not registered',
    description: 'Call Status - Not registered',
    id: 'callStatus.NOT_REGISTERED',
  },
  callStatusReady: {
    defaultMessage: 'Ready',
    description: 'Call Status - Ready',
    id: 'callStatus.READY',
  },
  callStatusRegistered: {
    defaultMessage: 'Registered',
    description: 'Call Status - Registered',
    id: 'callStatus.REGISTERED',
  },
  callStatusRegistering: {
    defaultMessage: 'Registering',
    description: 'Call Status - Registering',
    id: 'callStatus.REGISTERING',
  },
  callStatusSynchronizationFailed: {
    defaultMessage: 'Synchronization failed',
    description: 'Call Status - Synchronization failed',
    id: 'callStatus.SYNCHRONIZATION_FAILED',
  },
  callStatusSynchronizing: {
    defaultMessage: 'Synchronizing',
    description: 'Call Status - Synchronizing',
    id: 'callStatus.SYNCHRONIZING',
  },
  callStatusUnknown: {
    defaultMessage: 'Unknown',
    description: 'Call Status - unknown',
    id: 'callStatus.unknown',
  },
  callStatusUnlicensed: {
    defaultMessage: 'Unlicensed',
    description: 'Call Status - Unlicensed',
    id: 'callStatus.UNLICENSED',
  },
  callStatusUnreachable: {
    defaultMessage: 'Synchronization failed',
    description: 'Call Status - Unreachable',
    id: 'callStatus.UNREACHABLE',
  },
  callStatusWaiting: {
    defaultMessage: 'Waiting for response',
    description: 'Call Status - Waiting for response',
    id: 'callStatus.WAITING',
  },
});
