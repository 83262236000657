import userTypes from '../user/types';
import types from './types';

export const initialState = {
  accessControlApartments: {
    data: null,
    error: null,
    isFetching: false,
  },
  accessControlDevices: {
    data: null,
    error: null,
    isFetching: false,
  },
  accessControlSettings: {
    data: null,
    error: null,
    isFetching: false,
  },
  accessControlUsers: {
    data: null,
    error: null,
    isFetching: false,
  },
  apartment: {
    add: {
      error: null,
      isFetching: false,
    },
    createGroup: {
      error: null,
      isFetching: false,
    },
    data: null,
    didInvalid: false,
    error: null,
    isFetching: false,
  },
  device: {
    add: {
      error: null,
      isFetching: false,
    },
    createGroup: {
      error: null,
      isFetching: false,
    },
    data: null,
    didInvalid: false,
    error: null,
    isFetching: false,
  },
  groupCreate: {
    error: null,
    isFetching: false,
  },
  groupDelete: {
    error: null,
    isFetching: false,
  },
  groupDetail: {
    apartments: {
      add: {
        error: null,
        isFetching: false,
      },
      data: null,
      didInvalid: false,
      error: null,
      isFetching: false,
      listAll: {
        data: null,
        error: null,
        isFetching: false,
      },
      order: 'number,name',
      page: 1,
      remove: {
        error: null,
        isFetching: false,
      },
      resultsFiltered: 0,
      resultsTotal: 0,
      rowsPerPage: 10,
    },
    data: null,
    devices: {
      add: {
        error: null,
        isFetching: false,
      },
      data: null,
      didInvalid: false,
      error: null,
      isFetching: false,
      listAll: {
        data: null,
        error: null,
        isFetching: false,
      },
      logSettings: {
        error: null,
        isFetching: false,
      },
      order: 'deviceName',
      page: 1,
      racActivate: {
        error: null,
        isFetching: false,
      },
      racDeactivate: {
        error: null,
        isFetching: false,
      },
      remove: {
        error: null,
        isFetching: false,
      },
      resultsFiltered: 0,
      resultsTotal: 0,
      rowsPerPage: 10,
    },
    didInvalid: false,
    error: null,
    isFetching: false,
    users: {
      add: {
        error: null,
        isFetching: false,
      },
      create: {
        error: null,
        isFetching: false,
      },
      data: null,
      didInvalid: false,
      error: null,
      isFetching: false,
      listAll: {
        data: null,
        error: null,
        isFetching: false,
      },
      order: 'lastName',
      page: 1,
      remove: {
        error: null,
        isFetching: false,
      },
      resultsFiltered: 0,
      resultsTotal: 0,
      rowsPerPage: 10,
    },
  },
  groupDetailUpdate: {
    error: null,
    isFetching: false,
  },
  groupsList: {
    data: null,
    didInvalid: false,
    error: null,
    isFetching: false,
    listAll: {
      data: null,
      error: null,
      isFetching: false,
    },
    order: 'name',
    page: 1,
    resultsFiltered: 0,
    resultsTotal: 0,
    rowsPerPage: 10,
  },
  user: {
    add: {
      error: null,
      isFetching: false,
    },
    apartmentsGroups: {
      data: null,
      error: null,
      isFetching: false,
    },
    createGroup: {
      error: null,
      isFetching: false,
    },
    data: null,
    didInvalid: false,
    error: null,
    isFetching: false,
  },
};

const reducers = {
  [userTypes.SAVE_USER_PIN_SUCCESS]: (state, { context }) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      users: {
        ...state.groupDetail.users,
        didInvalid: context === 'ACCESS_SETTINGS_USERS_LIST',
      },
    },
  }),

  [userTypes.CREATE_USER_RFID_SUCCESS]: (state) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      users: {
        ...state.groupDetail.users,
        didInvalid: true,
      },
    },
  }),

  [userTypes.ACTIVATE_USER_MY2N_APP_ACCESS_SUCCESS]: (state, { context }) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      users: {
        ...state.groupDetail.users,
        didInvalid: context === 'ACCESS_SETTINGS_USERS_LIST',
      },
    },
  }),

  [types.CREATE_ACCESS_GROUP_FAILURE]: (state, { error }) => ({
    ...state,
    groupCreate: {
      error,
      isFetching: false,
    },
  }),

  [types.CREATE_ACCESS_GROUP_REQUEST]: (state) => ({
    ...state,
    groupCreate: {
      ...initialState.groupCreate,
      isFetching: true,
    },
  }),

  [types.CREATE_ACCESS_GROUP_SUCCESS]: (state) => ({
    ...state,
    groupCreate: {
      ...initialState.groupCreate,
    },
    groupsList: {
      ...initialState.groupsList,
      didInvalid: true,
    },
  }),

  [types.DELETE_ACCESS_GROUP_FAILURE]: (state, { error }) => ({
    ...state,
    groupDelete: {
      error,
      isFetching: false,
    },
  }),

  [types.DELETE_ACCESS_GROUP_REQUEST]: (state) => ({
    ...state,
    groupDelete: {
      ...initialState.groupDelete,
      isFetching: true,
    },
  }),

  [types.DELETE_ACCESS_GROUP_SUCCESS]: (state) => ({
    ...state,
    groupDelete: {
      ...initialState.groupDelete,
    },
    groupsList: {
      ...initialState.groupsList,
      didInvalid: true,
    },
  }),

  [types.GET_ACCESS_GROUP_FAILURE]: (state, { error }) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      error,
      isFetching: false,
    },
  }),

  [types.GET_ACCESS_GROUP_REQUEST]: (state) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      isFetching: true,
    },
  }),

  [types.GET_ACCESS_GROUP_SUCCESS]: (state, { payload }) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      data: payload,
      didInvalid: false,
      error: null,
      isFetching: false,
    },
  }),

  [types.UPDATE_ACCESS_GROUP_FAILURE]: (state, { error }) => ({
    ...state,
    groupDetailUpdate: {
      ...state.groupDetailUpdate,
      error,
      isFetching: false,
    },
  }),

  [types.UPDATE_ACCESS_GROUP_REQUEST]: (state) => ({
    ...state,
    groupDetailUpdate: {
      ...state.groupDetailUpdate,
      isFetching: true,
    },
  }),

  [types.UPDATE_ACCESS_GROUP_SUCCESS]: (state) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      didInvalid: true,
    },
    groupDetailUpdate: {
      ...initialState.groupDetailUpdate,
    },
  }),

  [types.GET_ACCESS_CONTROL_APARTMENTS_FAILURE]: (state, { error }) => ({
    ...state,
    accessControlApartments: {
      ...initialState.accessControlApartments,
      error,
      isFetching: false,
    },
  }),

  [types.GET_ACCESS_CONTROL_APARTMENTS_REQUEST]: (state) => ({
    ...state,
    accessControlApartments: {
      ...state.accessControlApartments,
      isFetching: true,
    },
  }),

  [types.GET_ACCESS_CONTROL_APARTMENTS_SUCCESS]: (state, { payload }) => ({
    ...state,
    accessControlApartments: {
      ...initialState.accessControlApartments,
      data: payload.results,
    },
  }),

  [types.GET_ACCESS_CONTROL_DEVICES_FAILURE]: (state, { error }) => ({
    ...state,
    accessControlDevices: {
      ...initialState.accessControlDevices,
      error,
      isFetching: false,
    },
  }),

  [types.GET_ACCESS_CONTROL_DEVICES_REQUEST]: (state) => ({
    ...state,
    accessControlDevices: {
      ...state.accessControlDevices,
      isFetching: true,
    },
  }),

  [types.GET_ACCESS_CONTROL_DEVICES_SUCCESS]: (state, { payload }) => ({
    ...state,
    accessControlDevices: {
      ...initialState.accessControlDevices,
      data: payload.results,
    },
  }),

  [types.GET_ACCESS_CONTROL_SETTINGS_FAILURE]: (state, { error }) => ({
    ...state,
    accessControlSettings: {
      ...state.accessControlSettings,
      error,
      isFetching: false,
    },
  }),

  [types.GET_ACCESS_CONTROL_SETTINGS_REQUEST]: (state) => ({
    ...state,
    accessControlSettings: {
      ...state.accessControlSettings,
      isFetching: true,
    },
  }),

  [types.GET_ACCESS_CONTROL_SETTINGS_SUCCESS]: (state, { payload }) => ({
    ...state,
    accessControlSettings: {
      ...initialState.accessControlSettings,
      data: payload,
    },
  }),

  [types.SET_ACCESS_CONTROL_LOG_SETTINGS_FAILURE]: (state, { error }) => ({
    ...state,
    accessControlSettings: {
      ...state.accessControlSettings,
      error,
      isFetching: false,
    },
  }),

  [types.SET_ACCESS_CONTROL_LOG_SETTINGS_REQUEST]: (state) => ({
    ...state,
    accessControlSettings: {
      ...state.accessControlSettings,
      error: null,
      isFetching: true,
    },
  }),

  [types.SET_ACCESS_CONTROL_LOG_SETTINGS_SUCCESS]: (state, { payload }) => ({
    ...state,
    accessControlSettings: {
      ...initialState.accessControlSettings,
      data: payload,
    },
  }),

  [types.SET_ACCESS_CONTROL_SETTINGS_FAILURE]: (state, { error }) => ({
    ...state,
    accessControlSettings: {
      ...state.accessControlSettings,
      error,
      isFetching: false,
    },
  }),

  [types.SET_ACCESS_CONTROL_SETTINGS_REQUEST]: (state) => ({
    ...state,
    accessControlSettings: {
      ...state.accessControlSettings,
      error: null,
      isFetching: true,
    },
  }),

  [types.SET_ACCESS_CONTROL_SETTINGS_SUCCESS]: (state, { payload }) => ({
    ...state,
    accessControlSettings: {
      ...initialState.accessControlSettings,
      data: payload,
    },
  }),

  [types.GET_ACCESS_CONTROL_USERS_FAILURE]: (state, { error }) => ({
    ...state,
    accessControlUsers: {
      ...initialState.accessControlUsers,
      error,
      isFetching: false,
    },
  }),

  [types.GET_ACCESS_CONTROL_USERS_REQUEST]: (state) => ({
    ...state,
    accessControlUsers: {
      ...state.accessControlUsers,
      isFetching: true,
    },
  }),

  [types.GET_ACCESS_CONTROL_USERS_SUCCESS]: (state, { payload }) => ({
    ...state,
    accessControlUsers: {
      ...initialState.accessControlUsers,
      data: payload,
    },
  }),

  [types.ADD_ACCESS_GROUP_APARTMENTS_FAILURE]: (state, { error }) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      apartments: {
        ...state.groupDetail.apartments,
        add: {
          ...initialState.groupDetail.apartments.add,
          error,
        },
      },
    },
  }),

  [types.ADD_ACCESS_GROUP_APARTMENTS_REQUEST]: (state) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      apartments: {
        ...state.groupDetail.apartments,
        add: {
          ...initialState.groupDetail.apartments.add,
          isFetching: true,
        },
      },
    },
  }),

  [types.ADD_ACCESS_GROUP_APARTMENTS_SUCCESS]: (state) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      apartments: {
        ...initialState.groupDetail.apartments,
        add: {
          ...initialState.groupDetail.apartments.add,
        },
        didInvalid: true,
      },
      didInvalid: true,
    },
    groupsList: {
      ...state.groupsList,
      didInvalid: true,
    },
  }),

  [types.REMOVE_ACCESS_GROUP_APARTMENT_FAILURE]: (state, { error }) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      apartments: {
        ...state.groupDetail.apartments,
        remove: {
          ...initialState.groupDetail.apartments.remove,
          error,
        },
      },
    },
  }),

  [types.REMOVE_ACCESS_GROUP_APARTMENT_REQUEST]: (state) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      apartments: {
        ...state.groupDetail.apartments,
        remove: {
          ...initialState.groupDetail.apartments.remove,
          isFetching: true,
        },
      },
    },
  }),

  [types.REMOVE_ACCESS_GROUP_APARTMENT_SUCCESS]: (state) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      apartments: {
        ...initialState.groupDetail.apartments,
        didInvalid: true,
        remove: {
          ...initialState.groupDetail.apartments.remove,
        },
      },
      didInvalid: true,
    },
  }),

  [types.GET_ACCESS_GROUP_APARTMENTS_ALL_FAILURE]: (state, { error }) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      apartments: {
        ...state.groupDetail.apartments,
        listAll: {
          ...initialState.groupDetail.apartments.listAll,
          error,
          isFetching: false,
        },
      },
    },
  }),

  [types.GET_ACCESS_GROUP_APARTMENTS_ALL_REQUEST]: (state) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      apartments: {
        ...state.groupDetail.apartments,
        listAll: {
          ...state.groupDetail.apartments.listAll,
          isFetching: true,
        },
      },
    },
  }),

  [types.GET_ACCESS_GROUP_APARTMENTS_ALL_SUCCESS]: (state, { payload }) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      apartments: {
        ...state.groupDetail.apartments,
        listAll: {
          ...initialState.groupDetail.apartments.listAll,
          data: payload.results,
          isFetching: false,
        },
      },
    },
  }),

  [types.GET_ACCESS_GROUP_APARTMENTS_FAILURE]: (state, { error }) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      apartments: {
        ...state.groupDetail.apartments,
        didInvalid: false,
        error,
        isFetching: false,
      },
    },
  }),

  [types.GET_ACCESS_GROUP_APARTMENTS_REQUEST]: (state) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      apartments: {
        ...state.groupDetail.apartments,
        isFetching: true,
      },
    },
  }),

  [types.GET_ACCESS_GROUP_APARTMENTS_SUCCESS]: (state, { payload, ...action }) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      apartments: {
        ...initialState.groupDetail.apartments,
        data: payload.results,
        order: action.order,
        page: action.page,
        resultsFiltered: payload.resultsTotal,
        resultsTotal: payload.resultsTotal,
        rowsPerPage: action.rowsPerPage,
      },
    },
  }),

  [types.GET_ACCESS_GROUP_DEVICES_ALL_FAILURE]: (state, { error }) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      devices: {
        ...state.groupDetail.devices,
        listAll: {
          ...initialState.groupDetail.devices.listAll,
          error,
          isFetching: false,
        },
      },
    },
  }),

  [types.GET_ACCESS_GROUP_DEVICES_ALL_REQUEST]: (state) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      devices: {
        ...state.groupDetail.devices,
        listAll: {
          ...state.groupDetail.devices.listAll,
          isFetching: true,
        },
      },
    },
  }),

  [types.GET_ACCESS_GROUP_DEVICES_ALL_SUCCESS]: (state, { payload }) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      devices: {
        ...state.groupDetail.devices,
        listAll: {
          ...initialState.groupDetail.devices.listAll,
          data: payload.results,
          isFetching: false,
        },
      },
    },
  }),

  [types.GET_ACCESS_GROUP_DEVICES_FAILURE]: (state, { error }) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      devices: {
        ...state.groupDetail.devices,
        didInvalid: false,
        error,
        isFetching: false,
      },
    },
  }),

  [types.GET_ACCESS_GROUP_DEVICES_REQUEST]: (state) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      devices: {
        ...state.groupDetail.devices,
        isFetching: true,
      },
    },
  }),

  [types.GET_ACCESS_GROUP_DEVICES_SUCCESS]: (state, { payload, ...action }) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      devices: {
        ...initialState.groupDetail.devices,
        data: payload.results,
        order: action.order,
        page: action.page,
        resultsFiltered: payload.resultsTotal,
        resultsTotal: payload.resultsTotal,
        rowsPerPage: action.rowsPerPage,
      },
    },
  }),

  [types.ADD_ACCESS_GROUP_DEVICE_FAILURE]: (state, { error }) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      devices: {
        ...state.groupDetail.devices,
        add: {
          ...initialState.groupDetail.devices.add,
          error,
        },
      },
    },
  }),

  [types.ADD_ACCESS_GROUP_DEVICE_REQUEST]: (state) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      devices: {
        ...state.groupDetail.devices,
        add: {
          ...initialState.groupDetail.devices.add,
          isFetching: true,
        },
      },
    },
  }),

  [types.ADD_ACCESS_GROUP_DEVICE_SUCCESS]: (state) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      devices: {
        ...initialState.groupDetail.devices,
        add: {
          ...initialState.groupDetail.devices.add,
        },
        didInvalid: true,
      },
      didInvalid: true,
    },
    groupsList: {
      ...state.groupsList,
      didInvalid: true,
    },
  }),

  [types.REMOVE_ACCESS_GROUP_DEVICE_FAILURE]: (state, { error }) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      devices: {
        ...state.groupDetail.devices,
        remove: {
          ...initialState.groupDetail.devices.remove,
          error,
        },
      },
    },
  }),

  [types.REMOVE_ACCESS_GROUP_DEVICE_REQUEST]: (state) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      devices: {
        ...state.groupDetail.devices,
        remove: {
          ...initialState.groupDetail.devices.remove,
          isFetching: true,
        },
      },
    },
  }),

  [types.REMOVE_ACCESS_GROUP_DEVICE_SUCCESS]: (state) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      devices: {
        ...initialState.groupDetail.devices,
        didInvalid: true,
        remove: {
          ...initialState.groupDetail.devices.remove,
        },
      },
      didInvalid: true,
    },
  }),

  [types.ACTIVATE_RAC_ACCESS_GROUP_DEVICE_FAILURE]: (state, { error }) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      devices: {
        ...state.groupDetail.devices,
        racActivate: {
          ...initialState.groupDetail.devices.racActivate,
          error,
        },
      },
    },
  }),

  [types.ACTIVATE_RAC_ACCESS_GROUP_DEVICE_REQUEST]: (state) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      devices: {
        ...state.groupDetail.devices,
        racActivate: {
          ...initialState.groupDetail.devices.racActivate,
          isFetching: true,
        },
      },
    },
  }),

  [types.ACTIVATE_RAC_ACCESS_GROUP_DEVICE_SUCCESS]: (state) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      devices: {
        ...initialState.groupDetail.devices,
        didInvalid: true,
        racActivate: {
          ...initialState.groupDetail.devices.racActivate,
        },
      },
      didInvalid: true,
    },
  }),

  [types.DEACTIVATE_RAC_ACCESS_GROUP_DEVICE_FAILURE]: (state, { error }) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      devices: {
        ...state.groupDetail.devices,
        racDeactivate: {
          ...initialState.groupDetail.devices.racDeactivate,
          error,
        },
      },
    },
  }),

  [types.DEACTIVATE_RAC_ACCESS_GROUP_DEVICE_REQUEST]: (state) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      devices: {
        ...state.groupDetail.devices,
        racDeactivate: {
          ...initialState.groupDetail.devices.racDeactivate,
          isFetching: true,
        },
      },
    },
  }),

  [types.DEACTIVATE_RAC_ACCESS_GROUP_DEVICE_SUCCESS]: (state) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      devices: {
        ...initialState.groupDetail.devices,
        didInvalid: true,
        racDeactivate: {
          ...initialState.groupDetail.devices.racDeactivate,
        },
      },
      didInvalid: true,
    },
  }),

  [types.SET_LOG_ACCESS_GROUP_DEVICE_FAILURE]: (state, { error }) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      devices: {
        ...state.groupDetail.devices,
        logSettings: {
          ...initialState.groupDetail.devices.logSettings,
          error,
        },
      },
    },
  }),

  [types.SET_LOG_ACCESS_GROUP_DEVICE_REQUEST]: (state) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      devices: {
        ...state.groupDetail.devices,
        logSettings: {
          ...initialState.groupDetail.devices.logSettings,
          isFetching: true,
        },
      },
    },
  }),

  [types.SET_LOG_ACCESS_GROUP_DEVICE_SUCCESS]: (state) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      devices: {
        ...initialState.groupDetail.devices,
        didInvalid: true,
        logSettings: {
          ...initialState.groupDetail.devices.logSettings,
        },
      },
      didInvalid: true,
    },
  }),

  [types.GET_ACCESS_GROUP_USERS_FAILURE]: (state, { error }) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      users: {
        ...state.groupDetail.users,
        didInvalid: false,
        error,
        isFetching: false,
      },
    },
  }),

  [types.GET_ACCESS_GROUP_USERS_REQUEST]: (state) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      users: {
        ...state.groupDetail.users,
        isFetching: true,
      },
    },
  }),

  [types.GET_ACCESS_GROUP_USERS_SUCCESS]: (state, { payload, ...action }) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      users: {
        ...initialState.groupDetail.users,
        data: payload.data,
        order: action.order,
        page: action.page,
        resultsFiltered: payload.resultsTotal,
        resultsTotal: payload.resultsTotal,
        rowsPerPage: action.rowsPerPage,
      },
    },
  }),

  [types.GET_ACCESS_GROUP_USERS_ALL_FAILURE]: (state, { error }) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      users: {
        ...state.groupDetail.users,
        listAll: {
          ...initialState.groupDetail.users.listAll,
          error,
          isFetching: false,
        },
      },
    },
  }),

  [types.GET_ACCESS_GROUP_USERS_ALL_REQUEST]: (state) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      users: {
        ...state.groupDetail.users,
        listAll: {
          ...state.groupDetail.users.listAll,
          isFetching: true,
        },
      },
    },
  }),

  [types.GET_ACCESS_GROUP_USERS_ALL_SUCCESS]: (state, { payload }) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      users: {
        ...state.groupDetail.users,
        listAll: {
          ...initialState.groupDetail.users.listAll,
          data: payload.groupUsers,
          isFetching: false,
        },
      },
    },
  }),

  [types.ADD_ACCESS_GROUP_USER_FAILURE]: (state, { error }) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      users: {
        ...state.groupDetail.users,
        add: {
          ...initialState.groupDetail.users.add,
          error,
        },
      },
    },
  }),

  [types.ADD_ACCESS_GROUP_USER_REQUEST]: (state) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      users: {
        ...state.groupDetail.users,
        add: {
          ...initialState.groupDetail.users.add,
          isFetching: true,
        },
      },
    },
  }),

  [types.ADD_ACCESS_GROUP_USER_SUCCESS]: (state) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      didInvalid: true,
      users: {
        ...initialState.groupDetail.users,
        add: {
          ...initialState.groupDetail.users.add,
        },
        didInvalid: true,
      },
    },
    groupsList: {
      ...state.groupsList,
      didInvalid: true,
    },
  }),

  [types.CREATE_ADD_ACCESS_GROUP_USER_FAILURE]: (state, { error }) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      users: {
        ...state.groupDetail.users,
        create: {
          ...initialState.groupDetail.users.create,
          error,
        },
      },
    },
  }),

  [types.CREATE_ADD_ACCESS_GROUP_USER_REQUEST]: (state) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      users: {
        ...state.groupDetail.users,
        create: {
          ...initialState.groupDetail.users.create,
          isFetching: true,
        },
      },
    },
  }),

  [types.CREATE_ADD_ACCESS_GROUP_USER_SUCCESS]: (state) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      didInvalid: true,
      users: {
        ...initialState.groupDetail.users,
        create: {
          ...initialState.groupDetail.users.create,
        },
        didInvalid: true,
      },
    },
  }),

  [types.REMOVE_ACCESS_GROUP_USER_FAILURE]: (state, { error }) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      users: {
        ...state.groupDetail.users,
        remove: {
          ...initialState.groupDetail.users.remove,
          error,
        },
      },
    },
  }),

  [types.REMOVE_ACCESS_GROUP_USER_REQUEST]: (state) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      users: {
        ...state.groupDetail.users,
        remove: {
          ...initialState.groupDetail.users.remove,
          isFetching: true,
        },
      },
    },
  }),

  [types.REMOVE_ACCESS_GROUP_USER_SUCCESS]: (state) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      didInvalid: true,
      users: {
        ...initialState.groupDetail.users,
        didInvalid: true,
        remove: {
          ...initialState.groupDetail.users.remove,
        },
      },
    },
  }),

  [types.GET_ACCESS_GROUPS_LIST_FAILURE]: (state, { error }) => ({
    ...state,
    groupsList: {
      ...state.groupsList,
      didInvalid: false,
      error,
      isFetching: false,
    },
  }),

  [types.GET_ACCESS_GROUPS_LIST_REQUEST]: (state) => ({
    ...state,
    groupsList: {
      ...state.groupsList,
      isFetching: true,
    },
  }),

  [types.GET_ACCESS_GROUPS_LIST_SUCCESS]: (state, { payload, ...action }) => ({
    ...state,
    groupsList: {
      ...initialState.groupsList,
      data: payload.results,
      order: action.order,
      page: action.page,
      resultsFiltered: payload.resultsTotal,
      resultsTotal: payload.resultsTotal,
      rowsPerPage: action.rowsPerPage,
    },
  }),

  [types.GET_ACCESS_GROUPS_LIST_ALL_FAILURE]: (state, { error }) => ({
    ...state,
    groupsList: {
      ...state.groupsList,
      listAll: {
        ...state.groupsList.listAll,
        error,
        isFetching: false,
      },
    },
  }),

  [types.GET_ACCESS_GROUPS_LIST_ALL_REQUEST]: (state) => ({
    ...state,
    groupsList: {
      ...state.groupsList,
      listAll: {
        ...state.groupsList.listAll,
        isFetching: true,
      },
    },
  }),

  [types.GET_ACCESS_GROUPS_LIST_ALL_SUCCESS]: (state, { payload }) => ({
    ...state,
    groupsList: {
      ...state.groupsList,
      listAll: {
        ...initialState.groupsList.listAll,
        data: payload,
      },
    },
  }),

  [types.GET_USER_ACCESS_GROUPS_LIST_FAILURE]: (state, { error }) => ({
    ...state,
    user: {
      ...state.user,
      didInvalid: false,
      error,
      isFetching: false,
    },
  }),

  [types.GET_USER_ACCESS_GROUPS_LIST_REQUEST]: (state) => ({
    ...state,
    user: {
      ...state.user,
      isFetching: true,
    },
  }),

  [types.GET_USER_ACCESS_GROUPS_LIST_SUCCESS]: (state, { payload }) => ({
    ...state,
    user: {
      ...state.user,
      data: payload,
      didInvalid: false,
      error: null,
      isFetching: false,
    },
  }),

  [types.GET_USER_APARTMENTS_ACCESS_GROUPS_LIST_FAILURE]: (state, { error }) => ({
    ...state,
    user: {
      ...state.user,
      apartmentsGroups: {
        ...state.user.apartmentsGroups,
        error,
        isFetching: false,
      },
    },
  }),

  [types.GET_USER_APARTMENTS_ACCESS_GROUPS_LIST_REQUEST]: (state) => ({
    ...state,
    user: {
      ...state.user,
      apartmentsGroups: {
        ...state.user.apartmentsGroups,
        isFetching: true,
      },
    },
  }),

  [types.GET_USER_APARTMENTS_ACCESS_GROUPS_LIST_SUCCESS]: (state, { payload }) => ({
    ...state,
    user: {
      ...state.user,
      apartmentsGroups: {
        ...initialState.user.apartmentsGroups,
        data: payload,
      },
    },
  }),

  [types.ADD_USER_ACCESS_GROUPS_FAILURE]: (state, { error }) => ({
    ...state,
    user: {
      ...state.user,
      add: {
        ...state.user.add,
        error,
        isFetching: false,
      },
    },
  }),

  [types.ADD_USER_ACCESS_GROUPS_REQUEST]: (state) => ({
    ...state,
    user: {
      ...state.user,
      add: {
        ...state.user.add,
        isFetching: true,
      },
    },
  }),

  [types.ADD_USER_ACCESS_GROUPS_SUCCESS]: (state) => ({
    ...state,
    user: {
      ...state.user,
      add: {
        ...initialState.user.add,
      },
    },
  }),

  [types.CREATE_ADD_USER_ACCESS_GROUPS_FAILURE]: (state, { error }) => ({
    ...state,
    user: {
      ...state.user,
      createGroup: {
        ...state.user.createGroup,
        error,
        isFetching: false,
      },
    },
  }),

  [types.CREATE_ADD_USER_ACCESS_GROUPS_REQUEST]: (state) => ({
    ...state,
    user: {
      ...state.user,
      createGroup: {
        ...state.user.createGroup,
        isFetching: true,
      },
    },
  }),

  [types.CREATE_ADD_USER_ACCESS_GROUPS_SUCCESS]: (state) => ({
    ...state,
    groupDetail: {
      ...state.groupDetail,
      didInvalid: true,
      users: {
        ...state.groupDetail.users,
        didInvalid: true,
      },
    },
    user: {
      ...state.user,
      createGroup: {
        ...initialState.user.createGroup,
      },
      didInvalid: true,
    },
  }),

  [types.GET_APARTMENT_ACCESS_GROUPS_LIST_FAILURE]: (state, { error }) => ({
    ...state,
    apartment: {
      ...state.apartment,
      didInvalid: false,
      error,
      isFetching: false,
    },
  }),

  [types.GET_APARTMENT_ACCESS_GROUPS_LIST_REQUEST]: (state) => ({
    ...state,
    apartment: {
      ...state.apartment,
      isFetching: true,
    },
  }),

  [types.GET_APARTMENT_ACCESS_GROUPS_LIST_SUCCESS]: (state, { payload }) => ({
    ...state,
    apartment: {
      ...initialState.apartment,
      data: payload,
    },
  }),

  [types.ADD_APARTMENT_ACCESS_GROUPS_FAILURE]: (state, { error }) => ({
    ...state,
    apartment: {
      ...state.apartment,
      add: {
        ...state.apartment.add,
        error,
        isFetching: false,
      },
    },
  }),

  [types.ADD_APARTMENT_ACCESS_GROUPS_REQUEST]: (state) => ({
    ...state,
    apartment: {
      ...state.apartment,
      add: {
        ...state.apartment.add,
        isFetching: true,
      },
    },
  }),

  [types.ADD_APARTMENT_ACCESS_GROUPS_SUCCESS]: (state) => ({
    ...state,
    apartment: {
      ...state.apartment,
      add: {
        ...initialState.apartment.add,
      },
    },
  }),

  [types.CREATE_ADD_APARTMENT_ACCESS_GROUPS_FAILURE]: (state, { error }) => ({
    ...state,
    apartment: {
      ...state.apartment,
      createGroup: {
        ...state.apartment.createGroup,
        error,
        isFetching: false,
      },
    },
  }),

  [types.CREATE_ADD_APARTMENT_ACCESS_GROUPS_REQUEST]: (state) => ({
    ...state,
    apartment: {
      ...state.apartment,
      createGroup: {
        ...state.apartment.createGroup,
        isFetching: true,
      },
    },
  }),

  [types.CREATE_ADD_APARTMENT_ACCESS_GROUPS_SUCCESS]: (state) => ({
    ...state,
    apartment: {
      ...state.apartment,
      createGroup: {
        ...initialState.apartment.createGroup,
      },
      didInvalid: true,
    },
    groupDetail: {
      ...state.groupDetail,
      apartments: {
        ...state.groupDetail.apartments,
        didInvalid: true,
      },
    },
  }),

  [types.GET_DEVICE_ACCESS_GROUPS_LIST_FAILURE]: (state, { error }) => ({
    ...state,
    device: {
      ...state.device,
      didInvalid: false,
      error,
      isFetching: false,
    },
  }),

  [types.GET_DEVICE_ACCESS_GROUPS_LIST_REQUEST]: (state) => ({
    ...state,
    device: {
      ...state.device,
      isFetching: true,
    },
  }),

  [types.GET_DEVICE_ACCESS_GROUPS_LIST_SUCCESS]: (state, { payload }) => ({
    ...state,
    device: {
      ...initialState.device,
      data: payload,
    },
  }),

  [types.ADD_DEVICE_ACCESS_GROUPS_FAILURE]: (state, { error }) => ({
    ...state,
    device: {
      ...state.device,
      add: {
        ...state.device.add,
        error,
        isFetching: false,
      },
    },
  }),

  [types.ADD_DEVICE_ACCESS_GROUPS_REQUEST]: (state) => ({
    ...state,
    device: {
      ...state.device,
      add: {
        ...state.device.add,
        isFetching: true,
      },
    },
  }),

  [types.ADD_DEVICE_ACCESS_GROUPS_SUCCESS]: (state) => ({
    ...state,
    device: {
      ...state.device,
      add: {
        ...initialState.device.add,
      },
    },
  }),

  [types.CREATE_ADD_DEVICE_ACCESS_GROUPS_FAILURE]: (state, { error }) => ({
    ...state,
    device: {
      ...state.device,
      createGroup: {
        ...state.device.createGroup,
        error,
        isFetching: false,
      },
    },
  }),

  [types.CREATE_ADD_DEVICE_ACCESS_GROUPS_REQUEST]: (state) => ({
    ...state,
    device: {
      ...state.device,
      createGroup: {
        ...state.device.createGroup,
        isFetching: true,
      },
    },
  }),

  [types.CREATE_ADD_DEVICE_ACCESS_GROUPS_SUCCESS]: (state) => ({
    ...state,
    device: {
      ...state.device,
      createGroup: {
        ...initialState.device.createGroup,
      },
      didInvalid: true,
    },
  }),
};

const reducer = (state = initialState, action = {}) => {
  if (reducers[action.type]) {
    return reducers[action.type](state, action);
  }
  return state;
};

export default reducer;
