import { call } from 'redux-saga/effects';
import * as Api from '../../../api/deviceApi';
import { CONNECTION_MODE, DEVICE_TYPE } from '../../../config/devices';
import { responseValidateStatusOrThrow } from '../../../modules/helpers';
import { getDevicesTypesSettings, getServices } from './helpers';

export default function* axisDoorStationSaga({ company, site, values }) {
  const settings = yield getDevicesTypesSettings(company.id, DEVICE_TYPE.DOOR_STATION);
  const services = yield getServices(settings, {
    ...values,
    connectionMode: CONNECTION_MODE.CLOUD,
    mobileVideo: true,
  });
  const response = yield call(Api.addDevice, company.id, site.id, {
    name: values.name,
    securityCode: values.macAddress,
    services,
    type: DEVICE_TYPE.DOOR_STATION,
  });
  yield responseValidateStatusOrThrow(response);
  return response.data || {};
}
