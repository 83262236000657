import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import messages from './messages';

function AddDeviceAutocomplete({ devices }) {
  const { control } = useFormContext();
  const { field } = useController({ control, name: 'device' });
  const { formatMessage } = useIntl();

  return (
    <Autocomplete
      {...field}
      autoHighlight
      fullWidth
      getOptionLabel={(option) => option?.deviceName || ''}
      id="access-group-devices-select"
      isOptionEqualToValue={(option, val) => isEqual(option, val)}
      onChange={(e, val) => field.onChange(val)}
      options={devices}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off',
          }}
          label={formatMessage(messages.accessGroupAddDeviceModalAddDeviceAutocompleteLabel)}
          placeholder={formatMessage(messages.accessGroupAddDeviceModalAddDeviceAutocompletePlaceholder)}
        />
      )}
      sx={{ py: 5 }}
    />
  );
}

AddDeviceAutocomplete.propTypes = {
  devices: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default AddDeviceAutocomplete;
