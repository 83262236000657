import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';

export const styles = {
  childrenWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
  },
  itemStyle: (theme) => ({
    [theme.breakpoints.down('xs')]: {
      paddingBottom: 0,
      paddingTop: 0,
      width: '100%',
    },
    padding: theme.spacing(1, 1.5, 1, 0),
  }),
};

function QRCodeItem({ children, qrCode }) {
  return (
    <Box sx={styles.itemStyle}>
      <Box sx={styles.container}>
        <Box sx={styles.itemStyle}>{qrCode}</Box>
        <Box sx={(theme) => ({ ...styles.itemStyle(theme), ...styles.childrenWrapper })}>{children}</Box>
      </Box>
    </Box>
  );
}

QRCodeItem.propTypes = {
  children: PropTypes.node.isRequired,
  qrCode: PropTypes.node.isRequired,
};

export default QRCodeItem;
