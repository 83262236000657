import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { PATHS } from '../../config/consts';
import ButtonLink from '../ButtonLink';
import messages from './messages';

const Footer = ({ companyId }) => (
  <Box p={2}>
    <ButtonLink color="primary" id="sites-link" size="small" to={PATHS.COMPANY_SITES.replace(':companyId', companyId)}>
      <FormattedMessage {...messages.footerViewAllSites} />
    </ButtonLink>
  </Box>
);

Footer.propTypes = {
  companyId: PropTypes.number.isRequired,
};

export default Footer;
