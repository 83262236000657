export const moduleName = 'accessSettings';

export default {
  ACTIVATE_RAC_ACCESS_GROUP_DEVICE_FAILURE: `${moduleName}/ACTIVATE_RAC_ACCESS_GROUP_DEVICE_FAILURE`,
  ACTIVATE_RAC_ACCESS_GROUP_DEVICE_REQUEST: `${moduleName}/ACTIVATE_RAC_ACCESS_GROUP_DEVICE_REQUEST`,
  ACTIVATE_RAC_ACCESS_GROUP_DEVICE_SUCCESS: `${moduleName}/ACTIVATE_RAC_ACCESS_GROUP_DEVICE_SUCCESS`,
  ADD_ACCESS_GROUP_APARTMENTS_FAILURE: `${moduleName}/ADD_ACCESS_GROUP_APARTMENTS_FAILURE`,
  ADD_ACCESS_GROUP_APARTMENTS_REQUEST: `${moduleName}/ADD_ACCESS_GROUP_APARTMENTS_REQUEST`,
  ADD_ACCESS_GROUP_APARTMENTS_SUCCESS: `${moduleName}/ADD_ACCESS_GROUP_APARTMENTS_SUCCESS`,
  ADD_ACCESS_GROUP_DEVICE_FAILURE: `${moduleName}/ADD_ACCESS_GROUP_DEVICE_FAILURE`,
  ADD_ACCESS_GROUP_DEVICE_REQUEST: `${moduleName}/ADD_ACCESS_GROUP_DEVICE_REQUEST`,
  ADD_ACCESS_GROUP_DEVICE_SUCCESS: `${moduleName}/ADD_ACCESS_GROUP_DEVICE_SUCCESS`,
  ADD_ACCESS_GROUP_USER_FAILURE: `${moduleName}/ADD_ACCESS_GROUP_USER_FAILURE`,
  ADD_ACCESS_GROUP_USER_REQUEST: `${moduleName}/ADD_ACCESS_GROUP_USER_REQUEST`,
  ADD_ACCESS_GROUP_USER_SUCCESS: `${moduleName}/ADD_ACCESS_GROUP_USER_SUCCESS`,
  ADD_ACCESS_GROUP_USERS_FAILURE: `${moduleName}/ADD_ACCESS_GROUP_USERS_FAILURE`,
  ADD_ACCESS_GROUP_USERS_REQUEST: `${moduleName}/ADD_ACCESS_GROUP_USERS_REQUEST`,
  ADD_ACCESS_GROUP_USERS_SUCCESS: `${moduleName}/ADD_ACCESS_GROUP_USERS_SUCCESS`,
  ADD_APARTMENT_ACCESS_GROUPS_FAILURE: `${moduleName}/ADD_APARTMENT_ACCESS_GROUPS_FAILURE`,
  ADD_APARTMENT_ACCESS_GROUPS_REQUEST: `${moduleName}/ADD_APARTMENT_ACCESS_GROUPS_REQUEST`,
  ADD_APARTMENT_ACCESS_GROUPS_SUCCESS: `${moduleName}/ADD_APARTMENT_ACCESS_GROUPS_SUCCESS`,
  ADD_DEVICE_ACCESS_GROUPS_FAILURE: `${moduleName}/ADD_DEVICE_ACCESS_GROUPS_FAILURE`,
  ADD_DEVICE_ACCESS_GROUPS_REQUEST: `${moduleName}/ADD_DEVICE_ACCESS_GROUPS_REQUEST`,
  ADD_DEVICE_ACCESS_GROUPS_SUCCESS: `${moduleName}/ADD_DEVICE_ACCESS_GROUPS_SUCCESS`,
  ADD_USER_ACCESS_GROUPS_FAILURE: `${moduleName}/ADD_USER_ACCESS_GROUPS_FAILURE`,
  ADD_USER_ACCESS_GROUPS_REQUEST: `${moduleName}/ADD_USER_ACCESS_GROUPS_REQUEST`,
  ADD_USER_ACCESS_GROUPS_SUCCESS: `${moduleName}/ADD_USER_ACCESS_GROUPS_SUCCESS`,
  CREATE_ACCESS_GROUP_FAILURE: `${moduleName}/CREATE_ACCESS_GROUP_FAILURE`,
  CREATE_ACCESS_GROUP_REQUEST: `${moduleName}/CREATE_ACCESS_GROUP_REQUEST`,
  CREATE_ACCESS_GROUP_SUCCESS: `${moduleName}/CREATE_ACCESS_GROUP_SUCCESS`,
  CREATE_ADD_ACCESS_GROUP_USER_FAILURE: `${moduleName}/CREATE_ADD_ACCESS_GROUP_USER_FAILURE`,
  CREATE_ADD_ACCESS_GROUP_USER_REQUEST: `${moduleName}/CREATE_ADD_ACCESS_GROUP_USER_REQUEST`,
  CREATE_ADD_ACCESS_GROUP_USER_SUCCESS: `${moduleName}/CREATE_ADD_ACCESS_GROUP_USER_SUCCESS`,
  CREATE_ADD_APARTMENT_ACCESS_GROUPS_FAILURE: `${moduleName}/CREATE_ADD_APARTMENT_ACCESS_GROUPS_FAILURE`,
  CREATE_ADD_APARTMENT_ACCESS_GROUPS_REQUEST: `${moduleName}/CREATE_ADD_APARTMENT_ACCESS_GROUPS_REQUEST`,
  CREATE_ADD_APARTMENT_ACCESS_GROUPS_SUCCESS: `${moduleName}/CREATE_ADD_APARTMENT_ACCESS_GROUPS_SUCCESS`,
  CREATE_ADD_DEVICE_ACCESS_GROUPS_FAILURE: `${moduleName}/CREATE_ADD_DEVICE_ACCESS_GROUPS_FAILURE`,
  CREATE_ADD_DEVICE_ACCESS_GROUPS_REQUEST: `${moduleName}/CREATE_ADD_DEVICE_ACCESS_GROUPS_REQUEST`,
  CREATE_ADD_DEVICE_ACCESS_GROUPS_SUCCESS: `${moduleName}/CREATE_ADD_DEVICE_ACCESS_GROUPS_SUCCESS`,
  CREATE_ADD_USER_ACCESS_GROUPS_FAILURE: `${moduleName}/CREATE_ADD_USER_ACCESS_GROUPS_FAILURE`,
  CREATE_ADD_USER_ACCESS_GROUPS_REQUEST: `${moduleName}/CREATE_ADD_USER_ACCESS_GROUPS_REQUEST`,
  CREATE_ADD_USER_ACCESS_GROUPS_SUCCESS: `${moduleName}/CREATE_ADD_USER_ACCESS_GROUPS_SUCCESS`,
  DEACTIVATE_RAC_ACCESS_GROUP_DEVICE_FAILURE: `${moduleName}/DEACTIVATE_RAC_ACCESS_GROUP_DEVICE_FAILURE`,
  DEACTIVATE_RAC_ACCESS_GROUP_DEVICE_REQUEST: `${moduleName}/DEACTIVATE_RAC_ACCESS_GROUP_DEVICE_REQUEST`,
  DEACTIVATE_RAC_ACCESS_GROUP_DEVICE_SUCCESS: `${moduleName}/DEACTIVATE_RAC_ACCESS_GROUP_DEVICE_SUCCESS`,
  DELETE_ACCESS_GROUP_DEVICE_SUCCESS: `${moduleName}/DELETE_ACCESS_GROUP_DEVICE_SUCCESS`,
  DELETE_ACCESS_GROUP_FAILURE: `${moduleName}/DELETE_ACCESS_GROUP_FAILURE`,
  DELETE_ACCESS_GROUP_REQUEST: `${moduleName}/DELETE_ACCESS_GROUP_REQUEST`,
  DELETE_ACCESS_GROUP_SUCCESS: `${moduleName}/DELETE_ACCESS_GROUP_SUCCESS`,
  GET_ACCESS_CONTROL_APARTMENTS_FAILURE: `${moduleName}/GET_ACCESS_CONTROL_APARTMENTS_FAILURE`,
  GET_ACCESS_CONTROL_APARTMENTS_REQUEST: `${moduleName}/GET_ACCESS_CONTROL_APARTMENTS_REQUEST`,
  GET_ACCESS_CONTROL_APARTMENTS_SUCCESS: `${moduleName}/GET_ACCESS_CONTROL_APARTMENTS_SUCCESS`,
  GET_ACCESS_CONTROL_DEVICES_FAILURE: `${moduleName}/GET_ACCESS_CONTROL_DEVICES_FAILURE`,
  GET_ACCESS_CONTROL_DEVICES_REQUEST: `${moduleName}/GET_ACCESS_CONTROL_DEVICES_REQUEST`,
  GET_ACCESS_CONTROL_DEVICES_SUCCESS: `${moduleName}/GET_ACCESS_CONTROL_DEVICES_SUCCESS`,
  GET_ACCESS_CONTROL_SETTINGS_FAILURE: `${moduleName}/GET_ACCESS_CONTROL_SETTINGS_FAILURE`,
  GET_ACCESS_CONTROL_SETTINGS_REQUEST: `${moduleName}/GET_ACCESS_CONTROL_SETTINGS_REQUEST`,
  GET_ACCESS_CONTROL_SETTINGS_SUCCESS: `${moduleName}/GET_ACCESS_CONTROL_SETTINGS_SUCCESS`,
  GET_ACCESS_CONTROL_USERS_FAILURE: `${moduleName}/GET_ACCESS_CONTROL_USERS_FAILURE`,
  GET_ACCESS_CONTROL_USERS_REQUEST: `${moduleName}/GET_ACCESS_CONTROL_USERS_REQUEST`,
  GET_ACCESS_CONTROL_USERS_SUCCESS: `${moduleName}/GET_ACCESS_CONTROL_USERS_SUCCESS`,
  GET_ACCESS_GROUP_APARTMENTS_ALL_FAILURE: `${moduleName}/GET_ACCESS_GROUP_APARTMENTS_ALL_FAILURE`,
  GET_ACCESS_GROUP_APARTMENTS_ALL_REQUEST: `${moduleName}/GET_ACCESS_GROUP_APARTMENTS_ALL_REQUEST`,
  GET_ACCESS_GROUP_APARTMENTS_ALL_SUCCESS: `${moduleName}/GET_ACCESS_GROUP_APARTMENTS_ALL_SUCCESS`,
  GET_ACCESS_GROUP_APARTMENTS_FAILURE: `${moduleName}/GET_ACCESS_GROUP_APARTMENTS_FAILURE`,
  GET_ACCESS_GROUP_APARTMENTS_REQUEST: `${moduleName}/GET_ACCESS_GROUP_APARTMENTS_REQUEST`,
  GET_ACCESS_GROUP_APARTMENTS_SUCCESS: `${moduleName}/GET_ACCESS_GROUP_APARTMENTS_SUCCESS`,
  GET_ACCESS_GROUP_DEVICES_ALL_FAILURE: `${moduleName}/GET_ACCESS_GROUP_DEVICES_ALL_FAILURE`,
  GET_ACCESS_GROUP_DEVICES_ALL_REQUEST: `${moduleName}/GET_ACCESS_GROUP_DEVICES_ALL_REQUEST`,
  GET_ACCESS_GROUP_DEVICES_ALL_SUCCESS: `${moduleName}/GET_ACCESS_GROUP_DEVICES_ALL_SUCCESS`,
  GET_ACCESS_GROUP_DEVICES_FAILURE: `${moduleName}/GET_ACCESS_GROUP_DEVICES_FAILURE`,
  GET_ACCESS_GROUP_DEVICES_REQUEST: `${moduleName}/GET_ACCESS_GROUP_DEVICES_REQUEST`,
  GET_ACCESS_GROUP_DEVICES_SUCCESS: `${moduleName}/GET_ACCESS_GROUP_DEVICES_SUCCESS`,
  GET_ACCESS_GROUP_FAILURE: `${moduleName}/GET_ACCESS_GROUP_FAILURE`,
  GET_ACCESS_GROUP_REQUEST: `${moduleName}/GET_ACCESS_GROUP_REQUEST`,
  GET_ACCESS_GROUP_SUCCESS: `${moduleName}/GET_ACCESS_GROUP_SUCCESS`,
  GET_ACCESS_GROUP_USERS_ALL_FAILURE: `${moduleName}/GET_ACCESS_GROUP_USERS_ALL_FAILURE`,
  GET_ACCESS_GROUP_USERS_ALL_REQUEST: `${moduleName}/GET_ACCESS_GROUP_USERS_ALL_REQUEST`,
  GET_ACCESS_GROUP_USERS_ALL_SUCCESS: `${moduleName}/GET_ACCESS_GROUP_USERS_ALL_SUCCESS`,
  GET_ACCESS_GROUP_USERS_FAILURE: `${moduleName}/GET_ACCESS_GROUP_USERS_FAILURE`,
  GET_ACCESS_GROUP_USERS_REQUEST: `${moduleName}/GET_ACCESS_GROUP_USERS_REQUEST`,
  GET_ACCESS_GROUP_USERS_SUCCESS: `${moduleName}/GET_ACCESS_GROUP_USERS_SUCCESS`,
  GET_ACCESS_GROUPS_LIST_ALL_FAILURE: `${moduleName}/GET_ACCESS_GROUPS_LIST_ALL_FAILURE`,
  GET_ACCESS_GROUPS_LIST_ALL_REQUEST: `${moduleName}/GET_ACCESS_GROUPS_LIST_ALL_REQUEST`,
  GET_ACCESS_GROUPS_LIST_ALL_SUCCESS: `${moduleName}/GET_ACCESS_GROUPS_LIST_ALL_SUCCESS`,
  GET_ACCESS_GROUPS_LIST_FAILURE: `${moduleName}/GET_ACCESS_GROUPS_LIST_FAILURE`,
  GET_ACCESS_GROUPS_LIST_REQUEST: `${moduleName}/GET_ACCESS_GROUPS_LIST_REQUEST`,
  GET_ACCESS_GROUPS_LIST_SUCCESS: `${moduleName}/GET_ACCESS_GROUPS_LIST_SUCCESS`,
  GET_APARTMENT_ACCESS_GROUPS_LIST_FAILURE: `${moduleName}/GET_APARTMENT_ACCESS_GROUPS_LIST_FAILURE`,
  GET_APARTMENT_ACCESS_GROUPS_LIST_REQUEST: `${moduleName}/GET_APARTMENT_ACCESS_GROUPS_LIST_REQUEST`,
  GET_APARTMENT_ACCESS_GROUPS_LIST_SUCCESS: `${moduleName}/GET_APARTMENT_ACCESS_GROUPS_LIST_SUCCESS`,
  GET_DEVICE_ACCESS_GROUPS_LIST_FAILURE: `${moduleName}/GET_DEVICE_ACCESS_GROUPS_LIST_FAILURE`,
  GET_DEVICE_ACCESS_GROUPS_LIST_REQUEST: `${moduleName}/GET_DEVICE_ACCESS_GROUPS_LIST_REQUEST`,
  GET_DEVICE_ACCESS_GROUPS_LIST_SUCCESS: `${moduleName}/GET_DEVICE_ACCESS_GROUPS_LIST_SUCCESS`,
  GET_USER_ACCESS_GROUPS_LIST_FAILURE: `${moduleName}/GET_USER_ACCESS_GROUPS_LIST_FAILURE`,
  GET_USER_ACCESS_GROUPS_LIST_REQUEST: `${moduleName}/GET_USER_ACCESS_GROUPS_LIST_REQUEST`,
  GET_USER_ACCESS_GROUPS_LIST_SUCCESS: `${moduleName}/GET_USER_ACCESS_GROUPS_LIST_SUCCESS`,
  GET_USER_APARTMENTS_ACCESS_GROUPS_LIST_FAILURE: `${moduleName}/GET_USER_APARTMENTS_ACCESS_GROUPS_LIST_FAILURE`,
  GET_USER_APARTMENTS_ACCESS_GROUPS_LIST_REQUEST: `${moduleName}/GET_USER_APARTMENTS_ACCESS_GROUPS_LIST_REQUEST`,
  GET_USER_APARTMENTS_ACCESS_GROUPS_LIST_SUCCESS: `${moduleName}/GET_USER_APARTMENTS_ACCESS_GROUPS_LIST_SUCCESS`,
  REMOVE_ACCESS_GROUP_APARTMENT_FAILURE: `${moduleName}/REMOVE_ACCESS_GROUP_APARTMENT_FAILURE`,
  REMOVE_ACCESS_GROUP_APARTMENT_REQUEST: `${moduleName}/REMOVE_ACCESS_GROUP_APARTMENT_REQUEST`,
  REMOVE_ACCESS_GROUP_APARTMENT_SUCCESS: `${moduleName}/REMOVE_ACCESS_GROUP_APARTMENT_SUCCESS`,
  REMOVE_ACCESS_GROUP_DEVICE_FAILURE: `${moduleName}/REMOVE_ACCESS_GROUP_DEVICE_FAILURE`,
  REMOVE_ACCESS_GROUP_DEVICE_REQUEST: `${moduleName}/REMOVE_ACCESS_GROUP_DEVICE_REQUEST`,
  REMOVE_ACCESS_GROUP_DEVICE_SUCCESS: `${moduleName}/REMOVE_ACCESS_GROUP_DEVICE_SUCCESS`,
  REMOVE_ACCESS_GROUP_USER_FAILURE: `${moduleName}/REMOVE_ACCESS_GROUP_USER_FAILURE`,
  REMOVE_ACCESS_GROUP_USER_REQUEST: `${moduleName}/REMOVE_ACCESS_GROUP_USER_REQUEST`,
  REMOVE_ACCESS_GROUP_USER_SUCCESS: `${moduleName}/REMOVE_ACCESS_GROUP_USER_SUCCESS`,
  SET_ACCESS_CONTROL_LOG_SETTINGS_FAILURE: `${moduleName}/SET_ACCESS_CONTROL_LOG_SETTINGS_FAILURE`,
  SET_ACCESS_CONTROL_LOG_SETTINGS_REQUEST: `${moduleName}/SET_ACCESS_CONTROL_LOG_SETTINGS_REQUEST`,
  SET_ACCESS_CONTROL_LOG_SETTINGS_SUCCESS: `${moduleName}/SET_ACCESS_CONTROL_LOG_SETTINGS_SUCCESS`,
  SET_ACCESS_CONTROL_SETTINGS_FAILURE: `${moduleName}/SET_ACCESS_CONTROL_SETTINGS_FAILURE`,
  SET_ACCESS_CONTROL_SETTINGS_REQUEST: `${moduleName}/SET_ACCESS_CONTROL_SETTINGS_REQUEST`,
  SET_ACCESS_CONTROL_SETTINGS_SUCCESS: `${moduleName}/SET_ACCESS_CONTROL_SETTINGS_SUCCESS`,
  SET_LOG_ACCESS_GROUP_DEVICE_FAILURE: `${moduleName}/SET_LOG_ACCESS_GROUP_DEVICE_FAILURE`,
  SET_LOG_ACCESS_GROUP_DEVICE_REQUEST: `${moduleName}/SET_LOG_ACCESS_GROUP_DEVICE_REQUEST`,
  SET_LOG_ACCESS_GROUP_DEVICE_SUCCESS: `${moduleName}/SET_LOG_ACCESS_GROUP_DEVICE_SUCCESS`,
  UPDATE_ACCESS_GROUP_FAILURE: `${moduleName}/UPDATE_ACCESS_GROUP_FAILURE`,
  UPDATE_ACCESS_GROUP_REQUEST: `${moduleName}/UPDATE_ACCESS_GROUP_REQUEST`,
  UPDATE_ACCESS_GROUP_SUCCESS: `${moduleName}/UPDATE_ACCESS_GROUP_SUCCESS`,
};
