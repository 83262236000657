import Button, { ButtonProps } from '@mui/material/Button';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface IButtonLinkNew extends ButtonProps {
  link: string;
  message: React.ReactElement | string;
}

function ButtonLinkNew({ link, message, ...props }: IButtonLinkNew) {
  const navigate = useNavigate();

  return (
    <Button onClick={() => navigate(link)} {...props}>
      {message}
    </Button>
  );
}

export default ButtonLinkNew;
