import { defineMessages } from 'react-intl';

const AAmodalNewGuest = defineMessages({
  AAmodalNewGuestApartmentDirectLabel: {
    defaultMessage: 'Allow access directly to the apartment',
    id: 'AAmodal.newGuest.aprtmDirect.label',
  },
  AAmodalNewGuestApartmentMsgErr: {
    defaultMessage: 'There was an error, please try again',
    id: 'AAmodal.newGuest.msgErr',
  },
  AAmodalNewGuestApartmentMsgSucc1: {
    defaultMessage: 'PIN created successfully',
    id: 'AAmodal.newGuest.msgSucc1',
  },
  AAmodalNewGuestApartmentMsgSucc2: {
    defaultMessage: 'PIN created successfully',
    id: 'AAmodal.newGuest.msgSucc2',
  },
  AAmodalNewGuestCancel: {
    defaultMessage: 'Cancel',
    id: 'AAmodal.newGuest.cancel',
  },
  AAmodalNewGuestEmailsErrInvalid: {
    defaultMessage: 'Invalid email address',
    id: 'AAmodal.newGuest.emails.errInvalid',
  },
  AAmodalNewGuestEmailsErrTooMany: {
    defaultMessage: 'Too many email addresses',
    id: 'AAmodal.newGuest.emails.errTooMany',
  },
  AAmodalNewGuestEmailsHelper: {
    defaultMessage: 'Comma separated email addresses',
    id: 'AAmodal.newGuest.emails.helper',
  },
  AAmodalNewGuestEmailsLabel: {
    defaultMessage: 'Send PIN to e-mail',
    id: 'AAmodal.newGuest.emails.label',
  },
  AAmodalNewGuestEmailsPlaceholder: {
    defaultMessage: 'E-mail address',
    id: 'AAmodal.newGuest.emails.placeholder',
  },
  AAmodalNewGuestEndErrEarlier: {
    defaultMessage: 'End of a period cannot be earlier than start',
    id: 'AAmodal.newGuest.end.errEarlier',
  },
  AAmodalNewGuestEndLabel: {
    defaultMessage: 'End of validity',
    id: 'AAmodal.newGuest.end.label',
  },
  AAmodalNewGuestEndPlaceholder: {
    defaultMessage: 'Set date and time',
    id: 'AAmodal.newGuest.end.placeholder',
  },
  AAmodalNewGuestPinnameErrRequired: {
    defaultMessage: 'Visitor name is required',
    id: 'AAmodal.newGuest.PINname.errRequired',
  },
  AAmodalNewGuestPinnameHelper: {
    defaultMessage: 'Assign a name to the PIN to monitor the visitor’s access',
    id: 'AAmodal.newGuest.PINname.helper',
  },
  AAmodalNewGuestPinnameLabel: {
    defaultMessage: 'PIN name',
    id: 'AAmodal.newGuest.PINname.label',
  },
  AAmodalNewGuestPinnamePlaceholder: {
    defaultMessage: 'Placeholder',
    id: 'AAmodal.newGuest.PINname.placeholder',
  },
  AAmodalNewGuestSitenameErrRequired: {
    defaultMessage: 'Cannot by empty',
    id: 'AAmodal.newGuest.sitename.errRequired',
  },
  AAmodalNewGuestSiteNameErrTooLong: {
    defaultMessage: 'Value is too long',
    id: 'AAmodal.newGuest.sitename.errTooLong',
  },
  AAmodalNewGuestSitenameHelper: {
    defaultMessage: 'HelperText sitename',
    id: 'AAmodal.newGuest.sitename.helper',
  },
  AAmodalNewGuestSitenameLabel: {
    defaultMessage: 'Site name in e-mail',
    id: 'AAmodal.newGuest.sitename.label',
  },
  AAmodalNewGuestSitenamePlaceholder: {
    defaultMessage: 'Placeholder',
    id: 'AAmodal.newGuest.sitename.placeholder',
  },
  AAmodalNewGuestStartErrLater: {
    defaultMessage: 'Start of a period cannot be later than end',
    id: 'AAmodal.newGuest.start.errLater',
  },
  AAmodalNewGuestStartLabel: {
    defaultMessage: 'Start of validity',
    id: 'AAmodal.newGuest.start.label',
  },
  AAmodalNewGuestStartPlaceholder: {
    defaultMessage: 'Set date and time',
    id: 'AAmodal.newGuest.start.placeholder',
  },
  AAmodalNewGuestSubmit1: {
    defaultMessage: 'save pin',
    id: 'AAmodal.newGuest.submit1',
  },
  AAmodalNewGuestSubmit2: {
    defaultMessage: 'save & send pin',
    id: 'AAmodal.newGuest.submit2',
  },
  AamodalNewGuestText1: {
    id: 'AAmodal.newGuest.text1',
  },
  AAmodalNewGuestTitle: {
    defaultMessage: 'Generate new PIN',
    id: 'AAmodal.newGuest.title',
  },
});

const UserAccessValidityEditmodal = defineMessages({
  UserAccessValidityEditmodalAlert1: {
    defaultMessage: 'After creating the PIN, set the access rights via access group',
    id: 'user.access.validityEditmodal.alert1',
  },
  UserAccessValidityEditmodalAprtmDirect: {
    defaultMessage: 'Allow access directly to the apartment',
    id: 'user.access.validityEditmodal.aprtmDirect',
  },
  UserAccessValidityEditmodalAprtmDirectLabel: {
    defaultMessage: 'Allow access directly to the apartment',
    id: 'user.access.validityEditmodal.aprtmDirect.label',
  },
  UserAccessValidityEditmodalCancel: {
    defaultMessage: 'Cancel',
    id: 'user.access.validityEditmodal.cancel',
  },
  UserAccessValidityEditmodalEmailsErrInvalid: {
    id: 'user.access.validityEditmodal.emails.errInvalid',
  },
  UserAccessValidityEditmodalEmailsErrToMany: {
    defaultMessage: 'Too many email addresses',
    id: 'user.access.validityEditmodal.emails.errToMany',
  },
  UserAccessValidityEditmodalEmailsErrTooMany: {
    defaultMessage: 'Too many email addresses',
    id: 'user.access.validityEditmodal.emails.errTooMany',
  },
  UserAccessValidityEditmodalEmailsHelper: {
    defaultMessage: 'You can enter multiple email addresses either on separate lines or separated by commas',
    id: 'user.access.validityEditmodal.emails.helper',
  },
  UserAccessValidityEditmodalEmailsLabel: {
    defaultMessage: 'Send PIN to email',
    id: 'user.access.validityEditmodal.emails.label',
  },
  UserAccessValidityEditmodalEmailsPlaceholder: {
    defaultMessage: 'Email addresses',
    id: 'user.access.validityEditmodal.emails.placeholder',
  },
  UserAccessValidityEditmodalEndErrEarlier: {
    defaultMessage: 'End of a period cannot be earlier than start',
    id: 'user.access.validityEditmodal.end.errEarlier',
  },
  UserAccessValidityEditmodalEndLabel: {
    defaultMessage: 'End of validity',
    id: 'user.access.validityEditmodal.end.label',
  },
  UserAccessValidityEditmodalEndPlaceholder: {
    defaultMessage: 'Set date and time',
    id: 'user.access.validityEditmodal.end.placeholder',
  },
  UserAccessValidityEditmodalMsgErr: {
    defaultMessage: 'There was an error, please try again',
    id: 'user.access.validityEditmodal.msgErr',
  },
  UserAccessValidityEditmodalMsgSucc1: {
    defaultMessage: 'PIN created successfully',
    id: 'user.access.validityEditmodal.msgSucc1',
  },
  UserAccessValidityEditmodalMsgSucc2: {
    defaultMessage: 'PIN created successfully',
    id: 'user.access.validityEditmodal.msgSucc2',
  },
  UserAccessValidityEditmodalPinnameErrRequired: {
    defaultMessage: 'Visitor name is required',
    id: 'user.access.validityEditmodal.PINname.errRequired',
  },
  UserAccessValidityEditmodalPinnameHelper: {
    defaultMessage: 'Name visitor for easier management',
    id: 'user.access.validityEditmodal.PINname.helper',
  },
  UserAccessValidityEditmodalPinnameLabel: {
    defaultMessage: 'Visitor name',
    id: 'user.access.validityEditmodal.PINname.label',
  },
  UserAccessValidityEditmodalPinnamePlaceholder: {
    defaultMessage: 'Placeholder',
    id: 'user.access.validityEditmodal.PINname.placeholder',
  },
  UserAccessValidityEditmodalSendEmailLabel: {
    defaultMessage: 'Send info e-mail',
    id: 'user.access.validityEditmodal.sendEmail.label',
  },
  UserAccessValidityEditmodalSitenameErrTooLong: {
    defaultMessage: 'Value is too long',
    id: 'user.access.validityEditmodal.sitename.errTooLong',
  },
  UserAccessValidityEditmodalSitenameHelper: {
    defaultMessage: 'The location name will be included in the email subject',
    id: 'user.access.validityEditmodal.sitename.helper',
  },
  UserAccessValidityEditmodalSitenameLabel: {
    defaultMessage: 'Location name in email',
    id: 'user.access.validityEditmodal.sitename.label',
  },
  UserAccessValidityEditmodalSitenamePlaceholder: {
    defaultMessage: 'Can’t by empty',
    id: 'user.access.validityEditmodal.sitename.placeholder',
  },
  UserAccessValidityEditmodalStartErrLater: {
    defaultMessage: 'Start of a period cannot be later than end',
    id: 'user.access.validityEditmodal.start.errLater',
  },
  UserAccessValidityEditmodalStartLabel: {
    defaultMessage: 'Start of validity',
    id: 'user.access.validityEditmodal.start.label',
  },
  UserAccessValidityEditmodalStartPlaceholder: {
    defaultMessage: 'Set date and time',
    id: 'user.access.validityEditmodal.start.placeholder',
  },
  UserAccessValidityEditmodalSubmit: {
    defaultMessage: 'Save pin',
    id: 'user.access.validityEditmodal.submit1',
  },
  UserAccessValidityEditmodalSubmit1: {
    defaultMessage: 'Save pin',
    id: 'user.access.validityEditmodal.submit1',
  },
  UserAccessValidityEditmodalSubmit2: {
    defaultMessage: 'Save & send pin',
    id: 'user.access.validityEditmodal.submit2',
  },
  UserAccessValidityEditmodalText1: {
    defaultMessage: 'Edit time validity. You can send your PIN to the email addresses you enter',
    id: 'user.access.validityEditmodal.text1',
  },
  UserAccessValidityEditmodalTitle: {
    defaultMessage: 'Edit PIN validity',
    id: 'user.access.validityEditmodal.title',
  },
});

const UserListNewGuestmodal = defineMessages({
  UserListNewGuestmodalAlert1: {
    defaultMessage: 'After creating the PIN, set the access rights via access group',
    id: 'userList.newGuestmodal.alert1',
  },
  UserListNewGuestmodalAprtmDirectLabel: {
    defaultMessage: 'Allow access directly to the apartment',
    id: 'userList.newGuestmodal.aprtmDirect.label',
  },
  UserListNewGuestmodalCancel: {
    defaultMessage: 'Cancel',
    id: 'userList.newGuestmodal.cancel',
  },
  UserListNewGuestmodalEmailsErrInvalid: {
    id: 'userList.newGuestmodal.emails.errInvalid',
  },
  UserListNewGuestmodalEmailsErrToMany: {
    defaultMessage: 'Too many email addresses',
    id: 'userList.newGuestmodal.emails.errToMany',
  },
  UserListNewGuestmodalEmailsErrTooMany: {
    defaultMessage: 'Too many email addresses',
    id: 'userList.newGuestmodal.emails.errTooMany',
  },
  UserListNewGuestmodalEmailsHelper: {
    defaultMessage: 'You can enter multiple email addresses either on separate lines or separated by commas',
    id: 'userList.newGuestmodal.emails.helper',
  },
  UserListNewGuestmodalEmailsLabel: {
    defaultMessage: 'Send PIN to email',
    id: 'userList.newGuestmodal.emails.label',
  },
  UserListNewGuestmodalEmailsPlaceholder: {
    defaultMessage: 'Email addresses',
    id: 'userList.newGuestmodal.emails.placeholder',
  },
  UserListNewGuestmodalEndErrEarlier: {
    defaultMessage: 'End of a period cannot be earlier than start',
    id: 'userList.newGuestmodal.end.errEarlier',
  },
  UserListNewGuestmodalEndLabel: {
    defaultMessage: 'End of validity',
    id: 'userList.newGuestmodal.end.label',
  },
  UserListNewGuestmodalEndPlaceholder: {
    defaultMessage: 'Set date and time',
    id: 'userList.newGuestmodal.end.placeholder',
  },
  UserListNewGuestmodalMsgErr: {
    defaultMessage: 'There was an error, please try again',
    id: 'userList.newGuestmodal.msgErr',
  },
  UserListNewGuestmodalMsgSucc1: {
    defaultMessage: 'PIN created successfully',
    id: 'userList.newGuestmodal.msgSucc1',
  },
  UserListNewGuestmodalMsgSucc2: {
    defaultMessage: 'PIN created successfully',
    id: 'userList.newGuestmodal.msgSucc2',
  },
  UserListNewGuestmodalPinnameErrRequired: {
    defaultMessage: 'Visitor name is required',
    id: 'userList.newGuestmodal.PINname.errRequired',
  },
  UserListNewGuestmodalPinnameHelper: {
    defaultMessage: 'Name visitor for easier management',
    id: 'userList.newGuestmodal.PINname.helper',
  },
  UserListNewGuestmodalPinnameLabel: {
    defaultMessage: 'Visitor name',
    id: 'userList.newGuestmodal.PINname.label',
  },
  UserListNewGuestmodalPinnamePlaceholder: {
    defaultMessage: 'Placeholder',
    id: 'userList.newGuestmodal.PINname.placeholder',
  },
  UserListNewGuestmodalSitenameErrTooLong: {
    defaultMessage: 'Value is too long',
    id: 'userList.newGuestmodal.sitename.errTooLong',
  },
  UserListNewGuestmodalSitenameHelper: {
    defaultMessage: 'The location name will be included in the email subject',
    id: 'userList.newGuestmodal.sitename.helper',
  },
  UserListNewGuestmodalSitenameLabel: {
    defaultMessage: 'Location name in email',
    id: 'userList.newGuestmodal.sitename.label',
  },
  UserListNewGuestmodalSitenamePlaceholder: {
    defaultMessage: 'Can’t by empty',
    id: 'userList.newGuestmodal.sitename.placeholder',
  },
  UserListNewGuestmodalStartErrLater: {
    defaultMessage: 'Start of a period cannot be later than end',
    id: 'userList.newGuestmodal.start.errLater',
  },
  UserListNewGuestmodalStartLabel: {
    defaultMessage: 'Start of validity',
    id: 'userList.newGuestmodal.start.label',
  },
  UserListNewGuestmodalStartPlaceholder: {
    defaultMessage: 'Set date and time',
    id: 'userList.newGuestmodal.start.placeholder',
  },
  UserListNewGuestmodalSubmit: {
    defaultMessage: 'Save pin',
    id: 'userList.newGuestmodal.submit1',
  },
  UserListNewGuestmodalSubmit2: {
    defaultMessage: 'Save & send pin',
    id: 'userList.newGuestmodal.submit2',
  },
  UserListNewGuestmodalText1: {
    defaultMessage:
      'Create and share a PIN code to allow your visitors to open doors. You must also specify time validity.',
    id: 'userList.newGuestmodal.text1',
  },
  UserListNewGuestmodalTitle: {
    defaultMessage: 'Create new visitor',
    id: 'userDetail.newGuestmodal.title',
  },
});

const ApartmentUsersNewGuestmodal = defineMessages({
  ApartmentUsersNewGuestmodalAlert1: {
    defaultMessage: 'After creating the PIN, set the access rights via access group',
    id: 'apartment.users.newGuestmodal.alert1',
  },
  ApartmentUsersNewGuestmodalApartment: {
    defaultMessage: 'Apartment: {apartment}',
    id: 'apartment.users.newGuestmodal.apartment',
  },
  ApartmentUsersNewGuestmodalAprtmDirectLabel: {
    defaultMessage: 'Allow access directly to the apartment',
    id: 'apartment.users.newGuestmodal.aprtmDirect.label',
  },
  ApartmentUsersNewGuestmodalCancel: {
    defaultMessage: 'Cancel',
    id: 'apartment.users.newGuestmodal.cancel',
  },
  ApartmentUsersNewGuestmodalEmailsErrInvalid: {
    id: 'apartment.users.newGuestmodal.emails.errInvalid',
  },
  ApartmentUsersNewGuestmodalEmailsErrToMany: {
    defaultMessage: 'Too many email addresses',
    id: 'apartment.users.newGuestmodal.emails.errToMany',
  },
  ApartmentUsersNewGuestmodalEmailsErrTooMany: {
    defaultMessage: 'Too many email addresses',
    id: 'apartment.users.newGuestmodal.emails.errTooMany',
  },
  ApartmentUsersNewGuestmodalEmailsHelper: {
    defaultMessage: 'You can enter multiple email addresses either on separate lines or separated by commas',
    id: 'apartment.users.newGuestmodal.emails.helper',
  },
  ApartmentUsersNewGuestmodalEmailsLabel: {
    defaultMessage: 'Send PIN to email',
    id: 'apartment.users.newGuestmodal.emails.label',
  },
  ApartmentUsersNewGuestmodalEmailsPlaceholder: {
    defaultMessage: 'Email addresses',
    id: 'apartment.users.newGuestmodal.emails.placeholder',
  },
  ApartmentUsersNewGuestmodalEndErrEarlier: {
    defaultMessage: 'End of a period cannot be earlier than start',
    id: 'apartment.users.newGuestmodal.end.errEarlier',
  },
  ApartmentUsersNewGuestmodalEndLabel: {
    defaultMessage: 'End of validity',
    id: 'apartment.users.newGuestmodal.end.label',
  },
  ApartmentUsersNewGuestmodalEndPlaceholder: {
    defaultMessage: 'Set date and time',
    id: 'apartment.users.newGuestmodal.end.placeholder',
  },
  ApartmentUsersNewGuestmodalMsgErr: {
    defaultMessage: 'There was an error, please try again',
    id: 'apartment.users.newGuestmodal.msgErr',
  },
  ApartmentUsersNewGuestmodalMsgSucc1: {
    defaultMessage: 'PIN created successfully',
    id: 'apartment.users.newGuestmodal.msgSucc1',
  },
  ApartmentUsersNewGuestmodalMsgSucc2: {
    defaultMessage: 'PIN created successfully',
    id: 'apartment.users.newGuestmodal.msgSucc2',
  },
  ApartmentUsersNewGuestmodalPinnameErrRequired: {
    defaultMessage: 'Visitor name is required',
    id: 'apartment.users.newGuestmodal.PINname.errRequired',
  },
  ApartmentUsersNewGuestmodalPinnameHelper: {
    defaultMessage: 'Name visitor for easier management',
    id: 'apartment.users.newGuestmodal.PINname.helper',
  },
  ApartmentUsersNewGuestmodalPinnameLabel: {
    defaultMessage: 'Visitor name',
    id: 'apartment.users.newGuestmodal.PINname.label',
  },
  ApartmentUsersNewGuestmodalPinnamePlaceholder: {
    defaultMessage: 'Placeholder',
    id: 'apartment.users.newGuestmodal.PINname.placeholder',
  },
  ApartmentUsersNewGuestmodalSitenameErrTooLong: {
    defaultMessage: 'Value is too long',
    id: 'apartment.users.newGuestmodal.sitename.errTooLong',
  },
  ApartmentUsersNewGuestmodalSitenameHelper: {
    defaultMessage: 'The location name will be included in the email subject',
    id: 'apartment.users.newGuestmodal.sitename.helper',
  },
  ApartmentUsersNewGuestmodalSitenameLabel: {
    defaultMessage: 'Location name in email',
    id: 'apartment.users.newGuestmodal.sitename.label',
  },
  ApartmentUsersNewGuestmodalSitenamePlaceholder: {
    defaultMessage: 'Can’t by empty',
    id: 'apartment.users.newGuestmodal.sitename.placeholder',
  },
  ApartmentUsersNewGuestmodalStartErrLater: {
    defaultMessage: 'Start of a period cannot be later than end',
    id: 'apartment.users.newGuestmodal.start.errLater',
  },
  ApartmentUsersNewGuestmodalStartLabel: {
    defaultMessage: 'Start of validity',
    id: 'apartment.users.newGuestmodal.start.label',
  },
  ApartmentUsersNewGuestmodalStartPlaceholder: {
    defaultMessage: 'Set date and time',
    id: 'apartment.users.newGuestmodal.start.placeholder',
  },
  ApartmentUsersNewGuestmodalSubmit: {
    defaultMessage: 'Save pin',
    id: 'apartment.users.newGuestmodal.submit1',
  },
  ApartmentUsersNewGuestmodalSubmit2: {
    defaultMessage: 'Save & send pin',
    id: 'apartment.users.newGuestmodal.submit2',
  },
  ApartmentUsersNewGuestmodalText1: {
    defaultMessage:
      'Create and share a PIN code to allow your visitors to open doors. You must also specify time validity.',
    id: 'apartment.users.newGuestmodal.text1',
  },
  ApartmentUsersNewGuestmodalTitle: {
    defaultMessage: 'Create new visitor',
    id: 'apartment.users.newGuestmodal.title',
  },
});

const AAmodalNewGuestPIN = defineMessages({
  AAmodalNewGuestPINAlert1: {
    defaultMessage: 'After creating the PIN, set the access rights via access group',
    id: 'AAmodal.newGuestPIN.alert1',
  },
  AAmodalNewGuestPINApartment: {
    defaultMessage: 'Apartment: {apartment}',
    id: 'AAmodal.newGuestPIN.apartment',
  },
  AAmodalNewGuestPINAprtmDirectLabel: {
    defaultMessage: 'Allow access directly to the apartment',
    id: 'AAmodal.newGuestPIN.aprtmDirect.label',
  },
  AAmodalNewGuestPINCancel: {
    defaultMessage: 'Cancel',
    id: 'AAmodal.newGuestPIN.cancel',
  },
  AAmodalNewGuestPINEmailsErrInvalid: {
    id: 'AAmodal.newGuestPIN.emails.errInvalid',
  },
  AAmodalNewGuestPINEmailsErrToMany: {
    defaultMessage: 'Too many email addresses',
    id: 'AAmodal.newGuestPIN.emails.errToMany',
  },
  AAmodalNewGuestPINEmailsErrTooMany: {
    defaultMessage: 'Too many email addresses',
    id: 'AAmodal.newGuestPIN.emails.errTooMany',
  },
  AAmodalNewGuestPINEmailsHelper: {
    defaultMessage: 'You can enter multiple email addresses either on separate lines or separated by commas',
    id: 'AAmodal.newGuestPIN.emails.helper',
  },
  AAmodalNewGuestPINEmailsLabel: {
    defaultMessage: 'Send PIN to email',
    id: 'AAmodal.newGuestPIN.emails.label',
  },
  AAmodalNewGuestPINEmailsPlaceholder: {
    defaultMessage: 'Email addresses',
    id: 'AAmodal.newGuestPIN.emails.placeholder',
  },
  AAmodalNewGuestPINEndErrEarlier: {
    defaultMessage: 'End of a period cannot be earlier than start',
    id: 'AAmodal.newGuestPIN.end.errEarlier',
  },
  AAmodalNewGuestPINEndLabel: {
    defaultMessage: 'End of validity',
    id: 'AAmodal.newGuestPIN.end.label',
  },
  AAmodalNewGuestPINEndPlaceholder: {
    defaultMessage: 'Set date and time',
    id: 'AAmodal.newGuestPIN.end.placeholder',
  },
  AAmodalNewGuestPINMsgErr: {
    defaultMessage: 'There was an error, please try again',
    id: 'AAmodal.newGuestPIN.msgErr',
  },
  AAmodalNewGuestPINMsgSucc1: {
    defaultMessage: 'PIN created successfully',
    id: 'AAmodal.newGuestPIN.msgSucc1',
  },
  AAmodalNewGuestPINMsgSucc2: {
    defaultMessage: 'PIN created successfully',
    id: 'AAmodal.newGuestPIN.msgSucc2',
  },
  AAmodalNewGuestPINPinnameErrRequired: {
    defaultMessage: 'Visitor name is required',
    id: 'AAmodal.newGuestPIN.PINname.errRequired',
  },
  AAmodalNewGuestPINPinnameHelper: {
    defaultMessage: 'Name visitor for easier management',
    id: 'AAmodal.newGuestPIN.PINname.helper',
  },
  AAmodalNewGuestPINPinnameLabel: {
    defaultMessage: 'Visitor name',
    id: 'AAmodal.newGuestPIN.PINname.label',
  },
  AAmodalNewGuestPINPinnamePlaceholder: {
    defaultMessage: 'Placeholder',
    id: 'AAmodal.newGuestPIN.PINname.placeholder',
  },
  AAmodalNewGuestPINSitenameErrTooLong: {
    defaultMessage: 'Value is too long',
    id: 'AAmodal.newGuestPIN.sitename.errTooLong',
  },
  AAmodalNewGuestPINSitenameHelper: {
    defaultMessage: 'The location name will be included in the email subject',
    id: 'AAmodal.newGuestPIN.sitename.helper',
  },
  AAmodalNewGuestPINSitenameLabel: {
    defaultMessage: 'Location name in email',
    id: 'AAmodal.newGuestPIN.sitename.label',
  },
  AAmodalNewGuestPINSitenamePlaceholder: {
    defaultMessage: 'Can’t by empty',
    id: 'AAmodal.newGuestPIN.sitename.placeholder',
  },
  AAmodalNewGuestPINStartErrLater: {
    defaultMessage: 'Start of a period cannot be later than end',
    id: 'AAmodal.newGuestPIN.start.errLater',
  },
  AAmodalNewGuestPINStartLabel: {
    defaultMessage: 'Start of validity',
    id: 'AAmodal.newGuestPIN.start.label',
  },
  AAmodalNewGuestPINStartPlaceholder: {
    defaultMessage: 'Set date and time',
    id: 'AAmodal.newGuestPIN.start.placeholder',
  },
  AAmodalNewGuestPINSubmit: {
    defaultMessage: 'Save pin',
    id: 'AAmodal.newGuestPIN.submit1',
  },
  AAmodalNewGuestPINSubmit2: {
    defaultMessage: 'Save & send pin',
    id: 'AAmodal.newGuestPIN.submit2',
  },
  AAmodalNewGuestPINText1: {
    defaultMessage:
      'Create and share a PIN code to allow your visitors to open doors. You must also specify time validity.',
    id: 'AAmodal.newGuestPIN.text1',
  },
  AAmodalNewGuestPINTitle: {
    defaultMessage: 'Generate new PIN',
    id: 'AAmodal.newGuestPIN.title',
  },
});

export default {
  AAmodalNewGuest,
  AAmodalNewGuestPIN,
  ApartmentUsersNewGuestmodal,
  UserAccessValidityEditmodal,
  UserListNewGuestmodal,
};
