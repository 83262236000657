import { defineMessages } from 'react-intl';

export default defineMessages({
  devicesOverviewCardAccessUnit: {
    defaultMessage: 'Access units (<bold>{count}</bold>)',
    description: 'Devices overview card - access units',
    id: 'devicesOverviewCard.accessUnit',
  },
  devicesOverviewCardAddDevice: {
    defaultMessage: 'Add device',
    description: 'Devices overview card - add device',
    id: 'devicesOverviewCard.addDevice',
  },
  devicesOverviewCardAddedDevices: {
    defaultMessage: 'added {count, plural, one {device} other {devices}}',
    description: 'Devices overview card - added devices',
    id: 'devicesOverviewCard.addedDevices',
  },
  devicesOverviewCardAnsweringUnit: {
    defaultMessage: 'Answering units (<bold>{count}</bold>)',
    description: 'Devices overview card - answering units',
    id: 'devicesOverviewCard.answeringUnit',
  },
  devicesOverviewCardIntercom: {
    defaultMessage: 'Intercoms (<bold>{count}</bold>)',
    description: 'Devices overview card - intercoms',
    id: 'devicesOverviewCard.intercom',
  },
  devicesOverviewCardSeeAllDevices: {
    defaultMessage: 'See all devices',
    description: 'Devices overview card - see all devices',
    id: 'devicesOverviewCard.seeAllDevices',
  },
  devicesOverviewCardSmartphoneOrTablet: {
    defaultMessage: 'Smartphones (<bold>{count}</bold>)',
    description: 'Devices overview card - smartphones/tablets',
    id: 'devicesOverviewCard.smartphoneOrTablet',
  },
  devicesOverviewCardThirdParty: {
    defaultMessage: '3rd party devices (<bold>{count}</bold>)',
    description: 'Devices overview card - third party',
    id: 'devicesOverviewCard.thirdParty',
  },
  devicesOverviewCardTitle: {
    defaultMessage: 'Devices',
    description: 'Devices overview card - title',
    id: 'devicesOverviewCard.title',
  },
});
