import cx from 'classnames';
import React, { forwardRef } from 'react';

export interface IIcon2NProps {
  children?: React.ReactNode;
  className?: string;
  name: string;
}

const Icon2N = forwardRef(({ children, className, name, ...rest }: IIcon2NProps, ref) => (
  <span className={cx('my2n', `my2n-${name}`, className)} ref={ref as any} {...rest}>
    {children}
  </span>
));

export default Icon2N;
