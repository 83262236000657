import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Button from '@mui/material/Button';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import actions, { selectors } from '../../modules/auth';
import messages from './messages';

export interface IUserInvitationResendButton {
  email: string;
}

export function UserInvitationResendButton({ email }: IUserInvitationResendButton): JSX.Element {
  const dispatch = useDispatch();
  const isFetching = useSelector(selectors.isResendInvitationFetching);

  return (
    <Button
      color="primary"
      disabled={isFetching}
      id="resend-confirmation-button"
      onClick={() => {
        dispatch(actions.resendConfirmationEmailRequest({ email }));
      }}
      size="large"
      startIcon={<MailOutlineIcon />}
      variant="outlined"
    >
      <FormattedMessage {...messages.userInvitationResendButtonResend} />
    </Button>
  );
}
