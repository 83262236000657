import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import React from 'react';

// eslint-disable-next-line max-len
export const LINK_HREF =
  'https://wiki.2n.com/hip/conf/latest/en/3-rozdily-mezi-modely-a-licencovani-funkci/3-2-licencovani-funkci';

const WikipediaLink = ({ children, color }) => (
  <Link color={color} href={LINK_HREF} rel="noopener noreferer" target="_blank">
    {children}
  </Link>
);

WikipediaLink.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
};

WikipediaLink.defaultProps = {
  color: 'common.black',
};

export default WikipediaLink;
