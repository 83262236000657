import { defineMessages } from 'react-intl';

export default defineMessages({
  UserAccessSettingsStatusBluetooth: {
    defaultMessage: 'My2N app access{br}enabled',
    id: 'userAccessSettingsStatusBluetooth',
  },

  UserAccessSettingsStatusBluetoothNot: {
    defaultMessage: 'My2N app access{br}not enabled',
    id: 'userAccessSettingsStatusBluetooth.not',
  },

  UserAccessSettingsStatusPin: {
    defaultMessage: 'PIN{br}generated',
    id: 'userAccessSettingsStatusPin',
  },

  UserAccessSettingsStatusPinNot: {
    defaultMessage: 'PIN{br}not generated',
    id: 'userAccessSettingsStatusPin.not',
  },
  UserAccessSettingsStatusRfId: {
    defaultMessage: 'RFID key fob{br}assigned',
    id: 'userAccessSettingsStatusRfId',
  },
  UserAccessSettingsStatusRfIdNot: {
    defaultMessage: 'RFID key fob{br}not assigned',
    id: 'userAccessSettingsStatusRfId.not',
  },
});
