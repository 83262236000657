import Grid from '@mui/material/Grid';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { OutlinedTextField } from '../../../../components/HookFormFields/TextField';
import { isFirstNameRequired } from './hooks/getGlobalHooks';
import { useGetUserInfoFormData } from './hooks/getUserInfoFormData';
import messages from './messages';

function PersonalInfo() {
  const {
    userData: { initialUserRole },
  } = useGetUserInfoFormData();

  return (
    <Grid item xs={12}>
      <OutlinedTextField
        disabled
        fullWidth
        label={<FormattedMessage {...messages.personalInfoEmailLabel} />}
        margin="normal"
        name="email"
        required={false}
        sx={{ mb: 2 }}
      />
      <OutlinedTextField
        fullWidth
        label={<FormattedMessage {...messages.personalInfoFirstNameLabel} />}
        margin="normal"
        name="firstName"
        required={isFirstNameRequired(initialUserRole)}
        sx={{ mb: 2 }}
      />
      <OutlinedTextField
        fullWidth
        label={<FormattedMessage {...messages.personalInfoLastNameLabel} />}
        margin="normal"
        name="lastName"
        required
        sx={{ mb: 2 }}
      />
    </Grid>
  );
}

export default PersonalInfo;
