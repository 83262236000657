import { defineMessages } from 'react-intl';

export default defineMessages({
  addBatchItemButton: {
    defaultMessage: 'Add new voucher',
    description: 'Add batch button label',
    id: 'addBatchItem.button',
  },
  addBatchItemInfoAdd: {
    defaultMessage: 'Add new voucher',
    description: 'Add batch action text',
    id: 'addBatchItem.info.add',
  },
  addBatchItemInfoText: {
    defaultMessage: 'Do you need more than one type of vouchers? {add}.',
    description: 'Add batch info text',
    id: 'addBatchItem.info.text',
  },
  submitButtonLabel: {
    defaultMessage: 'Check the order',
    description: 'Submit button label',
    id: 'submitButton.label',
  },
  validationMaxSubscriptionLength: {
    defaultMessage: '{subscription} subscriptions is a maximum',
    description: 'Batch - validation max subscription length',
    id: 'validation.maxSubscriptionLength',
  },
  validationMaxYearLength: {
    defaultMessage: '{year} years is a maximum',
    description: 'Batch - validation max year length',
    id: 'validation.maxYearLength',
  },
  validationMinSubscriptionLength: {
    defaultMessage: '{subscription} subscription is a minimum',
    description: 'Batch - validation min subscription length',
    id: 'validation.minSubscriptionLength',
  },
  validationMinYearLength: {
    defaultMessage: '{year} year is a minimum',
    description: 'Batch - validation min year length',
    id: 'validation.minYearLength',
  },
  validationRequired: {
    defaultMessage: 'Value is mandatory',
    description: 'Batch - validation required',
    id: 'validation.required',
  },
  validationRequiredName: {
    defaultMessage: 'Name your voucher',
    description: 'Batch - validation required name',
    id: 'validation.requiredName',
  },
  validationRequiredSubscriptionType: {
    defaultMessage: 'Select your subscription to proceed',
    description: 'Batch - validation required subscription type',
    id: 'validation.requiredSubscriptionType',
  },
});
