import { UseFormReturn } from 'react-hook-form';
import { NavigateFunction } from 'react-router-dom';
import { Dispatch } from 'redux';
import { PATHS } from '../../../config/consts';
import { TYPE_DESCRIPTOR } from '../../../modules/notifications';
import { addSnackbarError, addSnackbarSuccess } from '../../../modules/notifications/actions';
import { MaxWrongCurrentPasswordAttemptsDuringChangePassword } from '../../config/const';
import { containsOneOfSpecificErrors } from '../../errors/errorParser';
import { IUseLoginApi, IUseSettingsApi } from '../../hooks/oryApi';
import { IUseOryResponseParser } from '../../hooks/oryResponseParser';
import { OryContext, OryErrorNamedMessageId } from '../../types';
import messages from './messages';
import { FormFieldNames, IChangePasswordFormData } from './validationSchema';

export interface IChangePasswordBaseParams {
  navigate: NavigateFunction;
  dispatch: Dispatch;
  parseOryResponse: IUseOryResponseParser['parseOryResponse'];
  onSuccess: () => void;
  formData: UseFormReturn<IChangePasswordFormData>;
}

export interface IRefreshSessionParams extends IChangePasswordBaseParams {
  wrongCurrentPasswordAttempts: number;
  setWrongCurrentPasswordAttempts: React.Dispatch<React.SetStateAction<number>>;
  email: string;
  loginToOry: IUseLoginApi['loginToOry'];
  currentPassword: string;
}

export interface IUpdatePasswordParams extends IChangePasswordBaseParams {
  updatePassword: IUseSettingsApi['updatePassword'];
  setIsSessionRefreshRequired: React.Dispatch<React.SetStateAction<boolean>>;
  password: string;
}

export async function refreshSession({
  currentPassword,
  dispatch,
  email,
  formData,
  loginToOry,
  navigate,
  onSuccess,
  parseOryResponse,
  setWrongCurrentPasswordAttempts,
  wrongCurrentPasswordAttempts,
}: IRefreshSessionParams) {
  const response = await loginToOry({
    context: OryContext.ChangeCurrentUserPassword,
    email: email,
    password: currentPassword,
    sessionRefresh: true,
  });

  parseOryResponse({
    onError: (error) => {
      if (error) {
        if (containsOneOfSpecificErrors(error, [OryErrorNamedMessageId.Login_flow_invalid_credentials_4000006])) {
          const attemptsCount = wrongCurrentPasswordAttempts + 1;
          setWrongCurrentPasswordAttempts(attemptsCount);
          if (attemptsCount >= MaxWrongCurrentPasswordAttemptsDuringChangePassword) {
            dispatch(addSnackbarError({ ...messages.ImsLoginErrorIncorrectCredentialsEditProfile }, TYPE_DESCRIPTOR));
            navigate(PATHS.LOGOUT);
          }
        }
      }
    },
    onSuccess: onSuccess,
    response,
    validationField: { fieldName: FormFieldNames.CurrentPassword, formData },
  });
}

export async function tryToUpdatePassword({
  dispatch,
  formData,
  navigate,
  onSuccess,
  parseOryResponse,
  password,
  setIsSessionRefreshRequired,
  updatePassword,
}: IUpdatePasswordParams) {
  const response = await updatePassword({ password });
  parseOryResponse({
    onError: async (error) => {
      if (error) {
        if (containsOneOfSpecificErrors(error, [OryErrorNamedMessageId.Session_inactive])) {
          navigate(PATHS.LOGOUT);
        }
        if (containsOneOfSpecificErrors(error, [OryErrorNamedMessageId.Session_refresh_required])) {
          setIsSessionRefreshRequired(true);
        }
      }
    },
    onSuccess: () => {
      dispatch(addSnackbarSuccess(messages.ImsNewPasswordSuccess));
      onSuccess();
    },
    response,
    validationField: { fieldName: FormFieldNames.Password, formData },
  });
}
