import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Stepper, Title } from '../../components';
import { PAYMENT_CONTEXT } from '../../config/payments';
import { useGetLoadedParams } from '../../hooks/paramsHooks';
import billingActions from '../../modules/billing';
import { ApplicationContext } from '../../modules/commonTypes';
import paymentsActions from '../../modules/payments';
import * as siteActions from '../../modules/sites/actions';
import actions from '../../modules/subscription';
import { useAppContext } from '../../pages/App/AppContextProvider';
import BillingInfoSettings from './BillingInfoSettings';
import { getSubscriptionStepsIntl } from './helpers';
import messages from './messages';
import MobileVideoPaymentBox from './MobileVideoPaymentBox';
import subscriptionStyles from './styles';
import { SubscriptionControl } from './SubscriptionControl';
import { useGetSubscriptionCardsData } from './useGetSubscriptionCardsData';
import VatWarning from './VatWarning/VatWarning';

const styles = {
  payments: {
    mb: 1,
    p: 1,
  },
  subtitle: {
    color: 'grey.800',
    mb: 1,
    p: 1,
  },
};

export function SubscriptionCards() {
  const { company, dashboardMvService, isLastMVPaymentFinished, isVatVerified, isVatVerifiedLoading } =
    useGetSubscriptionCardsData();
  const { feSiteRoles, userRoles } = useAppContext();
  const { companyId, siteId } = useGetLoadedParams(ApplicationContext.Site);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.invalidatePaymentStatus());
    if (!feSiteRoles.isSiteAdmin) {
      dispatch(
        paymentsActions.getPaymentsList(
          companyId,
          siteId,
          1,
          10,
          { fulltext: '' },
          '-dateCreated',
          PAYMENT_CONTEXT.SITE_PAYMENTS
        )
      );
    }
    dispatch(siteActions.getSiteDashboard(companyId, siteId));
  }, [companyId, siteId]);

  const { formatMessage } = useIntl();

  return (
    <>
      <Title heading={<FormattedMessage {...messages.subscriptionTitle} />} />
      <Stepper activeStep={0} steps={getSubscriptionStepsIntl(formatMessage)} />
      <Paper sx={subscriptionStyles.root}>
        {Object.keys(dashboardMvService).length !== 0 &&
          isVatVerified &&
          (feSiteRoles.isSiteAdminPayment || feSiteRoles.isCompanyAdmin) && (
            <Box sx={styles.payments}>
              <MobileVideoPaymentBox
                companyId={companyId}
                isLastMVPaymentFinished={isLastMVPaymentFinished}
                licenceModel={dashboardMvService?.licenceModel}
                onDeactivate={() => dispatch(actions.deactivateRecurringPayment())}
                paymentFailure={dashboardMvService?.paymentFailure}
                siteId={siteId}
                subscribed={dashboardMvService?.subscribed}
              />
            </Box>
          )}
        <Typography sx={styles.subtitle} variant="subtitle1">
          <FormattedMessage {...messages.subscriptionChooseService} />
        </Typography>
        {company?.address && !isVatVerified && !isVatVerifiedLoading && (
          <Box pb={1}>
            <VatWarning />
          </Box>
        )}
        <SubscriptionControl
          companyId={companyId}
          siteId={siteId}
          userRoles={userRoles}
          isVatVerified={isVatVerified}
        />
        {!company?.address && company?.billingInfoEditable && (
          <Box pt={3}>
            <BillingInfoSettings
              companyName={company.name}
              isCompanyAdmin={feSiteRoles.isCompanyAdmin}
              isSiteAdminPayment={feSiteRoles.isSiteAdminPayment}
              onEditBilling={() => dispatch(billingActions.editBillingShowModal())}
            />
          </Box>
        )}
      </Paper>
    </>
  );
}
