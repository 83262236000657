import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHasFeature } from '../../components/Feature/Feature';
import featureFlags from '../../config/featureFlags';
import Modal from '../Modal';
import messages from './messages';

function UserAccessSettingsInfoModal() {
  const isBLEAccessEnable = useHasFeature(featureFlags.USER_BLE_CREDENTIAL);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { formatMessage } = useIntl();
  return (
    <>
      <Link component="button" onClick={() => setIsModalOpen(true)} variant="body2">
        <FormattedMessage {...messages.userAccessSettingsInfoModalTitle} />
      </Link>
      <Modal
        actions={
          <Button color="primary" onClick={() => setIsModalOpen(false)}>
            <FormattedMessage {...messages.userAccessSettingsInfoModalButton} />
          </Button>
        }
        fullWidth
        maxWidth="sm"
        name="user-access-settings-info-modal"
        onClose={() => setIsModalOpen(false)}
        open={isModalOpen}
        title={formatMessage(messages.userAccessSettingsInfoModalTitle)}
      >
        <FormattedMessage
          {...(isBLEAccessEnable
            ? messages.userAccessSettingsInfoModalContentWithBLE
            : messages.userAccessSettingsInfoModalContent)}
        />
      </Modal>
    </>
  );
}

export default UserAccessSettingsInfoModal;
