import { defineMessages } from 'react-intl';

export default defineMessages({
  paymentsOverviewInvoice: {
    defaultMessage: 'Invoice',
    description: 'Payments Overview - invoice',
    id: 'paymentsOverview.invoice',
  },
  paymentsOverviewTitle: {
    defaultMessage: 'Company payments',
    description: 'Payments Overview - title',
    id: 'paymentsOverview.title',
  },
});
