import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormikFields } from '../../../../../../components';
import messages from '../messages';

const NumberOfYears = ({ maxYears, minYears, name }) => (
  <FormikFields.Input
    fullWidth
    id="PromoBatchForm-yearsCount"
    InputLabelProps={{
      required: false,
      shrink: true,
    }}
    inputProps={{
      max: maxYears,
      min: minYears,
    }}
    InputProps={{
      endAdornment: (
        <Typography color="text.secondary">
          <FormattedMessage {...messages.promoBatchFormInputYearsCountAdornment} />
        </Typography>
      ),
    }}
    label={<FormattedMessage {...messages.promoBatchFormInputYearsCountLabel} />}
    margin="dense"
    name={`${name}.years`}
    required
    size="small"
    type="number"
  />
);

NumberOfYears.propTypes = {
  maxYears: PropTypes.number.isRequired,
  minYears: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
};

export default NumberOfYears;
