import { MOBILE_VIDEO_STATUS } from '../config/consts';
import { DEVICE_STATUS, DEVICE_TYPE } from '../config/devices';

export const getCountMobileVideoDevices = (devices) => {
  let countError = 0;
  let count = 0;
  devices.forEach((device) => {
    count += device.count - device[DEVICE_STATUS.INACTIVE];
    countError += device[DEVICE_STATUS.ERROR];
    countError += device[DEVICE_STATUS.WARN];
  });
  return { count, countError };
};

export const getCountMobileVideoFreeDevices = (devices) => {
  let countError = 0;
  let count = 0;
  devices.forEach((device) => {
    if (
      device.type === DEVICE_TYPE.IP_INTERCOM ||
      device.type === DEVICE_TYPE.IP_INTERCOM_V2 ||
      device.type === DEVICE_TYPE.IP_ONE_INTERCOM ||
      device.type === DEVICE_TYPE.IP_STYLE ||
      device.type === DEVICE_TYPE.ID_TALK ||
      device.type === DEVICE_TYPE.ID_COMPACT ||
      device.type === DEVICE_TYPE.IDT ||
      device.type === DEVICE_TYPE.ID_VIEW ||
      device.type === DEVICE_TYPE.CLIP ||
      device.type === DEVICE_TYPE.DOOR_STATION
    ) {
      count += device.count - device[DEVICE_STATUS.INACTIVE];
      countError += device[DEVICE_STATUS.ERROR];
      countError += device[DEVICE_STATUS.WARN];
    }
  });
  return { count, countError };
};

export const noPaidDevices = (licenceModel) => {
  if (
    [
      MOBILE_VIDEO_STATUS.TEMPORARY,
      MOBILE_VIDEO_STATUS.TRIAL_ACTIVE,
      MOBILE_VIDEO_STATUS.STANDARD,
      MOBILE_VIDEO_STATUS.STANDARD_RENEWAL,
    ].includes(licenceModel?.status)
  ) {
    if (licenceModel?.requirePaymentCount === 0) {
      return true;
    }
  }
  return false;
};
