export const moduleName = 'company';

export default {
  ADD_COMPANY_FAILURE: `${moduleName}/ADD_COMPANY_FAILURE`,
  ADD_COMPANY_REQUEST: `${moduleName}/ADD_COMPANY_REQUEST`,
  ADD_COMPANY_ROLE_FAILURE: `${moduleName}/ADD_COMPANY_ROLE_FAILURE`,
  ADD_COMPANY_ROLE_REQUEST: `${moduleName}/ADD_COMPANY_ROLE_REQUEST`,
  ADD_COMPANY_ROLE_SUCCESS: `${moduleName}/ADD_COMPANY_ROLE_SUCCESS`,
  ADD_COMPANY_SUCCESS: `${moduleName}/ADD_COMPANY_SUCCESS`,
  CHANGE_SETTINGS_FAILURE: `${moduleName}/CHANGE_SETTINGS_FAILURE`,
  CHANGE_SETTINGS_REQUEST: `${moduleName}/CHANGE_SETTINGS_REQUEST`,
  CHANGE_SETTINGS_SUCCESS: `${moduleName}/CHANGE_SETTINGS_SUCCESS`,
  CLEAR_COMPANY: `${moduleName}/CLEAR_COMPANY`,
  DELETE_COMPANY_FAILURE: `${moduleName}/DELETE_COMPANY_FAILURE`,
  DELETE_COMPANY_HIDE_MODAL: `${moduleName}/DELETE_COMPANY_HIDE_MODAL`,
  DELETE_COMPANY_REQUEST: `${moduleName}/DELETE_COMPANY_REQUEST`,
  DELETE_COMPANY_ROLE_FAILURE: `${moduleName}/DELETE_COMPANY_ROLE_FAILURE`,
  DELETE_COMPANY_ROLE_REQUEST: `${moduleName}/DELETE_COMPANY_ROLE_REQUEST`,
  DELETE_COMPANY_ROLE_SUCCESS: `${moduleName}/DELETE_COMPANY_ROLE_SUCCESS`,
  DELETE_COMPANY_SHOW_MODAL: `${moduleName}/DELETE_COMPANY_SHOW_MODAL`,
  DELETE_COMPANY_SUCCESS: `${moduleName}/DELETE_COMPANY_SUCCESS`,
  GET_COMPANY_DEVICE_TYPES_FAILURE: `${moduleName}/GET_COMPANY_DEVICE_TYPES_FAILURE`,
  GET_COMPANY_DEVICE_TYPES_REQUEST: `${moduleName}/GET_COMPANY_DEVICE_TYPES_REQUEST`,
  GET_COMPANY_DEVICE_TYPES_SUCCESS: `${moduleName}/GET_COMPANY_DEVICE_TYPES_SUCCESS`,
  GET_MV_SDK_SETTINGS_FAILURE: `${moduleName}/GET_MV_SDK_SETTINGS_FAILURE`,
  GET_MV_SDK_SETTINGS_REQUEST: `${moduleName}/GET_MV_SDK_SETTINGS_REQUEST`,
  GET_MV_SDK_SETTINGS_SUCCESS: `${moduleName}/GET_MV_SDK_SETTINGS_SUCCESS`,
  LOAD_COMPANY_FAILURE: `${moduleName}/LOAD_COMPANY_FAILURE`,
  LOAD_COMPANY_REQUEST: `${moduleName}/LOAD_COMPANY_REQUEST`,
  LOAD_COMPANY_SUCCESS: `${moduleName}/LOAD_COMPANY_SUCCESS`,
  RESET_MV_SDK_SETTINGS_FAILURE: `${moduleName}/RESET_MV_SDK_SETTINGS_FAILURE`,
  RESET_MV_SDK_SETTINGS_REQUEST: `${moduleName}/RESET_MV_SDK_SETTINGS_REQUEST`,
  RESET_MV_SDK_SETTINGS_SUCCESS: `${moduleName}/RESET_MV_SDK_SETTINGS_SUCCESS`,
  UPDATE_MV_SDK_SETTINGS_FAILURE: `${moduleName}/UPDATE_MV_SDK_SETTINGS_FAILURE`,
  UPDATE_MV_SDK_SETTINGS_REQUEST: `${moduleName}/UPDATE_MV_SDK_SETTINGS_REQUEST`,
  UPDATE_MV_SDK_SETTINGS_SUCCESS: `${moduleName}/UPDATE_MV_SDK_SETTINGS_SUCCESS`,
};
