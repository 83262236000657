import { defineMessages } from 'react-intl';

export default defineMessages({
  UserInfoBoxChangeLanguage: {
    defaultMessage: 'Change language',
    description: 'User Info Box - Change language button',
    id: 'userInfoBox.changeLanguage',
  },
  UserInfoBoxChangePassword: {
    defaultMessage: 'Change password',
    description: 'User Info Box - Change password button',
    id: 'userInfoBox.changePassword',
  },
  UserInfoBoxEditProfile: {
    defaultMessage: 'Edit profile',
    description: 'User Info Box - Edit profile button',
    id: 'userInfoBox.editProfile',
  },
  UserInfoBoxGenerateNewPin: {
    defaultMessage: 'Generate new PIN',
    description: 'User Info Box - Generate new PIN',
    id: 'userInfoBox.generateNewPin',
  },
  UserInfoBoxInstallApp: {
    defaultMessage: 'Install My2N app',
    description: 'User Info Box - Install My2N app button',
    id: 'userInfoBox.installApp',
  },
  UserInfoBoxReceivingNewslettersDisabled: {
    defaultMessage: 'Receiving newsletters disabled',
    description: 'User Info Box - receiving newsletters disabled',
    id: 'userInfoBox.receivingNewslettersDisabled',
  },
  UserInfoBoxReceivingNewslettersEnabled: {
    defaultMessage: 'Receiving newsletters enabled',
    description: 'User Info Box - receiving newsletters enabled',
    id: 'userInfoBox.receivingNewslettersEnabled',
  },
});
