import ButtonMaterial from '@mui/material/Button';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import LoadingButton from '../LoadingButton';

const styles = {
  '&:hover, &:focus, &:active': {
    borderColor: 'error.dark',
  },
  borderColor: 'error.dark',
  color: 'error.dark',
};

const ButtonDanger = forwardRef(({ isLoading, ...props }, ref) =>
  isLoading ? (
    <LoadingButton {...props} color="secondary" isLoading={isLoading} ref={ref} sx={styles} variant="outlined" />
  ) : (
    <ButtonMaterial {...props} color="secondary" ref={ref} sx={styles} variant="outlined" />
  )
);

ButtonDanger.propTypes = {
  isLoading: PropTypes.bool,
};

ButtonDanger.defaultProps = {
  isLoading: false,
};

export default ButtonDanger;
