import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PATHS } from '../../config/consts';
import ButtonLink from '../ButtonLink';
import TableItem from '../TableItem';
import DevicesInGroupText from './DevicesInGroupText';
import messages from './messages';

const DevicesInGroupChips = ({ companyId, devices, isText, siteId }) =>
  isText ? (
    <DevicesInGroupText
      allCount={devices.length}
      inactiveCount={devices.filter((item) => !item.accessControl)?.length}
    />
  ) : (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        mb: 0.5,
      }}
    >
      {devices.length ? (
        devices.map((device) => (
          <Tooltip
            key={device.deviceId}
            placement="right"
            title={
              device?.accessControl ? (
                <FormattedMessage {...messages.accessSettingsDataGridChipsRacActive} />
              ) : (
                <FormattedMessage {...messages.accessSettingsDataGridChipsRacInactive} />
              )
            }
          >
            <Chip
              component={ButtonLink}
              label={device.deviceName}
              onClick={(event) => event.stopPropagation()}
              size="small"
              sx={{
                '& .MuiChip-label': {
                  textTransform: 'none',
                },
                '&:hover': { cursor: 'pointer' },
                borderColor: device?.accessControl ? 'primary.main' : 'warning.main',
                color: device?.accessControl ? 'primary.main' : 'warning.main',
                display: 'inline-grid',
                mr: 1,
                mt: 0.5,
              }}
              to={PATHS.DEVICE_ACCESS_SETTINGS.replace(':companyId', companyId)
                .replace(':siteId', siteId)
                .replace(':deviceId', device.deviceId)}
              variant="outlined"
            />
          </Tooltip>
        ))
      ) : (
        <TableItem isActive={false} itemText={<FormattedMessage {...messages.accessSettingsDataGridChipsEmpty} />} />
      )}
    </Box>
  );

DevicesInGroupChips.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  devices: PropTypes.arrayOf(PropTypes.object).isRequired,
  isText: PropTypes.bool.isRequired,
  siteId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default DevicesInGroupChips;
