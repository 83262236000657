import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  icon: {
    display: 'inline-flex',
    p: 0,
    pr: 2,
    typography: 'caption',
  },
  label: (minHeight = 0) => ({
    alignItems: 'flex-end',
    display: 'flex',
    minWidth: minHeight > 0 ? `${minHeight}px` : 'none',
    p: 0,
    pr: 1,
    typography: 'caption',
  }),
  labelHeadline: {
    color: 'secondary.main',
    typography: 'caption',
  },
  root: {
    alignItems: 'flex-end',
    display: 'flex',
    px: 1,
    py: 0.5,
  },
  unit: {
    color: 'secondary.main',
    p: 0,
    pl: 0.25,
  },
  value: {
    color: 'common.black',
    fontWeight: 'medium',
    typography: 'caption',
  },
  valueWrapper: {
    display: 'flex',
    typography: 'caption',
  },
};

const DetailLabel = ({ emptyLabel, label, minHeight, startIcon, unit, value }) => (
  <Box sx={styles.root}>
    {!emptyLabel && (
      <Box sx={styles.label(minHeight)}>
        {startIcon && <Box sx={styles.icon}>{startIcon}</Box>}
        <Box sx={styles.labelHeadline}>{label}</Box>
      </Box>
    )}
    <Box sx={styles.valueWrapper}>
      <Box sx={styles.value}>{value}</Box>
      {unit && (
        <Box component="span" sx={styles.unit}>
          {unit}
        </Box>
      )}
    </Box>
  </Box>
);

DetailLabel.propTypes = {
  emptyLabel: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  minHeight: PropTypes.number,
  startIcon: PropTypes.node,
  unit: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

DetailLabel.defaultProps = {
  emptyLabel: false,
  minHeight: 0,
  startIcon: null,
  unit: null,
};

export default DetailLabel;
