import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, { useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FooterPublic, HeaderPublic } from '../../components';
import { PATHS } from '../../config/consts';
import { logout, resendConfirmationEmailRequest, resetAuthStore } from '../../modules/auth/actions';
import { getResendEmail } from '../../modules/auth/selectors';
import { useAppContext } from '../App/AppContextProvider';
import messages from './messages';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const landingBgImage = require('../../assets/landing_bg.jpg');

const styles = {
  box: {
    backgroundColor: '#fff',
    borderRadius: '6px',
    boxSizing: 'border-box',
    mt: {
      sm: 7.5,
      xs: 3.25,
    },
    pb: {
      lg: 2,
      xl: 3.75,
      xs: 2,
    },
    pt: {
      lg: 5.75,
      xl: 7.5,
      xs: 3.75,
    },
    px: {
      lg: 5.75,
      xl: 7.5,
      xs: 3.75,
    },
    width: '100%',
  },
  boxContent: {
    maxWidth: '810px',
    mx: 'auto',
    my: 0,
    p: 1.25,
    width: '100%',
  },
  boxDescription: {
    mb: 5,
  },
  boxTitle: {
    mb: 5,
    mt: 1,
    textAlign: 'center',
  },
  btnResend: {
    mb: 3,
    textAlign: 'center',
  },
  sub: {
    '& a': {
      color: 'primary.main',
      fontSize: '16.8px',
      fontWeight: 'bold',
    },
    color: '#fff',
    fontWeight: 'regular',
    pb: 1,
    textAlign: 'center',
  },
  title: {
    color: '#fff',
    fontSize: '32px',
    fontWeight: 'bold',
    mb: 3.25,
    mt: {
      sm: 7,
      xl: 15.75,
    },
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  wrapper: {
    backgroundImage: `url(${landingBgImage})`,
    backgroundPosition: '50% 20%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    minHeight: '980px',
  },
};

export function Resend() {
  const dispatch = useDispatch();
  const storedResendEmail = useSelector(getResendEmail);
  const emailToResend = useRef('');
  const { loggedIn } = useAppContext();

  const resendEmail = () => {
    if (emailToResend.current) {
      dispatch(resendConfirmationEmailRequest({ email: emailToResend.current }));
    }
  };

  useEffect(() => {
    if ((storedResendEmail?.length ?? 0) === 0 && loggedIn) {
      dispatch(logout());
      return;
    }

    if (storedResendEmail) {
      emailToResend.current = storedResendEmail;
      dispatch(resetAuthStore());
    }
  }, [loggedIn, storedResendEmail]);

  return (
    <>
      <HeaderPublic />
      <Box sx={styles.wrapper}>
        <Box sx={styles.boxContent}>
          <Typography sx={styles.title} variant="h1">
            <FormattedMessage {...messages.resendLogin} />
          </Typography>
          <Box sx={styles.box}>
            <Typography sx={styles.boxTitle} variant="h5">
              <FormattedMessage {...messages.resendTitle} />
            </Typography>
            <Typography sx={styles.boxDescription}>
              <FormattedMessage {...messages.resendDescription} />
            </Typography>
            <Box sx={styles.btnResend}>
              <Button
                color="primary"
                id="login-button"
                onClick={resendEmail}
                size="large"
                type="submit"
                variant="contained"
              >
                <FormattedMessage {...messages.resendBtnResend} />
              </Button>
            </Box>
            <Typography sx={styles.sub}>
              <Link to={PATHS.LOGIN}>
                <FormattedMessage {...messages.resendBackToLogin} />
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
      <FooterPublic />
    </>
  );
}
