import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import PropTypes from 'prop-types';
import React from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from '../../../components/Navbar';
import Sidebar from '../../../components/Sidebar';
import { PATHS } from '../../../config/consts';
import { styles as sharedStyles } from './styles';

const styles = {
  contentWithVersionWarning: {
    mt: (theme) => ({
      md: (theme.navBarHeight + theme.appNotificationHeightLargeDevice) / 8 + 3,
      xs: (theme.navBarHeight + theme.appNotificationHeightSmallDevice) / 8 + 3,
    }),
  },
  root: (serviceAlertHeight, isServiceAlertOpen) => ({
    mb: 3,
    ml: { md: '255px' },
    mt: serviceAlertHeight !== null && isServiceAlertOpen ? `${serviceAlertHeight + 88}px` : 11,
    width: 'auto',
  }),
};

const StandardContent = ({
  children,
  collapse,
  company,
  isCompanyAdmin,
  isServiceAlertOpen,
  isSiteAdmin,
  isVersionWarningDisplayed,
  serviceAlertHeight,
}) => {
  const isAdmin = Object.keys(company).length > 0 && (isSiteAdmin || isCompanyAdmin);

  return (
    <>
      <Navbar
        isCompanyAdmin={isCompanyAdmin}
        isSiteAdmin={isSiteAdmin}
        isVersionWarningDisplayed={isVersionWarningDisplayed}
      />
      <Box sx={{ display: { md: 'block', xs: 'none' } }}>
        <Sidebar isVersionWarningDisplayed={isVersionWarningDisplayed} />
      </Box>
      <Box
        sx={{
          mt: (theme) => theme.navBarHeight / 8,
          ...(isVersionWarningDisplayed && styles.contentWithVersionWarning),
        }}
      >
        {children && (
          <Container
            sx={{
              ...styles.root(serviceAlertHeight, isServiceAlertOpen),
              ...(isAdmin && collapse && sharedStyles.collapseSidebar),
              ...(!isAdmin && sharedStyles.noSidebar),
            }}
          >
            {children}
          </Container>
        )}
      </Box>
    </>
  );
};

StandardContent.propTypes = {
  children: PropTypes.node,
  collapse: PropTypes.bool,
  company: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  isCompanyAdmin: PropTypes.bool,
  isServiceAlertOpen: PropTypes.bool.isRequired,
  isSiteAdmin: PropTypes.bool,
  isVersionWarningDisplayed: PropTypes.bool,
  serviceAlertHeight: PropTypes.number,
};

StandardContent.defaultProps = {
  children: null,
  collapse: false,
  company: {},
  isCompanyAdmin: false,
  isSiteAdmin: false,
  isVersionWarningDisplayed: false,
  serviceAlertHeight: null,
};

export default StandardContent;
