export const moduleName = 'apartmentAdmin';

export const types = {
  ACTIVATE_USER_PIN_FAILURE: `${moduleName}/ACTIVATE_USER_PIN_FAILURE`,
  ACTIVATE_USER_PIN_REQUEST: `${moduleName}/ACTIVATE_USER_PIN_REQUEST`,
  ACTIVATE_USER_PIN_SUCCESS: `${moduleName}/ACTIVATE_USER_PIN_SUCCESS`,

  CHANGE_DEVICE_MV_STATUS_FAILURE: `${moduleName}/CHANGE_DEVICE_MV_STATUS_FAILURE`,
  CHANGE_DEVICE_MV_STATUS_REQUEST: `${moduleName}/CHANGE_DEVICE_MV_STATUS_REQUEST`,
  CHANGE_DEVICE_MV_STATUS_SUCCESS: `${moduleName}/CHANGE_DEVICE_MV_STATUS_SUCCESS`,

  CREATE_APARTMENT_USER_FAILURE: `${moduleName}/CREATE_APARTMENT_USER_FAILURE`,
  CREATE_APARTMENT_USER_REQUEST: `${moduleName}/CREATE_APARTMENT_USER_REQUEST`,
  CREATE_APARTMENT_USER_SUCCESS: `${moduleName}/CREATE_APARTMENT_USER_SUCCESS`,

  CREATE_DEVICE_WITHIN_APARTMENT_FAILURE: `${moduleName}/CREATE_DEVICE_WITHIN_APARTMENT_FAILURE`,
  CREATE_DEVICE_WITHIN_APARTMENT_REQUEST: `${moduleName}/CREATE_DEVICE_WITHIN_APARTMENT_REQUEST`,
  CREATE_DEVICE_WITHIN_APARTMENT_SUCCESS: `${moduleName}/CREATE_DEVICE_WITHIN_APARTMENT_SUCCESS`,

  CREATE_UWL_ASSIGN_PIN_FAILURE: `${moduleName}/CREATE_UWL_ASSIGN_PIN_FAILURE`,
  CREATE_UWL_ASSIGN_PIN_REQUEST: `${moduleName}/CREATE_UWL_ASSIGN_PIN_REQUEST`,
  CREATE_UWL_ASSIGN_PIN_SUCCESS: `${moduleName}/CREATE_UWL_ASSIGN_PIN_SUCCESS`,

  DELETE_DEVICE_WITHIN_APARTMENT_FAILURE: `${moduleName}/DELETE_DEVICE_WITHIN_APARTMENT_FAILURE`,
  DELETE_DEVICE_WITHIN_APARTMENT_REQUEST: `${moduleName}/DELETE_DEVICE_WITHIN_APARTMENT_REQUEST`,
  DELETE_DEVICE_WITHIN_APARTMENT_SUCCESS: `${moduleName}/DELETE_DEVICE_WITHIN_APARTMENT_SUCCESS`,

  DELETE_USER_PIN_FAILURE: `${moduleName}/DELETE_USER_PIN_FAILURE`,
  DELETE_USER_PIN_REQUEST: `${moduleName}/DELETE_USER_PIN_REQUEST`,
  DELETE_USER_PIN_SUCCESS: `${moduleName}/DELETE_USER_PIN_SUCCESS`,

  DELETE_USER_RFID_FAILURE: `${moduleName}/DELETE_USER_RFID_FAILURE`,
  DELETE_USER_RFID_REQUEST: `${moduleName}/DELETE_USER_RFID_REQUEST`,
  DELETE_USER_RFID_SUCCESS: `${moduleName}/DELETE_USER_RFID_SUCCESS`,

  GENERATE_USER_PIN_FAILURE: `${moduleName}/GENERATE_USER_PIN_FAILURE`,
  GENERATE_USER_PIN_REQUEST: `${moduleName}/GENERATE_USER_PIN_REQUEST`,
  GENERATE_USER_PIN_SUCCESS: `${moduleName}/GENERATE_USER_PIN_SUCCESS`,

  GET_APARTMENT_GROUPS_FAILURE: `${moduleName}/GET_APARTMENT_GROUPS_FAILURE`,
  GET_APARTMENT_GROUPS_REQUEST: `${moduleName}/GET_APARTMENT_GROUPS_REQUEST`,
  GET_APARTMENT_GROUPS_SUCCESS: `${moduleName}/GET_APARTMENT_GROUPS_SUCCESS`,

  GET_AVAILABLE_PIN_CLEAN: `${moduleName}/GET_AVAILABLE_PIN_CLEAN`,
  GET_AVAILABLE_PIN_FAILURE: `${moduleName}/GET_AVAILABLE_PIN_FAILURE`,
  GET_AVAILABLE_PIN_REQUEST: `${moduleName}/GET_AVAILABLE_PIN_REQUEST`,
  GET_AVAILABLE_PIN_SUCCESS: `${moduleName}/GET_AVAILABLE_PIN_SUCCESS`,

  GET_DEVICES_WITHIN_APARTMENT_FAILURE: `${moduleName}/GET_DEVICES_WITHIN_APARTMENT_FAILURE`,
  GET_DEVICES_WITHIN_APARTMENT_REQUEST: `${moduleName}/GET_DEVICES_WITHIN_APARTMENT_REQUEST`,
  GET_DEVICES_WITHIN_APARTMENT_SUCCESS: `${moduleName}/GET_DEVICES_WITHIN_APARTMENT_SUCCESS`,

  GET_USER_GROUPS_FAILURE: `${moduleName}/GET_USER_GROUPS_FAILURE`,
  GET_USER_GROUPS_REQUEST: `${moduleName}/GET_USER_GROUPS_REQUEST`,
  GET_USER_GROUPS_SUCCESS: `${moduleName}/GET_USER_GROUPS_SUCCESS`,

  GET_USER_PIN_FAILURE: `${moduleName}/GET_USER_PIN_FAILURE`,
  GET_USER_PIN_REQUEST: `${moduleName}/GET_USER_PIN_REQUEST`,
  GET_USER_PIN_SUCCESS: `${moduleName}/GET_USER_PIN_SUCCESS`,

  GET_USER_RFID_FAILURE: `${moduleName}/GET_USER_RFID_FAILURE`,
  GET_USER_RFID_REQUEST: `${moduleName}/GET_USER_RFID_REQUEST`,
  GET_USER_RFID_SUCCESS: `${moduleName}/GET_USER_RFID_SUCCESS`,

  GET_USERS_IN_APARTMENTS_FAILURE: `${moduleName}/GET_USERS_IN_APARTMENTS_FAILURE`,
  GET_USERS_IN_APARTMENTS_INVALIDATE: `${moduleName}/GET_USERS_IN_APARTMENTS_INVALIDATE`,
  GET_USERS_IN_APARTMENTS_REQUEST: `${moduleName}/GET_USERS_IN_APARTMENTS_REQUEST`,
  GET_USERS_IN_APARTMENTS_SUCCESS: `${moduleName}/GET_USERS_IN_APARTMENTS_SUCCESS`,
};
