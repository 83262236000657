import { defineMessages } from 'react-intl';

export default defineMessages({
  AAUserDetailAccessDeletePIN: {
    defaultMessage: 'Delete  ',
    id: 'AA.userDetail.access.deletePIN',
  },
  AAUserDetailAccessDeleteRFID: {
    defaultMessage: 'Delete  ',
    id: 'AA.userDetail.access.deleteRFID',
  },
  AAUserDetailAccessEditValidity: {
    defaultMessage: 'Edit',
    id: 'AA.userDetail.access.editValidity',
  },
  AAUserDetailAccessGeneratePIN: {
    defaultMessage: 'Generate',
    id: 'AA.userDetail.access.generatePIN',
  },
  AAUserDetailAccessShowEditPIN: {
    defaultMessage: 'Show / Edit',
    id: 'AA.userDetail.access.editPIN',
  },
  AAUserDetailAccessShowPIN: {
    defaultMessage: 'Show',
    id: 'AA.userDetail.access.showPIN',
  },
  AAUserDetailAccessShowRFID: {
    defaultMessage: 'Show',
    id: 'AA.userDetail.access.showRFID',
  },
});
