import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getOryLoginStatusSelector } from '../../../modules/auth/selectors';
import { OryLoginStatus } from '../../types';
import { useOryLoginApi } from '../oryApi';
import { IUseOryUserLogged, IUseOryUserLoggedProps } from './data';
import { checkOrySessionIfNeeded, isOryUserPotentiallyAlreadyLoggedIn } from './utils';

export function useOryUserLogged({ accessToken, currentUser }: IUseOryUserLoggedProps): IUseOryUserLogged {
  const dispatch = useDispatch();
  const oryLoginStatus = useSelector(getOryLoginStatusSelector);
  const [isOrySessionCheckResolved, setIsOrySessionCheckResolved] = useState(false);
  const { getOryCurrentUserSession, isOryApiFetching } = useOryLoginApi();

  useEffect(
    () =>
      checkOrySessionIfNeeded({
        accessToken,
        currentUser,
        dispatch,
        getOryCurrentUserSession,
        oryLoginStatus,
        setIsOrySessionCheckResolved,
      }),
    []
  );

  return {
    isOryApiFetching,
    isOryLoginVerificationDone: isOrySessionCheckResolved,
    isOryUserLoggedIn: !!(
      isOryUserPotentiallyAlreadyLoggedIn(accessToken, currentUser) &&
      oryLoginStatus === OryLoginStatus.SessionConfirmed
    ),
    oryLoginStatus,
  };
}
