import * as yup from 'yup';
import { OfflinePairingDto } from '../../apiRtk/accessControlApi';

export enum FormFieldNames {
  NoBLE = 'NoBLE',
  Acom = 'Acom',
  ManageBLE = 'ManageBLE',
  SelectId = 'SelectManageBLE',
  CustomId = 'CustomId',
}

export function getDefaultValues(offlinePairingDto: OfflinePairingDto) {
  let noBleDefaultValue: boolean | undefined;
  let acomDefaultValue: boolean | undefined;

  if (!offlinePairingDto?.offlinePairing && !offlinePairingDto?.acom) {
    noBleDefaultValue = true;
  } else if (offlinePairingDto?.offlinePairing && offlinePairingDto?.acom) {
    acomDefaultValue = true;
  }

  return {
    [FormFieldNames.NoBLE]: noBleDefaultValue,
    [FormFieldNames.Acom]: acomDefaultValue,
    [FormFieldNames.ManageBLE]: false,
    [FormFieldNames.SelectId]: '',
    [FormFieldNames.CustomId]: '',
  };
}

export function getValidationSchema() {
  return yup.object().shape({
    [FormFieldNames.NoBLE]: yup.boolean(),
    [FormFieldNames.Acom]: yup.boolean(),
    [FormFieldNames.ManageBLE]: yup.boolean().required(),
    [FormFieldNames.SelectId]: yup
      .string()
      .when([FormFieldNames.ManageBLE, FormFieldNames.NoBLE], ([manageBLE, noBLE], schema) => {
        if (noBLE === false) {
          return schema.notRequired();
        }
        return manageBLE ? schema.required() : schema;
      }),
    [FormFieldNames.CustomId]: yup
      .string()
      .trim()
      .when(
        [FormFieldNames.SelectId, FormFieldNames.ManageBLE, FormFieldNames.NoBLE],
        ([selectId, manageBLE, noBLE], schema) => {
          if (noBLE === false) {
            return schema.notRequired();
          }
          if (selectId === 'OwnId' && manageBLE) {
            return schema.required();
          }
          return schema.notRequired();
        }
      ),
  });
}
