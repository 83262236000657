import http, { encode } from './index';

export const getSiteApartments = async ({ company, params, site }) =>
  http.get(`/companies/${encode(company)}/sites/${encode(site)}/apartments`, {
    params,
  });

export const addApartment = async (company, site, values) =>
  http.post(`/companies/${encode(company)}/sites/${encode(site)}/apartments`, values);

export const addFloor = async (company, site, values) =>
  http.post(`/companies/${encode(company)}/sites/${encode(site)}/floors`, values);

export const getSiteFloors = async (company, site, params) =>
  http.get(`/companies/${encode(company)}/sites/${encode(site)}/floors`, {
    params,
  });

export const getApartmentDetail = async (company, site, apartment) =>
  http.get(`/companies/${encode(company)}/sites/${encode(site)}/apartments/${encode(apartment)}`);

export const editApartment = async (company, site, apartment, values) =>
  http.put(`/companies/${encode(company)}/sites/${encode(site)}/apartments/${encode(apartment)}`, values);

export const deleteApartment = async (company, site, apartment) =>
  http.delete(`/companies/${encode(company)}/sites/${encode(site)}/apartments/${encode(apartment)}`);

export const getApartmentDevices = async (company, site, apartment, params) =>
  http.get(`/companies/${encode(company)}/sites/${encode(site)}/apartments/${encode(apartment)}/devices`, {
    params,
  });
