import { useParams } from 'react-router-dom';

type IUrlParams = {
  companyId?: string;
  deviceId?: string;
  phonebookId?: string;
  siteId?: string;
  userId?: string;
  apartmentId?: string;
  groupId?: string;
};

export type IParams = {
  companyId?: number;
  deviceId?: number;
  phonebookId?: number;
  siteId?: number;
  userId?: number;
  apartmentId?: number;
  groupId?: string;
};

export function useUrlParams(): IParams {
  const params: IUrlParams = useParams();

  const companyId = params?.companyId ? parseInt(params.companyId, 10) : undefined;
  const deviceId = params?.deviceId ? parseInt(params.deviceId, 10) : undefined;
  const phonebookId = params?.phonebookId ? parseInt(params.phonebookId, 10) : undefined;
  const siteId = params?.siteId ? parseInt(params.siteId, 10) : undefined;
  const userId = params?.userId ? parseInt(params.userId, 10) : undefined;
  const apartmentId = params?.apartmentId ? parseInt(params.apartmentId, 10) : undefined;
  const groupId = params?.groupId ? params.groupId : undefined;

  return {
    apartmentId,
    companyId,
    deviceId,
    groupId,
    phonebookId,
    siteId,
    userId,
  };
}
