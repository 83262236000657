import { yupResolver } from '@hookform/resolvers/yup';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useUrlParams } from '../../helpers/urlParams';
import actions, { selectors } from '../../modules/accessSettings';
import AccessGroupAutocomplete from '../AccessGroupAutocomplete';
import useAccessGroupFormValidationSchema from '../AccessGroupAutocomplete/useAccessGroupFormValidationSchema';
import PrimaryModal from '../PrimaryModal';
import messages from './messages';

// eslint-disable-next-line max-statements
const DeviceAccessGroupsModal = ({ onClose, open }) => {
  const hasFormItemKey = 'hasForm';
  const newItemId = 'newItemId';
  const { deviceId } = useUrlParams();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const data = {
    groups: useSelector(selectors.getAccessGroupsListAll),
    isLoading: useSelector(selectors.isCreateAddDeviceAccessGroupsFetching),
    isLoadingDeviceGroups: useSelector(selectors.getAccessGroupDeviceGroupsFetching),
    selectedGroups: useSelector(selectors.getDeviceAccessGroupsList),
  };

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(useAccessGroupFormValidationSchema()),
    shouldUnregister: true,
  });
  const { isDirty, isValid } = methods.formState;

  useEffect(() => {
    methods.reset({
      group: data.selectedGroups,
      [hasFormItemKey]: false,
      name: '',
    });
  }, [data.selectedGroups]);

  useEffect(() => {
    if (open) {
      dispatch(actions.getAccessGroupsListAll());
      dispatch(actions.getDeviceAccessGroupsList(deviceId));
    }
  }, [open]);

  const handleAddGroups = (form) => {
    const newGroupName = form?.name;
    const currentGroups = [];
    form.group.forEach((item) => {
      if (item.id !== newItemId) {
        currentGroups.push(item.id);
      }
    });
    dispatch(actions.addDeviceAccessGroupsWithCreate(deviceId, newGroupName, currentGroups));

    onClose();
  };

  return !data.isLoadingDeviceGroups ? (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleAddGroups)}>
        <PrimaryModal
          actionButtonLabel={formatMessage(messages.deviceAccessGroupsModalAddButtonLabel)}
          isActionButtonDisabled={!isDirty || !isValid}
          isLoading={data.isLoading}
          name="device-access-groups-dialog"
          onClose={onClose}
          onCreate={handleAddGroups}
          open={open || data.isLoading}
          title={formatMessage(messages.deviceAccessGroupsModalTitle)}
        >
          <ModalText />
          <AccessGroupAutocomplete hasFormItemKey={hasFormItemKey} newItemId={newItemId} optionsData={data.groups} />
        </PrimaryModal>
      </form>
    </FormProvider>
  ) : (
    <>{false}</>
  );
};

function ModalText() {
  return (
    <Typography component="span" variant="body1">
      <FormattedMessage {...messages.deviceAccessGroupsModalText} values={{ p: (chunks) => <p>{chunks}</p> }} />
    </Typography>
  );
}

DeviceAccessGroupsModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default DeviceAccessGroupsModal;
