import { ReactComponent as ThirdPartyAxisSIP } from '../../assets/ThirdPartyAxisSIP.svg';
import { DEVICE_TYPE } from '../../config/devices';
import { getTypeFromDeviceType } from '../../helpers/devices';

const UNKNOWN_DEVICE = 'unknown_device';

export function getIcon(deviceType: string, type?: string) {
  if (deviceType) {
    const model = getModelFromDeviceType(deviceType);
    if (model !== undefined && modelsTypeIconMap[model] !== undefined) {
      return modelsTypeIconMap[model];
    } else {
      const typeDevice = getTypeFromDeviceType(deviceType);
      if (typeDevice !== undefined && deviceTypeIconMap[typeDevice] !== undefined) {
        return deviceTypeIconMap[typeDevice];
      }
    }
  }
  if (type !== undefined && deviceTypeIconMap[type] !== undefined) {
    return deviceTypeIconMap[type];
  }
  return UNKNOWN_DEVICE;
}

export function getModelFromDeviceType(type?: string): string | undefined {
  if (type === undefined) {
    return undefined;
  }

  if (specificDevices[type] !== undefined) {
    return specificDevices[type];
  }

  const genericType = type?.split('.').slice(0, 2).join('.');
  if (genericDevices[genericType] !== undefined) {
    return genericDevices[genericType];
  }

  return undefined;
}

export const devicesWithSvgIcon: { [key: string]: unknown } = {
  [DEVICE_TYPE.AXIS_THIRD_PARTY]: ThirdPartyAxisSIP,
};

const specificDevices: { [key: string]: string } = {
  '2.10.1.0': 'IP_AUDIO_KIT_LITE',
  '2.14.0.2': 'ZENNIO_IP_VERSO',
  '2.14.0.6': 'CONTROL4_DS2_DOOR_STATION',
  '2.14.1.0': 'IP_VERSO_LTE',
  '2.21.0.2': 'ZENNIO_IP_SOLO',
  '2.21.0.6': 'CONTROL4_DS2_DOOR_STATION_MINI',
};

const genericDevices: { [key: string]: string } = {
  '2.0': 'IP_VARIO',
  2.3: 'IP_FORCE',
  2.5: 'IP_SAFETY',
  2.7: 'IP_UNI',
  2.9: 'SIP_AUDIO_CONVERTER',
  '2.10': 'IP_AUDIO_KIT',
  2.12: 'IP_VIDEO_KIT',
  2.14: 'IP_VERSO',
  2.16: 'ACCESS_UNIT',
  2.18: 'SIP_SPEAKER',
  2.19: 'IP_BASE',
  2.21: 'IP_SOLO',
  2.27: 'ACCESS_UNIT_M',
  2.28: 'ACCESS_UNIT',
  2.32: 'IP_VERSO_V2',
  2.35: 'CHAMELEON',
};

const deviceTypeIconMap: { [key: string]: string } = {
  [DEVICE_TYPE.HIPMO]: 'mobile',
  [DEVICE_TYPE.IDT]: 'idt',
  [DEVICE_TYPE.THIRD_PARTY]: 'third-party',
  [DEVICE_TYPE.DOOR_STATION]: 'axis-ds',
  [DEVICE_TYPE.ID_TALK]: 'indoortalk',
  [DEVICE_TYPE.CLIP]: 'clip',
  [DEVICE_TYPE.CHAMELEON]: 'auqr',
  [DEVICE_TYPE.ID_COMPACT]: 'idcompact',
  [DEVICE_TYPE.ACCESS_UNIT]: 'access_unit',
  [DEVICE_TYPE.ACCESS_UNIT_M]: 'access_unit_m',
  [DEVICE_TYPE.ID_VIEW]: 'idview',
  [DEVICE_TYPE.IP_STYLE]: 'ipstyle',
  [DEVICE_TYPE.IP_INTERCOM_V2]: 'verso',
  [DEVICE_TYPE.IP_ONE_INTERCOM]: 'ipone',
};

const modelsTypeIconMap: { [key: string]: string } = {
  ACCESS_UNIT: 'access_unit',
  ACCESS_UNIT_M: 'access_unit_m',
  CHAMELEON: 'auqr',
  CLIP: UNKNOWN_DEVICE,
  CONTROL4_DS2_DOOR_STATION: 'verso',
  CONTROL4_DS2_DOOR_STATION_MINI: 'solo',
  INDOOR_TALK: UNKNOWN_DEVICE,
  IP_AUDIO_KIT: UNKNOWN_DEVICE,
  IP_AUDIO_KIT_LITE: UNKNOWN_DEVICE,
  IP_BASE: 'base',
  IP_FORCE: 'force',
  IP_SAFETY: 'safety',
  IP_SOLO: 'solo',
  IP_UNI: 'uni',
  IP_VARIO: 'vario',
  IP_VERSO: 'verso',
  IP_VERSO_LTE: 'verso',
  IP_VERSO_V2: 'verso',
  IP_VIDEO_KIT: UNKNOWN_DEVICE,
  SIP_AUDIO_CONVERTER: UNKNOWN_DEVICE,
  SIP_SPEAKER: UNKNOWN_DEVICE,
  UNKNOWN: UNKNOWN_DEVICE,
  ZENNIO_IP_SOLO: 'solo',
  ZENNIO_IP_VERSO: 'verso',
};
