import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ButtonLink } from '../../components';
import { PATHS } from '../../config/consts';
import messages from './messages';

const styles = {
  boxes: {
    display: 'flex',
    justifyContent: 'space-between',
    my: 4,
  },
  footer: {
    color: 'secondary.main',
    mt: 4,
    textAlign: 'center',
  },
  footerText: {
    fontSize: '80%',
  },
  info: {
    textAlign: 'center',
  },
  item: {
    border: '1px solid',
    borderColor: 'divider',
    borderTopColor: 'primary.main',
    borderTopWidth: '5px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    px: {
      md: 8,
      sm: 6,
      xs: 2,
    },
    py: 6,
  },
  itemTitle: {
    fontSize: '18px',
    fontWeight: 'medium',
    mb: 4,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  longTermText: { color: 'text.secondary', fontSize: '18px', textAlign: 'center' },
  price: {
    '& strong': {
      fontSize: '75px',
      mr: 1,
    },
    '& sub': {
      fontSize: '18px',
    },
    '& sup': {
      fontSize: '24px',
      lineHeight: '75px',
      mr: 1,
      verticalAlign: 'top',
    },
    fontWeight: 500,
    mx: 'auto',
    my: 0,
    textAlign: 'center',
  },
  priceDescription: {
    display: 'block',
    fontSize: '13px',
    mb: 2,
    opacity: '.5',
  },
  root: {
    backgroundColor: 'common.white',
    width: '100%',
  },
  sticker: {
    backgroundColor: 'success.main',
    color: 'common.white',
    fontSize: '14px',
    left: 'auto',
    lineHeight: '36px',
    position: 'absolute',
    right: '-50px',
    textAlign: 'center',
    textTransform: 'uppercase',
    top: '25px',
    transform: 'rotate(45deg)',
    userSelect: 'none',
    width: '200px',
  },
  title: {
    color: 'common.black',
    fontSize: '40px',
    fontWeight: 'regular',
    mb: 4,
  },
  wrapper: {
    maxWidth: '1050px',
    mx: 'auto',
    px: 2,
    py: 9,
    width: '100%',
  },
};

const HowToStartUsing = () => (
  <Box sx={styles.root}>
    <Box sx={styles.wrapper}>
      <Box sx={styles.info}>
        <Typography component="h2" sx={styles.title}>
          <FormattedMessage
            {...messages.mvideoHowToStartUsingTitle}
            values={{ sup: (chunks) => <sup>{chunks}</sup> }}
          />
        </Typography>
        <Typography paragraph>
          <FormattedMessage
            {...messages.mvideoHowToStartUsingDescription1}
            values={{ sup: (chunks) => <sup>{chunks}</sup> }}
          />
        </Typography>
        <Typography paragraph>
          <FormattedMessage {...messages.mvideoHowToStartUsingDescription2} />
        </Typography>
      </Box>
      <Box sx={styles.boxes}>
        <Grid container spacing={4}>
          <Grid item md={6} xs={12}>
            <Box sx={styles.item}>
              <Typography component="h3" sx={styles.itemTitle}>
                <FormattedMessage {...messages.mvideoHowToStartUsingSubscribeOnlineTitle} />
              </Typography>
              <Box>
                <Box alignItems="center" display="flex" flexDirection="column" justifyContent="center">
                  <Box display="flex" justifyContent="center">
                    <Typography sx={styles.price}>
                      <FormattedMessage
                        {...messages.mvideoHowToStartUsingSubscribeOnlinePrice}
                        values={{
                          strong: (chunks) => <strong>{chunks}</strong>,
                          sub: (chunks) => <sub>{chunks}</sub>,
                          sup: (chunks) => <sup>{chunks}</sup>,
                        }}
                      />
                    </Typography>
                  </Box>
                  <Typography component="small" sx={styles.priceDescription}>
                    <FormattedMessage {...messages.mvideoHowToStartUsingSubscribeOnlinePriceDescription} />
                  </Typography>
                  <Chip
                    label={<FormattedMessage {...messages.mvideoHowToStartUsingSubscribeOnlineChip} />}
                    sx={{ my: 1 }}
                  />
                </Box>
                <Box sx={{ mb: 4, mt: 2 }}>
                  <Typography fontWeight="bold">
                    <FormattedMessage {...messages.mvideoHowToStartUsingSubscribeOnlineSuitable} />
                  </Typography>
                  <Typography>
                    <FormattedMessage {...messages.mvideoHowToStartUsingSubscribeOnlineSuitableDescription} />
                  </Typography>
                  <Typography fontWeight="bold" sx={{ mt: 2 }}>
                    <FormattedMessage {...messages.mvideoHowToStartUsingSubscribeOnlinePaymentMethod} />
                  </Typography>
                  <Typography>
                    <FormattedMessage {...messages.mvideoHowToStartUsingSubscribeOnlinePaymentMethodDescription} />
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" justifyContent="center" sx={{ mt: { md: 'auto', xs: 9 } }}>
                <ButtonLink color="primary" size="large" to={PATHS.REGISTER} variant="contained">
                  <FormattedMessage {...messages.mvideoHowToStartUsingSubscribeOnlineCreateAccount} />
                </ButtonLink>
              </Box>
              <Box sx={styles.sticker}>
                <FormattedMessage {...messages.mvideoHowToStartUsingSubscribeOnlineBasic} />
              </Box>
            </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            <Box sx={styles.item}>
              <Typography component="h3" sx={styles.itemTitle}>
                <FormattedMessage {...messages.mvideoHowToStartUsingPurchaseFromDistributorTitle} />
              </Typography>
              <Box>
                <Typography sx={styles.longTermText}>
                  <FormattedMessage {...messages.mvideoHowToStartUsingPurchaseFromDistributorDescription} />
                </Typography>
                <Box display="flex" flexDirection="column" sx={{ my: 2 }}>
                  <Chip
                    label={<FormattedMessage {...messages.mvideoHowToStartUsingPurchaseFromDistributorChip1} />}
                    sx={{ my: 1 }}
                  />
                  <Chip
                    label={<FormattedMessage {...messages.mvideoHowToStartUsingPurchaseFromDistributorChip2} />}
                    sx={{ my: 1 }}
                  />
                </Box>
              </Box>
              <Box sx={{ mb: 4, mt: 2 }}>
                <Typography fontWeight="bold">
                  <FormattedMessage {...messages.mvideoHowToStartUsingPurchaseFromDistributorSuitable} />
                </Typography>
                <Typography>
                  <FormattedMessage {...messages.mvideoHowToStartUsingPurchaseFromDistributorSuitableDescription} />
                </Typography>
                <Typography fontWeight="bold" sx={{ mt: 2 }}>
                  <FormattedMessage {...messages.mvideoHowToStartUsingPurchaseFromDistributorPaymentMethod} />
                </Typography>
                <Typography>
                  <FormattedMessage {...messages.mvideoHowToStartUsingPurchaseFromDistributorPaymentDescription} />
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center" sx={{ mt: 9 }}>
                <Button
                  color="primary"
                  component="a"
                  href="https://www.2n.com/en_GB/how-to-buy/where-to-buy"
                  size="large"
                  target="_blank"
                  variant="contained"
                >
                  <FormattedMessage {...messages.mvideoHowToStartUsingPurchaseFromDistributorFindDistributor} />
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={styles.footer}>
        <Typography paragraph sx={styles.footerText}>
          <FormattedMessage {...messages.mvideoHowToStartUsingFooterDescription1} />
        </Typography>
        <Typography paragraph sx={styles.footerText}>
          <FormattedMessage {...messages.mvideoHowToStartUsingFooterDescription2} />
        </Typography>
      </Box>
    </Box>
  </Box>
);

export default HowToStartUsing;
