import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert } from '../../../components';
import messages from './messages';

const ConnectionWarningAlert = ({ deviceName }) => (
  <Alert severity="warning">
    <FormattedMessage {...messages.connectionWarningAlertWarning} values={{ deviceName }} />
  </Alert>
);

ConnectionWarningAlert.propTypes = {
  deviceName: PropTypes.string.isRequired,
};

export default ConnectionWarningAlert;
