import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import React from 'react';
import TextField from '../TextField';

const UserSelectFieldOption = ({ classesKey, labelKey, options, valueKey, ...props }) => (
  <TextField
    {...props}
    select
    SelectProps={{
      MenuProps: {
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'bottom',
        },
      },
    }}
  >
    {options.map((item) => (
      <MenuItem classes={item[classesKey]} key={item[valueKey]} value={item[valueKey]}>
        {item[labelKey]}
      </MenuItem>
    ))}
  </TextField>
);

UserSelectFieldOption.propTypes = {
  classesKey: PropTypes.string,
  labelKey: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  valueKey: PropTypes.string,
};

UserSelectFieldOption.defaultProps = {
  classesKey: 'classes',
  labelKey: 'label',
  valueKey: 'value',
};

export default UserSelectFieldOption;
