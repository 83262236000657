import { defineMessages } from 'react-intl';

export default defineMessages({
  accessGroupApartmentRemoveModalRemove: {
    defaultMessage: 'Remove',
    description: 'Access group apartment remove modal - remove button',
    id: 'accessGroupApartmentRemoveModal.remove',
  },
  accessGroupApartmentRemoveModalTextNoUsers: {
    defaultMessage:
      'The <strong>{apartmentName}</strong> contains 0 users.' +
      '<p>No users will lose access via devices in the <strong>{groupName}</strong> Access group.</p>',
    description: 'Access group apartment remove modal - text no users',
    id: 'accessGroupApartmentRemoveModal.text.noUsers',
  },
  accessGroupApartmentRemoveModalTextTitle: {
    defaultMessage: 'Remove from Access group',
    description: 'Access group apartment remove modal - title',
    id: 'accessGroupApartmentRemoveModal.title',
  },
  accessGroupApartmentRemoveModalTextUsers: {
    defaultMessage:
      '{users, plural, one {# user} other {# users}} from the <strong>{apartmentName}</strong>' +
      ' will no longer have access via devices in the <strong>{groupName}</strong> Access group.' +
      '<p>You can add removed users back to Access group anytime.</p>',
    description: 'Access group apartment remove modal - text users',
    id: 'accessGroupApartmentRemoveModal.text.users',
  },
});
