import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import siteSvg from '../../assets/site.svg';
import Modal from '../../components/Modal';
import { selectors } from '../../modules/user';
import messages from './messages';

const styles = {
  flex: {
    alignItems: 'start',
    display: 'flex',
    textAlign: 'left',
  },
  info: {
    '& svg': {
      m: 2,
    },
    background: 'secondary.light',
    color: 'secondary.main',
    px: 0,
    py: 1,
  },
  root: {
    '& a': {
      color: 'primary.main',
    },
    '& ul': {
      mt: 0,
    },
    color: 'grey.800',
    fontSize: '14px',
  },
};

function SiteAboutModal({ onAddSiteModal, onClose, onOpenCreateCompanyModal }) {
  const companies = useSelector(selectors.getUserCompanies);

  return (
    <Modal
      actions={
        <div>
          <Button
            color="primary"
            onClick={() => {
              onClose();
              onOpenCreateCompanyModal();
            }}
          >
            <FormattedMessage {...messages.siteAboutModalCreateCompany} />
          </Button>
          {companies.length > 0 && (
            <Button
              color="primary"
              onClick={() => {
                onClose();
                onAddSiteModal();
              }}
            >
              <FormattedMessage {...messages.siteAboutModalCreateSite} />
            </Button>
          )}
          <Button color="primary" onClick={onClose}>
            <FormattedMessage {...messages.siteAboutModalClose} />
          </Button>
        </div>
      }
      name="site-about-modal"
      onClose={onClose}
      open
      title={<FormattedMessage {...messages.siteAboutModalTitle} />}
    >
      <Box sx={styles.root}>
        <ul>
          <li>
            <FormattedMessage {...messages.siteAboutModalListItemOne} />
          </li>
          <li>
            <FormattedMessage {...messages.siteAboutModalListItemTwo} />
          </li>
          <li>
            <FormattedMessage {...messages.siteAboutModalListItemThree} />
          </li>
          <li>
            <FormattedMessage {...messages.siteAboutModalListItemFour} />
          </li>
        </ul>
        {companies.length > 0 && (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onClose();
              onAddSiteModal();
            }}
          >
            <FormattedMessage {...messages.siteAboutModalCreateNewSite} />
          </a>
        )}
        <img alt="My Site" src={siteSvg} />
        <Box sx={styles.info}>
          <Box sx={styles.flex}>
            <InfoOutlinedIcon />
            <div>
              <p>
                <FormattedMessage {...messages.siteAboutModalDescription} />
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    onClose();
                    onOpenCreateCompanyModal();
                  }}
                >
                  <FormattedMessage {...messages.siteAboutModalCreateNewCompany} />
                </a>
              </p>
              <p>
                <strong>
                  <FormattedMessage {...messages.siteAboutModalEachCompanyTitle} />
                </strong>
              </p>
              <ul>
                <li>
                  <FormattedMessage {...messages.siteAboutModalEachCompanyListItemOne} />
                </li>
                <li>
                  <FormattedMessage {...messages.siteAboutModalEachCompanyListItemTwo} />
                </li>
              </ul>
            </div>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

SiteAboutModal.propTypes = {
  onAddSiteModal: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  onOpenCreateCompanyModal: PropTypes.func.isRequired,
};

SiteAboutModal.defaultProps = {
  onAddSiteModal: null,
};

export default SiteAboutModal;
