import { FE_ROLES } from '../../config/consts';
import { ApartmentUserRole } from '../apartmentAdmin/data';
import { UserRoles } from './store';

export function getUserName({ firstName, lastName }: { firstName?: string; lastName: string }): string {
  return `${firstName || ''}${firstName ? ' ' : ''}${lastName}`;
}

export function isUserGuest(role: UserRoles | ApartmentUserRole) {
  return role === FE_ROLES.APARTMENT_GUEST || role === FE_ROLES.GUEST;
}
