import { defineMessages } from 'react-intl';

export default defineMessages({
  ActivatePaidServicesModalCancel: {
    defaultMessage: 'Cancel',
      id: 'activatePaidServices.modal.cancel',
  },
  ActivatePaidServicesModalCheckbox: {
    defaultMessage: 'Don’t show this again',
      id: 'activatePaidServices.modal.checkbox',
  },
  ActivatePaidServicesModalSubmit: {
    defaultMessage: 'Activate',
      id: 'activatePaidServices.modal.submit',
},
  ActivatePaidServicesModalText: {
    defaultMessage: 'Your <b>{credits}</b> credits will be used to cover the subscription for apartment <b>{apartment}</b>.' +
      '<p>The amount deducted from your credit pool is based on the number of days remaining in the month.</p>',
    id: 'activatePaidServices.modal.text',
  },
  ActivatePaidServicesModalTitle: {
    defaultMessage: 'Activate paid services',
      id: 'activatePaidServices.modal.title',
  },
});
