import { defineMessages } from 'react-intl';

export default defineMessages({
  deviceForFreeTooltipApartmentsFree: {
    defaultMessage: 'Mobile Video is free for all apartments using only 2N devices' + ' (no mobile phones or tablets).',
    description: '',
    id: 'deviceForFreeTooltip.apartmentsFree',
  },
  deviceForFreeTooltipDevicesFree: {
    defaultMessage: 'Mobile Video runs for free on all 2N devices',
    description: 'Device for free tooltip - devices free',
    id: 'deviceForFreeTooltip.devicesFree',
  },
  deviceForFreeTooltipFree: {
    defaultMessage: 'Free',
    description: 'Device for free tooltip - free',
    id: 'deviceForFreeTooltip.free',
  },
  FreeWithIntercomDeviceModelPopupText: {
    defaultMessage:
      'Having a 2N intercom on the site grants a free Mobile Video license' +
      'for up to {count} {count, plural, one {smartphone or tablet} other {smartphones or tablets}}',
    id: 'freeWithIntercom.deviceModel.popup.text',
  },
  FreeWithIntercomDeviceModelPopupTitle: {
    defaultMessage: 'Free with intercom',
    id: 'freeWithIntercom.deviceModel.popup.title',
  },
});
