import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Percent, SectionHeader } from '../../../../components';
import { LICENSE } from '../../../../config/subscription';
import messages from '../../messages';
import { mobileVideoOrderContentPropTypes } from '../../unitProps';
import PriceMessage from '../OneStep/PriceMessage';

const styles = {
  firstDivider: {
    mb: 3,
  },
  priceContainer: {
    pl: 1.5,
  },
  root: {
    mb: 3,
  },
  secondDivider: {
    mt: 3,
  },
  textContainer: {
    pb: {
      md: 0,
      xs: 2,
    },
    pt: 2,
  },
};

const OrderList = ({ currency, mobileVideoOrderContent, monthPercentage }) => {
  const getPercentMessage = () =>
    (mobileVideoOrderContent.purchaseType =
      'EXTENSION' &&
      (mobileVideoOrderContent.product === LICENSE.MOBILE_VIDEO_YEAR ||
        mobileVideoOrderContent.product === LICENSE.APARTMENT_MOBILE_VIDEO_YEAR)
        ? { ...messages.twoStepOrderListYearPercent }
        : { ...messages.twoStepOrderListMonthPercent });

  const isMDU = () =>
    [LICENSE.APARTMENT_MOBILE_VIDEO_MONTH, LICENSE.APARTMENT_MOBILE_VIDEO_YEAR].includes(
      mobileVideoOrderContent.product
    );

  return (
    <Grid container direction="column" sx={styles.root}>
      <SectionHeader headerText={<FormattedMessage {...messages.twoStepOrderListHeader} />} variant="h6" />
      {mobileVideoOrderContent && (
        <>
          <Grid container item sx={styles.textContainer}>
            <Grid item md={8} xs={12}>
              <Typography gutterBottom>
                <FormattedMessage
                  {...orderListProductMap[mobileVideoOrderContent.product]}
                  values={{
                    percent: (
                      <FormattedMessage
                        {...getPercentMessage()}
                        values={{
                          percent: <Percent number={monthPercentage} />,
                        }}
                      />
                    ),
                  }}
                />
              </Typography>
            </Grid>
            <Grid alignItems="center" container item md={4} sx={styles.priceContainer} xs={12}>
              <PriceMessage
                currency={currency}
                payment={{
                  unitFullPrice: mobileVideoOrderContent.unitFullPrice * mobileVideoOrderContent.percentage,
                  unitFullPriceVat: mobileVideoOrderContent.unitFullPriceVat * mobileVideoOrderContent.percentage,
                }}
              />
            </Grid>
          </Grid>
          <Grid container item>
            <Grid item md={8} xs={12}>
              <Typography gutterBottom>
                {isMDU() ? (
                  <FormattedMessage {...messages.twoStepOrderListCountApartment} />
                ) : (
                  <FormattedMessage {...messages.twoStepOrderListCountDevices} />
                )}
              </Typography>
            </Grid>
            <Grid alignItems="center" container item md={4} sx={styles.priceContainer} xs={12}>
              <Typography gutterBottom>{mobileVideoOrderContent.licences}</Typography>
            </Grid>
          </Grid>
          <Grid item sx={styles.secondDivider}>
            <Divider />
          </Grid>
        </>
      )}
    </Grid>
  );
};

OrderList.propTypes = {
  currency: PropTypes.string.isRequired,
  mobileVideoOrderContent: mobileVideoOrderContentPropTypes,
  monthPercentage: PropTypes.number,
};

OrderList.defaultProps = {
  mobileVideoOrderContent: null,
};

export default OrderList;

const orderListProductMap = {
  [LICENSE.APARTMENT_MOBILE_VIDEO_MONTH]: messages.twoStepOrderListAPARTMENT_MOBILE_VIDEO_MONTH,
  [LICENSE.APARTMENT_MOBILE_VIDEO_YEAR]: messages.twoStepOrderListAPARTMENT_MOBILE_VIDEO_YEAR,
  [LICENSE.MOBILE_VIDEO_MONTH]: messages.twoStepOrderListMOBILE_VIDEO_MONTH,
  [LICENSE.MOBILE_VIDEO_YEAR]: messages.twoStepOrderListMOBILE_VIDEO_YEAR,
};
