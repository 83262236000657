import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SITE_TYPE } from '../../../config/sites';
import actions, { selectors } from '../../../modules/apartments';
import { selectors as sitesSelectors } from '../../../modules/sites';
import { IApartmentFormProps, useGetApartmentFormData } from '../../ApartmentsOverview/hooks/useGetApartmentFormData';

export function useGetApartmentInfoData() {
  const dispatch = useDispatch();
  const floors = useSelector(selectors.getFloors);
  const siteType = useSelector(sitesSelectors.getCurrentSiteType);
  const apartmentDetailData = useSelector(selectors.getApartmentData);
  const didInvalidFloors = useSelector(selectors.getDidInvalidFloors);

  const initValues: IApartmentFormProps = {
    floor: apartmentDetailData.floor?.name || '',
    hasForm: true,
    isSiteMdu: siteType === SITE_TYPE.MDU,
    name: apartmentDetailData.name || '',
    number: apartmentDetailData.number,
    paidServicesActive: apartmentDetailData.paidServicesActive,
  };

  const { companyId, formMethods, licenceModel, siteId } = useGetApartmentFormData(initValues);

  useEffect(() => {
    if (apartmentDetailData && siteType) {
      formMethods.reset(initValues);
    }
  }, [apartmentDetailData, siteType]);

  useEffect(() => {
    dispatch(actions.getSiteFloorsList(companyId, siteId));
  }, [companyId, siteId]);

  useEffect(() => {
    if (didInvalidFloors) {
      dispatch(actions.getSiteFloorsList(companyId, siteId));
    }
  }, [didInvalidFloors]);

  const onSubmit = (values: IApartmentFormProps) => {
    dispatch(actions.editApartment(companyId, siteId, apartmentDetailData, floors, values));
  };

  return {
    apartmentDetailData,
    companyId,
    floors,
    formMethods,
    isSiteMdu: siteType === SITE_TYPE.MDU,
    licenceModel,
    onSubmit,
    siteId,
  };
}
