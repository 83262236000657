import { defineMessages } from 'react-intl';

export default defineMessages({
  authSnackbarConfirmationEmail: {
    defaultMessage: 'Confirmation e-mail was sent. Please check your e-mail box and follow the instructions.',
    description: 'Auth snackbar - Confirmation e-mail',
    id: 'authSnackbarConfirmationEmail',
  },
  authSnackbarInvitationEmail: {
    defaultMessage: 'Invitation email was sent successfully.',
    description: 'Auth snackbar - Invitation email',
    id: 'authSnackbarInvitationEmail',
  },
  authSnackbarPasswordReset: {
    defaultMessage: 'Password reset successful, a verification e-mail has been sent to your e-mail.',
    description: 'Auth snackbar - Password reset',
    id: 'authSnackbarPasswordReset',
  },
});
