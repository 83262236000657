import FolderSharedIcon from '@mui/icons-material/FolderShared';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import RowMenuItem from '../../DataGrid/RowMenuItem';
import messages from '../messages';

function SetApartment({ onOpenUserApartmentModal, setUserApartmentModalOpen, user }) {
  return (
    <RowMenuItem
      icon={<FolderSharedIcon />}
      label={<FormattedMessage {...messages.siteUsersRowMenuSetApartment} />}
      onClick={() => {
        onOpenUserApartmentModal(user);
        setUserApartmentModalOpen();
      }}
    />
  );
}

SetApartment.propTypes = {
  onOpenUserApartmentModal: PropTypes.func.isRequired,
  setUserApartmentModalOpen: PropTypes.func.isRequired,
  user: PropTypes.shape({}).isRequired,
};

export default SetApartment;
