import TransferWithinAStation from '@mui/icons-material/TransferWithinAStation';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { EmptyValue } from '../../EmptyValue';
import messages from '../messages';

function AccessIcon({ status }) {
  const { formatMessage } = useIntl();

  return status === 'ACTIVE' ? (
    <Tooltip title={formatMessage(messages.apartmentDataGridTooltipsAccessIcon, { br: <br /> })}>
      <TransferWithinAStation color="secondary" />
    </Tooltip>
  ) : (
    <EmptyValue />
  );
}

AccessIcon.propTypes = {
  status: PropTypes.string,
};

AccessIcon.defaultProps = {
  status: '',
};

export default AccessIcon;
