import PropTypes from 'prop-types';
import { LICENSE } from '../../config/subscription';

export const unitPurchaseProps = PropTypes.shape({
  licences: PropTypes.number,
  price: PropTypes.number,
  priceVat: PropTypes.number,
  unitFullPrice: PropTypes.number,
  unitFullPriceVat: PropTypes.number,
});

const unitNextPurchaseProps = PropTypes.shape({
  calculation: unitPurchaseProps,
  date: PropTypes.string,
});

const unitPricesProps = PropTypes.shape({
  chargeVat: PropTypes.bool,
  unitFullPrice: PropTypes.number,
  unitFullPriceVat: PropTypes.number,
});

export const pricesPropTypes = PropTypes.shape({
  [LICENSE.MOBILE_VIDEO_MONTH]: unitPricesProps,
  [LICENSE.MOBILE_VIDEO_YEAR]: unitPricesProps,
});

export const paymentPropTypes = PropTypes.shape({
  [LICENSE.MOBILE_VIDEO_MONTH]: unitPurchaseProps,
  [LICENSE.MOBILE_VIDEO_YEAR]: unitPurchaseProps,
});

export const nextPaymentPropTypes = PropTypes.shape({
  [LICENSE.MOBILE_VIDEO_MONTH]: unitNextPurchaseProps,
  [LICENSE.MOBILE_VIDEO_YEAR]: unitNextPurchaseProps,
});

export const mobileVideoOrderContentPropTypes = PropTypes.shape({
  licences: PropTypes.number,
  percentage: PropTypes.number,
  price: PropTypes.number,
  priceVat: PropTypes.number,
  product: PropTypes.string,
  unitFullPrice: PropTypes.number,
  unitFullPriceVat: PropTypes.number,
});

export const purchasePaymentResultPropTypes = PropTypes.shape({
  paymentResult: PropTypes.string,
  purchaseState: PropTypes.string,
});
