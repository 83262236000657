import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import Box from '@mui/material/Box';
import ListSubheader from '@mui/material/ListSubheader';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const styles = {
  createOption: {
    alignItems: 'center',
    color: 'primary.main',
    display: 'flex',
  },
  menuItem: {
    lineHeight: '22px',
  },
};

const ApartmentFormField = ({ apartments, disabled, onChange, required, value }) => {
  const getItems = () => {
    const items = [];
    if (!apartments || !Array.isArray(apartments)) {
      return items;
    }

    apartments
      .sort((a) => {
        if (a.floorName === '') {
          return -1
        }
        if (a.floorName !== '') {
          return 1
        }
        return 0
      })
      .forEach((item) => {
        items.push({
          id: null,
          name: item.floorName,
        });
        (item.items || []).forEach((option) => {
          items.push({
            id: option.id,
            name: option.name ? `${option.number} – ${option.name}` : option.number,
          });
        });
      });

    return items;
  };

  const onChangeApartment = (e) => {
    if (e.target.name === 'apartmentId') {
      onChange({
        target: {
          name: 'existApartment',
          value: e.target.value !== 'createNewApartment',
        },
      });
    }
    onChange(e);
  };

  return (
    <Box mt={2}>
      <TextField
        disabled={disabled}
        fullWidth
        helperText={<FormattedMessage {...messages.apartmentFormFieldHelperText} />}
        label={<FormattedMessage {...messages.apartmentFormFieldLabel} />}
        margin="none"
        name="apartmentId"
        onChange={onChangeApartment}
        required={required}
        select
        SelectProps={{
          MenuProps: {
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom',
            },
          },
        }}
        value={value || 'placeholder'}
        variant="outlined"
        InputProps={{
          sx: {
            ...(value === 'createNewApartment' && { color: 'primary.main' }),
          },
        }}
      >
        <MenuItem key={0} sx={styles.menuItem} value="placeholder">
          <FormattedMessage {...messages.apartmentFormFieldEmptySelect} />
        </MenuItem>
        <MenuItem key={-1} sx={styles.createOption} value="createNewApartment">
          <CreateNewFolderIcon fontSize="small" sx={{ mr: 1 }} />
          <FormattedMessage {...messages.apartmentFormFieldCreateNewApartment} />
        </MenuItem>
        {getItems().map((item, index) =>
          item.id ? (
            <MenuItem key={item.id} sx={styles.menuItem} value={item.id}>
              {item.name}
            </MenuItem>
          ) : (
            <ListSubheader disableSticky key={`heading-${index + 1}`}>
              {item.name}
            </ListSubheader>
          )
        )}
      </TextField>
    </Box>
  );
};

ApartmentFormField.propTypes = {
  apartments: PropTypes.arrayOf(
    PropTypes.shape({
      floorName: PropTypes.string,
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
          name: PropTypes.string,
          number: PropTypes.string,
        })
      ),
    })
  ),
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ApartmentFormField.defaultProps = {
  apartments: [],
  disabled: false,
  required: false,
  value: null,
};

export default ApartmentFormField;
