import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { getItems, types } from './items';
import messages from './messages';
import SiteTypeItem from './SiteTypeItem';

const SelectSiteType = ({ company, name, onChange, site, value }) => (
  <Box>
    <Typography paragraph variant="body2">
      <FormattedMessage {...messages.selectSiteTypeDescription} />
    </Typography>
    <Box>
      {getItems(company, site).map((item) => (
        <SiteTypeItem
          currentValue={value}
          description={item.description}
          icon={item.icon}
          key={item.value}
          name={name}
          onChange={onChange}
          title={item.title}
          value={item.value}
        />
      ))}
    </Box>
  </Box>
);

SelectSiteType.propTypes = {
  company: PropTypes.shape({}).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  site: PropTypes.shape({}).isRequired,
  value: PropTypes.oneOf(Object.values(types)),
};

SelectSiteType.defaultProps = {
  value: null,
};

export default SelectSiteType;
