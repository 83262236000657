import { DEVICE_TYPE } from '../config/devices';

// eslint-disable-next-line max-statements,complexity
export const getTypeFromDeviceType = (value) => {
  if (new RegExp(/^2\.25\.\d{1,2}\.\d{1,2}$/).test(value)) {
    return DEVICE_TYPE.ID_TALK;
  }
  if (new RegExp(/^2\.26\.\d{1,2}\.\d{1,2}$/).test(value)) {
    return DEVICE_TYPE.ID_COMPACT;
  }
  if (new RegExp(/^2\.30\.\d{1,2}\.\d{1,2}$/).test(value)) {
    return DEVICE_TYPE.ID_VIEW;
  }
  if (new RegExp(/^2\.38\.\d{1,2}\.\d{1,2}$/).test(value)) {
    return DEVICE_TYPE.ID_VIEW;
  }
  if (new RegExp(/^2\.34\.\d{1,2}\.\d{1,2}$/).test(value)) {
    return DEVICE_TYPE.CLIP;
  }
  if (new RegExp(/^2\.31\.\d{1,2}\.\d{1,2}$/).test(value)) {
    return DEVICE_TYPE.IP_STYLE;
  }
  if (new RegExp(/^2\.33\.\d{1,2}\.\d{1,2}$/).test(value)) {
    return DEVICE_TYPE.IP_ONE_INTERCOM;
  }
  if (new RegExp(/^2\.32\.\d{1,2}\.\d{1,2}$/).test(value)) {
    return DEVICE_TYPE.IP_INTERCOM_V2;
  }
  if (new RegExp(/^2\.16\.\d{1,2}\.\d{1,2}$/).test(value)) {
    return DEVICE_TYPE.ACCESS_UNIT;
  }
  if (new RegExp(/^2\.27\.\d{1,2}\.\d{1,2}$/).test(value)) {
    return DEVICE_TYPE.ACCESS_UNIT_M;
  }
  if (new RegExp(/^2\.28\.\d{1,2}\.\d{1,2}$/).test(value)) {
    return DEVICE_TYPE.ACCESS_UNIT;
  }
  if (new RegExp(/^2\.35\.\d{1,2}\.\d{1,2}$/).test(value)) {
    return DEVICE_TYPE.CHAMELEON;
  }
  if (new RegExp(/^2\.\d{1,2}\.\d{1,2}\.\d{1,2}$/).test(value)) {
    return DEVICE_TYPE.IP_INTERCOM;
  }
  if (new RegExp(/^3\.[12]\.\d{1,2}\.\d{1,2}$/).test(value)) {
    return DEVICE_TYPE.IDT;
  }
  return undefined;
};

// eslint-disable-next-line max-statements
export const getNameFromDeviceType = (type) => {
  if (!type || typeof type !== 'string') {
    return null;
  }
  /* eslint-disable quote-props */
  const generic = {
    '2.0': 'IP Vario',
    2.3: 'IP Force',
    2.5: 'IP Safety',
    2.7: 'IP Uni',
    2.9: 'SIP Audio Converter',
    '2.10': 'IP Audio Kit',
    2.12: 'IP Video Kit',
    2.14: 'IP Verso',
    2.16: 'Access Unit',
    2.18: 'SIP Speaker',
    2.19: 'IP Base',
    2.21: 'IP Solo',
    2.25: 'Indoor Talk',
    2.26: 'Indoor Compact',
    2.27: 'Access Unit M',
    2.28: 'Access Unit 2.0',
    2.29: 'Lift IP 2',
    '2.30': 'Indoor View',
    2.31: 'IP Style',
    2.32: 'IP Verso 2.0',
    2.33: 'IP One',
    2.34: 'Clip',
    2.35: 'Access Unit QR',
    2.38: 'Indoor View WiFi',
    3.1: 'Indoor Touch',
    3.2: 'Indoor Touch 2.0',
    5.1: 'EasyGate IP',
    5.2: 'EasyGate IP - modem',
    5.3: 'LiftGate',
  };
  /* eslint-enable quote-props */

  const specific = {
    '2.10.1.0': 'IP Audio Kit Lite',
    '2.14.0.2': 'Zennio GetFace IP',
    '2.14.0.6': 'Control4 DS2 Door Station',
    '2.14.0.7': 'IP Verso - KONE',
    '2.14.1.0': 'LTE Verso',
    '2.21.0.2': 'Zennio GetFace IP Single',
    '2.21.0.6': 'Control4 DS2 Mini Door Station',
    '3.1.1.0': 'Indoor Touch - WiFi',
    '3.2.0.7': 'Indoor Touch 2.0 - KONE',
    '3.2.1.0': 'Indoor Touch 2.0 - WiFi',
    '3.2.1.7': 'Indoor Touch 2.0 - WiFi - KONE',
    '4.1.0.0': 'Axis Doorstation',
    '5.1.0.0': 'EasyGate IP - EU',
    '5.1.0.7': 'EasyGate IP - EU',
    '5.1.1.0': 'EasyGate IP - US',
    '5.1.1.7': 'EasyGate IP - US',
    '5.1.2.0': 'EasyGate IP - AU',
    '5.1.2.7': 'EasyGate IP - AU',
    '5.2.0.0': 'EasyGate IP - modem, EU',
    '5.2.0.7': 'EasyGate IP - modem, EU',
    '5.2.1.0': 'EasyGate IP - modem, US',
    '5.2.1.7': 'EasyGate IP - modem, US',
    '5.2.2.0': 'EasyGate IP - modem, AU',
    '5.2.2.7': 'EasyGate IP - modem, AU',
    '5.3.0.0': 'LiftGate (EU)',
    '5.3.1.0': 'LiftGate (US)',
    '5.3.2.0': 'LiftGate (AU)',
  };

  if (specific[type]) {
    return specific[type];
  }

  const genericType = type?.split('.').slice(0, 2).join('.');
  if (generic[genericType]) {
    return generic[genericType];
  }
  return null;
};

export const getIs2nDevice = (type) =>
  [
    DEVICE_TYPE.ACCESS_UNIT,
    DEVICE_TYPE.ACCESS_UNIT_M,
    DEVICE_TYPE.CHAMELEON,
    DEVICE_TYPE.CLIP,
    DEVICE_TYPE.DOOR_STATION,
    DEVICE_TYPE.IDT,
    DEVICE_TYPE.ID_COMPACT,
    DEVICE_TYPE.ID_TALK,
    DEVICE_TYPE.ID_VIEW,
    DEVICE_TYPE.IP_INTERCOM,
    DEVICE_TYPE.IP_INTERCOM_V2,
    DEVICE_TYPE.IP_ONE_INTERCOM,
    DEVICE_TYPE.IP_STYLE,
  ].includes(type);

export const isAnsweringUnit = (type) =>
  [
    DEVICE_TYPE.CLIP,
    DEVICE_TYPE.IDT,
    DEVICE_TYPE.ID_COMPACT,
    DEVICE_TYPE.ID_TALK,
    DEVICE_TYPE.ID_VIEW,
    DEVICE_TYPE.HIPMO,
    DEVICE_TYPE.THIRD_PARTY,
  ].includes(type);

export const isIntercomOrAccessUnit = (type) =>
  [
    DEVICE_TYPE.ACCESS_UNIT,
    DEVICE_TYPE.ACCESS_UNIT_M,
    DEVICE_TYPE.CHAMELEON,
    DEVICE_TYPE.IP_INTERCOM,
    DEVICE_TYPE.IP_INTERCOM_V2,
    DEVICE_TYPE.IP_ONE_INTERCOM,
    DEVICE_TYPE.IP_STYLE,
  ].includes(type);

export const isIdt2 = (deviceType) =>
  !!(typeof deviceType === 'string' && deviceType.split('.').slice(0, 2).join('.') === '3.2');
