import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FE_ROLES } from '../../config/consts';
import Alert from '../Alert';
import messages from './messages';

const UserRoleInfo = ({ companyName, userRole }) => {
  const userInfoSettings = {
    [FE_ROLES.SITE_USER]: [0, 1],
    [FE_ROLES.LOGIN_LESS]: [0, 2],
    [FE_ROLES.APARTMENT_ADMIN]: [],
    [FE_ROLES.SITE_ADMIN]: [3, 4],
    [FE_ROLES.SITE_ADMIN_PAYMENT]: [3, 5],
    [FE_ROLES.COMPANY_ADMIN]: [6, 7],
  };

  return (
    Object.keys(userInfoSettings).includes(userRole) && (
      <Alert
        severity="info"
        title={
          <Box fontWeight="regular">
            <FormattedMessage
              {...messages.userRoleInfoRole}
              values={{
                role: (
                  <Box component="span" fontWeight="bold">
                    <FormattedMessage {...userRoleMap[userRole]} />
                  </Box>
                ),
              }}
            />
          </Box>
        }
      >
        <Box component="ul" sx={{ mx: 2.5, my: 0, p: 0 }}>
          {userInfoSettings[userRole].map((item) => (
            <li key={item}>
              <FormattedMessage
                {...userInfoSettingsMap[item]}
                values={{
                  companyName,
                  strong: (chunks) => <strong>{chunks}</strong>,
                }}
              />
            </li>
          ))}
        </Box>
      </Alert>
    )
  );
};

UserRoleInfo.propTypes = {
  companyName: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
};

export default UserRoleInfo;

const userRoleMap = {
  [FE_ROLES.SITE_USER]: messages.userRoleInfoSITE_USERHeader,
  [FE_ROLES.APARTMENT_ADMIN]: messages.userRoleInfoAPARTMENT_ADMINHeader,
  [FE_ROLES.COMPANY_ADMIN]: messages.userRoleInfoCOMPANY_ADMINHeader,
  [FE_ROLES.SITE_ADMIN]: messages.userRoleInfoSITE_ADMINHeader,
  [FE_ROLES.SITE_ADMIN_PAYMENT]: messages.userRoleInfoSITE_ADMIN_PAYMENTHeader,
  [FE_ROLES.LOGIN_LESS]: messages.userRoleInfoLOGIN_LESSHeader,
};

const userInfoSettingsMap = {
  0: messages.userRoleInfoAuthorization0,
  1: messages.userRoleInfoAuthorization1,
  2: messages.userRoleInfoAuthorization2,
  3: messages.userRoleInfoAuthorization3,
  4: messages.userRoleInfoAuthorization4,
  5: messages.userRoleInfoAuthorization5,
  6: messages.userRoleInfoAuthorization6,
  7: messages.userRoleInfoAuthorization7,
  8: messages.userRoleInfoAuthorization8,
  9: messages.userRoleInfoAuthorization9,
  10: messages.userRoleInfoAuthorization10,
};
