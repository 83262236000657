import { selectors, types } from '.';
import { all, call, put, SagaReturnType, select, takeEvery } from 'redux-saga/effects';
import { createFeatureDevice, updateFeatureDevice } from '../../api/deviceApi';
import { selectors as companySelectors } from '../company';
import { buttonConfiguration, contactList, display } from '../constants';
import { errorHelper, responseValidateStatusOrThrow } from '../helpers';
import { addSnackbarSuccess } from '../notifications/actions';
import { selectors as siteSelectors } from '../sites';
import {
  addContactsFromResponse,
  CallSettingsRequestObject,
  ISaveContactListResponse,
} from './helpers/saveDeviceHelper';
import messages from './messages';
import { IRequestButtonsFeature, IRequestContactsFeature } from './saveActions';
import { DeviceFeatureType, IDevice } from './store/deviceStore';

// eslint-disable-next-line max-statements
export function* saveDeviceContactListRequest(action: {
  payload: { values: Map<DeviceFeatureType, IRequestContactsFeature | IRequestButtonsFeature> };
  type: string;
}) {
  try {
    const { data: device } = yield select(selectors.getCurrentDeviceDetailSelector);
    const typedDevice: IDevice = device;
    const companyId: number = yield select(companySelectors.getCompanyId);
    const siteId: number = yield select(siteSelectors.getSiteId);
    const contactListData = action.payload.values.get(contactList);

    let hasCallSettingsSetup = false;
    typedDevice.features?.forEach((feature) => {
      if (feature.type === contactList) {
        hasCallSettingsSetup = true;
      }
    });

    let contactListResponse: SagaReturnType<typeof updateFeatureDevice>;
    if (hasCallSettingsSetup) {
      contactListResponse = yield call(
        updateFeatureDevice,
        ...[companyId, siteId, typedDevice.id, contactListData?.type, contactListData?.id, contactListData]
      );
    } else {
      contactListResponse = yield call(createFeatureDevice, ...[companyId, siteId, typedDevice.id, contactListData]);
    }

    yield responseValidateStatusOrThrow(contactListResponse);

    const buttonConfigData = action.payload.values.get(buttonConfiguration) as IRequestButtonsFeature;
    yield saveDeviceFeature(
      companyId,
      siteId,
      typedDevice,
      buttonConfigData,
      contactListResponse.data as ISaveContactListResponse
    );

    const displayData = action.payload.values.get(display) as IRequestButtonsFeature;
    yield saveDeviceFeature(
      companyId,
      siteId,
      typedDevice,
      displayData,
      contactListResponse.data as ISaveContactListResponse
    );

    yield put({ type: types.SAVE_DEVICE_CONTACT_LIST_SUCCESS });
    yield put(addSnackbarSuccess(messages.devicesSnackbarContactListUpdated));
  } catch (error) {
    yield put(errorHelper(types.SAVE_DEVICE_CONTACT_LIST_FAILURE, error));
  }
}

// eslint-disable-next-line max-statements
export function* saveMultipleDevicesContactListRequest(action: {
  payload: {
    values: {
      device: IDevice;
      requestObjects: Map<DeviceFeatureType, CallSettingsRequestObject>;
    }[];
  };
  type: string;
}) {
  try {
    for (const { device, requestObjects } of action.payload.values) {
      const companyId: number = yield select(companySelectors.getCompanyId);
      const siteId: number = yield select(siteSelectors.getSiteId);
      const contactListData = requestObjects.get(contactList);

      let hasCallSettingsSetup = false;
      device.features?.forEach((feature) => {
        if (feature.type === contactList) {
          hasCallSettingsSetup = true;
        }
      });

      let contactListResponse: SagaReturnType<typeof updateFeatureDevice>;
      if (hasCallSettingsSetup) {
        contactListResponse = yield call(
          updateFeatureDevice,
          ...[companyId, siteId, device.id, contactListData?.type, contactListData?.id, contactListData]
        );
      } else {
        contactListResponse = yield call(createFeatureDevice, ...[companyId, siteId, device.id, contactListData]);
      }

      yield responseValidateStatusOrThrow(contactListResponse);

      const buttonConfigData = requestObjects.get(buttonConfiguration) as IRequestButtonsFeature;
      yield saveDeviceFeature(
        companyId,
        siteId,
        device,
        buttonConfigData,
        contactListResponse.data as ISaveContactListResponse
      );

      const displayData = requestObjects.get(display) as IRequestButtonsFeature;
      yield saveDeviceFeature(
        companyId,
        siteId,
        device,
        displayData,
        contactListResponse.data as ISaveContactListResponse
      );
    }
    yield put(addSnackbarSuccess(messages.devicesSnackbarContactListUpdated));
    yield put({ type: types.SAVE_CALL_SETTINGS_MULTIPLE_SUCCESS });
  } catch (error) {
    yield put({ type: types.SAVE_CALL_SETTINGS_MULTIPLE_FAILURE });
  }
}

function* saveDeviceFeature(
  companyId: number,
  siteId: number,
  device: IDevice,
  data: IRequestButtonsFeature,
  response: ISaveContactListResponse
) {
  let hasFeatureSetup = false;
  device.features?.forEach((feature) => {
    if (feature.type === data.type) {
      hasFeatureSetup = true;
    }
  });

  const updatedConfigData = addContactsFromResponse(data, response);

  if (hasFeatureSetup) {
    yield call(
      updateFeatureDevice,
      ...[companyId, siteId, device.id, updatedConfigData.type, updatedConfigData.id, updatedConfigData]
    );
  } else {
    if (data.type === display) {
      updatedConfigData.name = 'Display 1';
    }
    yield call(createFeatureDevice, ...[companyId, siteId, device.id, updatedConfigData]);
  }
}

export default function* saveDevicesSagas() {
  yield all([
    takeEvery(types.SAVE_DEVICE_CALL_SETTINGS_REQUEST, saveDeviceContactListRequest),
    takeEvery(types.SAVE_MULTIPLE_DEVICES_CALL_SETTINGS_REQUEST, saveMultipleDevicesContactListRequest),
  ]);
}
