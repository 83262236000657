import Box from '@mui/material/Box';
import List from '@mui/material/List';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReactSVG } from 'react-svg';
import My2LogoIcon from '../../assets/my2LogoIcon.svg';
import TechSupportIcon from '../../assets/techSupportIcon.svg';
import { PATHS } from '../../config/consts';
import messages from './messages';
import SidebarItem from './SidebarItem';

const NonAdminCommonList = ({ onCloseSidebarMobile }: { onCloseSidebarMobile: () => void }) => {
  return (
    <List>
      <Box color="secondary.main" pb={2} pt={4}>
        <SidebarItem
          activePaths={[PATHS.NON_ADMIN_ABOUT]}
          icon={<ReactSVG src={My2LogoIcon} wrapper="span" />}
          id="non-admin-about-link"
          link={PATHS.NON_ADMIN_ABOUT}
          onClick={onCloseSidebarMobile}
          title={<FormattedMessage {...messages.SidebarAbout} />}
          disabled={false}
          showOnlyTitle={false}
          showSelected
          showTitleOnHover
        />
        <SidebarItem
          activePaths={[PATHS.NON_ADMIN_SUPPORT]}
          icon={<ReactSVG src={TechSupportIcon} wrapper="span" />}
          id="non-admin-help-link"
          link={PATHS.NON_ADMIN_SUPPORT}
          onClick={onCloseSidebarMobile}
          title={<FormattedMessage {...messages.SidebarHelp} />}
          disabled={false}
          showOnlyTitle={false}
          showSelected
          showTitleOnHover
        />
      </Box>
    </List>
  );
};

export default NonAdminCommonList;
