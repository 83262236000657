import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from '../../messages';

const NoLicenseNew = () => (
  <Box
    alignItems="center"
    display="flex"
    sx={{
      border: '1px solid',
      borderColor: 'secondary.main',
      borderRadius: 1,
      pl: 2,
      pr: {
        sm: 6,
        xs: 2,
      },
      py: 1,
      width: 'fit-content',
    }}
  >
    <InfoOutlinedIcon color="secondary" />
    <Typography color="secondary" sx={{ ml: 2 }}>
      <FormattedMessage {...messages.licenseSelectionNoLicenseNew} />
    </Typography>
  </Box>
);

export default NoLicenseNew;
