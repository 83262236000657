import { defineMessages } from 'react-intl';

export default defineMessages({
  sitePaymentsCompleteListSitePaymentsCompleteList: {
    defaultMessage: `The complete list of payments for your Company (including License purchase)
        can be found in <companyPaymentsLink>Company payments</companyPaymentsLink>.`,
    description: 'SitePayments - complete list',
    id: 'sitePayments.completeList',
  },
  sitePaymentsCompleteListSitePaymentsInvoice: {
    defaultMessage: 'Invoice',
    description: 'SitePayments - invoice',
    id: 'sitePayments.invoice',
  },
  sitePaymentsCompleteListSitePaymentsTitle: {
    defaultMessage: 'Site payments',
    description: 'SitePayments - title',
    id: 'sitePayments.title',
  },
});
