export const moduleName = 'accessLog';

export default {
  GET_ACCESS_LOG_DETAIL_FAILURE: `${moduleName}/GET_ACCESS_LOG_DETAIL_FAILURE`,
  GET_ACCESS_LOG_DETAIL_REQUEST: `${moduleName}/GET_ACCESS_LOG_DETAIL_REQUEST`,
  GET_ACCESS_LOG_DETAIL_SUCCESS: `${moduleName}/GET_ACCESS_LOG_DETAIL_SUCCESS`,
  GET_ACCESS_LOGS_LIST_FAILURE: `${moduleName}/GET_ACCESS_LOGS_LIST_FAILURE`,
  GET_ACCESS_LOGS_LIST_REQUEST: `${moduleName}/GET_ACCESS_LOGS_LIST_REQUEST`,
  GET_ACCESS_LOGS_LIST_SUCCESS: `${moduleName}/GET_ACCESS_LOGS_LIST_SUCCESS`,
};
