import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Paper } from '../../../components';
import DetailItemInfo from '../../../components/DetailItemInfo';
import { OutlinedTextField } from '../../../components/HookFormFields/TextField';
import { SaveChangesBtn } from '../../../components/SaveChangesBtn';
import { useGetAccessGroupDetailFormData } from './hooks/useGetAccessGroupDetailFormData';
import messages from './messages';

const AccessGroupDetailForm = () => {
  const { data, formMethods, onSubmit } = useGetAccessGroupDetailFormData();
  const watchName = formMethods.watch(['name']);

  const getNameHelperText = (isValid: boolean, touched: any, value: string) => {
    if ((isValid || !touched.name) && value.length >= 30) {
      return (
        <Box component="span" sx={{ color: 'warning.main' }}>
          <FormattedMessage {...messages.accessGroupDetailFormInputNameLongName} />
        </Box>
      );
    }

    return <FormattedMessage {...messages.accessGroupDetailFormInputNameHelperText} />;
  };

  return (
    <Paper tabContent>
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Grid container spacing={{ md: 3, xs: 1 }}>
            <Grid item md={4} xs={12}>
              <OutlinedTextField
                fullWidth
                hasTopAndBottomMargin
                helperText={
                  watchName !== data.name &&
                  getNameHelperText(
                    formMethods.formState.isValid,
                    formMethods.formState.touchedFields,
                    formMethods.getValues().name
                  )
                }
                label={<FormattedMessage {...messages.accessGroupDetailFormInputNameLabel} />}
                name="name"
                required
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <OutlinedTextField
                fullWidth
                hasTopAndBottomMargin
                label={<FormattedMessage {...messages.accessGroupDetailFormInputDescription} />}
                multiline
                name="description"
                rows={5}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Box sx={{ pt: { md: 1, xs: 0 } }}>
                <DetailItemInfo
                  count={data.devices.length}
                  label={
                    <FormattedMessage
                      {...messages.accessGroupDetailFormInfoDevices}
                      values={{ count: data.devices.length }}
                    />
                  }
                />
                <DetailItemInfo
                  count={data.users}
                  label={
                    <FormattedMessage {...messages.accessGroupDetailFormInfoUsers} values={{ count: data.users }} />
                  }
                />
                <DetailItemInfo
                  count={data.apartments}
                  label={
                    <FormattedMessage
                      {...messages.accessGroupDetailFormInfoApartments}
                      values={{ count: data.apartments }}
                    />
                  }
                />
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <SaveChangesBtn
              disabled={!Object.keys(formMethods.formState.dirtyFields).length || !formMethods.formState.isValid}
            />
          </Box>
        </form>
      </FormProvider>
    </Paper>
  );
};

export default AccessGroupDetailForm;
