import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PATHS } from '../../config/consts';
import BillingInfoModal from '../BillingInfoModal/BillingInfoModal';
import ButtonLink from '../ButtonLink';
import messages from './messages';
import { styles } from './styles/content';

const EmptyContent = ({ companyId, isBillingEditable, isBillingFilled, onEditBillingShowModal }) => (
  <Grid container direction="column" justifyContent="center" sx={styles.content}>
    <Grid item>
      <Typography sx={styles.textColor} variant="body2">
        <FormattedMessage {...messages.noPaymentsContentPaymentsContentStartUsingMv} />
      </Typography>
    </Grid>
    <Grid item>
      <Typography sx={styles.textColor} variant="body2">
        {isBillingFilled ? (
          <FormattedMessage {...messages.paymentsAndCreditsConsumptionCheckBillingToPurchase} />
        ) : (
          <FormattedMessage {...messages.contentFillBillingToPurchase} />
        )}
      </Typography>
    </Grid>
    {isBillingFilled ? (
      <Grid item sx={styles.buttonWrapper}>
        <ButtonLink color="primary" to={PATHS.BILLING_INFO.replace(':companyId', companyId)} variant="contained">
          <FormattedMessage {...messages.paymentsAndCreditsConsumptionCheckBillingInfo} />
        </ButtonLink>
      </Grid>
    ) : (
      isBillingEditable && (
        <Grid item sx={styles.buttonWrapper}>
          <Button color="primary" onClick={onEditBillingShowModal} startIcon={<EditIcon />} variant="outlined">
            <FormattedMessage {...messages.contentFillBillingInfo} />
          </Button>
        </Grid>
      )
    )}
    <Grid item sx={styles.textWrapper}>
      <Typography variant="body2">
        <FormattedMessage
          values={{
            a: (text) => <Link to={PATHS.PAYMENTS_OVERVIEW.replace(':companyId', companyId)}>{text}</Link>,
            br: <br />,
          }}
          {...messages.contentHereYouCanAccess}
        />
      </Typography>
    </Grid>
    <BillingInfoModal />
  </Grid>
);

EmptyContent.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isBillingEditable: PropTypes.bool,
  isBillingFilled: PropTypes.bool,
  onEditBillingShowModal: PropTypes.func.isRequired,
};

EmptyContent.defaultProps = {
  isBillingEditable: false,
  isBillingFilled: false,
};

export default EmptyContent;
