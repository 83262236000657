import MuiSlider, { SliderProps } from '@mui/material/Slider';
import React from 'react';
import { Controller, FieldPath, FieldValues, useFormContext } from 'react-hook-form';

interface ISlider<T extends FieldValues> extends SliderProps {
  name: FieldPath<T>;
}

function Slider<T extends FieldValues>({ name, ...other }: ISlider<T>) {
  const { control } = useFormContext<T>();

  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => <MuiSlider {...other} {...field} value={field.value as number | number[]} />}
    />
  );
}

export default Slider;
