export const styles = {
  card: {
    height: '100%',
    textAlign: 'center',
  },
  root: {
    '& h6': {
      fontWeight: 'bold',
    },
    '& p': {
      mb: 2,
    },
  },
  uppercase: {
    textTransform: 'uppercase',
  },
};
