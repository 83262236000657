import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ActionModalError } from '../../../components/_DesignSystemWrappers/Modal';
import { IModalBase } from '../../../modules/commonTypes';
import messages from './messages';

interface IChangeCreditCardDialogProps extends IModalBase {
  onConfirm: () => void;
}

function ChangeCreditCardDialog({ onClose, onConfirm, open }: IChangeCreditCardDialogProps) {
  const { formatMessage } = useIntl();
  const onSubmit = () => {
    onConfirm();
    onClose();
  };

  return (
    <ActionModalError
      muiDialogProps={{
        maxWidth: 'xs',
      }}
      icon={false}
      isPrimaryButtonDisabled={false}
      primaryButtonAction={onSubmit}
      primaryButtonText={formatMessage(messages.ChangeCreditCardDialogDeactivate)}
      secondaryButtonText={formatMessage(messages.ChangeCreditCardDialogClose)}
      onClose={onClose}
      open={open}
      title={formatMessage(messages.ChangeCreditCardDialogTitle)}
    >
      <Typography>
        <FormattedMessage {...messages.ChangeCreditCardDialogText} />
      </Typography>
    </ActionModalError>
)}

export default ChangeCreditCardDialog;
