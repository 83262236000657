import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import AccessGroupsChipList from '../AccessGroupsChipList';
import Alert from '../Alert';

function AccessGroupsList({ groups, helperText, isRacActive, noGroupsLabel, racInactiveLabel }) {
  return groups.length ? (
    <Grid container>
      <Grid item>
        <AccessGroupsChipList groups={groups} />
      </Grid>
      <Grid item md sx={{ maxWidth: { md: 'fitContent' }, minWidth: 300 }} xs={12}>
        <Typography sx={{ color: 'text.secondary' }} variant="subtitle1">
          {helperText}
        </Typography>
      </Grid>
    </Grid>
  ) : (
    <Box sx={{ '& .MuiAlert-root': { mt: 0 } }}>
      <Alert severity={isRacActive ? 'warning' : 'info'}>{isRacActive ? noGroupsLabel : racInactiveLabel}</Alert>
    </Box>
  );
}

AccessGroupsList.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.object).isRequired,
  helperText: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  isRacActive: PropTypes.bool.isRequired,
  noGroupsLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  racInactiveLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

export default AccessGroupsList;
