import { useSelector } from 'react-redux';
import { getAccessGroupId } from '../modules/accessSettings/selectors';
import { getApartmentId } from '../modules/apartments/selectors';
import { ApplicationContext } from '../modules/commonTypes';
import { getCurrentDeviceIdSelector } from '../modules/devices/selectors';
import { getPhonebooksDetailData } from '../modules/phonebooks/selectors';
import { getCurrentUserDetailId } from '../modules/user/selectors';
import { useGetActualCompanyId } from './companyHooks';
import { useGetActualSiteId } from './siteHooks';

export interface ILoadedUrlParams<TContext extends ApplicationContext> {
  companyId: TContext extends ApplicationContext.None ? number | undefined : number;
  siteId: TContext extends ApplicationContext.None | ApplicationContext.Company ? number | undefined : number;
  deviceId: TContext extends ApplicationContext.DeviceDetail ? number : number | undefined;
  phonebookId: TContext extends ApplicationContext.PhoneBookDetail ? number : number | undefined;
  apartmentId: TContext extends ApplicationContext.ApartmentDetail ? number : number | undefined;
  groupId: TContext extends ApplicationContext.PhoneBookDetail ? number : number | undefined;
  userId: TContext extends ApplicationContext.UserDetail ? number : number | undefined;
}

export function useGetLoadedParams<TContext extends ApplicationContext = ApplicationContext.None>(
  _context?: TContext
): ILoadedUrlParams<TContext> {
  const companyId = useGetActualCompanyId();
  const siteId = useGetActualSiteId();
  const deviceId = useSelector(getCurrentDeviceIdSelector);
  const phonebookId = useSelector(getPhonebooksDetailData)?.id;
  const apartmentId = useSelector(getApartmentId);
  const groupId = useSelector(getAccessGroupId);
  const userId = useSelector(getCurrentUserDetailId);

  return {
    apartmentId,
    companyId,
    deviceId,
    groupId,
    phonebookId,
    siteId,
    userId,
  };
}
