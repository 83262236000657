import ClearIcon from '@mui/icons-material/Clear';
import React from 'react';
import { useIntl } from 'react-intl';
import { ActionModalError } from '../_DesignSystemWrappers/Modal';
import messages from './messages';

interface IDeactivateIntegrationModal {
  onClose: () => void;
  onSubmit: () => void;
  open: boolean;
}

function DeactivateIntegrationModal({ onClose, onSubmit, open }: IDeactivateIntegrationModal) {
  const { formatMessage } = useIntl();

  function handleSubmit() {
    onSubmit();
    onClose();
  }

  return (
    <ActionModalError
      onClose={onClose}
      open={open}
      muiDialogProps={{ maxWidth: 'xs' }}
      primaryButtonAction={handleSubmit}
      isPrimaryButtonDisabled={false}
      primaryButtonText={formatMessage({ ...messages.elevatorIntegrationModalDeactivateSubmit })}
      secondaryButtonText={formatMessage({ ...messages.elevatorIntegrationModalDeactivateCancel })}
      icon={<ClearIcon />}
      title={formatMessage({ ...messages.elevatorIntegrationModalDeactivateTitle })}
    >
      {formatMessage({ ...messages.elevatorIntegrationModalDeactivateText1 })}
    </ActionModalError>
  );
}

export default DeactivateIntegrationModal;
