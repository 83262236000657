import Button from '@mui/material/Button';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { headSiteData } from '../../../api/siteRequest';
import { FormikFields, Modal } from '../../../components';
import { changeFilter, dataGridProps, getDataGridSelector } from '../../../helpers/dataGrid';
import commonActions from '../../../modules/common';
import actions, { NAME as PHONEBOOKS_MODULE } from '../../../modules/phonebooks';
import messages from '../messages';
import PhonebookMembersTable from './PhonebookMembersTable';

const PhonebookFormModal = ({
  companyId,
  isEdit,
  onClose,
  onLoadMembers,
  onSave,
  onSearch,
  open,
  phonebookData,
  phonebookMembersTable,
  siteId,
}) => {
  const [fulltext, setFulltext] = useState(phonebookMembersTable.filter.fulltext || '');
  const onSaveHandler = (values, formActions) => {
    onSave(companyId, siteId, values, formActions, isEdit);
  };
  const getValidationSchema = (initialValues) =>
    Yup.object().shape({
      members: Yup.array().nullable(),
      name: Yup.string()
        .required(<FormattedMessage {...messages.phonebookFormModalRequired} />)
        .test({
          message: <FormattedMessage {...messages.phonebookFormModalNameExist} />,
          name: 'isExistName',
          test: async (name) => {
            try {
              let state = true;
              if (!name || name.length === 0 || name === initialValues.name) {
                return true;
              }
              await headSiteData({ companyId, siteId }, 'services/MOBILE_VIDEO/phonebooks', { name }, false).then(
                (response) => {
                  state = response.status === 404;
                }
              );

              return state;
            } catch (error) {
              return true;
            }
          },
        }),
    });

  const onChangeFilter = ({ target }) => {
    const { name, value } = target;
    setFulltext(value);
    changeFilter(onSearch, actions.loadMembers, {
      ...phonebookMembersTable,
      companyId,
      page: 0,
      siteId,
    })(name, value);
  };

  if (!open) {
    return null;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={phonebookData}
      isInitialValid={isEdit}
      onSubmit={onSaveHandler}
      render={({ handleChange, isValid, values }) => (
        <Modal
          actions={
            <>
              <Button onClick={onClose} type="button">
                <FormattedMessage {...messages.phonebookFormModalCancel} />
              </Button>
              <Button color="primary" disabled={!isValid} type="submit" variant="contained">
                <FormattedMessage {...messages.phonebookFormModalSubmit} />
              </Button>
            </>
          }
          fullWidth
          isForm
          name="phonebook-form-modal"
          onClose={onClose}
          open={open}
          title={
            isEdit ? (
              <FormattedMessage {...messages.phonebookFormModalTitleEdit} />
            ) : (
              <FormattedMessage {...messages.phonebookFormModalTitleAdd} />
            )
          }
        >
          <FormikFields.Input
            label={<FormattedMessage {...messages.phonebookFormModalLabelName} />}
            name="name"
            required
          />
          {open && (
            <PhonebookMembersTable
              {...phonebookMembersTable}
              companyId={companyId}
              field={{
                name: 'members',
                onChange: handleChange,
                value: values.members || [],
              }}
              fulltext={fulltext}
              onChangeFilter={onChangeFilter}
              onLoadMembers={onLoadMembers}
              siteId={siteId}
            />
          )}
        </Modal>
      )}
      validationSchema={getValidationSchema(phonebookData)}
    />
  );
};

PhonebookFormModal.propTypes = {
  companyId: PropTypes.number.isRequired,
  isEdit: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onLoadMembers: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  open: PropTypes.bool,
  phonebookData: PropTypes.shape({
    externalId: PropTypes.string,
    id: PropTypes.number,
    members: PropTypes.arrayOf(PropTypes.number),
    name: PropTypes.string,
  }),
  phonebookMembersTable: dataGridProps.isRequired,
  siteId: PropTypes.number.isRequired,
};

PhonebookFormModal.defaultProps = {
  isEdit: false,
  open: false,
  phonebookData: {
    externalId: null,
    id: null,
    members: [],
    name: '',
  },
};

const mapStateToProps = (state) => ({
  isEdit: state.phonebooks.phonebookModal.isEdit,
  open: state.phonebooks.phonebookModal.open,
  phonebookData: state.phonebooks.phonebookModal.data || {
    externalId: null,
    id: null,
    members: [],
    name: '',
  },
  phonebookMembersTable: getDataGridSelector(state, PHONEBOOKS_MODULE, 'membersList'),
});

const mapDispatchToProps = {
  onClose: actions.phonebookHideModal,
  onLoadMembers: actions.loadMembers,
  onSave: actions.phonebookSave,
  onSearch: commonActions.search,
};

export default connect(mapStateToProps, mapDispatchToProps)(PhonebookFormModal);
