import DialpadIcon from '@mui/icons-material/Dialpad';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import RowMenuItem from '../../../../components/DataGrid/RowMenuItem';
import messages from './messages';

const GeneratePin = ({ isPinSet, onClick }) => (
  <RowMenuItem
    disabled={isPinSet}
    icon={<DialpadIcon />}
    label={<FormattedMessage {...messages.accessGroupUsersDataGridDataGridRowMenuPin} />}
    onClick={onClick}
  />
);

GeneratePin.propTypes = {
  isPinSet: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default GeneratePin;
