import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

const Percent = ({ number }) => {
  const { formatNumber } = useIntl();
  return formatNumber(number, { maximumFractionDigits: 2, style: 'percent' });
};

Percent.propTypes = {
  number: PropTypes.number.isRequired,
};

export default Percent;
