import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  danger: {
    color: 'error.dark',
  },
  h6: {
    fontWeight: 'bold',
    textTransform: 'none',
  },
  primary: {
    color: 'primary.main',
  },
  title: {
    borderBottom: '1px solid',
    borderBottomColor: 'divider',
    fontSize: '1.5rem',
    padding: (theme) => theme.spacing(2, 1, 1, 3),
  },
};

const DialogHeader = ({ canClose, headerVariant, id, onCancel, title }) => (
  <DialogTitle id={id} sx={styles.title}>
    <Grid alignItems="center" container direction="row" justifyContent="space-between">
      <Grid item xs>
        <Typography
          sx={{
            ...styles.h6,
            ...(headerVariant && styles?.[headerVariant] ? styles[headerVariant] : styles.primary),
          }}
          variant="h6"
        >
          {title}
        </Typography>
      </Grid>
      {canClose && (
        <Grid item>
          <IconButton id="dialog-header-cancel-button" onClick={onCancel} size="large">
            <CloseIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  </DialogTitle>
);

DialogHeader.propTypes = {
  canClose: PropTypes.bool,
  headerVariant: PropTypes.oneOf(['primary', 'danger']),
  id: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
};

DialogHeader.defaultProps = {
  canClose: true,
  headerVariant: 'primary',
};

export default DialogHeader;
