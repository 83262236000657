import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import React from 'react';

export interface IIconProps {
  count: number;
  isError?: boolean;
}

export function Icon({ count, isError }: IIconProps) {
  if (count === 0) {
    return <CancelIcon sx={{ color: 'text.disabled' }} />;
  }
  if (isError) {
    return <ErrorIcon color="error" />;
  }
  return <CheckCircleIcon color="success" />;
}
