import { sortBy } from 'lodash';

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (err) {
    // Ignore write error
  }
};

/**
 * @deprecated
 * use processError in sagaHelpers.ts instead
 */
export const errorHelper = (type, error, context = null) => ({
  context,
  error,
  type,
});

export const handleErrorLocation = (status) => [404, 403].includes(status);

export const getUniqueKey = () => new Date().getTime() + Math.random();

export const validateStatus = (response) => response.status >= 200 && response.status < 300;

export const responseValidateStatusOrThrow = (response) => {
  if (!validateStatus(response)) {
    const error = {
      data: response?.data,
      status: response?.status,
    };

    throw error;
  }
  return true;
};

export const prepareDeviceButtonConfiguration = (responseData, requestData, configuration, type) => {
  const values = [];
  responseData.contacts.forEach((contact) => {
    const buttonConfiguration = configuration?.buttons.find((item) => item.externalId === contact.externalId);
    if (buttonConfiguration) {
      values.push({
        ...buttonConfiguration,
        contacts: [contact.id],
        name: contact.name,
        type,
      });
    } else {
      const reqIntercomContactList = requestData.find((item) => item.externalId === contact.externalId);
      values.push({
        contacts: [contact.id],
        externalId: contact.externalId,
        index: reqIntercomContactList.index + 1,
        name: contact.name,
        type,
      });
    }
  });
  return sortBy(values, ['index']);
};
