import { put } from 'redux-saga/effects';
import { IError } from './reduxTypes';

export function* processError<TError, TData>(type: string, error: TError, data?: TData, context?: string) {
  yield put({
    context,
    error: {
      ...error,
      ...data,
    },
    type,
  });
}

export function isStandardError(error: unknown): error is IError {
  return (error as IError).data !== undefined && typeof (error as IError).status === 'number';
}
