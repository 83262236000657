import React from 'react';
import { useIntl } from 'react-intl';
import DevicesInfoChip from '../DevicesInfoChip';
import messages from './messages';

const DefaultPasswordStatus = () => {
  const { formatMessage } = useIntl();
  return (
    <DevicesInfoChip
      error
      label={formatMessage(messages.StatusDeviceDefaultPassword)}
      size="small"
      variant="outlined"
    />
  )
};

export default DefaultPasswordStatus;
