import { defineMessages } from 'react-intl';

export default defineMessages({
  firmwareInfoFirmwareAvailable: {
    defaultMessage: 'Out of date',
    description: 'Firmware notification',
    id: 'firmwareInfo.firmwareAvailable',
  },
  firmwareInfoFirmwareDeprecated: {
    defaultMessage: 'Deprecated',
    description: 'Firmware notification',
    id: 'firmwareInfo.firmwareDeprecated',
  },
  firmwareInfoFirmwareUnsupported: {
    defaultMessage: 'Unsupported',
    description: 'Firmware notification',
    id: 'firmwareInfo.firmwareUnsupported',
  },
  firmwareInfoFirmwareUpToDate: {
    defaultMessage: 'Up to date',
    description: 'Firmware notification',
    id: 'firmwareInfo.firmwareUpToDate',
  },
  firmwareInfoFirmwareVersionNotAvailable: {
    defaultMessage: 'Unknown',
    description: 'Firmware notification',
    id: 'firmwareInfo.firmwareVersionNotAvailable',
  },
  firmwareInfoTitle: {
    defaultMessage: 'Firmware Version',
    description: 'Firmware version title',
    id: 'firmwareInfo.title',
  },
  firmwareInfoUpdate: {
    defaultMessage: 'Update',
    description: 'Firmware button update',
    id: 'firmwareInfo.update',
  },
});
