import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { UserTitle } from '../../components/UserTitle';
import { useGetLoadedParams } from '../../hooks/paramsHooks';
import { getApartmentsList } from '../../modules/apartments/selectorsTS';
import { ApplicationContext } from '../../modules/commonTypes';
import { selectors } from '../../modules/user';
import { getUserDetail } from '../../modules/user/selectorsTS';
import { getUserApartmentsTitleData } from './helpers';
import TabPanel from './TabPanel';

export function SiteUserDetail(): React.JSX.Element {
  const { companyId, siteId, userId } = useGetLoadedParams(ApplicationContext.Site);

  const { apartmentsList, user, userMy2NAppAccessStatus, userPin, userRfidCard } = {
    apartmentsList: useSelector(getApartmentsList),
    user: useSelector(getUserDetail),
    userMy2NAppAccessStatus: useSelector(selectors.getUserMy2NAppAccessEnabledStatus),
    userPin: useSelector(selectors.getUserPin),
    userRfidCard: useSelector(selectors.getUserRfidCard),
  };

  return (
    <>
      {user && (
        <UserTitle
          apartments={getUserApartmentsTitleData(apartmentsList, user?.apartments)}
          user={{
            firstName: user.firstName,
            id: user.id,
            lastName: user.lastName,
            role: user.role,
          }}
          userAccessDetails={{
            hasBt: !!userMy2NAppAccessStatus,
            hasPin: !!userPin?.data?.pin,
            hasRfid: !!userRfidCard?.data?.rfidCode,
          }}
          hasDeleteUserButton={true}
          deleteUserButtonData={{
            siteIdsInfo: { companyId, siteId },
          }}
        />
      )}
      {userId && <TabPanel companyId={companyId} siteId={siteId} user={user} userId={userId} />}
      <Outlet />
    </>
  );
}

export default SiteUserDetail;
