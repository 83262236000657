import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EventIcon from '@mui/icons-material/Event';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LabelIcon from '@mui/icons-material/Label';
import TodayIcon from '@mui/icons-material/Today';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { grey } from '@mui/material/colors';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ClipboardLabel from '../ClipboardLabel';
import CustomDate from '../CustomDate';
import DetailLabel from '../DetailLabel';
import { dateDiff } from './helpers';
import messages from './messages';

const styles = {
  borderBottom: {
    px: 0,
    py: 1,
  },
  chip: {
    '&:first-of-type': {
      ml: 0,
    },
    mx: 0.25,
    my: 0,
  },
  noteWrapper: {
    '& p': {
      color: grey[500],
    },
    borderTop: '1px solid',
    borderTopColor: 'secondary.light',
    px: 0,
    py: 2,
    typography: 'caption',
  },
  root: {
    px: 0,
    py: 2,
  },
};

const DetailBatchInfoPanel = ({ data, minHeight }) => (
  <Box sx={styles.root}>
    <Box sx={styles.borderBottom}>
      <DetailLabel
        label={<FormattedMessage {...messages.detailBatchDialogDetailBatchInfoPanelValidFrom} />}
        minHeight={minHeight}
        startIcon={<TodayIcon fontSize="small" htmlColor="#B6B6B6" />}
        value={
          <ClipboardLabel
            description={<FormattedMessage {...messages.detailBatchDialogDetailBatchInfoPanelValidFromUnit} />}
            label={<CustomDate dateValue={data.dateCreated} />}
            value={data.dateCreated}
          />
        }
      />
      <DetailLabel
        label={<FormattedMessage {...messages.detailBatchDialogDetailBatchInfoPanelValidTo} />}
        minHeight={minHeight}
        startIcon={<EventIcon fontSize="small" htmlColor="#B6B6B6" />}
        value={
          <ClipboardLabel
            description={
              <FormattedMessage
                {...messages.detailBatchDialogDetailBatchInfoPanelValidToUnit}
                values={{
                  value: dateDiff(data.dateCreated, data.validityTo),
                }}
              />
            }
            label={<CustomDate dateValue={data.validityTo} />}
            value={data.validityTo}
          />
        }
      />
      <DetailLabel
        label={<FormattedMessage {...messages.detailBatchDialogDetailBatchInfoPanelInvoice} />}
        minHeight={minHeight}
        startIcon={<InsertDriveFileIcon fontSize="small" htmlColor="#B6B6B6" />}
        value={<ClipboardLabel label={data.invoice || '—'} />}
      />
      <DetailLabel
        label={<FormattedMessage {...messages.detailBatchDialogDetailBatchInfoPanelTags} />}
        minHeight={minHeight}
        startIcon={<LabelIcon fontSize="small" htmlColor="#B6B6B6" />}
        value={
          <ClipboardLabel
            label={
              data.utm
                ? data.utm
                    .split(',')
                    .map((tag) => <Chip color="secondary" key={tag} label={tag} size="small" sx={styles.chip} />)
                : '—'
            }
            value={data.utm}
          />
        }
      />
      <DetailLabel
        label={<FormattedMessage {...messages.detailBatchDialogDetailBatchInfoPanelCreator} />}
        minHeight={minHeight}
        startIcon={<AccountCircleIcon fontSize="small" htmlColor="#B6B6B6" />}
        value={<ClipboardLabel label={data.createdBy.name} />}
      />
    </Box>
    {data.notes && (
      <Box sx={styles.noteWrapper}>
        <FormattedMessage tagName="p" {...messages.detailBatchDialogDetailBatchInfoPanelNotes} />
        <div>{data.notes}</div>
      </Box>
    )}
  </Box>
);

DetailBatchInfoPanel.propTypes = {
  data: PropTypes.shape({
    createdBy: PropTypes.shape({
      name: PropTypes.string,
    }),
    dateCreated: PropTypes.string,
    invoice: PropTypes.string,
    notes: PropTypes.string,
    utm: PropTypes.string,
    validityTo: PropTypes.string,
  }).isRequired,
  minHeight: PropTypes.number,
};

DetailBatchInfoPanel.defaultProps = {
  minHeight: 120,
};

export default DetailBatchInfoPanel;
