export default {
  alignLeft: {
    display: 'flex',
    flexDirection: {
      lg: 'row',
      xs: 'column',
    },
    gap: 2,
  },
  alignRight: {
    display: 'flex',
    flexDirection: {
      lg: 'row',
      xs: 'column',
    },
    gap: 2,
    justifyContent: {
      sm: 'flex-end',
      xs: 'flex-start',
    },
  },
  email: {
    mt: {
      md: 2,
    },
  },
  icon: {
    backgroundColor: 'divider',
    borderRadius: '85px',
    color: 'common.white',
    fontSize: '85px',
    height: '85px',
    lineHeight: 1,
    mr: 3,
    p: 1,
    verticalAlign: 'middle',
    width: '85px',
  },
  infoBox: {
    alignItems: 'flex-start',
    display: {
      md: 'flex',
      sm: 'block',
    },
    flex: '0 0 100%',
    justifyContent: 'space-between',
    textAlign: 'left',
  },
  infoBoxUser: {
    width: '100%',
  },
  infoButtons: {
    '& button': {
      ml: -0.5,
    },
    alignItems: {
      xs: 'flex-start',
    },
    display: 'flex',
    flexDirection: {
      sm: 'row',
      xs: 'column',
    },
    gap: 2,
    justifyContent: 'space-between',
    mt: 2,
  },
  name: {
    color: 'rgba(0,0,0,.87)',
    fontSize: (theme) => ({
      md: '24px',
      xs: theme.typography.fontSize * 1.3,
    }),
    fontWeight: 400,
    mt: {
      md: 0,
      xs: 1,
    },
  },
  newsletters: {
    display: 'inline-flex',
  },
  passwordButton: {
    mr: 1,
  },
  root: {
    mb: 2,
    pb: 3,
    pt: 4,
    px: 4,
    textAlign: 'center',
  },
};
