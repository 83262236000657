import { defineMessages } from 'react-intl';

export default defineMessages({
  alertMainMessage: {
    defaultMessage: 'New My2N version is available. Some features may behave unexpectedly until you ',
    description: 'Message will appear when user have loaded old website in browser.',
    id: 'appVersionWarning.alertMainMessage',
  },
  alertMessageEnding: {
    defaultMessage: ' refresh the page.',
    description: 'In case of mobile device, it serves as button for refresh page.',
    id: 'appVersionWarning.alertMessageEnding',
  },
  alertRefreshButton: {
    defaultMessage: 'REFRESH PAGE',
    description: 'Refresh page on click - refresh button',
    id: 'appVersionWarning.refreshButton',
  },
});
