import React from 'react';
import { FormattedMessage } from 'react-intl';
import NoRowsOverlayDataGrid from '../DataGrid/NoRowsOverlay';
import messages from './messages';

const NoRowsOverlay = () => (
  <NoRowsOverlayDataGrid
    headerText={<FormattedMessage {...messages.columnsNoRowsOverlayHeaderText} />}
    text={
      <FormattedMessage
        {...messages.columnsNoRowsOverlayText}
        values={{
          br: <br />,
        }}
      />
    }
  />
);

export default NoRowsOverlay;
