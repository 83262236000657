import CheckCircle from '@mui/icons-material/CheckCircle';
import AlertMD from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import appleStoreIcon from '../../assets/apple-store-icon.svg';
import googlePlayIcon from '../../assets/google-play-icon.svg';
import qrAndroidSvg from '../../assets/qr-android.svg';
import qrIosSvg from '../../assets/qr-ios.svg';
import { MOBILE_APP_LINKS } from '../../config/consts';
import QRCodeItem from '../../containers/DeviceModal/HipmoForm/TwoStep/QRCodeItem';
import { QRCode } from '../index';
import messages from './messages';

export const styles = {
  flex: (theme) => ({
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      justifyContent: 'start',
    },
  }),
  hideIcon: {
    '& .MuiAlert-icon': {
      display: {
        md: 'flex',
        xs: 'none',
      },
    },
  },
  title: {
    color: 'primary.main',
    fontWeight: 'bold',
  },
};

function InstallAppModalContent({ context }) {
  const { APP_STORE_LINK, GOOGLE_PLAY_LINK } = MOBILE_APP_LINKS;

  return (
    <Box>
      {context === 'installApp' ? (
        <Typography variant="body1">
          <FormattedMessage {...messages.InstallAppModalAlertInfo} />
        </Typography>
      ) : (
        <AlertMD icon={<CheckCircle />} severity="success" sx={styles.hideIcon}>
          <Typography variant="body2">
            <FormattedMessage {...messages.InstallAppModalAlertSuccess} />
          </Typography>
        </AlertMD>
      )}
      <Box sx={styles.flex}>
        <QRCodeItem qrCode={<QRCode alt="Android download app" isInstallApp src={qrAndroidSvg} />}>
          <Typography sx={styles.title} variant="subtitle1">
            <FormattedMessage {...messages.InstallAppModalAndroidLink} />
          </Typography>
          <Link
            href={GOOGLE_PLAY_LINK}
            rel="noopener noreferrer"
            sx={{ '& img': { height: '32px' } }}
            target="_blank"
            title="Get it on Google Play"
          >
            <img alt="Get it on Google Play" src={googlePlayIcon} />
          </Link>
        </QRCodeItem>
        <QRCodeItem qrCode={<QRCode alt="iOS download app" isInstallApp src={qrIosSvg} />}>
          <Typography sx={styles.title} variant="subtitle1">
            <FormattedMessage {...messages.InstallAppModalIosLink} />
          </Typography>
          <Link
            href={APP_STORE_LINK}
            rel="noopener noreferrer"
            sx={{ '& img': { height: '32px' } }}
            target="_blank"
            title="Get it on Apple store"
          >
            <img alt="Get it on Apple store" src={appleStoreIcon} />
          </Link>
        </QRCodeItem>
      </Box>
    </Box>
  );
}

InstallAppModalContent.propTypes = {
  context: PropTypes.string.isRequired,
};

export default InstallAppModalContent;
