import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import actions from '../../modules/user';
import { IUser, IUserBase } from '../../modules/user/store';
import ActionModalError from '../_DesignSystemWrappers/Modal/ActionModalError';
import SelectedUser from '../UserPinModalNew/SelectedUser';
import { useGetGlobalHooks } from './hooks/getGlobalHooks';
import messages from './messages';

export interface IUserData extends IUserBase {
  role: IUser['role'];
}

interface IRemoveUserRfidCardModal {
  onClose: () => void;
  open: boolean;
  user: IUserData;
  userRfidCardData: {
    id: string;
    rfidCode: string;
  };
}

const RemoveUserRfidCardModal = ({ onClose, open, user, userRfidCardData }: IRemoveUserRfidCardModal) => {
  const { companyId, dispatch, formatMessage, siteId } = useGetGlobalHooks();

  const handleDelete = () => {
    dispatch(actions.removeUserRfidCard(companyId, siteId, user.id, userRfidCardData.id));
    onClose();
  };

  return (
    <ActionModalError
      icon={<DeleteIcon />}
      isPrimaryButtonDisabled={false}
      muiDialogProps={{
        maxWidth: 'xs',
      }}
      onClose={onClose}
      open={open}
      primaryButtonAction={handleDelete}
      primaryButtonText={formatMessage(messages.removeUserRfidCardModalSubmit)}
      secondaryButtonText={formatMessage(messages.removeUserRfidCardModalCancel)}
      title={formatMessage(messages.removeUserRfidCardModalTitle)}
    >
      <Box sx={{ my: 2.5 }}>
        <Typography sx={{ mb: 1 }} variant="body2">
          <FormattedMessage {...messages.removeUserRfidCardModalDescription} />
        </Typography>
        <SelectedUser user={user} />
        <Typography sx={{ mt: 1 }} variant="body2">
          <FormattedMessage {...messages.removeUserRfidCardModalBottomDescription} />
        </Typography>
      </Box>
    </ActionModalError>
  );
};

export default RemoveUserRfidCardModal;
