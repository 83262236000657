import { defineMessages } from 'react-intl';

export default defineMessages({
  accessGroupAddUserModalAdd: {
    defaultMessage: 'Add to group',
    description: 'Add user modal - add button',
    id: 'accessGroupAddUserModal.add',
  },
  accessGroupAddUserModalText: {
    defaultMessage:
      'Access groups define which users have access to which devices.' +
      '{br}{br}Selected user with valid credentials will have access to all devices' +
      ' exceptin the Access group. Access is activated within seconds.',
    description: 'Add user modal - text',
    id: 'accessGroupAddUserModal.text',
  },
  accessGroupAddUserModalTitle: {
    defaultMessage: 'Add user to Access group',
    description: 'Add user modal - title',
    id: 'accessGroupAddUserModal.title',
  },
  addUserAutocompleteLabel: {
    defaultMessage: 'User',
    description: 'Add user autocomplete - label',
    id: 'addUserAutocomplete.label',
  },
  addUserAutocompleteNewUser: {
    defaultMessage: 'Create new user',
    description: 'Add user autocomplete - new user option label',
    id: 'addUserAutocomplete.newUser',
  },
  addUserAutocompletePlaceholder: {
    defaultMessage: 'Select, or create a new one',
    description: 'Add user autocomplete - placeholder',
    id: 'addUserAutocomplete.placeholder',
  },
  newUserFieldNewUserEmailHelperText: {
    defaultMessage: 'The email is important, please double-check',
    description: 'User email input - new user email helper text',
    id: 'newUserField.newUser.email.helperText',
  },
  newUserFieldNewUserEmailLabel: {
    defaultMessage: 'New user email',
    description: 'User email input - new user email label',
    id: 'newUserField.newUser.email.label',
  },
  newUserFieldNewUserFirstName: {
    defaultMessage: 'New user first name',
    description: 'User firstName input - new user first name label',
    id: 'newUserField.newUser.firstName',
  },
  newUserFieldNewUserLastName: {
    defaultMessage: 'New user surname',
    description: 'User lastName input - new user last name label',
    id: 'newUserField.newUser.lastName',
  },
  validationEmailExistUser: {
    defaultMessage: 'User with this e-mail address already exists in this Site.',
    description: 'User email input - new user email helper text',
    id: 'newUserField.validation.email.existUser',
  },
  validationEmailValid: {
    defaultMessage: 'Wrong format of the e-mail.',
    description: 'User email input - new user email helper text',
    id: 'newUserField.validation.email.valid',
  },
  validationMaxLength: {
    defaultMessage: 'Name is too long.',
    description: 'User email input - new user email helper text',
    id: 'newUserField.validation.maxLength',
  },
  validationRequired: {
    defaultMessage: 'Value is mandatory',
    description: 'User email input - new user email helper text',
    id: 'newUserField.validation.required',
  },
});
