import { MessageDescriptor } from 'react-intl';
import * as yup from 'yup';
import validationMessage from '../../../../validations/messages';

export interface IRecoveryCodeFormData {
  recoveryCode: string;
}

export enum FormFieldNames {
  RecoveryCode = 'recoveryCode',
}

export const defaultValues: IRecoveryCodeFormData = {
  recoveryCode: '',
};

export function getRecoveryCodeFormValidationSchema(formatMessage: (message: MessageDescriptor) => string) {
  return yup.object().shape({
    [FormFieldNames.RecoveryCode]: yup.string().required(formatMessage(validationMessage.validationRequired)),
  });
}
