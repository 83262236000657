import { ContentCopy } from '@mui/icons-material';
import { Button } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useClipboard } from '../../hooks/useClipboard';
import messages from './messages';

export interface CopyButtonProps {
  fileData: Blob;
  disabled: boolean;
}

export function CopyButton({ disabled, fileData }: CopyButtonProps) {
  const { copyToClipboard } = useClipboard({
    copySuccessMessage: messages.SharedNotificationQRcopied,
  });

  const onCopyToClipboard = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (fileData !== undefined) {
      copyToClipboard({ blob: fileData });
    }
  };

  return (
    <Button color="secondary" size="small" startIcon={<ContentCopy />} onClick={onCopyToClipboard} disabled={disabled}>
      <FormattedMessage {...messages.AAmodalPinformCopy} />
    </Button>
  );
}
