import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useAppContext } from '../../../pages/App/AppContextProvider';

export function useGetGlobalHooks() {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { currentUserLang } = useAppContext();
  const location = useLocation();

  const formMethods = useForm({
    mode: 'onChange',
    shouldUnregister: true,
  });

  return {
    currentUserLang,
    dispatch,
    formatMessage,
    formMethods,
    location,
  };
}
