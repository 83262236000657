import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { CONFIGURATION_MODE } from '../../config/devices';
import { ConfigurationManualAlertBox } from './ConfigurationManualAlertBox';
import messages from './messages';

interface ConfigurationModeTextProps {
  mode: string;
}

const FAQ_URL = 'https://2nwiki.2n.cz/display/FAQ/How+to+correctly+use+autoprovisioning';
const HIGHLIGHTED_FAQ_URL =
  'https://2nwiki.2n.cz/display/FAQ/How+to+correctly+use+autoprovisioning#:~:text=If%20you%20want%20to%20make,using%20its%20local%20web%20interface.';

export function ConfigurationModeText({ mode }: ConfigurationModeTextProps) {
  const isConfigModeManual = mode === CONFIGURATION_MODE.MANUAL;
  const values = {
    url: (chunks: React.ReactNode) => (
      <Link href={isConfigModeManual ? FAQ_URL : HIGHLIGHTED_FAQ_URL} rel="noopener noreferrer" target="_blank">
        {chunks}
      </Link>
    ),
  };

  return (
    <>
      <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
        <ConfigurationManualAlertBox isConfigModeManual={isConfigModeManual} values={values} />
      </Box>
      <Typography variant="body1">
        <FormattedMessage {...messages.configurationModeTextAutomatic} />
      </Typography>
      {isConfigModeManual ? (
        <Typography variant="body1" sx={{ paddingTop: 2 }}>
          <FormattedMessage {...messages.configurationModeTextFaq} values={values} />
        </Typography>
      ) : null}
    </>
  );
}
