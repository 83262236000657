import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import CellTooltip from './CellTooltip';

function CellBox({ hideTooltip, ...props }) {
  const boxComponent = (
    <Box
      {...props}
      component="span"
      sx={{
        ...props.sx,
        display: 'block',
        minWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    />
  );
  return hideTooltip ? boxComponent : <CellTooltip>{boxComponent}</CellTooltip>;
}

CellBox.propTypes = {
  hideTooltip: PropTypes.bool,
  sx: PropTypes.shape({}),
};

CellBox.defaultProps = {
  hideTooltip: false,
  sx: {},
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
function BoxComponent() {}

export default memo(CellBox);
