import { defineMessages } from 'react-intl';

export default defineMessages({
  activationStatusErrorInfoUnknownMessage: {
    defaultMessage: "Enter it again and make sure it's correct.",
    description: 'Activation status error unknown info',
    id: 'activationStatusError.info.unknown.message',
  },
  activationStatusErrorInfoUsedMessage: {
    defaultMessage:
      'It has expired in the meantime or has been used by' +
      '{br}someone else.' +
      '{br}If that doesn’t work, please Contact our {link}' +
      "{br}support if you’re sure the voucher hasn't been used.",
    description: 'Activation status error used info',
    id: 'activationStatusError.info.used.message',
  },
  activationStatusErrorLabel: {
    defaultMessage: 'Payment unsuccessful',
    description: 'Activation status error label',
    id: 'activationStatusError.label',
  },
  activationStatusErrorLink: {
    defaultMessage: 'Customer care team',
    description: 'Activation status error info link',
    id: 'activationStatusError.info.link',
  },
  activationStatusSuccessApartmentsButton: {
    defaultMessage: 'See apartments',
    id: 'activationStatusSuccess.apartmentsButton',
  },
  activationStatusSuccessDevicesButton: {
    defaultMessage: 'See devices',
    id: 'activationStatusSuccess.devicesButton',
  },
  activationStatusSuccessInfoCreditPool: {
    defaultMessage:
      'Your current credit pool balance:' +
      ' {pool, plural, =1 {<strong>#</strong> credit} other {<strong>#</strong> credits}}',
    description: 'Activation status success info credit pool',
    id: 'activationStatusSuccess.info.creditPool',
  },
  activationStatusSuccessInfoCreditsUsedPER_APARTMENT: {
    defaultMessage:
      'You received' +
      ' {credit, plural, =1 {<strong>#</strong> credit}' +
      ' other {<strong>#</strong> credits}} from the voucher,{br}of which' +
      ' {consumption, plural, =1 {<strong>#</strong> credit}' +
      ' other {<strong>#</strong> credits}}' +
      ' were used to cover your apartments{br}with active' +
      ' subscriptions. {br}Your current credit pool balance:' +
      ' {pool, plural, =1 {<strong>#</strong> credit} other {<strong>#</strong> credits}}',
    description: 'Activation status success info credits used apartment',
    id: 'activationStatusSuccess.info.creditsUsed.PER_APARTMENT',
  },
  activationStatusSuccessInfoCreditsUsedPER_DEVICE: {
    defaultMessage:
      'You received' +
      ' {credit, plural, =1 {<strong>#</strong> credit}' +
      ' other {<strong>#</strong> credits}} from the voucher, of which' +
      ' {consumption, plural, =1 {<strong>#</strong> credit}' +
      ' other {<strong>#</strong> credits}}' +
      '{br}were used to cover your active devices’' +
      ' subscriptions. {br}Your current credit pool balance:' +
      ' {pool, plural, =1 {<strong>#</strong> credit} other {<strong>#</strong> credits}}',
    description: 'Activation status success info credits used devices',
    id: 'activationStatusSuccess.info.creditsUsed.PER_DEVICE',
  },
  activationStatusSuccessInfoLabel: {
    defaultMessage: 'Success',
    description: 'Activation status success label',
    id: 'activationStatusSuccess.label',
  },
  activationStatusSuccessInfoNextPayment: {
    defaultMessage: 'Your next recurring payment is due on {expiration}',
    description: 'Activation status success info credits used',
    id: 'activationStatusSuccess.info.nextPayment',
  },
  activationStatusSuccessInfoPoolInfoNewlyLicencedApartments: {
    defaultMessage:
      '<strong>{count}</strong> {count, plural, one {apartment is}' + 'other {apartments are}} newly licensed.',
    id: 'activationStatusSuccess.info.poolInfo.newlyLicencedApartments',
  },
  activationStatusSuccessInfoPoolInfoPER_APARTMENT: {
    defaultMessage:
      'Due to an insufficient credit balance, we’ve suspended the' +
      '{br}Mobile Video service in your apartments with active' +
      '{br}Mobile Video subscriptions.' +
      '{br}The service keeps running on 2N devices.' +
      '{br}Activate selected apartments in the Apartments section.',
    description: 'Activation status success info credits used',
    id: 'activationStatusSuccess.info.poolInfo.PER_APARTMENT',
  },
  activationStatusSuccessInfoPoolInfoPER_DEVICE: {
    defaultMessage:
      'Due to insufficient credit, we’ve suspended the Mobile' +
      '{br}Video service on your mobile and 3rd party devices. The' +
      '{br}service keeps running on 2N devices.' +
      '{br}Activate selected devices in the Devices section.',
    description: 'Activation status success info credits used',
    id: 'activationStatusSuccess.info.poolInfo.PER_DEVICE',
  },
  activationStatusSuccessInfoPoolInfoReceivedCredit: {
    defaultMessage:
      'You received' +
      ' {credit, plural, one {<strong>#</strong> credit} other {<strong>#</strong> credits}}' +
      ' from the voucher.{br}{creditPool}',
    description: 'Activation status success info received credit',
    id: 'activationStatusSuccess.info.receivedCredit',
  },
});
