import AddCircle from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import React, { useState } from 'react';
import { ElIntStop, useElevatorIntegrationFloorMappingQuery } from '../../../../../../apiRtk/be4feApi';
import { useGetLoadedParams } from '../../../../../../hooks/paramsHooks';
import { useGetGlobalInfo } from '../../../../../../hooks/useGetGlobalInfo';
import { useTypedSelector } from '../../../../../../modules/configureStore';
import {
  elevatorIntegrationSlice,
  selectElevatorIntegration,
} from '../../../../../../modules/my2nApi/elevatorIntegrationSlice';
import DestinationSelect from '../../DestinationSelect';
import ElevatorGroupSelect from '../../ElevatorGroupSelect';
import { ElevatorGroupSelectItem } from '../../FloorConfiguration';
import FloorName from '../FloorName';
import { getDestinationId, getElevatorGroupId } from './helpers';
import messages from './messages';

interface IFloorMapping {
  floorId: string;
  floorMapping: ElIntStop;
  floorName: string;
  context: 'add' | 'delete';
  index: number;
  elevatorGroups: Array<ElevatorGroupSelectItem>;
}

export function FloorMapping({ context, elevatorGroups, floorId, floorMapping, floorName, index }: IFloorMapping) {
  const { dispatch, formatMessage } = useGetGlobalInfo();
  const { companyId, siteId } = useGetLoadedParams();
  const isFirst = !index;
  const { data: siteElevatorIntegrationFloorMappingData, isSuccess: siteElevatorIntegrationFloorMappingSuccess } =
    useElevatorIntegrationFloorMappingQuery({ companyId: companyId!, siteId: siteId! });
  const elevatorIntegrationFloorMapping = useTypedSelector(selectElevatorIntegration);
  const elevatorGroupId = getElevatorGroupId(floorMapping);
  const destinationId = getDestinationId(floorMapping);
  const [selectedElevatorGroupId, setSelectedElevatorGroupId] = useState(elevatorGroupId);
  const [selectedDestinationId, setSelectedDestinationId] = useState(destinationId);

  function getDestinationsForElevatorGroup(elevatorGroupId: string, buildingId: string) {
    const elIntBuilding = siteElevatorIntegrationFloorMappingData?.buildingTopology.buildings.find(
      (building) => building.id === buildingId
    );
    return (
      elIntBuilding?.elevatorGroups?.find((elevatorGroup) => elevatorGroup.id === elevatorGroupId)?.destinations || []
    );
  }

  function addNewFloorMapping(floorId: string) {
    dispatch(
      elevatorIntegrationSlice.actions.addElevatorIntegrationFloorMappingFloorMapping({
        floorId: floorId,
        mapping: { areaId: '', buildingId: '', elevatorGroupId: '' },
      })
    );
  }

  function deleteFloorMapping(floorId: string, index: number) {
    dispatch(elevatorIntegrationSlice.actions.deleteElevatorIntegrationFloorMapping({ floorId, mappingId: index }));
  }

  return elevatorIntegrationFloorMapping && floorName ? (
    <>
      <Grid item xs={2}>
        {isFirst && <FloorName name={floorName}></FloorName>}
      </Grid>
      <Grid item xs={6}>
        <ElevatorGroupSelect
          placeholder={formatMessage(messages.elevatorGroupSelectPlaceholder)}
          elevatorGroupId={elevatorGroupId}
          floorElevatorGroups={elevatorGroups}
          buildingId={floorMapping.buildingId}
          onChange={({ target }) => (
            setSelectedElevatorGroupId((target.value as string) || 'null'),
            dispatch(
              elevatorIntegrationSlice.actions.editElevatorIntegrationFloorMappingElevatorGroup({
                floorId,
                mappingIndex: index,
                value: target.value as string,
              })
            )
          )}
        />
      </Grid>
      <Grid item xs={3}>
        {siteElevatorIntegrationFloorMappingSuccess && (
          <DestinationSelect
            placeholder={formatMessage(messages.destinationSelectPlaceholder)}
            destinationId={destinationId}
            destinations={getDestinationsForElevatorGroup(floorMapping.elevatorGroupId, floorMapping.buildingId)}
            onChange={({ target }) => (
              setSelectedDestinationId((target.value as string) || 'null'),
              dispatch(
                elevatorIntegrationSlice.actions.editElevatorIntegrationFloorMappingArea({
                  floorId,
                  mappingIndex: index,
                  value: target.value as string,
                })
              )
            )}
          />
        )}
      </Grid>
      <Grid item xs={1}>
        {context === 'add' && (
          <IconButton
            onClick={() => addNewFloorMapping(floorId)}
            disabled={
              elevatorGroups.every((group) => group.disabled) ||
              selectedElevatorGroupId === 'null' ||
              selectedDestinationId === 'null'
            }
          >
            <AddCircle />
          </IconButton>
        )}
        {context === 'delete' && (
          <IconButton onClick={() => deleteFloorMapping(floorId, index)}>
            <CloseIcon color="error" />
          </IconButton>
        )}
      </Grid>
    </>
  ) : (
    <>{false}</>
  );
}
