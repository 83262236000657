import { defineMessages } from 'react-intl';

export default defineMessages({
  mobileVideoCardActivateRecurringPayment: {
    defaultMessage: 'Activate recurring payment',
    description: 'Mobile video card - activate recurring payment button',
    id: 'mobileVideoCard.activateRecurringPayment',
  },
  mobileVideoCardActivateService: {
    defaultMessage: 'Activate service',
    description: 'Mobile video card - activate service button',
    id: 'mobileVideoCard.activateService',
  },
  mobileVideoCardActiveForFreeDevicesStatus: {
    defaultMessage: '<status>Active</status> <span>only for 2N devices</span>',
    description: 'mobileVideoCard - active for free devices status',
    id: 'mobileVideoCard.activeForFreeDevicesStatus',
  },
  mobileVideoCardContactCustomerCare: {
    defaultMessage: 'Contact customer care for service activation.',
    description: 'mobileVideoCard - contact customer care',
    id: 'mobileVideoCard.contactCustomerCare',
  },
  mobileVideoCardContactDistributor: {
    defaultMessage: 'Contact customer care or your distributor for service activation.',
    description: 'mobileVideoCard - contact distributor',
    id: 'mobileVideoCard.contactDistributor',
  },
  mobileVideoCardDayLeft: {
    defaultMessage: 'Only {time} day left',
    description: 'mobileVideoCard - one day left',
    id: 'mobileVideoCard.dayLeft',
  },
  mobileVideoCardHourLeft: {
    defaultMessage: 'Only {time} hour left',
    description: 'mobileVideoCard - one hour left',
    id: 'mobileVideoCard.hourLeft',
  },
  mobileVideoCardHoursLeft: {
    defaultMessage: 'Only {time} hours left',
    description: 'mobileVideoCard - hours left',
    id: 'mobileVideoCard.hoursLeft',
  },
  mobileVideoCardKeepUsingDevicesForFree: {
    defaultMessage: 'You can keep using 2N devices for free',
    description: 'Mobile video card - keep using 2n devices for free',
    id: 'mobileVideoCard.keepUsingDevicesForFree',
  },
  mobileVideoCardMobileVideo: {
    defaultMessage: '2N Mobile Video',
    description: '2N Mobile Video',
    id: 'mobileVideoCard.mobileVideo',
  },
  mobileVideoCardMy2nId: {
    defaultMessage: 'My2N ID {my2nId}',
    description: 'mobileVideoCard - my2nId',
    id: 'mobileVideoCard.my2nId',
  },
  mobileVideoCardMy2nIdTooltip: {
    defaultMessage: 'Your site My2N ID',
    description: 'mobileVideoCard - my2nId tooltip',
    id: 'mobileVideoCard.my2nIdTooltip',
  },
  mobileVideoCardNeedSubscription: {
    defaultMessage: 'You need subscriptions for' + ' {count, plural, one {# mobile phone} other {# mobile phones}}',
    description: 'mobileVideoCard - need subscription',
    id: 'mobileVideoCard.needSubscription',
  },
  mobileVideoCardNoDevices: {
    defaultMessage: 'No devices for this service',
    description: 'mobileVideoCard - no devices for this service',
    id: 'mobileVideoCard.noDevices',
  },
  mobileVideoCardOnlyDaysLeft: {
    defaultMessage: 'Only {time} days left',
    description: 'mobileVideoCard - only days left',
    id: 'mobileVideoCard.onlyDaysLeft',
  },
  mobileVideoCardPayForMobilePhonesOnly: {
    defaultMessage: 'You only pay for mobile phones subscriptions.',
    description: 'mobileVideoCard - pay for mobile phones only',
    id: 'mobileVideoCard.payForMobilePhonesOnly',
  },
  mobileVideoCardProlongService: {
    defaultMessage: 'Contact customer care or your distributor to prolong the service.',
    description: 'mobileVideoCard - prolong service',
    id: 'mobileVideoCard.prolongService',
  },
  mobileVideoCardPurchaseSubscription: {
    defaultMessage: 'Purchase subscription',
    description: 'Mobile video card - purchase subscription button',
    id: 'mobileVideoCard.purchaseSubscription',
  },
  mobileVideoCardServiceInaccessible: {
    defaultMessage: 'Service inaccessible for {count, plural, one {# device} other {# devices}}',
    description: 'mobileVideoCard - service inaccessible',
    id: 'mobileVideoCard.serviceInaccessible',
  },
  mobileVideoCardServiceNotWork: {
    defaultMessage: "Service isn't working",
    description: 'mobileVideoCard - service does not work',
    id: 'mobileVideoCard.serviceNotWork',
  },
  mobileVideoCardServiceNotWorkOnDevice: {
    defaultMessage: "Service isn't working on 1 device",
    description: 'mobileVideoCard - service does not work on',
    id: 'mobileVideoCard.serviceNotWorkOnDevice',
  },
  mobileVideoCardServiceNotWorkOnDevices: {
    defaultMessage: "Service isn't working on {count, plural, one {# device} other {# devices}}",
    description: 'mobileVideoCard - service does not work on',
    id: 'mobileVideoCard.serviceNotWorkOnDevices',
  },
  mobileVideoCardServiceWillBeTerminated: {
    defaultMessage: 'Service will be terminated in {hours} hours!',
    description: 'mobileVideoCard - service will be terminated',
    id: 'mobileVideoCard.serviceWillBeTerminated',
  },
  mobileVideoCardServiceWorksOn: {
    defaultMessage: 'Service working on {count, plural, one {# device} other {# devices}}',
    description: 'mobileVideoCard - service works on',
    id: 'mobileVideoCard.serviceWorksOn',
  },
  mobileVideoCardShowDevices: {
    defaultMessage: 'Show devices',
    description: 'Mobile video card - show devices button',
    id: 'mobileVideoCard.showDevices',
  },
  mobileVideoCardStartTrial: {
    defaultMessage: 'Start my free 30 days trial now',
    description: 'Mobile video card - start trial button',
    id: 'mobileVideoCard.startTrial',
  },
  mobileVideoCardTitleInitial: {
    defaultMessage: 'Activate the 2N<sup>®</sup> Mobile Video service',
    description: 'Mobile video card - title initial',
    id: 'mobileVideoCard.titleInitial',
  },
  mobileVideoCardUseAvailableCredits: {
    defaultMessage: 'Use available credits',
    description: 'Mobile video card - use available credits button',
    id: 'mobileVideoCard.useAvailableCredits',
  },
});
