export enum UrlQueryParamName {
  IsOrySessionActive = 'isOrySessionActive',
}

export enum UrlQueryParamValueBoolean {
  True = 'true',
}

export function buildUrlWithQueryParams({
  baseUrl,
  queryParams,
}: {
  baseUrl: string;
  queryParams?: Record<string, string>;
}): string {
  if (queryParams) {
    return `${baseUrl}?${new URLSearchParams(queryParams).toString()}`;
  }
  return baseUrl;
}

export function getUrlQueryParamValue({ paramName }: { paramName: string }): undefined | string | boolean {
  const urlParams = new URLSearchParams(window.location.search);
  const value = urlParams.get(paramName) || undefined;
  if (value === UrlQueryParamValueBoolean.True) {
    return true;
  }
  return value;
}
