import Box from '@mui/material/Box';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SOCKET_ERROR } from './const';
import messages from './messages';

const errorMap = {
  [SOCKET_ERROR.CARD_ERR]: messages.cardIdentifierInputHelperTextErrorCardErr,
  [SOCKET_ERROR.CONNECTION_ERR]: messages.cardIdentifierInputHelperTextErrorConnectionErr,
  [SOCKET_ERROR.DEVICE_ERR]: messages.cardIdentifierInputHelperTextErrorDeviceErr,
  [SOCKET_ERROR.DRIVER_ERR]: messages.cardIdentifierInputHelperTextErrorDriverErr,
  [SOCKET_ERROR.READING_ERR]: messages.cardIdentifierInputHelperTextErrorReadingErr,
};

interface IErrorHelperText {
  error: string;
  errorMessage?: React.ReactNode;
  validationError: boolean;
}

function ErrorHelperText({ error, errorMessage, validationError }: IErrorHelperText) {
  return (
    <Box color="error.main" component="span">
      {validationError ? (
        errorMessage || <FormattedMessage {...messages.cardIdentifierInputHelperTextErrorInvalidId} />
      ) : (
        <FormattedMessage {...errorMap[error]} />
      )}
    </Box>
  );
}

ErrorHelperText.defaultProps = {
  errorMessage: null,
};

export default ErrorHelperText;
