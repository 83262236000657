import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import * as userSelectors from '../../../modules/user/selectors';
import { useGetValidationSchema } from './useGetValidation';

export interface IChangePasswordFormValues {
  password: string;
  passwordRepeat: string;
}

export function useGetGlobalHooks() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const currentUser = useSelector(userSelectors.getCurrentUserSelector);

  const formMethods = useForm<IChangePasswordFormValues>({
    defaultValues: {
      password: '',
      passwordRepeat: '',
    },
    mode: 'onChange',
    resolver: yupResolver(useGetValidationSchema()),
    shouldUnregister: true,
  });

  return {
    currentUser,
    dispatch,
    formatMessage,
    formMethods,
  };
}
