import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import Neighborhood from '../../assets/neighborhood.svg';
import { PATHS } from '../../config/consts';
import messages from './messages';

const styles = {
  descriptionLink: {
    display: 'block',
    mt: 2,
  },
  image: {
    display: 'block',
    maxWidth: 71,
    mb: 5,
    mt: 0,
    mx: 'auto',
    opacity: '.54',
  },
  link: {
    textDecoration: 'none',
  },
  root: {
    borderRadius: 0,
    height: '100%',
    p: 4,
    textAlign: 'center',
    width: '100%',
  },
  siteCount: {
    color: 'primary.main',
    display: 'block',
    fontSize: 36,
    fontWeight: 'regular',
    lineHeight: 1.5,
    textDecoration: 'none',
  },
  siteText: {
    color: 'text.primary',
    display: 'block',
    fontSize: 'h6.fontSize',
    mb: 3.75,
    textDecoration: 'none',
  },
  wrapper: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
  },
};

const SiteOverview = ({ companyId, onAddSite, onSiteAboutModalShow, siteCount }) => (
  <Paper elevation={1} sx={styles.root}>
    <Box sx={styles.wrapper}>
      <div>
        <Box component={Link} sx={styles.link} to={PATHS.COMPANY_SITES.replace(':companyId', companyId)}>
          <Box alt="" component="img" src={Neighborhood} sx={styles.image} />
        </Box>
        <div>
          <Box component={Link} sx={styles.link} to={PATHS.COMPANY_SITES.replace(':companyId', companyId)}>
            <Box component="strong" sx={styles.siteCount}>
              {siteCount}
            </Box>
            <Box component="span" sx={styles.siteText}>
              <FormattedMessage {...messages.siteOverviewSites} />
            </Box>
          </Box>
        </div>
        <div>
          <Button color="primary" id="addsite-button" onClick={onAddSite} startIcon={<AddIcon />} variant="contained">
            <FormattedMessage {...messages.siteOverviewNewSite} />
          </Button>
          <Button color="primary" id="site-overview" onClick={onSiteAboutModalShow} sx={styles.descriptionLink}>
            <FormattedMessage {...messages.siteOverviewSitesInformation} />
          </Button>
        </div>
      </div>
    </Box>
  </Paper>
);

SiteOverview.propTypes = {
  companyId: PropTypes.number.isRequired,
  onAddSite: PropTypes.func.isRequired,
  onSiteAboutModalShow: PropTypes.func.isRequired,
  siteCount: PropTypes.number,
};

SiteOverview.defaultProps = {
  siteCount: 0,
};

export default SiteOverview;
