import { defineMessages } from 'react-intl';

export default defineMessages({
  deleteDeviceCancelButton: {
    defaultMessage: 'Cancel',
    description: 'Cancel button',
    id: 'deleteDevice.cancelButton',
  },
  deleteDeviceConfirmButton: {
    defaultMessage: 'Delete device',
    description: 'Confirm button',
    id: 'deleteDevice.confirmButton',
  },
  deleteDeviceDeleteButton: {
    defaultMessage: 'Delete device',
    description: 'Delete device from My2N',
    id: 'deleteDevice.deleteButton',
  },
  deleteDeviceTextList1: {
    defaultMessage: 'immediately log you out from the mobile app',
    description: 'Delete device - text in dialog - list item 1',
    id: 'deleteDevice.textList1',
  },
  deleteDeviceTextList2: {
    defaultMessage: 'disconnect you from the Mobile Video service',
    description: 'Delete device - text in dialog - list item 2',
    id: 'deleteDevice.textList2',
  },
  deleteDeviceTextList3: {
    defaultMessage: 'delete the device settings in My2N.',
    description: 'Delete device - text in dialog - list item 3',
    id: 'deleteDevice.textList3',
  },
  deleteDeviceTextRow1: {
    defaultMessage: 'You will delete the device from My2N and all phone books and buttons.',
    description: 'Delete device - text in dialog - row 1',
    id: 'deleteDevice.textRow1',
  },
  deleteDeviceTextRow2: {
    defaultMessage: 'Deleting the device will:',
    description: 'Delete device - text in dialog - row 2',
    id: 'deleteDevice.textRow2',
  },
  deleteDeviceTextRow3: {
    defaultMessage: 'We suggest a factory reset if you plan to sell or pass the device on to' + ' another user.',
    description: 'Delete device - text in dialog - row 3',
    id: 'deleteDevice.textRow3',
  },
  deleteDeviceTitle: {
    defaultMessage: 'Delete device',
    description: 'Delete device',
    id: 'deleteDevice.title',
  },
});
