import Box from '@mui/material/Box';
import { DataGridPro, useGridApiRef } from '@mui/x-data-grid-pro';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { EMAIL_REGEXP, IMPORT_USERS_FAILED_ROW_RESULT } from '../../../config/consts';
import { selectors } from '../../../modules/user';
import CellValidator from '../CheckStep/CellValidator';
import messages from '../CheckStep/messages';
import { IMPORT_KEYS } from '../helpers/transformJsonData';
import FailedRecordIcon from './FailedRecordIcon';

function ImportResultDataGrid() {
  const apiRef = useGridApiRef();
  const { formatMessage } = useIntl();
  const currentImportUsersData = useSelector(selectors.currentImportUsersDataFailed);
  const columns = useMemo(
    () => [
      {
        field: IMPORT_KEYS.rowNumber,
        flex: 1,
        maxWidth: 60,
        renderCell: (cellData) => <Box color="error.main">{cellData.value}</Box>,
        renderHeader: () => <Box color="error.main">{'#'}</Box>,
        resizable: false,
      },
      {
        editable: false,
        field: IMPORT_KEYS.firstName,
        flex: 0.5,
        headerName: formatMessage(messages.siteUsersImportCheckStepDataGridTableHeadFirstName),
        renderCell: (cellData) => <CellValidator hideTooltip isValueValid={!!cellData.value} value={cellData.value} />,
        resizable: false,
      },
      {
        editable: false,
        field: IMPORT_KEYS.lastName,
        flex: 0.5,
        headerName: formatMessage(messages.siteUsersImportCheckStepDataGridTableHeadLastName),
        renderCell: (cellData) => <CellValidator hideTooltip isValueValid={!!cellData.value} value={cellData.value} />,
        resizable: false,
      },
      {
        editable: false,
        field: IMPORT_KEYS.email,
        flex: 1,
        headerName: formatMessage(messages.siteUsersImportCheckStepDataGridTableHeadEmail),
        renderCell: (cellData) => (
          <CellValidator
            hideTooltip
            isValueValid={EMAIL_REGEXP.test(cellData.value)}
            regex={EMAIL_REGEXP}
            value={cellData.value}
          />
        ),
        resizable: false,
      },
      {
        editable: false,
        field: IMPORT_KEYS.result,
        flex: 1,
        headerName: '',
        maxWidth: 40,
        renderCell: (cellData) => <FailedRecordIcon result={cellData.value} />,
        resizable: false,
        sortable: false,
      },
    ],
    []
  );

  const rows = useMemo(
    () =>
      currentImportUsersData
        .filter((item) => item.result !== IMPORT_USERS_FAILED_ROW_RESULT.ABORTED)
        .map((item) => ({
          [IMPORT_KEYS.rowNumber]: item[IMPORT_KEYS.rowNumber],
          [IMPORT_KEYS.firstName]: item[IMPORT_KEYS.firstName],
          [IMPORT_KEYS.lastName]: item[IMPORT_KEYS.lastName],
          [IMPORT_KEYS.email]: item[IMPORT_KEYS.email],
          [IMPORT_KEYS.result]: { detail: item[IMPORT_KEYS.detail], status: item[IMPORT_KEYS.result] },
          id: item[IMPORT_KEYS.rowNumber],
        })),
    []
  );

  const hasDataGridAutoHeight = useMemo(() => rows.length < 10, [rows]);

  return (
    rows.length > 0 && (
      <Box sx={{ height: hasDataGridAutoHeight ? 'auto' : 510, py: 2, width: '100%' }}>
        <DataGridPro
          apiRef={apiRef}
          autoHeight={hasDataGridAutoHeight}
          columns={columns}
          disableColumnMenu
          disableColumnReorder
          disableRowSelectionOnClick
          hideFooter
          hideFooterPagination
          hideFooterRowCount
          rowHeight={40}
          rows={rows}
          sortingOrder={['desc', 'asc']}
          sx={{
            '.MuiDataGrid-columnSeparator': {
              display: 'flex',
            },
          }}
        />
      </Box>
    )
  );
}

export default ImportResultDataGrid;
