import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import React from 'react';
import RowMenu from '../../DataGrid/RowMenu';
import AddApartmentToGroup from './AddApartmentToGroup';
import AddDeviceToGroup from './AddDeviceToGroup';
import AddUserToGroup from './AddUserToGroup';
import DeleteGroup from './DeleteGroup';
import EditGroup from './EditGroup';

const AccessSettingsRowMenu = ({
  companyId,
  group,
  groupId,
  onAddApartment,
  onAddDevice,
  onAddUser,
  onDelete,
  siteId,
}) => {
  const handleMenuClick = (fn) => {
    if (fn) {
      fn();
    }
  };

  return (
    <RowMenu
      menuItems={
        <div>
          <EditGroup companyId={companyId} groupId={groupId} siteId={siteId} />
          <AddApartmentToGroup onClick={() => handleMenuClick(onAddApartment(group))} />
          <AddDeviceToGroup onClick={() => handleMenuClick(onAddDevice(group))} />
          <AddUserToGroup onClick={() => handleMenuClick(onAddUser(group))} />
          <Divider />
          <DeleteGroup onClick={() => handleMenuClick(onDelete(group))} />
        </div>
      }
    />
  );
};

AccessSettingsRowMenu.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  group: PropTypes.shape({}).isRequired,
  groupId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onAddApartment: PropTypes.func.isRequired,
  onAddDevice: PropTypes.func.isRequired,
  onAddUser: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  siteId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default AccessSettingsRowMenu;
