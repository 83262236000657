import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';
import { CURRENCY } from '../../../../../config/consts';
import RemoveBatchButton from '../../Common/RemoveBatchButton';
import { orderItemPropTypes } from '../../types';
import ItemPrice from './ItemPrice';
import ItemVoucherValue from './ItemVoucherValue';

const CountedOrderData = ({ currency, isRemoveBatchDisabled, item, onRemoveBatch }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('xl'));
  return matches ? (
    <Grid alignItems="center" container item justifyContent="space-between" xl={5}>
      <Grid container item xl={7}>
        <ItemVoucherValue item={item.configuration} />
        <ItemPrice currency={currency} price={item.price} />
      </Grid>
      <Box display={{ lg: 'block', xs: 'none' }}>
        <Grid item sx={{ mr: -1 }} xl="auto">
          <RemoveBatchButton disabled={isRemoveBatchDisabled} onClick={onRemoveBatch} />
        </Grid>
      </Box>
    </Grid>
  ) : (
    <Grid container item justifyContent="space-between" lg sm={6}>
      <ItemVoucherValue item={item.configuration} />
      <ItemPrice currency={currency} price={item.price} />
    </Grid>
  );
};

CountedOrderData.propTypes = {
  currency: PropTypes.oneOf(Object.keys(CURRENCY)).isRequired,
  isRemoveBatchDisabled: PropTypes.bool.isRequired,
  item: orderItemPropTypes.isRequired,
  onRemoveBatch: PropTypes.func.isRequired,
};

export default CountedOrderData;
