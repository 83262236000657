import { defineMessages } from 'react-intl';

export default defineMessages({
  AAUserListContextMenuChangeLabel: {
    defaultMessage: 'Change button label',
    id: 'AA.userList.contextMenu.changeLabel',
  },
  AAUserListContextMenuDeletePIN: {
    defaultMessage: 'Delete PIN',
    id: 'AA.userList.contextMenu.deletePIN',
  },
  AAUserListContextMenuDeleteRFID: {
    defaultMessage: 'Delete RFID key fob',
    id: 'AA.userList.contextMenu.deleteRFID',
  },
  AAUserListContextMenuDeleteUser: {
    defaultMessage: 'Delete user',
    id: 'AA.userList.contextMenu.deleteUser',
  },
  AAUserListContextMenuEditPIN: {
    defaultMessage: 'Edit PIN',
    id: 'AA.userList.contextMenu.editPIN',
  },
  AAUserListContextMenuEditRFID: {
    defaultMessage: 'Edit RFID key fob',
    id: 'AA.userList.contextMenu.editRFID',
  },
  AAUserListContextMenuEditUser: {
    defaultMessage: 'Edit user',
    id: 'AA.userList.contextMenu.editUser',
  },
  AAUserListContextMenuShowApt: {
    defaultMessage: 'Show apartment',
    id: 'AA.userList.contextMenu.showApt',
  },
  AAUserListCreateGuest: {
    defaultMessage: 'Add visitor(s)',
    id: 'AA.userList.createGuest',
  },
  AAUserListCreateGuestXs: {
    defaultMessage: 'Visitor',
    id: 'AA.userList.createGuest.xs',
  },
  AAUserListCreateUserXs: {
    defaultMessage: 'user',
    id: 'AA.userList.createUser.xs',
  },
  AAUserListGeneratePinXs: {
    defaultMessage: 'PIN',
    id: 'AA.userList.generatePIN.xs',
  },
  usersInApartmentsCreateUser: {
    defaultMessage: 'Create User',
    id: 'AA.userList.createUser',
  },
  usersInApartmentsGeneratePin: {
    defaultMessage: 'Generate Pin',
    id: 'AA.userList.generatePIN',
  },
});
