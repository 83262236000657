import React, { createRef, forwardRef } from 'react';
import MaskedInput from 'react-text-mask';

const SerialNumberMaskedField = forwardRef(({ ...props }, ref) => {
  const inputRef = createRef(ref);
  return (
    <MaskedInput
      {...props}
      mask={[/[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
      placeholderChar="*"
      ref={inputRef}
    />
  );
});

export default SerialNumberMaskedField;
