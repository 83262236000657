import { IEditSiteRequest } from '../../api/siteApiTS';
import { types } from './types';

export const getCurrentSiteLanguage = (companyId: number, siteId: number) => ({
  companyId,
  siteId,
  type: types.GET_CURRENT_SITE_LANGUAGE_REQUEST,
});

export const getCurrentUserLanguage = () => ({
  type: types.GET_CURRENT_USER_LANGUAGE_REQUEST,
});

export const setLandingPageLanguage = (lang: string, loggedIn: boolean) => ({
  lang,
  loggedIn,
  type: types.SET_LANDING_PAGE_LANGUAGE_REQUEST,
});

export const setCurrentSiteLanguage = (companyId: number, siteId: number, values: IEditSiteRequest) => ({
  companyId,
  siteId,
  type: types.SET_CURRENT_SITE_LANGUAGE_REQUEST,
  values
});

export const setUserModalLanguage = (lang: string) => ({
  lang,
  type: types.SET_USER_MODAL_LANGUAGE_REQUEST,
});

export const setIsFetchingMessages = (isFetching: boolean) => ({
  payload: isFetching,
  type: types.SET_FETCHING_MESSAGES,
});
