import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import MuiStepper from '@mui/material/Stepper';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import messages from './messages';

function Stepper({ activeStep }) {
  const { formatMessage } = useIntl();

  const steps = [
    formatMessage(messages.StepperStep1),
    formatMessage(messages.StepperStep2),
    formatMessage(messages.StepperStep3),
    formatMessage(messages.StepperStep4),
    formatMessage(messages.StepperStep5),
  ];

  return (
    <MuiStepper activeStep={activeStep}>
      {steps.map((label, index) => (
        <Step completed={index < activeStep} key={label}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </MuiStepper>
  );
}

Stepper.propTypes = {
  activeStep: PropTypes.number.isRequired,
};

export default Stepper;
