import { defineMessages } from 'react-intl';

export default defineMessages({
  devicesColumnDevices: {
    defaultMessage: '(<green>{devices}</green>' + ' {count, plural, one {device} other {devices}} from {totalDevices})',
    id: 'devicesColumn.devices',
  },
  devicesColumnDevicesWithSubscriptions: {
    defaultMessage: 'Devices with subscriptions',
    id: 'devicesColumn.devicesWithSubscriptions',
  },
  devicesColumnTotalDevices: {
    defaultMessage: 'Total number of devices',
    id: 'devicesColumn.totalDevices',
  },
});
