import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import SockJS from 'sockjs-client';
import { Stomp } from 'stompjs/lib/stomp';
import { SERVICE } from '../../config/devices';
import { SERVICE as WS_SERVICE } from '../../config/websocket';
import { selectors as authSelectors } from '../../modules/auth';
import { selectors as companySelectors } from '../../modules/company';
import { selectors as siteSelectors } from '../../modules/sites';
import actions from '../../modules/websocket';
import { OryLoginStatus } from '../../ory/types';

const subscriptions = (companyId, siteId) => [
  {
    destination: `/notification/companies/${companyId}/sites/${siteId}/devices`,
    id: WS_SERVICE.DEVICE_CHANGE,
    service: WS_SERVICE.DEVICE_CHANGE,
  },
  {
    destination: `/notification/companies/${companyId}/sites/${siteId}/waiting-devices`,
    id: WS_SERVICE.WAITING_DEVICE_CHANGE,
    service: WS_SERVICE.WAITING_DEVICE_CHANGE,
  },
  {
    destination: `/notification/companies/${companyId}/sites/${siteId}/services/${SERVICE.REMOTE_CONFIGURATION}/configurations`,
    id: WS_SERVICE.REMOTE_CONFIGURATION_DEVICE_CHANGE,
    service: WS_SERVICE.REMOTE_CONFIGURATION_DEVICE_CHANGE,
  },
  {
    destination: `/notification/companies/${companyId}/payments`,
    id: WS_SERVICE.SUBSCRIPTION_PAYMENT,
    service: WS_SERVICE.SUBSCRIPTION_PAYMENT,
  },
];

const WebSocket = ({ accessToken, companyId, onEmit, oryLoginStatus, siteId }) => {
  useEffect(() => {
    const sock = new SockJS(process.env.SOCKET_URL);
    const client = new Stomp.over(sock);
    client.debug = null;

    if ((accessToken || oryLoginStatus === OryLoginStatus.SessionConfirmed) && companyId && siteId) {
      const availableSubscriptions = subscriptions(companyId, siteId);
      const headers = accessToken ? { 'X-Api-Key': accessToken } : {};
      client.connect(headers, () => {
        for (const subscription of availableSubscriptions) {
          client.unsubscribe(subscription.id);
        }
        for (const subscription of availableSubscriptions) {
          client.subscribe(
            subscription.destination,
            (message) => {
              if (message?.headers?.destination) {
                for (const sub of availableSubscriptions) {
                  if (message.headers.destination === sub.destination) {
                    onEmit(sub.service, JSON.parse(message.body));
                  }
                }
              }
            },
            { ...headers, id: subscription.id }
          );
        }
      });
    }

    return () => {
      if (client && client.connected) {
        client.disconnect();
      }
    };
  }, [accessToken, oryLoginStatus, companyId, siteId, onEmit]);
  return null;
};

WebSocket.propTypes = {
  accessToken: PropTypes.string,
  companyId: PropTypes.number,
  onEmit: PropTypes.func.isRequired,
  oryLoginStatus: PropTypes.string,
  siteId: PropTypes.number,
};

WebSocket.defaultProps = {
  accessToken: null,
  companyId: null,
  oryLoginStatus: null,
  siteId: null,
};

const mapStateToProps = (state) => ({
  accessToken: authSelectors.getAccessTokenSelector(state),
  companyId: companySelectors.getCompanyId(state),
  oryLoginStatus: authSelectors.getOryLoginStatusSelector(state),
  siteId: siteSelectors.getSiteId(state),
});

const mapDispatchToProps = {
  onEmit: actions.listeningFromSocket,
};

export default connect(mapStateToProps, mapDispatchToProps)(WebSocket);
