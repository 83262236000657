import { isArrayEmpty } from '../../../../../helpers/array';
import { ApartmentUser } from '../../../../../modules/apartmentAdmin/data';
import { hasPIN, hasRFID } from '../../../../../modules/apartmentAdmin/utils';

export function hasNoAccessGroup({
  apartmentAccessGroups,
  userAccessGroups,
}: {
  apartmentAccessGroups?: string[];
  userAccessGroups?: string[];
}): boolean {
  return isArrayEmpty(apartmentAccessGroups) && isArrayEmpty(userAccessGroups);
}

export function isAccessSettingsWarningVisible({
  apartmentAccessGroups,
  user,
  userAccessGroups,
}: {
  user: ApartmentUser;
  apartmentAccessGroups?: string[];
  userAccessGroups?: string[];
}): boolean {
  return (hasPIN(user) || hasRFID(user)) && isArrayEmpty(apartmentAccessGroups) && isArrayEmpty(userAccessGroups);
}
