import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import landingRemote from '../../assets/landing_remote.jpg';
import { ButtonLink } from '../../components';
import { PATHS } from '../../config/consts';
import messages from './messages';

const styles = {
  btn: {
    mt: 4,
    px: 6,
    py: 1,
  },
  content: {
    '& > div': {
      '& > *': {
        maxWidth: {
          md: '60%',
        },
      },
      display: 'table-cell',
      verticalAlign: 'middle',
    },
    display: 'table',
    height: '100%',
    maxWidth: '1050px',
    mx: 'auto',
    my: 0,
    position: 'relative',
    px: 4,
    py: 2,
    width: '100%',
  },
  description: {
    color: 'white',
    display: 'block',
    fontSize: {
      lg: '32px',
      md: '26px',
      sm: '24px',
      xs: '20px',
    },
    fontWeight: 'light',
  },
  root: (theme) => ({
    [theme.breakpoints.down('md')]: {
      height: '350px',
      pt: 0,
    },
    backgroundImage: `url(${landingRemote})`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    height: 0,
    lineHeight: 1.5,
    paddingTop: `${(630 / 1904) * 100}%`,
    position: 'relative',
  }),
  title: {
    color: 'white',
    display: 'block',
    fontSize: {
      lg: '44px',
      md: '40px',
      sm: '36px',
      xs: '32px',
    },
    fontWeight: 'regular',
    lineHeight: 1.1,
    mb: 2,
    mt: 0,
  },
  wrapper: {
    height: '100%',
    position: 'absolute',
    textAlign: {
      sm: 'left',
      xs: 'center',
    },
    top: 0,
    width: '100%',
  },
};

const RcMain = () => (
  <Box sx={styles.root}>
    <Box sx={styles.wrapper}>
      <Box sx={styles.content}>
        <div>
          <Typography sx={styles.title} variant="h2">
            <FormattedMessage
              values={{
                br: <br />,
                nbsp: <>&nbsp;</>,
                sup: (chunks) => <sup>{chunks}</sup>,
              }}
              {...messages.rcLandingPageRcMainTitle}
            />
          </Typography>
          <Typography component="span" sx={styles.description}>
            <FormattedMessage
              values={{
                br: <br />,
                nbsp: <>&nbsp;</>,
                sup: (chunks) => <sup>{chunks}</sup>,
              }}
              {...messages.rcLandingPageRcMainDescription}
            />
          </Typography>
          <ButtonLink color="primary" size="large" sx={styles.btn} to={PATHS.REGISTER} variant="contained">
            <FormattedMessage {...messages.rcLandingPageCreateAccount} />
          </ButtonLink>
        </div>
      </Box>
    </Box>
  </Box>
);

export default RcMain;
