import { all, call, delay, put, select, takeLatest } from 'redux-saga/effects';
import * as Api from '../../api/commonApi';
import { errorHelper, responseValidateStatusOrThrow } from '../helpers';
import * as localizationSelectors from '../localization/selectors';
import { addSnackbarSuccess } from '../notifications/actions';
import * as userSelectors from '../user/selectors';
import messages from './messages';
import types from './types';

export function* loadDataCompanySwitcher() {
  try {
    const response = yield call(Api.getCompanyList);
    yield responseValidateStatusOrThrow(response);
    yield put({
      payload: response.data?.companies || [],
      type: types.COMPANY_SWITCHER_LOAD_DATA_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.COMPANY_SWITCHER_LOAD_DATA_FAILURE, error));
  }
}

export function* loadGlobalFeatures() {
  try {
    const response = yield call(Api.getGlobalFeatures);
    yield put({
      payload: response?.data || [],
      type: types.LOAD_GLOBAL_FEATURES_SUCCESS,
    });
  } catch (error) {
    yield put({ type: types.LOAD_GLOBAL_FEATURES_FAILURE });
  }
}

export function* loadGTCDetail({ fullPath }) {
  try {
    const response = yield call(Api.getGTCDetail, fullPath);
    yield responseValidateStatusOrThrow(response);
    yield put({
      payload: response?.data || null,
      type: types.LOAD_GTC_DETAIL_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.LOAD_GTC_DETAIL_FAILURE, error));
  }
}

export function* loadGTCAgreements({ companyId }) {
  try {
    const user = yield select(userSelectors.getCurrentUserSelector);
    const agreements = yield call(Api.getGTCAgreements, companyId);
    yield responseValidateStatusOrThrow(agreements);
    const currentUser = yield call(Api.getGTCAgreementsCurrentUser);
    yield responseValidateStatusOrThrow(currentUser);
    yield put({
      payload: [...(agreements?.data || []), ...(currentUser?.data || [])].map((item) => ({
        ...item,
        authorEmail: item?.authorEmail || user.email || null,
        authorId: item?.authorId || user.id || null,
        companyId: parseInt(item?.companyId || companyId, 10),
      })),
      type: types.LOAD_GTC_AGREEMENTS_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.LOAD_GTC_AGREEMENTS_FAILURE, error));
  }
}

export function* loadGTCList({ companyId }) {
  try {
    const response = yield call(Api.getGTCList, companyId ? { companyId } : {});
    yield responseValidateStatusOrThrow(response);
    yield put({
      payload: response?.data || [],
      type: types.LOAD_GTC_LIST_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.LOAD_GTC_LIST_FAILURE, error));
  }
}

export function* sendSupport({ values }) {
  try {
    const response = yield call(Api.sendHelpForm, values);
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.SEND_SUPPORT_FORM_SUCCESS });
    yield put(addSnackbarSuccess(messages.commonSnackbarSupportMessageSentSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.SEND_SUPPORT_FORM_FAILURE, error));
  }
}

export function* loadDataSiteSwitcher({ companyId }) {
  try {
    const response = yield call(Api.getCompanyList);
    yield responseValidateStatusOrThrow(response);
    yield put({
      payload: (response.data?.companies || []).find((item) => item.id === companyId)?.sites || [],
      type: types.SITE_SWITCHER_LOAD_DATA_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.SITE_SWITCHER_LOAD_DATA_FAILURE, error));
  }
}

export function* getGtcOptOutText() {
  const userLang = yield select(localizationSelectors.getCurrentUserLanguage);

  try {
    const response = yield call(Api.getGtcOptOutText);
    yield responseValidateStatusOrThrow(response);
    yield put({
      payload:
        Object.entries(response.data.translations).find(
          (translation) => translation[0] === userLang.substring(0, 2)
        )?.[1] || JSON.parse(response.data.text),
      type: types.GET_GTC_OPT_OUT_TEXT_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_GTC_OPT_OUT_TEXT_FAILURE, error));
  }
}

export function* search({ onLoad, params }) {
  yield delay(500);
  yield put(onLoad(...params));
}

export default function* commonSagas() {
  yield all([
    takeLatest(types.COMPANY_SWITCHER_LOAD_DATA_REQUEST, loadDataCompanySwitcher),
    takeLatest(types.LOAD_GLOBAL_FEATURES_REQUEST, loadGlobalFeatures),
    takeLatest(types.LOAD_GTC_AGREEMENTS_REQUEST, loadGTCAgreements),
    takeLatest(types.LOAD_GTC_DETAIL_REQUEST, loadGTCDetail),
    takeLatest(types.LOAD_GTC_LIST_REQUEST, loadGTCList),
    takeLatest(types.SEND_SUPPORT_FORM_REQUEST, sendSupport),
    takeLatest(types.SITE_SWITCHER_LOAD_DATA_REQUEST, loadDataSiteSwitcher),
    takeLatest(types.GET_GTC_OPT_OUT_TEXT_REQUEST, getGtcOptOutText),
    takeLatest(types.SEARCH, search),
  ]);
}
