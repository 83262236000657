import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import QRCodeReader from '../../components/QRCodeReader';
import { QR_CODE_DEVICE_MASK } from '../../config/devices';
import messages from './messages';
import QRScanArea from './QRScanArea';
import ScanAlerts from './ScanAlerts';

const styles = {
  content: {
    '& .MuiAlert-root': {
      my: 0,
    },
    background: (theme) => theme.palette.qrScanner.background,
    bottom: 0,
    position: 'absolute',
    px: 2,
    top: 0,
    width: 'calc(100% - 238px)',
  },
  root: {
    height: '178px',
    mb: 2,
    mt: 2,
    position: 'relative',
  },
  title: {
    '& h6': {
      fontSize: 'medium',
      pb: 0.5,
    },
    color: 'white',
    pb: 2,
    pt: 1,
  },
};

const DeviceDesktopScanQR = ({ onSuccess }) => {
  const [error, setError] = useState('');

  const onScanSuccess = (decodedText) => {
    const match = decodedText?.match(QR_CODE_DEVICE_MASK);
    if (match && match.length >= 6) {
      onSuccess({
        securityCode: match[5],
        serialNumber: match[2],
      });
    }
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.content}>
        <Box sx={styles.title}>
          <Typography variant="h6">
            <FormattedMessage {...messages.deviceDesktopScanQRTitlesScanCode} />
          </Typography>
          <Typography variant="body2">
            <FormattedMessage {...messages.deviceDesktopScanQRTitlesScanCodeDescription} />
          </Typography>
        </Box>
        <ScanAlerts error={error} />
      </Box>
      <Box sx={styles.gradient} />
      <QRCodeReader
        error={error}
        onError={setError}
        onSuccess={onScanSuccess}
        videoContainerStyle={{
          marginLeft: 'auto',
          paddingTop: '178px',
          width: '238px',
        }}
      >
        <QRScanArea size={120} />
      </QRCodeReader>
    </Box>
  );
};

DeviceDesktopScanQR.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default DeviceDesktopScanQR;
