import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import SITE_TYPE from './types';

const SiteType = ({ type }) => {
  const value = type || SITE_TYPE.UNKNOWN;
  const color = useMemo(() => (value === SITE_TYPE.UNKNOWN ? 'error.dark' : 'inherit'), [value]);
  return (
    <Box
      color={color}
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }}
    >
      <FormattedMessage {...siteTypeMap[value]} />
    </Box>
  );
};

SiteType.propTypes = {
  type: PropTypes.oneOf(Object.values(SITE_TYPE)).isRequired,
};

export default SiteType;

const siteTypeMap = {
  [SITE_TYPE.MDU]: messages.siteTypeMdu,
  [SITE_TYPE.OTHER]: messages.siteTypeOther,
  [SITE_TYPE.SINGLE_VILLA]: messages.siteTypeSingleVilla,
  [SITE_TYPE.UNKNOWN]: messages.siteTypeUnknown,
};
