import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import messages from '../messages';

const styles = {
  rightGridPrice: {
    ml: {
      xl: 2,
      xs: 0,
    },
  },
  root: {
    mb: 4,
  },
  totalPriceContainer: {
    backgroundColor: 'subscription.backgroundLight',
    flexFlow: 'row',
    minHeight: (theme) => theme.spacing(8),
    p: {
      xl: 3,
      xs: 2,
    },
  },
};

const TotalPriceBar = ({ currency, price, priceVat, totalPriceNote }) => {
  const { formatNumber } = useIntl();
  const currencyString = currency ? { currency, style: 'currency' } : {};
  return (
    <Grid container direction="column" item sx={styles.root}>
      <Grid alignItems="center" container item sx={styles.totalPriceContainer}>
        <Grid alignItems="baseline" container item sm={7} xs={12}>
          <FormattedMessage
            {...messages.totalPriceBarTotalPriceTitle}
            values={{
              strong: (chunks) => (
                <Typography fontWeight="bold" variant="h6">
                  {chunks}
                </Typography>
              ),
            }}
          />
          <Box sx={{ display: { sm: 'block', xs: 'none' } }}>
            {totalPriceNote && (
              <Box color="text.secondary" pl={1}>
                {totalPriceNote}
              </Box>
            )}
          </Box>
        </Grid>
        <Grid alignItems="baseline" container item justifyContent="flex-end" sm={5} xs={12}>
          <Grid alignItems="baseline" container item justifyContent="flex-end" sm sx={styles.rightGridPrice} xl={7}>
            <Grid item xl={6} />
            <Grid item sm="auto" sx={{ textAlign: 'end' }} xl={6}>
              <Box fontSize="h6.fontSize" fontWeight="medium">
                {formatNumber(price, currencyString)}
              </Box>
            </Grid>
          </Grid>
          <Grid item sm="auto" xl="auto">
            <Box color="text.secondary" pl={1}>
              <FormattedMessage
                {...messages.totalPriceBarPriceVatInfo}
                values={{
                  priceVat: formatNumber(priceVat, currencyString),
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {priceVat === 1 && (
        <Grid container item sx={{ mt: 2 }}>
          <Typography color="secondary" variant="body2">
            <FormattedMessage
              {...messages.totalPriceBarAdditionalNote}
              values={{ priceVat: formatNumber(priceVat, currencyString) }}
            />
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
TotalPriceBar.propTypes = {
  currency: PropTypes.string,
  price: PropTypes.number,
  priceVat: PropTypes.number,
  totalPriceNote: PropTypes.node,
};

TotalPriceBar.defaultProps = {
  currency: '',
  price: 0,
  priceVat: 0,
  totalPriceNote: null,
};

export default TotalPriceBar;
