import { IDTMFSwitchCode } from '../../../modules/devices/store/deviceStore';

export interface ISwitchItem {
  code: string;
  codeWarn: boolean;
  enabled: boolean;
  id?: number;
  index: number;
  managed: boolean;
  name: string;
}

export function initialState(): Array<ISwitchItem> {
  const createItem = (index: number): ISwitchItem => ({
    code: '',
    codeWarn: false,
    enabled: false,
    index,
    managed: false,
    name: '',
  });

  return [createItem(1), createItem(2), createItem(3), createItem(4)];
}

export function fillInitStateItem(values: { switches: IDTMFSwitchCode[] }, item: ISwitchItem) {
  const row = values.switches.find((i) => i.index === item.index);

  if (row) {
    return { ...item, ...row };
  }

  return item;
}
