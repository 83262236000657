import Stack from '@mui/material/Stack';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Paper } from '../../../components';
import ButtonLinkNew from '../../../components/ButtonLinkNew';
import { SaveChangesBtn } from '../../../components/SaveChangesBtn';
import { PATHS } from '../../../config/consts';
import { replaceURLParams } from '../../../routes/helpers/pathParamsReplacer';
import { useGetApartmentInfoData } from '../hooks/useGetApartmentInfoData';
import messages from '../messages';
import ApartmentDataForm from './ApartmentDataForm';

function ApartmentInfo() {
  const { apartmentDetailData, companyId, floors, formMethods, isSiteMdu, licenceModel, onSubmit, siteId } =
    useGetApartmentInfoData();
  const watchFields = formMethods.watch();

  const disableSubmit = useMemo(
    () => !Object.keys(formMethods.formState.dirtyFields).length || !formMethods.formState.isValid,
    [watchFields]
  );

  return (
    <Paper tabContent>
      <ApartmentDataForm
        apartmentDetailData={apartmentDetailData}
        floors={floors}
        formMethods={formMethods}
        formWrapperProps={{
          container: true,
          item: true,
          md: 5,
          sm: 12,
        }}
        isSiteMdu={isSiteMdu}
        isModal={false}
        licenceModel={licenceModel}
        onSubmit={onSubmit}
      />
      <Stack direction="row" justifyContent="flex-end" spacing={1} mt={2}>
        <ButtonLinkNew
          link={replaceURLParams({ params: { companyId, siteId }, url: PATHS.APARTMENTS_OVERVIEW })}
          sx={{ mt: 2 }}
          message={<FormattedMessage {...messages.apartmentDetailButtonCancel} />}
        />
        <SaveChangesBtn disabled={disableSubmit} onClick={() => onSubmit(formMethods.getValues())} />
      </Stack>
    </Paper>
  );
}

export default ApartmentInfo;
