import {useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch} from "react-redux";

const useGetGlobalHooks = () => {
    const { formatMessage } = useIntl();
    const dispatch = useDispatch();
    const [step, setStep] = useState(1);
    const [siteType, setSiteType] = useState(undefined);

    return {
        dispatch,
        formatMessage,
        setSiteType,
        setStep,
        siteType,
        step,
    }
}

export default useGetGlobalHooks;
