import { defineMessages } from 'react-intl';

export default defineMessages({
  companyAboutModalClose: {
    defaultMessage: 'Close',
    description: 'Company about modal - close button',
    id: 'companyAboutModal.close',
  },
  companyAboutModalListItemOne: {
    defaultMessage: 'A company is created when you first create your My2N account.',
    description: 'Company about modal - first item of list',
    id: 'companyAboutModal.list.itemOne',
  },
  companyAboutModalListItemThird: {
    defaultMessage: 'Each company uses its own billing information.',
    description: 'Company about modal - third item of list',
    id: 'companyAboutModal.list.itemThird',
  },
  companyAboutModalListItemTwo: {
    defaultMessage: 'It can include one or more sites (e.g. buildings).',
    description: 'Company about modal - second item of list',
    id: 'companyAboutModal.list.itemTwo',
  },
  companyAboutModalNotification: {
    defaultMessage:
      'Did we help? To change the settings of your intercom or device, ' +
      '<strong>contact your site administrator</strong>.',
    description: 'Company about modal - notification',
    id: 'companyAboutModal.notification',
  },
  companyAboutModalTitle: {
    defaultMessage: 'What is a company?',
    description: 'Company about modal - what is a company',
    id: 'companyAboutModal.title',
  },
  companyAboutModalWhatIsSite: {
    defaultMessage: 'What is site?',
    description: 'Company about modal - what is site button',
    id: 'companyAboutModal.whatIsSite',
  },
  installAppModalAlreadyInstalledButton: {
    defaultMessage: 'App already installed',
    description: 'Install app modal - app already installed button',
    id: 'installAppModalAlreadyInstalledButton',
  },
  installAppModalCloseButton: {
    defaultMessage: 'Close',
    description: 'Install app modal - close button',
    id: 'installAppModalCloseButton',
  },
  installAppModalInstallTitle: {
    defaultMessage: 'Install My2N app',
    description: 'Install app modal - install title',
    id: 'installAppModalInstallTitle',
  },
  installAppModalLaterButton: {
    defaultMessage: 'Later',
    description: 'Install app modal - later button',
    id: 'installAppModalLaterButton',
  },
  installAppModalWelcomeTitle: {
    defaultMessage: 'Welcome to My2N',
    description: 'Install app modal - welcome title',
    id: 'installAppModalWelcomeTitle',
  },
  userProfileDevicesTableTitleOne: {
    defaultMessage: 'My device in My2N',
    description: 'User profile device title',
    id: 'userProfile.devicesTable.titleOne',
  },
  userProfileDevicesTableTitlePlural: {
    defaultMessage: 'My devices in My2N',
    description: 'User profile devices table title',
    id: 'userProfile.devicesTable.title.plural',
  },
  userProfileTitle: {
    defaultMessage: 'My Profile',
    description: 'User profile - my profile',
    id: 'userProfile.title',
  },
});
