import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { batchPropTypes, formikValuesPropTypes, helperFunctionsPropTypes } from '../../types';
import RowOne from './RowOne';
import RowTwo from './RowTwo';

const PromoBatchForm = ({
  batch,
  batchIndex,
  currency,
  formikValues,
  helperFunctions,
  isSiteMdu,
  maxSubscriptions,
  maxYears,
  minSubscriptions,
  minYears,
  name,
  unitFullPrice,
}) => (
  <Grid container spacing={2}>
    <RowOne batchIndex={batchIndex} formikValues={formikValues} helperFunctions={helperFunctions} name={name} />
    <RowTwo
      batch={batch}
      currency={currency}
      isSiteMdu={isSiteMdu}
      maxSubscriptions={maxSubscriptions}
      maxYears={maxYears}
      minSubscriptions={minSubscriptions}
      minYears={minYears}
      name={name}
      unitFullPrice={unitFullPrice}
    />
  </Grid>
);

PromoBatchForm.propTypes = {
  batch: batchPropTypes.isRequired,
  batchIndex: PropTypes.number.isRequired,
  currency: PropTypes.string.isRequired,
  formikValues: formikValuesPropTypes.isRequired,
  helperFunctions: helperFunctionsPropTypes.isRequired,
  isSiteMdu: PropTypes.bool.isRequired,
  maxSubscriptions: PropTypes.number.isRequired,
  maxYears: PropTypes.number.isRequired,
  minSubscriptions: PropTypes.number.isRequired,
  minYears: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  unitFullPrice: PropTypes.number.isRequired,
};

export default PromoBatchForm;
