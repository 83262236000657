import Alert from '@mui/material/Alert';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

function IntroWarning() {
  return (
    <Alert severity="warning">
      <FormattedMessage {...messages.siteUsersImportIntroStepWarning} />
    </Alert>
  );
}

export default IntroWarning;
