import { defineMessages } from 'react-intl';

export default defineMessages({
  AAmodalPinformCopy: {
    defaultMessage: 'Copy',
    id: 'AAmodal.PINform.copy',
  },
  SharedNotificationQRcopied: {
    defaultMessage: 'QR code copied',
    id: 'shared.notification.QRcopied',
  },
});
