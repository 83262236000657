import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import React from 'react';
import { CustomDate } from '../../../components';

const styles = {
  active: {
    fontWeight: 'bold',
  },
  box: {
    display: 'flex',
    px: 0,
    py: 0.5,
  },
  effective: {
    color: 'black',
  },
  item: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  link: {
    color: 'primary.main',
    display: 'inline-block',
    textDecoration: 'none',
  },
  posted: {
    color: 'secondary.main',
    ml: 0.5,
  },
  root: {
    mb: 0,
    mt: 2,
  },
};

const GTCVersionList = ({ basePath, versions }) => (
  <Box sx={styles.root}>
    {versions.map((item, key) => (
      <Box key={`versions-${item.id}`} sx={styles.item}>
        <Link
          href={basePath.replace(':slug', item.path).replace(':version', `v${item.version}`)}
          sx={{
            ...styles.link,
            ...(item.inEffect && styles.active),
          }}
          target="_blank"
          title={item.title}
        >
          {`Version ${versions.length - key}`}
        </Link>
        <Box sx={styles.box}>
          <Box sx={styles.effective}>
            <CustomDate dateValue={item.dateEffective} isLegalDocument />
          </Box>
          <Box sx={styles.posted}>
            {'('}
            <CustomDate dateValue={item.dateCreated ? item.dateCreated : item.dateEffective} isLegalDocument />
            {')'}
          </Box>
        </Box>
      </Box>
    ))}
  </Box>
);

GTCVersionList.propTypes = {
  basePath: PropTypes.string.isRequired,
  versions: PropTypes.arrayOf(
    PropTypes.shape({
      dateCreated: PropTypes.string,
      dateEffective: PropTypes.string,
      inEffect: PropTypes.bool,
      name: PropTypes.string,
      path: PropTypes.string,
      version: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    })
  ).isRequired,
};

export default GTCVersionList;
