import { defineMessages } from 'react-intl';

export default defineMessages({
  ApartmentDataGridColumnsAccess: {
    defaultMessage: 'Access',
    description: '',
    id: 'apartmentDataGrid.columns.access',
  },
  ApartmentDataGridColumnsApartmentNameDesktop: {
    defaultMessage: 'Apartment',
    description: '',
    id: 'apartmentDataGrid.columns.apartmentNameDesktop',
  },
  ApartmentDataGridColumnsApartmentNameMobile: {
    defaultMessage: 'Apartment',
    description: '',
    id: 'apartmentDataGrid.columns.apartmentNameMobile',
  },
  ApartmentDataGridColumnsApartmentStatus: {
    defaultMessage: 'Apartment status',
    description: '',
    id: 'apartmentDataGrid.columns.apartmentStatus',
  },
  ApartmentDataGridColumnsApartmentStatusMobile: {
    defaultMessage: 'Status',
    description: '',
    id: 'apartmentDataGrid.columns.apartmentStatusMobile',
  },
  ApartmentDataGridColumnsCountDevices: {
    defaultMessage: 'Devices',
    description: '',
    id: 'apartmentDataGrid.columns.countDevices',
  },
  ApartmentDataGridColumnsFloor: {
    defaultMessage: 'Floor',
    description: '',
    id: 'apartmentDataGrid.columns.floor',
  },
  ApartmentDataGridColumnsPaidServices: {
    defaultMessage: 'Paid services',
    id: 'apartmentDataGrid.columns.paidServices',
  },
  ApartmentDataGridColumnsUsersCount: {
    defaultMessage: 'Users',
    description: '',
    id: 'apartmentDataGrid.columns.usersCount',
  },
  apartmentDataGridTooltipsAccessIcon: {
    defaultMessage: 'Apartment is used for access control',
    description: '',
    id: 'apartmentDataGrid.tooltips.accessIcon',
  },
});
