import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import DevicesInfoChip from '../DevicesInfoChip';
import messages from './messages';

const CallStatus = ({ size, status }) => {
  let styles = null;
  if (['REGISTERED', 'READY'].includes(status)) {
    styles = 'success';
  } else if (
    ['CONFIGURATION_NEEDED', 'CREDENTIALS_NEEDED', 'REGISTERING', 'WAITING', 'SYNCHRONIZING'].includes(status)
  ) {
    styles = 'warning';
  } else if (
    ['UNLICENSED', 'NOT_REGISTERED', 'MISSING_CALL_SETTINGS', 'SYNCHRONIZATION_FAILED', 'UNREACHABLE'].includes(status)
  ) {
    styles = 'error';
  }

  const message = statusMap[status] ? statusMap[status] : messages.callStatusUnknown;

  return (
    <DevicesInfoChip
      error={styles === 'error'}
      label={<FormattedMessage {...message} />}
      size={size}
      success={styles === 'success'}
      variant="outlined"
      warning={styles === 'warning'}
    />
  );
};

CallStatus.propTypes = {
  size: PropTypes.string.isRequired,
  status: PropTypes.string,
};

CallStatus.defaultProps = {
  status: undefined,
};

export default CallStatus;

const statusMap = {
  CONFIGURATION_NEEDED: messages.callStatusConfigurationNeeded,
  CREDENTIALS_NEEDED: messages.callStatusCredentialsNeeded,
  LOCAL: messages.callStatusLocal,
  MISSING_CALL_SETTINGS: messages.callStatusMissingCallSettings,
  NOT_REGISTERED: messages.callStatusNotRegistered,
  READY: messages.callStatusReady,
  REGISTERED: messages.callStatusRegistered,
  REGISTERING: messages.callStatusRegistering,
  SYNCHRONIZATION_FAILED: messages.callStatusSynchronizationFailed,
  SYNCHRONIZING: messages.callStatusSynchronizing,
  Unknown: messages.callStatusUnknown,
  UNLICENSED: messages.callStatusUnlicensed,
  UNREACHABLE: messages.callStatusUnreachable,
  WAITING: messages.callStatusWaiting,
};
