import { Alert, AlertColor, FormControlLabel, FormHelperText, Radio } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useGetGlobalInfo } from '../../../../../hooks/useGetGlobalInfo';
import RadioGroup from '../../../../HookFormFields/RadioGroup';
import { FormFieldNames } from '../../../validationSchema';
import { SelectId } from '../SelectId/SelectId';
import messages from './messages';

interface RadioGroupVariantProps {
  hideAlert?: boolean;
  firstOptionDisabled?: boolean;
  alertVariant?: AlertColor;
  hideSelectId?: boolean;
  selectIdDisabled?: boolean;
  locationIds?: string[];
}

export function RadioGroupVariant({
  alertVariant,
  firstOptionDisabled,
  hideAlert,
  hideSelectId,
  locationIds,
  selectIdDisabled,
}: RadioGroupVariantProps) {
  const { formatMessage } = useGetGlobalInfo();
  return (
    <>
      <RadioGroup name={FormFieldNames.ManageBLE}>
        <FormControlLabel
          disabled={firstOptionDisabled}
          control={<Radio />}
          value={true}
          label={formatMessage(messages.configureMy2NAccessModal4ManageLabel)}
        />
        <FormHelperText sx={{ mt: 0, pl: 4 }}>
          <FormattedMessage {...messages.configureMy2NAccessModal4ManageHelper} />
        </FormHelperText>
        {hideSelectId ? <></> : <SelectId locationIds={locationIds} disabled={selectIdDisabled} />}
        <FormControlLabel
          control={<Radio />}
          value={false}
          label={formatMessage(messages.configureMy2NAccessModal4DoNotManageLabel)}
        />
        <FormHelperText sx={{ mt: 0, pl: 4 }}>
          <FormattedMessage {...messages.configureMy2NAccessModal4DoNotManageHelper} />
        </FormHelperText>
      </RadioGroup>
      {!hideAlert && (
        <Alert severity={alertVariant} sx={{ mt: 2 }}>
          <FormattedMessage
            {...(alertVariant === 'info'
              ? messages.configureMy2NAccessModal4Info
              : messages.configureMy2NAccessModal4DoNotManageWarn)}
          />
        </Alert>
      )}
    </>
  );
}
