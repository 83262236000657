import authTypes from '../auth/types';
import types from './types';

export const initialState = {
  detail: {
    data: null,
    error: null,
    isFetching: false,
  },
  list: {
    data: [],
    didInvalid: false,
    error: null,
    filter: { fulltext: '' },
    isFetching: false,
    order: '-startAt',
    page: 0,
    resultsAlert: 0,
    resultsTotal: 0,
    rowsPerPage: 100,
  },
};

const reducers = {
  [types.GET_ACCESS_LOG_DETAIL_REQUEST]: (state) => ({
    ...state,
    detail: {
      ...state.detail,
      isFetching: true,
    },
  }),

  [types.GET_ACCESS_LOG_DETAIL_SUCCESS]: (state, { payload }) => ({
    ...state,
    detail: {
      ...state.detail,
      data: payload,
      error: null,
      isFetching: false,
    },
  }),

  [types.GET_ACCESS_LOG_DETAIL_FAILURE]: (state, { error }) => ({
    ...state,
    detail: {
      ...state.detail,
      data: null,
      error,
      isFetching: false,
    },
  }),

  [types.GET_ACCESS_LOGS_LIST_REQUEST]: (state) => ({
    ...state,
    list: {
      ...state.list,
      isFetching: true,
    },
  }),

  [types.GET_ACCESS_LOGS_LIST_SUCCESS]: (state, { payload, ...actions }) => ({
    ...state,
    list: {
      ...state.list,
      data: payload.results,
      didInvalid: false,
      error: null,
      filter: actions.filter,
      isFetching: false,
      order: actions.order,
      page: actions.page,
      resultsAlert: payload.resultsAlert || 0,
      resultsTotal: payload.resultsTotal || 0,
      rowsPerPage: actions.rowsPerPage,
    },
  }),

  [types.GET_ACCESS_LOGS_LIST_FAILURE]: (state, { error }) => ({
    ...state,
    list: {
      ...initialState.list,
      error,
    },
  }),
};

const reducer = (state = initialState, action = {}) => {
  if (reducers[action.type]) {
    return reducers[action.type](state, action);
  }
  return state;
};

export default reducer;
