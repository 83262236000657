import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { AccessLogDataGrid, DataGridSearchField, Title } from '../../components';
import { AccessLogPropType } from '../../components/AccessLogDataGrid/type';
import AccessLogDetailModal from '../../components/AccessLogDetailModal';
import { changeFilter, dataGridProps, useDataGrid } from '../../helpers/dataGrid';
import { useUrlParams } from '../../helpers/urlParams';
import actions, { selectors } from '../../modules/accessLog';
import commonActions from '../../modules/common';
import messages from './messages';

const AccessLogPage = ({ accessLogsTable, data, isFetching, onLoadAccessLogs, onSearch }) => {
  const { companyId, siteId } = useUrlParams();
  const [showDetailId, setShowDetailId] = useState('');
  const [fullText, setFullText] = useState(accessLogsTable.filter.fulltext);

  const { onOrderBy, onRowsPerPageChange, onSelectPage } = useDataGrid(
    onLoadAccessLogs,
    {
      companyId,
      emptySite: siteId === null,
      filter: accessLogsTable.filter,
      order: accessLogsTable.order,
      page: accessLogsTable.page,
      rowsPerPage: accessLogsTable.rowsPerPage,
      siteId,
    },
    false,
    isFetching
  );

  const onChangeFilter = ({ target }, isFulltext = false) => {
    const { name, value } = target;
    if (isFulltext) {
      setFullText(value);
    }
    changeFilter(onSearch, actions.getAccessLogsList, {
      ...accessLogsTable,
      companyId,
      siteId,
    })(name, value);
  };

  return (
    <>
      <Title
        heading={<FormattedMessage {...messages.AccessLogPageToolsTitle} />}
        rightSide={<DataGridSearchField fulltext={fullText} onChangeFilter={(event) => onChangeFilter(event, true)} />}
      />
      <AccessLogDataGrid
        accessLogsTable={accessLogsTable}
        data={data}
        isFetching={isFetching}
        onAccessLogShowDetail={setShowDetailId}
        onChangeFilter={onChangeFilter}
        onOrderBy={onOrderBy}
        onRowsPerPageChange={onRowsPerPageChange}
        onSelectPage={onSelectPage}
      />
      <AccessLogDetailModal logId={showDetailId} onClose={() => setShowDetailId('')} open={!!showDetailId} />
    </>
  );
};

AccessLogPage.propTypes = {
  accessLogsTable: dataGridProps.isRequired,
  data: PropTypes.arrayOf(AccessLogPropType).isRequired,
  isFetching: PropTypes.bool.isRequired,
  onLoadAccessLogs: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  accessLogsTable: selectors.getAccessLogsList(state),
  data: selectors.getAccessLogsListData(state),
  isFetching: selectors.isAccessLogsListLoading(state),
});

const dispatchToProps = {
  onLoadAccessLogs: actions.getAccessLogsList,
  onSearch: commonActions.search,
};

export default connect(mapStateToProps, dispatchToProps)(AccessLogPage);
