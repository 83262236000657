import { DEVICE_TYPE } from '../../../../config/devices';

export const hasConfigurationModeField = (deviceType: string) =>
  [
    DEVICE_TYPE.CLIP,
    DEVICE_TYPE.ID_COMPACT,
    DEVICE_TYPE.ID_TALK,
    DEVICE_TYPE.ID_VIEW,
    DEVICE_TYPE.IP_INTERCOM,
    DEVICE_TYPE.IP_INTERCOM_V2,
    DEVICE_TYPE.IP_ONE_INTERCOM,
    DEVICE_TYPE.IP_STYLE,
  ].includes(deviceType);

export const hasConnectionModeField = (deviceType: string) =>
  [DEVICE_TYPE.IDT, DEVICE_TYPE.ID_COMPACT, DEVICE_TYPE.ID_TALK, DEVICE_TYPE.CLIP, DEVICE_TYPE.ID_VIEW].includes(
    deviceType
  );

export const hasIpAddressField = (deviceType: string) =>
  [DEVICE_TYPE.IP_INTERCOM, DEVICE_TYPE.IP_ONE_INTERCOM, DEVICE_TYPE.IP_INTERCOM_V2, DEVICE_TYPE.IP_STYLE].includes(
    deviceType
  );

export const hasCallsIdentifierField = (deviceType: string) =>
  [DEVICE_TYPE.IDT, DEVICE_TYPE.ID_COMPACT, DEVICE_TYPE.ID_TALK, DEVICE_TYPE.CLIP, DEVICE_TYPE.ID_VIEW].includes(
    deviceType
  );
