import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ACCESS_LOG_FAILED_RESULTS } from '../../../config/consts';
import { dataGridProps } from '../../../helpers/dataGrid';
import messages from '../messages';

const FilterHead = ({ accessLogsTable, onChangeFilter }) => {
  const onShowFailedRecords = (e) => {
    onChangeFilter({
      target: {
        name: 'results',
        value: e.target.checked ? ACCESS_LOG_FAILED_RESULTS.join(',') : null,
      },
    });
  };

  const filterHead = accessLogsTable.filter.fulltext
    ? messages.noRowsSearchOverlayFilterHeadRecordsFulltext
    : messages.noRowsSearchOverlayFilterHeadRecords;

  return (
    <Grid container sx={{ pb: 2, px: 2 }}>
      <Grid item>
        <Typography
          sx={{
            display: {
              lg: 'inline-block',
            },
            mb: {
              lg: 0,
              xs: 1,
            },
            mr: 3,
          }}
        >
          <FormattedMessage
            {...filterHead}
            values={{
              alerts: accessLogsTable?.resultsAlert,
              strong: (text) => <strong>{text}</strong>,
              total: accessLogsTable.resultsTotal,
            }}
          />
        </Typography>
        {accessLogsTable?.resultsAlert > 0 && (
          <FormControlLabel
            control={
              <Checkbox
                checked={!!accessLogsTable?.filter?.results}
                color="primary"
                name="showFailed"
                onChange={onShowFailedRecords}
              />
            }
            label={
              <Typography>
                <FormattedMessage {...messages.noRowsSearchOverlayFilterHeadShowFaileds} />
              </Typography>
            }
            sx={{
              my: {
                lg: -1,
              },
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

FilterHead.propTypes = {
  accessLogsTable: dataGridProps.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
};

export default FilterHead;
