import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedNumber } from 'react-intl';

const styles = {
  price: {
    fontWeight: 'bold',
    marginRight: 2,
    mr: {
      xl: 0,
      xs: 2,
    },
    textAlign: 'end',
  },
};

const ItemPrice = ({ currency, price }) => (
  <Grid item sm="auto" xl={6} xs={6}>
    <Typography sx={styles.price}>
      <FormattedNumber
        currency={currency}
        // eslint-disable-next-line react/style-prop-object
        style="currency"
        value={price}
      />
    </Typography>
  </Grid>
);

ItemPrice.propTypes = {
  currency: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
};

export default ItemPrice;
