import { IApartmentsTitleData } from '../../components/UserTitle/data';
import { IApartmentDetail } from '../../modules/apartments/store';
import { TId } from '../../modules/commonTypes';

export function getUserApartmentsTitleData(
  apartmentsList?: IApartmentDetail[],
  userApartmentIds?: TId[]
): IApartmentsTitleData {
  const firstApartment = apartmentsList?.find((apartment) => apartment.id === userApartmentIds?.[0]);

  return {
    count: userApartmentIds?.length || 0,
    id: firstApartment?.id,
    name: firstApartment?.name,
    number: firstApartment?.number,
  };
}
