import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { FE_ROLES } from '../../config/consts';
import actions from '../../modules/user';
import { IUser, IUserBase } from '../../modules/user/store';
import ActionModalError from '../_DesignSystemWrappers/Modal/ActionModalError';
import SelectedUser from '../UserPinModalNew/SelectedUser';
import messages from './messages';

export interface IUserData extends IUserBase {
  role: IUser['role'];
}

interface IRevokeUserPinModal {
  isGuestQrEnabled?: boolean;
  onClose: () => void;
  open: boolean;
  user: IUserData;
}

export function RevokeUserPinModal({ isGuestQrEnabled, onClose, open, user }: IRevokeUserPinModal) {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const handleDelete = () => {
    dispatch(actions.revokeUserPinRequest(user.id));
    onClose();
  };

  return (
    <ActionModalError
      icon={<DeleteIcon />}
      isPrimaryButtonDisabled={false}
      muiDialogProps={{
        maxWidth: 'xs',
      }}
      onClose={onClose}
      open={open}
      primaryButtonAction={handleDelete}
      primaryButtonText={formatMessage(
        isGuestQrEnabled ? messages.revokeUserQrModalSubmit : messages.revokeUserPinModalSubmit
      )}
      secondaryButtonText={formatMessage(messages.revokeUserPinModalCancel)}
      title={formatMessage(isGuestQrEnabled ? messages.revokeUserQrModalTitle : messages.revokeUserPinModalTitle)}
    >
      <Box sx={{ my: 2.5 }}>
        {user.role === FE_ROLES.GUEST ? (
          <Typography sx={{ mb: 1 }} variant="body2">
            {isGuestQrEnabled ? (
              <FormattedMessage {...messages.revokeUserQrModalText} values={{ br: <br /> }} />
            ) : (
              <FormattedMessage {...messages.AAmodalDeleteGuestPinText} />
            )}
          </Typography>
        ) : (
          <>
            <Typography sx={{ mb: 1 }} variant="body2">
              <FormattedMessage {...messages.revokeUserPinModalDescription} />
            </Typography>
            <SelectedUser user={user} />
            <Typography sx={{ mt: 1 }} variant="body2">
              <FormattedMessage {...messages.revokeUserPinModalBottomDescription} />
            </Typography>
          </>
        )}
      </Box>
    </ActionModalError>
  );
}

export default RevokeUserPinModal;
