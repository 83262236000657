/* eslint-disable */
import React, { useEffect } from 'react';
import { Form } from 'formik';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import InputAdornment from '@mui/material/InputAdornment';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import FormikFields from '../FormikFields';
import messages from './messages';
import styles from './styles';
import RecapBatch from './RecapBatch';

const FormComponent = ({ isValid, values, setFieldValue, setValid, onClose, step, setStep, username }) => {
  const calcTotal = (s, c) => {
    const isNumber = (value) => /^\d+$/.test(value);
    if (isNumber(s) && isNumber(c)) {
      return parseInt(s, 10) * parseInt(c, 10);
    }
    return 0;
  };

  useEffect(() => {
    if (!isValid && step > 1) {
      setStep(1);
    }
    setValid(isValid);
  }, [isValid]);

  return (
    <Form autoComplete="off" noValidate>
      {step === 1 && (
        <Grid container>
          <Grid container item sx={styles.firstGridRow}>
            <FormLabel component="legend" sx={styles.textFieldLabel}>
              <FormattedMessage {...messages.createBatchDialogNameLabel} />
            </FormLabel>
            <FormikFields.Input
              fullWidth
              helperText={<FormattedMessage {...messages.createBatchDialogNameHelperText} />}
              name="name"
              required
              sx={{ ...styles.textField, ...styles.boldInput }}
              variant="outlined"
            />
          </Grid>
          <Grid container item columnSpacing={4} sx={styles.gridRow}>
            <Grid item xs={3}>
              <FormLabel component="legend" sx={styles.textFieldLabel}>
                <FormattedMessage {...messages.createBatchDialogChannelLabel} />
              </FormLabel>
              <ButtonGroup fullWidth size="large" sx={styles.channelButtonGroup}>
                <Button
                  color={values.channel === 'PURCHASE' ? 'primary' : 'secondary'}
                  onClick={() => setFieldValue('channel', 'PURCHASE')}
                  variant={values.channel === 'PURCHASE' ? 'contained' : 'outlined'}
                >
                  <FormattedMessage {...messages.createBatchDialogChannelTitleLeftButton} />
                </Button>
                <Button
                  color={values.channel === 'PROMO' ? 'primary' : 'secondary'}
                  onClick={() => setFieldValue('channel', 'PROMO')}
                  variant={values.channel === 'PROMO' ? 'contained' : 'outlined'}
                >
                  <FormattedMessage {...messages.createBatchDialogChannelTitleRightButton} />
                </Button>
              </ButtonGroup>
            </Grid>
            <Grid item xs={3}>
              <FormLabel component="legend" sx={styles.textFieldLabel}>
                <FormattedMessage {...messages.createBatchDialogSizeLabel} />
              </FormLabel>
              <FormikFields.Input
                fullWidth
                helperText={<FormattedMessage {...messages.createBatchDialogSizeHelperText} />}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={styles.inputAdornment}>
                      <FormattedMessage {...messages.createBatchDialogSizeAdornment} />
                    </InputAdornment>
                  ),
                }}
                name="size"
                required
                sx={{ ...styles.textField, ...styles.boldInput, ...styles.adornment }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={3}>
              <FormLabel component="legend" sx={styles.textFieldLabel}>
                <FormattedMessage {...messages.createBatchDialogCreditLabel} />
              </FormLabel>
              <FormikFields.Input
                fullWidth
                helperText={<FormattedMessage {...messages.createBatchDialogCreditHelperText} />}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={styles.inputAdornment}>
                      <FormattedMessage {...messages.createBatchDialogCreditAdornment} />
                    </InputAdornment>
                  ),
                }}
                name="credit"
                required
                sx={{ ...styles.textField, ...styles.boldInput, ...styles.adornment }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={3}>
              <FormLabel component="legend" sx={styles.textFieldLabel}>
                <FormattedMessage {...messages.createBatchDialogTotalLabel} />
              </FormLabel>
              <FormikFields.Input
                disabled
                fullWidth
                helperText={<FormattedMessage {...messages.createBatchDialogTotalHelperText} />}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" sx={styles.inputAdornment}>
                      <FormattedMessage {...messages.createBatchDialogTotalAdornment} />
                    </InputAdornment>
                  ),
                }}
                name="total"
                sx={{ ...styles.textField, ...styles.boldInput, ...styles.adornment }}
                value={calcTotal(values.size, values.credit)}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container item columnSpacing={4} sx={styles.gridRow}>
            <FormikFields.DateRange
              fromLabel={<FormattedMessage {...messages.createBatchDialogDateCreatedLabel} />}
              fromName="dateCreated"
              helperFromText={<FormattedMessage {...messages.createBatchDialogDateCreatedHelperText} />}
              helperToText={<FormattedMessage {...messages.createBatchDialogValidityToHelperText} />}
              monthsName="months"
              toLabel={<FormattedMessage {...messages.createBatchDialogValidityToLabel} />}
              toName="validityTo"
            />
          </Grid>
          <Grid container item columnSpacing={4} sx={styles.gridRow}>
            <Grid item sx={styles.invoiceGrid}>
              <FormLabel component="legend" sx={styles.textFieldLabel}>
                <FormattedMessage {...messages.createBatchDialogInvoiceLabel} />
              </FormLabel>
              <FormikFields.Input
                fullWidth
                helperText={<FormattedMessage {...messages.createBatchDialogInvoiceHelperText} />}
                name="invoice"
                required
                sx={styles.textField}
                variant="outlined"
              />
            </Grid>
            <Grid item sx={styles.invoiceGrid}>
              <FormLabel component="legend" sx={styles.textFieldLabel}>
                <FormattedMessage {...messages.createBatchDialogUtmLabel} />
              </FormLabel>
              <FormikFields.Input
                fullWidth
                helperText={<FormattedMessage {...messages.createBatchDialogUtmHelperText} />}
                name="utm"
                required
                sx={{ ...styles.textField, ...styles.notes }}
                variant="outlined"
              />
            </Grid>
          </Grid>
          <Grid container item columnSpacing={4} sx={styles.gridRow}>
            <Grid item xs={12}>
              <FormLabel component="legend" sx={styles.textFieldLabel}>
                <FormattedMessage {...messages.createBatchDialogDescriptionLabel} />
              </FormLabel>
              <FormikFields.Input
                fullWidth
                maxRows={6}
                minRows={6}
                multiline
                name="description"
                sx={{ ...styles.textField, ...styles.notes }}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Grid>
      )}
      {step === 2 && (
        <RecapBatch
          calculateTotalCredits={calcTotal}
          onClose={onClose}
          onSetDetails={() => setStep((prevState) => prevState - 1)}
          username={username}
          values={values}
        />
      )}
    </Form>
  );
};

FormComponent.propTypes = {
  isValid: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setValid: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  username: PropTypes.string.isRequired,
  values: PropTypes.shape({
    name: PropTypes.string,
    channel: PropTypes.oneOf(['PROMO', 'PURCHASE']),
    credit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    dateCreated: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    validityTo: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    months: PropTypes.number,
    invoice: PropTypes.string,
    utm: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
};

export default FormComponent;
