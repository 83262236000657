import { defineMessages } from 'react-intl';

export default defineMessages({
  accessGroupDevicesDataGridDataGridRowMenuEdit: {
    defaultMessage: 'Edit device',
    description: 'Table row actions - edit',
    id: 'accessGroupDevicesDataGrid.dataGridRowMenu.edit',
  },
  accessGroupDevicesDataGridDataGridRowMenuLogActivate: {
    defaultMessage: 'Activate logging',
    description: 'Table row actions - log activate',
    id: 'accessGroupDevicesDataGrid.dataGridRowMenu.logActivate',
  },
  accessGroupDevicesDataGridDataGridRowMenuLogDeactivate: {
    defaultMessage: 'Deactivate logging',
    description: 'Table row actions - log deactivate',
    id: 'accessGroupDevicesDataGrid.dataGridRowMenu.logDeactivate',
  },
  accessGroupDevicesDataGridDataGridRowMenuRacActivate: {
    defaultMessage: 'Activate Residential Access',
    description: 'Table row actions - rac activate',
    id: 'accessGroupDevicesDataGrid.dataGridRowMenu.racActivate',
  },
  accessGroupDevicesDataGridDataGridRowMenuRacDeactivate: {
    defaultMessage: 'Deactivate Residential Access',
    description: 'Table row actions - rac deactivate',
    id: 'accessGroupDevicesDataGrid.dataGridRowMenu.racDeactivate',
  },
  accessGroupDevicesDataGridDataGridRowMenuRemove: {
    defaultMessage: 'Remove from group',
    description: 'Table row actions - remove',
    id: 'accessGroupDevicesDataGrid.dataGridRowMenu.remove',
  },
});
