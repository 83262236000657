import React from 'react';
import Alert from '../Alert';

type TCredentialAlert = string | React.JSX.Element | (string | React.JSX.Element)[];

function CredentialAlert({ infoText }: { infoText: TCredentialAlert }) {
  return (
    <Alert severity="info" sx={{ minHeight: 168 }}>
      {Array.isArray(infoText) ? infoText.map((item) => item) : infoText}
    </Alert>
  );
}

export default CredentialAlert;
