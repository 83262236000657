import { defineMessages } from 'react-intl';

export default defineMessages({
  apartmentDeleteConfirmModalCancelButton: {
    defaultMessage: 'Cancel',
    description: 'Cancel button',
    id: 'apartmentDeleteConfirmModal.cancelButton',
  },
  apartmentDeleteConfirmModalConfirmButton: {
    defaultMessage: 'Delete apartment',
    description: 'Confirm button',
    id: 'apartmentDeleteConfirmModal.confirmButton',
  },
  apartmentDeleteConfirmModalText: {
    defaultMessage: 'Are you sure you want to delete this apartment?',
    description: 'Delete apartment - text in modal',
    id: 'apartmentDeleteConfirmModal.text',
  },
  apartmentDeleteConfirmModalTitle: {
    defaultMessage: 'Confirm delete apartment',
    description: 'Delete apartment',
    id: 'apartmentDeleteConfirmModal.title',
  },
});
