import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import { DeviceTypeLabel, TableFooter } from '../../../components';
import { table } from '../../../config/styles';
import { useDataGrid } from '../../../helpers/dataGrid';
import messages from '../messages';

const styles = {
  disabled: {
    opacity: 0.38,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    mb: 2,
  },
  inputSearch: {
    '& input': {
      p: 1.5,
    },
  },
  loading: {
    width: '100%',
  },
  loadingWrapper: {
    '& td': {
      m: 0,
      p: 0,
      width: '100%',
    },
    bgcolor: 'inherit !important',
  },
  noResults: {
    '& td': {
      fontWeight: 'bold',
      textAlign: 'center',
    },
  },
  table: {
    ...table(),
    '& tr:hover': {
      cursor: 'pointer',
    },
  },
  title: {
    fontSize: '1.25rem',
    fontWight: 400,
    lineHeight: 1.125,
  },
};

/**
 * @usage Use only inside the Formik component
 */
const PhonebookMembersTable = ({
  companyId,
  data,
  didInvalid,
  field,
  filter,
  fulltext,
  isFetching,
  onChangeFilter,
  onLoadMembers,
  order,
  page,
  resultsFiltered,
  rowsPerPage,
  siteId,
}) => {
  const { onRowsPerPageChange, onSelectPage } = useDataGrid(
    onLoadMembers,
    {
      companyId,
      filter,
      order,
      page,
      rowsPerPage,
      siteId,
    },
    didInvalid
  );
  const { formatMessage } = useIntl();

  const setValue = (values) => {
    const { name, onChange } = field;
    const event = new EventTarget();
    event.target = {
      name,
      value: values.sort(),
    };
    onChange(event);
  };

  const onSelect = (member) => {
    const { id } = member;
    const { value } = field;
    let values = value || [];
    if (value.indexOf(id) !== -1) {
      values = value.filter((item) => item !== id);
    } else {
      values.push(id);
    }
    setValue(values);
  };

  const isAllSelected = () => {
    const { value } = field;
    const memberIds = data.map((item) => item.id).sort();
    const count = value.filter((item) => memberIds.includes(item)).length;
    return data.length !== 0 && data.length === count;
  };

  const onAllSelect = () => {
    const { value } = field;
    let values = [];
    if (!isAllSelected()) {
      values = [...new Set([...value, ...data.map((item) => item.id)])];
    } else {
      const membersIds = data.map((item) => item.id).sort();
      values = value.filter((item) => !membersIds.includes(item));
    }
    setValue(values);
  };

  const isSelected = (member) => {
    const { id } = member;
    const { value } = field;
    return value.indexOf(id) !== -1;
  };

  return (
    <div>
      <Box sx={styles.header}>
        <Typography sx={styles.title} variant="h6">
          <FormattedMessage {...messages.phonebookMembersTableTitle} />
        </Typography>
        <TextField
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          name="fulltext"
          onChange={onChangeFilter}
          placeholder={formatMessage(messages.phonebookMembersTableSearchPlaceholder)}
          sx={styles.inputSearch}
          type="search"
          value={fulltext}
          variant="outlined"
        />
      </Box>
      <Table size="small" sx={styles.table}>
        <TableHead>
          <TableRow aria-checked={isAllSelected()} hover onClick={() => onAllSelect()} role="checkbox">
            <TableCell padding="checkbox">
              <Checkbox
                checked={isAllSelected()}
                color="primary"
                disabled={data.length === 0}
                inputProps={{ 'aria-label': 'Select all' }}
                onChange={() => onAllSelect()}
              />
            </TableCell>
            <TableCell>
              <FormattedMessage {...messages.phonebookMembersTableColumnsName} />
            </TableCell>
            <TableCell>
              <FormattedMessage {...messages.phonebookMembersTableColumnsDeviceId} />
            </TableCell>
            <TableCell>
              <FormattedMessage {...messages.phonebookMembersTableColumnsType} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!isFetching && data.length === 0 && (
            <TableRow sx={styles.noResults}>
              <TableCell colSpan={4}>
                <FormattedMessage {...messages.phonebookMembersTableNoResults} />
              </TableCell>
            </TableRow>
          )}
          {data.map((item) => (
            <TableRow
              aria-checked={isSelected(item)}
              disabled={!item.active}
              hover
              key={item.id}
              onClick={() => onSelect(item)}
              role="checkbox"
              selected={isSelected(item)}
              sx={{ ...(!item.active && styles.disabled) }}
              tabIndex={-1}
            >
              <TableCell padding="checkbox">
                <Checkbox checked={isSelected(item)} color="primary" />
              </TableCell>
              <TableCell>{item.name}</TableCell>
              <TableCell>{item.sipNumber}</TableCell>
              <TableCell>
                <DeviceTypeLabel type={item.type} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
        <TableFooter
          colSpan={4}
          onRowsPerPageChange={onRowsPerPageChange}
          onSelectPage={onSelectPage}
          page={page}
          resultsFiltered={resultsFiltered}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 15]}
        />
      </Table>
    </div>
  );
};

PhonebookMembersTable.propTypes = {
  companyId: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  didInvalid: PropTypes.bool.isRequired,
  field: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.arrayOf(PropTypes.number),
  }).isRequired,
  filter: PropTypes.shape({}).isRequired,
  fulltext: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onLoadMembers: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  resultsFiltered: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  siteId: PropTypes.number.isRequired,
};

export default PhonebookMembersTable;
