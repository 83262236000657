import Box from '@mui/material/Box';
import MLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import logo from '../../assets/2N_Logo_RGB_Black.svg';
import { PATHS } from '../../config/consts';
import * as localizationSelectors from '../../modules/localization/selectors';
import messages from './messages';

const styles = {
  companyLink: {
    display: {
      md: 'block',
      xs: 'none',
    },
    mr: 6,
    my: 1,
    typography: 'body2',
  },
  headline: {
    color: 'grey.400',
    mt: 2,
  },
  link: {
    '&:last-of-type': {
      mr: 0,
    },
    mr: {
      md: 6,
      sm: 2,
      xs: 1,
    },
    my: 1,
    typography: 'body2',
  },
  logo: {
    display: 'block',
    height: '105px',
    paddingTop: '13.5px',
    width: '110px',
  },
  root: {
    bgcolor: 'common.white',
    borderTop: '1px solid',
    borderTopColor: 'grey.400',
    px: 4,
    py: 6,
    width: '100%',
  },
};

const Footer = () => {
  const yearTo = new Date().getFullYear().toString();
  const currentUserLang = useSelector(localizationSelectors.getCurrentUserLanguage);

  return (
    <Box component="footer" sx={styles.root}>
      <Box alignItems="center" display="flex">
        <Box
          alignSelf="flex-start"
          component="a"
          href={`https://www.2n.com/${currentUserLang}/`}
          sx={styles.logo}
          target="_blank"
        >
          <img alt="2N" src={logo} />
        </Box>
        <Box display="flex" flexDirection={{ sm: 'row', xs: 'column' }} sx={{ pl: 4 }}>
          <MLink
            color="inherit"
            href={`https://www.2n.com/${currentUserLang}/`}
            rel="noreferrer"
            sx={styles.companyLink}
            target="_blank"
            underline="always"
          >
            {'www.2n.com'}
          </MLink>
          <MLink
            color="inherit"
            href="https://faq.2n.cz/pages/viewpage.action?pageId=60261019"
            rel="noreferrer"
            sx={styles.link}
            target="_blank"
            underline="hover"
          >
            {'FAQ'}
          </MLink>
          <MLink color="inherit" component={Link} sx={styles.link} to={PATHS.TERMS_OF_SERVICE} underline="hover">
            <FormattedMessage {...messages.footerTerms} />
          </MLink>
          <MLink color="inherit" component={Link} sx={styles.link} to={PATHS.COOKIE_POLICY} underline="hover">
            <FormattedMessage {...messages.footerCookies} />
          </MLink>
          <MLink color="inherit" component={Link} sx={styles.link} to={PATHS.PRIVACY_NOTICE} underline="hover">
            <FormattedMessage {...messages.footerPrivacy} />
          </MLink>
        </Box>
      </Box>
      <Box sx={styles.headline}>
        <Typography component="span" variant="body2">
          <FormattedMessage {...messages.footerHeadline} values={{ yearTo }} />
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
