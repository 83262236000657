import { defineMessages } from 'react-intl';

export default defineMessages({
  orderListHeader: {
    defaultMessage: 'Your order',
    description: 'Device license order list header',
    id: 'subscription.deviceLicense.twoStep.orderListHeader',
  },
  twoStepButtonCancel: {
    defaultMessage: 'Cancel',
    description: 'Delete order dialog cancel button',
    id: 'subscription.deviceLicense.twoStep.deleteOrderModal.button.cancel',
  },
  twoStepButtonOk: {
    defaultMessage: 'Delete license',
    description: 'Delete order dialog ok button',
    id: 'subscription.deviceLicense.twoStep.deleteOrderModal.button.ok',
  },
  twoStepContent: {
    defaultMessage: 'You will remove the license from your order.',
    description: 'Delete order dialog content',
    id: 'subscription.deviceLicense.twoStep.deleteOrderModal.content',
  },
  twoStepDeleteButtonTooltip: {
    defaultMessage: 'Remove license from order',
    description: 'Order list delete button tooltip',
    id: 'subscription.deviceLicense.twoStepDeleteButtonTooltip',
  },
  twoStepTitle: {
    defaultMessage: 'Delete license',
    description: 'Delete order dialog title',
    id: 'subscription.deviceLicense.twoStep.deleteOrderModal.title',
  },
});
