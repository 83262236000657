import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { table } from '../../config/styles';
import Modal from '../Modal';
import messages from './messages';

const LicenseDetailModal = ({ invoice, onClose, show }) => {
  const getLicenseKeys = (keys) => keys.join(', ');

  const getProducts = (products) => {
    const names = [];
    products.forEach((product) => {
      const license = licenceSelectionTypeMap[product.name];
      if (license) {
        names.push(<FormattedMessage {...license} />);
      } else {
        names.push(product.name);
      }
    });
    return names;
  };

  return (
    <Modal
      actions={
        <Button color="primary" onClick={onClose}>
          <FormattedMessage {...messages.licenseDetailModalClose} />
        </Button>
      }
      fullWidth
      name="licence-detail-modal"
      onClose={onClose}
      open={show}
      title={
        <>
          <FormattedMessage {...messages.licenseDetailModalTitle} /> {invoice.invoiceNumber}
        </>
      }
    >
      <Table size="medium" sx={table}>
        <TableHead>
          <TableRow>
            <TableCell component="th">
              <FormattedMessage {...messages.licenseDetailModalTableSerialNumber} />
            </TableCell>
            <TableCell component="th">
              <FormattedMessage {...messages.licenseDetailModalTableLicenseFeatures} />
            </TableCell>
            <TableCell component="th">
              <FormattedMessage {...messages.licenseDetailModalTableLicenseKey} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(invoice).length !== 0 &&
            invoice.orderResults.map((key) => (
              <TableRow hover key={key.id}>
                <TableCell>{key.serialNumber}</TableCell>
                <TableCell>
                  {getProducts(key.products).map((product) => (
                    <div key={product}>{product}</div>
                  ))}
                </TableCell>
                <TableCell>{getLicenseKeys(key.licenceKeys)}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Modal>
  );
};

LicenseDetailModal.propTypes = {
  invoice: PropTypes.shape({
    invoiceNumber: PropTypes.string,
    orderResults: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        licenceKeys: PropTypes.arrayOf(PropTypes.string),
        products: PropTypes.arrayOf(
          PropTypes.shape({
            code: PropTypes.number,
            name: PropTypes.string,
          })
        ),
        serialNumber: PropTypes.string,
      })
    ),
  }),
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool,
};

LicenseDetailModal.defaultProps = {
  invoice: {},
  show: false,
};

export default LicenseDetailModal;

const licenceSelectionTypeMap = {
  LICENCE_ACCESS_UNIT_LIFT_MODULE: messages.licensesTypeLICENCE_ACCESS_UNIT_LIFT_MODULE,
  LICENCE_ENHANCED_AUDIO: messages.licensesTypeLICENCE_ENHANCED_AUDIO,
  LICENCE_ENHANCED_INTEGRATION: messages.licensesTypeLICENCE_ENHANCED_INTEGRATION,
  LICENCE_ENHANCED_SECURITY: messages.licensesTypeLICENCE_ENHANCED_SECURITY,
  LICENCE_ENHANCED_VIDEO: messages.licensesTypeLICENCE_ENHANCED_VIDEO,
  LICENCE_GOLD: messages.licensesTypeLICENCE_GOLD,
  LICENCE_INFORMCAST: messages.licensesTypeLICENCE_INFORMCAST,
  LICENCE_INTERCOM_LIFT_MODULE: messages.licensesTypeLICENCE_INTERCOM_LIFT_MODULE,
  LICENCE_NFC: messages.licensesTypeLICENCE_NFC,
  LICENCE_NFC_ACCESS_UNIT: messages.licensesTypeLICENCE_NFC_ACCESS_UNIT,
};
