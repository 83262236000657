export const moduleName = 'billing';

export default {
  EDIT_BILLING_AUTOFILL_VAT_NUMBER_PREFIX: `${moduleName}/EDIT_BILLING_AUTOFILL_VAT_NUMBER_PREFIX`,
  EDIT_BILLING_FORM_FAILURE: `${moduleName}/EDIT_BILLING_FORM_FAILURE`,
  EDIT_BILLING_FORM_REQUEST: `${moduleName}/EDIT_BILLING_FORM_REQUEST`,
  EDIT_BILLING_FORM_SUCCESS: `${moduleName}/EDIT_BILLING_FORM_SUCCESS`,
  EDIT_BILLING_HIDE_MODAL: `${moduleName}/EDIT_BILLING_HIDE_MODAL`,
  EDIT_BILLING_SHOW_MODAL: `${moduleName}/EDIT_BILLING_SHOW_MODAL`,
  EDIT_BILLING_SHOW_SAGA_MODAL: `${moduleName}/EDIT_BILLING_SHOW_SAGA_MODAL`,
};
