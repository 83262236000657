import { defineMessages } from 'react-intl';

export default defineMessages({
  AAmodalApartmentDetailButtonLabelMultiple: {
    defaultMessage: 'Different labels',
    id: 'AAmodal.apartmentDetail.buttonLabelMultiple',
  },

  AAmodalApartmentDetailButtonLabelNone: {
    defaultMessage: 'No label on intercom',
    id: 'AAmodal.apartmentDetail.buttonLabelNone',
  },
  AAModalGrantRightsMsgErr: {
    defaultMessage: 'There was an error, please try again',
    description: 'Apartment snackbar - Apartment admin rights granted unsuccessfully',
    id: 'AA.modal.grantRights.msgErr',
  },
  AAModalGrantRightsMsgSucc: {
    defaultMessage: 'Apartment admin rights granted successfully',
    description: 'Apartment snackbar - Apartment admin rights granted successfully',
    id: 'AA.modal.grantRights.msgSucc',
  },
  AAModalRevokeRightsMsgErr: {
    defaultMessage: 'There was an error, please try again',
    description: 'Apartment snackbar - Apartment admin rights revoked unsuccessfully',
    id: 'AA.modal.revokeRights.msgErr',
  },
  AAModalRevokeRightsMsgSucc: {
    defaultMessage: 'Apartment admin rights revoked successfully',
    description: 'Apartment snackbar - Apartment admin rights revoked successfully',
    id: 'AA.modal.revokeRights.msgSucc',
  },
  apartmentSnackbarAddedSuccessfully: {
    defaultMessage: 'Apartment added successfully.',
    description: 'Apartment snackbar - Apartment added successfully',
    id: 'apartmentSnackbarAddedSuccessfully',
  },
  apartmentSnackbarDeletedSuccessfully: {
    defaultMessage: 'Apartment deleted successfully.',
    description: 'Apartment snackbar - Apartment deleted successfully',
    id: 'apartmentSnackbarDeletedSuccessfully',
  },
  apartmentSnackbarEditedSuccessfully: {
    defaultMessage: 'Apartment edited successfully.',
    description: 'Apartment snackbar - Apartment edited successfully',
    id: 'apartmentSnackbarEditedSuccessfully',
  },
  apartmentSnackbarUsersCreatedSuccessfully: {
    defaultMessage: 'Apartment users successfully created.',
    description: 'Apartment snackbar - Apartment users successfully created',
    id: 'apartmentSnackbarUsersCreatedSuccessfully',
  },
  userSnackbarUsersAddedSuccessfully: {
    defaultMessage: 'Users added successfully.',
    description: 'User snackbar - Users added successfully',
    id: 'userSnackbarUsersAddedSuccessfully',
  },
});
