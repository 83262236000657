import { defineMessages } from 'react-intl';

export default defineMessages({
  AAPinModalBody: {
    defaultMessage: `The PIN code allows to open doors through which the apartment is granted access.<p>Here you can generate PIN for people that don’t have My2N account.</p>`,
    id: 'AAmodal.newPIN.text',
  },
  AAPinModalCancel: {
    defaultMessage: 'Cancel',
    id: 'AAmodal.newPIN.cancel',
  },
  AAPinModalHint: {
    defaultMessage: `Assign a name to the PIN to monitor the visitor’s access`,
    id: 'AAmodal.newPIN.nameHelper',
  },
  AAPinModalPinName: {
    defaultMessage: 'PIN Name',
    id: 'AAmodal.newPIN.nameLabel',
  },
  AAPinModalSavePin: {
    defaultMessage: 'SAVE PIN',
    id: 'AAmodal.newPIN.submit',
  },
  AAPinModalTitle: {
    defaultMessage: 'Generate new PIN',
    id: 'AAmodal.newPIN.title',
  },
});
