import { defineMessages } from 'react-intl';

export default defineMessages({
  AAUserDetailAccessTitle: {
    defaultMessage: 'Access settings',
    id: 'AA.userDetail.access.title',
  },
  AAUserDetailTitle: {
    defaultMessage: 'User basic info',
    id: 'AA.userDetail.title',
  },
  AAUserDetailTitleUwL: {
    defaultMessage: 'User basic info',
    id: 'AA.userDetail.title.UwL',
  },
  AAUserDetailTitleUwLPIN: {
    defaultMessage: 'PIN basic info',
    id: 'AA.userDetail.title.UwL.PIN',
  },
  AAUserDetailTitleUwLRFID: {
    defaultMessage: 'RFID key fob basic info',
    id: 'AA.userDetail.title.UwL.RFID',
  },
  AAUserDetailTitleVisitor: {
    defaultMessage: 'Visitor basic info',
    id: 'AA.userDetail.title.visitor',
  },
});
