import { defineMessages } from 'react-intl';

export default defineMessages({
  DataGridDesktopPaginationLabel: {
    defaultMessage: 'Rows per page',
    description: 'DataGrid - desktop pagination label',
    id: 'dataGrid.desktopPaginationLabel',
  },
  DataGridFooterRows: {
    defaultMessage: '{count, plural, =1 {row} other {rows}} selected',
    id: 'dataGrid.footerLabel',
  },
  DataGridMobilePaginationLabel: {
    defaultMessage: 'Rows',
    description: 'DataGrid - mobile pagination label',
    id: 'dataGrid.mobilePaginationLabel',
  },
  DataGridNoRows: {
    defaultMessage: 'No rows',
    description: 'DataGrid - noRows default message',
    id: 'dataGrid.noRows',
  },
});
