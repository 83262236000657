import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useGetLoadedParams } from '../../../hooks/paramsHooks';
import { ApplicationContext } from '../../../modules/commonTypes';
import subscriptionActions from '../../../modules/subscription';

export function useGetAvailableCreditModalData(onClose: () => void) {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { companyId, siteId } = useGetLoadedParams(ApplicationContext.Site);

  function onSubmit() {
    dispatch(subscriptionActions.useAvailableCreditRequest());
    onClose();
  }

  return {
    companyId,
    formatMessage,
    onSubmit,
    siteId,
  };
}
