import Icon from '@mui/material/Icon';
import PropTypes from 'prop-types';
import React from 'react';

const iconStyles = {
  root: {
    backgroundRepeat: 'no-repeat',
    display: 'inline-block',
    fill: 'currentColor',
    height: '24px',
    margin: 'auto',
    minHeight: '24px',
    minWidth: '24px',
    verticalAlign: 'middle',
    width: '24px',
  },
};

const IconMaterial = React.forwardRef(({ name, styles, ...props }, ref) => (
  <Icon {...props} ref={ref} sx={{ ...styles, ...iconStyles.root }}>
    {name}
  </Icon>
));

IconMaterial.propTypes = {
  name: PropTypes.string.isRequired,
  styles: PropTypes.shape({}),
};

IconMaterial.defaultProps = {
  styles: {},
};

export default IconMaterial;
