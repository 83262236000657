import userTypes from '../user/types';
import types from './types';

export const initialState = {
  apartmentsList: {
    data: [],
    didInvalid: false,
    isFetching: false,
  },
  apartmentUsers: {
    addApartmentUsersModal: {
      error: null,
      isFetching: false,
    },
    list: {
      data: [],
      didInvalid: false,
      error: null,
      filter: {
        fulltext: '',
      },
      isFetching: false,
      order: '-role',
      page: 1,
      resultsFiltered: 0,
      resultsTotal: 0,
      rowsPerPage: 10,
    },
  },
  createMultipleUsers: {
    error: false,
    isFetching: false,
  },
  deleteModal: {
    apartmentId: null,
    isFetching: false,
    show: false,
  },
  detail: {
    data: {},
    didInvalid: false,
    isFetching: false,
  },
  devicesList: {
    data: [],
    didInvalid: false,
    filter: {
      fulltext: '',
    },
    isFetching: false,
    order: 'name',
    page: 1,
    resultsFiltered: 0,
    resultsTotal: 0,
    rowsPerPage: 10,
  },
  floorsList: {
    data: [],
    didInvalid: false,
    isFetching: false,
  },
  list: {
    data: [],
    didInvalid: false,
    filter: {
      fulltext: '',
    },
    isFetching: false,
    order: 'floor',
    page: 1,
    resultsFiltered: 0,
    resultsTotal: 0,
    rowsPerPage: 10,
  },
  setAccessGroupsModal: {
    apartmentId: null,
    show: false,
  },
};

const reducers = {
  [types.CREATE_MULTIPLE_APARTMENT_USERS_REQUEST]: (state) => ({
    ...state,
    createMultipleUsers: {
      ...state.createMultipleUsers,
      isFetching: true,
    },
  }),

  [types.CREATE_MULTIPLE_APARTMENT_USERS_SUCCESS]: (state) => ({
    ...state,
    apartmentUsers: {
      ...initialState.apartmentUsers,
      list: {
        ...initialState.apartmentUsers.list,
        didInvalid: true,
      },
    },
    createMultipleUsers: {
      ...state.createMultipleUsers,
      error: false,
      isFetching: false,
    },
  }),

  [types.CREATE_MULTIPLE_APARTMENT_USERS_FAILURE]: (state) => ({
    ...state,
    createMultipleUsers: {
      ...state.createMultipleUsers,
      error: true,
      isFetching: false,
    },
  }),

  [userTypes.ACTIVATE_USER_MY2N_APP_ACCESS_SUCCESS]: (state, { context }) => ({
    ...state,
    apartmentUsers: {
      ...state.apartmentUsers,
      list: {
        ...state.apartmentUsers.list,
        didInvalid: context === 'APARTMENT_USERS_LIST',
      },
    },
  }),

  [userTypes.SAVE_USER_PIN_SUCCESS]: (state, { context }) => ({
    ...state,
    apartmentUsers: {
      ...state.apartmentUsers,
      list: {
        ...state.apartmentUsers.list,
        didInvalid: context === 'APARTMENT_USERS_LIST',
      },
    },
  }),

  [types.GET_SITE_APARTMENTS_REQUEST]: (state) => ({
    ...state,
    list: {
      ...state.list,
      isFetching: true,
    },
  }),

  [types.GET_SITE_APARTMENTS_SUCCESS]: (state, { payload, ...action }) => ({
    ...state,
    list: {
      ...state.list,
      data: payload.results || [],
      didInvalid: false,
      filter: action.filter,
      isFetching: false,
      order: action.order,
      page: action.page,
      resultsFiltered: payload.resultsFiltered || 0,
      resultsTotal: payload.resultsTotal || 0,
      rowsPerPage: action.rowsPerPage,
    },
  }),

  [types.GET_SITE_APARTMENTS_FAILURE]: (state) => ({
    ...state,
    list: initialState.list,
  }),

  [types.ADD_APARTMENT_SUCCESS]: (state) => ({
    ...state,
    list: {
      ...state.list,
      didInvalid: true,
    },
  }),

  [types.ADD_FLOOR_SUCCESS]: (state) => ({
    ...state,
    floorsList: {
      ...state.floorsList,
      didInvalid: true,
    },
  }),

  [types.GET_SITE_FLOORS_REQUEST]: (state) => ({
    ...state,
    floorsList: {
      ...state.floorsList,
      isFetching: true,
    },
  }),

  [types.GET_SITE_FLOORS_SUCCESS]: (state, { payload }) => ({
    ...state,
    floorsList: {
      ...state.floorsList,
      data: payload.results || [],
      didInvalid: false,
      isFetching: false,
    },
  }),

  [types.GET_SITE_FLOORS_FAILURE]: (state) => ({
    ...state,
    floorsList: initialState.floorsList,
  }),

  [types.GET_APARTMENT_DETAIL_REQUEST]: (state) => ({
    ...state,
    detail: {
      ...initialState.detail,
      isFetching: true,
    },
  }),

  [types.GET_APARTMENT_DETAIL_SUCCESS]: (state, { payload }) => ({
    ...state,
    detail: {
      ...initialState.detail,
      data: payload,
    },
  }),

  [types.GET_APARTMENT_DETAIL_FAILURE]: (state) => ({
    ...state,
    detail: initialState.detail,
  }),

  [types.DELETE_APARTMENT_REQUEST]: (state) => ({
    ...state,
    deleteModal: {
      ...state.deleteModal,
      isFetching: true,
    },
  }),

  [types.SET_APARTMENT_ACCESS_GROUP_SHOW_MODAL]: (state, { apartmentId }) => ({
    ...state,
    setAccessGroupsModal: {
      ...state.setAccessGroupsModal,
      apartmentId,
      show: true,
    },
  }),

  [types.SET_APARTMENT_ACCESS_GROUP_HIDE_MODAL]: (state) => ({
    ...state,
    setAccessGroupsModal: {
      ...state.setAccessGroupsModal,
      apartmentId: null,
      show: false,
    },
  }),

  [types.DELETE_APARTMENT_SUCCESS]: (state) => ({
    ...state,
    deleteModal: {
      ...initialState.deleteModal,
    },
    detail: initialState.detail,
    list: {
      ...state.list,
      didInvalid: true,
    },
  }),

  [types.DELETE_APARTMENT_FAILURE]: (state) => ({
    ...state,
    deleteModal: {
      ...state.deleteModal,
      isFetching: false,
    },
  }),

  [types.GET_APARTMENT_DEVICES_REQUEST]: (state) => ({
    ...state,
    devicesList: {
      ...state.devicesList,
      isFetching: true,
    },
  }),

  [types.GET_APARTMENT_DEVICES_SUCCESS]: (state, { payload, ...action }) => ({
    ...state,
    devicesList: {
      ...state.devicesList,
      data: payload.results || [],
      didInvalid: false,
      filter: action.filter,
      isFetching: false,
      order: action.order,
      page: action.page,
      resultsFiltered: payload.resultsFiltered || 0,
      resultsTotal: payload.resultsTotal || 0,
      rowsPerPage: action.rowsPerPage,
    },
  }),

  [types.DELETE_APARTMENT_DEVICE_SUCCESS]: (state) => ({
    ...state,
    devicesList: {
      ...state.devicesList,
      didInvalid: true,
    },
  }),

  [types.GET_APARTMENT_DEVICES_FAILURE]: (state) => ({
    ...state,
    devicesList: initialState.devicesList,
  }),

  [types.GET_APARTMENT_USERS_REQUEST]: (state) => ({
    ...state,
    apartmentUsers: {
      ...state.apartmentUsers,
      list: {
        ...initialState.apartmentUsers.list,
        isFetching: true,
      },
    },
  }),

  [types.GET_APARTMENT_USERS_FAILURE]: (state, { error }) => ({
    ...state,
    apartmentUsers: {
      ...state.apartmentUsers,
      list: {
        ...initialState.apartmentUsers.list,
        error,
      },
    },
  }),

  [types.GET_APARTMENT_USERS_SUCCESS]: (state, { payload, ...action }) => ({
    ...state,
    apartmentUsers: {
      ...state.apartmentUsers,
      list: {
        ...state.apartmentUsers.list,
        data: payload.data,
        didInvalid: false,
        error: null,
        filter: action.filter,
        isFetching: false,
        order: action.order,
        page: action.page,
        resultsFiltered: payload.resultsFiltered,
        resultsTotal: payload.resultsTotal,
        rowsPerPage: action.rowsPerPage,
      },
    },
  }),

  [types.CREATE_ADD_APARTMENT_USERS_REQUEST]: (state) => ({
    ...state,
    apartmentUsers: {
      ...state.apartmentUsers,
      addApartmentUsersModal: {
        ...initialState.apartmentUsers.addApartmentUsersModal,
        isFetching: true,
      },
    },
  }),

  [types.CREATE_ADD_APARTMENT_USERS_SUCCESS]: (state) => ({
    ...state,
    apartmentUsers: {
      ...initialState.apartmentUsers,
      list: {
        ...initialState.apartmentUsers.list,
        didInvalid: true,
      },
    },
  }),

  [types.CREATE_ADD_APARTMENT_USERS_FAILURE]: (state, { error }) => ({
    ...state,
    apartmentUsers: {
      ...initialState.apartmentUsers,
      addApartmentUsersModal: {
        ...initialState.apartmentUsers.addApartmentUsersModal,
        error,
      },
      list: {
        ...initialState.apartmentUsers.list,
        didInvalid: true,
      },
    },
  }),

  [types.DELETE_APARTMENT_USER_SUCCESS]: (state) => ({
    ...state,
    apartmentUsers: {
      ...initialState.apartmentUsers,
      list: {
        ...initialState.apartmentUsers.list,
        didInvalid: true,
      },
    },
  }),

  [types.GRANT_APARTMENT_ADMIN_FAILURE]: (state) => ({
    ...state,
    apartmentUsers: {
      ...initialState.apartmentUsers,
      list: {
        ...initialState.apartmentUsers.list,
        didInvalid: true,
      },
    },
  }),

  [types.GRANT_APARTMENT_ADMIN_SUCCESS]: (state) => ({
    ...state,
    apartmentUsers: {
      ...initialState.apartmentUsers,
      list: {
        ...initialState.apartmentUsers.list,
        didInvalid: true,
      },
    },
  }),

  [types.REVOKE_APARTMENT_ADMIN_FAILURE]: (state) => ({
    ...state,
    apartmentUsers: {
      ...initialState.apartmentUsers,
      list: {
        ...initialState.apartmentUsers.list,
        didInvalid: true,
      },
    },
  }),

  [types.REVOKE_APARTMENT_ADMIN_SUCCESS]: (state) => ({
    ...state,
    apartmentUsers: {
      ...initialState.apartmentUsers,
      list: {
        ...initialState.apartmentUsers.list,
        didInvalid: true,
      },
    },
  }),

  [types.EDIT_APARTMENT_SUCCESS]: (state, { payload }) => ({
    ...state,
    detail: {
      ...initialState.detail,
      data: payload,
    },
    list: {
      ...state.list,
      didInvalid: true,
    },
  }),

  [types.GET_APARTMENTS_LIST_REQUEST]: (state) => ({
    ...state,
    apartmentsList: {
      ...state.apartmentsList,
      isFetching: true,
    },
  }),

  [types.GET_APARTMENTS_LIST_SUCCESS]: (state, { payload }) => ({
    ...state,
    apartmentsList: {
      ...state.apartmentsList,
      data: payload,
      isFetching: false,
    },
  }),

  [types.GET_APARTMENTS_LIST_FAILURE]: (state) => ({
    ...state,
    apartmentsList: initialState.apartmentsList,
  }),

  [types.DELETE_APARTMENT_SHOW_MODAL]: (state, { apartmentId }) => ({
    ...state,
    deleteModal: {
      apartmentId,
      show: true,
    },
  }),

  [types.DELETE_APARTMENT_HIDE_MODAL]: (state) => ({
    ...state,
    deleteModal: {
      ...state.deleteModal,
      apartmentId: null,
      show: false,
    },
  }),

  [userTypes.CREATE_USER_RFID_SUCCESS]: (state) => ({
    ...state,
    apartmentUsers: {
      ...initialState.apartmentUsers,
      list: {
        ...initialState.apartmentUsers.list,
        didInvalid: true,
      },
    },
  }),
};

const reducer = (state = initialState, action = {}) => {
  if (reducers[action.type]) {
    return reducers[action.type](state, action);
  }
  return state;
};

export default reducer;
