import { IDevice } from '../../../../../modules/devices/store/deviceStore';
import { IButtonRenderData, ICallSettingOption } from '../hooks/useGetStateData';

export function handleChangeListItem(
  buttonRenderData: IButtonRenderData,
  currentStateData: IButtonRenderData[],
  stateSetter: (newList: IButtonRenderData[]) => void,
  dataUpdate?: ICallSettingOption[]
): void {
  const clonedStateData: IButtonRenderData[] = [];

  currentStateData.forEach((currentButtonData) => {
    if (currentButtonData.index === buttonRenderData.index) {
      const clonedButtonData = currentButtonData;

      if (dataUpdate === undefined || dataUpdate.length === 0) {
        clonedButtonData.selectedOptions = [];
      } else {
        if (dataUpdate.length === 1) {
          clonedButtonData.selectedOptions = dataUpdate;
        } else {
          if (dataUpdate[0].optionKind === 'Devices') {
            clonedButtonData.selectedOptions = dataUpdate;
          } else {
            const option = dataUpdate.find(
              (option) =>
                option.optionKey !== clonedButtonData.selectedOptions?.[0].optionKey &&
                option.optionKind === clonedButtonData.selectedOptions?.[0].optionKind
            );

            clonedButtonData.selectedOptions = option ? [option] : undefined;
          }
        }
      }

      clonedStateData.push(clonedButtonData);
    } else {
      clonedStateData.push(currentButtonData);
    }
  });

  stateSetter(clonedStateData);
}

export function handleChangeButtonName(
  buttonRenderData: IButtonRenderData,
  newName: string,
  currentStateData: IButtonRenderData[],
  stateSetter: (newList: IButtonRenderData[]) => void
): void {
  const clonedStateData: IButtonRenderData[] = [];

  currentStateData.forEach((currentButtonData) => {
    if (currentButtonData.index === buttonRenderData.index) {
      const clonedButtonData = currentButtonData;
      clonedButtonData.buttonName = newName;
      clonedStateData.push(clonedButtonData);
    } else {
      clonedStateData.push(currentButtonData);
    }
  });

  stateSetter(clonedStateData);
}

export function isOptionDisabled(actualOption: ICallSettingOption, selectedOptions?: ICallSettingOption[]): boolean {
  if (selectedOptions === undefined || selectedOptions.length === 0) {
    return false;
  }

  if (selectedOptions[0].optionKind === 'Devices' && actualOption.optionKind === 'Apartments') {
    return true;
  }

  if (selectedOptions[0].optionKind === 'Apartments' && actualOption.optionKind === 'Devices') {
    return true;
  }

  return false;
}

export function getIsChipWarning(device: IDevice) {
  return device?.services?.MOBILE_VIDEO?.hasLicence === false && device?.services?.LOCAL_CALLS?.active === false;
}
