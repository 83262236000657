import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import React, { createRef, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import OutlinedTextField from '../HookFormFields/TextField';
import ErrorHelperText from './ErrorHelperText';
import messages from './messages';

interface ICardIdentifierInput {
  cardId: string;
  error: string;
  isConnecting: boolean;
  isDriverInitialized: boolean;
  isInitError: boolean;
  isReading: boolean;
  isTesting: boolean;
}

const CardIdentifierInput = ({
  cardId,
  error,
  isConnecting,
  isDriverInitialized,
  isInitError,
  isReading,
  isTesting,
}: ICardIdentifierInput) => {
  const { formatMessage } = useIntl();
  const inputRef: React.RefObject<HTMLInputElement> = createRef();
  const { clearErrors, control, formState, getValues, setValue } = useFormContext();
  const { errors, isValid } = formState;
  const errorMessage = errors?.card?.message;

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);

  useEffect(() => {
    if (cardId) {
      setValue('card', cardId, { shouldValidate: true });
    }
  }, [cardId]);

  useEffect(() => {
    if (isReading || isTesting || isConnecting) {
      clearErrors();
      setValue('card', '');
    }
  }, [isReading, isTesting, isConnecting]);

  const isError = !!error && !getValues('card');
  const isInvalid = !isValid && !!getValues('card');

  const getHelperText = () => {
    if (isTesting || isConnecting) {
      return <FormattedMessage {...messages.cardIdentifierInputHelperTextDriverTesting} />;
    }

    if (isReading) {
      return (
        <Box color="primary.main" component="span" fontWeight="bold">
          <FormattedMessage {...messages.cardIdentifierInputErrorReading} />
        </Box>
      );
    }

    if (isValid && cardId) {
      return (
        <Box color="success.main" component="span">
          <FormattedMessage {...messages.cardIdentifierInputErrorValidId} />
        </Box>
      );
    }

    if ((isError || isInvalid) && !isInitError) {
      return <ErrorHelperText error={error} errorMessage={errorMessage} validationError={isInvalid} />;
    }

    if (isInitError || (!isDriverInitialized && isValid)) {
      return <FormattedMessage {...messages.cardIdentifierInputHelperTextDefault} />;
    }

    return <FormattedMessage {...messages.cardIdentifierInputHelperTextDriverAvailable} />;
  };

  const getEndAdornment = () => {
    if (isReading) {
      return (
        <Box height="auto">
          <CircularProgress size={24} />
        </Box>
      );
    }

    if (isValid && cardId) {
      return (
        <Box color="success.main">
          <CheckCircleIcon />
        </Box>
      );
    }

    if (isError && !isInitError) {
      return (
        <Box color="error.main">
          <CancelIcon />
        </Box>
      );
    }

    return null;
  };

  return (
    <OutlinedTextField
      color="primary"
      control={control}
      disabled={isReading}
      error={(isError || isInvalid) && !isInitError}
      fullWidth
      helperText={getHelperText()}
      InputLabelProps={{ shrink: true }}
      inputProps={{
        autofocus: true,
      }}
      InputProps={{
        endAdornment: getEndAdornment(),
      }}
      inputRef={inputRef}
      label={<FormattedMessage {...messages.cardIdentifierInputLabel} />}
      name="card"
      placeholder={error && !isInitError ? formatMessage(messages.cardIdentifierInputHelperTextErrorPlaceholder) : ''}
      sx={{
        '& .MuiFormHelperText-root': {
          minHeight: 46.4,
        },
      }}
    />
  );
};

export default CardIdentifierInput;
