import { defineMessages } from 'react-intl';

export default defineMessages({
  verifyDeviceModalRequired: {
    defaultMessage: 'Value is mandatory',
    description: 'Verify Device Modal - required value',
    id: 'verifyDeviceModal.required',
  },
  verifyDeviceModalSecurityCodeNoValid: {
    defaultMessage: 'Please enter My2N Security Code in valid format.',
    description: 'Verify Device Modal 2N - security code no valid',
    id: 'verifyDeviceModal.securityCode.noValid',
  },
  verifyDeviceModalSerialNumberNoValid: {
    defaultMessage: 'Please enter serial number in valid format.',
    description: 'Verify Device Modal 2N - serial number no valid',
    id: 'verifyDeviceModal.serialNumber.noValid',
  },
  verifyDeviceModalTitle: {
    defaultMessage: "Verify a 2N Device','verifyDeviceModal.title",
    description: 'Verify a 2N Device',
    id: 'verifyDeviceModal.title',
  },
  verifyDeviceModalTooLong: {
    defaultMessage: 'Value is too long',
    description: 'Verify Device Modal - value is too long',
    id: 'verifyDeviceModal.tooLong',
  },
});
