import { Add } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';
import { TestID } from './testIds';

export function getDataGridFooterActions({
  isGuestAccessFFEnabled,
  onAddNewPin,
  onAddNewUser,
  onAddNewVisitor,
}: {
  onAddNewUser: () => void;
  onAddNewPin: () => void;
  onAddNewVisitor: () => void;
  isGuestAccessFFEnabled: boolean;
}) {
  const buttons = [
    <Button
      data-testid={TestID.CreateUser}
      key="table-action-1"
      color="primary"
      onClick={onAddNewUser}
      variant="outlined"
      startIcon={<Add />}
    >
      <Box sx={{ display: { md: 'block', sm: 'none', xs: 'none' } }}>
        <FormattedMessage {...messages.usersInApartmentsCreateUser} />
      </Box>
      <Box sx={{ display: { md: 'none', sm: 'block', xs: 'block' } }}>
        <FormattedMessage {...messages.AAUserListCreateUserXs} />
      </Box>
    </Button>,
  ];

  if (isGuestAccessFFEnabled) {
    buttons.push(
      <Button
        data-testid={TestID.AddVisitor}
        key="table-action-2"
        color="primary"
        onClick={onAddNewVisitor}
        variant="outlined"
        startIcon={<Add />}
      >
        <Box sx={{ display: { md: 'block', sm: 'none', xs: 'none' } }}>
          <FormattedMessage {...messages.AAUserListCreateGuest} />
        </Box>
        <Box sx={{ display: { md: 'none', sm: 'block', xs: 'block' } }}>
          <FormattedMessage {...messages.AAUserListCreateGuestXs} />
        </Box>
      </Button>
    );
  }

  buttons.push(
    <Button
      data-testid={TestID.GeneratePin}
      key="table-action-3"
      color="primary"
      onClick={onAddNewPin}
      variant="outlined"
      startIcon={<Add />}
    >
      <Box sx={{ display: { md: 'block', sm: 'none', xs: 'none' } }}>
        <FormattedMessage {...messages.usersInApartmentsGeneratePin} />
      </Box>
      <Box sx={{ display: { md: 'none', sm: 'block', xs: 'block' } }}>
        <FormattedMessage {...messages.AAUserListGeneratePinXs} />
      </Box>
    </Button>
  );

  return buttons;
}
