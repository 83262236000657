import { IRfid } from '../../api/userApiTS';
import { IPin } from '../commonTypes';
import { IDevice } from '../devices/store/deviceStore';
import { IError } from '../reduxTypes';
import { AccessGroupName, IAggregatedApartmentUsers } from './data';

export interface IApartmentAdminStore {
  createUWLWithPinModal: ICreateUWLWithPinModal;
  usersInApartments: IUsersInApartments;
  userDetail: IUserDetail;
}

export interface IUserDetail {
  accessSettings: {
    apartmentGroups: {
      isFetching: boolean;
      data: AccessGroupName[] | undefined;
      error?: IError;
    };
    userGroups: {
      isFetching: boolean;
      data: AccessGroupName[] | undefined;
      error?: IError;
    };
    pin: {
      isFetching: boolean;
      error?: IError;
      data: IPin | undefined;
    };
    rfid: {
      isFetching: boolean;
      error?: IError;
      data: IRfid | undefined;
    };
  };
  devicesWithinApartment: {
    data: IDevice[] | undefined;
    error?: IError;
    isFetching: boolean;
    didInvalid: boolean;
  };
}

export interface IUsersInApartments {
  data: IAggregatedApartmentUsers[] | undefined;
  error?: IError;
  isFetching: boolean;
  didInvalid: boolean;
}

export interface ICreateUWLWithPinModal {
  pin?: { pin: string };
  error?: IError;
  isFetching: boolean;
}

export function getInitialApartmentAdminStore(): IApartmentAdminStore {
  return {
    createUWLWithPinModal: {
      error: undefined,
      isFetching: false,
      pin: undefined,
    },
    userDetail: {
      accessSettings: {
        apartmentGroups: {
          data: undefined,
          error: undefined,
          isFetching: false,
        },
        pin: {
          data: undefined,
          error: undefined,
          isFetching: false,
        },
        rfid: {
          data: undefined,
          error: undefined,
          isFetching: false,
        },
        userGroups: {
          data: undefined,
          error: undefined,
          isFetching: false,
        },
      },
      devicesWithinApartment: {
        data: undefined,
        didInvalid: false,
        error: undefined,
        isFetching: false,
      },
    },
    usersInApartments: {
      data: undefined,
      didInvalid: false,
      error: undefined,
      isFetching: false,
    },
  };
}
