import { defineMessages } from 'react-intl';

export default defineMessages({
  addApartmentUsersModalAddUsersButton: {
    defaultMessage: 'Add users',
    description: 'Apartment users add users modal - add user button',
    id: 'addApartmentUsersModalAddUsersButton',
  },
  addApartmentUsersModalCancelButton: {
    defaultMessage: 'Cancel',
    description: 'Apartment users add users modal - cancel button',
    id: 'addApartmentUsersModalCancelButton',
  },
  addApartmentUsersModalDescription: {
    defaultMessage: 'Apartments group users and devices together for easier administration.',
    description: 'Apartment users add users modal - description',
    id: 'addApartmentUsersModalDescription',
  },
  addApartmentUsersModalHeader: {
    defaultMessage: 'Add users to apartment',
    description: 'Apartment users add users modal - modal header',
    id: 'addApartmentUsersModalHeader',
  },
});
