import camelcase from 'camelcase';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { setAuthToken } from '../../modules/auth/actions';
import { addSnackbarError } from '../../modules/notifications/actions';
import messages from './messages';

interface ISearchObject {
  [key: string]: string;
}

const searchToObject = (search: string) => {
  const params = search.substring(1).split('&');
  const object: ISearchObject = {};
  for (const i in params) {
    if (params[i] === '') {
      continue;
    }
    const row = params[i].split('=');
    object[camelcase(decodeURIComponent(row[0]))] = decodeURIComponent(row[1]);
  }
  return object;
};

export function useSetAccessToken() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  useEffect(() => {
    const { key = '', preferUserLogin, registrationToken = '' } = searchToObject(window.location.search);
    if (key && registrationToken.toLowerCase() === 'ok') {
      dispatch(setAuthToken({ accessToken: key.trim(), preferUserLogin: preferUserLogin !== undefined }));
    } else if (registrationToken.toLowerCase() === 'fail') {
      dispatch(addSnackbarError(formatMessage(messages.authLoginFailed)));
    }
  }, [window.location.search]);
}
