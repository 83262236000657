import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Paper } from '../../../components';
import AccessGroupAddDeviceModal from '../../../components/AccessGroupAddDeviceModal';
import AccessGroupDeviceRemoveModal from '../../../components/AccessGroupDeviceRemoveModal';
import RacActivateModal from '../../../components/RacActivateModal';
import RacDeactivateModal from '../../../components/RacDeactivateModal';
import TableControlPanel from '../../../components/TableControlPanel';
import { dataGridProps, getDataGridSelector } from '../../../helpers/dataGrid';
import { useUrlParams } from '../../../helpers/urlParams';
import actions, { NAME as ACCESS_SETTINGS_MODULE, selectors } from '../../../modules/accessSettings';
import DataGrid from './DataGrid';
import messages from './messages';

const AccessGroupDevicesDataGrid = ({
  data,
  devicesDataGrid,
  isFetching,
  isInvalid,
  loadAccessGroupDevices,
  onSetDeviceLogging,
}) => {
  const { companyId, groupId, siteId } = useUrlParams();
  const [modalOpen, setModalOpen] = useState(null);
  const [modalDevice, setModalDevice] = useState('');
  const modalType = {
    ADD: 'ADD',
    RAC_ACTIVATE: 'RAC_ACTIVATE',
    RAC_DEACTIVATE: 'RAC_DEACTIVATE',
    REMOVE: 'REMOVE',
  };

  const handleOpenModal = (type, device) => {
    setModalDevice(device);
    setModalOpen(type);
  };

  const handleCloseModal = () => setModalOpen(null);

  return (
    <>
      <Paper tabContent tabTable>
        <TableControlPanel
          addButtonAction={() => setModalOpen(modalType.ADD, null)}
          addButtonLabel={<FormattedMessage {...messages.accessGroupDevicesDataGridAddDevice} />}
          tabToolbar
        />
        <DataGrid
          companyId={companyId}
          data={data}
          devicesDataGrid={devicesDataGrid}
          groupId={groupId}
          isFetching={isFetching}
          isInvalid={isInvalid}
          loadAccessGroupDevices={loadAccessGroupDevices}
          onLogActivation={(device) => onSetDeviceLogging(device?.deviceId, { active: !device?.accessLog })}
          onRacActivation={(device) =>
            handleOpenModal(device?.accessControl ? modalType.RAC_DEACTIVATE : modalType.RAC_ACTIVATE, device)
          }
          onRemove={(device) => handleOpenModal(modalType.REMOVE, device)}
          siteId={siteId}
        />
        <AccessGroupDeviceRemoveModal
          deviceId={modalDevice.deviceId}
          onClose={handleCloseModal}
          open={modalOpen === modalType.REMOVE}
        />
        <RacActivateModal
          deviceId={modalDevice.deviceId}
          onClose={handleCloseModal}
          open={modalOpen === modalType.RAC_ACTIVATE}
        />
        <RacDeactivateModal
          deviceId={modalDevice.deviceId}
          groups={modalDevice.groups}
          onClose={handleCloseModal}
          open={modalOpen === modalType.RAC_DEACTIVATE}
        />
        <AccessGroupAddDeviceModal groupId={groupId} onClose={handleCloseModal} open={modalOpen === modalType.ADD} />
      </Paper>
    </>
  );
};

AccessGroupDevicesDataGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  devicesDataGrid: dataGridProps.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isInvalid: PropTypes.bool.isRequired,
  loadAccessGroupDevices: PropTypes.func.isRequired,
  onSetDeviceLogging: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  data: selectors.getAccessGroupDevices(state),
  devicesDataGrid: getDataGridSelector(state, ACCESS_SETTINGS_MODULE, 'groupDetail.devices'),
  isFetching: selectors.isAccessGroupDevicesFetching(state),
  isInvalid: selectors.isAccessGroupDevicesInvalid(state),
});

const mapDispatchToProps = {
  loadAccessGroupDevices: actions.getAccessGroupDevices,
  onSetDeviceLogging: actions.setLogAccessGroupDevice,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccessGroupDevicesDataGrid);
