import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import PersonAddIcon from '@mui/icons-material/PersonAddAlt1';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { UserIcon } from '../index';

function MultiAutocompleteOption({ newItemId, newItemWithoutLoginId, option, optionLabelKey, renderProps }) {
  const isNewItem = option.id === newItemId;
  const isNewItemWithoutLogin = option.id === newItemWithoutLoginId;
  let optionApartmentData = option.apartments?.length
    ? `(${option.apartments[0].number}${option.apartments[0].name ? ` – ${option.apartments[0].name}` : ''})`
    : '';
  if (option.apartments?.length > 1) {
    optionApartmentData = `${optionApartmentData} + ${option.apartments.length - 1}`;
  }

  return (
    <Box component="span" key={option.id} {...renderProps}>
      {isNewItem && (
        <Stack direction="row" spacing={2} sx={{ color: 'primary.main' }}>
          <PersonAddIcon />
          <Typography variant="body1">{option?.[optionLabelKey] || ''}</Typography>
        </Stack>
      )}
      {isNewItemWithoutLogin && (
        <Stack direction="row" spacing={2} sx={{ color: 'primary.main' }}>
          <PersonAddAltIcon />
          <Typography variant="body1">{option?.[optionLabelKey] || ''}</Typography>
        </Stack>
      )}
      {!isNewItem && !isNewItemWithoutLogin && (
        <Stack direction="row" sx={{ alignItems: 'center' }}>
          <UserIcon userRole={option.role} sx={{ color: 'secondary.main' }} />
          <Typography variant="body1" sx={{ ml: 2 }}>
            {option.label || ''}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary', ml: 1 }}>
            {optionApartmentData}
          </Typography>
        </Stack>
      )}
    </Box>
  );
}

MultiAutocompleteOption.propTypes = {
  newItemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  newItemWithoutLoginId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  option: PropTypes.shape({
    apartments: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        number: PropTypes.string,
      })
    ),
    companies: PropTypes.arrayOf(PropTypes.shape({})),
    email: PropTypes.string,
    firstName: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    label: PropTypes.string,
    lastName: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
  optionLabelKey: PropTypes.string.isRequired,
  renderProps: PropTypes.shape({}).isRequired,
};

export default MultiAutocompleteOption;
