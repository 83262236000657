import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FIRMWARE_STATUS, SERVICE } from '../../../../config/devices';
import { isActiveService } from '../../../../modules/devices/helpers/helpers';
import ConfigurationModeField from '../ConfigurationModeField';
import ConnectionModeField from '../ConnectionModeField';
import DeviceInfoTitle from '../DeviceInfoTitle';
import IpAddressField from '../IpAddressField';
import LocalCallsIdentifierField from '../LocalCallsIdentifierField';
import UnavailableSettings from '../UnavailableSettings';
import {
  hasCallsIdentifierField,
  hasConfigurationModeField,
  hasConnectionModeField,
  hasIpAddressField,
} from './helpers';

const DeviceDetailNetworkSettings = ({ device, onChange, values }) => {
  const hasConfigurationMode = () => hasConfigurationModeField(device.type) && device?.services?.[SERVICE.ACS];

  const hasIPAddress = () => hasIpAddressField(device.type) && device?.services?.[SERVICE.ACS]?.ip;

  const disabled = useMemo(
    () =>
      device?.services?.[SERVICE.ACS]?.firmwareStatus === FIRMWARE_STATUS.UNSUPPORTED ||
      device?.services?.[SERVICE.ACS]?.firmwareStatus === FIRMWARE_STATUS.DEPRECATED,
    [device?.services?.[SERVICE.ACS]?.firmwareStatus]
  );

  return (
    <Grid container direction="column">
      <Grid item>
        <DeviceInfoTitle type="network" />
      </Grid>
      {hasConfigurationMode() && (
        <Grid item>
          <ConfigurationModeField disabled={disabled} onChange={onChange} value={values.configurationMode} />
        </Grid>
      )}
      {hasConnectionModeField(device.type) && (
        <Grid item>
          <ConnectionModeField
            deviceType={device.type}
            disabled={disabled}
            isLocalModeInit={isActiveService(device, SERVICE.LOCAL_CALLS)}
            onChange={onChange}
            value={values.connectionMode}
          />
        </Grid>
      )}
      {hasIPAddress() && (
        <Grid item>
          <IpAddressField disabled={disabled} value={device.services[SERVICE.ACS].ip} />
        </Grid>
      )}
      {hasCallsIdentifierField(device.type) && (
        <Grid item>
          <LocalCallsIdentifierField
            deviceType={device.type}
            disabled={disabled}
            isLocalCallsActive={isActiveService(device, SERVICE.LOCAL_CALLS)}
            onChange={onChange}
            value={values.localCallsIdentifier}
          />
        </Grid>
      )}
      {!hasConfigurationModeField(device.type) &&
        !hasConnectionModeField(device.type) &&
        !hasIpAddressField(device.type) &&
        !hasCallsIdentifierField(device.type) && (
          <Grid item>
            <UnavailableSettings />
          </Grid>
        )}
    </Grid>
  );
};

DeviceDetailNetworkSettings.propTypes = {
  device: PropTypes.shape({
    services: PropTypes.shape({
      [SERVICE.ACS]: PropTypes.shape({
        active: PropTypes.bool,
        firmwareStatus: PropTypes.string,
        ip: PropTypes.string,
      }),
    }),
    type: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  values: PropTypes.shape({
    configurationMode: PropTypes.string,
    connectionMode: PropTypes.string,
    localCallsIdentifier: PropTypes.string,
  }).isRequired,
};

export default DeviceDetailNetworkSettings;
