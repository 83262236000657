export const moduleName = 'devices';

export default {
  ADD_DEVICE_CLEAR: `${moduleName}/ADD_DEVICE_CLEAR`,
  ADD_DEVICE_FEATURES: `${moduleName}/ADD_DEVICE_FEATURES`,
  ADD_DEVICE_FEATURES_FAILED: `${moduleName}/ADD_DEVICE_FEATURES_FAILED`,
  ADD_DEVICE_FEATURES_SUCCESS: `${moduleName}/ADD_DEVICE_FEATURES_SUCCESS`,
  ADD_DEVICE_REQUEST: `${moduleName}/ADD_DEVICE_REQUEST`,
  ADD_DEVICE_STOP_WAIT: `${moduleName}/ADD_DEVICE_STOP_WAIT`,
  ADD_DEVICE_SUCCESS: `${moduleName}/ADD_DEVICE_SUCCESS`,
  ADD_DEVICE_WAIT: `${moduleName}/ADD_DEVICE_WAIT`,
  APPROVE_DEVICE_FAILURE: `${moduleName}/APPROVE_DEVICE_FAILURE`,
  APPROVE_DEVICE_REQUEST: `${moduleName}/APPROVE_DEVICE_REQUEST`,
  APPROVE_DEVICE_SUCCESS: `${moduleName}/APPROVE_DEVICE_SUCCESS`,
  CHANGE_DEVICE_DETAIL_FROM_SOCKET: `${moduleName}/CHANGE_DEVICE_DETAIL_FROM_SOCKET`,
  CHANGE_DEVICE_FROM_SOCKET: `${moduleName}/CHANGE_DEVICE_FROM_SOCKET`,
  CHANGE_DEVICE_LIST_FROM_SOCKET: `${moduleName}/CHANGE_DEVICE_LIST_FROM_SOCKET`,
  CHANGE_DEVICE_SERVICE_STATUS_FAILURE: `${moduleName}/CHANGE_DEVICE_SERVICE_STATUS_FAILURE`,
  CHANGE_DEVICE_SERVICE_STATUS_REQUEST: `${moduleName}/CHANGE_DEVICE_SERVICE_STATUS_REQUEST`,
  CHANGE_DEVICE_SERVICE_STATUS_SUCCESS: `${moduleName}/CHANGE_DEVICE_SERVICE_STATUS_SUCCESS`,
  COUNT_DEVICE_FAILURE: `${moduleName}/COUNT_DEVICE_FAILURE`,
  COUNT_DEVICE_REQUEST: `${moduleName}/COUNT_DEVICE_REQUEST`,
  COUNT_DEVICE_SUCCESS: `${moduleName}/COUNT_DEVICE_SUCCESS`,
  DELETE_DEVICE_FAILURE: `${moduleName}/DELETE_DEVICE_FAILURE`,
  DELETE_DEVICE_MODAL_HIDE: `${moduleName}/DELETE_DEVICE_MODAL_HIDE`,
  DELETE_DEVICE_MODAL_SHOW: `${moduleName}/DELETE_DEVICE_MODAL_SHOW`,
  DELETE_DEVICE_REQUEST: `${moduleName}/DELETE_DEVICE_REQUEST`,
  DELETE_DEVICE_SUCCESS: `${moduleName}/DELETE_DEVICE_SUCCESS`,
  EDIT_DEVICE_FAILURE: `${moduleName}/EDIT_DEVICE_FAILURE`,
  EDIT_DEVICE_REQUEST: `${moduleName}/EDIT_DEVICE_REQUEST`,
  EDIT_DEVICE_SUCCESS: `${moduleName}/EDIT_DEVICE_SUCCESS`,
  GET_CALL_SETTINGS_DEVICES_CHIPS_FAILURE: `${moduleName}/GET_CALL_SETTINGS_DEVICES_CHIPS_FAILURE`,
  GET_CALL_SETTINGS_DEVICES_CHIPS_REQUEST: `${moduleName}/GET_CALL_SETTINGS_DEVICES_CHIPS_REQUEST`,
  GET_CALL_SETTINGS_DEVICES_CHIPS_SUCCESS: `${moduleName}/GET_CALL_SETTINGS_DEVICES_CHIPS_SUCCESS`,
  GET_CALL_SETTINGS_DEVICES_FAILURE: `${moduleName}/GET_CALL_SETTINGS_DEVICES_FAILURE`,
  GET_CALL_SETTINGS_DEVICES_REQUEST: `${moduleName}/GET_CALL_SETTINGS_DEVICES_REQUEST`,
  GET_CALL_SETTINGS_DEVICES_SUCCESS: `${moduleName}/GET_CALL_SETTINGS_DEVICES_SUCCESS`,
  GET_COMPANY_ADMIN_DEVICES_FAILURE: `${moduleName}/GET_COMPANY_ADMIN_DEVICES_FAILURE`,
  GET_COMPANY_ADMIN_DEVICES_REQUEST: `${moduleName}/GET_COMPANY_ADMIN_DEVICES_REQUEST`,
  GET_COMPANY_ADMIN_DEVICES_SUCCESS: `${moduleName}/GET_COMPANY_ADMIN_DEVICES_SUCCESS`,
  GET_DEFAULT_PASSWORD_DEVICES_FAILURE: `${moduleName}/GET_DEFAULT_PASSWORD_DEVICES_FAILURE`,
  GET_DEFAULT_PASSWORD_DEVICES_REQUEST: `${moduleName}/GET_DEFAULT_PASSWORD_DEVICES_REQUEST`,
  GET_DEFAULT_PASSWORD_DEVICES_SUCCESS: `${moduleName}/GET_DEFAULT_PASSWORD_DEVICES_SUCCESS`,
  GET_DEVICE_DETAIL_FAILURE: `${moduleName}/GET_DEVICE_DETAIL_FAILURE`,
  GET_DEVICE_DETAIL_REQUEST: `${moduleName}/GET_DEVICE_DETAIL_REQUEST`,
  GET_DEVICE_DETAIL_SUCCESS: `${moduleName}/GET_DEVICE_DETAIL_SUCCESS`,
  GET_DEVICES_FAILURE: `${moduleName}/GET_DEVICES_FAILURE`,
  GET_DEVICES_REQUEST: `${moduleName}/GET_DEVICES_REQUEST`,
  GET_DEVICES_SUCCESS: `${moduleName}/GET_DEVICES_SUCCESS`,
  GET_FIRMWARE_VERSION_FAILURE: `${moduleName}/GET_FIRMWARE_VERSION_FAILURE`,
  GET_FIRMWARE_VERSION_REQUEST: `${moduleName}/GET_FIRMWARE_VERSION_REQUEST`,
  GET_FIRMWARE_VERSION_SUCCESS: `${moduleName}/GET_FIRMWARE_VERSION_SUCCESS`,
  GET_NOT_VERIFIED_DEVICES_FAILURE: `${moduleName}/GET_NOT_VERIFIED_DEVICES_FAILURE`,
  GET_NOT_VERIFIED_DEVICES_REQUEST: `${moduleName}/GET_NOT_VERIFIED_DEVICES_REQUEST`,
  GET_NOT_VERIFIED_DEVICES_SUCCESS: `${moduleName}/GET_NOT_VERIFIED_DEVICES_SUCCESS`,
  GET_USER_PROFILE_DEVICES_FAILURE: `${moduleName}/GET_USER_PROFILE_DEVICES_FAILURE`,
  GET_USER_PROFILE_DEVICES_REQUEST: `${moduleName}/GET_USER_PROFILE_DEVICES_REQUEST`,
  GET_USER_PROFILE_DEVICES_SUCCESS: `${moduleName}/GET_USER_PROFILE_DEVICES_SUCCESS`,
  GET_WAITING_DEVICES_FAILURE: `${moduleName}/GET_WAITING_DEVICES_FAILURE`,
  GET_WAITING_DEVICES_RELOAD: `${moduleName}/GET_WAITING_DEVICES_RELOAD`,
  GET_WAITING_DEVICES_REQUEST: `${moduleName}/GET_WAITING_DEVICES_REQUEST`,
  GET_WAITING_DEVICES_SUCCESS: `${moduleName}/GET_WAITING_DEVICES_SUCCESS`,
  LOAD_USERS_FAILURE: `${moduleName}/LOAD_USERS_FAILURE`,
  LOAD_USERS_REQUEST: `${moduleName}/LOAD_USERS_REQUEST`,
  LOAD_USERS_SUCCESS: `${moduleName}/LOAD_USERS_SUCCESS`,
  MOBILE_VIDEO_RENEW_FAILURE: `${moduleName}/MOBILE_VIDEO_RENEW_FAILURE`,
  MOBILE_VIDEO_RENEW_REQUEST: `${moduleName}/MOBILE_VIDEO_RENEW_REQUEST`,
  MOBILE_VIDEO_RENEW_SUCCESS: `${moduleName}/MOBILE_VIDEO_RENEW_SUCCESS`,
  RC_ACTIVATE_FAILURE: `${moduleName}/RC_ACTIVATE_FAILURE`,
  RC_ACTIVATE_REQUEST: `${moduleName}/RC_ACTIVATE_REQUEST`,
  RC_ACTIVATE_SUCCESS: `${moduleName}/RC_ACTIVATE_SUCCESS`,
  RC_CREATE_FAILURE: `${moduleName}/RC_CREATE_FAILURE`,
  RC_CREATE_REQUEST: `${moduleName}/RC_CREATE_REQUEST`,
  RC_CREATE_SUCCESS: `${moduleName}/RC_CREATE_SUCCESS`,
  RC_HIDE_MODAL: `${moduleName}/RC_HIDE_MODAL`,
  RC_IFRAME_DEVICE_CHANGE: `${moduleName}/RC_IFRAME_DEVICE_CHANGE`,
  RC_RENEW_FAILURE: `${moduleName}/RC_RENEW_FAILURE`,
  RC_RENEW_REQUEST: `${moduleName}/RC_RENEW_REQUEST`,
  RC_RENEW_SUCCESS: `${moduleName}/RC_RENEW_SUCCESS`,
  RC_SHOW_MODAL: `${moduleName}/RC_SHOW_MODAL`,
  RC_WEBSOCKET_KNOCK_FAILURE: `${moduleName}/RC_WEBSOCKET_KNOCK_FAILURE`,
  RC_WEBSOCKET_KNOCK_REQUEST: `${moduleName}/RC_WEBSOCKET_KNOCK_REQUEST`,
  RC_WEBSOCKET_KNOCK_SUCCESS: `${moduleName}/RC_WEBSOCKET_KNOCK_SUCCESS`,
  REJECT_DEVICE_FAILURE: `${moduleName}/REJECT_DEVICE_FAILURE`,
  REJECT_DEVICE_REQUEST: `${moduleName}/REJECT_DEVICE_REQUEST`,
  REJECT_DEVICE_SUCCESS: `${moduleName}/REJECT_DEVICE_SUCCESS`,
  SAVE_CALL_SETTINGS_MULTIPLE_FAILURE: `${moduleName}/SAVE_CALL_SETTINGS_MULTIPLE_FAILURE`,
  SAVE_CALL_SETTINGS_MULTIPLE_REQUEST: `${moduleName}/SAVE_CALL_SETTINGS_MULTIPLE_REQUEST`,
  SAVE_CALL_SETTINGS_MULTIPLE_SUCCESS: `${moduleName}/SAVE_CALL_SETTINGS_MULTIPLE_SUCCESS`,
  SAVE_DEVICE_BUTTON_CONFIGURATION_FAILURE: `${moduleName}/SAVE_DEVICE_BUTTON_CONFIGURATION_FAILURE`,
  SAVE_DEVICE_BUTTON_CONFIGURATION_REQUEST: `${moduleName}/SAVE_DEVICE_BUTTON_CONFIGURATION_REQUEST`,
  SAVE_DEVICE_BUTTON_CONFIGURATION_SUCCESS: `${moduleName}/SAVE_DEVICE_BUTTON_CONFIGURATION_SUCCESS`,
  SAVE_DEVICE_CALL_SETTINGS_REQUEST: `${moduleName}/SAVE_DEVICE_CALL_SETTINGS_REQUEST`,
  SAVE_DEVICE_CONTACT_LIST_FAILURE: `${moduleName}/SAVE_DEVICE_CONTACT_LIST_FAILURE`,
  SAVE_DEVICE_CONTACT_LIST_REQUEST: `${moduleName}/SAVE_DEVICE_CONTACT_LIST_REQUEST`,
  SAVE_DEVICE_CONTACT_LIST_SUCCESS: `${moduleName}/SAVE_DEVICE_CONTACT_LIST_SUCCESS`,
  SAVE_DEVICE_FEATURE_FACE_TRACKING_REQUEST: `${moduleName}/SAVE_DEVICE_FEATURE_FACE_TRACKING_REQUEST`,
  SAVE_DEVICE_SWITCH_CODES_FAILURE: `${moduleName}/SAVE_DEVICE_SWITCH_CODES_FAILURE`,
  SAVE_DEVICE_SWITCH_CODES_REQUEST: `${moduleName}/SAVE_DEVICE_SWITCH_CODES_REQUEST`,
  SAVE_DEVICE_SWITCH_CODES_SUCCESS: `${moduleName}/SAVE_DEVICE_SWITCH_CODES_SUCCESS`,
  SAVE_MULTIPLE_DEVICES_CALL_SETTINGS_REQUEST: `${moduleName}/SAVE_MULTIPLE_DEVICES_CALL_SETTINGS_REQUEST`,
  SET_DEVICE_EDITING: `${moduleName}/ SET_DEVICE_EDITING`,
  UPDATE_DEVICE_PHONEBOOK_FAILURE: `${moduleName}/UPDATE_DEVICE_PHONEBOOK_FAILURE`,
  UPDATE_DEVICE_PHONEBOOK_REQUEST: `${moduleName}/UPDATE_DEVICE_PHONEBOOK_REQUEST`,
  UPDATE_DEVICE_PHONEBOOK_SUCCESS: `${moduleName}/UPDATE_DEVICE_PHONEBOOK_SUCCESS`,
  UPDATE_DEVICE_SERVICE_FAILURE: `${moduleName}/UPDATE_DEVICE_SERVICE_FAILURE`,
  UPDATE_DEVICE_SERVICE_REQUEST: `${moduleName}/UPDATE_DEVICE_SERVICE_REQUEST`,
  UPDATE_DEVICE_SERVICE_SUCCESS: `${moduleName}/UPDATE_DEVICE_SERVICE_SUCCESS`,
  UPDATE_DTMF_CODE_FAILURE: `${moduleName}/UPDATE_DTMF_CODE_FAILURE`,
  UPDATE_DTMF_CODE_REQUEST: `${moduleName}/UPDATE_DTMF_CODE_REQUEST`,
  UPDATE_DTMF_CODE_SUCCESS: `${moduleName}/UPDATE_DTMF_CODE_SUCCESS`,
  UPDATE_FIRMWARE_FAILURE: `${moduleName}/UPDATE_FIRMWARE_FAILURE`,
  UPDATE_FIRMWARE_REQUEST: `${moduleName}/UPDATE_FIRMWARE_REQUEST`,
  UPDATE_FIRMWARE_SUCCESS: `${moduleName}/UPDATE_FIRMWARE_SUCCESS`,
  VERIFY_DEVICE_REQUEST: `${moduleName}/VERIFY_DEVICE_REQUEST`,
  VERIFY_DEVICE_STOP_WAIT: `${moduleName}/VERIFY_DEVICE_STOP_WAIT`,
  VERIFY_DEVICE_SUCCESS: `${moduleName}/VERIFY_DEVICE_SUCCESS`,
  VERIFY_DEVICE_WAIT: `${moduleName}/VERIFY_DEVICE_WAIT`,
  VERIFY_HIDE_MODAL: `${moduleName}/VERIFY_HIDE_MODAL`,
  VERIFY_SHOW_MODAL: `${moduleName}/VERIFY_SHOW_MODAL`,
};
