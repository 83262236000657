import Cancel from '@mui/icons-material/Cancel';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import IconBox from '../IconBox';
import messages from './messages';

const Newsletter = ({ newsletters }) =>
  newsletters ? (
    <Box>
      <IconBox icon={<CheckCircle color="success" />}>
        <FormattedMessage {...messages.UserInfoBoxReceivingNewslettersEnabled} />
      </IconBox>
    </Box>
  ) : (
    <Box>
      <IconBox icon={<Cancel color="error" />}>
        <FormattedMessage {...messages.UserInfoBoxReceivingNewslettersDisabled} />
      </IconBox>
    </Box>
  );

Newsletter.propTypes = {
  newsletters: PropTypes.bool.isRequired,
};

export default Newsletter;
