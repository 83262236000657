import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import mvServiceSvg from '../../assets/mvideo.svg';
import acServiceSvg from '../../assets/service-ac.svg';
import rcServiceSvg from '../../assets/service-rc.svg';
import { SERVICE } from '../../config/devices';
import { helpers } from '../../modules/devices';
import ItemService from './ItemService';

const IconServices = ({ item }) => (
  <Box display="flex">
    <ItemService
      active={helpers.isActiveService(item, SERVICE.MOBILE_VIDEO) || helpers.isActiveService(item, SERVICE.LOCAL_CALLS)}
      image={mvServiceSvg}
      service={SERVICE.MOBILE_VIDEO}
    />
    <ItemService
      active={helpers.isActiveService(item, SERVICE.ACCESS_CONTROL)}
      image={acServiceSvg}
      service={SERVICE.ACCESS_CONTROL}
    />
    <ItemService
      active={helpers.isActiveService(item, SERVICE.REMOTE_CONFIGURATION)}
      image={rcServiceSvg}
      service={SERVICE.REMOTE_CONFIGURATION}
    />
  </Box>
);

const serviceProps = PropTypes.shape({
  active: PropTypes.bool,
});

IconServices.propTypes = {
  item: PropTypes.shape({
    services: PropTypes.shape({
      [SERVICE.LOCAL_CALLS]: serviceProps,
      [SERVICE.MOBILE_VIDEO]: serviceProps,
      [SERVICE.ACCESS_CONTROL]: serviceProps,
      [SERVICE.REMOTE_CONFIGURATION]: serviceProps,
    }),
  }).isRequired,
};

export default IconServices;
