import { Dialog } from '@2n/design-system';
import Box from '@mui/material/Box';
import React from 'react';
import { Color, IDSModalBase } from '../designSystemUnexported';

export interface IProgressModal extends IDSModalBase {
  buttonColor: Color;
}

export function ProgressModal(data: IProgressModal) {
  return (
    <Dialog
      closeOnlyByButton
      footerOptions={{
        primaryButton:
          data.primaryButtonText !== undefined
            ? {
                action: data.onClose,
                color: data.buttonColor,
                text: data.primaryButtonText,
                variant: 'text',
              }
            : undefined,
      }}
      hideCloseButton
      onClose={() => data.onClose(false)}
      open={!!data.open}
      title={data.title}
    >
      <Box data-testid={data.testId}>{data.children}</Box>
    </Dialog>
  );
}

export default ProgressModal;
