import { useSelector } from 'react-redux';
import * as selectors from '../../../modules/accessSettings/selectors';
import * as siteSelectors from '../../../modules/sites/selectors';

export function useGetApartmentFormData() {
  const { isLoading, isSiteMdu, optionsData } = {
    isLoading: useSelector(selectors.isAddAccessGroupApartmentsFetching),
    isSiteMdu: useSelector(siteSelectors.isSiteMdu),
    optionsData: useSelector(selectors.getAccessControlApartmentsFiltered),
  };

  return {
    isLoading,
    isSiteMdu,
    optionsData,
  };
}
