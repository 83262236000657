import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import React from 'react';
import { useIntl } from 'react-intl';
import SectionTemplate from '../SectionTemplate';
import messages from './messages';

function Steps() {
  const { formatMessage } = useIntl();
  const steps = [
    formatMessage(messages.siteUsersImportIntroStepStepsStep1),
    formatMessage(messages.siteUsersImportIntroStepStepsStep2),
    formatMessage(messages.siteUsersImportIntroStepStepsStep3),
    formatMessage(messages.siteUsersImportIntroStepStepsStep4),
    formatMessage(messages.siteUsersImportIntroStepStepsStep5),
  ];

  return (
    <SectionTemplate title={formatMessage(messages.siteUsersImportIntroStepStepsTitle)}>
      <Stepper connector={null} orientation="vertical" sx={{ '.MuiStepLabel-vertical': { py: 0.5 } }}>
        {steps.map((label) => (
          <Step active completed={false} key={label}>
            <StepLabel sx={{ '.MuiStepLabel-label': { fontWeight: 'regular', typography: 'body1' } }}>
              {label}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </SectionTemplate>
  );
}

export default Steps;
