import CancelIcon from '@mui/icons-material/Cancel';
import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import flags from '../../../../../config/featureFlags';
import { isFeatureEnabled } from '../../../../../helpers/common';
import { useAppContext } from '../../../../../pages/App/AppContextProvider';
import { CallSettingOptionIcon } from '../CallSettingOptionIcon';
import { CallSettingOptionLabel } from '../CallSettingOptionLabel';
import { isOptionDisabled } from '../helpers/helpers';
import { ICallSettingOption } from '../hooks/useGetStateData';
import { getChipColor, getPlaceholder } from './helpers';
import messages from './messages';

export interface IAutocompleteChipsData {
  maxLength: number;
  options: ICallSettingOption[];
  selected?: ICallSettingOption[];
  onClear: () => void;
  onChange: (dataUpdate: ICallSettingOption[]) => void;
}

const AutocompleteChips = (chipsData: IAutocompleteChipsData) => {
  const { globalFeatures } = useAppContext();
  const isApartmentInCallSettingsFFEnabled = isFeatureEnabled(flags.APARTMENTS_IN_CALL_SETTINGS, globalFeatures);

  const onChangeHandler = (_event: React.SyntheticEvent, value: ICallSettingOption[]) => {
    let updatedValue: ICallSettingOption[] = [];
    if (chipsData.selected !== undefined && chipsData.selected.length < value.length) {
      value.forEach((newSelectedOption) => {
        const clonedOption = newSelectedOption;
        if (
          chipsData.selected!.findIndex(
            (oldOption) =>
              oldOption.optionKey === newSelectedOption.optionKey &&
              oldOption.optionKind === newSelectedOption.optionKind
          ) < 0
        ) {
          clonedOption.hasUpdated = true;
        }
        updatedValue.push(clonedOption);
      });
    } else {
      updatedValue = value;
    }
    chipsData.onChange(updatedValue);
  };
  const onClearHandler = () => chipsData.onClear();
  const isDisabled = () =>
    chipsData.maxLength && chipsData.selected && chipsData.selected.length === chipsData.maxLength;
  const { formatMessage } = useIntl();
  const getOptionGroupName = (name: string) => {
    if (`autocompleteGroup${name}` in messages) {
      return formatMessage(messages[`autocompleteGroup${name}` as keyof typeof messages]);
    }

    return name;
  };

  return (
    <Box py={1}>
      <Grid container>
        <Grid item xs={10}>
          <Autocomplete<ICallSettingOption, true, true, false>
            blurOnSelect
            disableClearable
            filterSelectedOptions={false}
            forcePopupIcon={false}
            getOptionDisabled={(option: ICallSettingOption) => isOptionDisabled(option, chipsData.selected)}
            getOptionLabel={(option: ICallSettingOption) => option.optionName || option.optionKey.toString()}
            groupBy={(option) => getOptionGroupName(option.optionKind)}
            isOptionEqualToValue={(option: ICallSettingOption, value: ICallSettingOption) =>
              option.optionKey === value.optionKey && option.optionKind === value.optionKind
            }
            multiple
            onChange={onChangeHandler}
            options={chipsData.options}
            renderInput={(params) => (
              <TextField
                {...params}
                inputProps={{
                  ...params.inputProps,
                  disabled: isDisabled(),
                }}
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                }}
                placeholder={getPlaceholder(
                  chipsData.maxLength,
                  formatMessage,
                  isApartmentInCallSettingsFFEnabled,
                  chipsData.selected
                )}
                variant="standard"
              />
            )}
            renderOption={(props: React.HTMLAttributes<HTMLLIElement>, option: ICallSettingOption) =>
              CallSettingOptionLabel({
                numberColor: 'primary.dark',
                option,
                props,
              })
            }
            renderTags={(value, getTagProps) =>
              value.map((option: ICallSettingOption, index) => (
                <Chip
                  {...getTagProps({ index })}
                  color={getChipColor(option)}
                  deleteIcon={<CancelIcon />}
                  icon={
                    <CallSettingOptionIcon
                      deviceAsIcon={option.optionIcon.deviceAsIcon}
                      isNew={option.hasUpdated}
                      stringAsIcon={option.optionIcon.stringAsIcon}
                    />
                  }
                  key={option.optionKey}
                  label={<Typography variant="body2">{option.optionName}</Typography>}
                  sx={{
                    ...(!option.optionName && {
                      '& .MuiChip-label': {
                        px: '6px !important',
                      },
                    }),
                  }}
                />
              ))
            }
            sx={isDisabled() ? { '& input': { display: 'none' } } : {}}
            value={chipsData.selected ?? []}
          />
        </Grid>
        {chipsData.selected && chipsData.selected.length > 0 && (
          <Grid alignItems="center" container item xs={2}>
            <Grid item>
              <Button color="primary" onClick={onClearHandler} startIcon={<CloseIcon />} sx={{ ml: 1 }}>
                <FormattedMessage {...messages.clearButton} />
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default AutocompleteChips;
