import types from './types';

export const initialState = {
  messages: [],
};

const reducers = {
  [types.ADD_SNACKBAR]: (state, { message }) => ({
    ...state,
    messages: [...state.messages, { ...message }],
  }),

  [types.REMOVE_SNACKBAR]: (state, { key }) => ({
    ...state,
    messages: state.messages.filter((message) => message.key !== key),
  }),
};

const reducer = (state = initialState, action = {}) => {
  if (reducers[action.type]) {
    return reducers[action.type](state, action);
  }
  return state;
};

export default reducer;
