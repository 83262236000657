import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import * as Api from '../../api/siteApi';
import { getSiteUsersList } from '../../api/userApiTS';
import { PATHS } from '../../config/consts';
import { SERVICE } from '../../config/devices';
import { replaceURLParams } from '../../routes/helpers/pathParamsReplacer';
import { selectors as companySelectors } from '../company';
import { errorHelper, handleErrorLocation, responseValidateStatusOrThrow } from '../helpers';
import { types as infraTypes } from '../infrastructure/types';
import { types as localizationTypes } from '../localization';
import { addSnackbarSuccess } from '../notifications/actions';
import actions, { helpers, selectors, types } from './index';
import messages from './messages';

export function* getSitesList(action) {
  const { companyId, context, filter, order, page, params, rowsPerPage } = action;
  try {
    const response = yield call(Api.getSites, companyId, params);
    yield responseValidateStatusOrThrow(response);
    const { results, resultsFiltered, resultsTotal } = response.data;
    const sites = helpers.setSites(results);
    yield put({
      context,
      filter,
      order,
      page,
      payload: {
        data: sites,
        resultsFiltered,
        resultsTotal,
      },
      rowsPerPage,
      type: types.GET_SITES_SUCCESS,
    });
  } catch (error) {
    yield put({
      context,
      error: error.message ? error.message : error,
      type: types.GET_SITES_FAILURE,
    });
  }
}

export function* loadSite({ companyId, siteId }) {
  try {
    const response = yield call(Api.getSite, companyId, siteId);
    yield responseValidateStatusOrThrow(response);
    yield put({ payload: response.data, type: types.LOAD_SITE_SUCCESS });
    yield put({ payload: response.data?.locale, type: localizationTypes.GET_CURRENT_SITE_LANGUAGE_SUCCESS });
    yield put(actions.setLastVisitedSite(siteId));
  } catch (error) {
    yield put(errorHelper(types.LOAD_SITE_FAILURE, error));
    if (handleErrorLocation(error?.status)) {
      yield put({
        payload: {
          locationSubRoutesCheck: ['/sites'],
        },
        type: infraTypes.SET_REDIRECT_DATA,
      });
    }
  }
}

export function* deleteSite({ site }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const response = yield call(Api.deleteSite, companyId, site.id);
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.DELETE_SITE_SUCCESS });
    yield put(addSnackbarSuccess(messages.siteSnackbarDeletedSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.DELETE_SITE_FAILURE, error));
  }
}

export function* addSite({ values }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const response = yield call(Api.addSite, companyId, values);
    yield responseValidateStatusOrThrow(response);
    yield put({ payload: response.data, type: types.ADD_SITE_FORM_SUCCESS });
    yield put(addSnackbarSuccess(messages.siteSnackbarAddedSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.ADD_SITE_FORM_FAILURE, error));
  }
}

export function* editSite({ values }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const currentSite = yield select(({ sites }) => sites.editModal.site);
    const response = yield call(Api.editSite, companyId, currentSite.id, values);
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.EDIT_SITE_FORM_SUCCESS });
    yield put(addSnackbarSuccess(messages.siteSnackbarEditedSuccessfully));
  } catch (error) {
    yield put(errorHelper(types.EDIT_SITE_FORM_FAILURE, error));
  }
}

export function* verifyNameSite({ name }) {
  if (!name || name.length === 0) {
    yield put({ type: types.VERIFY_NAME_SITE_EXISTS });
  } else {
    try {
      const companyId = yield select(companySelectors.getCompanyId);
      const response = yield call(Api.verifySite, companyId, name);
      yield responseValidateStatusOrThrow(response);
      yield put({ type: types.VERIFY_NAME_SITE_EXISTS });
    } catch (error) {
      yield put({ type: types.VERIFY_NAME_SITE_FREE });
    }
  }
}

export function* getSitesUsersNames() {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(selectors.getSiteId);
    const response = yield call(
      getSiteUsersList,
      { companyId, siteId },
      {
        limit: 1000,
      }
    );
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({ payload: data.users, type: types.GET_SITES_USERS_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.GET_SITES_USERS_FAILURE, error));
  }
}

export function* redirectToSiteDashboard() {
  const companyId = yield select(companySelectors.getCompanyId);
  const siteId = yield select(selectors.getSiteId);

  yield put({
    payload: {
      data: {
        showSelectSiteTypeModal: true,
      },
      to: replaceURLParams({ params: { companyId, siteId }, url: PATHS.SITE_DASHBOARD }),
    },
    type: infraTypes.SET_REDIRECT_DATA,
  });
}

export function* getSiteDashboard({ companyId, siteId }) {
  try {
    const response = yield call(Api.getSiteDashboard, companyId, siteId);
    yield responseValidateStatusOrThrow(response);
    yield put({ payload: response.data, type: types.GET_SITE_DASHBOARD_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.GET_SITE_DASHBOARD_FAILURE, error));
  }
}

export function* getCurrentSiteLicenseModel() {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(selectors.getSiteId);

    if (siteId) {
      const response = yield call(Api.getSite, companyId, siteId);
      yield responseValidateStatusOrThrow(response);
      const { data } = response;
      yield put({
        payload: data?.services?.[SERVICE.MOBILE_VIDEO]?.licenceModel,
        type: types.GET_CURRENT_SITE_LICENSE_MODEL_SUCCESS,
      });
    }
  } catch (error) {
    yield put(errorHelper(types.GET_CURRENT_SITE_LICENSE_MODEL_FAILURE, error));
    if (handleErrorLocation(error?.status)) {
      yield put({
        payload: {
          locationSubRoutesCheck: ['/sites'],
        },
        type: infraTypes.SET_REDIRECT_DATA,
      });
    }
  }
}

export function* setSiteTypeRequest({ site, siteType }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const response = yield call(Api.editSite, companyId, site.id, {
      name: site.name,
      type: siteType,
    });
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.SET_SITE_TYPE_SUBMIT });
  } catch (error) {
    yield put(errorHelper(types.SET_SITE_TYPE_FAILURE, error));
  }
}

export function* setLastVisitedSite({ siteId }) {
  try {
    const response = yield call(Api.viewSite, siteId);
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.SET_LAST_VISITED_SITE_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.SET_LAST_VISITED_SITE_FAILURE, error));
  }
}

export default function* sitesSagas() {
  yield all([
    // classic actions
    takeEvery(types.ADD_SITE_FORM_REQUEST, addSite),
    takeLatest(types.DELETE_SITE_REQUEST, deleteSite),
    takeEvery(types.EDIT_SITE_FORM_REQUEST, editSite),
    takeEvery(types.GET_CURRENT_SITE_LICENSE_MODEL_REQUEST, getCurrentSiteLicenseModel),
    takeEvery(types.GET_SITES_RECENT_REQUEST, getSitesList),
    takeLatest(types.SET_LAST_VISITED_SITE_REQUEST, setLastVisitedSite),
    takeEvery(types.GET_SITE_DASHBOARD_REQUEST, getSiteDashboard),
    takeEvery(types.LOAD_SITE_REQUEST, loadSite),
    takeLatest(types.GET_SITES_REQUEST, getSitesList),
    takeLatest(types.GET_SITES_USERS_REQUEST, getSitesUsersNames),
    takeLatest(types.SET_SITE_TYPE_REQUEST, setSiteTypeRequest),
    takeLatest(types.VERIFY_NAME_SITE_REQUEST, verifyNameSite),

    // redirects
    takeEvery(types.ADD_SITE_FORM_SUCCESS, redirectToSiteDashboard),
  ]);
}
