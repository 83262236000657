import types, { CONTEXT } from './types';

export const initialUsersList = {
  data: [],
  didInvalid: false,
  error: null,
  filter: { fulltext: '' },
  isFetching: false,
  order: 'lastName',
  page: 1,
  resultsFiltered: 0,
  resultsTotal: 0,
  rowsPerPage: 10,
};

export const initialState = {
  [CONTEXT.SITE_USERS]: initialUsersList,
  [CONTEXT.COMPANY_ADMINS]: initialUsersList,
  data: {},
  deleteModal: {
    context: null,
    show: false,
    user: {},
  },
  deleteUser: {
    isFetching: false,
  },
  didInvalid: false,
  error: null,
  importUsers: {
    currentImport: {
      data: null,
      isFetching: false,
    },
    data: [],
  },
  isFetching: false,
  my2NAppAccessActivation: {
    error: null,
    isFetching: false,
  },
  my2NAppAccessStatus: {
    data: null,
    didInvalid: false,
    error: null,
    isFetching: false,
  },
  userAccessGroupsModal: {
    error: null,
    isFetching: false,
    show: false,
    userId: null,
  },
  userApartmentModal: {
    error: null,
    isFetching: false,
    user: null,
  },
  userDetail: {
    data: {},
    devices: {
      data: [],
      didInvalid: false,
      error: null,
      filter: {
        fulltext: '',
      },
      isFetching: false,
      order: 'name',
      page: 1,
      resultsFiltered: 0,
      resultsTotal: 0,
      rowsPerPage: 10,
    },
    didInvalid: false,
    isFetching: false,
  },
  userModal: {
    context: null,
    data: {
      apartments: [],
      email: '',
      firstName: '',
      lastName: '',
      newsletters: false,
    },
    isEdit: false,
  },
  userPin: {
    data: null,
    didInvalid: false,
    error: null,
    generatePin: {
      data: null,
      error: null,
      isFetching: false,
    },
    isFetching: false,
  },
  userRfidCard: {
    data: null,
    didInvalid: false,
    error: null,
    isFetching: false,
  },
  userRole: {
    error: null,
    isFetching: false,
  },
};

const reducers = {
  [types.SET_USER_DETAIL]: (state, { context, payload }) => ({
    ...state,
    userDetail: {
      ...initialState.userDetail,
      data: payload,
      isFetching: false,
    },
    userModal: {
      ...initialState.userModal,
      context,
      data: payload,
      isEdit: true,
    },
  }),

  [types.SET_IDENTITY]: (state, { payload }) => ({
    ...state,
    data: payload,
  }),

  [types.GET_USER_PIN_REQUEST]: (state) => ({
    ...state,
    userPin: {
      ...state.userPin,
      data: null,
      isFetching: true,
    },
  }),

  [types.GET_USER_PIN_SUCCESS]: (state, { payload }) => ({
    ...state,
    userPin: {
      ...state.userPin,
      data: payload,
      didInvalid: false,
      isFetching: false,
    },
  }),

  [types.GET_USER_PIN_FAILURE]: (state, { error }) => ({
    ...state,
    userPin: {
      ...state.userPin,
      didInvalid: false,
      error,
      isFetching: false,
    },
  }),

  [types.GENERATE_USER_PIN_REQUEST]: (state) => ({
    ...state,
    userPin: {
      ...state.userPin,
      generatePin: {
        ...state.userPin.generatePin,
        data: null,
        error: null,
        isFetching: true,
      },
    },
  }),

  [types.GENERATE_USER_PIN_REQUEST]: (state) => ({
    ...state,
    userPin: {
      ...state.userPin,
      generatePin: {
        ...state.userPin.generatePin,
        data: null,
        error: null,
        isFetching: true,
      },
    },
  }),

  [types.GENERATE_USER_PIN_SUCCESS]: (state, { payload }) => ({
    ...state,
    userPin: {
      ...state.userPin,
      generatePin: {
        ...state.userPin.generatePin,
        data: payload,
        error: null,
        isFetching: false,
      },
    },
  }),

  [types.GENERATE_USER_PIN_FAILURE]: (state, { error }) => ({
    ...state,
    userPin: {
      ...state.userPin,
      generatePin: {
        ...state.userPin.generatePin,
        data: null,
        error,
        isFetching: false,
      },
    },
  }),

  [types.SAVE_USER_PIN_SUCCESS]: (state, { context }) => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      didInvalid: context === 'SITE_USER_DETAIL',
    },
    userPin: {
      ...state.userPin,
      didInvalid: context === 'SITE_USER_DETAIL',
    },
    usersSites: {
      ...state.usersSites,
      didInvalid: context === 'SITE_USERS_LIST',
    },
  }),

  [types.REVOKE_USER_PIN_SUCCESS]: (state) => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      didInvalid: true,
    },
    userPin: {
      ...state.userPin,
      didInvalid: true,
    },
  }),

  [types.SET_APARTMENT_TO_USER_SHOW_MODAL]: (state, { user }) => ({
    ...state,
    userApartmentModal: {
      ...state.userApartmentModal,
      user,
    },
  }),

  [types.SET_APARTMENT_TO_USER_REQUEST]: (state) => ({
    ...state,
    userApartmentModal: {
      ...state.userApartmentModal,
      isFetching: true,
    },
  }),

  [types.SET_APARTMENT_TO_USER_SUCCESS]: (state) => ({
    ...state,
    [CONTEXT.SITE_USERS]: {
      ...state[CONTEXT.SITE_USERS],
      didInvalid: true,
    },
    userApartmentModal: {
      ...state.userApartmentModal,
      isFetching: false,
    },
  }),

  [types.SET_APARTMENT_TO_USER_FAILURE]: (state, { error }) => ({
    ...state,
    userApartmentModal: {
      ...initialState.userApartmentModal,
      error,
    },
  }),

  [types.GET_USER_RFID_CARD_REQUEST]: (state) => ({
    ...state,
    userRfidCard: {
      ...state.userRfidCard,
      data: null,
      isFetching: true,
    },
  }),

  [types.GET_USER_RFID_CARD_SUCCESS]: (state, { payload }) => ({
    ...state,
    userRfidCard: {
      ...state.userRfidCard,
      data: payload,
      didInvalid: false,
      isFetching: false,
    },
  }),

  [types.GET_USER_RFID_CARD_FAILURE]: (state, { error }) => ({
    ...state,
    userRfidCard: {
      ...state.userRfidCard,
      didInvalid: false,
      error,
      isFetching: false,
    },
  }),

  [types.CREATE_USER_RFID_REQUEST]: (state, { context }) => ({
    ...state,
    userRfidCard: {
      ...state.userRfidCard,
      isFetching: true,
    },
  }),

  [types.CREATE_USER_RFID_FAILURE]: (state, { error }) => ({
    ...state,
    userRfidCard: {
      ...state.userRfidCard,
      error,
      isFetching: false,
    },
  }),

  [types.CREATE_USER_RFID_SUCCESS]: (state, { context }) => ({
    ...state,
    userRfidCard: {
      ...state.userRfidCard,
      didInvalid: context === 'SITE_USER_DETAIL',
      error: null,
      isFetching: false,
    },
    usersSites: {
      ...state.usersSites,
      didInvalid: context === 'SITE_USERS_LIST',
    },
  }),

  [types.REMOVE_USER_RFID_CARD_SUCCESS]: (state) => ({
    ...state,
    userRfidCard: {
      ...state.userRfidCard,
      didInvalid: true,
    },
  }),

  [types.DETAIL_USER_REQUEST]: (state) => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      isFetching: true,
    },
  }),

  [types.DETAIL_USER_SUCCESS]: (state, { context, payload }) => ({
    ...state,
    userDetail: {
      ...initialState.userDetail,
      data: payload,
      isFetching: false,
    },
    userModal: {
      ...initialState.userModal,
      context,
      data: payload,
      isEdit: true,
    },
  }),

  [types.DETAIL_USER_FAILURE]: (state) => ({
    ...state,
    userDetail: {
      ...initialState.userDetail,
    },
  }),

  [types.DELETE_USER_SHOW_MODAL]: (state, { context, user }) => ({
    ...state,
    deleteModal: {
      context,
      show: true,
      user,
    },
  }),

  [types.DELETE_USER_HIDE_MODAL]: (state) => ({
    ...state,
    deleteModal: {
      ...initialState.deleteModal,
    },
  }),

  [types.DELETE_USER_REQUEST]: (state) => ({
    ...state,
    deleteUser: {
      isFetching: true,
    },
  }),

  [types.DELETE_USER_SUCCESS]: (state, { context }) => ({
    ...state,
    [context]: {
      ...state[context],
      didInvalid: true,
    },
    deleteUser: {
      isFetching: false,
    },
  }),

  [types.DELETE_USER_FAILURE]: (state) => ({
    ...state,
    deleteUser: {
      isFetching: false,
    },
  }),

  [types.USER_SAVE_SUCCESS]: (state, { context }) => ({
    ...state,
    [context]: {
      ...state[context],
      didInvalid: true,
    },
    userModal: {
      ...initialState.userModal,
      context: state.userModal.context,
    },
  }),

  [types.GET_USERS_LIST_REQUEST]: (state, { context }) => ({
    ...state,
    [context]: {
      ...initialState[context],
      isFetching: true,
    },
  }),

  [types.GET_USERS_LIST_SUCCESS]: (state, { payload, ...action }) => ({
    ...state,
    [action.context]: {
      ...state[action.context],
      data: payload.data,
      didInvalid: false,
      error: null,
      filter: action.filter,
      isFetching: false,
      order: action.order,
      page: action.page,
      resultsFiltered: payload.resultsFiltered,
      resultsTotal: payload.resultsTotal,
      rowsPerPage: action.rowsPerPage,
    },
    userModal: {
      ...state.userModal,
      context: null,
    },
  }),

  [types.GET_USERS_LIST_FAILURE]: (state, { context, error }) => ({
    ...state,
    [context]: {
      ...initialState[context],
      error,
    },
  }),

  [types.GET_SITE_USERS_LIST_REQUEST]: (state) => ({
    ...state,
    usersSites: {
      ...initialState.usersSites,
      isFetching: true,
    },
  }),

  [types.GET_SITE_USERS_LIST_SUCCESS]: (state, { payload, ...action }) => ({
    ...state,
    userModal: {
      ...state.userModal,
      context: null,
    },
    usersSites: {
      ...state.usersSites,
      data: payload.data,
      didInvalid: false,
      error: null,
      filter: action.filter,
      isFetching: false,
      order: action.order,
      page: action.page,
      resultsFiltered: payload.resultsFiltered,
      resultsTotal: payload.resultsTotal,
      rowsPerPage: action.rowsPerPage,
    },
  }),

  [types.GET_SITE_USERS_LIST_FAILURE]: (state, { context, error }) => ({
    ...state,
    usersSites: {
      ...initialState.usersSites,
      error,
    },
  }),

  [types.GET_CURRENT_USER_REQUEST]: (state) => ({
    ...state,
    isFetching: true,
  }),

  [types.GET_CURRENT_USER_SUCCESS]: (state, { payload }) => ({
    ...state,
    data: payload,
    didInvalid: false,
    isFetching: false,
  }),

  [types.GET_CURRENT_USER_FAILURE]: (state, { error }) => ({
    ...initialState,
    error,
  }),

  [types.CLEAR_IDENTITY]: (state) => ({
    ...state,
    data: initialState.data,
  }),

  [types.SET_USER_ROLE_REQUEST]: (state) => ({
    ...state,
    userRole: {
      error: null,
      isFetching: true,
    },
  }),

  [types.SET_USER_ROLE_SUCCESS]: (state) => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      didInvalid: true,
    },
    userRole: {
      error: null,
      isFetching: false,
    },
  }),

  [types.SET_USER_ROLE_FAILURE]: (state, { error }) => ({
    ...state,
    userRole: {
      error,
      isFetching: false,
    },
  }),

  [types.IMPORT_USERS_GET_STATUS_REQUEST]: (state) => ({
    ...state,
    importUsers: {
      ...state.importUsers,
      currentImport: {
        ...state.importUsers.currentImport,
        isFetching: true,
      },
    },
  }),

  [types.IMPORT_USERS_GET_STATUS_FAILURE]: (state) => ({
    ...state,
    importUsers: {
      ...state.importUsers,
      currentImport: {
        data: null,
        isFetching: false,
      },
    },
  }),

  [types.IMPORT_USERS_GET_STATUS_SUCCESS]: (state, { data }) => ({
    ...state,
    importUsers: {
      ...state.importUsers,
      currentImport: {
        data,
        isFetching: false,
      },
    },
  }),

  [types.IMPORT_USERS_FINISH]: (state) => ({
    ...state,
    [CONTEXT.SITE_USERS]: {
      ...state[CONTEXT.SITE_USERS],
      didInvalid: true,
    },
  }),

  [types.SET_ACCESS_GROUPS_SHOW_MODAL]: (state, { userId }) => ({
    ...state,
    userAccessGroupsModal: {
      ...initialState.userAccessGroupsModal,
      show: true,
      userId,
    },
  }),

  [types.SET_ACCESS_GROUPS_HIDE_MODAL]: (state) => ({
    ...state,
    userAccessGroupsModal: initialState.userAccessGroupsModal,
  }),

  [types.GET_SITE_USER_DEVICES_REQUEST]: (state) => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      devices: {
        ...state.userDetail.devices,
        isFetching: true,
      },
    },
  }),

  [types.GET_SITE_USER_DEVICES_SUCCESS]: (state, { payload, ...action }) => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      devices: {
        ...state.userDetail.devices,
        data: payload.results || [],
        didInvalid: false,
        filter: action.filter,
        isFetching: false,
        order: action.order,
        page: action.page,
        resultsFiltered: payload.resultsFiltered || 0,
        resultsTotal: payload.resultsTotal || 0,
        rowsPerPage: action.rowsPerPage,
      },
    },
  }),

  [types.GET_SITE_USER_DEVICES_FAILURE]: (state) => ({
    ...state,
    userDetail: {
      ...state.userDetail,
      devices: initialState.userDetail.devices,
    },
  }),

  [types.ACTIVATE_USER_MY2N_APP_ACCESS_REQUEST]: (state) => ({
    ...state,
    my2NAppAccessActivation: {
      ...state.my2NAppAccessActivation,
      error: null,
      isFetching: true,
    },
  }),

  [types.ACTIVATE_USER_MY2N_APP_ACCESS_SUCCESS]: (state, { context }) => ({
    ...state,
    my2NAppAccessActivation: {
      ...state.my2NAppAccessActivation,
      error: null,
      isFetching: false,
    },
    my2NAppAccessStatus: {
      ...state.my2NAppAccessStatus,
      didInvalid: context === 'SITE_USER_DETAIL',
    },
    usersSites: {
      ...state.usersSites,
      didInvalid: context === 'SITE_USERS_LIST',
    },
  }),

  [types.ACTIVATE_USER_MY2N_APP_ACCESS_FAILURE]: (state, { error }) => ({
    ...state,
    my2NAppAccessActivation: {
      ...state.my2NAppAccessActivation,
      error,
      isFetching: false,
    },
  }),

  [types.GET_USER_MY2N_APP_ACCESS_STATUS_REQUEST]: (state) => ({
    ...state,
    my2NAppAccessStatus: {
      ...state.my2NAppAccessStatus,
      didInvalid: false,
      error: null,
      isFetching: true,
    },
  }),

  [types.GET_USER_MY2N_APP_ACCESS_STATUS_SUCCESS]: (state, { payload }) => ({
    ...state,
    my2NAppAccessStatus: {
      ...state.my2NAppAccessStatus,
      data: payload,
      error: null,
      isFetching: false,
    },
  }),

  [types.GET_USER_MY2N_APP_ACCESS_STATUS_FAILURE]: (state, { error }) => ({
    ...state,
    my2NAppAccessStatus: {
      ...state.my2NAppAccessStatus,
      error,
      isFetching: false,
    },
  }),

  [types.INVALIDATE_CURRENT_USER]: (state) => ({
    ...state,
    didInvalid: true,
  }),
};

const reducer = (state = initialState, action = {}) => {
  if (reducers[action.type]) {
    return reducers[action.type](state, action);
  }
  return state;
};

export default reducer;
