import VerifiedIcon from '@mui/icons-material/VerifiedUser';
import Box from '@mui/material/Box';
import { sortBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { PATHS } from '../../config/consts';
import { useUrlParams } from '../../helpers/urlParams';
import ChipLink from '../ChipLink';

function AccessGroupsChipList({ groups }) {
  const { companyId, siteId } = useUrlParams();
  const sortedGroups = sortBy(groups, ['name']);

  return sortedGroups.map((group) => (
    <Box item key={group.id} sx={{ display: 'inline-grid' }}>
      <ChipLink
        icon={<VerifiedIcon />}
        label={group.name}
        link={PATHS.ACCESS_SETTINGS_DETAIL.replace(':companyId', companyId)
          .replace(':siteId', siteId)
          .replace(':groupId', group.id)}
      />
    </Box>
  ));
}

AccessGroupsChipList.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    })
  ).isRequired,
};

export default AccessGroupsChipList;
