import { useCookies } from 'react-cookie';
import { COOKIE_OPTIONS } from '../config/consts';
import { useCookieConsent } from './cookieConsent';

export const useCookie = () => {
  const [cookies, setNewCookie] = useCookies();
  const { functional } = useCookieConsent();

  return {
    getCookie: (cookieName) => cookies?.[cookieName] || null,
    setFunctionalCookie: (cookieName, settings) =>
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      functional ? setNewCookie(cookieName, settings, COOKIE_OPTIONS) : () => {},
  };
};
