import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PATHS } from '../../../../config/consts';
import { menuListItem } from '../../../../config/styles';
import EllipsisText from '../../../EllipsisText';
import IconMaterial from '../../../IconMaterial';
import messages from '../../messages';

const styles = {
  arrowIcon: {
    opacity: '0.87',
  },
  avatar: {
    backgroundColor: 'appBar.username',
    fontSize: '20px',
    height: '40px',
    marginRight: 1,
    width: '40px',
  },
  buttonBase: {
    '& .MuiButtonBase-root': {
      height: '100%',
      px: 0.5,
      py: 0,
    },
  },
  icon: {
    '&.MuiListItemIcon-root': {
      minWidth: 0,
      pr: 2.5,
    },
  },
  logoutItem: {
    '&.MuiMenuItem-root': {
      ...menuListItem,
      color: 'error.main',
    },
  },
  menuItem: {
    '&.MuiMenuItem-root': {
      ...menuListItem,
      minWidth: 224,
    },
  },
  paper: {
    '&.MuiPaper-root': {
      mr: 2.5,
      pt: 2,
    },
  },
  root: {
    color: 'common.white',
    mr: 2.5,
    textAlign: 'left',
  },
  usersName: {
    fontFamily: 'Roboto',
    fontWeight: 'regular',
    maxWidth: '250px',
    opacity: '0.87',
  },
};

function UserMenu({ onLogout, user }) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => setOpen(!open);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <Box id="user-menu" sx={styles.root}>
      <ButtonBase
        aria-controls="user-menu-list-grow"
        aria-haspopup="true"
        disableRipple
        onClick={handleToggle}
        ref={anchorRef}
        sx={styles.buttonBase}
      >
        <Avatar sx={styles.avatar}>
          {`${user?.firstName?.[0] || ''}${user?.lastName?.[0] || ''}`.toUpperCase().trim()}
        </Avatar>
        <Box sx={{ display: { md: 'flex', xs: 'none' } }}>
          <Typography noWrap sx={styles.usersName}>
            <EllipsisText>{`${user?.firstName} ${user?.lastName}`.trim()}</EllipsisText>
          </Typography>
          <Box component="span" sx={styles.arrowIcon}>
            {open ? <IconMaterial name="arrow_drop_up" /> : <IconMaterial name="arrow_drop_down" />}
          </Box>
        </Box>
      </ButtonBase>
      <Popper anchorEl={anchorRef.current} open={Boolean(open)} transition>
        {({ placement, TransitionProps }) => (
          <Fade
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper elevation={24} id="user-menu-list-grow" sx={styles.paper}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList disablePadding>
                  <MenuItem
                    component={Link}
                    sx={styles.menuItem}
                    to={PATHS.USER_PROFILE}
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <ListItemIcon sx={styles.icon}>
                      <AccountCircleIcon color="secondary" />
                    </ListItemIcon>
                    <FormattedMessage {...messages.userMenuUserProfile} />
                  </MenuItem>
                  <Divider sx={{ '&.MuiDivider-root': { m: 0 } }} />
                  <MenuItem onClick={onLogout} sx={styles.logoutItem}>
                    <ListItemIcon sx={styles.icon}>
                      <ExitToAppIcon color="error" />
                    </ListItemIcon>
                    <FormattedMessage {...messages.userMenuLogOut} />
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Box>
  );
}

UserMenu.propTypes = {
  onLogout: PropTypes.func.isRequired,
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }).isRequired,
};

export default UserMenu;
