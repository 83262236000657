import { defineMessages } from 'react-intl';

export default defineMessages({
  elevatorIntegrationKonecloudAlertText: {
    defaultMessage: 'Connection details:',
    id: 'elevatorIntegration.KONEcloud.alert.text',
  },
  elevatorIntegrationKonecloudAlertTitle: {
    defaultMessage: 'My2n site is connected to KONE cloud',
    id: 'elevatorIntegration.KONEcloud.alert.title',
  },
  elevatorIntegrationKonecloudButtonRefresh: {
    defaultMessage: 'refresh configuration',
    id: 'elevatorIntegration.KONEcloud.buttonRefresh',
  },
  elevatorIntegrationKonecloudTitle: {
    defaultMessage: 'KONE cloud connection',
    id: 'elevatorIntegration.KONEcloud.title',
  },
  siteSettingsKoneCloudConnectionInfoText: {
    defaultMessage: '{br}buildingId [ {index} ]: {buildingId}',
    description: 'KONE cloud connection info box text',
    id: 'siteSettingsKoneCloudConnectionInfoText',
  },
});
