import { defineMessages } from 'react-intl';

export default defineMessages({
  changeUserRoleModalCancel: {
    defaultMessage: 'Cancel',
    description: 'Change user role modal - cancel button',
    id: 'changeUserRoleModal.cancel',
  },
  changeUserRoleModalChangeRole: {
    defaultMessage: 'Change role',
    description: 'Change user role modal - change role button',
    id: 'changeUserRoleModal.changeRole',
  },
  changeUserRoleModalDescriptionPt1: {
    defaultMessage:
      'You are about to make a change from the <b>user without login</b> role.' +
      ' This action can’t be undone as the email can’t be removed.',
    description: 'Change user role modal - description first part',
    id: 'changeUserRoleModal.descriptionPt1',
  },
  changeUserRoleModalDescriptionPt2: {
    defaultMessage: 'Access, apartment and call settings will remain the same.',
    description: 'Change user role modal - description second part',
    id: 'changeUserRoleModal.descriptionPt2',
  },
  changeUserRoleModalEmailHelperText: {
    defaultMessage: 'User will be sent an activation email.',
    description: 'Change user role modal - email input helper text',
    id: 'changeUserRoleModal.emailHelperText',
  },
  changeUserRoleModalEmailLabel: {
    defaultMessage: 'User email',
    description: 'Change user role modal - email input label',
    id: 'changeUserRoleModal.emailLabel',
  },
  changeUserRoleModalFirstNameLabel: {
    defaultMessage: 'First name',
    description: 'Change user role modal - first name input label',
    id: 'changeUserRoleModal.firstNameLabel',
  },
  changeUserRoleModalSurnameHelperText: {
    defaultMessage: 'You can change the surname.',
    description: 'Change user role modal - surname input helper text',
    id: 'changeUserRoleModal.surnameHelperText',
  },
  changeUserRoleModalSurnameLabel: {
    defaultMessage: 'Surname',
    description: 'Change user role modal - surname input label',
    id: 'changeUserRoleModal.surnameLabel',
  },
  changeUserRoleModalTitle: {
    defaultMessage: 'Change user role',
    description: 'Change user role modal - title',
    id: 'changeUserRoleModal.title',
  },
});
