import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert } from '../../../components';
import messages from './messages';

const UnlicensedDevicesWarningAlert = () => (
  <Alert severity="warning">
    <FormattedMessage {...messages.unlicensedDevicesWarningAlertWarning} />
  </Alert>
);

export default UnlicensedDevicesWarningAlert;
