import PropTypes from 'prop-types';

export const ColumnPropType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  render: PropTypes.func.isRequired,
  renderCellProps: PropTypes.shape({}),
  renderHeadProps: PropTypes.shape({}),
});

export const AccessLogPropType = PropTypes.shape({
  credentials: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      valid: PropTypes.bool,
    })
  ),
  detail: PropTypes.shape({
    log: PropTypes.string,
  }),
  device: PropTypes.shape({
    name: PropTypes.string.isRequired,
    sn: PropTypes.string.isRequired,
    timezoneShift: PropTypes.number.isRequired,
  }),
  direction: PropTypes.string,
  endAt: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rejectionReason: PropTypes.string,
  result: PropTypes.string.isRequired,
  startAt: PropTypes.string.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
});
