import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavigateFunction, NavigateOptions, useLocation, useNavigate } from 'react-router-dom';
import { clearRedirectData } from '../modules/infrastructure/actions';
import { getRedirectData } from '../modules/infrastructure/selectors';

export function useRedirectOnChange(): void {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const redirectData = useSelector(getRedirectData);

  useEffect(() => {
    if (redirectData !== undefined) {
      if (redirectData.locationSubRoutesCheck !== undefined && redirectData.locationSubRoutesCheck.length > 0) {
        redirectByLocation(redirectData.locationSubRoutesCheck, navigate, location.pathname);
      }

      if (redirectData.action !== undefined && redirectData.action.length > 0) {
        redirectData.action.forEach((action) => {
          dispatch(action);
        });
      }

      dispatch(clearRedirectData());
      let options: NavigateOptions | undefined = undefined;
      if (redirectData.data !== undefined) {
        options = {
          state: redirectData.data,
        };
      }

      navigate(redirectData.to, options);
    }
  }, [redirectData]);
}

function redirectByLocation(subRoutes: string[], navigate: NavigateFunction, pathName: string): void {
  let redirectSubstring = '';
  subRoutes.forEach((subRoute) => {
    if (pathName.indexOf(subRoute) > 0) {
      redirectSubstring = subRoute;
    }
  });

  if (redirectSubstring !== '') {
    navigate(pathName.slice(0, pathName.indexOf(redirectSubstring) + redirectSubstring.length), { replace: true });
  }
}
