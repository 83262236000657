import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { MOBILE_VIDEO_STATUS } from '../../../config/consts';
import { SERVICE } from '../../../config/devices';
import { isOpenRcConfigurationDisabled } from '../../../containers/DeviceDetail/helpers';
import devicesActions from '../../../modules/devices';
import paymentsActions from '../../../modules/payments';
import { getSitesServiceMobileVideoLicenceModel } from '../../../modules/sites/selectors';
import UseAvailableCreditMenuItem from '../../UseAvailableCreditMenuItem';
import DeviceDataGridAction from '../DeviceDataGridAction';
import ActivateTrial from './ActivateTrial';
import ChangePassword from './ChangePassword';
import DeleteDevice from './DeleteDevice';
import EditDevice from './EditDevice';
import OpenRc from './OpenRc';
import PurchaseSubscription from './PurchaseSubscription';
import Reverify from './Reverify';

const DeviceRowMenu = ({
  device,
  licenceModel,
  onOpenAvailableCreditModal,
  onOpenDeleteDeviceModal,
  onOpenRCModal,
  onStartTrial,
  onVerify,
  showActionButton,
}) => {
  const isRcConfigurationDisabled = useMemo(
    () =>
      isOpenRcConfigurationDisabled(
        device?.services?.[SERVICE.REMOTE_CONFIGURATION]?.status || null,
        device.services?.[SERVICE.ACS]?.firmwareStatus || null
      ),
    [device.services?.[SERVICE.ACS]?.firmwareStatus, device?.services?.[SERVICE.REMOTE_CONFIGURATION]?.status]
  );

  return (
    <Box>
      {showActionButton && (
        <DeviceDataGridAction
          activateTrialComponent={<ActivateTrial onStartTrial={onStartTrial} />}
          changePasswordComponent={<ChangePassword device={device} onOpenRCModal={onOpenRCModal} />}
          device={device}
          licenceModel={licenceModel}
          purchaseComponent={<PurchaseSubscription deviceSite={device?.site} />}
          reverifyComponent={<Reverify device={device} onVerify={onVerify} />}
          useAvailableCreditComponent={
            <UseAvailableCreditMenuItem onOpenAvailableCreditModal={onOpenAvailableCreditModal} />
          }
        />
      )}
      <EditDevice companyId={device.site.company.id} deviceId={device.id} siteId={device.site.id} />
      <OpenRc device={device} disabled={isRcConfigurationDisabled} onOpenRCModal={onOpenRCModal} />
      <Divider />
      <DeleteDevice deviceId={device.id} onOpenModal={onOpenDeleteDeviceModal} />
    </Box>
  );
};

DeviceRowMenu.propTypes = {
  device: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    services: PropTypes.shape({
      [SERVICE.ACS]: PropTypes.shape({
        firmware: PropTypes.string,
      }),
      [SERVICE.REMOTE_CONFIGURATION]: PropTypes.shape({
        status: PropTypes.string,
      }),
    }),
    site: PropTypes.shape({
      company: PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    type: PropTypes.string,
  }).isRequired,
  licenceModel: PropTypes.shape({
    additional: PropTypes.number,
    licencesPool: PropTypes.number,
    status: PropTypes.oneOf(Object.keys(MOBILE_VIDEO_STATUS)),
    unlicensedCount: PropTypes.number,
  }).isRequired,
  onOpenAvailableCreditModal: PropTypes.func.isRequired,
  onOpenDeleteDeviceModal: PropTypes.func.isRequired,
  onOpenRCModal: PropTypes.func.isRequired,
  onStartTrial: PropTypes.func.isRequired,
  onVerify: PropTypes.func.isRequired,
  showActionButton: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  licenceModel: getSitesServiceMobileVideoLicenceModel(state),
});

const mapDispatchToProps = {
  onOpenDeleteDeviceModal: devicesActions.deleteDeviceModalShow,
  onOpenRCModal: devicesActions.rcShowModal,
  onStartTrial: paymentsActions.startTrialVersion,
  onVerify: devicesActions.verifyDeviceShowModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeviceRowMenu);
