import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { FIRMWARE_STATUS, SERVICE } from '../../../../config/devices';
import { useUrlParams } from '../../../../helpers/urlParams';
import apartmentActions from '../../../../modules/apartments';
import messages from '../../../DeviceModal/X2NDeviceForm/OneStep/messages';
import DeviceInfoTitle from '../DeviceInfoTitle';
import DeviceNameField from '../DeviceNameField';
import DeviceOwnerField from '../DeviceOwnerField';
import DeviceNewApartmentFormDeprecated from '../NewApartmentForm';
import ApartmentFormFieldDeprecated from '../NewApartmentForm/ApartmentFormField_deprecated';
import { hasDeviceOwnerField } from './helpers';

const DeviceDetailMy2nSettings = ({
  apartments,
  device,
  floors,
  isApartmentActivable,
  onChange,
  site,
  users,
  values,
}) => {
  const { companyId, siteId } = useUrlParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(apartmentActions.getSiteFloorsList(companyId, siteId));
  }, []);

  const disabled = useMemo(
    () =>
      device?.services?.[SERVICE.ACS]?.firmwareStatus === FIRMWARE_STATUS.UNSUPPORTED ||
      device?.services?.[SERVICE.ACS]?.firmwareStatus === FIRMWARE_STATUS.DEPRECATED,
    [device?.services?.[SERVICE.ACS]?.firmwareStatus]
  );

  return (
    <Grid container direction="column">
      <Grid item>
        <DeviceInfoTitle type="my2n" />
      </Grid>
      <Grid item>
        <DeviceNameField disabled={disabled} onChange={onChange} value={values.name} />
      </Grid>
      {hasDeviceOwnerField(device.type) && (
        <Grid item width={'100%'}>
          <DeviceOwnerField disabled={disabled} onChange={onChange} users={users} value={values.userId} />
        </Grid>
      )}
      {isApartmentActivable && (
        <Grid item>
          <ApartmentFormFieldDeprecated
            apartments={apartments}
            deviceType={device.type}
            disabled={disabled}
            label={<FormattedMessage {...messages.x2nDeviceOneStepApartmentLabel} />}
            onChange={onChange}
            siteType={site.type}
            value={values.apartmentId}
          />
          {values?.apartmentId === 'createNewApartment' && (
            <Box my={2}>
              <DeviceNewApartmentFormDeprecated
                apartments={apartments}
                floors={floors}
                onChange={onChange}
                siteType={site.type}
                values={values}
              />
            </Box>
          )}
        </Grid>
      )}
    </Grid>
  );
};

DeviceDetailMy2nSettings.propTypes = {
  apartments: PropTypes.arrayOf(PropTypes.object).isRequired,
  device: PropTypes.shape({
    services: PropTypes.shape({
      [SERVICE.ACS]: PropTypes.shape({
        firmwareStatus: PropTypes.string,
      }),
    }),
    type: PropTypes.string,
  }).isRequired,
  floors: PropTypes.arrayOf(PropTypes.object).isRequired,
  isApartmentActivable: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  site: PropTypes.shape({
    type: PropTypes.string,
  }).isRequired,
  users: PropTypes.arrayOf(PropTypes.object).isRequired,
  values: PropTypes.shape({
    apartmentId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    userId: PropTypes.number,
  }).isRequired,
};

export default DeviceDetailMy2nSettings;
