import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import RowMenuItem from '../../DataGrid/RowMenuItem';
import messages from '../messages';

const RenameSite = ({ onRenameSite, site }) => (
  <RowMenuItem
    icon={<EditIcon />}
    label={<FormattedMessage {...messages.recentSitesRowMenuRenameSite} />}
    onClick={() => onRenameSite(site)}
  />
);

RenameSite.propTypes = {
  onRenameSite: PropTypes.func.isRequired,
  site: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
};

export default RenameSite;
