import { useEffect, useState } from 'react';

export function useTimeoutEffect(valueExecutor: boolean, customDelay?: number): boolean {
  const delay = customDelay ?? 1000;
  const [value, setValue] = useState(false);

  useEffect(() => {
    if (valueExecutor) {
      setValue(true);
      setTimeout(() => {
        setValue(false);
      }, delay);
    }
  }, [delay, valueExecutor]);

  return value;
}
