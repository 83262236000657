import { EMAIL_REGEXP, IMPORT_USERS_FAILED_ROW_RESULT } from '../../../config/consts';

const detectErrorTextValue = (value) => !value;
const detectErrorEmail = (email) => !EMAIL_REGEXP.test(email);

export const getErrors = (data) =>
  data.filter((item) => {
    const isApartmentFloorError = detectErrorTextValue(item.apartmentFloor);
    const isApartmentNameError = detectErrorTextValue(item.apartmentName);
    const isApartmentNumberError = detectErrorTextValue(item.apartmentNumber);
    const isEmailError = detectErrorEmail(item.email);
    const isFirstNameError = detectErrorTextValue(item.firstName);
    const isLastNameError = detectErrorTextValue(item.lastName);
    const isAborted = item.result === IMPORT_USERS_FAILED_ROW_RESULT.ABORTED;
    const isInternalError = item.result === IMPORT_USERS_FAILED_ROW_RESULT.INTERNAL_ERROR;

    const isApartmentError =
      (!isApartmentFloorError && isApartmentNumberError) ||
      (isApartmentNumberError && !isApartmentNameError) ||
      (isApartmentFloorError && !isApartmentNumberError);

    return (isApartmentError || isFirstNameError || isLastNameError || isEmailError || isInternalError) && !isAborted;
  });
