export const PAYMENT_STATUS = {
  BEFORE_COMPLETION: 'Generating licenses',
  CANCELLED: 'Payment cancelled',
  COMPLETED: 'Delivered',
  CREATED: 'Incomplete',
  DELIVERED: 'Generating licenses',
  FAILED: 'Payment failed',
  INVOICE_ISSUED: 'Generating licenses',
  PAID: 'Generating licenses',
  REFUNDED: 'Payment refunded',
};

export const PAYMENT_ERROR = {
  CARD_ERROR: 'CARD_ERROR',
  CARD_ISSUER_ERROR: 'CARD_ISSUER_ERROR',
  CREDIT_LIMIT_EXCEEDED: 'CREDIT_LIMIT_EXCEEDED',
  GATEWAY_ERROR: 'GATEWAY_ERROR',
  GENERIC: 'GENERIC',
  INVOICE_PAYMENT_ERROR: 'INVOICE_PAYMENT_ERROR',
  MY2N_ERROR: 'MY2N_ERROR',
  NOT_PROCESSED: 'NOT_PROCESSED',
  SESSION_EXPIRED: 'SESSION_EXPIRED',
  USER_ERROR: 'USER_ERROR',
};

export const PAYMENT_CONTEXT = {
  COMPANY_PAYMENTS: 'COMPANY_PAYMENTS',
  RECENT_PAYMENTS: 'RECENT_PAYMENTS',
  SITE_PAYMENTS: 'SITE_PAYMENTS',
  SUPERADMIN_PAYMENTS: 'SUPERADMIN_PAYMENTS',
};
