import types from './types';

export const initialState = {
  open: false,
};

const reducers = {
  [types.SET_COOKIES_SETTINGS_OPEN]: (state, { open }) => ({
    ...state,
    open,
  }),
};

const reducer = (state = initialState, action = {}) => {
  if (reducers[action.type]) {
    return reducers[action.type](state, action);
  }
  return state;
};

export default reducer;
