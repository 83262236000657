import { defineMessages } from 'react-intl';

export default defineMessages({
  iconServicesAccessControl: {
    defaultMessage: '2N® Residential Access - {state}',
    description: '',
    id: 'iconServices.services.accessControl',
  },
  iconServicesElevatorIntegration: {
    defaultMessage: 'Elevator integration - {state}',
    description: '',
    id: 'iconServices.services.elevatorIntegration',
  },
  iconServicesIconServicesDisabled: {
    defaultMessage: 'Inactive service',
    description: '',
    id: 'iconServices.disabled',
  },
  iconServicesIconServicesEnabled: {
    defaultMessage: 'Active service',
    description: '',
    id: 'iconServices.enabled',
  },
  iconServicesMobileVideo: {
    defaultMessage: '2N® Mobile Video - {state}',
    description: '',
    id: 'iconServices.services.mobileVideo',
  },
  iconServicesRemoteConfiguration: {
    defaultMessage: '2N® Remote Configuration - {state}',
    description: '',
    id: 'iconServices.services.remoteConfiguration',
  },
});
