import { defineMessages } from 'react-intl';

export default defineMessages({
  basicDataPanelCodes: {
    defaultMessage: 'Codes',
    description: 'Codes label',
    id: 'basicDataPanel.codes',
  },
  basicDataPanelCodesActiveUnit: {
    defaultMessage: 'active',
    description: 'Codes active unit',
    id: 'basicDataPanel.codesActiveUnit',
  },
  basicDataPanelCodesCanceledUnit: {
    defaultMessage: 'canceled',
    description: 'Codes canceled unit',
    id: 'basicDataPanel.codesCanceledUnit',
  },
  basicDataPanelCodesUnit: {
    defaultMessage: 'codes',
    description: 'Codes unit',
    id: 'basicDataPanel.codesUnit',
  },
  basicDataPanelCodesUsedUnit: {
    defaultMessage: 'used',
    description: 'Codes used unit',
    id: 'basicDataPanel.codesUsedUnit',
  },
  basicDataPanelCodeType: {
    defaultMessage: 'Code type',
    description: 'Code type label',
    id: 'basicDataPanel.codeType',
  },
  basicDataPanelCredits: {
    defaultMessage: 'Credits',
    description: 'Credits label',
    id: 'basicDataPanel.credits',
  },
  basicDataPanelCreditsUnit: {
    defaultMessage: 'month/device',
    description: 'Credits unit',
    id: 'basicDataPanel.creditsUnit',
  },
  basicDataPanelDetailBatchDialogCloseBatchButton: {
    defaultMessage: 'Close batch',
    description: 'Close batch button - title',
    id: 'detailBatchDialog.closeBatchButton',
  },
  basicDataPanelDetailBatchDialogCloseButton: {
    defaultMessage: 'Close',
    description: 'Close dialog button',
    id: 'detailBatchDialog.closeButton',
  },
  basicDataPanelDetailBatchDialogCopyAllButton: {
    defaultMessage: 'Copy all codes',
    description: 'Copy all codes - button',
    id: 'detailBatchDialog.copyAllButton',
  },
  basicDataPanelDetailBatchDialogTitle: {
    defaultMessage: 'Detail of <strong>{name}</strong>',
    description: 'Detail batch dialog - title',
    id: 'detailBatchDialog.title',
  },
  basicDataPanelPromo: {
    defaultMessage: 'Promo',
    description: 'Promo value',
    id: 'basicDataPanel.promo',
  },
  basicDataPanelPurchase: {
    defaultMessage: 'Paid',
    description: 'Purchase value',
    id: 'basicDataPanel.purchase',
  },
  basicDataPanelSummary: {
    defaultMessage: 'Summary',
    description: 'Summary label',
    id: 'basicDataPanel.summary',
  },
  basicDataPanelSummaryUsedUnit: {
    defaultMessage: 'm/device used',
    description: 'Summary used unit',
    id: 'basicDataPanel.summaryUsedUnit',
  },
  detailBatchDialogDetailBatchInfoPanelCreator: {
    defaultMessage: 'Creator',
    description: 'Creator label',
    id: 'detailBatchInfoPanel.creator',
  },
  detailBatchDialogDetailBatchInfoPanelInvoice: {
    defaultMessage: 'Invoice',
    description: 'Invoice label',
    id: 'detailBatchInfoPanel.invoice',
  },
  detailBatchDialogDetailBatchInfoPanelNotes: {
    defaultMessage: 'Notes, comments, instructions',
    description: 'Notes label',
    id: 'detailBatchInfoPanel.notes',
  },
  detailBatchDialogDetailBatchInfoPanelTags: {
    defaultMessage: 'Tags',
    description: 'Tags label',
    id: 'detailBatchInfoPanel.tags',
  },
  detailBatchDialogDetailBatchInfoPanelValidFrom: {
    defaultMessage: 'Valid from',
    description: 'Valid from label',
    id: 'detailBatchInfoPanel.validFrom',
  },
  detailBatchDialogDetailBatchInfoPanelValidFromUnit: {
    defaultMessage: 'Creation date',
    description: 'Valid from unit',
    id: 'detailBatchInfoPanel.validFromUnit',
  },
  detailBatchDialogDetailBatchInfoPanelValidTo: {
    defaultMessage: 'Valid to',
    description: 'Valid to label',
    id: 'detailBatchInfoPanel.validTo',
  },
  detailBatchDialogDetailBatchInfoPanelValidToUnit: {
    defaultMessage: '{value} year(s)',
    description: 'Valid to unit',
    id: 'detailBatchInfoPanel.validToUnit',
  },
});
