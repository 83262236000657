import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { actionsTS, CONTEXT } from '../../../modules/user';

export function useFetchAdminDetailData(companyId: number, userId: number | undefined, isFetching: boolean): void {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!isFetching && userId !== undefined) {
      dispatch(actionsTS.detailUserRequest(CONTEXT.COMPANY_ADMINS)(companyId, undefined, userId));
    }
  }, [userId]);
}
