import BusinessIcon from '@mui/icons-material/Business';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PaymentsIcon from '@mui/icons-material/Payments';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import SitesIcon from '@mui/icons-material/Room';
import CompanyAdminsIcon from '@mui/icons-material/SupervisedUserCircle';
import List from '@mui/material/List';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { PATHS } from '../../config/consts';
import messages from './messages';
import SidebarItem from './SidebarItem';

const CompanyList = ({ onCloseSidebarMobile }: { onCloseSidebarMobile: () => void }) => {
  const companyId = useParams()?.companyId || '';

  return (
    <List>
      <SidebarItem
        activePaths={[PATHS.COMPANY_DASHBOARD]}
        icon={<DashboardIcon />}
        id="dashboard-link"
        link={PATHS.COMPANY_DASHBOARD.replace(':companyId', companyId)}
        onClick={onCloseSidebarMobile}
        title={<FormattedMessage {...messages.SidebarCompanyListCompanyDashboard} />}
        disabled={false}
        showOnlyTitle={false}
        showSelected
        showTitleOnHover
      />
      <SidebarItem
        activePaths={[PATHS.COMPANY_SITES]}
        icon={<SitesIcon />}
        id="sites-link"
        link={PATHS.COMPANY_SITES.replace(':companyId', companyId)}
        onClick={onCloseSidebarMobile}
        title={<FormattedMessage {...messages.SidebarCompanyListSites} />}
        disabled={false}
        showOnlyTitle={false}
        showSelected
        showTitleOnHover
      />
      <SidebarItem
        activePaths={[PATHS.COMPANY_ADMIN, PATHS.COMPANY_ADMIN_DETAIL]}
        icon={<CompanyAdminsIcon />}
        id="admins-link"
        link={PATHS.COMPANY_ADMIN.replace(':companyId', companyId)}
        onClick={onCloseSidebarMobile}
        title={<FormattedMessage {...messages.SidebarCompanyListCompanyAdmins} />}
        disabled={false}
        showOnlyTitle={false}
        showSelected
        showTitleOnHover
      />
      <SidebarItem
        activePaths={[PATHS.BILLING_INFO]}
        icon={<BusinessIcon />}
        id="billinginfo-link"
        link={PATHS.BILLING_INFO.replace(':companyId', companyId)}
        onClick={onCloseSidebarMobile}
        title={<FormattedMessage {...messages.SidebarCompanyListBillingInfo} />}
        disabled={false}
        showOnlyTitle={false}
        showSelected
        showTitleOnHover
      />
      <SidebarItem
        activePaths={[PATHS.PAYMENTS_OVERVIEW]}
        icon={<PaymentsIcon />}
        id="payments-link"
        link={PATHS.PAYMENTS_OVERVIEW.replace(':companyId', companyId)}
        onClick={onCloseSidebarMobile}
        title={<FormattedMessage {...messages.SidebarCompanyListPaymentsOverview} />}
        disabled={false}
        showOnlyTitle={false}
        showSelected
        showTitleOnHover
      />
      <SidebarItem
        activePaths={[PATHS.MV_SDK_SETTINGS]}
        icon={<PhonelinkSetupIcon />}
        id="mvsdk-link"
        link={PATHS.MV_SDK_SETTINGS.replace(':companyId', companyId)}
        onClick={onCloseSidebarMobile}
        title={<FormattedMessage {...messages.SidebarCompanyListMvSdkSettings} />}
        disabled={false}
        showOnlyTitle={false}
        showSelected
        showTitleOnHover
      />
    </List>
  );
};

export default CompanyList;
