export function getApartmentInfoLeftPadding(isMobile: boolean, isBackButtonUsed: boolean): number {
  if (isMobile && isAvatarIconVisible(isMobile, isBackButtonUsed)) {
    return 8;
  }
  if (isMobile) {
    return 5;
  }
  return 3;
}

export function isAvatarIconVisible(isMobile: boolean, isBackButtonUsed: boolean): boolean {
  if (isMobile && isBackButtonUsed) {
    return false;
  }
  return true;
}
