import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import ActionsSection from '../ActionsSection';
import CreditInfo from '../CreditInfo';
import PrepaidCode from './PrepaidCode';
import ValidationStatus from './ValidationStatus';

const OneStep = ({
  activationCodeError,
  codeCreditState,
  codeData,
  codeExpirationState,
  isCodeVerificationLoading,
  isMobileVideoAllowed,
  isVatVerified,
  mobileVideoLicense,
  onCodeVerification,
  onStepChange,
}) => (
  <Grid container direction="column" item spacing={4}>
    <Grid container item spacing={2}>
      <Grid item md={4} xs={12}>
        <PrepaidCode
          activationCodeError={activationCodeError}
          codeData={codeData}
          isLoading={isCodeVerificationLoading}
          mobileVideoLicense={mobileVideoLicense}
          onCodeVerification={onCodeVerification}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <ValidationStatus
          activationCodeError={activationCodeError}
          credit={codeData?.configuration?.credit}
          mobileVideoLicense={mobileVideoLicense}
        />
      </Grid>
      <Grid item md={4} xs={12}>
        <CreditInfo
          codeCreditState={codeCreditState}
          codeExpirationState={codeExpirationState}
          mobileVideoLicense={mobileVideoLicense}
        />
      </Grid>
    </Grid>
    <Grid container item justifyContent="space-between">
      <Grid item xs>
        <ActionsSection
          isMobileVideoAllowed={isMobileVideoAllowed}
          isNextStepDisabled={!codeData?.configuration?.credit}
          isVatVerified={isVatVerified}
          onNextStep={() => onStepChange((prevState) => prevState + 1)}
        />
      </Grid>
    </Grid>
  </Grid>
);

OneStep.propTypes = {
  activationCodeError: PropTypes.shape({
    data: PropTypes.shape({}),
    status: PropTypes.number,
  }).isRequired,
  codeCreditState: PropTypes.arrayOf(PropTypes.string).isRequired,
  codeData: PropTypes.shape({
    configuration: PropTypes.shape({
      credit: PropTypes.number,
    }),
  }).isRequired,
  codeExpirationState: PropTypes.string.isRequired,
  isCodeVerificationLoading: PropTypes.bool.isRequired,
  isMobileVideoAllowed: PropTypes.bool.isRequired,
  isVatVerified: PropTypes.bool.isRequired,
  mobileVideoLicense: PropTypes.shape({
    status: PropTypes.string,
    validityTo: PropTypes.string,
  }).isRequired,
  onCodeVerification: PropTypes.func.isRequired,
  onStepChange: PropTypes.func.isRequired,
};

export default OneStep;
