import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const styles = {
  content: (theme) => ({
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center',
      textAlign: 'center',
    },
    display: 'flex',
    flexWrap: 'wrap',
    height: '100%',
    justifyContent: 'space-between',
    maxWidth: '1050px',
    mx: 'auto',
    my: 0,
    p: 2,
    pb: 4,
    pt: 0,
    width: '100%',
  }),
  plans: (theme) => ({
    [theme.breakpoints.up('lg')]: {
      flexBasis: '40%',
    },
    [theme.breakpoints.down('md')]: {
      flexBasis: 'auto',
      flexGrow: 1,
      textAlign: 'center',
    },
    '& a': {
      color: 'primary.main',
      display: 'block',
      fontSize: '16px',
      fontWeight: 'regular',
      mb: 0.5,
      textDecoration: 'underline',
    },
    '& h2': {
      [theme.breakpoints.down('lg')]: {
        fontSize: '38px',
      },
      [theme.breakpoints.down('md')]: {
        fontSize: '33px',
        mt: 6,
      },
      color: 'grey.900',
      fontSize: '48px',
      fontWeight: 'regular',
      mb: 3,
      mt: 15,
    },
    '& h3': {
      [theme.breakpoints.down('md')]: {
        mx: 'auto',
      },
      color: 'grey.600',
      fontSize: '20px',
      fontWeight: 'regular',
      maxWidth: '370px',
    },
    '& ul': {
      '& li': {
        pt: 1,
      },
      listStyleType: 'none',
      m: 0,
      mt: 2.5,
      p: 0,
    },
    flexBasis: '48%',
  }),
  productboard: (theme) => ({
    [theme.breakpoints.up('lg')]: {
      flexBasis: '60%',
    },
    [theme.breakpoints.down('md')]: {
      flexBasis: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'none',
      p: 0,
      textAlign: 'center',
      width: '100%',
    },
    '& iframe': {
      border: 0,
      boxShadow: '0 0 20px rgb(0 0 0 / 12%)',
      height: '100%',
      width: '100%',
    },
    flexBasis: '52%',
    height: '515px',
    mt: 6,
  }),
  root: {
    background: 'white',
    lineHeight: 1.5,
    minHeight: '550px',
    width: '100%',
  },
};

const News = ({ productboardUrl }) => (
  <Box sx={styles.root}>
    <Box sx={styles.content}>
      <Box sx={styles.plans}>
        <h2>
          <FormattedMessage {...messages.newsTitle} />
        </h2>
        <h3>
          <FormattedMessage {...messages.newsDescription} />
        </h3>
        <ul>
          <li>
            <a href={productboardUrl} rel="noopener noreferrer" target="_blank">
              <FormattedMessage {...messages.newsProductboard} />
            </a>
          </li>
        </ul>
      </Box>
      <Box sx={styles.productboard}>
        <iframe
          sandbox="allow-scripts"
          src="https://portal.productboard.com/uef9lw6yumoa4gcpd53z34px/tabs/6-released"
          title="Productboard"
        />
      </Box>
    </Box>
  </Box>
);

News.propTypes = {
  productboardUrl: PropTypes.string,
};

News.defaultProps = {
  productboardUrl: 'https://portal.productboard.com/uef9lw6yumoa4gcpd53z34px/tabs/6-released',
};

export default News;
