import React from 'react';
import { FormattedMessage } from 'react-intl';
import NoRowsOverlayDataGrid from '../../../components/DataGrid/NoRowsOverlay';
import messages from './messages';

const NoRowsOverlay = () => (
  <NoRowsOverlayDataGrid
    headerText={<FormattedMessage {...messages.accessGroupDevicesDataGridNoRowsOverlayHeaderText} />}
    text={
      <FormattedMessage
        {...messages.accessGroupDevicesDataGridNoRowsOverlayText}
        values={{
          br: <br />,
        }}
      />
    }
  />
);

export default NoRowsOverlay;
