import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as Yup from 'yup';
import messages from './messages';

export const getValidationSchema = Yup.object().shape({
  description: Yup.string().max(255, <FormattedMessage {...messages.accessGroupDetailFormInputDescriptionMaxLength} />),
  name: Yup.string()
    .required(<FormattedMessage {...messages.accessGroupDetailFormInputRequired} />)
    .max(255, <FormattedMessage {...messages.accessGroupDetailFormInputNameMaxLength} />),
});
