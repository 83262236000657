import { IntlFormatters } from 'react-intl';
import { IAggregatedApartmentUsers } from '../apartmentAdmin/data';
import messages from './messages';
import { IApartmentIdsInfo } from './store';

export function getApartmentNumberAndName({ name, number, separator }: { name?: string; number: string, separator?: string }): string {
  if (name) {
    return `${number}${separator ?? ' — '}${name}`;
  }
  return number;
}

export function getApartmentContactLabel({
  formatMessage,
  labels,
}: {
  labels: string[];
  formatMessage: IntlFormatters['formatMessage'];
}): string {
  if (labels.length === 1) {
    return labels[0];
  }
  if (labels.length) {
    return formatMessage(messages.AAmodalApartmentDetailButtonLabelMultiple);
  }
  return formatMessage(messages.AAmodalApartmentDetailButtonLabelNone);
}

export function getApartmentIdsInfo(apartment: IAggregatedApartmentUsers): IApartmentIdsInfo {
  return {
    apartmentId: apartment.id,
    companyId: apartment.company.id,
    siteId: apartment.site.id,
  };
}
