import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useUrlParams } from '../../helpers/urlParams';
import actions, { selectors } from '../../modules/accessSettings';
import { selectors as devicesSelectors } from '../../modules/devices';
import AccessGroupsSettings from '../AccessGroupsSettings';
import messages from './messages';

const DeviceAccessSettings = ({ buttonAction }) => {
  const { formatMessage } = useIntl();
  const { deviceId } = useUrlParams();
  const dispatch = useDispatch();
  const { groups, hasApartment, isListInvalid, isRacActive } = {
    groups: useSelector(selectors.getDeviceAccessGroupsList),
    hasApartment: useSelector(devicesSelectors.isDeviceInApartment),
    isListInvalid: useSelector(selectors.isDeviceAccessGroupsListInvalid),
    isRacActive: useSelector(devicesSelectors.isDeviceRacActive),
  };

  useEffect(() => {
    dispatch(actions.getDeviceAccessGroupsList(deviceId));
  }, []);

  useEffect(() => {
    if (isListInvalid) {
      dispatch(actions.getDeviceAccessGroupsList(deviceId));
    }
  }, [isListInvalid]);

  return (
    <AccessGroupsSettings
      buttonAction={buttonAction}
      groups={groups}
      helperText={
        hasApartment
          ? formatMessage(messages.deviceAccessSettingsHelperTextWithApartment, { count: groups.length })
          : formatMessage(messages.deviceAccessSettingsHelperText, { count: groups.length })
      }
      isRacActive={groups.length > 0 || (!hasApartment && isRacActive)}
      noGroupsLabel={formatMessage(messages.deviceAccessSettingsAlertNoGroups)}
      racInactiveLabel={
        isRacActive
          ? formatMessage(messages.deviceAccessSettingsAlertRacActiveWithoutGroups)
          : formatMessage(messages.deviceAccessSettingsAlertRacInactive)
      }
    />
  );
};

DeviceAccessSettings.propTypes = {
  buttonAction: PropTypes.func.isRequired,
};

export default DeviceAccessSettings;
