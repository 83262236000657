import { IntlFormatters } from 'react-intl';
import * as Yup from 'yup';
import {
  PASSWORD_REGEXP,
  PASSWORD_REGEXP_DIGITS,
  PASSWORD_REGEXP_LENGTH,
  PASSWORD_REGEXP_LOWERCASE,
  PASSWORD_REGEXP_UPPERCASE,
} from '../config/consts';
import messages from './messages';

interface IPasswordCheck {
  noDigit: boolean;
  noLowercase: boolean;
  noRequiredLength: boolean;
  noUppercase: boolean;
}

export function getNewPasswordValidationSchema(formatMessage: IntlFormatters['formatMessage']) {
  return {
    password: Yup.string()
      .required(formatMessage(messages.registerPasswordRequired))
      .test({
        name: 'regex-password',
        test(value, context) {
          if (PASSWORD_REGEXP.test(value)) {
            return true;
          }
          const check = {
            noDigit: !PASSWORD_REGEXP_DIGITS.test(value),
            noLowercase: !PASSWORD_REGEXP_LOWERCASE.test(value),
            noRequiredLength: !PASSWORD_REGEXP_LENGTH.test(value),
            noUppercase: !PASSWORD_REGEXP_UPPERCASE.test(value),
          };
          return context.createError({
            message: getErrorMessage(check, formatMessage),
            path: 'password',
          });
        },
      }),
  };
}

function getErrorMessage(check: IPasswordCheck, formatMessage: IntlFormatters['formatMessage']): string {
  const errorMessages: string[] = [];

  if (check.noDigit) {
    errorMessages.push(formatMessage(messages.registerPasswordNoDigit));
  }

  if (check.noLowercase) {
    errorMessages.push(formatMessage(messages.registerPasswordNoLowercase));
  }

  if (check.noRequiredLength) {
    errorMessages.push(formatMessage(messages.registerPasswordNoRequiredLength));
  }

  if (check.noUppercase) {
    errorMessages.push(formatMessage(messages.registerPasswordNoUppercase));
  }

  return formatMessage(messages.registerPasswordNoValidAny) + errorMessages.join(', ');
}
