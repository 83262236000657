import { defineMessages } from 'react-intl';

export default defineMessages({
  mvideoAllYouNeedDescription: {
    defaultMessage:
      'We are not the only company on the market offering a mobile application for intercoms.' +
      ' However, our advantage is that <strong>apart from the intercom and your' +
      ' smartphone, you don’t need anything else</strong> to open a door,' +
      ' meaning no network gateways or hardware answering units.' +
      ' The My2N application is <strong>the' +
      ' least expensive video answering unit on the market</strong> and' +
      ' isn’t complicated to set up either.',
    description: '',
    id: 'mvideo.allYouNeed.description',
  },
  mvideoAllYouNeedTitle: {
    defaultMessage: 'All You Need Is an Intercom and a Mobile Phone',
    description: '',
    id: 'mvideo.allYouNeed.title',
  },
  mvideoAppsAndroidLink: {
    defaultMessage: 'Android phones and tablets',
    description: 'MVideo Apps - Android phones and tablets',
    id: 'mvideo.apps.androidLink',
  },
  mvideoAppsIosLink: {
    defaultMessage: 'iPhones and iPads',
    description: 'MVideo Apps - iPhones and iPads',
    id: 'mvideo.apps.iosLink',
  },
  mvideoAppsProductText: {
    defaultMessage:
      'My2N App calls are provided over the internet.' +
      ' You might be charged for data transfer fees according to your' +
      " internet provider's price list." +
      ' Smart phones with the operating system iOS 15 (and newer) or Android 8' +
      ' (and newer) are supported.',
    description: 'MVideo Apps - product text',
    id: 'mvideo.apps.productText',
  },
  mvideoAppsTitle: {
    defaultMessage: 'The My2N App is available on',
    description: 'MVideo Apps - title',
    id: 'mvideo.apps.title',
  },
  mvideoCreateAccount: {
    defaultMessage: 'Create My2N account',
    description: 'MVideo - create account button',
    id: 'mvideo.createAccount',
  },
  mvideoFeaturesFour: {
    defaultMessage: 'No need for network setup',
    id: 'mvideo.features.four',
  },
  mvideoFeaturesOne: {
    defaultMessage: "See who's ringing at your door",
    description: 'MVideo Features - first item',
    id: 'mvideoFeaturesOne',
  },
  mvideoFeaturesThree: {
    defaultMessage: 'Check out any missed calls',
    description: 'MVideo Features - third item',
    id: 'mvideo.features.three',
  },
  mvideoFeaturesTitle: {
    defaultMessage: 'Keep up with your visitors',
    description: 'MVideo Features - title',
    id: 'mvideo.features.title',
  },
  mvideoFeaturesTwo: {
    defaultMessage: 'Open the door remotely with one touch',
    description: 'MVideo Features - second item',
    id: 'mvideo.featuresTwo',
  },
  mvideoHowToStartUsingDescription1: {
    defaultMessage: 'Cloud calling from intercom to smartphone is a subscription-based service.*',
    id: 'mvideo.howToStartUsing.description1',
  },
  mvideoHowToStartUsingDescription2: {
    defaultMessage: 'Take advantage of a 30-Day Free Trial which includes smartphones.',
    id: 'mvideo.howToStartUsing.description2',
  },
  mvideoHowToStartUsingFooterDescription1: {
    defaultMessage:
      '* You don’t need a subscription for any 2N device except for 2N® IP Handset and' +
      ' 2N® IP Phone D7A. Subscriptions are needed for mobile phones and 3rd-party devices.' +
      ' ** Apartment = a living unit. This option allows you to use 4 mobile' +
      ' phones per apartment.',
    description: '',
    id: 'mvideo.howToStartUsing.footer.description1',
  },
  mvideoHowToStartUsingFooterDescription2: {
    defaultMessage:
      'The information regarding prices on this website is merely for informational purposes.' +
      ' It does not constitute a binding offer, a proposal to conclude a contract, or' +
      ' any other action leading to creating, changing, or terminating a legal' +
      ' relationship. Nor does it constitute a pre-contractual obligation on' +
      ' the part of 2N TELEKOMUNIKACE a.s.',
    description: '',
    id: 'mvideo.howToStartUsing.footer.description2',
  },
  mvideoHowToStartUsingPurchaseFromDistributorChip1: {
    defaultMessage: 'price per mobile phone for 1 month*',
    description: '',
    id: 'mvideo.howToStartUsing.purchaseFromDistributor.chip1',
  },
  mvideoHowToStartUsingPurchaseFromDistributorChip2: {
    defaultMessage: 'price per apartment for 1 month**',
    description: '',
    id: 'mvideo.howToStartUsing.purchaseFromDistributor.chip2',
  },
  mvideoHowToStartUsingPurchaseFromDistributorDescription: {
    defaultMessage: 'Long-term subscriptions and large-scale projects',
    description: '',
    id: 'mvideo.howToStartUsing.purchaseFromDistributor.description',
  },
  mvideoHowToStartUsingPurchaseFromDistributorFindDistributor: {
    defaultMessage: 'Find distributor',
    description: '',
    id: 'mvideo.howToStartUsing.purchaseFromDistributor.findDistributor',
  },
  mvideoHowToStartUsingPurchaseFromDistributorPaymentDescription: {
    defaultMessage:
      'Plans and payments are defined in individual contracts with distributors.' +
      ' This option is also available to customers who do not use credit cards.',
    description: '',
    id: 'mvideo.howToStartUsing.purchaseFromDistributor.paymentDescription',
  },
  mvideoHowToStartUsingPurchaseFromDistributorPaymentMethod: {
    defaultMessage: 'Payment method',
    description: '',
    id: 'mvideo.howToStartUsing.purchaseFromDistributor.paymentMethod',
  },
  mvideoHowToStartUsingPurchaseFromDistributorSuitable: {
    defaultMessage: 'Suitable for',
    description: '',
    id: 'mvideo.howToStartUsing.purchaseFromDistributor.suitable',
  },
  mvideoHowToStartUsingPurchaseFromDistributorSuitableDescription: {
    defaultMessage:
      'System integrators or resellers looking for flexible options, bulk' +
      ' discounts, and special plans. Please note that you can only choose one' +
      ' type of subscription per site.',
    description: '',
    id: 'mvideo.howToStartUsing.purchaseFromDistributor.suitableDescription',
  },
  mvideoHowToStartUsingPurchaseFromDistributorTitle: {
    defaultMessage: 'PURCHASE FROM 2N DISTRIBUTOR',
    description: '',
    id: 'mvideo.howToStartUsing.purchaseFromDistributor.title',
  },
  mvideoHowToStartUsingSubscribeOnlineBasic: {
    defaultMessage: 'Basic',
    description: '',
    id: 'mvideo.howToStartUsing.subscribeOnline.basic',
  },
  mvideoHowToStartUsingSubscribeOnlineChip: {
    defaultMessage: '1 mobile phone for 1 month*',
    description: '',
    id: 'mvideo.howToStartUsing.subscribeOnline.chip',
  },
  mvideoHowToStartUsingSubscribeOnlineCreateAccount: {
    defaultMessage: 'Create My2N Account',
    description: '',
    id: 'mvideo.howToStartUsing.subscribeOnline.createAccount',
  },
  mvideoHowToStartUsingSubscribeOnlinePaymentMethod: {
    defaultMessage: 'Payment method',
    description: '',
    id: 'mvideo.howToStartUsing.subscribeOnline.paymentMethod',
  },
  mvideoHowToStartUsingSubscribeOnlinePaymentMethodDescription: {
    defaultMessage: 'Monthly or yearly recurring payment using a credit card (Visa, Mastercard)',
    description: '',
    id: 'mvideo.howToStartUsing.subscribeOnline.paymentMethodDescription',
  },
  mvideoHowToStartUsingSubscribeOnlinePrice: {
    defaultMessage: '<sup>€</sup><strong>2</strong><sub>(or $ 2,2)</sub>',
    description: '',
    id: 'mvideo.howToStartUsing.subscribeOnline.price',
  },
  mvideoHowToStartUsingSubscribeOnlinePriceDescription: {
    defaultMessage: '€ 2,42 (or 2,66 USD) including VAT',
    description: '',
    id: 'mvideo.howToStartUsing.subscribeOnline.priceDescription',
  },
  mvideoHowToStartUsingSubscribeOnlineSuitable: {
    defaultMessage: 'Suitable for',
    description: '',
    id: 'mvideo.howToStartUsing.subscribeOnline.suitable',
  },
  mvideoHowToStartUsingSubscribeOnlineSuitableDescription: {
    defaultMessage:
      'Homeowners who want to provide secure and convenient access for their' +
      ' residents. This option is also recommended to integrators who install' +
      ' the service for end-users.',
    description: '',
    id: 'mvideo.howToStartUsing.subscribeOnline.suitableDescription',
  },
  mvideoHowToStartUsingSubscribeOnlineTitle: {
    defaultMessage: 'SUBSCRIBE ONLINE ON MY2N.COM',
    description: '',
    id: 'mvideo.howToStartUsing.subscribeOnline.title',
  },
  mvideoHowToStartUsingTitle: {
    defaultMessage: 'How to start using the My2N App',
    description: '',
    id: 'mvideo.howToStartUsing.title',
  },
  mvideoIntegration3rdPartyDescription: {
    defaultMessage:
      'One of the biggest advantages of 2N cloud services is that' +
      ' it is a <strong>professional VoIP platform</strong>. Its cloud PBX <strong>solves more' +
      ' complex scenarios</strong> than just call forwarding or remote concierge' +
      ' service. The smart doorbell app – My2N - and its connected' +
      ' cloud service supports SIP and is <strong>compatible with all major' +
      ' 3rd party smart home apps and devices</strong>.',
    description: '',
    id: 'mvideo.integration3rdParty.description',
  },
  mvideoIntegration3rdPartyTitle: {
    defaultMessage: 'Integration with 3rd party smart home apps',
    description: '',
    id: 'mvideo.integration3rdParty.title',
  },
  mvideoJustFourStepsCallingOptionsDescription: {
    defaultMessage:
      'Which intercom buttons should call on which smartphones? Let the intercom know whom it is supposed to ring.',
    description: '',
    id: 'mvideo.justFourSteps.callingOptions.description',
  },
  mvideoJustFourStepsCallingOptionsTitle: {
    defaultMessage: 'Set up the calling options',
    description: '',
    id: 'mvideo.justFourSteps.callingOptions.title',
  },
  mvideoJustFourStepsCreateAccountDescription: {
    defaultMessage: 'Use your PC, laptop or tablet and register with My2N.',
    description: '',
    id: 'mvideo.justFourSteps.createAccount.description',
  },
  mvideoJustFourStepsCreateAccountTitle: {
    defaultMessage: 'Create My2N account',
    description: '',
    id: 'mvideo.justFourSteps.createAccount.title',
  },
  mvideoJustFourStepsDownloadDescription: {
    defaultMessage:
      'Install our mobile application on your device, using the credentials we will send to your e-mail address.',
    description: '',
    id: 'mvideo.justFourSteps.download.description',
  },
  mvideoJustFourStepsDownloadTitle: {
    defaultMessage: 'Download the My2N App',
    description: '',
    id: 'mvideo.justFourSteps.download.title',
  },
  mvideoJustFourStepsIntercomDescription: {
    defaultMessage: 'Set which intercom you want to call from and which devices you wish to call to.',
    description: '',
    id: 'mvideo.justFourSteps.intercom.description',
  },
  mvideoJustFourStepsIntercomTitle: {
    defaultMessage: 'Add your intercom and smartphone',
    description: '',
    id: 'mvideo.justFourSteps.intercom.title',
  },
  mvideoJustFourStepsTitle: {
    defaultMessage: 'Just <strong>4</strong> steps to set up',
    description: '',
    id: 'mvideo.justFourSteps.title',
  },
  mvideoLandingHeaderSubtitle: {
    defaultMessage: 'with My2N App{br}on your smartphone',
    description: '',
    id: 'mvideo.landingHeader.subtitle',
  },
  mvideoLandingHeaderTitle: {
    defaultMessage: "Control your door even{br}when you're not home",
    description: '',
    id: 'mvideo.landingHeader.title',
  },
  mvideoLandingInfoServicesInfo01: {
    defaultMessage: 'Reliable and secure cloud service',
    description: '',
    id: 'mvideo.landingInfo.services.info01',
  },
  mvideoLandingInfoServicesInfo02: {
    defaultMessage: 'All you need is an intercom and a mobile phone',
    description: '',
    id: 'mvideo.landingInfo.services.info02',
  },
  mvideoLandingInfoServicesInfo03: {
    defaultMessage: 'Real-time synchronization',
    description: '',
    id: 'mvideo.landingInfo.services.info03',
  },
  mvideoLandingInfoServicesInfo04: {
    defaultMessage: 'Integration with 3rd party smart home apps',
    description: '',
    id: 'mvideo.landingInfo.services.info04',
  },
  mvideoLandingInfoTitle: {
    defaultMessage: 'Why is the My2N App special in the world of intercom apps?',
    description: '',
    id: 'mvideo.landingInfo.title',
  },
  mvideoRealTimeSynchronizationDescription: {
    defaultMessage: 'With My2N you get things done faster and more comfortably.',
    description: '',
    id: 'mvideo.realTimeSynchronization.description',
  },
  mvideoRealTimeSynchronizationText: {
    defaultMessage:
      'All changes made in My2N platform are <strong>synchronized with your device real-time</strong>.' +
      ' Any large residential project is thus managed effectively.' +
      ' You can effortlessly maintain installations with up to' +
      ' <strong>3000 devices in one Site</strong> and add or remove users on-demand.',
    description: '',
    id: 'mvideo.realTimeSynchronization.text',
  },
  mvideoRealTimeSynchronizationTitle: {
    defaultMessage: 'Real-time synchronization of each device',
    description: '',
    id: 'mvideo.realTimeSynchronization.title',
  },
  mvideoSecuredCloudDescription: {
    defaultMessage:
      'While managing thousands of intercom mobile applications for on-site' +
      ' residents, you probably expect a massively' +
      " tested and quality-approved solution. Well, there's no doubt about that" +
      ' with 2N intercom app. Our professional' +
      ' monitoring of world-wide VoIP situation and a significant amount of test automation' +
      ' results in <strong>99.98% test call success ratio</strong>.',
    description: '',
    id: 'mvideo.securedCloud.description',
  },
  mvideoSecuredCloudQuestion: {
    defaultMessage:
      'Any issue with the service? Just contact our online support for millions of' +
      ' connected devices. Our 360° approach to cybersecurity ensures that your' +
      ' connected devices and personal data are absolutely safe.',
    description: '',
    id: 'mvideo.securedCloud.question',
  },
  mvideoSecuredCloudText: {
    defaultMessage:
      'Cloud calling via the My2N App is a <strong>top performing service with' +
      ' amazing speed and architecture based on load-balanced microservices</strong>.',
    description: '',
    id: 'mvideo.securedCloud.text',
  },
  mvideoSecuredCloudTitle: {
    defaultMessage: 'Reliable and secure cloud service',
    description: '',
    id: 'mvideo.securedCloud.title',
  },
  mvideoServiceDescription: {
    defaultMessage: `Try your 30-Day Free Trial of the My2N App. There is
        no commitment {br} to purchase the service after the trial expires.`,
    description: 'MVideo Service - description',
    id: 'mvideo.service.description',
  },
  mvideoServiceTitle: {
    defaultMessage: 'Get started with the My2N App',
    description: 'MVideo Service - title',
    id: 'mvideo.service.title',
  },
});
