import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { SiteWithAcCredentialsSettingsResponse } from '../../../../apiRtk/be4feApi';
import { My2NAppAccessModal } from '../../../../components';
import { useModal } from '../../../../hooks/useModal';
import * as siteSelectors from '../../../../modules/sites/selectors';
import { getUserMy2NAppAccessEnabledStatus } from '../../../../modules/user/selectors';
import { IUser } from '../../../../modules/user/store';
import messages from './messages';

function EnableMy2NAppAccessButton({ user }: { user: IUser }) {
  const { Modal: My2NAppAccessUModal, onOpen: onOpenMy2NAppAccessModal } = useModal({
    Modal: My2NAppAccessModal,
  });

  const isEnabled = useSelector(getUserMy2NAppAccessEnabledStatus);
  const siteData: SiteWithAcCredentialsSettingsResponse = useSelector(siteSelectors.getSiteData);
  const siteBle = siteData?.services?.ACCESS_CONTROL?.bluetoothEnabled;

  return (
    <>
      <Button
        data-testid="enableMy2NAppAccessButton"
        disabled={!siteBle || isEnabled}
        onClick={onOpenMy2NAppAccessModal}
        startIcon={<AddIcon />}
        variant="text"
      >
        <FormattedMessage {...messages.enableMy2NAppAccessAddButtonLabel} />
      </Button>
      <My2NAppAccessUModal context="SITE_USER_DETAIL" onActivate={() => false} user={user} />
    </>
  );
}

export default EnableMy2NAppAccessButton;
