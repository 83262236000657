import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

export function useGetGlobalHooks() {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  return {
    dispatch,
    formatMessage,
  };
}
