import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { canUserDelete } from '../../helpers/user';
import { useModal } from '../../hooks/useModal';
import { CONTEXT } from '../../modules/user';
import UserDeleteModal from '../UserDeleteModal';
import messages from './messages';

const styles = {
  removeButton: {
    '&:hover': {
      color: 'secondary.main',
    },
  },
};

const RemoveUser = ({ context, currentUser, siteIdsInfo, user }) => {
  const { Modal: UserDeleteUModal, onOpen: onOpenUserDeleteUModal } = useModal({
    Modal: UserDeleteModal,
  });

  return (
    <>
      <Box sx={{ display: { lg: 'block', xs: 'none' } }}>
        <Button
          color="secondary"
          disabled={!canUserDelete(user, currentUser, context, siteIdsInfo)}
          onClick={onOpenUserDeleteUModal}
          startIcon={<DeleteIcon />}
          sx={styles.removeButton}
        >
          <FormattedMessage {...messages.removeUserRemoveButton} />
        </Button>
      </Box>
      <Box sx={{ display: { lg: 'none', xs: 'block' } }}>
        <IconButton
          color="secondary"
          disabled={!canUserDelete(user, currentUser, context, siteIdsInfo)}
          onClick={onOpenUserDeleteUModal}
          sx={styles.removeButton}
        >
          <DeleteIcon />
        </IconButton>
      </Box>
      <UserDeleteUModal context={CONTEXT.SITE_USERS} user={user} />
    </>
  );
};

RemoveUser.propTypes = {
  context: PropTypes.oneOf([CONTEXT.SITE_USERS, CONTEXT.COMPANY_ADMINS]),
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  siteIdsInfo: PropTypes.shape({
    companyId: PropTypes.number,
    siteId: PropTypes.number,
  }),
  user: PropTypes.shape({
    firstName: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    lastName: PropTypes.string,
    role: PropTypes.string,
  }).isRequired,
};

RemoveUser.defaultProps = {
  context: CONTEXT.SITE_USERS,
};

export default RemoveUser;
