import { PAYMENT_STATUS } from '../../config/payments';

export const getPaymentStatusStyles = (status, styles) => {
  if (typeof styles !== 'object' || !styles.danger || !styles.warning || !styles.success) {
    throw new Error('No property danger or warning or success in styles object.');
  }
  switch (status) {
    case PAYMENT_STATUS.PAID:
    case PAYMENT_STATUS.COMPLETED:
    case PAYMENT_STATUS.DELIVERED:
    case PAYMENT_STATUS.INVOICE_ISSUED:
    case PAYMENT_STATUS.BEFORE_COMPLETION:
      return styles.success;
    case PAYMENT_STATUS.FAILED:
      return styles.danger;
    case PAYMENT_STATUS.REFUNDED:
    case PAYMENT_STATUS.CANCELLED:
    case PAYMENT_STATUS.CREATED:
      return styles.warning;
    default:
      return '';
  }
};
