import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import CustomDate from '../../CustomDate';
import messages from '../messages';
import { AccessLogPropType } from '../type';
import AccessLogResultIcon from './AccessLogResultIcon';

const AccessLogStartTime = ({ accessLog, displayTime }) => (
  <Box sx={{ alignItems: 'center', display: 'inline-flex' }}>
    <AccessLogResultIcon accessLog={accessLog} />
    {displayTime && (
      <Tooltip
        placement="right"
        title={
          <FormattedMessage
            {...messages.columnsDeviceTime}
            values={{
              br: <br />,
              time: (
                <CustomDate dateValue={accessLog.startAt} showTimeZone timezoneShift={accessLog.device.timezoneShift} />
              ),
            }}
          />
        }
      >
        <Box component="span" sx={{ ml: 1 }}>
          <CustomDate dateValue={accessLog.startAt} isTechnicalRecord />
        </Box>
      </Tooltip>
    )}
  </Box>
);

AccessLogStartTime.propTypes = {
  accessLog: AccessLogPropType.isRequired,
  displayTime: PropTypes.bool.isRequired,
};

export default AccessLogStartTime;
