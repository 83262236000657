import { OryLoginStatus } from '../../ory/types';
import types from './types';

export const setFirstLogin = (value: boolean) => ({ type: types.SET_FIRST_LOGIN, value });
export const loginRequest = (values: { email: string; password: string; onUserIsInOry: () => void }) => ({
  type: types.LOGIN_REQUEST,
  values,
});
export const setOryLoginStatus = (values: OryLoginStatus) => ({ type: types.SET_ORY_LOGIN_STATUS, values });
export const setAuthToken = (values: { accessToken: string; preferUserLogin: boolean }) => ({
  accessToken: values.accessToken,
  preferUserLogin: values.preferUserLogin,
  type: types.SET_AUTH_TOKEN,
});
export const setPreferUserLogin = (values: { preferUserLogin: boolean }) => ({
  type: types.SET_PREFER_USER_LOGIN,
  values,
});
export const registerRequest = (values: any) => ({ type: types.REGISTER_REQUEST, values });
export const resetPasswordRequest = (values: { email: string; onUserIsInOry: () => void }) => ({
  type: types.RESET_PASSWORD_REQUEST,
  values,
});
export const resendConfirmationEmailRequest = (values: { email: string }) => ({
  type: types.RESEND_INVITATION_EMAIL_REQUEST,
  values,
});
export const resetAuthStore = () => ({
  type: types.LOGIN_RESET,
});
export const logout = () => ({ type: types.LOGOUT });
