import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const childrenStyle = {
  '& ul': {
    my: 1,
    px: 4,
    typography: 'body1',
  },
  p: 0,
};

const LicenseAccordion = ({ children, description, title }) => (
  <Accordion elevation={0}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ p: 0 }}>
      <Box>
        <Typography fontWeight="bold">{title}</Typography>
        {description && <Typography variant="body2">{description}</Typography>}
      </Box>
    </AccordionSummary>
    <AccordionDetails sx={childrenStyle}>{children}</AccordionDetails>
  </Accordion>
);

LicenseAccordion.propTypes = {
  children: PropTypes.node.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

LicenseAccordion.defaultProps = {
  description: null,
};

export default LicenseAccordion;
