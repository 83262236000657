import { defineMessages } from 'react-intl';

export default defineMessages({
  siteUsersImportUploadStepButtonLabel: {
    defaultMessage: 'Select data file',
    description: 'Site users import - upload step button label',
    id: 'siteUsersImportUploadStepButtonLabel',
  },
  siteUsersImportUploadStepFileHelperText: {
    defaultMessage: 'Supported formats: XLS, XLSX, CSV',
    description: 'Site users import - upload step button helper text',
    id: 'siteUsersImportUploadStepFileHelperText',
  },
  siteUsersImportUploadStepFileNotSelected: {
    defaultMessage: 'No file selected',
    description: 'Site users import - upload step not selected message',
    id: 'siteUsersImportUploadStepFileNotSelected',
  },
  siteUsersImportUploadStepInfo: {
    defaultMessage: 'The data will appear once you upload it or copy and paste it from a spreadsheet.',
    description: 'Site users import - upload step info',
    id: 'siteUsersImportUploadStepInfo',
  },
  siteUsersImportUploadStepInfoAlert: {
    defaultMessage: 'You can edit your data before the import.',
    description: 'Site users import - upload step info alert',
    id: 'siteUsersImportUploadStepInfoAlert',
  },
  siteUsersImportUploadStepManualImportPlaceholder: {
    defaultMessage: 'Paste spreadsheet data',
    description: 'Site users import - upload step manual import placeholder',
    id: 'siteUsersImportUploadStepManualImportPlaceholder',
  },
});
