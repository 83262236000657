import React from 'react';
import { ApartmentUser } from '../../../../../../../modules/apartmentAdmin/data';
import { AAUserInfo } from '../../../../../components/AAUserInfo';
import { UWLName } from '../../../../../components/UWLName';
import { isUWLUser } from '../../../../../utils/UWLUserType';

export interface IAAUserNameProps {
  user: ApartmentUser;
}

export function AAUserName({ user }: IAAUserNameProps) {
  if (isUWLUser(user)) {
    return <UWLName name={{ firstName: user.firstName, lastName: user.lastName }} />;
  }
  return <AAUserInfo user={user} />;
}
