import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import React, { useCallback, useEffect } from 'react';
import actions from '../../modules/sites';
import { Variant } from '../_DesignSystemWrappers/designSystemUnexported';
import GenericModal from '../_DesignSystemWrappers/Modal/GenericModal';
import getPrimaryButton from './getPrimaryButton';
import useGetGlobalHooks from './hooks/getGlobalHooks';
import useGetSelectSiteTypeData from './hooks/getSelectSiteTypeData';
import messages from './messages';
import ResultMessage from './ResultMessage';
import SelectedItemType from './SelectedItemType';
import SelectSiteType from './SelectSiteType';
import { ISelectSiteTypeModal } from './types';

const SelectSiteTypeModal = ({ company, onClose, site }: ISelectSiteTypeModal) => {
  const hooks = useGetGlobalHooks();
  const { error, isSubmit } = useGetSelectSiteTypeData();

  const handleSetType = useCallback(() => {
    hooks.dispatch(actions.setTypeSite(hooks.siteType, site));
  }, [hooks.siteType, site]);

  function closeModal(onClose: () => void): void {
    if (hooks.step === 3) {
      hooks.dispatch(actions.finishSetSiteType());
    }
    onClose();
  }

  const handleClickBack = () => hooks.setStep((prevState) => Math.max(prevState - 1, 1));
  const handleClickNext = () => hooks.setStep((prevState) => Math.min(prevState + 1, 3));

  const primaryButton = getPrimaryButton(
    hooks.step,
    isSubmit,
    hooks.siteType,
    () => closeModal(onClose),
    handleClickNext,
    hooks.formatMessage,
    handleSetType
  );
  const secondaryButton =
    hooks.step === 3
      ? undefined
      : {
          action: onClose,
          text: hooks.formatMessage(messages.SelectSiteTypeModalCancelBtn),
          variant: 'text' as Variant,
        };

  useEffect(() => {
    if (isSubmit && hooks.step !== 3) {
      hooks.setStep(3);
    }
  }, [isSubmit]);

  return (
    <GenericModal
      onClose={() => closeModal(onClose)}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      ternaryButton={{
        action: handleClickBack,
        color: 'secondary',
        icon: {
          icon: <ArrowBackIcon />,
          iconPosition: 'start',
        },
        isDisabled: isSubmit || hooks.step === 1,
        text: hooks.formatMessage(messages.SelectSiteTypeModalBackBtn),
      }}
      title={hooks.formatMessage(messages.SelectSiteTypeModalTitle)}
      testId="select-site-type-modal"
    >
      {hooks.step === 1 && (
        <SelectSiteType
          company={company}
          name="siteType"
          onChange={(_, type) => hooks.setSiteType(type)}
          site={site}
          value={hooks.siteType}
        />
      )}
      {hooks.step === 2 && <SelectedItemType company={company} site={site} siteType={hooks.siteType} />}
      {hooks.step === 3 && <ResultMessage company={company} isSuccess={!error} site={site} onCloseModal={onClose} />}
    </GenericModal>
  );
};

export default SelectSiteTypeModal;
