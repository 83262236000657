export const moduleName = 'notifications';

export const TYPE_TEXT = 'text';
export const TYPE_CODE = 'code';
export const TYPE_DESCRIPTOR = 'descriptor';

export default {
  ADD_SNACKBAR: `${moduleName}/ADD_SNACKBAR`,
  REMOVE_SNACKBAR: `${moduleName}/REMOVE_SNACKBAR`,
};
