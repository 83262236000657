import { defineMessages } from 'react-intl';

export default defineMessages({
  destinationSelectItem: {
    defaultMessage: '<typography>{shortName}</typography> {destinationArea}',
    description: 'Destination select item text',
    id: 'destinationSelectSelectItem',
  },
  elevatorIntegrationMappingDestinationAreaNull: {
    defaultMessage: 'No destination',
    id: 'elevatorIntegration.mapping.destinationArea.null',
  },
});
