import { IReduxAction } from '../reduxTypes';

export interface IInfrastructureStore {
  redirectData?: IRedirectData;
}

export interface IRedirectData {
  to: string;
  data?: object;
  action?: IReduxAction<any>[];
  locationSubRoutesCheck?: string[];
}

export function getInitialInfrastructureStore(): IInfrastructureStore {
  return {
    redirectData: undefined,
  };
}
