import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import * as Api from '../../api/subscriptionApi';
import { selectors as companySelectors } from '../company';
import { errorHelper, responseValidateStatusOrThrow } from '../helpers';
import { addSnackbarSuccess } from '../notifications/actions';
import { selectors as sitesSelectors } from '../sites';
import sitesTypes from '../sites/types';
import { selectors as subscriptionSelectors } from './index';
import messages from './messages';
import types from './types';

export function* createLicenseInvoicePayment() {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const purchaseId = yield select(subscriptionSelectors.getDeviceLicensePurchaseId);
    const response = yield call(Api.createInvoicePayment, companyId, purchaseId, {
      id: purchaseId,
    });
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.CREATE_LICENSE_INVOICE_PAYMENT_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.CREATE_LICENSE_INVOICE_PAYMENT_FAILURE, error));
  }
}

export function* getSerialNumberLicenses({ serialNumber }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const response = yield call(Api.getSerialNumberLicenses, companyId, serialNumber);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({ payload: data, type: types.GET_SERIAL_NUMBER_LICENSES_SUCCES });
  } catch (error) {
    yield put(errorHelper(types.GET_SERIAL_NUMBER_LICENSES_FAILURE, error));
  }
}

export function* getPayments() {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const purchaseId = yield select(subscriptionSelectors.getDeviceLicensePurchaseId);
    const response = yield call(Api.getPayments, companyId, purchaseId);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({ payload: data, type: types.GET_PAYMENTS_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.GET_PAYMENTS_FAILURE, error));
  }
}

export function* setSerialNumberLicenses({ licensesData }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const response = yield call(Api.setPurchase, companyId, licensesData);
    yield responseValidateStatusOrThrow(response);
    const {
      data,
      headers: { location },
    } = response;
    yield put({ payload: { ...data, location }, type: types.SET_SERIAL_NUMBER_LICENSES_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.SET_SERIAL_NUMBER_LICENSES_FAILURE, error));
  }
}

export function* setPayments({ paymentsData }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const purchaseId = yield select(subscriptionSelectors.getDeviceLicensePurchaseId);
    const response = yield call(Api.setPayments, companyId, purchaseId, paymentsData);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({ payload: data, type: types.SET_PAYMENTS_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.SET_PAYMENTS_FAILURE, error));
  }
}

export function* setPromoCodesPayments({ paymentsData }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const purchaseId = yield select(subscriptionSelectors.getPromoCodesOrderId);
    const response = yield call(Api.setPayments, companyId, purchaseId, paymentsData);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({ payload: data, type: types.SET_PROMO_CODES_ORDER_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.SET_PROMO_CODES_ORDER_FAILURE, error));
  }
}

export function* deleteOrderLicense({ licenseId }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const purchaseId = yield select(subscriptionSelectors.getDeviceLicensePurchaseId);
    const response = yield call(Api.deleteOrderLicense, companyId, purchaseId, licenseId);
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.DELETE_ORDER_LICENSE_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.DELETE_ORDER_LICENSE_FAILURE, error));
  }
}

export function* getMobileVideoPricesRequest({ filterMonth, filterYear }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const response = yield call(Api.getMobileVideoPricesRequest, companyId, filterMonth, filterYear);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;

    yield put({
      payload: data,
      type: types.GET_MOBILE_VIDEO_LICENSE_PRICES_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_MOBILE_VIDEO_LICENSE_PRICES_FAILURE, error));
  }
}

export function* getMobileVideoSubscriptionRequest() {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(sitesSelectors.getSiteId);
    const response = yield call(Api.getMobileVideoSubscriptionRequest, companyId, siteId);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;

    yield put({
      payload: data,
      type: types.GET_MOBILE_VIDEO_LICENSE_SUCCESS,
    });
  } catch (error) {
    yield put(errorHelper(types.GET_MOBILE_VIDEO_LICENSE_FAILURE, error));
  }
}

export function* useAvailableCreditRequest() {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(sitesSelectors.getSiteId);
    const action = { action: 'CONSUME' };
    const response = yield call(Api.updateMvLicence, companyId, siteId, action);
    yield responseValidateStatusOrThrow(response);
    yield put({ type: sitesTypes.INVALIDATE_SITE });
    yield put({ type: sitesTypes.INVALIDATE_SITE_DASHBOARD });
  } catch (error) {
    yield put(errorHelper(types.GET_MOBILE_VIDEO_LICENSE_FAILURE, error));
  }
}

export function* setMobileVideoProduct({ mobileVideoData }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const response = yield call(Api.setPurchase, companyId, mobileVideoData);
    yield responseValidateStatusOrThrow(response);
    const {
      data,
      headers: { location },
    } = response;
    yield put({ payload: { ...data, location }, type: types.SET_MOBILE_VIDEO_PRODUCT_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.SET_MOBILE_VIDEO_PRODUCT_FAILURE, error));
  }
}

export function* paymentResultFromSocket({ data }) {
  if (data && data.purchasePaymentResult) {
    const result = {
      paymentResult: data.purchasePaymentResult.paymentResult,
      purchaseState: data.purchaseState,
    };
    yield put({ payload: result, type: types.SET_PAYMENT_RESULT });
  }
}

export function* verifyActivationCode({ code }) {
  let response;
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(sitesSelectors.getSiteId);
    response = yield call(Api.verifyActivationCode, companyId, siteId, code);
    yield responseValidateStatusOrThrow(response);
    yield put({ code, payload: response.data, type: types.VERIFY_ACTIVATION_CODE_SUCCESS });
  } catch (error) {
    if ([410, 404].includes(response.status)) {
      yield put({ error: response, type: types.VERIFY_ACTIVATION_CODE_ERROR });
    } else {
      yield put(errorHelper(types.VERIFY_ACTIVATION_CODE_FAILURE, response.data));
    }
  }
}

export function* activateActivationCode() {
  let response;
  try {
    const code = yield select(subscriptionSelectors.getVerifiedCode);
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(sitesSelectors.getSiteId);
    response = yield call(Api.activateActivationCode, companyId, siteId, code);
    yield responseValidateStatusOrThrow(response);
    yield put({ payload: response.data, type: types.ACTIVATE_ACTIVATION_CODE_SUCCESS });
  } catch (error) {
    yield put({ error: response, type: types.ACTIVATE_ACTIVATION_CODE_ERROR });
  }
}

export function* deactivateRecurringPayment() {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(sitesSelectors.getSiteId);
    const action = { action: 'UNSUBSCRIBE' };
    const response = yield call(Api.updateMvLicence, companyId, siteId, action);
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.DEACTIVATE_RECURRING_PAYMENT_SUCCESS });
    yield put({ type: sitesTypes.DEACTIVATE_MV_RECURRING_PAYMENT });
    yield put(addSnackbarSuccess(messages.subscriptionSnackbarMVSubscriptionDeactivated));
  } catch (error) {
    yield put(errorHelper(types.DEACTIVATE_RECURRING_PAYMENT_FAILURE, error));
  }
}

export function* activateMobileVideo() {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const siteId = yield select(sitesSelectors.getSiteId);
    const action = { action: 'ACTIVATE' };
    const response = yield call(Api.updateMvLicence, companyId, siteId, action);
    yield responseValidateStatusOrThrow(response);
    yield put({ type: sitesTypes.INVALIDATE_SITE });
    yield put({ type: types.ACTIVATE_MOBILE_VIDEO_LICENSE_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.ACTIVATE_MOBILE_VIDEO_LICENSE_FAILURE, error));
  }
}

export function* getVatVerification() {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const response = yield call(Api.getVatVerification, companyId);
    if ([409].includes(response?.status)) {
      throw new Error();
    }
    yield put({ type: types.GET_VAT_VERIFICATION_SUCCES });
  } catch {
    yield put({ type: types.GET_VAT_VERIFICATION_ERROR });
  }
}

export function* getPromoCodes() {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const response = yield call(Api.getPromoCodes, companyId);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({ payload: data, type: types.GET_PROMO_CODES_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.GET_PROMO_CODES_FAILURE, error));
  }
}

export function* setPromoCodesOrder({ promoCodeData }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const response = yield call(Api.setPurchase, companyId, promoCodeData);
    yield responseValidateStatusOrThrow(response);
    const {
      data,
      headers: { location },
    } = response;
    yield put({ payload: { ...data, location }, type: types.SET_PROMO_CODES_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.SET_PROMO_CODES_FAILURE, error));
  }
}

export function* getPromoCodesOrderContent() {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const purchaseId = yield select(subscriptionSelectors.getPromoCodesOrderId);
    const response = yield call(Api.getPayments, companyId, purchaseId);
    yield responseValidateStatusOrThrow(response);
    const { data } = response;
    yield put({ payload: data, type: types.GET_PROMO_CODES_ORDER_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.GET_PROMO_CODES_ORDER_FAILURE, error));
  }
}

export function* deletePromoOrderContent({ id }) {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const purchaseId = yield select(subscriptionSelectors.getPromoCodesOrderId);
    const response = yield call(Api.deletePromoOrderContent, companyId, purchaseId, id);
    yield responseValidateStatusOrThrow(response);
    yield getPromoCodesOrderContent();
    yield put({ type: types.DELETE_PROMO_CODES_ORDER_CONTENT_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.DELETE_PROMO_CODES_ORDER_CONTENT_FAILURE, error));
  }
}

export function* createPromoInvoicePayment() {
  try {
    const companyId = yield select(companySelectors.getCompanyId);
    const purchaseId = yield select(subscriptionSelectors.getPromoCodesOrderId);
    const response = yield call(Api.createInvoicePayment, companyId, purchaseId, {
      id: purchaseId,
    });
    yield responseValidateStatusOrThrow(response);
    yield put({ type: types.CREATE_PROMO_INVOICE_PAYMENT_SUCCESS });
  } catch (error) {
    yield put(errorHelper(types.CREATE_PROMO_INVOICE_PAYMENT_FAILURE, error));
  }
}

export default function* subscriptionSagas() {
  yield all([
    takeEvery(types.GET_SERIAL_NUMBER_LICENSES_REQUEST, getSerialNumberLicenses),
    takeEvery(types.SET_SERIAL_NUMBER_LICENSES_REQUEST, setSerialNumberLicenses),
    takeEvery(types.CREATE_LICENSE_INVOICE_PAYMENT_REQUEST, createLicenseInvoicePayment),
    takeEvery(types.SET_PAYMENTS_REQUEST, setPayments),
    takeEvery(types.DELETE_ORDER_LICENSE_REQUEST, deleteOrderLicense),
    takeEvery(types.DELETE_ORDER_LICENSE_SUCCESS, getPayments),
    takeEvery(types.VERIFY_ACTIVATION_CODE_REQUEST, verifyActivationCode),
    takeEvery(types.GET_MOBILE_VIDEO_LICENSE_PRICES_REQUEST, getMobileVideoPricesRequest),
    takeEvery(types.ACTIVATE_ACTIVATION_CODE_REQUEST, activateActivationCode),
    takeLatest(types.GET_MOBILE_VIDEO_LICENSE_REQUEST, getMobileVideoSubscriptionRequest),
    takeLatest(types.USE_AVAILABLE_CREDIT_REQUEST, useAvailableCreditRequest),
    takeLatest(types.SET_MOBILE_VIDEO_PRODUCT_REQUEST, setMobileVideoProduct),
    takeLatest(types.PAYMENT_RESULT_FROM_SOCKET, paymentResultFromSocket),
    takeLatest(types.DEACTIVATE_RECURRING_PAYMENT_REQUEST, deactivateRecurringPayment),
    takeEvery(types.GET_VAT_VERIFICATION_REQUEST, getVatVerification),
    takeEvery(types.ACTIVATE_MOBILE_VIDEO_LICENSE_REQUEST, activateMobileVideo),
    takeLatest(types.GET_PROMO_CODES_REQUEST, getPromoCodes),
    takeLatest(types.SET_PROMO_CODES_REQUEST, setPromoCodesOrder),
    takeLatest(types.SET_PROMO_CODES_ORDER_REQUEST, setPromoCodesPayments),
    takeLatest(types.CREATE_PROMO_INVOICE_PAYMENT_REQUEST, createPromoInvoicePayment),
    takeLatest(types.DELETE_PROMO_CODES_ORDER_CONTENT_REQUEST, deletePromoOrderContent),
  ]);
}
