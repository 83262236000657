import ErrorIcon from '@mui/icons-material/Error';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Alert from '../../../components/Alert';
import messages from '../messages';

function DefaultPasswordAlert() {
  return (
    <Alert
      icon={<ErrorIcon />}
      severity={"error"}
    >
      <Typography color="warning.text" variant="body2">
        <FormattedMessage {...messages.DeviceListDefaultPasswordError} />
      </Typography>
    </Alert>
  );
}

export default DefaultPasswordAlert;
