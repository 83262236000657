import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  label: {
    maxWidth: 150,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  root: {
    px: 0,
    py: 1.5,
    width: '100%',
  },
};

const SideBarMenuButton = ({ emptyLabel, itemLabel, onClick, open, startIcon, value }) => (
  <ButtonBase onClick={onClick} sx={styles.root}>
    <Grid alignItems={value ? 'stretch' : 'center'} container>
      <Grid item>
        <Box color={value ? 'primary.main' : 'secondary.main'} px={2}>
          {startIcon}
        </Box>
      </Grid>
      <Grid item>
        <Box
          color={value ? 'primary.main' : 'secondary.main'}
          fontSize={value ? 16 : 14}
          fontWeight={value ? 'bold' : 'regular'}
          sx={styles.label}
        >
          {value || emptyLabel}
        </Box>
        <Box color="secondary.main" fontSize={12} sx={styles.label} textAlign="left">
          {value ? itemLabel : ''}
        </Box>
      </Grid>
      <Grid item>
        <Box color="secondary.main">{open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}</Box>
      </Grid>
    </Grid>
  </ButtonBase>
);

SideBarMenuButton.propTypes = {
  emptyLabel: PropTypes.element.isRequired,
  itemLabel: PropTypes.element.isRequired,
  onClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  startIcon: PropTypes.element.isRequired,
  value: PropTypes.string.isRequired,
};

export default SideBarMenuButton;
