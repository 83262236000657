import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { AccessGroupDeleteModal, DetailDeleteButton } from '../../components';
import { PATHS } from '../../config/consts';
import messages from './messages';

const DeleteGroupButton = ({ companyId, group, siteId }) => {
  const [open, setIsOpen] = useState(false);

  return (
    <>
      <DetailDeleteButton
        label={<FormattedMessage {...messages.AccessSettingsDetailDeleteButtonLabel} />}
        onClick={() => setIsOpen(true)}
      />
      <AccessGroupDeleteModal
        group={group}
        onClose={() => setIsOpen(false)}
        open={open}
        redirectTo={PATHS.ACCESS_SETTINGS.replace(':companyId', companyId).replace(':siteId', siteId)}
      />
    </>
  );
};

DeleteGroupButton.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  group: PropTypes.shape({
    devices: PropTypes.arrayOf(PropTypes.object),
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
    users: PropTypes.number.isRequired,
  }).isRequired,
  siteId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default DeleteGroupButton;
