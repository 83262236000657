import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormikFields } from '../../../../../../components';
import { SUBSCRIPTION_TYPE } from '../../../consts';
import messages from '../messages';

const NumberOfSubscriptions = ({ isSiteMdu, maxSubscriptions, minSubscriptions, name, subscriptionType }) => {
  const getSubscriptionAdornment = () => {
    if (subscriptionType === SUBSCRIPTION_TYPE.DEVICE) {
      return <FormattedMessage {...messages.promoBatchFormInputSubscriptionsAdornmentDevices} />;
    }
    if (subscriptionType === SUBSCRIPTION_TYPE.APARTMENT) {
      return <FormattedMessage {...messages.promoBatchFormInputSubscriptionsAdornmentApartments} />;
    }
    if (isSiteMdu) {
      return <FormattedMessage {...messages.promoBatchFormInputSubscriptionsAdornmentApartments} />;
    }
    return <FormattedMessage {...messages.promoBatchFormInputSubscriptionsAdornmentDevices} />;
  };

  return (
    <FormikFields.Input
      fullWidth
      id="PromoBatchForm-subscriptions"
      InputLabelProps={{
        required: false,
        shrink: true,
        sx: {
          width: 'max-content',
        },
      }}
      inputProps={{
        max: maxSubscriptions,
        min: minSubscriptions,
      }}
      InputProps={{
        endAdornment: <Typography color="text.secondary">{getSubscriptionAdornment()}</Typography>,
      }}
      label={<FormattedMessage {...messages.promoBatchFormInputSubscriptionsLabel} />}
      margin="dense"
      name={`${name}.subscriptions`}
      required
      size="small"
      type="number"
    />
  );
};

NumberOfSubscriptions.propTypes = {
  isSiteMdu: PropTypes.bool.isRequired,
  maxSubscriptions: PropTypes.number.isRequired,
  minSubscriptions: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  subscriptionType: PropTypes.string.isRequired,
};

export default NumberOfSubscriptions;
