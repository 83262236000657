import DialpadIcon from '@mui/icons-material/Dialpad';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isUserGuest } from '../../../modules/user/utils';
import RowMenuItem from '../../DataGrid/RowMenuItem';
import messages from '../messages';

const GenerateNewPin = ({ disabled, onOpenGenerateNewPinModal, onOpenVisitorModal, user }) => (
  <RowMenuItem
    disabled={disabled}
    icon={<DialpadIcon />}
    label={<FormattedMessage {...messages.siteUsersRowMenuGenerateNewPin} />}
    onClick={() => (isUserGuest(user.role) ? onOpenVisitorModal() : onOpenGenerateNewPinModal())}
  />
);

GenerateNewPin.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onOpenGenerateNewPinModal: PropTypes.func.isRequired,
  onOpenVisitorModal: PropTypes.func.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    role: PropTypes.string,
  }).isRequired,
};

export default GenerateNewPin;
