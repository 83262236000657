import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import PollIcon from '@mui/icons-material/Poll';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import CloseBatchDialog from '../CloseBatchDialog';
import DetailLabel from '../DetailLabel';
import { getCountCodes, getCountCodesByName, isBatchClosed } from './helpers';
import messages from './messages';

const CHANNEL = {
  PROMO: 'PROMO',
  PURCHASE: 'PURCHASE',
};

const styles = {
  closeButton: {
    borderColor: 'error.main',
    color: 'error.main',
  },
  flex: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};

const BasicDataPanel = ({ data, onCloseBatch, onCloseDialog }) => {
  const [close, setClose] = useState(false);
  const { configuration, stats } = data;
  const countCodes = getCountCodes(stats);
  const isClosed = isBatchClosed(stats);

  return (
    <Box sx={styles.root}>
      <Box sx={styles.infoWrapper}>
        <Box sx={styles.flex}>
          {data.channel === CHANNEL.PURCHASE && (
            <DetailLabel
              label={<FormattedMessage {...messages.basicDataPanelCodeType} />}
              startIcon={<MonetizationOnIcon color="primary" fontSize="small" />}
              value={
                <strong>
                  <FormattedMessage {...messages.basicDataPanelPurchase} />
                </strong>
              }
            />
          )}
          {data.channel === CHANNEL.PROMO && (
            <DetailLabel
              label={<FormattedMessage {...messages.basicDataPanelCodeType} />}
              startIcon={<WhatshotIcon fontSize="small" htmlColor="orange" />}
              value={
                <strong>
                  <FormattedMessage {...messages.basicDataPanelPromo} />
                </strong>
              }
            />
          )}
          <DetailLabel
            label={<FormattedMessage {...messages.basicDataPanelCredits} />}
            startIcon={<LocalAtmIcon fontSize="small" htmlColor="#B6B6B6" />}
            unit={<FormattedMessage {...messages.basicDataPanelCreditsUnit} />}
            value={<strong>{configuration.credit}</strong>}
          />
        </Box>
        <Box sx={styles.flex}>
          <DetailLabel
            label={<FormattedMessage {...messages.basicDataPanelCodes} />}
            minHeight={110}
            startIcon={<ConfirmationNumberIcon fontSize="small" htmlColor="#B6B6B6" />}
            unit={<FormattedMessage {...messages.basicDataPanelCodesUnit} />}
            value={<strong>{countCodes}</strong>}
          />
          <DetailLabel
            emptyLabel
            label=""
            unit={<FormattedMessage {...messages.basicDataPanelCodesActiveUnit} />}
            value={getCountCodesByName(stats, 'CREATED')}
          />
          <DetailLabel
            emptyLabel
            label=""
            unit={<FormattedMessage {...messages.basicDataPanelCodesUsedUnit} />}
            value={getCountCodesByName(stats, 'USED')}
          />
          <DetailLabel
            emptyLabel
            label=""
            unit={<FormattedMessage {...messages.basicDataPanelCodesCanceledUnit} />}
            value={getCountCodesByName(stats, 'CANCELLED')}
          />
        </Box>
        <Box sx={styles.flex}>
          <DetailLabel
            label={<FormattedMessage {...messages.basicDataPanelSummary} />}
            minHeight={110}
            startIcon={<PollIcon fontSize="small" htmlColor="#B6B6B6" />}
            unit="m/device"
            value={<strong>{countCodes * configuration.credit}</strong>}
          />
          <DetailLabel
            emptyLabel
            label=""
            unit={<FormattedMessage {...messages.basicDataPanelSummaryUsedUnit} />}
            value={getCountCodesByName(stats, 'USED') * configuration.credit}
          />
        </Box>
      </Box>
      <div>
        <Button
          disabled={isClosed}
          onClick={() => setClose(true)}
          startIcon={<DeleteForeverIcon />}
          sx={styles.closeButton}
          variant="outlined"
        >
          <FormattedMessage {...messages.basicDataPanelDetailBatchDialogCloseBatchButton} />
        </Button>
      </div>
      <CloseBatchDialog
        batch={data}
        onCloseBatch={(batch, resolution) => {
          onCloseDialog();
          onCloseBatch(batch, resolution);
        }}
        onCloseDialog={() => setClose(false)}
        show={close}
      />
    </Box>
  );
};

BasicDataPanel.propTypes = {
  data: PropTypes.shape({
    channel: PropTypes.oneOf(Object.values(CHANNEL)),
    configuration: PropTypes.shape({
      credit: PropTypes.number,
      type: PropTypes.string,
    }),
    id: PropTypes.string,
    name: PropTypes.string,
    stats: PropTypes.shape({
      CANCELLED: PropTypes.number,
      CREATED: PropTypes.number,
      EXPIRED: PropTypes.number,
      LOCKED: PropTypes.number,
      USED: PropTypes.number,
    }),
  }).isRequired,
  onCloseBatch: PropTypes.func.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
};

export default BasicDataPanel;
