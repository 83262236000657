import GppGoodIcon from '@mui/icons-material/GppGood';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import RowMenuItem from '../../DataGrid/RowMenuItem';
import messages from '../messages';

const SetAccessGroups = ({ onOpenAccessGroupsModal, userId }) => (
  <RowMenuItem
    icon={<GppGoodIcon />}
    label={<FormattedMessage {...messages.siteUsersRowMenuSetAccessGroups} />}
    onClick={() => onOpenAccessGroupsModal(userId)}
  />
);

SetAccessGroups.propTypes = {
  onOpenAccessGroupsModal: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
};

export default SetAccessGroups;
