import Step from '@mui/material/Step';
import StepConnector from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import MaterialStepper from '@mui/material/Stepper';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  root: {
    backgroundColor: 'subscription.backgroundLight',
    overflowX: 'auto',
    p: 3,
  },
  stepperLabel: {
    '& svg': {
      color: 'text.secondary',
    },
  },
};

const Stepper = ({ activeStep, steps }) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.down('md'));

  return (
    <MaterialStepper
      activeStep={activeStep}
      alternativeLabel={matches}
      connector={matches ? null : <StepConnector />}
      sx={styles.root}
    >
      {steps.map((label) => (
        <Step key={label} sx={styles.stepperLabel}>
          <StepLabel>{label}</StepLabel>
        </Step>
      ))}
    </MaterialStepper>
  );
};

Stepper.propTypes = {
  activeStep: PropTypes.number,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
};

Stepper.defaultProps = {
  activeStep: 0,
};

export default Stepper;
