import Avatar from '@mui/material/Avatar';
import React from 'react';

export interface IIconProps {
  showAvatar?: boolean;
  children: JSX.Element;
}

export function Icon({ children, showAvatar }: IIconProps) {
  return showAvatar ? <Avatar sx={{ color: 'text.primary' }}>{children}</Avatar> : children;
}
