import ApartmentIcon from '@mui/icons-material/FolderShared';
import Skeleton from '@mui/material/Skeleton';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AccessGroupsTableChipList, DataGrid as DataGridComponent } from '../../../components';
import NameColumn from '../../../components/DataGrid/NameColumn';
import { PATHS } from '../../../config/consts';
import { getDataGridSelector, useDataGrid } from '../../../helpers/dataGrid';
import { useUrlParams } from '../../../helpers/urlParams';
import useBreakpoints from '../../../helpers/useBreakpoints';
import actions, { NAME as ACCESS_SETTINGS_MODULE, selectors } from '../../../modules/accessSettings';
import { isFetchingMessages } from '../../../modules/localization/selectors';
import DataGridRowMenu from './DataGridRowMenu/DataGridRowMenu';
import messages from './messages';
import NoRowsOverlay from './NoRowsOverlay';

// eslint-disable-next-line max-statements
function DataGrid({ onRemove, onSetAccessGroup }) {
  const { companyId, groupId, siteId } = useUrlParams();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const isLocalizationLoaded = useSelector(isFetchingMessages);

  const { apartmentsDataGrid, data, isFetching, isInvalid } = {
    apartmentsDataGrid: useSelector((state) =>
      getDataGridSelector(state, ACCESS_SETTINGS_MODULE, 'groupDetail.apartments')
    ),
    data: useSelector(selectors.getAccessGroupApartments),
    isFetching: useSelector(selectors.isAccessGroupApartmentsFetching),
    isInvalid: useSelector(selectors.isAccessGroupApartmentsInvalid),
  };

  const { onOrderBy, onRowsPerPageChange, onSelectPage } = useDataGrid(
    (...gridData) => dispatch(actions.getAccessGroupApartments(...gridData)),
    {
      companyId,
      groupId,
      order: apartmentsDataGrid.order,
      page: apartmentsDataGrid.page,
      rowsPerPage: apartmentsDataGrid.rowsPerPage,
      siteId,
    },
    isInvalid
  );

  const { upMd, upSm } = useBreakpoints();

  const navigate = useNavigate();

  const loadingWrapper = (children) => (isFetching ? <Skeleton animation="wave" height={25} width="80%" /> : children);

  const columns = useMemo(
    () => [
      {
        disableColumnMenu: true,
        field: 'number,name',
        flex: 1,
        headerName: formatMessage(messages.accessGroupApartmentsDataGridColumnsName),
        renderCell: (apartment) =>
          loadingWrapper(
            apartment?.value && (
              <NameColumn
                iconComponent={<ApartmentIcon />}
                isActive
                name={
                  apartment?.value?.name
                    ? `${apartment?.value?.number} – ${apartment?.value?.name}`
                    : apartment?.value?.number
                }
              />
            )
          ),
        sortable: true,
      },
      {
        disableColumnMenu: true,
        field: 'floor,number',
        headerName: upSm ? formatMessage(messages.accessGroupApartmentsDataGridColumnsFloor) : '',
        renderCell: (apartment) => loadingWrapper(apartment?.value?.floor || '–'),
        sortable: true,
        width: upSm ? 150 : 50,
      },
      {
        ...(upSm && { flex: 0.5 }),
        disableColumnMenu: true,
        field: 'users',
        headerName: upSm ? formatMessage(messages.accessGroupApartmentsDataGridColumnsUsers) : '',
        renderCell: (apartment) => loadingWrapper(apartment?.value && apartment?.value?.userCount),
        sortable: false,
        width: !upSm && 100,
      },
      {
        disableColumnMenu: true,
        field: 'groups',
        flex: 1,
        headerName: formatMessage(messages.accessGroupApartmentsDataGridColumnsGroups),
        renderCell: (apartment) =>
          loadingWrapper(
            apartment?.value && (
              <AccessGroupsTableChipList
                companyId={companyId}
                currentGroupId={groupId}
                groups={apartment?.value?.groups}
                siteId={siteId}
              />
            )
          ),
        sortable: false,
      },
      {
        disableColumnMenu: true,
        field: 'rowMenu',
        headerName: '',
        renderCell: (apartment) =>
          loadingWrapper(
            apartment?.value && (
              <DataGridRowMenu
                apartmentId={apartment?.value?.coreId}
                companyId={companyId}
                onRemove={() => onRemove(apartment?.value)}
                onSetAccessGroup={() => onSetAccessGroup(apartment?.value)}
                siteId={siteId}
              />
            )
          ),
        sortable: false,
        width: 65,
      },
    ],
    [isFetching, isLocalizationLoaded]
  );

  const rows = useMemo(
    () =>
      data.map((apartment) => ({
        'floor,number': apartment,
        groups: apartment,
        id: apartment.id,
        isClickable: true,
        'number,name': apartment,
        rowMenu: apartment,
        users: apartment,
      })),
    [data]
  );

  return (
    <DataGridComponent
      columns={columns}
      columnVisibilityModel={{
        groups: upMd,
      }}
      disableRowSelectionOnClick
      hideFooterPagination={isFetching}
      loading={isFetching}
      noRowsOverlay={<NoRowsOverlay />}
      onCellClick={(params) => {
        if (params.field !== 'rowMenu') {
          navigate(
            PATHS.APARTMENT_DETAIL.replace(':companyId', companyId)
              .replace(':siteId', siteId)
              .replace(':apartmentId', params?.value?.coreId)
          );
        }
      }}
      onOrderBy={onOrderBy}
      onRowsPerPageChange={onRowsPerPageChange}
      onSelectPage={onSelectPage}
      order={apartmentsDataGrid.order}
      page={apartmentsDataGrid.page}
      resultsFiltered={apartmentsDataGrid.resultsFiltered}
      resultsTotal={apartmentsDataGrid.resultsTotal}
      rows={rows}
      rowsPerPage={apartmentsDataGrid.rowsPerPage}
    />
  );
}

DataGrid.propTypes = {
  onRemove: PropTypes.func.isRequired,
  onSetAccessGroup: PropTypes.func.isRequired,
};

export default DataGrid;
