import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import SectionHeader from '../SectionHeader';
import CodeVerification from './CodeVerification';
import messages from './messages';

const PrepaidCode = ({ activationCodeError, codeData, isLoading, mobileVideoLicense, onCodeVerification }) => (
  <>
    <Grid container>
      <Grid item xs>
        <SectionHeader variant="h6">
          <FormattedMessage {...messages.prepaidCodeHeader} />
        </SectionHeader>
        <Typography component="div" variant="body2">
          <Grid container direction="column">
            <Grid item>
              <Box maxWidth={220}>
                <FormattedMessage {...messages.prepaidCodeSubscription} />
              </Box>
            </Grid>
          </Grid>
        </Typography>
      </Grid>
    </Grid>
    <Grid item xs>
      <CodeVerification
        activationCodeError={activationCodeError}
        codeData={codeData}
        isLoading={isLoading}
        mobileVideoLicense={mobileVideoLicense}
        onCodeVerification={onCodeVerification}
      />
    </Grid>
  </>
);

PrepaidCode.propTypes = {
  activationCodeError: PropTypes.shape({}).isRequired,
  codeData: PropTypes.shape({}).isRequired,
  isLoading: PropTypes.bool.isRequired,
  mobileVideoLicense: PropTypes.shape({}).isRequired,
  onCodeVerification: PropTypes.func.isRequired,
};

export default PrepaidCode;
