import { useIntl } from 'react-intl';
import * as yup from 'yup';
import http from '../../api';
import { FORM, ROLES } from '../../config/consts';
import {
  getBasicEmailValidation,
  getFirstNameValidation,
  getLastNameValidation,
} from '../../validations/user/userCommonValidations';
import messages from './messages';

function useUsersFormValidationSchema(companyId, siteId) {
  const { formatMessage } = useIntl();

  return yup.object().shape({
    email: yup.string().when([FORM.HAS_FORM, 'users'], {
      is: (hasForm, users) => hasForm && users?.some((u) => u.id === FORM.NEW_ITEM_ID),
      then: (_schema) =>
        getBasicEmailValidation(
          formatMessage(messages.usersAutocompleteUseUsersFormValidationSchemaValidationUserEmailValid),
          formatMessage(messages.usersAutocompleteUseUsersFormValidationSchemaValidationRequiredEmail)
        ).test({
          message: formatMessage(messages.usersAutocompleteUseUsersFormValidationSchemaValidationUserEmailExistUser),
          name: 'isExistEmail',
          test: async (email) => {
            let state = true;
            await http
              .head(`/companies/${companyId}/sites/${siteId}/users`, {
                params: { email, roles: ROLES.USER },
              })
              .then((response) => {
                state = response.status === 404;
              });

            return state;
          },
        }),
    }),
    firstName: yup
      .string()
      .when([FORM.HAS_FORM, 'users'], {
        is: (hasForm, users) => hasForm && users?.some((u) => u.id === FORM.NEW_ITEM_ID),
        then: (_schema) => getFirstNameValidation(true, formatMessage),
      })
      .when([FORM.HAS_FORM, 'users'], {
        is: (hasForm, users) => hasForm && users?.some((u) => u.id === FORM.NEW_ITEM_WITHOUT_LOGIN_ID),
        then: (_schema) => getFirstNameValidation(false, formatMessage),
      }),
    hasForm: yup.boolean(),
    lastName: yup.string().when([FORM.HAS_FORM, 'users'], {
      is: (hasForm, users) =>
        hasForm && users?.some((u) => u.id === FORM.NEW_ITEM_ID || u.id === FORM.NEW_ITEM_WITHOUT_LOGIN_ID),
      then: (_schema) => getLastNameValidation(formatMessage),
    }),
    users: yup.array().min(1, formatMessage(messages.usersAutocompleteUseUsersFormValidationSchemaValidationUser)),
  });
}

export default useUsersFormValidationSchema;
