import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Alert from '../../../components/Alert';
import messages from './messages';

const WarningSelectSiteBox = ({ onOpenDialog, site }) => (
  <Alert
    action={
      <Button onClick={() => onOpenDialog(site)} size="small">
        <FormattedMessage {...messages.warningSelectSiteBoxAction} />
      </Button>
    }
    severity="warning"
    title={<FormattedMessage {...messages.warningSelectSiteBoxTitle} />}
  >
    <FormattedMessage {...messages.warningSelectSiteBoxDescription} />
  </Alert>
);

WarningSelectSiteBox.propTypes = {
  onOpenDialog: PropTypes.func.isRequired,
  site: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }).isRequired,
};

export default WarningSelectSiteBox;
