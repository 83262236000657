import ApartmentIcon from '@mui/icons-material/FolderShared';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import AccessGroupsChipList from '../AccessGroupsChipList';
import ChipListWrapper from '../ChipListWrapper';
import RowSection from '../RowSection';
import messages from './messages';

function UserApartmentsAccessGroupsList({ groups }) {
  const { formatMessage } = useIntl();

  return (
    <RowSection icon={<ApartmentIcon />} label={formatMessage(messages.userApartmentsAccessGroupsListLabel)} hasDivider>
      <ChipListWrapper emptyPlaceholder={formatMessage(messages.userApartmentsAccessGroupsListEmpty)}>
        {groups.length ? <AccessGroupsChipList groups={groups} /> : null}
      </ChipListWrapper>
    </RowSection>
  );
}

UserApartmentsAccessGroupsList.propTypes = {
  groups: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default UserApartmentsAccessGroupsList;
