import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';
import { LANGUAGES } from '../../../config/consts';
import { useUrlParams } from '../../../helpers/urlParams';
import { getCreateSiteValidationSchema } from '../../../validations/createSiteValidationSchema';

export interface ICreateSiteValues {
  lang?: string;
  name: string;
}

export function useGetGlobalHooks() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { companyId } = useUrlParams();

  const formMethods = useForm<ICreateSiteValues>({
    defaultValues: {
      lang: LANGUAGES.english.locale,
      name: '',
    },
    mode: 'onChange',
    resolver: yupResolver(
      yup.object().shape({
        ...getCreateSiteValidationSchema(companyId, formatMessage),
      })
    ),
    shouldUnregister: true,
  });

  return {
    dispatch,
    formatMessage,
    formMethods,
  };
}
