import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useUrlParams } from '../../../helpers/urlParams';
import { getValidationSchema } from '../utils';

export function useGetGlobalHooks() {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { companyId, siteId } = useUrlParams();

  const formMethods = useForm({
    mode: 'onChange',
    resolver: yupResolver(getValidationSchema(companyId, siteId, formatMessage)),
    shouldUnregister: true,
  });

  return {
    dispatch,
    formatMessage,
    formMethods,
  };
}
