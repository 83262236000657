import { useSelector } from 'react-redux';
import { useUrlParams } from '../../../helpers/urlParams';
import { selectors } from '../../../modules/elevatorIntegration';

export function useGetDeviceElevatorIntegrationData() {
  const { companyId, deviceId, siteId } = useUrlParams();
  const elevatorIntegrationSettings = useSelector(selectors.getDeviceElevatorIntegrationData);
  const floors = useSelector(selectors.getSiteElevatorIntegrationFloorMappingFloors);
  const isFloorMappingFetching = useSelector(selectors.isSiteElevatorIntegrationFloorMappingFetching);

  return {
    elevatorIntegrationSettings,
    floors,
    isFloorMappingFetching,
    urlParams: {
      companyId,
      deviceId,
      siteId,
    },
  };
}
