import { defineMessages } from 'react-intl';

export default defineMessages({
  apartmentAddMultipleUsersModalCancelButton: {
    defaultMessage: 'Cancel',
    description: 'Apartment add multiple users modal - cancel button',
    id: 'apartmentAddMultipleUsersModalCancelButton',
  },
  apartmentAddMultipleUsersModalCheckbox: {
    defaultMessage: 'Generate a unique PIN for each user',
    description: 'Apartment add multiple users modal - checkbox label',
    id: 'apartmentAddMultipleUsersModalCheckbox',
  },
  apartmentAddMultipleUsersModalCreateButton: {
    defaultMessage: 'Create users',
    description: 'Apartment add multiple users modal - create button',
    id: 'apartmentAddMultipleUsersModalCreateButton',
  },
  apartmentAddMultipleUsersModalInputHelperText: {
    defaultMessage: 'Write one user name on each line.',
    description: 'Apartment add multiple users modal - input helper text',
    id: 'apartmentAddMultipleUsersModalInputHelperText',
  },
  apartmentAddMultipleUsersModalInputLabel: {
    defaultMessage: 'User names',
    description: 'Apartment add multiple users modal - input label',
    id: 'apartmentAddMultipleUsersModalInputLabel',
  },
  apartmentAddMultipleUsersModalInputPlaceholder: {
    defaultMessage: 'First user\nSecond user\nThird user',
    description: 'Apartment add multiple users modal - input helper text',
    id: 'apartmentAddMultipleUsersModalInputPlaceholder',
  },
  apartmentAddMultipleUsersModalText: {
    defaultMessage:
      '<p>You can create multiple <strong>users without login</strong> and add them to the apartment. Write one user surname on each line.</p>',
    description: 'Apartment add multiple users modal - text',
    id: 'apartmentAddMultipleUsersModalText',
  },
  apartmentAddMultipleUsersModalTitle: {
    defaultMessage: 'Create multiple users',
    description: 'Apartment add multiple users modal - label',
    id: 'apartmentAddMultipleUsersModalTitle',
  },
});
