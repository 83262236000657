import PropTypes from 'prop-types';
import React, { useState } from 'react';
import DeviceApproveModal from '../../../components/DeviceApproveModal';
import DeviceRejectModal from '../../../components/DeviceRejectModal';
import DataGrid from './DataGrid';

const WaitingDevicesDataGrid = ({
  companyId,
  data,
  didInvalid,
  filter,
  isFetching,
  onLoadDevices,
  page,
  resultsFiltered,
  resultsTotal,
  rowsPerPage,
  siteId,
}) => {
  const [modalOpen, setModalOpen] = useState(null);
  const [modalDevice, setModalDevice] = useState();
  const modalType = {
    APPROVE: 'APPROVE',
    REJECT: 'REJECT',
  };

  const handleOpenModal = (type, device) => {
    setModalDevice(device);
    setModalOpen(type);
  };

  const handleCloseModal = () => setModalOpen(null);

  return (
    <>
      <DataGrid
        companyId={companyId}
        data={data}
        didInvalid={didInvalid}
        filter={filter}
        isFetching={isFetching}
        onApprove={(device) => handleOpenModal(modalType.APPROVE, device)}
        onLoadDevices={onLoadDevices}
        onReject={(device) => handleOpenModal(modalType.REJECT, device)}
        page={page}
        resultsFiltered={resultsFiltered}
        resultsTotal={resultsTotal}
        rowsPerPage={rowsPerPage}
        siteId={siteId}
      />
      {modalOpen === modalType.APPROVE && <DeviceApproveModal device={modalDevice} onClose={handleCloseModal} open />}
      {modalOpen === modalType.REJECT && <DeviceRejectModal device={modalDevice} onClose={handleCloseModal} open />}
    </>
  );
};

WaitingDevicesDataGrid.propTypes = {
  companyId: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  didInvalid: PropTypes.bool.isRequired,
  filter: PropTypes.shape({
    fulltext: PropTypes.string,
  }).isRequired,
  isFetching: PropTypes.bool.isRequired,
  onLoadDevices: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  resultsFiltered: PropTypes.number.isRequired,
  resultsTotal: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  siteId: PropTypes.number.isRequired,
};

export default WaitingDevicesDataGrid;
