import { defineMessages } from 'react-intl';

export default defineMessages({
  buttonScanQRCodeClose: {
    defaultMessage: 'Close scanner',
    description: 'Button scan QR code - close',
    id: 'buttonScanQRCode.close',
  },
  buttonScanQRCodeScan: {
    defaultMessage: 'Scan QR code',
    description: 'Button scan QR code - scan',
    id: 'buttonScanQRCode.scan',
  },
});
