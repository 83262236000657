import { defineMessages } from 'react-intl';

export default defineMessages({
  elevatorIntegrationSettingsActiveSwitchCaption: {
    defaultMessage: 'Activates or deactivates integration{br}for the entire site',
    id: 'elevatorIntegration.settings.activeSwitch.caption',
  },
  elevatorIntegrationSettingsActiveSwitchLabel: {
    defaultMessage: 'Activate KONE cloud integration ',
    id: 'elevatorIntegration.settings.activeSwitch.label',
  },
  elevatorIntegrationSettingsAlertText: {
    defaultMessage:
      'The activation or deactivation of the integration will occur immediately after saving the form. Activate only after it’s fully configured.',
    id: 'elevatorIntegration.settings.alert.text',
  },
  elevatorIntegrationSettingsDestinationCallLabel: {
    defaultMessage: 'Destination call',
    id: 'elevatorIntegration.settings.destinationCall.label',
  },
  elevatorIntegrationSettingsDestinationCallTtip: {
    defaultMessage: 'Preset apartment floor{br}in elevator on entry',
    id: 'elevatorIntegration.settings.destinationCall.TTip',
  },
  elevatorIntegrationSettingsLandingCallLabel: {
    defaultMessage: 'Landing call',
    id: 'elevatorIntegration.settings.landingCall.label',
  },
  elevatorIntegrationSettingsLandingCallTtip: {
    defaultMessage: 'Call the elevator{br}on entry',
    id: 'elevatorIntegration.settings.landingCall.TTip',
  },
  elevatorIntegrationSettingsTitle: {
    defaultMessage: 'My2N integration settings',
    id: 'elevatorIntegration.settings.title',
  },
  elevatorIntegrationSettingsUserDefaultCaption: {
    defaultMessage: 'Customizable for each user. Disabling these options won’t affect users with personal settings.',
    id: 'elevatorIntegration.settings.userDefault.caption',
  },
  elevatorIntegrationSettingsUserDefaultTitle: {
    defaultMessage: 'Default user settings',
    id: 'elevatorIntegration.settings.userDefault.title',
  },
  elevatorIntegrationSettingsVisitorCallLabel: {
    defaultMessage: 'Visitor call',
    id: 'elevatorIntegration.settings.visitorCall.label',
  },
  elevatorIntegrationSettingsVisitorCallTtip: {
    defaultMessage: 'Call the elevator for visitor on entry{br}and preset apartment floor{br}in elevator on entry',
    id: 'elevatorIntegration.settings.visitorCall.TTip',
  },
});
