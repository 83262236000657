import { useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { orderItemPropTypes } from '../../types';
import ItemName from './ItemName';
import ItemValueTitle from './ItemValueTitle';
import ItemYearsAndSubscriptionsCount from './ItemYearsAndSubscriptionsCount';

const UserOrderData = ({ item }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('xl'));
  return matches ? (
    <Grid alignItems="center" container item xl={7}>
      <ItemName name={item.name} />
      <ItemYearsAndSubscriptionsCount item={item.configuration} />
      <ItemValueTitle />
    </Grid>
  ) : (
    <>
      <ItemName name={item.name} />
      <ItemYearsAndSubscriptionsCount item={item.configuration} />
      <ItemValueTitle />
    </>
  );
};

UserOrderData.propTypes = {
  item: orderItemPropTypes.isRequired,
};

export default UserOrderData;
