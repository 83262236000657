import { defineMessages } from 'react-intl';

export default defineMessages({
  configurationModeDialogAutomaticConfirm: {
    defaultMessage: 'Set automatic mode',
    description: 'Automatic mode dialog confirm button',
    id: 'configurationModeDialog.AUTOMATIC.confirm',
  },
  configurationModeDialogAutomaticTitle: {
    defaultMessage: 'Set to automatic mode – recommended',
    description: 'Automatic mode dialog title',
    id: 'configurationModeDialog.AUTOMATIC.title',
  },
  configurationModeDialogCancel: {
    defaultMessage: 'Cancel',
    description: 'Dialog cancel button',
    id: 'configurationModeDialog.cancel',
  },
  configurationModeDialogManualConfirm: {
    defaultMessage: 'Set manual mode',
    description: 'MANUAL mode dialog confirm button',
    id: 'configurationModeDialog.MANUAL.confirm',
  },
  configurationModeDialogManualTitle: {
    defaultMessage: 'Set to manual mode – not recommended',
    description: 'MANUAL mode dialog title',
    id: 'configurationModeDialog.MANUAL.title',
  },
  Label: {
    defaultMessage: 'Configuration mode',
    description: 'ConfigurationModeField label',
    id: 'configurationModeField.Label',
  },
  optionsAutomatic: {
    defaultMessage: 'Automatic',
    description: 'ConfigurationModeField option automatic',
    id: 'configurationModeField.optionsAutomatic',
  },
  optionsManual: {
    defaultMessage: 'Manual',
    description: 'ConfigurationModeField option manual',
    id: 'configurationModeField.optionsManual',
  },
});
