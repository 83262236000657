export enum TestID {
  GeneratePin = 'generate-pin',
  ShowPin = 'show-pin',
  ShowEditPin = 'show-edit-pin',
  EditValidity = 'edit-validity',
  ShowRFID = 'show-rfid',
  DeletePin = 'delete-pin',
  DeleteRFID = 'delete-rfid',
  OpenHelpModal = 'open-help-modal',
  ShowPinModal = 'show-pin-modal',
  ShowRfidModal = 'show-rfid-modal',
  DeleteRfidModal = 'delete-rfid-modal',
  DeletePinModal = 'delete-pin-modal',
  UWLDeletePinModal = 'uwl-delete-pin-modal',
  UWLDeleteRfidModal = 'uwl-delete-rfid-modal',
  GeneratePinModal = 'generate-pin-modal',
  ShowQrPin = 'show-qr-pin',
}
