import PropTypes from 'prop-types';
import React from 'react';
import { Modal } from '../../components';

const AddDeviceModal = ({ actions, children, onClose, open, title, ...props }) => (
  <Modal
    actions={actions}
    headerVariant="primary"
    isForm
    maxWidth="sm"
    name="add-device-modal"
    onClose={onClose}
    open={open}
    title={title}
    {...props}
  >
    {children}
  </Modal>
);

AddDeviceModal.propTypes = {
  actions: PropTypes.node,
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

AddDeviceModal.defaultProps = {
  actions: null,
  children: null,
  title: null,
};

export default AddDeviceModal;
