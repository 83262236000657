import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import AboutPage from '../containers/AboutPage';
import BillingInfo from '../containers/BillingInfo';
import CompanyAdmin from '../containers/CompanyAdmin';
import CompanyAdminDetail from '../containers/CompanyAdminDetail/CompanyAdminDetail';
import GTCPageCompany from '../containers/GTCPageCompany';
import GTCPageDetailCompany from '../containers/GTCPageDetailCompany';
import MvSdkSettings from '../containers/MvSdkSettings';
import PaymentsOverview from '../containers/PaymentsOverview';
import Sites from '../containers/Sites';
import SupportPage from '../containers/SupportPage';
import { useUrlParams } from '../helpers/urlParams';
import { setInvalidateCompanySwitcher } from '../modules/common/actionsTS';
import { Roles } from '../modules/commonTypes';
import { clearCompany, loadCompany } from '../modules/company/actions';
import { didInvalid, getCompanyData, isCompanyFetching } from '../modules/company/selectors';
import CompanyDashboardPage from '../pages/CompanyDashboardPage';
import { PrivateRoute } from './components/PrivateRoute';
import { SiteRoutes } from './SiteRoutes';

export function CompanyRoutes() {
  const dispatch = useDispatch();
  const isCompanyLoading = useSelector(isCompanyFetching);
  const [stateCompanyId, setStateCompanyId] = useState<number | undefined>(undefined);
  const loadedCompany = useSelector(getCompanyData) as { id: number };

  const { companyId } = useUrlParams();
  const didInvalidCompany = useSelector(didInvalid);

  useEffect(() => {
    if (companyId !== stateCompanyId) {
      setStateCompanyId(companyId);
    }

    return () => {
      dispatch(clearCompany());
      dispatch(setInvalidateCompanySwitcher());
    };
  }, [companyId]);

  useEffect(() => {
    if (!isCompanyLoading) {
      dispatch(loadCompany(companyId));
    }
  }, [stateCompanyId, didInvalidCompany]);

  if (isCompanyLoading || didInvalidCompany || loadedCompany?.id === undefined) {
    return <>{false}</>;
  }

  return (
    <Routes>
      <Route
        element={
          <PrivateRoute allowedRoles={{ contextRoles: [Roles.COMPANY_ADMIN] }}>
            <CompanyDashboardPage />
          </PrivateRoute>
        }
        path={'*'}
      />
      <Route
        element={
          <PrivateRoute allowedRoles={{ contextRoles: [Roles.COMPANY_ADMIN] }}>
            <CompanyAdmin />
          </PrivateRoute>
        }
        path={'admins'}
      />
      <Route
        element={
          <PrivateRoute allowedRoles={{ contextRoles: [Roles.COMPANY_ADMIN] }}>
            <CompanyAdminDetail />
          </PrivateRoute>
        }
        path={'admins/:userId'}
      />
      <Route
        element={
          <PrivateRoute allowedRoles={{ contextRoles: [Roles.COMPANY_ADMIN] }}>
            <Sites />
          </PrivateRoute>
        }
        path={'sites'}
      />
      <Route
        element={
          <PrivateRoute allowedRoles={{ contextRoles: [Roles.COMPANY_ADMIN] }}>
            <BillingInfo />
          </PrivateRoute>
        }
        path={'billing-info'}
      />
      <Route
        element={
          <PrivateRoute allowedRoles={{ contextRoles: [Roles.COMPANY_ADMIN] }}>
            <PaymentsOverview />
          </PrivateRoute>
        }
        path={'payments-overview'}
      />
      <Route
        element={
          <PrivateRoute allowedRoles={{ contextRoles: [Roles.COMPANY_ADMIN] }}>
            <MvSdkSettings />
          </PrivateRoute>
        }
        path={'mv-sdk-settings'}
      />
      <Route
        element={
          <PrivateRoute allowedRoles={{ contextRoles: [Roles.COMPANY_ADMIN, Roles.SUPERADMIN] }}>
            <AboutPage />
          </PrivateRoute>
        }
        path={'about'}
      />
      <Route
        element={
          <PrivateRoute allowedRoles={{ contextRoles: [Roles.COMPANY_ADMIN, Roles.SUPERADMIN] }}>
            <SupportPage />
          </PrivateRoute>
        }
        path={'help'}
      />
      <Route
        element={
          <PrivateRoute allowedRoles={{ contextRoles: [Roles.ANY] }}>
            <GTCPageCompany />
          </PrivateRoute>
        }
        path={'legal'}
      />
      <Route
        element={
          <PrivateRoute allowedRoles={{ contextRoles: [Roles.ANY] }}>
            <GTCPageDetailCompany />
          </PrivateRoute>
        }
        path={'legal/:slug/:version'}
      />
      <Route
        element={
          <PrivateRoute allowedRoles={{ contextRoles: [Roles.ANY] }}>
            <GTCPageDetailCompany />
          </PrivateRoute>
        }
        path={'legal/:slug'}
      />

      <Route path="sites/:siteId/*" element={<SiteRoutes />} />
    </Routes>
  );
}
