import messages from './messages';

export type VisitorModalMsgContext =
  | 'UserAccessValidityEditmodal'
  | 'UserListNewGuestmodal'
  | 'ApartmentUsersNewGuestmodal'
  | 'AAmodalNewGuestPIN';

export function getMessage(context: VisitorModalMsgContext, id: string) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return messages[context][`${context}${id}`];
}
