import { defineMessages } from 'react-intl';

export default defineMessages({
  setAccessGroupsModalAddButtonLabel: {
    defaultMessage: 'Set Groups',
    description: 'Apartment access groups modal - add button label',
    id: 'setAccessGroupsModal.addButtonLabel',
  },
  setAccessGroupsModalHelperText: {
    defaultMessage: 'One apartment can be included in one or more Access groups',
    description: 'Access group select helperText',
    id: 'setAccessGroupsModal.helperText',
  },
  setAccessGroupsModalText: {
    defaultMessage: 'Apartment access groups modal - text',
    description: '',
    id: 'setAccessGroupsModal.text',
  },
  setAccessGroupsModalTitle: {
    defaultMessage: 'Set Access groups for apartment',
    description: 'Apartment access groups modal - title',
    id: 'setAccessGroupsModal.title',
  },
});
