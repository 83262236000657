import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReadOnlyField } from '../../../../components';
import { FIRMWARE_STATUS, SERVICE } from '../../../../config/devices';
import { unitFirmwareProps } from '../../unitProps';
import FirmwareModal from '../../VersionNotification/FirmwareModal';
import messages from './messages';

const styles = {
  chip: {
    borderWidth: 2,
    fontWeight: 'medium',
  },
  error: {
    borderColor: 'error.main',
    color: 'error.main',
  },
  fwField: {
    maxWidth: 130,
  },
  success: {
    borderColor: 'success.main',
    color: 'success.main',
  },
  warning: {
    borderColor: 'warning.main',
    color: 'warning.main',
  },
};

const FirmwareInfo = ({ device, firmware, firmwareDisabled, onUpdateFirmware }) => {
  const { formatMessage } = useIntl();
  const [openFWUpdateModal, setOpenFWUpdateModal] = useState(false);

  // eslint-disable-next-line max-statements
  const getInfo = (isActive) => {
    let info = null;
    if (isActive) {
      if (firmware?.info?.haveUpdate === false) {
        info = (
          <Chip
            label={<FormattedMessage {...messages.firmwareInfoFirmwareUpToDate} />}
            sx={{ ...styles.chip, ...styles.success }}
            variant="outlined"
          />
        );
      }
      let isWarning = false;
      let isError = false;
      let isUpgradeDisabled = firmwareDisabled;
      let textId = '';
      if (firmware?.info?.haveUpdate === true) {
        if (device?.services?.[SERVICE.ACS]?.firmwareStatus === FIRMWARE_STATUS.UNSUPPORTED) {
          isUpgradeDisabled = true;
          isError = true;
          textId = messages.firmwareInfoFirmwareUnsupported;
        } else if (device?.services?.[SERVICE.ACS]?.firmwareStatus === FIRMWARE_STATUS.DEPRECATED) {
          isWarning = true;
          textId = messages.firmwareInfoFirmwareDeprecated;
        } else {
          isWarning = true;
          textId = messages.firmwareInfoFirmwareAvailable;
        }
        info = (
          <Grid container>
            <Grid item>
              <Chip
                label={<FormattedMessage {...textId} />}
                sx={{
                  ...styles.chip,
                  ...(isWarning && styles.warning),
                  ...(isError && styles.error),
                }}
                variant="outlined"
              />
            </Grid>
            <Grid item>
              <Box pl={1}>
                <Button color="primary" disabled={isUpgradeDisabled} onClick={() => setOpenFWUpdateModal(true)}>
                  <FormattedMessage {...messages.firmwareInfoUpdate} />
                </Button>
              </Box>
            </Grid>
          </Grid>
        );
      }
    }

    return info;
  };

  return (
    <>
      <Box pb={2}>
        {device?.services?.[SERVICE.ACS]?.firmware ? (
          <Grid alignItems="flex-end" container spacing={1}>
            <Grid item>
              <ReadOnlyField
                label={<FormattedMessage {...messages.firmwareInfoTitle} />}
                sx={styles.fwField}
                value={device.services[SERVICE.ACS].firmware}
              />
            </Grid>
            <Grid item>{getInfo(device.services[SERVICE.ACS].active)}</Grid>
          </Grid>
        ) : (
          <ReadOnlyField
            label={<FormattedMessage {...messages.firmwareInfoTitle} />}
            value={formatMessage(messages.firmwareInfoFirmwareVersionNotAvailable)}
          />
        )}
      </Box>
      <FirmwareModal
        disabled={device?.services?.[SERVICE.ACS]?.firmwareStatus === FIRMWARE_STATUS.UNSUPPORTED}
        onClose={() => setOpenFWUpdateModal(false)}
        onUpdateFirmware={onUpdateFirmware}
        open={openFWUpdateModal}
        releaseNotes={firmware?.info?.newFirmware?.releaseNotes}
        version={firmware?.info?.newFirmware?.version}
      />
    </>
  );
};

FirmwareInfo.propTypes = {
  device: PropTypes.shape({
    services: PropTypes.shape({
      [SERVICE.ACS]: PropTypes.shape({
        firmware: PropTypes.string,
        firmwareStatus: PropTypes.string,
        lastSeen: PropTypes.string,
      }),
    }),
    site: PropTypes.shape({
      company: PropTypes.shape({
        name: PropTypes.string,
      }),
      name: PropTypes.string,
    }),
  }).isRequired,
  firmware: unitFirmwareProps.isRequired,
  firmwareDisabled: PropTypes.bool.isRequired,
  onUpdateFirmware: PropTypes.func.isRequired,
};

export default FirmwareInfo;
