import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { CALL_SETTINGS } from '../../../../config/consts';
import { DEVICE_TYPE } from '../../../../config/devices';
import devicesActions from '../../../../modules/devices';
import { getDeviceMaxButtonCount } from '../../../../modules/devices/helpers/callSettings/devicesCallSettingsHelper';
import { shouldShowAddButton } from '../../../../modules/devices/helpers/helpers';
import { handleSaveDeviceCallSettings } from '../../../../modules/devices/helpers/saveDeviceHelper';
import { saveDeviceCallSettingsRequest } from '../../../../modules/devices/saveActions';
import CallSettingsListActions from '../CallSettingsListActions';
import { handleAddListItem } from '../helpers';
import UnlicensedDevicesWarningAlert from '../UnlicensedDevicesWarningAlert';
import ButtonList from './ButtonList';
import DoorStationHeader from './DoorStationHeader';
import { handleChangeButtonName, handleChangeListItem } from './helpers/helpers';
import { useFetchData } from './hooks/useFetchData';
import { IButtonRenderData, ICallSettingOption, useGetStateData } from './hooks/useGetStateData';
import { useMemoizedData } from './hooks/useMemoizedData';
import ManualWarningAlert from './ManualWarningAlert';
import messages from './messages';

function Intercom(): React.ReactNode {
  const stateData = useGetStateData();
  const dispatch = useDispatch();

  useFetchData(stateData.companyId, stateData.siteId, dispatch);

  const [ipIntercomButtonList, setIntercomButtonList] = useState<IButtonRenderData[]>([]);
  const [deviceMaxButtonCount] = useState(getDeviceMaxButtonCount(stateData.device));

  const memoizedData = useMemoizedData(stateData, ipIntercomButtonList);

  useEffect(() => {
    if (!stateData.isFetching) {
      const updatedButtonsRenderData: IButtonRenderData[] = [];
      memoizedData.buttonsRenderData.forEach((buttonRenderData) => {
        const clonedButtonData = { ...buttonRenderData };
        updatedButtonsRenderData.push(clonedButtonData);
      });
      setIntercomButtonList(updatedButtonsRenderData);
    }
  }, [stateData.isFetching]);

  return (
    <>
      {stateData.isManualWarning && (
        <Box pb={2}>
          <ManualWarningAlert />
        </Box>
      )}
      {memoizedData.isUnlicensedWarning && <UnlicensedDevicesWarningAlert />}
      {stateData.device.type === DEVICE_TYPE.DOOR_STATION && (
        <Box pb={2}>
          <DoorStationHeader />
        </Box>
      )}
      <Box pb={2} pt={1} px={2}>
        <ButtonList
          isDeviceDetailEditing={stateData.isDeviceDetailEditing}
          list={ipIntercomButtonList}
          onChangeButtonName={(buttonRenderData: IButtonRenderData, newName: string) =>
            handleChangeButtonName(buttonRenderData, newName, ipIntercomButtonList, setIntercomButtonList)
          }
          onChangeItem={(buttonRenderData: IButtonRenderData, dataUpdate?: ICallSettingOption[]) =>
            handleChangeListItem(buttonRenderData, ipIntercomButtonList, setIntercomButtonList, dataUpdate)
          }
          options={
            memoizedData.callSettingsDataManager !== undefined
              ? memoizedData.callSettingsDataManager.getCallSettingsChipOptions()
              : []
          }
          ringingGroupSizeLimit={stateData.ringingGroupSizeLimit}
          setDeviceEditing={(value: boolean) => dispatch(devicesActions.setDeviceEditing(value))}
        />
      </Box>
      <CallSettingsListActions
        addButtonLabel={
          shouldShowAddButton(memoizedData.buttonsRenderData, deviceMaxButtonCount) ? (
            <FormattedMessage {...messages.addButton} />
          ) : undefined
        }
        onAdd={handleAddListItem(CALL_SETTINGS.INTERCOM.TYPE, setIntercomButtonList)}
        onSave={() => {
          memoizedData.callSettingsDataManager &&
            handleSaveDeviceCallSettings(
              memoizedData.callSettingsDataManager,
              ipIntercomButtonList,
              stateData.device,
              stateData.apartmentsChipsOptions,
              (values: any) => dispatch(saveDeviceCallSettingsRequest(values))
            );
        }}
      />
    </>
  );
}

export default Intercom;
