import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { BillingInfoModal, Link } from '../../components';
import messages from './messages';

const BillingInfoSettings = ({ companyName, isCompanyAdmin, isSiteAdminPayment, onEditBilling }) => (
  <>
    {isCompanyAdmin && (
      <>
        <Typography variant="body2">
          <FormattedMessage
            {...messages.subscriptionInformationIncomplete}
            values={{
              link: (
                <Link component="button" onClick={onEditBilling}>
                  <Typography>
                    <FormattedMessage {...messages.subscriptionEditBillingInfo} />
                  </Typography>
                </Link>
              ),
            }}
          />
        </Typography>
        <BillingInfoModal />
      </>
    )}
    {!isCompanyAdmin && isSiteAdminPayment && (
      <Typography variant="body2">
        <FormattedMessage
          {...messages.subscriptionContactForBillingInfo}
          values={{
            br: <br />,
            companyName,
          }}
        />
      </Typography>
    )}
  </>
);

BillingInfoSettings.propTypes = {
  companyName: PropTypes.string.isRequired,
  isCompanyAdmin: PropTypes.bool,
  isSiteAdminPayment: PropTypes.bool,
  onEditBilling: PropTypes.func.isRequired,
};

BillingInfoSettings.defaultProps = {
  isCompanyAdmin: false,
  isSiteAdminPayment: false,
};

export default BillingInfoSettings;
