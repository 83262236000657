import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const styles = {
  content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  model: {
    color: 'primary.main',
    fontSize: 24,
    fontWeight: 'medium',
    mt: 3,
    textAlign: 'center',
  },
  root: {
    height: '180px',
  },
  title: {
    fontWeight: 'bold',
    mb: 1,
    ml: 2,
    mr: 2,
    mt: 1,
  },
};

function BusinessModelCard({ model }: { model: string }) {
  return (
    <Card sx={styles.root}>
      <CardContent sx={styles.content}>
        <Typography color="card.header" sx={styles.title} variant="body2">
          <FormattedMessage {...messages.businessModelCardTitle} />
        </Typography>
        <div>
          <Typography sx={styles.model} variant="h4">
            {model}
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
}

export default BusinessModelCard;
