/* eslint-disable max-len */
import './style.scss';
import React from 'react';

const SvgLoader = () => (
  <div className="main-loader">
    <div className="loader-circle" />
    <div className="loader-line-mask">
      <div className="loader-line" />
    </div>
    <svg viewBox="0 0 200 200" width="70">
      <path
        d="M151.45 68.150007c-8.7-21.400004-28.6-35.000004-51.4-35.000004-26.8 0-50 19.1-55.5 44.900004-17.5 4.3-30.3 20.4-30.3 38.900003 0 21.99999 17.8 39.89999 39.6 39.99999h34.4v-45.29999c6.2-1.9 10.7-7.6 10.7-14.400003 0-8.3-6.7-15-15-15s-15 6.7-15 15c0 6.300003 3.9 11.600003 9.3 13.900003V146.85h-24.3c-16.4 0-29.7-13.39999-29.7-29.99999 0-14.8 10.9-27.600003 25.3-29.700003l3.7-.5.5-3.7c3.3-22.700004 23.2-39.800004 46.2-39.800004 19.5 0 36.4 12.2 43 31.100004l1 2.8 3 .4c16.6 2.5 28.7 17 28.7 34.600003 0 19-17.2 30.99999-33.2 33.99999-1 .2-2.3.4-3.6.5-2.2-5.6-7.5-9.5-13.8-9.5-8.2 0-14.9 6.7-14.9 14.9s6.7 14.9 14.9 14.9c6.5 0 12.1-4.3 14.1-10.2 2-.2 3.8-.5 5.3-.7 19.9-3.8 41.3-19.2 41.3-43.79999 0-21.600003-14.3-39.600003-34.3-44.000003m-67.5 24c2.8 0 5 2.2 5 5s-2.2 5.000003-5 5.000003-5-2.200003-5-5.000003c0-2.7 2.2-5 5-5M125.05 156.85c-2.7 0-4.9-2.2-4.9-4.9s2.2-4.9 4.9-4.9 4.9 2.2 4.9 4.9-2.2 4.9-4.9 4.9"
        style={{ fill: '#acacac' }}
      />
    </svg>
  </div>
);

export default SvgLoader;
