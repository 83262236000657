import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import RowMenuItem from '../../DataGrid/RowMenuItem';
import messages from './messages';

const EditDevice = ({ deviceDetailLink }) => (
  <RowMenuItem
    component={Link}
    icon={<EditIcon />}
    label={<FormattedMessage {...messages.companyAdminDevicesDataGridRowMenuEdit} />}
    to={deviceDetailLink}
  />
);

EditDevice.propTypes = {
  deviceDetailLink: PropTypes.string.isRequired,
};

export default EditDevice;
