import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';

const TableItem = ({ isActive, itemIcon, itemText }) => (
  <Box
    sx={{
      alignItems: 'center',
      color: isActive ? 'text.primary' : 'text.disabled',
      display: 'inline-flex',
    }}
  >
    {itemIcon && (
      <Box
        sx={{
          '& svg': { verticalAlign: 'middle' },
          color: isActive ? 'action.active' : 'action.disabled',
          pr: 2,
        }}
      >
        {itemIcon}
      </Box>
    )}
    {itemText && <Box sx={{ '& a': { color: 'inherit' } }}>{itemText}</Box>}
  </Box>
);

TableItem.propTypes = {
  isActive: PropTypes.bool.isRequired,
  itemIcon: PropTypes.node,
  itemText: PropTypes.node,
};

TableItem.defaultProps = {
  itemIcon: null,
  itemText: null,
};

export default TableItem;
