import { defineMessages } from 'react-intl';

export default defineMessages({
  addSiteContentHelperTextLabel: {
    defaultMessage: "Choose a helpful name','addSiteContent.helperTextLabel",
    description: 'Choose a helpful name',
    id: 'addSiteContent.helperTextLabel',
  },
  addSiteContentLabel: {
    defaultMessage: "New site name','addSiteContent.label",
    description: 'New site name',
    id: 'addSiteContent.label',
  },
  addSiteContentListBillingInfo: {
    defaultMessage: "will use the same billing info for the new site','addSiteContent.list.billingInfo",
    description: 'will use the same billing info for the new site',
    id: 'addSiteContent.list.billingInfo',
  },
  addSiteContentListDevices: {
    defaultMessage: "want to interconnect a separate group of devices','addSiteContent.list.devices",
    description: 'want to interconnect a separate group of devices',
    id: 'addSiteContent.list.devices',
  },
  addSiteContentListManageBuilding: {
    defaultMessage: "manage several buildings or a large property','addSiteContent.list.manageBuilding",
    description: 'manage several buildings or a large property',
    id: 'addSiteContent.list.manageBuilding',
  },
  addSiteContentTitle: {
    defaultMessage: "A separate site is useful if you:','addSiteContent.title",
    description: 'A separate site is useful if you:',
    id: 'addSiteContent.title',
  },
  addSiteModalCancel: {
    defaultMessage: 'Cancel',
    description: 'Add Site Modal - Cancel button',
    id: 'addSiteModal.cancel',
  },
  addSiteModalNotificationLink: {
    defaultMessage: "create a new company','addSiteModal.notification.link",
    description: 'create a new company',
    id: 'addSiteModal.notification.link',
  },
  addSiteModalNotificationText: {
    defaultMessage:
      'Not what you are looking for? Maybe you would rather' +
      ' {addCompany} with its own billing' +
      ' information (different invoice address).',
    description: '',
    id: 'addSiteModal.notification.text',
  },
  addSiteModalTitle: {
    defaultMessage: 'Create new site',
    description: 'Add Site Modal - title',
    id: 'addSiteModal.title',
  },
  addSiteModalVerificationExists: {
    defaultMessage: "This site name is already used.','addSiteModal.verification.exists",
    description: 'This site name is already used.',
    id: 'addSiteModal.verification.exists',
  },
  addSiteModalVerificationMaxLength: {
    defaultMessage: "Site name is too long.','addSiteModal.verification.maxLength",
    description: 'Site name is too long.',
    id: 'addSiteModal.verification.maxLength',
  },
  addSiteModalVerificationRequired: {
    defaultMessage: "Value is mandatory.','addSiteModal.verification.required",
    description: 'Value is mandatory.',
    id: 'addSiteModal.verification.required',
  },
  deleteSiteModalCancel: {
    defaultMessage: 'Cancel',
    description: 'Delete Site Modal - cancel button',
    id: 'deleteSiteModal.cancel',
  },
  deleteSiteModalConfirm: {
    defaultMessage: 'Delete site',
    description: 'Delete Site Modal - confirm button',
    id: 'deleteSiteModal.confirm',
  },
  deleteSiteModalDescriptionBottom: {
    defaultMessage: 'Users who are only in this site will be deleted permanently.',
    description: 'Delete Site Modal - description bottom',
    id: 'deleteSiteModal.description.bottom',
  },
  deleteSiteModalDescriptionListOneItem: {
    defaultMessage: 'delete the settings of all devices within it',
    description: 'Delete Site Modal - first item of list',
    id: 'deleteSiteModal.description.list.oneItem',
  },
  deleteSiteModalDescriptionListThreeItem: {
    defaultMessage: 'log all users out of the mobile app immediately.',
    description: 'Delete Site Modal - third item of list',
    id: 'deleteSiteModal.description.list.threeItem',
  },
  deleteSiteModalDescriptionListTwoItem: {
    defaultMessage: 'disconnect all users from the site',
    description: 'Delete Site Modal - second item of list',
    id: 'deleteSiteModal.description.list.twoItem',
  },
  deleteSiteModalDescriptionTitle: {
    defaultMessage: 'This action is irreversible. By deleting the site, you will:',
    description: 'Delete Site Modal - description title',
    id: 'deleteSiteModal.description.title',
  },
  deleteSiteModalTitle: {
    defaultMessage: 'Delete site',
    description: 'Delete Site Modal - title',
    id: 'deleteSiteModal.title',
  },
  editSiteModalCancel: {
    defaultMessage: 'Cancel',
    description: 'Edit Site Modal - cancel button',
    id: 'editSiteModal.cancel',
  },
  editSiteModalDescription: {
    defaultMessage: 'You can rename the site at any time.',
    description: 'Edit Site Modal - description',
    id: 'editSiteModal.description',
  },
  editSiteModalHelperTextLabel: {
    defaultMessage: 'Choose a helpful name',
    description: 'Edit Site Modal - heper text for site name label',
    id: 'editSiteModal.helperTextLabel',
  },
  editSiteModalLabel: {
    defaultMessage: 'Site name',
    description: 'Edit Site Modal - label',
    id: 'editSiteModal.label',
  },
  editSiteModalSubmit: {
    defaultMessage: 'Save change',
    description: 'Edit Site Modal - submit button',
    id: 'editSiteModal.submit',
  },
  editSiteModalTitle: {
    defaultMessage: 'Rename site',
    description: 'Edit Site Modal - title',
    id: 'editSiteModal.title',
  },
  siteAboutModalClose: {
    defaultMessage: 'Ok',
    description: 'Site About Modal - close button',
    id: 'siteAboutModal.close',
  },
  siteAboutModalCreateCompany: {
    defaultMessage: 'Create company',
    description: 'Site About Modal - Create company',
    id: 'siteAboutModal.createCompany',
  },
  siteAboutModalCreateNewCompany: {
    defaultMessage: 'create a new company',
    description: 'Site About Modal - Create a new company',
    id: 'siteAboutModal.createNewCompany',
  },
  siteAboutModalCreateNewSite: {
    defaultMessage: 'Create a new Site',
    description: 'Site About Modal - Create a new Site',
    id: 'siteAboutModal.createNewSite',
  },
  siteAboutModalCreateSite: {
    defaultMessage: 'Create Site',
    description: 'Site About Modal - Create Site',
    id: 'siteAboutModal.createSite',
  },
  siteAboutModalDescription: {
    defaultMessage: 'If you need a site with different billing info, ',
    description: 'Site About Modal - description',
    id: 'siteAboutModal.description',
  },
  siteAboutModalEachCompanyListItemOne: {
    defaultMessage: 'uses its own billing information',
    description: 'Site About Modal - Each company list first item',
    id: 'siteAboutModal.eachCompany.list.itemOne',
  },
  siteAboutModalEachCompanyListItemTwo: {
    defaultMessage: 'can include several sites using the same billing information',
    description: 'Site About Modal - Each company list second item',
    id: 'siteAboutModal.eachCompany.list.itemTwo',
  },
  siteAboutModalEachCompanyTitle: {
    defaultMessage: 'Each company',
    description: 'Site About Modal - Each company',
    id: 'siteAboutModal.eachCompany.title',
  },
  siteAboutModalListItemFour: {
    defaultMessage: 'All sites within a company use the same billing info.',
    description: 'Site About Modal - fourth item of list',
    id: 'siteAboutModal.list.itemFour',
  },
  siteAboutModalListItemOne: {
    defaultMessage: 'A site is usually a building or a set of buildings.',
    description: 'Site About Modal - first item of list',
    id: 'siteAboutModal.list.itemOne',
  },
  siteAboutModalListItemThree: {
    defaultMessage: 'A site is part of a company, which can include more than one site.',
    description: 'Site About Modal - third item of list',
    id: 'siteAboutModal.list.itemThree',
  },
  siteAboutModalListItemTwo: {
    defaultMessage: 'All devices within the site installation can call each other.',
    description: 'Site About Modal - second item of list',
    id: 'siteAboutModal.list.itemTwo',
  },
  siteAboutModalTitle: {
    defaultMessage: 'What is a site?',
    description: 'Site About Modal - title',
    id: 'siteAboutModal.title',
  },
  sitesDelete: {
    defaultMessage: 'Delete',
    description: 'Sites - Delete',
    id: 'sites.delete',
  },
  sitesSiteDetail: {
    defaultMessage: 'Site detail',
    description: 'Sites - Site detail',
    id: 'sites.siteDetail',
  },
  sitesTitle: {
    defaultMessage: 'Sites',
    description: 'Sites - List of Sites',
    id: 'sites.title',
  },
  sitesToolsAddNewSite: {
    defaultMessage: 'Create site',
    description: 'Sites tools - Create site',
    id: 'sites.tools.addNewSite',
  },
  sitesToolsAddNewSiteMobile: {
    defaultMessage: 'Site',
    description: 'Sites tools - Create site on mobile device',
    id: 'sites.tools.addNewSiteMobile',
  },
});
