import { DEVICE_TYPE } from '../../../../config/devices';
import { IButtonConfiguration, IDevice, IDeviceFeature, isButtonConfigurationFeature } from '../../store/deviceStore';

export function getDeviceMaxButtonCount(deviceDetail: IDevice) {
  if (deviceDetail !== undefined) {
    if (deviceDetail.type === DEVICE_TYPE.IP_ONE_INTERCOM) {
      return 1;
    }

    if (deviceDetail.type === DEVICE_TYPE.IP_INTERCOM) {
      if (/2\.21\.\d+\.([01345789]|\d{2,})/.test(deviceDetail.deviceType)) {
        return 1;
      }

      if (/^2\.7\.\d+\.\d+$/.test(deviceDetail.deviceType)) {
        return 2;
      }
    }
  }

  return undefined;
}

export function getButtonConfigurationFeature(
  features: IDeviceFeature[] | undefined
): IButtonConfiguration | undefined {
  if (features === undefined || features.length === 0) {
    return undefined;
  }
  let foundFeature: IButtonConfiguration | undefined;
  features.forEach((feature) => {
    if (feature !== undefined && isButtonConfigurationFeature(feature)) {
      foundFeature = feature;
    }
  });

  return foundFeature;
}
