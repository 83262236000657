import CreateIcon from '@mui/icons-material/CreateNewFolder';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import React from 'react';

function Option({ newItemId, option, renderProps }) {
  const isNewItem = option.id === newItemId;
  return (
    <Box component="span" key={option.id} {...renderProps}>
      {isNewItem ? (
        <Stack direction="row" spacing={2} sx={{ color: 'primary.main' }}>
          <CreateIcon />
          <Box>{option.label || ''}</Box>
        </Stack>
      ) : (
        <Stack direction="row" spacing={1}>
          <Box color="primary.dark" component="span" fontWeight="bold">
            {option.number || ''}
          </Box>
          <Box color={renderProps['aria-selected'] ? 'primary.main' : 'text.primary'} component="span">
            {option.name || ''}
          </Box>
        </Stack>
      )}
    </Box>
  );
}

Option.propTypes = {
  newItemId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  option: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    label: PropTypes.string,
    name: PropTypes.string,
    number: PropTypes.string,
  }).isRequired,
  renderProps: PropTypes.shape({
    'aria-selected': PropTypes.bool,
  }).isRequired,
};

export default Option;
