import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { BillingInfoModal } from '../../../../components';
import BillingCompany from './BillingCompany';
import BillingHeader from './BillingHeader';
import ChangeBillingInfo from './ChangeBillingInfo';
import PurchaseOrderInput from './PurchaseOrderInput';

const styles = {
  billingInfoWrapper: {
    marginBottom: {
      sm: 3,
      xs: 2,
    },
    whiteSpace: {
      sm: 'nowrap',
      xs: 'wrap',
    },
  },
  root: {
    mb: {
      sm: 2,
      xs: 3,
    },
  },
};

const BillingInfo = ({ company, onEditBilling, onOrderNumberChange }) => {
  const [number, setNumber] = useState(null);
  const [value] = useDebounce(number, 500);

  useEffect(() => {
    if (onOrderNumberChange) {
      if (value && value !== '') {
        onOrderNumberChange(value);
      } else if (value === '') {
        onOrderNumberChange(null);
      }
    }
  }, [value]);

  const handleChange = (event) => {
    const actualEvent = { ...event };
    if (actualEvent.target.value.length <= 12) {
      if (actualEvent.target.value.length > 0) {
        setNumber(actualEvent.target.value.replace(' ', ''));
      } else {
        setNumber('');
      }
    }
  };

  return (
    <>
      <Grid container direction="column" sx={styles.root}>
        <BillingHeader company={company} onEditBilling={onEditBilling} />
        <Grid item sx={styles.billingInfoWrapper} xs={12}>
          <Grid container direction="row">
            <Grid item lg={9} sm={12} xs={9}>
              <Box pt={1}>
                <BillingCompany company={company} />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {onOrderNumberChange && <PurchaseOrderInput number={number} onInputChange={handleChange} />}
        {!company.billingInfoEditable && <ChangeBillingInfo />}
      </Grid>
      <BillingInfoModal editableCurrency={false} />
    </>
  );
};

BillingInfo.propTypes = {
  company: PropTypes.shape({
    address: PropTypes.shape({
      city: PropTypes.string,
      country: PropTypes.string,
      postalCode: PropTypes.string,
      street: PropTypes.string,
    }),
    billingInfoEditable: PropTypes.bool,
    name: PropTypes.string,
  }).isRequired,
  onEditBilling: PropTypes.func.isRequired,
  onOrderNumberChange: PropTypes.func,
};

BillingInfo.defaultProps = {
  onOrderNumberChange: null,
};

export default BillingInfo;
