import React from 'react';
import { useAppContext } from '../../pages/App/AppContextProvider';

interface IFeature {
  children: JSX.Element;
  fallback?: JSX.Element;
  featureName: string;
}

export function useHasFeature(featureName: IFeature['featureName']): boolean {
  const { globalFeatures } = useAppContext();

  return globalFeatures.findIndex((feature: { name: string }) => feature.name === featureName) !== -1;
}

const Feature = ({ children, fallback, featureName }: IFeature): JSX.Element => {
  const hasFeature = useHasFeature(featureName);

  return hasFeature ? children : fallback || <>{false}</>;
};

Feature.defaultProps = {
  fallback: null,
};

export default Feature;
