import { DEFAULT_USER_LANGUAGE } from '../../config/consts';

export interface ILocalizationStore {
  isFetchingMessages: boolean;
  currentSiteLang: string | undefined;
  currentUserLang: string;
  landingPageSelect: ILandingPageSelectStore;
  userLanguageModal: IUserLanguageModalStore;
}

export interface ILandingPageSelectStore {
  error?: any;
  isFetching: boolean;
}

export interface IUserLanguageModalStore {
  error?: any;
  isFetching: boolean;
}

export function getInitialLocalizationStore(): ILocalizationStore {
  return {
    currentSiteLang: undefined,
    currentUserLang: DEFAULT_USER_LANGUAGE,
    isFetchingMessages: false,
    landingPageSelect: {
      error: null,
      isFetching: false,
    },
    userLanguageModal: {
      error: null,
      isFetching: false,
    },
  };
}
