import DoneIcon from '@mui/icons-material/Done';
import { Box, Button, Grid, Stack, TextField } from '@mui/material';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useUpdateUserBasicInfoInApartmentMutation } from '../../../../../apiRtk/be4feApi';
import { OutlinedTextField as TextFieldForm } from '../../../../../components/HookFormFields/TextField';
import { ApartmentUser } from '../../../../../modules/apartmentAdmin/data';
import { IApartmentIdsInfo } from '../../../../../modules/apartments/store';
import { TYPE_DESCRIPTOR } from '../../../../../modules/notifications';
import { addSnackbarError, addSnackbarSuccess } from '../../../../../modules/notifications/actions';
import { UserFormFieldNames } from '../../../../../validations/user/userCommonValidations';
import messages from './messages';
import { useUserDetail } from './useUserDetail';
import { IFormData } from './validationScheme';

export interface IUserDetailData {
  user: ApartmentUser;
  apartmentIdsInfo: IApartmentIdsInfo;
}

export function UserDetail(props: IUserDetailData): React.JSX.Element {
  const { dispatch, formatMessage, formMethods, isUserUWLOrGuest, lastNameLabel } = useUserDetail(props.user);
  const [editInfo, { isLoading }] = useUpdateUserBasicInfoInApartmentMutation();

  async function handleSubmit(data: IFormData): Promise<void> {
    try {
      const response = await editInfo({
        apartmentId: props.apartmentIdsInfo.apartmentId,
        companyId: props.apartmentIdsInfo.companyId,
        siteId: props.apartmentIdsInfo.siteId,
        userId: props.user.id,
        userModifyNameRequest: {
          firstName: data.firstName || undefined,
          lastName: data.lastName || '',
        },
      }).unwrap();

      dispatch(addSnackbarSuccess({ ...messages.AAUserDetailMsgSucc }));
      formMethods.reset({ firstName: response.firstName || '', lastName: response.lastName });
    } catch (e) {
      dispatch(addSnackbarError({ ...messages.AAUserDetailMsgErr }, TYPE_DESCRIPTOR));
    }
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(handleSubmit)}>
        <Stack spacing={1}>
          {!isUserUWLOrGuest && (
            <TextField
              label={formatMessage(messages.AAUserDetailEmailLabel)}
              required
              disabled
              fullWidth
              defaultValue={props.user.email}
              helperText={formatMessage(messages.AAUserDetailEmailHelper)}
            />
          )}
          <Box>
            <Grid container spacing={3}>
              {props.user.firstName && (
                <Grid item xs={6}>
                  <TextFieldForm
                    label={formatMessage(messages.AAUserDetailFirstNameLabel)}
                    required={!isUserUWLOrGuest}
                    name={UserFormFieldNames.firstName}
                    margin="normal"
                  />
                </Grid>
              )}
              <Grid item xs={!props.user.firstName ? 12 : 6}>
                <TextFieldForm
                  fullWidth={!props.user.firstName}
                  required
                  label={formatMessage(lastNameLabel)}
                  name={UserFormFieldNames.lastName}
                  margin="normal"
                />
              </Grid>
            </Grid>
          </Box>
        </Stack>
        <Stack justifyContent={'flex-end'} spacing={1} direction={'row'}>
          <Button onClick={() => formMethods.reset()} disabled={!formMethods.formState.isDirty}>
            <FormattedMessage {...messages.AAUserDetailCancel} />
          </Button>
          <Button
            color="primary"
            startIcon={<DoneIcon />}
            type="submit"
            variant="contained"
            disabled={isLoading || !formMethods.formState.isValid || !formMethods.formState.isDirty}
          >
            <FormattedMessage {...messages.AAUserDetailSubmit} />
          </Button>
        </Stack>
      </form>
    </FormProvider>
  );
}
