import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { getNewPasswordValidationSchema } from '../../../validations/newPasswordValidationSchema';
import messages from '../messages';

export function useGetValidationSchema(): Yup.AnyObjectSchema {
  const { formatMessage } = useIntl();

  return Yup.object().shape({
    ...getNewPasswordValidationSchema(formatMessage),
    passwordRepeat: Yup.string()
      .oneOf([Yup.ref('password'), undefined], formatMessage(messages.userChangePasswordDialogValidationNoMatch))
      .required(formatMessage(messages.userChangePasswordDialogValidationRequired)),
  });
}
