import BackIcon from '@mui/icons-material/ArrowBack';
import NextIcon from '@mui/icons-material/ArrowForward';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../../config/consts';
import { useUrlParams } from '../../../helpers/urlParams';
import { useImportDataContext } from '../ImportDataProvider';
import messages from './messages';

function Control({ activeStep, isLoading, onSetStep, startImport }) {
  const { companyId, siteId } = useUrlParams();
  const { importData } = useImportDataContext();
  const navigate = useNavigate();

  function handleNextStep() {
    onSetStep(activeStep + 1);
  }

  function handleStartImport() {
    startImport();
  }

  function handleCloseImport() {
    navigate(PATHS.SITE_USERS.replace(':companyId', companyId).replace(':siteId', siteId));
  }

  return (
    <Stack direction="row" justifyContent="space-between">
      <div>
        {![0, 4].includes(activeStep) && (
          <Button disabled={isLoading} onClick={() => onSetStep(activeStep - 1)} startIcon={<BackIcon />}>
            <FormattedMessage {...messages.ControlButtonBack} />
          </Button>
        )}
      </div>
      <Stack direction="row" spacing={2}>
        {activeStep < 4 && (
          <Button color="secondary" onClick={handleCloseImport}>
            <FormattedMessage {...messages.ControlButtonCancel} />
          </Button>
        )}
        {[0, 2].includes(activeStep) && (
          <Button endIcon={<NextIcon />} onClick={handleNextStep} variant="contained">
            <FormattedMessage {...messages.ControlButtonNext} />
          </Button>
        )}
        {activeStep === 1 && (
          <Button disabled={!importData.length} endIcon={<NextIcon />} onClick={handleNextStep} variant="contained">
            <FormattedMessage {...messages.ControlButtonNext} />
          </Button>
        )}
        {activeStep === 3 && (
          <Button endIcon={<NextIcon />} onClick={handleStartImport} variant="contained">
            <FormattedMessage {...messages.ControlButtonImport} />
          </Button>
        )}
        {activeStep === 4 && (
          <Button onClick={handleCloseImport} variant="contained">
            <FormattedMessage {...messages.ControlButtonCloseImport} />
          </Button>
        )}
      </Stack>
    </Stack>
  );
}

Control.propTypes = {
  activeStep: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
  onSetStep: PropTypes.func.isRequired,
  startImport: PropTypes.func,
};

Control.defaultProps = {
  isLoading: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  startImport: () => {},
};

export default Control;
