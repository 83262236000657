import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import messages from '../messages';

function useGetGlobalHooks() {
  const { formatMessage } = useIntl();
  const validationSchema = yup.object().shape({
    deactivate: yup
      .string()
      .oneOf(
        [formatMessage(messages.deactivateMy2NAccessModalInput)],
        formatMessage(messages.deactivateMy2NAccessModalInputErr)
      )
      .required(),
  });

  const formMethods = useForm({
    defaultValues: { deactivate: '' },
    mode: 'onChange',
    resolver: yupResolver(validationSchema),
  });

  return {
    error: formMethods.formState.errors.deactivate,
    formatMessage,
    formMethods,
  };
}

export default useGetGlobalHooks;
