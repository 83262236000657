import { defineMessages } from 'react-intl';

export default defineMessages({
  documentDateInfoConfirmed: {
    defaultMessage: 'Confirmed: <strong>{date}</strong>',
    description: '',
    id: 'GtcDetailCompany.documentDateInfo.confirmed',
  },
  documentDateInfoEffective: {
    defaultMessage: 'Effective: <strong>{date}</strong>',
    id: 'GtcDetailCompany.documentDateInfo.effective',
  },
  documentDateInfoPosted: {
    defaultMessage: 'Posted: <strong>{date}</strong>',
    id: 'GtcDetailCompany.documentDateInfo.posted',
  },
});
