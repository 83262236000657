import { DEVICE_TYPE, SERVICE } from '../../config/devices';
import { isIntercomOrAccessUnit } from '../../helpers/devices';
import { isActiveService } from '../../modules/devices/helpers/helpers';
import { IDevice } from '../../modules/devices/store/deviceStore';

export const showCallSettings = (device: IDevice) =>
  [
    DEVICE_TYPE.IP_INTERCOM,
    DEVICE_TYPE.IP_INTERCOM_V2,
    DEVICE_TYPE.IP_ONE_INTERCOM,
    DEVICE_TYPE.IP_STYLE,
    DEVICE_TYPE.DOOR_STATION,
    DEVICE_TYPE.ID_COMPACT,
    DEVICE_TYPE.ID_TALK,
    DEVICE_TYPE.CLIP,
    DEVICE_TYPE.ID_VIEW,
  ].includes(device.type);

export const showPhonebooks = (device: IDevice) =>
  (isActiveService(device, SERVICE.MOBILE_VIDEO) &&
    !isActiveService(device, SERVICE.LOCAL_CALLS) &&
    device.type === DEVICE_TYPE.IDT) ||
  device.type === DEVICE_TYPE.HIPMO;

export const showSwitchCodes = (device: IDevice) =>
  isIntercomOrAccessUnit(device.type) || device.type === DEVICE_TYPE.DOOR_STATION;

export const showUnlockSettings = (device: IDevice) =>
  [DEVICE_TYPE.ID_COMPACT, DEVICE_TYPE.ID_TALK, DEVICE_TYPE.CLIP, DEVICE_TYPE.ID_VIEW].includes(device.type);

export const showAccessSettings = (device: IDevice) => device.services?.ACCESS_CONTROL !== undefined;
