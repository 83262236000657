import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { animateScroll, Link } from 'react-scroll';

const styles = {
  fixed: {
    position: 'fixed',
    top: 0,
  },
  menu: {
    '& a.active': {
      color: 'primary.main',
    },
    '& a:hover, dd span:hover': {
      cursor: 'pointer',
    },
    '& dd': {
      marginInlineStart: 0,
      pt: 2,
      userSelect: 'none',
    },
    color: 'text.secondary',
    height: '100%',
    lineHeight: 1,
    overflowY: 'auto',
    position: 'absolute',
    width: 300,
  },
};

const ScrollMenu = ({ menuData }) => {
  const [fixed, setFixed] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.scrollY > 60) {
        setFixed(true);
      } else {
        setFixed(false);
      }
    };

    window.addEventListener('scroll', handleResize);

    handleResize();

    return () => window.removeEventListener('scroll', handleResize);
  }, []);

  return (
    <Box component="dl" sx={{ ...styles.menu, ...(fixed && styles.fixed) }}>
      {menuData.map((item, index) =>
        index === 0 ? (
          // eslint-disable-next-line react/no-array-index-key
          <dd key={index}>
            <Box
              color="common.black"
              component="span"
              fontWeight={900}
              onClick={() => animateScroll.scrollToTop({ duration: 1000, smooth: true })}
            >
              {item.title}
            </Box>
          </dd>
        ) : (
          // eslint-disable-next-line react/no-array-index-key
          <dd key={index}>
            <Link activeClass="active" duration={1000} smooth spy to={item.scrollLink}>
              {item.title}
            </Link>
          </dd>
        )
      )}
    </Box>
  );
};

ScrollMenu.propTypes = {
  menuData: PropTypes.arrayOf(
    PropTypes.shape({
      scrollLink: PropTypes.string,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    })
  ).isRequired,
};

export default ScrollMenu;
