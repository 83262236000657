import { defineMessages } from 'react-intl';

export default defineMessages({
  AddButton: {
    defaultMessage: 'Add more contacts',
    description: 'Add button label',
    id: 'callSettingsListActions.AddButton',
  },
  Header: {
    defaultMessage: 'Assign devices to the display of this device',
    description: 'Call settings header text',
    id: 'compact.Header',
  },
  labelContactName: {
    defaultMessage: 'Contact name',
    description: 'Contact name label',
    id: 'listHeader.labelContactName',
  },
  labelDevicesToCall: {
    defaultMessage: 'Devices to call to',
    description: 'Devices to call label',
    id: 'listHeader.labelDevicesToCall',
  },
  placeholderAddDevice: {
    defaultMessage: 'Click to add device',
    description: 'Placeholder addDevice',
    id: 'placeholder.addDevice',
  },
  placeholderContactName: {
    defaultMessage: 'Contact name',
    description: 'Placeholder ContactName',
    id: 'placeholder.contactName',
  },
});
