import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import actions, { selectors } from '../../modules/accessLog';
import AccessLogStartTime from '../AccessLogDataGrid/components/AccessLogStartTime';
import AccessLogUser from '../AccessLogDataGrid/components/AccessLogUser';
import Modal from '../Modal';
import AccessLogAccessType from './AccessLogAccessType';
import messages from './messages';

const styles = {
  infoBox: {
    minHeight: '30px',
  },
};

const AccessLogDetailModal = ({ logId, onClose, open }) => {
  const dispatch = useDispatch();
  const data = useSelector(selectors.getAccessLogDetailData);
  const isLoading = useSelector(selectors.getAccessLogDetailIsFetching);

  useEffect(() => {
    if (open) {
      dispatch(actions.getAccessLogDetail(logId));
    }
  }, [open]);

  const values = useMemo(
    () => [
      { key: 'user', value: <AccessLogUser accessLog={data} /> },
      {
        key: 'apartments',
        value: <span>{data?.apartments?.map((item) => `${item.number} ${item.name}`).join(', ')}</span>,
      },
      { key: 'device', value: <span>{data?.device?.name}</span> },
      { key: 'method', value: <AccessLogAccessType accessLog={data} /> },
      { key: 'detail', value: <span>{data?.detail?.log || '—'}</span> },
    ],
    [data]
  );

  const renderValueTitle = (title) => (
    <Typography color="secondary" component="span">
      <FormattedMessage {...messagesMap[title]} />
      {': '}
    </Typography>
  );

  return (
    open &&
    !isLoading && (
      <Modal
        actions={
          <Button color="primary" onClick={onClose}>
            <FormattedMessage {...messages.accessTypeClose} />
          </Button>
        }
        fullWidth
        maxWidth="xs"
        name="access-log-detail-modal"
        onClose={onClose}
        open={open}
        title={<FormattedMessage {...messages.accessTypeTitle} />}
      >
        <Box sx={styles.infoBox}>
          <AccessLogStartTime accessLog={data} displayTime />
        </Box>
        {values.map((item) => (
          <Box key={item.key} sx={styles.infoBox}>
            {renderValueTitle(item.key)}
            {item.value}
          </Box>
        ))}
      </Modal>
    )
  );
};

AccessLogDetailModal.propTypes = {
  logId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default AccessLogDetailModal;

const messagesMap = {
  apartments: messages.accessTypeApartments,
  detail: messages.accessTypeDetail,
  device: messages.accessTypeDevice,
  method: messages.accessTypeMethod,
  user: messages.accessTypeUser,
};
