import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const styles = {
  inner: {
    borderColor: 'white',
    borderStyle: 'solid',
    content: '""',
    height: '30px',
    position: 'absolute',
    width: '29px',
  },
};

const QRScanArea = ({ size }) => (
  <Box
    sx={{
      '&:after': {
        ...styles.inner,
        borderWidth: '3px 3px 0 0',
        right: -7,
        top: -7,
      },
      '&:before': {
        ...styles.inner,
        borderWidth: '3px 0 0 3px',
        left: -7,
        top: -7,
      },
      boxShadow: (theme) => `0 0 0 40px ${theme.palette.qrScanner.transparent}`,
      height: `${size}px`,
      left: `calc(50% - ${size / 2 - 19}px)`,
      position: 'absolute',
      top: `calc(50% - ${size / 2}px)`,
      width: `${size}px`,
      zIndex: 1,
    }}
  >
    <Box
      sx={{
        '&:after': {
          ...styles.inner,
          borderWidth: '0 3px 3px 0',
          bottom: -7,
          right: -7,
        },
        '&:before': {
          ...styles.inner,
          borderWidth: '0 0 3px 3px',
          bottom: -7,
          left: -7,
        },
      }}
    />
    <Box
      sx={{
        '&:after': {
          background: (theme) =>
            'linear-gradient(90deg, ' +
            `${theme.palette.qrScanner.background} 0%, ` +
            `${theme.palette.qrScanner.transparent} 100%)`,
          content: '""',
          height: '178px',
          left: '-78px',
          position: 'absolute',
          top: '-29px',
          width: '38px',
        },
      }}
    />
  </Box>
);

QRScanArea.propTypes = {
  size: PropTypes.number,
};

QRScanArea.defaultProps = {
  size: 200,
};

export default QRScanArea;
