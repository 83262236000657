import Typography from '@mui/material/Typography';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ItemFeature from './ItemFeature';
import LicenseAccordion from './LicenseAccordion';
import messages from './messages';
import WikipediaLink from './WikipediaLink';

const LicenseInfoNew = () => (
  <>
    <Typography>
      <FormattedMessage {...messages.licenseInfoNew} />
    </Typography>
    <Typography sx={{ my: 2 }}>
      <FormattedMessage {...messages.licenseInfoNewUpgrade} />
    </Typography>
    <LicenseAccordion title={<FormattedMessage {...messages.licenseInfoNewFreeFeaturesTitle} />}>
      <>
        <FormattedMessage {...messages.licenseInfoNewLicenseEnabled} />
        <ul>
          <li>
            <ItemFeature
              helpId={messages.licenseInfoNewFreeFeaturesFeature1Help}
              id={messages.licenseInfoNewFreeFeaturesFeature1Content}
            />
          </li>
          <li>
            <ItemFeature
              helpId={messages.licenseInfoNewFreeFeaturesFeature2Help}
              id={messages.licenseInfoNewFreeFeaturesFeature2Content}
            />
          </li>
          <li>
            <ItemFeature
              helpId={messages.licenseInfoNewFreeFeaturesFeature3Help}
              id={messages.licenseInfoNewFreeFeaturesFeature3Content}
            />
          </li>
          <li>
            <ItemFeature
              helpId={messages.licenseInfoNewFreeFeaturesFeature4Help}
              id={messages.licenseInfoNewFreeFeaturesFeature4Content}
            />
          </li>
        </ul>
      </>
    </LicenseAccordion>
    <LicenseAccordion
      description={<FormattedMessage {...messages.licenseInfoNewGoldLicenseDescription} />}
      title={<FormattedMessage {...messages.licenseInfoNewGoldLicenseTitle} />}
    >
      <>
        <FormattedMessage {...messages.licenseInfoNewLicenseEnabled} />
        <ul>
          <li>
            <ItemFeature
              helpId={messages.licenseInfoNewGoldLicenseFeature1Help}
              id={messages.licenseInfoNewGoldLicenseFeature1Content}
            />
          </li>
          <li>
            <ItemFeature
              helpId={messages.licenseInfoNewGoldLicenseFeature2Help}
              id={messages.licenseInfoNewGoldLicenseFeature2Content}
            />
          </li>
          <li>
            <ItemFeature
              helpId={messages.licenseInfoNewGoldLicenseFeature3Help}
              id={messages.licenseInfoNewGoldLicenseFeature3Conent}
            />
          </li>
        </ul>
      </>
    </LicenseAccordion>
    <LicenseAccordion
      description={<FormattedMessage {...messages.licenseInfoNewInformcastLicenseDescription} />}
      title={<FormattedMessage {...messages.licenseInfoNewInformcastLicenseTitle} />}
    >
      <Typography>
        <ItemFeature
          helpId={messages.licenseInfoNewInformcastLicenseFeatureHelp}
          id={messages.licenseInfoNewInformcastLicenseFeatureContent}
        />
      </Typography>
    </LicenseAccordion>
    <Typography sx={{ mt: 4 }}>
      <FormattedMessage
        {...messages.licenseInfoNewWikipedia}
        values={{ link: (chunks) => <WikipediaLink>{chunks}</WikipediaLink> }}
      />
    </Typography>
  </>
);

export default LicenseInfoNew;
