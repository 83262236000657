import { createSelector } from 'reselect';
import { CALL_SETTINGS, MOBILE_VIDEO_STATUS } from '../../config/consts';
import { DEVICE_TYPE, FEATURES, SERVICE } from '../../config/devices';
import { selectors as sitesSelectors } from '../sites';
import {
  findItemInDeviceTypeTemplate,
  getEmptyIntercomList,
  getFeatureByType,
  getFeaturesDevice,
  getIntercomList,
  getSelectedDevice,
} from './helpers/helpers';

export const getUsers = ({ devices }) =>
  (devices.users.data?.filter((user) => user?.email) || []).map((item) => ({
    fullname: item.firstName ? `${item.lastName} ${item.firstName}`.trim() : item.lastName,
    value: item.id,
  }));
export const isFetchingUsers = ({ devices }) => devices.users.isFetching;
export const getCurrentDeviceDetailSelector = ({ devices }) => devices.detailDevice;
export const getCurrentDeviceDataSelector = (state) => getCurrentDeviceDetailSelector(state).data || {};
export const getCurrentDeviceIdSelector = (state) => getCurrentDeviceDataSelector(state).id || null;
export const getDeviceFeatures = createSelector(getCurrentDeviceDataSelector, getFeaturesDevice);

export const getRenewConfigurationAction = () => ({ action: 'RENEW_CONFIGURATION' });
// eslint-disable-next-line complexity
export const getInitialMobileVideoService = () => (state) => {
  const data = state.devices?.detailDevice?.data?.services?.[SERVICE.MOBILE_VIDEO];
  return {
    active: data?.active || false,
    cameraSwitchAllowed: data?.cameraSwitchAllowed || false,
    externalId: data?.externalId || null,
    hasLicense: data?.hasLicense || false,
    id: data?.id || null,
    lastUpdated: data?.lastUpdated || '',
    phonebookId: data?.phonebookId || null,
    sipNumber: data?.sipNumber || '',
    sipPassword: data?.sipPassword || '',
    status: data?.status || '',
    type: data?.type || '',
  };
};

// eslint-disable-next-line complexity
export const getInitialACSService = () => (state) => {
  const data = state.devices?.detailDevice?.data?.services?.[SERVICE.ACS];
  return {
    active: data?.active || false,
    deviceName: data?.deviceName || '',
    displayPreferIcons: data?.displayPreferIcons || false,
    externalId: data?.externalId || null,
    firmware: data?.firmware || '',
    id: data?.id || null,
    ip: data?.ip || '',
    lastSeen: data?.lastSeen || '',
    lastUpdated: data?.lastUpdated || '',
    modelName: data?.modelName || '',
    status: data?.status || '',
    syncEvents: data?.syncEvents || [],
    type: data?.type || '',
  };
};

export const getMobileVideoService = (values) => (state) => {
  let data = state.devices?.detailDevice?.data?.services?.[SERVICE.MOBILE_VIDEO];
  if (!data || !data.hasOwnProperty.call('detailDevice')) {
    data = getInitialMobileVideoService()(state);
  }
  return { ...data, ...values };
};

export const getACSService = (values) => (state) => {
  const key = Object.keys(values)[0];
  const val = Object.values(values)[0];
  let data = state.devices?.detailDevice?.data?.services?.[SERVICE.ACS];
  if (!data || !data.hasOwnProperty.call('detailDevice')) {
    data = getInitialACSService()(state);
  }
  return { ...data, [key]: val };
};

export const getLocalCallsService = (values) => (state) => {
  if (!state.devices?.detailDevice?.data?.services?.[SERVICE.LOCAL_CALLS]) {
    return null;
  }

  const data = state.devices.detailDevice.data.services[SERVICE.LOCAL_CALLS];
  return { ...data, ...values };
};

export const getDeviceSite = ({ devices }) => devices?.detailDevice?.data?.site || null;

export const isAddDeviceModalOpen = (state) => state.devices.addDeviceModal.show;
export const isFinishedAddDeviceModal = (state) => state.devices.addDeviceModal.isFinished;
export const isAdvanceFinishedAddDeviceModal = (state) => state.devices.addDeviceModal.isAdvanceFinished;
export const isWaitAddDeviceModal = (state) => state.devices.addDeviceModal.wait;
export const getFinishedDataDeviceModal = (state) => state.devices.addDeviceModal.data;

export const isVerifyDeviceModalOpen = (state) => state.devices.verifyModal.show;
export const isFinishedVerifyDeviceModal = (state) => state.devices.verifyModal.isFinished;
export const getDeviceVerifyDeviceModal = (state) => state.devices.verifyModal.data || {};
export const isVerifyWaitModal = (state) => state.devices.verifyModal.wait;
export const getCallSettingsDevices = (state) => state?.devices.callSettingsDevices.data;

export const getNotVerifiedDevices = (state) => state.devices.notVerifiedDevices.data;
export const isFetchingNotVerifiedDevices = (state) => state.devices.notVerifiedDevices.isFetching;
export const isDidInvalidNotVerifiedDevices = (state) => state.devices.notVerifiedDevices.didInvalid;

const getCallSettingsDevicesChips = (state) => state.devices.callSettingsDevices.chipOptions.data;

export const getDeviceCallSettingsList = (type) => createSelector(getDeviceFeatures, getFeatureByType(type));

const getDeviceChipOptionsMobileVideo = createSelector(
  getCurrentDeviceDataSelector,
  getCallSettingsDevicesChips,
  (deviceDetail, options) => {
    if (options && options.length > 0) {
      const chipOptions = options.reduce((acc, value) => {
        if (value?.services?.MOBILE_VIDEO) {
          acc.push(value);
        }
        return acc;
      }, []);
      return chipOptions || [];
    }
    return [];
  }
);

const getDevicesChipOptionsFilteredByDeviceType = createSelector(
  getCurrentDeviceDataSelector,
  getDeviceChipOptionsMobileVideo,
  (currentDevice, devicesOptions) => {
    let devices = [];
    if (
      currentDevice?.id &&
      ![DEVICE_TYPE.ID_COMPACT, DEVICE_TYPE.CLIP, DEVICE_TYPE.ID_TALK, DEVICE_TYPE.ID_VIEW].includes(currentDevice.type)
    ) {
      devices = devicesOptions.filter(
        (item) =>
          ![
            DEVICE_TYPE.IP_INTERCOM,
            DEVICE_TYPE.IP_INTERCOM_V2,
            DEVICE_TYPE.IP_ONE_INTERCOM,
            DEVICE_TYPE.DOOR_STATION,
          ].includes(item.type)
      );
    } else {
      devices = devicesOptions;
    }

    return devices;
  }
);

export const getCallSettingsDevicesOptions = createSelector(
  getCurrentDeviceDataSelector,
  getDevicesChipOptionsFilteredByDeviceType,
  (deviceDetail, devices) => {
    const editedContactList = [];
    devices.forEach((device) => {
      if (
        deviceDetail?.services?.LOCAL_CALLS?.active === true &&
        device?.services?.MOBILE_VIDEO?.active === true &&
        (!device.services?.LOCAL_CALLS || device.services?.LOCAL_CALLS?.active === false)
      ) {
        editedContactList.push({ ...device, warning: true });
      } else {
        editedContactList.push({ ...device, warning: false });
      }
    });
    return editedContactList;
  }
);

export const getCallSettingsAllDevicesOptions = createSelector(getDeviceChipOptionsMobileVideo, (devices) => {
  const editedContactList = [];
  devices.forEach((device) => {
    if (
      device?.services?.MOBILE_VIDEO?.active === true &&
      (!device.services?.LOCAL_CALLS || device.services?.LOCAL_CALLS?.active === false)
    ) {
      editedContactList.push({ ...device, warning: true });
    } else {
      editedContactList.push({ ...device, warning: false });
    }
  });
  return editedContactList;
});

const getDefaultCompactList = createSelector(getDeviceCallSettingsList(FEATURES.CONTACT_LIST), (items) => {
  const defaultCount = CALL_SETTINGS.COMPACT.DEFAULT_LIST_COUNT;
  let createdItems = [];

  const addEmptyItems = (createdListItems, maxLength) => {
    const listItems = createdListItems;
    for (let i = 0; i < maxLength; i += 1) {
      listItems.push({
        ...CALL_SETTINGS.COMPACT.DEFAULT_OBJECT,
        index: listItems.length + 1,
      });
    }

    return listItems;
  };

  if (items) {
    createdItems = items.contacts.slice();
    createdItems = createdItems.sort((a, b) => (a.name > b.name ? 1 : -1));

    if (items.contacts.length < defaultCount) {
      createdItems = addEmptyItems(createdItems, defaultCount - items.contacts.length);
    }
  } else {
    createdItems = addEmptyItems(createdItems, defaultCount);
  }

  return createdItems;
});

export const getDefaultIntercomList = createSelector(
  getDeviceCallSettingsList(FEATURES.BUTTON_CONFIGURATION),
  getDeviceCallSettingsList(FEATURES.CONTACT_LIST),
  getDeviceCallSettingsList(FEATURES.DISPLAY),
  getCurrentDeviceDataSelector,
  (items, contacts, displays, deviceDetail) =>
    items ? getIntercomList(items, contacts, displays, deviceDetail, true) : getEmptyIntercomList(deviceDetail, true)
);

export const getCompactContactList = createSelector(
  getDefaultCompactList,
  getCallSettingsDevicesOptions,
  (contacts, devices) => {
    const editedContacts = [];
    if (contacts) {
      contacts.forEach((item, index) => {
        const selectedDeviceId =
          item.contactItems?.find((contact) => contact.type === SERVICE.MOBILE_VIDEO)?.deviceConfigId || [];

        editedContacts.push({
          ...item,
          index,
          selectedDevices: getSelectedDevice(devices, selectedDeviceId),
        });
      });
    }

    return editedContacts;
  }
);

const getDefaultIdTalkList = createSelector(
  getDeviceCallSettingsList(FEATURES.BUTTON_CONFIGURATION),
  getDeviceCallSettingsList(FEATURES.CONTACT_LIST),
  (items, contacts) => {
    const defaultCount = CALL_SETTINGS.ID_TALK.DEFAULT_LIST_COUNT;
    const createdItems = [];

    const setList = () => {
      const maximumIndex = Math.max(...items.buttons.map((item) => item.index));
      for (let i = 0; i < maximumIndex || i < defaultCount; i += 1) {
        const currentButton = items?.buttons?.find((item) => item.index === i + 1);
        if (currentButton) {
          createdItems.push({
            button: currentButton,
            contact: contacts?.contacts?.find((item) => currentButton.externalId === item.externalId),
          });
        } else {
          createdItems.push({ ...CALL_SETTINGS.ID_TALK.DEFAULT_OBJECT });
        }
      }
    };

    const setEmptyList = () => {
      for (let i = 0; i < defaultCount; i += 1) {
        createdItems.push({ ...CALL_SETTINGS.ID_TALK.DEFAULT_OBJECT });
      }
    };

    if (items) {
      setList();
    } else {
      setEmptyList();
    }

    return createdItems;
  }
);

export const getIdTalkButtonList = createSelector(
  getDefaultIdTalkList,
  getCallSettingsDevicesOptions,
  (buttons, devices) => {
    const editedButtons = [];
    let selectedDeviceId;
    let ringingGroup;

    if (buttons) {
      buttons.forEach((item, index) => {
        selectedDeviceId =
          item.button?.contacts?.[0]?.contactItems?.find((button) => button.type === SERVICE.RINGING_GROUP)?.members ||
          null;

        ringingGroup =
          item.button?.contacts?.[0]?.contactItems?.find((button) => button.type === SERVICE.RINGING_GROUP)
            ?.sipNumber || null;

        editedButtons.push({
          button: item.button || null,
          contact: item.contact || null,
          index,
          ringingGroup,
          selectedDevices: getSelectedDevice(devices, selectedDeviceId),
        });
      });
    }

    return editedButtons;
  }
);

export const getRingingGroupSizeLimit = (state) =>
  state?.sites?.data?.services?.MOBILE_VIDEO?.ringingGroupSizeLimit || 0;

export const getIsManualIntercomWarning = (state) =>
  !state.devices?.detailDevice?.data?.services?.ACS?.active &&
  [DEVICE_TYPE.IP_INTERCOM, DEVICE_TYPE.IP_INTERCOM_V2, DEVICE_TYPE.IP_ONE_INTERCOM].includes(
    state.devices?.detailDevice?.data?.type
  );

export const getDtmfCodeData = (state) => {
  const dtmfData = state.devices?.detailDevice?.data?.features?.find((item) => item.type === FEATURES.DTMF_CODE);

  return dtmfData || null;
};

export const getDtmfCode = (state) => {
  const dtmfCode = state.devices?.detailDevice?.data?.features?.find((item) => item.type === FEATURES.DTMF_CODE);

  return dtmfCode?.dtmfCode || '';
};

export const getCallSettingsDevicesIsFetching = (state) => state.devices.callSettingsDevices.isFetching;
export const getCallSettingsDevicesDataAreLoaded = (state) =>
  state.devices.callSettingsDevices.chipOptions?.data?.length > 0;

export const getCallSettingsDevicesIsDidInvalid = (state) => state.devices.callSettingsDevices.didInvalid;

export const getCallSettingsDevicesPage = (state) => state.devices.callSettingsDevices.page;

export const getCallSettingsDevicesRowsPerPage = (state) => state.devices.callSettingsDevices.rowsPerPage;

export const getCallSettingsDevicesResultsFiltered = (state) => state.devices.callSettingsDevices.resultsFiltered;

export const getCallSettingsDevicesOrder = (state) => state.devices.callSettingsDevices.order;

export const showPurchaseButton = createSelector(
  getCurrentDeviceDetailSelector,
  sitesSelectors.getSiteData,
  (device, site) => {
    if (
      device?.data?.services?.[SERVICE.MOBILE_VIDEO] &&
      site?.services?.[SERVICE.MOBILE_VIDEO]?.licenceModel &&
      device.data.services[SERVICE.MOBILE_VIDEO].active &&
      !device.data.services[SERVICE.MOBILE_VIDEO].hasLicence &&
      !(
        device.data.type === DEVICE_TYPE.IDT &&
        device.data.services[SERVICE.LOCAL_CALLS] &&
        device.data.services[SERVICE.LOCAL_CALLS].active === true
      ) &&
      ![
        MOBILE_VIDEO_STATUS.STANDARD_DEACTIVATED,
        MOBILE_VIDEO_STATUS.TEMPORARY,
        MOBILE_VIDEO_STATUS.TRIAL_ACTIVE,
        MOBILE_VIDEO_STATUS.STANDARD_RENEWAL,
      ].includes(site.services[SERVICE.MOBILE_VIDEO].licenceModel.status) &&
      site.services[SERVICE.MOBILE_VIDEO].licenceModel.additional >
        site.services[SERVICE.MOBILE_VIDEO].licenceModel.licencesPool
    ) {
      return true;
    }
    return false;
  }
);

export const showConsumeButton = createSelector(
  getCurrentDeviceDetailSelector,
  sitesSelectors.getSiteData,
  // eslint-disable-next-line complexity
  (device, site) => {
    if (
      device?.data?.services?.[SERVICE.MOBILE_VIDEO] &&
      site?.services?.[SERVICE.MOBILE_VIDEO]?.licenceModel &&
      ((device.data.services[SERVICE.MOBILE_VIDEO].active &&
        !device.data.services[SERVICE.MOBILE_VIDEO].hasLicence &&
        !(device.data.type === DEVICE_TYPE.IDT && device.data.services[SERVICE.LOCAL_CALLS]?.active) &&
        [MOBILE_VIDEO_STATUS.STANDARD].includes(site.services[SERVICE.MOBILE_VIDEO].licenceModel.status) &&
        site.services[SERVICE.MOBILE_VIDEO].licenceModel.additional <=
          site.services[SERVICE.MOBILE_VIDEO].licenceModel.licencesPool) ||
        (device.data.services[SERVICE.MOBILE_VIDEO].active &&
          !device.data.services[SERVICE.MOBILE_VIDEO].hasLicence &&
          !(device.data.type === DEVICE_TYPE.IDT && device.data.services[SERVICE.LOCAL_CALLS]?.active) &&
          [MOBILE_VIDEO_STATUS.STANDARD_RENEWAL].includes(site.services[SERVICE.MOBILE_VIDEO].licenceModel.status)))
    ) {
      return true;
    }
    return false;
  }
);

export const isRCModalOpen = (state) => state.devices.rcModal.show;
export const getDeviceRCModal = (state) => state.devices.rcModal.device;
export const getDeviceListIsInvalid = (state) => state.devices.list.didInvalid;
export const getCompanyDeviceTypes = (state) => state.company.deviceTypes?.data?.results || [];
export const getCurrentDeviceType = (state) => getCurrentDeviceDataSelector(state)?.type || null;

export const getIsRcActivable = (state) => {
  const type = getCurrentDeviceType(state);
  const deviceTypes = getCompanyDeviceTypes(state);
  return (findItemInDeviceTypeTemplate(deviceTypes, type, 'availableServices') || []).includes(
    SERVICE.REMOTE_CONFIGURATION
  );
};

export const getIsMvActivable = (state) => {
  const type = getCurrentDeviceType(state);
  const deviceTypes = getCompanyDeviceTypes(state);
  return (findItemInDeviceTypeTemplate(deviceTypes, type, 'availableServices') || []).includes(SERVICE.MOBILE_VIDEO);
};

export const getIsAcActivable = (state) => !!getCurrentDeviceDataSelector(state)?.services?.[SERVICE.ACCESS_CONTROL];

export const getIsApartmentActivable = (state) => {
  const type = getCurrentDeviceType(state);
  const deviceTypes = getCompanyDeviceTypes(state);
  return findItemInDeviceTypeTemplate(deviceTypes, type, 'availableForApartment') || false;
};

export const getDevicesLoadedFirstTime = (state) => state.devices.userProfile.isFirstTimeLoaded;

export const isDeviceRacActive = (state) =>
  state.devices.detailDevice.data?.services?.[SERVICE.ACCESS_CONTROL]?.active || false;

export const getDeleteModalDeviceId = (state) => state.devices.deleteDeviceModal.id;

export const getDeleteModalShow = (state) => state.devices.deleteDeviceModal.show;

export const getDeleteModalProcessing = (state) => state.devices.deleteDeviceModal.processing;

export const isDeviceEditing = (state) => state.devices.detailDevice.isEditing;

export const isDeviceInApartment = (state) => !!state.devices.detailDevice.data?.apartmentId;

export const didInvalidDeviceDetail = (state) => state?.devices?.detailDevice?.didInvalid;
