import { defineMessages } from 'react-intl';

export default defineMessages({
    CreditSummaryWarningInsufficientCredit: {
      defaultMessage: 'This month, a total of <strong>{licencesPool} {licencesPool, plural, one {credit} other {credits}}</strong>' +
        'is sufficient to cover <strong>{apartmentsCovered} out of {apartmentsNeeded}</strong> apartments ' +
        'that need to be licensed. You can later deactivate some apartments and activate others instead.',
      id: 'creditSummary.warning.insufficientCredit',
    },
});
