import { ReactNode } from 'react';
import { isAggregatedDataType, MuiRow } from '../aggregatedCellContentRenderer';
import { IDataGridAggregatedRowMenuActions } from '../data';
import { renderRowMenuComponent } from './rowMenu';

export function renderRowMenuColumnContent<TAggregatedItem extends object, TItem extends object>({
  row,
  rowMenuActions,
}: {
  rowMenuActions: IDataGridAggregatedRowMenuActions<TAggregatedItem, TItem>;
  row: MuiRow<TItem | TAggregatedItem>;
}): ReactNode {
  if (isAggregatedDataType(row)) {
    return renderRowMenuComponent(
      rowMenuActions.aggregatedRowMenuActions
        ? rowMenuActions.aggregatedRowMenuActions((row as MuiRow<TAggregatedItem>).data)
        : undefined
    );
  }
  return renderRowMenuComponent(rowMenuActions.rowMenuActions((row as MuiRow<TItem>).data));
}
