import GetAppIcon from '@mui/icons-material/GetApp';
import Button from '@mui/material/Button';
import MaterialLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { DataGridSearchField, Title } from '../../components';
import PaymentsTable from '../../components/PaymentsTable';
import { PATHS } from '../../config/consts';
import { PAYMENT_CONTEXT } from '../../config/payments';
import { changeFilter, dataGridProps, getDataGridSelector } from '../../helpers/dataGrid';
import { useUrlParams } from '../../helpers/urlParams';
import commonActions from '../../modules/common';
import actions, { NAME as PAYMENTS_MODULE } from '../../modules/payments';
import { useAppContext } from '../../pages/App/AppContextProvider';
import messages from './messages';

const SitePayments = ({ onLoadPayments, onSearch, paymentsTable }) => {
  const { feSiteRoles } = useAppContext();
  const { companyId, siteId } = useUrlParams();
  const [fulltext, setFulltext] = useState(paymentsTable.filter.fulltext || '');

  const onChangeFilter = ({ target }) => {
    const { name, value } = target;
    setFulltext(value);
    changeFilter(onSearch, actions.getPaymentsList, {
      ...paymentsTable,
      companyId,
      context: PAYMENT_CONTEXT.SITE_PAYMENTS,
      siteId,
    })(name, value);
  };

  if (!feSiteRoles.isSiteAdminPayment && (feSiteRoles.isSiteAdmin || feSiteRoles.isSiteUser)) {
    return <Navigate replace to={PATHS.SITE_DASHBOARD.replace(':companyId', companyId).replace(':siteId', siteId)} />;
  }

  return (
    <>
      <Title
        heading={<FormattedMessage {...messages.sitePaymentsCompleteListSitePaymentsTitle} />}
        rightSide={
          paymentsTable.resultsTotal > 0 && <DataGridSearchField fulltext={fulltext} onChangeFilter={onChangeFilter} />
        }
      />
      <PaymentsTable
        {...paymentsTable}
        action={(payment) => (
          <div>
            {['INVOICE_ISSUED', 'BEFORE_COMPLETION', 'COMPLETED'].includes(payment.purchaseState) && (
              <MaterialLink component="a" href={payment.link} rel="noreferrer noopener" target="_blank">
                <Button color="primary" startIcon={<GetAppIcon />} variant="outlined">
                  <FormattedMessage {...messages.sitePaymentsCompleteListSitePaymentsInvoice} />
                </Button>
              </MaterialLink>
            )}
          </div>
        )}
        companyId={companyId}
        context={PAYMENT_CONTEXT.SITE_PAYMENTS}
        onLoadPayments={onLoadPayments}
        siteId={siteId}
        text={
          feSiteRoles.isCompanyAdmin && (
            <Typography>
              <FormattedMessage
                {...messages.sitePaymentsCompleteListSitePaymentsCompleteList}
                values={{
                  companyPaymentsLink: (chunks) => (
                    <MaterialLink component={Link} to={PATHS.PAYMENTS_OVERVIEW.replace(':companyId', companyId)}>
                      {chunks}
                    </MaterialLink>
                  ),
                }}
              />
            </Typography>
          )
        }
      />
    </>
  );
};

SitePayments.propTypes = {
  onLoadPayments: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  paymentsTable: dataGridProps.isRequired,
};

const mapStateToProps = (state) => ({
  paymentsTable: getDataGridSelector(state, PAYMENTS_MODULE, 'list'),
});

const dispatchToProps = {
  onLoadPayments: actions.getPaymentsList,
  onSearch: commonActions.search,
};

export default connect(mapStateToProps, dispatchToProps)(SitePayments);
