import PropTypes from 'prop-types';
import React from 'react';
import RowMenu from '../../../../components/DataGrid/RowMenu';
import EditDevice from './EditDevice';
import LogActivation from './LogActivation';
import RacActivation from './RacActivation';
import RemoveDevice from './RemoveDevice';

const DataGridRowMenu = ({
  companyId,
  deviceId,
  isLogActive,
  isRacActive,
  onLogActivation,
  onRacActivation,
  onRemove,
  siteId,
}) => {
  const handleMenuClick = (fn) => {
    if (fn) {
      fn();
    }
  };

  return (
    <RowMenu
      menuItems={
        <div>
          <EditDevice companyId={companyId} deviceId={deviceId} siteId={siteId} />
          <RemoveDevice onClick={() => handleMenuClick(onRemove)} />
          <RacActivation isRacActive={isRacActive} onClick={() => handleMenuClick(onRacActivation)} />
          <LogActivation isLogActive={isLogActive} onClick={() => handleMenuClick(onLogActivation)} />
        </div>
      }
    />
  );
};

DataGridRowMenu.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  deviceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  isLogActive: PropTypes.bool.isRequired,
  isRacActive: PropTypes.bool.isRequired,
  onLogActivation: PropTypes.func.isRequired,
  onRacActivation: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  siteId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default DataGridRowMenu;
