import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Roles } from '../modules/commonTypes';
import { ApartmentAdminUserDetailPage } from '../pages/apartmentAdmin/ApartmentAdminUserDetailPage/ApartmentAdminUserDetailPage';
import { ApartmentAdminUsersOverviewPage } from '../pages/apartmentAdmin/ApartmentAdminUsersOverviewPage/ApartmentAdminUsersOverviewPage';
import { PrivateRoute } from './components/PrivateRoute';
import { apartmentComposedIdName } from './composedIds/config';

export function ApartmentAdminRoutes(): React.JSX.Element {
  return (
    <Routes>
      <Route
        path={'/*'}
        element={
          <PrivateRoute
            allowedRoles={{
              deepCheckRoles: [Roles.APARTMENT_ADMIN, Roles.COMPANY_ADMIN, Roles.ADMIN, Roles.COMPANY_CREATOR],
            }}
          >
            <ApartmentAdminUsersOverviewPage />
          </PrivateRoute>
        }
      />

      <Route
        path={`/:${apartmentComposedIdName}/user-detail/:userId`}
        element={
          <PrivateRoute
            allowedRoles={{
              deepCheckRoles: [Roles.APARTMENT_ADMIN, Roles.COMPANY_ADMIN, Roles.ADMIN, Roles.COMPANY_CREATOR],
            }}
          >
            <ApartmentAdminUserDetailPage />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
