import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import ButtonDanger from '../ButtonDanger';
import Modal from '../Modal';
import messages from './messages';

const CloseBatchDialog = ({ batch, defaultResolution, onCloseBatch, onCloseDialog, show }) => {
  const [resolution, setResolution] = useState(defaultResolution);
  const hasResolutionError = resolution.trim() === '';
  return (
    <Modal
      actions={
        <div>
          <Button onClick={onCloseDialog}>
            <FormattedMessage {...messages.closeBatchDialogCancel} />
          </Button>
          <ButtonDanger
            disabled={hasResolutionError}
            onClick={() => {
              onCloseBatch(batch.id, { resolution });
              onCloseDialog();
            }}
            startIcon={<DeleteIcon />}
          >
            <FormattedMessage {...messages.closeBatchDialogClose} />
          </ButtonDanger>
        </div>
      }
      headerVariant="danger"
      name="close-batch-dialog"
      onClose={onCloseDialog}
      open={show}
      title={<FormattedMessage {...messages.closeBatchDialogTitle} />}
    >
      <Typography component="p">
        <FormattedMessage {...messages.closeBatchDialogBody} />
      </Typography>
      <Typography component="p" sx={{ py: 2 }}>
        <FormattedMessage
          {...messages.closeBatchDialogConfirmQuestion}
          values={{
            name: batch.name,
            strong: (chunks) => <strong>{chunks}</strong>,
          }}
        />
      </Typography>
      <TextField
        error={hasResolutionError}
        fullWidth
        helperText={hasResolutionError ? <FormattedMessage {...messages.closeBatchDialogResolutionRequired} /> : null}
        label={<FormattedMessage {...messages.closeBatchDialogResolution} />}
        name="resolution"
        onChange={(event) => setResolution(event.target.value)}
        value={resolution}
        variant="outlined"
      />
    </Modal>
  );
};

CloseBatchDialog.propTypes = {
  batch: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  defaultResolution: PropTypes.string,
  onCloseBatch: PropTypes.func.isRequired,
  onCloseDialog: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

CloseBatchDialog.defaultProps = {
  defaultResolution: 'Cancel batch resolution',
};

export default CloseBatchDialog;
