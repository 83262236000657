import TablePagination from '@mui/material/TablePagination';
import PropTypes from 'prop-types';
import React from 'react';

const Pagination = ({
  colSpan,
  labelRowsPerPage,
  onRowsPerPageChange,
  onSelectPage,
  page,
  resultsFiltered,
  rowsPerPage,
}) => (
  <TablePagination
    colSpan={colSpan}
    component="div"
    count={resultsFiltered}
    labelRowsPerPage={labelRowsPerPage}
    onPageChange={(_, value) => onSelectPage(value + 1)}
    onRowsPerPageChange={({ target }) => onRowsPerPageChange(target.value)}
    page={page > 0 ? page - 1 : 0}
    rowsPerPage={rowsPerPage}
    rowsPerPageOptions={[10, 25, 50, 100]}
    showFirstButton
    showLastButton
    sx={{
      '& .MuiInputBase-root': {
        ml: { sm: 1, xs: 0 },
        mr: { sm: 4, xs: 1 },
      },
      '& .MuiTablePagination-actions': {
        ml: { sm: '20px !important', xs: '2px !important' },
      },
      '& .MuiTablePagination-actions button': {
        px: { sm: 1, xs: 0.5 },
      },
      '& .MuiToolbar-root': {
        pl: { sm: '8px !important', xs: '4px !important' },
        pr: { sm: '0px !important', xs: '0px !important' },
      },
    }}
  />
);

Pagination.propTypes = {
  colSpan: PropTypes.number.isRequired,
  labelRowsPerPage: PropTypes.node.isRequired,
  onRowsPerPageChange: PropTypes.func.isRequired,
  onSelectPage: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  resultsFiltered: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default Pagination;
