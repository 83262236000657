import { Divider } from '@mui/material';
import React, { ReactNode } from 'react';
import RowMenu from '../../DataGrid/RowMenu';
import RowMenuItem from '../../DataGrid/RowMenuItem';
import { IRowAction } from '../data';

export function renderRowMenuComponent(actions?: IRowAction[]): ReactNode {
  if (!actions || (actions && !actions.length)) {
    return <>{false}</>;
  }
  const menuItems: ReactNode[] = [];
  actions.forEach((action, index) => {
    if (action.hasTopSeparator) {
      menuItems.push(<Divider key={`${index}-separator`} />);
    }
    menuItems.push(
      <RowMenuItem
        colorIcon={action.hasErrorStyle ? 'error.main' : undefined}
        colorText={action.hasErrorStyle ? 'error.main' : undefined}
        icon={action.icon as React.JSX.Element}
        label={action.label}
        disabled={action.isDisabled}
        onClick={() => handleOnClick({ isDisabled: action.isDisabled, onClick: action.onClick })}
        key={`${index}-menu-item`}
      />
    );
  });

  return <RowMenu menuItems={menuItems} />;
}

export function handleOnClick({ isDisabled, onClick }: { isDisabled?: boolean; onClick: IRowAction['onClick'] }) {
  if (!isDisabled) {
    onClick();
  }
}
