import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

interface SelectIdAlertTextProps {
  locationIds?: string[];
  selected: string;
}

export const SelectIdAlertText = ({ locationIds, selected }: SelectIdAlertTextProps) => {
  if (locationIds?.includes(selected)) {
    if (locationIds.length === 1) {
      return <FormattedMessage {...messages.configureMy2NAccessModal4ManageInfo} />;
    } else {
      return <FormattedMessage {...messages.configureMy2NAccessModal4ManageWarnA} />;
    }
  } else if (selected === 'GenerateNew') {
    return <FormattedMessage {...messages.configureMy2NAccessModal4ManageWarnB} />;
  } else if (selected === 'OwnId') {
    return <FormattedMessage {...messages.configureMy2NAccessModal4ManageWarnC} />;
  }
  return null;
};
