import { defineMessages } from 'react-intl';

export default defineMessages({
  pricesAddAnotherDevice: {
    defaultMessage:
      '<title>What happens when you add a new mobile phone</title>' +
      ' <text>When you add a new mobile phone to Mobile Video Service within a subscribed' +
      ' period, a proportional amount of credits is deducted from your pool. The amount is' +
      ' determined by the number of days remaining in the month. If you have a sufficient' +
      ' amount of credits in your pool, you can use them to activate the service for the newly' +
      ' added phone. If not, we’ll ask you to purchase another subscription' +
      ' (more credits).</text>',
    description: 'Add another device',
    id: 'prices.ADD_ANOTHER_DEVICE',
  },
  pricesAnnualSubscription: {
    defaultMessage: 'annual subscription',
    description: 'Annual subscription',
    id: 'prices.ANNUAL_SUBSCRIPTION',
  },
  pricesAnnualSubscriptionCreditPool: {
    defaultMessage:
      '<title>Annual subscription: How the credit pool works</title>' +
      ' <text>At the beginning of every month, credits are taken from your Mobile Video' +
      ' credit pool. The amount equals the number of phones you have currently active in' +
      ' the service. If you don’t have enough credits left for next month, we automatically' +
      ' charge your credit card for the difference.</text>',
    description: 'Annual subscription credit pool',
    id: 'prices.ANNUAL_SUBSCRIPTION_CREDIT_POOL',
  },
  pricesCreditPoolSystemMdu: {
    defaultMessage:
      '<title>Credit system</title>' +
      ' <text>When you purchase a subscription, a credit pool is created.' +
      ' This makes long-term subscriptions convenient. Each month, a certain' +
      ' amount of credits is deducted from your credit pool. {credits}' +
      ' {credits, plural, one {credit is} other {credits are}} needed' +
      ' for a monthly subscription per 1 apartment. The credit pool is replenished' +
      ' through recurring payments or vouchers from your distributor.</text>',
    description: 'Credit pool system mdu',
    id: 'prices.CREDIT_POOL_SYSTEM.mdu',
  },
  pricesCreditPoolSystemNonMdu: {
    defaultMessage:
      '<title>Credit system</title>' +
      ' <text>When you purchase a subscription, a credit pool is created.' +
      ' This makes long-term subscriptions convenient. Each month, a certain' +
      ' amount of credits is deducted from your credit pool. {credits}' +
      ' {credits, plural, one {credit is} other {credits are}} needed' +
      ' for a monthly subscription of 1 mobile phone. The credit pool is replenished' +
      ' through recurring payments or vouchers from your distributor.</text>',
    description: 'Credit pool system non mdu',
    id: 'prices.CREDIT_POOL_SYSTEM.nonMdu',
  },
  pricesExamplePricePerApartment: {
    defaultMessage:
      'Example: When you activate the service for 10 apartments, your monthly' +
      ' subscription amounts to {price} ({priceVat} incl. VAT). Each apartment is limited' +
      ' to {deviceLimit} paid devices (mobile phones, tablets, and 3rd party devices).',
    description: 'Prices - example per apartment',
    id: 'prices.EXAMPLE.PRICE_PER_APARTMENT',
  },
  pricesExamplePricePerDevice: {
    defaultMessage:
      'Example: When you add a 2N IP intercom and a smartphone to' +
      ' Mobile Video, your monthly subscription amounts to {price} ({priceVat} incl. VAT).',
    description: 'Prices - example per device',
    id: 'prices.EXAMPLE.PRICE_PER_DEVICE',
  },
  pricesIntroductionMdu: {
    defaultMessage:
      '2N<sup>®</sup> Mobile Video is a subscription-based service. You pay for' +
      ' dwelling units in your building. The service runs on 2N devices for free.*' +
      ' The amount you pay (monthly or annually) is equal to the number' +
      ' of apartments in your building.',
    description: 'Prices Introduction mdu',
    id: 'prices.INTRODUCTION.mdu',
  },
  pricesIntroductionNonMdu: {
    defaultMessage:
      '2N<sup>®</sup> Mobile Video is a subscription-based service. You pay for' +
      ' smartphones which use Mobile Video. The service runs on 2N devices for free.*' +
      ' The amount you pay (monthly or annually) is equal to the number' +
      ' of mobile phones added to Mobile Video.',
    description: 'Prices Introduction non mdu',
    id: 'prices.INTRODUCTION.nonMdu',
  },
  pricesMonthlySubscription: {
    defaultMessage: 'monthly subscription',
    description: 'Monthly subscription',
    id: 'prices.MONTHLY_SUBSCRIPTION',
  },
  pricesMonthlySubscriptionCreditPool: {
    defaultMessage:
      '<title>Monthly subscription: How the credit pool works</title>' +
      ' <text>The pool is replenished by your credit card payments at monthly intervals.' +
      ' The amount corresponds to the number of active Mobile Video phones at the beginning of' +
      ' the month. Credits purchased are immediately deducted to cover your subscriptions for' +
      ' the current month. Therefore, the number of credits in the pool usually' +
      ' equals zero.</text>',
    description: 'Monthly subscription credit pool',
    id: 'prices.MONTHLY_SUBSCRIPTION_CREDIT_POOL',
  },
  pricesNotEnoughCredits: {
    defaultMessage:
      '<title>What happens if you don’t have enough credits</title>' +
      ' <text>If the amount of credits doesn’t cover the subscriptions of all your mobile' +
      ' phones for the next month (for example, if you add a new phone), you will be notified' +
      ' and asked to purchase additional subscriptions.</text>',
    description: 'Not enough credits',
    id: 'prices.NOT_ENOUGH_CREDITS',
  },
  pricesPaymentMehtod: {
    defaultMessage:
      '<title>Payment method</title>' +
      ' <text>You can pay for your Mobile Video subscriptions with a credit card. When you' +
      ' activate recurring payments with your credit card, your card will be charged at the' +
      ' beginning of each month (monthly subscription) or when you run out of credits' +
      ' (annual subscription).</text>',
    description: 'Payment method',
    id: 'prices.PAYMENT_METHOD',
  },
  pricesPriceInclVat: {
    defaultMessage: '({price} incl. VAT)',
    description: 'Price incl vat',
    id: 'prices.PRICE_INCL_VAT',
  },
  pricesPricePerApartment: {
    defaultMessage: '<b>{price}</b><small> per apartment</small>',
    description: 'Price per device',
    id: 'prices.PRICE_PER_APARTMENT',
  },
  pricesPricePerDevice: {
    defaultMessage: '<b>{price}</b><small> per device</small>',
    description: 'Price per device',
    id: 'prices.PRICE_PER_DEVICE',
  },
  pricesRecuringPaymentTermination: {
    defaultMessage:
      '<title>How to deactivate recurring payments</title>' +
      ' <text>The "Deactivate" button on the Mobile Video dashboard lets you stop recurring' +
      ' payments. The service will stay active on all devices until you run out of credits.' +
      ' After then, it will only run on 2N devices (no mobile phones).</text>',
    description: 'Recurring payment termination',
    id: 'prices.RECURRING_PAYMENT_TERMINATION',
  },
  pricesRemoveDevice: {
    defaultMessage:
      '<title>What happens when you remove a mobile phone</title>' +
      ' <text>When you remove a mobile phone from Mobile Video service, your payment next' +
      ' month will be reduced accordingly. You can add another phone within the month' +
      ' (see the paragraph below) without being charged extra fees.</text>',
    description: 'Remove a device',
    id: 'prices.REMOVE_DEVICE',
  },
  pricesReplaceDevice: {
    defaultMessage:
      '<title>What happens when you replace a mobile phone with another one</title>' +
      ' <text>When you remove a mobile phone to be replaced by another one in Mobile' +
      ' Video, you just delete the old one. You add the new one using the original' +
      ' subscription. No additional payment is needed.</text>',
    description: 'Replace a device',
    id: 'prices.REPLACE_DEVICE',
  },
  pricesSubscriptionExplanation: {
    defaultMessage:
      '* You don’t need a subscription for any 2N device except for two - IP Handset' +
      ' and IP Phone D7A. Subscriptions are still needed for mobile phones and third-party devices.',
    description: 'Prices - subscription explanation',
    id: 'prices.SUBSCRIPTION_EXPLANATION',
  },
  pricingModalClose: {
    defaultMessage: 'Close',
    description: 'Pricing Modal - close',
    id: 'pricingModal.close',
  },
  pricingModalPricingTitle: {
    defaultMessage: '2N<sup>®</sup> Mobile video subscription',
    description: 'Pricing Modal - title',
    id: 'pricingModal.pricingTitle',
  },
});
