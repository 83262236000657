import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import CardIdentifierInput from './CardIdentifierInput';
import { CARD_READER_STATE, SOCKET_ERROR } from './const';
import InfoIconButton from './InfoIconButton';
import ReaderInfo from './ReaderInfo';
import ReadingButton from './ReadingButton';
import RefreshIconButton from './RefreshIconButton';
import useCardReader from './useCardReader';

function CardReader() {
  const { cardId, eraseCardId, error, readCard, status, testDriver } = useCardReader();
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const { getValues } = useFormContext();
  const value = getValues('card');

  useEffect(() => {
    if (value !== cardId) {
      eraseCardId();
    }
  }, [value]);

  const handleReading = () => readCard();

  const isDriverInitialized =
    [CARD_READER_STATE.DRIVER_AVAILABLE].includes(status) && ![SOCKET_ERROR.CONNECTION_DEVICE_ERR].includes(error);

  const isConnecting = [CARD_READER_STATE.CONNECT].includes(status);
  const isTesting = [CARD_READER_STATE.TEST].includes(status);
  const isReading = [CARD_READER_STATE.READING].includes(status);

  const isInitError =
    ([CARD_READER_STATE.DRIVER_UNAVAILABLE].includes(status) || [SOCKET_ERROR.CONNECTION_DEVICE_ERR].includes(error)) &&
    !value;

  const isRefreshButton =
    [CARD_READER_STATE.DRIVER_UNAVAILABLE].includes(status) ||
    [SOCKET_ERROR.CONNECTION_DEVICE_ERR, SOCKET_ERROR.CONNECTION_ERR].includes(error) ||
    isTesting;

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <CardIdentifierInput
          cardId={cardId}
          error={error}
          isConnecting={isConnecting}
          isDriverInitialized={isDriverInitialized}
          isInitError={isInitError}
          isReading={isReading}
          isTesting={isTesting}
        />
      </Grid>
      <Grid item>
        <Grid alignItems="center" container direction="row" spacing={1}>
          <Grid item>
            <ReadingButton disabled={!isDriverInitialized} isLoading={isReading} onClick={handleReading} />
          </Grid>
          <Grid item>
            <InfoIconButton onClick={() => setIsInfoOpen(!isInfoOpen)} />
          </Grid>
          {isRefreshButton && (
            <Grid item>
              <RefreshIconButton disabled={isConnecting || isTesting || isReading} onClick={testDriver} />
            </Grid>
          )}
        </Grid>
      </Grid>
      {isInfoOpen && (
        <Grid item>
          <ReaderInfo />
        </Grid>
      )}
    </Grid>
  );
}

export default CardReader;
