import React, { createRef, forwardRef } from 'react';
import MaskedInput from 'react-text-mask';

const SerialNumberMaskedInput = forwardRef(({ ...props }, ref) => {
  const inputRef = createRef(ref);
  return (
    <MaskedInput
      {...props}
      mask={[/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      ref={inputRef}
    />
  );
});

export default SerialNumberMaskedInput;
