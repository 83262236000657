import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { MobileVideoStatus } from '../../../components';
import { getStyles } from '../../../components/MobileVideoStatus/helpers';
import messages from './messages';

const MobileVideoSiteStatus = ({ active, is2nDevice, licenceModel, subscribed }) =>
  active ? (
    <Box color={`${getStyles(is2nDevice, licenceModel, subscribed)}.main`}>
      <MobileVideoStatus
        is2nDevice={is2nDevice}
        licenceModel={licenceModel}
        showTooltip
        statusStyles={{ fontWeight: 'bold' }}
        subscribed={subscribed}
      />
    </Box>
  ) : (
    <strong>
      <FormattedMessage {...messages.mobileVideoStatusStatusNotActive} />
    </strong>
  );

MobileVideoSiteStatus.propTypes = {
  active: PropTypes.bool,
  is2nDevice: PropTypes.bool,
  licenceModel: PropTypes.shape({
    status: PropTypes.string,
    validityTo: PropTypes.string,
  }),
  subscribed: PropTypes.bool,
};

MobileVideoSiteStatus.defaultProps = {
  active: false,
  is2nDevice: false,
  licenceModel: {
    status: '',
    validityTo: '',
  },
  subscribed: false,
};

export default MobileVideoSiteStatus;
