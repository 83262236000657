import { AnyAction } from 'redux';
import { types } from './types';

export function clearRedirectData(): AnyAction {
  return {
    type: types.CLEAR_REDIRECT_DATA,
  };
}

export function setRedirectData(destinationPath: string): AnyAction {
  return {
    payload: {
      to: destinationPath,
    },
    type: types.SET_REDIRECT_DATA,
  };
}
