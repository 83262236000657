import { connect, getIn } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import http from '../../../api';
import { DeviceModelName, FormikFields } from '../../../components';
import { DEVICE_TYPE } from '../../../config/devices';
import { SITE_TYPE } from '../../../config/sites';
import { getNameFromDeviceType, getTypeFromDeviceType, isIntercomOrAccessUnit } from '../../../helpers/devices';
import { helpers as deviceHelpers } from '../../../modules/devices';
import messages from './messages';
import SerialNumberField from './SerialNumberField';

const isAvailableForApartment = (types, deviceType) =>
  deviceHelpers.findItemInDeviceTypeTemplate(types, deviceType, 'availableForApartment') || false;

const SerialNumberControl = ({ companyId, deviceTypes, disabled, formik, site }) => {
  const [error, setError] = useState({ isError: false, message: '' });
  const { setFieldTouched, setFieldValue, touched, values } = formik;
  const serialNumber = getIn(values, 'serialNumber');
  const deviceType = getIn(values, 'deviceType');

  useEffect(() => {
    const isDeviceIntercomOrAccessUnit = isIntercomOrAccessUnit(getTypeFromDeviceType(values?.deviceType));

    setFieldValue('isDeviceIntercomOrAccessUnit', isDeviceIntercomOrAccessUnit);
  }, [values?.deviceType]);

  useEffect(() => {
    setFieldValue('isSiteMDU', site?.type === SITE_TYPE.MDU);
  }, [site?.type]);

  useEffect(() => {
    if (!disabled) {
      // eslint-disable-next-line max-statements
      const setDeviceType = (value) => {
        setFieldValue('deviceType', value);
        setFieldValue('isIDT', getTypeFromDeviceType(value) === DEVICE_TYPE.IDT);
        if (isAvailableForApartment(deviceTypes, getTypeFromDeviceType(value))) {
          setFieldValue('isAvailableForApartment', true);
        } else {
          setFieldValue('isAvailableForApartment', false);
          setFieldValue('apartmentId', null);
        }

        if (value) {
          if (!getIn(touched, 'name')) {
            setFieldValue('name', getNameFromDeviceType(value));
            setFieldTouched('name');
          }

          setError({
            isError: false,
            message: null,
          });
        }
      };

      if (serialNumber && new RegExp(/^(\d{2}-\d{4}-\d{4})$/).test(serialNumber)) {
        http
          .get(`/companies/${companyId}/sites/${site.id}/device-meta/${serialNumber}`)
          .then((response) => {
            const { data, status } = response;
            if (status === 409) {
              setDeviceType('');
              setError({
                isError: true,
                message: <FormattedMessage {...messages.serialNumberControlNoUnique} />,
              });
            } else if (data.deviceType !== null && data.deviceType.length > 0) {
              setDeviceType(data.deviceType);
            } else {
              throw new Error();
            }
          })
          .catch(() => {
            setDeviceType('');
            setError({
              isError: true,
              message: <FormattedMessage {...messages.serialNumberControlInvalid} />,
            });
          });
      } else {
        setDeviceType('');
      }
    }
  }, [disabled, serialNumber]);

  const helperText = <DeviceModelName deviceType={deviceType} unknownType={false} /> || ' ';

  return (
    <FormikFields.Input
      autoFocus
      disabled={disabled}
      error={error.isError}
      helperText={error.isError ? error.message : helperText}
      InputProps={{ inputComponent: SerialNumberField }}
      label={<FormattedMessage {...messages.serialNumberControlInputLabel} />}
      name="serialNumber"
      required
    />
  );
};

SerialNumberControl.propTypes = {
  companyId: PropTypes.number.isRequired,
  deviceTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  disabled: PropTypes.bool.isRequired,
  formik: PropTypes.shape({
    setFieldTouched: PropTypes.func,
    setFieldValue: PropTypes.func,
    touched: PropTypes.shape({
      name: PropTypes.bool,
    }),
    values: PropTypes.shape({
      deviceType: PropTypes.string,
      serialNumber: PropTypes.string,
    }),
  }).isRequired,
  site: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    type: PropTypes.string,
  }).isRequired,
};

export default connect(SerialNumberControl);
