import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHasFeature } from '../../../../components/Feature/Feature';
import { FE_ROLES } from '../../../../config/consts';
import featureFlags from '../../../../config/featureFlags';
import { IUser } from '../../../../modules/user/store';
import CreateRfidCardButton from './CreateRfidCardButton';
import EnableMy2NAppAccessButton from './EnableMy2NAppAccessButton';
import GenerateNewPinButton from './GenerateNewPinButton';
import messages from './messages';
import styles from './styles/UserInfoForm';

interface IAccessSecurityInfo {
  canChangePassword: boolean;
  onShowEditPasswordModal: () => void;
  user: IUser;
  isUserGuest: boolean;
}

function AccessSecurityInfo({ canChangePassword, isUserGuest, onShowEditPasswordModal, user }: IAccessSecurityInfo) {
  const isBLEAccessEnable = useHasFeature(featureFlags.USER_BLE_CREDENTIAL);

  return (
    <Grid item xs={12}>
      {canChangePassword && (
        <Grid sx={styles.changePassword}>
          <Button color="primary" onClick={onShowEditPasswordModal} startIcon={<LockOutlinedIcon />}>
            <FormattedMessage {...messages.accessAndSecurityChangePassword} />
          </Button>
        </Grid>
      )}
      <Grid>
        <GenerateNewPinButton user={user} />
      </Grid>
      {!isUserGuest && (
        <Grid>
          <CreateRfidCardButton user={user} />
        </Grid>
      )}
      {isBLEAccessEnable && user.role !== FE_ROLES.LOGIN_LESS && !isUserGuest && (
        <Grid>
          <EnableMy2NAppAccessButton user={user} />
        </Grid>
      )}
    </Grid>
  );
}

export default AccessSecurityInfo;
