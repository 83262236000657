export default function getMultilineUsersArray(multilineValue: string) {
  const usersArr: string[] = [];
  multilineValue.split('\n').forEach((value) => {
    const trimmedValue = value.trim();
    if (trimmedValue.length > 0) {
      usersArr.push(trimmedValue);
    }
  });

  return usersArr;
}
