import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert } from '../../../../components';
import messages from './messages';

const VerificationIsUsedAlert = ({ errorCompanyName, errorSiteName }) => (
  <Alert
    severity="warning"
    title={
      <FormattedMessage
        {...messages.codeVerificationWarningUsedHeader}
        values={{
          context: errorCompanyName && errorSiteName && (
            <FormattedMessage
              {...messages.codeVerificationWarningUsedContext}
              values={{
                company: errorCompanyName,
                site: errorSiteName,
                strong: (chunks) => <strong>{chunks}</strong>,
              }}
            />
          ),
        }}
      />
    }
  >
    <FormattedMessage {...messages.codeVerificationWarningUsedText} />
  </Alert>
);

VerificationIsUsedAlert.propTypes = {
  errorCompanyName: PropTypes.string.isRequired,
  errorSiteName: PropTypes.string.isRequired,
};

export default VerificationIsUsedAlert;
