import types from './types';

export function setInvalidateSiteSwitcher() {
  return {
    type: types.INVALIDATE_SITE_SWITCHER,
  };
}

export function setInvalidateCompanySwitcher() {
  return {
    type: types.INVALIDATE_COMPANY_SWITCHER,
  };
}
