import { getOffset } from '../../helpers/dataGrid';
import types from './types';

export const createAccessGroup = (params) => ({
  params,
  type: types.CREATE_ACCESS_GROUP_REQUEST,
});

export const deleteAccessGroup = (groupId) => ({
  groupId,
  type: types.DELETE_ACCESS_GROUP_REQUEST,
});

export const getAccessControlApartments = (groupId) => ({
  groupId,
  type: types.GET_ACCESS_CONTROL_APARTMENTS_REQUEST,
});

export const getAccessControlDevices = (groupId) => ({
  groupId,
  type: types.GET_ACCESS_CONTROL_DEVICES_REQUEST,
});

export const getAccessControlUsers = (groupId) => ({
  groupId,
  type: types.GET_ACCESS_CONTROL_USERS_REQUEST,
});

export const getAccessGroup = (companyId, siteId, groupId) => ({
  companyId,
  groupId,
  siteId,
  type: types.GET_ACCESS_GROUP_REQUEST,
});

export const getAccessGroupApartments = (
  companyId,
  siteId,
  groupId,
  page = 1,
  rowsPerPage = 10,
  filter = { fulltext: '' },
  order = 'number,name'
) => ({
  companyId,
  filter,
  groupId,
  order,
  page,
  params: {
    filter: filter.fulltext,
    limit: rowsPerPage,
    offset: getOffset(page, rowsPerPage),
    order,
  },
  rowsPerPage,
  siteId,
  type: types.GET_ACCESS_GROUP_APARTMENTS_REQUEST,
});

export const getAccessGroupApartmentsAll = (groupId) => ({
  groupId,
  params: { limit: 1000, offset: 0 },
  type: types.GET_ACCESS_GROUP_APARTMENTS_ALL_REQUEST,
});

export const getAccessGroupDevices = (
  companyId,
  siteId,
  groupId,
  page = 1,
  rowsPerPage = 10,
  filter = { fulltext: '' },
  order = 'deviceName'
) => ({
  companyId,
  filter,
  groupId,
  order,
  page,
  params: {
    filter: filter.fulltext,
    limit: rowsPerPage,
    offset: getOffset(page, rowsPerPage),
    order,
  },
  rowsPerPage,
  siteId,
  type: types.GET_ACCESS_GROUP_DEVICES_REQUEST,
});

export const getAccessGroupDevicesAll = (groupId) => ({
  groupId,
  params: { limit: 1000, offset: 0 },
  type: types.GET_ACCESS_GROUP_DEVICES_ALL_REQUEST,
});

export const removeAccessGroupApartment = (apartmentId) => ({
  apartmentId,
  type: types.REMOVE_ACCESS_GROUP_APARTMENT_REQUEST,
});

export const removeAccessGroupDevice = (deviceId) => ({
  deviceId,
  type: types.REMOVE_ACCESS_GROUP_DEVICE_REQUEST,
});

export const activateRacAccessGroupDevice = (deviceId) => ({
  deviceId,
  params: { active: true },
  type: types.ACTIVATE_RAC_ACCESS_GROUP_DEVICE_REQUEST,
});

export const deactivateRacAccessGroupDevice = (deviceId) => ({
  deviceId,
  params: { active: false },
  type: types.DEACTIVATE_RAC_ACCESS_GROUP_DEVICE_REQUEST,
});

export const setLogAccessGroupDevice = (deviceId, params) => ({
  deviceId,
  params,
  type: types.SET_LOG_ACCESS_GROUP_DEVICE_REQUEST,
});

export const getAccessGroupUsers = (
  companyId,
  siteId,
  groupId,
  page = 1,
  rowsPerPage = 10,
  filter = { fulltext: '' },
  order = 'lastName'
) => ({
  companyId,
  filter,
  groupId,
  order,
  page,
  params: {
    filter: filter.fulltext,
    limit: rowsPerPage,
    offset: getOffset(page, rowsPerPage),
    order,
  },
  rowsPerPage,
  siteId,
  type: types.GET_ACCESS_GROUP_USERS_REQUEST,
});

export const removeAccessGroupUser = (userId) => ({
  type: types.REMOVE_ACCESS_GROUP_USER_REQUEST,
  userId,
});

export const getAccessGroupsList = (
  companyId,
  siteId,
  page = 1,
  rowsPerPage = 10,
  filter = { fulltext: '' },
  order = 'name'
) => ({
  companyId,
  filter,
  order,
  page,
  params: {
    filter: filter.fulltext,
    limit: rowsPerPage,
    offset: getOffset(page, rowsPerPage),
    order,
  },
  rowsPerPage,
  siteId,
  type: types.GET_ACCESS_GROUPS_LIST_REQUEST,
});

export const getAccessGroupsListAll = () => ({
  type: types.GET_ACCESS_GROUPS_LIST_ALL_REQUEST,
});

export const updateAccessGroup = (companyId, siteId, groupId, params) => ({
  companyId,
  groupId,
  params,
  siteId,
  type: types.UPDATE_ACCESS_GROUP_REQUEST,
});

export const getApartmentAccessGroupsList = (apartmentId) => ({
  apartmentId,
  type: types.GET_APARTMENT_ACCESS_GROUPS_LIST_REQUEST,
});

export const addApartmentAccessGroups = (apartmentId, groups) => ({
  apartmentId,
  groups,
  type: types.ADD_APARTMENT_ACCESS_GROUPS_REQUEST,
});

export const addApartmentAccessGroupsWithCreate = (apartmentId, newGroupName, groups) => ({
  apartmentId,
  groups,
  newGroupName,
  type: types.CREATE_ADD_APARTMENT_ACCESS_GROUPS_REQUEST,
});

export const getUserApartmentsAccessGroupsList = (companyId, siteId, userId) => ({
  companyId,
  siteId,
  type: types.GET_USER_APARTMENTS_ACCESS_GROUPS_LIST_REQUEST,
  userId,
});

export const getUserAccessGroupsList = (companyId, siteId, userId) => ({
  companyId,
  siteId,
  type: types.GET_USER_ACCESS_GROUPS_LIST_REQUEST,
  userId,
});

export const addUserAccessGroups = (userId, groups) => ({
  groups,
  type: types.ADD_USER_ACCESS_GROUPS_REQUEST,
  userId,
});

export const addUserAccessGroupsWithCreate = (userId, newGroupName, groups) => ({
  groups,
  newGroupName,
  type: types.CREATE_ADD_USER_ACCESS_GROUPS_REQUEST,
  userId,
});

export const addAccessGroupApartments = (groupId, apartments, newApartment) => ({
  apartments,
  groupId,
  newApartment,
  type: types.ADD_ACCESS_GROUP_APARTMENTS_REQUEST,
});

export const addAccessGroupDevice = (groupId, deviceId) => ({
  deviceId,
  groupId,
  type: types.ADD_ACCESS_GROUP_DEVICE_REQUEST,
});

export const addAccessGroupUser = (groupId, userId) => ({
  groupId,
  type: types.ADD_ACCESS_GROUP_USER_REQUEST,
  userId,
});

export const addAccessGroupUsers = (groupId, usersUUId) => ({
  groupId,
  type: types.ADD_ACCESS_GROUP_USERS_REQUEST,
  usersUUId,
});

export const addAccessGroupUserWithCreate = (groupId, user) => ({
  groupId,
  type: types.CREATE_ADD_ACCESS_GROUP_USER_REQUEST,
  user,
});

export const getDeviceAccessGroupsList = (deviceId) => ({
  deviceId,
  type: types.GET_DEVICE_ACCESS_GROUPS_LIST_REQUEST,
});

export const addDeviceAccessGroups = (deviceId, groups) => ({
  deviceId,
  groups,
  type: types.ADD_DEVICE_ACCESS_GROUPS_REQUEST,
});

export const addDeviceAccessGroupsWithCreate = (deviceId, newGroupName, groups) => ({
  deviceId,
  groups,
  newGroupName,
  type: types.CREATE_ADD_DEVICE_ACCESS_GROUPS_REQUEST,
});

export const getAccessControlSettings = (deviceId) => ({
  deviceId,
  type: types.GET_ACCESS_CONTROL_SETTINGS_REQUEST,
});

export const setAccessControlSettings = (deviceId, params) => ({
  deviceId,
  params,
  type: types.SET_ACCESS_CONTROL_SETTINGS_REQUEST,
});

export const setAccessControlLogSettings = (deviceId, params) => ({
  deviceId,
  params,
  type: types.SET_ACCESS_CONTROL_LOG_SETTINGS_REQUEST,
});
