import { useSelector } from 'react-redux';
import { useUrlParams } from '../../../helpers/urlParams';
import { useGetLoadedParams } from '../../../hooks/paramsHooks';
import { ApplicationContext, IReduxTableStoreBase } from '../../../modules/commonTypes';
import { devicesSelectors } from '../../../modules/devices';
import { IDevice } from '../../../modules/devices/store/deviceStore';
import { getUserSelector } from '../../../modules/user/selectorsTS';
import { IUser, IUserDetail } from '../../../modules/user/store';

export interface IAdminDetailData {
  currentCompanyId: number;
  currentUserId?: number;
  currentUser?: IUser;
  devicesTable: IReduxTableStoreBase<IDevice>;
  userDetail?: IUserDetail;
  isFetching: boolean;
}

export function useGetAdminDetailData(): IAdminDetailData {
  const { companyId } = useGetLoadedParams(ApplicationContext.Company);
  const { userId } = useUrlParams();
  const user = useSelector(getUserSelector);
  const devicesTable = useSelector(devicesSelectors.getUserDevices);

  return {
    currentCompanyId: companyId,
    currentUser: user.data,
    currentUserId: userId,
    devicesTable,
    isFetching: user.userDetail.isFetching,
    userDetail: user.userDetail.data,
  };
}
