import Tab from '@mui/material/Tab';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { TabsContainer, Title } from '../../components';
import { PATHS } from '../../config/consts';
import { useUrlParams } from '../../helpers/urlParams';
import messages from './messages';

const CallSettings = () => {
  const { companyId, siteId } = useUrlParams();

  const location = useLocation();

  const intercoms = PATHS.CALL_SETTINGS_INTERCOMS.replace(':companyId', companyId).replace(':siteId', siteId);
  const answeringUnits = PATHS.CALL_SETTINGS_ANSWERING_UNITS.replace(':companyId', companyId).replace(
    ':siteId',
    siteId
  );
  const phonebooks = PATHS.CALL_SETTINGS_PHONEBOOKS.replace(':companyId', companyId).replace(':siteId', siteId);

  const value = [intercoms, answeringUnits, phonebooks].includes(location?.pathname) ? location?.pathname : null;

  return (
    <>
      <Title heading={<FormattedMessage {...messages.callSettingsTitle} />} />
      {value && (
        <TabsContainer value={value}>
          <Tab
            component={Link}
            label={<FormattedMessage {...messages.callSettingsTypesIntercoms} />}
            to={intercoms}
            value={intercoms}
          />
          <Tab
            component={Link}
            label={<FormattedMessage {...messages.callSettingsTypesAnsweringUnits} />}
            to={answeringUnits}
            value={answeringUnits}
          />
          <Tab
            component={Link}
            label={<FormattedMessage {...messages.callSettingsTypesPhonebooks} />}
            to={phonebooks}
            value={phonebooks}
          />
        </TabsContainer>
      )}
      <Outlet />
    </>
  );
};

export default CallSettings;
