import Box from '@mui/material/Box';
import { DataGridPro as MuiDataGrid, useGridApiRef } from '@mui/x-data-grid-pro';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { EMAIL_REGEXP } from '../../../config/consts';
import { IMPORT_KEYS } from '../helpers/transformJsonData';
import ApartmentCellValidator from './ApartmentCellValidator';
import CellBox from './CellBox';
import CellDeleteButton from './CellDeleteButton';
import CellValidator from './CellValidator';
import messages from './messages';

function DataGrid({ data, errors, onSetData, viewErrors }) {
  const { formatMessage } = useIntl();
  const apiRef = useGridApiRef();
  const [state, setState] = useState();

  useEffect(() => {
    if (state) {
      apiRef.current.restoreState(state);
    }
  }, [data]);

  const handleDataGridStateChange = useCallback(() => {
    setState(apiRef.current.exportState());
  }, [apiRef]);

  const handleDelete = useCallback(
    (rowNumber) => {
      onSetData(data.filter((dataItem) => dataItem[IMPORT_KEYS.rowNumber] !== rowNumber));
    },
    [data]
  );

  const handleChangeRow = useCallback(
    (rowData) => {
      setTimeout(() => {
        onSetData(
          data.map((dataItem) => {
            if (dataItem[IMPORT_KEYS.rowNumber] === rowData[IMPORT_KEYS.rowNumber]) {
              return rowData;
            }
            return dataItem;
          })
        );
      });

      return rowData;
    },
    [data]
  );

  const columns = useMemo(
    () => [
      {
        field: IMPORT_KEYS.rowNumber,
        flex: 1,
        maxWidth: 60,
        renderCell: (cellData) => <Box color="primary.main">{cellData.value}</Box>,
        renderHeader: () => <Box color="primary.main">{'#'}</Box>,
        resizable: false,
      },
      {
        editable: true,
        field: IMPORT_KEYS.apartmentFloor,
        flex: 1,
        headerName: formatMessage(messages.siteUsersImportCheckStepDataGridTableHeadFloor),
        renderCell: (cellData) => (
          <ApartmentCellValidator
            otherCellFilled={!!cellData.row[IMPORT_KEYS.apartmentNumber] || !!cellData.row[IMPORT_KEYS.apartmentName]}
            value={cellData.value}
          />
        ),
        resizable: true,
      },
      {
        editable: true,
        field: IMPORT_KEYS.apartmentNumber,
        flex: 1,
        headerName: formatMessage(messages.siteUsersImportCheckStepDataGridTableHeadNumber),
        renderCell: (cellData) => (
          <ApartmentCellValidator
            otherCellFilled={!!cellData.row[IMPORT_KEYS.apartmentFloor] || !!cellData.row[IMPORT_KEYS.apartmentName]}
            value={cellData.value}
          />
        ),
      },
      {
        editable: true,
        field: IMPORT_KEYS.apartmentName,
        flex: 1,
        headerName: formatMessage(messages.siteUsersImportCheckStepDataGridTableHeadApartmentName),
        renderCell: (cellData) => <ApartmentCellValidator otherCellFilled={false} value={cellData.value} />,
      },
      {
        editable: true,
        field: IMPORT_KEYS.firstName,
        flex: 1,
        headerName: formatMessage(messages.siteUsersImportCheckStepDataGridTableHeadFirstName),
        renderCell: (cellData) => <CellValidator isValueValid={!!cellData.value} value={cellData.value} />,
      },
      {
        editable: true,
        field: IMPORT_KEYS.lastName,
        flex: 1,
        headerName: formatMessage(messages.siteUsersImportCheckStepDataGridTableHeadLastName),
        renderCell: (cellData) => <CellValidator isValueValid={!!cellData.value} value={cellData.value} />,
      },
      {
        editable: true,
        field: IMPORT_KEYS.email,
        flex: 1,
        headerName: formatMessage(messages.siteUsersImportCheckStepDataGridTableHeadEmail),
        renderCell: (cellData) => (
          <CellValidator isValueValid={EMAIL_REGEXP.test(cellData.value)} regex={EMAIL_REGEXP} value={cellData.value} />
        ),
      },
      {
        editable: true,
        field: IMPORT_KEYS.comment,
        flex: 1,
        headerName: formatMessage(messages.siteUsersImportCheckStepDataGridTableHeadComment),
        renderCell: (cellData) => (
          <CellBox sx={{ fontStyle: 'italic' }}>{cellData.value || <span>&nbsp;</span>}</CellBox>
        ),
        resizable: false,
      },
      {
        field: 'actions',
        getActions: (cellData) => [
          <CellDeleteButton key={cellData.row.id} onClick={() => handleDelete(cellData.row[IMPORT_KEYS.rowNumber])} />,
        ],
        headerName: '',
        resizable: false,
        sortable: false,
        type: 'actions',
        width: 50,
      },
    ],
    [data]
  );

  const rows = useMemo(() => {
    const currentData = viewErrors ? errors : data;
    return currentData.map((item) => ({
      [IMPORT_KEYS.apartmentFloor]: item[IMPORT_KEYS.apartmentFloor],
      [IMPORT_KEYS.apartmentName]: item[IMPORT_KEYS.apartmentName],
      [IMPORT_KEYS.apartmentNumber]: item[IMPORT_KEYS.apartmentNumber],
      [IMPORT_KEYS.comment]: item[IMPORT_KEYS.comment],
      [IMPORT_KEYS.email]: item[IMPORT_KEYS.email],
      [IMPORT_KEYS.firstName]: item[IMPORT_KEYS.firstName],
      [IMPORT_KEYS.lastName]: item[IMPORT_KEYS.lastName],
      [IMPORT_KEYS.rowNumber]: item[IMPORT_KEYS.rowNumber],
      actions: item[IMPORT_KEYS.rowNumber],
      id: item[IMPORT_KEYS.rowNumber],
    }));
  }, [data, errors, viewErrors]);

  const hasDataGridAutoHeight = useCallback(() => rows.length < 10, [rows]);

  return (
    <div style={{ height: hasDataGridAutoHeight() ? 'auto' : 500, width: '100%' }}>
      <MuiDataGrid
        apiRef={apiRef}
        autoHeight={hasDataGridAutoHeight()}
        columns={columns}
        disableColumnMenu
        disableColumnReorder
        disableRowSelectionOnClick
        hideFooterPagination
        hideFooterRowCount
        onColumnWidthChange={handleDataGridStateChange}
        processRowUpdate={handleChangeRow}
        rowHeight={40}
        rows={rows}
        sortingOrder={['desc', 'asc']}
        sx={{
          '.MuiDataGrid-columnSeparator': {
            display: 'flex',
          },
        }}
      />
    </div>
  );
}

DataGrid.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      apartmentFloor: PropTypes.string,
      apartmentName: PropTypes.string,
      apartmentNumber: PropTypes.string,
      comment: PropTypes.string,
      email: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      rowNumber: PropTypes.number,
    })
  ).isRequired,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      apartmentFloor: PropTypes.string,
      apartmentName: PropTypes.string,
      apartmentNumber: PropTypes.string,
      comment: PropTypes.string,
      email: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      rowNumber: PropTypes.number,
    })
  ).isRequired,
  onSetData: PropTypes.func.isRequired,
  viewErrors: PropTypes.bool.isRequired,
};

export default memo(DataGrid);
