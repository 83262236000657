import VerifiedIcon from '@mui/icons-material/VerifiedUser';
import Skeleton from '@mui/material/Skeleton';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../../config/consts';
import { useDataGrid } from '../../helpers/dataGrid';
import useBreakpoints from '../../helpers/useBreakpoints';
import { isFetchingMessages } from '../../modules/localization/selectors';
import DataGridComponent from '../DataGrid/DataGrid';
import NameColumn from '../DataGrid/NameColumn';
import AccessSettingsRowMenu from './AccessSettingsRowMenu/AccessSettingsRowMenu';
import DevicesInGroupChips from './DevicesInGroupChips';
import messages from './messages';
import NoRowsOverlay from './NoRowsOverlay';

const DataGrid = ({
  accessSettingsDataGrid,
  companyId,
  data,
  isFetching,
  isInvalid,
  loadAccessGroups,
  onAddApartment,
  onAddDevice,
  onAddUser,
  onCreateGroup,
  onDelete,
  siteId,
}) => {
  const { onOrderBy, onRowsPerPageChange, onSelectPage } = useDataGrid(
    loadAccessGroups,
    {
      companyId,
      order: accessSettingsDataGrid.order,
      page: accessSettingsDataGrid.page,
      rowsPerPage: accessSettingsDataGrid.rowsPerPage,
      siteId,
    },
    isInvalid
  );

  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { upLg, upMd, upSm } = useBreakpoints();
  const isLocalizationLoaded = useSelector(isFetchingMessages);

  const loadingWrapper = (children) => (isFetching ? <Skeleton animation="wave" height={25} width="80%" /> : children);

  const columns = useMemo(
    () => [
      {
        disableColumnMenu: true,
        field: 'name',
        flex: 1,
        headerName: formatMessage(messages.accessSettingsDataGridColumnsName),
        renderCell: (group) =>
          loadingWrapper(
            group?.value && (
              <NameColumn
                iconComponent={<VerifiedIcon />}
                isActive={!!group?.value?.devices?.find((item) => item.accessControl === true)}
                name={group?.value?.name}
              />
            )
          ),
        sortable: true,
      },
      {
        disableColumnMenu: true,
        field: 'devices',
        flex: 1,
        headerName: formatMessage(messages.accessSettingsDataGridColumnsDevicesLabel),
        renderCell: (group) =>
          loadingWrapper(
            group?.value && (
              <DevicesInGroupChips
                companyId={companyId}
                devices={group.value?.devices}
                isText={!upSm}
                siteId={siteId}
              />
            )
          ),
        sortable: false,
      },
      {
        disableColumnMenu: true,
        field: 'users',
        headerName: formatMessage(messages.accessSettingsDataGridColumnsUsers),
        renderCell: (group) => loadingWrapper(<span>{group?.value?.users}</span>),
        sortable: false,
        width: upSm ? 150 : 65,
      },
      {
        disableColumnMenu: true,
        field: 'apartments',
        headerName: formatMessage(messages.accessSettingsDataGridColumnsApartments),
        renderCell: (group) => loadingWrapper(<span>{group?.value?.apartments}</span>),
        sortable: false,
        width: upSm ? 150 : 65,
      },
      {
        align: 'right',
        disableColumnMenu: true,
        field: 'rowMenu',
        headerName: '',
        renderCell: (group) =>
          loadingWrapper(
            group?.value && (
              <AccessSettingsRowMenu
                companyId={companyId}
                group={group.value}
                groupId={group.id}
                onAddApartment={onAddApartment}
                onAddDevice={onAddDevice}
                onAddUser={onAddUser}
                onDelete={onDelete}
                siteId={siteId}
              />
            )
          ),
        sortable: false,
        width: 65,
      },
    ],
    [upMd, upSm, upLg, isFetching, isLocalizationLoaded]
  );

  const rows = useMemo(
    () =>
      data.map((group) => ({
        apartments: group,
        devices: group,
        id: group.id,
        isClickable: true,
        name: group,
        rowMenu: group,
        users: group,
      })),
    [data]
  );

  return (
    <DataGridComponent
      columns={columns}
      columnVisibilityModel={{
        apartments: upSm,
        devices: upMd,
        users: upSm,
      }}
      disableRowSelectionOnClick
      hideFooterPagination={isFetching}
      loading={isFetching}
      noRowsOverlay={<NoRowsOverlay onCreateGroup={onCreateGroup} />}
      onCellClick={(params) => {
        if (params.field !== 'rowMenu') {
          navigate(
            PATHS.ACCESS_SETTINGS_DETAIL.replace(':companyId', companyId)
              .replace(':siteId', siteId)
              .replace(':groupId', params.value?.id)
          );
        }
      }}
      onOrderBy={onOrderBy}
      onRowsPerPageChange={onRowsPerPageChange}
      onSelectPage={onSelectPage}
      order={accessSettingsDataGrid.order}
      page={accessSettingsDataGrid.page}
      resultsFiltered={accessSettingsDataGrid.resultsFiltered}
      resultsTotal={accessSettingsDataGrid.resultsTotal}
      rows={rows}
      rowsPerPage={accessSettingsDataGrid.rowsPerPage}
    />
  );
};

DataGrid.propTypes = {
  accessSettingsDataGrid: PropTypes.shape({
    order: PropTypes.string.isRequired,
    page: PropTypes.number.isRequired,
    resultsFiltered: PropTypes.number.isRequired,
    resultsTotal: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  }).isRequired,
  companyId: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isFetching: PropTypes.bool.isRequired,
  isInvalid: PropTypes.bool.isRequired,
  loadAccessGroups: PropTypes.func.isRequired,
  onAddApartment: PropTypes.func.isRequired,
  onAddDevice: PropTypes.func.isRequired,
  onAddUser: PropTypes.func.isRequired,
  onCreateGroup: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  siteId: PropTypes.number.isRequired,
};

export default DataGrid;
