import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ReadOnlyField } from '../../../components';
import messages from '../messages';

const styles = {
  message: {
    color: 'secondary.main',
    fontSize: 12,
  },
};

function DeviceAppLoginInfo({ onShowInstallAppModal }) {
  return (
    <Grid container direction="column" item lg={3} sm={6} spacing={2}>
      <Grid item>
        <ReadOnlyField
          label={<FormattedMessage {...messages.userLoserDeviceAppLoginInfoTitle} />}
          value={
            <>
              <Box mb={2} sx={styles.message}>
                <FormattedMessage
                  values={{
                    link: (
                      <Link href="#" onClick={onShowInstallAppModal}>
                        <FormattedMessage {...messages.userLoserDeviceAppLoginInfoLink} />
                      </Link>
                    ),
                  }}
                  {...messages.userLoserDeviceAppLoginInfoMessage}
                />
              </Box>
            </>
          }
        />
      </Grid>
    </Grid>
  );
}

DeviceAppLoginInfo.propTypes = {
  onShowInstallAppModal: PropTypes.func.isRequired,
};

export default DeviceAppLoginInfo;
