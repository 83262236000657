import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import mvCloudServiceImg from '../../assets/mv_cloud_service.svg';
import questionImg from '../../assets/question.svg';
import messages from './messages';

const styles = {
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    px: 2,
    py: 9,
  },
  description: {
    fontSize: '16px',
  },
  image: {
    display: {
      md: 'block',
      xs: 'none',
    },
    pr: {
      md: 4,
      xs: 0,
    },
  },
  infoBox: {
    alignItems: 'center',
    backgroundColor: 'info.light',
    display: 'flex',
    fontSize: '14px',
    fontStyle: 'italic',
    mt: 4,
    p: 3,
  },
  infoBoxIcon: {
    mr: 4,
  },
  root: {
    backgroundColor: 'common.white',
    width: '100%',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'medium',
    mb: 3,
  },
  wrapper: {
    maxWidth: '1050px',
    mx: 'auto',
    width: '100%',
  },
};

const SecuredCloud = forwardRef((_, ref) => (
  <Box ref={ref} sx={styles.root}>
    <Box sx={styles.wrapper}>
      <Box sx={styles.content}>
        <Box sx={styles.image}>
          <img alt="Reliable and secure cloud service" src={mvCloudServiceImg} />
        </Box>
        <Box>
          <Typography component="h3" sx={styles.title}>
            <FormattedMessage {...messages.mvideoSecuredCloudTitle} />
          </Typography>
          <Typography paragraph sx={styles.description}>
            <FormattedMessage
              {...messages.mvideoSecuredCloudDescription}
              values={{ strong: (chunks) => <strong>{chunks}</strong> }}
            />
          </Typography>
          <Typography paragraph sx={styles.description}>
            <FormattedMessage
              {...messages.mvideoSecuredCloudText}
              values={{
                strong: (chunks) => <strong>{chunks}</strong>,
                sup: (chunks) => <sup>{chunks}</sup>,
              }}
            />
          </Typography>
          <Box sx={styles.infoBox}>
            <Box sx={styles.infoBoxIcon}>
              <img alt="Question" src={questionImg} />
            </Box>
            <Typography>
              <FormattedMessage {...messages.mvideoSecuredCloudQuestion} />
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  </Box>
));

export default SecuredCloud;
