import { defineMessages } from 'react-intl';

export default defineMessages({
  companyDashboardLinksBillingInfoDescription: {
    defaultMessage: "Edit this company's invoice address,{br} currency and other details.",
    description: 'Company dashboard - billing info description',
    id: 'companyDashboard.links.billingInfo.description',
  },
  companyDashboardLinksBillingInfoTitle: {
    defaultMessage: 'Billing info',
    description: 'Company dashboard - billing info',
    id: 'companyDashboard.links.billingInfo.title',
  },
  companyDashboardLinksCompanyAdminsDescription: {
    defaultMessage: `Manage users who have administrator \n access
          to this company and all its Sites.`,
    description: 'Company dashboard - company admins description',
    id: 'companyDashboard.links.companyAdmins.description',
  },
  companyDashboardLinksCompanyAdminsTitle: {
    defaultMessage: 'Company admins',
    description: 'Company dashboard - company admins',
    id: 'companyDashboard.links.companyAdmins.title',
  },
  companyDashboardLinksPaymentsOverviewDescription: {
    defaultMessage: 'View your payment history and manage {br} company invoices.',
    description: 'Company dashboard - payments overview description',
    id: 'companyDashboard.links.paymentsOverview.description',
  },
  companyDashboardLinksPaymentsOverviewTitle: {
    defaultMessage: 'Payments overview',
    description: 'Company dashboard - payments overview',
    id: 'companyDashboard.links.paymentsOverview.title',
  },
  companyDashboardTitle: {
    defaultMessage: 'Company dashboard',
    description: 'Company dashboard - title',
    id: 'companyDashboard.title',
  },
  recentPaymentsInvoice: {
    defaultMessage: 'Invoice',
    description: 'Site overview - invoice',
    id: 'recentPayments.invoice',
  },
  recentPaymentsViewAllPayments: {
    defaultMessage: 'View all payments',
    description: 'Site overview - view all payments',
    id: 'recentPayments.viewAllPayments',
  },
  siteOverviewNewSite: {
    defaultMessage: 'New site',
    description: 'Site overview - new site',
    id: 'siteOverview.newSite',
  },
  siteOverviewSites: {
    defaultMessage: 'Sites',
    description: 'Site overview - sites',
    id: 'siteOverview.sites',
  },
  siteOverviewSitesInformation: {
    defaultMessage: 'What is a Site?',
    description: 'Site overview - sites information',
    id: 'siteOverview.sitesInformation',
  },
});
