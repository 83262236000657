import Tab from '@mui/material/Tab';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { TabsContainer } from '../../../components';
import { PATHS } from '../../../config/consts';
import useBreakpoints from '../../../helpers/useBreakpoints';
import { TId } from '../../../modules/commonTypes';
import { replaceURLParams } from '../../../routes/helpers/pathParamsReplacer';
import messages from '../messages';

interface IApartmentTabPanel {
  apartmentId: TId;
  companyId: TId;
  siteId: TId;
}

function setPath(path: string, apartmentId: TId, companyId: TId, siteId: TId) {
  return replaceURLParams({ params: { apartmentId, companyId, siteId }, url: path });
}

function ApartmentTabPanel({ apartmentId, companyId, siteId }: IApartmentTabPanel) {
  const { downSm } = useBreakpoints();
  const { formatMessage } = useIntl();
  const location = useLocation();
  const tabItem = useMemo(
    () => ({
      access: setPath(PATHS.APARTMENT_ACCESS, apartmentId, companyId, siteId),
      detail: setPath(PATHS.APARTMENT_DETAIL, apartmentId, companyId, siteId),
      devices: setPath(PATHS.APARTMENT_DEVICES, apartmentId, companyId, siteId),
      users: setPath(PATHS.APARTMENT_USERS, apartmentId, companyId, siteId),
    }),
    [location]
  );

  const value = useMemo(
    () =>
      [tabItem.access, tabItem.detail, tabItem.devices, tabItem.users].includes(location?.pathname)
        ? location?.pathname
        : null,
    [location]
  );

  return (
    <TabsContainer isXs={downSm} value={value}>
      <Tab
        component={Link}
        label={
          downSm ? formatMessage(messages.apartmentTabPanelDetailXs) : formatMessage(messages.apartmentTabPanelDetail)
        }
        to={tabItem.detail}
        value={tabItem.detail}
      />
      <Tab
        component={Link}
        label={formatMessage(messages.apartmentTabPanelDevices)}
        to={tabItem.devices}
        value={tabItem.devices}
      />
      <Tab
        component={Link}
        label={formatMessage(messages.apartmentTabPanelUsers)}
        to={tabItem.users}
        value={tabItem.users}
      />
      <Tab
        component={Link}
        label={formatMessage(messages.apartmentTabPanelAccess)}
        to={tabItem.access}
        value={tabItem.access}
      />
    </TabsContainer>
  );
}

export default ApartmentTabPanel;
