import { AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete/Autocomplete';
import Chip from '@mui/material/Chip';
import React from 'react';
import { TooltipForEllipsisElement } from '../TooltipForEllipsisElement';

type OptionLabelKey = string;
type Option = {
  id: string;
  tag: string;
  [key: OptionLabelKey]: string;
};
interface ITag {
  getTagProps: AutocompleteRenderGetTagProps;
  newItemId: string;
  newItemId2?: string;
  newItemIdLabel?: string;
  newItemId2Label?: string;
  optionLabelKey: OptionLabelKey;
  tagValue: Option[];
}

function Tag({
  getTagProps,
  newItemId,
  newItemId2 = '',
  newItemId2Label = '',
  newItemIdLabel = '',
  optionLabelKey,
  tagValue,
}: ITag) {
  function getLabel(option: Option) {
    if (option.id === newItemId) {
      return newItemIdLabel;
    }
    if (option.id === newItemId2) {
      return newItemId2Label;
    }
    return option?.[optionLabelKey] || '';
  }

  return tagValue.map((option, index) => {
    const props = getTagProps({ index });
    return (
      <TooltipForEllipsisElement
        title={getLabel(option)}
        key={props.key}
        onGetTextElementWithEllipsis={(child) => {
          if (child && child.children.length) {
            return child.children[0];
          }
          return undefined;
        }}
      >
        <Chip
          color={option.id === newItemId || option.id === newItemId2 ? 'primary' : 'default'}
          label={getLabel(option)}
          {...props}
        />
      </TooltipForEllipsisElement>
    );
  });
}

export default Tag;
