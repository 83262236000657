import { defineMessages } from 'react-intl';

export default defineMessages({
  deviceDesktopScanQRCloseScanner: {
    defaultMessage: 'Close scanner',
    description: 'Device mobile scan QR - close button - close scanner',
    id: 'deviceDesktopScanQR.closeScanner',
  },
  deviceDesktopScanQRScanAlertsAllowCamera: {
    defaultMessage: 'Allow camera access to scan the QR code.',
    description: 'Device mobile scan QR - alerts - allowCamera',
    id: 'deviceDesktopScanQR.scanAlerts.allowCamera',
  },
  deviceDesktopScanQRScanAlertsInvalidCode: {
    defaultMessage: 'It looks like you’re trying to scan an incorrect QR code.',
    description: 'Device mobile scan QR - alerts - invalid code',
    id: 'deviceDesktopScanQR.scanAlerts.invalidCode',
  },
  deviceDesktopScanQRScanAlertsPermissionDenied: {
    defaultMessage: 'Your browser is blocking camera access. You must allow it.',
    description: 'Device mobile scan QR - alerts - permission denied',
    id: 'deviceDesktopScanQR.scanAlerts.permissionDenied',
  },
  deviceDesktopScanQRScanAlertsTimeout: {
    defaultMessage: 'We’re unable to scan the code. Close scanner and type manually.',
    description: 'Device mobile scan QR - alerts - timeout',
    id: 'deviceDesktopScanQR.scanAlerts.timeout',
  },
  deviceDesktopScanQRTitlesScanCode: {
    defaultMessage: 'Scan QR code',
    description: 'Device mobile scan QR - titles - scan QR code',
    id: 'deviceDesktopScanQR.titles.scanCode',
  },
  deviceDesktopScanQRTitlesScanCodeDescription: {
    defaultMessage: 'Use the code from the device certificate included in the box',
    description: 'Device mobile scan QR - titles - scan QR code description',
    id: 'deviceDesktopScanQR.titles.scanCodeDescription',
  },
});
