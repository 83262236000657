import axios from 'axios';
import http, { encode } from './index';

export const sendHelpForm = async (values) => http.post('/help', values);
export const getCompanyList = async () => http.get('/users/current');
export const getGlobalFeatures = async () =>
  axios
    .create({
      baseURL: process.env.PAYMENT_GATEWAY_URL,
      timeout: 2000,
    })
    .get('/features/public/flags');
export const getGTCDetail = async (fullPath) => http.get('/terms/GTC', { params: { fullPath } });
export const getGTCAgreements = async (companyId) => http.get(`/companies/${encode(companyId)}/agreements`);
export const getGTCAgreementsCurrentUser = async () => http.get('/users/current/agreements');
export const getGTCList = async (params = {}) =>
  http.get('/terms/GTC', {
    params: { ...params, latest: 0 },
  });
export const getGtcOptOutText = async () => http.get('/terms/MKT_OPT_OUT/latest');
