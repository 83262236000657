import { defineMessages } from 'react-intl';

export default defineMessages({
  firmwareStatusFirmwareStatusDeprecated: {
    defaultMessage: 'FW update needed',
    description: 'Firmware Status - Deprecated',
    id: 'firmwareStatus.DEPRECATED',
  },
  firmwareStatusFirmwareStatusUnsupported: {
    defaultMessage: 'FW update urgent',
    description: 'Firmware Status - Unsupported',
    id: 'firmwareStatus.UNSUPPORTED',
  },
});
