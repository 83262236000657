import { orderBy } from 'lodash';

export const getMobileVideo = (item) => item.services && item.services.MOBILE_VIDEO;
export const getProxy = (item) => getMobileVideo(item).proxy || {};
export const getLicenceModel = (item) => getMobileVideo(item).licenceModel || {};
export const setSites = (results) =>
  results.map((item) => ({
    id: item.id,
    mobileVideo: getMobileVideo(item),
    mVideoStatus: getLicenceModel(item).status || '',
    my2nId: item.my2nId,
    name: item.name,
    phonePrefix: getMobileVideo(item).phonePrefix || '',
    proxyHost: getProxy(item).host || '',
    proxyPort: getProxy(item).port || '',
    type: item.type,
  }));

export function getUsersApartmentsArray(companies, companyId, siteId) {
  const apartments = companies
    ?.filter((company) => company?.id === companyId)?.[0]
    ?.sites.filter((site) => site?.id === siteId)?.[0]?.apartments;
  return orderBy(apartments, ['number'], ['asc']);
}

export const getSiteUsersFilteredData = (state) => {
  const data = [];

  state.sites.siteUsers.data.forEach((siteUser) =>
    data.push({
      apartments: siteUser.apartments.map((element) => ({ name: element.name, number: element.number })),
      email: siteUser.email,
      firstName: siteUser.firstName,
      id: siteUser.id,
      label: siteUser.firstName ? `${siteUser.firstName} ${siteUser.lastName}` : siteUser.lastName,
      lastName: siteUser.lastName,
      role: siteUser.role,
    })
  );

  return data;
};
