import MyProfileIcon from '@mui/icons-material/AccountCircle';
import MyApartmentsIcon from '@mui/icons-material/FolderShared';
import List from '@mui/material/List';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { PATHS } from '../../config/consts';
import { isApartmentAdmin } from '../../helpers/user';
import { selectors as userSelectors } from '../../modules/user';
import messages from './messages';
import SidebarItem from './SidebarItem';

function ApartmentList({ onCloseSidebarMobile }: { onCloseSidebarMobile: () => void }) {
  const currentUserCompanies = useSelector(userSelectors.getUserCompanies);

  return (
    <List>
      {isApartmentAdmin(currentUserCompanies) && (
        <SidebarItem
          activePaths={[PATHS.USERS_IN_APARTMENTS, PATHS.USERS_IN_APARTMENTS_DETAIL]}
          icon={<MyApartmentsIcon />}
          id="usersInApartments-link"
          link={PATHS.USERS_IN_APARTMENTS}
          title={<FormattedMessage {...messages.SidebarApartmentListMyApartments} />}
          disabled={false}
          onClick={onCloseSidebarMobile}
          showOnlyTitle={false}
          showSelected
          showTitleOnHover
        />
      )}
      <SidebarItem
        activePaths={[PATHS.USER_PROFILE]}
        icon={<MyProfileIcon />}
        id="profile-link"
        link={PATHS.USER_PROFILE}
        title={<FormattedMessage {...messages.SidebarApartmentListMyProfile} />}
        disabled={false}
        onClick={onCloseSidebarMobile}
        showOnlyTitle={false}
        showSelected
        showTitleOnHover
      />
    </List>
  );
}

export default ApartmentList;
