export const styles = {
  commonColor: {
    color: 'common.white',
  },
  icon: {
    pr: 0.5,
  },
  inactiveOpacity: {
    opacity: '0.74',
  },
  mainButtonHeading: {
    display: 'flex',
  },
  mainName: {
    fontWeight: 'bold',
    maxWidth: { md: 146, xs: 120 },
    overflow: 'hidden',
    pl: 1,
    textAlign: 'left',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  wrapper: {
    lineHeight: '64px',
    minHeight: '64px',
  },
};
