import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import actions, { selectors } from '../../modules/accessSettings';
import DangerModal from '../DangerModal';
import messages from './messages';

const AccessGroupDeviceRemoveModal = ({ deviceId, groupName, isLoading, onClose, onRemove, open, users }) => {
  const handleRemoveDevice = () => {
    onRemove(deviceId);
    onClose();
  };

  const getIsOpen = open || isLoading;
  return (
    getIsOpen && (
      <DangerModal
        actionButtonLabel={<FormattedMessage {...messages.accessGroupDeviceRemoveModalRemove} />}
        isLoading={isLoading}
        name="access-group-device-remove-modal"
        onClose={onClose}
        onSubmit={handleRemoveDevice}
        open={getIsOpen}
        title={<FormattedMessage {...messages.accessGroupDeviceRemoveModalTitle} />}
      >
        <Typography variant="body1">
          <FormattedMessage
            {...messages.accessGroupDeviceRemoveModalText}
            values={{
              groupName,
              strong: (chunks) => <strong>{chunks}</strong>,
              users,
            }}
          />
        </Typography>
      </DangerModal>
    )
  );
};

AccessGroupDeviceRemoveModal.propTypes = {
  deviceId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  groupName: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  users: PropTypes.number.isRequired,
};

AccessGroupDeviceRemoveModal.defaultProps = {
  deviceId: '',
};

const mapStateToProps = (state) => ({
  groupName: selectors.getAccessGroupName(state),
  isLoading: selectors.getIsRemoveAccessGroupDeviceFetching(state),
  users: selectors.getAccessGroupUsersCount(state),
});

const mapDispatchToProps = {
  onRemove: actions.removeAccessGroupDevice,
};

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(AccessGroupDeviceRemoveModal));
