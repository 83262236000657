import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SUBSCRIPTION_TYPE } from '../../../consts';
import { getVoucherValue } from '../../../helpers';
import { batchPropTypes } from '../../../types';
import InputLabel from '../InputLabel';
import messages from '../messages';

const TotalValue = ({ batch, isSiteMdu }) => {
  const getAdornmentPluralOrSingular = (context) => {
    const totalValue = getVoucherValue(batch.years, batch.subscriptions);
    if (totalValue === 1) {
      return (
        <FormattedMessage
          {...(context === 'apartment'
            ? messages.promoBatchFormInputValueAdornmentApartmentYear
            : messages.promoBatchFormInputValueAdornmentDeviceYear)}
        />
      );
    }
    return (
      <FormattedMessage
        {...(context === 'apartment'
          ? messages.promoBatchFormInputValueAdornmentApartmentYears
          : messages.promoBatchFormInputValueAdornmentDeviceYears)}
      />
    );
  };

  const getSubscriptionInputAdornment = () => {
    if (batch.subscriptionType === SUBSCRIPTION_TYPE.DEVICE) {
      return getAdornmentPluralOrSingular('device');
    }
    if (batch.subscriptionType === SUBSCRIPTION_TYPE.APARTMENT || isSiteMdu) {
      return getAdornmentPluralOrSingular('apartment');
    }
    return getAdornmentPluralOrSingular('device');
  };

  return (
    <>
      <InputLabel>
        <FormattedMessage {...messages.promoBatchFormInputValueLabel} />
      </InputLabel>
      <Typography>
        <strong>{getVoucherValue(batch.years, batch.subscriptions)}</strong>{' '}
        <Box
          component="span"
          sx={{
            color: 'text.secondary',
            display: 'inline-flex',
          }}
        >
          {getSubscriptionInputAdornment()}
        </Box>
      </Typography>
    </>
  );
};

TotalValue.propTypes = {
  batch: batchPropTypes.isRequired,
  isSiteMdu: PropTypes.bool.isRequired,
};

export default TotalValue;
