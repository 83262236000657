import { getOffset } from '../../helpers/dataGrid';
import types from './types';

export const getAccessLogsList = (
  companyId,
  siteId,
  page = 1,
  rowsPerPage = 10,
  filter = {
    fulltext: '',
    results: null,
  },
  order = '-startAt'
) => ({
  companyId,
  filter,
  order,
  page,
  params: {
    filter: filter.fulltext,
    limit: rowsPerPage,
    offset: getOffset(page, rowsPerPage),
    order,
    results: filter.results,
  },
  rowsPerPage,
  siteId,
  type: types.GET_ACCESS_LOGS_LIST_REQUEST,
});

export const getAccessLogDetail = (logId) => ({
  logId,
  type: types.GET_ACCESS_LOG_DETAIL_REQUEST,
});
