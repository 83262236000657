import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { canUserDelete } from '../../../helpers/user';
import { CONTEXT } from '../../../modules/user';
import RowMenuItem from '../../DataGrid/RowMenuItem';
import messages from '../messages';

const DeleteUser = ({ currentUser, onOpenDeleteUserModal, siteIdsInfo, user }) => (
  <RowMenuItem
    colorIcon="error.dark"
    colorText="error.dark"
    disabled={!canUserDelete(user, currentUser, CONTEXT.SITE_USERS, siteIdsInfo)}
    icon={<DeleteIcon />}
    label={<FormattedMessage {...messages.siteUsersRowMenuDeleteUser} />}
    onClick={() => onOpenDeleteUserModal(user)}
  />
);

DeleteUser.propTypes = {
  currentUser: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  onOpenDeleteUserModal: PropTypes.func.isRequired,
  siteIdsInfo: PropTypes.shape({
    companyId: PropTypes.number,
    siteId: PropTypes.number,
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    role: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]),
  }).isRequired,
};

export default DeleteUser;
