import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import React from 'react';
import { useIntl } from 'react-intl';
import { ListItemNumber } from '../../../../components';
import { RingingGroup } from '../RingingGroup';
import TextFieldRow from '../TextFieldRow';
import AutocompleteChips from './AutocompleteChips';
import { IButtonRenderData, ICallSettingOption } from './hooks/useGetStateData';
import messages from './messages';

export interface IButtonListItemData {
  buttonRenderData: IButtonRenderData;
  options: ICallSettingOption[];
  isDeviceDetailEditing: boolean;
  ringingGroupSizeLimit: number;
  onChangeItem: (buttonRenderData: IButtonRenderData, dataUpdate?: ICallSettingOption[]) => void;
  onChangeButtonName: (buttonRenderData: IButtonRenderData, newName: string) => void;
  setDeviceEditing: (value: boolean) => void;
}

const ButtonListItem = (itemData: IButtonListItemData) => {
  const { formatMessage } = useIntl();
  const handleChangeChips = (value: ICallSettingOption[]) => {
    if (!itemData.isDeviceDetailEditing) {
      itemData.setDeviceEditing(true);
    }
    itemData.onChangeItem(itemData.buttonRenderData, value);
  };

  return (
    <ListItem
      sx={{
        borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        padding: 0,
      }}
    >
      <Grid alignItems="center" container>
        <Grid item md={1} xs={1}>
          <ListItemNumber number={itemData.buttonRenderData.index + 1} />
        </Grid>
        <Grid item md={3} xs={6}>
          <TextFieldRow
            handleChangeName={(event: React.ChangeEvent<{ value: unknown }>) => {
              if (!itemData.isDeviceDetailEditing) {
                itemData.setDeviceEditing(true);
              }
              itemData.onChangeButtonName(itemData.buttonRenderData, event.target.value as string);
            }}
            placeholder={formatMessage(messages.placeholderButtonName)}
            value={itemData.buttonRenderData.buttonName}
          />
        </Grid>
        <Grid item md={2} xs={5}>
          <RingingGroup ringingGroup={itemData.buttonRenderData.ringingGroup} />
        </Grid>
        <Grid item md={6} xs={12}>
          <AutocompleteChips
            maxLength={itemData.ringingGroupSizeLimit}
            onChange={handleChangeChips}
            onClear={() => handleChangeChips([])}
            options={itemData.options}
            selected={itemData.buttonRenderData.selectedOptions}
          />
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default ButtonListItem;
