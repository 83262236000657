import Tab from '@mui/material/Tab';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import { TabsContainer } from '../../../components';
import { PATHS } from '../../../config/consts';
import useBreakpoints from '../../../helpers/useBreakpoints';
import { isUserGuest } from '../../../modules/user/utils';
import messages from './messages';

const getUrl = (path, companyId, siteId, userId) =>
  path.replace(':companyId', companyId).replace(':siteId', siteId).replace(':userId', userId);

const TabPanel = ({ companyId, siteId, user, userId }) => {
  const { downSm } = useBreakpoints();
  const location = useLocation();

  const detail = getUrl(PATHS.SITE_USER_DETAIL, companyId, siteId, userId);
  const devices = getUrl(PATHS.SITE_USER_DEVICES, companyId, siteId, userId);
  const access = getUrl(PATHS.SITE_USER_ACCESS, companyId, siteId, userId);

  const value = [detail, devices, access].includes(location?.pathname) ? location?.pathname : null;
  return value ? (
    <TabsContainer value={value}>
      <Tab
        component={Link}
        label={
          downSm ? (
            <FormattedMessage {...messages.tabPanelUserDetailXs} />
          ) : (
            <FormattedMessage {...messages.tabPanelUserDetail} />
          )
        }
        to={detail}
        value={detail}
      />
      {!isUserGuest(user.role) && (
        <Tab component={Link} label={<FormattedMessage {...messages.tabPanelDevices} />} to={devices} value={devices} />
      )}
      <Tab component={Link} label={<FormattedMessage {...messages.tabPanelAccess} />} to={access} value={access} />
    </TabsContainer>
  ) : (
    <>{false}</>
  );
};

TabPanel.propTypes = {
  companyId: PropTypes.number.isRequired,
  siteId: PropTypes.number.isRequired,
  user: PropTypes.shape({
    role: PropTypes.string,
  }),
  userId: PropTypes.number.isRequired,
};

export default TabPanel;
