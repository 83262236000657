import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import qrMissing from '../../assets/font-icons/qrcode-missing.svg';
import useBreakpoints from '../../helpers/useBreakpoints';
import { CopyButton } from '../CopyButton/CopyButton';
import { DownloadButton } from '../DownloadButton/DownloadButton';
import { ShareButton } from '../ShareButton/ShareButton';
import messages from './messages';
import { styles } from './styles';

interface IQRPanel {
  url: string;
}

function QRPanel({ url }: IQRPanel) {
  const [imageBlobData, setImageBlobData] = useState(new Blob());
  const { upSm } = useBreakpoints();

  const isError = url === '';

  useEffect(() => {
    const fetchImage = async () => {
      const result = await fetch(url).then((r) => r.blob());
      setImageBlobData(result);
    };
    fetchImage();
  }, []);

  return (
    <>
      <Box
        sx={isError ? {...styles.root, ...styles.rootError} : styles.root}
      >
        <Box sx={styles.qr} >
          <Box
            component="img"
            sx={{
              maxWidth: 200,
              width: 200,
            }}
            src={isError ? qrMissing : url}
          />
          {isError && (
            <Box sx={styles.missingPin}>
              ########
            </Box>
            )}
        </Box>
        <Box sx={styles.buttons}>
          <Stack direction={upSm ? 'column' : 'row'} justifyContent={'center'} spacing={2}>
            <CopyButton fileData={imageBlobData} disabled={isError} />
            {upSm ? <DownloadButton fileData={imageBlobData} disabled={isError} /> : <ShareButton fileData={imageBlobData} disabled={isError} />}
          </Stack>
        </Box>
      </Box>
      { isError && (
        <Typography sx={styles.errorMessage} variant="body2">
          <FormattedMessage {...messages.AAmodalShowGuestQRError} />
        </Typography>
      ) }
    </>
  );
}

export default QRPanel;
