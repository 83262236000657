import { PATHS } from '../../config/consts';

export const getHtmlContent = (html) =>
  html.replace(/%BASE_URL%/g, PATHS.GTC_PAGES).replace(/%PUBLIC_URL%/g, process.env.PUBLIC_URL);

export const getSidebarData = (html, title = null) => {
  const nodes = [];
  if (title) {
    nodes.push({
      scrollLink: '',
      title,
    });
  }

  if (html) {
    const document = new DOMParser().parseFromString(html, 'text/html');
    document.querySelectorAll('div[data-heading="true"]').forEach((element) => {
      nodes.push({
        scrollLink: element.id,
        title: element.getAttribute('data-title'),
      });
    });
  }
  return nodes;
};

export const getTitleContent = (str) => (str ? str.replace(/\r?\n/g, '<br />') : '');
