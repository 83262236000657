import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PATHS } from '../config/consts';
import SiteUserDetail from '../containers/SiteUserDetail';
import Access from '../containers/SiteUserDetail/Access';
import UserInfoForm from '../containers/SiteUserDetail/Detail/UserInfoForm';
import Devices from '../containers/SiteUserDetail/Devices';
import { useUrlParams } from '../helpers/urlParams';
import { useGetLoadedParams } from '../hooks/paramsHooks';
import { getUserApartmentsAccessGroupsList } from '../modules/accessSettings/actions';
import { getApartmentsList } from '../modules/apartments/actions';
import { ApplicationContext, Roles } from '../modules/commonTypes';
import { CONTEXT } from '../modules/user';
import { getUserPin, getUserRfidCard } from '../modules/user/actions';
import { detailUserRequest, getUserMy2NAppAccessStatus } from '../modules/user/actionsTS';
import { getCurrentUserDetailIsFetching } from '../modules/user/selectors';
import {
  didInvalidUserDetail,
  didInvalidUserDetailMy2nAppStatus,
  didInvalidUserDetailRfid,
} from '../modules/user/selectorsTS';
import { PrivateRoute } from './components/PrivateRoute';

export function SiteUsersDetailRoutes() {
  const { companyId, siteId, userId: loadedUserId } = useGetLoadedParams(ApplicationContext.Site);
  const { userId } = useUrlParams();
  const currentUserDetailIsFetching = useSelector(getCurrentUserDetailIsFetching);
  const dispatch = useDispatch();
  const didUserDetailInvalid = useSelector(didInvalidUserDetail);
  const didUserDetailRfidInvalid = useSelector(didInvalidUserDetailRfid);
  const didUserDetailMy2nInvalid = useSelector(didInvalidUserDetailMy2nAppStatus);
  const didInvalid = didUserDetailInvalid || didUserDetailRfidInvalid || didUserDetailMy2nInvalid;

  useEffect(() => {
    if (!currentUserDetailIsFetching && userId !== undefined) {
      dispatch(detailUserRequest(CONTEXT.SITE_USERS)(companyId, siteId, userId));
      dispatch(getApartmentsList(companyId, siteId));
      dispatch(getUserPin(companyId, siteId, userId));
      dispatch(getUserRfidCard(companyId, siteId, userId));
      dispatch(getUserMy2NAppAccessStatus(companyId, siteId, userId));
      dispatch(getUserApartmentsAccessGroupsList(companyId, siteId, userId));
    }
  }, [companyId, siteId, userId, didInvalid]);

  if (currentUserDetailIsFetching || userId === undefined || loadedUserId === undefined) {
    return <>{false}</>;
  }

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <PrivateRoute allowedRoles={{ contextRoles: [Roles.ADMIN, Roles.STANDARD] }}>
            <SiteUserDetail />
          </PrivateRoute>
        }
      >
        <Route path={'detail'} element={<UserInfoForm />} />
        <Route path={'devices'} element={<Devices />} />
        <Route path={'access'} element={<Access />} />
      </Route>
      <Route
        path={'*'}
        element={
          <Navigate
            replace
            to={PATHS.SITE_USER_DETAIL.replace(':companyId', companyId.toString())
              .replace(':siteId', siteId.toString())
              .replace(':userId', userId?.toString())}
          />
        }
      />
    </Routes>
  );
}
