import { defineMessages } from 'react-intl';

export default defineMessages({
  disableMy2NAccessModalConfirmButton: {
    defaultMessage: 'Disable',
    description: 'Activate My2N Access key modal button label - confirm',
    id: 'disableMy2NAccessModalConfirmButton',
  },
  disableMy2NAccessModalInfoText: {
    defaultMessage:
      'User will be notified but no additional action is required.' +
      '<p>If the user has other access methods available, they can continue using them.</p>',
    description: 'Activate My2N Access key modal text - info',
    id: 'disableMy2NAccessModalInfoText',
  },
  disableMy2NAccessModalTitle: {
    defaultMessage: 'Disable My2N app access',
    description: 'Activate My2N Access key modal - title',
    id: 'disableMy2NAccessModalTitle',
  },
  disableMy2NAccessModalUserText: {
    defaultMessage: 'User won’t be able to use My2N app for access.',
    description: 'Activate My2N Access key modal text - user',
    id: 'disableMy2NAccessModalUserText',
  },
  enableMy2NAccessModalAlert: {
    defaultMessage:
      'Enable this setting only if no users are already paired offline.' +
      '{br}Activating the My2N app access will immediately revoke bluetooth access for all offline-paired users.',
    description: 'Activate My2N Access key modal alert',
    id: 'enableMy2NAccessModalAlert',
  },
  enableMy2NAccessModalCancelButton: {
    defaultMessage: 'Cancel',
    description: 'Activate My2N Access modal button label - cancel',
    id: 'enableMy2NAccessModalCancelButton',
  },
  enableMy2NAccessModalConfirmButton: {
    defaultMessage: 'Enable',
    description: 'Activate My2N Access key modal button label - confirm',
    id: 'enableMy2NAccessModalConfirmButton',
  },
  enableMy2NAccessModalInfoText: {
    defaultMessage:
      'User is required to activate this function in their My2N app. They will be notified through both email and app notification.',
    description: 'Activate My2N Access key modal text - info',
    id: 'enableMy2NAccessModalInfoText',
  },
  enableMy2NAccessModalTitle: {
    defaultMessage: 'Enable My2N app access',
    description: 'Activate My2N Access key modal - title',
    id: 'enableMy2NAccessModalTitle',
  },
  enableMy2NAccessModalUserText: {
    defaultMessage: 'Access using My2N app is enabled for:',
    description: 'Activate My2N Access key modal text - user',
    id: 'enableMy2NAccessModalUserText',
  },
});
