import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { SITE_TYPE } from '../../../../config/sites';
import DeviceApartmentFloorAutocompleteDeprecated from './ApartmentFloorAutocomplete';
import { getNameFieldState, getNumberFieldState, nameField, numberField } from './apartmentFormFieldsControl';
import messages from './messages';

/**
 * This component is temporary solution, use component ready for react-hookform instead
 */
const DeviceNewApartmentFormDeprecated = ({ floors, onChange, siteType, values }) => {
  const numberFieldState = getNumberFieldState(values);
  const nameFieldState = getNameFieldState(values);

  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        pl: 2,
        pr: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <DeviceApartmentFloorAutocompleteDeprecated
            floors={floors}
            onChange={onChange}
            siteType={siteType}
            typedFloor={values?.newApartmentFloor}
            values={values}
          />
        </Grid>
        <Grid item sm={6}>
          <TextField
            error={numberFieldState.hasError}
            helperText={numberFieldState.helperText}
            label={<FormattedMessage {...messages.newApartmentNumberLabel} />}
            name={numberField}
            onChange={onChange}
            required
          />
        </Grid>
        <Grid item sm={12}>
          <TextField
            error={nameFieldState.hasError}
            helperText={nameFieldState.helperText}
            label={<FormattedMessage {...messages.newApartmentNameLabel} />}
            name={nameField}
            onChange={onChange}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

DeviceNewApartmentFormDeprecated.propTypes = {
  apartments: PropTypes.arrayOf(PropTypes.object).isRequired,
  floors: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
  siteType: PropTypes.oneOf(Object.keys(SITE_TYPE)).isRequired,
  values: PropTypes.shape({
    existUser: PropTypes.bool,
    floor: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    formErrors: PropTypes.object,
    newApartmentFloor: PropTypes.string,
    newApartmentName: PropTypes.string,
    newApartmentNumber: PropTypes.string,
  }).isRequired,
};

export default DeviceNewApartmentFormDeprecated;
