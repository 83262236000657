import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import messages from './messages';

const DeviceNameField = ({ disabled, onChange, value }) => (
  <Box mt={1} pb={2}>
    <TextField
      disabled={disabled}
      fullWidth
      inputProps={{
        maxLength: 50,
      }}
      label={<FormattedMessage {...messages.Name} />}
      name="name"
      onChange={onChange}
      value={value}
      variant="outlined"
    />
  </Box>
);

DeviceNameField.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default DeviceNameField;
