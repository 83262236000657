import EditIcon from '@mui/icons-material/Edit';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { PATHS } from '../../../config/consts';
import RowMenuItem from '../../DataGrid/RowMenuItem';
import messages from './messages';

const EditGroup = ({ companyId, groupId, siteId }) => (
  <>
    <RowMenuItem
      component={Link}
      icon={<EditIcon />}
      label={<FormattedMessage {...messages.AccessSettingsDataGridTableRowActionsEdit} />}
      to={PATHS.ACCESS_SETTINGS_DETAIL.replace(':companyId', companyId)
        .replace(':siteId', siteId)
        .replace(':groupId', groupId)}
    />
  </>
);

EditGroup.propTypes = {
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  groupId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  siteId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};

export default EditGroup;
