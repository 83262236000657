import { defineMessages } from 'react-intl';

export default defineMessages({
  siteSettingsAccessTabTitle: {
    defaultMessage: 'Access',
    id: 'siteSettings.access.tabTitle',
  },
  siteSettingsElevatorIntegrationTabTitle: {
    defaultMessage: 'Elevator integration',
    description: 'Tab label - Elevator integration',
    id: 'siteSettings.elevatorIntegration.tabTitle',
  },
  siteSettingsSiteLanguageTabTitle: {
    defaultMessage: 'Site language',
    description: 'Tab label - SiteLanguage',
    id: 'siteLanguage.title',
  },
  siteSettingsTitle: {
    defaultMessage: 'Site settings',
    description: 'Title of site settings page',
    id: 'siteSettingsTitle',
  },
});
