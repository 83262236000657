import { MenuItem } from '@mui/material';
import React from 'react';
import { IUser } from '../../../../../modules/user/store';
import UserSelectOptionLabel, { UserSelectOptionLabelType } from '../UserSelectOptionLabel';

function setOption(user: IUser, currentUser: { id: number }) {
  let type: UserSelectOptionLabelType = 'normal';
  if (user?.id === currentUser?.id) {
    type = 'me';
  }

  let optionApartmentData = user.apartments?.length
    ? `(${user.apartments[0].number}${user.apartments[0].name ? ` – ${user.apartments[0].name}` : ''})`
    : '';
  if (user.apartments?.length > 1) {
    optionApartmentData = `${optionApartmentData} + ${user.apartments.length - 1}`;
  }
  return (
    <MenuItem key={user.id} value={user.id}>
      <UserSelectOptionLabel apartments={optionApartmentData} user={{ ...user, apartments: [] }} type={type} />
    </MenuItem>
  );
}

export function getUsers(users: IUser[], currentUser: { id: number }, addNew: boolean) {
  const sorted = users
    .sort((a: IUser, b: IUser) => {
      if (a.id === currentUser.id) {
        return -1;
      }
      if (b.id === currentUser.id) {
        return 1;
      }
      if (a.lastName < b.lastName) {
        return -1;
      }
      if (a.lastName > b.lastName) {
        return 1;
      }
      return 0;
    })
    .map((user) => setOption(user, currentUser));
  if (addNew) {
    sorted.unshift(
      <MenuItem key={-1} value={-1}>
        <UserSelectOptionLabel type="new" />
      </MenuItem>
    );
  }
  return sorted;
}
