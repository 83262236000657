import { defineMessages } from 'react-intl';

export default defineMessages({
  AAmodalShowPINClose: {
    defaultMessage: 'close',
    id: 'AAmodal.showPIN.close',
  },
  AAmodalShowPINText1: {
    defaultMessage: 'The PIN code allows to open doors through which the user is granted access.',
    id: 'AAmodal.showPIN.text1',
  },
  AAmodalShowPINText2: {
    defaultMessage: 'This PIN code is assigned to:',
    id: 'AAmodal.showPIN.text2',
  },
  AAmodalShowPINTitle: {
    defaultMessage: 'PIN code',
    id: 'AAmodal.showPIN.title',
  },
});
