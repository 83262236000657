import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useLayoutEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { PricingModal, Stepper, Title } from '../../../components';
import AvailableCreditDialog from '../../../components/AvailableCreditModal';
import { SERVICE } from '../../../config/devices';
import { LICENSE, PROCESS } from '../../../config/subscription';
import { useUrlParams } from '../../../helpers/urlParams';
import { useModal } from '../../../hooks/useModal';
import billingActions from '../../../modules/billing';
import { BillingType } from '../../../modules/commonTypes';
import { selectors as companySelectors } from '../../../modules/company';
import { selectors as siteSelectors } from '../../../modules/sites';
import siteActions from '../../../modules/sites';
import actions, { selectors } from '../../../modules/subscription';
import ThreeStep from '../Common/ThreeStep';
import { GP_WEBPAY } from '../constants';
import { getSubscriptionStepsIntl, getUseAvailableCredit } from '../helpers';
import messages from '../messages';
import subscriptionStyles from '../styles';
import {
  mobileVideoOrderContentPropTypes,
  nextPaymentPropTypes,
  paymentPropTypes,
  purchasePaymentResultPropTypes,
} from '../unitProps';
import OneStep from './OneStep';
import TwoStep from './TwoStep';
import WarningBox from './WarningBox';

const styles = {
  addButton: {
    mt: {
      md: 1,
      xs: 0,
    },
  },
  loader: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    px: 0,
    py: 4,
  },
  root: {
    '& sub': {
      verticalAlign: 'text-bottom',
    },
  },
};

const MobileVideoLicense = ({
  calculation,
  company,
  currency,
  isAvailableCredit,
  loading,
  mobileVideoLicenceModel,
  mobileVideoOrderContent,
  mobileVideoPaymentIsLoading,
  mobileVideoPurchaseId,
  mobileVideoPurchaseLocation,
  mobileVideoStartPurchase,
  monthPercentage,
  nextPayment,
  onEditBilling,
  onGetCurrentSiteLicenseModel,
  onLoadMobileVideoLicense,
  onSetProduct,
  product,
  purchasePaymentResult,
  regularPayment,
  unlicensedMobileVideoCount,
}) => {
  const { siteId } = useUrlParams();
  const [isOpenPricingModal, setIsOpenPricingModal] = useState(false);
  const [step, setStep] = useState(1);
  const { formatMessage } = useIntl();

  const { Modal: AvailableCreditModal, onOpen: onOpenAvailableCreditModal } = useModal({
    Modal: AvailableCreditDialog,
  });

  useLayoutEffect(() => {
    onGetCurrentSiteLicenseModel();

    if (isAvailableCredit && mobileVideoLicenceModel.billingType === BillingType.PER_DEVICE) {
      onOpenAvailableCreditModal();
    } else {
      onLoadMobileVideoLicense();
    }
  }, [isAvailableCredit, mobileVideoLicenceModel.billingType]);

  const validationSchema = Yup.object().shape({
    isRecurringPayment: Yup.bool().required(),
    product: Yup.string().required(),
  });

  const submitForm = (values, formActions) => {
    const productData = {
      content: [
        {
          licences: unlicensedMobileVideoCount,
          orderType: 'RECURRING',
          product: values.product,
          siteId,
          type: SERVICE.MOBILE_VIDEO,
        },
      ],
      paymentMethod: GP_WEBPAY,
    };
    setStep((prevStep) => prevStep + 1);
    onSetProduct(productData);
    formActions.resetForm();
    formActions.setSubmitting(false);
  };

  if (loading) {
    return (
      <Box sx={styles.loader}>
        <CircularProgress size={60} />
      </Box>
    );
  }

  return (
    <>
      <Title heading={<FormattedMessage {...messages.subscriptionMOBILE_VIDEO_LICENSETitle} />} />
      <Stepper activeStep={step} steps={getSubscriptionStepsIntl(formatMessage)} />
      <Paper sx={subscriptionStyles.root}>
        <>
          {mobileVideoStartPurchase === PROCESS.STOP && <WarningBox />}
          {mobileVideoStartPurchase === PROCESS.START && !isAvailableCredit && (
            <>
              {isOpenPricingModal && (
                <PricingModal
                  currency={currency}
                  isOpenPricingModal={isOpenPricingModal}
                  onClosePricingModal={() => setIsOpenPricingModal(false)}
                />
              )}
              <Box sx={styles.root}>
                <Formik
                  initialValues={{
                    isRecurringPayment: false,
                    product: product || LICENSE.MOBILE_VIDEO_MONTH,
                  }}
                  onSubmit={submitForm}
                  render={(form) => (
                    <Form autoComplete="off" noValidate>
                      {step === 1 && (
                        <OneStep
                          calculation={calculation}
                          currency={currency}
                          formProps={form}
                          nextPayment={nextPayment}
                          onSetProduct={onSetProduct}
                          regularPayment={regularPayment}
                          setIsOpenPricingModal={setIsOpenPricingModal}
                          siteId={siteId}
                          unlicensedMobileVideoCount={unlicensedMobileVideoCount}
                        />
                      )}
                      {step === 2 && (
                        <TwoStep
                          company={company}
                          currency={currency}
                          formProps={form}
                          mobileVideoOrderContent={mobileVideoOrderContent}
                          mobileVideoPaymentIsLoading={mobileVideoPaymentIsLoading}
                          mobileVideoPurchaseId={mobileVideoPurchaseId}
                          mobileVideoPurchaseLocation={mobileVideoPurchaseLocation}
                          monthPercentage={monthPercentage}
                          onEditBilling={onEditBilling}
                          onStepChange={setStep}
                        />
                      )}
                      {step === 3 && <ThreeStep purchasePaymentResult={purchasePaymentResult} />}
                    </Form>
                  )}
                  validationSchema={validationSchema}
                />
              </Box>
            </>
          )}
        </>
      </Paper>
      {isAvailableCredit && <AvailableCreditModal />}
    </>
  );
};

MobileVideoLicense.propTypes = {
  calculation: paymentPropTypes,
  company: PropTypes.shape({}).isRequired,
  currency: PropTypes.string.isRequired,
  isAvailableCredit: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  mobileVideoLicenceModel: PropTypes.shape({
    billingType: PropTypes.oneOf(Object.keys(BillingType)),
  }).isRequired,
  mobileVideoOrderContent: mobileVideoOrderContentPropTypes,
  mobileVideoPaymentIsLoading: PropTypes.bool.isRequired,
  mobileVideoPurchaseId: PropTypes.string,
  mobileVideoPurchaseLocation: PropTypes.string,
  mobileVideoStartPurchase: PropTypes.string,
  monthPercentage: PropTypes.number,
  nextPayment: nextPaymentPropTypes,
  onEditBilling: PropTypes.func.isRequired,
  onGetCurrentSiteLicenseModel: PropTypes.func.isRequired,
  onLoadMobileVideoLicense: PropTypes.func.isRequired,
  onSetProduct: PropTypes.func.isRequired,
  product: PropTypes.string,
  purchasePaymentResult: purchasePaymentResultPropTypes,
  regularPayment: paymentPropTypes,
  unlicensedMobileVideoCount: PropTypes.number.isRequired,
};

MobileVideoLicense.defaultProps = {
  calculation: null,
  mobileVideoOrderContent: null,
  mobileVideoPurchaseId: null,
  mobileVideoPurchaseLocation: '',
  mobileVideoStartPurchase: null,
  nextPayment: null,
  product: null,
  purchasePaymentResult: null,
  regularPayment: null,
};

const mapStateToProps = (state) => ({
  calculation: selectors.getCalculation(state),
  company: companySelectors.getCompanyData(state),
  currency: companySelectors.getCurrentCompanyCurrencySelector(state),
  isAvailableCredit: getUseAvailableCredit(state),
  loading: selectors.getVideoMobileLicenseIsLoading(state),
  mobileVideoLicenceModel: siteSelectors.getSitesServiceMobileVideoLicenceModel(state),
  mobileVideoOrderContent: selectors.getVMOrderContent(state),
  mobileVideoPaymentIsLoading: selectors.getMobileVideoPaymentIsLoading(state),
  mobileVideoPurchaseId: selectors.getMobileVideoPurchaseId(state),
  mobileVideoPurchaseLocation: selectors.getMobileVideoPurchaseLocation(state),
  mobileVideoStartPurchase: selectors.getMobileVideoStartPurchase(state),
  monthPercentage: selectors.getMonthPercentage(state),
  nextPayment: selectors.getNextPayment(state),
  product: siteSelectors.getSitesServiceMobileVideoProduct(state),
  purchasePaymentResult: selectors.getPurchasePaymentResult(state),
  regularPayment: selectors.getRegularPayment(state),
  unlicensedMobileVideoCount: siteSelectors.getUnlicensedMobileVideoCount(state),
});

const mapDispatchToProps = {
  onEditBilling: billingActions.editBillingShowModal,
  onGetCurrentSiteLicenseModel: siteActions.getCurrentSiteLicenseModel,
  onLoadMobileVideoLicense: actions.getMobileVideoLicenseRequest,
  onSetProduct: actions.setMobileVideoProduct,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileVideoLicense);
