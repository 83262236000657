import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import RowMenuItem from '../../DataGrid/RowMenuItem';
import messages from '../messages';

const DeleteSite = ({ onDeleteSite, site }) => (
  <RowMenuItem
    colorIcon="error.dark"
    colorText="error.dark"
    icon={<DeleteIcon />}
    label={<FormattedMessage {...messages.sitesDataGridActionsDelete} />}
    onClick={() => onDeleteSite(site)}
  />
);

DeleteSite.propTypes = {
  onDeleteSite: PropTypes.func.isRequired,
  site: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    name: PropTypes.string,
  }).isRequired,
};

export default DeleteSite;
