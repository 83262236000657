import { defineMessages } from 'react-intl';

export default defineMessages({
  AAmodalUwLDeleteRFIDCancel: {
    defaultMessage: 'Cancel',
    id: 'AAmodal.UwL.deleteRFID.cancel',
  },
  AAmodalUwLDeleteRFIDSubmit: {
    defaultMessage: 'Delete key fob',
    id: 'AAmodal.UwL.deleteRFID.submit',
  },
  AAmodalUwLDeleteRFIDText: {
    defaultMessage:
      'When you delete the RFID key fob, its user won’t be able to use it for access.<p>The RFID key fob is also deleted for other apartments the user may be member of.</p>',
    id: 'AAmodal.UwL.deleteRFID.text',
  },
  AAmodalUwLDeleteRFIDTitle: {
    defaultMessage: 'Delete RFID key fob',
    id: 'AAmodal.UwL.deleteRFID.title',
  },
});
