import InfoIcon from '@mui/icons-material/InfoOutlined';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert, Link, ListHeaderLabel, Tooltip } from '../../../components';
import { AVQ_SUPPORT, PATHS } from '../../../config/consts';
import { FACE_TRACKING_MODE } from '../../../config/devices';
import { supportsAVQ } from '../helpers';
import messages from './messages';
import types from './types';

const MobileVideoSettings = ({
  adaptiveVideoQuality,
  adaptiveVideoQualitySupported,
  answerMode,
  cameraSwitchAllowed,
  companyId,
  faceTrackingMode,
  firmware,
  handleOnChange,
  isACSDisabled,
  isAdaptiveQuality,
  isConfigManual,
}) => {
  const canUpgradeToSupported = useMemo(
    () => firmware.info.haveUpdate && supportsAVQ(firmware.info.newFirmware.version),
    [firmware.info.haveUpdate, firmware.info.newFirmware?.version]
  );

  return (
    <Grid container direction="column">
      <Grid item>
        <ListHeaderLabel>
          <FormattedMessage {...messages.mobileVideoSettingsTitle} />
        </ListHeaderLabel>
      </Grid>
      <Grid item>
        <FormControl>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={cameraSwitchAllowed}
                  color="primary"
                  disabled={isConfigManual}
                  onChange={(e) => handleOnChange(e.target.checked, types.CAMERA_SWITCH_ALLOWED)}
                  value={cameraSwitchAllowed}
                />
              }
              label={
                <FormattedMessage
                  {...messages.mobileVideoSettingsExternalCamera}
                  values={{ sup: (chunks) => <sup>{chunks}</sup> }}
                />
              }
            />
            {isAdaptiveQuality && (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={adaptiveVideoQuality && adaptiveVideoQualitySupported}
                      color="primary"
                      disabled={!adaptiveVideoQualitySupported || isConfigManual}
                      onChange={(e) => handleOnChange(e.target.checked, types.ADAPTIVE_VIDEO_QUALITY)}
                      value={adaptiveVideoQuality}
                    />
                  }
                  label={
                    <Box component="span">
                      <FormattedMessage {...messages.mobileVideoSettingsAdaptiveVideoQualityText} />
                      {adaptiveVideoQuality && !adaptiveVideoQualitySupported && (
                        <Tooltip
                          placement="bottom"
                          title={
                            <FormattedMessage
                              values={{
                                br: <br />,
                                version: AVQ_SUPPORT,
                              }}
                              {...(canUpgradeToSupported
                                ? messages.mobileVideoSettingsAdaptiveVideoQualityTooltipUpgradeAvailable
                                : messages.mobileVideoSettingsAdaptiveVideoQualityTooltipUpgradeNotAvailable)}
                            />
                          }
                        >
                          <InfoIcon
                            sx={{
                              color: 'info.main',
                              cursor: 'pointer',
                              filter: 'none !important',
                              ml: 1,
                              verticalAlign: 'text-bottom',
                            }}
                          />
                        </Tooltip>
                      )}
                    </Box>
                  }
                />
                <FormHelperText sx={{ ml: 4 }}>
                  <FormattedMessage
                    {...messages.mobileVideoSettingsAdaptiveVideoQualityHelperText}
                    values={{
                      a: (text) => <Link to={PATHS.SUPPORT.replace(':companyId', companyId)}>{text}</Link>,
                    }}
                  />
                </FormHelperText>
              </>
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={!answerMode}
                  color="primary"
                  disabled={isConfigManual}
                  onChange={(e) => handleOnChange(!e.target.checked, types.ANSWER_MODE)}
                  value={!answerMode}
                />
              }
              label={<FormattedMessage {...messages.mobileVideoSettingsRejectIncomingCalls} />}
            />
            <FormHelperText sx={{ ml: 4 }}>
              <FormattedMessage
                values={{ sup: (chunks) => <sup>{chunks}</sup> }}
                {...messages.mobileVideoSettingsRejectIncomingCallsHelperText}
              />
            </FormHelperText>
            {faceTrackingMode && (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={faceTrackingMode === FACE_TRACKING_MODE.ZOOM_AND_PTZ}
                      color="primary"
                      disabled={isConfigManual}
                      onChange={(e) => {
                        handleOnChange(
                          e.target.checked ? FACE_TRACKING_MODE.ZOOM_AND_PTZ : FACE_TRACKING_MODE.DISABLED,
                          types.FACE_TRACKING_MODE
                        );
                      }}
                      value={faceTrackingMode === FACE_TRACKING_MODE.ZOOM_AND_PTZ}
                    />
                  }
                  label={<FormattedMessage {...messages.mobileVideoSettingsFaceZooming} />}
                />
                <FormHelperText sx={{ ml: 4 }}>
                  <FormattedMessage
                    values={{ sup: (chunks) => <sup>{chunks}</sup> }}
                    {...messages.mobileVideoSettingsFaceZoomingHelperText}
                  />
                </FormHelperText>
              </>
            )}
            {isConfigManual && (
              <>
                <Alert severity="warning">
                  <FormattedMessage {...messages.mobileVideoSettingsManualModeWarning} />
                </Alert>
              </>
            )}
          </FormGroup>
        </FormControl>
      </Grid>
    </Grid>
  );
};

MobileVideoSettings.propTypes = {
  adaptiveVideoQuality: PropTypes.bool.isRequired,
  adaptiveVideoQualitySupported: PropTypes.bool.isRequired,
  answerMode: PropTypes.bool.isRequired,
  cameraSwitchAllowed: PropTypes.bool.isRequired,
  companyId: PropTypes.number.isRequired,
  faceTrackingMode: PropTypes.string,
  firmware: PropTypes.shape({
    info: PropTypes.shape({
      haveUpdate: PropTypes.bool,
      newFirmware: PropTypes.shape({
        version: PropTypes.string,
      }),
    }),
  }).isRequired,
  handleOnChange: PropTypes.func.isRequired,
  isACSDisabled: PropTypes.bool.isRequired,
  isAdaptiveQuality: PropTypes.bool.isRequired,
  isConfigManual: PropTypes.bool,
};

export default MobileVideoSettings;
