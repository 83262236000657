import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useUrlParams } from '../../../../../helpers/urlParams';

export function useGetGlobalHooks() {
  const { companyId, siteId } = useUrlParams();
  const { formatMessage } = useIntl();
  const formMethods = useFormContext();

  return {
    companyId: companyId!.toString(),
    formatMessage,
    formMethods,
    siteId: siteId!.toString(),
  };
}
