import { defineMessages } from 'react-intl';

export default defineMessages({
  formActionBackBtn: {
    defaultMessage: 'Back',
    description: 'Device Modal Form action - back button',
    id: 'hipmo.formAction.backBtn',
  },
  formActionCancelBtn: {
    defaultMessage: 'Cancel',
    description: 'Device Modal Form action - cancel button',
    id: 'hipmo.formAction.cancelBtn',
  },
  formActionNextBtn: {
    defaultMessage: 'Next',
    description: 'Device Modal Form action - next button',
    id: 'hipmo.formAction.nextBtn',
  },
  oneStepDescription: {
    defaultMessage:
      'Each device needs to be assigned to a user. Select an existing' +
      ' user or create a new one. New users will receive an email notification to' +
      ' confirm their accounts.',
    description: 'Description',
    id: 'oneStep.description',
  },
  oneStepNameHelperText: {
    defaultMessage: 'The name appears on the device list and phone book',
    description: 'Device name helper text',
    id: 'oneStep.name.helperText',
  },
  oneStepNameLabel: {
    defaultMessage: 'Device name',
    description: 'Device name label',
    id: 'oneStep.name.label',
  },
  userSelectFieldAssignToMe: {
    defaultMessage: 'Assign to me ({currentUser})',
    description: 'UserSelectField - assign to me',
    id: 'userSelectField.assignToMe',
  },
  userSelectFieldCreateNewUser: {
    defaultMessage: 'Create new user',
    description: 'UserSelectField - create new user',
    id: 'userSelectField.createNewUser',
  },
  userSelectFieldNewUserEmailHelperText: {
    defaultMessage: 'The email is important, please double-check',
    description: 'Device Modal UserSelectField - new user email helper text',
    id: 'userSelectField.newUser.email.helperText',
  },
  userSelectFieldNewUserEmailLabel: {
    defaultMessage: 'New user email',
    description: 'Device Modal UserSelectField - new user email label',
    id: 'userSelectField.newUser.email.label',
  },
  userSelectFieldNewUserFirstName: {
    defaultMessage: 'New user first name',
    description: 'Device Modal UserSelectField - new user first name label',
    id: 'hipmo.userSelectField.newUser.firstName',
  },
  userSelectFieldNewUserLastName: {
    defaultMessage: 'New user surname',
    description: 'Device Modal UserSelectField - new user last name label',
    id: 'hipmo.userSelectField.newUser.lastName',
  },
  userSelectFieldUserHelperText: {
    defaultMessage: 'Provide a full name and email address to create a new user',
    description: 'UserSelectField - user helper text',
    id: 'hipmo.userSelectField.user.helperText',
  },
  userSelectFieldUserLabel: {
    defaultMessage: 'User',
    description: 'UserSelectField - user label',
    id: 'hipmo.userSelectField.user.label',
  },
});
