import * as yup from 'yup';
import { getApartmentValidationSchema } from '../../../../validations/apartmentFormValidationSchema';
import {
  getFirstNameValidation,
  getLastNameValidation,
  UserFormFieldNames,
} from '../../../../validations/user/userCommonValidations';

export function getValidationSchema(companyId, siteId, isSiteMdu, isFirstNameRequired, formatMessage) {
  return yup.object().shape({
    ...getApartmentValidationSchema(isSiteMdu, companyId, siteId, formatMessage),
    [UserFormFieldNames.firstName]: getFirstNameValidation(isFirstNameRequired, formatMessage),
    [UserFormFieldNames.lastName]: getLastNameValidation(formatMessage),
  });
}
