import { AVQ_SUPPORT } from '../../config/consts';
import { FIRMWARE_STATUS, RC_STATUS } from '../../config/devices';

export const supportsAVQ = (firmwareVersion: string) => isGreaterEqualFirmware(firmwareVersion, AVQ_SUPPORT);

export const hasSupportedFirmware = (deviceFirmware: string | undefined, latestFirmware: string) =>
  deviceFirmware ? [0, 1].includes(versionCompare(deviceFirmware, latestFirmware)) : false;

export const isGreaterFirmwareVersion = (actualVersion: string, comparedVersion: string) =>
  versionCompare(actualVersion, comparedVersion) === 1;

export const isFirmwareStatusUnsupportedOrDeprecated = (firmwareStatus: string) =>
  firmwareStatus === FIRMWARE_STATUS.UNSUPPORTED || firmwareStatus === FIRMWARE_STATUS.DEPRECATED;

export const isOpenRcConfigurationDisabled = (rcStatus: RC_STATUS | null, firmwareStatus?: string | null) =>
  !rcStatus ||
  !firmwareStatus ||
  [RC_STATUS.OFFLINE, RC_STATUS.DISABLED, RC_STATUS.UNSUPPORTED_FIRMWARE].includes(rcStatus) ||
  isFirmwareStatusUnsupportedOrDeprecated(firmwareStatus);

export function versionCompare(v1: string, v2: string) {
  const x = v1.split('.').map((e) => parseInt(e, 10));
  const y = v2.split('.').map((e) => parseInt(e, 10));

  for (const i in x) {
    y[i] = y[i] || 0;
    x[i] = x[i] || 0;

    if (x[i] === y[i]) {
      continue;
    }

    return x[i] > y[i] ? 1 : -1;
  }
  return y.length > x.length ? -1 : 0;
}

export function isGreaterEqualFirmware(actualVersion: string, comparedVersion: string) {
  const result = versionCompare(actualVersion, comparedVersion);
  return result === 1 || result === 0;
}
