import { defineMessages } from 'react-intl';

export default defineMessages({
  errorActionsCodesPrefixCodesHG: {
    defaultMessage: 'Service currently unavailable.',
    description: '',
    id: 'error.prefixCodes.HG',
  },
  errorActionsCodesPrefixCodesIV: {
    defaultMessage: 'Validation error.',
    description: '',
    id: 'error.prefixCodes.IV',
  },
  errorActionsCodesPrefixCodesRO: {
    defaultMessage: 'Access to resource denied.',
    description: '',
    id: 'error.prefixCodes.RO',
  },
  errorActionsLoginFailure: {
    defaultMessage: 'Invalid credentials.',
    description: '',
    id: 'error.actions.LOGIN_FAILURE',
  },
  errorActionsRegisterFailure: {
    defaultMessage: 'Registration was not successful.',
    description: '',
    id: 'error.actions.REGISTER_FAILURE',
  },
  errorActionsResendInvitationEmailFailure: {
    defaultMessage: 'Given e-mail is not registered.',
    description: '',
    id: 'error.actions.RESEND_INVITATION_EMAIL_FAILURE',
  },
  errorActionsResetPasswordFailure: {
    defaultMessage: 'Given e-mail is not registered.',
    description: '',
    id: 'error.actions.RESET_PASSWORD_FAILURE',
  },
  errorCodesER08: {
    defaultMessage: 'Device cannot be added to My2N, please contact technical support.',
    description: '',
    id: 'error.codes.ER08',
  },
  errorCodesER09: {
    defaultMessage: 'Concurrent update.',
    description: '',
    id: 'error.codes.ER09',
  },
  errorCodesIV65: {
    defaultMessage: 'Invalid floor request.',
    description: '',
    id: 'error.codes.IV65',
  },
  errorCodesIV66: {
    defaultMessage: 'Apartment device limit reached.',
    description: '',
    id: 'error.codes.IV66',
  },
  errorDefault: {
    defaultMessage: 'Internal server error.',
    description: '',
    id: 'error.default',
  },
});
