import DoneIcon from '@mui/icons-material/Done';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ConfigurationModeText, Modal } from '../../../../components';
import { CONFIGURATION_MODE } from '../../../../config/devices';
import messages from './messages';

const ConfigurationModeDialog = ({ isOpen, mode, onClose, onConfirm }) => (
  <Modal
    actions={
      <Box>
        <Button onClick={onClose}>
          <FormattedMessage {...messages.configurationModeDialogCancel} />
        </Button>
        <Button
          color={'primary'}
          onClick={onConfirm}
          startIcon={<DoneIcon />}
          variant={mode === 'AUTOMATIC' ? 'contained' : undefined}
        >
          <FormattedMessage {...configurationModeConfirmMap[mode]} />
        </Button>
      </Box>
    }
    headerVariant="primary"
    name="configuration-mode-dialog"
    onClose={onClose}
    open={isOpen}
    title={<FormattedMessage {...configurationModeTitleMap[mode]} />}
  >
    <ConfigurationModeText mode={mode} />
  </Modal>
);

ConfigurationModeDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  mode: PropTypes.oneOf(['AUTOMATIC', 'MANUAL']).isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ConfigurationModeDialog;

const configurationModeConfirmMap = {
  [CONFIGURATION_MODE.AUTOMATIC]: messages.configurationModeDialogAutomaticConfirm,
  [CONFIGURATION_MODE.MANUAL]: messages.configurationModeDialogManualConfirm,
};

const configurationModeTitleMap = {
  [CONFIGURATION_MODE.AUTOMATIC]: messages.configurationModeDialogAutomaticTitle,
  [CONFIGURATION_MODE.MANUAL]: messages.configurationModeDialogManualTitle,
};
