import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-scroll';

const ContentScrollList = ({ listData }) => (
  <Box
    color="primary.main"
    sx={{
      '& dd:hover': {
        cursor: 'pointer',
      },
    }}
  >
    <dl>
      {listData.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <dd key={index}>
          <Link activeClass="active" duration={1000} smooth spy to={item.scrollLink}>
            {item.title}
          </Link>
        </dd>
      ))}
    </dl>
  </Box>
);

ContentScrollList.propTypes = {
  listData: PropTypes.arrayOf(
    PropTypes.shape({
      scrollLink: PropTypes.string,
      title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    })
  ).isRequired,
};

export default ContentScrollList;
