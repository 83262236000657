import {
  IDeviceElevatorIntegration as IDeviceElevatorIntegrationData,
  IElevatorIntegrationFloorMapping,
  ISiteElevatorIntegration as ISiteElevatorIntegrationData,
  IUserElevatorIntegration as IUserElevatorIntegrationData,
} from '../../api/elevatorIntegrationTS';

export interface IElevatorIntegrationStore {
  device: IDeviceElevatorIntegration;
  site: ISiteElevatorIntegration;
  user: IUserElevatorIntegration;
}

export interface IDeviceElevatorIntegration {
  data?: IDeviceElevatorIntegrationData;
  didInvalidate: boolean;
  error?: any;
  isFetching: boolean;
  set: {
    error?: any;
    isFetching: boolean;
  };
}

export interface ISiteElevatorIntegration {
  data?: ISiteElevatorIntegrationData;
  error?: any;
  isFetching: boolean;
  set: {
    error?: any;
    isFetching: boolean;
  };
  floorMapping: {
    data: IElevatorIntegrationFloorMapping | undefined;
    isFetching: boolean;
    error?: any;
  };
}

export interface IUserElevatorIntegration {
  data?: IUserElevatorIntegrationData;
  didInvalidate: boolean;
  error?: any;
  isFetching: boolean;
  set: {
    error?: any;
    isFetching: boolean;
  };
}

export function getInitialElevatorIntegrationStore(): IElevatorIntegrationStore {
  return {
    device: {
      data: undefined,
      didInvalidate: false,
      error: null,
      isFetching: false,
      set: {
        error: null,
        isFetching: false,
      },
    },
    site: {
      data: undefined,
      error: null,
      floorMapping: {
        data: undefined,
        error: null,
        isFetching: false,
      },
      isFetching: false,
      set: {
        error: null,
        isFetching: false,
      },
    },
    user: {
      data: undefined,
      didInvalidate: false,
      error: null,
      isFetching: false,
      set: {
        error: null,
        isFetching: false,
      },
    },
  };
}
