import { Check, Send } from '@mui/icons-material';
import { Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { enhancedBe4FEApi } from '../../../../../../../apiRtk/be4feApiEnhancer';
import { ActionModal } from '../../../../../../../components/_DesignSystemWrappers/Modal';
import { PinPanel } from '../../../../../../../components/PinPanel';
import { activateUserPin, generateUserPin } from '../../../../../../../modules/apartmentAdmin/actions';
import { ApartmentUser } from '../../../../../../../modules/apartmentAdmin/data';
import { getUserPinSelector } from '../../../../../../../modules/apartmentAdmin/selectors';
import { IApartmentIdsInfo } from '../../../../../../../modules/apartments/store';
import { IModalBase } from '../../../../../../../modules/commonTypes';
import { isUserGuest } from '../../../../../../../modules/user/utils';
import { isUWLUser } from '../../../../../utils/UWLUserType';
import { TestID } from '../../testIds';
import { AAUserName } from '../AAUserName';
import messages from './messages';

interface IGeneratePinModalProps extends IModalBase {
  apartmentIdsInfo: IApartmentIdsInfo;
  user: ApartmentUser;
}

export function GeneratePinModal({
  apartmentIdsInfo: { apartmentId, companyId, siteId },
  onClose,
  open,
  user,
}: IGeneratePinModalProps) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data, error, isFetching } = useSelector(getUserPinSelector);

  const onSubmitSuccessHandler = () => {
    setIsSubmitting(false);
    dispatch(enhancedBe4FEApi.util.resetApiState());
    onClose();
  };

  const onSubmitHandler = () => {
    setIsSubmitting(true);
    if (data) {
      dispatch(activateUserPin(companyId, siteId, apartmentId, user.id, data.id, onSubmitSuccessHandler));
    }
  };

  useEffect(() => {
    if (error) {
      setIsSubmitting(false);
    }
  }, [error]);

  const generatePin = () => {
    dispatch(generateUserPin(companyId, siteId, apartmentId, user.id));
  };

  return (
    <ActionModal
      icon={isUWLUser(user) ? <Check /> : <Send />}
      primaryButtonText={formatMessage(
        isUWLUser(user) || isUserGuest(user.role)
          ? messages.AAmodalGeneratePINSubmitUwL
          : messages.AAmodalGeneratePINSubmit
      )}
      testId={TestID.GeneratePinModal}
      primaryButtonAction={onSubmitHandler}
      isPrimaryButtonDisabled={isSubmitting || isFetching || !!error}
      secondaryButtonText={formatMessage(messages.AAmodalGeneratePINCancel)}
      secondaryButtonAction={onClose}
      muiDialogProps={{ maxWidth: 'xs' }}
      onClose={onClose}
      open={open}
      title={formatMessage(messages.AAmodalGeneratePINTitle)}
    >
      <Stack spacing={2} pb={1}>
        <Typography variant="body2">
          <FormattedMessage {...messages.AAmodalGeneratePINText1} />
        </Typography>
        <Typography variant="body2">
          <FormattedMessage {...messages.AAmodalGeneratePINText2} />
        </Typography>
      </Stack>
      <Stack spacing={2} pb={2}>
        <AAUserName user={user} />
        <PinPanel
          pin={data?.pin}
          onInit={generatePin}
          onGenerate={generatePin}
          isFetching={isFetching}
          hasError={!!error}
        />
      </Stack>
    </ActionModal>
  );
}
