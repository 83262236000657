import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PATHS } from '../config/consts';
import AccessGroupApartmentsDataGrid from '../containers/AccessSettingsDetail/AccessGroupApartmentsDataGrid';
import AccessGroupDetailForm from '../containers/AccessSettingsDetail/AccessGroupDetailForm';
import AccessGroupDevicesDataGrid from '../containers/AccessSettingsDetail/AccessGroupDevicesDataGrid';
import AccessGroupUsersDataGrid from '../containers/AccessSettingsDetail/AccessGroupUsersDataGrid';
import AccessSettingsDetail from '../containers/AccessSettingsDetail/AccessSettingsDetail';
import { useUrlParams } from '../helpers/urlParams';
import { useGetLoadedParams } from '../hooks/paramsHooks';
import { getAccessGroup } from '../modules/accessSettings/actions';
import { getAccessGroupIsFetching } from '../modules/accessSettings/selectors';
import { ApplicationContext, Roles } from '../modules/commonTypes';
import { PrivateRoute } from './components/PrivateRoute';

export function AccessGroupDetailRoutes(): JSX.Element {
  const { companyId, siteId } = useGetLoadedParams(ApplicationContext.Site);
  const { groupId } = useUrlParams();
  const dispatch = useDispatch();

  const isFetching = useSelector(getAccessGroupIsFetching);

  useEffect(() => {
    dispatch(getAccessGroup(companyId, siteId, groupId));
  }, [companyId, siteId, groupId]);

  if (isFetching) {
    return <>{false}</>;
  }

  return (
    <Routes>
      <Route
        path="/*"
        element={
          <PrivateRoute allowedRoles={{ contextRoles: [Roles.ADMIN, Roles.STANDARD] }}>
            <AccessSettingsDetail />
          </PrivateRoute>
        }
      >
        <Route element={<AccessGroupDetailForm />} path={'detail'} />
        <Route element={<AccessGroupDevicesDataGrid />} path={'devices'} />
        <Route element={<AccessGroupUsersDataGrid />} path={'users'} />
        <Route element={<AccessGroupApartmentsDataGrid />} path={'apartments'} />
      </Route>
      <Route
        path={PATHS.ACCESS_SETTINGS_GROUP}
        element={<Navigate replace to={getFallbackPath(companyId, siteId, groupId)} />}
      />
    </Routes>
  );
}

function getFallbackPath(companyId: number, siteId: number, groupId?: string): string {
  if (groupId !== undefined) {
    return PATHS.ACCESS_SETTINGS_DETAIL.replace(':companyId', companyId.toString())
      .replace(':siteId', siteId.toString())
      .replace(':groupId', groupId);
  }

  return PATHS.ACCESS_SETTINGS.replace(':companyId', companyId.toString()).replace(':siteId', siteId.toString());
}
