import WifiIcon from '@mui/icons-material/Wifi';
import Box from '@mui/material/Box';
import { ButtonProps } from '@mui/material/Button';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import LoadingButton from '../LoadingButton';
import messages from './messages';

interface TReadingButton extends ButtonProps {
  isLoading: boolean;
  onClick: () => void;
}

function ReadingButton({ isLoading, onClick, ...other }: TReadingButton) {
  return (
    <Box width={240}>
      <LoadingButton
        {...other}
        color="primary"
        fullWidth
        isLoading={isLoading}
        onClick={onClick}
        startIcon={<WifiIcon />}
        variant="outlined"
      >
        <FormattedMessage {...messages.readingButtonButtonLabel} />
      </LoadingButton>
    </Box>
  );
}

export default ReadingButton;
