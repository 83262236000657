import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { IMPORT_USERS_FAILED_ROW_RESULT } from '../../../config/consts';
import FailedResultStatus from './FailedResultStatus';

const styles = {
  icon: {
    color: 'error.main',
    cursor: 'pointer',
    mt: 0.75,
  },
};

function FailedRecordIcon({ result }) {
  return (
    <Tooltip placement="right" title={<FailedResultStatus result={result} />}>
      <InfoOutlinedIcon fontSize="small" sx={styles.icon} />
    </Tooltip>
  );
}

FailedRecordIcon.propTypes = {
  result: PropTypes.shape({
    detail: PropTypes.string,
    status: PropTypes.oneOf(Object.values(IMPORT_USERS_FAILED_ROW_RESULT)),
  }).isRequired,
};

export default FailedRecordIcon;
