import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Theme } from '@mui/system';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FooterPublic, HeaderPublic } from '../../components';
import { PATHS } from '../../config/consts';
import actions, { selectors } from '../../modules/auth';
import { resetAuthStore } from '../../modules/auth/actions';
import messages from './messages';
import { RegisterControl } from './RegisterControl';
import { IRegisterFormData } from './RegisterForm';
import { SuccessMessage } from './SuccessMessage';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const landingBgImage = require('../../assets/landing_bg.jpg');

const styles = {
  box: {
    backgroundColor: '#fff',
    borderRadius: '6px',
    boxSizing: 'border-box',
    mt: {
      sm: 7.5,
      xs: 3.25,
    },
    pb: {
      lg: 2,
      xl: 3.75,
      xs: 2,
    },
    pt: {
      lg: 5.75,
      xl: 7.5,
      xs: 3.75,
    },
    px: {
      lg: 5.75,
      xl: 7.5,
      xs: 3.75,
    },
    width: '100%',
  },
  boxContent: {
    maxWidth: '810px',
    mx: 'auto',
    my: 0,
    p: 1.25,
    width: '100%',
  },
  btnPass: {
    color: 'primary.main',
    display: 'block',
    fontSize: (theme: Theme) => (theme.typography as { fontSize: number }).fontSize * 1.2,
    mt: 4,
    textDecoration: 'underline',
  },
  sub: {
    '& a': {
      color: 'primary.contrastText',
      ml: 0.75,
    },
    color: '#fff',
    fontWeight: 'regular',
    mb: 8.75,
    mt: 10,
    textAlign: 'center',
  },
  subtitle: {
    color: '#fff',
    fontSize: '16px',
    fontWeight: 'regular',
    mb: 7.5,
    mt: 0,
    textAlign: 'center',
  },
  title: {
    color: '#fff',
    fontSize: '32px',
    fontWeight: 'bold',
    mb: 3,
    mt: {
      sm: 7,
      xl: 15.75,
    },
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  wrapper: {
    backgroundImage: `url(${landingBgImage})`,
    backgroundPosition: '50% 20%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    minHeight: '980px',
  },
};

export function Register() {
  const isRegistered = useSelector(selectors.isRegisteredSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetAuthStore());
  }, [])

  return (
    <>
      <HeaderPublic />
      <Box sx={styles.wrapper}>
        <Box sx={styles.boxContent}>
          <Typography sx={styles.title} variant="h1">
            <FormattedMessage {...messages.registerTitle} />
          </Typography>
          {!isRegistered && (
            <Typography sx={styles.subtitle} variant="h2">
              <FormattedMessage {...messages.registerSubtitle} />
            </Typography>
          )}
          <Box sx={styles.box}>
            {isRegistered ? (
              <SuccessMessage />
            ) : (
              <RegisterControl onRegister={(values: IRegisterFormData) => dispatch(actions.registerRequest(values))} />
            )}
          </Box>
          {!isRegistered && (
            <Typography sx={styles.sub}>
              <FormattedMessage {...messages.registerLoginText} />
              <Link to={PATHS.LOGIN}>
                <FormattedMessage {...messages.registerLoginAction} />
              </Link>
            </Typography>
          )}
        </Box>
      </Box>
      <FooterPublic />
    </>
  );
}
