import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { DeviceModelName, DeviceTypeIcon, LoadingButton, SectionHeader } from '../../../../components';
import { getNameFromDeviceType } from '../../../../helpers/devices';
import { addSnackbarError } from '../../../../modules/notifications/actions';
import { GOLD_LICENSE, GOLD_LICENSE_PACK, GP_WEBPAY, INVOICE } from '../../constants';
import messages from '../../messages';
import LicenseSelection from './LicenseSelection';

const styles = {
  intercomIcon: {
    '& .my2n': {
      fontSize: 48,
    },
  },
  listItem: {
    backgroundColor: 'subscription.backgroundLight',
    mb: 1,
  },
  listItemInvalid: {
    backgroundColor: 'subscription.backgroundError',
  },
  listItemTextPrimary: {
    fontWeight: 'medium',
  },
};

// eslint-disable-next-line max-statements
const SerialNumberList = ({
  onRemoveSerialNumber,
  onSetLicenses,
  onStepChange,
  serialNumbers,
  snLicenseData,
  snLicenseIsLoading,
}) => {
  const [licenseSelection, setLicenseSelection] = useState({});
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    if (!snLicenseIsLoading && serialNumbers && snLicenseData.serialNumbers.length < serialNumbers.length) {
      dispatch(addSnackbarError(intl.formatMessage(messages.serialNumberListErrorNotFound)));
    }
  }, [snLicenseIsLoading]);

  const isSerialNumberInData = (sn) =>
    !!snLicenseData.serialNumbers.find((item) => item.serialNumber === sn)?.deviceType;

  const getSerialNumberData = (sn) => snLicenseData.serialNumbers.find((item) => item.serialNumber === sn);

  const handleLicenseSelect = (sn) => (event) => {
    const { name } = event.target;
    const currentLicense = licenseSelection;

    if (name === GOLD_LICENSE && event.target.checked) {
      GOLD_LICENSE_PACK.forEach((item) => {
        if (sn in currentLicense && item in currentLicense[sn]) {
          currentLicense[sn][item] = false;
        }
      });
    }

    setLicenseSelection({
      ...currentLicense,
      [sn]: {
        ...currentLicense[sn],
        [name]: event.target.checked,
      },
    });
  };

  const handleRemoveSerialNumber = (serialNumber) => {
    const licenseSelectionCopy = licenseSelection;
    delete licenseSelection[serialNumber];
    setLicenseSelection(licenseSelectionCopy);
    onRemoveSerialNumber(serialNumber);
  };

  const handleSetLicenses = () => {
    const licenses = {
      content: [],
      orderNumber: null,
      paymentMethod: snLicenseData.canBeInvoiced ? INVOICE : GP_WEBPAY,
    };

    Object.keys(licenseSelection).forEach((sn) => {
      Object.keys(licenseSelection[sn]).forEach((license) => {
        if (licenseSelection[sn][license]) {
          licenses.content.push({ licence: license, serialNumber: sn, type: 'IP_INTERCOM' });
        }
      });
    });

    onSetLicenses(licenses);
    onStepChange(2);
  };

  const isLicenseSelected = () => {
    let isSelected = false;

    Object.keys(licenseSelection).forEach((sn) => {
      Object.keys(licenseSelection[sn]).forEach((license) => {
        if (licenseSelection[sn][license]) {
          isSelected = true;
        }
      });
    });

    return isSelected;
  };

  return (
    serialNumbers.length > 0 && (
      <>
        <List>
          <SectionHeader headerText={<FormattedMessage {...messages.serialNumberListTitle} />} variant="h6" />
          {serialNumbers.map((serialNumber) => (
            <div key={serialNumber}>
              <ListItem
                sx={{
                  ...((isSerialNumberInData(serialNumber) || snLicenseIsLoading) && styles.listItem),
                  ...(!isSerialNumberInData(serialNumber) && !snLicenseIsLoading && styles.listItemInvalid),
                }}
              >
                <ListItemIcon sx={styles.intercomIcon}>
                  <DeviceTypeIcon deviceType={getSerialNumberData(serialNumber)?.deviceType} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography sx={styles.listItemTextPrimary} variant="subtitle1">
                      <FormattedMessage
                        {...messages.serialNumberListSerialNumberPrimaryText}
                        values={{ serialNumber }}
                      />
                    </Typography>
                  }
                  secondary={
                    getNameFromDeviceType(getSerialNumberData(serialNumber)?.deviceType) && (
                      <Typography>
                        <FormattedMessage
                          {...messages.serialNumberListSerialNumberSecondaryText}
                          values={{
                            sup: (chunks) => <sup>{chunks}</sup>,
                            type: <DeviceModelName deviceType={getSerialNumberData(serialNumber)?.deviceType} />,
                          }}
                        />
                      </Typography>
                    )
                  }
                />
                <ListItemSecondaryAction>
                  <Tooltip title={<FormattedMessage {...messages.serialNumberListRemoveTooltip} />}>
                    <IconButton onClick={() => handleRemoveSerialNumber(serialNumber)}>
                      <DeleteIcon color="secondary" />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
              <Box p={2}>
                <Typography>
                  {!isSerialNumberInData(serialNumber) && !snLicenseIsLoading && (
                    <FormattedMessage {...messages.serialNumberListSerialNumberNotFound} values={{ serialNumber }} />
                  )}
                </Typography>
                {isSerialNumberInData(serialNumber) && (
                  <LicenseSelection
                    onChange={handleLicenseSelect(serialNumber)}
                    selected={serialNumber in licenseSelection ? licenseSelection[serialNumber] : {}}
                    serialNumber={serialNumber}
                    snLicenseData={snLicenseData}
                  />
                )}
              </Box>
            </div>
          ))}
        </List>
        <Grid alignItems="center" container justifyContent="flex-end">
          <Grid item>
            <LoadingButton
              color="primary"
              disabled={!isLicenseSelected()}
              isLoading={snLicenseIsLoading}
              onClick={handleSetLicenses}
              variant="contained"
            >
              <FormattedMessage {...messages.nextStepButton} />
            </LoadingButton>
          </Grid>
        </Grid>
      </>
    )
  );
};

SerialNumberList.propTypes = {
  onRemoveSerialNumber: PropTypes.func.isRequired,
  onSetLicenses: PropTypes.func.isRequired,
  onStepChange: PropTypes.func.isRequired,
  serialNumbers: PropTypes.arrayOf(PropTypes.string).isRequired,
  snLicenseData: PropTypes.shape({
    canBeInvoiced: PropTypes.bool,
    serialNumbers: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  snLicenseIsLoading: PropTypes.bool.isRequired,
};

export default SerialNumberList;
