import { MessageDescriptor } from 'react-intl';
import * as yup from 'yup';
import { getBasicEmailValidation } from '../../../../validations/user/userCommonValidations';
import messages from './messages';

export interface IEmailFormData {
  email: string;
}

export enum FormFieldNames {
  Email = 'email',
}

export const defaultValues: IEmailFormData = {
  email: '',
};

export function getEmailFormValidationSchema(formatMessage: (message: MessageDescriptor) => string) {
  return yup.object().shape({
    [FormFieldNames.Email]: getBasicEmailValidation(
      formatMessage(messages.resetPasswordEmailInvalid),
      formatMessage(messages.resetPasswordEmailRequired)
    ),
  });
}
